import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { InputProps } from "@mui/material/Input";

interface Props extends InputProps {
  label?: string;
  value?: string | number;
  isRequired?: boolean;
  multiline?: boolean;
  rows?: number;
  showSymbolCount?: boolean;
  minChar?: number;
  maxChar?: number;
  handleChange?: (value: any) => void;
  min?: number,
  max?: number,
  background?: string;
  borderRadius?: number | string;
  size?: 'small' | 'medium'
}

const CustomInput: React.FC<Props> = ({
  label,
  name,
  value,
  disabled,
  placeholder,
  isRequired = false,
  multiline = false,
  rows,
  showSymbolCount = false,
  minChar = 100,
  maxChar = 700,
  handleChange,
  type,
  min = -99999999999999,
  max = 9999999999999,
  background = 'white',
  borderRadius = 0,
  size = 'small'
}: Props) => {
  const { common } = useTheme().palette;
  const [currentValue, setCurrentValue] = useState<string | number>("");

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    if (type === 'number') {
      if (value >= min && value <= max) {
        if (handleChange) handleChange(event);

        setCurrentValue(value);
      }
    } else {
      if (handleChange) handleChange(event);

      setCurrentValue(value);
    }
  };

  useEffect(() => {
    setCurrentValue(value ?? "");
  }, [value]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%",
      }}
    >
      {label &&
        <Typography>
          {label}{isRequired && <span style={{ color: common.errorColor }}>*</span>}
        </Typography>
      }
      <TextField
        size={size}
        variant="outlined"
        type={type}
        color="primary"
        name={name}
        value={currentValue}
        onChange={(e: any) => handleInputChange(e)}
        placeholder={placeholder}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        sx={{
          background,
          borderRadius,
          '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: disabled ? common.inputDisabledBg : "transparent"
          },
          '&::-webkit-scrollbar': {
            width: '7px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#E5E0EB',
            borderRadius: '6px'
          },
          '& label.Mui-focused': { color: common.primaryColor },
          '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': { borderColor: common.primaryColor },
            '&.Mui-focused fieldset': { borderColor: common.primaryColor }
          }
        }}
      />
      {
        showSymbolCount && (
          <>
            <Box display="flex" justifyContent="space-between">
              {((currentValue.toString().length > 0 &&
                currentValue.toString().length < minChar) ||
                currentValue.toString().length > maxChar) && (
                  <Typography fontSize={"11px"} color={common.errorColor}>
                    {`Минимальное количество символов должно быть - ${minChar}, максимальное - ${maxChar}`}
                  </Typography>
                )
              }
              <Box
                sx={{
                  display: 'flex',
                  marginLeft: 'auto'
                }}
              >
                <Typography
                  fontSize={"14px"}
                  color={
                    currentValue.toString().length < minChar ||
                      currentValue.toString().length > maxChar
                      ? common.errorColor
                      : "#000000"
                  }
                >
                  {currentValue.toString().length || 0}
                </Typography>
                <Typography fontSize={"14px"}>/{maxChar}</Typography>
              </Box>
            </Box>
          </>
        )
      }
    </Box>
  );
};

export default CustomInput;
