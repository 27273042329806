import React, { useState } from 'react'
import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import { PieChart, Pie, Cell, Label, Tooltip } from "recharts";
import { useQuery } from '@tanstack/react-query';
import { serviceReports } from '@services/reports';
import { courseTypes } from '@utils/enums';
import { CourseStatusEnum } from '@models/course';
import { useTranslation } from 'react-i18next';
import { CourseStatus } from '@interfaces/index';

const COLORS = [
  "#31AA27",
  "#6B6B6B",
  "#D9B201",
  "#FD0053",
];

export const CourseChart = ({ filter }) => {
  const { common } = useTheme().palette;
  const [activePieIndex, setActivePieIndex] = useState<number | null>(null);
  const { t } = useTranslation(["sideMenu", "common", "dictionary", "course"]);
  const { data: totalInfoCourse } = useQuery({
    queryKey: ["totalInfoCourse", filter],
    queryFn: () =>
      serviceReports.getTotalInfoCourse(filter),
  });

  const totalInfo = [{ type: t(`course:${CourseStatus.PUBLISHED}`), value: totalInfoCourse?.data?.totalPublished || 0 },
  { type: t(`course:${CourseStatus.SAVED}`), value: totalInfoCourse?.data?.totalSaved || 0 },
  { type: t(`course:${CourseStatus.WITHDRAWN}`), value: totalInfoCourse?.data?.totalWithdrawn || 0 },
  { type: t(`course:${CourseStatus.CLOSED}`), value: totalInfoCourse?.data?.totalClosed || 0 }
  ]

  const CustomTooltip = () => {
    if (activePieIndex) {
      return (
        <Box display="flex" justifyContent="space-between" width="250px" height="42px" sx={{ background: common.white, padding: "0px 12px" }} alignItems="center">
          <Box display="flex" gap={1} alignItems="center">
            <Box sx={{ width: "15px", height: "15px", borderRadius: "20px", background: `${COLORS[activePieIndex - 1]}` }}>
            </Box>
            <Typography fontWeight={500}>{totalInfo[activePieIndex - 1].type}</Typography>
          </Box>
          <Typography fontWeight={500}>{totalInfo[activePieIndex - 1].value}</Typography>

        </Box>
      );
    }
    return null


  };

  return (
    <Box sx={{ background: common.white, border: `1px solid ${common.borderSecondary}`, borderRadius: "12px", height: "344px", minWidth: "460px", marginTop: "16px" }}>
      <Grid container margin="20px 16px">
        <Grid item xs={9.5}>
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>{t("course:COURSE_UNIVERSITY")}</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ margin: "16px 0px" }} />
      <Box display="flex" sx={{ margin: "0px auto" }}>
        <PieChart
          width={250}
          height={250}
        >
          <Pie
            data={totalInfo}
            cx={110}
            cy={110}
            innerRadius={50}
            outerRadius={100}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            onMouseEnter={(_, index) => setActivePieIndex(index + 1)}
            onMouseLeave={() => setActivePieIndex(null)}
          >
            {(
              <Label
                value={`${totalInfoCourse?.data?.total || 0}`}
                position="center"
                fontSize={"18px"}
                fontWeight={500}
                fill='#000'
              />
            )}
            {totalInfo?.map((_, index: number) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}

          </Pie>
          <Tooltip content={<CustomTooltip />} allowEscapeViewBox={{ x: true, y: true }} />
        </PieChart>
        <Grid sx={{ width: "400px", margin: "30px 0px 0px 30px" }}>
          {totalInfo?.map((mock, index) => {
            return (<Grid container marginBottom="8px" sx={{ padding: "5px 15px", borderRadius: "6px", background: activePieIndex === index + 1 ? "#F5F5F5" : "" }}>
              <Grid item xs={8} gap={1} display="flex" alignItems="center">
                <Box sx={{ width: "15px", height: "15px", borderRadius: "20px", background: `${COLORS[index]}` }}>
                </Box>
                <Typography fontWeight={500}>{mock.type}</Typography>
              </Grid>
              <Grid item xs={4} fontWeight={500}>
                {mock.value}
              </Grid>
            </Grid>)
          })}
        </Grid>
      </Box>
    </Box>
  )
}
