import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined';
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import account from "@services/account";
import { IProfile, IUserRole, Role } from "@interfaces/index";
import SwitchProfile from "./modals/SwitchProfileModal";
import { Popover, Typography } from "@mui/material";
import { ReactComponent as HugeiconsTeacher } from "@assets/svg_files/hugeicons_teacher.svg";
import { serviceUser } from "@services/user";
import { AddNewRoleForFreeUserModal } from "./modals/AddNewRoleForFreeUserModal";

interface IProps {
  profile: IProfile;
  resetProfile: () => void;
}

const ProfileMenu = (props: IProps) => {
  const { t } = useTranslation(["profile", "user", "enum"]);
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const { profile, resetProfile } = props;
  const { lastName, firstName } = profile;
  const { role, orgId } = profile.authorityInfo;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [addRoleAnchorEl, setAddRoleAnchorEl] = useState<HTMLElement | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openAddUserRoleModal, setOpenAddUserRoleModal] = useState<boolean>(false);
  const [userRoles, setUserRoles] = useState<Array<IUserRole>>([]);
  const [hasMutipleRoles, setHasMultipleRoles] = useState<boolean>(false);

  useEffect(() => {
    if (profile?.pltaId) {
      account.getProfileFullInfo(profile.pltaId).then(res => {
        const roles = res.data.userRoles;
        setUserRoles(roles);
        setHasMultipleRoles(roles.length > 1);
      })
    }
  }, [profile.pltaId, openAddUserRoleModal]);

  const handleToggleSwitchProfileModal = (modalState: boolean) => {
    setAnchorEl(null);
    setOpenModal(modalState);
  }

  const logout = () => {
    account.logout()
      .finally(() => {
        resetProfile();
        setAnchorEl(null);
        sessionStorage.removeItem('defaultPageAfterAuth');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('contentAccordionData');
        localStorage.removeItem('breadcrumbsMap');
        navigate('/');
      });
  }

  return (
    <div>
      <Box
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{ cursor: 'pointer' }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.2, mr: 0.1 }}>
          <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <p style={{ margin: 0, fontWeight: 600, overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '170px', whiteSpace: 'nowrap' }}>
              {`${firstName} ${lastName}`}
            </p>
            <KeyboardArrowDownIcon />
          </Box>
          <p style={{ margin: 0, color: "#90A0B7", lineHeight: 1 }}>{t(`roles.${role}`)}</p>
        </Box>
      </Box>
      {Boolean(anchorEl) &&
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          sx={{ marginTop: "8px" }}
          onClose={() => {
            setAnchorEl(null);
            setAddRoleAnchorEl(null);
          }}
          autoFocus={false}
          disableScrollLock={true}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          PaperProps={{
            style: { minWidth: 130 },
          }}
        >
          {role !== Role.SUPER_ADMIN && hasMutipleRoles &&
            <MenuItem
              sx={{ fontWeight: 500 }}
              onClick={() => handleToggleSwitchProfileModal(true)}
            >
              <SwitchAccountOutlinedIcon sx={{ mr: 0.5 }} />
              {t("SWITCH_PROFILE")}
            </MenuItem>
          }
          {/* <MenuItem sx={{ fontWeight: 500 }}>{t("HELP")}</MenuItem> */}

          {orgId === 0 && !hasMutipleRoles && (role === Role.EDUCATOR || role === Role.LISTENER) &&
            <MenuItem
              sx={{
                fontWeight: 500,
                background: Boolean(addRoleAnchorEl) ? common.primaryColor : undefined,
                color: Boolean(addRoleAnchorEl) ? common.white : undefined,
              }}
              onClick={(e) => setAddRoleAnchorEl(e.currentTarget)}
            >
              <PersonAddOutlinedIcon sx={{ mr: 0.5 }} />
              {t('user:ADD_ROLE')}
            </MenuItem>
          }
          <MenuItem
            sx={{ fontWeight: 500 }}
            onClick={() => logout()}>
            <LogoutOutlinedIcon sx={{ mr: 0.5 }} />
            {t("LOGOUT")}
          </MenuItem>
        </Menu>
      }
      {openModal &&
        <SwitchProfile
          openModal={openModal}
          handleToggleModal={handleToggleSwitchProfileModal}
          userRoles={userRoles}
          setUserRoles={setUserRoles}
        />
      }
      {openAddUserRoleModal &&
        <AddNewRoleForFreeUserModal
          openModal={openAddUserRoleModal}
          handleToggleModal={() => {
            setAnchorEl(null)
            setAddRoleAnchorEl(null)
            setOpenAddUserRoleModal(!openAddUserRoleModal)
          }}
          userRoles={userRoles}
          setUserRoles={setUserRoles}
        />
      }
      {Boolean(addRoleAnchorEl) &&
        <Popover
          id="mouse-over-popover-add-role"
          sx={{
            cursor: "pointer"
          }}
          anchorEl={addRoleAnchorEl}
          open={Boolean(addRoleAnchorEl)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={() => setAddRoleAnchorEl(null)}
        >
          <Box
            sx={{
              p: "8px 16px",
              background: common.white,
              width: '200px',
              alignItems: "center",
              display: "flex",
              gap: 1,
              "&:hover": {
                color: common.primaryColor,
                backgroundColor: common.secondaryColor,
              },
            }}
            onClick={() => {
              serviceUser.addRoleFreeUser(role === Role.LISTENER ? Role.EDUCATOR : Role.LISTENER).then((res) => {
                if (res.status === 200) {
                  setUserRoles(res.data.userRoles);
                  setOpenAddUserRoleModal(true);
                }
              })
            }}
          >
            <HugeiconsTeacher stroke="#000" style={{ height: "24px", width: "24px" }} />
            <Typography fontSize="16px">{role === Role.LISTENER ? t('enum:TUTOR') : t('enum:LISTENER')}</Typography>
          </Box>
        </Popover>
      }
    </div>
  );
}

export default ProfileMenu;
