import React, { useEffect, useState } from 'react'
import CustomModal from '@components/Modal'
import { Box, Divider, Typography, Tab, Tabs, useTheme, AlertColor } from '@mui/material'
import { Formik, Form as FormikForm } from "formik";
import { useTranslation } from 'react-i18next';
import FormikInputNew from '@ui/formik/InputNew';
import { TabContext } from '@mui/lab';
import FormikSwitchNew from '@ui/formik/SwitchNew';
import CustomButton from '@ui/Button';
import { initialSettingAntiPlagiarismData, ISettingAntiPlagiarism } from './models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { serviceAntiPlagiarism } from '@services/antiPlagiarism';
import Message from '@components/Message';
import { settingAntiPlagiarismValidationSchema } from './validation';

export const SettingAntiPlagiarismModal = ({ open, handleToggleModal }) => {
  const [typeSetting, setTypeSetting] = useState("TASK")
  const { common } = useTheme().palette;
  const { t } = useTranslation(["sideMenu", "common", "dictionary", "antiPlagiarism", "enum"]);
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const queryClient = useQueryClient()
  const handleChange = (event: React.SyntheticEvent, type: string) => {
    setTypeSetting(type);
  };

  const { data: settingAntiPlagiarism } =
    useQuery({
      queryKey: ['settingAntiPlagiarism'],
      queryFn: () => serviceAntiPlagiarism.getSettingAntiPlagiarism(),
      initialData: initialSettingAntiPlagiarismData
    });

  const { mutate: handleSave } = useMutation({
    mutationFn: (reqBody: ISettingAntiPlagiarism[]) => {
      return serviceAntiPlagiarism.saveSettingAntiPlagiarism(reqBody);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['settingAntiPlagiarism'] })
      setMessage(t("common:messages.DATA_SAVED_SUCCESSFULLY"));
      setMessageOpen(true);
      setMessageType("success");
      setTimeout(() => {
        setMessageOpen(false);
      }, 3000);
    },
  })


  return (
    <CustomModal
      open={open}
      handleToggleModal={handleToggleModal}
      closeIsControlled
      showCloseIcon
      width="640px"
    >
      <Box>
        <Formik
          initialValues={settingAntiPlagiarism?.filter((data) => data.taskType === typeSetting)[0]}
          enableReinitialize
          validationSchema={settingAntiPlagiarismValidationSchema(t)}
          onSubmit={(values) => {
            const reqBody = [
              {
                ...settingAntiPlagiarism?.filter((data) => data.taskType !== typeSetting)[0],
                personMaxCheckCount: values.personMaxCheckCount
              },
              {
                ...values, taskType: typeSetting
              }
            ]
            handleSave(reqBody)
          }}
        >
          {({ isValid, dirty, submitForm }) => {
            return (<FormikForm>
              <Typography fontSize="20px" fontWeight={500}>{t("antiPlagiarism:EDITING_ANTIPLAGIARISM_SETTING")}</Typography>
              <Divider sx={{ margin: "16px 0px" }} />
              <FormikInputNew
                name='personMaxCheckCount'
                label={t("antiPlagiarism:MAXIMUM_NUMBER_PLAGIARISM_CHECKS")}
                isRequired
                flexDirection="initial"
                justifyContent='space-between'
                width='100px'
                type='number'
                min={0}
                fontWeight={600}
              />
              <TabContext value={typeSetting}>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      padding: "10px 0 0 10px",
                    }}
                  >
                    <Tabs
                      onChange={handleChange}
                      indicatorColor="secondary"
                      value={typeSetting}
                      sx={{
                        "& .MuiTab-root.Mui-selected": {
                          color: "#1A141F",
                        },
                        "& .MuiTabs-indicator": {
                          background: common.borderPrimary,
                        },
                      }}
                    >
                      <Tab
                        label={t("course:TASKS")}
                        value={"TASK"}
                        sx={{ textTransform: "none", fontSize: "18px" }}
                      />
                      <Tab
                        label={t("enum:CONTROL")}
                        value={"CONTROL"}
                        sx={{ textTransform: "none", fontSize: "18px" }}
                      />
                    </Tabs>
                  </Box>
                </Box>
              </TabContext>
              {messageOpen && (
                <Box mt="12px" mb="20px">
                  <Message
                    type={messageType}
                    message={message}
                    setMessageOpen={messageOpen}
                  />
                </Box>
              )}
              <FormikSwitchNew name="checkWhenSendingRevision" flexDirection="initial" justifyContent='space-between' labelStart={t("antiPlagiarism:CHECKING_PLAGIARISM_SENDING_ASSIGNMENT")} />
              <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                <Typography color="#6D737A" fontSize="14px" width="90%">{t("antiPlagiarism:ASSIGNMENT_RECHECKED_PLAGIARISM_TEACHER_SENDS")}</Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                  <FormikInputNew
                    name='originality'
                    label={t("antiPlagiarism:ORIGINALITY_COEFFICIENT")}
                    isRequired
                    flexDirection="initial"
                    justifyContent='space-between'
                    width='100px'
                    type='number'
                    min={0}
                    max={100}
                    fontWeight={500}
                    adornmentIcon={<Typography>%</Typography>}
                  />
                  <FormikInputNew
                    name='allowedReduceOriginality'
                    label={t("antiPlagiarism:ALLOW_TEACHER_REDUCE_MINIMUM_ORIGINALITY_PERCENTAGE")}
                    isRequired
                    flexDirection="initial"
                    justifyContent='space-between'
                    width='100px'
                    type='number'
                    fontSize='14px'
                    min={0}
                    max={100}
                    textColor="#6D737A"
                    adornmentIcon={<Typography>%</Typography>}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                  <FormikInputNew
                    name='similarity'
                    label={t("antiPlagiarism:SIMILARITY_COEFFICIENT")}
                    isRequired
                    flexDirection="initial"
                    justifyContent='space-between'
                    width='100px'
                    type='number'
                    min={0}
                    max={100}
                    fontWeight={500}
                    adornmentIcon={<Typography>%</Typography>}
                  />
                  <FormikInputNew
                    name='allowedReduceSimilarity'
                    label={t("antiPlagiarism:ALLOW_TEACHER_REDUCE_MINIMUM_OSIMILARITY_PERCENTAGE")}
                    isRequired
                    flexDirection="initial"
                    justifyContent='space-between'
                    width='100px'
                    type='number'
                    fontSize='14px'
                    textColor="#6D737A"
                    min={0}
                    adornmentIcon={<Typography>%</Typography>}
                  />
                </Box>
                <FormikInputNew
                  name='citation'
                  label={t("antiPlagiarism:CITATION_COEFFICIENT")}
                  isRequired
                  flexDirection="initial"
                  justifyContent='space-between'
                  width='100px'
                  type='number'
                  min={0}
                  fontWeight={500}
                  adornmentIcon={<Typography>%</Typography>}
                />
                <FormikInputNew
                  name='selfCitation'
                  label={t("antiPlagiarism:SELF_CITATION_COEFFIENT")}
                  isRequired
                  flexDirection="initial"
                  justifyContent='space-between'
                  width='100px'
                  type='number'
                  min={0}
                  fontWeight={500}
                  adornmentIcon={<Typography>%</Typography>}
                />
                <FormikInputNew
                  name='symbolReplacementMaxCount'
                  label={t("antiPlagiarism:ALLOW_TEACHER_REDUCE_MAXIMUM_NUMBER_SUBSTITUTIONS")}
                  isRequired
                  flexDirection="initial"
                  justifyContent='space-between'
                  width='100px'
                  type='number'
                  min={0}
                  fontWeight={500}
                  adornmentIcon={<Typography>%</Typography>}
                />
              </Box>
              <Divider sx={{ margin: "16px 0px" }} />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }} gap={1.5}>
                <CustomButton
                  width="92px"
                  height="40px"
                  borderRadius="8px"
                  backgroundColor="#F6F6F6"
                  fontColor={common.primaryColor}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={() => handleToggleModal()}
                >
                  {t('common:actions.CANCEL')}
                </CustomButton>
                <CustomButton
                  width="148px"
                  height="40px"
                  borderRadius="8px"
                  backgroundColor={common.primaryColor}
                  fontColor="#FFFFFF"
                  disabled={!(isValid && dirty)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={submitForm}
                >
                  {t('common:actions.SAVE')}
                </CustomButton>
              </Box>
            </FormikForm>
            )
          }}
        </Formik>
      </Box>
    </CustomModal>)

}    
