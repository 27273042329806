import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, TableCell, useTheme } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { serviceCourse } from '@services/course';
import { useParams } from 'react-router-dom';
import isBefore from 'date-fns/isBefore';
import { useSelector } from 'react-redux';
import { getFlowId } from '@store/journal/journalSelector';


interface Props {
  id: string,
  handleSubjectMark: (subject: any) => void;
  firstDayInList: string | null;
  countAbsent: any;
  setCountUnmarked: (subject: any) => void;
  countUnmarked: any;
  setDisableExportToExcel: (disable: boolean) => void;
  updateLists: () => void
}

export const ListenerVisitCell = ({ id, updateLists, handleSubjectMark, firstDayInList, countAbsent, setCountUnmarked, countUnmarked, setDisableExportToExcel }: Props) => {
  const [attendanceList, setAttendanceList] = useState<any>([]);
  const { common } = useTheme().palette;
  const { courseId } = useParams();
  const flowId = useSelector(getFlowId);
  useEffect(() => {
    if (firstDayInList && flowId && id) {
      const paginationConfig = {
        classId: flowId,
        date: firstDayInList,
        memberId: id
      }

      serviceCourse.getAttendanceByDate(paginationConfig).then((res) => {
        setAttendanceList(res.data)
      }
      )
    }
  }, [firstDayInList, flowId, id, updateLists])

  const absentDay = attendanceList.filter((attendance) => attendance.attendanceStatus === "ABSENT")

  const percentAbsentDay = absentDay.length > 0 && countAbsent === 1 ? `${((absentDay.length / attendanceList.length) * 100).toFixed(2)}%` : "—"

  useEffect(() => {
    const absentPercentage = attendanceList[0]?.permission?.absentPercentage
    setDisableExportToExcel(isNaN(absentPercentage))
  }, [attendanceList, percentAbsentDay, setDisableExportToExcel])

  return (
    <>
      {attendanceList?.map((subject) => {
        if (subject.attendanceStatus === "WORKED") {
          return (
            <TableCell key={subject.id} align="center" size="small" sx={{ border: "1px solid #E5E0EB", background: "#FBFAFC" }}>
              <Box sx={{ background: "#FFF" }} padding="4px 10px 2px 10px" width="80px" marginLeft={attendanceList.length > 1 ? `${100 / (attendanceList.length * 2)}%` : "35%"} border={`1px solid #FFCA0E`} borderRadius="6px" onClick={() => handleSubjectMark(subject)}><CircleOutlinedIcon sx={{ color: "#FFCA0E", fontSize: "16px" }} /></Box>
            </TableCell>
          )
        }
        else if (subject.attendanceStatus === "PRESENT") {
          return (
            <TableCell key={subject.id} align="center" size="small" sx={{ border: "1px solid #E5E0EB", background: "#FBFAFC" }}>
              <Box sx={{ background: "#FFF" }} padding="5px 10px 2px 10px" width="80px" marginLeft={attendanceList.length > 1 ? `${100 / (attendanceList.length * 2)}%` : "35%"} border={`1px solid #3BCA23`} borderRadius="6px" onClick={() => handleSubjectMark(subject)}><CheckOutlinedIcon sx={{ color: "#3BCA23", fontSize: "16px", background: "#FFF" }} /></Box>
            </TableCell>
          )
        } else if (subject.attendanceStatus === "ABSENT") {
          return (
            <TableCell key={subject.id} align="center" size="small" sx={{ border: "1px solid #E5E0EB", background: "#FBFAFC" }}>
              <Box sx={{ background: "#FFF" }} padding="7px 10px 2px 10px" width="80px" marginLeft={attendanceList.length > 1 ? `${100 / (attendanceList.length * 2)}%` : "35%"} border={`1px solid #FD0053`} borderRadius="6px" onClick={() => handleSubjectMark(subject)}><ClearOutlinedIcon sx={{ color: common.errorColor, fontSize: "16px", background: "#FFF" }} /></Box>
            </TableCell>
          )
        } else {
          return (
            <TableCell key={subject.id} align="center" size="small" sx={{ border: "1px solid #E5E0EB", background: "#FBFAFC" }}>
              <Checkbox
                // checked={filter.isDisabled}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCountUnmarked([...countUnmarked, subject.id])
                  } else {
                    setCountUnmarked(countUnmarked.filter((unmarked) => unmarked !== subject.id))
                  }
                }}
                disabled={isBefore(new Date(), new Date(firstDayInList || ""))}
                sx={{
                  marginLeft: 2, color: common.primaryColor, "&, &.Mui-checked": {
                    color: common.primaryColor,
                  },
                }}
              />
              {/* <Button sx={{ width: "100px", border: `1px solid ${common.primaryColor}`, color: common.primaryColor }} size="small" onClick={() => handleSubjectMark(subject)}><AddOutlinedIcon sx={{ color: common.primaryColor, fontSize: "16px" }} /></Button> */}
            </TableCell>
          )
        }
      })}
      <TableCell size="small" sx={{ border: "1px solid #E5E0EB", width: "15%", paddingLeft: "7%" }}>
        {countAbsent === 2 && attendanceList[0]?.permission?.absentCount > 0 ? attendanceList[0]?.permission?.absentCount : countAbsent === 1 && absentDay.length > 0 ? absentDay.length : "—"}
      </TableCell>
      <TableCell size="small" sx={{ border: "1px solid #E5E0EB", width: "15%", paddingLeft: "5%" }}>
        {countAbsent === 2 && attendanceList[0]?.permission ? `${attendanceList[0]?.permission?.absentPercentage.toFixed(2)}%` : countAbsent === 1 ? percentAbsentDay : "—"}
      </TableCell></>
  )

}
