import { useState, useEffect } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme, AlertColor } from '@mui/material'
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import CustomButton from '@ui/Button';
import FormikSelect from "@ui/formik/Select";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSelector } from "react-redux";
import FormikSwitch from '@ui/formik/Switch';
import Message from '@components/Message';
import { serviceIntegration } from '@services/integrations';
import { LocalAdminIntegrationProps, SuperAdminIntegrationProps } from '../models';
import AeroIntegrationModal from './AeroIntegrationModal';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import Breadcrumbs from '@components/Breadcrumbs';
import { IntagrationCode, Role } from '@interfaces/index';
import { AntiPlagiarismIntegrationModal } from './AntiPlagiarismIntegrationModal';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';

interface IMessage {
  open: boolean;
  text: string;
  type: AlertColor
}
const Integrations = () => {
  const { t } = useTranslation(["dictionary", "sideMenu", "enum", "course", "common"]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { common } = useTheme().palette;
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { authorityInfo: { role } } = profile;
  const [initialData, setInitialData] = useState<LocalAdminIntegrationProps | SuperAdminIntegrationProps | null>(null)
  const [message, setMessage] = useState<IMessage>({ open: false, text: '', type: 'success' })
  const [showFullText, setShowFullText] = useState<boolean[]>();
  const [isDeleted, setIsDeleted] = useState(false);
  const [openAeroModal, setOpenAeroModal] = useState<boolean>(false)
  const [openAntiPlagiarismModal, setOpenAntiPlagiarismModal] = useState<boolean>(false)
  const localAdminAcccess = ((role === Role.SUPER_ADMIN) && id) || role === Role.LOCAL_ADMIN
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    if (id) {
      serviceIntegration.getIntegrationByOrgId(Number(id)).then(res => {
        setInitialData({ integrations: res.data })
        setShowFullText(res.data?.map(_ => false))
        setIsDeleted(false);
      })
    }
    else {
      serviceIntegration.getAllIntegrations().then(res => {
        setInitialData({ integrations: res.data })
        setShowFullText(res.data?.map(_ => false))
      })
    }
  }, [id, isDeleted])

  const centerCellStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
  const tableHeadCellStyle = {
    fontWeight: 500,
    fontSize: '14px',
    border: `1px solid ${common.borderSecondary}`
  }
  const integrationType = ["LOCAL", "COMMON"];

  useEffect(() => {
    if (role === Role.SUPER_ADMIN && id) {
      setBreadcrumbs("integration", [{
        level: 1,
        page: 'dictionary',
        label: "dictionary:DICTIONARIES",
        path: '/dict',
        tabNum: null
      }, {
        level: 2,
        page: 'organizations',
        label: "dictionary:ORGANIZATIONS",
        path: '/dict/organizations',
        tabNum: null
      },
      {
        level: 3,
        page: 'edit organization',
        label: "dictionary:EDIT_ORGANIZATION",
        path: `/dict/organization/${id}?tab=additionalSettings`,
        tabNum: null
      },
      {
        level: 4,
        page: 'integrations',
        label: "sideMenu:INTEGRATIONS",
        path: null,
        tabNum: null
      }]);
    } else if (role === Role.LOCAL_ADMIN) {
      setBreadcrumbs("integration", [{
        level: 1,
        page: 'settings',
        label: "course:SETTINGS",
        path: '/settings',
        tabNum: null
      }, {
        level: 2,
        page: 'integrations',
        label: "sideMenu:INTEGRATIONS",
        path: null,
        tabNum: null
      }]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      {((role === Role.SUPER_ADMIN && id) || role === Role.LOCAL_ADMIN) &&
        <Box sx={{ display: "flex", alignItems: 'center', mb: '24px' }}>
          <Breadcrumbs id="integration" />
        </Box>
      }
      <Typography sx={{ fontSize: '36px', fontWeight: 500, mb: '24px' }}>
        {t("sideMenu:INTEGRATIONS")}
      </Typography>
      {
        message.open &&
        <Box sx={{ mb: 2 }}>
          <Message type={message.type} message={message.text} setMessageOpen={message.open} />
        </Box>
      }
      <Box sx={{
        display: 'flex', width: '100%', border: `1px solid ${common.borderSecondary}`, borderRadius: '9px',
        backgroundColor: 'white', p: '32px', minHeight: '600px'
      }}>
        <Formik
          initialValues={initialData || { integrations: [] }}
          enableReinitialize
          validationSchema={null}
          onSubmit={(values) => {
            serviceIntegration.updateIntegrationByOrgId(values.integrations)
              .then(res => {
                if (res.status === 200) {
                  if (res.data.message === 'saved') {
                    setMessage({ open: true, text: t("common:messages.DATA_CHANGED_SUCCESSFULLY"), type: 'success' })
                    setTimeout(() => {
                      setMessage({ ...message, open: false })
                    }, 3000)
                  }
                  if (res.data.message === 'IS_USED') {
                    setMessage({ open: true, text: t('common:messages.RECORD_NOT_DELETED'), type: 'warning' })
                    setTimeout(() => {
                      setMessage({ ...message, open: false })
                    }, 3000)
                  }
                }
              }).catch((e) => {
                setMessage({ open: true, text: t("common:messages.ERROR"), type: 'error' })
                setTimeout(() => {
                  setMessage({ ...message, open: false })
                }, 3000);
              }).finally(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              })
          }}
        >
          {({ values, submitForm }) => {
            return <FieldArray
              name='integrations'
              render={() => (
                <FormikForm style={{ display: 'flex', flexDirection: 'column', gap: '15px', width: '100%' }}>
                  {(role === Role.SUPER_ADMIN && id) &&
                    <CustomButton
                      width="98px"
                      height="40px"
                      backgroundColor={common.primaryColor}
                      borderRadius="8px"
                      fontColor={common.fontWhite}
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        alignItems: "center",
                        color: "#FFFFFF",
                        mb: '0'
                      }}
                      onClick={() => navigate(`/settings/integrations/${id}/add-integrations`)}
                    >
                      {t('common:actions.ADD')}
                    </CustomButton>
                  }
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: '300px', ...tableHeadCellStyle }}>
                            {t('course:task.NAME')}
                          </TableCell>
                          <TableCell sx={{ width: '200px', ...tableHeadCellStyle, borderLeft: 'none' }}>
                            {t('dictionary:TYPE')}
                          </TableCell>
                          <TableCell sx={{ width: '400px', ...tableHeadCellStyle, borderLeft: 'none' }}>
                            {t('common:label.DESCRIPTION')}
                          </TableCell>
                          {localAdminAcccess &&
                            <TableCell sx={{ width: '250px', textAlign: 'center', ...tableHeadCellStyle, borderLeft: 'none' }}>
                              {t('enum:IS_USED')}
                            </TableCell>}
                          {
                            (role === Role.SUPER_ADMIN && id) &&
                            <TableCell sx={{ width: '250px', textAlign: 'center', ...tableHeadCellStyle, borderLeft: 'none', }}>
                              {t('dictionary:ACTION')}
                            </TableCell>
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {((role === Role.SUPER_ADMIN && !!id) || role === Role.LOCAL_ADMIN) && values.integrations.map((orgIntegration, index) => {
                          return (
                            <TableRow key={orgIntegration.id}>
                              <TableCell sx={{ fontWeight: 500, fontSize: '14px', border: `1px solid ${common.borderSecondary}`, borderTop: 'none' }}>
                                {(orgIntegration.integration.code === IntagrationCode.AERO || orgIntegration.integration.code === IntagrationCode.ANTI_PLAGIARISM) ?
                                  <Typography sx={{ cursor: "pointer", ":hover": { color: common.primaryColor } }}
                                    onClick={() => {
                                      orgIntegration.integration.code === IntagrationCode.AERO && setOpenAeroModal(true)
                                      role === Role.LOCAL_ADMIN && orgIntegration.integration.code === IntagrationCode.ANTI_PLAGIARISM && navigate("anti-plagiarism")
                                    }}>
                                    {orgIntegration.integration.name}
                                  </Typography> :
                                  <Typography>
                                    {orgIntegration.integration.name}
                                  </Typography>
                                }
                              </TableCell>
                              <TableCell sx={{ borderRight: `1px solid ${common.borderSecondary}`, textAlign: 'center' }}>
                                <Box sx={centerCellStyle}>
                                  <FormikSelect name={`integrations[${index}].integration.viewType`} options={integrationType}
                                    disabled={role === Role.SUPER_ADMIN || role === Role.LOCAL_ADMIN}
                                  />
                                </Box>
                              </TableCell>
                              <TableCell sx={{ borderRight: `1px solid ${common.borderSecondary}`, textAlign: 'justify' }}>
                                <Box sx={{ mt: '6px', ...centerCellStyle, display: 'inline' }}>
                                  <Typography sx={{ display: 'inline' }}>
                                    {showFullText && showFullText[index] ? orgIntegration.integration.description : orgIntegration.integration.description?.substring(0, 250)}
                                  </Typography>
                                  {orgIntegration.integration.description?.length > 250 && (
                                    <Typography sx={{ color: common.primaryColor, cursor: 'pointer', display: 'inline' }}
                                      onClick={() => {
                                        if (showFullText) {
                                          const updatedShowFullText = [...showFullText];
                                          updatedShowFullText[index] = !showFullText[index];
                                          setShowFullText(updatedShowFullText);
                                        }
                                      }}>
                                      {showFullText && showFullText[index] ? ' Скрыть' : '...Показать полностью'}
                                    </Typography>
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell sx={{ borderRight: `1px solid ${common.borderSecondary}` }}>
                                <Box>
                                  <FormikSwitch name={`integrations[${index}].isActive`} sx={{ ...centerCellStyle }} />
                                </Box>
                              </TableCell>
                              {(role === Role.SUPER_ADMIN && id) && <TableCell sx={{ borderRight: `1px solid ${common.borderSecondary}` }}>
                                <Box sx={centerCellStyle}>
                                  <DeleteOutlineIcon
                                    sx={{ color: common.errorColor, cursor: 'pointer' }}
                                    onClick={() => {
                                      if (!orgIntegration.isActive) {
                                        serviceIntegration.deleteIntegration(orgIntegration.id).then((res) => {
                                          if (res.status === 200) {
                                            setMessage({ open: true, text: t("enum:SERVICE_DELETED"), type: 'success' })
                                            setTimeout(() => {
                                              setMessage({ ...message, open: false })
                                            }, 3000)
                                            setIsDeleted(true)
                                          }
                                        }).catch((e) => {
                                          setMessage({ open: true, text: t("enum:ERROR"), type: 'error' })
                                          setTimeout(() => {
                                            setMessage({ ...message, open: false })
                                          }, 3000);
                                        })
                                      }
                                    }}
                                  />
                                </Box>
                              </TableCell>}
                            </TableRow>
                          )
                        })}
                        {role === Role.SUPER_ADMIN && !id && values.integrations.map((integration, index) => {
                          return (
                            <TableRow key={integration.id}>
                              <TableCell sx={{ fontWeight: 500, fontSize: '14px', border: `1px solid ${common.borderSecondary}`, borderTop: 'none' }}>
                                <Typography onClick={() => integration.code === IntagrationCode.ANTI_PLAGIARISM && setOpenAntiPlagiarismModal(!openAntiPlagiarismModal)} sx={{
                                  color: integration.code === IntagrationCode.ANTI_PLAGIARISM ? common.primaryColor : common.black,
                                  cursor: integration.code === IntagrationCode.ANTI_PLAGIARISM ? "pointer" : "default"
                                }}>
                                  {integration.name}
                                </Typography>
                                {integration.code === IntagrationCode.PLATONUS && <Box
                                  sx={{
                                    borderRadius: "6px",
                                    border: `1px solid ${common.borderSecondary}`,
                                    width: "160px",
                                    display: "flex",
                                    mt: "12px",
                                    gap: 0.5,
                                    alignItems: "center",
                                    background: "#F9FAFB",
                                    cursor: "pointer",
                                    p: "4px 8px",
                                    "&:hover": { border: `1px solid ${common.primaryColor}` }
                                  }}
                                  onClick={() => navigate("/integrations/logs")}
                                >
                                  <ReportOutlinedIcon sx={{ fontSize: "20px" }} />
                                  <Typography fontSize="14px">Перейти к логам</Typography>
                                </Box>}
                              </TableCell>
                              <TableCell sx={{ borderRight: `1px solid ${common.borderSecondary}`, textAlign: 'center' }}>
                                <Box sx={centerCellStyle}>
                                  <FormikSelect name={`integrations[${index}].viewType`} options={integrationType}
                                    disabled={role === Role.SUPER_ADMIN}
                                  />
                                </Box>
                              </TableCell>
                              <TableCell sx={{ borderRight: `1px solid ${common.borderSecondary}`, textAlign: 'justify' }}>
                                <Box sx={{ mt: '6px', ...centerCellStyle, display: 'inline' }}>
                                  <Typography sx={{ display: 'inline' }}>
                                    {showFullText && showFullText[index] ? integration.description : integration.description?.substring(0, 250)}
                                  </Typography>
                                  {integration.description?.length > 250 && (
                                    <Typography sx={{ color: common.primaryColor, cursor: 'pointer', display: 'inline' }}
                                      onClick={() => {
                                        if (showFullText) {
                                          const updatedShowFullText = [...showFullText];
                                          updatedShowFullText[index] = !showFullText[index];
                                          setShowFullText(updatedShowFullText);
                                        }
                                      }}>
                                      {showFullText && showFullText[index] ? ' Скрыть' : '...Показать полностью'}
                                    </Typography>
                                  )}
                                </Box>
                              </TableCell>
                              {localAdminAcccess &&
                                <TableCell sx={{ borderRight: `1px solid ${common.borderSecondary}` }}>
                                  <Box>
                                    <FormikSwitch name={`integrations[${index}].isActive`} sx={{ ...centerCellStyle }} />
                                  </Box>
                                </TableCell>}
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {localAdminAcccess &&
                    <Box sx={{
                      mt: '250px', display: 'flex', justifyContent: 'flex-end'
                    }}>
                      <CustomButton
                        width="98px"
                        height="40px"
                        backgroundColor={common.primaryColor}
                        borderRadius="8px"
                        fontColor={common.fontWhite}
                        sx={{
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "20px",
                          alignItems: "center",
                          color: "#FFFFFF",
                        }}
                        onClick={submitForm}
                        disabled={initialData?.integrations.length === 0}
                      >
                        {t('common:actions.SAVE')}
                      </CustomButton>
                    </Box>}
                  {openAntiPlagiarismModal && <AntiPlagiarismIntegrationModal
                    openModal={openAntiPlagiarismModal}
                    handleToggleModal={() => setOpenAntiPlagiarismModal(false)}
                    integrationsInfo={initialData?.integrations}
                    handleMessageSuccess={() => {
                      setMessage({ open: true, text: t("common:messages.CONNECTION_SUCCESS_ANTI_PLAGIAT"), type: 'success' })
                      setTimeout(() => {
                        setMessage({ ...message, open: false })
                      }, 3000);
                    }} />}
                  {openAeroModal && <AeroIntegrationModal
                    openModal={openAeroModal}
                    handleToggleModal={() => setOpenAeroModal(false)}
                    integrationsInfo={initialData?.integrations}
                    isReadable
                  />}
                </FormikForm>
              )}
            />
          }}
        </Formik>
      </Box>
    </Box>
  )
}

export default Integrations
