import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { MaterialReactTable, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import {
  type MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table"
import { Typography } from '@mui/material';
import { formatDateWithHourMinute } from '@utils/functions';

export const StudentDetailsAntiPlagiarism = ({ studentDetails }) => {
  const { t } = useTranslation(["dictionary", "course", "enum", "antiPlagiarism"]);
  const [studentDetailsd, setStudentDetailsd] = useState(studentDetails);
  const columns = useMemo<any>(
    () => [
      {
        accessorKey: 'taskName',
        header: `${t("enum:TASK")}`,
        size: 400
      },
      {
        accessorKey: 'taskType',
        header: `${t("course:task.TYPE_OF_TASK")}`,
        Cell: ({ renderedCellValue, row }) => (
          <Typography>{t(`enum:${row.original.taskType}`)}</Typography>
        ),
        size: 80
      },
      {
        accessorKey: 'date',
        header: `${t("antiPlagiarism:CHECK_TIME")}`,
        size: 80,
        Cell: ({ renderedCellValue, row }) => (
          <Typography>{formatDateWithHourMinute(row.original.date)}</Typography>
        ),
      },
      {
        accessorKey: 'status',
        header: `${t("antiPlagiarism:CHECK_RESULT")}`,
        size: 80
      },

    ],
    [t],
  );

  const table = useMaterialReactTable({
    columns,
    data: studentDetailsd, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableTopToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    enableTableFooter: false,
    enableBottomToolbar: false,
    enableRowNumbers: false,
    localization: {
      noRecordsToDisplay: t('dictionary:EMPTY'),
      rowsPerPage: t('enum:LINES_PER_PAGE'),
      actions: "",
      expand: "",
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default, //change default background color
    }),
    muiTableBodyRowProps: { hover: false },
    muiTableHeadCellProps: {
      sx: {
        // borderLeft: '1px solid rgba(81, 81, 81, .5)'
      },
    },
    muiTableBodyCellProps: {
      sx: {
        // borderLeft: '1px solid rgba(81, 81, 81, .5)',
      },
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        display: 'grid'
      },
    },
    muiTableContainerProps: {
      sx: {
        '&::-webkit-scrollbar': { height: '4px', width: '7px' },
        '&::-webkit-scrollbar-thumb': { backgroundColor: '#D9D9D9', borderRadius: '4px' }
      },
    },
  });

  //using MRT_Table instead of MaterialReactTable if we do not need any of the toolbar components or features
  return <MaterialReactTable table={table} />;
};
