import { ThemeOptions } from "@mui/material";

export const darkTheme: ThemeOptions = {
  palette: {
    mode: "dark",
    common: {
      primaryColor: '#C2CFE0',
      errorColor: 'red',
      fontPrimary: '#FFFFFF',
      // rootBgPrimary: 'linear-gradient(107.91deg, rgba(234, 171, 240, 0.15) 7.37%, rgba(70, 35, 233, 0.15) 95.19%)',
      // rootBgPrimary: 'linear-gradient(107.91deg, #EAABF0 7.37%, #4623E9 95.19%)',
      headerBgPrimary: 'black',
      footerBgPrimary: 'black',
    },
  },
};