import { useEffect } from 'react'
import Breadcrumbs from "@components/Breadcrumbs";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { useBreadcrumbs } from '@context/BreadcrumbsContext';

const TermsOfUse = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  const textStyle = {
    fontFamily: 'Golos',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '25px'
  }

  const { common } = useTheme().palette;

  useEffect(() => {
    setBreadcrumbs("terms_of_use", [{
      level: 1,
      page: 'home',
      label: 'Главная',
      path: '/',
      tabNum: null
    }, {
      level: 2,
      page: 'organizations',
      label: 'Пользовательское соглашение',
      path: null,
      tabNum: null
    }]);
  }, [setBreadcrumbs])

  return (
    <Box sx={{ width: '100%', pl: '120px', mt: '24px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Breadcrumbs id="terms_of_use" />
      <h1>Пользовательское соглашение</h1>
      <Box sx={{ width: '880px', backgroundColor: '#fff', borderRadius: '24px', p: '40px', border: `1px solid ${common.borderSecondary}` }}>
        <Typography sx={{ ...textStyle, fontWeight: 600, fontSize: '22px', mb: '24px' }}>1. Общие положения</Typography>
        <Typography sx={textStyle}>1.1. Настоящее Пользовательское соглашение (далее – Соглашение) регулирует отношения по использованию платформы обучения в онлайн режиме («Platonus Academy») физическими и (или) юридическими лицами.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>1.2. В настоящем Соглашении используются следующие понятия:</Typography>
        <Typography sx={textStyle}>1) Платформа обучения в онлайн режиме (далее – «Platonus Academy»), расположенная на доменном имени ***, предназначена для повышения уровня образованности и профессионализма Пользователей;
        </Typography>
        <Typography sx={textStyle}>2) Пользователь – физическое или юридическое лицо, принявшее условия настоящего Соглашения;</Typography>
        <Typography sx={textStyle}>3) Администратор Портала (далее – Администратор) – работники Товарищества с ограниченной ответственностью «Platonus», осуществляющие методологическое, техническое сопровождение и системно-техническое обслуживание Портала;
        </Typography>
        <Typography sx={textStyle}>4) Учетная запись (аккаунт) – набор данных, хранящихся в программном обеспечении и (или) системе Портала, содержащих сведения, необходимые для идентификации Пользователя в целях предоставления ему доступа;
        </Typography>
        <Typography sx={textStyle}>5) Владелец Портала – Товарищество с ограниченной ответственностью «Platonus»;
        </Typography>
        <Typography sx={textStyle}>6) Инструкция по работе на Портале – документ, содержащий правила, указания или руководства, устанавливающие порядок и способ выполнения или осуществления каких-либо действий на Портале.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>1.3. Настоящее Соглашение является публичным договором-офертой (предложением) согласно пункту 5 статьи 395 Гражданского кодекса Республики Казахстан и регулирует отношения, возникающие при пользовании Портала.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>1.4. Администратор оставляет за собой право в любое время изменять, добавлять или удалять пункты настоящего Соглашения без уведомления Пользователя.
          Новая редакция настоящего Соглашения вступает в силу с момента ее опубликования на Портале.</Typography>
      </Box>
      <Box sx={{ width: '880px', backgroundColor: '#fff', borderRadius: '24px', p: '40px', border: `1px solid ${common.borderSecondary}` }}>
        <Typography sx={{ ...textStyle, fontWeight: 600, fontSize: '22px', mb: '24px' }}>2. Предмет соглашения</Typography>
        <Typography sx={textStyle}>
          2.1. Предметом настоящего Соглашения является предоставление Пользователю доступа к содержащимся на Портале информации и урегулирование взаимоотношений по оказанию услуг «Platonus Academy»:
        </Typography>
        <Typography sx={textStyle}>2.1.1. Размещение онлайн курсов на Платформе;</Typography>
        <Typography sx={textStyle}>2.1.2. Онлайн обучение на Платформе;
        </Typography>
        <Typography sx={textStyle}>2.1.3. Получение сертификата о прохождении курса при сдаче итогового тестирования, при этом пороговое значение не должно быть ниже 70% правильных ответов;
        </Typography>
        <Typography sx={textStyle}>2.1.4. Оплата стоимости онлайн обучения в размере и порядке, указанном при выборе курса на Платформе посредством системы электронных платежей;
        </Typography>
        <Typography sx={textStyle}>2.1.5. Возврат стоимости онлайн обучения производится до начала обучения по приобретенному курсу на Платформе посредством системы электронных платежей.
        </Typography>
      </Box>
      <Box sx={{ width: '880px', backgroundColor: '#fff', borderRadius: '24px', p: '40px', border: `1px solid ${common.borderSecondary}` }}>
        <Typography sx={{ ...textStyle, fontWeight: 600, fontSize: '22px', mb: '24px' }}>3. Регистрация пользователя</Typography>
        <Typography sx={textStyle}>
          3.1. Использование сервиса для Пользователей доступно только после регистрации на Портале. Пользователь при регистрации на Портале обязан указать принадлежащий лично ему адрес электронной почты (e-mail).
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>3.2. При регистрации Пользователю присваивается указанная им пара логин (e-mail) и пароль, которая используется в дальнейшем Пользователем при работе с Порталом.
          Пользователь может пройти процедуру регистрации только один раз.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          3.3. При авторизации на Портале у Пользователя может запрашиваться дополнительная информация для подтверждения личности.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          3.4. Восстановление пароля Пользователя осуществляется при помощи его e-mail, указанного при регистрации.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          3.5. При Регистрации Пользователь выражает свое согласие с настоящим Соглашением и принимает на себя указанные в нем права и обязанности, связанные с использованием и функционированием Портала.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          3.6. Доступ пользователя к изменению личной информации, содержащейся в профиле, производится на основе уникальных логина (e-mail) и пароля, введенных Пользователем при регистрации.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          3.7. Вся информация, размещенная на Портале, доступна для уточнения и дальнейшего использования ОВПО для оценки.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          3.8. Все данные, размещенные на Портале, является общедоступными, за исключением пароля для доступа к личному разделу Пользователя и персональных данных Пользователя. Персональные данные Пользователя доступны только авторизованным на Портале ОВПО.
        </Typography>
      </Box>
      <Box sx={{ width: '880px', backgroundColor: '#fff', borderRadius: '24px', p: '40px', border: `1px solid ${common.borderSecondary}` }}>
        <Typography sx={{ ...textStyle, fontWeight: 600, fontSize: '22px', mb: '24px' }}>4. Права и обязанности сторон</Typography>
        <Typography sx={{ ...textStyle, fontWeight: 500 }}>
          4.1. Пользователь вправе:</Typography>
        <Typography sx={textStyle}>4.1.1. Получать доступ к использованию Портала при условии соблюдения условий настоящего Соглашения;</Typography>
        <Typography sx={textStyle}>4.1.2. Пользоваться Порталом для получения услуг, описанных в пункте 2.1 раздела 2 настоящего Соглашения;
        </Typography>
        <Typography sx={textStyle}>4.1.3. Менять логин и (или) пароль для доступа к Учетной записи;
        </Typography>
        <Typography sx={textStyle}>4.1.4. Осуществлять иные права, не запрещенные законодательством Республики Казахстан;
        </Typography>
        <Typography sx={textStyle}>4.1.5. Получить консультационную и практическую помощь от Администратора в части пользования Порталом.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px', fontWeight: 500 }}>
          4.2. Пользователь обязуется:
        </Typography>
        <Typography sx={textStyle}>
          4.2.1. Предоставить Администратору достоверную, точную и полную информацию по пунктам, предлагаемым в форме регистрации на Портале. Любые сомнения Администратора о недостоверности информации, предоставленной Пользователем, влекут за собой отказ в регистрации, временную блокировку и (или) удаление Учетной записи Пользователя;
        </Typography>
        <Typography sx={textStyle}>
          4.2.2. Не выдавать себя за другое физическое или юридическое лицо;
        </Typography>
        <Typography sx={textStyle}>
          4.2.3. Сохранить в тайне уникальный логин и пароль, используемые для доступа в Учетную запись. Логин и пароль Учетной записи, являются конфиденциальной информацией и не подлежат разглашению, за исключением случаев, предусмотренных действующим законодательством Республики Казахстан. Любые риски, связанные с утерей Пользователем пароля и логина собственной Учетной записи, Пользователь несет самостоятельно;
        </Typography>
        <Typography sx={textStyle}>
          4.2.4. Периодически знакомиться на Портале с содержанием настоящего Соглашения в целях ознакомления с изменениями, внесенными согласно пункту 1.4. раздела 1 настоящего Соглашения;
        </Typography>
        <Typography sx={textStyle}>4.2.5. Не размещать на Портале:</Typography>
        <Typography sx={textStyle}>
          1) информацию, которую Пользователь не имеет права делать публичной по закону или согласно каким-либо договорным отношениям;
        </Typography>
        <Typography sx={textStyle}>
          2) информацию, нарушающую действующее законодательство, честь и достоинство, права и охраняемые законом интересы третьих лиц;
        </Typography>
        <Typography sx={textStyle}>
          3) информацию, которая нарушает авторские и (или) смежные права собственности;
        </Typography>
        <Typography sx={textStyle}>
          4) информацию оскорбительного содержания, а также пропагандирующую дискриминацию людей по какому-либо признаку, насилие, ненависть;
        </Typography>
        <Typography sx={textStyle}>
          5) информацию и (или) ссылки на материалы, содержащие вирусы или другое вредоносное программное обеспечение.
        </Typography>
        <Typography sx={textStyle}>
          4.2.6. Соблюдать иные требования и выполнять иные обязательства, предусмотренные настоящим Соглашением и утвержденными нормативно-правовыми актами Республики Казахстан.
        </Typography>
        <Typography sx={textStyle}>
          4.2.7. Не использовать сервисы Портала с целью:
        </Typography>
        <Typography sx={textStyle}>
          4.2.7.1. Загрузки контента, который является незаконным, нарушает любые права третьих лиц, пропагандирует насилие, жестокость, ненависть и (или) дискриминацию по расовому, национальному, половому, религиозному, социальному признакам и содержит недостоверные сведения и (или) оскорбления в адрес конкретных лиц, организаций, органов власти;
        </Typography>
        <Typography sx={textStyle}>
          4.2.7.2. Побуждения к совершению противоправных действий, а также содействия лицам, действия которых направлены на нарушение ограничений и запретов, действующих на территории Республики Казахстан;
        </Typography>
        <Typography sx={textStyle}>
          4.2.7.3. Нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой форме;
        </Typography>
        <Typography sx={textStyle}>
          4.2.7.4. Представления себя за другого человека или представителя организации и (или) сообщества без достаточных на то прав, в том числе за работников Портала;
        </Typography>
        <Typography sx={textStyle}>
          4.2.7.5. Сознательного введения в заблуждение относительно информации и данных, размещенных на Портале.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px', fontWeight: 500 }}>
          4.3. Пользователю запрещается:
        </Typography>
        <Typography sx={textStyle}>
          4.3.2. Нарушать надлежащее функционирование Портала;
        </Typography>
        <Typography sx={textStyle}>
          4.3.3. Любым способом обходить навигационную структуру Портала для получения или попытки получения любой информации, документов или материалов любыми средствами, которые специально не представлены сервисами данного Портала;
        </Typography>
        <Typography sx={textStyle}>
          4.3.4. Несанкционированный доступ к функциям Портала, любым другим системам или сетям, относящимся к Порталу, а также к любым услугам, предлагаемым на Портале;
        </Typography>
        <Typography sx={textStyle}>
          4.3.5. Нарушать систему безопасности или аутентификации на Портале или в любой сети, относящейся к Порталу;
        </Typography>
        <Typography sx={textStyle}>
          4.3.6. Выполнять обратный поиск, отслеживать или пытаться отслеживать любую информацию о любом другом Пользователе Портала;
        </Typography>
        <Typography sx={textStyle}>
          4.3.7. Использовать Портал и его содержание в любых целях, запрещенных законодательством Республики Казахстан, а также подстрекать к любой незаконной деятельности или другой деятельности, нарушающей права Владельца Портала или других лиц;
        </Typography>
        <Typography sx={textStyle}>
          4.3.8. Размещать информацию об участии в инвестиционных пирамидах и схемах, матричных программах, других аналогичных схемах быстрого обогащения или программах сетевого маркетинга, иных программах, по своему содержанию направленных на хищение чужого имущества или приобретение права на чужое имущество путем обмана или злоупотребления доверием.
        </Typography>

        <Typography sx={{ ...textStyle, mt: '24px', fontWeight: 500 }}>
          4.4. Администратор вправе:
        </Typography>
        <Typography sx={textStyle}>
          4.4.1. В случае нарушения Пользователем условий настоящего Соглашения отказать в регистрации Пользователю, удалить или блокировать Учетную запись Пользователя;
        </Typography>
        <Typography sx={textStyle}>
          4.4.2. Запрашивать и получать необходимую информацию от Пользователя в порядке, определенном законодательством Республики Казахстан;
        </Typography>
        <Typography sx={textStyle}>
          4.4.3. Проверять достоверность информации, представленной Пользователем на Портале;
        </Typography>
        <Typography sx={textStyle}>
          4.4.4. В любое время изменять оформление и пользовательский интерфейс Портала, его содержание, содержание предоставляемых функций, изменять или дополнять используемые скрипты, программное обеспечение, контент и другие объекты, используемые или хранящиеся на Портале и любые Приложения, без уведомления Пользователя
        </Typography>
      </Box>

      <Box sx={{ width: '880px', backgroundColor: '#fff', borderRadius: '24px', p: '40px', border: `1px solid ${common.borderSecondary}` }}>
        <Typography sx={{ ...textStyle, fontWeight: 600, fontSize: '22px', mb: '24px' }}>5. Вступление соглашения в силу</Typography>
        <Typography sx={textStyle}>
          5.1. Настоящее Соглашение вступает в силу с момента принятия Пользователем его условий (акцепт) путем прохождения процедуры регистрации на Портале, что по смыслу пункта 3 статьи 396 Гражданского кодекса Республики Казахстан является принятием (акцептом) оферты Администратора.
          Настоящее Соглашение действует на неопределенный срок.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          5.2. Регистрация выполняется путем заполнения формы регистрации Пользователя, выражения Пользователем согласия со всеми условиями настоящего Соглашения, и отправки данных формы регистрации на Портал согласно Инструкции по работе на Портале.
        </Typography>
      </Box>

      <Box sx={{ width: '880px', backgroundColor: '#fff', borderRadius: '24px', p: '40px', border: `1px solid ${common.borderSecondary}` }}>
        <Typography sx={{ ...textStyle, fontWeight: 600, fontSize: '22px', mb: '24px' }}>6. Ответственность</Typography>
        <Typography sx={textStyle}>
          6.1. При размещении на Портале любой информации, сведений и материалов Пользователь несет персональную ответственность за содержание размещаемых им материалов, сведений и данных, правомерность их размещения и использования в соответствии с законодательством Республики Казахстан.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          6.2. Пользователь несет персональную ответственность за безопасное хранение своего пароля, а также за последствия, которые могут произойти при его компрометации.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          6.3. Пользователь несет персональную ответственность за своевременное размещение на Портале материалов, информации и сведений, подлежащих обязательному размещению на Портале.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          6.4. Администратор не несет ответственности за содержание информации, сведений, материалов, размещаемых Пользователем на Портале, их соответствие требованиям законодательства Республики Казахстан, за неправомерные действия Пользователя по использованию информации, сведений и материалов, размещенных на Портале, за нарушение Пользователем любых других прав третьих лиц.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          6.5. Администратор не несет ответственности за технические временные сбои и перерывы в работе Портала, возникшие по независящим от Администратора причинам или в случае проведения плановых или внеплановых мероприятий по обслуживанию Портала.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          6.6. Администратор не несет ответственности за временные сбои и обстоятельства непреодолимой силы, в том числе перебои в работе электрических сетей, сети Интернет и другие обстоятельства, возникшие независимо от Администратора и которые препятствуют выполнению условий настоящего Соглашения.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          6.7. Администратор не несет ответственности перед Пользователем за действия других Пользователей и (или) любых других участников Портала.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          6.8. Администратор не несет ответственности за любой прямой, косвенный ущерб, включая упущенную выгоду, ущерб от использования, потерю данных или какие-либо другие нематериальные потери, ущерб репутации и иной ущерб, возникающий вследствие:
        </Typography>
        <Typography sx={textStyle}>
          1) пользования или невозможности пользования Порталом;
        </Typography>
        <Typography sx={textStyle}>
          2) изменения условий настоящего Соглашения;
        </Typography>
        <Typography sx={textStyle}>
          3) неуполномоченного доступа к данным Пользователя или изменения передаваемых Пользователем, или хранящихся на сервере данных;
        </Typography>
        <Typography sx={textStyle}>
          4) любого другого случая, имеющего отношение к оказываемым на Портале услугам.</Typography>
      </Box>
      <Box sx={{ width: '880px', backgroundColor: '#fff', borderRadius: '24px', p: '40px', border: `1px solid ${common.borderSecondary}` }}>
        <Typography sx={{ ...textStyle, fontWeight: 600, fontSize: '22px', mb: '24px' }}>7. Прочие условия</Typography>
        <Typography sx={textStyle}>
          7.1. Пользователь не возражает в получении и публикации Администратором дополнительных сведений о себе, содержащихся в государственных и (или) негосударственных информационных системах (базах данных).
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          7.2. Все претензии, запросы, вопросы, любая иная корреспонденция касательно технической работы Портала и (или) его подсистем должны направляться посредством формы обратной связи, указанной на главной странице Портала и (или) его подсистем.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          7.3. В случае поступления претензий от третьих лиц, связанных с размещением и (или) использованием материалов, информации и сведений Пользователем, Пользователь самостоятельно и за свой счет урегулирует указанные претензии.
        </Typography>
      </Box>
      <Box sx={{ width: '880px', backgroundColor: '#fff', borderRadius: '24px', p: '40px', border: `1px solid ${common.borderSecondary}` }}>
        <Typography sx={{ ...textStyle, fontWeight: 600, fontSize: '22px', mb: '24px' }}>8. Разрешение споров</Typography>
        <Typography sx={textStyle}>
          8.1. В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным условием до обращения в суд является предварительным предъявлением претензии (письменного предложения о добровольном урегулировании спора).
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          8.2. Получатель претензии в течение 15 календарных дней со дня ее получения, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          8.3. При невозможности разрешить спор в добровольном порядке любая из Сторон вправе обратиться в суд за защитой своих прав, которые предоставлены им действующим законодательством Республики Казахстан.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          8.4. Любой иск в отношении условий использования Портала должен быть предъявлен согласно нормам Гражданского кодекса Республики Казахстан.
        </Typography>
      </Box>
      <Box sx={{ width: '880px', backgroundColor: '#fff', borderRadius: '24px', p: '40px', border: `1px solid ${common.borderSecondary}` }}>
        <Typography sx={{ ...textStyle, fontWeight: 600, fontSize: '22px', mb: '24px' }}>9. Дополнительные условия</Typography>
        <Typography sx={textStyle}>
          9.1. Администратор не принимает встречные предложения от Пользователя относительно изменений настоящего Соглашения.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          9.2. Отзывы Пользователя, размещенные на Портале, не являются конфиденциальной информацией и могут быть использованы Администратором без ограничений.
        </Typography>
        <Typography sx={{ ...textStyle, mt: '24px' }}>
          9.3. На Портале данные можно опубликовать на казахском или русском языках. Информация должна соответствовать нормам казахского или русского языка, то есть в них не должно быть орфографических, пунктуационных и стилистических ошибок.
        </Typography>
      </Box>
    </Box >
  )
}

export default TermsOfUse
