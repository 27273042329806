import { AdditionalMaterialTypes } from "@interfaces/index";
import * as yup from "yup";

export const eduCourseValidationSchema = (t: any) =>
  yup.object({
    name: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD"))
      .max(60, t('common:validation.MAX_NUMBER_OF_SYMBOLS', { length: 60 })),
    shortDescription: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    description: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    credits: yup
      .number()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
  });

export const courseMediaValidationSchema = (t: any) =>
  yup.object({
    promoVideoUrl: yup
      .string()
      .nullable()
      .when("promoVideoType", {
        is: (value: string) => value === AdditionalMaterialTypes.LINK,
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
        otherwise: yup.string().nullable(),
      }),
  });

export const freeCourseValidationSchema = (t: any) =>
  yup.object({
    name: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD"))
      .max(60, t('common:validation.MAX_NUMBER_OF_SYMBOLS', { length: 60 })),
    difficultyLevelId: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    categoryId: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    shortDescription: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    description: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    targetAudience: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
  });

export const specifyManuallyAuthorValidation = (t: any) =>
  yup.object({
    username: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    userInfo: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
  });
