import React from "react";
import { useFormikContext, useField } from "formik";
import {
  Box,
  useTheme,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1A1F36",
    color: theme.palette.common.white,
    mb: '0 !important'
  },
}));

interface Props {
  groupLabel?: string;
  name: string;
  isRequired?: boolean;
  options: Array<{ label: string, value: string, disabled?: boolean, hintText?: string }>;
  disabled?: boolean;
  labelPosition?: string;
  row?: boolean;
}

const FormikRadioGroup: React.FC<Props> = ({
  groupLabel,
  name,
  isRequired = false,
  options,
  disabled = false,
  labelPosition = "left",
  row = true
}) => {
  const { common } = useTheme().palette;
  const formikProps = useFormikContext();
  const [field, meta] = useField(name);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center'
      }}
    >
      <FormControl
        sx={{
          display: "flex",
          flexDirection: labelPosition === "top" ? "column" : "row",
          alignItems: labelPosition === "top" ? "left" : "center", gap: 0.5
        }}
        disabled={disabled}
      >
        {groupLabel && (
          <FormLabel
            sx={{
              color: common.fontPrimary,
              "&.Mui-focused": {
                color: common.fontPrimary,
              },
            }}
            disabled={disabled}
          >
            <Typography sx={{ display: 'flex' }}>
              {groupLabel} {isRequired && <span style={{ color: common.errorColor }}>*</span>}
            </Typography>
          </FormLabel>
        )}
        <RadioGroup
          row={row}
          name={name}
          value={field.value || ''}
          onChange={(event) => {
            formikProps.setFieldValue(name, event.currentTarget.value);
          }}
        >
          {options.map((radio: { label: string; value: string, disabled?: boolean, hintText?: string }) => {
            if (radio.hintText) 
              return (
                <CustomTooltip
                  key={radio.value}
                  title={radio.hintText} 
                  placement="top"
                  TransitionComponent={Zoom}
                >
                  <FormControlLabel
                    value={radio.value}
                    disabled={radio.disabled}
                    control={<Radio />}
                    label={radio.label}
                  />
                </CustomTooltip>
              )
            return (
              <FormControlLabel
                key={radio.value}
                value={radio.value}
                disabled={radio.disabled}
                control={
                  <Radio
                    sx={{
                      "&, &.Mui-checked": {
                        color: common.primaryColor,
                      },
                    }}
                  />
                }
                label={radio.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      {meta.touched && Boolean(meta.error) &&
        <FormHelperText error={Boolean(meta.error)} sx={{ px: 1.75 }}>
          {meta.error}
        </FormHelperText>
      }
    </Box>
  );
};

export default FormikRadioGroup;
