export interface IEventCalendar {
  id: string | null;
  title: string | null;
  type: EventType;
  startDate: Date | null; // Дата и время начала события
  endDate: Date | null; // Дата и время окончания события
  description: string | null;
  userId: string | null;
  teacherId: string | null;
  courseId: string | null;
  allDay: boolean; // Видимость события
  createdAt: string | null;
  updatedAt: string | null;
  authorId: string | null;
  deleted: boolean;
}

export interface IEventCalendarFilter {
  title?: string; // Поиск по названию событий
  description?: string; // Поиск по описанию
  teacherId?: string; // Поиск по преподавателям
  courseId?: string;
  type?: EventType;
  startDate?: string;
  endDate?: string;
  eventDateType?: EventDateType;
}

export enum EventType {
  GENERAL = "GENERAL",
  PRIVATE = "PRIVATE"
}

export enum EventDateType {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH"
}