import * as yup from 'yup';

export const categoryValidationSchema = (t: any) => yup.object({
  id: yup.number().nullable(),
  nameKz: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  nameRu: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  nameEn: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  isDisabled: yup.boolean()
});

export const subcategoryValidationSchema = (t: any) => yup.object({
  id: yup.number().nullable(),
  nameKz: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  nameRu: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  nameEn: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  parentId: yup.number().nullable().required(t('common:validation.MANDATORY_FIELD')),
  isDisabled: yup.boolean(),
  parent: yup.object().nullable().required(t('common:validation.MANDATORY_FIELD')),
});