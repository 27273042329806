import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, TextField, Typography, useTheme, Menu, MenuItem, Checkbox, FormControlLabel, Divider, Avatar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import { AlertColor } from '@mui/material/Alert';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { convertToRaw } from 'draft-js';

import { serviceCourse } from '@services/course';
import { StateSchema } from '@store/StateSchema';
import Message from "@components/Message";
import UnhiddenHints from '@components/UnhiddenHints';
import Hint from '@components/Hint';
import Form from './Form';
import { INote, initialDto } from './models';
import { CourseStatus, Role } from '@interfaces/index';
import { formatDateWithHourMinute } from '@utils/functions';

const Notes = () => {
  const { t } = useTranslation(["common", "course", "generalSetting"]);
  const { common } = useTheme().palette;
  const [initialData, setInitialData] = useState<INote>(initialDto);
  const { classId, status } = useParams<{ classId: string, status: string }>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [theme, setTheme] = useState<boolean>(false);
  const [task, setTask] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [noteList, setNoteList] = useState<INote[]>([]);
  const [noteId, setNoteId] = useState<string | null>(null);
  const [memberId, setMemberId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredNotes, setFilteredNotes] = useState<INote[]>([]);
  const [isNoteVisible, setIsNoteVisible] = useState<boolean>(true)
  const profile = useSelector((store: StateSchema) => store.account.profileInfo);
  const { role } = profile.authorityInfo;
  const navigate = useNavigate();

  useEffect(() => {
    if (role === Role.LISTENER && classId) {
      serviceCourse.getNoteList(classId).then(res => {
        if (res.status === 200) {
          setNoteList(res.data.reverse());
          if (res.data.length > 0) {
            setInitialData(res.data[0]);
            setNoteId(res.data[0].id);
          }
        }
      })

      serviceCourse.getCourseMe(classId).then(res => {
        if (res.status === 200) setMemberId(res.data.id);
      })
    }
  }, [classId, role]);

  useEffect(() => {
    let notesToFilter = noteList;

    if (theme && task) {
      notesToFilter = notesToFilter.filter((note: INote) => note.themeId !== undefined || note.taskId !== undefined);
    } else {
      if (theme) {
        notesToFilter = notesToFilter.filter((note: INote) => note.themeId !== undefined);
      }
      if (task) {
        notesToFilter = notesToFilter.filter((note: INote) => note.taskId !== undefined);
      }
    }
    const filteredNotes: INote[] = notesToFilter.filter((note: INote) => note.content && note.content.includes(searchQuery));
    setFilteredNotes(filteredNotes);
  }, [theme, task, searchQuery, noteList]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filterMenuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteNote = (id: string | null) => {
    if (id) {
      serviceCourse.deleteNote(id).then(res => {
        if (res.status === 200) {
          setMessage(t("course:note.NOTE_DELETE_SUCCESS"));
          setMessageOpen(true);
          setMessageType("success");
          setNoteList(noteList.filter((item: INote) => item.id !== id));
          setNoteId(null)
          if (id === initialData.id) {
            if (noteList.length > 1) {
              setInitialData(noteList.filter((item: INote) => item.id !== id)[0]);
            } else {
              setInitialData(initialDto);
            }
          }
        }
      }).catch(err => {
        if (err) {
          setMessage(t("course:note.NOTE_DELETE_ERROR"));
          setMessageOpen(true);
          setMessageType("error");
        }
      }).finally(() => {
        setTimeout(() => {
          setMessageOpen(false);
        }, 3000);
      })
    }
  }

  useEffect(() => {
    const storedHintNoteVisibility = localStorage.getItem(`noteHint/${classId}`);
    if (storedHintNoteVisibility === 'false') {
      setIsNoteVisible(false);
    }
  }, [classId]);

  useEffect(() => {
    localStorage.setItem(`noteHint/${classId}`, isNoteVisible.toString());
  }, [isNoteVisible, classId]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {isNoteVisible && role === Role.LISTENER &&
        <UnhiddenHints
          clickHandler={() => setIsNoteVisible(false)}
          clearIconVisible={true}
          text={t('course:note.NOTE_INFO_MESSAGE')} />
      }
      {role !== Role.LISTENER && <Hint text={t('course:note.PREVIEW_COURSE_HINT')} />}
      {messageOpen &&
        <Box sx={{ mb: '24px', mt: '-12px', width: '94.5%' }}>
          <Message type={messageType} message={message} setMessageOpen={setMessageOpen} />
        </Box>
      }
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          {status?.toUpperCase() === CourseStatus.CURRENT &&
            <Button
              sx={{
                background: common.primaryColor,
                ":hover": { background: common.primaryColor },
                color: "#fff",
                "&.Mui-disabled": {
                  background: '#c1c1c1',
                },
                mb: 2
              }}
              fullWidth
              onClick={() => {
                setNoteId(null); setInitialData(initialDto);
              }}
              disabled={filteredNotes.length === 0}
            >
              <AddIcon />{t('course:note.NEW_NOTE')}
            </Button>
          }
          <Box sx={{ background: "#fff", borderRadius: "6px", padding: " 0 10px 10px 10px" }}>
            <Box display="flex" alignItems="center" height="50px" gap={1}>
              <SearchOutlinedIcon sx={{ color: '#C1C1C1' }} />
              <TextField
                placeholder={t('common:placeholders.SEARCH')}
                sx={{ width: '90%' }}
                size="small"
                variant="standard"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <Box sx={{ border: `1px solid ${common.borderSecondary}`, backgroundColor: 'white', borderRadius: '9px', display: 'flex', alignItems: 'center', px: '10px', cursor: 'pointer' }}
                onClick={handleClick}>
                <TuneIcon />
              </Box>
            </Box>
            <Box sx={{
              display: 'flex', 
              flexDirection: 'column',
              gap: 1,
              overflowY: 'scroll', 
              height: '490px', 
              '&::-webkit-scrollbar': { width: '4px' },
              '&::-webkit-scrollbar-thumb': { 
                backgroundColor: '#E5E0EB', borderRadius: '6px' 
              }
            }}>
              {filteredNotes.length > 0 ? filteredNotes.map((note: INote) => {
                const editorState = EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(note.content || "<p></p>\n").contentBlocks));
                const contentState = editorState.getCurrentContent();
                const rawContentState = convertToRaw(contentState);
                const plainText = rawContentState.blocks.map(block => block.text).join('\n');
                return (
                  <Box 
                    key={note.id} 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                      p: 1,
                      borderRadius: '12px',
                      backgroundColor: note.id === noteId ? '#F5F5F5' : '#FFFFFF',
                      '&:hover': { backgroundColor: '#F5F5F5' }
                    }}
                  >
                    <Box sx={{ display: 'block', cursor: 'pointer' }} onClick={() => { setNoteId(note.id); setInitialData(note); }}>
                      <Typography sx={{ fontSize: '16px', lineHeight: '24px', cursor: 'pointer', fontWeight: 500 }}>
                        {plainText.length > 17 ? `${plainText.slice(0, 17)}...` : plainText}
                      </Typography>
                      <Typography sx={{ fontSize: '14px', color: '#9C9C9C', lineHeight: '20px' }}>
                        {formatDateWithHourMinute(note.updatedAt)}
                      </Typography>
                      {(note?.taskId || note?.themeId) &&
                        <>
                          {note?.taskId &&
                            <Typography
                              sx={{ fontSize: '14px', color: '#9C9C9C', lineHeight: '20px', '&:hover': { color: common.primaryColor } }}
                              onClick={() => navigate(`/myCourses/${status}/${classId}/tasks/${note.taskId}`)}
                            >
                              {t('enum:TASK')}: {note?.taskName}
                            </Typography>
                          }
                          {note?.themeId &&
                            <Typography
                              sx={{ fontSize: '14px', color: '#9C9C9C', lineHeight: '20px', '&:hover': { color: common.primaryColor } }}
                              onClick={() => navigate(`/myCourses/${classId}/theme/${note.themeId}`)}
                            >
                              {t('course:theme.THEME')}: {note?.themeName}
                            </Typography>
                          }
                        </>
                      }
                    </Box>
                    {status?.toUpperCase() === CourseStatus.CURRENT &&
                      <DeleteOutlineIcon onClick={() => handleDeleteNote(note.id)} sx={{ color: common.errorColor, cursor: 'pointer', mr: '5px' }} />
                    }
                  </Box>
                )
              }) : <>
                {role === Role.LISTENER ? <>
                  <Avatar
                    alt="Notes-empty-bg-img"
                    src={require(`@assets/images/emptyDataBg.png`)}
                    variant="square"
                    sx={{
                      height: { xs: '100px', sm: "171px" },
                      width: { xs: '100px', sm: "171px" },
                      mb: "8px",
                      mt: "20%",
                      ml: "20%"
                    }}
                  />
                  <Typography sx={{ fontSize: { sm: '16px', xs: '14px', marginLeft: "15%" } }}>
                    {t('course:note.NOTES_WILL_GO_HERE')}
                  </Typography>
                </> : null}
              </>}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Form
            initialData={initialData}
            setInitialData={setInitialData}
            courseId={classId}
            memberId={memberId}
            setMessage={setMessage}
            setMessageOpen={setMessageOpen}
            setMessageType={setMessageType}
            noteId={noteId}
            setNoteId={setNoteId}
            noteList={noteList}
            setNoteList={setNoteList}
          />
        </Grid>
      </Grid >
      {filterMenuOpen &&
        <Menu
          id="long-menu"
          MenuListProps={{ 'aria-labelledby': 'long-button' }}
          anchorEl={anchorEl}
          open={filterMenuOpen}
          onClose={handleClose}
        >
          <MenuItem>
            <FormControlLabel
              control={
                <Checkbox
                  // checked={!theme && !task}
                  onChange={(e) => {
                    setTheme(false);
                    setTask(false);
                  }}
                  sx={{
                    color: common.primaryColor,
                    "&, &.Mui-checked": { color: common.primaryColor }
                  }}
                />
              }
              label={t('generalSetting:GENERAL')}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={theme}
                  onChange={(e) => setTheme(e.target.checked)}
                  sx={{
                    color: common.primaryColor,
                    "&, &.Mui-checked": { color: common.primaryColor }
                  }}
                />
              }
              label={t('course:theme.TO_THEME')}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={task}
                  onChange={(e) => setTask(e.target.checked)}
                  sx={{
                    color: common.primaryColor,
                    "&, &.Mui-checked": { color: common.primaryColor }
                  }}
                />
              }
              label={t('course:task.TO_TASK')}
            />
          </MenuItem>
        </Menu>
      }
    </Box>
  )
}

export default Notes;