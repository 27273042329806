import React, { useState } from 'react'
import { TabContext, TabList } from '@mui/lab';
import { Box, Divider, Typography, useTheme } from '@mui/material'
import CustomButton from '@ui/Button';
import Tab from "@mui/material/Tab";
import { useTranslation } from 'react-i18next';
import { AuthorConfirmation } from './AuthorConfirmation';
import { MemberConfirmation } from './MemberConfirmation';

export const ConfirmationInvitation = ({ memberConfirmation, authorConfirmation }) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course", "enum"])
  const [type, setType] = useState<string>(authorConfirmation.length > 0 ? "AUTHOR" : "PARTICIPATION");
  const handleTypeChange = (type: string) => {
    setType(type);
  };

  return (
    <Box
      sx={{
        display: "block",
        width: "100%",
        background: common.fontWhite,
        borderRadius: 3,
        padding: "16px 20px",
        overflowY: "auto",
        marginTop: "16px",
        border: `1px solid ${common.borderSecondary}`,
        '&::-webkit-scrollbar': {
          width: 3,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'lightgray',
          borderRadius: '6px'
        },
        overflow:"hidden"
      }}
    >
      <Typography fontSize="16px" fontWeight={600}>{t("course:INVITATIONS")}</Typography>
      <Divider sx={{ margin: "16px 0px" }} />
      <TabContext value={type}>
        <Box>
          <TabList
            onChange={(_, value: string) => handleTypeChange(value)}
            variant="scrollable"
            sx={{
              "& .MuiTab-root": {
                minHeight: "34px"
              },
              '& .MuiTabs-flexContainer': {
                gap: 1
              },
              display: "flex",
              alignItems: "center",
            }}
            TabIndicatorProps={{
              style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                backgroundColor: common.secondaryColor,
                opacity: 0.15,
                border: `1px solid ${common.borderPrimary}`,
                borderRadius: "6px",
              },
            }}
          >
            {authorConfirmation.length > 0 && <Tab
              label={t("course:AUTHOR")}
              value={"AUTHOR"}
              sx={{
                fontSize: "12px",
                color: "#686B81",
                width: "auto",
                height: "28px",
                borderRadius: "6px",
                backgroundColor: "#FFFFFF",
                textTransform: "capitalize",
                lineHeight: "14px",
                transition: 'transform 1s',
                '&:hover': {
                  backgroundColor: "#F5EFFF",
                },
                '&.Mui-selected': {
                  backgroundColor: common.primaryColor,
                  color: '#FFFFFF'
                },
                padding:"8px 16px"
              }}
            />}
            {memberConfirmation.length > 0 &&
              <Tab
                label={t("course:PARTICIPATION")}
                value={"PARTICIPATION"}
                sx={{
                  fontSize: "12px",
                  color: "#686B81",
                  width: "auto",
                  height: "28px",
                  borderRadius: "6px",
                  backgroundColor: "#FFFFFF",
                  textTransform: "capitalize",
                  lineHeight: "14px",
                  transition: 'transform 1s',
                  '&:hover': {
                    backgroundColor: "#F5EFFF",
                  },
                  '&.Mui-selected': {
                    backgroundColor: common.primaryColor,
                    color: '#FFFFFF'
                  },
                  padding:"8px 16px"
                }}
              />}

          </TabList>
        </Box>
      </TabContext>
      <Box marginTop="16px" overflow={"auto"} height={"192px"} 
      sx={{
        '&::-webkit-scrollbar': {
          width: 12,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#BDBDBD',
          borderRadius: '6px',
          border: "3px solid #EEEEEE",
          width:7
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor:"#EEEEEE"
        },
        paddingRight:"15px"
      }}>
        {type === "AUTHOR" && authorConfirmation.length > 0 && <AuthorConfirmation authorConfirmation={authorConfirmation} />}
        {type === "PARTICIPATION" && memberConfirmation.length > 0 && <MemberConfirmation memberConfirmation={memberConfirmation} />}
      </Box>
    </Box>
  )
}
