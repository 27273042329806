import * as yup from "yup";

export const recipientValidationSchema = (t) => {
  yup.object({
    planConference: yup.boolean(),
    link: yup
      .string()
      .nullable()
      .when("planConference", {
        is: true,
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      }),
    login: yup.string().nullable(),
    password: yup.string().nullable(),
    conferenceDate: yup
      .string()
      .nullable()
      .when("planConference", {
        is: true,
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      }),
    startTime: yup
      .string()
      .nullable()
      .when("planConference", {
        is: true,
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      })
      .test(
        "start-time",
        t("common:validation.INCORRECT_START_AND_END_TIMES"),
        function (startTime) {
          const { endTime } = this.parent;
          if (!startTime || !endTime) {
            return true;
          }
          return endTime !== null && startTime <= endTime;
        }
      ),
    endTime: yup
      .string()
      .nullable()
      .when("planConference", {
        is: true,
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      }),
  });
};
export const recipientValidationOfflineConferenceSchema = (t) => {
  yup.object({
    isMeetingPlaceAssigned: yup.boolean(),
    meetingPlace: yup
      .string()
      .nullable()
      .when("isMeetingPlaceAssigned", {
        is: true,
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      }),
    meetingDate: yup
      .string()
      .nullable()
      .when("isMeetingPlaceAssigned", {
        is: true,
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      }),
    meetingStartDate: yup
      .string()
      .nullable()
      .when("isMeetingPlaceAssigned", {
        is: true,
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      })
      .test(
        "start-time",
        t("common:validation.INCORRECT_START_AND_END_TIMES"),
        function (meetingStartDate) {
          const { meetingEndDate } = this.parent;
          if (!meetingStartDate || !meetingEndDate) {
            return true;
          }
          return meetingEndDate !== null && meetingStartDate <= meetingEndDate;
        }
      ),
    meetingEndDate: yup
      .string()
      .nullable()
      .when("isMeetingPlaceAssigned", {
        is: (value: boolean) => value === true,
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      }),
  });
};

export const taskRecipientParameters = (t) => {
  yup.object({
    isCloseAccess: yup.boolean(),
    dateOfAccess: yup
      .string()
      .nullable()
      .when("isCloseAccess", {
        is: true,
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      }),
  });
};
