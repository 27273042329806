import { useState, useEffect, useContext, MouseEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Divider,
  useMediaQuery,
  Link,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import StickyNote2Outlined from "@mui/icons-material/StickyNote2Outlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";

import { serviceListener } from "@services/listener";
import { serviceCourse } from "@services/course";
import { serviceTask, useGetRecipientByIdQuery } from "@services/task";
import { useBreadcrumbs } from "@context/BreadcrumbsContext";
import { formatDateWithHourMinute } from "@utils/functions";

import Breadcrumbs from "@components/Breadcrumbs";
import { FloatingNote } from "@components/FloatingNote";
import CustomButton from "@ui/Button";
import TextReader from "@ui/TextReader";
import UploadButton from "@ui/UploadButton";
import SuccesSend from "./SuccesSend";
import { SelectTask } from "./SelectTask";
import MaterialList from "./MaterialList";
import { SingleCommentComponent } from "./CommentList";
import { taskCommentInitialDto } from "./models";
import {
  IFile,
  IComment,
  IProfile,
  CourseStatus,
  Role,
  ITasksList,
  TaskFormat,
  TaskStatus,
  TypeCode,
  AnswerTypes,
  CourseFormatType,
  ListArray,
} from "@interfaces/index";
import { statusBgColor } from "./List";
import CustomInput from "@ui/Input";
import UnhiddenHints from "@components/UnhiddenHints";
import RefreshIcon from "@mui/icons-material/Refresh";
import QuizResult from "./QuizResult";
import { timeStyle } from "./Timer";
import YesOrNoModal from "@components/modals/YesOrNoModal";
import TaskQuizResult from "./TaskQuizResult";
import { AeroRuleModal } from "@pages/generalSettings/AeroReport/AeroRuleModal";
import { IBreadcrumb } from "@interfaces/index";
import PreviewLogout from "@components/PreviewLogout";
import { useSelector } from "react-redux";
import { StateSchema } from "@store/StateSchema";
import { taskInitialDtoFreeCourses } from "@pages/myCourses/Educator/TabList/Content/Task/models";
import {
  IRecipients,
  initialDTO,
} from "@pages/myCourses/Educator/TabList/Content/Theme/models";
import { CourseContext } from "@context/CourseContext";
import VideoPlayer from "@components/VideoPlayer";
import { downloadFile } from "@utils/functions";
import MeetingList from "./MeetingList";
import { useGetMeetingFindOneQuery } from "@services/meeting";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";
import { SingleCommentComponent as SingleCommentComponentOral } from "@components/common";
const initialTaskData = {
  allowed: true,
  dateOfDeadline: null,
  dateOfAccess: null,
  createdAt: "",
  hasDeadlineDate: false,
  id: null,
  recipientId: "",
  status: "",
  member: {
    id: null,
    taskProgresses: [],
  },
  task: taskInitialDtoFreeCourses,
};

const Task = () => {
  const { taskId, status, classId } = useParams();
  const { t } = useTranslation([
    "common",
    "enum",
    "course",
    "aero",
    "user",
  ]);
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const profile: IProfile = useSelector(
    (store: StateSchema) => store.account.profileInfo
  );
  const { role } = profile.authorityInfo;
  const [floatingNoteOpen, setFloatingNoteOpen] = useState<boolean>(false);
  const [descriptionOpen, setDescriptionOpen] = useState<boolean>(true);
  const [taskInitialData, setTaskInitialData] =
    useState<ITasksList>(initialTaskData);
  const [fileList, setFileList] = useState<Array<IFile>>([]);
  const [comment, setComment] = useState<string>("");
  const [lastComment, setLastComment] = useState<IComment>();
  const [replyData, setReplyData] = useState<IComment>(taskCommentInitialDto);
  const [quizProgressStarted, setQuizProgressStarted] = useState<
    boolean | null
  >(null);
  const [quizAttemptList, setQuizAttemptList] = useState<Array<any>>([]);
  const [answerTypeFileFormats, setAnswerTypeFileFormats] =
    useState<string>(".pdf");
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const handleToggleConfirmModal = () => setConfirmModalOpen(!confirmModalOpen);
  const [courseStatus, setCourseStatus] = useState<string>("");
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
  const handleToggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
  const theme = useTheme();
  const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const completedCourse = status === "completed";
  const [numberOfQuestions, setNumberOfQuestions] = useState<number | null>(null);
  const [currentQuestionId, setCurrentQuestionId] = useState<string | null>(null);
  const [questions, setQuestions] = useState<Array<any>>([]);
  const [selfTestResult, setSelfTestResult] = useState<any>();
  const [quizResult, setQuizResult] = useState<any>();
  const [showQuizResult, setShowQuizResult] = useState<boolean>(false);
  const [currentAttemptId, setCurrentAttemptId] = useState<string | null>(null);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [openAeroModal, setOpenAeroModal] = useState<boolean>(false);
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const currentStep =
    getBreadcrumbs(classId || "").find((x: IBreadcrumb) => x.level === 2)?.tabNum || 1;
  const [memberId, setMemberId] = useState("");
  const [recipientParams, setRecipientParams] =
    useState<IRecipients>(initialDTO);
  const { courseData } = useContext(CourseContext);
  const typeCode = courseData?.typeCode;
  const formatType = courseData?.formatType;
  const minLimitForTask = courseData?.minLimitForTask;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [currentVideoId, setCurrentVideoId] = useState<string | null>(null);

  useEffect(() => {
    const updatedCrumbs = [...getBreadcrumbs(classId || "")];
    if (
      (updatedCrumbs.length === 3 && updatedCrumbs[2].page === "task") ||
      updatedCrumbs.length === 4
    )
      updatedCrumbs.pop();
    updatedCrumbs.push({
      level: updatedCrumbs.length === 2 ? 3 : 4,
      page: "task",
      label:
        taskId === "new"
          ? t("course:task.ADDING_TASK")
          : taskInitialData.task?.name,
      path: null,
      tabNum: null,
    });
    // Check if the breadcrumbs have actually changed before updating
    if (JSON.stringify(updatedCrumbs) !== JSON.stringify(getBreadcrumbs(classId || ""))) {
      if (classId) setBreadcrumbs(classId, updatedCrumbs);
    }
  }, [getBreadcrumbs, setBreadcrumbs, classId, taskId, taskInitialData.task?.name, t]);

  // request for responsible members and date of access
  useEffect(() => {
    if (classId && taskId)
      serviceCourse.getTaskRecipientsParameters(classId, taskId).then((res) => {
        if (res.status === 200) setRecipientParams(res.data);
      });
  }, [classId, taskId]);

  // request for memberId (for task info) and course status
  useEffect(() => {
    if (classId) {
      serviceCourse.getCourseMe(classId).then((res) => {
        if (res.status === 200) setMemberId(res.data.id);
      });
      serviceCourse.getCourseDataByClassId(classId).then((res) => {
        if (res.status === 200) setCourseStatus(res.data.status);
      });
    }
  }, [classId]);

  // request for task info
  useEffect(() => {
    if (taskId && memberId) {
      if (role === Role.LISTENER) {
        serviceListener
          .findByTaskIdAndMemberId(taskId, memberId)
          .then((res) => {
            if (res.status === 200) {
              setTaskInitialData(res.data);
              if (res.data.isSelfTest) {
                setSelfTestResult(
                  JSON.parse(sessionStorage.getItem("selfTestResult") || "")
                );
              }
            }
          });
      } else {
        serviceTask.getTaskInfo(taskId).then((res) => {
          if (res.status === 200) setTaskInitialData(res.data);
        });
      }
    }
  }, [taskId, role, classId, memberId]);

  useEffect(() => {
    return () => sessionStorage.removeItem("selfTestResult");
  }, []);

  useEffect(() => {
    if (taskInitialData.id) {
      serviceTask.getAllTaskComments(taskInitialData.id).then((res) => {
        // setCommentList(res.data);
        if (res.status === 200) {
          const lastComment = res.data[res.data.length - 1];
          setLastComment(lastComment);
        }
      });

      serviceTask.checkQuizProgress(taskInitialData.id).then((res) => {
        if (res.status === 200) setQuizProgressStarted(res.data);
      });

      serviceTask.getQuizAttemptResult(taskInitialData.id).then((res) => {
        if (res.status === 200) setQuizAttemptList(res.data);
      });
    }
  }, [taskInitialData?.id]);
  // timer in test
  useEffect(() => {
    if (selfTestResult?.quizResult && taskInitialData.task?.isSelfTest) {
      const givenSeconds = Number(taskInitialData.task?.quiz?.duration) * 60;
      const usedSeconds = Math.abs(
        Math.round(
          (new Date(selfTestResult?.completedDateTime).getTime() -
            new Date(selfTestResult?.startedDateTime).getTime()) /
          1000
        )
      );
      const leftMinutes = Math.floor((givenSeconds - usedSeconds) / 60);
      setMinutes(leftMinutes);
      setSeconds(60 - (usedSeconds % 60));
      setQuizResult(selfTestResult.quizResult);
      const questionList = selfTestResult.quizResult.questions;
      setQuestions(questionList);
      setCurrentQuestionId(questionList[0].questionId);
    }
  }, [
    selfTestResult,
    taskInitialData.task?.isSelfTest,
    taskInitialData.task?.quiz?.duration,
  ]);

  // accepted file formats to submit
  useEffect(() => {
    if (taskInitialData.task?.answerTypeFileFormats) {
      const extensions: string = taskInitialData.task?.answerTypeFileFormats
        .join(",")
        .toString();
      setAnswerTypeFileFormats(extensions);
    }
  }, [taskInitialData.task?.answerTypeFileFormats]);

  // number of questions in test
  useEffect(() => {
    if (taskInitialData?.task?.quiz?.id) {
      serviceTask.getAllQuizList(taskInitialData.task?.quiz.id).then((res) => {
        if (res.status === 200) {
          setNumberOfQuestions(res.data.length);
        }
      });
    }
  }, [taskInitialData?.task?.quiz?.id]);

  const getAttemtpResult = (attemptId: string, showAnswers = false) => {
    setCurrentAttemptId(attemptId);
    serviceTask.getFullResultViaAttemptId(attemptId).then((res) => {
      if (res.status === 200) {
        setQuizResult(res.data);
        if (showAnswers) {
          const questionList = res.data.questions;
          setQuestions(questionList);
          setCurrentQuestionId(questionList[0].questionId);
          setShowQuizResult(true);
        }
      }
    });
  };

  const addFile = (fileId: string, fileName?: string) => {
    setFileList([...fileList, { id: null, fileId, fileName }]);
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "comment") setComment(value);
  };

  const setValuesOpenModal = () => {
    const bodyData = taskCommentInitialDto;
    bodyData.comment = comment;
    bodyData.progressId = taskInitialData?.id || null;
    bodyData.replyFiles = fileList;
    bodyData.commentatorMember = { id: memberId };
    setReplyData(bodyData);
    handleToggleConfirmModal();
  };

  const createTaskReply = () => {
    serviceTask.createTaskComment(replyData).then((res) => {
      if (res.status === 200) {
        setTaskInitialData({
          ...taskInitialData,
          status: TaskStatus.UNDER_REVIEW,
        });
        setLastComment(replyData);
        handleToggleConfirmModal();
        handleToggleSuccessModal();
      }
    });
  };

  const cancelCreateReply = () => {
    setComment("");
    setFileList([]);
  };

  const startResumeQuiz = (taskProgressId: string | null | undefined) => {
    if (taskProgressId) {
      serviceTask.startResumeQuiz(taskProgressId).then(async (res) => {
        if (res.status === 200) {
          if (res.data.id) {
            navigate(
              `/myCourses/${classId}/tasks/${taskId}/quiz/${res.data?.id}?progressId=${taskProgressId}`
            );
          }
        }
      });
    }
  };

  const startSelfTestQuiz = (taskId: string | null) => {
    if (taskId) {
      serviceTask.startSelfTestQuiz(taskId).then((res) => {
        if (res.status === 200) {
          if (res.data.id)
            navigate(
              `/myCourses/${classId}/tasks/${taskId}/selfQuiz/${res.data.id}`
            );
        }
      });
    }
  };

  const goToQuestion = (questionId: string) => {
    const index = [...questions].findIndex(
      (x: any) => x.questionId === questionId
    );
    const questionToGo = [...questions][index];
    setCurrentQuestionId(questionToGo.questionId);
  };

  const switchPage = () => {
    setShowQuizResult(false);
    setCurrentQuestionId(null);
  };
  const deleteFile = (fileId) => {
    setFileList((prev) => prev.filter((file) => file.fileId !== fileId));
  };

  const CommonInfoBox = ({ icon, message }) => (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        border: "1px solid #E8E8E8",
        padding: "10px",
        backgroundColor: "#F9F9F9",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "28px",
          height: "28px",
          borderRadius: "50%",
          background: `rgba(148, 105, 227,0.3)`,
          padding: "10px",
        }}
      >
        {icon}
      </Box>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "20px",
          color: common.primaryColor,
        }}
      >
        {message}
      </Typography>
    </Box>
  );

  const openVideoActions = (event: MouseEvent<HTMLElement>, videoFileId) => {
    setAnchorEl(event.currentTarget);
    setCurrentVideoId(videoFileId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const deleteVideo = () => {
    setFileList(fileList.filter((video) => video.fileId !== currentVideoId));
    setCurrentVideoId(null);
    handleClose();
  };
  const { data: conference } = useGetMeetingFindOneQuery(
    {
      taskId: taskInitialData.task?.id ?? "",
      classId: classId ?? "",
    },
    {
      skip: !(
        taskInitialData &&
        taskInitialData.task?.answerType === AnswerTypes.ONLINE_CONFERENCE
      ),
    }
  );

  const { data: taskRecipient } = useGetRecipientByIdQuery(
    taskInitialData.recipientId,
    {
      skip: !(
        taskInitialData &&
        taskInitialData.task?.answerType === AnswerTypes.OFFLINE
      ),
    }
  );

  const OnlineConferenceContent: ListArray[] = [
    {
      key: "",
      value: conference?.platform.nameRu
        ? conference?.platform.nameRu + " conference"
        : "",
      colored: true,
      svg: (
        <OpenInNewRoundedIcon
          sx={{
            fontSize: "20px",
          }}
        />
      ),
    },
    {
      value: "",
      key:
        (conference &&
          conference?.startDate &&
          format(
            new Date(conference?.startDate ?? Date.now()),
            "dd MMMM, yyyy | HH:mm ",
            { locale: ru }
          ) +
          format(new Date(conference?.endDate ?? Date.now()), "- HH:mm", {
            locale: ru,
          })) ||
        "",
    },
    {
      key: "",
      value: conference?.login ? t("user:LOGIN") + ":" + conference?.login : "",
    },
    {
      key: "",
      value: conference?.password
        ? t("profile:PASS") + ":" + conference?.password
        : "",
    },
  ];

  const OfflineConferenceContent: ListArray[] = [
    {
      value: "",
      key:
        (taskRecipient &&
          taskRecipient?.meetingStartDate &&
          t("course:meeting.DATE_OF_MEETING") +
          " " +
          format(
            new Date(taskRecipient?.meetingStartDate ?? Date.now()),
            "dd MMMM, yyyy | HH:mm ",
            { locale: ru }
          ) +
          format(
            new Date(taskRecipient?.meetingEndDate ?? Date.now()),
            "- HH:mm",
            {
              locale: ru,
            }
          )) ||
        "",
    },
    {
      key: t("course:meeting.MEETING_PLACE") + ": ",
      value: taskRecipient?.meetingPlace ?? "",
    },
  ];

  const meetingProps = {
    [AnswerTypes.ONLINE_CONFERENCE]: {
      title: t("enum:SCHEDULED_CONFERENCE"),
      svg: <EventNoteOutlinedIcon />,
      list: OnlineConferenceContent,
    },
    [AnswerTypes.OFFLINE]: {
      title: t("enum:MEETING"),
      svg: <Diversity1OutlinedIcon />,
      list: OfflineConferenceContent,
    },
    DEFAULT: {
      title: "",
      svg: "",
      list: [],
    },
  };

  const { title, svg, list } =
    meetingProps[taskInitialData.task?.answerType as AnswerTypes] ||
    meetingProps.DEFAULT;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", mb: 3 }}>
        <Breadcrumbs id={classId} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          gap: 3,
        }}
      >
        <Box sx={{ width: "100%" }}>
          {!taskInitialData.task?.isSelfTest &&
            !showQuizResult &&
            role === Role.LISTENER && <SelectTask />}
          {!showQuizResult &&
            taskInitialData.task?.format === TaskFormat.QUIZ &&
            taskInitialData.task?.quiz?.allowMultipleAttempts &&
            (courseStatus === CourseStatus.CURRENT ||
              courseStatus === CourseStatus.COMPLETED) && (
              <UnhiddenHints
                text={t("course:hints.QUIZ_HINT_FOR_STUDENTS", {
                  numberOfAttempts:
                    taskInitialData?.task?.quiz?.numberOfAttempts,
                  testingTime: taskInitialData?.task?.quiz?.duration,
                  considerResult: t(
                    `enum:${taskInitialData?.task?.quiz?.considerResult}`
                  ).toLowerCase(),
                })}
              />
            )}
          {typeCode === TypeCode.FREE &&
            taskInitialData.task?.format === TaskFormat.QUIZ &&
            formatType === CourseFormatType.SYNC && (
              <UnhiddenHints
                text={
                  taskInitialData?.hasDeadlineDate
                    ? t(
                      "course:hints.QUIZ_HINT_FREE_COURSE_WITH_TASK_DEADLINE",
                      { minLimitForTask }
                    )
                    : t(
                      "course:hints.QUIZ_HINT_FREE_COURSE_WITHOUT_TASK_DEADLINE",
                      { minLimitForTask }
                    )
                }
              />
            )}
          <Box
            sx={{
              width: "100%",
              backgroundColor: "white",
              border: `1px solid ${common.borderSecondary}`,
              height: "auto",
              borderRadius: "12px",
              mt: "15px",
            }}
          >
            {(taskInitialData.task?.isSelfTest || showQuizResult) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  ml: 2,
                  mt: 1.5,
                  "&:hover": {
                    color: common.primaryColor,
                  },
                }}
                onClick={() =>
                  showQuizResult
                    ? switchPage()
                    : navigate(
                      `/myCourses/${classId}/theme/${taskInitialData.task?.theme?.id}`
                    )
                }
              >
                <ArrowBackIosIcon sx={{ width: 14, height: 14 }} />
                <Typography>{t("common:actions.GO_BACK")}</Typography>
              </Box>
            )}
            {!showQuizResult && (
              <Box
                sx={{
                  my: 1.5,
                  mx: 2,
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  justifyContent: "space-between",
                  backgroundColor: "#F1EEF6",
                  borderRadius: "10px",
                  height: "auto",
                }}
              >
                <Box sx={{ width: "100%", ml: "17px", mt: "52px", mb: "17px" }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "28px",
                      lineHeight: "36px",
                      mb: "16px",
                      color: common.primaryColor,
                    }}
                  >
                    {taskInitialData.task?.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#708099",
                    }}
                  >
                    {taskInitialData?.task?.theme?.name ||
                      taskInitialData?.task?.section?.name}
                  </Typography>
                  <Box sx={{ flexGrow: 1, mt: 2.5 }}>
                    {taskInitialData.task?.isSelfTest ? (
                      <Grid container spacing={2} width="100%">
                        <Grid item xs={6}>
                          <div
                            style={{
                              border: "1px dashed #E8E8E8",
                              padding: "10px",
                              backgroundColor: "#F9F9F9",
                              borderRadius: "12px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              {t("course:test.START_DATE")}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", lineHeight: "24px" }}
                            >
                              {formatDateWithHourMinute(
                                selfTestResult?.startedDateTime
                              )}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div
                            style={{
                              border: "1px dashed #E8E8E8",
                              padding: "10px",
                              backgroundColor: "#F9F9F9",
                              borderRadius: "12px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              {t("course:test.END_DATE")}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", lineHeight: "24px" }}
                            >
                              {formatDateWithHourMinute(
                                selfTestResult?.completedDateTime
                              )}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div
                            style={{
                              border: "1px dashed #E8E8E8",
                              padding: "10px",
                              backgroundColor: "#F9F9F9",
                              borderRadius: "12px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              {t("course:test.SPENT_TIME")}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", lineHeight: "24px" }}
                            >
                              {Math.abs(
                                Math.round(
                                  (new Date(
                                    selfTestResult?.completedDateTime
                                  ).getTime() -
                                    new Date(
                                      selfTestResult?.startedDateTime
                                    ).getTime()) /
                                  60000
                                )
                              )}{" "}
                              {t("common:MINUTE_SHORT")}
                            </Typography>
                          </div>
                        </Grid>
                        {taskInitialData.task?.quiz?.displayGrade && (
                          <Grid item xs={6}>
                            <div
                              style={{
                                border: "1px dashed #E8E8E8",
                                padding: "10px",
                                backgroundColor: "#F9F9F9",
                                borderRadius: "12px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                }}
                              >
                                {t("course:test.TEST_RESULT")}
                              </Typography>
                              <Typography
                                sx={{ fontSize: "16px", lineHeight: "24px" }}
                              >
                                {t("course:POINTS", {
                                  point: `${selfTestResult?.currentTakenPoint}/${selfTestResult?.currentMaxPoint}`,
                                })}
                              </Typography>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <Grid container spacing={2} width="100%">
                        <Grid item xs={6}>
                          <div
                            style={{
                              border: "1px dashed #E8E8E8",
                              padding: "10px",
                              backgroundColor: "#F9F9F9",
                              borderRadius: "12px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              {t("course:task.FORMAT_TASK")}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", lineHeight: "24px" }}
                            >
                              {t(`enum:${taskInitialData?.task?.format}`)}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div
                            style={{
                              border: "1px dashed #E8E8E8",
                              padding: "10px",
                              backgroundColor: "#F9F9F9",
                              borderRadius: "12px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              {t("course:task.TYPE_OF_TASK")}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", lineHeight: "24px" }}
                            >
                              {t(`enum:${taskInitialData?.task?.type}`)}
                            </Typography>
                          </div>
                        </Grid>
                        {taskInitialData.task?.format === TaskFormat.QUIZ && (
                          <>
                            <Grid item xs={6}>
                              <div
                                style={{
                                  border: "1px dashed #E8E8E8",
                                  padding: "10px",
                                  backgroundColor: "#F9F9F9",
                                  borderRadius: "12px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                  }}
                                >
                                  {t("course:test.NUMBER_OF_QUESTIONS")}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: "16px", lineHeight: "24px" }}
                                >
                                  {taskInitialData?.task?.quiz?.numberOfQuestions || numberOfQuestions}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div
                                style={{
                                  border: "1px dashed #E8E8E8",
                                  padding: "10px",
                                  backgroundColor: "#F9F9F9",
                                  borderRadius: "12px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                  }}
                                >
                                  {t("course:test.TESTING_TIME_IN_MINUTES")}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: "16px", lineHeight: "24px" }}
                                >
                                  {taskInitialData?.task?.quiz?.duration}
                                </Typography>
                              </div>
                            </Grid>
                          </>
                        )}
                        {taskInitialData.task?.format !== TaskFormat.QUIZ && (
                          <Grid item xs={6}>
                            <div
                              style={{
                                border: "1px dashed #E8E8E8",
                                padding: "10px",
                                backgroundColor: "#F9F9F9",
                                borderRadius: "12px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                }}
                              >
                                {t("course:test.ANSWERING_METHOD")}
                              </Typography>
                              <Typography
                                sx={{ fontSize: "16px", lineHeight: "24px" }}
                              >
                                {t(`enum:${taskInitialData.task?.answerType}`)}
                              </Typography>
                            </div>
                          </Grid>
                        )}
                        {taskInitialData?.hasDeadlineDate && (
                          <Grid item xs={6}>
                            <div
                              style={{
                                border: "1px dashed #E8E8E8",
                                padding: "10px",
                                backgroundColor: "#F9F9F9",
                                borderRadius: "12px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                }}
                              >
                                {t("course:task.DEADLINE_FOR_COMPLETION")}
                              </Typography>
                              <Typography
                                sx={{ fontSize: "16px", lineHeight: "24px" }}
                              >
                                {formatDateWithHourMinute(
                                  taskInitialData?.dateOfDeadline
                                )}
                              </Typography>
                            </div>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={
                            taskInitialData.task?.format ===
                              TaskFormat.WRITTEN &&
                              taskInitialData.dateOfDeadline
                              ? 12
                              : 6
                          }
                        >
                          <div
                            style={{
                              border: "1px dashed #E8E8E8",
                              padding: "10px",
                              backgroundColor: "#F9F9F9",
                              borderRadius: "12px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              {t("course:theme.RESPONSIBLE_TEACHER")}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", lineHeight: "24px" }}
                            >
                              {recipientParams?.responsibleMembers?.map(
                                (member, index) => (
                                  <span key={member.id}>
                                    {member.userFullName}
                                    {index <
                                      recipientParams?.responsibleMembers
                                        .length -
                                      1 && ", "}
                                  </span>
                                )
                              )}
                            </Typography>
                          </div>
                        </Grid>
                        {taskInitialData.task?.quiz?.applyProctoring && (
                          <Grid item xs={12}>
                            <div
                              style={{
                                border: "1px dashed #E8E8E8",
                                padding: "10px",
                                backgroundColor: "#F9F9F9",
                                borderRadius: "12px",
                              }}
                            >
                              <Box display="flex" gap={1}>
                                <ErrorOutlineOutlinedIcon
                                  sx={{ color: common.primaryColor }}
                                />
                                <Typography color={common.primaryColor}>
                                  {t("aero:TESTING_AERO")}
                                </Typography>
                              </Box>
                              <Divider sx={{ margin: "15px 0" }} />
                              <Link
                                style={{ textDecoration: "none" }}
                                href="https://chrome.google.com/webstore/detail/aero-proctoring/pknhbkoicndpapfcbhhccnikagmankgg/related?hl=ru&authuser=0"
                                target="_blank"
                              >
                                <Box
                                  display="flex"
                                  gap={1}
                                  marginBottom="10px"
                                  sx={{ cursor: "pointer" }}
                                >
                                  <FileUploadOutlinedIcon
                                    sx={{ color: common.primaryColor }}
                                  />
                                  <Typography color={common.primaryColor}>
                                    {t("aero:DOWNLOAD_AERO")}
                                  </Typography>
                                </Box>
                              </Link>
                              {/* <Box
                                display="flex"
                                gap={1}
                                marginBottom="10px"
                                sx={{ cursor: "pointer" }}
                              >
                                <LaunchOutlinedIcon
                                  sx={{ color: common.primaryColor }}
                                />
                                <Typography color={common.primaryColor}>
                                  {t("aero:DOWNLOAD_AERO")}
                                </Typography>
                              </Box> */}
                            </div>
                          </Grid>
                        )}
                        {taskInitialData.task?.format !== TaskFormat.QUIZ &&
                          (taskInitialData.task?.isNoMark ||
                            taskInitialData.task?.isExcludeTotalMark) && (
                            <Grid item xs={12}>
                              <CommonInfoBox
                                icon={
                                  <ErrorIcon
                                    sx={{
                                      color: common.primaryColor,
                                      opacity: 1,
                                      width: "20px",
                                    }}
                                  />
                                }
                                message={
                                  taskInitialData.task?.isNoMark
                                    ? t("course:theme.WITHOUT_EVALUATION")
                                    : t(
                                      "course:task.TASK_NOT_TAKEN_INTO_ACCOUNT_IN_FINAL_GRADE"
                                    )
                                }
                              />
                            </Grid>
                          )}
                        {taskInitialData.task.format === TaskFormat.ORAL &&
                          taskInitialData.task?.answerType ===
                          AnswerTypes.VIDEO && (
                            <Grid item xs={12}>
                              <CommonInfoBox
                                icon={
                                  <ErrorIcon
                                    sx={{
                                      color: common.primaryColor,
                                      opacity: 1,
                                      width: "20px",
                                    }}
                                  />
                                }
                                message={t(
                                  "course:hints.ORAL_TASK_ANSWER_TYPE_VIDEO_HINT",
                                  {
                                    videoSum: taskInitialData.task?.videoSum,
                                    videoDuration:
                                      taskInitialData.task?.videoDuration,
                                  }
                                )}
                              />
                            </Grid>
                          )}
                      </Grid>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
            {taskInitialData.task?.description && !showQuizResult && (
              <>
                <Box
                  onClick={() => setDescriptionOpen(!descriptionOpen)}
                  sx={{
                    display: "flex",
                    my: "14px",
                    mx: "17px",
                    backgroundColor: "#E9E9E9",
                    width: "24px",
                    height: "24px",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                >
                  {descriptionOpen ? (
                    <ExpandMoreIcon sx={{ color: "#1F2855" }} />
                  ) : (
                    <ExpandMoreIcon
                      sx={{ color: "#1F2855", rotate: "180deg" }}
                    />
                  )}
                  <Typography
                    sx={{ fontSize: "16px", ml: "4px", color: "#1F2855" }}
                  >
                    {t("common:label.DESCRIPTION")}
                  </Typography>
                </Box>
                {descriptionOpen && (
                  <Box
                    sx={{
                      width: "auto",
                      maxHeight: "222px",
                      overflowY: "scroll",
                      my: "14px",
                      mx: "17px",
                      py: "5px",
                      px: "7px",
                      backgroundColor: "white",
                      border: `1px solid ${common.borderSecondary}`,
                      height: "auto",
                      borderRadius: "12px",
                      "&::-webkit-scrollbar": { width: "7px" },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#E5E0EB",
                        borderRadius: "6px",
                      },
                    }}
                  >
                    <TextReader
                      htmlContent={taskInitialData.task?.description}
                      hideBorder
                    />
                  </Box>
                )}
              </>
            )}
            {taskInitialData.task?.format === TaskFormat.WRITTEN && (
              <Box sx={{ my: "14px", mx: "17px" }}>
                <Typography
                  sx={{
                    fontSize: "28px",
                    lineHeight: "36px",
                    color: common.primaryColor,
                    fontWeight: 500,
                  }}
                >
                  {t("common:ANSWERS")}
                </Typography>
                <Divider sx={{ my: "16px" }} />
                {(taskInitialData.status === TaskStatus.ASSIGNED ||
                  taskInitialData.status === TaskStatus.ON_CORRECTION) && (
                    <Box sx={{ display: "block" }}>
                      <CustomInput
                        name="comment"
                        value={comment}
                        handleChange={handleInputChange}
                        placeholder={t("course:WRITE_A_COMMENT")}
                        label={`${t("common:COMMENTS")}:`}
                        multiline
                        rows={4}
                        showSymbolCount
                        minChar={
                          taskInitialData.task?.answerType === AnswerTypes.FILE
                            ? 0
                            : 150
                        }
                        maxChar={
                          taskInitialData.task?.answerType === AnswerTypes.FILE
                            ? 64
                            : 3000
                        }
                        disabled={completedCourse}
                      />
                      {taskInitialData.task?.answerType === AnswerTypes.FILE && (
                        <UploadButton
                          label={t("common:actions.ADD_FILE")}
                          backgroundColor={common.primaryColor}
                          fontColor={common.fontWhite}
                          width="172px"
                          borderRadius="6px"
                          height="28px"
                          fontWeight={400}
                          typeCode="004"
                          acceptedExtensions={answerTypeFileFormats}
                          sizeLimit={courseData?.maxFileSizeCanUploadStudent}
                          attachAndSaveFile={addFile}
                          disabled={completedCourse}
                        />
                      )}
                      {fileList.length > 0 &&
                        fileList.map((file: IFile) => {
                          return (
                            <Box
                              key={file.fileId}
                              sx={{
                                display: "flex",
                                my: 2,
                                gap: 1,
                              }}
                            >
                              <Typography>{file.fileName}</Typography>
                              <DeleteIcon
                                color="error"
                                sx={{ cursor: "pointer" }}
                                onClick={() => deleteFile(file.fileId)}
                              />
                            </Box>
                          );
                        })}
                      <Box sx={{ display: "flex", gap: 2, mt: "12px" }}>
                        <CustomButton
                          width="100px"
                          backgroundColor={common.fontWhite}
                          borderColor={common.primaryColor}
                          borderRadius="6px"
                          fontColor={common.primaryColor}
                          onClick={() => cancelCreateReply()}
                        >
                          {t("course:task.CANCELLATION")}
                        </CustomButton>
                        <CustomButton
                          width="250px"
                          backgroundColor={common.primaryColor}
                          borderRadius="6px"
                          fontColor={common.fontWhite}
                          disabled={
                            (taskInitialData.task?.answerType !== "FILE" &&
                              !comment) ||
                            (taskInitialData.task?.answerType === "FILE" &&
                              fileList.length <= 0) ||
                            (taskInitialData.task?.answerType === "TEXT" &&
                              (comment.length < 150 || comment.length > 3000)) ||
                            completedCourse
                          }
                          onClick={() => setValuesOpenModal()}
                        >
                          {t("course:task.SUBMIT_FOR_REVIEW")}
                        </CustomButton>
                      </Box>
                    </Box>
                  )}
                {lastComment && (
                  <Box sx={{ mt: 3 }}>
                    {taskInitialData.task?.format === TaskFormat.WRITTEN && (
                      <SingleCommentComponent
                        item={lastComment}
                        userId={profile.pltaId}
                      />
                    )}

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        sx={{
                          color: common.white,
                          fontWeight: 500,
                          mt: "16px",
                          backgroundColor: common.primaryColor,
                          p: "5px 10px",
                          borderRadius: "6px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(
                            `${window.location.pathname}/replyHistory/${taskInitialData?.id}`
                          );
                        }}
                      >
                        {t("course:task.ANSWER_HISTORY")}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            {currentQuestionId && (
              <Box m={2}>
                <QuizResult
                  showCorrectAnswer={
                    taskInitialData.task?.quiz?.displayRightAnswers
                  }
                  attemptResult={quizResult}
                  questions={questions}
                  currentQuestionId={currentQuestionId}
                  goToQuestion={goToQuestion}
                />
              </Box>
            )}
            {taskInitialData.task?.format === TaskFormat.QUIZ &&
              !taskInitialData.task?.isSelfTest &&
              ((typeCode === TypeCode.EDU &&
                taskInitialData?.status !== TaskStatus.COMPLETED &&
                ((!taskInitialData.task?.quiz?.allowMultipleAttempts &&
                  quizAttemptList.length === 0) ||
                  (taskInitialData.task?.quiz?.allowMultipleAttempts &&
                    quizAttemptList.length <=
                    Number(taskInitialData.task?.quiz.numberOfAttempts)))) ||
                typeCode === TypeCode.FREE) && (
                <Box sx={{ display: "block" }}>
                  {taskInitialData?.task?.quiz?.allowMultipleAttempts &&
                    typeCode === TypeCode.EDU && (
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          color: "#1F2855",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "24px",
                          mt: "27px",
                        }}
                      >
                        {t("course:theme.NUMBER_OF_ATTEMPTS")}:{" "}
                        {Number(taskInitialData?.task?.quiz?.numberOfAttempts) -
                          quizAttemptList.length}
                        /{taskInitialData?.task?.quiz?.numberOfAttempts}
                      </Typography>
                    )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      my: "16px",
                      gap: 2,
                    }}
                  >
                    {taskInitialData.task?.quiz?.applyProctoring ? (
                      <CustomButton
                        width="350px"
                        backgroundColor={common.primaryColor}
                        borderRadius="6px"
                        fontColor={common.fontWhite}
                        sx={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "130%",
                        }}
                        onClick={() => {
                          setOpenAeroModal(!openAeroModal);
                        }}
                        disabled={
                          Number(
                            taskInitialData?.task?.quiz?.numberOfAttempts
                          ) -
                          quizAttemptList.length ===
                          0
                        }
                      >
                        {t("course:test.READ_AERO_RULES")}
                      </CustomButton>
                    ) : quizProgressStarted ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            width: "70%",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: common.primaryColor,
                              textAlign: "center",
                            }}
                          >
                            {t("course:test.CONTINUE_TESTING_TEXT")}
                          </Typography>
                        </Box>
                        <CustomButton
                          width="250px"
                          backgroundColor={common.primaryColor}
                          borderRadius="6px"
                          fontColor={common.fontWhite}
                          sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "130%",
                          }}
                          onClick={() => {
                            startResumeQuiz(taskInitialData?.id);
                          }}
                          disabled={
                            Number(
                              taskInitialData?.task?.quiz?.numberOfAttempts
                            ) -
                            quizAttemptList.length ===
                            0
                          }
                        >
                          {t("course:test.CONTINUE_TESTING")}
                        </CustomButton>
                      </>
                    ) : (
                      <CustomButton
                        width="220px"
                        backgroundColor={common.primaryColor}
                        borderRadius="6px"
                        fontColor={common.fontWhite}
                        fontWeight={500}
                        sx={{ fontSize: "14px", lineHeight: "130%" }}
                        onClick={() =>
                          quizAttemptList.length === 0
                            ? startResumeQuiz(taskInitialData?.id)
                            : setConfirmModalOpen(true)
                        }
                        disabled={
                          (typeCode === TypeCode.EDU &&
                            Number(
                              taskInitialData?.task?.quiz?.numberOfAttempts
                            ) -
                            quizAttemptList.length ===
                            0) ||
                          role !== Role.LISTENER
                        }
                      >
                        {quizAttemptList.length === 0 ? null : (
                          <RefreshIcon sx={{ mr: 1 }} />
                        )}
                        {quizAttemptList.length === 0
                          ? t("course:test.START_TESTING")
                          : t("course:test.TAKE_TEST_AGAIN")}
                      </CustomButton>
                    )}
                  </Box>
                </Box>
              )}
          </Box>
          {taskInitialData.task?.format === TaskFormat.ORAL &&
            taskInitialData.task?.answerType === AnswerTypes.VIDEO && (
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "white",
                  border: `1px solid ${common.borderSecondary}`,
                  height: "auto",
                  borderRadius: "12px",
                  mt: "15px",
                  p: 2,
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h2"
                    sx={{ fontSize: "28px", fontWeight: 500, mb: 2 }}
                  >
                    {t("common:ANSWERS")}
                  </Typography>
                  {(taskInitialData.status === TaskStatus.ASSIGNED ||
                    taskInitialData.status === TaskStatus.ON_CORRECTION) && (
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <UploadButton
                          label={t("enum:UPLOAD_VIDEO")}
                          borderRadius="4px"
                          fontColor="#fff"
                          attachAndSaveFile={addFile}
                          typeCode="009"
                          acceptedExtensions=".mp4, .avi"
                          disabled={
                            fileList.length === taskInitialData.task?.videoSum
                          }
                          videoLimit={
                            (taskInitialData.task?.videoDuration ?? 1) * 60
                          }
                          sizeLimit={courseData?.maxFileSizeCanUploadStudent}
                        />
                        <CustomButton
                          borderRadius="4px"
                          onClick={() => setValuesOpenModal()}
                          disabled={fileList.length <= 0}
                        >
                          {t("course:task.SUBMIT_FOR_REVIEW")}
                        </CustomButton>
                      </Box>
                    )}
                </Box>


                {(taskInitialData.status === TaskStatus.ASSIGNED ||
                  taskInitialData.status === TaskStatus.ON_CORRECTION) && (
                    <>
                      <Grid
                        display={"grid"}
                        gridTemplateColumns={
                          "repeat(auto-fill,minmax(293px,1fr))"
                        }
                        gap={1.5}
                      >
                        {fileList.length > 0 &&
                          fileList.map((video) => (
                            <Box key={video.fileId} sx={{wordBreak: 'break-word'}}>
                              <VideoPlayer
                                videoFileId={video.fileId}
                                height={220}
                                width="100%"
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {video.fileName}
                                <Box
                                  onClick={(e) =>
                                    openVideoActions(e, video.fileId)
                                  }
                                  sx={{ cursor: "pointer" }}
                                >
                                  <MoreVertIcon />
                                </Box>
                                <Menu
                                  id="fade-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "fade-button",
                                  }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  TransitionComponent={Fade}
                                  disableScrollLock
                                >
                                  <MenuItem
                                    onClick={() => {
                                      downloadFile(currentVideoId);
                                      handleClose();
                                    }}
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: common.primaryColor,
                                        color: common.fontWhite,
                                      },
                                    }}
                                  >
                                    {t("common:actions.DOWNLOAD")}
                                  </MenuItem>
                                  <MenuItem
                                    onClick={deleteVideo}
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: common.primaryColor,
                                        color: common.fontWhite,
                                      },
                                    }}
                                  >
                                    {t("common:actions.DELETE")}
                                  </MenuItem>
                                </Menu>
                              </Box>
                            </Box>
                          ))}
                      </Grid>

                      <Box sx={{ display: "block", mt: 3 }}>
                        <CustomInput
                          name="comment"
                          value={comment}
                          handleChange={handleInputChange}
                          placeholder={t("course:WRITE_A_COMMENT")}
                          label={`${t("common:COMMENTS")}:`}
                          multiline
                          rows={4}
                          showSymbolCount
                          minChar={0}
                          maxChar={64}
                        />
                      </Box>
                    </>
                  )}
                {
                  //TODO:VIDEO LAST COMMENT
                  (taskInitialData.status === TaskStatus.UNDER_REVIEW ||
                    taskInitialData.status === TaskStatus.COMPLETED ||
                    taskInitialData.status ===
                    TaskStatus.NOT_COMPLETED_ON_TIME ||
                    taskInitialData.status === TaskStatus.ON_CORRECTION) &&
                  lastComment && (
                    <Box sx={{ mt: 3 }}>
                      <SingleCommentComponentOral
                        item={lastComment}
                        userId={profile.pltaId}
                      />
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography
                          sx={{
                            color: common.white,
                            fontWeight: 500,
                            mt: "16px",
                            backgroundColor: common.primaryColor,
                            p: "5px 10px",
                            borderRadius: "6px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate(
                              `${window.location.pathname}/replyHistory/${taskInitialData?.id}?type=video`
                            );
                          }}
                        >
                          {t("course:task.ANSWER_HISTORY")}
                        </Typography>
                      </Box>
                    </Box>
                  )
                }
              </Box>
            )}
        </Box>
        {!taskInitialData.task?.isSelfTest ? (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "column", sm: "row", md: "column" },
              justifyContent: { xs: "center", md: "flex-start" },
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            {/* Статус */}
            <Box
              sx={{
                width: { xs: "100%", sm: "256px" },
                borderRadius: "12px",
                ml: { xs: 0, md: "26px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  ml: "18px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ display: "block" }}>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "20px",
                      lineHeight: "18px",
                      mb: "16px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Статус
                  </Typography>
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      backgroundColor:
                        statusBgColor[
                        role === Role.LISTENER
                          ? taskInitialData.status || ""
                          : TaskStatus.ASSIGNED
                        ],
                      p: "12px",
                      px: "20px",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "12px",
                    }}
                  >
                    {role === Role.LISTENER
                      ? t(`common:statuses.${taskInitialData.status}`)
                      : t(`enum:ASSIGNED`)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* Оценка */}
            {!taskInitialData.task?.isNoMark &&
              <Box
                sx={{
                  width: { xs: "100%", sm: "256px" },
                  borderRadius: "12px",
                  borderTop: "solid 12px #57B2DF",
                  backgroundColor: common.white,
                  ml: { xs: 0, md: "26px" },
                  py: 1,
                }}
              >
                <Box sx={{ display: "flex", gap: 2, mx: 2 }}>
                  <Box
                    sx={{
                      backgroundColor: common.secondaryColor,
                      width: "60px",
                      height: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mr: "10px",
                      borderRadius: "9px",
                    }}
                  >
                    <AssignmentTurnedInOutlinedIcon
                      sx={{
                        color: common.primaryColor,
                        width: "24px",
                        height: "26px",
                      }}
                    />
                  </Box>
                  <Box sx={{ display: "block" }}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20px",
                      }}
                    >
                      {t("course:GRADE")}
                    </Typography>
                    {typeCode === TypeCode.EDU ? (
                      <Typography sx={{ fontSize: "16px", lineHeight: "20px" }}>
                        {taskInitialData?.task?.userMark ?? (taskInitialData?.point && Math.ceil(Number(taskInitialData?.point))) ?? "-"} /{" "}
                        {Number(taskInitialData.task?.point)}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "20px",
                          textAlign: "center",
                        }}
                      >
                        {taskInitialData?.status === TaskStatus.PASSED
                          ? t(`common:statuses.PASSED`)
                          : taskInitialData?.status === TaskStatus.NOT_PASSED
                            ? t(`common:statuses.NOT_PASSED`)
                            : taskInitialData?.point ? taskInitialData?.point : "--"}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            }
            {/* Дополнительные материал */}
            {taskInitialData.task?.format !== TaskFormat.QUIZ &&
              !matchesDownMd && <MaterialList />}
            {taskInitialData.task?.format === TaskFormat.ORAL &&
              (taskInitialData.task?.answerType === AnswerTypes.OFFLINE ||
                taskInitialData.task?.answerType ===
                AnswerTypes.ONLINE_CONFERENCE) && (
                <MeetingList title={title} svg={svg} list={list} />
              )}
            {/* Result of test */}
            {taskInitialData.task?.format === TaskFormat.QUIZ && (
              <TaskQuizResult
                quizAttemptList={quizAttemptList}
                attemptResult={quizResult}
                showAnswers={showQuizResult}
                displayGrade={taskInitialData.task?.quiz?.displayGrade}
                displayRightAnswers={
                  taskInitialData.task?.quiz?.displayRightAnswers
                }
                currentAttemptId={currentAttemptId}
                getAttemtpResult={getAttemtpResult}
              />
            )}

            {/* Zametka */}
            {role === Role.LISTENER && (
              <FloatingNote
                open={floatingNoteOpen}
                setOpen={setFloatingNoteOpen}
              />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "flex-start",
              mt: "15px",
            }}
          >
            <Box
              sx={{
                borderRadius: "12px",
                backgroundColor: common.white,
                borderTop: `solid 12px ${common.secondaryColor}`,
                py: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  ml: "18px",
                  justifyContent: "center",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "24px",
                  alignItems: "center",
                }}
              >
                <TimerOutlinedIcon sx={{ mr: "10px" }} />
                <Typography
                  sx={{ fontWeight: 500, fontSize: "16px", lineHeight: "24px" }}
                >
                  {t("course:test.TIME_LEFT")}
                </Typography>
              </Box>
              <Box
                sx={{
                  mx: "48px",
                  display: "flex",
                  py: "26px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{ ...timeStyle, backgroundColor: common.primaryColor }}
                >
                  {minutes < 10 ? "0" + minutes : minutes}
                </Box>
                <Typography
                  sx={{
                    color: "#E6E1E5",
                    fontSize: "36px",
                    lineHeight: "24px",
                    mx: "10px",
                  }}
                >
                  :
                </Typography>
                <Box sx={{ ...timeStyle, backgroundColor: "#49454F" }}>
                  {seconds < 10 ? "0" + seconds : seconds}
                </Box>
              </Box>
            </Box>
            <CustomButton
              backgroundColor={common.primaryColor}
              borderRadius="6px"
              onClick={() => startSelfTestQuiz(taskInitialData.id)}
            >
              <Typography
                sx={{
                  color: "white",
                  lineHeight: "130%",
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RefreshOutlinedIcon sx={{ mr: "12px" }} />
                {t("course:test.TAKE_TEST_AGAIN")}
              </Typography>
            </CustomButton>
          </Box>
        )}
      </Box>

      {/* Дополнительные материал */}
      {taskInitialData.task?.format !== TaskFormat.QUIZ && matchesDownMd && (
        <MaterialList />
      )}
      {(courseStatus === CourseStatus.SAVED
        ? currentStep === 5
        : Number(currentStep) >= 9) && <PreviewLogout courseId={classId} />}
      {role === Role.LISTENER && !taskInitialData.task?.isSelfTest && (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            bottom: "100px",
            right: "50px",
            zIndex: 100000,
          }}
          onClick={() => setFloatingNoteOpen(!floatingNoteOpen)}
        >
          <IconButton
            sx={{
              background: "#F3F56D",
              padding: "15px",
              color: "#000",
              ":hover": { background: "#F3F56D" },
            }}
          >
            <StickyNote2Outlined fontSize="small" />
          </IconButton>
        </Box>
      )}
      {confirmModalOpen && (
        <YesOrNoModal
          openModal={confirmModalOpen}
          handleToggleModal={handleToggleConfirmModal}
          success={false}
          attention
          message={
            taskInitialData.task?.format === TaskFormat.QUIZ
              ? t("course:messages.ARE_YOU_SURE_TO_GET_TESTED_AGAIN", {
                considerResult: t(
                  `enum:${taskInitialData?.task?.quiz?.considerResult}`
                ).toLowerCase(),
              })
              : t("course:messages.TASK_CANNOT_BE_CHANGED_LATER")
          }
          confirmAction={
            taskInitialData.task?.format === TaskFormat.QUIZ
              ? () => startResumeQuiz(taskInitialData?.id)
              : createTaskReply
          }
        />
      )}
      {successModalOpen && (
        <SuccesSend
          open={successModalOpen}
          handleToggleModal={handleToggleSuccessModal}
        />
      )}
      {openAeroModal && (
        <AeroRuleModal
          openModal={openAeroModal}
          handleToggleModal={() => setOpenAeroModal((prev) => !prev)}
          progressId={taskInitialData?.id || ""}
        />
      )}
    </Box>
  );
};

export default Task;
