import { Box, Typography } from "@mui/material";
import React from "react";

interface Props {
  userList: any[];
  blockLabel: string;
  onChange?: any;
}
const InfoBlock: React.FC<Props> = ({ blockLabel, onChange, userList }) => {
  return (
    <>
      <Typography
        sx={{
          fontFamily: "Golos",
          fontSize: "14px",
          fontWeight: "600",
          lineHeight: "20px",
        }}
        gutterBottom
      >
        {blockLabel}
      </Typography>
      <Box
        padding={1.5}
        sx={{
          border: "1px solid #E5E0EB",
        }}
        borderRadius={"4px"}
      >
        <Box
          sx={{
            "&::-webkit-scrollbar": {
              width: "12px",
              backgroundColor: "#EEEEEE",
              borderRadius: "9999px",
            },
            "&::-webkit-scrollbar-track": {
              padding: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#BDBDBD",
              borderRadius: "9999px",
              backgroundClip: "padding-box",
              border: "3px solid rgba(0, 0, 0, 0)",
              height: "20px",
            },
            height: "144px",
            overflowY: "auto",
          }}
        >
          <Typography>
            {userList.map((user) => user?.userFullName).join(", ")}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export { InfoBlock };
