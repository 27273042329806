import { useState } from 'react';

import { BreadcrumbsContext } from '@context/BreadcrumbsContext';
import { IBreadcrumb } from '@interfaces/index';

const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbsMap, setBreadcrumbsMap] = useState<Map<string, IBreadcrumb[]>>(() => {
    const storedBreadcrumbs = localStorage.getItem('breadcrumbsMap');
    return storedBreadcrumbs ? new Map<string, IBreadcrumb[]>(JSON.parse(storedBreadcrumbs)) : new Map();
});

  const setBreadcrumbs = (courseId: string, breadcrumbs: Array<IBreadcrumb>) => {
    setBreadcrumbsMap((prev) => {
      const newMap = new Map(prev).set(courseId, breadcrumbs);
      // Store breadcrumbs in localStorage
      localStorage.setItem('breadcrumbsMap', JSON.stringify(Array.from(newMap.entries())));
      return newMap;
    });
  };

  const getBreadcrumbs = (courseId: string): IBreadcrumb[] => {
    return breadcrumbsMap.get(courseId) || [];
  };

  return (
    <BreadcrumbsContext.Provider value={{ setBreadcrumbs, getBreadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export default BreadcrumbsProvider;