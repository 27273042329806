import { useEffect, useState, useContext } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Tab,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import LanguageIcon from "@mui/icons-material/Language";
import BallotIcon from "@mui/icons-material/Ballot";

import { serviceCourse } from "@services/course";
import { getLangDictById, formatDateWithHourMinute } from "@utils/functions";
import { Evaluation } from "./Evaluation";
import { DescriptionCurrentCourse } from "./Description";
import ImportantEvents from "./ImportantEvents";

import { serviceTask } from "@services/task";
import { useSelector } from "react-redux";
import { serviceUser } from "@services/user";
import { DTO } from "@pages/profile/models";
import AvatarPicture from "@components/AvatarPicture";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import { capitalize } from "lodash";
import { serviceDict } from "@services/dictionary";
import { CourseContext } from "@context/CourseContext";
import {
  CourseFormatType,
  Role,
  TypeCode,
} from "@interfaces/index";
import { ActionCourse } from "@pages/myCourses/Educator/PublishedCourse/ActionCourse";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { ContentAccessExpirationStatus } from "@interfaces/status";

const CourseMainPage = () => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course", "enum"]);
  const { classId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [courseTaskGroupInfo, setcourseTaskGroupInfo] = useState<any | null>(
    []
  );
  const location = useLocation();

  const currentTab = searchParams.get("type");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role } = profile.authorityInfo;
  const [authorsInfo, setAuthorsInfo] = useState<DTO[]>([]);
  const [difficultyLevel, setDifficultyLevel] = useState();
  const lang = localStorage.getItem("i18nextLng") || "kz";
  const { courseData } = useContext(CourseContext);
  const isFreeCourse = courseData?.typeCode === TypeCode.FREE;
  useEffect(() => {
    if (courseData?.difficultyLevelId) {
      serviceDict
        .getDictionary("COURSE_DIFFICULTY_LEVEL", courseData?.difficultyLevelId)
        .then((res) => {
          setDifficultyLevel(res.data);
        });
    }
  }, [courseData?.difficultyLevelId]);

  const handleTabChange = (type: string) => {
    searchParams.set("type",type);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (classId) {
      if (role === Role.LISTENER) {
        serviceCourse.getListenerCurrent(classId).then((res) => {
          if (res.status === 200) {
            setcourseTaskGroupInfo(res.data);
            searchParams.set("currentRate",res.data?.currentRate);
            searchParams.set("done",`${res.data?.tasks?.filter(item=>item?.status==="NOT_PASSED")?.length}/${res.data?.tasks?.length}`);
            setSearchParams(searchParams);
          }
        });
      } else {
        serviceTask
          .getTaskList(classId, { name: "", format: null, status: "" })
          .then((res) => {
            if (res.status === 200) {
              setcourseTaskGroupInfo((prev) => ({
                ...prev,
                tasks: res.data.dtoList,
              }));
            }
          });
      }
    }
    // eslint-disable-next-line
  }, [classId, role]);

  const getCourseMessageByTime = (
    canStudentViewAfterEnd:boolean,
    contentAccessExpiration:ContentAccessExpirationStatus
  ):string => {
    if (!canStudentViewAfterEnd) {
      return t("course:messages.CAN_VIEW_AFTER_END");
    }
  
    switch (contentAccessExpiration) {
      case ContentAccessExpirationStatus.IMMEDIATELY_AFTER_COMPLETION:
        return t("course:messages.CAN_VIEW_AFTER_END");
      case ContentAccessExpirationStatus.ONE_WEEK_AFTER_COMPLETION:
        return t("course:messages.ONE_WEEK_AFTER_COMPLETION_FINAL_GRADE");
      case ContentAccessExpirationStatus.ONE_MONTH_AFTER_COMPLETION:
        return t("course:messages.ONE_MONTH_AFTER_COMPLETION_FINAL_GRADE");
      case ContentAccessExpirationStatus.THREE_MONTHS_AFTER_COMPLETION:
        return t("course:messages.THREE_MONTHS_AFTER_COMPLETION_FINAL_GRADE");
      default:
        return "";
    }
  };

  useEffect(() => {
    if (courseData?.courseId) {
      serviceCourse.getCourseAuthors(courseData?.courseId).then((res) => {
        if (res.status === 200) {
          const userIDs = res.data.map((user) => user.userId);
          Promise.all(
            userIDs.map((userID) =>
              serviceUser.getUserInfoById(userID).then((response) => {
                if (response.status === 200) {
                  // Check if the user is already in the state before adding
                  if (
                    !authorsInfo.some(
                      (author) => author.id === response.data.id
                    )
                  ) {
                    setAuthorsInfo([...authorsInfo, response.data]);
                  }
                }
              })
            )
          );
        }
      });
    }
    // eslint-disable-next-line
  }, [courseData?.courseId]);

  const tabs = [
    {
      value: "EVALUATION",
      component: (
        <Evaluation
          gradingSystemId={courseData?.gradingSystemId || ""}
          showFinalMark={courseData?.finalGradeDisplayMethod === "AUTOMATIC"}
          completedTasksRate={courseData?.completedTasksRate}
          minLimitForTask={courseData?.minLimitForTask}
        />
      ),
    },
    {
      value: "DESCRIPTION",
      component: <DescriptionCurrentCourse />,
    },
  ];

  if (
    courseTaskGroupInfo?.groups?.length < 1 &&
    courseTaskGroupInfo?.tasks?.length < 1
  ) {
    tabs.shift();
  }

  return (
    <Box
      sx={{ width: "100%" }}
      display={"flex"}
      flexDirection={"column"}
      gap={1.5}
    >
      {location.pathname.split("/")?.[2] === "completed" && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: `1px solid ${common.borderSecondary}`,
            borderRadius: "12px",
            overflow: "hidden",
            gap: "16px",
          }}
        >
          <Box
            width={"6px"}
            borderRadius={"8px 0 0 8px"}
            sx={{
              backgroundColor: "#00B998",
              minHeight: "78px",
            }}
          />
          <Box
            width={"40px"}
            height={"40px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
            bgcolor={"#00B99830"}
          >
            <FeedbackOutlinedIcon
              sx={{
                fill: "#00B998",
              }}
            />
          </Box>
          <Box>
            <Typography fontWeight={"600"} fontSize={"16px"}>
              {"Вы успешно завершили курс 🥳"}
            </Typography>
            <Typography fontWeight={"400"} fontSize={"14px"} color={"#6F6F7E"}>
             {getCourseMessageByTime(courseData?.canStudentViewAfterEnd, courseData?.contentAccessExpiration)}
            </Typography>
          </Box>
        </Box>
      )}

      <Box>
        <Box
          sx={{
            backgroundColor: common.primaryColor,
            padding: "15px",
            color: "#fff",
            borderRadius: "10px 10px 0 0",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography fontSize="24px">{courseData?.courseName}</Typography>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            gap="30px"
          >
            <Typography>
              {t("course:listener.PASS_PERIOD")}:{" "}
              {formatDateWithHourMinute(courseData?.startDate)}-
              {formatDateWithHourMinute(courseData?.endDate)}
            </Typography>
            {!isFreeCourse && (
              <Box display="flex" gap={2}>
                <Box display="flex">
                  <LanguageIcon
                    sx={{ width: "20px", height: "20px", mr: "7px" }}
                  />
                  {getLangDictById(courseData?.langId || 1)}
                </Box>
                <Box display="flex">
                  <BallotIcon
                    sx={{ width: "20px", height: "20px", mr: "7px" }}
                  />
                  {courseData?.credits} {t("course:listener.CREDITS")}
                </Box>
              </Box>
            )}
          </Box>
          {isFreeCourse && (
            <>
              <Box sx={{ display: "flex", gap: 2 }}>
                {authorsInfo.map((author) => (
                  <Box
                    key={author.id}
                    display="flex"
                    gap={2}
                    alignItems="center"
                  >
                    <AvatarPicture
                      id={author.profilePhotoId}
                      variant="circular"
                      sxProps={{ width: "44px", height: "44px" }}
                    />
                    <Typography fontSize="20px" color="#fff">
                      {author.firstName} {author.patronymic}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                gap="16px"
              >
                <Box
                  sx={{
                    padding: "10px",
                    background: "#9D7CEF",
                    borderRadius: "20px",
                  }}
                >
                  <Typography fontSize="14px" color="#fff">
                    {t(`enum:${courseData?.formatType}`)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    padding: "10px",
                    background: "#9D7CEF",
                    borderRadius: "20px",
                  }}
                >
                  <Typography fontSize="14px" color="#fff">
                    {getLangDictById(courseData?.langId || 1)}
                  </Typography>
                </Box>
              </Box>
            </>
          )}

          {isFreeCourse && (
            <Box display={"flex"} gap={2}>
              <Box display="flex" alignItems="center" gap={1}>
                <TaskOutlinedIcon sx={{ fontSize: "20px", color: "#fff" }} />
                <Typography fontSize="14px" color="#fff">
                  8 оцениваемых заданий
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1} margin="12px 0">
                <QuizOutlinedIcon sx={{ fontSize: "20px", color: "#fff" }} />
                <Typography fontSize="14px" color="#fff">
                  7 самостоятельных заданий
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}>
                <SignalCellularAltOutlinedIcon
                  sx={{ fontSize: "20px", color: "#fff" }}
                />
                <Typography fontSize="14px" color="#fff">
                  {difficultyLevel &&
                    difficultyLevel[`name${capitalize(lang)}`]}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" margin="12px 0" gap={1}>
                <BadgeOutlinedIcon sx={{ fontSize: "20px", color: "#fff" }} />
                <Typography fontSize="14px" color="#fff">
                  Выдача сертификата
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <TabContext
          value={
            currentTab ||
            (courseTaskGroupInfo?.groups?.length < 1 &&
            courseTaskGroupInfo?.tasks?.length < 1
              ? "DESCRIPTION"
              : "EVALUATION")
          }
        >
          <Box sx={{ px: 1.25, bgcolor: common.white }}>
            <TabList
              onChange={(_, value: string) => handleTabChange(value)}
              indicatorColor="secondary"
              sx={{
                "& .MuiTab-root.Mui-selected": {
                  color: "#1A141F",
                },
                "& .MuiTabs-indicator": {
                  background: common.borderPrimary,
                },
              }}
            >
              {tabs.map((tab, index) => {
                return (
                  <Tab
                    key={index}
                    value={tab.value}
                    label={t(`course:listener.${tab.value}`)}
                    sx={{
                      textTransform: "none",
                      fontSize: "18px",
                      lineHeight: "24px",
                      fontWeight: 500,
                      color: "#1A141F",
                    }}
                  />
                );
              })}
            </TabList>
          </Box>
          {tabs.map((tab, index) => {
            return (
              <TabPanel value={tab.value} key={index} sx={{ p: 0 }}>
                {tab.component}
              </TabPanel>
            );
          })}
        </TabContext>
        {!matches &&
          (!isFreeCourse ||
            (isFreeCourse &&
              courseData?.formatType === CourseFormatType.SYNC)) && (
            <ImportantEvents />
          )}
        {!matches && <ActionCourse />}
      </Box>
    </Box>
  );
};

export default CourseMainPage;
