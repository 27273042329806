import { IOrganization } from '@pages/dictionary/Organization/models';
import * as yup from 'yup';

export const validationSchema = (t: any, orgList: Array<IOrganization> = []) => yup.object({
  lastName: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  firstName: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  iin: yup.string().nullable().min(12, t('common:validation.INCORRECT_IIN_MIN_12_SYMBOLS')).max(12, t('common:validation.INCORRECT_IIN_MAX_12_SYMBOLS')),
  birthdate: yup.date().nullable()
    .when("iin", {
      is: (value: string) => { return value && value.length === 12 },
      then: yup.date().typeError(t('common:validation.FORMAT_IS_INCORRECT')).nullable().required(t('common:validation.MANDATORY_FIELD')),
      otherwise: yup.date().typeError(t('common:validation.FORMAT_IS_INCORRECT')).nullable().notRequired(),
    }),
  email: yup.string().email('Не корректно введена электронная почта')
    .matches(/^[a-zA-Z0-9$&?^_`~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/,
      "email должен содержать только латинские буквы, цифры и спецсимволы: $ & ? ^ _ ` ~ . -")
    .nullable().required(t('common:validation.MANDATORY_FIELD')),
  sex: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  mobilePhone: yup.string().nullable().when('migrated', {
    is: false,
    then: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  })
    .min(18, t('common:validation.FORMAT_IS_INCORRECT')).max(18, t('common:validation.FORMAT_IS_INCORRECT')),
  userRoles: yup.array()
    .of(
      yup.object().shape({
        role: yup.object()
          .shape({
            id: yup.number().nullable().required(t('common:validation.MANDATORY_FIELD'))
          }),
        belongsToOrg: yup.boolean(),
        orgId: yup.number().nullable().when('belongsToOrg', {
          is: true,
          then: yup.number().nullable().required(t('common:validation.MANDATORY_FIELD')),
          otherwise: yup.number().nullable().notRequired()
        }),
        eduProgramId: yup.number().nullable()
          .test('eduProgramId', t('common:validation.MANDATORY_FIELD'), function (value) {
            const { role, orgId } = this.parent;
            if (!orgId && !value) return true;
            return (orgList.find(x => x.id === orgId)?.branchTypeId === 2 && role.code === "LISTENER" && !!value)
              || (orgList.find(x => x.id === orgId)?.branchTypeId !== 2 && role.code === "LISTENER" && !value)
              || role.code !== "LISTENER";
          }),
      })
    )
    .test(
      'unique-roles',
      t('common:validation.ROLE_HAS_ALREADY_BEEN_ADDED'),
      (value) => {
        if (!Array.isArray(value)) {
          return true;
        }

        const seenRoles = new Set();
        for (const item of value) {
          const { role, orgId } = item;
          const key = `${role.id}-${orgId || ''}`;
          if (seenRoles.has(key)) {
            return false;
          }
          seenRoles.add(key);
        }
        return true;
      }
    )
    .test(
      'at-least-one',
      t('common:validation.ONE_ROLE_AT_LEAST_HAVE_TO_BE_ADDED'),
      (value: any) => {
        return value.length >= 1 && !!value[0].role.id;
      }
    )
});