import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from "@mui/material";

import { serviceCourse } from '@services/course';
import CourseAuthorCard from "./CourseAuthorCard";

const Authors = ({ courseId, creatorId }) => {
  const { t } = useTranslation(["course"]);
  const [authorList, setAuthorList] = useState<Array<any>>([]);

  useEffect(() => {
    if (courseId) {
      serviceCourse.getCourseAuthorsPublic(courseId).then(res => {
        if (res.status === 200) {
          // console.log(res.data)
          setAuthorList(res.data);
        }
      })
    }
  }, [courseId]);

  return (
    <Box id="authors">
      <Typography fontSize="32px" fontWeight={700}>
        {t("course:AUTHOR_COURSE")}
      </Typography>
      {authorList.map((author) => {
        return (
          <CourseAuthorCard
            key={author?.userId}
            authorData={author}
            isCreator={creatorId === author?.userId}
          />
        );
      })}
    </Box>
  )
}

export default Authors;