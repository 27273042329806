import { useState, useEffect } from 'react'
import AvatarPicture from './AvatarPicture';
import { serviceUser } from '@services/user';
import { SxProps } from '@mui/material';

interface AvatarViaUserIdProps {
  userId: string;
  sxProps: SxProps;
  variant?: "square" | "rounded" | "circular";
}

const AvatarViaUserId = ({ userId, sxProps, variant = 'circular' }: AvatarViaUserIdProps) => {
  const [photoId, setPhotoId] = useState<string | null>(null);

  useEffect(() => {
    async function formatProfilePhoto() {
      const response = userId ? await serviceUser.getUserInfoById(userId) : null;
      if (response) {
        setPhotoId(response.data.profilePhotoId)
      } else {
        setPhotoId(null);
      }
    }

    formatProfilePhoto();
  }, [userId]);

  return (
    <AvatarPicture id={photoId} sxProps={sxProps} variant={variant} />
  )
}

export default AvatarViaUserId;