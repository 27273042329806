import Helper from "@assets/images/home/123.png";
import CustomModal from "@components/Modal";
import { Button, Typography } from "@mui/material";
import React from "react";
import s from "./ConnectionHelper.module.scss";
interface CustomModalProps {
  onClose: () => void;
  open: boolean;
}
const ConnectionHelper: React.FC<CustomModalProps> = ({ onClose, open }) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <CustomModal open={open} handleToggleModal={onClose} width={503}>
      <div className={s.wrapper}>
        <div className={s.icon}>
          <img src={Helper} alt="" />
        </div>
        <div className={s.content}>
          <div className={s.title}>Скоро позвоним</div>
          <div className={s.text}>
            Мы работаем с 9:00 до 18:00 по Астане и связываемся в течение одного
            дня
          </div>
          <Button onClick={handleClose} variant="contained">
            <Typography>Хорошо, спасибо</Typography>
          </Button>
        </div>
        <div className={s.bottom}>
          <div className={s.helper}>Или же свяжитесь с нами:</div>
          <div className={s.info}>+7 (7172) 47-25-25 info@platonus.kz</div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ConnectionHelper;
