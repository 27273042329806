import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Formik } from "formik";

import CustomModal from "@components/Modal";
import CustomButton from "@ui/Button";
import FormikInput from "@ui/formik/Input";
import { IMaterial } from "@interfaces/index";
import { validationSchemaForLink } from "@pages/myCourses/Educator/TabList/Content/Theme/validation";

interface Props {
  open: boolean;
  material: IMaterial;
  updateMaterialList: (material: IMaterial) => void;
  handleToggleEditLinkModal: (modalState: boolean) => void;
}

const EditLinkModal = ({
  open,
  material,
  updateMaterialList,
  handleToggleEditLinkModal
}: Props) => {
  const { t } = useTranslation(["common", "course", "enum"]);
  const { common } = useTheme().palette;

  return (
    <CustomModal
      open={open}
      handleToggleModal={handleToggleEditLinkModal}
      width={515}
      borderColor={common.primaryColor}
    >
      <>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "28px",
            lineHeight: "36px",
            mb: 3,
          }}
        >
          {t("course:content.EDIT_RESOURSE")}
        </Typography>
        <Formik
          initialValues={material}
          enableReinitialize
          validationSchema={validationSchemaForLink(t)}
          onSubmit={(values: IMaterial) => updateMaterialList(values)}
        >
          {({ submitForm }) => (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FormikInput name="name" label={t('course:content:TITLE_LINK')} />
              <FormikInput name="url" label={t('enum:LINK')} placeholder='https://' />
              <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                <CustomButton
                  onClick={() => handleToggleEditLinkModal(false)}
                  width="209px"
                  height="40px"
                  borderRadius="8px"
                  backgroundColor="#F6F6F6"
                  fontColor={common.primaryColor}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {t("common:NO")}
                </CustomButton>
                <CustomButton
                  backgroundColor={common.primaryColor}
                  fontColor={common.fontWhite}
                  width="209px"
                  borderRadius="8px"
                  height="40px"
                  onClick={submitForm}
                  sx={{
                    alignItems: "center",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >{t("course:content.SAVE_LINK")}</CustomButton>
              </Box>
            </Box>
          )}
        </Formik>
      </>
    </CustomModal>
  );
}

export default EditLinkModal;