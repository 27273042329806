import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { Tab, Tabs, } from '@mui/material';
import { TabContext, TabPanel } from "@mui/lab";
import { AlertColor } from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";

import UnhiddenHints from "@components/UnhiddenHints";
import { CommonSetting } from "./CommonSetting";
import { TaskGradesSetting } from "./TaskGradesSetting";
import { ICourse, TabOptionsSettings } from "@interfaces/index";

interface Props {
  courseInfo: ICourse;
  setCourseInfo: (course: ICourse) => void;
  setMessageOpen: (isOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  setMessage: (message: string) => void;
}

export const SettingPage = ({ courseInfo, setCourseInfo, setMessageOpen, setMessageType, setMessage }: Props) => {
  const { t } = useTranslation(["course", "generalSetting"]);
  const { common } = useTheme().palette;
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get('settingsTab');
  const [typeProfile, setTypeProfile] = useState<TabOptionsSettings>(TabOptionsSettings.COMMON);

  const handleTabChange = (tab: string) => {
    setTypeProfile(tab as TabOptionsSettings);
    setSearchParams({ settingsTab: tab });
  };

  useEffect(() => {
    if (currentTab) {
      setTypeProfile(currentTab as TabOptionsSettings);
    } else {
      setTypeProfile(TabOptionsSettings.COMMON);
    }
  }, [currentTab]);

  return (
    <>
      {!courseInfo.courseSetting.langId &&
        <UnhiddenHints
          text={t('course:hints.SETTINGS_SECTION_HINT')}
        />
      }
      <Box
        sx={{
          display: "block",
          width: "100%",
          background: common.fontWhite,
          border: `1px solid ${common.borderSecondary}`,
          borderRadius: 3,
          mt: !courseInfo.courseSetting.langId ? 2 : 0
        }}
      >
        <TabContext value={typeProfile}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                padding: "10px 0 0 10px",
              }}
            >
              <Tabs
                onChange={(_, value: string) => handleTabChange(value)}
                indicatorColor="secondary"
                value={typeProfile}
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: "#1A141F",
                  },
                  "& .MuiTabs-indicator": {
                    background: common.borderPrimary,
                  },
                }}
              >

                <Tab
                  label={t('generalSetting:GENERAL')}
                  value={"common"}
                  sx={{ textTransform: "none", fontSize: "18px" }}
                />
                <Tab
                  label={t('course:TASK_AND_ASSESSMENTS')}
                  value={"taskGrades"}
                  sx={{ textTransform: "none", fontSize: "18px" }}
                />
              </Tabs>
            </Box>
          </Box>
          <TabPanel value={typeProfile}>
            {typeProfile === TabOptionsSettings.COMMON &&
              <CommonSetting
                courseInfo={courseInfo}
                setCourseInfo={setCourseInfo}
                setMessageOpen={setMessageOpen}
                setMessageType={setMessageType}
                setMessage={setMessage}
              />}
            {typeProfile === TabOptionsSettings.TASK_GRADES &&
              <TaskGradesSetting
                courseInfo={courseInfo}
                setCourseInfo={setCourseInfo}
                setMessageOpen={setMessageOpen}
                setMessageType={setMessageType}
                setMessage={setMessage}
              />}
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}