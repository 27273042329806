import Message from '@components/Message';
import { AlertColor } from '@mui/material/Alert';
import { useEffect, useMemo, useState } from 'react';

interface IShowAlert {
    alertText: string,
    alertColor: AlertColor,
}

const useMessage = () => {
    const [open, setOpen] = useState(false)
    const [alertText, setAlertText] = useState("")
    const [alertColor, setAlertColor] = useState<undefined | AlertColor>()

    const show = () => setOpen(true)
    const hide = () => setOpen(false)

    useEffect(() => {
        if (!open) {
            setAlertText("")
            setAlertColor(undefined)
        }
        if(open) {
            setTimeout(() => hide(), 5000)
        }
    }, [open])

    const showAlert = ({
        alertColor, alertText
    }: IShowAlert) => {
        setAlertText(alertText)
        setAlertColor(alertColor)
        show()
    }

    const MessageComponent = useMemo(() => {
        return !alertColor || !alertText || !open ? null :
            <Message
                type={alertColor}
                message={alertText}
                setMessageOpen={setOpen}
            />
    }, [alertColor, alertText, open])

    return {
        MessageComponent, showAlert
    }
}

export default useMessage