import * as yup from 'yup';

export const validationSchema = (t: any) => yup.object({
  id: yup.number().nullable(),
  nameKz: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  nameRu: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  nameEn: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  isDisabled: yup.boolean(),
  // serverAddress: yup.string().required(t('common:validation.MANDATORY_FIELD')),
  branchTypeId: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  bin: yup.string().required(t('common:validation.MANDATORY_FIELD')),
  availableCourseTypes: yup.array().of(yup.string() || yup.number).min(1, t('common:validation.MANDATORY_FIELD')).required(),
  // hasOwnDomain: yup.boolean()
});