import { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import CustomButton from '@ui/Button';
import { formatDateWithTimezone } from '@utils/functions';
import CustomModal from '@components/Modal';
import { useTranslation } from "react-i18next";
import CustomInput from '@ui/Input';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { serviceTask } from '@services/task';
import { taskCommentInitialDto } from './models';
import { useSelector } from 'react-redux';
import { StateSchema } from '@store/StateSchema';

const CorrectionModal = ({ progressId, setInitialData, deadlineDate, initialData, courseMeUserId, open, handleToggleModal, setRefreshComment }) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course"]);
  const [comment, setComment] = useState<string>("");
  const currentDate = formatDateWithTimezone(new Date());
  const profile = useSelector((store: StateSchema) => store.account.profileInfo);
  const handleInputChange = (event: any) => {
    setComment(event.target.value)
  };

  const sendToCorrection = () => {
    const bodyData = taskCommentInitialDto;
    bodyData.progressId = progressId;
    bodyData.comment = comment;
    bodyData.commentatorMember = { id: courseMeUserId };
    //TODO: bodyData.id=profile.pltaId
    serviceTask.createTaskComment(bodyData).then(res => {
      if (res.status === 200) {
        setInitialData(initialData => ({ ...initialData, status: "ON_CORRECTION" }));
        setRefreshComment(prev => !prev)
      }
    })
    handleToggleModal()
  }

  return (
    <CustomModal open={open} handleToggleModal={handleToggleModal} width={515}>
      <>
        <>
          <Typography sx={{ textAlign: 'center', fontWeight: 500, fontSize: '20px' }}>Отправка на доработку</Typography>
          <CustomInput
            name='comment'
            value={comment}
            handleChange={handleInputChange}
            placeholder={t('course:WRITE_A_COMMENT')}
            label={`${t('common:COMMENTS')}:`}
            multiline={true}
            rows={4}
            showSymbolCount
            maxChar={500}
            minChar={0}
          />
          {
            <Tooltip
              TransitionComponent={Zoom}
              disableHoverListener={!!(comment && currentDate && deadlineDate && currentDate < deadlineDate)}
              componentsProps={{
                tooltip: {
                  sx: {
                    background: "#1A1F36",
                    display: "flex",
                    borderRadius: "8px",
                    width: "254px"
                  }
                }
              }}
              title={!comment
                ? t('course:task.FILL_IN_COMMENT_TO_SUBMIT_TASK_FOR_REVIEW')
                : (initialData.hasDeadlineDate
                  ? t('course:messages.NEED_TO_CHANGE_DEADLINE_FOR_COMPLETION')
                  : '')}
              placement="bottom"
            >
              <Box>
                <CustomButton
                  backgroundColor={common.primaryColor}
                  fontColor={common.fontWhite}
                  width="100%"
                  borderRadius="8px"
                  height="40px"
                  onClick={() => sendToCorrection()}
                  disabled={!comment || !!(currentDate && deadlineDate && currentDate >= deadlineDate)}
                >
                  {t('course:SUBMIT_FOR_IMPROVEMENT')}
                </CustomButton>
              </Box>
            </Tooltip>
          }
        </>
      </>
    </CustomModal>
  )
}

export default CorrectionModal;