import { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Formik, Form as FormikForm } from "formik";
import { AlertColor, Box, useTheme } from '@mui/material';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';

import { serviceCourse } from '@services/course';
import { serviceMember } from '@services/member';
import FormikAutocomplete from '@ui/formik/Autocomplete';
import FormikCheckbox from '@ui/formik/Checkbox';
import DateTimePicker from '@ui/formik/DateTimePicker';
import CustomButton from '@ui/Button';
import { IRecipients, IStream, initialDTO, initialStreamData } from './models';
import { recipientsValidationSchema } from './validation';
import { CourseRole, IMember } from '@interfaces/index';
interface IProps {
  setMessage: (message: string) => void
  setMessageOpen: (isMessageOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void
}

const Recipients = ({ setMessage, setMessageOpen, setMessageType }: IProps) => {
  const [initialData, setInitialData] = useState<IRecipients>(initialDTO);
  const { t } = useTranslation(['common', 'course', 'enum']);
  const { common } = useTheme().palette;
  const [currentStream, setCurrentStream] = useState<IStream | null>(null)
  const [streams, setStreams] = useState<IStream[]>(initialStreamData)
  const { courseId, themeId } = useParams();
  const [tutorOptions, setTutorOptions] = useState<IMember[]>([]);
  const [tutorsLoaded, setTutorsLoaded] = useState<boolean>(false);

  // Все потоки курса
  useEffect(() => {
    if (courseId) {
      serviceCourse.getAllStudyClassesByCourseId(courseId).then((res) => {
        if (res.status === 200) {
          setStreams(res.data);
          // Начальное значение для потока
          setCurrentStream(res.data[0])
        }
      })
    }
  }, [courseId])

  const getDefaultDateOfAccess = useCallback(() => {
    const currentDate = new Date()
    if (currentStream?.startDate) return currentStream?.startDate;
    return currentDate;
  }, [currentStream?.startDate]);

  // Параметры выбранного потока
  useEffect(() => {
    if (themeId && currentStream?.id) {
      serviceCourse.getThemeRecipientsParameters(currentStream.id, themeId).then((res) => {
        if (res.status === 200) {
          setInitialData(prev => (
            {
              ...prev, ...res.data,
              id: res.data ? res.data.id : null,
              isCloseAccess: res.data ? !!res.data.dateOfAccess : false,
              responsibleMembers: res.data ? res.data.responsibleMembers : []
            }
          ))
        }
      })
      serviceMember.getMembersByStream([CourseRole.TUTOR], currentStream?.id).then((res) => {
        if (res.status === 200) {
          setTutorOptions(res.data);
          setTutorsLoaded(true);
        }
      })
    }
  }, [themeId, currentStream?.id]);

  return (
    <Box sx={{ px: 4 }}>
      {streams.length > 0 ? (
        <Formik
          initialValues={initialData}
          enableReinitialize
          validationSchema={recipientsValidationSchema(t)}
          onSubmit={(values) => {
            const classId = streams.find(stream => stream.id === currentStream?.id)?.id
            const responsibleMemberIds = values.responsibleMembers?.length > 0 ? values.responsibleMembers.map(member => member.id) : [];
            const createRecipientParameters = {
              themeId,
              classId,
              dateOfAccess: values.dateOfAccess,
              responsibleMemberIds,
            }
            serviceCourse.changeThemeRecipientsParameters(values.id === null
              ? createRecipientParameters
              : { ...values, responsibleMemberIds }).then((res) => {
                if (res.status === 200) {
                  setMessage(t('common:messages.CHANGES_SAVED'))
                  setMessageType('success')
                  setMessageOpen(true)
                  setTimeout(() => {
                    setMessageOpen(false)
                  }, 3000)
                  setInitialData({ ...values, id: res.data.id })
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }
              })
          }}
        >{({ values, setFieldValue, submitForm, dirty, isValid }) => {
          return (
            <FormikForm>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  py: 2
                }}>
                {tutorOptions.length <= 0 && tutorsLoaded &&
                  <Box sx={{ border: `1px solid ${common.borderSecondary}`, borderLeft: `6px solid rgba(255, 149, 0,1)`, borderRadius: '4px', p: 1, display: 'flex', gap: 1, alignItems: 'center', width: '70%' }}>
                    <Box sx={{ width: '28px', height: '28px', borderRadius: '50%', backgroundColor: 'rgba(255, 149, 0, 0.4)', display: 'flex', flexShrink: 0 }}>
                      <WarningOutlinedIcon sx={{ color: 'rgba(255, 149, 0,1)', width: '20px', height: '20px', display: 'flex', margin: 'auto' }} />
                    </Box>
                    {t('course:hints.THERE_IS_NO_ANY_PARTICIPANT_IN_THE_FLOW')}
                  </Box>
                }
                <Box sx={{ width: '100%', display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {streams.map((stream, index) => {
                    return <Box key={stream.id}
                      onClick={() => setCurrentStream(stream)}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minWidth: '115px',
                        borderRadius: '6px',
                        height: '40px',
                        p: '12px 16px',
                        cursor: 'pointer',
                        background: currentStream?.id === stream.id ? common.primaryColor : 'none',
                        color: currentStream?.id === stream.id ? '#fff' : '#000',
                        '&:hover': {
                          background: common.primaryColor,
                          color: '#fff'
                        }
                      }}>
                      {t('course:EDU_STREAM')} #{index + 1}
                    </Box>
                  })}
                </Box>
                <FormikAutocomplete
                  sx={{ width: '40%' }}
                  name="responsibleMembers"
                  label={t('course:theme.RESPONSIBLE_TEACHER')}
                  multiple
                  valueAsObject
                  selectAll
                  options={tutorOptions}
                  clearOptions
                  hintText={t('course:hints.RESPONSIBLE_MEMBER_HINT')}
                />
                <FormikCheckbox
                  label={t('course:theme.ACCESS_TO_TOPIC_IS_RESTRICTED')}
                  onChange={(_, value) => setFieldValue('dateOfAccess', !value ? null : getDefaultDateOfAccess())}
                  name='isCloseAccess'
                  labelPlacement='end'
                />
                {values.isCloseAccess &&
                  <Box sx={{ width: { lg: '35%', sm: '50%', xs: '100%' } }}>
                    <DateTimePicker
                      label={t('course:theme.ACCESS_OPENING_DATE')}
                      name="dateOfAccess"
                      isRequired
                      pickerType="dateTime"
                      minDate={currentStream?.startDate}
                      maxDate={currentStream?.endDate}
                    />
                  </Box>
                }
                <Box sx={{ display: 'flex', gap: 1, justifyContent: { sm: 'flex-end', xs: 'flex-start' }, mt: '12px' }}>
                  <Box sx={{ border: `1px solid ${common.primaryColor}`, borderRadius: '9px' }}>
                    <CustomButton
                      width="98px"
                      height="38px"
                      backgroundColor={common.fontWhite}
                      borderRadius="8px"
                      fontColor={common.primaryColor}
                      sx={{
                        fontWeight: "500",
                        lineHeight: "20px",
                        alignItems: "center",
                      }}
                      onClick={submitForm}
                      disabled={!(isValid && dirty)}
                    >
                      {t('actions.SAVE')}
                    </CustomButton>
                  </Box>
                </Box>
              </Box>
            </FormikForm>
          )
        }}</Formik>
      ) : (
        <Box sx={{ border: `1px solid ${common.borderSecondary}`, borderLeft: `6px solid rgba(255, 149, 0,1)`, borderRadius: '4px', p: 1, display: 'flex', gap: 1, alignItems: 'center', width: '70%' }}>
          <Box sx={{ width: '28px', height: '28px', borderRadius: '50%', backgroundColor: 'rgba(255, 149, 0, 0.4)', display: 'flex', flexShrink: 0 }}>
            <WarningOutlinedIcon sx={{ color: 'rgba(255, 149, 0,1)', width: '20px', height: '20px', display: 'flex', margin: 'auto' }} />
          </Box>
          {t('course:hints.THERE_IS_NO_ANY_FLOW')}
        </Box>
      )}
    </Box>
  )
}

export default Recipients
