import React from 'react'
import AvatarViaUserId from '@components/AvatarViaUserId';
import {
  Box, Typography, useTheme,
  Table, TableBody, TableCell, TableHead, TableRow,
  Divider,
  Grid,
} from '@mui/material'
import { serviceReports } from '@services/reports';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import { useTranslation } from 'react-i18next';

export const StudentList = () => {
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const { t } = useTranslation(["course", "dictionary", "enum", "user", "common"]);
  const { data: topListenerList } = useQuery({
    queryKey: ["topListenerList"],
    queryFn: () =>
      serviceReports.getTopListenerList(),
  });

  return (
    <Box sx={{ background: common.white, border: `1px solid ${common.borderSecondary}`, borderRadius: "12px", height: "460px", minWidth: "460px" }}>
      <Grid container margin="20px 16px">
        <Grid item xs={9.5}>
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>{t("course:REPORT_LISTENERS")}</Typography>
        </Grid>
        <Grid item xs={2.5}>
          {topListenerList?.data?.length &&
            <Box sx={{
              padding: "5px 12px", width: "130px", "&:hover": {
                background: "#F5F5F5",
                borderRadius: "12px"
              }
            }}>
              <Typography sx={{ color: common.primaryColor, cursor: "pointer" }} onClick={() => navigate("/reports/student")}>{t("course:ALL_LIST")}</Typography>
            </Box>}
        </Grid>
      </Grid>
      <Divider sx={{ margin: "16px 0px" }} />
      {topListenerList?.data?.length > 0 ? <Table>
        <TableHead>
          <TableRow >
            <Grid container>
              <Grid item xs={9.5}>
                <TableCell sx={{ fontSize: 14, color: "#6D737A", borderBottom: "none" }}>{t("enum:LISTENER")}</TableCell>
              </Grid>
              <Grid item xs={2.5}>
                <TableCell sx={{ fontSize: 14, color: "#6D737A", borderBottom: "none" }}>{t("enum:COURSE")}</TableCell>
              </Grid>
            </Grid>
          </TableRow>
        </TableHead>
        <TableBody>
          {topListenerList?.data?.map((listener) => {
            return (
              <TableRow key={listener.name}>
                <Grid container>
                  <Grid item xs={9.5}>
                    <TableCell sx={{ fontSize: 16, borderBottom: "none" }} size="small">
                      <Box display="flex" gap={0.5} alignItems="center">
                        <AvatarViaUserId
                          userId={listener?.id || ""}
                          sxProps={{ width: "35px", height: "35px" }}
                        />
                        {listener.name}
                      </Box>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2.5}>
                    <TableCell sx={{ fontSize: 16, borderBottom: "none" }} size="small">{listener.coursesCount}</TableCell>
                  </Grid>
                </Grid>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
        :
        <Box margin="18% 35%">
          <Box sx={{ background: "#F9FAFB", padding: "8px 12px", borderRadius: "50px", width: "80px", height: "80px", marginLeft: "45px", border: "1px solid #E8E8E8" }} alignItems="center">
            <DataSaverOffOutlinedIcon sx={{ mr: 0.8, color: "#6D737A", margin: "20px 15px" }} />
          </Box>
          <Typography sx={{ color: "#6D737A", marginTop: "16px" }}>{t("course:NO_DATA_AVAILABLE")}</Typography>
        </Box>
      }
    </Box>
  )
}
