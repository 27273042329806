import React, { Dispatch, SetStateAction, useState } from "react";
import {
  AlertColor,
  Box,
  Popover,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import CustomSearch from "@ui/Search";
import CustomButton from "@ui/Button";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { AboutScoreTable } from "./AboutScoreTable";
import { ScoreTable } from "./ScoreTable";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { serviceCourse } from "@services/course";
import { decodeContentDispositionFilename } from "@utils/functions";
import { getFlowId } from "@store/journal/journalSelector";
import { useSelector } from "react-redux";
import { HintComponent, EmptyComponent } from "@components/common";
import { ReactComponent as EmptyMeasurementSvg } from "@assets/svg_files/emptyComponent/emptyMeasurement.svg";
import { ReactComponent as WarningHintComponent } from "@assets/svg_files/hintComponent/warning.svg";
import { IStudyClass } from "@interfaces/index";

interface Props {
  setMessage: (message: string) => void;
  setMessageOpen: (open: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  tasks: any[];
  journalList: any;
  setJournalList: Dispatch<any>;
}

export const ScorePage = ({
  setMessage,
  setMessageOpen,
  setMessageType,
  tasks,
  journalList,
  setJournalList,

}: Props) => {
  const { t } = useTranslation(["common"]);
  const { common } = useTheme().palette;
  const { courseId } = useParams();
  const flowId = useSelector(getFlowId);
  const [searchFIO, setSearchFIO] = useState<string | null>(null);
  const searchByName = (searchValue: string) => {
    setSearchFIO(searchValue);
  };

  const handleExporToExcel = () => {
    if (flowId) {
      serviceCourse.exportJournalToExcel(flowId).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${decodeContentDispositionFilename(
            res.headers["content-disposition"]
          )}`
        );
        document.body.appendChild(link);
        link.click();
      });
    }
  };


  return (
    <Box>
      <Box
        gap={1.5}
        sx={{ display: "flex", width: "100%", marginBottom: "20px" }}
      >
        <CustomSearch
          placeholder={t("common:placeholders.SEARCH_BY_LISTENER_FULL_NAME")}
          fullWidth
          onChange={searchByName}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          <CustomButton
            height="40px"
            backgroundColor={common.primaryColor}
            borderRadius="8px"
            width="200px"
            onClick={handleExporToExcel}
            fontColor={common.fontWhite}
            sx={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              alignItems: "center",
              color: "#FFFFFF",
            }}
          >
            {t("common:actions.EXPORT_TO_EXCEL")}
            <DescriptionOutlinedIcon />
          </CustomButton>
          <AboutScoreTable />
        </Box>
      </Box>

      <ScoreTable
        journalList={journalList}
        setJournalList={setJournalList}
        tasks={tasks}
        searchFIO={searchFIO}
        setMessage={setMessage}
        setMessageOpen={setMessageOpen}
        setMessageType={setMessageType}
      />
    </Box>
  );
};
