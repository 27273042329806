import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';

import AvatarPicture from '@components/AvatarPicture';
import AuthorInfoModal from './AuthorInfoModal';
import { removeHtml } from '@utils/functions';

const CourseTeacherCard = ({ authorData, isCreator }: { authorData: any, isCreator: boolean }) => {
  const { t } = useTranslation(["common"]);
  const { common } = useTheme().palette;
  const [openTeacherModal, setOpenTeacherModal] = useState<boolean>(false);

  return (
    <Box sx={{ background: "#fff", margin: "10px 0px", borderRadius: "10px" }}>
      <Box padding="24px">
        <Box display="flex" justifyContent="space-between" marginBottom="12px">
          <Box display="flex" gap={2} alignItems="center">
            <AvatarPicture userId={authorData?.userId} sxProps={{ width: "100px", height: "100px" }} />
            <Box>
              <Typography fontSize="16px" fontWeight={600}>{authorData?.fullName}</Typography>
              {isCreator &&
                <Box sx={{ width: "fit-content", background: "#385FE8", color: "#fff", fontSize: "12px", padding: "2px 10px", borderRadius: "15px" }}>
                  {t('common:CREATOR')}
                </Box>
              }
              <Box sx={{ padding: "10px", background: "#F4F0FC", cursor: "pointer", borderRadius: "10px", marginTop: "14px", display: "flex", gap: 1, alignItems: "center", opacity: authorData?.userInfo || authorData?.achievements.length > 0 ? 1 : 0.3 }}
                onClick={() => (authorData?.userInfo || authorData?.achievements.length > 0) && setOpenTeacherModal(!openTeacherModal)}>
                <BadgeOutlinedIcon sx={{ color: common.primaryColor }} />
                <Typography color={common.primaryColor}>{t("common:actions.SHOW_PROFILE_CERTIFICATES")}</Typography>
              </Box>
            </Box>
          </Box>
          {(authorData?.instagram || authorData?.youtube || authorData?.facebook) &&
            <Box display="flex" gap={1}>
              {authorData?.youtube && <YouTubeIcon sx={{ color: "#FF0000", fontSize: "28px", cursor: "pointer" }} onClick={() => { window.open(`${authorData?.youtube}`, "_blank") }} />}
              {authorData?.instagram && <InstagramIcon sx={{ color: "#AE38B4", fontSize: "28px", cursor: "pointer" }} onClick={() => { window.open(`${authorData?.instagram}`, "_blank") }} />}
              {authorData?.facebook && <FacebookIcon sx={{ color: "#337FFF", fontSize: "28px", cursor: "pointer" }} onClick={() => { window.open(`${authorData?.facebook}`, "_blank") }} />}
            </Box>
          }
        </Box>
        {authorData?.userInfo &&
          <Typography sx={{ display: 'inline', fontSize: "16px", color: "#8E8E93" }}>
            {removeHtml(authorData?.userInfo || '').length > 96 ? `${removeHtml(authorData?.userInfo || '').substring(0, 96)}...` : removeHtml(authorData?.userInfo || '')}
          </Typography>
        }
      </Box>
      {openTeacherModal && <AuthorInfoModal isCreator={isCreator} authorInfo={authorData} open={openTeacherModal} handleToggleModal={() => setOpenTeacherModal(!openTeacherModal)} />}
    </Box>

  )
}

export default CourseTeacherCard
