import React from "react";

interface Props {
  previewCanvasRef: React.RefObject<HTMLCanvasElement>;
}
const CanvasHelper = (props: Props) => {
  const { previewCanvasRef } = props;
  return (
    <div>
      <canvas
        ref={previewCanvasRef}
        style={{
          objectFit: "contain",
          width: "100%",
          height: "auto",
        }}
      />
    </div>
  );
};

export default CanvasHelper;
