import axios from "@utils/axios";

export const serviceReports = {
  getTopEducatorsList: () => axios.get("/api/course/reports/educators-info"),
  getTopListenerList: () => axios.get("/api/course/reports/listeners-info"),
  getTopCourseList: (body: any) =>
    axios.post("/api/course/reports/popular-courses", body),
  getTotalInfoCourse: (body: any) =>
    axios.post("/api/course/reports/courses/total-info", body),
  getAllListenerList: (body: any) =>
    axios.post("/api/course/reports/listeners/full-info", body),
  getAllCourseList: (body: any) =>
    axios.post("/api/course/reports/courses/detailed", body),
  getAllEducaotrList: (body: any) =>
    axios.post("/api/course/reports/educators/full-info", body),
};
