export interface DTO {
  id: number;
  code: string;
  maxFileSizeCanUploadPerTheme: number | null;
  maxFileSizeCanTutorUploadPerTask: number | null;
  maxPromoVideoSize: number | null;
  maxThemeVideoSize: number | null;
  accountAttendance?: boolean;
  accountAttendanceInControlWork?: boolean;
  availablePassesPerCourse?: number | null;
  branchId: number
  createdAt: string | null;
  useTimetable?: boolean;
  provideExpertiseBeforePublish: boolean;
  useProctoring?: boolean;
  rule?: {
    faceRec: boolean,
    recording: boolean,
    audio: boolean,
    authorization: boolean,
    clipboard: boolean,
    screen: boolean,
    dualScreen: boolean,
    thirdpartyApps: boolean,
    capturingTabs: boolean,
    otherTabsBlock: boolean
  } | null
  violation?: {
    deviceAccessMicrophone?: string | undefined
    deviceAccessScreen?: string | undefined
    deviceAccessWebcam?: string | undefined
    faceRecLookAway?: string | undefined
    faceRecNoStudent?: string | undefined
    faceRecNotRecognized?: string | undefined
    faceRecTooManyPeople?: string | undefined
    audio?: string | undefined
    clipboardCopy?: string | undefined
    clipboardPaste?: string | undefined
    internetConnectionOffline?: string | undefined
    screenBrowserFocus?: string | undefined
    tabsForbiddenTab?: string | undefined
    trustLevelLow: number
    trustLevelHigh: number
  } | null
}

export const initialDtoForEDUCourse: DTO = {
  id: 0,
  code: '',
  maxFileSizeCanUploadPerTheme: 1024,
  maxFileSizeCanTutorUploadPerTask: 1024,
  maxPromoVideoSize: 716800,
  maxThemeVideoSize: 716800,
  accountAttendance: false,
  accountAttendanceInControlWork: false,
  availablePassesPerCourse: 20,
  branchId: 0,
  createdAt: null,
  useTimetable: false,
  provideExpertiseBeforePublish: false,
  useProctoring: false,
  violation: null
};

export const initialDtoForFreeCourse: DTO = {
  id: 0,
  code: '',
  provideExpertiseBeforePublish: false,
  maxFileSizeCanUploadPerTheme: 1024,
  maxFileSizeCanTutorUploadPerTask: 1024,
  maxPromoVideoSize: 716800,
  maxThemeVideoSize: 716800,
  createdAt: null,
  branchId: 0
};

export interface COMMON_OPTION {
  id?: string;
  code: string;
  authUseGoogle: boolean;
  authUsePhone: boolean;
};

export const initialDto: COMMON_OPTION = {
  code: "COMMON_OPTION",
  authUseGoogle: false,
  authUsePhone: false,
};

export interface IDictType {
  id?: number;
  externalId?: string | null;
  nameEn: string;
  shortNameEn?: string;
  nameRu: string;
  shortNameRu?: string;
  nameKz: string;
  shortNameKz?: string;
  isDisabled: boolean;
}
export const initialDTODictType = {
  nameEn: "",
  shortNameEn: '',
  nameKz: "",
  shortNameKz: '',
  nameRu: "",
  shortNameRu: '',
  externalId: null,
  isDisabled: false
}
export interface IRole {
  code: string;
  id: number;
  nameEn: string;
  nameRu: string;
  nameKz: string;
  permissionCodes: string[];
}