/* tslint:disable */
/* eslint-disable */
/**
 * course service
 * <b>use example:</b> http://{host}:4044/course/{this service rest endpoints}
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CourseTaskQuiz } from "./course-task-quiz";
import { QuizQuestionAnswer } from "./quiz-question-answer";
/**
 * Вопрос
 * @export
 * @interface QuizQuestion
 */
export interface QuizQuestion {
  /**
   *
   * @type {string}
   * @memberof QuizQuestion
   */
  id?: string;
  /**
   *
   * @type {CourseTaskQuiz}
   * @memberof QuizQuestion
   */
  // quiz: CourseTaskQuiz;
  quizId: string;
  /**
   *
   * @type {QuizQuestion}
   * @memberof QuizQuestion
   */
  parent?: QuizQuestion;
  /**
   * Контент вопроса
   * @type {string}
   * @memberof QuizQuestion
   */
  content: string;
  /**
   * Тип вопроса
   * @type {string}
   * @memberof QuizQuestion
   */
  type: QuizQuestionTypeEnum;
  /**
   * Балл до 100
   * @type {number}
   * @memberof QuizQuestion
   */
  point?: number;
  /**
   * Варианты ответа текущего вопроса
   * @type {Array<QuizQuestionAnswer>}
   * @memberof QuizQuestion
   */
  answers: Array<QuizQuestionAnswer>;
  /**
   * Получения дочерних вопросов (если тип MAPPING и parent NULL)
   * @type {Array<QuizQuestion>}
   * @memberof QuizQuestion
   */
  children: Array<QuizQuestion>;
}

/**
 * @export
 * @enum {string}
 */
export enum QuizQuestionTypeEnum {
  ONECORRECTANSWER = "ONE_CORRECT_ANSWER",
  MULTIPLECORRECTANSWERS = "MULTIPLE_CORRECT_ANSWERS",
  MAPPING = "MAPPING",
  TRUEORFALSE = "TRUE_OR_FALSE",
  SEQUENCE = "SEQUENCE",
}
