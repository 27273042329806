import { useState, useEffect } from 'react'
import { Box, Typography, useTheme, TextField, InputAdornment, TableBody } from '@mui/material';
import { useTranslation } from "react-i18next";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import { serviceRole } from '@services/role';
import { IRole } from './models';
import { TablePagination } from "@mui/material";
import useLocalPagination from "@hooks/useLocalPagination";
import { useParams } from "react-router-dom";
import { capitalize } from 'lodash';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import Breadcrumbs from '@components/Breadcrumbs';

const Roles = () => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["sideMenu", "common", "course", "user", "enum"]);
  const { id } = useParams()
  const lng = localStorage.getItem("i18nextLng") || "kz";
  const [initialData, setInitialData] = useState<IRole[]>()
  const [totalElements, setTotalElements] = useState<number>(0)
  const { setBreadcrumbs } = useBreadcrumbs();
  const [filter, setFilter] = useState<{ search: string, branchId: number }>({
    search: '', branchId: Number(id)
  })
  const styles = {
    color: common.primaryColor,
    p: 2,
    fontSize: '16px',
    lineHeight: '24px'
  }
  const searchByName = (searchValue: string) => {
    setFilter({ ...filter, search: searchValue });
    if (searchValue !== '') handleChangePage(null, 0);
  };

  const {
    rowsPerPage, page,
    handleChangePage, handleChangeRowsPerPage,
  } = useLocalPagination<IRole>()

  useEffect(() => {
    serviceRole.getAllRoles({
      pageNumber: page,
      size: rowsPerPage,
      filter
    }).then((res) => {
      if (res.status === 200) {
        setInitialData(res.data.dtoList)
        setTotalElements(res.data.totalElements)
      }
    })
  }, [page, rowsPerPage, filter])

  useEffect(() => {
    setBreadcrumbs("roles", [{
      level: 1,
      page: 'settings',
      label: "course:SETTINGS",
      path: '/settings',
      tabNum: null
    }, {
      level: 2,
      page: 'roles',
      label: "user:ROLES",
      path: null,
      tabNum: null
    }]);
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Box sx={{ display: "flex", alignItems: 'center', mb: '24px' }}>
        <Breadcrumbs id="roles" />
      </Box>
      <Typography sx={{ fontWeight: "500", fontSize: "36px", lineHeight: "44px", mb: "27px", mt: '20px' }}>
        {t('user:ROLES')}
      </Typography>
      <Box sx={{
        border: `1px solid ${common.borderSecondary}`, backgroundColor: common.fontWhite, borderRadius: '24px', p: '24px', height: '546px'
      }}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          onChange={(e) => searchByName(e.target.value)}
          size="small"
          color="primary"
          sx={{
            width: { xs: '100%', md: '70%' }, mr: "24px", justifyContent: "flex-start", '& label.Mui-focused': { color: common.primaryColor },
            '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': { borderColor: common.primaryColor },
              '&.Mui-focused fieldset': { borderColor: common.primaryColor }
            }
          }}
          placeholder={t('common:placeholders.SEARCH_BY_NAME')}
          name="searchValue"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box sx={{ backgroundColor: common.primaryColor, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px', mr: '-14px', borderRadius: '6px' }}>
                  <SearchIcon sx={{ color: '#FFFFFF', width: '26px', height: '26px' }} />
                </Box>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ mt: 4, borderTop: `2px solid ${common.borderSecondary}`, borderBottom: `2px solid ${common.borderSecondary}` }}>
          <Typography sx={{ ...styles, color: 'black', fontWeight: '600' }}>
            {t('course:task.NAME')}
          </Typography>
        </Box>
        <TableContainer component={Paper} sx={{
          fontSize: '16px', lineHeight: '24px'
        }}>
          <Table aria-label="My Table">
            <TableBody>
              {initialData?.map(role => {
                return (
                  <TableRow key={role.id}>
                    <TableCell>
                      <Typography>
                        {role[`name${capitalize(lng)}`]}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t('enum:LINES_PER_PAGE')}
              labelDisplayedRows={
                ({ from, to, count }) => t('enum:TABLE_PAGES', { from, to, count })
              }
            />
          </Box>
        </TableContainer>
      </Box >
    </>

  )
}

export default Roles
