import { Box, Divider, Typography, useTheme } from '@mui/material';

import { getFileType } from '@utils/functions';
import "./styles.scss"
import CustomModal from '@components/Modal';
import PdfViewer from '@components/PdfViewer';
import ImageViewer from '@components/ImageViewer';
import AudioPlayer from '@components/AudioPlayer';
import TextReader from '@ui/TextReader';

import "./styles.scss";

interface Props {
  openModal: boolean;
  handleToggleModal: (modalState: boolean) => void;
  file?: any;
}

const FileViewerModal = ({ openModal, handleToggleModal, file }: Props) => {
  const { common } = useTheme().palette;

  return (
    <CustomModal
      open={openModal}
      handleToggleModal={handleToggleModal}
      bgColor={getFileType(file?.name) === 'audio' ? 'transparent' : undefined}
      borderColor={getFileType(file?.name) === 'audio' ? 'transparent' : common.primaryColor}
      showCloseIcon
    >
      <Box>
        {getFileType(file?.name) === 'image' &&
          <ImageViewer fileId={file.fileId} />
        }
        {file?.resourceType === "TEXT" &&
          <Box sx={{ backgroundColor: common.white, width: '100%' }}>
            <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>
              {file?.name}
            </Typography>
            <Divider sx={{ margin: "5px 0 20px 0" }} />
            <TextReader htmlContent={file?.content} />
          </Box>
        }
        {getFileType(file?.name) === 'pdf' &&
          <PdfViewer fileId={file.fileId} />
        }
        {getFileType(file?.name) === 'audio' &&
          <AudioPlayer fileId={file.fileId} />
        }
      </Box>
    </CustomModal>
  )
}

export default FileViewerModal;