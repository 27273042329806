import { EventType, IEventCalendar } from "@interfaces/eventCalendar";

export const eventInitialDto: IEventCalendar = {
  id: null,
  title: null,
  type: EventType.GENERAL,
  startDate: null,
  endDate: null,
  description: null,
  userId: null,
  teacherId: null,
  courseId: null,
  allDay: false,
  createdAt: null,
  updatedAt: null,
  authorId: null,
  deleted: false
}