import { useTranslation } from "react-i18next";
import { Field, useField } from "formik";
import { Select as FormikSelect } from "formik-mui";
import { useTheme, Box, MenuItem, Typography } from "@mui/material";
import { get, capitalize, isEmpty } from 'lodash';

interface Props {
  label?: string;
  name: string;
  size?: string;
  disabled?: boolean;
  options?: Array<any>;
  isRequired?: boolean;
  onChange?: any;
  multiple?: boolean;
  placeholder?: string;
}

const Select: React.FC<Props> = ({
  label,
  name,
  size = "small",
  disabled = false,
  options,
  isRequired = false,
  onChange,
  multiple = false,
  placeholder
}) => {
  const { t } = useTranslation(['enum', 'common']);
  const { common } = useTheme().palette;
  const [field] = useField(name);
  const lng = localStorage.getItem("i18nextLng") || "kz";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%"
      }}
    >
      <Typography sx={{ display: 'flex' }}>{label} {isRequired && <span style={{ color: common.errorColor }}>*</span>}</Typography>
      <Field
        component={FormikSelect}
        name={name}
        value={field.value || ""}
        size={size}
        disabled={disabled}
        onChange={onChange || field.onChange}
        inputProps={{ name: name, id: 'select-multiple-native' }}
        multiple={multiple}
        sx={
          disabled
            ? { background: common.inputDisabledBg, color: common.fontPrimary }
            : null
        }
        renderValue={isEmpty(field.value) && typeof field.value !== 'number' ? () => <span style={{ color: "lightgray" }}>{placeholder || t('common:placeholders.CHOOSE_ITEM')}</span> : undefined}
        displayEmpty
        MenuProps={{ PaperProps: { sx: { maxHeight: '300px' } } }}
      >
        {options?.map((item: any) => {
          if (typeof item === 'string')
            return (
              <MenuItem key={item} value={item}>
                {t(item)}
              </MenuItem>
            );

          return (
            <MenuItem key={item.id} value={item.id}>
              {item.name || t(item.text) || get(item, `name${capitalize(lng)}`) || item.userFullName}
            </MenuItem>
          );
        })}
      </Field>
    </Box >
  );
};

export default Select;
