/* eslint-disable react/jsx-pascal-case */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { AlertColor } from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { capitalize, get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import LoadingSpinner from "@components/LoadingSpinner";
import Message from "@components/Message";
import UnhiddenHints from "@components/UnhiddenHints";
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import { DictionaryCode } from "@interfaces/index";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { serviceDict } from "@services/dictionary";
import CustomButton from "@ui/Button";
import {
  MaterialReactTable,
  MRT_PaginationState,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
} from "material-react-table";
import Form from "./Form";
import { ICategory, IFilter, initialFilter } from "./models";

const List = () => {
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const { t } = useTranslation(["dictionary", "common", "enum"]);
  const [dropdownCategorylist, setDropdownCategoryList] = useState<ICategory[]>(
    []
  );
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const [type, setType] = useState<string>("category");
  const [typeInForm, setTypeInForm] = useState<string>(type);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<IFilter>(initialFilter);
  const [message, setMessage] = useState<string>("");
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [categoryId, setCategoryId] = useState<string | number | null>(null);
  const lng = localStorage.getItem("i18nextLng") || "kz";
  const [validate, setValidate] = useState(0);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });
  useEffect(() => {
    serviceDict.getDictListForDropdown(DictionaryCode.CATEGORY).then((res) => {
      if (res.status === 200) setDropdownCategoryList(res.data);
    });
  }, [type, typeInForm]);

  const toggleDrawer =
    (openState: boolean, id?: number) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      )
        return;
      setCategoryId(id ? id : null);
      setDrawerOpen(openState);
    };

  const DrawerComponent = (
    <Box sx={{ width: { xs: 400, l: 600, xl: 784 } }}>
      <Form
        categoryId={categoryId}
        setMessage={setMessage}
        setMessageOpen={setMessageOpen}
        setMessageType={setMessageType}
        typeInForm={typeInForm}
        setTypeInForm={setTypeInForm}
        dropdownCategorylist={dropdownCategorylist}
        setDrawerOpen={setDrawerOpen}
        setValidate={setValidate}
      />
    </Box>
  );

  const searchByName = (searchValue: string) => {
    const new_filter = { ...filter, search: searchValue };
    setFilter(new_filter);
  };

  const searchByActiveness = (checked: boolean) => {
    const new_filter = { ...filter, isDisabled: checked };
    setFilter(new_filter);
  };

  const deleteCategory = async () => {
    try {
      try {
        const res = await serviceDict.deleteDictionary(
          DictionaryCode.CATEGORY,
          categoryId
        );
        if (res.status === 200) {
          setMessageOpen(true);
          setCategoryList(
            categoryList.filter((item) => item.id !== categoryId)
          );
          setMessage(t("common:messages.RECORD_DELETED"));
          setMessageType("success");
          setTimeout(() => {
            setMessageOpen(false);
          }, 5000);
        }
      } catch (err) {
        setMessageOpen(true);
        setMessage(t("common:messages.RECORD_NOT_DELETED"));
        setMessageType("error");
        setTimeout(() => {
          setMessageOpen(false);
        }, 5000);
      }
    } finally {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteCategory,
    modalTitle: t(`common:DELETE_ITEM_CONFIRMATION_TEXT`),
    // handleModalClose: () => setCategoryId(null)
  });

  useEffect(() => {
    try {
      setIsLoading(true);
      serviceDict
        .getDictionaryList(DictionaryCode.CATEGORY, {
          pageNumber: pagination.pageIndex,
          size: pagination.pageSize,
          filter,
          sorting:
            lng === "kz"
              ? { isStandard: "desc", nameKz: "asc" }
              : lng === "ru"
              ? { isStandard: "desc", nameRu: "asc" }
              : { isStandard: "desc", nameEn: "asc" },
        })
        .then((res) => {
          if (res.status === 200) {
            setCategoryList(res.data.dtoList);
            setCount(res.data.totalElements);
          }
        });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, lng, validate, pagination.pageIndex]);

  const handleChange = (event: SelectChangeEvent) => {
    const currentParentId = Number(event.target.value);
    setFilter({ ...filter, parentId: currentParentId });
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setType(value);
    setTypeInForm(value);
    setFilter({ ...filter, parentId: value === "category" ? 0 : -1 });
  };

  const style = {
    "& label.Mui-focused": { color: common.primaryColor },
    "& .MuiInput-underline:after": { borderBottomColor: common.primaryColor },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": { borderColor: common.primaryColor },
      "&.Mui-focused fieldset": { borderColor: common.primaryColor },
    },
  };

  const [data, setData] = useState(() => categoryList);

  useEffect(() => {
    if (categoryList && categoryList.length > 0) {
      setData(categoryList);
    }
  }, [categoryList]);

  const columns = useMemo<MRT_ColumnDef<ICategory>[]>(
    () => [
      {
        accessorKey: "photo",
        header: t("common:PHOTO"),
        size: 60,
        minSize: 30,
      },
      {
        accessorKey: "nameRu",
        header: t("course:task.NAME"),
      },
      {
        accessorKey: "isDisabled",
        header: t("dictionary:USAGE_STATUS"),
        Cell: ({ row }) => {
          const { isDisabled } = row.original;
          return (
            <span>{isDisabled ? t("enum:NOT_USED") : t("enum:IS_USED")}</span>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowOrdering: true,
    layoutMode: "grid",
    enableColumnActions: false,
    enableSorting: false,
    enableRowActions: true,
    positionActionsColumn: "last",
    enableRowNumbers: true,
    rowNumberDisplayMode: "static",
    enablePagination: false,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    getRowId: (originalRow) => String(originalRow.id),
    icons: {
      DragHandleIcon: (props) => <DragIndicatorIcon {...props} />,
    },

    localization: {
      noRecordsToDisplay: t("dictionary:EMPTY"),
      rowsPerPage: t("enum:LINES_PER_PAGE"),
      actions: t("common:ORDER"),
      expand: "",
      move: t("common:ORDER"),
      rowNumber: "№",
    },
    muiTablePaperProps: {
      elevation: 0,

      sx: {
        display: "grid",
        borderRadius: "6px",
        border: "1px solid #E8E8E8",
        caption: {
          captionSide: "top",
        },
      },
    },
    muiTableContainerProps: {
      sx: {
        "&::-webkit-scrollbar": { height: "6px", width: "7px" },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#D9D9D9",
          borderRadius: "4px",
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        height: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "16px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        backgroundColor: common.white,
        height: "60px",
      },
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: t("dictionary:ACTION"),
        size: 40,
        maxSize: 80,
        muiTableHeadCellProps: {
          sx: {
            padding: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "0.6rem",
            paddingTop: ".75rem",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      },
      "mrt-row-drag": {
        size: 30,
        maxSize: 30,
        minSize: 30,
        muiTableHeadCellProps: {
          sx: {
            padding: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "0.6rem",
            paddingTop: ".75rem",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            display: "flex",
            justifyContent: "center",
          },
        },
      },
      "mrt-row-numbers": {
        size: 10,
        maxSize: 10,
        muiTableHeadCellProps: {
          sx: {
            padding: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "0.6rem",
            paddingTop: ".75rem",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            display: "flex",
            justifyContent: "center",
          },
        },
      },
    },
    renderRowActions: ({ row, cell, table }) => (
      <Stack direction={"row"}>
        <IconButton>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={toggleDrawer(true, Number(row.original.id))}
          >
            <path
              d="M2 21C1.45 21 0.979167 20.8042 0.5875 20.4125C0.195833 20.0208 0 19.55 0 19V5.00001C0 4.45001 0.195833 3.97917 0.5875 3.58751C0.979167 3.19584 1.45 3.00001 2 3.00001H10.925L8.925 5.00001H2V19H16V12.05L18 10.05V19C18 19.55 17.8042 20.0208 17.4125 20.4125C17.0208 20.8042 16.55 21 16 21H2ZM6 15V10.75L15.175 1.57501C15.375 1.37501 15.6 1.22501 15.85 1.12501C16.1 1.02501 16.35 0.975006 16.6 0.975006C16.8667 0.975006 17.1208 1.02501 17.3625 1.12501C17.6042 1.22501 17.825 1.37501 18.025 1.57501L19.425 3.00001C19.6083 3.20001 19.75 3.42084 19.85 3.66251C19.95 3.90417 20 4.15001 20 4.40001C20 4.65001 19.9542 4.89584 19.8625 5.13751C19.7708 5.37917 19.625 5.60001 19.425 5.80001L10.25 15H6ZM8 13H9.4L15.2 7.20001L14.5 6.50001L13.775 5.80001L8 11.575V13Z"
              fill="#9469E3"
            />
          </svg>
        </IconButton>

        {!row.original.isStandard && (
          <IconButton>
            <DeleteOutlineIcon
              onClick={() => {
                setCategoryId(row.original.id);
                openDeleteConfirmModal(String(row.original.id));
              }}
              color="error"
              sx={{ cursor: "pointer" }}
            />
          </IconButton>
        )}
      </Stack>
    ),
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data.splice(
            (hoveredRow as MRT_Row<ICategory>).index,
            0,
            data.splice(draggingRow.index, 1)[0]
          );
          setData([...data]);
        }
      },
    }),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    paginationDisplayMode: "default",
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20],
      shape: "rounded",
      variant: "outlined",
      sx: {
        "& .Mui-selected": {
          background: `${common.primaryColor} !important`,
          color: "#FFFFFF",
        },
      },
    },
    rowCount: count,
    manualPagination: true,
  });

  return (
    <Box>
      {
        //TODO:work here !!
      }
      {DeleteModal}
      <Box sx={{ display: "flex", alignItems: "center", mb: "24px" }}>
        <Typography
          sx={{ fontSize: 16, mr: "9px", cursor: "pointer" }}
          onClick={() => {
            navigate("/dict");
          }}
        >
          {t("DICTIONARIES")}
        </Typography>
        <ArrowForwardIosIcon
          sx={{ width: "10px", height: "10px", mr: "9px", mt: "1.5px" }}
        />
        <Typography sx={{ fontSize: 16, mr: "9px" }}>
          {t("CATEGORIES")}
        </Typography>
      </Box>
      <Typography sx={{ fontSize: 28, mb: "38px" }}>
        {t("CATEGORIES")}
      </Typography>
      {messageOpen && (
        <Box sx={{ mb: "24px", mt: "-12px" }}>
          <Message
            type={messageType}
            message={message}
            setMessageOpen={setMessageOpen}
          />
        </Box>
      )}
      <Box mb={1}>
        <UnhiddenHints
          text={""}
          helperText={[
            t("common:messages.CHANGE_ORDER_CATEGORY"),
            t("common:messages.FIRST_8_CATEGORY_DISPLAYED"),
          ]}
        />
      </Box>

      <Box
        sx={{
          border: `1px solid ${common.borderSecondary}`,
          backgroundColor: common.fontWhite,
          borderRadius: "24px",
          p: "24px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mr: "8px",
            }}
          >
            <Box sx={{ width: "40%", mr: "8px" }}>
              <TextField
                label={t("common:placeholders.SEARCH_BY_NAME")}
                color="primary"
                size="small"
                name="searchValue"
                sx={{
                  ...style,
                  width: "90%",
                }}
                onChange={(e) => searchByName(e.target.value)}
              />
            </Box>

            <FormControl
              sx={{
                ...style,
                width: "20%",
                mr: "8px",
              }}
            >
              <InputLabel size="small">{t("TYPE")}</InputLabel>
              <Select
                value={type}
                onChange={(e) => handleTypeChange(e)}
                color="primary"
                size="small"
                label={t("TYPE")}
              >
                <MenuItem value="category">{t("CATEGORY")}</MenuItem>
                <MenuItem value="subcategory">{t("SUBCATEGORY")}</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              sx={{ width: "250px", mr: "8px" }}
              control={
                <Checkbox
                  checked={filter.isDisabled}
                  onChange={(e) => searchByActiveness(e.target.checked)}
                  sx={{
                    marginLeft: 2,
                    color: common.primaryColor,
                    "&, &.Mui-checked": {
                      color: common.primaryColor,
                    },
                  }}
                />
              }
              label={t("enum:NOT_USED")}
              labelPlacement="end"
            />
            {type === "subcategory" && (
              <FormControl
                sx={{
                  ...style,
                  width: "30%",
                  mx: "8px",
                }}
              >
                <InputLabel>{t("CATEGORY")}</InputLabel>
                <Select
                  defaultValue="-1"
                  onChange={handleChange}
                  color="primary"
                  size="small"
                  MenuProps={{ style: { maxHeight: 300 } }}
                  label={t("CATEGORY")}
                >
                  <MenuItem value="-1">{t("ALL")}</MenuItem>
                  {dropdownCategorylist
                    .filter((category) => category.parent === null)
                    .sort((a, b) =>
                      lng === "kz" || lng === "ru"
                        ? String(a?.nameRu).localeCompare(String(b?.nameRu))
                        : String(a?.nameEn).localeCompare(String(b?.nameEn))
                    )
                    ?.map((category, index) => {
                      return (
                        <MenuItem
                          value={category.id || ""}
                          key={`${index}_${category.id}`}
                        >
                          {get(category, `name${capitalize(lng)}`, "").length >
                          25 ? (
                            <Tooltip
                              title={get(
                                category,
                                `name${capitalize(lng)}`,
                                ""
                              )}
                              placement="top"
                            >
                              <span>
                                {get(
                                  category,
                                  `name${capitalize(lng)}`,
                                  ""
                                ).slice(0, 25)}
                                {get(category, `name${capitalize(lng)}`, "")
                                  .length > 25 && "..."}
                              </span>
                            </Tooltip>
                          ) : (
                            get(category, `name${capitalize(lng)}`, "")
                          )}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )}
          </Box>
          <CustomButton
            width="90px"
            height="40px"
            backgroundColor={common.primaryColor}
            borderRadius="8px"
            fontColor={common.fontWhite}
            sx={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              alignItems: "center",
            }}
            onClick={toggleDrawer(true)}
          >
            {t("common:actions.ADD")}
          </CustomButton>
        </Box>

        {isLoading ? <LoadingSpinner /> : <MaterialReactTable table={table} />}
      </Box>
      {drawerOpen && (
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {DrawerComponent}
        </Drawer>
      )}
    </Box>
  );
};

export default List;
