import { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { AlertColor } from '@mui/material/Alert';
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";

import Message from "@components/Message";
import GeneralSettings from "./GeneralSettings";
import AdditionalSettings from "./AdditionalSettings";

const Form = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState("general");
  const navigate = useNavigate();
  const { t } = useTranslation(["dictionary", "common", "enum"]);
  const { id } = useParams();
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const currentTab = searchParams.get('tab');

  const handleChange = (newValue: string) => {
    setValue(newValue);
    setSearchParams({ tab: newValue })
  };

  useEffect(() => {
    if (currentTab) {
      setValue(currentTab);
    } else {
      setValue("general");
    }
  }, [currentTab])

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: 'center', mb: '24px' }}>
        <Typography sx={{ fontSize: 16, mr: '9px', cursor: 'pointer' }} onClick={() => { navigate('/dict') }} >
          {t("DICTIONARIES")}
        </Typography>
        <ArrowForwardIosIcon sx={{ width: '10px', height: '10px', mr: '9px', mt: '1.5px' }} />
        <Typography sx={{ fontSize: 16, mr: '9px', cursor: 'pointer' }} onClick={() => navigate('/dict/organizations')}>
          {t("ORGANIZATIONS")}
        </Typography>
        <ArrowForwardIosIcon sx={{ width: '10px', height: '10px', mr: '9px', mt: '1.5px  ' }} />
        <Typography>
          {id === "new" ? `${t("ADD_ORGANIZATION")}` : `${t("EDIT_ORGANIZATION")}`}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ fontSize: '36px', fontWeight: 500, mb: '36px' }}>
          {id === "new" ? `${t("ADD_ORGANIZATION")}` : `${t("EDIT_ORGANIZATION")}`}
        </Typography>
        {messageOpen &&
          <Box sx={{ mb: '24px', mt: '-12px' }}>
            <Message
              type={messageType}
              message={message}
              setMessageOpen={setMessageOpen}
            />
          </Box>
        }
        <TabContext value={value}>
          <TabList onChange={(_, value: string) => handleChange(value)} textColor="secondary" indicatorColor="secondary" sx={{ ml: '24px' }}>
            <Tab
              label={t('enum:GENERAL')}
              value="general"
              sx={{ textTransform: 'none', fontSize: '18px', lineHeight: '24px', fontWeight: 500, color: '#1A141F' }} />
            <Tab
              label={t('enum:ADDITIONAL_SETTINGS')}
              value="additionalSettings"
              sx={{ textTransform: 'none', fontSize: '18px', lineHeight: '24px', fontWeight: 500, color: '#1A141F' }}
              disabled={id === 'new'}
            />
          </TabList>
          <TabPanel value="general" sx={{ p: 0 }}>
            <GeneralSettings setMessage={setMessage} setMessageType={setMessageType} setMessageOpen={setMessageOpen} />
          </TabPanel>
          <TabPanel value="additionalSettings" sx={{ p: 0 }}>
            <AdditionalSettings />
          </TabPanel>
        </TabContext>
      </Box>
    </Box >
  );
};

export default Form;