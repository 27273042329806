import { useEffect, useState } from "react";
import { useField, useFormikContext } from "formik";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "./styles.scss";

interface ITextEditorProps {
  name: string;
  icon?: boolean;
  hideToolbar?: boolean;
}

export const NoteEditor = ({ name, icon, hideToolbar = false }: ITextEditorProps) => {
  const formikProps = useFormikContext();
  const [field] = useField(name);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (toHtml(editorState) === field.value) return;
    field.value && setEditorState(
      EditorState.push(
        editorState,
        ContentState.createFromBlockArray(
          htmlToDraft(field.value || "<p></p>\n").contentBlocks
        ),
        "adjust-depth"
      )
    );
  }, [field.value, editorState]);

  function onEditorStateChange(es: EditorState) {
    setEditorState(es);
    const html = toHtml(es);
    if (field.value !== html) {
      formikProps.setFieldValue(name, html);
    }
  }

  function toHtml(es: EditorState) {
    return draftToHtml(convertToRaw(es.getCurrentContent()));
  }

  const iconToolbar = {
    options: [
      "inline",
      "fontSize",
      "list",
      "textAlign",
      "history",
    ],
    inline: { inDropdown: true },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    history: { inDropdown: true },
  }

  const toolbar = {
    options: [
      "inline",
      "fontSize",
      "fontFamily",
      "link",
      "image",
      "list",
      "textAlign",
      "history",
    ],
    inline: { inDropdown: true },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    history: { inDropdown: true },
  }

  const iconToolbarStyle = { backgroundColor: '#FFF7D1', pl: '4px' };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      editorClassName={icon ? "custom-scroll-editor" : "custom-notes-scroll-editor"}
      toolbar={icon ? iconToolbar : toolbar}
      toolbarStyle={icon ? iconToolbarStyle : {}}
      toolbarClassName={hideToolbar ? 'hide-toolbar' : 'toolbar'}
      readOnly={hideToolbar}
    />
  );
};