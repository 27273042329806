import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MaterialReactTable, //import alternative sub-component if we do not want toolbars
  type MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table';

import { IStudyClass } from '@interfaces/index';

export const StudyClassList = ({ potokData }) => {
  const { t } = useTranslation(["dictionary", "course", "enum"]);
  const columns = useMemo<MRT_ColumnDef<IStudyClass>[]>(
    () => [
      {
        accessorKey: 'period',
        header: `${t("course:FOLOW_PERIOD")}`,
        size: 300
      },
      {
        accessorKey: 'listenersCount',
        header: `${t("enum:LISTENERS")}`,
        size: 50
      },

    ],
    [t],
  );

  const table = useMaterialReactTable({
    columns,
    data: potokData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableTopToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    enableTableFooter: false,
    enableBottomToolbar: false,
    enableRowNumbers: true,
    localization: {
      noRecordsToDisplay: t('dictionary:EMPTY'),
      rowsPerPage: t('enum:LINES_PER_PAGE'),
      actions: "",
      expand: "",
      rowNumber: '№ Потока',
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default, //change default background color
    }),
    muiTableBodyRowProps: { hover: false },
    muiTableHeadCellProps: {
      sx: {
        borderLeft: '1px solid rgba(81, 81, 81, .5)'
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderLeft: '1px solid rgba(81, 81, 81, .5)',
      },
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        display: 'grid'
      },
    },
    muiTableContainerProps: {
      sx: {
        '&::-webkit-scrollbar': { height: '4px', width: '7px' },
        '&::-webkit-scrollbar-thumb': { backgroundColor: '#D9D9D9', borderRadius: '4px' }
      },
    },
  });

  //using MRT_Table instead of MaterialReactTable if we do not need any of the toolbar components or features
  return <MaterialReactTable table={table} />;
};

export default StudyClassList;
