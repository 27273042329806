import * as yup from 'yup';

export const settingAntiPlagiarismValidationSchema = (t) => yup.object({
    personMaxCheckCount: yup.string().required(t('common:validation.MANDATORY_FIELD')),
    originality: yup.string().required(t('common:validation.MANDATORY_FIELD')),
    allowedReduceOriginality: yup.string().required(t('common:validation.MANDATORY_FIELD')),
    similarity: yup.string().required(t('common:validation.MANDATORY_FIELD')),
    allowedReduceSimilarity: yup.string().required(t('common:validation.MANDATORY_FIELD')),
    citation: yup.string().required(t('common:validation.MANDATORY_FIELD')),
    selfCitation: yup.string().required(t('common:validation.MANDATORY_FIELD')),
    symbolReplacementMaxCount: yup.string().required(t('common:validation.MANDATORY_FIELD')),
});
