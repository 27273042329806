import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import FormikInput from '@ui/formik/Input';
import { Formik, Form as FormikForm } from "formik";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import CustomModal from './Modal';

interface IProps {
  gradingSystemId: number | null;
  open: boolean;
  handleToggleModal: any;
  initialData: any
}

const GradingSystemTable = ({ gradingSystemId, open, handleToggleModal, initialData }: IProps) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course", 'common']);

  return (
    <CustomModal
      open={open}
      handleToggleModal={handleToggleModal}
    >
      <>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }} onClick={() => handleToggleModal(false)}>
          <ClearIcon sx={{ color: '#9C9C9C', cursor: 'pointer', width: '25px' }} />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 2 }}>
          <Typography>
            {
              gradingSystemId === 1
                ? t('common:hints.100_POINT_SCALE_GRADING_SYSTEM_HINT')
                : gradingSystemId === 2
                  ? t('common:hints.100_POINT_FX_SCALE_GRADING_SYSTEM_HINT')
                  : t('common:hints.CUMULATIVE_GRADING_SYSTEM_HINT')
            }
          </Typography>
          <Box sx={{
            width: '100%', backgroundColor: common.white,
            borderRadius: '9px'
          }}>
            <Formik
              initialValues={initialData}
              enableReinitialize
              validationSchema={null}
              onSubmit={(values) => {
              }}
            >
              {({ values }) => (
                <FormikForm>
                  {gradingSystemId === 3 &&
                    <TableContainer component={Paper} sx={{ overflowX: 'unset' }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              {t('course:task.NAME')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              {t('course:task.POINT')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              {t('common:label.DESCRIPTION')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              {t('common:MIN_SCORE')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              0
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Box sx={{ ml: '-17px', mr: '-24px', mt: '-15px', mb: '-16px' }}>
                                <FormikInput name="Aaa" label="" multiline={true} size='small' placeholder='Text' maxRows={3} disabled />
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              {t('common:MAX_SCORE')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              100
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Box sx={{ ml: '-17px', mr: '-24px', mt: '-15px', mb: '-16px' }}>
                                <FormikInput name="Aaa" label="" multiline={true} size='small' placeholder='Text' maxRows={3} disabled />
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                  {gradingSystemId !== 3 &&
                    <TableContainer component={Paper} sx={{ overflowX: 'unset' }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{
                              fontWeight: 500, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}`, py: 0,
                            }}>
                              {t('GRADE')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '25%', borderRight: `1px solid ${common.borderSecondary}`, py: 0, pl: 2 }}>
                              <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>Диапазон баллов</Typography>
                              <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>До</Typography>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>От</Typography>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              {t('common:label.DESCRIPTION')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {/* <TableHead>
                          <TableRow sx={{ height: '52px' }}>
                            <TableCell sx={{
                              fontWeight: 500, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}`
                            }}>
                              Оценка
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '25%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Box sx={{ display: 'block' }}>
                                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>Диапазон баллов</Typography>
                                <Box sx={{ display: 'flex', mt: '5px' }}>
                                  <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>До</Typography>
                                  <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>От</Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', borderRight: `1px solid ${common.borderSecondary}` }}>
                              Описание
                            </TableCell>
                          </TableRow>
                        </TableHead> */}
                        <TableBody>
                          <TableRow sx={{ height: '30px' }}>
                            <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              A
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>100</Typography>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>95</Typography>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Box sx={{ ml: '-15px', mr: '-22px', mt: '-15px', mb: '-15px', py: 0 }}>
                                <FormikInput name="Aaa" label="" multiline={true} size='small' placeholder='Text' maxRows={3} disabled />
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              A-
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>94</Typography>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>90</Typography>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Box sx={{ ml: '-15px', mr: '-22px', mt: '-15px', mb: '-15px' }}>
                                <FormikInput name="Aaa" label="" multiline={true} size='small' placeholder='Text' maxRows={3} disabled />
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              B+
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              <Box sx={{ display: 'flex', mt: '5px' }}>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>89</Typography>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>85</Typography>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Box sx={{ ml: '-15px', mr: '-22px', mt: '-15px', mb: '-15px' }}>
                                <FormikInput name="Aaa" label="" multiline={true} size='small' placeholder='Text' maxRows={3} disabled />
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              B
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              <Box sx={{ display: 'flex', mt: '5px' }}>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>84</Typography>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>80</Typography>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Box sx={{ ml: '-15px', mr: '-22px', mt: '-15px', mb: '-15px' }}>
                                <FormikInput name="Aaa" label="" multiline={true} size='small' placeholder='Text' maxRows={3} disabled />
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              B-
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              <Box sx={{ display: 'flex', mt: '5px' }}>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>79</Typography>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>75</Typography>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Box sx={{ ml: '-15px', mr: '-22px', mt: '-15px', mb: '-15px' }}>
                                <FormikInput name="Aaa" label="" multiline={true} size='small' placeholder='Text' maxRows={3} disabled />
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              C+
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              <Box sx={{ display: 'flex', mt: '5px' }}>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>74</Typography>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>70</Typography>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Box sx={{ ml: '-15px', mr: '-22px', mt: '-15px', mb: '-15px' }}>
                                <FormikInput name="Aaa" label="" multiline={true} size='small' placeholder='Text' maxRows={3} disabled />
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              C
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              <Box sx={{ display: 'flex', mt: '5px' }}>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>69</Typography>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>65</Typography>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Box sx={{ ml: '-15px', mr: '-22px', mt: '-15px', mb: '-15px' }}>
                                <FormikInput name="Aaa" label="" multiline={true} size='small' placeholder='Text' maxRows={3} disabled />
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              C-
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '5px' }}>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>64</Typography>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>60</Typography>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Box sx={{ ml: '-15px', mr: '-22px', mt: '-15px', mb: '-15px' }}>
                                <FormikInput name="Aaa" label="" multiline={true} size='small' placeholder='Text' maxRows={3} disabled />
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              D+
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '5px' }}>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>59</Typography>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>55</Typography>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Box sx={{ ml: '-15px', mr: '-22px', mt: '-15px', mb: '-15px' }}>
                                <FormikInput name="Aaa" label="" multiline={true} size='small' placeholder='Text' maxRows={3} disabled />
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              D
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '5px' }}>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>54</Typography>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>50</Typography>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Box sx={{ ml: '-15px', mr: '-22px', mt: '-15px', mb: '-15px' }}>
                                <FormikInput name="Aaa" label="" multiline={true} size='small' placeholder='Text' maxRows={3} disabled />
                              </Box>
                            </TableCell>
                          </TableRow>
                          {gradingSystemId === 2 &&
                            <TableRow>
                              <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                                FX
                              </TableCell>
                              <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                                <Box sx={{ display: 'flex', mt: '5px' }}>
                                  <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>49</Typography>
                                  <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>25</Typography>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                                <Box sx={{ ml: '-15px', mr: '-22px', mt: '-15px', mb: '-15px' }}>
                                  <FormikInput name="Aaa" label="" multiline={true} size='small' placeholder='Text' maxRows={3} disabled />
                                </Box>
                              </TableCell>
                            </TableRow>
                          }
                          <TableRow>
                            <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              F
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}`, py: 0 }}>
                              <Box sx={{ display: 'flex', mt: '5px' }}>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>{gradingSystemId === 1 ? 49 : 24}</Typography>
                                <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>0</Typography>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Box sx={{ ml: '-15px', mr: '-22px', mt: '-15px', mb: '-15px' }}>
                                <FormikInput name="Aaa" label="" multiline={true} size='small' placeholder='Text' maxRows={3} disabled />
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                </FormikForm>
              )}
            </Formik>
          </Box>
        </Box>
      </>
    </CustomModal>
  );
}

export default GradingSystemTable;