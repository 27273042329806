import { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import List from "./List";
import { TabListCourse } from "@components/common";
import { TypeCode } from "@interfaces/index";
import { CT_ValueState } from "@pages/myCourses/Educator";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const AdminCourseList = () => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course", "header"]);
  const [openFilter, setOpenFilter] = useState<boolean>(true);
  const [courseType, setCourseType] = useState<string>("");
  const [courseStatus, setCourseStatus] = useState<CT_ValueState>({
    edu: null,
    free: null,
  });
  const [courseName, setCourseName] = useState<CT_ValueState>({
    edu: null,
    free: null,
  });

  const searchByName = (searchValue: string) => {
    courseType === TypeCode.EDU
      ? setCourseName({ ...courseName, edu: searchValue })
      : setCourseName({ ...courseName, free: searchValue });
  };

  const statusList = [
    {
      id: 1,
      label: t("ALL"),
      code: "",
    },
    {
      id: 2,
      label: t("SAVED_MULTIPLE"),
      code: 'SAVED',
    },
    {
      id: 3,
      label: t("PUBLISHED_MULTIPLE"),
      code: 'PUBLISHED',
    },
    {
      id: 4,
      label: t("WITHDRAWN_MULTIPLE"),
      code: 'WITHDRAWN',
    },
    {
      id: 5,
      label: t("CLOSED_MULTIPLE"),
      code: 'CLOSED',
    }
  ];
  return (
    <Box sx={{ maxWidth: '1880px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: { sm: "36px", xs: '28px' },
            lineHeight: "44px",
            alignItems: "center",
            mt: 2
          }}
        >
          {t('header:COURSES')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "36px",
          width: "100%",
          height: 'auto',
          minHeight: "650px",
          borderRadius: "20px",
        }}
      >
        <TabContext value={courseType}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabListCourse
              onChange={(_, value: string) => setCourseType(value)}
              setCourseType={setCourseType}
            />
          </Box>
          <TabPanel value={courseType} sx={{ px: 0 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mt: 2,
                width: "100%",
                height: "auto",
                minHeight: "650px",
              }}
            >
              <TabContext
                value={
                  (courseType === TypeCode.EDU
                    ? courseStatus.edu
                    : courseStatus.free) || ""
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                  }}
                >
                  <TabList
                    onChange={(_, value: string) =>
                      courseType === TypeCode.EDU
                        ? setCourseStatus({ ...courseStatus, edu: value })
                        : setCourseStatus({ ...courseStatus, free: value })
                    }
                    variant="scrollable"
                    sx={{
                      "& .MuiTab-root": {
                        minHeight: "34px",
                      },
                      "& .MuiTabs-flexContainer": {
                        gap: 1,
                      },
                      display: "flex",
                      alignItems: "center",
                    }}
                    TabIndicatorProps={{
                      style: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        backgroundColor: common.secondaryColor,
                        opacity: 0.15,
                        border: `1px solid ${common.borderPrimary}`,
                        borderRadius: "6px",
                      },
                    }}
                  >
                    {statusList.map((status) => {
                      return (
                        <Tab
                          key={status.code}
                          label={status.label}
                          value={status.code}
                          sx={{
                            fontSize: "14px",
                            color: "#686B81",
                            width: "auto",
                            height: "40px",
                            borderRadius: "6px",
                            backgroundColor: "#FFFFFF",
                            textTransform: "capitalize",
                            lineHeight: "14px",
                            transition: "transform 1s",
                            "&:hover": {
                              backgroundColor: "#F5EFFF",
                            },
                            "&.Mui-selected": {
                              backgroundColor: common.primaryColor,
                              color: "#FFFFFF",
                            },
                          }}
                        />
                      );
                    })}
                  </TabList>
                  <Box width="50%" display="flex" gap={1}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      color="primary"
                      sx={{
                        width: "70%",
                        height: "44px",
                        display: "flex",
                        backgroundColor: "#FFFFFF",
                        ml: "auto",
                        "& label.Mui-focused": { color: common.primaryColor },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: common.primaryColor,
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": { borderColor: common.primaryColor },
                          "&.Mui-focused fieldset": {
                            borderColor: common.primaryColor,
                          },
                        },
                      }}
                      placeholder={t("common:placeholders.SEARCH_BY_COURSE_NAME")}
                      name="searchValue"
                      value={
                        courseType === TypeCode.EDU
                          ? courseName.edu || ""
                          : courseName.free || ""
                      }
                      onChange={(e) => searchByName(e.target.value)}
                      InputProps={{
                        sx: {
                          height: "44px",
                          pr: 0.5,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Box
                              sx={{
                                backgroundColor: common.primaryColor,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "45px",
                                height: "36px",
                                borderRadius: "6px",
                              }}
                            >
                              <SearchIcon
                                sx={{
                                  color: "#FFFFFF",
                                  width: "26px",
                                  height: "26px",
                                }}
                              />
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box sx={{
                      border: `1px solid ${common.borderSecondary}`,
                      height: '40px',
                      width: '124px',
                      backgroundColor: openFilter ? common.primaryColor : 'white', borderRadius: '9px', display: 'flex', alignItems: 'center', px: '4px', cursor: 'pointer'
                    }}
                      onClick={() => setOpenFilter(!openFilter)}
                    >
                      <TuneIcon sx={{ color: openFilter ? "white" : "black" }} />
                      <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "20px", color: openFilter ? "white" : "black", mr: '8px', ml: '4px' }}>
                        {t('common:FILTER')}
                      </Typography>
                      {
                        openFilter ? (
                          <ArrowDropUpIcon sx={{ color: openFilter ? "white" : "black", width: '30px', height: '30px' }} />
                        ) : (
                          <ArrowDropDownIcon sx={{ color: openFilter ? "white" : "black", width: '30px', height: '30px' }} />
                        )
                      }
                    </Box>
                  </Box>
                </Box>
              </TabContext>
              <List
                type={courseType}
                openFilter={openFilter}
                courseStatus={courseStatus}
                courseName={courseName}
              />
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default AdminCourseList;