import Img1 from "@assets/images/home/1.png";
import AvatarPicture from "@components/AvatarPicture";
import { StudyClass } from "@interfaces/studyClass";
import ErrorIcon from "@mui/icons-material/Error";
import FavoriteIcon from "@mui/icons-material/Favorite";
import StarIcon from "@mui/icons-material/Star";
import { Box, Grid, Popover, useTheme } from "@mui/material";
import { useQueries, useQuery } from "@tanstack/react-query";
import { getBase64, removeHtml } from "@utils/functions";
import React, { useEffect, useState } from "react";
import s from "./CardItem.module.scss";
import {
  dictionaryControllerApi,
  fileStorageControllerApi,
  publicControllerApi,
} from "@services/index";
import { useTranslation } from "react-i18next";
import { DictionaryCode, DurationType } from "@interfaces/index";
import { capitalize } from "lodash";
import { useNavigate } from "react-router-dom";
import { serviceFile } from "@services/file";

interface Props {
  data: StudyClass;
  backgroundColor?: string;
}

const CardItem = (props: Props) => {
  const { data, backgroundColor } = props;
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course", "freeCourse", "common"]);
  const [hint, setHint] = useState<HTMLElement | null>(null);
  const lang = localStorage.getItem("i18nextLng") || "kz";
  const navigate = useNavigate();
  const isCurrentDateInRange = (regStartDate: string, regEndDate: string) => {
    const startDate = new Date(regStartDate);
    const endDate = new Date(regEndDate);
    const now = new Date();
    return now >= startDate && now <= endDate;
  };
  const planned = isCurrentDateInRange(data?.regStartDate, data?.regEndDate);
  const current = isCurrentDateInRange(data?.startDate, data?.endDate);
  const completed = new Date() > new Date(data?.endDate);
  const courseStatus = planned
    ? "PLANNED"
    : current
      ? "CURRENT"
      : completed
        ? "COMPLETED"
        : "";
  const [fileUrl, setFileUrl] = useState<string>("");
  const hintPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHint(event.currentTarget);
  };
  const hintPopoverClose = () => {
    setHint(null);
  };
  const openHint = Boolean(hint);

  const authorInfo = useQueries({
    queries: data?.course?.authors?.map((author) => ({
      queryKey: ["getAuthorById", author.userId],
      queryFn: () => publicControllerApi.getUserByIdPublic(author.userId),
      enabled: !!data.courseId,
      staleTime: 5 * 60 * 1000,
    })),
    combine: (results) => results.map((result) => result.data),
  });

  const { data: difficultyLevel } = useQuery({
    queryKey: [
      "getCourseDifficultyLevel",
      DictionaryCode.COURSE_DIFFICULTY_LEVEL,
    ],
    queryFn: () =>
      dictionaryControllerApi.getDictionaryListByCodePublic(
        DictionaryCode.COURSE_DIFFICULTY_LEVEL
      ),
    staleTime: 5 * 60 * 1000,
    select: (item) =>
      item.find((x) => x.id === data?.course?.difficultyLevelId),
  });

  const { data: estimatedTime } = useQuery({
    queryKey: ["getContentSummaryPublic", data?.courseId],
    queryFn: () => publicControllerApi.getContentSummaryPublic(data?.courseId),
    enabled: !!data?.courseId,
    staleTime: 5 * 60 * 1000,
    select: (data) => data.estimatedTime,
  });

  useEffect(() => {
    if (data.course?.coverFileId) {
      serviceFile.getFileMimeTypePublic(data.course.coverFileId).then((res) => {
        const contentDisposition = res.headers["content-disposition"];
        const fileName = contentDisposition
          .split(";")[1]
          .split("filename")[1]
          .split("=")[1]
          .trim();
        const file = new File([res.data], fileName);
        getBase64(file, (url) => setFileUrl(url));
      });
    }
  }, [data.course?.coverFileId]);

  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <div
        className={s.wrapper}
        onClick={() => navigate(`/myCourses/planned/${data?.id}`)}
        style={{ backgroundColor: backgroundColor }}
      >
        <div style={{ background: data?.course?.coverColor }} className={s.img}>
          <Box
            sx={{
              background: `url(${fileUrl})`,
              height: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              position: "relative",
              borderRadius: "4px",
            }}
          >
            {!fileUrl && <div className={s.img_text}>{data?.course?.name}</div>}
          </Box>
          <div className={s.head}>
            <div className={s.star}>
              <StarIcon sx={{ color: "#F4C41A", fontSize: "16px" }} />
              <div>{data?.course?.rating}</div>
            </div>
            <div
              className={s.attention}
              onMouseEnter={hintPopoverOpen}
              onMouseLeave={hintPopoverClose}
            >
              <ErrorIcon sx={{ color: "white", opacity: 1, fontSize: "16px" }} />
            </div>
          </div>

          <div className={s.bookmark}>
            <FavoriteIcon
              sx={{
                color: "#fff",
                stroke: common.primaryColor,
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <div className={s.content}>
          <div className={s.title}>{data?.course?.name}</div>
          <div className={s.info}>
            <div className={s.price}>{t("freeCourse:FOR_FREE")}</div>
            <div className={s.tip}>
              {data?.course?.courseSetting.durationType !==
                DurationType.INDEFINITE
                ? t("freeCourse:WITH_EDUCATOR")
                : estimatedTime && estimatedTime / 60 > 1
                  ? `${Math.floor(estimatedTime / 60)} ${t('common:HOURS_SHORT')}`
                  : `${estimatedTime ?? 0} ${t('common:MINUTS_SHORT')}`}
              /{difficultyLevel && difficultyLevel[`name${capitalize(lang)}`]}
            </div>
          </div>

          <div className={s.author}>
            <div className={s.avatar}>
              <AvatarPicture
                variant="circular"
                id={authorInfo?.[0]?.profilePhotoId}
                sxProps={{ width: "32px", height: "32px" }}
              />
            </div>
            <div className={s.name}>
              {authorInfo?.[0]?.lastName} {authorInfo?.[0]?.firstName}
            </div>
          </div>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={openHint}
            anchorEl={hint}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={hintPopoverClose}
            disableRestoreFocus
            disableScrollLock
          >
            <Box
              sx={{
                p: 2,
                color: common.fontWhite,
                backgroundColor: "#1A1F36",
                width: { sm: "318px", xs: "232px" },
                fontSize: "12px",
                fontFamily: "Golos",
              }}
            >
              {removeHtml(data?.course?.shortDescription || "")}
            </Box>
          </Popover>
        </div>
      </div>
    </Grid>
  );
};

export default CardItem;
