import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NotificationPopoverList } from "./NotificationPopoverList";
import { NotificationAllRead } from "./NotificationAllRead";


const PopoverNotification = ({ onClickNotificationBox, handleClose }: any) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["notification"]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: "12px",
          width: "500px",
        }}
      >
        <Typography sx={{ fontWeight: 500, fontSize: "20px", ml: "5px" }}>
          {t("NOTIFICATION")}
        </Typography>
        <NotificationAllRead />
      </Box>
      <NotificationPopoverList />
      <Typography
        sx={{
          color: common.primaryColor,
          p: "12px",
          mt: "8px",
          cursor: "pointer",
        }}
        onClick={() => {
          onClickNotificationBox();
          handleClose();
        }}
      >
        {t("SHOW_ALL_NOTIFICATION")}
      </Typography>
    </Box>
  );
};

export default PopoverNotification;
