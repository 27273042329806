import { useMemo, useContext } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

import { StateSchema } from "@store/StateSchema";
import OneCorrectAnswersView from './OneCorrectAnswersView';
import MultipleCorrectAnswersView from './MultipleCorrectAnswersView';
import { MappingView } from './MappingView';
import TrueOrFalseView from './TrueOrFalseView';
import { SequenceView } from './SequenceView';
import { QuizQuestion, QuizQuestionTypeEnum } from '@models/quiz-question';
import { quizQuestionTypes } from '@utils/enums';
import { CourseContext } from "@context/CourseContext";
import { CourseStatus, Role } from '@interfaces/index';
import "../styles.scss";

interface IMappingViewProps {
  data: QuizQuestion;
  index: number;
  setQuestionData: (data: QuizQuestion) => void;
  openDeleteConfirmModal: (id: string) => void;
  taskStatus: string
}

export const QuizTypesView = ({ data, openDeleteConfirmModal, index, setQuestionData, taskStatus }: IMappingViewProps) => {
  const { t } = useTranslation(['enum', 'course']);
  const { common } = useTheme().palette;
  const profile = useSelector((store: StateSchema) => store.account.profileInfo);
  const { role } = profile.authorityInfo;
  const { courseData } = useContext(CourseContext);
  const isAuthor = courseData?.userId && courseData?.userId === courseData?.createdBy;

  const QuestionAnswerFormsView = useMemo(() => {
    switch (data.type) {
      case QuizQuestionTypeEnum.ONECORRECTANSWER:
        return <OneCorrectAnswersView index={index} data={data} />
      case QuizQuestionTypeEnum.MAPPING:
        return <MappingView index={index} data={data} />
      case QuizQuestionTypeEnum.MULTIPLECORRECTANSWERS:
        return <MultipleCorrectAnswersView index={index} data={data} />
      case QuizQuestionTypeEnum.TRUEORFALSE:
        return <TrueOrFalseView index={index} data={data} />
      case QuizQuestionTypeEnum.SEQUENCE:
        return <SequenceView index={index} data={data} />
      default:
        return null
    }
  }, [data, index])

  const handleClickDelete = () => {
    openDeleteConfirmModal(data.id || "")
  }

  const quizTypeName = useMemo(() => {
    return quizQuestionTypes.find(el => el === data.type)
  }, [data.type])

  return (
    <Box sx={{ border: `1px solid ${common.borderSecondary}`, mb: '24px' }}>
      <Box sx={{ display: 'flex', backgroundColor: common.secondaryColor, py: '8px', px: '32px', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'block' }}>
          <Typography sx={{ fontWeight: 500, fontSize: '20px' }}>{t('course:test.QUESTION')} {index + 1}</Typography>
          <Typography>{t('course:test.QUESTION_TYPE')}| {t(quizTypeName || "")}</Typography>
        </Box>
        <Typography sx={{ fontWeight: 500, fontSize: '20px' }}>{t('course:test.POINTS', { point: data.point })}</Typography>
      </Box>
      <Box sx={{ borderTop: `1px solid ${common.borderSecondary}`, display: 'block' }}>
        <Grid sx={{ width: "100%", px: '36px', py: '14px', margin: "0" }} alignItems="flex-start" container spacing={2}>
          <Grid item xs={11}>
            <Box sx={{ fontWeight: 500, fontSize: '20px' }}>
              <div
                className='text-reader'
                dangerouslySetInnerHTML={{
                  __html: data.content,
                }}
              ></div>
            </Box>
          </Grid>
          {((isAuthor && taskStatus === CourseStatus.SAVED) || role !== Role.LOCAL_ADMIN) &&
            <Grid item display="flex" justifyContent="flex-end" xs={1}>
              <IconButton onClick={() => setQuestionData(data)} >
                <ModeEditOutlineOutlinedIcon />
              </IconButton>
              <IconButton onClick={handleClickDelete} >
                <DeleteOutlinedIcon color="error" />
              </IconButton>
            </Grid>
          }
        </Grid>
        {QuestionAnswerFormsView}
      </Box>
    </Box>
  )
}