import axios from '@utils/axios';
import { NavigateFunction } from "react-router-dom";
import { NotificationRouteENUM } from "@pages/notification/models";

export const serviceNotification = {
  getListUserId: (params: any) => axios.post(`/api/notification/data/list/by/user`, params),
  updateNotificationStatus: (notificationUserId: number) => axios.put(`/api/notification/data/update/status/${notificationUserId}`),
  deleteByUser: (notificationUserId: any) => axios.put(`/api/notification/data/delete/${Number(notificationUserId)}`),
  updateAllStatusToRead: () => axios.put(`/api/notification/data/update/status/to-read`),

  goToPage(item: any, navigate: NavigateFunction) {
    const type = NotificationRouteENUM[item.routeType];
    if (type) {
      if (item.linkParams) {
        const path = item.linkParams['path'];
        const query = item.linkParams['query'];
        if (path && query) {
          navigate({ pathname: type.route + '/' + path, search: '?' + NotificationRouteENUM.convertQueryParams(query) })

        } else if (path) {
          navigate(type.route + '/' + path);
        } else if (query) {
          navigate({ pathname: type.route, search: '?' + NotificationRouteENUM.convertQueryParams(query) })
        }

      } else {
        navigate(type.route);
      }
    }
  }
};