import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Tooltip, Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const AboutProgressPassedOrNotPassed = () => {
  const { t } = useTranslation(["common"]);
  const { common } = useTheme().palette;

  return (
    <Tooltip componentsProps={{
      tooltip: { sx: { background: "none" } }
    }} title={<Box
      sx={{
        background: "#fff",
        color: "#000",
        fontSize: 18,
        width: "200px",
        padding: "10px",
        borderRadius: "6px",
        border: `1px solid ${common.borderSecondary}`
      }}>
      <Box sx={{ alignItems: "center", display: "flex", gap: 1, mb: "10px" }}>
        <Box sx={{ background: "#00B998", width: "6px", height: "42px", borderRadius: "4px" }}></Box>
        <Box>
          <Typography fontSize="14px" fontWeight={500}>{t("common:GREAT")}</Typography>
          <Typography fontSize="14px" fontWeight={400} color="#6F6F7E">50-100%</Typography>
        </Box>
      </Box>
      <Box sx={{ alignItems: "center", display: "flex", gap: 1, mb: "10px" }}>
        <Box sx={{ background: "#EAC600", width: "6px", height: "42px", borderRadius: "4px" }}></Box>
        <Box>
          <Typography fontSize="14px" fontWeight={500}>{t("common:FINE")}</Typography>
          <Typography fontSize="14px" fontWeight={400} color="#6F6F7E">40-50%</Typography>
        </Box>
      </Box>
      <Box sx={{ alignItems: "center", display: "flex", gap: 1, mb: "10px" }}>
        <Box sx={{ background: "#FD9C15", width: "6px", height: "42px", borderRadius: "4px" }}></Box>
        <Box>
          <Typography fontSize="14px" fontWeight={500}>{t("common:NOTBAD")}</Typography>
          <Typography fontSize="14px" fontWeight={400} color="#6F6F7E">30-40%</Typography>
        </Box>
      </Box>
      <Box sx={{ alignItems: "center", display: "flex", gap: 1, mb: "10px" }}>
        <Box sx={{ background: "#D62B20", width: "6px", height: "42px", borderRadius: "4px" }}></Box>
        <Box>
          <Typography fontSize="14px" fontWeight={500}>{t("common:BADLY")}</Typography>
          <Typography fontSize="14px" fontWeight={400} color="#6F6F7E">0-30%</Typography>
        </Box>
      </Box>
    </Box>}
      placement="top-start">
      <Box display="flex" alignItems="center" gap={1} sx={{ color: "#8E8E93", cursor: "pointer" }}>
        <ErrorOutlineOutlinedIcon fontSize="small" />
      </Box>
    </Tooltip>
  )
}
