import CustomSearch from '@ui/Search'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Typography, useTheme, Divider, Autocomplete, TextField } from '@mui/material'
import { useParams } from 'react-router-dom';

import emptyMeeting from "@assets/images/emptyMeeting.png";
import { serviceCourse } from '@services/course';
import { getOnlyDateAndFormat } from '@utils/functions';


const statusOptions = [{ label: "Завершено", value: "FINISHED" }]

export const FinishedConference = () => {
    const { t } = useTranslation(["user", "common", "dictionary", "course"]);
    const { common } = useTheme().palette;
    const { courseId } = useParams()
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [searchName, setSearchName] = useState<string>("");
    const [statusState, setStatusState] = useState<any>({ label: "Завершено", value: "FINISHED" })
    const [conferenceList, setConferenceList] = useState<any>([]);
    const [allConferenceList, setAllConferenceList] = useState<any>([]);

    useEffect(() => {
        if (courseId) {
            serviceCourse.getMeetingAll(courseId || "", { status: "COMPLETED" })
                .then(res => setAllConferenceList(res.data))
        }
    }, [courseId])

    useEffect(() => {
        let resultAfterSearch = allConferenceList;
        if (searchName) {
            resultAfterSearch = allConferenceList.filter((conference) => conference.name.includes(searchName));
        }
        setConferenceList(resultAfterSearch);
    }, [searchName, allConferenceList])

    return (
        <Box sx={{ background: "#fff", padding: "15px", borderRadius: "6px", height: "550px" }}>
            <Box display="flex" gap={2} marginBottom="15px">
                <CustomSearch
                    placeholder={t('course:conference.SEARCH_BY_CONFERENCE_NAME')}
                    height="44px"
                    onChange={setSearchName}
                />
                <Box sx={{
                    border: `1px solid ${common.borderSecondary}`,
                    height: '40px',
                    width: '124px',
                    backgroundColor: 'white', borderRadius: '9px', display: 'flex', alignItems: 'center', px: '4px', cursor: 'pointer'
                }}
                    onClick={() => setOpenFilter(!openFilter)}
                >
                    <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "20px", color: "black", mr: '8px', ml: '4px' }}>{t('common:FILTER')}</Typography>
                    <TuneIcon />
                    {
                        openFilter ? (
                            <ArrowDropUpIcon sx={{ width: '30px', height: '30px' }} />
                        ) : (
                            <ArrowDropDownIcon sx={{ width: '30px', height: '30px' }} />
                        )
                    }
                </Box>
            </Box>
            {openFilter && <Box display="flex" alignItems="center" gap={3} marginBottom={"20px"}>
                <Typography>{t('course:conference.ACTIVE_CONFERENCES')}: </Typography>
                <Autocomplete options={statusOptions} getOptionLabel={(option) => `${option.label}`}
                    size="small"
                    defaultValue={statusState}
                    onChange={(e, value) => setStatusState(value)}
                    renderInput={(params) => (
                        <TextField {...params} label="" placeholder={t('user:STATUS')} sx={{ width: "250px" }} />
                    )} /></Box>}
            {conferenceList?.length > 0 ? conferenceList.map((conference) => {
                return (
                    <Box marginBottom="10px">
                        <Typography fontSize="16px" color={common.primaryColor}>{conference?.name}</Typography>
                        <Box display="flex" gap={1}>
                            <Typography>{t('course:theme.THEME')}: {conference?.title}</Typography>
                            <Box display="flex" alignItems="center">
                                <Box sx={{ background: "#D9D9D9", width: "5px", height: "5px", borderRadius: "5px", marginRight: "5px" }}></Box>
                                <Typography >{t('dictionary:ORGANIZER')}: {conference?.coordinatorUserName}</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" gap={1}>
                            <Typography>{t('course:listener.STATUS_CONFERENCE')}: </Typography>
                            <Box display="flex"
                                sx={{ background: "#BCBCBC" }}
                                alignItems="center"
                                borderRadius="6px"
                                padding={"2px 8px"}>
                                <Typography color="#fff">{t('course:conference.COMPLETED')}</Typography>
                            </Box>
                        </Box>
                        <Box display="flex">

                            <Typography color={"#708099"}>{getOnlyDateAndFormat(conference.startDate)}-{getOnlyDateAndFormat(conference.endDate)}</Typography>
                        </Box>
                        <Divider />
                    </Box>
                )
            }) : <Box margin="0 auto" width="500px" paddingTop="50px">
                <img src={emptyMeeting} width="550px" alt="emptyMeeting" />
                <Typography marginLeft="150px">{t('course:conference.NO_CONFERENCES_YET')}</Typography>
            </Box>}
        </Box>
    )
}