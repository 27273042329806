import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Formik, Form as FormikForm } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import RegisterToCourseModal from "@components/modals/RegisterToCourseModal";
import { IUserFilter, IUserSearchItem } from "@interfaces/index";
import { Divider } from "@mui/material";
import { serviceMember } from "@services/member";
import { serviceUser } from "@services/user";
import CustomButton from "@ui/Button";
import FormikAutocomplete from "@ui/formik/Autocomplete";
import FormikInput from "@ui/formik/Input";
import { participantRoles } from "@utils/enums";
import { DTO, initialDto, userFilterInitialDto } from "../models";
import { notRegisteredMember } from "../validation";
import Message from "@components/Message";

const groupEducator = ["TUTOR", "ASSISTANT", "EXAMINER"];

export const AddNotRegisteredFreeParticipants = ({
  handleToggleModal,
  // updateParticipantList,
  subdivisionIds,
  eduProgramIds,
  studyClasses,
  refetch,
}) => {
  const { t } = useTranslation(["common", "user", "course"]);
  const { courseId } = useParams();
  const { common } = useTheme().palette;
  const [userFilter, setUserFilter] =
    useState<IUserFilter>(userFilterInitialDto);
  const [userList, setUserList] = useState<IUserSearchItem[]>([]);
  // const [disabledOptions, setDisabledOptions] = useState<string[]>([]);
  const [isRegisteredMember, setIsRegisteredMember] = useState(false);
  const [isMemberInClass, setIsMemberInClass] = useState(false);
  const [openRegisterToCourseModal, setOpenRegisterToCourseModal] =
    useState<boolean>(false);
  const [registeredMemberInfo, setRegisteredMemberInfo] = useState<any>(null);
  const [userEmail, setUserEmail] = useState(null);
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<DTO>(() => {
    if (courseId) return { ...initialDto, courseId };
    return initialDto;
  });

  useEffect(() => {
    setIsRegisteredMember(false);
    setIsMemberInClass(false);
  }, [userEmail]);

  useEffect(() => {
    if (studyClasses?.id) {
      if (userFilter.roleCode) {
        serviceMember
          .getNonMemberList(studyClasses.id, userFilter)
          .then((res) => {
            if (res.status === 200) setUserList(res.data);
          });
      }
    }
  }, [studyClasses.id, userFilter]);

  return (
    <Formik
      initialValues={initialData}
      enableReinitialize
      validationSchema={notRegisteredMember(t)}
      onSubmit={(values: DTO) => {
        if (isRegisteredMember) {
          serviceMember
            .sendInvitationMember({
              ...values,
              email: values.email?.trim(),
              classId: studyClasses.id,
              registered: true,
            })
            .then((res) => {
              if (res.status === 200) {
                // updateParticipantList(res.data);
                handleToggleModal(
                  false,
                  t("course:messages.PARTICIPANTS_ADDED")
                );
                refetch();
              }
            });
        } else {
          serviceMember
            .sendInvitationMember({
              ...values,
              email: values.email?.trim(),
              classId: studyClasses.id,
              registered: false,
            })
            .then((res) => {
              if (res.status === 200) {
                // updateParticipantList(res.data);
                handleToggleModal(
                  false,
                  t("course:messages.PARTICIPANTS_ADDED")
                );
                refetch();
              }
            })
            .catch((err) => {
              if (err.response.data.code === "MEMBER_EXISTS") {
                setIsMemberInClass(true);
                setOpenRegisterToCourseModal(false);
                serviceUser
                  .getFullUserInfoByUserName(values.email || "")
                  .then((res) => {
                    if (res.status === 200) {
                      setRegisteredMemberInfo(res.data);
                    }
                  });
              }
              if (err.response.data.code === "USERNAME_EXISTS") {
                setIsRegisteredMember(true);
                setOpenRegisterToCourseModal(false);
                serviceUser
                  .getFullUserInfoByUserName(values.email || "")
                  .then((res) => {
                    if (res.status === 200) {
                      setRegisteredMemberInfo(res.data);
                    }
                  });
              }
            });
        }
      }}
    >
      {({ values, submitForm, isValid, dirty }) => (
        <FormikForm>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            {messageOpen && (
              <Message
                type="error"
                message={t(
                  "course:messages.NUMBER_OF_ADD_PARTICIPANTS_EXCEEDS_MAX",
                  {
                    capacity: studyClasses?.capacity,
                  }
                )}
                setMessageOpen={setMessageOpen}
              />
            )}
            <FormikAutocomplete
              label={t("user:ROLE")}
              name="roleType"
              options={participantRoles}
              // disabledOptions={disabledOptions}
              clearable={false}
              // onChange={(value: any) => handleRoleChange(value.id, values)}
              isRequired
              isInputEnterable={false}
            />
            <FormikInput
              label={t("user:EMAIL")}
              placeholder={t("user:ENTER_EMAIL")}
              name="email"
              isRequired
              handleChange={(value) => setUserEmail(value.target.value.trim())}
            />
            {isRegisteredMember && (
              <Box
                sx={{
                  background: "#FF9500",
                  padding: "16px",
                  borderRadius: "6px",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  {t("user:messages.EMAIL_ALREADY_REGISTERED", {
                    lastName: registeredMemberInfo?.lastName,
                    firstName: registeredMemberInfo?.firstName,
                  })}
                </Typography>
                <Box display="flex" gap={1} mt="12px">
                  <CustomButton
                    backgroundColor={common.btnCancel}
                    fontColor={common.primaryColor}
                    borderRadius="6px"
                    height="35px"
                    width="80px"
                    sx={{
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    onClick={() => handleToggleModal(false)}
                  >
                    {t("common:NO")}
                  </CustomButton>
                  <CustomButton
                    backgroundColor={common.primaryColor}
                    fontColor={common.fontWhite}
                    borderRadius="6px"
                    height="35px"
                    width="80px"
                    onClick={() =>
                      setOpenRegisterToCourseModal((prev) => !prev)
                    }
                    sx={{
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    {t("common:YES")}
                  </CustomButton>
                </Box>
              </Box>
            )}
            {isMemberInClass && (
              <Box
                sx={{
                  background: "#FF9500",
                  padding: "16px",
                  borderRadius: "6px",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  {t("user:messages.EMAIL_ALREADY_REGISTERED", {
                    lastName: registeredMemberInfo?.lastName,
                    firstName: registeredMemberInfo?.firstName,
                  })}
                </Typography>
              </Box>
            )}
            <FormikInput
              label={t("user:NAME")}
              placeholder={t("user:ENTER_NAME")}
              name="name"
              isRequired
            />
            <FormikInput
              label={t("user:LAST_NAME")}
              placeholder={t("user:ENTER_LAST_NAME")}
              name="lastName"
              isRequired
            />
            <FormikInput
              label={t("user:PATRONYMIC")}
              placeholder={t("user:ENTER_PATRONYMIC")}
              name="patronymic"
            />
            <Divider />
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <CustomButton
                backgroundColor={common.btnCancel}
                fontColor={common.primaryColor}
                borderRadius="6px"
                height="40px"
                sx={{
                  alignItems: "center",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => handleToggleModal(false)}
              >
                {t("actions.CANCEL")}
              </CustomButton>
              <CustomButton
                backgroundColor={common.primaryColor}
                fontColor={common.fontWhite}
                borderRadius="6px"
                height="40px"
                disabled={!(isValid && dirty)}
                onClick={() => setOpenRegisterToCourseModal((prev) => !prev)}
                sx={{
                  alignItems: "center",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {t("actions.SEND_INVITATION")}
              </CustomButton>
            </Box>
          </Box>
          {openRegisterToCourseModal && (
            <RegisterToCourseModal
              openModal={openRegisterToCourseModal}
              message={
                t("user:messages.INVITATION_SENT_ATTENTION")
              }
              handleToggleModal={() => setOpenRegisterToCourseModal(false)}
              registerToCourse={submitForm}
              success={false}
              attention={false}
            />
          )}
        </FormikForm>
      )}
    </Formik>
  );
};
