import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
  Box, Typography, useTheme, AlertColor, TextField, InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  SxProps,
  Theme
} from '@mui/material';
import { useSelector } from "react-redux";
import Message from '@components/Message';
import SearchIcon from "@mui/icons-material/Search";
import CustomButton from "@ui/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddChartModal from './AddOrEditChartModal';
import { serviceSchedule } from '@services/schedule';
import { TablePagination } from "@mui/material";
import useLocalPagination from "@hooks/useLocalPagination";
import { formatDateWithHourMinute } from '@utils/functions';
import Breadcrumbs from '@components/Breadcrumbs';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";

interface IProps {
  id?: number;
  name: string;
  externalId: string;
  status: string | boolean;
  startDate: string;
  endDate: string;
  numberOfCourses?: number;
  canUpdate: boolean
}
const initialDTO = [{
  name: '',
  externalId: '',
  status: '',
  startDate: '',
  endDate: '',
  numberOfCourses: 0,
  canUpdate: false
},
]
const Charts = () => {
  const { t } = useTranslation(["common", "enum", "dictionary", "course", "user", "generalSetting"]);
  const { common } = useTheme().palette;
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { authorityInfo: { role } } = profile;
  const [messageOpen, setMessageOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messageType, setMessageType] = useState<AlertColor>('success')
  const [isAddChartModalOpen, setIsAddChartModalOpen] = useState<boolean>(false)
  const [initialData, setInitialData] = useState<IProps[]>(initialDTO)
  const [isChartEditing, setIsChartEditing] = useState<boolean>(false)
  const [currentChart, setCurrentChart] = useState<IProps | null>(null)
  const [totalElements, setTotalElements] = useState<number>(0)
  const [search, setSearch] = useState<string>('')
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | null>(null)
  const { setBreadcrumbs } = useBreadcrumbs();

  const baseTableRowCellStyle: SxProps<Theme> = {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#7D7D7D",
    // mr: "16px",
  }
  const handleSaveSchedule = () => {
    getChartList()
  }
  const handleToggleAddChartModal = (modalState: boolean) => {
    // значение статуса переводится в string значения после закрытия модалки
    if (currentChart && !modalState) {
      const updatedData = initialData.map(chart => {
        if (chart.id === currentChart.id) {
          return { ...chart, status: chart?.status ? 'ARCHIVED' : 'SAVED' }
        }
        return chart
      })
      setInitialData(updatedData);
    }
    setIsAddChartModalOpen(modalState)
  };

  const {
    rowsPerPage, page,
    handleChangePage, handleChangeRowsPerPage,
  } = useLocalPagination<IProps>()

  const deleteChart = () => {
    return serviceSchedule.deleteSchedule(deleteId || '').then(res => {
      if (res.status === 200) {
        if (res.data.message === 'used in courses') {
          setMessageOpen(true)
          setMessageType('warning')
          setMessage(t('common:messages.RECORD_NOT_DELETED'))
        } else {
          setInitialData(prev => prev.filter(chart => chart.id !== deleteId))
          setMessageOpen(true)
          setMessageType('success')
          setMessage(t('common:messages.SCHEDULE_SUCCESSFULLY_DELETED'))
        }
        setTimeout(() => {
          setMessageOpen(false)
        }, 3000)
      }
    }).catch((e) => {
      setMessageOpen(true)
      setMessageType('error')
      setMessage(t('common:messages.CANT_DELETE_SCHEDULE'))
      setTimeout(() => {
        setMessageOpen(false)
      }, 3000)
    }).finally(() => {
      handleToggleDeleteModal(false)
      window.scrollTo({ top: 0, behavior: 'smooth' });
    })
  }

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteChart,
    modalTitle: t(`common:DELETE_ITEM_CONFIRMATION_TEXT`),
  })

  function getChartList() {
    serviceSchedule.postScheduleList({
      pageNumber: page,
      size: rowsPerPage,
      filter: { search }
    }).then(res => {
      if (res.status === 200) {
        setInitialData(res.data.dtoList);
        setTotalElements(res.data.totalElements || 0)
      }
    })
  }

  useEffect(() => {
    getChartList()
    // eslint-disable-next-line
  }, [page, rowsPerPage, search, deleteModalOpen])

  const searchByName = (searchValue: string) => {
    setSearch(searchValue);
  };
  const handleToggleDeleteModal = (modalState: boolean, cId?: number | null) => {
    if (modalState) {
      if (cId) {
        setDeleteId(cId);
      }
    } else {
      setDeleteId(null);
    }
    setDeleteModalOpen(modalState);
  }

  useEffect(() => {
    setBreadcrumbs("charts", [{
      level: 1,
      page: 'settings',
      label: "course:SETTINGS",
      path: '/settings',
      tabNum: null
    }, {
      level: 2,
      page: 'charts',
      label: t('generalSetting:schedule.SCHEDULES'),
      path: null,
      tabNum: null
    }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      {DeleteModal}
      {role === 'LOCAL_ADMIN' &&
        <Box sx={{ display: "flex", alignItems: 'center', mb: '24px' }}>
          <Breadcrumbs id="charts" />
        </Box>
      }
      <Typography sx={{ fontSize: '36px', fontWeight: 500, mb: '24px' }}>
        {t('generalSetting:schedule.SCHEDULES')}
      </Typography>
      {
        messageOpen &&
        <Box sx={{ mb: 2 }}>
          <Message type={messageType} message={message} setMessageOpen={setMessageOpen} />
        </Box>
      }
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', border: `1px solid ${common.borderSecondary}`, borderRadius: '9px', backgroundColor: 'white', p: '32px', minHeight: '600px' }}>
        <Box sx={{ display: "flex", width: '100%', flexDirection: { lg: 'row', xs: 'column' }, gap: { lg: 0, xs: 2 }, justifyContent: { lg: "space-between", xs: 'center' } }}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            color="primary"
            onChange={(e) => searchByName(e.target.value)}
            sx={{
              width: { lg: "100%", xs: '100%' }, mr: "24px", justifyContent: "flex-start", '& label.Mui-focused': { color: common.primaryColor },
              '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': { borderColor: common.primaryColor },
                '&.Mui-focused fieldset': { borderColor: common.primaryColor }
              }
            }}
            placeholder={t('common:placeholders.SEARCH_BY_NAME_AND_ID')}
            name="searchValue"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box sx={{ backgroundColor: common.primaryColor, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px', mr: '-14px', borderRadius: '6px' }}>
                    <SearchIcon sx={{ color: '#FFFFFF', width: '26px', height: '26px' }} />
                  </Box>
                </InputAdornment>
              ),
            }}
          />
          <CustomButton
            backgroundColor={common.primaryColor}
            fontColor={common.fontWhite}
            width='20%'
            borderRadius="6px"
            height="38px"
            sx={{
              alignItems: "center",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "130%"
            }}
            onClick={() => {
              setIsChartEditing(false)
              handleToggleAddChartModal(true)
            }}
          >
            {t('common:actions.ADD_SCHEDULE')}
          </CustomButton>
        </Box>
        <Box sx={{ mt: "40px", width: '100%', display: 'table', tableLayout: 'fixed' }
        }>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      ...baseTableRowCellStyle,
                      ml: "8px",
                    }}
                  >
                    {t('course:task.NAME')}
                  </TableCell>
                  <TableCell
                    sx={baseTableRowCellStyle}
                  >
                    {t('generalSetting:schedule.EXTERNAL_ID')}
                  </TableCell>
                  <TableCell
                    sx={baseTableRowCellStyle}
                  >
                    {t('user:STATUS')}
                  </TableCell>
                  <TableCell
                    sx={baseTableRowCellStyle}
                  >
                    {t('generalSetting:schedule.COURSE_PERIOD')}
                  </TableCell>
                  <TableCell
                    sx={baseTableRowCellStyle}
                  >
                    {t('generalSetting:schedule.NUMBER_COURSES')}
                  </TableCell>
                  <TableCell
                    sx={baseTableRowCellStyle}
                  >
                    {t('dictionary:ACTION')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {initialData.map((chart, index) => {
                  return (
                    <TableRow key={`${chart.id}_${index}`}>
                      <TableCell sx={{
                        ...baseTableRowCellStyle,
                        ml: "8px",
                      }}>
                        {chart.name}
                      </TableCell>
                      <TableCell
                        sx={baseTableRowCellStyle}
                      >
                        {chart.externalId}
                      </TableCell>
                      <TableCell
                        sx={baseTableRowCellStyle}
                      >
                        {t(`enum:${chart.status}`)}
                      </TableCell>
                      <TableCell
                        sx={baseTableRowCellStyle}
                      >
                        {formatDateWithHourMinute(chart.startDate)}-{formatDateWithHourMinute(chart.endDate)}
                      </TableCell>
                      <TableCell
                        sx={baseTableRowCellStyle}
                      >
                        {chart.numberOfCourses}
                      </TableCell>
                      <TableCell
                        sx={baseTableRowCellStyle}
                      >
                        <Box sx={{ display: 'flex', gap: '10px', pr: '10px' }}>
                          <EditIcon color="info" sx={{ cursor: "pointer", color: common.primaryColor }} onClick={() => {
                            setIsChartEditing(true)
                            handleToggleAddChartModal(true)
                            setCurrentChart(chart)
                          }} />
                          <DeleteOutlineIcon
                            color="error"
                            sx={{ cursor: "pointer" }}
                            onClick={() => { setDeleteId(chart.id || null); openDeleteConfirmModal(chart.id?.toString() || '') }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('enum:LINES_PER_PAGE')}
                labelDisplayedRows={
                  ({ from, to, count }) => t('enum:TABLE_PAGES', { from, to, count })
                }
              />
            </Box>
          </TableContainer>
          {isAddChartModalOpen &&
            <AddChartModal
              modalOpen={isAddChartModalOpen}
              handleToggleModal={handleToggleAddChartModal}
              isEditing={isChartEditing}
              selectedChart={currentChart}
              onSaveSuccess={handleSaveSchedule}
              setMessage={setMessage}
              setMessageType={setMessageType}
              setMessageOpen={setMessageOpen}
            />
          }
        </Box >
      </Box>
    </Box >
  )
}

export default Charts

