export interface IOrganization {
  id: number | null;
  nameKz: string | null;
  nameRu: string | null;
  nameEn: string | null;
  isDisabled: boolean;
  serverAddress: string | null;
  branchTypeId?: string | number | null;
  branchType?: IOrgType;
  bin: string | null;
  availableCourseTypes: Array<string | number>;
  hasOwnDomain: boolean;
  about: string | null;
  profilePhotoId: string | null;
  youtube?: string | null;
  facebook?: string | null;
  instagram?: string | null;
}

export const initialDto: IOrganization = {
  id: null,
  nameKz: null,
  nameRu: null,
  nameEn: null,
  isDisabled: false,
  serverAddress: null,
  branchTypeId: null,
  bin: null,
  availableCourseTypes: [],
  hasOwnDomain: false,
  about: null,
  profilePhotoId: null,
};

interface IFilter {
  search: string;
  isDisabled: boolean;
}

export const initialFilter: IFilter = {
  search: "",
  isDisabled: false,
};

export interface IOrgType {
  isDisabled: boolean;
  id: number;
  nameKz: string;
  nameRu: string;
  nameEn: string;
}

export interface LocalAdminIntegrationProps {
  integrations: {
    id: number;
    branchId: number;
    isActive: boolean;
    integration: {
      id: number;
      name: string;
      description: string;
      type: string;
      viewType: string;
      isActive: boolean;
      code: string;
    };
  }[];
}
export interface SuperAdminIntegrationProps {
  integrations: {
    id: number;
    name: string;
    description: string;
    type: string;
    viewType: string;
    isActive: boolean;
    code: string;
  }[];
}
