import axios from "@utils/axios";

export const serviceUser = {
  getUserList: (bodyData: any) => axios.post("/api/user/v1/findAll", bodyData),
  getUserListByFilter: (bodyData: any) =>
    axios.post("/api/user/v1/short/find-by-filter", bodyData),
  getAllUserList: (search: string, branchId: number) =>
    axios.get(
      `/api/user/v1/list/findAll?search=${search}&branchId=${branchId}`
    ),
  getFullUserInfoByUserName: (username: string) =>
    axios.get(
      `/api/user/v1/user-full-info/find-by-username?username=${username}`
    ),
  createUser: (bodyData: any) => axios.post("/api/user/v1/create", bodyData),
  updateUser: (bodyData: any) => axios.post("/api/user/v1/update", bodyData),
  addRoleFreeUser: (role: string) =>
    axios.post(`/api/user/v1/add-role?role=${role}`),
  deleteUser: (id: any) => axios.delete(`/api/user/v1/delete/${id}`),
  saveProfilePhoto: (userId: string, photoId: string) =>
    axios.post(
      `/api/user/v1/profile/photo/save?userId=${userId}&photoId=${photoId}`
    ),
  generatePassword: (bodyData: Array<string>, sendEmail: boolean) =>
    axios.post(
      `/api/user/v1/generate/passwords?sendEmail=${sendEmail}`,
      bodyData
    ),
  getUserInfoById: (id: string) => axios.get(`/api/user/v1/${id}`),
  getUserFullNameById: (id: any) =>
    axios.get(`/api/user/v1/get/fullname/${id}`),
  changeEmail: (bodyData: any) =>
    axios.post("/api/user/v1/change-before/EMAIL", bodyData),
  confirmEmail: (bodyData: any) =>
    axios.post("/api/user/v1/change-confirm/EMAIL", bodyData),
  changePassword: (bodyData: any) =>
    axios.post("/api/user/v1/change/password", bodyData),
  getGeneralSettingsTypeOptionByCode: (code: string) =>
    axios.get(`/api/user/common-option/${code}`),
  createGeneralSettingsTypeOptionByCode: (bodyData: any) =>
    axios.post(`/api/user/common-option/${bodyData.code}`, bodyData),
  updateAchievments: (fileId: string, bodyData: any) =>
    axios.put(`/api/user/v1/change-achievements/${fileId}`, bodyData),
  getNumOfListeners: () => axios.get(`/api/user/v1/listeners-sum`),
  getNumOfTutors: () => axios.get(`/api/user/v1/educators-sum`),
  registerUser: (registrationType: string, bodyData: any) =>
    axios.post(
      `/api/user/register?registrationType=${registrationType}`,
      bodyData
    ),
  registerConfirm: (bodyData: any) =>
    axios.post(`/api/user/register/confirm`, bodyData),
  registerResendCode: (username: any, registrationType: any) =>
    axios.post(
      `/api/user/register/resend-confirmation-code?username=${username}&registrationType=${registrationType}`
    ),
  getTotalUser: () => axios.get(`/api/user/v1/total-count-by-branch`),
  // public apis
  getUserByIdPublic: (userId: string) =>
    axios.get(`/api/user/public/user/${userId}`),
  downloadUserList: (bodyData: any) =>
    axios.post("api/user/v1/build-xlsx", bodyData, { responseType: "blob" }),
};
