import CloseIcon from "@mui/icons-material/Close";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SearchIcon from "@mui/icons-material/Search";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  AlertColor,
  Box,
  InputAdornment,
  Paper,
  Popover,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { capitalize, get } from "lodash";
import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import AvatarPicture from "@components/AvatarPicture";
import AvatarViaUserId from "@components/AvatarViaUserId";
import UserEmail from "@components/UserEmail";
import FileViewerModal from "@components/modals/FileViewerModal";
import YesOrNoModal from "@components/modals/YesOrNoModal";
import {
  CourseFormatType,
  CourseRole,
  CourseStatus,
  DictionaryCode,
  FlowStatus,
  IParticipant,
  Role,
  TypeCode,
} from "@interfaces/index";
import { DTO } from "@pages/profile/models";
import serviceAccount from "@services/account";
import { serviceCourse } from "@services/course";
import { serviceDict } from "@services/dictionary";
import {
  serviceMember,
  useGetPaginatedMemberListQuery,
} from "@services/member";
import { StateSchema } from "@store/StateSchema";
import CustomAutocomplete from "@ui/Autocomplete";
import CustomButton from "@ui/Button";
import Pagination from "@ui/Pagination";
import {
  participantRoles,
  participantStatus,
  participantStatusObject,
} from "@utils/enums";
import { formatDateWithHourMinute, getLangDictById, getOnlyDateAndFormat } from "@utils/functions";
import { useSelector } from "react-redux";
import { AddFreeParticipantsModal } from "./Modal/AddFreeParticipantsModal";
import AddParticipantsModal from "./Modal/AddParticipantsModal";
interface IProps {
  name: string;
  courseId: string;
  groupdId: string;
  classId: string | null;
  roleType: string | null;
  branchId: number;
  updateDate: string;
  setMessage: (message: string) => void;
  setMessageOpen: (isOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  studyClasses?: any;
}

const AllParticipants = ({
  courseData,
  setMessage,
  setMessageType,
  setMessageOpen,
  studyClasses,
}) => {
  const { courseId } = useParams();
  const { common } = useTheme().palette;
  const { t } = useTranslation(["user", "course", "enum", "common", "profile"]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const profile = useSelector(
    (store: StateSchema) => store.account.profileInfo
  );
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [activeItem, setActiveItem] = useState<IParticipant>();
  const open = Boolean(anchorEl);
  const openPopover = Boolean(popoverAnchorEl);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [filter, setFilter] = useState<Partial<IProps>>({
    name: "",
    classId: studyClasses?.id,
  });
  const [addParticipantModalOpen, setAddParticipantModalOpen] = useState(false);
  const [addFreeParticipantModalOpen, setAddFreeParticipantModalOpen] =
    useState(false);
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isAuthor = courseData?.authors?.some(
    (author) => author.userId === courseData?.userId
  );
  const isLocalAdmin = courseData?.roleType === Role.LOCAL_ADMIN;
  // const checkUserWithSpecificRoleExists = useCallback((list: Array<any>, role: string) => {
  //   return list.some((x: any) => x.roleType === role);
  // }, []);
  const isClosed = courseData?.status === CourseStatus.CLOSED;
  const [profileInfo, setProfileInfo] = useState<DTO | null>(null);
  const [eduProgramId, setEduProgramId] = useState<number | null>(null);
  const [eduProgramName, setEduProgramName] = useState<string>("");
  const [subdivisionId, setSubdivisionId] = useState<number | null>(null);
  const [subdivisionName, setSubdivisionName] = useState<string>("");
  const lang = localStorage.getItem("i18nextLng") || "kz";
  const [isPublicProfileOpen, setIsPublicProfileOpen] =
    useState<boolean>(false);
  const [showFullText, setShowFullText] = useState<boolean>(false);
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [currentImage, setCurrentImage] = useState<any>();
  const [openDeactivateModal, setOpenDeactivateModal] =
    useState<boolean>(false);
  const [openRevokeInvitationModal, setOpenRevokeInvitationDeactivateModal] =
    useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [memberInfo, setMemberInfo] = useState<any>(null);
  const isMemberAddParticipants =
    memberInfo?.roleType === CourseRole.TUTOR ||
    memberInfo?.roleType === CourseRole.CURATOR;

  const toggleShowMore = () => {
    setShowFullText(!showFullText);
  };

  useEffect(() => {
    if (studyClasses.id) {
      setFilter((prev) => ({ ...prev, classId: studyClasses.id }));
      serviceCourse.getCourseMe(studyClasses.id).then((res) => {
        if (res.status === 200) setMemberInfo(res.data);
      });
    }
  }, [studyClasses.id]);

  useEffect(() => {
    const handleScroll = () => {
      if (open) {
        handleClose();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [open]);

  const { data: participants, refetch: refetchParticipants } =
    useGetPaginatedMemberListQuery(
      {
        pageNumber: currentPage,
        size: rowsPerPage,
        filter,
      },
      {
        skip: !studyClasses.id,
      }
    );

  const searchByName = (searchValue: string) => {
    const new_filter = { ...filter, name: searchValue };
    setCurrentPage(0);
    setFilter(new_filter);
  };

  const handleChangePage = (newPage: number) => setCurrentPage(newPage);

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
    setCurrentPage(0);
  };

  // api changed, fix later
  const updateStatus = (status: string) => {
    if (activeItem?.id && courseId && activeItem.classId) {
      serviceMember
        .updateStatus(activeItem?.id.toString(), activeItem.classId, status)
        .then((res) => {
          if (res.status === 200) {
            refetchParticipants();
          }
          if (status === participantStatusObject.BLOCKED) {
            setOpenDeactivateModal(false);
          }
          if (status === participantStatusObject.INVITATION_REJECTED) {
            setOpenRevokeInvitationDeactivateModal(false);
          }
        })
        .finally(() => handleClose());
    }
  };

  const handleClick = (event: MouseEvent<HTMLElement>, item: IParticipant) => {
    if (!isClosed) {
      setAnchorEl(event.currentTarget);
      setActiveItem(item);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setActiveItem(undefined)
  };

  const handlePopoverOpen = (userId: string, profile: boolean) => {
    serviceAccount.getProfileFullInfo(userId).then((res) => {
      if (res.status === 200) {
        setProfileInfo(res.data);
        setEduProgramId(
          res.data.userRoles.find(
            (userRole) => userRole.orgId === courseData?.branchId
          ).eduProgramId
        );
        setSubdivisionId(
          res.data.userRoles.find(
            (userRole) => userRole.orgId === courseData?.branchId
          ).subdivisionId
        );
      }
    });
    setPopoverAnchorEl(anchorEl);
    setAnchorEl(null);
    setIsPublicProfileOpen(profile);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
    setSubdivisionName("");
    setEduProgramName("");
  };

  useEffect(() => {
    if (subdivisionId) {
      serviceDict
        .getDictionary(DictionaryCode.SUBDIVISION, subdivisionId)
        .then((res) => {
          if (res.status === 200) {
            setSubdivisionName(get(res.data, `name${capitalize(lang)}`));
          }
        });
    }
    if (eduProgramId) {
      serviceDict
        .getDictionary(DictionaryCode.EDU_PROGRAM, eduProgramId)
        .then((res) => {
          if (res.status === 200) {
            setEduProgramName(get(res.data, `name${capitalize(lang)}`));
          }
        });
    }
  }, [eduProgramId, lang, subdivisionId]);

  const handleToggleImageViewer = (modalState: boolean, imageFile?: any) => {
    if (imageFile) setCurrentImage(imageFile);
    setOpenImageViewer(modalState);
  };

  const handleToggleAddParticipantModal = (
    modalState: boolean,
    message?: string
  ) => {
    courseData.typeCode === TypeCode.FREE
      ? setAddFreeParticipantModalOpen(modalState)
      : setAddParticipantModalOpen(modalState);
    if (message) {
      setMessage(t(message));
      setMessageOpen(true);
      setMessageType("success");
      setTimeout(() => {
        setMessageOpen(false);
      }, 3000);
    }
  };

  // const updateParticipantList = (list: Array<IParticipant>) => {
  //   if (checkUserWithSpecificRoleExists(list, CourseRole.TUTOR)) setTutorAddDisabled(true);
  //   if (checkUserWithSpecificRoleExists(list, 'CURATOR')) setCuratorAddDisabled(true);
  //   const currentLength = participants?.paticipantList.length;
  //   const countLeft = currentLength && (rowsPerPage - currentLength);
  //   // setParticipantList([...participants?.paticipantList].concat(list.slice(0, countLeft)));
  //   setCount(count + list.length);
  //   setParticipantsCount(count + list.length);
  //   handleToggleAddParticipantModal(false);
  // };

  const baseTableRowCellStyle: SxProps<Theme> = {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#7D7D7D",
    mr: "16px",
  };

  const menuItemHoverStyle = {
    backgroundColor: common.primaryColor,
    color: common.fontWhite,
  };

  const handleChangeRole = (valueObj: any) => {
    if (valueObj) {
      setFilter({
        ...filter,
        roleType: valueObj,
      });
    } else {
      setFilter({
        ...filter,
        roleType: null,
      });
    }
    setCurrentPage(0);
  };

  const deleteParticipants = () => {
    return serviceMember
      .deleteMember(activeItem?.id || "")
      .then((res) => {
        if (res.status === 200) {
          refetchParticipants();
          setOpenDeleteModal(!openDeleteModal);
          handlePopoverClose();
          setAnchorEl(null);
          setMessageOpen(true);
          setMessage(t("common:messages.PARTICIPANTS_DELETED"));
          setMessageType("success");
          setTimeout(() => {
            setMessageOpen(false);
          }, 3000);
        } else {
          setMessage(t("common:messages.RECORD_NOT_DELETED"));
          setMessageOpen(true);
          setOpenDeleteModal(!openDeleteModal);
          handlePopoverClose();
          setAnchorEl(null);
          setMessageType("error");
          setTimeout(() => {
            setMessageOpen(false);
          }, 10000);
        }
      })
      .finally(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: { lg: "row", xs: "column" },
          gap: { lg: 0, xs: 2 },
          justifyContent: { lg: "space-between", xs: "center" },
          mt: "24px",
        }}
      >
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          color="primary"
          sx={{
            flex: "2",
            mr: "24px",
            justifyContent: "flex-start",
            "& label.Mui-focused": { color: common.primaryColor },
            "& .MuiInput-underline:after": {
              borderBottomColor: common.primaryColor,
            },
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": { borderColor: common.primaryColor },
              "&.Mui-focused fieldset": { borderColor: common.primaryColor },
            },
          }}
          placeholder={t("common:placeholders.SEARCH_BY_FULL_NAME")}
          name="searchValue"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box
                  sx={{
                    backgroundColor: common.primaryColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "40px",
                    height: "40px",
                    mr: "-14px",
                    borderRadius: "6px",
                  }}
                >
                  <SearchIcon
                    sx={{ color: "#FFFFFF", width: "26px", height: "26px" }}
                  />
                </Box>
              </InputAdornment>
            ),
          }}
          onChange={(e) => searchByName(e.target.value)}
        />
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: { xs: "column", sm: "row" },
            flex: "1"
          }}
        >
          <Box
            sx={{
              flex: "1",
            }}
          >
            <CustomAutocomplete
              name="roleTypes"
              placeholder={t("user:ROLE")}
              options={participantRoles}
              onChange={(e, value) => handleChangeRole(value)}
            />
          </Box>

          {courseData?.formatType !== CourseFormatType.ASYNC && (
            <CustomButton
              backgroundColor={common.primaryColor}
              fontColor={common.fontWhite}
              width={`${matchesDownSm ? "200px" : "50%"}`}
              borderRadius="6px"
              height="38px"
              disabled={
                isClosed ||
                (!isAuthor && !isLocalAdmin && !isMemberAddParticipants) ||
                !!courseData?.externalId ||
                !studyClasses.id
              }
              onClick={() => handleToggleAddParticipantModal(true)}
            >
              {t("common:actions.ADD_PARTICIPANT")}
            </CustomButton>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          mt: "40px",
          width: "100%",
          display: "table",
          tableLayout: "fixed",
        }}
      >
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    ...baseTableRowCellStyle,
                    ml: "8px",
                  }}
                >
                  {t("FULL_NAME")}
                </TableCell>
                <TableCell sx={baseTableRowCellStyle}>{t("ROLE")}</TableCell>
                <TableCell sx={baseTableRowCellStyle}>{t("EMAIL")}</TableCell>
                <TableCell sx={baseTableRowCellStyle}>{t("STATUS")}</TableCell>
                <TableCell sx={baseTableRowCellStyle}>
                  {t("enum:ADDITION_DATE")}
                </TableCell>
                <TableCell sx={baseTableRowCellStyle}>
                  {t("enum:LAST_ACTIVITY")}
                </TableCell>
                <TableCell sx={baseTableRowCellStyle}> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {participants?.dtoList?.map((user, index) => {
                return (
                  <TableRow key={`${index}_${user.id}`}>
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        mr: "16px",
                        ml: "8px",
                        width: "235px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1.5,
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <AvatarViaUserId userId={user.userId} sxProps={null} />
                        <Typography
                          sx={{
                            fontWeight: "500",
                            lineHeight: "20px",
                            width: "180px",
                          }}
                        >
                          {user.userFullName}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={baseTableRowCellStyle}>
                      {t(user.roleType)}
                    </TableCell>
                    <TableCell sx={baseTableRowCellStyle}>
                      <UserEmail id={user.userId} />
                    </TableCell>
                    <TableCell sx={baseTableRowCellStyle}>
                      {t(
                        `user:${participantStatus.find(
                          (status) => status.id === user.status
                        )?.text
                        }`
                      )}
                    </TableCell>
                    <TableCell sx={baseTableRowCellStyle}>
                      {getOnlyDateAndFormat(user.createdAt)}
                    </TableCell>
                    <TableCell sx={baseTableRowCellStyle}>{user?.lastActivityDate ? formatDateWithHourMinute(user?.lastActivityDate) : "-"}</TableCell>
                    <TableCell>
                      <Box
                        id="fade-button"
                        aria-controls={open ? "fade-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(e) => handleClick(e, user)}
                        sx={{ cursor: "pointer" }}
                      >
                        <MoreVertIcon />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Pagination
              count={participants ? participants.totalElements : 0}
              page={currentPage}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </TableContainer>
      </Box>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        disableScrollLock
      >
        {activeItem?.status === participantStatusObject.BLOCKED &&
          profile.pltaId !== activeItem?.userId && (
            <MenuItem
              disabled={
                !!activeItem?.externalId || (!isAuthor && !isLocalAdmin)
              }
              onClick={() => updateStatus(participantStatusObject.ACTIVE)}
              sx={{ "&:hover": menuItemHoverStyle }}
            >
              {t("common:actions.ACTIVATE")}
            </MenuItem>
          )}
        {activeItem?.status === participantStatusObject.ACTIVE &&
          profile.pltaId !== activeItem?.userId &&
          courseData?.formatType === "SYNC" && (
            <MenuItem
              disabled={
                !!activeItem?.externalId || (!isAuthor && !isLocalAdmin)
              }
              onClick={() => {
                setOpenDeactivateModal(true);
                handleClose();
              }}
              sx={{ "&:hover": menuItemHoverStyle }}
            >
              {t("common:actions.DEACTIVATE")}
            </MenuItem>
          )}
        {activeItem?.roleType !== "LISTENER" && (
          <MenuItem
            onClick={() => handlePopoverOpen(activeItem?.userId || "", true)}
            sx={{ "&:hover": menuItemHoverStyle }}
          >
            {t("common:actions.VIEWING_PUBLIC_PROFILE")}
          </MenuItem>
        )}
        {!(
          courseData?.formatType === CourseFormatType.ASYNC ||
          (courseData?.formatType === CourseFormatType.SYNC &&
            (activeItem?.status === participantStatusObject.BLOCKED ||
              activeItem?.status === participantStatusObject.ACTIVE) &&
            [
              FlowStatus.CREATED,
              FlowStatus.PUBLISHED,
              FlowStatus.WITHDRAWN,
            ].includes(studyClasses?.status as FlowStatus)) ||
          FlowStatus.COMPLETED === studyClasses?.status ||
          activeItem?.status === participantStatusObject.INVITATION_REJECTED
        ) && (
            <MenuItem
              onClick={() => {
                setOpenRevokeInvitationDeactivateModal(true);
                handleClose();
              }}
              sx={{ "&:hover": menuItemHoverStyle }}
            >
              {t("common:actions.REVOKE_INVITATION")}
            </MenuItem>
          )}

        {profile.pltaId !== activeItem?.userId &&
          !(
            courseData?.formatType === CourseFormatType.ASYNC ||
            (courseData?.formatType === CourseFormatType.SYNC &&
              (activeItem?.status === participantStatusObject.INVITATION_SENT ||
                activeItem?.status === participantStatusObject.ACTIVE) &&
              [
                FlowStatus.CREATED,
                FlowStatus.PUBLISHED,
                FlowStatus.WITHDRAWN,
              ].includes(studyClasses?.status as FlowStatus)) ||
            FlowStatus.COMPLETED === studyClasses?.status
          ) && (
            <MenuItem
              disabled={
                !!activeItem?.externalId || (!isAuthor && !isLocalAdmin)
              }
              onClick={() => setOpenDeleteModal(true)}
              sx={{ "&:hover": menuItemHoverStyle }}
            >
              {t("common:actions.DELETE")}
            </MenuItem>
          )}
        <MenuItem
          onClick={() => handlePopoverOpen(activeItem?.userId || "", false)}
          sx={{ "&:hover": menuItemHoverStyle }}
        >
          {t("common:actions.VIEW_PROFILE")}
        </MenuItem>
      </Menu>
      <Popover
        open={openPopover}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
      >
        <Box
          sx={{
            width: "393px",
            height: "auto",
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <CloseIcon
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
              color: common.strokePrimary,
              cursor: "pointer",
              fontSize: 24,
            }}
            onClick={handlePopoverClose}
          />
          <Box sx={{ display: "flex", gap: 2 }}>
            <AvatarViaUserId
              userId={activeItem?.userId || ""}
              sxProps={{ width: "55px", height: "55px" }}
              variant="square"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 400 }}
              >{`${profileInfo?.lastName} ${profileInfo?.firstName} ${profileInfo?.patronymic}`}</Typography>
              <Typography
                sx={{ color: "#8E8E93", fontSize: "14px", fontWeight: 400 }}
              >
                {profileInfo?.birthdate}
              </Typography>
            </Box>
          </Box>
          {isPublicProfileOpen &&
            (profileInfo?.detailInfo ||
              (profileInfo?.achievements &&
                profileInfo?.achievements.length > 0) ||
              profileInfo?.instagram ||
              profileInfo?.facebook ||
              profileInfo?.youtube) && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Divider sx={{ color: common.borderPrimary }} />
                {profileInfo?.detailInfo && (
                  <Box>
                    <Typography sx={{ color: "#8E8E93" }}>
                      {t("common:label.ABOUT_ME")}
                    </Typography>
                    <Box sx={{ textAlign: "justify" }}>
                      {showFullText
                        ? profileInfo?.detailInfo
                          ?.replace(/<[^>]*>|&nbsp;/g, "")
                          .trim()
                        : profileInfo?.detailInfo
                          ?.replace(/<[^>]*>|&nbsp;/g, "")
                          .trim()
                          .slice(0, 100)}
                      {profileInfo?.detailInfo
                        ?.replace(/<[^>]*>|&nbsp;/g, "")
                        .trim().length >= 100 && (
                          <Typography
                            onClick={toggleShowMore}
                            sx={{
                              display: "inline",
                              cursor: "pointer",
                              color: common.primaryColor,
                            }}
                          >
                            {showFullText
                              ? ` ${t("common:actions.SHOW_LESS")}`
                              : `...${t("common:actions.SHOW_MORE")}`}
                          </Typography>
                        )}
                    </Box>
                    <Divider sx={{ color: common.borderPrimary, m: "5px 0" }} />
                  </Box>
                )}
                {profileInfo?.achievements &&
                  profileInfo?.achievements.length > 0 && (
                    <Box>
                      <Typography sx={{ color: "#8E8E93" }}>
                        {t("profile:ACHIEVMENTS")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          overflowY: "auto",
                          width: "95%",
                          "&::-webkit-scrollbar": {
                            width: "2px",
                            height: "7px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#E5E0EB",
                            borderRadius: "6px",
                          },
                        }}
                      >
                        {profileInfo?.achievements.map((achievement) => {
                          const isPDF = achievement.name.endsWith(".pdf");

                          return (
                            <Box
                              key={achievement.fileId}
                              sx={{ p: "12px 8px" }}
                              onClick={() =>
                                handleToggleImageViewer(true, achievement)
                              }
                            >
                              {isPDF ? (
                                <Box
                                  sx={{
                                    display: "grid",
                                    placeContent: "center",
                                    borderRadius: "8px",
                                    width: "130px",
                                    height: "130px",
                                    cursor: "pointer",
                                    background: "#A9B6FF33",
                                  }}
                                >
                                  <PictureAsPdfIcon
                                    sx={{
                                      width: "50px",
                                      height: "50px",
                                      color: "#9469E3",
                                    }}
                                  />
                                </Box>
                              ) : (
                                <AvatarPicture
                                  id={achievement.fileId}
                                  sxProps={{
                                    border: "1px solid #D9D9D9",
                                    borderRadius: "8px",
                                    width: "130px",
                                    height: "130px",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </Box>
                          );
                        })}
                      </Box>
                      <Divider
                        sx={{ color: common.borderPrimary, m: "5px 0" }}
                      />
                    </Box>
                  )}
                {(profileInfo?.instagram ||
                  profileInfo?.facebook ||
                  profileInfo?.youtube) && (
                    <Box>
                      <Typography sx={{ color: "#8E8E93" }}>
                        {t("profile:SOCIAL_NETWORKS")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "130px",
                          gap: 2,
                        }}
                      >
                        {profileInfo?.instagram && (
                          <Box
                            onClick={() => {
                              window.open(`${profileInfo?.instagram}`, "_blank");
                            }}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              background: "#9469E3",
                              color: "white",
                              width: "32px",
                              height: "32px",
                              borderRadius: "5px",
                            }}
                          >
                            <InstagramIcon />
                          </Box>
                        )}
                        {profileInfo?.facebook && (
                          <Box
                            onClick={() => {
                              window.open(`${profileInfo?.facebook}`, "_blank");
                            }}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              background: "#9469E3",
                              color: "white",
                              width: "32px",
                              height: "32px",
                              borderRadius: "5px",
                            }}
                          >
                            <FacebookIcon />
                          </Box>
                        )}
                        {profileInfo?.youtube && (
                          <Box
                            onClick={() => {
                              window.open(`${profileInfo?.youtube}`, "_blank");
                            }}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              background: "#9469E3",
                              color: "white",
                              width: "32px",
                              height: "32px",
                              borderRadius: "5px",
                            }}
                          >
                            <YouTubeIcon />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
              </Box>
            )}
          {!isPublicProfileOpen && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Divider sx={{ color: common.borderPrimary }} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ color: "#8E8E93" }}>
                  {t("user:PHONE_NUMBER")}
                </Typography>
                <Typography>{profileInfo?.mobilePhone}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ color: "#8E8E93" }}>
                  {t("user:GENDER")}
                </Typography>
                <Typography>{t(`user:${profileInfo?.sex}`)}</Typography>
              </Box>
              {activeItem?.roleType !== "LISTENER" && subdivisionName && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ color: "#8E8E93" }}>
                    {t("enum:SUBDIVISION")}
                  </Typography>
                  <Typography>{subdivisionName}</Typography>
                </Box>
              )}
              {activeItem?.roleType === "LISTENER" && eduProgramName && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ color: "#8E8E93" }}>
                    {t("enum:EDU_PROGRAM")}
                  </Typography>
                  <Typography>{eduProgramName}</Typography>
                </Box>
              )}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ color: "#8E8E93" }}>
                  {t("user:EMAIL")}
                </Typography>
                <Typography>{profileInfo?.email}</Typography>
              </Box>
              { !!profileInfo?.langIds?.length && <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ color: "#8E8E93" }}>
                  {t("user:LANGUAGES")}
                </Typography>
                <Typography>
                  {profileInfo?.langIds &&
                    profileInfo.langIds
                      .map((langId) => getLangDictById(langId))
                      .join(", ")}
                </Typography>
              </Box>}
            </Box>
          )}
        </Box>
      </Popover>
      {openDeactivateModal && (
        <YesOrNoModal
          openModal={openDeactivateModal}
          handleToggleModal={() => setOpenDeactivateModal((prev) => !prev)}
          confirmAction={() => updateStatus(participantStatusObject.BLOCKED)}
          success={false}
          attention={true}
          message={
            activeItem?.roleType === "LISTENER"
              ? t("course:messages.DEACTIVATE_PARTICIPANT_MESSAGE_LISTENER", {
                userFullName: activeItem?.userFullName,
              })
              : t("course:messages.DEACTIVATE_PARTICIPANT_MESSAGE", {
                userFullName: activeItem?.userFullName,
              })
          }
        />
      )}
      {openRevokeInvitationModal && (
        <YesOrNoModal
          openModal={openRevokeInvitationModal}
          handleToggleModal={() =>
            setOpenRevokeInvitationDeactivateModal((prev) => !prev)
          }
          confirmAction={() =>
            updateStatus(participantStatusObject.INVITATION_REJECTED)
          }
          success={false}
          attention={true}
          message={t("course:messages.REVOKE_INVITATIONS")}
        />
      )}
      {addParticipantModalOpen && (
        <AddParticipantsModal
          modalOpen={addParticipantModalOpen}
          handleToggleModal={handleToggleAddParticipantModal}
          subdivisionIds={courseData?.subdivisionIds}
          eduProgramIds={courseData?.eduProgramIds}
          studyClasses={studyClasses}
          refetch={refetchParticipants}
        />
      )}
      {addFreeParticipantModalOpen && (
        <AddFreeParticipantsModal
          modalOpen={addFreeParticipantModalOpen}
          handleToggleModal={handleToggleAddParticipantModal}
          subdivisionIds={courseData?.subdivisionIds}
          eduProgramIds={courseData?.eduProgramIds}
          studyClasses={studyClasses}
          refetch={refetchParticipants}
        />
      )}
      {openImageViewer && (
        <FileViewerModal
          openModal={openImageViewer}
          handleToggleModal={handleToggleImageViewer}
          file={currentImage}
        />
      )}
      {openDeleteModal && (
        <YesOrNoModal
          openModal={openDeleteModal}
          handleToggleModal={() => setOpenDeleteModal(!openDeleteModal)}
          confirmAction={deleteParticipants}
          success={false}
          attention={true}
          message={t("common:DELETE_PARTICIPANTS_ITEM_TEXT")}
        />
      )}
    </>
  );
};

export default AllParticipants;
