export const typeOptions = [
  {
    id: "REGISTRATION",
    text: "REGISTRATION",
  },
  {
    id: "OBJECT_CREATED",
    text: "OBJECT_CREATED",
  },
  {
    id: "OBJECT_CHANGED",
    text: "OBJECT_CHANGED",
  },
  {
    id: "OBJECT_DELETED",
    text: "OBJECT_DELETED",
  },
  {
    id: "AUTHORIZATION",
    text: "AUTHORIZATION",
  },
  {
    id: "SESSION_EXIT",
    text: "END_OF_SESSION",
  },
  {
    id: "AUTHORIZATION_PARAMS_CHANGED",
    text: "AUTHORIZATION_PARAMETERS_CHANGED",
  },
];
// NOT_SPECIFIED,
export const subjectOptions = [
  {
    id: "SUPER_ADMIN",
    text: "SUPER_ADMIN",
  },
  {
    id: "ADMIN",
    text: "ADMIN",
  },
  {
    id: "LOCAL_ADMIN",
    text: "LOCAL_ADMIN",
  },
  {
    id: "EDUCATOR",
    text: "EDUCATOR",
  },
  {
    id: "MANAGER",
    text: "MANAGER",
  },
  {
    id: "LISTENER",
    text: "LISTENER",
  },
];

export const objectOptions = [
  {
    id: "NOT_SPECIFIED",
    text: "NOT_SPECIFIED",
  },
  {
    id: "LOCAL_SETTINGS",
    text: "LOCAL_SETTINGS",
  },
  {
    id: "COURSE",
    text: "COURSE",
  },
  {
    id: "USER",
    text: "USER",
  },
  {
    id: "COURSE_MEMBER",
    text: "COURSE_MEMBER",
  },
  {
    id: "CONTENT_SECTION",
    text: "CONTENT_SECTION",
  },
  {
    id: "THEME",
    text: "THEME",
  },
  {
    id: "TASK",
    text: "TASK",
  },
  {
    id: "TASK_GROUP",
    text: "TASK_GROUP",
  },
  {
    id: "COURSE_MEMBER",
    text: "COURSE_MEMBER",
  },
  {
    id: "TASK_PROGRESS",
    text: "TASK_PROGRESS",
  },
  {
    id: "STUDY_CLASS",
    text: "STUDY_CLASS",
  },
  {
    id: "COURSE_SETTINGS",
    text: "COURSE_SETTINGS",
  },
];
