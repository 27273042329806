import { useEffect, RefObject, createRef, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Formik, Form as FormikForm } from "formik";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { serviceTask } from '@services/task';
import Message from '@components/Message';
import CustomModal from '@components/Modal';
import FormikInput from '@ui/formik/Input';
import FormikAutocomplete from '@ui/formik/Autocomplete';
import CustomButton from '@ui/Button';
import { taskTypes } from '@utils/enums';
import { taskGroupInitialDto } from './models';
import { ITaskGroup } from '@interfaces/index';
import { taskGroupValidationSchema } from './validation';
import { CourseContext } from "@context/CourseContext";

interface Props {
  modalOpen: boolean;
  handleToggleModal: (modalState: boolean) => void;
  id: string | null;
  gradingSystemId: number | null;
  createUpdateGroup: (data: ITaskGroup) => void;
  groupPercentage: number;
  setMessage: (message: string) => void;
  setMessageOpen: (messageOpen: boolean) => void;
  message: string;
  messageOpen: boolean
}

interface ITaskSearchItem {
  id: string;
  name: string;
  point: number | null;
  isNoMark: boolean;
  isExcludeTotalMark: boolean;
}

const GroupTasksModal = ({ modalOpen, handleToggleModal, id, gradingSystemId, createUpdateGroup, groupPercentage, setMessage, setMessageOpen, message, messageOpen }: Props) => {
  const { courseId } = useParams();
  const { t } = useTranslation(["common", "course"]);
  const { common } = useTheme().palette;
  const [initialData, setInitialData] = useState<ITaskGroup>(taskGroupInitialDto);
  const [taskList, setTaskList] = useState<Array<ITaskSearchItem>>([]);
  const [defaultTaskList, setDefaultTaskList] = useState<Array<ITaskSearchItem>>([]);
  const [taskType, setTasktype] = useState<string | undefined>('');
  const [defaultTaskType, setDefaultTasktype] = useState<string>();
  const [initialGroupPercentage, setInitialGroupPercentage] = useState<number>(0);
  const { courseData } = useContext(CourseContext);
  const published = courseData?.status === "PUBLISHED";
  const completed = courseData?.status === "COMPLETED";
  const validationMessageRef: RefObject<HTMLElement> = createRef();

  useEffect(() => {
    if (gradingSystemId) {
      setInitialData((prev: ITaskGroup) => ({ ...prev, gradingSystemId }));
    }
  }, [gradingSystemId]);

  useEffect(() => {
    if (id) {
      serviceTask.getTaskGroupInfo(id).then((res) => {
        // console.log(res.data);
        setInitialData((prev: ITaskGroup) => ({ ...prev, ...res.data }));
        setInitialGroupPercentage(res.data.percentage);
        if (gradingSystemId === 3 && res.data?.taskType) {
          setDefaultTaskList(res.data.tasks);
          setTasktype(res.data.taskType);
        }
        setDefaultTasktype(res.data.taskType);
      });
    }
  }, [id, gradingSystemId]);

  useEffect(() => {
    if (courseId) {
      const type = gradingSystemId === 3 ? taskType : undefined;
      serviceTask.getTaskListForDropdown(courseId, { type, isSelfTest: false, groupId: initialData.id }).then(res => {
        if (res.status === 200) {
          if (taskType === defaultTaskType) {
            setTaskList(([...defaultTaskList].concat(res.data)));
          } else {
            setTaskList(res.data);
          }
        }
      });
    }
  }, [courseId, gradingSystemId, taskType, defaultTaskType, defaultTaskList, initialData.id]);

  const handleChangeTaskType = (taskType: ITaskSearchItem, taskGroupValues: ITaskGroup) => {
    if (taskGroupValues.gradingSystemId === 3) setTasktype(taskType.id);
    else setTasktype(undefined);
    setInitialData({ ...taskGroupValues, taskType: taskType.id, tasks: [] });
  }

  const scrollToTop = () => validationMessageRef.current?.scrollTo(0, 0);

  return (
    <CustomModal
      open={modalOpen}
      handleToggleModal={handleToggleModal}
      width={515}
      divRef={validationMessageRef}
      showCloseIcon={completed}
    >
      <>
        {messageOpen &&
          <Message
            type="error"
            message={message}
            setMessageOpen={setMessageOpen}
          />
        }
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '28px',
            lineHeight: '36px'
          }}
        >
          {!id ? t('common:actions.ADD_GROUP_OF_TASKS') : t("course:task.TASK_GROUP") + ` ${initialData.name}`}
        </Typography>
        <Formik
          initialValues={initialData}
          enableReinitialize
          validationSchema={taskGroupValidationSchema(t)}
          onSubmit={(values: ITaskGroup) => {
            const overallTasksPoint = values.tasks.reduce((acc: number, task: any) => {
              return Number(acc) + Number(task.point)
            }, 0)

            if (values.gradingSystemId === 3 && overallTasksPoint !== 100 && values.tasks.length !== 0) {
              setMessage(t('course:messages.TOTAL_POINT_IN_GROUP_OF_TASK_SHOULD_BE_100'));
              setMessageOpen(true);
              setTimeout(() => {
                setMessageOpen(false)
              }, 5000);
              scrollToTop();
            } else {
              const data = {
                ...values,
                taskType: values.gradingSystemId !== 3 ? null : values.taskType,
                course: { id: courseId || null }
              }
              createUpdateGroup(data);
            }
          }}
        >
          {({ values, submitForm }) => (
            <FormikForm>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  mt: 3
                }}
              >
                <FormikInput
                  name="name"
                  label={t("course:task.NAME")}
                  isRequired
                  disabled={completed}
                />
                <FormikInput
                  name="percentage"
                  label={t("course:task.%RATIO")}
                  type="number"
                  min={0}
                  isRequired
                  disabled={completed}
                />
                {values?.gradingSystemId === 3 &&
                  <FormikAutocomplete
                    label={t("course:task.TYPE_OF_TASKS")}
                    name="taskType"
                    options={taskTypes}
                    isRequired
                    clearable={false}
                    disabled={(values.tasks?.length > 0 && values.gradingSystemId === 3) || published || completed}
                    onChange={(value: any) => handleChangeTaskType(value, values)}
                  />
                }
                {((values.gradingSystemId === 3 && values.taskType) || values.gradingSystemId !== 3) &&
                  <>
                    <FormikAutocomplete
                      label={t("course:task.SELECT_TASKS")}
                      name="tasks"
                      options={taskList}
                      valueAsObject
                      multiple
                      disabled={completed}
                      isInputEnterable={false}
                    />
                    <Box sx={{
                      mt: "24px",
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      maxHeight: "150px",
                      overflowY: "auto",
                      '&::-webkit-scrollbar': {
                        width: 3,
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'lightgray',
                        borderRadius: '6px'
                      }
                    }}>
                      {values.tasks.map((el: ITaskSearchItem, index: number) => {
                        return (
                          <Box
                            key={el.id}
                            sx={{
                              display: "flex",
                              gap: 1,
                              alignItems: "center",
                              px: 2
                            }}
                          >
                            <Box sx={{ width: '80%' }}>{el.name}</Box>
                            {values?.gradingSystemId === 3 && !el.isNoMark && !el.isExcludeTotalMark &&
                              <FormikInput
                                name={`tasks[${index}].point`}
                                label={t('course:task.POINT')}
                                type="number"
                                min={0}
                                max={100}
                              />
                            }
                          </Box>
                        )
                      })}
                    </Box>
                  </>
                }
                {!completed &&
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <CustomButton
                      backgroundColor={common.btnCancel}
                      fontColor={common.primaryColor}
                      borderRadius="6px"
                      height="40px"
                      sx={{
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                      onClick={() => handleToggleModal(false)}
                    >
                      {t("common:actions.CANCEL")}
                    </CustomButton>
                    <CustomButton
                      backgroundColor={common.primaryColor}
                      fontColor={common.fontWhite}
                      borderRadius="6px"
                      height="40px"
                      sx={{
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                      // disabled={!(dirty && isValid)}
                      onClick={() => {
                        if (Number(values.percentage) + (groupPercentage - initialGroupPercentage) <= 100) {
                          submitForm()
                        } else {
                          setMessageOpen(true);
                          setMessage(t('course:messages.TASK_GROUPS_PERCENTAGE_CANNOT_EXCEED_100%'));
                          setTimeout(() => {
                            setMessageOpen(false);
                          }, 5000);
                          scrollToTop();
                        }
                      }}
                    >
                      {!id ? `${t("common:actions.ADD")}` : `${t("common:actions.SAVE")}`}
                    </CustomButton>
                  </Box>
                }
              </Box>
            </FormikForm>
          )}
        </Formik>
      </>
    </CustomModal>
  )
}

export default GroupTasksModal