import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Box, FormControl, useTheme } from '@mui/material';

import QuestionAnswers from './QuestionAnswers';
import { IQuestionsFormikContext } from './Questions';
import CustomButton from '@ui/Button';
import FormikSelect from '@ui/formik/Select';
import { TextEditor } from '@ui/formik/TextEditor';
import { quizQuestionTypes } from '@utils/enums';
import { QuizQuestionTypeEnum } from '@models/quiz-question';
import { questionAnswerInitialDto, questionChildrenInitialDto } from '../models';

interface IQuizFormsProps {
  index: number;
  isEditingQuestion: boolean,
  submitForm: () => Promise<any>
  cancelEditingCreating: () => void;
  messageComponent: JSX.Element | null;
  isDraft?: boolean;
}

const QuizForms = ({ index, messageComponent, submitForm, cancelEditingCreating, isEditingQuestion, isDraft = false }: IQuizFormsProps) => {
  const { t } = useTranslation(["course", "common"]);
  const { common } = useTheme().palette;
  const { values: formikValues, setValues } = useFormikContext<IQuestionsFormikContext>();
  const values = formikValues.data[index]
  const pointOptions = useMemo(() => {
    return [...Array(10)].map((e, i) => ({
      id: i + 1,
      nameKz: i + 1,
      nameEn: i + 1,
      nameRu: i + 1,
    }))
  }, [])

  function handleTestType() {
    if (!values.id && values.type === QuizQuestionTypeEnum.TRUEORFALSE) {
      let newValues = { ...formikValues }
      newValues.data[index] = {
        ...values,
        children: [],
        answers: [
          questionAnswerInitialDto(),
          questionAnswerInitialDto(),
        ],
      }
      setValues(newValues)
    } else if (!values.id && values.type === QuizQuestionTypeEnum.MAPPING) {
      let newValues = { ...formikValues }
      newValues.data[index] = {
        ...values,
        answers: [],
        children: [
          questionChildrenInitialDto(),
          questionChildrenInitialDto(),
          questionChildrenInitialDto(),
          questionChildrenInitialDto(),
        ],
      }
      setValues(newValues)
    } else if (
      !values.id &&
      (
        values.type === QuizQuestionTypeEnum.ONECORRECTANSWER ||
        values.type === QuizQuestionTypeEnum.SEQUENCE
      )
    ) {
      let newValues = { ...formikValues }
      newValues.data[index] = {
        ...values,
        children: [],
        answers: [
          questionAnswerInitialDto(),
          questionAnswerInitialDto(),
          questionAnswerInitialDto(),
          questionAnswerInitialDto(),
        ],
      }
      setValues(newValues)
    } else if (
      !values.id &&
      values.type === QuizQuestionTypeEnum.MULTIPLECORRECTANSWERS
    ) {
      let newValues = { ...formikValues }
      newValues.data[index] = {
        ...values,
        children: [],
        answers: [
          questionAnswerInitialDto(),
          questionAnswerInitialDto(),
          questionAnswerInitialDto(),
          questionAnswerInitialDto(),
        ],
      }
      setValues(newValues)
    }
  }

  return (
    <Box sx={{ width: '95%', border: `1px solid ${common.borderSecondary}`, borderRadius: "10px", py: '8px', px: '32px', mb: "10px" }}>
      <FormControl>
        <Box sx={{ mb: '24px', width: '70%', display: 'flex' }}>
          <Box sx={{ width: '60%', mr: '16px' }}><TextEditor name={`data.${index}.content`} label={`${t('course:test.QUESTION')} ${index + 1}`} /></Box>
          <Box sx={{ display: 'block', width: '40%' }}>
            <FormikSelect
              label={t('course:test.QUESTION_TYPE')}
              name={`data.${index}.type`}
              options={quizQuestionTypes}
              disabled={isDraft}
              onChange={() => handleTestType()}
            />
            <FormikSelect
              label={t('course:task.POINT')}
              name={`data.${index}.point`}
              options={pointOptions}
            />
          </Box>
        </Box>
        <QuestionAnswers index={index} handleTestType={handleTestType} />
        <Box sx={{ marginBottom: "5px", width: "60%" }}>
          {messageComponent}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '80%', marginBottom: "20px" }}>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ border: '1px solid #FD0053', borderRadius: '6px' }}>
              <CustomButton onClick={isEditingQuestion ? cancelEditingCreating : () => {
                setValues({ data: formikValues.data.filter((_, innerIndex) => innerIndex !== index) })
              }} borderRadius="6px" backgroundColor="white" fontColor={common.errorColor} sx={{ fontWeight: 500 }}>
                {t('course:task.CANCELLATION')}
              </CustomButton>
            </Box>
            <Box sx={{ ml: '10px' }}>
              <CustomButton onClick={submitForm} borderRadius="6px" backgroundColor={common.primaryColor} fontColor="white">
                {t('common:actions.SAVE')}
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </FormControl>
    </Box>
  )
}

export default QuizForms;