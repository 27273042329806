import { useNavigate } from 'react-router-dom';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { Formik, Form as FormikForm } from "formik";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';

import CustomButton from '@ui/Button';
import FormikInput from '@ui/formik/Input';

const PassedNotPassedAssessmentSystem = ({ initialData }: any) => {
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course", "common"]);
  return (
    <Box sx={{ m: '32px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
        <Typography
          sx={{ cursor: 'pointer', fontSize: '14px' }}
          onClick={() => {
            navigate('/assessmentSystem')
          }}>Системы оценивания</Typography>
        <ArrowForwardIosIcon sx={{ width: '9px', height: '9px', ml: '9px', mt: '2.5px' }} />
        <Typography sx={{ ml: '8px', fontSize: '14px' }}>Оценка по системе «Пройдено-Не пройдено»</Typography>
      </Box>
      <Box>
        <Typography sx={{ fontWeight: 500, fontSize: '36px', mt: '16px', mb: '36px' }}>Оценка по системе «Пройдено-Не пройдено»</Typography>
      </Box>
      <Box sx={{ width: '100%', backgroundColor: common.white, height: 'auto', border: `1px solid ${common.borderSecondary}`, borderRadius: '9px', p: '32px' }}>
        <Formik
          initialValues={initialData}
          enableReinitialize
          validationSchema={null}
          onSubmit={(values) => {

          }}
        >
          {({ values, submitForm }) => (
            <FormikForm>
              <TableContainer component={Paper} sx={{ overflowX: 'unset' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        {t('course:task.NAME')}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '35%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        {t('common:label.DESCRIPTION')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        Пройдено
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '35%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ ml: '-17px', mr: '-24px', mt: '-15px', mb: '-16px' }}>
                          <FormikInput name="Aaa" label="" multiline={true} size='medium' placeholder='Text' maxRows={3} />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        Не пройдено
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '35%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ ml: '-17px', mr: '-24px', mt: '-15px', mb: '-16px' }}>
                          <FormikInput name="Aaa" label="" multiline={true} size='medium' placeholder='Text' maxRows={3} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{
                mt: '200px', display: 'flex', justifyContent: 'flex-end'
              }}>
                <CustomButton
                  width="98px"
                  height="40px"
                  backgroundColor={common.primaryColor}
                  borderRadius="8px"
                  fontColor={common.fontWhite}
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                    alignItems: "center",
                    color: "#FFFFFF",
                  }}
                >
                  {t('common:actions.SAVE')}
                </CustomButton>
              </Box>
            </FormikForm>
          )}
        </Formik>
      </Box>
    </Box>
  )
}

export default PassedNotPassedAssessmentSystem