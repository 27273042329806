export interface FilterTypeCatalogCourse {
  search: string | null,
  categoryId: number | null,
  subCategoryIds: Array<number> | [],
  ratingRanges: Array<{ startRange: number, endRange: number }> | [],
  langIds: Array<number> | [],
  difficultyLevelIds: Array<number> | [],
  orgIds: Array<number> | [],
  startDate: string | null,
  endDate: string | null
}


export const initialFilterTypeCatalogCourse: FilterTypeCatalogCourse = {
  search: null,
  categoryId: null,
  subCategoryIds: [],
  ratingRanges: [],
  langIds: [],
  difficultyLevelIds: [],
  orgIds: [],
  startDate: null,
  endDate: null
}
