import { Box, Typography, useTheme } from '@mui/material';
import { Formik, Form as FormikForm } from "formik";
import CustomModal from '@components/Modal';
import CustomButton from '@ui/Button';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import { serviceMember } from '@services/member';
import { useParams } from 'react-router-dom';
import { IGroupDto } from './models';
import FormikAutocomplete from '@ui/formik/Autocomplete';

const TransferGroup = ({ openTransferGroupModalWindow, handleCloseTransferGroupModalWindow, initialData, clickedUser, transferGroup, unselectUser, openSnackbarFunc }: any) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["common", "course"]);
  const { courseId } = useParams();
  const [groupList, setGroupList] = useState<IGroupDto[]>([])

  useEffect(() => {
    if (courseId) {
      serviceMember.getGroupList(courseId).then((res) => {
        if (res.status === 200) {
          setGroupList(res.data.filter((data: IGroupDto) => data.id !== initialData.id && Number(data.capacity) > Number(data.memberSum)))
        }
      })
    }
  }, [courseId, initialData.id])

  return (
    <>
      <CustomModal
        open={openTransferGroupModalWindow}
        handleToggleModal={handleCloseTransferGroupModalWindow}
        width={494}
      >
        <>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontWeight: 500,
              fontSize: "28px",
              lineHeight: "36px",
              color: "#000000",
              display: "flex",
              alignItems: "center",
              mb: "24px",
            }}
          >
            {t("course:TRANSFER_ANOTHER_GROUP")}
          </Typography>
          <Formik
            initialValues={{}}
            enableReinitialize
            validationSchema={null}
            onSubmit={(values: any) => {
              serviceMember.transferGroup(clickedUser.id, initialData.id, values.transferGroup.id).then((res) => {
                if (res.status === 200) {
                  unselectUser()
                  handleCloseTransferGroupModalWindow()
                  openSnackbarFunc()
                  transferGroup(initialData.id, values.transferGroup.id)
                }
              })
            }}
          >
            {({ submitForm }) => (

              <FormikForm>
                {groupList.length === 0 ? <Box>{t("course:AVAILABLE_TRANSLATION")}</Box> :
                  <FormikAutocomplete
                    label={t('common:placeholders.CHOOSE_GROUP')}
                    placeholder={t('common:placeholders.CHOOSE_GROUP')}
                    name="transferGroup"
                    options={groupList}
                    valueAsObject
                    isRequired
                  />}
                <Box
                  sx={{
                    mt: "24px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <CustomButton
                    backgroundColor={common.btnCancel}
                    fontColor={common.primaryColor}
                    width="199px"
                    borderRadius="6px"
                    height="40px"
                    sx={{
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    onClick={handleCloseTransferGroupModalWindow}
                  >
                    {t('common:actions.CANCEL')}
                  </CustomButton>
                  <CustomButton
                    backgroundColor={common.primaryColor}
                    fontColor={common.fontWhite}
                    width="199px"
                    borderRadius="6px"
                    height="40px"
                    disabled={groupList.length === 0}
                    sx={{
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    onClick={submitForm}
                  >
                    {t('common:actions.TRANSFER')}
                  </CustomButton>
                </Box>
              </FormikForm>
            )}
          </Formik>
        </>
      </CustomModal>
    </>
  )
}

export default TransferGroup;