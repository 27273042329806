import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@components/Modal";
import CustomButton from "@ui/Button";
import SendInfoModal from "./SendInfoModal";

function GenerateModal({
  handleToggleGeneratePasswordModal,
  modalOpen,
  handleSubmitGeneratePassword
}: any) {
  const { t } = useTranslation(['common']);
  const { common } = useTheme().palette;
  const [openSendInfoModal, setOpenSendInfoModal] = useState(false);
  const handleToggleSendInfoModal = (modalState: boolean) => setOpenSendInfoModal(modalState);
  return (
    <>
      <CustomModal
        open={modalOpen}
        handleToggleModal={handleToggleGeneratePasswordModal}
        borderColor={common.primaryColor}
        width={515}
      >
        <>
          <Typography
            id="transition-modal-title"
            sx={{
              color: "#000000",
              fontWeight: "500",
              fontSize: "20px",
              lineHeight: "26px",
              display: "flex",
              alignItems: "center",
              mb: "32px",
            }}
          >
            {t('common:messages.ARE_YOU_SURE_WANT_TO_GENERATE_PASSWORD')}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <CustomButton
              onClick={() => handleToggleGeneratePasswordModal(false)}
              width="209px"
              height="40px"
              borderRadius="8px"
              backgroundColor="#F6F6F6"
              fontColor={common.primaryColor}
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              {t('common:NO')}
            </CustomButton>
            <CustomButton
              onClick={() => handleToggleSendInfoModal(true)}
              width="209px"
              height="40px"
              borderRadius="8px"
              backgroundColor={common.primaryColor}
              fontColor="#FFFFFF"
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              {t('common:YES')}
            </CustomButton>
          </Box>
          {openSendInfoModal &&
            <SendInfoModal
              openSendInfoModal={openSendInfoModal}
              handleToggleSendInfoModal={handleToggleSendInfoModal}
              handleToggleGeneratePasswordModal={handleToggleGeneratePasswordModal}
              handleSubmitGeneratePassword={handleSubmitGeneratePassword}
            />
          }
        </>
      </CustomModal>
    </>
  );
}

export default GenerateModal;
