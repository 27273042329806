import AvatarPicture from "@components/AvatarPicture";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HomeVariant } from "../types/home";
import s from "./LeadingCourse.module.scss";
import { Role } from "@interfaces/index";
interface Props {
  variant: HomeVariant;
}

interface Result {
  title: string;
  text?: string;
  button?: string;
  onClick?: () => void;
  authors?: Record<string, string>[]
}
const LeadingCourse = (props: Props) => {
  const { variant } = props;
  const { t } = useTranslation(["home"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const userInfo = localStorage.getItem("userInfo");
  const role = JSON.parse(userInfo || "{}")?.authorityInfo?.role;
  const navigate = useNavigate();
  const handleHomeVariant = (e: HomeVariant): Result => {
    switch (e) {
      case HomeVariant.ORGANIZATION:
        return {
          title:
            t('home:KAZAKH_UNIVERSITIES_ARE_ALREADY_CREATING_COURSES'),
          button: t('home:CONTACT_US'),
          onClick: () => {
            searchParams.set("modal", "true");
            setSearchParams(searchParams);
          },
          authors: [
            {
              authorName: 'Александр Краснов',
              id: '1',
            },
            {
              authorName: 'Александр Краснов',
              id: '2',
            },
            {
              authorName: 'Александр Краснов',
              id: '3',
            },
            {
              authorName: 'Александр Краснов',
              id: '4',
            }
          ]
        };
      case HomeVariant.EDUCATOR:
        return {
          title: t('home:MARKET_LEADERS_ARE_ALREADY_CREATING_COURSES_AT_PLATONUS_ACADEMY'),
          button: (!role || role === Role.EDUCATOR || role === Role.MANAGER) ? t('home:BECOME_AN_AUTHOR') : undefined,
          onClick: () => {
            navigate(userInfo ? "/myCourses" : "/registration");
          },
          authors: [
            {
              authorName: 'Айдар Испусинов',
              authorImage: require('@assets/images/AidarManasovich.png'),
              id: '5',
            },
            {
              authorName: 'Айгуль Куразходжаева',
              authorImage: require('@assets/images/AigulKurazhodzhaeva.png'),
              id: '6',
            },
            {
              authorName: 'Дамир Башенов',
              authorImage: require('@assets/images/DamirBashenov.png'),
              id: '7',
            },
            {
              authorName: 'Айжан Жанузакова',
              authorImage: require('@assets/images/AizhanZhanuzakova.png'),
              id: '8',
            }
          ],
        };
      case HomeVariant.LISTENER:
        return {
          title: t("home:LEADING_UNIVERSITIES"),
          text: t("home:IMMERSE_LEARNING_COURSES"),
          authors: [
            {
              authorName: 'Айдар Испусинов',
              authorImage: require('@assets/images/AidarManasovich.png'),
              id: '9',
            },
            {
              authorName: 'Айгуль Куразходжаева',
              authorImage: require('@assets/images/AigulKurazhodzhaeva.png'),
              id: '10',
            },
            {
              authorName: 'Дамир Башенов',
              authorImage: require('@assets/images/DamirBashenov.png'),
              id: '11',
            },
            {
              authorName: 'Айжан Жанузакова',
              authorImage: require('@assets/images/AizhanZhanuzakova.png'),
              id: '12',
            }
          ],
        };
      default:
        return {
          title: t("home:LEADING_UNIVERSITIES"),
        };
    }
  };
  const data = handleHomeVariant(variant);

  return (
    <div className={s.wrapper}>
      <div className={s.head}>
        <div className={s.title_wrapper}>
          <div
            className={s.title}
            style={
              variant === HomeVariant.LISTENER ? { maxWidth: "80%" } : undefined
            }
          >
            {data.title}
          </div>
          {data.text && <div className={s.text}>{data.text}</div>}
        </div>

        {data.button && (
          <Box>
            <Button
              sx={{ borderRadius: "4px" }}
              size="large"
              variant="contained"
              color="primary"
              onClick={data?.onClick}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 400,
                  lineHeight: "28px",
                }}
              >
                {data.button}
              </Typography>
            </Button>
          </Box>
        )}
      </div>
      <div className={s.content}>
        {data?.authors && data?.authors?.map(author => (
          <div className={s.card} key={author.id}>
            <AvatarPicture
              variant="circular"
              id={"123"}
              lastName={author.authorImage ? author.authorImage : ''}
              sxProps={{ width: "80px", height: "80px" }}
            />
            <div className={s.block}>
              <div className={s.name}>{author.authorName}</div>
              <div className={s.subtitle}>{t('home:AUTHOR_OF_THE_COURSE')}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeadingCourse;
