import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { useUpdateReviewMutation } from "@services/courseRating";
import { useCourseContext } from "@context/CourseContext";
import CustomModal from "@components/Modal";
import CustomButton from "@ui/Button";
import { useQueryClient } from "@tanstack/react-query";

interface Props {
  openModal: boolean;
  handleToggleModal: (modalState: boolean) => void;
  handleGratefulModal?: (modalState: boolean) => void;
}

const RateCourseModal: FC<Props> = ({ openModal, handleToggleModal, handleGratefulModal }) => {
  const { t } = useTranslation(['course'])
  const { common } = useTheme().palette;
  const [rating, setRating] = useState<number | null>(0);
  const [text, setText] = useState<string>("");
  const [update, { isSuccess, reset }] = useUpdateReviewMutation();
  const { courseData } = useCourseContext();
  const queryClient = useQueryClient()
  const handleSubmit = async () => {
    await update({
      courseId: courseData?.courseId,
      grade: Number(rating),
      review: text,
    });
    queryClient.invalidateQueries({queryKey:['getReviewListByCourseId']})
  };
  const iconStyle = {
    color: common.strokePrimary,
    cursor: "pointer",
    fontSize: 32,
  };
  useEffect(() => {
    if(isSuccess && handleGratefulModal) {
      handleGratefulModal(true)
    }
  }, [isSuccess, handleGratefulModal])
  useEffect(() => {
    if (openModal) {
      setText("");
      setRating(0);
    }
  }, [openModal]);
  useEffect(() => {
    if (isSuccess) {
      handleToggleModal(false);
      reset();
    }
  }, [handleToggleModal, isSuccess, reset]);

  return (
    <CustomModal
      open={openModal}
      handleToggleModal={handleToggleModal}
      px={0}
      py={0}
      width={"480px"}
    >
      <Box display={"flex"} flexDirection={"column"} gap={3}>
        <Box>
          <Box
            display={"flex"}
            alignContent={"center"}
            justifyContent={"space-between"}
            padding={"24px"}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "21px",
                alignSelf: "center",
              }}
            >
              {t("course:task.GIVE_GRADE")}
            </Typography>
            <CloseIcon
              sx={iconStyle}
              onClick={() => handleToggleModal(false)}
            />
          </Box>
          <Divider />
        </Box>
        <Box display={"flex"} px={3} flexDirection={"column"} gap={3}>
          <Box
            display="flex"
            flexDirection={"column"}
            alignContent={"center"}
            gap={2}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "22px",
              }}
              alignSelf={"center"}
            >
              {t("course:task.CHOICE_GRADE")}
            </Typography>
            <Box display={"flex"} justifyContent={"center"}>
              <Rating
                sx={{}}
                size="large"
                name="simple-controlled"
                value={rating}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
                precision={0.5}
              />
            </Box>
          </Box>
          <Box>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "12px",
                  "&:hover fieldset": { borderColor: common.primaryColor },
                  "&.Mui-focused fieldset": {
                    borderColor: common.primaryColor,
                  },
                },
              }}
              minRows={2}
              multiline
              fullWidth
              placeholder="Оставить отзыв"
              onChange={(e) => setText(e.target.value)}
            />
            <Typography
              display={"flex"}
              width={"100%"}
              justifyContent={"flex-end"}
              sx={{
                fontSize: "14px",
                color: common.strokePrimary,
              }}
            >
              {text.length}/300
            </Typography>
          </Box>
        </Box>
        <Box>
          <Divider />
          <Box
            padding={"16px 24px"}
            display={"flex"}
            gap={1}
            justifyContent={"flex-end"}
          >
            <CustomButton
              height="40px"
              borderRadius="8px"
              backgroundColor="#F6F6F6"
              fontColor={common.primaryColor}
              width="auto"
              sx={{
                display: "flex",
                alignItems: "center",
                fontStyle: "normal",
                fontSize: "16px",
                lineHeight: "24px",
                minWidth: "140px",
              }}
            >
              {t("course:task.CANCELLATION")}
            </CustomButton>
            <CustomButton
              height="40px"
              borderRadius="8px"
              backgroundColor="#9469E3"
              fontColor={common.fontWhite}
              width="auto"
              sx={{
                display: "flex",
                alignItems: "center",
                fontStyle: "normal",
                fontSize: "16px",
                lineHeight: "24px",
              }}
              onClick={handleSubmit}
            >
              {t("course:task.SEND_GRADE")}
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default RateCourseModal;
