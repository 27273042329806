import { IReview, ReviewInfo } from "@interfaces/index";
import { ResponseList } from "@interfaces/responseList";
import { rtkApi } from "@store/rtkApi";

interface IReviewParams {
  filter: {
    search: string;
  },
  pageNumber: number;
  size: number
}

export const servicePublicApi = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getReviewListByCourseId: build.mutation<ResponseList<IReview>, IReviewParams & { id: string }>({
      query: ({ id, ...params }) => ({
        url: `course/public/main-page/reviews/${id}`,
        method: "POST",
        body: params
      }),
    }),
    getCourseReviewInfo: build.query<ReviewInfo, string>({
      query: (id) => ({
        url: `course/public/main-page/course-review-info/${id}`
      }),
      providesTags: () => ['Review'],
    })
  }),
})

export const {
  useGetReviewListByCourseIdMutation,
  useGetCourseReviewInfoQuery
} = servicePublicApi