import { Box, Typography, useTheme } from '@mui/material';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';

import CustomModal from '@components/Modal';
import CustomButton from '@ui/Button';
import { useTranslation } from 'react-i18next';

const CompleteTestModal = ({
  modalOpen,
  toggleCompleteQuizModal,
  completeQuiz
}: any) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course", "common"]);
  return (
    <CustomModal
      open={modalOpen}
      handleToggleModal={toggleCompleteQuizModal}
      width={494}
      borderColor={common.primaryColor}
    >
      <>
        <Box sx={{ display: 'block' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <PriorityHighOutlinedIcon sx={{ border: `8px solid ${common.secondaryColor}`, width: '84px', height: '84px', borderRadius: '42px', p: '8px', color: common.primaryColor, mb: '32px' }} />
          </Box>
          <Typography sx={{ textAlign: 'center', color: common.primaryColor, fontWeight: '500', fontSize: '20px', lineHeight: '21px', mb: '10px' }}>{t('course:test.COMPLETE_TESTING')}</Typography>
          <Typography id="modal-modal-title" sx={{ color: "#4D5377", display: "flex", alignItems: "center", textAlign: "center" }}>
            {t('course:messages.ARE_YOU_SURE_TO_COMPLETE_TESTING')}
          </Typography>
        </Box>
        <Box sx={{ mt: "24px", display: "flex", justifyContent: "space-between" }}>
          <CustomButton
            backgroundColor={common.btnCancel}
            fontColor={common.primaryColor}
            width="199px"
            borderRadius="6px"
            height="40px"
            sx={{ alignItems: "center", fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}
            onClick={() => toggleCompleteQuizModal(false)}
          >
            {t('common:NO')}
          </CustomButton>
          <CustomButton
            backgroundColor={common.primaryColor}
            fontColor={common.fontWhite}
            width="199px"
            borderRadius="6px"
            height="40px"
            sx={{ alignItems: "center", fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}
            onClick={() => completeQuiz()}
          >
            {t('common:YES')}
          </CustomButton>
        </Box>
      </>
    </CustomModal>
  )
}

export default CompleteTestModal;