export enum TaskProgressStatusEnum{
    ASSIGNED="ASSIGNED",
    NOT_ASSIGNED="NOT_ASSIGNED",
    STARTED="STARTED",
    UNDER_REVIEW="UNDER_REVIEW",
    ON_CORRECTION="ON_CORRECTION",
    NOT_COMPLETED_ON_TIME="NOT_COMPLETED_ON_TIME",
    COMPLETED="COMPLETED",
    PASSED="PASSED",
    NOT_PASSED="NOT_PASSED",
    NOT_ALLOWED="NOT_ALLOWED",
    CLOSED="CLOSED"
}