import { serviceFile } from "@services/file";

export const getBase64 = (img: File, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const formatDate = (value: string | undefined | null) => {
  if (value) {
    const chunks = value?.split("-");
    return `${chunks[2]}.${chunks[1]}.${chunks[0]}`;
  }
  return "";
};

export const formatDateWithHyphen = (value: string | undefined | null) => {
  if (value) {
    const chunks = value?.split("-");
    return `${chunks[2]}-${chunks[1]}-${chunks[0]}`;
  }
  return "";
};

export const getOnlyDateAndFormat = (value: string | undefined | null) => {
  if (value) {
    const parts = value?.split("T");
    const chunks = parts[0].split("-");
    return `${chunks[2]}.${chunks[1]}.${chunks[0]}`;
  }
  return "";
};

export const formatDateWithHourMinute = (value: string | undefined | null) => {
  if (value) {
    const parts = value?.split("T");
    const chunks1 = parts[0].split("-");
    const chunks2 = parts[1].split(":");
    return `${chunks1[2]}.${chunks1[1]}.${chunks1[0]}, ${chunks2[0]}:${chunks2[1]}`;
  }
  return "";
};

export const getLangDictById = (id: number) => {
  // TODO: need to come up with a better solution
  const langDictList = localStorage.getItem("languageDictList");
  const lng = localStorage.getItem("i18nextLng") || "kz";
  const langDict = langDictList ? JSON.parse(langDictList).filter(
    (lng: {
      id: number;
      nameRu: string;
      nameKz: string;
      nameEn: string;
      active: boolean;
      isStandard: boolean;
    }) => lng.id === id
  ) : [{
    nameEn: "Kazakh language",
    nameKz: "Қазақ тілі",
    nameRu: "Казахский язык"
  }];

  const language = lng === "kz" ? langDict[0]?.nameKz : lng === "en" ? langDict[0]?.nameEn : langDict[0]?.nameRu
  return language;
}

// Function to format the date to the ISO format with the original timezone offset
export const formatDateWithTimezone = (date: Date | null) => {
  const pad = (num: number) => (num < 10 ? "0" + num : num);
  if (date) {
    // const timezoneOffset = -date.getTimezoneOffset();
    // const timezoneOffsetHours = Math.floor(Math.abs(timezoneOffset / 60));
    // const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);
    // const sign = timezoneOffset >= 0 ? "+" : "-";

    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
  }
  return date;
}

export const downloadFile = (fileId: string | null | undefined) => {
  if (fileId) {
    serviceFile.downloadFile(fileId).then((res: any) => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          "download",
          `${decodeContentDispositionFilename(res.headers["content-disposition"])}`
        );
        document.body.appendChild(link);
        link.click();
      }
    })
  }
};

export const decodeContentDispositionFilename = (header: string) => {
  const match = header.match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?/);

  if (match && match[1]) {
    const encodedFilename = match[1];
    const decodedFilename = decodeURIComponent(encodedFilename.replace(/\+/g, ' '));
    return decodedFilename;
  }

  return "Название файла";
}

export const getTodayWithSpecificTime = (hour: number, minute: number) => {
  const today = new Date();
  today.setHours(hour, minute, 0);
  return formatDateWithTimezone(today);
}

export const getTimeFromDate = (date: Date | string) => {
  if (date) {
    const parts = typeof date === "string" ? date?.split("T") : date.toLocaleString().split(",");
    const chunks = parts[1].split(":");
    return `${chunks[0]}:${chunks[1]}`;
  }
  return "";
}

export const getFileType = (fileName: string) => {
  const parts = fileName.split('.');
  const extension = parts[parts.length - 1].toLowerCase();
  switch (extension) {
    case 'pdf':
      return 'pdf';
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return 'image';
    case 'mp3':
    case 'wav':
      return 'audio';
    case 'mp4':
    case 'avi':
      return 'video';
    default:
      return 'unknown';
  }
}

export const subtractFileNameFromFullFileName = (fullFileName: string) => {
  const parts = fullFileName.split('/');
  const fileNameWithExtension = parts[parts.length - 1];
  const lastDotIndex = fileNameWithExtension.lastIndexOf('.');
  const fileName = lastDotIndex !== -1 ? fileNameWithExtension.slice(0, lastDotIndex) : fileNameWithExtension;
  return fileName;
}

export const removeHtml = (text: string) => {
  return text.replace(/<[^>]*>|&nbsp;/g, '').trim()
}

export const  getGradingSystemName=(id:number)=>{
  switch (id){
    case 1:
      return "100_RATING_POINT";
    case 2:
      return "100_RATING_POINT_WITH_FX";
    case 3:
      return "CUMULATIVE_ASSESSMENT_SYSTEM";
    case 4:
      return "PASSED_NOT_PASSED";
    default:
      return "";
  }
}
