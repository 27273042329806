import React, { useEffect, useMemo, useState } from "react";
import { AlertColor, Avatar, Box, Switch, Typography, useTheme } from "@mui/material";
import Breadcrumbs from "@components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { useBreadcrumbs } from "@context/BreadcrumbsContext";
import { useParams } from "react-router-dom";
import CustomSearch from "@ui/Search";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { serviceAntiPlagiarism } from "@services/antiPlagiarism";
import { serviceCourse } from "@services/course";
import AvatarPicture from "@components/AvatarPicture";
import { MaterialReactTable, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import {
  type MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table"
import { StudentDetailsAntiPlagiarism } from "./StudentDetailsAntiPlagiarism";
import Message from "@components/Message";

export const StudentList = () => {
  const { id, courseId } = useParams();
  const { common } = useTheme().palette;
  const { setBreadcrumbs } = useBreadcrumbs();
  const [searchName, setSearchByName] = useState<string>("")
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const queryClient = useQueryClient()
  const { t } = useTranslation([
    "course",
    "user",
    "sideMenu",
    "dictionary",
    "common",
    "enum",
    "antiPlagiarism"
  ]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });

  useEffect(() => {
    setBreadcrumbs("antiPlagiarismStudentList", [
      {
        level: 1,
        page: "settings",
        label: "course:SETTINGS",
        path: "/settings",
        tabNum: null,
      },
      {
        level: 2,
        page: "integrations",
        label: "sideMenu:INTEGRATIONS",
        path: `/settings/integrations/${id}`,
        tabNum: null,
      },
      {
        level: 3,
        page: "antiPlagiarism",
        label: "antiPlagiarism:ANTIPLAGIARISM",
        path: `/settings/integrations/${id}/anti-plagiarism`,
        tabNum: null,
      },
      {
        level: 4,
        page: "antiPlagiarismStudentList",
        label: "antiPlagiarism:COURSE_REPORT",
        path: null,
        tabNum: null,
      },
    ]);
    // eslint-disable-next-line
  }, []);

  const { data: isActiveCourse } =
    useQuery({
      queryKey: ['getIsActiceCourse'],
      queryFn: () => serviceAntiPlagiarism.getIsActiceCourse(courseId, Number(id)),
      retry: 0,
    });

  const { data: courseInfo } = useQuery({
    queryKey: ["shortCourseInfo"],
    queryFn: () => serviceCourse.getShortInfoCourse(courseId),
    retry: 0,
  })

  const { data: listenetList } =
    useQuery({
      queryKey: ['listenetListByCourseIdAntiPlagiarism', pagination.pageIndex, pagination.pageSize, sorting, searchName],
      queryFn: () => serviceAntiPlagiarism.getListenerListByCourseId({
        pageNumber: pagination.pageIndex,
        size: pagination.pageSize,
        filter: { courseId: courseId, search: searchName },
        sorting: sorting?.length > 0 ? { [sorting[0].id]: sorting[0].desc === true ? "desc" : "asc" } : {}
      },),
      retry: 0,
      placeholderData: keepPreviousData,
    });

  const { mutate: handleUpdateCourseAntiPlagiarsm } = useMutation({
    mutationFn: (reqBody: { courseId: string, enabled: boolean }) => serviceAntiPlagiarism.updateSwitchCoursePlagiarism(reqBody),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getIsActiceCourse'] })
      queryClient.invalidateQueries({ queryKey: ['courseListAntiPlagiarism'] })
      setMessage(t("antiPlagiarism:SUCCESSFULLY_CONNECTED_ANTIPLAGIARISM"));
      setMessageOpen(true);
      setMessageType("success");
      setTimeout(() => {
        setMessageOpen(false);
      }, 3000);
    },
  })

  const columns = useMemo<any>(
    () => [
      {
        accessorKey: "name",
        header: t("course:COURSE_NAME"),
        size: 370,
        minSize: 190,
        enableSorting: true,
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: common.tableHeaderColor,
            boxShadow: "5px 0 7px -2px rgba(91, 90, 48, 0.1)",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            backgroundColor: "#FFFFFF",
            boxShadow: "12px 0 8px -1px rgba(91, 90, 48, 0.1)",
          },
        },
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            {row?.original?.profilePhotoId ? (
              <AvatarPicture
                id={row?.original?.profilePhotoId}
                sxProps={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <Avatar sx={{
                width: "36px",
                height: "36px",
              }} />
            )}
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "20px",
                cursor: "pointer",
              }}
            >
              {renderedCellValue}
            </Typography>
          </Box>
        ),
      },
      {
        accessorKey: 'checksCount',
        header: t("antiPlagiarism:SPENT"),
        enableSorting: true,
        size: 100
      },
    ], [t, common]);

  const table = useMaterialReactTable({
    columns,
    data: listenetList?.data.dtoList || [],
    enableSorting: true,
    enableColumnActions: false,
    enableExpandAll: false,
    enableColumnResizing: false,
    enableTopToolbar: false,
    enableRowNumbers: true,
    enableColumnPinning: false,
    enableRowActions: false,
    manualPagination: true,
    manualSorting: true,
    localization: {
      noRecordsToDisplay: t('dictionary:EMPTY'),
      rowsPerPage: t('enum:LINES_PER_PAGE'),
      actions: "",
      expand: "",
      rowNumber: '№',
    },
    displayColumnDefOptions: {
      "mrt-row-expand": {
        size: 30,
        muiTableBodyCellProps: { align: "center" },
      },
      "mrt-row-actions": {
        size: 30,
        muiTableBodyCellProps: { align: "center" },
      },
    },
    layoutMode: "grid",
    columnResizeMode: "onChange",
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        display: "grid",
        borderRadius: "6px",
        border: "1px solid #E8E8E8"
      },
    },
    muiTableContainerProps: {
      sx: {
        "&::-webkit-scrollbar": { height: "4px", width: "7px" },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#D9D9D9",
          borderRadius: "4px",
        },
      },
    },

    muiTableHeadCellProps: {
      sx: { backgroundColor: common.tableHeaderColor, borderLeft: '1px solid #E8E8E8' },
    },
    muiTableBodyCellProps: {
      sx: { backgroundColor: "#FFFFFF", borderLeft: '1px solid #E8E8E8' },
    },
    mrtTheme: (theme) => ({
      draggingBorderColor: theme.palette.common.primaryColor,
    }),
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50],
      shape: "rounded",
      variant: "outlined",
      SelectProps: {
        sx: {
          backgroundColor: "#FFFFFF",
          color: common.primaryColor,
          "& .MuiInputBase-root": {
            "& .MuiSelect-icon": { color: common.primaryColor },
          },
        },
      },
      sx: {
        "& .Mui-selected": {
          background: `${common.primaryColor} !important`,
          color: "#FFFFFF",
        },
      },
    },
    renderDetailPanel: ({ row }) => (
      <StudentDetailsAntiPlagiarism studentDetails={row.original?.usages} />
    ),
    onPaginationChange: setPagination,
    state: { pagination, sorting }, //pass the pagination state to the table
    rowCount: listenetList?.data.totalElements || 0,
    onSortingChange: setSorting,
    paginationDisplayMode: "pages",
  });

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: "24px" }}>
        <Breadcrumbs id="antiPlagiarismStudentList" />
      </Box>
      <Typography sx={{ fontSize: "36px", fontWeight: 500, mb: "24px" }}>
        {courseInfo?.data.name}
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="12px"
      >
        <CustomSearch placeholder={t("common:placeholders.SEARCH_BY_FULL_NAME")} width='460px' onChange={(value) => setSearchByName(value)} />
        <Box display="flex" gap={1} sx={{ background: common.white, borderRadius: "6px", padding: "0px 8px", alignItems: "center" }} alignItems="center">
          <Typography fontSize="16px" color="#6D737A">{t("antiPlagiarism:ACTIVATED")}</Typography>
          <Switch checked={isActiveCourse?.data} onChange={(event) => handleUpdateCourseAntiPlagiarsm({ courseId: courseId || "", enabled: event.target.checked })
          } />
        </Box>
      </Box>
      {messageOpen && (
        <Box mb="12px">
          <Message
            type={messageType}
            message={message}
            setMessageOpen={messageOpen}
          />
        </Box>
      )}
      <MaterialReactTable table={table} />
    </Box>
  );
};
