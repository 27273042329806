import { IUser, IUserRole } from "@interfaces/index";

export const initialUserRole: IUserRole = {
  id: null,
  belongsToOrg: false,
  disabled: false,
  orgId: 0,
  role: { id: null },
  subdivisionId: null,
  eduProgramId: null,
  used: false,
  current: false
}

export const initialDto: IUser = {
  id: '',
  lastName: null,
  firstName: null,
  patronymic: null,
  iin: null,
  sex: null,
  birthdate: null,
  email: null,
  username: null,
  mobilePhone: null,
  sendNotificationAfterCreate: false,
  profilePhotoId: null,
  detailInfo: null,
  youtube: null,
  facebook: null,
  instagram: null,
  langIds: [],
  userRoles: [
    initialUserRole
  ],
  isDisabled: false,
  createdBy: null,
  migrated: false
};

export interface IFilter {
  name: string;
  orgId: number | null;
  subdivisionId: number | null;
  eduProgramId: number | null;
  userType: string;
  isDisabled: boolean | null;
  roleId: number | null;
}

export const initialFilter: IFilter = {
  name: "",
  orgId: null,
  subdivisionId: null,
  eduProgramId: null,
  userType: "",
  isDisabled: null,
  roleId: null,
};

export interface IFilterExternal {
  search: string,
  branchId: number | null,
  courseId: string,
  startDate: string,
  endDate: string
}

export const initialFilterExternal: IFilterExternal = {
  search: '',
  branchId: null,
  courseId: '',
  startDate: '',
  endDate: ''
};

export enum SortingType {
  ASC = 'asc',
  DESC = 'desc'
}
export interface ISortingExternal {
  fio: SortingType | string,
  courseName: SortingType,
  regDate: SortingType | string,
  status: SortingType | string,
  email: SortingType | string
}
