import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SearchIcon from "@mui/icons-material/Search";

import { serviceMember } from "@services/member";
import useLocalPagination from "@hooks/useLocalPagination";
import GroupModal from "./GroupModal";
import CustomButton from "@ui/Button";
import { IGroupDto } from "./models";
import { Typography, Snackbar, } from "@mui/material";
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import { CourseStatus, Role } from "@interfaces/index";

const Group = ({ courseData, setMessage, setMessageType, setMessageOpen, studyClasses }) => {
  const { t } = useTranslation(["enum", "dictionary", "common", "course"]);
  const { common } = useTheme().palette;
  const [groupId, setGroupId] = useState<string>();
  const [groupList, setGroupList] = useState<IGroupDto[]>([])
  const {
    totalElements, rowsPerPage, page, data,
    handleChangePage, handleChangeRowsPerPage, setInitData,
  } = useLocalPagination<IGroupDto>()
  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const isClosed = courseData?.status === CourseStatus.CLOSED;
  const isAuthor = courseData?.authors?.some((author) => author.userId === courseData?.userId);
  const isLocalAdmin = courseData?.roleType === Role.LOCAL_ADMIN;
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    filterData(searchText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const handleToggleGroupModal = (modalState: boolean, groupId?: string, message?: string) => {
    setGroupId(groupId);
    setGroupModalOpen(modalState);
    if (message) {
      setMessageOpen(true)
      setMessage(message)
      setMessageType('success')
      setTimeout(() => {
        setMessageOpen(false)
      }, 3000)
    }
  };

  const deleteGroup = () => {
    return serviceMember.deleteGroup(groupId)
      .then(res => {
        if (res.status === 200) {
          setGroupId(undefined);
          setInitData([...data].filter(el => el.id !== groupId));
        }
      })
  }

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteGroup,
    modalTitle: t(`common:DELETE_ITEM_CONFIRMATION_TEXT`),
  })

  const filterData = (text: string) => {
    if (searchText.length > 0) {
      setInitData([...groupList].filter(el => el?.name?.toUpperCase().includes(text.toUpperCase())))
    } else {
      setInitData(groupList)
    }
  }

  useEffect(() => {
    if (studyClasses.id) {
      serviceMember.getGroupList(studyClasses.id || "")
        .then((res) => {
          setInitData(res.data)
          setGroupList(res.data)
        })
        .catch(() => { })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyClasses.id])

  const updateListenerGroupList = (newGroup: IGroupDto) => {
    if (data.some((group) => group.id === newGroup.id)) {
      setInitData(data.map((group) => {
        if (group.id === newGroup.id) {
          return newGroup
        } else {
          return group
        }
      }));
      setGroupList(data.map((group) => {
        if (group.id === newGroup.id) {
          return newGroup
        } else {
          return group
        }
      }));
    } else {
      setInitData([...data, newGroup]);
      setGroupList([...data, newGroup]);
    }
  }

  const transferGroup = (oldGroupId: string, newGroupId: string) => {
    const transferData = data.map((group) => {
      if ((group.id === oldGroupId || group.id === newGroupId) && group.memberSum !== undefined) {
        return { ...group, memberSum: group.id === oldGroupId ? group.memberSum - 1 : group.memberSum + 1 }
      } else {
        return group
      }
    })
    setInitData(transferData);
    setGroupList(transferData);
  }

  return (
    <>
      {DeleteModal}
      <Box sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mt: '24px' }}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          color="primary"
          sx={{
            width: { sm: "75%", xs: '100%' }, mr: "24px", justifyContent: "flex-start", '& label.Mui-focused': { color: common.primaryColor },
            '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': { borderColor: common.primaryColor },
              '&.Mui-focused fieldset': { borderColor: common.primaryColor }
            }
          }}
          placeholder={t('common:placeholders.SEARCH')}
          name="searchValue"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box sx={{ backgroundColor: common.primaryColor, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px', mr: '-14px', borderRadius: '6px' }}>
                  <SearchIcon sx={{ color: '#FFFFFF', width: '26px', height: '26px' }} />
                </Box>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <CustomButton
          backgroundColor={common.primaryColor}
          fontColor={common.fontWhite}
          width='fit-content'
          borderRadius="6px"
          height="38px"
          sx={{
            alignItems: "center",
            fontFamily: "Golos",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "130%",
          }}
          onClick={() => handleToggleGroupModal(true)}
          disabled={isClosed || (!isAuthor && !isLocalAdmin) || !!courseData?.externalId || !studyClasses.id}
        >
          {t('common:actions.ADD_GROUP_OF_STUDENTS')}
        </CustomButton>
      </Box>
      <Box>
        {data.length > 0 ? (
          <Box sx={{ mt: "40px", width: '100%', display: 'table', tableLayout: 'fixed' }}>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontFamily: "Golos",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#7D7D7D",
                        mr: "16px",
                        ml: "8px",
                        width: "235",
                      }}
                    >
                      {t('dictionary:TITLE')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Golos",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#7D7D7D",
                        mr: "16px",
                      }}
                    >
                      {t('enum:LISTENERS')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Golos",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#7D7D7D",
                        mr: "16px",
                      }}
                    >
                      {" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((group, index) => {
                    return (
                      <TableRow key={`${index}_${group.id}`}>
                        <TableCell
                          sx={{
                            color: common.primaryColor,
                            fontWeight: "500",
                            lineHeight: "20px",
                            mr: "16px",
                            ml: "8px",
                            width: "50%",
                            cursor: 'pointer'
                          }}
                          onClick={() => { if (isAuthor || isLocalAdmin) handleToggleGroupModal(true, group.id || undefined) }}
                        >
                          {group.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "500",
                            lineHeight: "20px",
                            color: "#7D7D7D",
                            mr: "16px",
                            width: '40%',
                          }}
                        >
                          {group?.memberSum}/{group.capacity}
                        </TableCell>
                        <TableCell sx={{ width: '10%' }}>
                          {!isClosed && !courseData?.externalId &&
                            <Box sx={{ cursor: "pointer" }}>
                              <DeleteOutlineIcon
                                sx={{ color: common.errorColor }}
                                onClick={() => {
                                  if (isAuthor || isLocalAdmin) {
                                    setGroupId(group.id || '');
                                    openDeleteConfirmModal(group.id || '');
                                  }
                                }}
                              />
                            </Box>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalElements}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t('enum:LINES_PER_PAGE')}
                  labelDisplayedRows={
                    ({ from, to, count }) => t('enum:TABLE_PAGES', { from, to, count })
                  }
                />
              </Box>
            </TableContainer>
          </Box>
        ) : (
          <Box
            sx={{
              my: "55px",
              display: "flex",
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2
            }}
          >
            <Avatar
              alt="ParticipantsGroup-default-bg-img"
              src={require(`@assets/images/emptyParticipants.png`)}
              variant="square"
              sx={{
                height: { xs: '200px', sm: "321px" },
                width: { xs: '200px', sm: "321px" },
                mb: "8px",
              }}
            />
            <Typography sx={{ fontSize: { sm: '16px', xs: '14px' } }}>
              {t("course:INFORMATION_GROUPS_LISTENERS")}
            </Typography>
          </Box>
        )}
      </Box>
      {groupModalOpen &&
        <GroupModal
          groupId={groupId}
          modalOpen={groupModalOpen}
          handleToggleGroupModal={handleToggleGroupModal}
          setData={updateListenerGroupList}
          courseCompleted={isClosed}
          migratedCourse={!!courseData?.externalId}
          transferGroup={transferGroup}
          openSnackbarFunc={() => setOpenSnackbar(true)}
          studyClasses={studyClasses}
        />
      }
      <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={() => setOpenSnackbar(false)}>
        <Paper sx={{ display: "flex", border: "1px solid #c1c1c1", width: "400px" }} elevation={3}>
          <Box width="6px" sx={{ background: "#ccf1ea", borderRadius: "4px 0px 0px 4px" }}></Box>
          <Box margin="15px 0px 15px 14px" sx={{ background: "#00b998", height: "24px", borderRadius: "15px" }}>
            <CheckCircleOutlineOutlinedIcon sx={{ color: "#fff", margin: "4px", fontSize: "16px" }} />
          </Box>
          <Box margin="15px 10px 15px 14px" sx={{ cursor: "pointer" }} >
            <Typography fontSize="16px" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "290px" }}>{t("course:messages.LISTENER_TRANSLATED")} </Typography>
          </Box>
        </Paper>
      </Snackbar>
    </>
  );
}

export default Group;