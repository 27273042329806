import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from "formik";

import { serviceTask } from '@services/task';
import { IQuestionsFormikContext } from './Questions';
import QuizForms from './QuizForms';
import { QuizTypesView } from './QuizTypesView';
import useDeleteConfirmModal from '@hooks/useDeleteConfirmModal';
import useSnackbar from '@hooks/useSnackbar';
import { QuizQuestion, QuizQuestionTypeEnum } from '@models/quiz-question';
import { quizQuestionTypes } from '@utils/enums';

interface IQuestionsCardProps {
  quizId: string;
  questionData: QuizQuestion;
  questions: QuizQuestion[];
  questionsCopy: QuizQuestion[];
  setQuestions: Dispatch<SetStateAction<QuizQuestion[]>>
  setQuestionsCopy: Dispatch<SetStateAction<QuizQuestion[]>>
  index: number;
  taskStatus: string;
  isDraft: boolean;
}

const QuestionsCard = ({
  questionData: initialQuestionData,
  index,
  quizId,
  setQuestions,
  questions,
  taskStatus,
  isDraft = false
}: IQuestionsCardProps) => {
  const { t } = useTranslation(['enum', 'course']);
  const [isEditingQuestion, setIsEditingQuestion] = useState(false);
  const { MessageComponent, showAlert } = useSnackbar();
  const { values: formikValues, setValues } = useFormikContext<IQuestionsFormikContext>();
  const values = formikValues.data[index];

  const handleSubmit = () => {
    const errText = validateValues(values)
    if (errText) {
      return showAlert({ alertColor: "info", alertText: errText })
    }
    const id = values.id
    if (id) {
      serviceTask.updateTaskQuiz({
        ...values,
        quizId: quizId
      }, id)
        .then((res) => {
          setIsEditingQuestion(false)
          let newValues = { ...formikValues }
          newValues.data[index] = res.data
          setQuestions(newValues.data)
          setValues(newValues)
        })
    } else {
      serviceTask.createTaskQuiz({
        ...values,
        quizId: quizId
      })
        .then((res) => {
          let newValues = { ...formikValues }
          newValues.data[index] = res.data
          setQuestions(newValues.data)
          setValues(newValues)
        })
    }
  }

  const validateValues = (values: QuizQuestion) => {
    let errText = ""
    const minTwoAnswersTypes = [
      QuizQuestionTypeEnum.ONECORRECTANSWER,
      QuizQuestionTypeEnum.SEQUENCE
    ]

    const firstAnswerTypes = [
      QuizQuestionTypeEnum.ONECORRECTANSWER,
      QuizQuestionTypeEnum.MULTIPLECORRECTANSWERS,
      QuizQuestionTypeEnum.TRUEORFALSE,
      QuizQuestionTypeEnum.SEQUENCE,
    ]

    if (minTwoAnswersTypes.includes(values.type) && values.answers && values.answers.length < 2) {
      return t('course:test.QUESTION_CAN_NOT_BE_SAVED', {
        quizTypeName: t(quizTypeName(values.type) || ''),
        variantsNum: 2
      })
    }
    if (QuizQuestionTypeEnum.MAPPING === values.type && values.children && values.children.length < 2) {
      return t('course:test.QUESTION_CAN_NOT_BE_SAVED', {
        quizTypeName: t(quizTypeName(values.type) || ''),
        variantsNum: 2
      })
    }
    if (values.type === QuizQuestionTypeEnum.MULTIPLECORRECTANSWERS && values.answers && values.answers.length < 3) {
      return t('course:test.QUESTION_CAN_NOT_BE_SAVED', {
        quizTypeName: t(quizTypeName(values.type) || ''),
        variantsNum: 3
      })
    }

    if (values.type === QuizQuestionTypeEnum.MULTIPLECORRECTANSWERS && values.answers && !values.answers.find(x => !x.correct)) {
      return `${t('course:messages.NUMBER_OF_CORRECTLY_MARKED_OPTIONS_LESS_THAN_NUMBER_OF_OPTIONS_IN_QUESTION')}`
    }

    if (values.type === QuizQuestionTypeEnum.ONECORRECTANSWER && values.answers && !values.answers.find(x => x.correct)) {
      return `${t('course:messages.OPTION_NOT_SELECTED')}`
    }

    if (values.type === QuizQuestionTypeEnum.TRUEORFALSE && values.answers && !values.answers.find(x => x.correct)) {
      return `${t('course:messages.OPTION_NOT_SELECTED')}`
    }

    if (values.type === QuizQuestionTypeEnum.MULTIPLECORRECTANSWERS && values.answers && !values.answers.find(x => x.correct)) {
      return `${t('course:messages.OPTION_NOT_SELECTED')}`
    }

    if (!values.content) {
      return `${t('course:messages.QUESTION_FIELD_SHOULD_NOT_BE_EMPTY')}`
    }

    if (firstAnswerTypes.includes(values.type)) {
      values.answers?.forEach((el) => {
        if (!el.answer) {
          errText = `${t('course:messages.QUESTION_FIELD_SHOULD_NOT_BE_EMPTY')}`
          return errText
        }
      })
    }

    if (values.type === QuizQuestionTypeEnum.SEQUENCE) {
      values.answers?.forEach((el) => {
        if (!el.order) {
          errText = `${t('course:messages.QUESTION_FIELD_SHOULD_NOT_BE_EMPTY')}`
          return errText
        }
      })
    }

    if (values.type === QuizQuestionTypeEnum.MAPPING) {
      values.children?.forEach((el) => {
        const answers = el.answers || []
        if (!el.content || answers?.length === 0 || !answers[0]?.answer) {
          errText = `${t('course:messages.QUESTION_FIELD_SHOULD_NOT_BE_EMPTY')}`
          return errText
        }
      })
    }

    return errText
  }

  const quizTypeName = (type: string) => {
    return quizQuestionTypes.find(el => el === type)
  }

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: async (id) => {
      await serviceTask.deleteTaskQuiz(id);
      let newValues = { ...formikValues };
      newValues.data = newValues.data.filter((el) => el.id !== id);
      setValues(newValues);
      setQuestions(newValues.data);
    },
    modalTitle: t('course:messages.ARE_YOU_SURE_TO_DELETE_ENTRY')
  })

  const cancelEditingCreating = () => {
    setIsEditingQuestion(false)
    if (!initialQuestionData.id) {
      const data = [...questions.filter((el, i) => i !== index)]
      setQuestions(data)
    }
  }

  const submitForm = () => {
    return new Promise((resolve, reject) => {
      handleSubmit()
      return resolve({})
    })
  }

  return (
    <>
      {DeleteModal}
      <>
        {(isEditingQuestion || !values.id) ?
          <QuizForms
            cancelEditingCreating={() => cancelEditingCreating()}
            isEditingQuestion={isEditingQuestion}
            index={index}
            submitForm={submitForm}
            messageComponent={MessageComponent}
            isDraft={isDraft}
          /> :
          <QuizTypesView
            data={initialQuestionData}
            index={index}
            setQuestionData={() => setIsEditingQuestion(true)}
            openDeleteConfirmModal={openDeleteConfirmModal}
            taskStatus={taskStatus}
          />
        }
      </>
    </>
  )
}

export default QuestionsCard;