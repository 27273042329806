import { Box, Typography, useTheme } from "@mui/material";

interface Props {
  svg?: any;
  text: string;
  leftBorder?: boolean;
  backgroundBorder?: string;
  textColor?: string;
  backgroundColorContent?: string;
  opacityBorderLeft?: number
}
const HintComponent = (props: Props) => {
  const { common } = useTheme().palette;
  const {
    text,
    svg,
    leftBorder,
    textColor,
    backgroundBorder = "#FF9500",
    backgroundColorContent = "#FFFFFF",
    opacityBorderLeft = 0.2
  } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: `1px solid ${common.borderSecondary}`,
        borderRadius: "12px",
        backgroundColor: backgroundColorContent,
        overflow: "hidden",
      }}
    >
      {leftBorder ? (
        <Box
          width={"6px"}
          borderRadius={"8px 0 0 8px"}
          sx={{
            opacity: opacityBorderLeft,
            backgroundColor: backgroundBorder,
            minHeight: "60px",
          }}
        />
      ) : null}
      <Box display={"flex"} alignItems={"center"} gap="12px" p={"10px"}>
        <Box>{svg && svg}</Box>
        <Typography sx={textColor ? { color: textColor } : undefined}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export { HintComponent };
