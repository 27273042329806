import { taskStatusBgColor } from "@utils/enums";
import { TaskProgressStatusEnum } from "./enum";


export function handleColorByStatus(status:string):string|void{
    if (status in TaskProgressStatusEnum) {
        return taskStatusBgColor[status];
    } else {
        return ""; 
    }
}