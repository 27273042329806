import { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Divider, Typography, useTheme, Grid } from '@mui/material';

import { serviceMember } from '@services/member';
import { CourseContext } from "@context/CourseContext";
import Evaluation from './Evaluation';
import { ImportantEvents } from './ImportantEvents';
import { CourseFormatType, CourseRole, ICourse, IMember, Role, TypeCode } from '@interfaces/index';
import { formatDateWithHourMinute } from '@utils/functions';
interface Props {
  courseInfo: ICourse;
  changeStep: (step: number, label: string) => void
}

const MainPage = ({ courseInfo, changeStep }: Props) => {
  const { t } = useTranslation(["user", "course"]);
  const { common } = useTheme().palette;
  const [educatorsList, setEducatorList] = useState<IMember[] | null>(null)
  const { courseId } = useParams();
  const { courseData } = useContext(CourseContext);
  const isFreeCourse = courseData?.typeCode === TypeCode.FREE;
  const isEduCourse = courseData?.typeCode === TypeCode.EDU;

  useEffect(() => {
    if (courseId) {
      const fetchTutorInfo = async () => {
        const res = await serviceMember.getMemberList(courseId.toString(), [CourseRole.TUTOR, CourseRole.CURATOR, CourseRole.ASSISTANT, CourseRole.EXAMINER]);
        setEducatorList(res.data)
      };
      fetchTutorInfo();
    }
  }, [courseId]);

  return (
    <Box>
      <Box sx={{ backgroundColor: common.primaryColor, padding: "30px 15px", color: "#fff", borderRadius: "10px 10px 0 0" }}>
        <Typography fontSize="24px" paddingBottom="20px">{courseInfo.name}</Typography>
        {/* <Box display="flex" alignItems="center" gap="30px">
          <Typography>{t("course:listener.PASS_PERIOD")}: {formatDateWithHourMinute(courseInfo.courseSetting.startDate)}-{formatDateWithHourMinute(courseInfo.courseSetting.endDate)}</Typography>
        </Box> */}
        {/* <Divider sx={{ margin: "20px 0", background: "#fff" }} /> */}
        <Grid container spacing={2}>
          {educatorsList?.map((educator) => {
            return <Grid item display='flex' gap={1} xs={12} sm={6} md={4} lg={3} key={educator.userFullName}>
              <Avatar />
              <Box>
                <Typography fontSize="14px">{educator.userFullName}</Typography>
                <Typography fontSize="12px">{t(`${educator.roleType}`)}</Typography>
              </Box>
            </Grid>
          })}
        </Grid>
      </Box>
      <Evaluation gradingSystemId={courseInfo.courseSetting.gradingSystemId || 1} roleType={courseData?.roleType} changeStep={changeStep} />
      {courseData?.roleType !== Role.ADMIN && courseData?.roleType !== Role.LOCAL_ADMIN && ((isFreeCourse && courseData?.formatType === CourseFormatType.SYNC) || isEduCourse) &&
        <ImportantEvents />
      }
    </Box>
  )
}

export default MainPage;
