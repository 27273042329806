export interface DTO {
  id: string | null;
  lastName: string | null;
  firstName: string | null;
  patronymic: string | null;
  email: string | null;
  sex: string | null;
  birthdate: string | null;
  iin: string | null;
  mobilePhone: string | null;
  role: string | null,
  organization: string | null;
  subdivision: string | null;
  eduProgram: string | null;
  sendNotificationAfterCreate: boolean;
  profilePhotoId: string | null;
  achievements: {
    fileId: string;
    name: string
  }[];
  detailInfo: string | null;
  youtube: string | null;
  facebook: string | null;
  instagram: string | null;
  migrated: boolean;
  langIds?: number[]
}

export interface IRole {
  id: string;
  nameKz: string;
  nameRu: string;
  nameEn: string;
  code: string;
  permissionCodes: Array<string>
}

export const initialDto: DTO = {
  id: null,
  lastName: null,
  firstName: null,
  patronymic: null,
  email: null,
  sex: null,
  birthdate: null,
  iin: null,
  mobilePhone: null,
  role: null,
  organization: null,
  subdivision: null,
  eduProgram: null,
  sendNotificationAfterCreate: true,
  profilePhotoId: null,
  achievements: [],
  detailInfo: null,
  youtube: null,
  facebook: null,
  instagram: null,
  migrated: false
}