import { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Formik, Form as FormikForm } from "formik";

import CustomModal from '@components/Modal';
import CustomButton from '@ui/Button';
import { quizValidationSchema } from "./validation";
import { QuizDraft, initialDto } from "./models";
import FormikInput from '@ui/formik/Input';
import FormikAutocomplete from '@ui/formik/Autocomplete';
import CustomFileUpload from '@ui/FileUpload';
import { serviceFile } from "@services/file";

interface Props {
  languageList: Array<any>;
  subdivisionList: Array<any>;
  modalOpen: boolean;
  handleToggleModal: (modalState: boolean, message?: string) => void;
  createQuizDraft: (reqBody: QuizDraft) => void;
}

const AddQuizModal = ({
  languageList,
  subdivisionList,
  modalOpen,
  handleToggleModal,
  createQuizDraft
}: Props) => {
  const { t } = useTranslation(['common', "user", "course", "dictionary"]);
  const { common } = useTheme().palette;
  const [file, setFile] = useState<{ id?: string, name?: string }>({});

  const handleFileAfterUpload = (fileId: string, fileName: string, setFieldValue: any) => {
    setFile({ id: fileId, name: fileName });
    setFieldValue('fileId', fileId);
  };

  return (
    <CustomModal
      open={modalOpen}
      handleToggleModal={handleToggleModal}
      width={550}
      showCloseIcon
    >
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: "21px",
            color: "#000000",
            display: "flex",
            alignItems: "center",
            mb: "24px",
            width: '100%'
          }}
        >
          {t('course:IMPORT_TESTS')}
        </Typography>
        <Formik
          initialValues={initialDto}
          enableReinitialize
          validationSchema={quizValidationSchema(t)}
          onSubmit={(values: QuizDraft) => createQuizDraft(values)}
        >
          {({ submitForm, isValid, dirty, values, setFieldValue }) => (
            <FormikForm>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2
                }}
              >
                <FormikInput
                  label={t('course:NAME_OF_TEST_ASSIGNMENT')}
                  name="name"
                  placeholder={t('common:placeholders.ENTER_NAME')}
                  isRequired
                />
                <FormikAutocomplete
                  name="langId"
                  label={t("dictionary:QUIZ_LANGUAGE")}
                  options={languageList}
                  isRequired
                  clearable={false}
                />
                <FormikAutocomplete
                  name="subdivisionIds"
                  label={t("enum:SUBDIVISION")}
                  options={subdivisionList}
                  isRequired
                  multiple
                  selectAll
                />
                {values.fileId ?
                  <Box sx={{ display: "flex", gap: 2 }}>
                    {file.name}
                    <DeleteOutlinedIcon
                      onClick={() => {
                        serviceFile.deleteFile(values.fileId || "").then((res) => {
                          if (res.status === 200) {
                            setFieldValue('fileId', null);
                            setFile({});
                          }
                        })
                      }}
                      sx={{ color: common.errorColor, cursor: 'pointer' }}
                    />
                  </Box> :
                  <CustomFileUpload
                    title={t('dictionary:DRAG_FILE_HERE_OR')}
                    fileTypeInfo={t('dictionary:MICROSOFT_WORD_FILES_CAN_BE_IMPORTED')}
                    fileTypeCode="010"
                    acceptedExtensions=".doc, .docx"
                    confirmed
                    handleFileAfterUpload={(fileId: string, file: any) => handleFileAfterUpload(fileId, file.name, setFieldValue)}
                    height={200}
                  />

                }
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    justifyContent: 'end'
                  }}
                >
                  <CustomButton
                    backgroundColor={common.btnCancel}
                    fontColor={common.primaryColor}
                    borderRadius="6px"
                    width='146px'
                    height="40px"
                    sx={{
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    onClick={() => handleToggleModal(false)}
                  >
                    {t('actions.CANCEL')}
                  </CustomButton>
                  <CustomButton
                    backgroundColor={common.primaryColor}
                    fontColor={common.fontWhite}
                    borderRadius="6px"
                    width='146px'
                    height="40px"
                    disabled={!(isValid && dirty)}
                    onClick={submitForm}
                    sx={{
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    {t('common:actions.IMPORT')}
                  </CustomButton>
                </Box>
              </Box>
            </FormikForm>
          )}
        </Formik>
      </>
    </CustomModal>
  )
}

export default AddQuizModal;