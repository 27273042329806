import { AlertColor, Box, Typography, useTheme } from '@mui/material'
import { Formik, Form as FormikForm } from 'formik';

import { serviceCourse } from '@services/course';
import CustomModal from '@components/Modal';
import DateTimePicker from '@ui/formik/DateTimePicker';
import CustomButton from '@ui/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  openDayModal: boolean;
  handleCloseDayModal: () => any;
  currentDay: any,
  setMessage: (message: string) => void;
  setMessageOpen: (open: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  updateLists: () => void;
  subjectList: any;
}

export const EditDayModal = ({ openDayModal, handleCloseDayModal, currentDay, setMessage, setMessageOpen, setMessageType, updateLists, subjectList }: Props) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["common"]);
  return (
    <>
      <CustomModal
        open={openDayModal}
        handleToggleModal={handleCloseDayModal}
        minWidth={450}
        disableAutoFocus
      >
        <>
          <Typography fontWeight={600} fontSize="24px">{t('common:actions.EDIT_ATTENDANCE_DATA')}</Typography>
          <Formik
            initialValues={{ day: currentDay }}
            enableReinitialize
            validationSchema={null}
            onSubmit={(value) => {
              const updateSubjectList = subjectList.map((subject) => subject.id)
              serviceCourse.updateDayAttendance(value.day, updateSubjectList).then((res) => {
                if (res.status === 200) {
                  handleCloseDayModal()
                  setMessageOpen(true);
                  setMessageType("success");
                  setMessage(t('common:messages.CHANGES_SAVED'));
                  updateLists()
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 3000)
                }
              })
            }}>
            {({ submitForm }) => {
              return (
                <FormikForm>
                  <Box mb="12px" mt="12px">
                    <DateTimePicker label={t('common:label.DATE')} name="day" isRequired />
                  </Box>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <CustomButton
                      backgroundColor={common.btnCancel}
                      fontColor={common.primaryColor}
                      width="200px"
                      borderRadius="6px"
                      height="40px"
                      sx={{
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                      onClick={handleCloseDayModal}
                    >
                      {t('common:actions.CANCEL')}
                    </CustomButton>
                    <CustomButton
                      width="200px"
                      height="40px"
                      backgroundColor={common.primaryColor}
                      borderRadius="8px"
                      fontColor={common.fontWhite}
                      onClick={submitForm}
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        alignItems: "center",
                        color: "#FFFFFF",
                      }}
                    >
                      {t('common:actions.SAVE')}
                    </CustomButton>
                  </Box>
                </FormikForm>
              )
            }}
          </Formik>
        </>
      </CustomModal>
    </>
  )
}
