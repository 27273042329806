import { useState } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { DTO } from '@pages/profile/models';
import CustomModal from '@components/Modal';
import AvatarPicture from '@components/AvatarPicture';
import FileViewerModal from '@components/modals/FileViewerModal';
import { removeHtml } from '@utils/functions';

interface IProps {
  open: boolean;
  handleToggleModal: any;
  tutorInfo: DTO;
  isAuthor: boolean
}

const AuthorModal = ({ open, handleToggleModal, tutorInfo, isAuthor }: IProps) => {
  const { common } = useTheme().palette;
  const [indexActiveTeacher, setIndexActiveTeacher] = useState<number>(0)
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [currentImage, setCurrentImage] = useState<any>();

  function handleForwardChange(index: number) {
    setIndexActiveTeacher(index + 1)
  }
  function handleBackChange(index: number) {
    setIndexActiveTeacher(index - 1)
  }
  const handleToggleImageViewer = (modalState: boolean, imageFile?: any) => {
    if (imageFile) setCurrentImage(imageFile);
    setOpenImageViewer(modalState);
  }

  return (
    <>
      <CustomModal
        open={open}
        handleToggleModal={handleToggleModal}
        showCloseIcon
        width={"870px"}
      >
        <>
          <Box display="flex" justifyContent="space-between" marginBottom="12px">
            <Box display="flex" gap={2} alignItems="center">
              <AvatarPicture id={tutorInfo.profilePhotoId} sxProps={{ width: "50px", height: "50px" }} />
              <Box>
                <Typography fontSize="16px" fontWeight={600} marginBottom="6px">{tutorInfo.firstName} {tutorInfo.lastName}</Typography>
                {isAuthor && <Box sx={{ width: "55px", background: "#385FE8", color: "#fff", fontSize: "12px", padding: "2px 10px", borderRadius: "15px" }}>Автор</Box>}
              </Box>
            </Box>

            <Box>
              {(tutorInfo.instagram || tutorInfo.youtube || tutorInfo.facebook) && <Box display="flex" gap={1}>
                {tutorInfo.youtube && <YouTubeIcon sx={{ color: "#FF0000", fontSize: "28px", cursor: "pointer" }} />}
                {tutorInfo.instagram && <InstagramIcon sx={{ color: "#AE38B4", fontSize: "28px", cursor: "pointer" }} />}
                {tutorInfo.facebook && <FacebookIcon sx={{ color: "#337FFF", fontSize: "28px", cursor: "pointer" }} />}
              </Box>}
            </Box>
          </Box>
          <Box position="absolute" display="flex" gap="800px" marginTop="50px">
            <Box sx={{ width: '28px', height: '28px', borderRadius: '50%', backgroundColor: '#F1EBFA', display: 'flex', justifyContent: 'center', marginLeft: "-35px" }}>
              <IconButton disabled={indexActiveTeacher === 0} onClick={() => handleBackChange(indexActiveTeacher)}>
                <ArrowBackIosNewIcon sx={{ width: '20px', height: '20px', color: common.primaryColor, cursor: 'pointer' }} />
              </IconButton>
            </Box>
            <Box sx={{ width: '28px', height: '28px', borderRadius: '50%', backgroundColor: '#F1EBFA', display: 'flex', justifyContent: 'center' }}>
              <IconButton onClick={() => handleForwardChange(indexActiveTeacher)}>
                <ArrowForwardIosIcon sx={{ width: '20px', height: '20px', color: common.primaryColor, cursor: 'pointer' }} />
              </IconButton>
            </Box>
          </Box>
          <Box margin="20px 0">
            {removeHtml(tutorInfo.detailInfo || '')}
          </Box>
          {tutorInfo.achievements.length > 0 && <Box display="flex" gap={1}
            sx={{
              display: 'flex',
              overflowY: "auto",
              width: "95%",
              '&::-webkit-scrollbar': { width: '2px', height: '7px' },
              '&::-webkit-scrollbar-thumb': { backgroundColor: '#E5E0EB', borderRadius: '6px' }
            }}
          // sx={{
          //   overflowX: "scroll",
          //   '&::-webkit-scrollbar': { height: '4px' },
          //   '&::-webkit-scrollbar-thumb': { backgroundColor: '#E5E0EB', borderRadius: '6px' }
          // }}
          >{tutorInfo.achievements.map((achievement) => {
            const isPDF = achievement.name.endsWith('.pdf')

            return (
              <Box
                key={achievement.fileId}
                sx={{ p: '12px 8px' }}
                onClick={() => handleToggleImageViewer(true, achievement)}
              >
                {isPDF
                  ? <Box
                    sx={{
                      display: 'grid',
                      placeContent: 'center',
                      borderRadius: "8px",
                      width: '130px',
                      height: '130px',
                      cursor: "pointer",
                      background: '#A9B6FF33'
                    }}>
                    <PictureAsPdfIcon sx={{ width: '50px', height: '50px', color: '#9469E3' }} />
                  </Box>
                  : <AvatarPicture
                    id={achievement.fileId}
                    sxProps={{
                      border: "1px solid #D9D9D9",
                      borderRadius: "8px",
                      width: '130px',
                      height: '130px',
                      cursor: "pointer"
                    }}
                  />}
              </Box>
            )
          })}

          </Box>}
        </>
      </CustomModal>
      {openImageViewer &&
        <FileViewerModal
          openModal={openImageViewer}
          handleToggleModal={handleToggleImageViewer}
          file={currentImage}
        />
      }
    </>
  )
}

export default AuthorModal;
