import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Divider, Grid, Tab, Typography, useTheme } from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";

import CustomAutocomplete from "@ui/Autocomplete";
import { FilterByGroup } from "@pages/myCourses/Educator/TabList/Conferences/model";
import { CourseRole, IComment } from "@interfaces/index";
import VideoPlayer from "@components/VideoPlayer";
import { VideoItem } from "../VideoItem/VideoItem";
import { formatRelative, subDays } from "date-fns";
import { ru } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { CommentaryInfo } from "../CommentaryInfo/CommentaryInfo";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { StateSchema } from "@store/StateSchema";
import CustomInput from "@ui/Input";

enum TabState {
  FILE = "FILE",
  RESULT = "RESULT",
}
interface Props {
  video?: IComment[];
}

interface IDate {
  id: string;
  name: string;
}
const ListVideo = (props: Props) => {
  const { progressId } = useParams();
  const { common } = useTheme().palette;
  const { video } = props;
  const [tabValue, setTabValue] = useState<TabState>(TabState.FILE);
  const [comment, setComment] = useState<string>("");
  const { t } = useTranslation(["course", "enum"]);
  const profile = useSelector(
    (store: StateSchema) => store.account.profileInfo
  );
  const firstPickData: IDate = {
    id: "0",
    name: format(
      new Date(video?.[0]?.updatedAt ?? Date.now()),
      "eeee dd.MM, HH:mm",
      {
        locale: ru,
      }
    ),
  };
  const [date, setDate] = useState<IDate>(firstPickData);
  const pickDate = video
    ?.filter((item) => item.commentatorType === CourseRole.LISTENER)
    ?.map((item, index) => {
      return {
        id: String(index),
        name: format(
          new Date(item?.updatedAt || "") ?? "",
          "eeee dd.MM, HH:mm",
          {
            locale: ru,
          }
        ),
      };
    });
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "comment") setComment(value);
  };

  return (
    <TabContext value={tabValue}>
      <TabList
        textColor="secondary"
        indicatorColor="secondary"
        onChange={(_, e) => setTabValue(e)}
        sx={{
          borderBottom: `1px solid ${common.borderSecondary}`,
        }}
        TabIndicatorProps={{
          style: {
            border: `1px solid ${common.primaryColor}`,
          },
        }}
      >
        {Object.values(TabState).map((item) => (
          <Tab label={t(`enum:${item}`)} value={item} />
        ))}
      </TabList>
      <TabPanel
        sx={{
          padding: 0,
        }}
        value={TabState.FILE}
      >
        <Typography
          sx={{
            fontSize: "12px",
            lineHeight: "15px",
            color: "#9C9C9C",
            mb: 0.5,
          }}
        >
          {t("course:task.ANSWER_TO_TASK")}
        </Typography>
        <CustomAutocomplete
          sx={{ width: "270px" }}
          name="video"
          options={pickDate}
          placeholder="Относится к"
          customIconDisabled={false}
          onChange={(_, value) => setDate(value)}
          value={date}
          valueAsObject
          clearable={false}
        />
        <Divider sx={{ my: 1.5 }} />

        <Grid
          display={"grid"}
          gridTemplateColumns={"repeat(auto-fill,minmax(293px,1fr))"}
          gap={1.5}
        >
          {video
            ?.filter((item) => item.commentatorType === CourseRole.LISTENER)
            ?.[date.id]?.replyFiles?.map((file) => (
              <VideoItem name={file.fileName ?? ""} src={file.fileId ?? ""} />
            ))}
        </Grid>
       
      </TabPanel>
      <TabPanel value={TabState.RESULT}>
        <CommentaryInfo progressId={progressId ?? ""} userId={profile.pltaId} />
      </TabPanel>
    </TabContext>
  );
};

export { ListVideo };
