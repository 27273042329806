import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AlertColor } from "@mui/material/Alert";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Popover from "@mui/material/Popover";

import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import { CourseContext } from '@context/CourseContext';
import Message from "@components/Message";
import Breadcrumbs from '@components/Breadcrumbs';
import MainPage from '../TabList/Main';
import { ActionCourse } from './ActionCourse';
import { ConferencePage } from '../TabList/Conferences';
import Description from '../TabList/Description';
import { SettingPage } from '../TabList/Settings';
import FlowList from '../TabList/Flows';
import { ParticipantsPage } from '../TabList/Participants/ParticipantsPage';
import Content from '../TabList/Content';
import { JournalPage } from '../TabList/Journal';
import TaskList from '../TabList/Assessment/TaskList';
import { CourseStatus, IBreadcrumb, ICourse } from '@interfaces/index';
import CourseMainPage from '@pages/myCourses/Student/TabList/Main';
import { TaskCurrentCourses } from '@pages/myCourses/Student/CurrentCourses/Task/List';
import { ConferenceCurrentCourses } from '@pages/myCourses/Student/CurrentCourses/Conference';
import Notes from '@pages/myCourses/Student/TabList/Notes';
import { Attendance } from '@pages/myCourses/Student/CurrentCourses/Attendance';
import { ContentCurrentCourses } from '@pages/myCourses/Student/CurrentCourses/Content';
import PreviewLogout from '@components/PreviewLogout';
import { serviceCourse } from '@services/course';

interface IProps {
  courseId: string;
  courseInfo: ICourse;
  setCourseInfo: (courseInfo: ICourse) => void;
};

const tabList = [
  {
    label: "course:MAIN_PAGE",
    tabName: "course:MAIN_PAGE",
    step: 1
  },
  {
    label: "course:DESCRIPTION",
    tabName: "course:DESCRIPTION",
    step: 2
  },
  {
    label: "course:SETTINGS",
    tabName: "course:SETTINGS",
    step: 3
  },
  {
    label: "course:FLOW_CONTROL",
    tabName: "course:FLOWS",
    step: 4
  },
  {
    label: "course:PARTICIPANTS",
    tabName: "course:PARTICIPANTS",
    step: 5
  },
  {
    label: "course:CONTENT_COURSE",
    tabName: "course:CONTENT",
    step: 6
  },
  {
    label: "course:EVALUATION",
    tabName: "course:EVALUATION",
    step: 7
  },
  {
    label: "course:GRADEBOOK",
    tabName: "course:GRADEBOOK",
    step: 8
  },
  {
    label: "course:CONFERENCES",
    tabName: "course:CONFERENCES",
    step: 9
  },
  {
    label: "common:actions.PREVIEW",
    tabName: "common:actions.PREVIEW",
    step: 10
  },
  {
    label: "course:MAIN_PAGE",
    tabName: "course:MAIN_PAGE",
    step: 11
  },
  {
    label: "course:CONTENT",
    tabName: "course:CONTENT",
    step: 12
  },
  {
    label: "course:TASKS",
    tabName: "course:TASKS",
    step: 13
  },
  {
    label: "course:CONFERENCES",
    tabName: "course:CONFERENCES",
    step: 14
  },
  {
    label: "course:ATTENDANCE",
    tabName: "course:ATTENDANCE",
    step: 15
  },
  {
    label: "course:NOTE",
    tabName: "course:NOTE",
    step: 16
  },
];

const PublishedCourse = ({ courseId, courseInfo, setCourseInfo }: IProps) => {
  const { t } = useTranslation(["course", "common"]);
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const location = useLocation();
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const theme = useTheme();
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [messageType, setMessageType] = useState<AlertColor>('success');
  const { courseData } = useContext(CourseContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const stepLabel = getBreadcrumbs(courseId).find((x: IBreadcrumb) => x.level === 2)?.label || "course:MAIN_PAGE";
  const currentStep = getBreadcrumbs(courseId).find((x: IBreadcrumb) => x.level === 2)?.tabNum || 1;
  const [unpublishCourseIsAvailable, setUnpublishCourseIsAvailable] = useState<boolean>(false);
  const [closeCourseIsAvailable, setCloseCourseIsAvailable] = useState<boolean>(false);
  const matchesUp1460px = useMediaQuery(theme.breakpoints.up(1460));
  const matchesDown1200px = useMediaQuery(theme.breakpoints.down(1200));
  const matchesDown600px = useMediaQuery(theme.breakpoints.down(600));
  const blockedTabPopover = Boolean(anchorEl);

  useEffect(() => {
    let updatedCrumbs = [...getBreadcrumbs(courseId)].filter((x: IBreadcrumb) => x.level !== 3 && x.level !== 4);
    if (updatedCrumbs.length === 0) {
      updatedCrumbs.push({ level: 1, page: "course", label: `${courseInfo?.name}`, path: `/myCourses/${courseId}`, tabNum: null });
      updatedCrumbs.push({ level: 2, page: 'course', label: "course:MAIN_PAGE", path: window.location.pathname, tabNum: 1 });
    } else if (updatedCrumbs.length === 1) {
      updatedCrumbs.push({ level: 2, page: 'course', label: "course:MAIN_PAGE", path: window.location.pathname, tabNum: 1 });
    }
    // Check if the breadcrumbs have actually changed before updating
    if (JSON.stringify(updatedCrumbs) !== JSON.stringify(getBreadcrumbs(courseId))) {
      setBreadcrumbs(courseId, updatedCrumbs);
    }
  }, [getBreadcrumbs, setBreadcrumbs, courseId, courseInfo?.name]);

  useEffect(() => {
    if (currentStep === 1) {
      serviceCourse.checkUnpublishCourseAction(courseId).then(res => {
        if (res.status === 200) setUnpublishCourseIsAvailable(res.data);
      })

      serviceCourse.checkCloseCourseAction(courseId).then(res => {
        if (res.status === 200) setCloseCourseIsAvailable(res.data);
      })
    }
  }, [courseId, currentStep]);

  const changeStep = (step: number, label: string) => {
    if (step === 10) {
      step = 11
      label = "course:MAIN_PAGE"
    }
    const newCrumbs = [...getBreadcrumbs(courseId)].map((crumb) => {
      // If the crumb to update is found, replace the crumb with updated fields
      if (crumb.level === 2) {
        return { ...crumb, label, tabNum: step };
      }

      // Otherwise, return the original crumb
      return crumb;
    });
    setBreadcrumbs(courseId, newCrumbs);
  }

  useEffect(() => {
    //TODO: need correct logic useEffect
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.has('type')) {
      queryParams.delete('type')
      navigate(queryParams.toString())
    }
    if (queryParams.has('descriptionType')) {
      queryParams.delete('descriptionType')
      navigate(queryParams.toString())
    }
    // eslint-disable-next-line
  }, [currentStep]);

  const handleBlockedTabPopover = (event: React.MouseEvent<HTMLElement> | null) => {
    setAnchorEl(event ? event.currentTarget : event);
  };

  return (
    <Box sx={{ maxWidth: '1880px' }}>
      <Box sx={{ display: 'flex', mb: 3 }}>
        <Breadcrumbs id={courseId} />
      </Box>
      <Grid container spacing={2} direction={matchesUp1460px ? 'row' : 'column-reverse'}>
        {currentStep && tabList.slice(Number(currentStep) <= 10 ? 0 : 9).map((item, index) => {
          if (currentStep !== item.step) return null;
          return (
            <Grid
              item xs={12} lg={10}
              key={`course_creation_step_${item.step}_${index}`}
            >
              <Box sx={{ width: "100%" }}>
                {item.step !== 1 &&
                  <Typography fontWeight={500} fontSize="36px">{t(`${stepLabel}`)}</Typography>
                }
                {messageOpen &&
                  <Message
                    type={messageType}
                    message={message}
                    setMessageOpen={setMessageOpen}
                  />
                }
                {(() => {
                  switch (item.step) {
                    case 1: return <MainPage courseInfo={courseInfo} changeStep={changeStep} />
                    case 2: return <Description
                      courseInfo={courseInfo}
                      setCourseInfo={setCourseInfo}
                      setMessageOpen={setMessageOpen}
                      setMessage={setMessage}
                      setMessageType={setMessageType}
                    />
                    case 3: return <SettingPage
                      courseInfo={courseInfo}
                      setCourseInfo={setCourseInfo}
                      setMessageOpen={setMessageOpen}
                      setMessage={setMessage}
                      setMessageType={setMessageType}
                    />;
                    case 4: return <FlowList />;
                    case 5: return <ParticipantsPage />;
                    case 6: return <Content
                      setMessageOpen={setMessageOpen}
                      setMessage={setMessage}
                      setMessageType={setMessageType}
                    />
                    case 7: return <TaskList courseInfo={courseInfo} />
                    case 8: return <JournalPage />
                    case 9: return <ConferencePage />
                    case 10: return <CourseMainPage />
                    case 11: return <CourseMainPage />
                    case 12: return <ContentCurrentCourses />
                    case 13: return <TaskCurrentCourses />
                    case 14: return <ConferenceCurrentCourses />
                    case 15: return <Attendance courseData={courseInfo} />
                    case 16: return <Notes />

                    default: return <MainPage courseInfo={courseInfo} changeStep={changeStep} />;
                  }
                })()}
              </Box>
              {currentStep === 1 && !matchesUp1460px && courseInfo?.status !== CourseStatus.CLOSED && (unpublishCourseIsAvailable || closeCourseIsAvailable) &&
                <ActionCourse
                  courseInfo={courseInfo}
                  unpublishCourseIsOpen={unpublishCourseIsAvailable}
                  closeCourseIsOpen={closeCourseIsAvailable}
                />
              }
            </Grid>
          );
        })}
        <Grid item xs={12} lg={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
              marginBottom: "15px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "18px"
                }}
              >
                {t("COURSE_STATUS")}
              </Typography>
              <Button
                sx={{
                  background: `${courseInfo.status === CourseStatus.PUBLISHED ? '#58A000' :
                    courseInfo.status === CourseStatus.WITHDRAWN ? '#FFEA8A' : courseInfo.status === CourseStatus.CLOSED ? '#2074D6' : '#E4E5E7'}`,
                  color: `${courseInfo.status === 'SAVED' || courseInfo.status === CourseStatus.WITHDRAWN ? '#000000' : '#FFFFFF'}`,
                  textTransform: "none",
                  borderRadius: "10px",
                  height: "28px",
                  ":hover": { background: '#58A000' }
                }}
              >
                {t(courseInfo?.status || CourseStatus.PUBLISHED)}
              </Button>
            </Box>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', overflowX: 'auto', }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: 'row', lg: "column" },
                alignItems: "center",
                gap: { xs: 1, lg: 3 },
                width: matchesUp1460px ? '100%' : matchesDown600px ? '250px' : matchesDown1200px ? '550px' : '900px',
                marginBottom: { xs: '10px', lg: 0 },
                overflowX: 'auto',
                background: common.fontWhite
              }}
            >
              <Box sx={{
                display: "flex",
                gap: { sm: 2, xs: 1 },
                flexDirection: !matchesUp1460px ? 'row' : "column",
                width: "100%",
                alignItems: "center",
                py: 2,
                px: 2,
                border: `1px solid ${common.borderSecondary}`,
                borderRadius: "12px"
              }}>
                {((!courseData?.accountAttendance && Number(currentStep) > 9)
                  ? tabList.filter(item => [11, 12, 13, 14, 16].includes(item.step)) // if accountAttendance===false, hide Attendance tab
                  : tabList.slice(Number(currentStep) < 10 ? 0 : 10, Number(currentStep) >= 10 ? tabList.length : 10)).map((item, index) => {
                    if ((item.step === 6 || item.step === 7 || item.step === 8 || item.step === 9) && courseData?.roleType === 'ADMIN')
                      return (
                        <Box
                          key={`course_creation_step_btns_${item.step}_${index}`}
                          aria-owns={blockedTabPopover ? 'mouse-over-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handleBlockedTabPopover}
                          onMouseLeave={() => handleBlockedTabPopover(null)}
                          sx={{
                            width: '100%',
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <Button
                            sx={{
                              borderRadius: "8px",
                              width: "100%",
                              minWidth: '100px',
                              border: `2px solid ${common.primaryColor}`,
                              textTransform: "none",
                              fontSize: '14px'
                            }}
                            disabled
                          >
                            {t(`${item.tabName}`)}
                          </Button>
                        </Box>
                      );
                    return (
                      <Button
                        key={`course_creation_step_btns_${item.step}_${index}`}
                        sx={{
                          background: currentStep === item.step ? common.primaryColor : common.fontWhite,
                          color: currentStep === item.step ? common.fontWhite : common.primaryColor,
                          "&.MuiButtonBase-root:hover": {
                            background: common.secondaryColor,
                            color: common.primaryColor
                          },
                          borderRadius: "8px",
                          minWidth: '150px',
                          width: '100%',
                          border: `2px solid ${common.primaryColor}`,
                          whiteSpace: 'nowrap',
                          textTransform: "none"
                        }}
                        onClick={() => changeStep(item.step, item.label)}
                      >
                        {t(`${item.tabName}`)}
                      </Button>
                    );
                  })}
                {blockedTabPopover &&
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: 'none',
                    }}
                    open={blockedTabPopover}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    onClose={() => handleBlockedTabPopover(null)}
                    disableRestoreFocus
                    disableScrollLock
                  >
                    <Box sx={{
                      p: 1,
                      color: common.fontWhite,
                      backgroundColor: '#1A1F36',
                      width: '200px',
                      fontFamily: 'Golos',
                      fontSize: '14px'
                    }}>{t('course:MODULE_ACCESS_LIMITED')}</Box>
                  </Popover>
                }
              </Box>
            </Box>
          </Box>
          {Number(currentStep) >= 10 && <PreviewLogout courseId={courseInfo?.id} />}
          {currentStep === 1 && matchesUp1460px && courseInfo?.status !== CourseStatus.CLOSED && (unpublishCourseIsAvailable || closeCourseIsAvailable) &&
            <ActionCourse
              courseInfo={courseInfo}
              unpublishCourseIsOpen={unpublishCourseIsAvailable}
              closeCourseIsOpen={closeCourseIsAvailable}
              setMainMessage={setMessage}
              setMainMessageOpen={setMessageOpen}
              setMainMessageType={setMessageType}
            />
          }
        </Grid>
      </Grid>
    </Box>
  )
}

export default PublishedCourse;
