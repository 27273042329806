import { useState } from 'react'
import { Box, Typography, Checkbox, useTheme } from '@mui/material'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { serviceDict } from '@services/dictionary';
import { DictionaryCode } from '@interfaces/index';

export const FreeCourseComplexityFilter = ({filter, setFilter }) => {
  const { t, i18n } = useTranslation(["freeCourse", "common"]);
  const { common } = useTheme().palette;
  const [showComplexity, setShowComplexity] = useState<boolean>(true)

  const { data: difficultyLevelList } =
    useQuery({
      queryKey: ['difficultyLevelList'],
      queryFn: () => serviceDict.getDictionaryListByCodePublic(DictionaryCode.COURSE_DIFFICULTY_LEVEL),
      retry: 0,
    });

  const isChecked = (difficultyLevel: number) => {
    return filter.difficultyLevelIds.some(level => level === difficultyLevel)
  }

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        justifyContent: "space-between",
        cursor: "pointer"
      }}
        onClick={() => setShowComplexity(!showComplexity)}>
        <Typography fontWeight={600} fontSize="18px">{t("freeCourse:COMPLEXITY")}</Typography>
        {showComplexity ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
      </Box>
      {showComplexity && <Box marginTop="20px">
        {difficultyLevelList?.data.map((difficultyLevel) => {
          return (<Box key={difficultyLevel.id} display="flex" alignItems="center">
            <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <Checkbox
                size="small"
                sx={{ color: common.borderSecondary, "&.Mui-checked": { color: common.primaryColor } }}
                checked={isChecked(difficultyLevel.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilter((prev) => ({ ...prev, difficultyLevelIds: [...prev.difficultyLevelIds, difficultyLevel.id] }))
                  } else {
                    setFilter((prev) => ({ ...prev, difficultyLevelIds: prev.difficultyLevelIds.filter((elem) => elem !== difficultyLevel.id) }))
                  }
                }}
              />
              <Typography marginLeft="8px" fontSize="14px">{i18n.language === 'ru' ? difficultyLevel?.nameRu : i18n.language === 'en' ? difficultyLevel?.nameEn : difficultyLevel?.nameKz}</Typography>
            </label>
          </Box>)
        })}
      </Box>}
    </Box>
  )
}
