import { useState, useEffect } from 'react';
import { get, capitalize } from 'lodash';

import { serviceDict } from '@services/dictionary';
import { DictionaryCode } from '@interfaces/index';

interface Props {
  id: number | null;
}

const SubdivisionCell = ({ id }: Props) => {
  const [subdivisionName, setSubdivisionName] = useState('');
  const lang = localStorage.getItem("i18nextLng") || "kz";

  useEffect(() => {
    async function getSubdivision() {
      if (id) {
        const response = await serviceDict.getDictionary(DictionaryCode.SUBDIVISION, id);
        const subdivisionName = await get(response.data, `name${capitalize(lang)}`);
        setSubdivisionName(subdivisionName);
      }
    }

    getSubdivision();
  }, [id, lang]);

  return (
    <>{subdivisionName}</>
  );
}

export default SubdivisionCell;