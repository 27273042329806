import { FC, useState } from 'react'
import { Box, Typography, Checkbox, useTheme } from '@mui/material'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useTranslation } from 'react-i18next';
import { CourseFormatTypeEnum } from '@models/course';


export const FreeCourseEducationFormatFilter = ({filter, setFilter }) => {
  const { t } = useTranslation(["freeCourse", "common", "enum"]);
  const { common } = useTheme().palette;
  const [showCertificate, setShowCertificate] = useState<boolean>(true)

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setFilter((prev) => ({ ...prev, formatType: CourseFormatTypeEnum[name] }));
    } else {
      setFilter((prev) => ({ ...prev, formatType: null }));
    }
  };

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        justifyContent: "space-between",
        cursor: "pointer"
      }}
        onClick={() => setShowCertificate(!showCertificate)}>
        <Typography fontWeight={600} fontSize="18px">{t("freeCourse:TRAINING_FORMAT")}</Typography>
        {showCertificate ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
      </Box>
      {showCertificate && <Box marginTop="20px">
        <Box display="flex" alignItems="center">
          <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Checkbox
              name="SYNC"
              size="small"
              sx={{ color: common.borderSecondary, "&.Mui-checked": { color: common.primaryColor } }}
              checked={filter.formatType === CourseFormatTypeEnum.SYNC}
              onChange={handleCheckboxChange}
            />
            <Typography marginLeft="8px" fontSize="14px">{t("enum:SYNC")}</Typography>
          </label>
        </Box>
        <Box display="flex" alignItems="center">
          <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Checkbox
              name="ASYNC"
              size="small"
              sx={{ color: common.borderSecondary, "&.Mui-checked": { color: common.primaryColor } }}
              checked={filter.formatType === CourseFormatTypeEnum.ASYNC}
              onChange={handleCheckboxChange}
            />
            <Typography marginLeft="8px" fontSize="14px">{t("enum:ASYNC")}</Typography>
          </label>
        </Box>
      </Box>}
    </Box>
  )
}
