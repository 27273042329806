import { Box, Popover, Typography, useTheme } from '@mui/material';
import { serviceNotification } from '@services/notification';
import { notificationActions } from '@store/notification/reducers';
import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import { useTranslation } from 'react-i18next';

export const NotificationAllRead = () => {
  const dispatch = useDispatch();
  const [hint, setHint] = useState<HTMLElement | null>(null);
  const { t, i18n } = useTranslation(["notification"]);
  const { common } = useTheme().palette;

  const hintPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHint(event.currentTarget);
  };

  const hintPopoverClose = () => {
    setHint(null);
  };

  const openHint = Boolean(hint);
  return (
    <Box>
      <Box
        onMouseEnter={hintPopoverOpen}
        onMouseLeave={hintPopoverClose}
        onClick={() => {
          serviceNotification.updateAllStatusToRead().then((res: any) => {
            if (res.status === 200) {
              dispatch(notificationActions.setNotificationCount(0));
            }
          });
        }}
      >
        <DraftsOutlinedIcon sx={{ cursor: "pointer" }} />
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={openHint}
        anchorEl={hint}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={hintPopoverClose}
        disableRestoreFocus
      >
        <Typography
          sx={{ p: 1, color: common.fontWhite, backgroundColor: "#1A1F36" }}
        >
          {t("MARK_ALL_READ")}
        </Typography>
      </Popover>
    </Box>

  )
}
