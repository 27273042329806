import emptyMeeting from "@assets/images/emptyMeeting.png";
import { useCourseContext } from "@context/CourseContext";
import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { serviceCourse, useGetMyMeetingByStatusQuery } from "@services/course";
import { serviceTask } from "@services/task";
import format from "date-fns/format";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FilterByGroup } from "./model";

interface Props {
  search: string;
  classId?: string | null;
  filterByCategory: FilterByGroup;
}

export const FinishedConference = ({
  search,
  classId,
  filterByCategory,
}: Props) => {
  const { courseData } = useCourseContext();
  const [filteredConferenceList, setFilteredConferenceList] = useState<any>([]);
  const { courseId } = useParams();
  const { common } = useTheme().palette;
  const [themeList, setThemeList] = useState<Array<any>>([]);
  const { t } = useTranslation(["dictionary", "course", "user"]);
  const [filteredTaskInfoById, setFilteredTaskInfoById] = useState<any[]>([]);
  const [filteredTaskListById, setFilteredTaskListById] = useState<string[]>(
    []
  );
  const { data: conferenceData, isError } = useGetMyMeetingByStatusQuery({
    courseId: classId ?? "",
    status: "COMPLETED",
  });
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    let resultAfterSearch = conferenceData;
    if (search) {
      resultAfterSearch = conferenceData?.filter(
        (conference) =>
          conference.title &&
          conference.title.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (filterByCategory === FilterByGroup.ONLINE) {
      resultAfterSearch = conferenceData?.filter((conference) => {
        return (
          conference !== null &&
          conference?.themeId !== "" &&
          conference?.themeId !== undefined
        );
      });
    }
    if (filterByCategory === FilterByGroup.ORAL) {
      resultAfterSearch = conferenceData?.filter((conference) => {
        return (
          conference !== null &&
          conference?.taskId !== "" &&
          conference?.taskId !== undefined
        );
      });
    }
    setFilteredConferenceList(resultAfterSearch);
  }, [search, conferenceData, filterByCategory]);

  useEffect(() => {
    if (courseId) {
      serviceCourse.getOrderedThemeList(courseId).then((res) => {
        setThemeList(res.data);
      });
    }
  }, [courseId]);
  useEffect(() => {
    if (filteredTaskListById.length > 0) {
      for (let i = 0; i < filteredTaskListById.length; i++) {
        const element = filteredTaskListById[i];
        serviceTask.getTaskInfo(element).then((res) => {
          if (res.status === 200) {
            setFilteredTaskInfoById((item) => [...item, res.data]);
          }
        });
      }
    }
  }, [filteredTaskListById]);
  return (
    <Box>
      <Box>
        {conferenceData?.length === 0 || isError ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="100%"
            paddingTop="50px"
          >
            <img
              src={emptyMeeting}
              width={matchesDownSm ? "200px" : "500px"}
              alt="emptyMeeting"
            />
            <Typography
              sx={{
                fontSize: { xs: "18px" },
                fontWeight: { xs: "700", sm: "500" },
              }}
            >
              {t("course:conference.NO_COMPLETED_CONFERENCES_YET")}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              background: common.fontWhite,
              borderRadius: 10,
              mt: 2,
              flexDirection: "column",
              gap: 2,
            }}
          >
            {filteredConferenceList?.map((conference) => {
              return (
                <Paper
                  elevation={3}
                  sx={{
                    padding: "24px",
                    height: "auto",
                    "&::-webkit-scrollbar": {
                      width: 3,
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "lightgray",
                      borderRadius: "6px",
                    },
                    boxShadow: "none",
                    borderRadius: "16px",
                    border: "1px solid #E0E0E0",
                  }}
                >
                  <Box
                    width="100%"
                    key={conference.id}
                    display="flex"
                    gap={"12px"}
                    flexDirection={"column"}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography color="#708099">
                        {format(new Date(conference.startDate), "dd.MM.yyyy")},{" "}
                        {format(new Date(conference.startDate), "HH:mm")} -{" "}
                        {format(new Date(conference.endDate), "HH:mm")}
                      </Typography>
                    </Box>
                    <Typography
                      color={common.primaryColor}
                      sx={{ cursor: "pointer" }}
                      fontSize="20px"
                      onClick={() => {
                        if (isAuthor)
                          window.open(`${conference.link}`, "_blank");
                      }}
                    >
                      {conference.title}
                    </Typography>
                    <Box display={"flex"} flexDirection={"column"}>
                      <Box display="flex" justifyContent={"space-between"}>
                        <Box display={"flex"} width={"100%"}>
                          <Typography sx={{ marginBottom: "12px" }}>
                            {t("dictionary:ORGANIZER")}:{" "}
                            {conference.coordinatorUserName}
                          </Typography>
                        </Box>

                        <Box
                          display={"flex"}
                          width={"100%"}
                          gap={6}
                          justifyContent={"flex-end"}
                        >
                          <Typography sx={{ marginBottom: "12px" }}>
                            {t("dictionary:PLATFORM")}:{" "}
                            {conference.platform.nameRu}
                          </Typography>
                          <Typography sx={{ marginBottom: "12px" }}>
                            Участники: {conference.memberList.length}
                          </Typography>
                        </Box>
                      </Box>
                      {conference.themeId && (
                        <Typography sx={{ marginBottom: "12px" }}>
                          {t("course:theme.THEME")}:{" "}
                          {
                            themeList.filter(
                              (theme) => theme.id === conference.themeId
                            )[0]?.name
                          }{" "}
                        </Typography>
                      )}
                      {conference?.taskId && (
                        <Typography sx={{ marginBottom: "12px" }}>
                          {t("course:Устное задание")}:{" "}
                          {
                            filteredTaskInfoById.filter(
                              (item) => item?.id === conference.taskId
                            )[0]?.name
                          }
                          {"1 "}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Paper>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};
