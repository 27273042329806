import { useState } from 'react'
import { Box, Typography, Checkbox, useTheme } from '@mui/material'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useQuery } from '@tanstack/react-query';
import { serviceDict } from '@services/dictionary';
import { DictionaryCode } from '@interfaces/index';
import { useTranslation } from 'react-i18next';

export const FreeCouresLangFilter = ({ filter, setFilter }) => {
  const { t, i18n } = useTranslation(["freeCourse", "common"]);
  const [showLanguage, setShowLanguage] = useState<boolean>(true)
  const { common } = useTheme().palette;
  const { data: LanguageList } =
    useQuery({
      queryKey: ['LanguageList'],
      queryFn: () => serviceDict.getDictionaryListByCodePublic(DictionaryCode.LANGUAGE),
      retry: 0,
    });

  const isChecked = (langId: number) => {
    return filter.langIds.some(lang => lang === langId)
  }

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        justifyContent: "space-between",
        cursor: "pointer"
      }}
        onClick={() => setShowLanguage(!showLanguage)}>
        <Typography fontWeight={600} fontSize="18px">{t("freeCourse:TUITION_LANGUAGE")}</Typography>
        {showLanguage ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
      </Box>
      {showLanguage && <Box marginTop="20px">
        {LanguageList?.data?.map((lang) => {
          return (<Box key={lang.id} display="flex" alignItems="center">
            <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <Checkbox
                size="small"
                sx={{ color: common.borderSecondary, "&.Mui-checked": { color: common.primaryColor } }}
                checked={isChecked(lang.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilter((prev) => ({ ...prev, langIds: [...prev.langIds, lang.id] }))
                  } else {
                    setFilter((prev) => ({ ...prev, langIds: prev.langIds.filter((elem) => elem !== lang.id) }))
                  }
                }}
              />
              <Typography marginLeft="8px" fontSize="14px">{i18n.language === 'ru' ? lang?.nameRu : i18n.language === 'en' ? lang?.nameEn : lang?.nameKz}</Typography>
            </label>
          </Box>)
        })}
      </Box>}
    </Box>
  )
}
