import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { DropResult } from 'react-beautiful-dnd';

import { serviceTask } from '@services/task';
import TextReader from '@ui/TextReader';
import DraggableAnswerList from './DraggableAnswerList';

const questionStyle = { border: '1px solid #747474', width: '20px', height: '20px', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', mr: '10px', fontSize: '12px', lineHeight: '14px', cursor: 'pointer' }
const fullAnsweredQuestionStyle = { border: '1px solid #747474', width: '20px', height: '20px', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', mr: '10px', fontSize: '12px', lineHeight: '14px', backgroundColor: '#E4D4FF', cursor: 'pointer' };
const currentQuestionStyle = { width: '20px', height: '20px', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', mr: '10px', color: '#FFFFFF', fontSize: '12px', lineHeight: '14px', backgroundColor: '#9469E3', cursor: 'pointer' };

const Question = ({ question, setQuestion, setInitialAnswerList, questions, currentQuestionId, firstQuestionId, lastQuestionId, goToQuestion }) => {
  const { quizProgressId } = useParams();
  const { t } = useTranslation(['enum', 'course']);
  const { common } = useTheme().palette;

  useEffect(() => {
    if (currentQuestionId && quizProgressId) {
      serviceTask.getQuizQuestionById(quizProgressId, currentQuestionId).then(res => {
        if (res.status === 200) {
          setQuestion(res.data);
          if (res.data.type === "MAPPING") {
            setInitialAnswerList(res.data.children);
          } else {
            setInitialAnswerList(res.data.answers);
          }
        }
      })
    }
  }, [quizProgressId, currentQuestionId, setQuestion, setInitialAnswerList]);

  const chooseAnswer = (answerId: string, questionType: string, userChoice: boolean) => {
    let answerList = [...question.answers];
    if (questionType === "ONE_CORRECT_ANSWER" || questionType === "TRUE_OR_FALSE") {
      answerList = [...answerList].map(x => (x.userChoice === true ? { ...x, userChoice: false } : x));
    }
    answerList = [...answerList].map(x => (x.id === answerId ? { ...x, userChoice } : x));
    setQuestion((question: any) => ({ ...question, answers: answerList }));
  }

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    if (destination.index !== source.index) {
      let items = [...question.answers];
      const [newOrder] = items.splice(source.index, 1);
      items.splice(destination.index, 0, newOrder);
      if (question.type === "MAPPING") {
        const updatedChildren = [...question.children].map((x, index) => ({ ...x, userAnswers: [items[index]] }));
        setQuestion((question: any) => ({ ...question, answers: items, children: updatedChildren }));
      } else {
        items = [...items].map((x, index) => ({ ...x, userOrder: index + 1 }));
        setQuestion((question: any) => ({ ...question, answers: items }));
      }
    }
  };

  return (
    <Box sx={{ width: '100%', backgroundColor: 'white', border: `1px solid ${common.borderSecondary}`, height: 'auto', borderRadius: '12px', p: '24px' }}>
      <Box sx={{ display: 'flex' }}>
        {questions.map((item: any, index: any) => {
          return (
            <Box
              key={item.questionId}
              sx={
                (item.questionId === currentQuestionId && currentQuestionStyle) ||
                (item.answered && fullAnsweredQuestionStyle) ||
                questionStyle
              }
              onClick={() => goToQuestion('random', item.questionId)}
            >
              {index + 1}
            </Box>
          );
        })}
      </Box>
      <Box>
        <Box sx={{ border: '1px solid #D4D2D5', mt: '26px', py: '8px', pl: '24px', pr: '48px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: common.secondaryColor }}>
          <Box sx={{ display: 'block' }}>
            <Typography>{t('course:test.QUESTION_TYPE')} | {t(`${question?.type}`)}</Typography>
          </Box>
          <Typography sx={{ fontWeight: 500, fontSize: '20px', lineHeight: '21px', }}>{question?.point}</Typography>
        </Box>
        <Box sx={{ border: '1px solid #D4D2D5', p: '20px' }}>
          <TextReader htmlContent={question?.content} hideBorder />
          {question?.type === "SEQUENCE" ?
            <DraggableAnswerList showOrder items={question.answers} onDragEnd={onDragEnd} /> :
            question?.type === "MAPPING" ? (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                  {question?.children.map((item: any, index: number) => {
                    return (
                      <Box key={item.id} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 500, fontSize: '20px', mr: 3 }}>{index + 1}</Typography>
                        <TextReader htmlContent={item.content} hideBorder />
                      </Box>
                    )
                  })}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                  <DraggableAnswerList items={question.answers} onDragEnd={onDragEnd} />
                </Box>
              </Box>
            ) :
              question?.answers.map((item: any) => {
                return (
                  <Box key={item.id} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {item?.userChoice ?
                      <RadioButtonCheckedOutlinedIcon
                        sx={{ color: common.primaryColor, cursor: 'pointer' }}
                        onClick={question.type === "MULTIPLE_CORRECT_ANSWERS" ? () => chooseAnswer(item.id, question.type, false) : undefined}
                      /> :
                      <RadioButtonUncheckedOutlinedIcon
                        sx={{ color: common.primaryColor, cursor: 'pointer' }}
                        onClick={() => chooseAnswer(item.id, question.type, true)}
                      />
                    }
                    <TextReader htmlContent={item.answer} hideBorder />
                  </Box>
                )
              })
          }
          <Box sx={{ display: 'flex' }}>
            {currentQuestionId !== firstQuestionId &&
              <Typography
                sx={{ cursor: 'pointer', color: common.primaryColor }}
                onClick={() => goToQuestion('prev', question.id)}
              >
                <ArrowBackIosIcon sx={{ width: '12x', height: '12px' }} />{t('course:test.PREVIOUS_QUESTION')}
              </Typography>
            }
            {currentQuestionId !== lastQuestionId &&
              <Typography
                sx={{ cursor: 'pointer', color: common.primaryColor, ml: 'auto' }}
                onClick={() => goToQuestion('next', question.id)}
              >
                {t('course:test.NEXT_QUESTION')}<ArrowForwardIosIcon sx={{ width: '12x', height: '12px' }} />
              </Typography>
            }
          </Box >
        </Box >
      </Box>
    </Box>
  )
}

export default Question;