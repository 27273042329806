import { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import Typography from "@mui/material/Typography";
import { Avatar, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";



import CircularProgress from "@mui/material/CircularProgress";
import { serviceFile } from "@services/file";
import AvatarPicture from "@components/AvatarPicture";
import { IOrganization } from "@pages/dictionary/Organization/models";

interface Props {

  typeCode?: string;
  disabled?: boolean;
  attachAndSaveFile: (fileId: string, name?: string) => void;
  acceptedExtensions?: string;
  sizeLimit?: number; // в килобайтах
  initialData: IOrganization
}

const UploadOrganizationLogo: React.FC<Props> = ({
  typeCode = "others",
  attachAndSaveFile,
  acceptedExtensions = ".png, .jpg, .jpeg, .pdf",
  sizeLimit = 10000,
  initialData
}) => {
  const { t } = useTranslation(["common"]);
  const { common } = useTheme().palette;
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<any>(null)

  const buttonStyles = {
    backgroundColor: common.primaryColor,
    color: common.white,
    fontWeight: "lighter",
    borderRadius: "6px",
    width: "172px",
    height: "40px",
    "&.MuiButtonBase-root:hover": {
      bgcolor: common.primaryColor,
    },
    textTransform: "none",
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [showSizeLimitWarning, setShowSizeLimitWarning] =
    useState<boolean>(false);
  const [showFileTypeWarning, setShowFileTypeWarning] =
    useState<boolean>(false);


  const handleFileSelect = async (event: any) => {
    const { files } = event?.target;
    const maxAllowedSize = sizeLimit * 1000;

    if (files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile(reader.result);
      };
      reader.readAsDataURL(files[0]);
      if (files[0].size > maxAllowedSize) {
        setShowSizeLimitWarning(true);
        setTimeout(() => {
          setShowSizeLimitWarning(false);
          clearFileValue();
        }, 3000);
      } else {
        const extension = files?.[0].name.substring(
          files?.[0].name.lastIndexOf("."),
          files?.[0].name.length
        );
        setShowSizeLimitWarning(false);
        if (
          acceptedExtensions.includes(extension) ||
          acceptedExtensions === ""
        ) {
          const fData = new FormData();
          fData.append("file", files[0]);
          setLoading(true);
          serviceFile
            .uploadFile(fData, {
              params: {
                typeCode,
                temporary: false,
                confirmed: true,
              },
            })
            .then((res) => {
              if (res.status === 200) {
                attachAndSaveFile(
                  res.data.uuid
                );

                setLoading(false);
                clearFileValue();
              }
            });
        } else {
          setShowFileTypeWarning(true);
          setTimeout(() => {
            setShowFileTypeWarning(false);
            clearFileValue();
          }, 3000);
        }
      }
    }
  };

  const clearFileValue = () => {
    if (hiddenFileInput.current) hiddenFileInput.current.value = "";
  };

  return (
    <>
      {file ? <Avatar src={file} sx={{
        width: "140px",
        height: "140px",
        border: `1px dashed ${common.primaryColor}`,
        margin: " 0 auto"
      }} /> :
        <AvatarPicture
          id={initialData?.profilePhotoId}
          lastName={""}
          sxProps={{
            width: "140px",
            height: "140px",
            border: `1px dashed ${common.primaryColor}`,
            margin: " 0 auto"
          }}
        />}
      <Typography fontSize="14px" margin="12px">
        {t('dictionary:IMAGE_UP_TO_10_MB')}
      </Typography>
      <input
        ref={hiddenFileInput}
        id="upload-org-file-button"
        type="file"
        hidden
        accept={acceptedExtensions}
        onChange={handleFileSelect}
      />
      <Button
        htmlFor="upload-org-file-button"
        sx={buttonStyles}
        component="label"
      >
        <FileUploadOutlinedIcon /> {t("common:actions.UPLOAD")}
      </Button>
      {loading && (
        <CircularProgress
          color="secondary"
          size={24}
          sx={{ ml: "10px", mt: "3px" }}
        />
      )}
      {showSizeLimitWarning && (
        <Typography sx={{ color: common.errorColor }}>
          {t("common:hints.MAX_FILE_SIZE_WARNING", { sizeLimit })}
        </Typography>
      )}
      {showFileTypeWarning && (
        <Typography sx={{ color: common.errorColor }}>
          {t("common:hints.ACCEPTABLE_FORMATS", { acceptedExtensions })}
        </Typography>
      )}
    </>
  );
}

export default UploadOrganizationLogo;
