import * as yup from "yup";

export const participantValidationSchema = (t) =>
  yup.object({
    roleType: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
  });

export const listenerGroupValidationSchema = (t) =>
  yup.object({
    name: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    capacity: yup
      .number()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
  });

export const notRegisteredMember = (t) =>
  yup.object({
    roleType: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    name: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    email: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    lastName: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
  });
