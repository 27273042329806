import { useEffect, useMemo, useState } from 'react'
import { Avatar, Box, Typography, useTheme } from '@mui/material'
import CustomButton from '@ui/Button'
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CustomSearch from '@ui/Search';
import { MaterialReactTable, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@components/Breadcrumbs';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import {
  type MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";
import { serviceReports } from '@services/reports';
import CustomAutocomplete from '@ui/Autocomplete';
import PeriodPopover from './PeriodPopover';
import { TypeCode } from '@interfaces/index';
import { formatDateWithTimezone } from '@utils/functions';
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';


export const AllStudentList = () => {
  const { common } = useTheme().palette;
  const { setBreadcrumbs } = useBreadcrumbs();
  const { t } = useTranslation(["dictionary", "course", "enum", "sideMenu"]);
  const [filter, setFilter] = useState({});
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showStartDateEndDate, setShowStartDateEndDate] = useState<boolean>(false)
  const [hint, setHint] = useState<HTMLElement | null>(null)
  const [showCloseIcon, setShowCloseIcon] = useState<boolean>(false)
  const isPeriodOpen = Boolean(hint)
  const [studentList, setStudentList] = useState<Array<any>>([]);
  const [count, setCount] = useState()
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25, //customize the default page size
  });
  useEffect(() => {
    setBreadcrumbs("report_student", [{
      level: 1,
      page: 'reports',
      label: "sideMenu:REPORTS",
      path: '/reports',
      tabNum: null
    }, {
      level: 2,
      page: 'reports listener',
      label: "course:REPORT_LISTENERS",
      path: null,
      tabNum: null
    }]);
    // eslint-disable-next-line
  }, [])
  const periodPopoverClose = () => {
    setHint(null);
  };
  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };
  const periodPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHint(event.currentTarget);
  };
  const handleClearDate = () => {
    setFilter((prev) => ({
      ...prev,
      startDate: null,
      endDate: null
    }))
    setHint(null);
    setStartDate(null);
    setEndDate(null);
    setShowStartDateEndDate(false);
  }
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${day}.${month}.${year}`;
  }

  const handleChangeTypeCourseFilter = (value: any) => {
    setFilter({ ...filter, type: value ? value : null });

  }

  useEffect(() => {
    if (startDate && endDate && showStartDateEndDate) {
      setFilter((prev) => ({
        ...prev,
        startDate: formatDateWithTimezone(startDate),
        endDate: formatDateWithTimezone(endDate)
      }))
    }

  }, [startDate, endDate, showStartDateEndDate])

  useEffect(() => {
    serviceReports.getAllListenerList(
      {
        pageNumber: pagination.pageIndex,
        size: pagination.pageSize,
        filter,
        sorting: sorting.length > 0 ? { [sorting[0].id]: sorting[0].desc === true ? "desc" : "asc" } : {}
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setStudentList(res.data.dtoList);
          setCount(res.data.totalElements);
        }
      });

  }, [filter, pagination.pageIndex, pagination.pageSize, sorting]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t("enum:LISTENER"),
        size: 300,
        enableSorting: true,
        Cell: ({ renderedCellValue, row }) => (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}>
            <Avatar sizes='small' />
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                {renderedCellValue}
              </Typography>
              <Typography sx={{
                color: "#8E8E93",
                fontSize: "12px",
                lineHeight: "20px"
              }}>{row.original.email}</Typography>
            </Box>
          </Box>
        )
      },
      {
        accessorKey: 'totalCourses',
        header: "Всего курсов",
        enableSorting: true,
        size: 50
      },
      {
        accessorKey: 'totalCompleted',
        header: "Пройдено",
        enableSorting: true,
        size: 50
      },
      {
        accessorKey: 'totalCurrent',
        header: "Текущие",
        enableSorting: true,
        size: 50
      },
      {
        accessorKey: 'totalPlanned',
        header: "Запланировано",
        enableSorting: true,
        size: 50
      },
      {
        accessorKey: 'totalCertificate',
        header: "Сертификаты",
        enableSorting: true,
        size: 50
      },
    ],
    [t],
  );

  const table = useMaterialReactTable({
    columns,
    data: studentList, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableSorting: true,
    enableColumnActions: false,
    enableExpandAll: false,
    enableColumnResizing: false,
    enableTopToolbar: false,
    enableRowNumbers: true,
    enableColumnPinning: false,
    enableRowActions: false,
    manualPagination: true,
    manualSorting: true,
    localization: {
      noRecordsToDisplay: t('dictionary:EMPTY'),
      rowsPerPage: t('enum:LINES_PER_PAGE'),
      actions: "",
      expand: "",
      rowNumber: '№',
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default, //change default background color
    }),
    muiTableBodyRowProps: { hover: false },
    muiTableHeadCellProps: {
      sx: {
        borderLeft: '1px solid rgba(81, 81, 81, .5)'
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderLeft: '1px solid rgba(81, 81, 81, .5)',
      },
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        display: 'grid'
      },
    },
    muiTableContainerProps: {
      sx: {
        '&::-webkit-scrollbar': { height: '4px', width: '7px' },
        '&::-webkit-scrollbar-thumb': { backgroundColor: '#D9D9D9', borderRadius: '4px' }
      },
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: { pagination, sorting }, //pass the pagination state to the table
    rowCount: count,
    paginationDisplayMode: "pages",
  });
  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: 'center', mb: '24px' }}>
        <Breadcrumbs id="report_student" />
      </Box>
      <Box display="flex" justifyContent="space-between" sx={{ mb: "27px", mt: '20px' }}>
        <Typography sx={{ fontWeight: "500", fontSize: "36px", lineHeight: "44px" }}>
          {t("course:REPORT_LISTENERS")}
        </Typography>
        <CustomButton
          width="fit-content"
          backgroundColor={common.primaryColor}
          borderRadius="6px"
          onClick={() => { }}
        >
          <Typography
            sx={{
              color: common.white,
              display: "flex",
              alignItems: "center",
            }}
          >
            Скачать в Excel
            <FileDownloadOutlinedIcon sx={{ mr: "12px" }} />
          </Typography>
        </CustomButton>
      </Box>
      <Box display='flex' justifyContent='space-between' sx={{ marginBottom: "16px" }} alignItems="center">
        <CustomSearch width='360px' placeholder={t("common:placeholders.SEARCH_BY_LISTENER_FULL_NAME")} />
        <Box display="flex" gap={2}>
          <CustomAutocomplete
            sx={{ width: { lg: '200px', xs: '100%' } }}
            placeholder={t("course:COURSE_TYPE")}
            name="typeCourse"
            options={[{ text: TypeCode.EDU, id: TypeCode.EDU }, { text: TypeCode.FREE, id: TypeCode.FREE }]}
            onChange={(_, value: any) => handleChangeTypeCourseFilter(value)}

          />
          <Box sx={{
            border: `1px solid ${common.borderSecondary}`,
            height: '40px',
            width: showStartDateEndDate ? '235px' : 'auto',
            backgroundColor: !isPeriodOpen ? 'white' : common.primaryColor,
            color: isPeriodOpen ? 'white' : 'black',
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            px: '10px',
            cursor: 'pointer',

          }}
            onMouseEnter={() => startDate && endDate && setShowCloseIcon(true)}
            onMouseLeave={() => setShowCloseIcon(false)}
            onClick={periodPopoverOpen}
          >
            <Typography sx={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              color: isPeriodOpen ? 'white' : 'black',
              mx: '8px',
            }}>
              {
                (startDate && endDate && showStartDateEndDate) ? `${formatDate(startDate)} ${'\u2014'} ${formatDate(endDate)}` : t('common:PERIOD')
              }
            </Typography>
            {showCloseIcon ?
              <CloseIcon
                sx={{}}
                onClick={handleClearDate}
              /> :
              <CalendarMonthOutlinedIcon />
            }
          </Box>
        </Box>
        <PeriodPopover
          isPeriodOpen={isPeriodOpen}
          startDate={startDate}
          endDate={endDate}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          hint={hint}
          periodPopoverClose={periodPopoverClose}
          handleShowDate={() => {
            setShowStartDateEndDate(true)
            periodPopoverClose()
          }}
        />
      </Box>
      <MaterialReactTable table={table}
      />
    </Box>
  )
}
