import R1 from "@assets/images/home/R1.png";
import R2 from "@assets/images/home/R2.png";
import R3 from "@assets/images/home/R3.png";
import CreateImg from "@assets/images/home/create.png";
import PermissionImg from "@assets/images/home/permission.png";
import SuitableImg from "@assets/images/home/suitable.png";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HomeVariant } from "../types/home";
import s from "./StartingEducation.module.scss";
import { Role } from "@interfaces/index";
interface Props {
  variant: HomeVariant;
}
interface Result {
  title: string;
  onClick: () => void;
  button?: string;
  block: {
    text: string;
    subtext: JSX.Element;
    img: string;
  }[];
}

const StartingEducation = (props: Props) => {
  const { variant } = props;
  const { t } = useTranslation(["home", "course"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const userInfo = localStorage.getItem("userInfo");
  const role = JSON.parse(userInfo || "{}")?.authorityInfo?.role;

  const handleNavigate = () => {
    if(!userInfo) return '/registration'
    const routes = {
      [Role.LISTENER]: '/myCourses',
      [Role.EDUCATOR]: '/myCourses',
      [Role.LOCAL_ADMIN]: '/courses',
      [Role.ADMIN]: '/courses',
      [Role.SUPER_ADMIN]: '/freeCatalogCourse',
      [Role.MANAGER]: '/freeCatalogCourse'
    }
    return routes[role]
  }

  const handleHomeVariant = (e: HomeVariant): Result => {
    switch (e) {
      case HomeVariant.ORGANIZATION:
        return {
          title: t('home:STAGES_OF_WORK'),
          button: t('home:CONTACT_US'),
          onClick: () => {
            searchParams.set("modal", "true");
            setSearchParams(searchParams);
          },
          block: [
            {
              text: t('home:LEAVE_A_REQUEST'),
              subtext: (
                <>
                  <span
                    onClick={() => {
                      searchParams.set("modal", "true");
                      setSearchParams(searchParams);
                    }}
                  >
                    {t('home:FILL_FORM')}
                  </span>{" "}
                  {t('home:FILL_OUT_THE_APPLICATION_FORM_TO_OUR_WEBSITE')}
                </>
              ),
              img: R1,
            },
            {
              text: t('home:SIGN_A_CONTRACT'),
              subtext: (
                <>{t('home:FORMALIZE_COOPERATION_BY_SIGNING_OFFICIAL_CONTRACT')}</>
              ),
              img: R2,
            },
            {
              text: t('home:MAKE_PAYMENT'),
              subtext: (
                <>
                  {t('home:CHOOSE_TARIFF_AND_MAKE_PAYMENT')}
                </>
              ),
              img: R3,
            },
          ],
        } as Result;
      case HomeVariant.EDUCATOR:
        return {
          title: t('home:FEW_STEPS_AWAY_FROM_LAUNCHING_COURSE'),
          button: (!role || role === Role.EDUCATOR || role === Role.MANAGER) ? t('course:CREATE_COURSE') : undefined,
          onClick: () => {
            navigate(userInfo ? "/myCourses" : "/registration");
          },
          block: [
            {
              text: t('home:CREATE_ACCOUNT'),
              subtext: (
                <>
                  <span
                    onClick={() => {
                      navigate("/registration");
                    }}
                  >
                    {t('home:REGISTRATION')}
                  </span>{" "}
                  {t('home:TO_START_TEACHING')}
                </>
              ),
              img: CreateImg,
            },
            {
              text: t('home:MAKE_PAYMENT'),
              subtext: (
                <>
                  <span onClick={() => { }}>{t('home:MAKE_PAYMENT')}</span> 
                  {t('home:MAKE_PAYMENT_TO_ACCESS_FUNCTIONALITY')}
                </>
              ),
              img: R3,
            },
            {
              text: t('home:START_DEVELOPING_COURSE'),
              subtext: (
                <>
                  {t('home:USE_HANDY_CONSTRUCTOR_TO_ADD_CONTENT')}
                </>
              ),
              img: PermissionImg,
            },
          ],
        } as Result;
      case HomeVariant.LISTENER:
        return {
          title: t("home:HOW_START_LEARNING"),
          onClick: () => navigate("/freeCatalogCourse"),
          button: t("home:GO_CATALOG"),
          block: [
            {
              text: t("home:CREATE_ACCOUNT"),
              subtext: (
                <>
                  <span
                    onClick={() => {
                      navigate("/registration");
                    }}
                  >
                    {t("home:REGISTRATION")}
                  </span>{" "}
                  {t("home:ON_PLATFORM_FOR_EDU")}
                </>
              ),
              img: CreateImg,
            },
            {
              text: t("home:SELECT_APPROPRIATE_COURSE"),
              subtext: (
                <>
                  {" "}
                  {t("home:MEET")}
                  <span
                    onClick={() => {
                      navigate("/freeCatalogCourse");
                    }}
                  >
                    {" "}
                    {t("home:DIFFERENT_COURSES")}
                  </span>{" "}
                  {t("home:AVAILABLE_COURSES")}
                </>
              ),
              img: SuitableImg,
            },
            {
              text: t("home:GET_ACCESS_MATERIAL"),
              subtext: (
                <>
                  <span
                    onClick={() => {
                      navigate(
                        handleNavigate()
                      );
                    }}
                  >
                    {t("home:STUDY_EDUCATIONAL_MATERIAL")}
                  </span>
                  {t("home:GET_READY_AND_ENROLL")}
                </>
              ),
              img: PermissionImg,
            },
          ],
        };
      default:
        return {
          title: t("home:HOW_START_LEARNING"),
          onClick: () => navigate("/freeCatalogCourse"),
          button: t("home:GO_CATALOG"),
          block: [
            {
              text: t("home:CREATE_ACCOUNT"),
              subtext: (
                <>
                  <span
                    onClick={() => {
                      navigate("/registration");
                    }}
                  >
                    {t("home:REGISTRATION")}
                  </span>{" "}
                  {t("home:ON_PLATFORM_FOR_EDU")}
                </>
              ),
              img: CreateImg,
            },
            {
              text: t("home:SELECT_APPROPRIATE_COURSE"),
              subtext: (
                <>
                  {" "}
                  {t("home:MEET")}
                  <span
                    onClick={() => {
                      navigate("/freeCatalogCourse");
                    }}
                  >
                    {" "}
                    {t("home:DIFFERENT_COURSES")}
                  </span>{" "}
                  {t("home:AVAILABLE_COURSES")}
                </>
              ),
              img: SuitableImg,
            },
            {
              text: t("home:GET_ACCESS_MATERIAL"),
              subtext: (
                <>
                  <span
                    onClick={() => {
                      navigate(
                        handleNavigate()
                      );
                    }}
                  >
                    {t("home:STUDY_EDUCATIONAL_MATERIAL")}
                  </span>
                  {t("home:GET_READY_AND_ENROLL")}
                </>
              ),
              img: PermissionImg,
            },
          ],
        };
    }
  };
  const data = handleHomeVariant(variant);
  return (
    <div className={s.wrapper}>
      <div className={s.head}>
        <div className={s.title}>{data.title}</div>
        {data.button &&
          <Button
            sx={{ padding: "8px 12px", borderRadius: "4px", height: "44px" }}
            size="large"
            variant="contained"
            color="primary"
            onClick={data.onClick}
          >
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: 400,
              }}
            >
              {data.button}
            </Typography>
          </Button>
        }
      </div>
      <div className={s.content}>
        {data.block.map((item, index) => (
          <div key={index} className={s.card}>
            <div className={s.img}>
              <img src={item.img} alt="" />
            </div>
            <div className={s.block}>
              <div className={s.text}>{item.text}</div>
              <div className={s.subtext}>{item.subtext}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StartingEducation;
