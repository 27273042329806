import axios from "@utils/axios";

export const serviceIntegration = {
  getIntegrationByOrgId: (orgId: number) =>
    axios.get(`/api/external-api/integration/${orgId}/findAll`),
  getAllIntegrations: () => axios.get("/api/external-api/integration/findAll"),
  updateAllIntegrations: (bodyData: any) =>
    axios.post("/api/external-api/integration/saveAll", bodyData),
  updateIntegrationByOrgId: (bodyData: any) =>
    axios.post("/api/external-api/integration/org/saveAll", bodyData),
  updateIntegration: (bodyData: any) =>
    axios.post("/api/external-api/integration/save", bodyData),
  deleteIntegration: (branchId: number) =>
    axios.delete(`/api/external-api/integration/org/${branchId}`),
  getIntegrationTypes: () => axios.get("/api/external-api/integration/types"),
  getAntiPlagiarismSetting: (code: string) =>
    axios.get(`/api/external-api/integration/anti-plagiarism/settings/${code}`),
  saveAntiPlagiarismSetting: (bodyData: any) =>
    axios.post(
      "/api/external-api/integration/anti-plagiarism/settings/save",
      bodyData
    ),
  getTariffInfoAntiPlagiat: () =>
    axios.get("/api/external-api/integration/anti-plagiarism/getTariffInfo"),
  getIntegrationLogs: (bodyData: any) =>
    axios.post(`/api/external-api/integration/logs`, bodyData),
  getInfoAntiPlagiarism: (orgId: number) =>
    axios.get(
      `/api/external-api/integration/anti-plagiarism/getPurchaseOrgInfo/${orgId}`
    ),
};
