import React, { FC, useState } from 'react'
import { Box, Rating, Typography, Checkbox, useTheme } from '@mui/material'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useTranslation } from 'react-i18next';

interface FreeCourseRatingFilterProps {
  filter: {
    ratingRanges: { startRange: number; endRange: number }[];
  };
  setFilter: React.Dispatch<React.SetStateAction<{
    ratingRanges: { startRange: number; endRange: number }[];
  }>>;
}

export const FreeCourseRatingFilter: FC<FreeCourseRatingFilterProps> = ({filter, setFilter }) => {
  const { t } = useTranslation(["freeCourse", "common"]);
  const { common } = useTheme().palette;
  const [showRating, setShowRating] = useState<boolean>(true)

  const handleCheckboxChange = (startRange: number, endRange: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.checked) {
      setFilter(prev => ({
        ...prev,
        ratingRanges: [...prev.ratingRanges, {startRange, endRange}]
      }))
    } else {
      setFilter(prev => ({
        ...prev,
        ratingRanges: prev.ratingRanges.filter(range => range.startRange !== startRange)
      }))
    }
  }

  const isChecked = (startRange: number) => {
    return filter.ratingRanges.some(range => range.startRange === startRange)
  }

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        justifyContent: "space-between",
        cursor: "pointer"
      }}
        onClick={() => setShowRating(!showRating)}>
        <Typography fontWeight={600} fontSize="18px">{t("freeCourse:COURSE_RATING")}</Typography>
        {showRating ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
      </Box>
      {showRating && <Box marginTop="20px">
        <Box display="flex" alignItems="center">
          <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Checkbox
              name="all"
              size="small"
              sx={{ color: common.borderSecondary, "&.Mui-checked": { color: common.primaryColor } }}
              checked={isChecked(4.5)}
              onChange={handleCheckboxChange(4.5, 5)}
            />
            <Rating value={4.5} readOnly size="small" precision={0.5} />
            <Typography marginLeft="8px" fontSize="14px">4,5 {t('freeCourse:AND_ABOVE')}</Typography>
          </label>
        </Box>
        <Box display="flex" alignItems="center">
          <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Checkbox
              name="all"
              size="small"
              sx={{ color: common.borderSecondary, "&.Mui-checked": { color: common.primaryColor } }}
              checked={isChecked(3.5)}
              onChange={handleCheckboxChange(3.5, 4)}
            />
            <Rating defaultValue={4} readOnly size="small" />
            <Typography marginLeft="8px" fontSize="14px">4 - 3.5</Typography>
          </label>
        </Box>
        <Box display="flex" alignItems="center">
          <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Checkbox
              name="all"
              size="small"
              sx={{ color: common.borderSecondary, "&.Mui-checked": { color: common.primaryColor } }}
              checked={isChecked(3)}
              onChange={handleCheckboxChange(3, 3.5)}
            />
            <Rating defaultValue={3.5} readOnly size="small" precision={0.5} />
            <Typography marginLeft="8px" fontSize="14px">3.5 - 3</Typography>
          </label>
        </Box>
        <Box display="flex" alignItems="center">
          <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Checkbox
              name="all"
              size="small"
              sx={{ color: common.borderSecondary, "&.Mui-checked": { color: common.primaryColor } }}
              checked={isChecked(0)}
              onChange={handleCheckboxChange(0, 3)}
            />
            <Rating defaultValue={3} readOnly size="small" />
            <Typography marginLeft="8px" fontSize="14px">3 {t('freeCourse:AND_BELOW')}</Typography>
          </label>
        </Box>
      </Box>}
    </Box>
  )
}
