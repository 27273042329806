import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
  Box, Typography, useTheme, AlertColor, TextField, InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  SxProps,
  Theme
} from '@mui/material'
import { TablePagination } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

import { serviceDict } from '@services/dictionary';
import CustomButton from "@ui/Button";
import AddHandbookTypeModal from './AddHandbookTypeModal';
import Message from '@components/Message';
import useLocalPagination from "@hooks/useLocalPagination";
import { IDictType, initialDTODictType } from './models';
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import Breadcrumbs from '@components/Breadcrumbs';

const HandbookTypes = () => {
  const { t } = useTranslation(["enum", "sideMenu", "dictionary", "course", "common", "generalSetting", "user"]);
  const { common } = useTheme().palette;
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { authorityInfo: { role } } = profile;
  const [messageOpen, setMessageOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messageType, setMessageType] = useState<AlertColor>('success')
  const [isAddHandbookTypeModalOpen, setIsAddHandbookTypeModalOpen] = useState<boolean>(false)
  const [isHandbookTypeEditing, setIsHandbookTypeEditing] = useState<boolean>(false)
  const { handbookType, id } = useParams()
  const [initialData, setInitialData] = useState<IDictType[]>([initialDTODictType])
  const [totalElements, setTotalElements] = useState<number>(0)
  const [filter, setFilter] = useState({ search: '', orgId: Number(id) })
  const [selectedType, setSelectedType] = useState<IDictType | null>(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | null>(null)
  const { setBreadcrumbs } = useBreadcrumbs();
  const lng = localStorage.getItem("i18nextLng") || "kz";

  const baseTableRowCellStyle: SxProps<Theme> = {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#7D7D7D",
    // mr: "16px",
  }

  const {
    rowsPerPage, page,
    handleChangePage, handleChangeRowsPerPage,
  } = useLocalPagination<IDictType>()

  const handleToggleAddHandbookTypeModal = (modalState: boolean) => {
    setIsAddHandbookTypeModalOpen(modalState)
    if (!modalState) {
      setIsHandbookTypeEditing(false)
    }
  };

  const searchByName = (searchValue: string) => {
    const new_filter = { ...filter, search: searchValue };
    setFilter(new_filter);
  };

  const handleSaveDictType = (updatedDictType) => {
    const updatedIndex = initialData?.findIndex(dictType => dictType.id === updatedDictType.id);

    if (updatedIndex !== -1) {
      const updatedData = [...initialData];
      updatedData[updatedIndex] = updatedDictType;
      setInitialData(updatedData);
    } else {
      serviceDict.getDictionaryList(`${handbookType?.toUpperCase()}`, {
        pageNumber: page,
        size: rowsPerPage,
        filter
      }).then((res) => {
        if (res.status === 200) {
          setInitialData(res.data.dtoList)
          setTotalElements(res.data.totalElements)
        }
      })
    }
  }

  const handleDeleteDictType = () => {
    return serviceDict.deleteDictionary(`${handbookType?.toUpperCase()}`, deleteId).then((res) => {
      if (res.status === 200) {
        setMessage(t('common:messages.RECORD_DELETED'))
        setMessageType('success')
        setMessageOpen(true)
        setInitialData(prevData => prevData.filter(item => item.id !== deleteId));
        setTimeout(() => {
          setMessageOpen(false)
        }, 3000)
      }
    }).catch((e) => {
      if (e.response.data.code === 'USED_DICTIONARY') {
        setMessage(t('common:messages.RECORD_NOT_DELETED'))
      } else {
        setMessage(t('common:messages.ERROR_SAVING_DATA'))
      }
      setMessageOpen(true)
      setMessageType('error')
      setTimeout(() => {
        setMessageOpen(false)
      }, 5000)
    }).finally(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    })
  }

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: handleDeleteDictType,
    modalTitle: t(`common:DELETE_ITEM_CONFIRMATION_TEXT`),
  })

  useEffect(() => {
    serviceDict.getDictionaryList(`${handbookType?.toUpperCase()}`, {
      pageNumber: page,
      size: rowsPerPage,
      filter
    }).then((res) => {
      if (res.status === 200) {
        setInitialData(res.data.dtoList)
        setTotalElements(res.data.totalElements)
      }
    })
  }, [page, rowsPerPage, id, filter, handbookType, deleteModalOpen])

  useEffect(() => {
    setBreadcrumbs("handbook", [{
      level: 1,
      page: 'settings',
      label: "course:SETTINGS",
      path: '/settings',
      tabNum: null
    }, {
      level: 2,
      page: 'dictionary',
      label: "sideMenu:DICTIONARY",
      path: `/settings/handbook/${id}`,
      tabNum: null
    }, {
      level: 3,
      page: 'handbook types',
      label: handbookType === 'subdivision' ? "user:DEPARTMENTS'"
        : handbookType === 'edu_program' ? "enum:EDU_PROGRAMS"
          : handbookType === 'class_type' ? "enum:TYPES_OF_CLASSES"
            : "enum:BASE_FOR_EARLY_COMPLETION",
      path: null,
      tabNum: null
    }]);
    // eslint-disable-next-line
  }, [])

  return (
    <Box>
      {DeleteModal}
      {role === 'LOCAL_ADMIN' &&
        <Box sx={{ display: "flex", alignItems: 'center', mb: '24px' }}>
          <Breadcrumbs id="handbook" />
        </Box>
      }
      <Typography sx={{ fontSize: '36px', fontWeight: 500, mb: '24px' }}>
        {handbookType === 'subdivision' ? t('user:DEPARTMENTS')
          : handbookType === 'edu_program' ? t('enum:EDU_PROGRAMS')
            : handbookType === 'class_type' ? t('enum:TYPES_OF_CLASSES')
              : t('enum:BASE_FOR_EARLY_COMPLETION')}
      </Typography>
      {
        messageOpen &&
        <Box sx={{ mb: 2 }}>
          <Message type={messageType} message={message} setMessageOpen={setMessageOpen} />
        </Box>
      }
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', border: `1px solid ${common.borderSecondary}`, borderRadius: '9px', backgroundColor: 'white', p: '32px', minHeight: '600px' }}>
        <Box sx={{ display: "flex", width: '100%', flexDirection: { lg: 'row', xs: 'column' }, gap: { lg: 0, xs: 2 }, justifyContent: { lg: "space-between", xs: 'center' } }}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            color="primary"
            onChange={(e) => searchByName(e.target.value)}
            sx={{
              width: { lg: "100%", xs: '100%' }, mr: "24px", justifyContent: "flex-start", '& label.Mui-focused': { color: common.primaryColor },
              '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': { borderColor: common.primaryColor },
                '&.Mui-focused fieldset': { borderColor: common.primaryColor }
              }
            }}
            placeholder={t('common:placeholders.SEARCH_BY_NAME_AND_ID')}
            name="searchValue"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box sx={{ backgroundColor: common.primaryColor, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px', mr: '-14px', borderRadius: '6px' }}>
                    <SearchIcon sx={{ color: '#FFFFFF', width: '26px', height: '26px' }} />
                  </Box>
                </InputAdornment>
              ),
            }}
          />
          <CustomButton
            backgroundColor={common.primaryColor}
            fontColor={common.fontWhite}
            width='20%'
            borderRadius="6px"
            height="38px"
            sx={{
              alignItems: "center",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "130%"
            }}
            onClick={() => {
              handleToggleAddHandbookTypeModal(true)
            }}
          >
            {t('common:actions.ADD')}
          </CustomButton>
        </Box>
        <Box sx={{ mt: "40px", width: '100%', display: 'table', tableLayout: 'fixed' }
        }>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      ...baseTableRowCellStyle,
                      ml: "8px",
                    }}
                  >
                    {handbookType === 'course_close_reason' ? t('enum:BASE_IN_KAZAKH_LANGUAGE') : t('course:task.NAME')}
                  </TableCell>
                  {handbookType === 'course_close_reason' &&
                    <>
                      <TableCell
                        sx={baseTableRowCellStyle}
                      >
                        {t('enum:BASE_IN_RUSSIAN_LANGUAGE')}
                      </TableCell>
                      <TableCell
                        sx={baseTableRowCellStyle}
                      >
                        {t('enum:BASE_IN_ENGLISH_LANGUAGE')}
                      </TableCell>
                    </>
                  }
                  {handbookType !== 'course_close_reason' &&
                    <TableCell
                      sx={baseTableRowCellStyle}
                    >
                      {handbookType === 'class_type' ? t('enum:ABBREVIATED_NAME') : t('generalSetting:schedule.EXTERNAL_ID')}
                    </TableCell>}
                  <TableCell
                    sx={baseTableRowCellStyle}
                  >
                    {t('dictionary:USAGE_STATUS')}
                  </TableCell>
                  <TableCell
                    sx={baseTableRowCellStyle}
                  >
                    {t('dictionary:ACTION')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {initialData?.map((dictType, index) => {
                  return (
                    <TableRow key={`${dictType.id}_${index}`}>
                      <TableCell sx={{
                        ...baseTableRowCellStyle,
                        ml: "8px",
                      }}>
                        {handbookType === 'course_close_reason' ? dictType.nameKz
                          : (lng === 'kz' ? dictType.nameKz
                            : lng === 'ru' ? dictType.nameRu
                              : dictType.nameEn)}
                      </TableCell>
                      {handbookType === 'course_close_reason' &&
                        <TableCell sx={{
                          ...baseTableRowCellStyle,
                          ml: "8px",
                        }}>
                          {dictType.nameRu}
                        </TableCell>}
                      {handbookType === 'course_close_reason' &&
                        <TableCell sx={{
                          ...baseTableRowCellStyle,
                          ml: "8px",
                        }}>
                          {dictType.nameEn}
                        </TableCell>}
                      {handbookType !== 'course_close_reason' &&
                        <TableCell
                          sx={baseTableRowCellStyle}
                        >
                          {handbookType === 'class_type' ? dictType.shortNameRu : dictType.externalId}
                        </TableCell>}
                      <TableCell
                        sx={baseTableRowCellStyle}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Box style={{
                            width: '12px',
                            height: '12px',
                            borderRadius: '50%',
                            backgroundColor: dictType.isDisabled ? common.errorColor : '#00B998'
                          }}></Box>{dictType.isDisabled ? t('enum:NOT_USED') : t('enum:IS_USED')}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={baseTableRowCellStyle}
                      >
                        <Box sx={{ display: 'flex', gap: '10px', pr: '10px' }}>
                          <EditIcon color="info" sx={{ cursor: "pointer", color: common.primaryColor }} onClick={() => {
                            setIsHandbookTypeEditing(true)
                            handleToggleAddHandbookTypeModal(true)
                            setSelectedType(dictType)
                          }} />
                          <DeleteOutlineIcon
                            onClick={() => {
                              setDeleteId(dictType.id || null)
                              openDeleteConfirmModal(dictType.id?.toString() || '')
                            }}
                            color="error"
                            sx={{ cursor: "pointer" }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('enum:LINES_PER_PAGE')}
                labelDisplayedRows={
                  ({ from, to, count }) => t('enum:TABLE_PAGES', { from, to, count })
                }
              />
            </Box>
          </TableContainer>
          {isAddHandbookTypeModalOpen &&
            <AddHandbookTypeModal
              modalOpen={isAddHandbookTypeModalOpen}
              handleToggleModal={handleToggleAddHandbookTypeModal}
              isEditing={isHandbookTypeEditing}
              selectedType={selectedType}
              setMessage={setMessage}
              setMessageType={setMessageType}
              setMessageOpen={setMessageOpen}
              handbookType={handbookType}
              onSaveSuccess={handleSaveDictType}
            />
          }
        </Box >
      </Box>
    </Box >
  )
}

export default HandbookTypes

