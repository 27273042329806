import { useEffect, useState } from 'react';
import { Box, Typography, Divider, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import CustomButton from '@ui/Button';
import FormikCheckbox from "@ui/formik/Checkbox";
import { Formik, Form as FormikForm } from 'formik';
import { initialDto, COMMON_OPTION } from './models';
import { serviceUser } from '@services/user';
import { AlertColor } from '@mui/material/Alert';

interface Props {
  setMessage: (message: string) => void;
  setMessageOpen: (open: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
}

const General = ({ setMessage, setMessageOpen, setMessageType }: Props) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["generalSetting", "common"]);
  const [initialData, setInitialData] = useState<COMMON_OPTION>(initialDto);

  useEffect(() => {
    serviceUser.getGeneralSettingsTypeOptionByCode("COMMON_OPTION").then((res) => {
      if (res.data) {
        setInitialData(res.data);
      }
    })
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: common.fontWhite,
        width: "100%",
        borderRadius: "12px",
        p: "32px",
        border: `1px solid ${common.borderSecondary}`,
      }}
    >
      <Typography sx={{
        fontWeight: 500,
        fontSize: '20px',
        mb: '24px'
      }}>
        {t("ADDITIONAL_METHODS_OF_REGISTRATION/AUTHORIZATION")}
      </Typography>
      <Divider />
      <Formik
        initialValues={initialData}
        enableReinitialize
        validationSchema={null}
        onSubmit={(values) => {
          serviceUser.createGeneralSettingsTypeOptionByCode(values).then((res) => {
            if (res.status === 200) {
              setMessageOpen(false);
              setTimeout(() => {
                setMessageOpen(true);
              }, 500)
              setMessage(t('common:messages.DATA_SAVED_SUCCESSFULLY'));
              setMessageType("success");
              setTimeout(() => {
                setMessageOpen(false);
              }, 3000)
            } else {
              setMessageOpen(false);
              setTimeout(() => {
                setMessageOpen(true);
              }, 500)
              setMessage(t('common:messages.ERROR_SAVING_DATA'));
              setMessageType("error");
              setTimeout(() => {
                setMessageOpen(false);
              }, 10000)
            }
          })
        }}
      >
        {({ submitForm }) => (
          <FormikForm>
            <Box sx={{ mt: '24px', ml: '-12px' }}>
              <FormikCheckbox
                label={t("BY_MOBILE_PHONE_NUMBER")}
                name="authUsePhone"
                labelPlacement='end'
              />
            </Box>

            <Box sx={{ mt: '24px', ml: '-12px' }}>
              <FormikCheckbox
                label={t("BY_USING_GOOGLE_ACCOUNT")}
                labelPlacement='end'
                name="authUseGoogle"
              />
            </Box>
            <Box
              sx={{
                mt: "255px",
                display: "flex",
                justifyContent: "end",
                mr: "4px",
              }}
            >
              <CustomButton
                onClick={submitForm}
                backgroundColor={common.primaryColor}
                fontColor={common.fontWhite}
                width="98px"
                borderRadius="6px"
                height="38px"
                sx={{
                  alignItems: "center",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "130%",
                }}
              >
                {t("common:actions.SAVE")}
              </CustomButton>
            </Box>
          </FormikForm>
        )}
      </Formik>
    </Box>
  )
}

export default General