import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from '@mui/icons-material/Tune';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';

import { serviceCourse } from "@services/course";
import AvatarPicture from "@components/AvatarPicture";
import Pagination from "@ui/Pagination";
import CustomAutocomplete from "@ui/Autocomplete";
import { IRole, IDictionary, IUserExternal } from "@interfaces/index";
import { IOrganization } from "@pages/dictionary/Organization/models";
import { IFilterExternal, initialFilterExternal, ISortingExternal, SortingType } from "./models";
import './styles.scss';
import dayjs from "dayjs";
import CustomDatePicker from "@ui/DatePicker";
import CustomButton from "@ui/Button";
import { ClickAwayListener } from "@mui/material";
import { serviceUser } from "@services/user";

const avatarStyle = {
  cursor: "pointer",
  width: "43px",
  height: "43px",
  mr: '12px'
};
interface IStatus {
  id: number;
  value: boolean;
  name: string;
}

const ExternalUsersList = () => {
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const { t } = useTranslation(["user", "common", "sideMenu", "enum"]);
  const lang = localStorage.getItem("i18nextLng") || "kz";
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role, orgId } = profile.authorityInfo;
  const [usersList, setUsersList] = useState<IUserExternal[]>([]);
  const [courseList, setCourseList] = useState<IRole[]>([]);
  const [count, setCount] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [filter, setFilter] = useState<IFilterExternal>(initialFilterExternal);
  const [sorting, setSorting] = useState<Partial<ISortingExternal>>({})
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openTimeFilter, setOpenTimeFilter] = useState<boolean>(false)
  const [timeFilterDates, setTimeFilterDates] = useState<string | null>(null)
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')

  const handleChangePage = (newPage: number) => setCurrentPage(newPage);

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
    setCurrentPage(0);
  };

  const handleChangeAutocomplete = (name: string, value: IDictionary | IOrganization | IStatus) => {
    setCurrentPage(0);
    setFilter({
      ...filter,
      [name]: value
    });
  }

  const handleSorting = (name: string) => {
    const sortType = sorting[name] === SortingType.ASC ? SortingType.DESC : SortingType.ASC
    setSorting({
      ...sorting,
      [name]: sortType
    })
  }

  const searchByName = (searchValue: string) => {
    setCurrentPage(0);
    const new_filter = { ...filter, search: searchValue };
    setFilter(new_filter);
  };

  const searchByDates = (startDate: string, endDate: string) => {
    setCurrentPage(0);
    setFilter({
      ...filter,
      startDate,
      endDate
    })
  }

  useEffect(() => {
      serviceCourse.findAllFreeCourses({
        pageNumber: null,
        size: null,
        filter: {},
        sorting: {}
      }).then((res) => {
        if (res.status === 200) {
          const courses = res.data.dtoList.map(data => data.course)
          setCourseList(courses);
        }
      })
  }, [filter.courseId]);

  const getUserList = (pageNumber: number, size: number, filter: IFilterExternal, sorting: any) => {
    serviceCourse
      .getExternalUsersList(
        {
          pageNumber,
          size,
          filter,
          sorting
        }
      )
      .then(async (res) => {
        if (res.status === 200) {
          const usersList = res.data.dtoList
          const usersListWithPhotoIdAndEmail = await Promise.all(usersList.map(async (externalUser) => {
            try {
              const info = await serviceUser.getUserFullNameById(externalUser.userId);
              return {
                ...externalUser,
                email: info.data.email,
                profilePhotoId: info.data.profilePhotoId,
              };
            } catch (error) {
              console.error(`Error fetching data for user ${externalUser.userId}:`, error);
              return externalUser;
            }
          }))
          setUsersList(usersListWithPhotoIdAndEmail);
          setCount(res.data.totalElements);
        }
      });
  }

  useEffect(() => {
    getUserList(currentPage, rowsPerPage, filter, sorting);
  }, [currentPage, rowsPerPage, filter, sorting]);

  const style = {
    '& label.Mui-focused': { color: common.primaryColor },
    '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': { borderColor: common.primaryColor },
      '&.Mui-focused fieldset': { borderColor: common.primaryColor }
    }
  };

  const tableStyle = {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "black",
  }

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${common.borderSecondary}`,
        backgroundColor: common.fontWhite,
        borderRadius: '24px',
        p: '24px',
        width: '100%',
        minHeight: '650px'
      }}>
        <Box sx={{ mb: '24px' }}>
          <Grid container spacing={1}>
            <Grid item xs={11} sm={10.5} md={10.5} lg={10.5} xl={11}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                color="primary"
                sx={{ width: "100%", justifyContent: "flex-start", ...style }}
                placeholder={t("common:placeholders.SEARCH_BY_FULL_NAME_AND_COURSE_AND_MAIL")}
                name="searchValue"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => searchByName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <Box sx={{
                border: `1px solid ${common.borderSecondary}`,
                height: '40px',
                width: '124px',
                backgroundColor: !openFilter ? 'white' : `${common.primaryColor}`, borderRadius: '9px', display: 'flex', alignItems: 'center', px: '4px', cursor: 'pointer',
                color: !openFilter ? 'black' : 'white',
                ":hover": { borderColor: `${common.borderPrimary}` }
              }}
                onClick={() => setOpenFilter(!openFilter)}
              >
                <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "20px", color: !openFilter ? "#7D7D7D" : 'white', mr: '8px', ml: '4px', minWidth: 0 }}>
                  {t('common:FILTER')}
                </Typography>
                <TuneIcon sx={{color: !openFilter ? "#7D7D7D" : 'white'}} />
                {
                  openFilter ? (
                    <ArrowDropUpIcon sx={{ width: '30px', height: '30px', color: !openFilter ? "#7D7D7D" : 'white' }} />
                  ) : (
                    <ArrowDropDownIcon sx={{ width: '30px', height: '30px', color: !openFilter ? "#7D7D7D" : 'white' }} />
                  )
                }
              </Box>
            </Grid>
          </Grid>
        </Box>
        {
          openFilter && (
            <>
              <Box sx={{
                display: 'flex', gap: 1, mb: '20px', justifyContent: 'space-between', height: '65px',
              }}>
                <CustomAutocomplete
                  name='courseId'
                  sx={{ width: '30%', height: '35px', ...style}}
                  valueKey="id"
                  options={courseList}
                  label={t('common:COURSE')}
                  onChange={(name: string, value: IDictionary) => handleChangeAutocomplete(name, value)}
                  customIconDisabled={false}
                />
                <Box sx={{
                    position: 'relative',
                    border: `1px solid ${common.borderSecondary}`,
                    height: '40px',
                    width: '245px',
                    marginTop: 'auto',
                    backgroundColor: !openTimeFilter ? 'white' : `${common.primaryColor}`, borderRadius: '9px', display: 'flex', alignItems: 'center', px: '4px', cursor: 'pointer',
                    color: !openTimeFilter ? 'black' : 'white',
                    ":hover": { borderColor: `${common.borderPrimary}` },
                }}
                    onClick={() => {
                        setOpenTimeFilter(!openTimeFilter)
                    }}
                >   
                    <CalendarMonthIcon/>
                    <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "20px", color: !openTimeFilter ? 'black' : 'white', mr: '8px', ml: '4px' }}>
                    { !timeFilterDates ? t('course:REGISTRATION_PERIOD') : timeFilterDates}
                    </Typography>
                    {
                    !timeFilterDates ? openTimeFilter ? (
                        <ArrowDropUpIcon sx={{ width: '30px', height: '30px' }} />
                    ) : (
                        <ArrowDropDownIcon sx={{ width: '30px', height: '30px' }} />
                    ) : <span onClick={(e) => {
                        e.stopPropagation()
                        setTimeFilterDates(null)
                        setStartDate('')
                        setEndDate('')
                        setFilter({
                          ...filter,
                          startDate: '',
                          endDate: ''
                        })
                        }}>
                            <ClearIcon/>
                        </span>
                    }
                    { openTimeFilter && 
                    <ClickAwayListener onClickAway={() => setOpenTimeFilter(!openTimeFilter)}>
                        <Box onClick={(e) => e.stopPropagation()} sx={{position: 'absolute' , height: 'auto', border: '1px solid #E5E0EB', borderRadius: '6px',padding: '12px', color: 'black', background: 'white', top: '100%', right: '0' }}>
                            <Box sx={{display: 'flex'}}>
                                <Box sx={{ width: '120px', mr: '10px' }} >
                                    <CustomDatePicker name='startDate' label="С" maxDate={endDate} placeholder={t("Начало")} date={startDate} handleDateChange={(newValue) => {
                                        if (newValue && newValue instanceof Date && !isNaN(newValue.getTime())) {
                                            setStartDate(dayjs(newValue).format('YYYY-MM-DD'))
                                        } else {
                                            setStartDate('');
                                        }
                                    }}/>
                                </Box>
                                <Box sx={{width: '120px'}} >
                                    <CustomDatePicker name='endDate' label="По" minDate={startDate} placeholder={t("Конец")} date={endDate} handleDateChange={(newValue) => {
                                        if (newValue && newValue instanceof Date && !isNaN(newValue.getTime())) {
                                            setEndDate(dayjs(newValue).format('YYYY-MM-DD'))
                                        } else {
                                            setEndDate('');
                                        }
                                    }}/>
                                </Box> 
                            </Box>
                            <CustomButton 
                                height="30px"
                                mt="10px"
                                onClick={() => {
                                    setTimeFilterDates(`${startDate} - ${endDate}`)
                                    searchByDates(startDate, endDate)
                                    setOpenTimeFilter(false)
                                }}
                            >
                                {t("common:actions.APPLY")}
                            </CustomButton>
                        </Box>
                    </ClickAwayListener>}
                </Box>
                
              </Box>
            </>
          )
        }
        <TableContainer component={Paper} sx={{ mt: '16px' }}>
          <Table>
            <TableHead sx={{height: '75px'}}>
              <TableRow>
                <TableCell sx={{...tableStyle}} >
                  <Box sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={() => handleSorting('fio')}>
                    <p>{t("FULL_NAME")}</p>
                    { sorting.fio === SortingType.ASC 
                      ?
                      <ArrowDropUpIcon sx={{ width: '30px', height: '30px' }} />
                      :
                      <ArrowDropDownIcon sx={{ width: '30px', height: '30px' }} />
                    }
                  </Box>
                </TableCell>
                <TableCell sx={{...tableStyle}} >
                  <Box sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}  onClick={() => handleSorting('email')}>
                    <p>{t("EMAIL")}</p>
                    { sorting.email === SortingType.ASC 
                      ?
                      <ArrowDropUpIcon sx={{ width: '30px', height: '30px' }} />
                      :
                      <ArrowDropDownIcon sx={{ width: '30px', height: '30px' }} />
                    }
                  </Box>
                </TableCell>
                <TableCell sx={{...tableStyle}}>
                  <Box sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}  onClick={() => handleSorting('courseName')}>
                    <p>{t("course:COURSE")}</p>
                    { sorting.courseName === SortingType.ASC 
                      ?
                      <ArrowDropUpIcon sx={{ width: '30px', height: '30px' }} />
                      :
                      <ArrowDropDownIcon sx={{ width: '30px', height: '30px' }} />
                    }
                  </Box>
                </TableCell>
                <TableCell sx={{...tableStyle}} >
                  <Box sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}  onClick={() => handleSorting('regDate')}>
                    <p>{t("course:COURSE_REGISTRATION_DATE")}</p>
                    { sorting.regDate === SortingType.ASC 
                      ?
                      <ArrowDropUpIcon sx={{ width: '30px', height: '30px' }} />
                      :
                      <ArrowDropDownIcon sx={{ width: '30px', height: '30px' }} />
                    }
                  </Box>
                </TableCell>
                <TableCell sx={{...tableStyle}} >
                  <Box sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}  onClick={() => handleSorting('status')}>
                    <p>{t("STATUS")}</p>
                    { sorting.status === SortingType.ASC 
                      ?
                      <ArrowDropUpIcon sx={{ width: '30px', height: '30px' }} />
                      :
                      <ArrowDropDownIcon sx={{ width: '30px', height: '30px' }} />
                    }
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            {usersList?.length > 0 &&
              <TableBody>
                {usersList.map((user, index) => {
                  return (
                    <TableRow key={`${index}_${user.userId}`} sx={{height: '75px', ':hover': {backgroundColor: '#F5F5F5'}}}>
                      <TableCell sx={{ ...tableStyle, width: 200 }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            color: "#323C47",
                            fontWeight: "500",
                            lineHeight: "20px",
                            height: "20px",
                          }}
                          // onClick={() => navigate(`/users/${user.userId}`)}
                        >
                          <AvatarPicture
                            id={user.profilePhotoId}
                            lastName={user.userId || undefined}
                            sxProps={avatarStyle}
                          />
                          {user.fio}
                        </Box>
                      </TableCell>
                      <TableCell sx={tableStyle}>
                        {user.email}
                      </TableCell>
                      <TableCell sx={tableStyle}>
                        {user.courseName}
                      </TableCell>
                      <TableCell sx={tableStyle}>
                        {user.regDate}
                      </TableCell>
                      <TableCell sx={tableStyle}>
                        {t(`course:${user.status}`)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            }
          </Table>
          {
            !usersList.length && 
            <Box sx={{mt: '3rem'}}>
              <Typography sx={{textAlign: 'center'}}>Нет данных для отображения</Typography>
            </Box>
          }
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Pagination
              count={count}
              page={currentPage}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </TableContainer>
      </Box>
    </Box >
  );
};

export default ExternalUsersList;