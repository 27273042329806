import { useState, useEffect } from 'react';
import { get, capitalize } from 'lodash';

import { serviceDict } from '@services/dictionary';
import { DictionaryCode } from '@interfaces/index';

interface Props {
  id: number | null;
}

const LanguageCell = ({ id }: Props) => {
  const [langName, setLangName] = useState('');
  const lang = localStorage.getItem("i18nextLng") || "kz";

  useEffect(() => {
    async function getLangNameById() {
      if (id) {
        const response = await serviceDict.getDictionary(DictionaryCode.LANGUAGE, id);
        const langName = await get(response.data, `name${capitalize(lang)}`);
        setLangName(langName);
      }
    }

    getLangNameById();
  }, [id, lang]);

  return (
    <>{langName}</>
  );
}

export default LanguageCell;