export interface ICategory {
  id: number | null;
  nameKz: string | null;
  nameRu: string | null;
  nameEn: string | null;
  isDisabled: boolean;
  parentId: number | null;
  parent?: ICategory | null;
  isStandard: boolean;
}

export const initialDto: ICategory = {
  id: null,
  nameKz: null,
  nameRu: null,
  nameEn: null,
  isDisabled: false,
  parentId: null,
  isStandard: false
};

export interface IFilter {
  search: string;
  isDisabled: boolean;
  parentId: number | null;
}

export const initialFilter: IFilter = {
  search: "",
  isDisabled: false,
  parentId: 0,
};
