import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';

import TextReader from '@ui/TextReader';

const CourseTargetAudience = ({ text }) => {
  const { t } = useTranslation(["common", "course"]);

  return (
    <Box>
      <Typography fontSize="32px" fontWeight={700}>{t("course:WHO_IS_THIS_COURSE_FOR")}</Typography>
      <TextReader htmlContent={text} hideBorder numberOfSymbols={300} />
    </Box>
  )
}

export default CourseTargetAudience;
