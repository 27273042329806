import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import { useNavigate } from 'react-router-dom';

const PreviewLogout = ({ courseId }) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(['common', 'course']);
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();

  const changeStep = (step: number, label: string) => {
    navigate(`/myCourses/${courseId}`)
    const newCrumbs = [...getBreadcrumbs(courseId)].map((crumb) => {
      if (crumb.level === 2) {
        return { ...crumb, label, tabNum: step };
      }

      return crumb;
    });
    setBreadcrumbs(courseId, newCrumbs);
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100vw',
        height: '94px',
        background: '#fff',
        boxShadow: '0px -5px 10px 3px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: `1px solid ${common.borderSecondary}`
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Typography>{t('course:hints.EXIT_FROM_PREVIEW')}</Typography>
        <Typography onClick={() => changeStep(1, "course:MAIN_PAGE")}
          sx={{
            color: common.primaryColor,
            textDecoration: 'underline',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'none'
            },
          }}>{t('profile:LOGOUT')}</Typography>
      </Box>
    </Box>
  )
}

export default PreviewLogout
