import React, { useState } from "react";
import CustomButton from "@ui/Button";
import { Box, Typography, useTheme } from "@mui/material";
import { CoursePicture } from "./CoursePicture";
import { serviceCourse } from "@services/course";
import { useSelector } from "react-redux";
import { StateSchema } from "@store/StateSchema";
import YesOrNoModal from "@components/modals/YesOrNoModal";
import { useTranslation } from "react-i18next";
import { InvitationDto } from "@interfaces/invitation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { authorControllerApi } from "@services/index";

interface Props {
  authorConfirmation: InvitationDto[];
}

interface MutationProps {
  classId: string;
  confirm: boolean;
}

export const AuthorConfirmation = (props: Props) => {
  const { authorConfirmation } = props;
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course", "enum", "common"]);
  const [openAuthorModal, setOpenAuthorModal] = useState<boolean>(false);
  const profileId =
    useSelector((store: StateSchema) => store.account.profileInfo.pltaId) ?? "";
  const [authorId, setAuthorId] = useState<string>("");
  const [confirm, setConfirm] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleChangeStatus = useMutation({
    mutationFn: (status: MutationProps) =>
      authorControllerApi.updateConfirmationAuthor(
        status.classId,
        status.confirm
      ),
    onSuccess: () => {
      setOpenAuthorModal(!openAuthorModal);
      queryClient.invalidateQueries({ queryKey: ["authorConfirmation"] });
    },
  });
  return (
    <>
      {authorConfirmation.map((author) => {
        return (
          <Box
            key={author.courseId}
            display="flex"
            justifyContent="space-between"
            marginBottom="16px"
          >
            <Box display="flex" gap={1.5}>
              <CoursePicture courseId={author?.courseId} />
              <Box>
                <Typography fontSize="16px" fontWeight={600}>
                  {author?.courseName}
                </Typography>
                <Typography fontSize="14px" color="#8E8E93">
                  {t("course:YOU_HAVE_BEEN_SELECTED_AS_AUTHOR", {
                    courseName: author.courseName,
                  })}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap={1}>
              <CustomButton
                backgroundColor={common.fontWhite}
                fontColor={common.primaryColor}
                onClick={() => {
                  setAuthorId(
                    String(
                      author.authors.find((ele) => ele.userId === profileId)?.id
                    )
                  );
                  setConfirm(false);
                  setOpenAuthorModal(true);
                }}
                borderRadius="4px"
                height="34px"
              >
                {t("common:actions.REJECT")}
              </CustomButton>
              <CustomButton
                onClick={() => {
                  setAuthorId(
                    String(
                      author.authors.find((ele) => ele.userId === profileId)?.id
                    )
                  );
                  setConfirm(true);
                  setOpenAuthorModal(true);
                }}
                borderRadius="4px"
                height="34px"
              >
                {t("common:actions.ACCEPT")}
              </CustomButton>
            </Box>
          </Box>
        );
      })}
      {openAuthorModal && (
        <YesOrNoModal
          openModal={openAuthorModal}
          handleToggleModal={() => setOpenAuthorModal((prev) => !prev)}
          confirmAction={() =>
            handleChangeStatus.mutate({
              classId: authorId ?? "",
              confirm,
            })
          }
          success={false}
          attention={true}
          message={
            confirm
              ? t("course:messages.ARE_YOU_SURE_ACCEPT_INVITATION")
              : t("course:messages.ARE_YOU_SURE_REJECT_INVITATION")
          }
        />
      )}
    </>
  );
};
