import { useState, useContext } from "react";
import { Box, useTheme } from "@mui/material";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import { CourseContext } from "@context/CourseContext";
import CourseDescription from "@pages/freeCourse/courseDetails/Description";
import FreeCourseAuthors from "./Authors/FreeCourseAuthors";
import EduCourseAuthors from "./Authors/EduCourseAuthors";
import ImageViewer from "@components/ImageViewer";
import { PromoVideoModal } from "@pages/freeCourse/courseDetails/PromoVideoModal";
import CourseTargetAudience from "@pages/freeCourse/courseDetails/TargetAudience";
import { TypeCode } from "@interfaces/index";

export const DescriptionCurrentCourse = () => {
  const { common } = useTheme().palette;
  const { courseData } = useContext(CourseContext);
  const [openPromoVideo, setOpenPromoVideo] = useState<boolean>(false);

  return (
    <Box
      sx={{
        mt: 1.25,
        border: `1px solid ${common.borderSecondary}`,
        borderRadius: "12px",
        backgroundColor: common.white,
        p: 2,
      }}
    >
      <Box>
        {courseData?.coverFileId ? <ImageViewer
          fileId={courseData?.coverFileId}
          viewOnly
          sxProps={{
            width: "100%",
            height: "450px",
            borderRadius: 3,
          }}
        /> :
          <Box sx={{
            background: `${courseData?.coverColor}`,
            width: "100%",
            height: "450px",
            borderRadius: 3
          }}>
          </Box>}
        {(courseData?.promoVideoId || courseData?.promoVideoUrl) && <Box
          display="flex"
          onClick={() => setOpenPromoVideo(!openPromoVideo)}
          gap={1}
          sx={{
            background: "#BABBC0",
            padding: "8px 12px",
            alignItems: "center",
            borderRadius: "30px",
            cursor: "pointer",
            width: "50px",
            position: "absolute",
            marginTop: "-220px",
            marginLeft: "33%",
          }}
        >
          <PlayCircleOutlineOutlinedIcon />
        </Box>}
      </Box>
      <CourseDescription description={courseData?.description} />
      {courseData?.typeCode === TypeCode.FREE ?
        <FreeCourseAuthors courseId={courseData?.courseId} /> :
        <EduCourseAuthors courseId={courseData?.courseId} />
      }
      {courseData?.typeCode === TypeCode.FREE && <CourseTargetAudience text={courseData?.targetAudience} />}
      {openPromoVideo && (
        <PromoVideoModal
          open={openPromoVideo}
          videoId={courseData?.promoVideoId}
          videoUrl={courseData?.promoVideoUrl}
          handleToggleModal={() => setOpenPromoVideo(!openPromoVideo)}
        />
      )}
    </Box>
  );
};
