import { useState, useEffect } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { Formik, Form as FormikForm } from "formik"
import Clear from "@mui/icons-material/Clear";
import { AlertColor } from '@mui/material/Alert';
import { useTranslation } from "react-i18next";

import { DTO, initialDto } from './models'
import FormikInput from '@ui/formik/Input'
import FormikCheckbox from '@ui/formik/Checkbox'
import CustomButton from '@ui/Button'
import { serviceDict } from '@services/dictionary'
import { fileFormatValidationSchema, dictionaryValidationSchema } from './validation'
import MaskedInput from '@ui/formik/MaskedInput';
import { IDictionary } from '@interfaces/index';

interface Props {
  state: { create: boolean };
  setState: (state: { create: boolean }) => void;
  lists: IDictionary[];
  setLists: (lists: IDictionary[]) => void;
  selectedId: number | null;
  dictCode?: string;
  setMessage: (message: string) => void;
  setMessageOpen: (open: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
}

const Form = ({ state, setState, lists, setLists, selectedId, dictCode, setMessage, setMessageOpen, setMessageType }: Props) => {
  const [initialData, setInitialData] = useState<DTO>(initialDto);
  const { common } = useTheme().palette;
  const { t } = useTranslation(["dictionary", "common", "enum"]);
  const [header, setHeader] = useState<string>('')

  useEffect(() => {
    if (selectedId) {
      if (dictCode) {
        serviceDict.getDictionary(dictCode, selectedId).then((res) => {
          setInitialData(res.data);
          setHeader(res.data.nameKz)
        });
      }
    }
  }, [selectedId, dictCode]);

  return (
    <Box sx={{ p: '48px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontWeight: 500, fontSize: 20, paddingBottom: '24px', lineHeight: '18px' }}>
          {!selectedId ? `${t("ADD_ENTRY")}` : `${t("EDIT_ENTRY")}`}
        </Typography>
        <Clear sx={{ color: common.errorColor, cursor: "pointer" }} onClick={() => setState({ ...state, create: false })} />
      </Box>

      {selectedId && (
        <Typography sx={{ fontSize: 30, paddingBottom: '24px', }}>
          {header}
        </Typography>
      )}
      <Box>
        <Formik
          initialValues={initialData}
          enableReinitialize
          validationSchema={dictCode === 'FILE_TYPE' ? fileFormatValidationSchema(t) : dictionaryValidationSchema(t)}
          onSubmit={(values) => {
            if (dictCode === 'FILE_TYPE') {
              values.nameRu = values.nameKz;
              values.nameEn = values.nameKz;
              values.code = values.nameKz;
            }
            if (dictCode) {
              if (!selectedId) {
                serviceDict.createDictionary(dictCode, values)
                  .then((res) => {
                    if (res.status === 200) {
                      setMessageOpen(true);
                      setMessageType("success");
                      setMessage(t("common:messages.RECORD_ADDED"));
                      setTimeout(() => {
                        setMessageOpen(false);
                      }, 3000)
                      setState({ ...state, create: false });
                    }
                  }).catch((err) => {
                    if (err.response.status === 400) {
                      setMessageOpen(true);
                      setMessageType("error");
                      err.response.data.message === "ITEM_IS_EXISTS" ?
                        setMessage(t("ITEM_IS_EXISTS")) : setMessage(t("common:messages.ERROR"));
                      setTimeout(() => {
                        setMessageOpen(false);
                      }, 10000)
                      setState({ ...state, create: false });
                    }
                  })
              }
              else {
                if (dictCode === 'FILE_TYPE') {
                  values.code = values.nameKz;
                }
                serviceDict.createDictionary(dictCode, values).then((res) => {
                  if (res.status === 200) {
                    const index = [...lists].findIndex((x) => x.id === selectedId);
                    setLists([
                      ...lists.slice(0, index),
                      res.data,
                      ...lists.slice(index + 1),
                    ]);
                    setMessageOpen(true);
                    setMessageType("success");
                    setMessage(t("common:messages.CHANGES_SAVED"));
                    setTimeout(() => {
                      setMessageOpen(false);
                    }, 3000)
                    setState({ ...state, create: false });
                  }
                }).catch((err) => {
                  if (err.response.status === 400) {
                    setMessageOpen(true);
                    setMessageType("error");
                    err.response.data.message === "ITEM_IS_EXISTS" ?
                      setMessage(t("ITEM_IS_EXISTS")) : setMessage(t("common:messages.ERROR"));
                    setTimeout(() => {
                      setMessageOpen(false);
                    }, 10000)
                    setState({ ...state, create: false });
                  }
                }).finally(() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                })
              }
            }
          }}
        >
          {({ values, submitForm, isSubmitting }) => (
            <FormikForm>
              {
                dictCode === "FILE_TYPE" ? (
                  <MaskedInput
                    label={t("NAME_THE_FILE_FORMAT")}
                    name='nameKz'
                    type="extension"
                    placeholder=".####"
                    disabled={initialData.isStandard}
                  />
                ) : (
                  <>
                    <Box sx={{ mb: '12px' }}><FormikInput label={t("NAME_IN_KAZAKH")} name='nameKz' disabled={values.isStandard} isRequired /></Box>
                    <Box sx={{ mb: '12px' }}><FormikInput label={t("NAME_IN_RUSSIAN")} name='nameRu' disabled={values.isStandard} isRequired /></Box>
                    <Box sx={{ mb: '12px' }}><FormikInput label={t("NAME_IN_ENGLISH")} name='nameEn' disabled={values.isStandard} isRequired /></Box>
                  </>
                )}

              <Box sx={{ mt: '12px' }}>
                <FormikCheckbox name='isDisabled' label={t("enum:NOT_USED")} labelPlacement='end' />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "end", mt: '12px' }}>
                <CustomButton
                  width="150px"
                  backgroundColor={common.primaryColor}
                  borderRadius="6px"
                  fontColor={common.fontWhite}
                  sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '130%',
                  }}
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  {!selectedId ? `${t("common:actions.ADD")}` : `${t("common:actions.SAVE")}`}
                </CustomButton>
              </Box>
            </FormikForm>
          )}
        </Formik>
      </Box>
    </Box>
  )
}

export default Form