import { ReactComponent as Ul } from "@assets/svg_files/home/ul.svg";
import CustomModal from "@components/Modal";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormikInput from "@ui/formik/Input";
import { TextEditor } from "@ui/formik/TextEditor";
import { Form, Formik } from "formik";
import React, { Dispatch, SetStateAction } from "react";
import * as yup from "yup";
import s from "./ConnectionWithUsModal.module.scss";
import Cover from "@assets/images/home/cover1.png";
import { useMutation } from "@tanstack/react-query";
import { publicControllerApi } from "@services/index";
import { AddOrganization } from "@interfaces/organization";
import { useNavigate } from "react-router-dom";
import MaskedInput from "@ui/formik/MaskedInput";

interface CustomModalProps {
  onClose: () => void;
  open: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
}


const ConnectionWithUsModal: React.FC<CustomModalProps> = ({
  onClose,
  open,
  setModal,
}) => {
  const navigate = useNavigate()
  const handleClose = () => {
    onClose();
  };
  const initialValues: AddOrganization = {
    responsiblePersonName: "",
    phone: "",
    email: "",
    organizationName: "",
    question: "",
    checked: false,
  };

  const validationSchema = () =>
    yup.object({
      phone: yup.string().nullable().required("Введите номер телефона"),
      organizationName: yup
        .string()
        .nullable()
        .required("Введите наименование организации"),
      checked: yup.boolean().required().oneOf([true], ""),
    });
  // 
  const mutation = useMutation({
    mutationFn: ((newTodo:AddOrganization) => publicControllerApi.updateAddOrganization(newTodo))
    ,
  })
  return (
    <CustomModal open={open} handleToggleModal={handleClose} width={794}>
      <Box
        sx={{
          display: "flex",
          gap: 3,
        }}
      >
        <div className={s.info_wrapper}>
          <div className={s.info}>
            <div className={s.title}>
              Заполните заявку и начните делиться своими знаниями
            </div>
            <div className={s.description}>
              <div className={s.text}>В ходе звонка мы:</div>
              <div className={s.list}>
                <Ul />
                <span>Проведём демонстрацию</span>
              </div>
              <div className={s.list}>
                <Ul />
                <span> Ознакомим с договором</span>
              </div>
              <div className={s.list}>
                <Ul />
                <span>Выдадим учётные записи</span>
              </div>
            </div>
          </div>
          <div className={s.img}>
            <img src={Cover} alt="" />
          </div>
        </div>

        <div className={s.content}>
          <Formik
            initialValues={initialValues}
            onSubmit={(values: AddOrganization) => {
              mutation.mutate(values);
              setModal(true);
              
              handleClose();
            }}
            validationSchema={validationSchema}
          >
            {({ submitForm, dirty, isValid, handleChange, values }) => {
              return (
                <Form>
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <FormikInput
                      name="responsiblePersonName"
                      label={"Ваше имя"}
                      placeholder="Введите имя"
                    />
                    <MaskedInput
                      name="phone"
                      label={"Номер телефона"}
                      type="cellphone"
                      placeholder="+7 (_ _ _) _ _ _-_ _-_ _"
                      isRequired
                    />
                    <FormikInput
                      name="email"
                      label={"Почта"}
                      placeholder="Введите почту"
                    />
                    <FormikInput
                      isRequired
                      name="organizationName"
                      label={"Наименование организации"}
                      placeholder="Введите наименование организации"
                    />
                    <TextEditor
                      name="question"
                      label={"Сообщение"}
                      minChar={32}
                      maxChar={512}
                      hideToolbar={true}
                    />
                    <Stack  direction="row">
                      <FormControl required>
                        <FormControlLabel
                          name="checked"
                          label=""
                          control={
                            <Checkbox size="medium" value={values.checked} />
                          }
                          onChange={handleChange}
                        />
                      </FormControl>
                      <div className={s.helper}>
                        Подтверждаю согласие{" "}
                        <span onClick={() => navigate('/terms-of-use')}>с политикой конфиденциальности</span>
                      </div>
                    </Stack>
                    <Button
                      onClick={submitForm}
                      type="submit"
                      sx={{
                        padding: "8px 22px",
                        borderRadius: "4px",
                        marginTop: 2,
                        width: "100%",
                      }}
                      size="large"
                      variant="contained"
                      color="primary"
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 400,
                        }}
                      >
                        Отправить заявку
                      </Typography>
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Box>
    </CustomModal>
  );
};

export default ConnectionWithUsModal;
