import React, { useMemo, useState } from 'react'
import { Box, MenuItem, Switch, Typography } from '@mui/material'
import CustomAutocomplete from '@ui/Autocomplete';
import CustomSearch from '@ui/Search'
import { useTranslation } from 'react-i18next';
import { MaterialReactTable, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import {
  type MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";

const mockData = [{
  name: "Евразийский Национальный Университет", dateStart: "10.12.2023",
  paid: true, dateEnd: "10.12.2024 10:30", status: "Остановлена"
},
{
  name: "Евразийский Национальный Университет", dateStart: "10.12.2023",
  paid: true, dateEnd: "10.12.2024 10:30", status: "Остановлена"
},
{
  name: "Евразийский Национальный Университет", dateStart: "10.12.2023",
  paid: true, dateEnd: "10.12.2024 10:30", status: "Остановлена"
},
{
  name: "Евразийский Национальный Университет", dateStart: "10.12.2023",
  paid: true, dateEnd: "10.12.2024 10:30", status: "Остановлена"
},
{
  name: "Евразийский Национальный Университет", dateStart: "10.12.2023",
  paid: true, dateEnd: "10.12.2024 10:30", status: "Остановлена"
}]

export const IssuedLicence = () => {
  const { t } = useTranslation(["license", "common", "sideMenu", "course"]);
  const [educatorList, setEducatorList] = useState<Array<any>>([]);
  const [count, setCount] = useState()
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25, //customize the default page size
  });
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t("license:ISSUED_LICENSE_ORGANIZATION"),
        size: 300,
        enableSorting: true,
        Cell: ({ renderedCellValue, row }) => (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}>
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                {renderedCellValue}
              </Typography>
            </Box>
          </Box>
        )
      },
      {
        accessorKey: 'dateStart',
        header: t("course:DATE_OF_ISSUE"),
        enableSorting: true,
        size: 50
      },
      {
        accessorKey: 'dateEnd',
        header: t("course:test.END_DATE"),
        enableSorting: true,
        size: 50
      },
      {
        accessorKey: 'paid',
        header: t("license:PAID"),
        enableSorting: true,
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Box>
            <Switch />
          </Box>
        )
      },
      {
        accessorKey: 'status',
        header: t("common:label.STATUS"),
        enableSorting: true,
        size: 50
      },

    ],
    [t],
  );

  const table = useMaterialReactTable({
    columns,
    data: mockData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableSorting: true,
    enableColumnActions: false,
    enableExpandAll: false,
    enableColumnResizing: false,
    enableTopToolbar: false,
    enableRowNumbers: false,
    enableColumnPinning: false,
    enableRowActions: true,
    manualPagination: true,
    manualSorting: true,
    positionActionsColumn: "last",
    localization: {
      noRecordsToDisplay: t('dictionary:EMPTY'),
      rowsPerPage: t('enum:LINES_PER_PAGE'),
      actions: "...",
      expand: "",
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: 30,
        muiTableBodyCellProps: { align: "center" },
        muiTableHeadCellProps: { align: "center" }
      },
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default, //change default background color
    }),
    initialState: {
      columnPinning: { right: ["mrt-row-actions",] },
    },
    muiTableBodyRowProps: { hover: false },
    muiTableHeadCellProps: {
      sx: {
        borderLeft: '1px solid rgba(81, 81, 81, .5)'
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderLeft: '1px solid rgba(81, 81, 81, .5)',
      },
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        display: 'grid'
      },
    },
    muiTableContainerProps: {
      sx: {
        '&::-webkit-scrollbar': { height: '4px', width: '7px' },
        '&::-webkit-scrollbar-thumb': { backgroundColor: '#D9D9D9', borderRadius: '4px' }
      },
    },
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <MenuItem
        key={0}
        onClick={() => { closeMenu() }}
        sx={{

        }}
      >
        {t("common:actions.EDIT")}
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => { closeMenu() }}
        sx={{
        }}
      >
        {t("common:actions.STOP")}
      </MenuItem>,
      <MenuItem
        key={2}
        onClick={() => {
          closeMenu()
        }}
      >
        {t("common:actions.EXTEND")}
      </MenuItem>,
      <MenuItem
        key={2}
        onClick={() => {
          closeMenu()
        }}
      >
        {t("common:actions.DELETE")}
      </MenuItem>,
    ],
    onPaginationChange: setPagination,
    state: { pagination, sorting }, //pass the pagination state to the table
    rowCount: count,
    onSortingChange: setSorting,
    paginationDisplayMode: "pages",
  });
  return (
    <Box sx={{ marginTop: "24px" }}>
      <Box display="flex" justifyContent="space-between" mb="12px">
        <CustomSearch width='390px' placeholder={t("common:placeholders.SEARCH_BY_ORGANIZATION_LICENSE")} />
        <Box display="flex" gap={1.5}>
          <CustomAutocomplete
            name="typeLicense"
            options={[]}
            sx={{ width: "166px" }}
            clearable={false}
          />
          <CustomAutocomplete
            name="statusLicense"
            options={[]}
            sx={{ width: "166px" }}
            clearable={false} />
        </Box>
      </Box>
      <MaterialReactTable table={table} />
    </Box>
  )
}
