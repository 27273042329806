/* tslint:disable */
/* eslint-disable */
/**
 * course service
 * <b>use example:</b> http://{host}:4044/course/{this service rest endpoints}
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CourseMember } from "./course-member";
import { CourseMemberGroup } from "./course-member-group";
import { CourseTask } from "./course-task";
/**
 * Получатели задания
 * @export
 * @interface CourseTaskRecipient
 */
export interface CourseTaskRecipient {
  /**
   *
   * @type {string}
   * @memberof CourseTaskRecipient
   */
  id?: string;
  /**
   * Тип получателя
   * @type {string}
   * @memberof CourseTaskRecipient
   */
  type: CourseTaskRecipientTypeEnum;
  /**
   * Статус
   * @type {string}
   * @memberof CourseTaskRecipient
   */
  status?: CourseTaskRecipientStatusEnum;
  /**
   *
   * @type {CourseTask}
   * @memberof CourseTaskRecipient
   */
  task?: CourseTask;
  /**
   *
   * @type {CourseMember}
   * @memberof CourseTaskRecipient
   */
  member?: CourseMember;
  /**
   *
   * @type {CourseMember}
   * @memberof CourseTaskRecipient
   */
  members?: Array<CourseMember>;
  /**
   *
   * @type {CourseMemberGroup}
   * @memberof CourseTaskRecipient
   */

  group?: CourseMemberGroup;
  /**
   * Ограничивать срок выполнения задания
   * @type {boolean}
   * @memberof CourseTaskRecipient
   */
  hasDeadlineDate?: boolean;
  /**
   * Выполнить до
   * @type {Date}
   * @memberof CourseTaskRecipient
   */
  dateOfDeadline?: Date;
  /**
   * Ограничивать доступ к заданию по IP адресу
   * @type {boolean}
   * @memberof CourseTaskRecipient
   */
  isRestrictByIp?: boolean;
  /**
   * Диапазон разрешенных IP адресов (начало)
   * @type {string}
   * @memberof CourseTaskRecipient
   */
  ipRangeStart?: string;
  /**
   * Диапазон разрешенных IP адресов (конец)
   * @type {string}
   * @memberof CourseTaskRecipient
   */
  ipRangeEnd?: string;
  /**
   * Создано кем
   * @type {string}
   * @memberof CourseTaskRecipient
   */
  createdBy?: string;
  /**
   * Дата и время создания
   * @type {Date}
   * @memberof CourseTaskRecipient
   */
  createdAt?: Date;
  /**
   * Запланировать конференцию
   * @type {boolean}
   * @memberof CourseTaskRecipient
   */
  planConference: boolean;
  /**
   * Платформа
   * @type {number}
   * @memberof CourseTaskRecipient
   */
  platform: number;
  /**
   * Ссылка
   * @type {string}
   * @memberof CourseTaskRecipient
   */
  link: string | null;
  /**
   * Логин
   * @type {string}
   * @memberof CourseTaskRecipient
   */
  login: string | null;
  /**
   * Пароль
   * @type {string}
   * @memberof CourseTaskRecipient
   */
  password: string | null;
  /**
   * Дата конференции
   * @type {string}
   * @memberof CourseTaskRecipient
   */
  conferenceDate: Date | string | null;
  /**
   * Время начала
   * @type {string}
   * @memberof CourseTaskRecipient
   */
  startTime: string | null;
  /**
   * Время конца
   * @type {string}
   * @memberof CourseTaskRecipient
   */
  endTime: string | null;
  /**
   * Назначить место сбора
   * @type {boolean}
   * @memberof CourseTaskRecipient
   */
  isMeetingPlaceAssigned: boolean;
  /**
   * Дата сбора
   * @type {string}
   * @memberof CourseTaskRecipient
   */
  meetingDate: Date | string | null;
  meetingPlace?: string | null;
  meetingStartDate?: string | null;
  meetingEndDate?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum CourseTaskRecipientTypeEnum {
  ALL = "ALL",
  GROUP = "GROUP",
  LISTENER = "LISTENER",
}
/**
 * @export
 * @enum {string}
 */
export enum CourseTaskRecipientStatusEnum {
  ASSIGNED = "ASSIGNED",
  EXPIRED = "EXPIRED",
  COMPLETED = "COMPLETED",
}
