import React from "react";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import SignalCellularNoSimOutlinedIcon from "@mui/icons-material/SignalCellularNoSimOutlined";
import { Tooltip, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const AboutScoreTable = () => {
  const { t } = useTranslation(["course", "enum"]);
  return (
    <Tooltip
      componentsProps={{
        tooltip: { sx: { background: "none" } },
      }}
      title={
        <Box
          sx={{
            background: "#fff",
            color: "#000",
            fontSize: 18,
            // width: "600px",
            padding: "10px",
            borderRadius: "6px",
          }}
        >
          <Box
            sx={{ alignItems: "center", display: "flex", gap: 1, mb: "10px" }}
          >
            <Box
              sx={{
                width: "24px",
                height: "24px",
                background: "#69CDE3",
                paddingLeft: "3px",
                borderRadius: "10px",
                color: "#fff",
              }}
            >
              <TaskOutlinedIcon sx={{ fontSize: "18px" }} />
            </Box>
            <Typography>{t("enum:CONTROL")}</Typography>
          </Box>
          <Box
            sx={{ alignItems: "center", display: "flex", gap: 1, mb: "10px" }}
          >
            <Box
              sx={{
                width: "24px",
                height: "24px",
                background: "#2D29EF",
                paddingLeft: "3px",
                borderRadius: "10px",
                color: "#fff",
              }}
            >
              <SignalCellularNoSimOutlinedIcon sx={{ fontSize: "18px" }} />
            </Box>
            <Typography>{t("course:theme.WITHOUT_EVALUATION")}</Typography>
          </Box>
          <Box
            sx={{ alignItems: "center", display: "flex", gap: 1, mb: "10px"}}
          >
            <Box
              sx={{
                width: "24px",
                height: "24px",
                background: "#F97A7A",
                paddingLeft: "3px",
                borderRadius: "10px",
                color: "#fff",
              }}
            >
              <BlockOutlinedIcon sx={{ fontSize: "18px" }} />
            </Box>
            <Typography whiteSpace={"break-spaces"}>
              {t("course:theme.EXCLUDE_FROM_FINAL_GRADE")}
            </Typography>
          </Box>
        </Box>
      }
    >
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        whiteSpace={"nowrap"}
        sx={{ color: "#8E8E93", cursor: "pointer" }}
      >
        <ErrorOutlineOutlinedIcon />
        {t("course:theme.ABOUT_THE_TABLE")}
      </Box>
    </Tooltip>
  );
};
