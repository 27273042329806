import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { serviceCourse } from '@services/course';
import CustomButton from '@ui/Button';
import OtherCoursesOfAuthorCard from './OtherCoursesOfAuthorCard';
import { IDictionary, IStudyClass, TypeCode } from '@interfaces/index';

export const OtherCourseThisAuthor = ({ authorId, courseId, diffLevelList }: { authorId: string; courseId: string, diffLevelList: Array<IDictionary> }) => {
  const [otherCourses, setOtherCourses] = useState<IStudyClass[]>([])
  const [courseNum, setCourseNum] = useState(4)
  const [totalElements, setTotalElements] = useState(0)

  useEffect(() => {
    const reqBody = {
      pageNumber: 0,
      size: courseNum,
      filter: {
        userId: authorId,
        typeCode: TypeCode.FREE,
        courseId
      }
    }
    serviceCourse.findCoursesByAuthorPublic(reqBody).then((res) => {
      if (res.status === 200) {
        // console.log(res.data);
        setOtherCourses(res.data.dtoList)
        setTotalElements(res.data.totalElements)
      }
    })
  }, [authorId, courseNum, courseId])

  const showMoreButtonVisible = otherCourses.length < totalElements;

  if(otherCourses?.length === 0) return null

  return (
    <Box>
      <Typography fontSize="32px" fontWeight={700} marginBottom="24px">Другие курсы этого автора</Typography>
      {otherCourses.map((studyClass) => {
        return (
          <OtherCoursesOfAuthorCard
            key={studyClass.id}
            course={studyClass.course}
            classId={studyClass.id}
            diffLevelList={diffLevelList}
          />
        )
      })}
      {showMoreButtonVisible &&
        <CustomButton width='131px' borderRadius='10px' sx={{ fontSize: "14px" }} onClick={() => setCourseNum(prev => prev + 4)}>
          Показать еще
        </CustomButton>
      }
    </Box>
  )
}
