import ConnectionHelper from "@components/modals/ConnectionWithUsModal/ConnectionHelper/ConnectionHelper";
import ConnectionWithUsModal from "@components/modals/ConnectionWithUsModal/ConnectionWithUsModal";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import BenefitNewSkill from "./BenefitNewSkill/BenefitNewSkill";
import CardList from "./CardList/CardList";
import CarouselComponent from "./CarouselComponent/CarouselComponent";
import Container from "./Container/Container";
import Faq from "./Faq/Faq";
import FormatEducation from "./FormatEducation/FormatEducation";
import InspirationStory from "./InspirationStory/InspirationStory";
import Integration from "./Integration/Integration";
import LeadingCourse from "./LeadingCourse/LeadingCourse";
import MeasureEarning from "./MeasureEarning/MeasureEarning";
import ReadyStudentBlock from "./ReadyStudentBlock/ReadyStudentBlock";
import ReasonChoose from "./ReasonChoose/ReasonChoose";
import SearchSuitable from "./SearchSuitable/SearchSuitable";
import StartingEducation from "./StartingEducation/StartingEducation";
import TakeCourses from "./TakeCourses/TakeCourses";
import s from "./index.module.scss";
import { HomeVariant } from "./types/home";

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const variant = searchParams.get("variant");
  const opened = searchParams.get("modal");
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (variant === HomeVariant.ORGANIZATION) {
      searchParams.set("modal", "false");
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, [variant]);

  useEffect(() => {
    const hash = location.hash.substring(1);
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  if (variant === HomeVariant.ORGANIZATION) {
    return (
      <div className={s.container}>
        <CarouselComponent variant={HomeVariant.ORGANIZATION} />
        <Container>
          <ReasonChoose variant={HomeVariant.ORGANIZATION} />
          <LeadingCourse variant={HomeVariant.ORGANIZATION} />
          <StartingEducation variant={HomeVariant.ORGANIZATION} />
        </Container>
        <Integration />
        <Container>
          <FormatEducation variant={HomeVariant.ORGANIZATION} />
          <Stack direction={"column"}>
            <ReadyStudentBlock variant={HomeVariant.ORGANIZATION} />
            <InspirationStory variant={HomeVariant.ORGANIZATION} />
            <Faq variant={HomeVariant.ORGANIZATION} />
          </Stack>
          
        </Container>
        <ConnectionWithUsModal
          open={opened === "true"}
          onClose={() => {
            searchParams.set("modal", "false");
            setSearchParams(searchParams);
          }}
          setModal={setModal}
        />
        <ConnectionHelper
          onClose={() => {
            setModal(false);
          }}
          open={modal}
        />
      </div>
    );
  }
  if (variant === HomeVariant.EDUCATOR) {
    return (
      <div className={s.container}>
        <CarouselComponent variant={HomeVariant.EDUCATOR} />
        <Container>
          <ReasonChoose variant={HomeVariant.EDUCATOR} />
          <LeadingCourse variant={HomeVariant.EDUCATOR} />
          <MeasureEarning />
          <StartingEducation variant={HomeVariant.EDUCATOR} />
          <FormatEducation variant={HomeVariant.EDUCATOR} />
          <Stack direction={"column"}>
            <ReadyStudentBlock variant={HomeVariant.EDUCATOR} />
            <InspirationStory variant={HomeVariant.EDUCATOR} />
            <Faq variant={HomeVariant.EDUCATOR} />
          </Stack>
          
        </Container>
      </div>
    );
  }
  return (
    <div className={s.container}>
      <CarouselComponent variant={HomeVariant.LISTENER} />
      <Container background="#FFFFFF">
        <SearchSuitable />
        <CardList />
        <LeadingCourse variant={HomeVariant.LISTENER} />
        <StartingEducation variant={HomeVariant.LISTENER} />
        <FormatEducation variant={HomeVariant.LISTENER} />
        <Stack direction={"column"}>
          <InspirationStory variant={HomeVariant.LISTENER} />
          <BenefitNewSkill />
        </Stack>
        <TakeCourses />
        <Faq variant={HomeVariant.LISTENER} />
      </Container>
    </div>
  );
};

export default Home;
