import { QuizQuestion } from '@models/quiz-question';
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, useTheme } from '@mui/material';
import { useMemo } from 'react';

interface ITrueOrFalseViewProps {
    data: QuizQuestion;
    index: number
}

const TrueOrFalseView = ({ data }: ITrueOrFalseViewProps) => {
    const { common } = useTheme().palette;

    const radioGroupValue = useMemo(() => {
        return data.answers?.find(x => x.correct)?.id
    }, [data.answers])

    return (
        <FormControl sx={{ width: "100%" }}>
            <RadioGroup value={radioGroupValue} >
                {
                    data.answers?.map((el, i) => (
                        // <Box key={i} sx={{ px: '36px', display: 'flex', alignItems: 'center' }}>
                        //     <FormControlLabel value={el.id} control={<Radio color='success' sx={{ color: common.primaryColor }} />} label="" sx={{ mr: '13px' }} />
                        //     <Typography>
                        //         <div
                        //             dangerouslySetInnerHTML={{
                        //                 __html: el.answer || "",
                        //             }}
                        //         ></div>
                        //     </Typography>
                        // </Box>
                        <Box key={i} sx={{ px: '48px', display: 'flex', alignItems: 'center', width: "100%" }}>
                            <Grid sx={{ width: "100%" }} alignItems="center" container spacing={2}>
                                <Grid item xs={.5}>
                                    <FormControlLabel value={el.id} control={<Radio color='success' sx={{ color: common.primaryColor }} />} label="" sx={{ mr: '13px' }} />
                                </Grid>
                                <Grid item xs={10.75}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: el.answer || "",
                                        }}
                                    ></div>
                                </Grid>
                            </Grid>
                        </Box>
                    ))
                }
            </RadioGroup>
        </FormControl >
    )
}

export default TrueOrFalseView