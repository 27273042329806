import { useEffect, useState } from 'react'
import { Box, Typography, useTheme, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { serviceAero } from '@services/aero';
import { serviceTask } from '@services/task';
import { IBreadcrumb, ITask } from '@interfaces/index';
import CustomButton from '@ui/Button';
import { formatDateWithHourMinute } from '@utils/functions';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import Breadcrumbs from '@components/Breadcrumbs';

export const AeroStudentList = () => {
  const { t } = useTranslation(["user", "common", "enum", "course", "aero"]);
  const { common } = useTheme().palette;
  const [userList, setUserList] = useState<any>(null)
  const { courseId, taskId } = useParams();
  const [questionSum, setQuestionSum] = useState()
  const [taskInfo, setTaskInfo] = useState<ITask>()
  const navigate = useNavigate();
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const orgInfoStyle = {
    width: '15%',
    height: '105px',
    p: '20px 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: common.fontWhite,
    flexDirection: 'column',
    border: `1px solid ${common.borderSecondary}`,
    borderRadius: '8px',
    fontFamily: 'Golos',
    textAlign: 'center',
    flex: 1
  }

  useEffect(() => {
    if (taskId) {
      serviceAero.getGradeList(taskId || "", {
        pageNumber: 0,
        size: 100,
        filter: null
      }).then((res) => {
        if (res.status === 200) {
          setUserList(res.data.dtoList)
        }
      })
      serviceTask.getTaskInfo(taskId).then((res) => {
        if (res.status === 200) {
          setTaskInfo(res.data)
        }
      })
    }
  }, [taskId])

  useEffect(() => {
    if (taskInfo?.quiz?.id) {
      serviceTask.getQuestionSum(taskInfo?.quiz?.id).then((res) => {
        if (res.status === 200) {
          setQuestionSum(res.data)
        }
      })
    }
  }, [taskInfo?.quiz?.id])

  useEffect(() => {
    if (taskInfo?.name) {
      const updatedCrumbs = [...getBreadcrumbs("aero_student")].filter((x: IBreadcrumb) => x.level !== 4);
      updatedCrumbs.push({
        level: 4,
        page: 'aeroStudentList',
        label: taskInfo?.name,
        path: `/settings/aero/course/${courseId}/task/${taskId}`,
        tabNum: null
      });
      // Check if the breadcrumbs have actually changed before updating
      if (JSON.stringify(updatedCrumbs) !== JSON.stringify(getBreadcrumbs("aero_student"))) {
        setBreadcrumbs("aero_student", updatedCrumbs);
      }
    }
  }, [getBreadcrumbs, setBreadcrumbs, t, taskInfo?.name, courseId, taskId]);

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: 3 }}>
        <Breadcrumbs id="aero_student" />
      </Box>
      <Typography sx={{ fontSize: "36px", fontWeight: 600, marginBottom: "30px" }}>{taskInfo?.name}</Typography>
      <Box sx={{ width: '100%', display: 'flex', gap: 1.5, mt: 2 }}>
        <Box sx={orgInfoStyle}>
          <Typography sx={{ fontSize: '14px', color: '#8E8E93' }}>{t('course:task.TYPE_OF_TASK')}</Typography>
          <Typography sx={{ fontSize: '14px', color: '#262626', fontWeight: 600 }}>{t(`enum:${taskInfo?.format}`)}</Typography>
        </Box>
        <Box sx={orgInfoStyle}>
          <Typography sx={{ fontSize: '14px', color: '#8E8E93' }}>{t('course:theme.ACCESS_OPENING_DATE')}</Typography>
          <Typography sx={{ fontSize: '14px', color: '#262626', fontWeight: 600 }}>{taskInfo?.dateOfAccess}</Typography>
        </Box>
        <Box sx={orgInfoStyle}>
          <Typography sx={{ fontSize: '14px', color: '#8E8E93' }}>{t('course:task.DEADLINE_FOR_COMPLETION')}</Typography>
          <Typography sx={{ fontSize: '14px', color: '#262626', fontWeight: 600 }}>{taskInfo?.dateOfDeadline}</Typography>
        </Box>
        <Box sx={orgInfoStyle}>
          <Typography sx={{ fontSize: '14px', color: '#8E8E93' }}>{t('course:test.NUMBER_OF_QUESTIONS_PER_TASK')}</Typography>
          <Typography sx={{ fontSize: '14px', color: '#262626', fontWeight: 600 }}>{questionSum}</Typography>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: common.fontWhite, width: "100%", borderRadius: "12px", p: "32px", marginTop: "20px" }}>
        <TableContainer sx={{ width: "100%" }}>
          <Table>
            <TableHead sx={{ background: common.secondaryColor }}>
              <TableRow>
                <TableCell>{t("aero:NAME_LISTENERS")}</TableCell>
                <TableCell>{t("common:label.STATUS")}</TableCell>
                <TableCell>{t("course:conference.START_TIME")}</TableCell>
                <TableCell>{t("course:conference.END_TIME")}</TableCell>
                <TableCell>{t("aero:NUMBER_CORRECT_ANSWERS")}</TableCell>
                <TableCell>{t("aero:NUMBER_POINTS_SCORED")}</TableCell>
                <TableCell>{t("course:GRADE")}</TableCell>
                <TableCell>{t("aero:TRUST_LEVEL")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList?.map((user, index) => {
                return (
                  <TableRow key={index} hover >
                    <TableCell>{user?.userName}</TableCell>
                    <TableCell>{t(`enum:${user?.status}`)}</TableCell>
                    <TableCell>{formatDateWithHourMinute(user.startDate)}</TableCell>
                    <TableCell>{formatDateWithHourMinute(user.endDate)}</TableCell>
                    <TableCell>{user.numberOfCorrectAnswers}</TableCell>
                    <TableCell>{user.takenPoint}</TableCell>
                    <TableCell>{user.grade}</TableCell>
                    <TableCell>{Math.ceil(user.trustScore)}</TableCell>
                    <TableCell><CustomButton height="35px" onClick={() => navigate(`/settings/aero/course/${courseId}/task/${taskId}/progress/${user.progressId}/user/${user.userId}`)} disabled={!user.trustScore}>
                      <Typography fontSize={"11px"}>
                        {t("aero:DETAILED_REPORT")}
                      </Typography>
                    </CustomButton></TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
