import { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

import List from './List';
import GroupList from './GroupList';
import { CourseContext } from "@context/CourseContext";

const Task = () => {
  const { courseId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation(["course"]);
  const { common } = useTheme().palette;
  const currentTab = searchParams.get('taskTab');
  const [tabValue, setTabValue] = useState<string>("tasks");
  const { courseData } = useContext(CourseContext);

  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);
    setSearchParams({ contentTab: 'tasks', taskTab: newValue });
  };

  useEffect(() => {
    if (currentTab) {
      setTabValue(currentTab);
    } else {
      setTabValue("tasks");
    }
  }, [currentTab])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', gap: 1, mx: 3, mt: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '115px',
            borderRadius: '6px',
            height: '40px',
            p: '12px 16px',
            cursor: 'pointer',
            background: tabValue === 'tasks' ? common.primaryColor : '#F6F4F8',
            color: tabValue === 'tasks' ? '#FFFFFF' : '#686B81',
            '&:hover': {
              background: common.secondaryColor,
              color: common.primaryColor
            }
          }}
          onClick={() => handleTabChange('tasks')}
        >{t("TASKS")}</Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '115px',
            borderRadius: '6px',
            height: '40px',
            p: '12px 16px',
            cursor: 'pointer',
            background: tabValue === 'groupTasks' ? common.primaryColor : '#F6F4F8',
            color: tabValue === 'groupTasks' ? '#FFFFFF' : '#686B81',
            '&:hover': {
              background: common.secondaryColor,
              color: common.primaryColor
            }
          }}
          onClick={() => handleTabChange('groupTasks')}
        >{t("task.TASK_GROUP")}</Box>
      </Box>
      {tabValue === 'tasks' &&
        <List courseId={courseId || ""} courseData={courseData} />
      }
      {tabValue === 'groupTasks' &&
        <GroupList courseId={courseId || ""} courseData={courseData} />
      }
    </Box>
  )
}

export default Task;