import React from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import TextField from '@mui/material/TextField';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useField } from 'formik';
import { enGB, kk, ru } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

type DateTimePickerProps = {
  name: string;
  label?: string;
  type?: 'date' | 'time' | 'dateTime';
  value?: Date | null;
  error?: string;
  touched?: boolean;
  showAdornment?: boolean;
  onChange?: (date: Date | string) => void;
} & Partial<ReactDatePickerProps>;

const localeMap = {
  en: enGB,
  kz: kk,
  ru: ru,
};

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  type = 'dateTime',
  name,
  label,
  value,
  error,
  touched,
  showAdornment = false,
  onChange,
  ...props
}) => {
  const { t, i18n } = useTranslation(["common"])
  const [field, meta] = useField(name);
  const isUsingFormik = !value && !onChange;

  const isError = isUsingFormik
    ? Boolean(meta.touched && meta.error)
    : Boolean(touched && error);

  const handleChange = (date: Date) => {
    if (isUsingFormik) {
      field?.onChange({ target: { name, value: format(date, type === "time" ? "HH:mm" : type === "date" ? "yyyy-MM-dd" : "yyyy-MM-dd'T'HH:mm") } });
    } else {
      onChange && onChange(format(date, type === "time" ? "HH:mm" : type === "date" ? "yyyy-MM-dd" : "yyyy-MM-dd'T'HH:mm"));
    }
  };

  const pickerProps = {
    selected: isUsingFormik ? field.value ? new Date(field.value) : undefined : value ? new Date(value) : undefined,
    onChange: handleChange,
    showTimeSelect: type === 'time' || type === 'dateTime',
    showTimeSelectOnly: type === 'time',
    timeIntervals: 15,
    timeCaption: t("common:TIME"),
    dateFormat: type === 'dateTime' ? 'dd.MM.yyyy HH:mm' : type === 'time' ? 'HH:mm' : 'dd.MM.yyyy',
    locale: localeMap[i18n.language],  // Use the locale map here
    ...props,
  };

  return (
    <DatePicker
      {...pickerProps}
      customInput={
        <TextField
          size="small"
          fullWidth
          label={label}
          InputProps={{
            endAdornment: showAdornment ? <CalendarMonthIcon sx={{ color: "#757575" }} /> : undefined
          }}
          error={isError}
          helperText={isError ? (isUsingFormik ? meta.error : error) : ''}
        />
      }
    />
  );
};

export default DateTimePicker;