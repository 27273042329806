import CoursesNotFound from "@assets/images/freeCourses_notFound.png"
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import RefreshIcon from '@mui/icons-material/Refresh';
import { styled } from '@mui/system';
import { initialFilterTypeCatalogCourse } from "./models";

const CenteredContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '60vh',
  width: '100%',
  textAlign: 'center',
});

export const FreeCoursesNotFound = ({ setFilter }) => {
    const { t } = useTranslation(["freeCourse", "common"]);
    const { common } = useTheme().palette;

    const resetFiltersHandler = () => {
        setFilter(initialFilterTypeCatalogCourse)
    }

    return (
        <CenteredContainer>
            <Box>
                <img  src={CoursesNotFound} alt=""/>
            </Box>
            <Typography variant="h6" component="p" color="textPrimary" gutterBottom>
                { t(`freeCourse:FREE_COURSES_NOT_FOUND_HEAD`) }
            </Typography>
            <Typography variant="body1" component="p" color="textSecondary">
                { t(
                    `freeCourse:FREE_COURSES_NOT_FOUND_BODY`
                ) }
            </Typography>
            <Button onClick={resetFiltersHandler} sx={{marginTop: '16px'}} variant="contained" color="primary">
                { t('freeCourse:RESET_FILTERS') }
            </Button>
     </CenteredContainer>
    )
}
