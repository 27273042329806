export const initialDto: INote = {
    id: null,
    content: "<p></p>\n",
    createdAt: null,
    updatedAt: null,
    courseId: "",
    taskId: null,
    themeId: null,
    memberId: null,
}

export interface INote {
    id: string | null;
    content: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    courseId: string;
    taskId?: string | null;
    taskName?: string | null;
    themeId?: string | null;
    themeName?: string | null;
    memberId: string | null;
}