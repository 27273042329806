import { useEffect, useMemo, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { Form as FormikForm, useFormikContext } from "formik";
import dayjs from "dayjs";

import useSnackbar from "@hooks/useSnackbar";
import { serviceMember } from "@services/member";
import CustomButton from "@ui/Button";
import FormikCheckbox from "@ui/formik/Checkbox";
import DateTimePicker from "@ui/formik/DateTimePicker";
import FormikAutocomplete from "@ui/formik/Autocomplete";
import IPMaskedInput from "@ui/formik/IPMaskedInput";
import { questionRecipientsInitialDto } from "../models";
import { CourseTaskStatusEnum } from "@models/course-task";
import {
  CourseTaskRecipient,
  CourseTaskRecipientTypeEnum,
} from "@models/course-task-recipient";
import {
  AnswerTypes,
  ICourseSetting,
  IGroupListItem,
  IParticipant,
  TaskFormat,
  TypeCode,
} from "@interfaces/index";
import { recipientTypes } from "@utils/enums";
import { useTranslation } from "react-i18next";
import { CourseContext } from "@context/CourseContext";
import FormikInput from "@ui/formik/Input";
import DatePicker from "@ui/formik/DatePicker";
import { formatDateWithTimezone } from "@utils/functions";
import { IStream } from "../../Theme/models";
import { useGetMeetingFindOneQuery } from "@services/meeting";
import { useGetRecipientByIdQuery } from "@services/task";

interface IAddRecipientForms {
  handleOpenModal: () => void;
  isOpen: boolean;
  setInitRecipientsData: (data: CourseTaskRecipient) => void;
  taskStatus: string;
  dateOfAccess?: string;
  settingsData?: ICourseSetting;
  taskType?: string;
  recipientsData?: any;
  classId: string;
  currentRecipient: any;
  meetingPlatform: any;
  answerType: string;
  currentStream: IStream;
  initRecipientsData: any;
}

const AddRecipientForms = ({
  handleOpenModal,
  isOpen,
  setInitRecipientsData,
  taskStatus,
  settingsData,
  dateOfAccess,
  taskType,
  recipientsData,
  classId,
  currentRecipient,
  meetingPlatform,
  answerType,
  currentStream,
  initRecipientsData
}: IAddRecipientForms) => {
  const { common } = useTheme().palette;
  const { values, setValues, setFieldValue, submitForm } =
    useFormikContext<CourseTaskRecipient>();
  const [participants, setParticipants] = useState<IParticipant[]>([]);
  const [groupList, setGroupList] = useState<IGroupListItem[]>([]);
  const { courseId, taskId, taskFormat } = useParams();
  const { t } = useTranslation(["common", "course", "dictionary", "user"]);
  const { MessageComponent, showAlert } = useSnackbar();
  const { courseData } = useContext(CourseContext);

  const { data: OnlineConference } = useGetMeetingFindOneQuery(
    {
      classId: currentStream.id ?? "",
      taskId: taskId ?? "",
    },
    {
      skip: !(answerType === AnswerTypes.ONLINE_CONFERENCE),
    }
  );

  const { data: taskRecipient } = useGetRecipientByIdQuery(currentRecipient?.id ?? "", {
    skip: !(answerType === AnswerTypes.OFFLINE),
  });

  const listeners = useMemo(() => {
    return participants.filter((x) => x.roleType === "LISTENER");
  }, [participants]);

  const isListenerAdded = recipientsData?.some(
    (recipient) => recipient.type === "LISTENER"
  );
  const disabledTypeOption = recipientTypes
    .filter((x) => x.id === CourseTaskRecipientTypeEnum.ALL)
    .map((x) => x.id);

  const isOnlyAddToAllListeners =
    taskStatus === CourseTaskStatusEnum.SAVED &&
    listeners.length === 0 &&
    !currentRecipient?.id;
  const currentDate = formatDateWithTimezone(new Date());

  useEffect(() => {
    if (!values.dateOfDeadline && settingsData?.endDate) {
      setValues({
        ...values,
        dateOfDeadline: new Date(settingsData?.endDate || ""),
      });
    }
  }, [setValues, values, settingsData?.endDate]);


  useEffect(() => {
    serviceMember
      .getNonRecipientListeners(taskId || "", classId)
      .then((res) => {
        if (res.status === 200) {
          setParticipants(res.data);
        }
      });
  }, [taskId, classId]);

  useEffect(() => {
    if (classId) {
      serviceMember.getGroupList(classId).then((res) => {
        if (res.status === 200) {
          setGroupList(res.data);
        }
      });
    }
  }, [classId]);

  useEffect(() => {
    if (isListenerAdded) {
      setValues({
        ...questionRecipientsInitialDto(),
        type: CourseTaskRecipientTypeEnum.LISTENER,
      });
      setInitRecipientsData({
        ...questionRecipientsInitialDto(),
        type: CourseTaskRecipientTypeEnum.LISTENER,
      });
    }
  }, [setValues, setInitRecipientsData, isListenerAdded]);

  const typeOptions = useMemo(() => {
    let options = isOnlyAddToAllListeners
      ? recipientTypes.filter((x) => x.id === CourseTaskRecipientTypeEnum.ALL)
      : recipientTypes;
    return options;
  }, [isOnlyAddToAllListeners]);

  const handleAutocompleteChange = (value: any) => {
    if (value.id === "LISTENER") {
      setFieldValue("group", null);
    } else if (value.id === "GROUP") {
      setFieldValue("members", null);
    } else {
      setFieldValue("members", null);
      setFieldValue("group", null);
    }
  };

  useEffect(() => {
    if (!values.planConference) {
      setFieldValue("conferenceDate", null);
      setFieldValue("startTime", null);
      setFieldValue("endTime", null);
    }
    // eslint-disable-next-line
  }, [values.planConference]);

  const handleSubmit = () => {
    if (
      taskFormat === TaskFormat.ORAL &&
      answerType === AnswerTypes.ONLINE_CONFERENCE &&
      currentDate &&
      values.planConference &&
      new Date(currentDate).getTime() <=
      new Date(currentStream.startDate).getTime()
    ) {
      return showAlert({
        alertColor: "info",
        alertText: t("common:hints.TIME_CONFERENCE_WITHIN_DATE"),
      });
    }

    if (
      !values.type ||
      (values.type === CourseTaskRecipientTypeEnum.LISTENER &&
        !values.members) ||
      (values.type === CourseTaskRecipientTypeEnum.GROUP && !values.group) ||
      (values.hasDeadlineDate && !values.dateOfDeadline)
    ) {
      return showAlert({
        alertColor: "info",
        alertText: t("common:hints.NECESSARY_TO_FILL_MANDATORY_FIELDS"),
      });
    }
    const conferenceDate = new Date(values.conferenceDate || "");
    const startTime = new Date(values.startTime || "");
    const endTime = new Date(values.endTime || "");

    const conferenceStartDate = new Date(
      conferenceDate.getFullYear(),
      conferenceDate.getMonth(),
      conferenceDate.getDate(),
      startTime.getHours(),
      startTime.getMinutes()
    );
    const conferenceEndDate = new Date(
      conferenceDate.getFullYear(),
      conferenceDate.getMonth(),
      conferenceDate.getDate(),
      endTime.getHours(),
      endTime.getMinutes()
    );

    // «Дата конференции» не должна быть раньше даты в поле «Дата открытия доступа» и не позже даты в поле «Выполнить задание до»
    if (
      (dateOfAccess && new Date(dateOfAccess) > conferenceStartDate) ||
      (values.dateOfDeadline &&
        new Date(values.dateOfDeadline) < conferenceEndDate)
    ) {
      return showAlert({
        alertColor: "info",
        alertText: t(
          "common:hints.CONFERENCE_DATE_MUST_BE_WITHIN_DATE_OF_OPENING_ACCESS_AND_DEADLING_FOR_TASK_COMPLETING"
        ),
      });
    }

    if (settingsData) {
      // const currentDate = dayjs(new Date());
      const startDate = dayjs(settingsData?.startDate);
      const endDate = dayjs(settingsData?.endDate);
      const dateOfDeadline = dayjs(values?.dateOfDeadline);
      if (
        values.hasDeadlineDate &&
        (dateOfDeadline.isBefore(startDate) || dateOfDeadline.isAfter(endDate))
      ) {
        return showAlert({
          alertColor: "info",
          alertText: t(
            "common:hints.SPECIFIED_DATE_IS_NOT_INCLUDED_IN_THE_COURSE_PERIOD"
          ),
        });
      }

      if (
        dateOfAccess &&
        values.dateOfDeadline &&
        dayjs(values.dateOfDeadline).isBefore(dateOfAccess)
      ) {
        return showAlert({
          alertColor: "info",
          alertText: t(
            "course:messages.COMPLETION_AND_OPENING_ACCESS_TO_TASK_CHECK"
          ),
        });
      }
      // Если не были отмечены параметры ""Ограничивать доступ к заданию" и "Ограничивать срок выполнения задания", сравнить дату конференции с датами начала и конца потока
      if (
        !values.dateOfDeadline &&
        !dateOfAccess &&
        (conferenceStartDate < new Date(settingsData.startDate || "") ||
          conferenceEndDate > new Date(settingsData?.endDate || ""))
      ) {
        return showAlert({
          alertColor: "info",
          alertText: t(
            "common:hints:CONFERENCE_DATE_IS_NOT_INCLUDED_IN_STREAM_PERIOD"
          ),
        });
      }
    }

    if (dateOfAccess && taskType === "CONTROL") {
      const dateOfAccessValue = dayjs(dateOfAccess);
      const dateOfDeadline = dayjs(values?.dateOfDeadline);
      if (
        values?.dateOfDeadline &&
        dateOfAccessValue.isBefore(dateOfDeadline)
      ) {
        return showAlert({
          alertColor: "info",
          alertText: t(
            "common:hints.SPECIFIED_DATE_CANNOT_BE_LATER_THAN_DATE_OF_TASK"
          ),
        });
      }
    }
    if (values.planConference && (!values.link || !conferenceDate || !startTime || !endTime)) {
      return showAlert({
        alertColor: "info",
        alertText: t(
          "common:hints.NECESSARY_TO_FILL_MANDATORY_FIELDS"
        ),
      });
    }
    if (values.isMeetingPlaceAssigned &&
      (!values.meetingPlace || !values.meetingDate || !values.meetingStartDate || !values.meetingEndDate)) {
      return showAlert({
        alertColor: "info",
        alertText: t(
          "common:hints.NECESSARY_TO_FILL_MANDATORY_FIELDS"
        ),
      });
    }

    if (!values.hasDeadlineDate) values.dateOfDeadline = undefined;
    submitForm();
  };
  useEffect(() => {
    if (OnlineConference) {
      setInitRecipientsData({
        ...values,
        planConference: true,
        platform: OnlineConference.platform.id,
        link: OnlineConference.link,
        login: OnlineConference.login,
        password: OnlineConference.password,
        conferenceDate: OnlineConference.startDate.split("T")[0],
        startTime: OnlineConference.startDate,
        endTime: OnlineConference.endDate,
      });
      setValues((prev) => ({
        ...prev,
        planConference: true,
        platform: OnlineConference.platform.id,
        link: OnlineConference.link,
        login: OnlineConference.login,
        password: OnlineConference.password,
        conferenceDate: OnlineConference.startDate.split("T")[0],
        startTime: OnlineConference.startDate,
        endTime: OnlineConference.endDate,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OnlineConference]);

  useEffect(() => {
    if (taskRecipient) {
      setInitRecipientsData({
        ...values,
        isMeetingPlaceAssigned: true,
        meetingDate: taskRecipient?.meetingStartDate ?? "",
        meetingStartDate: taskRecipient?.meetingStartDate,
        meetingEndDate: taskRecipient?.meetingEndDate,
      });
      setValues((prev) => ({
        ...prev,
        isMeetingPlaceAssigned: true,
        meetingDate: taskRecipient?.meetingStartDate ?? "",
        meetingStartDate: taskRecipient?.meetingStartDate,
        meetingEndDate: taskRecipient?.meetingEndDate,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskRecipient]);

  useEffect(() => {
    if (values.platform === 5) {
      setValues((prev) => ({ ...prev, link: "https://meet.jit.si" }))
    } else {
      setValues((prev) => ({ ...prev, link: "" }))
    }
  }, [values.platform, setValues])

  return (
    <FormikForm>
      <Box sx={{ my: "24px" }}>
        <Box sx={{ mb: "20px" }}>
          <FormikAutocomplete
            label={t("course:task.TO")}
            name="type"
            isRequired
            options={typeOptions}
            onChange={(value: any) => handleAutocompleteChange(value)}
            clearable={false}
            disabledOptions={isListenerAdded ? disabledTypeOption : []}
            disabled={!!currentRecipient?.id}
          />
        </Box>
        {values.type === CourseTaskRecipientTypeEnum.LISTENER && (
          <Box sx={{ mb: "20px" }}>
            <FormikAutocomplete
              label={t("course:task.SEARCH_FOR_LISTENERS_TO_ADD")}
              name="members"
              options={participants}
              isRequired
              valueAsObject
              multiple
              disabled={!!currentRecipient?.id}
            />
          </Box>
        )}

        {values.type === CourseTaskRecipientTypeEnum.GROUP && (
          <Box sx={{ mb: "20px" }}>
            <FormikAutocomplete
              label={t("course:task.SEARCH_FOR_GROUP_OF_LISTENERS_TO_ADD")}
              name="group"
              options={groupList}
              isRequired
              valueAsObject
            />
          </Box>
        )}
        {courseData?.typeCode === TypeCode.EDU && (
          <Box sx={{ ml: "-12px" }}>
            <FormikCheckbox
              name="isRestrictByIp"
              label={t("course:task.RESTRICT_ACCESS_TO_TASK_BY_IP_ADDRESS")}
              labelPlacement="end"
            />
          </Box>
        )}
        {values.isRestrictByIp && (
          <>
            <IPMaskedInput
              name="ipRangeStart"
              label={t("course:task.LOWER_LIMIT_OF_ALLOWED_IP_ADDRESSES")}
            />
            <IPMaskedInput
              name="ipRangeEnd"
              label={t("course:task.UPPER_LIMIT_OF_ALLOWED_IP_ADDRESSES")}
            />
          </>
        )}
        <Box sx={{ ml: "-12px" }}>
          <FormikCheckbox
            name="hasDeadlineDate"
            label={t("course:task.LIMIT_DEADLINE_FOR_COMPLETING_TASK")}
            labelPlacement="end"
          />
        </Box>
        {values.hasDeadlineDate && (
          <DateTimePicker
            label={t("course:task.COMPLETE_TASK_UNTIL")}
            name="dateOfDeadline"
            pickerType="dateTime"
            isRequired
            minDate={dateOfAccess || settingsData?.startDate || ""}
            maxDate={settingsData?.endDate || ""}
          />
        )}
        {taskFormat === TaskFormat.ORAL &&
          answerType === AnswerTypes.ONLINE_CONFERENCE && (
            // !currentRecipient &&
            <>
              <Box sx={{ ml: "-12px" }}>
                <FormikCheckbox
                  name="planConference"
                  label={t("course:task.PLAN_CONFERENCE")}
                  labelPlacement="end"
                />
              </Box>
              {values.planConference && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <FormikAutocomplete
                    name="platform"
                    options={meetingPlatform}
                    label={t("dictionary:PLATFORM")}
                    clearable={false}
                  />
                  <FormikInput label={t("enum:LINK")} name="link" isRequired disabled={values.platform === 5} />
                  <FormikInput label={t("user:LOGIN")} name="login" />
                  <FormikInput label={t("user:PASSWORD")} name="password" />
                  <DateTimePicker
                    label={t("course:conference.CONFERENCE_DATE")}
                    name="conferenceDate"
                    isRequired
                    minDate={settingsData?.startDate || dateOfAccess}
                    maxDate={
                      values.dateOfDeadline || settingsData?.endDate || ""
                    }
                  />
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <DatePicker
                      label={t("course:conference.START_TIME")}
                      name="startTime"
                      pickerType="time"
                      isRequired
                    />
                    <DatePicker
                      label={t("course:conference.END_TIME")}
                      name="endTime"
                      pickerType="time"
                      isRequired
                    />
                  </Box>
                </Box>
              )}
            </>
          )}
        {/*Without backend*/}
        {taskFormat === TaskFormat.ORAL &&
          answerType === AnswerTypes.OFFLINE && (
            <>
              <Box sx={{ ml: "-12px" }}>
                <FormikCheckbox
                  name="isMeetingPlaceAssigned"
                  label={t("course:meeting.ASSIGN_MEETING_PLACE")}
                  labelPlacement="end"
                />
              </Box>
              {values?.isMeetingPlaceAssigned && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <FormikInput
                    label={t("course:meeting.MEETING_PLACE")}
                    name="meetingPlace"
                    isRequired
                    multiline
                    placeholder={t("course:meeting.ENTER_MEETING_PLACE")}
                    minRows={3}
                    maxChar={200}
                    showSymbolCount
                  />
                  <DateTimePicker
                    label={t("course:meeting.DATE_OF_MEETING")}
                    name="meetingDate"
                    isRequired
                    minDate={settingsData?.startDate || dateOfAccess}
                    maxDate={
                      values?.dateOfDeadline || settingsData?.endDate || ""
                    }
                  />
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <DatePicker
                      label={t("course:conference.START_TIME")}
                      name="meetingStartDate"
                      pickerType="time"
                      isRequired
                    />
                    <DatePicker
                      label={t("course:conference.END_TIME")}
                      name="meetingEndDate"
                      pickerType="time"
                      isRequired
                    />
                  </Box>
                </Box>
              )}
            </>
          )}
        <Box
          sx={{ mt: "24px", display: "flex", justifyContent: "space-between" }}
        >
          <CustomButton
            backgroundColor={common.btnCancel}
            fontColor={common.primaryColor}
            width="199px"
            borderRadius="6px"
            height="40px"
            sx={{
              alignItems: "center",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
            }}
            onClick={handleOpenModal}
          >
            {t("common:actions.CANCEL")}
          </CustomButton>
          <CustomButton
            backgroundColor={common.primaryColor}
            fontColor={common.fontWhite}
            width="199px"
            borderRadius="6px"
            height="40px"
            sx={{
              alignItems: "center",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
            }}
            onClick={handleSubmit}
          >
            {t("common:actions.SAVE")}
          </CustomButton>
        </Box>
        <Box sx={{ marginBottom: "5px" }}>{MessageComponent}</Box>
      </Box>
    </FormikForm>
  );
};

export default AddRecipientForms;
