import { TypeConference } from './../../interfaces/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConferenceSchema } from './conferenceSchema';
import { AlertColor } from '@mui/material';


const initialState: ConferenceSchema = {
    messageOpen:false,
    message:"",
    messageType:"success",
    searchName:"",
    classesId:"",
    typeConference:TypeConference.ACTIVE
};

export const conferenceSlice = createSlice({
    name: 'conference',
    initialState,
    reducers: {
        setMessageOpen: (state, action: PayloadAction<boolean>) => {
           state.messageOpen = action.payload;
        },
        setMessage: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
         },
        setMessageType:(state, action: PayloadAction<AlertColor>) => {
            state.messageType = action.payload;
         },
        setSearchName:(state, action: PayloadAction<string>) => {
            state.searchName = action.payload;
        },
        setClassesId:(state, action: PayloadAction<string>) => {
            state.classesId = action.payload;
        },
        setTypeConference:(state, action: PayloadAction<TypeConference>) => {
            state.typeConference = action.payload;
        }
    },
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(, (state) => {
    //             state.error = undefined;
    //             state.isLoading = true;
    //         })
    //         .addCase(, (state) => {
    //             state.isLoading = false;
    //         })
    //         .addCase(, (state, action) => {
    //             state.isLoading = false;
    //             state.error = action.payload;
    //         });
    // },
});

export const { actions: conferenceActions } = conferenceSlice;
export const { reducer: conferenceReducer } = conferenceSlice;