import { AlertColor, Box, Typography, useTheme } from '@mui/material'
import { Formik, Form as FormikForm } from 'formik';
import FormikAutocomplete from '@ui/formik/Autocomplete';
import CustomButton from '@ui/Button';
import { TextEditor } from '@ui/formik/TextEditor';
import { serviceCourse } from '@services/course';
import { useTranslation } from "react-i18next";
import CustomModal from '@components/Modal';

interface Props {
  openTagListenerModal: boolean;
  handleCloseTagListenerModal: () => any;
  markStatus: any;
  setMessage: (message: string) => void;
  setMessageOpen: (open: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  updateLists: () => void;
}

export const TagListenerModal = ({ openTagListenerModal, handleCloseTagListenerModal, markStatus, setMessage, setMessageOpen, setMessageType, updateLists }: Props) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["common"]);
  return (
    <>
      <CustomModal
        open={openTagListenerModal}
        handleToggleModal={handleCloseTagListenerModal}
        width={550}
        minWidth={250}
        disableAutoFocus
      >
        <>
          <Typography fontWeight={600} fontSize="24px">Отметить слушателя</Typography>
          <Formik
            initialValues={{ mark: markStatus?.attendanceStatus, comment: markStatus?.comment || null }}
            enableReinitialize
            validationSchema={null}
            onSubmit={(value) => {
              const requestBody = {
                id: markStatus.id,
                attendanceStatus: value.mark,
                comment: value.comment
              }
              serviceCourse.updateStatusAttendance(requestBody).then((res) => {
                if (res.status === 200) {
                  handleCloseTagListenerModal()
                  setMessageOpen(true);
                  setMessageType("success");
                  setMessage("Успешно");
                  updateLists()
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 3000)
                }
              })
            }}>
            {({ submitForm }) => {
              return (
                <FormikForm>
                  <Box mb="12px" mt="12px">
                    <FormikAutocomplete
                      name="mark"
                      options={[{ id: "PRESENT", name: t('common:statuses.PRESENT') }, { id: "WORKED", name: t('common:statuses.MADE_UP') }, { id: "ABSENT", name: t('common:statuses.ABSENT') }]}
                      onChange={(value: any) => console.log(value)}
                      label='Отметка'
                      clearable={false}
                    />
                  </Box>
                  <Box mb="12px">
                    <TextEditor name="comment" label={"Комментарий"} shortToolbar showScrollEditor />
                  </Box>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <CustomButton
                      backgroundColor={common.btnCancel}
                      fontColor={common.primaryColor}
                      width="200px"
                      borderRadius="6px"
                      height="40px"
                      sx={{
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                      onClick={handleCloseTagListenerModal}
                    >
                      {t('common:actions.CANCEL')}
                    </CustomButton>
                    <CustomButton
                      width="200px"
                      height="40px"
                      backgroundColor={common.primaryColor}
                      borderRadius="8px"
                      fontColor={common.fontWhite}
                      onClick={submitForm}
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        alignItems: "center",
                        color: "#FFFFFF",
                      }}
                    >
                      {t('common:actions.SAVE')}
                    </CustomButton>
                  </Box>
                </FormikForm>
              )
            }}
          </Formik>
        </>
      </CustomModal>
    </>
  )
}
