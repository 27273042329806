import * as yup from 'yup';

export const validationSchema = (t) => yup.object({
  fileId: yup.string().nullable(),
  name: yup.string().required(t('common:validation.MANDATORY_FIELD'))
    .test("len", t('common:validation.MAX_NUMBER_OF_SYMBOLS', { length: 64 }), (val) => {
      if (val === undefined) {
        return true;
      }
      return (val.length < 65)
    })
    .test('name', 'Точка не разрешается', function (name) {
      const fileId = this.parent.fileId;
      if (!fileId) return true;
      return !(name && /[\\.]/.test(name));
    }),
  link: yup.string().when('resourceType', {
    is: 'LINK',
    then: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
    otherwise: yup.string().notRequired()
  })
});