import { useEffect, useState } from 'react'
import { Box, Typography, TextField, Divider, useTheme } from '@mui/material';
import StickyNote2Outlined from '@mui/icons-material/StickyNote2Outlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import Draggable from 'react-draggable';
import { Formik, Form as FormikForm } from "formik";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { convertToRaw } from 'draft-js';

import { NoteEditor } from "@ui/formik/NoteEditor";
import { serviceCourse } from '@services/course';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { INote, initialDto } from '@pages/myCourses/Student/TabList/Notes/models';
import { noteValidationSchema } from '@pages/myCourses/Student/TabList/Notes/validation';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const FloatingNote = ({ open, setOpen }: Props) => {
  const { t } = useTranslation(["common", "course"]);
  const { common } = useTheme().palette;
  const [openListNotes, setOpenListNotes] = useState<boolean>(true);
  const [initialData, setInitialData] = useState<INote>(initialDto);
  const { classId, themeId, taskId } = useParams<{ classId: string, themeId: string, taskId: string }>();
  const [notes, setNotes] = useState<any>([]);
  const [memberId, setMemberId] = useState<string | null>(null);
  const [noteId, setNoteId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [success, setSuccess] = useState<boolean>(false);
  const [clickEvent, setClickEvent] = useState<string>("");

  const filteredNotes = notes.filter((note: any) => note.content.includes(searchQuery));

  const handleSearchChange = (event: any) => { setSearchQuery(event.target.value); };

  useEffect(() => {
    serviceCourse.getNoteList(classId || "").then(res => { if (res.status === 200) setNotes(res.data.reverse()); })

    serviceCourse.getCourseMe(classId || "").then(res => { if (res.status === 200) setMemberId(res.data.id); })
  }, [classId, open]);

  useEffect(() => {
    if (noteId === null && !openListNotes) { setInitialData(initialDto); }
  }, [noteId, openListNotes]);

  const handleDeleteNote = (id: string) => {
    serviceCourse.deleteNote(id).then(res => {
      if (res.status === 200) {
        setNotes(notes.filter((item: any) => item.id !== id));
      }
    })
  }

  useEffect(() => {
    if (clickEvent === "add") {
      success && setOpenListNotes(false);
    }
    else if (clickEvent === "list") {
      success && setOpenListNotes(true);
    } else if (clickEvent === "close") {
      if (success) {
        setOpen(false);
        setNoteId(null);
        setOpenListNotes(true);
      }
    }
    setSuccess(false);
  }, [success, clickEvent, setOpen]);

  return (
    <Box>
      {open &&
        <Draggable handle="#draggable-dialog-title" bounds={{ top: -480 }}>
          <Box
            sx={{
              width: "392px",
              height: "290px",
              background: "#FFF7D1",
              position: "fixed",
              top: "55%",
              right: "85px",
              zIndex: 1,
              borderRadius: '10px',
              borderTop: 'solid 10px #FFF2AB'
            }}
          >
            <Formik
              initialValues={initialData}
              enableReinitialize
              validationSchema={noteValidationSchema}
              onSubmit={(values: any) => {
                if (classId) { values.courseId = classId }
                if (memberId) { values.memberId = memberId }
                if (themeId) { values.themeId = themeId }
                if (taskId) { values.taskId = taskId }
                if (initialData.themeId) {
                  values.themeId = initialData.themeId;
                }
                if (initialData.taskId) {
                  values.taskId = initialData.taskId;
                }
                if (noteId === null) {
                  values.id = null;
                  serviceCourse.createNote(values).then((res) => {
                    if (res.status === 200) {
                      setSuccess(true);
                      setInitialData(initialDto);
                    }
                    setNotes([res.data, ...notes]);
                  }).finally(() => {
                    setNoteId(null);
                    values.content = "<p></p>\n";
                  })
                } else {
                  values.id = noteId;
                  serviceCourse.updateNote(values).then((res) => {
                    if (res.status === 200) {
                      setSuccess(true);
                      setInitialData(initialDto);
                      const index = [...notes].findIndex(
                        (x) => x.id === noteId
                      );
                      setNotes([
                        ...notes.slice(0, index),
                        res.data,
                        ...notes.slice(index + 1),
                      ]);
                    }
                  }).finally(() => {
                    setNoteId(null);
                    values.content = "<p></p>\n";
                  })
                }
              }}
            >
              {({ values, submitForm, touched }) => {
                console.log('+++', values)
                return (
                  <FormikForm>
                    <Box display="flex" justifyContent="space-between" padding="10px" id="draggable-dialog-title" sx={{ cursor: "move" }}>
                      <Box display="flex" gap={2}>
                        {openListNotes && <><StickyNote2Outlined /><Typography>{t('course:note.NOTE')}</Typography></>}
                        <AddCircleOutlineOutlinedIcon sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (!openListNotes) { submitForm(); } else { values.content = "<p></p>\n"; setOpenListNotes(false); }
                            setClickEvent("add");
                          }}
                        />
                      </Box>
                      <Box>
                        {!openListNotes && <MoreHorizOutlinedIcon sx={{ cursor: 'pointer' }} onClick={() => {
                          setClickEvent("list");
                          if (values.content && values.content.length > 8) { submitForm() } else { setSuccess(true); values.content = initialData.content; }
                        }} />}
                        <CloseOutlinedIcon sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setClickEvent("close");
                            if (!openListNotes) { touched && values.content && values.content.length > 8 ? submitForm() : setSuccess(true); }
                            else { setSuccess(true); }
                          }} />
                      </Box>
                    </Box>
                    {!openListNotes &&
                      <Box>
                        <Divider sx={{ mb: '15px' }} />
                        <Box sx={{ px: '6px', mt: "-15px" }}>
                          <NoteEditor name='content' icon />
                        </Box>
                      </Box>
                    }
                  </FormikForm>
                )
              }}
            </Formik>
            {openListNotes &&
              <>
                <Box display="flex" alignItems="center" height="50px" gap={1} padding="10px">
                  <SearchOutlinedIcon />
                  <TextField placeholder={t('common:placeholders.SEARCH')} size="small" variant="standard" fullWidth sx={{ "& .MuiInputBase-input": { borderBottom: "none" } }}
                    value={searchQuery} onChange={handleSearchChange} />
                </Box>
                <Box sx={{ height: "180px", overflowY: 'scroll', paddingLeft: "10px", '&::-webkit-scrollbar': { width: '4px' }, '&::-webkit-scrollbar-thumb': { backgroundColor: '#E5E0EB', borderRadius: '6px' } }}>
                  {filteredNotes.map((note: any) => {
                    const editorState = EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(note.content || "<p></p>\n").contentBlocks));
                    const contentState = editorState.getCurrentContent();
                    const rawContentState = convertToRaw(contentState);
                    const plainText = rawContentState.blocks.map(block => block.text).join('\n');
                    return (
                      <Box key={note.id}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Box sx={{ cursor: 'pointer' }} onClick={() => { setNoteId(note.id); setInitialData(note); setOpenListNotes(false); }}>
                            <Typography>{plainText.length > 37 ? `${plainText.slice(0, 37)}...` : plainText}</Typography>
                            {note.updatedAt && <Typography color="#708099" fontSize="11px">{`${note.updatedAt?.slice(0, 10)} | ${note.updatedAt?.slice(11, 16)}`}</Typography>}
                            {note?.themeId && <Typography color="#708099" fontSize="11px">{t('course:theme.THEME')}: {note?.themeName}</Typography>}
                            {note?.taskId && <Typography color="#708099" fontSize="11px">{t('enum:TASK')}: {note?.taskName}</Typography>}
                          </Box>
                          <DeleteOutlineOutlinedIcon sx={{ color: common.errorColor, cursor: 'pointer' }} onClick={() => { note.id !== null && handleDeleteNote(note.id); }} />
                        </Box>
                        <Divider sx={{ margin: "5px 0" }} />
                      </Box>
                    )
                  })}
                </Box>
              </>
            }
          </Box>
        </Draggable>
      }
    </Box>
  )
}