import React from "react";
import s from "./TakeCourses.module.scss";
import Education from "@assets/images/home/education.png";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TakeCourses = () => {
  const { t } = useTranslation(["home"]);
  const navigate = useNavigate();
  return (
    <div className={s.wrapper}>
      <div className={s.head}>
        <div className={s.title}> {t("home:TAKE_COURSE_FROM_UNIVER")}</div>
        <div className={s.text}>{t("home:GIVE_ACCESS_TO_COURSES_QUALITY")}</div>
        <Box>
          <Button
            sx={{ borderRadius: "4px" }}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => navigate("/freeCatalogCourse")}
          >
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "28px",
              }}
            >
              {t("home:COURSE_FROM_EDU_ORG")}
            </Typography>
          </Button>
        </Box>
      </div>
      <div className={s.content}>
        <img src={Education} alt="" />
      </div>
    </div>
  );
};

export default TakeCourses;
