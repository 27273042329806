import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';

import MathJaxComponent from '@components/MathJaxComponent';
import CustomModal from '@components/Modal';

interface FormulaModalProps {
  open: boolean;
  handleToggleModal: () => void;
}

const PassedNotPassedIOModal = ({ open, handleToggleModal }: FormulaModalProps) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course"]);
  const GZFormula = "`Р_{курса} = П_{з} \\geq Д_{з}`";
  return (
    <CustomModal
      open={open}
      handleToggleModal={handleToggleModal}
      width={515}
      borderColor={common.primaryColor}
    >
      <>
        <Typography>
          {t("course:listener.TOTAL_SCORE_GROUP_TASKS")}:
        </Typography>
        <Box sx={{ background: 'rgba(105, 123, 227, 0.2)', color: "black", border: '1px solid #697BE3', borderRadius: '8px', margin: "0 auto" }}>
          {<MathJaxComponent formula={GZFormula} />}
        </Box>
        <Typography>{t("course:listener.WHERE")}:</Typography>
        <Typography><MathJaxComponent inline formula={"`Р_{курса}`"} /> – результат прохождения курса;</Typography>
        <Typography><MathJaxComponent inline formula={"`П_{з}`"} /> – Количество успешно пройденных заданий (оценка «Пройдено»);</Typography>
        <Typography><MathJaxComponent inline formula={"`Д_{з}`"} /> – Количество заданий, достаточное для успешного прохождения курс»;</Typography>
        <Typography>Если в результа <MathJaxComponent inline formula={"`П_{з}`"} /> больше <MathJaxComponent inline formula={"`Д_{з}`"} />, то присвоить итоговую оценку «Пройдено». В противном случае «Не пройдено».</Typography>
      </>
    </CustomModal>
  )
}

export default PassedNotPassedIOModal