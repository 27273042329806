import { useState, useEffect } from 'react';
import LoadingSpinner from '@components/LoadingSpinner';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowBackdIosIcon from '@mui/icons-material/ArrowBackIos';

import { serviceCourse } from '@services/course';
import { serviceDict } from '@services/dictionary';
import Questions from '@pages/myCourses/Educator/TabList/Content/Task/Quiz/Questions';
import { QuizDraft, initialDto } from "./models";
import { CourseStatus, DictionaryCode } from '@interfaces/index';
import CustomAutocomplete from '@ui/Autocomplete';

const QuizQuestionList = () => {
  const { draftId } = useParams();
  const { t } = useTranslation(["common", "enum", "dictionary"]);
  const navigate = useNavigate();
  const [subdivisionList, setSubdivisionList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [initialData, setInitialData] = useState<QuizDraft>(initialDto);

  useEffect(() => {
    serviceDict.getDictListForDropdown(DictionaryCode.SUBDIVISION).then((res) => {
      if (res.status === 200) setSubdivisionList(res.data);
    })
    serviceDict.getDictListForDropdown(DictionaryCode.LANGUAGE).then((res) => {
      if (res.status === 200) setLanguageList(res.data);
    })
  }, []);

  useEffect(() => {
    if (draftId) {
      serviceCourse.getQuizDraftById(draftId).then(res => {
        if (res.status === 200) {
          setInitialData(res.data);
        }
      })
    }
  }, [draftId]);

  if (!draftId)
    return <LoadingSpinner />

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: 1
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} onClick={() => navigate(-1)}>
        <ArrowBackdIosIcon sx={{ width: '12px', height: '12px' }} />
        <Typography sx={{ cursor: 'pointer' }}>{t("common:actions.GO_BACK")}</Typography>
      </Box>
      <Typography variant='h4' fontWeight={500}>{initialData.name}</Typography>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        border: `1px solid #C4C4C4`,
        borderRadius: "20px",
        p: "16px 24px 24px"
      }}>
        <Box display="flex" gap={1} alignItems="center">
          <CustomAutocomplete
            name="langId"
            value={initialData.langId}
            label={t("dictionary:QUIZ_LANGUAGE")}
            options={languageList}
            sx={{ width: "265px" }}
            customIconDisabled={false}
            disabled
          />
          <CustomAutocomplete
            name="subdivisionIds"
            value={initialData.subdivisionIds}
            label={t("enum:SUBDIVISION")}
            options={subdivisionList}
            multiple
            customIconDisabled={false}
            disabled
          />
        </Box>
        <Questions quizId={draftId} taskStatus={CourseStatus.SAVED} isDraft />
      </Box>
    </Box>
  )
}

export default QuizQuestionList;