import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Carousel from "react-multi-carousel";

import CustomModal from '@components/Modal';
import AvatarPicture from '@components/AvatarPicture';
import FileViewerModal from '@components/modals/FileViewerModal';
import { removeHtml } from '@utils/functions';

import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

interface IProps {
  open: boolean;
  handleToggleModal: (modalState: boolean) => void;
  authorInfo: any;
  isCreator: boolean
}

const AuthorInfoModal = ({ open, handleToggleModal, authorInfo, isCreator }: IProps) => {
  const { t } = useTranslation(["common"]);
  const { common } = useTheme().palette;
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [currentImage, setCurrentImage] = useState<any>();

  const handleToggleImageViewer = (modalState: boolean, imageFile?: any) => {
    if (imageFile) setCurrentImage(imageFile);
    setOpenImageViewer(modalState);
  }

  return (
    <>
      <CustomModal
        open={open}
        handleToggleModal={handleToggleModal}
        showCloseIcon
        width={"870px"}
      >
        <>
          <Box display="flex" justifyContent="space-between" marginBottom="12px">
            <Box display="flex" gap={2} alignItems="center">
              <AvatarPicture userId={authorInfo?.userId} sxProps={{ width: "50px", height: "50px" }} />
              <Box>
                <Typography fontSize="16px" fontWeight={600} marginBottom="6px">{authorInfo.fullName}</Typography>
                {isCreator &&
                  <Box sx={{ width: "fit-content", background: "#385FE8", color: "#fff", fontSize: "12px", padding: "2px 10px", borderRadius: "15px" }}>
                    {t('common:CREATOR')}
                  </Box>
                }
              </Box>
            </Box>
            <Box>
              {(authorInfo.instagram || authorInfo.youtube || authorInfo.facebook) && <Box display="flex" gap={1}>
                {authorInfo.youtube && <YouTubeIcon sx={{ color: "#FF0000", fontSize: "28px", cursor: "pointer" }} />}
                {authorInfo.instagram && <InstagramIcon sx={{ color: "#AE38B4", fontSize: "28px", cursor: "pointer" }} />}
                {authorInfo.facebook && <FacebookIcon sx={{ color: "#337FFF", fontSize: "28px", cursor: "pointer" }} />}
              </Box>}
            </Box>
          </Box>
          <Box margin="20px 0">
            {removeHtml(authorInfo.userInfo || '')}
          </Box>
          {authorInfo.achievements.length > 0 &&
            <Carousel 
              responsive={responsive}
            >
              {authorInfo.achievements.map((achievement: any) => {
                const isPDF = achievement.name.endsWith('.pdf');

                return (
                  <Box
                    key={achievement.fileId}
                    sx={{ p: '12px 8px' }}
                    onClick={() => handleToggleImageViewer(true, achievement)}
                  >
                    {isPDF
                      ? <Box
                        sx={{
                          display: 'grid',
                          placeContent: 'center',
                          borderRadius: "8px",
                          width: '130px',
                          height: '130px',
                          cursor: "pointer",
                          background: '#A9B6FF33'
                        }}>
                        <PictureAsPdfIcon sx={{ width: '50px', height: '50px', color: common.primaryColor }} />
                      </Box>
                      : <AvatarPicture
                        id={achievement.fileId}
                        sxProps={{
                          border: "1px solid #D9D9D9",
                          borderRadius: "8px",
                          width: '130px',
                          height: '130px',
                          cursor: "pointer"
                        }}
                      />}
                  </Box>
                )
              })}
            </Carousel>
          }
        </>
      </CustomModal>
      {openImageViewer &&
        <FileViewerModal
          openModal={openImageViewer}
          handleToggleModal={handleToggleImageViewer}
          file={currentImage}
        />
      }
    </>
  )
}

export default AuthorInfoModal;
