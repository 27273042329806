import VideoPlayer from "@components/VideoPlayer";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import Fade from "@mui/material/Fade";
import { downloadFile } from "@utils/functions";
import { t } from "i18next";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
interface Props {
  name: string;
  src: string;
}
const VideoItem = (props: Props) => {
  const { common } = useTheme().palette;
  const { name, src } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openVideoActions = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  return (
    <Box sx={{wordBreak: 'break-word'}}>
      <VideoPlayer height={170} videoFileId={src} />
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography variant="body1">{name}</Typography>
        <Box
          onClick={(e) => openVideoActions(e)}
          sx={{ cursor: "pointer" }}
        >
          <MoreVertIcon />
        </Box>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          disableScrollLock
        >
          <MenuItem
            onClick={() => {
              downloadFile(src);
              handleClose();
            }}
            sx={{
              "&:hover": {
                backgroundColor: common.primaryColor,
                color: common.fontWhite,
              },
            }}
          >
            {t("common:actions.DOWNLOAD")}
          </MenuItem>
          {/* <MenuItem
            onClick={deleteVideo}
            sx={{
              "&:hover": {
                backgroundColor: common.primaryColor,
                color: common.fontWhite,
              },
            }}
          >
            {t("common:actions.DELETE")}
          </MenuItem> */}
        </Menu>
      </Box>
    </Box>
  );
};

export { VideoItem };
