import React, { useEffect } from 'react'
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import EventNoteIcon from '@mui/icons-material/EventNote';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RootState } from '@store/index';
import { serviceCourse } from '@services/course';
import emptyMeeting from "@assets/images/emptyMeeting.png";
import { ImportantEventsConferenceCard } from './ImportantEventsConferenceCard';
import { useSelector } from 'react-redux';
import { StateSchema } from '@store/StateSchema';

export const ImportantEvents = () => {
  const { t } = useTranslation(["course"])
  const { courseId } = useParams<{ courseId: string }>();
  const [importantEvent, setImportantEvent] = React.useState([]);
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { common } = useTheme().palette;
  const profile = useSelector((store: StateSchema) => store.account.profileInfo);
  const { role } = profile.authorityInfo;

  useEffect(() => {
    if (role !== "ADMIN" && role !== "LOCAL_ADMIN") {
      serviceCourse.getImportantEvents(courseId || "").then((res) => {
        if (res.status === 200) setImportantEvent(res.data);
      });
    }
  }, [courseId, role]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: "20px",
        border: `1px solid ${common.borderSecondary}`,
        borderRadius: '12px'
      }}
    >
      <Box display="flex" padding="10px" sx={{ background: "#fff", borderRadius: '12px' }} alignItems="center" gap={1}>
        <Box width="3px" height="30px" borderRadius="2px" sx={{ background: "#FFA61A" }}></Box>
        <EventNoteIcon fontSize="medium" />
        <Typography fontWeight={500} fontSize="24px">{t("course:listener.IMPORTANT_EVENTS")}</Typography>
      </Box>

      <Box
        sx={{
          p: "10px",
          background: "#fff",
          overflowY: 'scroll',
          '&::-webkit-scrollbar': { width: '7px' },
          '&::-webkit-scrollbar-thumb': { backgroundColor: '#E5E0EB', borderRadius: '6px' },
          borderRadius: "0 0 12px 12px"
        }}
      >
        {importantEvent.length === 0 && <Box
          margin="0 auto"
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          width={{ sm: "500px", xs: '250px' }} paddingTop="50px">
          <img src={emptyMeeting} style={{ width: matchesDownSm ? '300px' : '550px' }} alt="emptyMeeting" />
          <Typography>{t('course:messages.NO_EVENTS_YET')}</Typography>
        </Box>}
        {importantEvent.map((event: any) => (
          <Box key={event.id}>
            <ImportantEventsConferenceCard conferenceId={event.id} status={event.status} />
          </Box>
        ))}
      </Box>
    </Box >
  )
}