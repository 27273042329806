import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import Clear from "@mui/icons-material/Clear";
import { Box, FormControl, IconButton } from '@mui/material';
import { TextEditor } from '@ui/formik/TextEditor';
import { FieldArrayRenderProps } from "formik";
import { useTranslation } from "react-i18next";


interface IMappingProps {
    arrayHelpers: FieldArrayRenderProps,
    index: number;
    quizIndex: number;
}

export const Mapping = ({ index, arrayHelpers, quizIndex }: IMappingProps) => {
    const { t } = useTranslation(["course"]);

    const deleteAnswer = () => {
        return new Promise((resolve, reject) => {
            arrayHelpers.remove(index)
            return resolve({})
        })
    }

    const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
        deleteFunc: deleteAnswer,
        modalTitle: t('course:messages.ARE_YOU_SURE_TO_DELETE_ANSWER_OPTION')
    })

    return (
        <Box sx={{ mb: '24px', width: '70%', display: 'flex', alignItems: 'center' }}>
            {DeleteModal}
            <Box sx={{ mr: '32px' }}>
                <TextEditor name={`data.${quizIndex}.children.${index}.content`} label='' />
            </Box>
            <FormControl>
                <TextEditor name={`data.${quizIndex}.children.${index}.answers.0.answer`} label='' />
            </FormControl>
            <IconButton onClick={deleteAnswer} >
                <Clear sx={{ width: '25px', height: '25px', ml: '15px' }} />
            </IconButton>
        </Box>
    )
}