import Stack from '@mui/material/Stack';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useAppDispatch } from '@store/hooks';
import { conferenceActions } from '@store/conference/conferenceSlice';

interface IAlert extends AlertProps {
  customstyles: {
    background: string;
    color: string;
  }
}

const Alert = styled(MuiAlert)<IAlert>(({ customstyles }) => ({
  background: customstyles.background,
  color: customstyles.color
}))

interface Props {
  type: AlertColor;
  message: string;
  setMessageOpen?: any;
  actions?: any
  messageAction?: any
};

const Message = (props: Props) => {
  const { type, message, setMessageOpen, actions, messageAction } = props;
  const dispatch = useAppDispatch();
  const CUSTOM_COLORS_MAPPING = {
    success: {
      background: "#00B998",
      color: "#FFFFFF",
      borderLeftColor: "#FFFFFF85"
    },
    info: {
      background: "#9469E3",
      color: "#FFFFFF",
      borderLeftColor: "#FFFFFF85"
    },
    error: {
      background: "#D51A52",
      color: "#FFFFFF",
      borderLeftColor: "#FFFFFF85"
    },
    warning: {
      background: "#FF9500",
      color: "#FFFFFF",
      borderLeftColor: "#FFFFFF85"
    },
  }

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Alert
        onClose={() => {
          actions && dispatch(actions);
          setMessageOpen && setMessageOpen(false);
        }}
        severity={type}
        customstyles={CUSTOM_COLORS_MAPPING[type]}
        sx={{
          mt: 1,
          width: "100%",
          borderRadius: "4px",
          borderLeft: `4px solid ${CUSTOM_COLORS_MAPPING[type].borderLeftColor}`,
          "& .MuiAlert-icon": {
            color: `${CUSTOM_COLORS_MAPPING[type].borderLeftColor}`
          }
        }}
        action={messageAction}
      >
        {message}
      </Alert>

    </Stack>
  );
}

export default Message;