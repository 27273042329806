import { useState, useEffect, Fragment, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
// import Checkbox from '@mui/material/Checkbox';
import Collapse from "@mui/material/Collapse";
import { useTheme } from "@mui/material/styles";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// import ImportExportIcon from '@mui/icons-material/ImportExport';
import LoginIcon from "@mui/icons-material/Login";
// import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { CourseContext, useCourseContext } from "@context/CourseContext";
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";

import { serviceTask } from "@services/task";
import CustomAutocomplete from "@ui/Autocomplete";
import CustomButton from "@ui/Button";
import {
  decodeContentDispositionFilename,
  formatDateWithHourMinute,
  getOnlyDateAndFormat,
} from "@utils/functions";
import { taskStatusBgColor } from "@utils/enums";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { AnswerTypes, CourseRole, ITask, IUserSearchItem, TaskFormat, TaskStatus } from "@interfaces/index";
import { AlertColor } from "@mui/material";
import Message from "@components/Message";
import { serviceMember } from "@services/member";
import { serviceAero } from "@services/aero";
import { useSelector } from "react-redux";

const taskStatusOptions = [
  {
    id: "ASSIGNED",
    text: "ASSIGNED",
  },
  {
    id: "UNDER_REVIEW",
    text: "UNDER_REVIEW",
  },
  {
    id: "ON_CORRECTION",
    text: "ON_CORRECTION",
  },
  {
    id: "COMPLETED",
    text: "COMPLETED",
  },
  {
    id: "NOT_COMPLETED_ON_TIME",
    text: "NOT_COMPLETED_ON_TIME",
  },
];

const RecipientList = ({ markable, excluded }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["course", "common", "enum", "user"]);
  const { common } = useTheme().palette;
  const { courseId, taskId, flowId } = useParams<{
    courseId: string;
    taskId: string;
    flowId: string;
  }>();
  const [recipientList, setRecipientList] = useState<any[]>();
  const [currentRecipientId, setCurrentRecipientId] = useState<string>("");
  const [filter, setFilter] = useState<{ name?: string; status?: string }>({});
  const [expanded, setExpanded] = useState<boolean>(false);
  const [taskInfo, setTaskInfo] = useState<ITask>();
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [aeroScore, setAeroScore] = useState<any>(null);
  const { courseData } = useCourseContext();

  const searchByName = (searchValue: string) => {
    const new_filter = { ...filter, name: searchValue };
    setFilter(new_filter);
  };

  const searchByStatus = (value: any) => {
    setFilter({ ...filter, status: value });
  };

  const handleExpandCard = (recipientId: string) => {
    setExpanded(!expanded);
    setCurrentRecipientId(recipientId);
  };

  const goToTaskAssessmentPage = (recipientId: string, progressId: string) => {
    navigate(
      `/myCourses/${courseId}/task-assessment/assessment/${recipientId}/${progressId}/flows/${flowId}`
    );
  };

  const downloadReplies = () => {
    if (taskId && flowId) {
      serviceTask.downloadTaskReplies(flowId, taskId).then((res) => {
        if (res.status === 200) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${decodeContentDispositionFilename(
              res.headers["content-disposition"]
            )}`
          );
          document.body.appendChild(link);
          link.click();
        }
      }).catch((err) => {
        if (err.response.data.code === "NO_ATTACHED_FILES") {
          setMessageType("error")
          setMessage(t("course:messages.NO_ATTACHED_FILES"))
          setMessageOpen(true)
        }
      }
      )
    }
  };

  const renderGroupsProgresses = (recipient) => {
    if (recipient.id === currentRecipientId) {
      return recipient.progresses.map((progress) => (
        <ListenerRow
          key={progress.id}
          recipientId={recipient.id}
          recipientType={recipient.type}
          progress={progress}
          markable={markable}
          goToTaskAssessmentPage={goToTaskAssessmentPage}
          taskInfo={taskInfo}
          setMessage={setMessage}
          setMessageOpen={setMessageOpen}
          setMessageType={setMessageType}
          setRecipientList={setRecipientList}
          recipientList={recipientList}
        />
      ));
    }
    return null;
  };

  useEffect(() => {
    if (taskId) {
      serviceTask
        .getTaskRecipientsWithFilter({
          taskId,
          name: filter.name,
          status: filter.status,
          classId: flowId,
        })
        .then((res) => {
          if (res.status === 200) {
            setRecipientList(res.data);
          }
        });
    }
  }, [taskId, filter.name, filter.status, flowId]);

  useEffect(() => {
    if (taskId) {
      serviceTask.getTaskInfo(taskId).then((res) => {
        setTaskInfo(res.data);
      });
    }
  }, [taskId]);

  useEffect(() => {
    if (taskInfo?.quiz?.applyProctoring && taskId) {
      serviceAero
        .getGradeList(taskId, {
          pageNumber: 0,
          size: 100,
          filter: null,
        })
        .then((res) => {
          if (res.status === 200) {
            setAeroScore(res.data.dtoList);
          }
        });
    }
  }, [taskInfo?.quiz?.applyProctoring, taskId]);

  return (
    <>
      {messageOpen && (
        <Box sx={{ mb: "12px", mt: "-12px" }}>
          <Message
            type={messageType}
            message={message}
            setMessageOpen={setMessageOpen}
          />
        </Box>
      )}
      <Box
        sx={{
          background: "#fff",
          padding: "15px",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          <TextField
            label={t("common:placeholders.SEARCH_BY_FULL_NAME")}
            color="primary"
            size="small"
            name="searchValue"
            onChange={(e) => searchByName(e.target.value)}
            sx={{
              width: "100%",
              "& label.Mui-focused": { color: common.primaryColor },
              "& .MuiInput-underline:after": {
                borderBottomColor: common.primaryColor,
              },
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": { borderColor: common.primaryColor },
                "&.Mui-focused fieldset": { borderColor: common.primaryColor },
              },
            }}
          />
          <CustomAutocomplete
            name="status"
            placeholder={t("user:STATUS")}
            options={taskStatusOptions}
            onChange={(_: any, taskStatus: any) => searchByStatus(taskStatus)}
            sx={{
              width: { xs: "100%", sm: "35%" },
            }}
          />
        </Box>
        {(taskInfo?.format === TaskFormat.WRITTEN || (taskInfo?.format === TaskFormat.ORAL && taskInfo.answerType !== AnswerTypes.ONLINE_CONFERENCE)) &&
          <CustomButton
            width="fit-content"
            backgroundColor={common.secondaryColor}
            borderRadius="6px"
            onClick={() => downloadReplies()}
          >
            <Typography
              sx={{
                color: common.primaryColor,
                display: "flex",
                alignItems: "center",
              }}
            >
              <FileDownloadOutlinedIcon sx={{ mr: "12px" }} />
              {t("common:actions.DOWNLOAD_ANSWERS")}
            </Typography>
          </CustomButton>}
        <Box>
          <Grid
            container
            columnSpacing={1}
            sx={{
              margin: "0 !important",
              width: "100% !important",
              border: "1px solid lightgray",
              py: 1,
              alignItems: "center",
            }}
          >
            {/* <Grid item xs={1}>
            <Checkbox
              name="all"
              // checked={filter.isNoMark}
              // onChange={(e) => onChangeCheckbox(e)}
              sx={{ color: common.primaryColor, "&, &.Mui-checked": { color: common.primaryColor } }}
            />
          </Grid> */}
            <Grid item xs={3} sx={{ display: "flex" }}>
              {t("user:FULL_NAME")}
              {/* <ImportExportIcon sx={{ ml: 1, cursor: 'pointer' }} /> */}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {t("common:actions.VIEW_ANSWERS")}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {t("user:STATUS")}
            </Grid>
            {markable ? (
              <Grid
                item
                xs={1.5}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {t("GRADE")}
                {excluded && (
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          background: "#1A1F36",
                          display: "flex",
                          borderRadius: "8px",
                          width: "254px",
                        },
                      },
                    }}
                    title={t(
                      "course:task.TASK_IS_NOT_CONSIDERING_IN_FINAL_GRADE"
                    )}
                    placement="top"
                  >
                    <ErrorOutlineOutlinedIcon
                      sx={{ color: "#FFA216", cursor: "pointer", ml: 1 }}
                    />
                  </Tooltip>
                )}
              </Grid>
            ) : (
              <Grid item xs={1.5} />
            )}
            <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
              {t("course:task.DEADLINE_FOR_COMPLETION")}
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          {recipientList &&
            recipientList.map((recipient: any) => {
              if (recipient.type === "GROUP")
                return (
                  <Fragment key={recipient.id}>
                    <Grid
                      key={recipient.id}
                      container
                      columnSpacing={1}
                      sx={{
                        margin: "0 !important",
                        width: "100% !important",
                        borderLeft: "1px solid lightgray",
                        borderRight: "1px solid lightgray",
                        borderBottom: "1px solid lightgray",
                        py: 1,
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => handleExpandCard(recipient.id)}
                    >
                      <Grid item xs={6}>
                        {recipient?.group?.name || t(`${recipient.type}`)}
                      </Grid>
                      <Grid item xs={6}>
                        <KeyboardArrowDownIcon
                          sx={{ display: "flex", ml: "auto", mr: 2 }}
                        />
                      </Grid>
                    </Grid>
                    <Collapse
                      in={expanded && recipient.id === currentRecipientId}
                      timeout="auto"
                      unmountOnExit
                      sx={{ width: "100%" }}
                    >
                      {renderGroupsProgresses(recipient)}
                    </Collapse>
                  </Fragment>
                );

              return (
                <Fragment key={recipient.id}>
                  {recipient.progresses.map((progress: any) => {
                    return (
                      <ListenerRow
                        key={progress.id}
                        recipientId={recipient.id}
                        recipientType={recipient.type}
                        progress={progress}
                        markable={markable}
                        goToTaskAssessmentPage={goToTaskAssessmentPage}
                        taskInfo={taskInfo}
                        setMessage={setMessage}
                        setMessageOpen={setMessageOpen}
                        setMessageType={setMessageType}
                        setRecipientList={setRecipientList}
                        recipientList={recipientList}
                      />
                    );
                  })}
                </Fragment>
              );
            })}
        </Box>
      </Box>
    </>
  );
};

const ListenerRow = ({
  recipientId,
  recipientType,
  progress,
  markable,
  goToTaskAssessmentPage,
  taskInfo,
  setMessage,
  setMessageOpen,
  setMessageType,
  setRecipientList,
  recipientList,
}) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["enum"]);
  const { courseData } = useContext(CourseContext);
  const { flowId } = useParams()
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const profile = useSelector((store: any) => store.account.profileInfo);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [responsibleMemberList, setResponsibleMemberList] = useState<
    IUserSearchItem[]
  >([]);

  const deleteRecipient = (id: string) => {
    return serviceTask.deleteTaskProgress(id).then((res) => {
      if (res.status === 200) {
        setRecipientList((recipientList) => {
          if (recipientType === "LISTENER") {
            return recipientList.filter(
              (recipient) => recipient.id !== recipientId
            );
          } else {
            return recipientList.map((recipient) => ({
              ...recipient,
              progresses: recipient.progresses.filter(
                (progress) => progress.id !== id
              ),
            }));
          }
        });
        setMessage(
          isActive
            ? t("common:messages.ACTIVE_LISTENER_DELETED")
            : t("common:messages.INACTIVE_LISTENER_DELETED")
        );
        setMessageOpen(true);
        setMessageType("success");
        setTimeout(() => {
          setMessageOpen(false);
        }, 10000);
      }
    });
  };
  useEffect(() => {
    serviceMember.getCourseMember(progress.member.id).then((res) => {
      setIsActive(res.data.isActive);
    });
  }, [progress.member.id]);

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteRecipient,
    modalTitle: isActive
      ? t("course:messages.ARE_YOU_SURE_TO_DELETE_PARTICIPANT")
      : t("course:messages.ARE_YOU_SURE_TO_DELETE_INACTIVE_PARTICIPANT"),
  });
  useEffect(() => {
    if (flowId) {
      serviceMember
        .getMemberList(flowId, [
          CourseRole.TUTOR,
          CourseRole.ASSISTANT,
          CourseRole.EXAMINER,
        ])
        .then((res) => {
          if (res.status === 200) {
            setResponsibleMemberList(res.data);
          }
        });
    }
  }, [flowId]);

  return (
    <Grid
      container
      columnSpacing={1}
      sx={{
        margin: "0 !important",
        width: "100% !important",
        borderLeft: "1px solid lightgray",
        borderRight: "1px solid lightgray",
        borderBottom: "1px solid lightgray",
        py: 1,
        alignItems: "center",
      }}
    >
      {DeleteModal}
      <Grid
        item
        xs={3}
        sx={{
          display: "flex",
          pl: recipientType !== "GROUP" ? "8px !important" : "24px !important",
        }}
      >
        {progress.member.userFullName}
      </Grid>
      <Grid item xs={2} sx={{ display: "flex", justifyContent: "center" }}>
        <CustomButton
          width="fit-content"
          backgroundColor={common.primaryColor}
          borderRadius="6px"
          disabled={!(isAuthor || responsibleMemberList.find((member) => member.userId === profile.pltaId))}
          onClick={() => goToTaskAssessmentPage(recipientId, progress.id)}
        >
          <LoginIcon sx={{ color: "#FFFFFF" }} />
        </CustomButton>
      </Grid>
      <Grid item xs={2}>
        <Typography
          sx={{
            color: "#FFFFFF",
            backgroundColor: taskStatusBgColor[progress.status],
            px: 3,
            py: 1,
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            lineHeight: "14px",
            borderRadius: "12px",
            width: "100%",
          }}
        >
          {t(`enum:${progress.status}`)}
        </Typography>
      </Grid>
      {markable ? (
        <Grid item xs={1.5} sx={{ display: "flex", justifyContent: "center" }}>
          {progress.point !== undefined && progress !== null
            ? progress.point
            : "-"}
        </Grid>
      ) : (
        <Grid item xs={1.5} />
      )}
      <Grid item xs={2.5} sx={{ display: "flex", alignItems: "center" }}>
        {formatDateWithHourMinute(progress.dateOfDeadline)}
      </Grid>
      {(taskInfo?.isNoMark || taskInfo?.isExcludeTotalMark) &&
        progress.status === TaskStatus.ASSIGNED &&
        recipientList &&
        recipientList?.[0].type !== "ALL" && (
          <Grid item xs={1}>
            <DeleteOutlineIcon
              sx={{ cursor: "pointer", color: common.errorColor }}
              onClick={() => openDeleteConfirmModal(progress.id)}
            />
          </Grid>
        )}
    </Grid>
  );
};

export default RecipientList;
