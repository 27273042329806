import { ICourse, IStudyClass } from "@interfaces/index";
import {
  serviceCourse,
  useGetAllStudyClassesByCourseIdQuery,
} from "@services/course";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { courseInitialDto, courseSettingInitialDto } from "../models";

interface FlowInfoContextValue {
  flowsInfo: IStudyClass[];
  activeStudyClass: number;
  setActiveStudyClass: Dispatch<SetStateAction<number>>;
  flowItem: IStudyClass;
}

const FlowInfoContext = createContext({} as FlowInfoContextValue);

const FlowInfoProvider = ({ children }: { children: ReactNode }) => {
  const { courseId } = useParams<{ courseId: string }>();
  const [activeStudyClass, setActiveStudyClass] = useState<number>(0);
  const { data: studyClasses } = useGetAllStudyClassesByCourseIdQuery(
    courseId || ""
  );
  if (!studyClasses) {
    return null;
  }
  return (
    <FlowInfoContext.Provider
      value={{
        flowsInfo: studyClasses,
        setActiveStudyClass,
        activeStudyClass,
        flowItem: studyClasses[activeStudyClass],
      }}
    >
      {children}
    </FlowInfoContext.Provider>
  );
};

function useFlowInfoContext(): FlowInfoContextValue {
  return useContext(FlowInfoContext);
}

export { useFlowInfoContext, FlowInfoProvider };
