import React, { useState, useEffect } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme, FormControlLabel, AlertColor, Checkbox, IconButton } from '@mui/material'
import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import CustomButton from '@ui/Button';
import FormikSelect from "@ui/formik/Select";
import Message from '@components/Message';
import { serviceIntegration } from '@services/integrations';
import { SuperAdminIntegrationProps } from '../models';
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add'; import AeroIntegrationModal from './AeroIntegrationModal';
import { AddAntiPlagiarismAttempts } from './AddAntiPlagiarismAttempts';
import { IntagrationCode } from '@interfaces/index';
;

interface IMessage {
  open: boolean;
  text: string;
  type: AlertColor
}
const AddIntegration = () => {
  const { t } = useTranslation(["dictionary", "sideMenu", "enum", "course", "common"]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { common } = useTheme().palette;
  const [initialData, setInitialData] = useState<SuperAdminIntegrationProps | null>(null)
  const [checked, setChecked] = useState<boolean[]>([])
  const [message, setMessage] = useState<IMessage>({ open: false, text: '', type: 'success' })
  const [orgDataUsedIntegrations, setOrgDataUsedIntegrations] = useState<number[]>([])
  const [showFullText, setShowFullText] = useState<boolean[]>();
  const [openAeroModal, setOpenAeroModal] = useState<boolean>(false)
  const [openAntiPlagiarismModal, setOpenAntiPlagiarismModal] = useState<boolean>(false)
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { authorityInfo: { role } } = profile;

  useEffect(() => {
    serviceIntegration.getIntegrationByOrgId(Number(id)).then(res => {
      setOrgDataUsedIntegrations(res.data.map(organization => organization.integration.id))
    })
    serviceIntegration.getAllIntegrations().then(res => {
      setInitialData({ integrations: res.data })
      setChecked(res.data.filter(integration => integration.isActive === true).map(_ => false))
      setShowFullText(res.data?.map(_ => false))
    })
  }, [id])

  const centerCellStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const tableHeadCellStyle = {
    fontWeight: 500,
    fontSize: '14px',
    border: `1px solid ${common.borderSecondary}`
  }

  const handleAddIntegration = (value) => {
    const selectedIntegration = [{
      orgId: Number(id),
      isActive: false,
      integration: value
    }]

    serviceIntegration.updateIntegrationByOrgId(selectedIntegration).then((res) => {
      if (res.status === 200) {
        setMessage({ open: true, text: t("enum:SERVICE_ADDED"), type: 'success' })
        setTimeout(() => {
          setMessage({ ...message, open: false })
          navigate(`/dict/organization/${id}/integrations`)
        }, 3000)
      }
    }).catch((e) => {
      setMessage({ open: true, text: t("common:messages.ERROR"), type: 'error' })
      setTimeout(() => {
        setMessage({ ...message, open: false })
      }, 3000);
    })
  }

  const integrationType = ["LOCAL", "COMMON"];

  return (
    <Box>
      {role === 'SUPER_ADMIN' &&
        <Box sx={{ display: "flex", alignItems: 'center', mb: '24px' }}>
          <Typography sx={{ fontSize: 16, mr: '9px', cursor: 'pointer' }} onClick={() => { navigate('/dict') }} >
            {t("DICTIONARIES")}
          </Typography>
          <ArrowForwardIosIcon sx={{ width: '10px', height: '10px', mr: '9px', mt: '1.5px' }} />
          <Typography sx={{ fontSize: 16, mr: '9px', cursor: 'pointer' }} onClick={() => navigate('/dict/organizations')}>
            {t("ORGANIZATIONS")}
          </Typography>
          <ArrowForwardIosIcon sx={{ width: '10px', height: '10px', mr: '9px', mt: '1.5px  ' }} />
          <Typography sx={{ fontSize: 16, mr: '9px', cursor: 'pointer' }} onClick={() => navigate(`/dict/organization/${id}?tab=additionalSettings`)}>
            {t("EDIT_ORGANIZATION")}
          </Typography>
          <ArrowForwardIosIcon sx={{ width: '10px', height: '10px', mr: '9px', mt: '1.5px  ' }} />
          <Typography sx={{ fontSize: 16, mr: '9px', cursor: 'pointer' }} onClick={() => navigate(`/dict/organization/${id}/integrations`)}>
            {t("sideMenu:INTEGRATIONS")}
          </Typography>
          <ArrowForwardIosIcon sx={{ width: '10px', height: '10px', mr: '9px', mt: '1.5px  ' }} />
          <Typography>
            {t("enum:ADD_INTEGRATION")}
          </Typography>
        </Box>}
      {role === 'LOCAL_ADMIN' &&
        <Box sx={{ display: "flex", alignItems: 'center', mb: '24px' }}>
          <RouterLink to={`/settings`} style={{ textDecoration: "none" }}>
            <Typography>
              {t('sideMenu:SETTINGS')}
            </Typography>
          </RouterLink>
          <ArrowForwardIosIcon sx={{ width: '10px', height: '10px', mr: '9px', mt: '1.5px' }} />
          <RouterLink to={`/settings/integrations/${id}`} style={{ textDecoration: "none" }}>
            <Typography>
              {t("sideMenu:INTEGRATIONS")}
            </Typography>
          </RouterLink>
          <ArrowForwardIosIcon sx={{ width: '10px', height: '10px', mr: '9px', mt: '1.5px' }} />
          <Typography>
            {t("enum:ADD_INTEGRATION")}
          </Typography>
        </Box>
      }
      <Typography sx={{ fontSize: '36px', fontWeight: 500, mb: '24px' }}>
        {t("enum:ADD_INTEGRATION")}
      </Typography>
      {
        message.open &&
        <Box sx={{ mb: 2 }}>
          <Message type={message.type} message={message.text} setMessageOpen={message.open} />
        </Box>
      }
      <Box sx={{
        display: 'flex', width: '100%', border: `1px solid ${common.borderSecondary}`, borderRadius: '9px',
        backgroundColor: 'white', p: '32px', minHeight: '600px'
      }}>
        <Formik
          initialValues={initialData || { integrations: [] }}
          enableReinitialize
          validationSchema={null}
          onSubmit={(values) => { }}
        >
          {({ values, submitForm }) => {
            return <FieldArray
              name='integrations'
              render={() => (
                <FormikForm style={{ display: 'grid', width: '100%' }}>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: '5%', ...tableHeadCellStyle }}>
                            {t('common:actions.ADD')}
                          </TableCell>
                          <TableCell sx={{ width: '40%', ...tableHeadCellStyle, borderLeft: 'none' }}>
                            {t('course:task.NAME')}
                          </TableCell>
                          <TableCell sx={{ width: '20%', ...tableHeadCellStyle, borderLeft: 'none' }}>
                            {t('dictionary:TYPE')}
                          </TableCell>
                          <TableCell sx={{ width: '40%', ...tableHeadCellStyle, borderLeft: 'none' }}>
                            {t('common:label.DESCRIPTION')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.integrations.map((integration, index) => {
                          return (!orgDataUsedIntegrations.includes(integration.id) &&
                            <TableRow key={integration.id}>
                              <TableCell sx={{ ...tableHeadCellStyle, borderTop: 'none' }}>
                                <IconButton
                                  sx={{ color: common.primaryColor, margin: "10px" }}
                                  onClick={() => {
                                    integration.code === IntagrationCode.AERO && setOpenAeroModal(true)
                                    integration.code === IntagrationCode.ANTI_PLAGIARISM && setOpenAntiPlagiarismModal(true)
                                    integration.code === IntagrationCode.PLATONUS && handleAddIntegration(values.integrations[index])
                                  }}>
                                  <AddIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell sx={{ fontWeight: 500, fontSize: '14px', border: `1px solid ${common.borderSecondary}`, borderTop: 'none', borderLeft: 'none' }}>
                                <Typography>
                                  {integration.name}
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ borderRight: `1px solid ${common.borderSecondary}`, textAlign: 'center' }}>
                                <Box sx={centerCellStyle}>
                                  <FormikSelect name={`integrations[${index}].viewType`} disabled={true} options={integrationType} />
                                </Box>
                              </TableCell>
                              <TableCell sx={{ borderRight: `1px solid ${common.borderSecondary}`, textAlign: 'justify' }}>
                                <Box sx={{ mt: '6px', ...centerCellStyle, display: 'inline' }}>
                                  <Typography sx={{ display: 'inline' }}>
                                    {showFullText && showFullText[index] ? integration.description : integration.description?.substring(0, 250)}
                                  </Typography>
                                  {integration.description?.length > 250 && (
                                    <Typography sx={{ color: common.primaryColor, cursor: 'pointer', display: 'inline' }}
                                      onClick={() => {
                                        if (showFullText) {
                                          const updatedShowFullText = [...showFullText];
                                          updatedShowFullText[index] = !showFullText[index];
                                          setShowFullText(updatedShowFullText);
                                        }
                                      }}>
                                      {showFullText && showFullText[index] ? ` ${t('common:actions.SHOW_LESS')}` : `...${t('common:actions.SHOW_MORE')}`}
                                    </Typography>
                                  )}
                                </Box>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </FormikForm>
              )}
            />
          }}
        </Formik>
      </Box>
      {openAeroModal && <AeroIntegrationModal
        openModal={openAeroModal}
        handleToggleModal={() => setOpenAeroModal(false)}
        integrationsInfo={initialData?.integrations} />}
      {openAntiPlagiarismModal && <AddAntiPlagiarismAttempts
        openModal={openAntiPlagiarismModal}
        handleToggleModal={() => setOpenAntiPlagiarismModal(false)}
        integrationsInfo={initialData?.integrations} />}
    </Box >
  )
}

export default AddIntegration
