import { memo } from "react";
import {
  Box,
  Tab,
  Typography,
  useTheme,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import CustomSearch from "@ui/Search";
import List from "./List";
import { useNavigate } from "react-router-dom";
import DateTimePicker from "@ui/DateTimePicker";
import { TabListCourse } from "@components/common";
import { useAppSelector } from "@store/hooks";
import { ConfirmationInvitation } from "../Educator/ConfirmationInvitation";
import { TypeCode } from "@interfaces/index";
import { useQuery } from "@tanstack/react-query";
import { courseApi, myCourseControllerApi } from "@services/index";

const StudentCourseList = () => {
  const dictionary = useAppSelector(
    (store) => store.dictionary.data?.availableCourseTypes
  );
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course", "common", "enum"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  //filter
  const isPeriodOpen = searchParams.get("period");
  const tab = searchParams.get("tab") ?? dictionary?.[0] ?? "";
  const startDate =
    searchParams.get("startDate") ??
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
  const endDate =
    searchParams.get("endDate") ??
    new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
  const type = searchParams.get("type") ?? "";

  const { data: limitedCoursesExist } = useQuery({
    queryKey: ["checkStudentHasRestrictedCourse"],
    queryFn: () => myCourseControllerApi.checkStudentHasRestrictedCourses(),
  });

  const { data: memberConfirmation } = useQuery({
    queryKey: ["memberConfirmation"],
    queryFn: () => courseApi.getConfirmationMember(),
    enabled: tab === TypeCode.FREE,
  });

  const handleTypeChange = (newType: string) => {
    searchParams.set("tab", newType);
    setSearchParams(searchParams);
  };

  const statusList = [
    {
      label: t("ALL"),
      code: "",
    },
    {
      label: t("CURRENT_MULTIPLE"),
      code: "CURRENT",
    },
    {
      label: t("PLANNED_MULTIPLE"),
      code: "PLANNED",
    },
    {
      label: t("COMPLETED_MULTIPLE"),
      code: "COMPLETED",
    },
  ];

  const handleChange = (newStatus: string) => {
    searchParams.set("type", newStatus);
    setSearchParams(searchParams);
  };

  const handleStartDateChange = (date: Date | null) => {
    const updatedDate =
      date !== null
        ? new Date(
          Date.UTC(
            date?.getFullYear()!,
            date?.getMonth()!,
            date?.getDate(),
            date?.getHours(),
            date?.getMinutes(),
            date?.getSeconds()
          )
        )
        : null;
    updatedDate ? searchParams.set("startDate", String(new Date(updatedDate))) : searchParams.delete("startDate");
    setSearchParams(searchParams);
  };

  const handleEndDateChange = (date: Date | null) => {
    const updatedDate =
      date !== null
        ? new Date(
          Date.UTC(
            date?.getFullYear()!,
            date?.getMonth()!,
            date?.getDate(),
            date?.getHours(),
            date?.getMinutes(),
            date?.getSeconds()
          )
        )
        : null;
    updatedDate ? searchParams.set("endDate", String(new Date(updatedDate))) : searchParams.delete("endDate");
    setSearchParams(searchParams);
  };

  const searchByName = (searchValue: string) => {
    searchParams.set("name", searchValue);
    setSearchParams(searchParams);
  };
  
  const handlePeriodChange = (e) => {
    searchParams.set("period", e.target.checked.toString());
    if (e.target.checked === false) {
      searchParams.delete("startDate");
      searchParams.delete("endDate");
    } else {
      searchParams.set("startDate", String(new Date(startDate)));
      searchParams.set("endDate", String(new Date(endDate)));
    }
    setSearchParams(searchParams);
  };

  return (
    <Box sx={{ maxWidth: "1880px" }}>
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: { sm: "36px", xs: "28px" },
          lineHeight: "44px",
          alignItems: "center",
          mt: 2,
          mb: 3,
        }}
      >
        {location.pathname.includes("/myCourses")
          ? t("course:listener.MY_COURSES")
          : t("enum:COURSE_CATALOG")}
      </Typography>
      <Box>
        {location.pathname.includes("/myCourses") ? (
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabListCourse
                onChange={(_, value: string) => handleTypeChange(value)}
              />
            </Box>
            {tab === TypeCode.FREE &&
              memberConfirmation &&
              memberConfirmation.length > 0 && (
                <ConfirmationInvitation
                  authorConfirmation={[]}
                  memberConfirmation={memberConfirmation}
                />
              )}
            <TabPanel value={tab} sx={{ px: 0 }}>
              <Box
                sx={{
                  width: { sm: "100%", xs: "95%" },
                  height: "auto",
                  border: `1px solid ${common.borderSecondary}`,
                  borderRadius: "20px",
                  background: common.fontWhite,
                }}
              >
                <TabContext value={type}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      gap: { xs: 2 },
                      flexDirection: { lg: "row", xs: "column" },
                      alignItems: { lg: "center", xs: "flex-start" },
                      justifyContent: "space-between",
                    }}
                  >
                    <TabList
                      onChange={(_, value: string) => handleChange(value)}
                      variant="scrollable"
                      sx={{
                        "& .MuiTab-root": { minHeight: "34px" },
                        "& .Mui-selected": {
                          color: "black!important",
                          backgroundColor: common.secondaryColor,
                        },
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "24px 24px 0",
                        width: "100%",
                      }}
                      TabIndicatorProps={{
                        style: {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          backgroundColor: common.secondaryColor,
                          opacity: 0.15,
                          border: `1px solid ${common.borderPrimary}`,
                          borderRadius: "6px",
                        },
                      }}
                    >
                      {statusList.map((status) => {
                        return (
                          <Tab
                            key={status.code}
                            label={status.label}
                            value={status.code}
                            sx={{
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "#A49D9D",
                              border: "1px solid #A49D9D",
                              width: "auto",
                              height: "45px",
                              borderRadius: "6px",
                              backgroundColor: "#FFFFFF",
                              textTransform: "capitalize",
                              lineHeight: "130%",
                              mr: "8px",
                              "&:hover": {
                                backgroundColor: "#F5EFFF",
                              },
                              "&.Mui-selected": {
                                backgroundColor: common.secondaryColor,
                              },
                            }}
                          />
                        );
                      })}
                    </TabList>
                  </Box>
                  <Box sx={{ width: "100%", p: "24px" }}>
                    <CustomSearch
                      placeholder={t(
                        "common:placeholders.SEARCH_BY_COURSE_NAME"
                      )}
                      onChange={searchByName}
                    />
                  </Box>
                  <Box sx={{ display: "flex", width: "100%", px: "24px" }}>
                    <Box width={"100%"} display={"flex"} alignSelf={"center"}>
                      {limitedCoursesExist && (
                        <Typography
                          color={common.primaryColor}
                          sx={{
                            cursor: "pointer",
                            fontSize: { xs: "14px", sm: "16px" },
                          }}
                          onClick={() => navigate("/myCourses/restricted")}
                        >
                          {t("course:RESTRICTED_COURSES")}
                        </Typography>
                      )}
                    </Box>

                    <Box
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      <FormControlLabel
                        sx={{ width: "100%" }}
                        control={
                          <Checkbox
                            checked={isPeriodOpen === "true"}
                            onChange={handlePeriodChange}
                            sx={{
                              color: common.primaryColor,
                              "&, &.Mui-checked": {
                                color: common.primaryColor,
                              },
                            }}
                          />
                        }
                        label={
                          location.pathname.includes("courseCatalog")
                            ? t("course:REGISTRATION_PERIOD")
                            : t("course:COURSE_PERIOD")
                        }
                      />
                    </Box>
                  </Box>

                  {isPeriodOpen === "true" ? (
                    <Box sx={{ px: "24px", paddingTop: "20px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                          gap: 2,
                          justifyContent: "flex-end",
                        }}
                      >
                        <DateTimePicker
                          name="startDate"
                          date={searchParams.get("startDate") ? new Date(startDate) : null}
                          shouldCloseOnSelect
                          isClearable
                          handleDateChange={handleStartDateChange}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                          }}
                          label={t("course:FROM")}
                          maxDate={new Date(endDate)}
                        />
                        <DateTimePicker
                          name="endDate"
                          date={searchParams.get("endDate") ? new Date(endDate) : null}
                          shouldCloseOnSelect
                          isClearable
                          handleDateChange={handleEndDateChange}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                          }}
                          label={t("course:TO")}
                          minDate={new Date(startDate)}
                        />
                      </Box>
                    </Box>
                  ) : null}

                  <TabPanel
                    sx={{
                      padding: "20px 24px",
                    }}
                    value={type}
                  >
                    <List />
                  </TabPanel>
                </TabContext>
              </Box>
            </TabPanel>
          </TabContext>
        ) : (
          <Box
            sx={{
              // course catalog
              border: `1px solid ${common.borderSecondary}`,
              width: { sm: "100%", xs: "95%" },
              height: "auto",
              borderRadius: "20px",
              background: common.fontWhite,
            }}
          >
            <Box sx={{ width: "100%", p: "20px 24px" }}>
              <CustomSearch
                placeholder={t("common:placeholders.SEARCH_BY_COURSE_NAME")}
                onChange={searchByName}
              />
            </Box>
            <Box sx={{ display: "flex", width: "100%", px: "24px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { md: "center", xs: "flex-start" },
                  mb: "16px",
                }}
              >
                <FormControlLabel
                  sx={{ mr: "16px" }}
                  control={
                    <Checkbox
                      checked={isPeriodOpen === "true"}
                      onChange={handlePeriodChange}
                      sx={{
                        color: common.primaryColor,
                        "&, &.Mui-checked": { color: common.primaryColor },
                      }}
                    />
                  }
                  label={
                    location.pathname.includes("courseCatalog")
                      ? t("course:REGISTRATION_PERIOD")
                      : t("course:COURSE_PERIOD")
                  }
                />
                <Box>
                  {isPeriodOpen ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: 2,
                      }}
                    >
                      <DateTimePicker
                        name="startDate"
                        date={searchParams.get("startDate") ? new Date(startDate) : null}
                        shouldCloseOnSelect
                        isClearable
                        handleDateChange={handleStartDateChange}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                        }}
                        label={t("course:FROM")}
                        maxDate={new Date(endDate)}
                      />
                      <DateTimePicker
                        name="endDate"
                        date={searchParams.get("endDate") ? new Date(endDate) : null}
                        shouldCloseOnSelect
                        isClearable
                        handleDateChange={handleEndDateChange}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                        }}
                        label={t("course:TO")}
                        minDate={new Date(startDate)}
                      />
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Box>
            <List />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(StudentCourseList);
