import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import CustomModal from "@components/Modal";
import { IUserFilter, IUserSearchItem } from "@interfaces/index";
import { Divider } from "@mui/material";
import { serviceMember } from "@services/member";
import { DTO, initialDto, userFilterInitialDto } from "../models";
import { AddNotRegisteredFreeParticipants } from "./AddNotRegisteredFreeParticipants";
import { AddRegisteredFreeParticipants } from "./AddRegisteredFreeParticipants";

const groupEducator = ["TUTOR", "ASSISTANT", "EXAMINER"];

interface Props {
  modalOpen: boolean;
  handleToggleModal: (modalState: boolean, message?: string) => void;
  // updateParticipantList: (list: Array<IParticipant>) => void;
  subdivisionIds: number[];
  eduProgramIds: number[];
  studyClasses?: any;
  refetch: any;
}

export const AddFreeParticipantsModal = ({
  modalOpen,
  handleToggleModal,
  // updateParticipantList,
  subdivisionIds,
  eduProgramIds,
  studyClasses,
  refetch
}) => {
  const { t } = useTranslation(["common", "user", "course"]);
  const { courseId } = useParams();
  const { common } = useTheme().palette;
  const [userFilter, setUserFilter] =
    useState<IUserFilter>(userFilterInitialDto);
  const [tabValue, setTabValue] = useState("registered");
  const [userList, setUserList] = useState<IUserSearchItem[]>([]);
  const [disabledOptions, setDisabledOptions] = useState<string[]>([]);
  const [openRegisterToCourseModal, setOpenRegisterToCourseModal] =
    useState<boolean>(false);

  const [initialData, setInitialData] = useState<DTO>(() => {
    if (courseId) return { ...initialDto, courseId };
    return initialDto;
  });
  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);
    // setSearchParams({ participantsTab: newValue });
  };

  useEffect(() => {
    if (studyClasses?.id) {
      if (userFilter.roleCode) {
        serviceMember
          .getNonMemberList(studyClasses.id, userFilter)
          .then((res) => {
            if (res.status === 200) setUserList(res.data);
          });
      }
    }
  }, [studyClasses.id, userFilter]);

  // useEffect(() => {
  //   if (tutorIsDisabled) {
  //     setDisabledOptions(prev => ([...prev, 'TUTOR']));
  //   } else {
  //     setDisabledOptions(prev => ([...prev].filter(e => e !== 'TUTOR')));
  //   }
  //   if (curatorIsDisabled) {
  //     setDisabledOptions(prev => ([...prev, 'CURATOR']));
  //   } else {
  //     setDisabledOptions(prev => ([...prev].filter(e => e !== 'CURATOR')));
  //   }
  // }, [tutorIsDisabled, curatorIsDisabled]);

  const handleRoleChange = (roleType: string, values: DTO) => {
    setInitialData({ ...values, roleType, userIds: [] });
    const roleCode = groupEducator.includes(roleType)
      ? "EDUCATOR"
      : roleType === "CURATOR"
      ? "MANAGER"
      : roleType;
    if (roleCode === "LISTENER") {
      const filter = { ...userFilter, roleCode, eduProgramIds };
      delete filter.subdivisionIds;
      setUserFilter(filter);
    } else {
      const ids = groupEducator.includes(roleType)
        ? subdivisionIds
        : userFilterInitialDto.subdivisionIds;
      const filter = { ...userFilter, roleCode, subdivisionIds: ids };
      delete filter.eduProgramIds;
      setUserFilter(filter);
    }
  };

  const handleInputChange = (keyword: string) => {
    if (keyword.length >= 3) {
      setUserFilter({ ...userFilter, name: keyword });
    } else if (keyword.length === 0) {
      let filter = { ...userFilter };
      delete filter?.name;
      setUserFilter(filter);
    }
  };

  return (
    <CustomModal
      open={modalOpen}
      handleToggleModal={handleToggleModal}
      width={550}
    >
      <>
        <Typography
          id="modal-modal-title"
          sx={{
            fontWeight: 500,
            fontSize: { sm: "28px", xs: "20px" },
            lineHeight: "12px",
            color: "#000000",
            display: "flex",
            alignItems: "center",
            mb: "24px",
            width: "100%",
          }}
        >
          Приглашение участников курса
        </Typography>
        <Divider />
        <TabContext value={tabValue}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                padding: "10px 0 0 10px",
              }}
            >
              <Tabs
                onChange={(_, value: string) => handleTabChange(value)}
                indicatorColor="secondary"
                value={tabValue}
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: "#1A141F",
                  },
                  "& .MuiTabs-indicator": {
                    background: common.borderPrimary,
                  },
                }}
              >
                <Tab
                  label={"Зарегистрирован"}
                  value={"registered"}
                  sx={{ textTransform: "none", fontSize: "18px" }}
                />
                <Tab
                  label={"Не зарегистрирован"}
                  value={"notRegistered"}
                  sx={{ textTransform: "none", fontSize: "18px" }}
                />
              </Tabs>
            </Box>
          </Box>
          <TabPanel value={tabValue}>
            {tabValue === "registered" && (
              <AddRegisteredFreeParticipants
                handleToggleModal={handleToggleModal}
                subdivisionIds={subdivisionIds}
                eduProgramIds={eduProgramIds}
                studyClasses={studyClasses}
                refetch={refetch}
              />
            )}
            {tabValue === "notRegistered" && (
              <AddNotRegisteredFreeParticipants
                handleToggleModal={handleToggleModal}
                subdivisionIds={subdivisionIds}
                eduProgramIds={eduProgramIds}
                studyClasses={studyClasses}
                refetch={refetch}
              />
            )}
          </TabPanel>
        </TabContext>
      </>
    </CustomModal>
  );
};
