import { useEffect, useState } from 'react'
import Stomp from 'stompjs';
import { useSelector, useDispatch } from 'react-redux';
import { IProfile } from '@interfaces/index';
import { Box, Paper, Snackbar, Typography, useTheme } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import { serviceNotification } from '@services/notification';
import { NotificationDto } from './models';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notificationActions } from '@store/notification/reducers';

const target = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_WEBSOCKET_PRODUCTION_TARGET
  : process.env.REACT_APP_WEBSOCKET_DEVELOPMENT_TARGET;

export const NotificationSocket = () => {
  const [message, setMessage] = useState<any>(null)
  const socket = new WebSocket(`${target}/notification/ws`);
  const stompClient = Stomp.over(socket);
  const profile: IProfile = useSelector((store: any) => store.account.profileInfo);
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(["notification"]);

  useEffect(() => {
    // Connect to the WebSocket server
    stompClient.connect({}, frame => {
      stompClient.subscribe(`/topic/user/${profile.pltaId}`, message => {
        serviceNotification
          .getListUserId({ size: 1000 })
          .then((res) => {
            dispatch(notificationActions.setNotificationCount(res.data.dtoList.filter((item: NotificationDto) => item.status === 'UNREAD').length ));

          });
        setMessage(JSON.parse(message.body))
      });
    }, error => {
      console.error('Error during connection:', error);
    });
    // eslint-disable-next-line
  }, [stompClient, profile.pltaId, message]);

  return (
    <>
      {message && <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={() => setMessage(null)} message={"message"}>
        <Paper sx={{ display: "flex", border: "1px solid #c1c1c1", width: "400px" }} elevation={3}>
          <Box width="6px" sx={{ background: "#eae1f9", borderRadius: "4px 0px 0px 4px" }}></Box>
          <Box margin="15px 0px 15px 14px" sx={{ background: common.primaryColor, height: "24px", borderRadius: "15px" }}>
            <MarkEmailUnreadOutlinedIcon sx={{ color: "#fff", margin: "4px", fontSize: "16px" }} />
          </Box>
          <Box margin="15px 10px 15px 14px" sx={{ cursor: "pointer" }} onClick={() => {
            navigate('/notifications')
            setMessage(null)
          }}>
            <Typography sx={{ color: common.primaryColor, marginBottom: "2px" }} fontWeight={600} fontSize="16px">{t("NEW_NOTIFICATION")}</Typography>
            <Typography fontSize="14px" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "290px" }}>{message.data.messageRu} </Typography>
          </Box>
          <ClearOutlinedIcon sx={{ color: "#aba7af", cursor: "pointer", margin: "8px" }} onClick={() => setMessage(null)} />
        </Paper>
      </Snackbar>}
    </>
  )
}
