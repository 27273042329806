import { useEffect, useState } from 'react'
import { AlertColor, Box, Typography, useTheme } from '@mui/material'
import { Formik, Form as FormikForm } from 'formik';
import { isBefore } from 'date-fns';

import { serviceCourse } from '@services/course';
import { serviceDict } from '@services/dictionary';
import CustomModal from '@components/Modal';
import CustomButton from '@ui/Button';
import DateTimePicker from '@ui/formik/DateTimePicker';
import FormikAutocomplete from '@ui/formik/Autocomplete';
import FormikInput from '@ui/formik/Input';
import { useTranslation } from 'react-i18next';

interface Props {
  openSubjectModal: boolean;
  handleCloseSubjectModal: () => any;
  currentDay: any,
  setMessage: (message: string) => void;
  setMessageOpen: (open: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  updateLists: () => void;
  currentSubject: any;
}

export const EditSubjectModal = ({ openSubjectModal, handleCloseSubjectModal, currentDay, setMessage, setMessageOpen, setMessageType, updateLists, currentSubject }: Props) => {
  const { common } = useTheme().palette;
  const lng = localStorage.getItem("i18nextLng") || "kz";
  const [studyDict, setStudyDict] = useState<any>([]);
  const [initialValues, setInitialValues] = useState<any>()
  const { t } = useTranslation(["course", "common"]);

  useEffect(() => {
    serviceDict
      .getDictionaryList("CLASS_TYPE", {
        pageNumber: 0,
        size: 10,
        filter: {},
        sorting: lng === 'kz' ? { isStandard: "desc", nameKz: "asc" } : lng === 'ru' ? { isStandard: "desc", nameRu: "asc" } : { isStandard: "desc", nameEn: "asc" },
      })
      .then((res) => {
        setStudyDict(res.data.dtoList);
        // setCount(res.data.totalElements);
      });
  }, [lng]);

  useEffect(() => {
    setInitialValues({ day: currentDay, name: currentSubject?.name || null, subject: currentSubject?.classTypeId, markAll: null })
  }, [currentDay, currentSubject, studyDict])

  return (
    <>
      <CustomModal
        open={openSubjectModal}
        handleToggleModal={handleCloseSubjectModal}
        minWidth={450}
        disableAutoFocus
      >
        <>
          <Typography fontWeight={600} fontSize="24px">{t('common:actions.EDIT_ATTENDANCE_DATA')}</Typography>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={null}
            onSubmit={(value) => {
              const requestBody = {
                id: currentSubject.id,
                name: value.subject === 1 ? "Практика" : value?.name,
                classTypeId: value.subject
              }
              serviceCourse.updateTitleAttendance(requestBody).then((res) => {
                if (res.status === 200) {
                  setMessageOpen(true);
                  setMessageType("success");
                  setMessage(t('common:messages.CHANGES_SAVED'));
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 3000)
                }
              })
              serviceCourse.updateOtherMarkAttendance(currentSubject.id, value.markAll).then((res) => {
                if (res.status === 200) {
                  handleCloseSubjectModal()
                  setMessageOpen(true);
                  setMessageType("success");
                  setMessage(t('common:messages.CHANGES_SAVED'));
                  updateLists()
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 3000)
                }
              })
            }}>
            {({ values, submitForm }) => {
              return (
                <FormikForm>
                  <Box mb="12px" mt="12px">
                    <DateTimePicker label={t('common:label.DATE')} name="day" isRequired disabled />
                  </Box>
                  <Box display="flex" gap={21} marginBottom="5px">
                    <Typography>{t('course:TYPES_OF_CLASSES')} <span style={{ color: common.errorColor }}>*</span></Typography>
                    <Typography>{t('common:actions.MARK_ALL_UNMARKED_AS')}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1} width='100%' mb="12px">
                    <Box display='flex' flexDirection='column' gap={1} width='100%'>
                      <FormikAutocomplete
                        name={`subject`}
                        options={studyDict}
                        clearable={false}
                        sx={{ width: "100%" }}
                      />
                    </Box>
                    <Box display='flex' flexDirection='column' gap={1} width='100%'>
                      <FormikAutocomplete
                        name={`markAll`}
                        sx={{ width: "100%" }}
                        clearable={false}
                        disabled={isBefore(new Date(), new Date(values.date || ""))}
                        options={[{ id: "PRESENT", name: t('common:statuses.PRESENT') }, { id: "WORKED", name: t('common:statuses.MADE_UP') }, { id: "ABSENT", name: t('common:statuses.ABSENT') }]}
                      />
                    </Box>
                  </Box>
                  {values?.subject === 2 && <Box m="12px 0px">
                    <FormikInput name={`name`} label={t('course:task.NAME')} isRequired />
                  </Box>}
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <CustomButton
                      backgroundColor={common.btnCancel}
                      fontColor={common.primaryColor}
                      borderRadius="6px"
                      height="40px"
                      sx={{
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                      onClick={handleCloseSubjectModal}
                    >
                      {t('common:actions.CANCEL')}
                    </CustomButton>
                    <CustomButton
                      height="40px"
                      backgroundColor={common.primaryColor}
                      borderRadius="8px"
                      fontColor={common.fontWhite}
                      onClick={submitForm}
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        alignItems: "center",
                        color: "#FFFFFF",
                      }}
                    >
                      {t('common:actions.SAVE')}
                    </CustomButton>
                  </Box>
                </FormikForm>
              )
            }}
          </Formik>
        </>
      </CustomModal>
    </>
  )
}
