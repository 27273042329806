import React, { useEffect, useState } from 'react'
import { Box, Typography, Checkbox, useTheme } from '@mui/material';
import { IOrganization } from '@pages/dictionary/Organization/models';
import { initialFilter } from '@pages/dictionary/models';
import { serviceDict } from '@services/dictionary';
import CustomSearch from '@ui/Search';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useTranslation } from 'react-i18next';

export const FreeCourseOrganizationFilter = ({ filter, setFilter }) => {
  const [showOrganization, setShowOrganization] = useState<boolean>(true)
  const { t } = useTranslation(["freeCourse", "user"]);
  const [filterOrg, setFilterOrg] = useState<{ search: string; isDisabled: boolean }>(initialFilter);
  const [organizationList, setOrganizationList] = useState<IOrganization[]>([]);
  const { common } = useTheme().palette;

  const lng = localStorage.getItem("i18nextLng") || "kz";

  useEffect(() => {
    serviceDict
      .getDictionaryListByCodePublic("BRANCH")
      .then((res) => {
        setOrganizationList(res.data);
      });
  }, [filterOrg, lng]);

  const isChecked = (orgId) => {
    return filter.orgIds.some(org => org === orgId)
  }

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        justifyContent: "space-between",
        cursor: "pointer",
        margin: "15px 0px"
      }}
        onClick={() => setShowOrganization(!showOrganization)}>
        <Typography fontWeight={600} fontSize="18px">{t("user:ORGANIZATION")}</Typography>
        {showOrganization ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
      </Box>
      {showOrganization && <Box>
        <CustomSearch
          placeholder={t('user:ORGANIZATION_NAME')}
          height="44px"
          sx={{ fontSize: "14px" }}
          onChange={(value) => setFilterOrg((prev) => ({ ...prev, search: value }))}
        />
        {organizationList.map((organization) => {
          return (<Box key={organization.id} display="flex" alignItems="center">
            <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <Checkbox
                size="small"
                sx={{ color: common.borderSecondary, "&.Mui-checked": { color: common.primaryColor } }}
                checked={isChecked(organization.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilter((prev) => ({ ...prev, orgIds: [...prev.orgIds, organization.id] }))
                  } else {
                    setFilter((prev) => ({ ...prev, orgIds: prev.orgIds.filter((elem) => elem !== organization.id) }))
                  }
                }}
              />
              <Typography marginLeft="8px" fontSize="14px">{organization?.nameRu}</Typography>
            </label>
          </Box>)
        })}
      </Box>}
    </Box>
  )
}