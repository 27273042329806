import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Rating, Typography, useTheme } from "@mui/material";
import Link from '@mui/material/Link';

import { useGetCourseReviewInfoQuery } from "@services/public";
import { serviceCourse } from "@services/course";
import { serviceDict } from "@services/dictionary";
import { getLangDictById, removeHtml } from "@utils/functions";
import AvatarPicture from "@components/AvatarPicture";
import CourseMainInfo from "./CourseMainInfo";
import CourseDescription from "./Description";
import Authors from "./Authors";
import CourseTargetAudience from "./TargetAudience";
import CourseReview from "./Review";
import { OtherCourseThisAuthor } from "./OtherCourses/OtherCourseThisAuthor";
import CourseContent from "./Content";
import { initialDto } from "@pages/myCourses/Educator/TabList/Flows/models";
import { DictionaryCode, IDictionary, IStudyClass, TypeCode } from "@interfaces/index";

interface IContentSummary {
  sectionSum: number;
  themesSum: number;
  evaluatedTasksSum: number;
  selfTasksSum: number;
  estimatedTime: number | null;
}

const CoursePage = () => {
  const { t } = useTranslation(["enum", "dictionary"]);
  const { common } = useTheme().palette;
  const { classId } = useParams();
  const [searchParams] = useSearchParams();
  const classIdFromCatalog = searchParams.get("classId");
  const [diffLevelList, setDiffLevelList] = useState<IDictionary[]>([]);
  const [courseInfo, setCourseInfo] = useState<IStudyClass>(initialDto);
  const [contentSum, setContentSum] = useState<IContentSummary | null>(null);

  useEffect(() => {
    serviceDict
      .getDictionaryListByCodePublic(DictionaryCode.COURSE_DIFFICULTY_LEVEL)
      .then((res) => {
        if (res.status === 200) setDiffLevelList(res.data);
      });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (classId || classIdFromCatalog) {
      serviceCourse.getStudyClassByIdPublic(classId || classIdFromCatalog || "").then((res) => {
        if (res.status === 200) {
          setCourseInfo(res.data);
        }
      });
    }
  }, [classId, classIdFromCatalog]);

  const { data: courseReview } = useGetCourseReviewInfoQuery(
    String(courseInfo?.courseId), {
    skip: !courseInfo?.courseId
  });

  useEffect(() => {
    if (courseInfo?.courseId) {
      serviceCourse.getContentSummaryPublic(courseInfo.courseId).then((res) => {
        if (res.status === 200) setContentSum(res.data);
      });
    }
  }, [courseInfo?.courseId]);

  return (
    <Box sx={{ width: '100%', margin: "0px auto" }}>
      <Box
        display="flex"
        justifyContent="flex-end"
        position="sticky"
        top="18px"
        paddingRight="40px"
        paddingTop={"20px"}
      >
        <CourseMainInfo
          courseInfo={courseInfo}
          setCourseInfo={setCourseInfo}
          contentSum={contentSum}
          diffLevelList={diffLevelList}
        />
      </Box>
      <Box
        sx={{
          background: common.primaryColor,
          borderRadius: "6px",
          padding: "40px ",
          marginBottom: "40px",
        }}
      >
        <Box sx={{ width: { sm: '60%' } }}>
          <Typography fontSize="36px" color="#fff" fontWeight={600}>
            {courseInfo.course?.name}
          </Typography>
          {courseInfo.course?.typeCode === TypeCode.FREE &&
            <Box display="flex" gap={1} alignItems="center" margin="24px 0">
              <Rating
                value={courseReview?.avgRating || 0}
                readOnly
                size="medium"
                precision={0.5}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fill: '#F4C41A'
                  }
                }}
              />
              {courseReview && courseReview?.avgRating > 0
                ?
                <Typography color="#fff" fontSize="16px" fontWeight={500}>
                  {courseReview?.avgRating}
                </Typography>
                :
                <Typography color="#fff" fontSize="16px" fontWeight={500}>
                  {t('course:NO_RATINGS')}
                </Typography>
              }
            </Box>
          }
          <Typography fontSize="18px" color="#fff">
            {removeHtml(courseInfo.course?.shortDescription || "")}
          </Typography>
          {courseInfo?.course?.authors &&
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              margin="24px 0"
            >
              {courseInfo.course.authors.map(
                (author) => (
                  <Box
                    key={author.userId}
                    display="flex"
                    gap={1}
                    alignItems="center"
                  >
                    <AvatarPicture
                      userId={author.userId}
                      variant="circular"
                      sxProps={{ width: "44px", height: "44px" }}
                    />
                    <Typography fontSize="20px" color="#fff">
                      <Link
                        href="#authors"
                        sx={{
                          color: '#FFFFFF',
                          textDecoration: 'none',
                          cursor: 'pointer',
                          "&:hover": {
                            color: common.strokePrimary
                          }
                        }}
                      >{author.username}</Link>
                    </Typography>
                  </Box>
                )
              )}
            </Box>
          }

          <Box display="flex" gap={1}>
            <Box
              sx={{
                padding: "10px",
                background: "#9D7CEF",
                borderRadius: "20px",
                display: "flex",
                gap: 1
              }}
            >
              <Typography fontSize="14px" color="#fff">
                {t(`enum:${courseInfo.course?.courseSetting?.formatType}`)}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "10px",
                background: "#9D7CEF",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize="14px" color="#fff">
                {getLangDictById(courseInfo.course?.courseSetting.langId || 1)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "60%"
        }}
      >
        <CourseDescription description={removeHtml(courseInfo.course?.description || "")} />
        <CourseContent courseId={courseInfo.courseId} contentSum={contentSum} durationType={courseInfo?.course?.courseSetting?.durationType} />
        <Authors courseId={courseInfo.courseId} creatorId={courseInfo?.course?.createdBy} />
        {courseInfo.course?.typeCode === TypeCode.FREE && <CourseTargetAudience text={removeHtml(courseInfo.course?.targetAudience || "")} />}
        {courseReview && courseReview?.avgRating > 0 && courseInfo.course?.typeCode === TypeCode.FREE &&
          <CourseReview courseId={courseInfo.courseId} courseReview={courseReview} />
        }
        {courseInfo?.course?.authors && courseInfo?.course?.authors.length > 0 && courseInfo.course?.typeCode === TypeCode.FREE &&
          <OtherCourseThisAuthor
            authorId={courseInfo.course.authors[0].userId || ''}
            courseId={courseInfo.courseId || ""}
            diffLevelList={diffLevelList}
          />
        }
      </Box>
    </Box>
  );
};

export default CoursePage;
