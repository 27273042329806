import Button, { ButtonProps } from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

interface Props extends ButtonProps {
  backgroundColor?: string;
  fontColor?: string;
  fontWeight?: string | number;
  borderRadius?: string;
  borderColor?: string;
  width?: string;
  height?: string;
  isSubmit?: boolean;
  mt?: string | number;
  disabled?: boolean;
}

const CustomButton: React.FC<Props> = ({
  backgroundColor,
  borderRadius = "18px",
  borderColor = "none",
  width = "100%",
  height = "40px",
  fontColor = "#FFFFFF",
  fontWeight = "lighter",
  disabled = false,
  size,
  variant = "contained",
  children,
  startIcon,
  endIcon,
  isSubmit = false,
  mt,
  onClick,
  style
}: Props) => {
  const { common } = useTheme().palette;
  const bgColor = backgroundColor || common.primaryColor;

  const buttonStyles = {
    backgroundColor: bgColor,
    color: fontColor,
    fontWeight,
    lineHeight: 1.25,
    borderRadius,
    border: `1px solid ${borderColor}`,
    width,
    height,
    "&.MuiButtonBase-root:hover": {
      bgcolor: bgColor,
    },
    "&.Mui-disabled": {
      color: '#FFFFFF',
    },
    textTransform: "none",
    marginTop: mt ? mt : 0
  };

  return (
    <Button
      disabled={disabled}
      size={size}
      variant={variant}
      sx={buttonStyles}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      type={isSubmit ? "submit" : "button"}
    >
      {children}
    </Button>
  );
};

export default CustomButton;