import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { useState } from "react";

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => {
  const { common, mode } = theme.palette;
  return ({
    width: 28,
    height: 16,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: common.primaryColor,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 1,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 1,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 12,
      height: 12,
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      backgroundColor: common.inputDisabledBg,
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  })
});

interface Props {
  checked: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
}

export default function CustomSwitch({ checked, disabled = false, onChange }: Props) {
  const [value, setValue] = useState<boolean>(checked);

  const handleChange = (val: boolean) => {
    if (onChange) onChange(val);
    setValue(val);
  }
  return (
    <StyledSwitch checked={value} disabled={disabled} onChange={() => handleChange(!value)} />
  );
}