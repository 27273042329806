import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import MathJaxComponent from '@components/MathJaxComponent';
import CustomModal from '@components/Modal';

interface FormulaModalProps {
  open: boolean;
  handleToggleModal: () => void;
}

export const FX100IOModal = ({ open, handleToggleModal }: FormulaModalProps) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course"])
  const IOFormula = "`ИО_{курса}=(ГЗ_{1} * Д_{ГЗ_{1}}) + (ГЗ_{2} * Д_{ГЗ_{2}}) + ... + (ГЗ_{N} * Д_{ГЗ_{N}})`";
  return (
    <CustomModal
      open={open}
      handleToggleModal={handleToggleModal}
      width={550}
      borderColor={common.primaryColor}
    >
      <>
        <Typography>
          {t("course:listener.GENERAL_FORMULA_ROI_COURSE")}:
        </Typography>
        <Box sx={{ background: 'rgba(105, 123, 227, 0.2)', color: "black", border: '1px solid #697BE3', borderRadius: '8px', margin: "0 auto" }}>
          {<MathJaxComponent formula={IOFormula} />}
        </Box>
        <Typography>{t("course:listener.WHERE")}:</Typography>
        <Typography><MathJaxComponent inline formula={"`ИО_{курса}`"} /> – {t("course:listener.FINAL_GRADE_COURSE")};</Typography>
        <Typography><MathJaxComponent inline formula={"`Д_{ГЗ}`"} /> – {t("course:listener.PERCENTAGE_TASK_GROUP_FINAL_GRADE")};</Typography>
        <Typography><MathJaxComponent inline formula={"`N`"} /> – {t("course:listener.NUMBER_GROUPS_TASKS_COURSE")}</Typography>
      </>
    </CustomModal>
  )
}
