import { ClipboardEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Avatar, Box, Typography } from '@mui/material';
import TextField from "@mui/material/TextField";
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { serviceUser } from '@services/user';
import CustomButton from '@ui/Button';

const VerificationCode = ({ setIsRegState, formValues, regWithEmail }) => {
  const { t } = useTranslation(["common", "registration"]);
  const { common } = useTheme().palette;
  const [timer, setTimer] = useState(60);
  const [errorMessage, setErrorMessage] = useState('');
  const [isRegSuccessful, setIsRegSuccessful] = useState(false);

  useEffect(() => {
    const firstInput = document.getElementById(`input-0`)
    if (firstInput) {
      firstInput.focus()
    }

    let interval: string | number | NodeJS.Timeout | undefined;

    interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const resetTimer = () => {
    setTimer(60);
  };

  const length = 6;
  const [values, setValues] = useState(Array(length).fill(''));
  const inputList = Array.from({ length }, (_, i) => i);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    if (value.length > 1) return;

    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);

    if (value !== '' && index < length - 1) {
      document.getElementById(`input-${index + 1}`)?.focus();
    }

    if (newValues.every((val) => val !== '')) {
      newValues.join('');
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement | HTMLDivElement>, index: number) => {
    if (e.key === 'Backspace' && values[index] === '' && index > 0) {
      document.getElementById(`input-${index - 1}`)?.focus();
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData('Text').slice(0, length);
    const newValues = pasteData.split('');
    setValues(newValues);

    if (newValues.length === length) {
      document.getElementById(`input-${length - 1}`)?.focus();
    }
  };

  function maskPhoneNumber(phoneNumber: string) {
    const cleanPhoneNumber = phoneNumber.replace(/[() ]/g, '') // remove spaces and brackets
    const operatorId = cleanPhoneNumber.slice(2, 5);
    const lastTwoDigits = cleanPhoneNumber.slice(-2);

    const maskedPhoneNumber = `+7 ${operatorId} *** ** ${lastTwoDigits}`;

    return maskedPhoneNumber;
  }

  const login = () => {
    window.location.href = '/oauth2/authorization/gateway';
  }

  const confirmRegistration = () => {
    serviceUser.registerConfirm({ registrationType: 'EMAIL', confirmationCode: values.join(''), username: formValues.email }).then((res) => {
      if (res.status === 200) {
        setIsRegSuccessful(true)
        localStorage.setItem('firstTime', 'true');
        setTimeout(login, 3000)
      }
    }).catch((err) => {
      setErrorMessage(t(`registration:messages.${err.response.data.code}`))
    })
  }

  const resendConfirmationCode = () => {
    if(timer === 0) {
      serviceUser.registerResendCode(formValues.email, 'EMAIL').then((res) => {
        if (res.status === 200) {
          resetTimer()
        }
      })
    }
  }

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '550px', height: 'auto', p: '40px 60px', borderRadius: '24px',
      backgroundColor: "rgba(255, 255, 255, 1)", gap: '24px', position: 'relative'
    }}>
      {isRegSuccessful
        ? <>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
            <Avatar
              alt="Congrats icon"
              src={require(`@assets/images/CongratsIcon.png`)}
              sx={{ width: '82px', height: '102px' }}
              variant="square"
            />
            <Typography sx={{ fontWeight: 600, fontSize: '28px', fontFamily: 'Golos', textAlign: 'center' }}>{t('registration:messages.CONGRATS')}</Typography>
            <Typography sx={{ fontWeight: 400, fontSize: '16px', fontFamily: 'Golos', lineHeight: '22px', textAlign: 'center' }}>
              {t('registration:messages.SUCCESSFULLY_REGISTERED')}
            </Typography>
          </Box></>
        : <>
          <Box sx={{ position: 'absolute', top: 20, left: 20, cursor: 'pointer' }} onClick={() => setIsRegState(true)}><ArrowBackIcon /></Box>
          <Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: '28px', fontFamily: 'Golos' }}>
            {regWithEmail ? t('common:label.ENTER_A_CODE') : t('registration:ENTER_CODE_FROM_SMS')}
          </Typography>
          <Typography sx={{ color: '#8E8E93', fontSize: '14px' }}>{regWithEmail ? t('registration:CODE_SENT_TO_EMAIL') : t('registration:CODE_SENT_TO_NUMBER')}</Typography>
          <Typography sx={{ fontSize: '18px' }}>{regWithEmail ? formValues.email : maskPhoneNumber(formValues.phoneNumber)}</Typography>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            {inputList.map((_, index) => (
              <TextField
                key={index}
                id={`input-${index}`}
                type='text'
                sx={{
                  '& .MuiInputBase-root': {
                    height: '52px',
                    fontSize: '35px',
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000'
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    borderBottom: '2px solid #8E8E93',
                    borderRadius: 0
                  }
                }}
                inputProps={{ maxLength: 1, fontSize: '35px' }}
                size="medium"
                value={values[index]}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={index === 0 ? handlePaste : undefined} // Only attach onPaste to the first input
              />
            ))}
          </Box>
          {errorMessage && <Typography sx={{ color: common.errorColor }}>{errorMessage}</Typography>}
          <CustomButton height='56px' borderRadius='8px' onClick={confirmRegistration}
            disabled={values.some(input => input === '')}>
            {t('common:actions.CONFIRM')}
          </CustomButton>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', gap: 1 }}>
            <Typography sx={{ cursor: 'pointer', color: common.primaryColor }} onClick={resendConfirmationCode}>
              {`${t('registration:SEND_CODE_AGAIN')} ${timer > 0 ? `0:${timer < 10 ? `0${timer}` : timer}` : '0:00'}`}
            </Typography>
            <Typography sx={{ cursor: 'pointer', color: common.primaryColor }} onClick={() => setIsRegState(true)}>
              {regWithEmail ? t('registration:CHOOSE_ANOTHER_EMAIL') : t('registration:CHOOSE_ANOTHER_PHONE_NUMBER')}
            </Typography>
          </Box>
        </>}
    </Box>
  )
}

export default VerificationCode;
