import { MouseEvent, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LinkIcon from '@mui/icons-material/Link';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TitleIcon from '@mui/icons-material/Title';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';

import { getFileType } from '@utils/functions';
import FileViewerModal from '@components/modals/FileViewerModal';
import { PDFIcon } from '@assets/svg_files/Icons';
import { CourseAdditionalResource, CourseAdditionalResourceResourceTypeEnum } from '@models/course-additional-resource';
import { resourceInitialDto } from './models';
import { useTranslation } from 'react-i18next';

interface Props {
  resourceList: Array<CourseAdditionalResource>;
  handleToggleActionMenu: (event: MouseEvent<HTMLElement> | null, currentResource?: CourseAdditionalResource) => void;
}

const List = ({ resourceList, handleToggleActionMenu }: Props) => {
  const { t } = useTranslation(["enum"]);
  const { common } = useTheme().palette;
  const [openFileModal, setOpenFileModal] = useState(false);
  const [resource, setResource] = useState<CourseAdditionalResource>(resourceInitialDto);
  const [expandedResourceTypes, setExpandedResourceTypes] = useState<{ [key: string]: boolean }>(() => {
    let storedData = localStorage.getItem('expandedResourceTypes');
    if (!storedData) storedData = JSON.stringify({});
    return JSON.parse(storedData);
  });
  const [contentAccordionData, setContentAccordionData] = useState<{ expanded: boolean, list: Array<string | null> }>(() => {
    let storedData = localStorage.getItem('contentAccordionData');
    if (!storedData) storedData = JSON.stringify({ expanded: true, list: ['additional_resource_id'] });
    return JSON.parse(storedData);
  });

  const handleResourseTypeChange = (isExpanded: boolean, resourceType: string) => {
    setExpandedResourceTypes((prev) => ({
      ...prev,
      [resourceType]: isExpanded,
    }));
    localStorage.setItem('expandedResourceTypes', JSON.stringify(expandedResourceTypes));
  };
  const handleChange = (_, isExpanded: boolean) => {
    let { list } = { ...contentAccordionData };
    if (isExpanded) {
      list = [...list, 'additional_resource_id'];
    } else {
      list = [...contentAccordionData.list].filter((x: any) => x !== 'additional_resource_id');
    }
    setContentAccordionData({ expanded: false, list });
    localStorage.setItem('contentAccordionData', JSON.stringify({ expanded: false, list }));
  };

  const handleToggleFileViewerModal = (modalState: boolean, resource?: CourseAdditionalResource) => {
    if (resource) setResource(resource);
    setOpenFileModal(modalState);
  };

  const groupedResources: { [key: string]: CourseAdditionalResource[] } = {};

  resourceList.forEach((resource) => {
    const resourceType = resource.resourceType || '';
    if (!groupedResources[resourceType]) {
      groupedResources[resourceType] = [];
    }
    groupedResources[resourceType].push(resource);
  });

  return (
    <Accordion
      expanded={contentAccordionData.list.includes('additional_resource_id')}
      onChange={(event, isExpanded) => handleChange(event, isExpanded)}
      disableGutters={true}
      sx={{
        backgroundColor: common.rootBgSecondary,
        border: `1px solid ${common.borderSecondary}`,
        borderRadius: 1,
        mt: 3,
      }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        sx={{
          display: 'flex',
          justifyContent: "space-between"
        }}
      >
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Typography sx={{
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "18px"
          }}>
            {t('enum:ADDITIONAL_RESOURCES')}
          </Typography>
          {contentAccordionData.list.includes('additional_resource_id') ?
            <ExpandMoreIcon /> :
            <KeyboardArrowRightIcon />
          }
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
        {Object.entries(groupedResources).map(([resourceType, resources]) => (
          <Accordion key={resourceType} disableGutters expanded={expandedResourceTypes[resourceType]}>
            <AccordionSummary onClick={() => handleResourseTypeChange(!expandedResourceTypes[resourceType], resourceType)}>
              <Box>{resourceType === CourseAdditionalResourceResourceTypeEnum.TEXT && <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><TitleIcon /> {t(`enum:TEXTS`)} </Box>}</Box>
              <Box>{resourceType === CourseAdditionalResourceResourceTypeEnum.FILE && <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><AttachmentOutlinedIcon /> {t(`enum:FILES`)} </Box>}</Box>
              <Box>{resourceType === CourseAdditionalResourceResourceTypeEnum.LINK && <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><LinkIcon /> {t(`enum:LINKS`)} </Box>}</Box>
              {expandedResourceTypes[resourceType] ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
            </AccordionSummary>
            <AccordionDetails>
              {resources.map((resource) => (
                <Box
                  key={resource.id}
                  sx={{
                    display: "flex",
                    gap: 1,
                    px: 2,
                    py: 1,
                    borderTop: `1px solid ${common.borderSecondary}`,
                    backgroundColor: common.fontWhite
                  }}
                >
                  {resource.name && getFileType(resource.name) === 'pdf' && <PDFIcon />}
                  {resource.name && getFileType(resource.name) === 'image' && <PhotoOutlinedIcon />}
                  {resource.name && getFileType(resource.name) === 'audio' && <PlayCircleOutlinedIcon />}
                  {resource.resourceType === CourseAdditionalResourceResourceTypeEnum.TEXT && <TitleIcon />}
                  {resource.link && <LinkIcon />}
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    onClick={resource?.link ? () => window.open(`${resource.link}`, "_blank") : () => handleToggleFileViewerModal(true, resource)}
                  >
                    {resource.name}
                  </Typography>
                  <Box
                    sx={{ marginLeft: 'auto', display: "flex", gap: 0.5 }}
                  >
                    <Box>
                      {resource.status === "SAVED" ?
                        <RemoveCircleOutlineIcon /> :
                        <CheckCircleIcon sx={{ color: `${common.primaryColor}` }} />
                      }
                    </Box>
                    <Box
                      onClick={(event) => handleToggleActionMenu(event, resource)}
                    >
                      <MoreVertIcon
                        sx={{ color: common.primaryColor, cursor: 'pointer' }}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionDetails>
      {openFileModal &&
        <FileViewerModal
          openModal={openFileModal}
          handleToggleModal={handleToggleFileViewerModal}
          file={resource}
        />
      }
    </Accordion>
  )
}

export default List;