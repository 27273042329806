import { IUserFilter } from "@interfaces/index";

export interface DTO {
  roleType: string | null;
  userIds: string[];
  courseId: string | null;
  classId: string | null;
  email?: string | null;
  name?: string | null;
  lastName?: string | null;
  patronymic?: string | null;
}

export interface IGroupDto {
  id: string | null;
  name: string | null;
  capacity: number | null;
  course: {
    id: null;
  };
  members: Array<any>;
  externalId?: string | null;
  memberSum?: number;
}

export const initialDto: DTO = {
  roleType: null,
  userIds: [],
  courseId: null,
  classId: null,
  email: null,
  name:null,
  lastName:null,
  patronymic:null
};

export const groupInitialDto: IGroupDto = {
  id: null,
  course: { id: null },
  name: null,
  capacity: null,
  members: [],
  externalId: null,
};

export const userFilterInitialDto: IUserFilter = {
  roleCode: null,
};
