import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';

import { serviceTask } from '@services/task';
import TextReader from '@ui/TextReader';

const QuizResult = ({ showCorrectAnswer, attemptResult, questions, currentQuestionId, goToQuestion }) => {
  const { t } = useTranslation(['enum', 'course']);
  const { common } = useTheme().palette;
  const [question, setQuestion] = useState<any>();
  const [questionNumber, setQuestionNumber] = useState<number>(1);

  useEffect(() => {
    const currentQuestion = attemptResult?.answerHistory.find((x: any) => x.id === currentQuestionId);
    const queryParams = showCorrectAnswer ? { showCorrectAnswer } : undefined;
    serviceTask.getQuestionById(currentQuestionId, queryParams).then(res => {
      if (res.status === 200) {
        setQuestion({ ...res.data, ...currentQuestion });
      }
    })
  }, [attemptResult, currentQuestionId, showCorrectAnswer]);

  return (
    <Box sx={{ width: '100%', height: 'auto', py: 3 }}>
      <Box sx={{ display: 'flex' }}>
        {questions.map((item: any, index: any) => {
          const answer = attemptResult.answerHistory.find((x: any) => x.id === item.questionId);
          let bgColor = common.white;
          if (answer?.takenPoint === answer?.point) bgColor = common.greenColor;
          else if (answer?.takenPoint >= answer?.point / 2) bgColor = `linear-gradient(to right bottom, ${common.errorColor} 50%, ${common.greenColor} 50%)`;
          else bgColor = common.errorColor;
          const backgroundColor = !item.answered ? common.errorColor : bgColor;
          return (
            <Box
              key={item.questionId}
              sx={{
                border: `1px solid #747474`,
                width: '32px',
                height: '32px',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mr: '10px',
                fontSize: '12px',
                lineHeight: '14px',
                cursor: 'pointer',
                color: common.white,
                background: backgroundColor,
                "&:hover": {
                  filter: 'brightness(0.9)'
                },
              }}
              onClick={() => {
                setQuestionNumber(index + 1);
                goToQuestion(item.questionId);
              }}
            >
              {index + 1}
            </Box>
          );
        })}
      </Box>
      <Box>
        <Box sx={{ border: '1px solid #D4D2D5', mt: '26px', py: '8px', pl: '24px', pr: '48px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: common.secondaryColor }}>
          <Box sx={{ display: 'block' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>{t('course:test.QUESTION')} {questionNumber}</Typography>
            <Typography>{t('course:test.QUESTION_TYPE')} | {t(`${question?.type}`)}</Typography>
          </Box>
          <Typography sx={{ fontWeight: 500, fontSize: '20px', lineHeight: '21px', }}>
            {t('course:task.POINT')}: {question?.takenPoint ? question.takenPoint : 0}/{question?.point}
          </Typography>
        </Box>
        <Box sx={{ border: '1px solid #D4D2D5', p: '20px' }}>
          <TextReader htmlContent={question?.content} hideBorder />
          {question?.type === "SEQUENCE" ? (
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: showCorrectAnswer ? '50%' : '100%' }}>
                {question.answers.sort((a: any, b: any) => a.userOrder - b.userOrder).map((item: any, index: number) => {
                  return (
                    <Box key={item.id} sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                      <Typography sx={{ fontWeight: 500, fontSize: '20px', mr: 1 }}>{index + 1}</Typography>
                      <TextReader htmlContent={item?.answer} hideBorder />
                    </Box>
                  )
                })}
              </Box>
              {showCorrectAnswer &&
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                  {question.answers.sort((a: any, b: any) => a.order - b.order).map((item: any) => {
                    return (
                      <Box key={item.id} sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                        <TextReader htmlContent={item?.answer} hideBorder />
                      </Box>
                    )
                  })}
                </Box>
              }
            </Box>
          ) :
            question?.type === "MAPPING" ? (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: showCorrectAnswer ? '33%' : '50%' }}>
                  {question?.children.map((item: any, index: number) => {
                    return (
                      <Box key={item.id} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 500, fontSize: '20px', mr: 3 }}>{index + 1}</Typography>
                        <TextReader htmlContent={item.content} hideBorder />
                      </Box>
                    )
                  })}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: showCorrectAnswer ? '33%' : '50%' }}>
                  {question.answers.map((item: any) => {
                    return (
                      <Box key={item.id} sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                        <TextReader htmlContent={item?.answer} hideBorder />
                      </Box>
                    )
                  })}
                </Box>
                {showCorrectAnswer &&
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
                    {question.children.map((item: any) => {
                      return (
                        <Box key={item.id} sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                          <TextReader htmlContent={item.answers[0].answer} hideBorder />
                        </Box>
                      )
                    })}
                  </Box>
                }
              </Box>
            ) :
              question?.answers.map((item: any) => {
                return (
                  <Box key={item.id} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {item?.userChoice ?
                      <RadioButtonCheckedOutlinedIcon
                        sx={{ color: showCorrectAnswer ? item?.correct ? common.greenColor : common.errorColor : common.primaryColor }}
                      /> :
                      <RadioButtonUncheckedOutlinedIcon
                        sx={{ color: item?.correct && showCorrectAnswer ? common.greenColor : common.primaryColor }}
                      />
                    }
                    <TextReader htmlContent={item.answer} hideBorder />
                  </Box>
                )
              })
          }
        </Box >
      </Box>
    </Box>
  )
}

export default QuizResult;