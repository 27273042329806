import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, useTheme } from "@mui/material";
import { Formik, Form as FormikForm } from "formik";
import SchoolIcon from '@assets/images/school.png'
import GlobusIcon from '@assets/images/globus.png'

import { serviceCourse } from "@services/course";
import { serviceDict } from "@services/dictionary";
import { useBreadcrumbs } from "@context/BreadcrumbsContext";
import CustomModal from "@components/Modal";
import FormikAutocomplete from "@ui/formik/Autocomplete";
import CustomButton from "@ui/Button";
import FormikInput from "@ui/formik/Input";
import { ICourse, TypeCode } from "@interfaces/index";
import { validationSchema } from "./validaton";
import { courseInitialDto } from "@pages/myCourses/models";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@store/hooks";

interface Props {
  open: boolean;
  handleToggleModal: any;
}

const CreateCourseModal = ({ open, handleToggleModal }: Props) => {
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const [courseTypeOptions, setCourseTypeOptions] = useState<any>([]);
  const { setBreadcrumbs } = useBreadcrumbs();
  const { t } = useTranslation(["common", "course"]);
  const orgId = useAppSelector(
    (store) => store.account.profileInfo.authorityInfo.orgId
  );
  useEffect(() => {
    serviceDict.getTypeOptionList().then((res) => {
      let options: any = [];
      res.data.forEach((type: any) => {
        options.push({
          id: type.id,
          text: type.code,
        });
      });
      setCourseTypeOptions(
        orgId === 0
          ? options.filter((item) => item?.text === TypeCode.FREE)
          : options
      );
    });
  }, [orgId]);

  const setBreadcrumbsAndNavigate = (courseName: string, courseId: string) => {
    const crumb = {
      level: 1,
      page: "course",
      label: `${courseName}`,
      path: `/myCourses/${courseId}`,
      tabNum: null,
    };
    setBreadcrumbs(courseId, [crumb]);

    navigate(`/myCourses/${courseId}`);
  };

  return (
    <CustomModal open={open} handleToggleModal={handleToggleModal} width={494}>
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "36px",
            color: "#000000",
            display: "flex",
            alignItems: "center",
            mb: "24px",
          }}
        >
          {t("course:COURSE_CREATION")}
        </Typography>
        <Formik
          initialValues={courseInitialDto}
          enableReinitialize
          validationSchema={validationSchema(t)}
          onSubmit={(values: ICourse) => {
            serviceCourse.createCourse(values).then((res) => {
              if (res.status === 200) {
                setBreadcrumbsAndNavigate(values.name || "", res.data.id);
              }
            });
          }}
        >
          {({ submitForm, dirty, isValid, setFieldValue, values }) => (
            <FormikForm>
              <Box sx={{ mb: "20px" }}>
                <FormikInput
                  label={t("course:COURSE_NAME")}
                  name="name"
                  isRequired
                />
              </Box>
              <Box>
                <Typography mb={1}>{t("course:COURSE_TYPE")}<span style={{color: common.errorColor}}>*</span></Typography>
                <Box>
                  { (courseTypeOptions && courseTypeOptions.length) && courseTypeOptions.map((option, idx) => {
                    return (
                      <Box 
                        key={option.id} 
                        sx={{
                          display: 'flex',
                          alignItems: 'center', 
                          gap: 2, 
                          border: `1px solid ${values.typeId === option.id ? common.primaryColor : common.strokeSecondary}`, 
                          p: '10px', 
                          borderRadius: '4px', 
                          cursor: 'pointer', 
                          ':hover': {borderColor: common.primaryColor},
                          mb: idx === 0 ? 1 : 0,
                          transition: 'all ease-in .2s',
                          background: values.typeId === option.id ? common.tableHeaderColor : 'transperent'
                        }}
                        onClick={() => {
                          setFieldValue('typeId', option.id)
                          setFieldValue('typeCode', option.text)
                        }}
                      >
                        {
                          option.text === TypeCode.EDU 
                          ?
                          <img src={SchoolIcon} alt={option.text} style={{maxWidth: '40px', maxHeight: '40px'}} />
                          :
                          <img src={GlobusIcon} alt={option.text} style={{maxWidth: '34px', maxHeight: '40px'}} />
                        }
                        <span >
                          <Typography variant="h1">{ t(`enum:${option.text}`) }</Typography>
                          <Typography variant="h2" fontSize={'14px'}>
                            { option.text === TypeCode.EDU 
                              ?
                              t('course:ESPECIALLY_FOR_STUDENTS_OF_YOUR_UNIVERSITY')
                              :
                              t('course:YOUR_PERSONAL_COURSE_FOR_PEOPLE_FROM_ALL_WORLD')
                            }
                          </Typography>
                        </span>
                      </Box>
                    )
                  }) }
                </Box>
              </Box>
              <Box
                sx={{
                  mt: "24px",
                  display: "flex",
                  gap: 1,
                }}
              >
                <CustomButton
                  backgroundColor={common.btnCancel}
                  fontColor={common.primaryColor}
                  borderRadius="6px"
                  height="40px"
                  sx={{
                    alignItems: "center",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={() => handleToggleModal(false)}
                >
                  {t("common:actions.CANCEL")}
                </CustomButton>
                <CustomButton
                  backgroundColor={common.primaryColor}
                  fontColor={common.fontWhite}
                  borderRadius="6px"
                  height="40px"
                  sx={{
                    alignItems: "center",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  disabled={!(isValid && dirty)}
                  onClick={submitForm}
                >
                  {t("common:actions.CREATE")}
                </CustomButton>
              </Box>
            </FormikForm>
          )}
        </Formik>
      </>
    </CustomModal>
  );
};

export default CreateCourseModal;
