import * as yup from "yup";

const commonValidationSchema = (t: any) => yup.object({
  lastName: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  firstName: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  password: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  repeatPassword: yup.string()
    .nullable()
    .required(t('common:validation.MANDATORY_FIELD'))
    .oneOf([yup.ref('password'), null], t('registration:PASSWORD_CONFIRMATION_IS_INCORRECT')),
  privacyPolicy: yup.boolean().oneOf([true])
});

export const regWithEmailValidationSchema = (t: any) => commonValidationSchema(t).shape({
  email: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')).email(t('common:validation.FORMAT_IS_INCORRECT')),
});

export const regWithPhoneNumberValidationSchema = (t: any) => commonValidationSchema(t).shape({
  phoneNumber: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
});