import CustomModal from '@components/Modal'
import { Box, Divider, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Formik, Form as FormikForm } from "formik";
import CustomButton from '@ui/Button';
import { useTranslation } from 'react-i18next';
import FormikAutocomplete from '@ui/formik/Autocomplete';
import DateTimePicker from "@ui/DateTimePicker";
import { initialDto, Issuance } from './models';
import FormikSwitchNew from '@ui/formik/SwitchNew';


export const IssuanceLicenseModal = ({ openIssuance, handleToggleModal }) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(['course', 'common', 'license'])
  const [initialData, setInitialData] = useState<Issuance>(initialDto)

  return (
    <CustomModal open={openIssuance}
      handleToggleModal={handleToggleModal}
      showCloseIcon
      py={3}
      px={3}
      width="500px">

      <Formik initialValues={initialData}
        enableReinitialize
        validationSchema={null}
        onSubmit={() => { }}>
        {({ values, submitForm, touched }) => {
          return (
            <FormikForm>
              <Box>
                <Typography fontSize="20px" fontWeight={500}>{t("license:ISSUANCE_LICENSE_ORGANIZATION")}</Typography>
                <Divider sx={{ margin: "16px 0px" }} />
                <FormikAutocomplete name='license' options={[]} label={t("license:LICENSE")} isRequired />
                <Box sx={{ mb: "24px" }} />
                <FormikAutocomplete name='organization' options={[]} label={t("license:ISSUE_ORGANIZATION")} isRequired />
                <Box sx={{ mb: "24px" }} />
                <Typography sx={{ mb: "8px" }}>{t("license:LICENSE_VALIDITY_PERIOD")}<span style={{ color: common.errorColor }}>*</span></Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: 2,
                  }}
                >
                  <DateTimePicker
                    name="startDate"
                    date={values.startDate}
                    shouldCloseOnSelect
                    isClearable
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                    }}
                    label={t("course:FROM")}
                    maxDate={values?.endDate ? new Date(values?.endDate) : null}
                  />
                  <DateTimePicker
                    name="endDate"
                    date={values.endDate}
                    shouldCloseOnSelect
                    isClearable
                    handleDateChange={() => { }}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                    }}
                    label={t("course:TO")}
                    minDate={values?.startDate ? new Date(values?.startDate) : new Date()}
                  />
                </Box>
                <Box sx={{ mb: "24px" }} />
                <FormikSwitchNew name='text' flexDirection="initial" justifyContent='space-between' labelStart={t("license:LICENSE_PAID")} />
                <Box sx={{ mb: "12px" }} />
                <FormikSwitchNew name='text' flexDirection="initial" justifyContent='space-between' labelStart={t("license:LICENSE_ACTIVATED")} />
                <Divider sx={{ margin: "16px 0px" }} />
                <Box display="flex" justifyContent="flex-end" gap={1}>
                  <CustomButton width="90px" borderRadius='6px' backgroundColor={common.white} fontColor={common.primaryColor}>
                    <Typography>{t("course:task.CANCELLATION")}</Typography>
                  </CustomButton>
                  <CustomButton width='145px' borderRadius='6px'>
                    <Typography>{t("common:actions.ADD")}</Typography>
                  </CustomButton>
                </Box>

              </Box>
            </FormikForm>)
        }}
      </Formik>
    </CustomModal>
  )
}
