import { Box, Typography, useTheme, Tab, Tabs, } from '@mui/material'
import CustomButton from '@ui/Button';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import { TabContext } from '@mui/lab';
import { DirectoryLicense } from './DirectoryLicense';
import { IssuedLicence } from './IssuedLicence';
import { AddLicenseModal } from './AddLicenseModal';
import { IssuanceLicenseModal } from './IssuanceLicenseModal';

const LicensePage = () => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["user", "common", "sideMenu", "course"]);
  const [typeViolations, setTypeViolations] = useState("directory");
  const [openAddLicense, setOpenAddLicense] = useState<boolean>(false)
  const [openIssuanceLicense, setOpenIssuanceLicense] = useState<boolean>(false)

  const handleChange = (event: React.SyntheticEvent, type: string) => {
    setTypeViolations(type);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography sx={{ fontWeight: "500", fontSize: "36px", lineHeight: "44px", mt: '20px' }}>
          {t('sideMenu:LICENSES')}
        </Typography>
        <Box display="flex" gap={1} alignItems="center">
          <CustomButton borderRadius='6px' width='135px' backgroundColor={common.white} fontColor={common.primaryColor} onClick={() => setOpenIssuanceLicense(!openIssuanceLicense)}>
            <DriveFileMoveOutlinedIcon fontSize='small' sx={{ marginRight: "4px" }} />
            {t("common:actions.ISSUE")}
          </CustomButton>
          <CustomButton sx={{ fontSize: "16px" }} borderRadius='6px' width='135px' onClick={() => setOpenAddLicense(!openAddLicense)}>
            <AddOutlinedIcon fontSize='small' sx={{ marginRight: "4px" }} />
            {t("common:actions.ADD")}
          </CustomButton>
        </Box>
      </Box>
      <TabContext value={typeViolations}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              padding: "10px 0 0 10px",
            }}
          >
            <Tabs
              onChange={handleChange}
              indicatorColor="secondary"
              value={typeViolations}
              sx={{
                "& .MuiTab-root.Mui-selected": {
                  color: "#1A141F",
                },
                "& .MuiTabs-indicator": {
                  background: common.borderPrimary,
                },
              }}
            >
              <Tab
                label={t("course:licenses.LICENSE_DIRECTORY")}
                value={"directory"}
                sx={{ textTransform: "none", fontSize: "18px" }}
              />
              <Tab
                label={t("course:licenses.ISSUED_LICENSES")}
                value={"issued"}
                sx={{ textTransform: "none", fontSize: "18px" }}
              />
            </Tabs>
          </Box>
        </Box>
      </TabContext>
      {typeViolations === "directory" && <DirectoryLicense />}
      {typeViolations === "issued" && <IssuedLicence />}
      {openAddLicense && <AddLicenseModal openAdd={openAddLicense} handleToggleModal={() => setOpenAddLicense(!openAddLicense)} />}
      {openIssuanceLicense && <IssuanceLicenseModal openIssuance={openIssuanceLicense} handleToggleModal={() => setOpenIssuanceLicense(!openIssuanceLicense)} />}
    </Box>
  )
}

export default LicensePage