import { IContent, IMaterial } from "@interfaces/index";

export const contentInitialDto: IContent = {
  id: null,
  courseId: null,
  index: null,
  name: null,
  parentId: null,
  parentType: null,
  type: null,
  status: null,
  format: null,
  children: []
}

export const materialInitialDto: IMaterial = {
  id: null,
  themeId: null,
  name: null,
  url: null,
  downloadable: true,
  fileId: null,
} 