import axios from "@utils/axios";

import { IParticipant, IUserFilter, IUserSearchItem } from "@interfaces/index";
import { rtkApi } from "@store/rtkApi";

interface Body {
  pageNumber: number;
  size: number;
  filter: any;
}

interface ResData {
  totalElements: number;
  dtoList: IParticipant[];
}

export const serviceMember = {
  getPaginatedMemberList: (bodyData: any) =>
    axios.post(`/api/course/member/findAll`, bodyData),
  getMemberList: (
    courseId: string,
    bodyData: Array<string>,
    queryParams = {}
  ) =>
    axios.post(`/api/course/member/list/${courseId}/findAll`, bodyData, {
      params: queryParams,
    }),
  getNonGroupMembers: (courseId: string, groupId?: string) =>
    axios.get(
      `/api/course/member/course/${courseId}/listeners${
        groupId ? `?groupId=${groupId}` : ""
      }`
    ),
  getNonRecipientListeners: (taskId: string, classId: string) =>
    axios.get(
      `/api/course/member/non-recipient/${taskId}/find?classId=${classId}`
    ),
  getNonMemberList: (courseId: string, bodyData: IUserFilter) =>
    axios.post(`/api/course/member/not-members/${courseId}/findAll`, bodyData),
  createMember: (bodyData: any) =>
    axios.post(`/api/course/member/create`, bodyData),
  sendInvitationMember: (bodyData: any) =>
    axios.post(`/api/course/invitation/member`, bodyData),
  updateStatus: (userId: string, classId: string, status: string) =>
    axios.put(
      `/api/course/member/update-status/${userId}?classId=${classId}&status=${status}`
    ),
  deleteMember: (id: string) => axios.delete(`/api/course/member/${id}`),
  getPaginatedGroupList: (bodyData: any, courseId: string) =>
    axios.post(`/api/course/member-group/${courseId}/findAll`, bodyData),
  getGroupList: (classId: string) =>
    axios.get(`/api/course/member-group/${classId}/findAll`),
  createGroup: (bodyData: any) =>
    axios.post(`/api/course/member-group/create`, bodyData),
  getMemberGroupById: (id: string) =>
    axios.get(`/api/course/member-group/${id}`),
  updateGroup: (bodyData: any) =>
    axios.put(`/api/course/member-group/update/${bodyData.id}`, bodyData),
  deleteGroup: (id: any) => axios.delete(`/api/course/member-group/${id}`),
  getCourseMember: (id: string) => axios.get(`/api/course/member/${id}`),
  transferGroup: (memberId: string, oldGroupId: string, newGroupId: string) =>
    axios.patch(
      `/api/course/member/move-from-group/${memberId}/${oldGroupId}/${newGroupId}`
    ),
  getMembersByStream: (body: string[], classId: string, groupId?: string) =>
    axios.post(
      `/api/course/member/list/${classId}/findAll${
        groupId ? `?groupId=${groupId}` : ""
      }`,
      body
    ),
};

export const serviceMemberApi = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getMemberList: build.mutation<
      IUserSearchItem[],
      { courseId: string; bodyData: Array<string>; queryParams: Object }
    >({
      query: ({ bodyData, courseId, queryParams }) => ({
        url: `course/member/list/${courseId}/findAll`,
        method: "POST",
        body: bodyData,
      }),
    }),
    createMember: build.mutation<
      void,
      { courseId: string; bodyData: any; queryParams: Object }
    >({
      query: ({ bodyData }) => ({
        url: `/course/member/create`,
        method: "POST",
        body: bodyData,
      }),
      invalidatesTags: [],
    }),
    getPaginatedMemberList: build.query<ResData, Body>({
      query: (params) => ({
        url: `/course/member/findAll`,
        method: "POST",
        body: params,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetMemberListMutation, useGetPaginatedMemberListQuery } =
  serviceMemberApi;
