import { IReview } from './../interfaces/index';
import { rtkApi } from "@store/rtkApi";

type ReviewPost=Omit<IReview,"id" | "userId" | "createdAt">

export const courseRatingServiceApi=rtkApi.injectEndpoints({
    endpoints:(build)=>({
        updateReview:build.mutation<Object,ReviewPost>({
            query:(body)=>({
                url:`course/course-review/create`,
                method:"POST",
                body:body
            }),
            invalidatesTags: ['Review'],
        }),
        getIsCurrentStudentLeftReview:build.query<Boolean,string>({
            query:(id)=>({
                url:`course/course-review/current-student/course/${id}`
            }),
            providesTags:()=>['Review']
        }),
        getMyReviewCourse:build.query<ReviewPost,string>({
            query:(id)=>({
                url:`course/course-review/my-review/course/${id}`
            }),
            providesTags:()=>['Review']
        })
    })
})

export const {
    useUpdateReviewMutation,
    useGetIsCurrentStudentLeftReviewQuery,
    useGetMyReviewCourseQuery
}=courseRatingServiceApi