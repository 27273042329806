/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import qs from 'qs';
// import ReactDOM from 'react-dom';
// import AuthMessageModal from '@components/modals/AuthMessageModal';

// axios.defaults.headers.common['X-Frame-Options'] = 'SAMEORIGIN';
axios.defaults.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' });

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    if (!config.headers) {
      config.headers = {};
    }
    const token = localStorage.getItem('plta_token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
)

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      (error?.response?.status === 409 || error?.response?.status === 400) &&
      !Array.isArray(error?.response?.data)
    ) {
      console.log(error?.response?.data ? error?.response?.data : '400 Bad Request');
    }

    if (error?.response?.status === 403) {
      return Promise.reject(error)
    }

    if (error?.response?.status === 401) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('contentAccordionData');
      if (!error?.request?.responseURL.includes('user/v1/auth/me')) {
        window.location.href = '/oauth2/authorization/gateway';
      }
      // if (!error?.request?.responseURL.includes('user/v1/auth/me')) {
      //   const modalRoot = document.getElementById('modal-root');
      //   if (modalRoot) {
      //     ReactDOM.render(
      //       <AuthMessageModal onClose={() => ReactDOM.unmountComponentAtNode(modalRoot)} />,
      //       modalRoot
      //     );
      //   }
      // } else {
      // }
    }

    return Promise.reject(error);
  },
);

export default axios;
