import { useState, useEffect } from 'react';
import { get, capitalize } from 'lodash';

import { serviceDict } from '@services/dictionary';
import { DictionaryCode } from '@interfaces/index';

interface Props {
  id: number | null;
}

const OrganizationCell = ({ id }: Props) => {
  const [orgName, setOrgName] = useState('--');
  const lang = localStorage.getItem("i18nextLng") || "kz";

  useEffect(() => {
    async function formatOrganization() {
      if (id) {
        const response = await serviceDict.getDictionary(DictionaryCode.BRANCH, id);
        const orgName = await get(response.data, `name${capitalize(lang)}`);
        setOrgName(id === 0 ? '--' : orgName);
      }
    }

    formatOrganization();
  }, [id, lang]);

  return (
    <>{orgName}</>
  );
}

export default OrganizationCell;