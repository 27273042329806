import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { useTheme } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import Message from "@components/Message";
import UnhiddenHints from "@components/UnhiddenHints";
import { FlowItem } from "@components/common/FlowItem/FlowItem";
import { CourseContext } from "@context/CourseContext";
import { CourseFormatType, TypeCode } from "@interfaces/index";
import { AlertColor, Avatar, Stack, Typography } from "@mui/material";
import { serviceCourse } from "@services/course";
import { useTranslation } from "react-i18next";
import AllParticipants from "./AllParticipants";
import { CreatedUserCourse } from "./CreatedUserCourse";
import Group from "./Group";

export enum TabsParticipants {
  ALL_PARTICIPANTS = "ALL_PARTICIPANTS",
  GROUP_LISTENER = "GROUP_LISTENER",
}

export const ParticipantsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState<TabsParticipants>(
    TabsParticipants.ALL_PARTICIPANTS
  );
  const currentTab = searchParams.get("participantsTab");
  const { common } = useTheme().palette;

  const { courseData } = useContext(CourseContext);
  const { t } = useTranslation(["course"]);
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [studyClasses, setStudyClasses] = useState<Array<any> | null>(null);
  const { courseId } = useParams();
  const [activeStudyClass, setActiveStudyClass] = useState<number>(0);

  useEffect(() => {
    async function getStudyClassList() {
      if (courseId) {
        const response = await serviceCourse.getAllStudyClassesByCourseId(
          courseId
        );
        setStudyClasses(response.data);
      }
    }

    getStudyClassList();
  }, [courseId]);

  const handleTabChange = (newValue: string) => {
    setTabValue(newValue as TabsParticipants);
    setSearchParams({ participantsTab: newValue });
  };

  useEffect(() => {
    if (currentTab) {
      setTabValue(currentTab as TabsParticipants);
    } else {
      setTabValue(TabsParticipants.ALL_PARTICIPANTS);
    }
  }, [currentTab]);

  return (
    <Box sx={{ width: "100%", minHeight: "650px" }}>
      <CreatedUserCourse courseData={courseData} />
      <Stack direction={"column"} gap={1}>
        {tabValue === TabsParticipants.ALL_PARTICIPANTS && (
          <UnhiddenHints text={t("course:messages.PARTICIPANTS_PAGE_HINT")} />
        )}
        {tabValue === TabsParticipants.ALL_PARTICIPANTS &&
          courseData?.formatType === CourseFormatType.ASYNC && (
            <UnhiddenHints
              text={t("course:messages.LIST_STUDENT_REGISTERED")}
            />
          )}
      </Stack>

      {messageOpen && (
        <Message
          type={messageType}
          message={message}
          setMessageOpen={setMessageOpen}
        />
      )}
      {studyClasses && studyClasses?.length > 0 && (
        <Box
          display="flex"
          gap={1}
          sx={{
            height: "60px",
            overflowX: "auto",
            "&::-webkit-scrollbar": { width: "6px", height: "3px" },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#E5E0EB",
              borderRadius: "6px",
            },
          }}
        >
          {studyClasses?.map((classes, index) => (
            <FlowItem
              activeStudyClass={activeStudyClass}
              index={index}
              key={classes.id}
              setActiveStudyClass={setActiveStudyClass}
            />
          ))}
        </Box>
      )}
      <Box
        sx={{
          backgroundColor: common.fontWhite,
          width: "100%",
          borderRadius: "12px",
          height: "auto",
          p: 4,
          border: `1px solid ${common.borderSecondary}`,
          mt: 2,
        }}
      >
        {studyClasses && studyClasses?.length > 0 ? (
          <TabContext value={tabValue}>
            <TabList
              onChange={(_, value: string) => handleTabChange(value)}
              textColor="inherit"
              indicatorColor="primary"
            >
              <Tab
                label={t("course:ALL")}
                value={TabsParticipants.ALL_PARTICIPANTS}
                sx={{
                  textTransform: "capitalize",
                  fontSize: { sm: "18px", xs: "16px" },
                  lineHeight: "24px",
                  fontWeight: 500,
                  color: "#1A141F",
                }}
              />
              {!(
                courseData?.typeCode === TypeCode.FREE &&
                courseData?.formatType === "ASYNC"
              ) && (
                <Tab
                  label={t("course:GROUP_OF_LISTENERS")}
                  value={TabsParticipants.GROUP_LISTENER}
                  sx={{
                    textTransform: "capitalize",
                    fontSize: { sm: "18px", xs: "16px" },
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "#1A141F",
                  }}
                />
              )}
            </TabList>
            <TabPanel value={TabsParticipants.ALL_PARTICIPANTS} sx={{ py: 0 }}>
              <AllParticipants
                studyClasses={
                  (studyClasses && studyClasses[activeStudyClass]) || []
                }
                courseData={courseData}
                setMessage={setMessage}
                setMessageType={setMessageType}
                setMessageOpen={setMessageOpen}
              />
            </TabPanel>
            <TabPanel value={TabsParticipants.GROUP_LISTENER}>
              <Group
                studyClasses={
                  (studyClasses && studyClasses[activeStudyClass]) || []
                }
                courseData={courseData}
                setMessage={setMessage}
                setMessageType={setMessageType}
                setMessageOpen={setMessageOpen}
              />
            </TabPanel>
          </TabContext>
        ) : (
          <Box
            sx={{
              my: "55px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Avatar
              alt="ParticipantsGroup-default-bg-img"
              src={require(`@assets/images/emptyParticipants.png`)}
              variant="square"
              sx={{
                height: { xs: "200px", sm: "321px" },
                width: { xs: "200px", sm: "321px" },
                mb: "8px",
              }}
            />
            <Typography
              sx={{ fontSize: { sm: "16px", xs: "14px" }, width: "350px" }}
            >
              {t("course:INFORMATION_ADD_PARTICIPANTS")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
