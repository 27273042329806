import SearchIcon from "@mui/icons-material/Search";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

import { TabListCourse } from "@components/common";
import { TypeCode } from "@interfaces/index";
import { courseApi } from "@services/index";
import { useQuery } from "@tanstack/react-query";
import CustomButton from "@ui/Button";
import { useNavigate } from "react-router-dom";
import { ConfirmationInvitation } from "./ConfirmationInvitation";
import CreateCourseModal from "./CreateCourseModal";
import List from "./List";

export interface CT_ValueState {
  edu: string | null;
  free: string | null;
}

const EducatorCourseList = () => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course", "enum"]);
  const navigate = useNavigate();
  const [courseStatus, setCourseStatus] = useState<CT_ValueState>({
    edu: null,
    free: null,
  });
  const [courseName, setCourseName] = useState<CT_ValueState>({
    edu: null,
    free: null,
  });


  const [courseType, setCourseType] = useState<string>("");
  const statusList = [
    {
      label: t("ALL"),
      code: "",
    },
    {
      label: t("SAVED_MULTIPLE"),
      code: "SAVED",
    },
    {
      label: t("PUBLISHED_MULTIPLE"),
      code: "PUBLISHED",
    },
    {
      label: t("WITHDRAWN_MULTIPLE"),
      code: "WITHDRAWN",
    },
    {
      label: t("CLOSED_MULTIPLE"),
      code: "CLOSED",
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const handleToggleModal = (modalState: boolean) => setOpenModal(modalState);

  const searchByName = (searchValue: string) => {
    courseType === TypeCode.EDU
      ? setCourseName({ ...courseName, edu: searchValue })
      : setCourseName({ ...courseName, free: searchValue });
  };

  const { data: memberConfirmation } = useQuery({
    queryKey: ["memberConfirmation"],
    queryFn: () => courseApi.getConfirmationMember(),
    enabled: courseType === TypeCode.FREE,
  });
  const { data: authorConfirmation } = useQuery({
    queryKey: ["authorConfirmation"],
    queryFn: () => courseApi.getConfirmationAuthor(),
    enabled: courseType === TypeCode.FREE,
  });
  const { data: hasLimitedCourse } = useQuery({
    queryKey: ["hasLimitedCourse"],
    queryFn: () => courseApi.getHasLimitedCourse(),
    enabled: courseType === TypeCode.FREE,
  });

  return (
    <Box sx={{ maxWidth: "1880px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: { sm: "36px", xs: "28px" },
            lineHeight: "44px",
            alignItems: "center",
            mt: 2,
            mb: 2,
          }}
        >
          {t("course:listener.MY_COURSES")}
        </Typography>
        <CustomButton
          backgroundColor={common.primaryColor}
          fontColor={common.fontWhite}
          width="140px"
          borderRadius="6px"
          onClick={() => handleToggleModal(true)}
          mt={2}
        >
          {t("course:CREATE_COURSE")}
        </CustomButton>
      </Box>
      {hasLimitedCourse && (
        <Box
          sx={{
            marginLeft: "70%",
            position: "absolute",
            paddingTop: "12px",
            cursor: "pointer",
            zIndex: 1,
          }}
          onClick={() => navigate("/myCourses/educator/restricted")}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: common.primaryColor,
            }}
          >
            {t("course:RESTRICTED_COURSES")}
          </Typography>
        </Box>
      )}
      <TabContext value={courseType}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabListCourse
            onChange={(_, value: string) => setCourseType(value)}
            setCourseType={setCourseType}
          />
        </Box>
        {courseType === TypeCode.FREE &&
          authorConfirmation &&
          memberConfirmation &&
          (authorConfirmation?.length > 0 ||
            memberConfirmation?.length > 0) && (
            <ConfirmationInvitation
              authorConfirmation={authorConfirmation}
              memberConfirmation={memberConfirmation}
            />
          )}
        <TabPanel value={courseType} sx={{ px: 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
              width: "100%",
              height: "auto",
              minHeight: "650px",
            }}
          >
            <TabContext
              value={
                (courseType === TypeCode.EDU
                  ? courseStatus.edu
                  : courseStatus.free) || ""
              }
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <TabList
                  onChange={(_, value: string) =>
                    courseType === TypeCode.EDU
                      ? setCourseStatus({ ...courseStatus, edu: value })
                      : setCourseStatus({ ...courseStatus, free: value })
                  }
                  variant="scrollable"
                  sx={{
                    "& .MuiTab-root": {
                      minHeight: "34px",
                    },
                    "& .MuiTabs-flexContainer": {
                      gap: 1,
                    },
                    display: "flex",
                    alignItems: "center",
                  }}
                  TabIndicatorProps={{
                    style: {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      backgroundColor: common.secondaryColor,
                      opacity: 0.15,
                      border: `1px solid ${common.borderPrimary}`,
                      borderRadius: "6px",
                    },
                  }}
                >
                  {statusList.map((status) => {
                    return (
                      <Tab
                        key={status.code}
                        label={status.label}
                        value={status.code}
                        sx={{
                          fontSize: "14px",
                          color: "#686B81",
                          width: "auto",
                          height: "40px",
                          borderRadius: "6px",
                          backgroundColor: "#FFFFFF",
                          textTransform: "capitalize",
                          lineHeight: "14px",
                          transition: "transform 1s",
                          "&:hover": {
                            backgroundColor: "#F5EFFF",
                          },
                          "&.Mui-selected": {
                            backgroundColor: common.primaryColor,
                            color: "#FFFFFF",
                          },
                        }}
                      />
                    );
                  })}
                </TabList>

                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  color="primary"
                  sx={{
                    width: "30%",
                    height: "44px",
                    display: "flex",
                    backgroundColor: "#FFFFFF",
                    ml: "auto",
                    "& label.Mui-focused": { color: common.primaryColor },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: common.primaryColor,
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": { borderColor: common.primaryColor },
                      "&.Mui-focused fieldset": {
                        borderColor: common.primaryColor,
                      },
                    },
                  }}
                  placeholder={t("common:placeholders.SEARCH_BY_COURSE_NAME")}
                  name="searchValue"
                  value={
                    courseType === TypeCode.EDU
                      ? courseName.edu || ""
                      : courseName.free || ""
                  }
                  onChange={(e) => searchByName(e.target.value)}
                  InputProps={{
                    sx: {
                      height: "44px",
                      pr: 0.5,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box
                          sx={{
                            backgroundColor: common.primaryColor,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "45px",
                            height: "36px",
                            borderRadius: "6px",
                          }}
                        >
                          <SearchIcon
                            sx={{
                              color: "#FFFFFF",
                              width: "26px",
                              height: "26px",
                            }}
                          />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </TabContext>
            <List
              type={courseType}
              courseStatus={courseStatus}
              courseName={courseName}
              limitedAccess={false}
            />
          </Box>
        </TabPanel>
      </TabContext>
      {openModal && (
        <CreateCourseModal
          open={openModal}
          handleToggleModal={handleToggleModal}
        />
      )}
    </Box>
  );
};

export default memo(EducatorCourseList);
