import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Formik, Form as FormikForm } from "formik";
import { AlertColor, Box, Divider, Typography, useTheme } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import { serviceCourse } from '@services/course';
import CustomButton from '@ui/Button';
import { INote } from './models';
import { noteValidationSchema } from './validation';
import { NoteEditor } from '@ui/formik/NoteEditor';
import { CourseStatus, Role } from '@interfaces/index';
import { formatDateWithHourMinute } from '@utils/functions';

interface Props {
  initialData: INote;
  setInitialData: (initialData: INote) => void;
  courseId?: string;
  memberId?: string | null;
  setMessage: (message: string) => void;
  setMessageOpen: (open: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  noteId: string | null;
  setNoteId: (noteId: string | null) => void;
  noteList: INote[];
  setNoteList: (noteList: INote[]) => void;
}

const Form = ({ initialData, setInitialData, courseId, memberId, setMessage, setMessageOpen, setMessageType, noteId, setNoteId, noteList, setNoteList }: Props) => {
  const { t } = useTranslation(["common", "course", "enum"]);
  const { common } = useTheme().palette;
  const { classId, status } = useParams<{ classId: string, status: string }>();
  const [cancel, setCancel] = useState<boolean>(false);
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role } = profile.authorityInfo;
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={initialData}
      enableReinitialize
      validationSchema={noteValidationSchema}
      onSubmit={(values) => {
        if (courseId) { values.courseId = courseId }
        if (memberId) { values.memberId = memberId }
        if (initialData.themeId) { values.themeId = initialData.themeId }
        if (initialData.taskId) { values.taskId = initialData.taskId }
        if (noteId === null) {
          serviceCourse.createNote(values).then((res) => {
            if (res.status === 200) {
              setMessage(t("course:note.NOTE_CREATE_MESSAGE"));
              setMessageOpen(true);
              setMessageType("success");
              setTimeout(() => {
                setMessageOpen(false);
              }, 3000);
              setNoteId(res.data.id);
              setInitialData({ ...initialData, id: res.data.id, content: res.data.content });
              setNoteList([res.data, ...noteList]);
            }
          }).catch((err) => {
            if (err) {
              setMessage(t("course:note.NOTE_CREATE_ERROR"));
              setMessageOpen(true);
              setMessageType("error");
              setTimeout(() => {
                setMessageOpen(false);
              }, 3000);
            }
          })
        } else {
          values.id = noteId;
          serviceCourse.updateNote(values).then((res) => {
            if (res.status === 200) {
              setMessage(t("course:note.NOTE_UPDATE_MESSAGE"));
              setMessageOpen(true);
              setMessageType("success");
              setTimeout(() => {
                setMessageOpen(false);
              }, 3000);
              setInitialData({ ...initialData, id: res.data.id });
              const index = [...noteList].findIndex(
                (x) => x.id === initialData.id
              );
              setNoteList([
                res.data,
                ...noteList.slice(0, index),
                ...noteList.slice(index + 1),
              ]);
            }
          }).catch((err) => {
            setMessage(t("course:note.NOTE_UPDATE_ERROR"));
            setMessageOpen(true);
            setMessageType("error");
            setTimeout(() => {
              setMessageOpen(false);
            }, 3000);
          })
        }
      }}>
      {({ values, submitForm, dirty, isValid }) => {
        if (cancel) {
          values.content = initialData.content;
          setCancel(false);
        }
        return (
          <FormikForm>
            {(values?.taskId || values?.themeId) &&
              <Box 
                sx={{ 
                  background: "#FFFFFF", 
                  display: "flex", 
                  gap: 2, 
                  padding: "10px 0 10px 15px"
                }}
              >
                {values?.taskId &&
                  <Typography
                    sx={{ fontWeight: 500, '&:hover': { color: common.primaryColor, cursor: 'pointer' } }}
                    onClick={() => navigate(`/myCourses/${status}/${classId}/tasks/${values.taskId}`)}
                  >
                    {t('enum:TASK')}: {values?.taskName}
                  </Typography>
                }
                {values?.themeId &&
                  <Typography
                    sx={{ fontWeight: 500, '&:hover': { color: common.primaryColor, cursor: 'pointer' } }}
                    onClick={() => navigate(`/myCourses/${classId}/theme/${values.themeId}`)}
                  >
                    {t('course:theme.THEME')}: {values?.themeName}
                  </Typography>
                }
              </Box>
            }
            <Box sx={{ background: "#FFFFFF", display: "flex", gap: 2, padding: "10px 0 10px 15px" }}>
              <CalendarMonthOutlinedIcon />
              <Typography>{t('common:label.DATE')}:</Typography>
              <Typography>
                {values.updatedAt ? `${formatDateWithHourMinute(values.updatedAt)} ` : `${new Date().toLocaleDateString()}`}
              </Typography>
            </Box>
            <Divider />
            <NoteEditor name='content' icon={false} hideToolbar={status?.toUpperCase() !== CourseStatus.CURRENT} />
            {role === Role.LISTENER && status?.toUpperCase() === CourseStatus.CURRENT &&
              <>
                <Divider />
                <Box sx={{ background: "#FFFFFF", borderRadius: "0 0 6px 6px", padding: "10px" }}>
                  <Box display="flex" justifyContent="flex-end" gap={2}>
                    <Box>
                      <CustomButton
                        backgroundColor="#EAE3F9"
                        fontColor={common.primaryColor}
                        borderRadius="6px"
                        height="40px"
                        sx={{
                          alignItems: "center",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                        disabled={!(isValid && dirty)}
                        onClick={() => setCancel(true)}>
                        {t('common:actions.CANCEL')}
                      </CustomButton>
                    </Box>
                    <Box>
                      <CustomButton
                        backgroundColor={common.primaryColor}
                        fontColor={common.fontWhite}
                        borderRadius="6px"
                        height="40px"
                        sx={{ alignItems: "center", fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}
                        disabled={!(isValid && dirty)}
                        onClick={submitForm}
                      >
                        {t('common:actions.SAVE')}
                      </CustomButton>
                    </Box>
                  </Box>
                </Box>
              </>
            }
          </FormikForm >
        )
      }}
    </Formik >
  )
}

export default Form;