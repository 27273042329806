import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography, useTheme } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeIcon from "@mui/icons-material/DateRange";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { capitalize } from "lodash";

import { serviceCourse } from "@services/course";
import { useBreadcrumbs } from "@context/BreadcrumbsContext";
import RegisterToCourseModal from "@components/modals/RegisterToCourseModal";
import { PromoVideoModal } from "./PromoVideoModal";
import CustomButton from "@ui/Button";
import { formatDateWithHourMinute } from "@utils/functions";
import { CourseStatus, DurationType, IBreadcrumb, IDictionary, TypeCode } from "@interfaces/index";

const CourseMainInfo = ({
  courseInfo,
  setCourseInfo,
  contentSum,
  diffLevelList
}) => {
  const { t } = useTranslation(["course", "freeCourse"]);
  const { common } = useTheme().palette;
  const difficultyLevel = diffLevelList.find(
    (x: IDictionary) => x.id === courseInfo?.course?.difficultyLevelId
  );
  const lang = localStorage.getItem("i18nextLng") || "kz";
  const [openPromoVideo, setOpenPromoVideo] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumbs();
  const messageRef = useRef<any>(null);
  const classIdFromCatalog = searchParams.get("classId");
  const [isCourseCurrent, setIsCourseCurrent] = useState<boolean>(false);
  const [isRegistrationActive, setIsRegistrationActive] = useState<boolean>(false);
  const [openRegisterToCourseModal, setOpenRegisterToCourseModal] = useState<boolean>(false);
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [attention, setAttention] = useState<boolean>(false);
  const pltaId = JSON.parse(localStorage.getItem("userInfo") ?? '{}')?.pltaId;

  const handleCloseModal = () => {
    if (messageRef.current) {
      clearTimeout(messageRef.current)
      setSuccess(false)
      setOpenRegisterToCourseModal(false)
    }
  }

  const registerToCourse = () => {
    if (courseInfo.id) {
      serviceCourse
        .signUpToCourse(courseInfo.id)
        .then((res) => {
          if (res.status === 200) {
            setSuccess(true);
            setCourseInfo({ ...courseInfo, allStudentCount: { ...courseInfo }.allStudentCount + 1 })
            setMessage(t("course:messages.SUCESSFULLY_REGISTERED_TO_COURSE"));
            setIsRegistered(true);
          }
        })
        .catch((error) => {
          if (error.response.data.code === "MAX_STUDENTS_EXCEEDED") {
            setMessage(t(`course:messages.${error.response.data.code}`));
          } else {
            setMessage(t("course:messages.FAILED_TO_ENROLL_IN_COURSE"));
          }
          setAttention(true);
        })
        .finally(() => {
          messageRef.current = setTimeout(() => {
            setOpenRegisterToCourseModal(false);
            setSuccess(false);
            setAttention(false);
          }, 5000);
        });
    }
  };

  const cancelRegistration = () => {
    if (courseInfo.id) {
      serviceCourse
        .cancelClassRegistration(courseInfo.id)
        .then((res) => {
          if (res.status === 200) {
            setCourseInfo({ ...courseInfo, allStudentCount: { ...courseInfo }.allStudentCount - 1 })
            setSuccess(true);
            setMessage(t("course:messages.REGISTRATION_TO_COURSE_CANCELED"));
            setIsRegistered(false);
          }
        })
        .catch((err) => {
          setMessage(t("course:messages.FAILED_TO_CANCEL_COURSE_REGISTRATION"));
          setAttention(true);
        })
        .finally(() => {
          messageRef.current = setTimeout(() => {
            setOpenRegisterToCourseModal(false);
            setSuccess(false);
            setAttention(false);
          }, 2000);
        });
    }
  };

  useEffect(() => {
    if (courseInfo?.id && pltaId) {
      serviceCourse
        .getCourseMe(
          courseInfo?.id
        ).then((res) => {
          if (res.status === 200) {
            setIsRegistered(true);
          }
        })

      serviceCourse.getCourseDataByClassId(courseInfo.id).then((res) => {
        if (res.status === 200) {
          setIsCourseCurrent(res.data.status === CourseStatus.CURRENT);
        }
      });
    }
    if (courseInfo?.id) {
      serviceCourse.checkCourseRegAvailable(courseInfo.id).then(res => {
        if (res.status === 200) setIsRegistrationActive(res.data);
      })
    }
  }, [courseInfo?.id, pltaId]);

  const goToCourseMain = () => {
    const crumb: IBreadcrumb = {
      level: 1,
      page: "course",
      label: `${courseInfo?.course?.name}`,
      path: `/myCourses/current/${courseInfo?.id}`,
      tabNum: null,
    };
    if (courseInfo?.id) setBreadcrumbs(courseInfo.id, [crumb]);
    navigate(`/myCourses/current/${courseInfo?.id}`);
  };

  return (
    <Box
      sx={{
        width: "320px",
        position: "absolute",
        background: "#fff",
        borderRadius: "10px",
        marginTop: "40px",
      }}
    >
      <Box
        sx={{
          background: `url(https://www.shutterstock.com/image-photo/young-woman-learning-language-during-600nw-2267595915.jpg)`,
          objectFit: "cover",
          width: "320px",
          height: "143px",
          borderRadius: "10px 10px 0 0 ",
          padding: "50px 52px",
        }}
      >
        <Box
          display="flex"
          onClick={() => setOpenPromoVideo(!openPromoVideo)}
          gap={1}
          sx={{
            background: "#BABBC0",
            padding: "8px 16px",
            alignItems: "center",
            borderRadius: "10px",
            cursor: "pointer",
          }}
        >
          <PlayCircleOutlineOutlinedIcon />
          <Typography fontSize="14px">{t('course:COURSE_PROMO_VIDEO')}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          m: 2
        }}
      >
        {!classIdFromCatalog && courseInfo?.course?.typeCode === TypeCode.FREE && (
          <Typography
            sx={{ fontSize: "22px", fontWeight: 600, marginBottom: "16px" }}
          >
            {t('freeCourse:FOR_FREE')}
          </Typography>
        )}

        {courseInfo?.course?.typeCode === TypeCode.EDU && <Box display="flex" alignItems="center" gap={1}>
          <AccountCircleOutlinedIcon
            sx={{ fontSize: "20px", color: "#6E7C8D" }}
          />
          <Typography fontSize="14px" color="#6E7C8D">
            {t("course:listener.SIGNED_UP")}: {courseInfo?.allStudentCount || 0}/
            {courseInfo?.capacity}
          </Typography>
        </Box>}
        {courseInfo?.course?.typeCode === TypeCode.EDU &&
          <Box display="flex" alignItems="center" gap={1}>
            <BallotOutlinedIcon sx={{ fontSize: "20px", color: "#6E7C8D" }} />
            <Typography fontSize="14px" color="#6E7C8D">
              {t('course:CREDITS')}: {courseInfo?.course?.credits}
            </Typography>
          </Box>
        }
        {courseInfo?.course?.typeCode === TypeCode.FREE && <Box display="flex" alignItems="center" gap={1}>
          <TaskOutlinedIcon sx={{ fontSize: "20px", color: "#6E7C8D" }} />
          <Typography fontSize="14px" color="#6E7C8D">
            {t('course:task.ASSESSED_ASSIGNMENTS')}: {contentSum?.evaluatedTasksSum}
          </Typography>
        </Box>}
        {courseInfo?.course?.typeCode === TypeCode.FREE && <Box display="flex" alignItems="center" gap={1}>
          <QuizOutlinedIcon sx={{ fontSize: "20px", color: "#6E7C8D" }} />
          <Typography fontSize="14px" color="#6E7C8D">
            {t('course:task.SELF_TEST_ASSIGNMENTS')}: {contentSum?.selfTasksSum}
          </Typography>
        </Box>}
        {courseInfo?.course?.typeCode === TypeCode.FREE && <Box display="flex" alignItems="center" gap={1}>
          <SignalCellularAltOutlinedIcon
            sx={{ fontSize: "20px", color: "#6E7C8D" }}
          />
          <Typography fontSize="14px" color="#6E7C8D">
            {difficultyLevel && difficultyLevel[`name${capitalize(lang)}`]}
          </Typography>
        </Box>}
        {courseInfo?.course?.courseSetting?.certificateDisplay && <Box display="flex" alignItems="center" gap={1}>
          <BadgeOutlinedIcon sx={{ fontSize: "20px", color: "#6E7C8D" }} />
          <Typography fontSize="14px" color="#6E7C8D">{t('course:CERTIFICATE_ISSUANCE')}</Typography>
        </Box>}
        {courseInfo?.course?.courseSetting?.durationType === DurationType.INDEFINITE &&
          <Box display="flex" alignItems="center" gap={1}>
            <AccessTimeIcon sx={{ fontSize: "20px", color: "#6E7C8D" }} />
            <Typography fontSize="14px" color="#6E7C8D">
              {t('course:task.STUDY_HOURS')}: {contentSum?.estimatedTime ? Math.floor(contentSum?.estimatedTime / 60) : 0}
            </Typography>
          </Box>
        }
        {courseInfo?.course?.courseSetting?.durationType !== DurationType.INDEFINITE &&
          <>
            <Box display="flex" alignItems="center" gap={1}>
              <DateRangeIcon sx={{ fontSize: "20px", color: "#6E7C8D" }} />
              <Box>
                <Typography fontSize="14px" color="#6E7C8D">
                  {t("course:listener.PASS_PERIOD")}:
                </Typography>
                <Typography fontSize="14px" color="#6E7C8D">
                  {formatDateWithHourMinute(courseInfo?.startDate)} -{" "}
                  {formatDateWithHourMinute(courseInfo?.endDate)}
                </Typography>
              </Box>
            </Box>
            {courseInfo?.canSelfRegistration && (
              <Box display="flex" alignItems="center" gap={1}>
                <DateRangeIcon sx={{ fontSize: "20px", color: "#6E7C8D" }} />
                <Box>
                  <Typography fontSize="14px" color="#6E7C8D">
                    {t("course:REGISTRATION_PERIOD")}:
                  </Typography>
                  <Typography fontSize="14px" color="#6E7C8D">
                    {formatDateWithHourMinute(courseInfo?.regStartDate)} -{" "}
                    {formatDateWithHourMinute(courseInfo?.regEndDate)}
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        }
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
        >
          {isRegistered &&
            <>
              {isCourseCurrent &&
                <Box display="flex" gap={1} alignItems="center" mt={2}>
                  <CustomButton
                    onClick={() => goToCourseMain()}
                    borderRadius="10px"
                  >
                    Перейти к курсу
                  </CustomButton>
                  <Box
                    width="40px"
                    height="40px"
                    border={`1px solid ${common.primaryColor}`}
                    borderRadius="10px"
                    sx={{ cursor: "pointer" }}
                    padding="12px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FavoriteBorderOutlinedIcon
                      sx={{ stroke: common.primaryColor }}
                    />
                  </Box>
                </Box>
              }
              {isRegistrationActive &&
                <CustomButton
                  borderRadius="8px"
                  fontColor={common.primaryColor}
                  backgroundColor="rgba(246, 246, 246, 1)"
                  onClick={() => {
                    setSuccess(false)
                    setMessage(t("course:messages.ARE_YOU_SURE_WANT_TO_CANCEL_COURSE_REGISTRATION"));
                    setOpenRegisterToCourseModal(true);
                  }}
                >
                  {t("course:listener.CANCEL_COURSE_REGISTRATION")}
                </CustomButton>
              }
            </>
          }
          {!isRegistered && isRegistrationActive &&
            <Box display="flex" gap={1} alignItems="center" mt={2}>
              <CustomButton
                onClick={() => {
                  if (pltaId) {
                    setMessage(t("course:messages.ARE_YOU_SURE_WANT_TO_ENROLL_IN_COURSE"));
                    setOpenRegisterToCourseModal(true);
                  } else {
                    sessionStorage.setItem('defaultPageAfterAuth', window.location.pathname);
                    window.location.href = '/oauth2/authorization/gateway'
                  }
                }}
                borderRadius="10px"
              >
                {t("course:listener.SIGN_UP_FOR_THE_COURSE")}
              </CustomButton>
              <Box
                width="40px"
                height="40px"
                border={`1px solid ${common.primaryColor}`}
                borderRadius="10px"
                sx={{ cursor: "pointer" }}
                padding="12px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <FavoriteBorderOutlinedIcon
                  sx={{ stroke: common.primaryColor }}
                />
              </Box>
            </Box>
          }
        </Box>
        {openPromoVideo && (
          <PromoVideoModal
            open={openPromoVideo}
            videoId={courseInfo?.course?.promoVideoId}
            videoUrl={courseInfo?.course?.promoVideoUrl}
            handleToggleModal={() => setOpenPromoVideo(!openPromoVideo)}
          />
        )}
        {openRegisterToCourseModal && (
          <RegisterToCourseModal
            openModal={openRegisterToCourseModal}
            message={message}
            handleToggleModal={() => handleCloseModal()}
            registerToCourse={isRegistered ? cancelRegistration : registerToCourse}
            success={success}
            attention={attention}
          />
        )}
      </Box>
    </Box>
  );
};

export default CourseMainInfo;