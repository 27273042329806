import { ChangeEvent } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { useTranslation } from "react-i18next";
interface rowsPerPageOption {
  label: string;
  value: number
}
interface IPaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  handleChangePage: (page: number) => void;
  handleChangeRowsPerPage: (rowsSize: number) => void; // temporary tipization
  rowsPerPageOptions?: rowsPerPageOption[]
}

const Pagination = (props: IPaginationProps) => {
  const { count, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage,
    rowsPerPageOptions = [
      { label: "5", value: 5 },
      { label: "10", value: 10 },
      { label: "25", value: 25 },
      { label: "50", value: 50 },
      { label: "100", value: 100 },
    ] } = props;
  const { t } = useTranslation(['enum']);

  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      onPageChange={(_, page: number) => handleChangePage(page)}
      rowsPerPage={rowsPerPage}
      labelRowsPerPage={t('enum:LINES_PER_PAGE')}
      labelDisplayedRows={
        ({ from, to, count }) => t('enum:TABLE_PAGES', { from, to, count })
      }
      onRowsPerPageChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeRowsPerPage(Number(event.target.value))}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );
}


export default Pagination;