import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material';

const LoadingSpinner = ({ size = 16 }) => {
  const { common } = useTheme().palette;

  return (
    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress sx={{ color: common.primaryColor, fontSize: size }} />
    </Box>
  );
}

export default LoadingSpinner;