import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';

import { serviceFile } from '@services/file';
import { getBase64 } from '@utils/functions';
import { SxProps } from '@mui/material';

interface Props {
  id?: string | null;
  lastName?: string;
  sxProps: SxProps,
  variant?: "square" | "rounded" | "circular",
  userId?: string | null;
}

const AvatarPicture = ({ id, lastName, sxProps, variant = 'circular', userId = null }: Props) => {
  const [profileImg, setProfileImg] = useState<string | undefined>(lastName);
  
  useEffect(() => {
    async function formatProfilePhoto() {
      const response = id ? await serviceFile.getFileMimeTypePublic(id) : userId ? await serviceFile.getProfileImgByUserIdPublic(userId) : null;
      if (response) {
        const contentDisposition = response.headers['content-disposition'];
        const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        const file = new File([response.data], fileName);
        getBase64(file, url => {
          // setLoading(false);
          setProfileImg(url);
        })
      } else {
        setProfileImg(lastName);
      }
    }

    formatProfilePhoto();
  }, [id, userId, lastName]);

  return (
    <Avatar
      src={profileImg}
      alt={lastName}
      sx={sxProps}
      variant={variant}
    />
  );
}

export default AvatarPicture;