import { Box, Typography, useTheme } from "@mui/material";

import CustomModal from "@components/Modal";
import CustomButton from "@ui/Button";
import { useTranslation } from "react-i18next";

function SendInfoModal({
  handleToggleSendInfoModal,
  openSendInfoModal,
  handleToggleGeneratePasswordModal,
  handleSubmitGeneratePassword
}: any) {
  const { common } = useTheme().palette;
  const { t } = useTranslation(['common']);

  return (
    <CustomModal
      open={openSendInfoModal}
      handleToggleModal={handleToggleSendInfoModal}
      borderColor={common.primaryColor}
      width={515}
    >
      <>
        <Typography
          id="transition-modal-title"
          sx={{
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "26px",
            display: "flex",
            alignItems: "center",
            mb: "32px",
            textAlign: 'center'
          }}
        >
          {t('common:messages.SEND_INFO_ABOUT_GENERATED_LOGIN_TO_USER')}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <CustomButton
            onClick={(e) => {
              handleSubmitGeneratePassword(e, false);
              handleToggleSendInfoModal(false);
              handleToggleGeneratePasswordModal(false);
            }}
            width="209px"
            height="40px"
            borderRadius="8px"
            backgroundColor="#F6F6F6"
            fontColor={common.primaryColor}
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {t('common:NO')}
          </CustomButton>

          <CustomButton
            onClick={(e) => {
              handleSubmitGeneratePassword(e, true);
              handleToggleSendInfoModal(false);
              handleToggleGeneratePasswordModal(false);
            }}
            width="209px"
            height="40px"
            borderRadius="8px"
            backgroundColor="#FF0000"
            fontColor="#FFFFFF"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {t('common:YES')}
          </CustomButton>
        </Box>
      </>
    </CustomModal>
  );
}
export default SendInfoModal;
