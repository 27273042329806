import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import CustomModal from "@components/Modal";
import CustomButton from "@ui/Button";

interface Props {
  open: boolean;
  itemType?: string;
  handleToggleModal: (modalState: boolean) => void;
  deleteItem: () => void;
}

const DeleteModal = ({
  open,
  itemType = "ITEM",
  handleToggleModal,
  deleteItem
}: Props) => {
  const { t } = useTranslation(["common", "course"]);
  const { common } = useTheme().palette;

  return (
    <CustomModal
      open={open}
      handleToggleModal={handleToggleModal}
      width={515}
      borderColor={common.primaryColor}
    >
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            id="transition-modal-title"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              mx: "40px",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "26px",
              mb: "32px",
            }}
          >
            {t(`${(itemType === "ITEM" || itemType === "FILE") ? 'common:' : 'course:'}DELETE_${itemType}_CONFIRMATION_TEXT`)}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <CustomButton
            onClick={() => handleToggleModal(false)}
            width="209px"
            height="40px"
            borderRadius="8px"
            backgroundColor={common.btnCancel}
            fontColor={common.primaryColor}
            sx={{
              display: "flex",
              alignItems: "center",
              fontStyle: "normal",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {t('NO')}
          </CustomButton>
          <CustomButton
            onClick={deleteItem}
            width="209px"
            height="40px"
            borderRadius="8px"
            backgroundColor="#FF0000"
            fontColor={common.fontWhite}
            sx={{
              display: "flex",
              alignItems: "center",
              fontStyle: "normal",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {t('YES')}
          </CustomButton>
        </Box>
      </>
    </CustomModal>
  );
}

export default DeleteModal;