import { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';

import { serviceFile } from '@services/file';

const AudioPlayer = ({ fileId }) => {
  const [audioFile, setAudioFile] = useState<any>(null);
  const [audioFileName, setAudioFileName] = useState<string>('');

  useEffect(() => {
    serviceFile.getFileMimeTypePublic(fileId)
      .then(res => {
        if (res.status === 200) {
          const contentDisposition = res.headers['content-disposition'];
          const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          setAudioFileName(fileName);
          let reader = new FileReader();
          reader.readAsDataURL(res.data);
          reader.onloadend = () => {
            setAudioFile(reader.result);
          }
        }
      })
      .catch((error) => console.error('Error fetching audio:', error));

  }, [fileId]);

  return (
    <ReactAudioPlayer
      src={audioFile}
      autoPlay
      controls
      title={audioFileName}
      controlsList="nodownload"
    />
  )
}

export default AudioPlayer;