import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material/styles';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import LoginIcon from '@mui/icons-material/Login';
import { get, capitalize } from 'lodash';

import account from "@services/account";
import * as types from '@store/account/types';
import { IUserRole } from '@interfaces/index';
import CustomModal from '@components/Modal';
import OrganizationCell from '@components/OrganizationCell';

interface Props {
  openModal: boolean;
  handleToggleModal: (modalState: boolean) => void;
  userRoles: Array<IUserRole>;
  setUserRoles: (userRoles: Array<IUserRole>) => void;
}

const SwitchProfile = ({ openModal, handleToggleModal, userRoles, setUserRoles }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["profile", "user"]);
  const dispatch = useDispatch();
  const { common } = useTheme().palette;
  const lang = localStorage.getItem("i18nextLng") || "kz";

  const MY_COURSES_ROLE: Array<string | null> = ["EDUCATOR", "LISTENER", "MANAGER"];
  const COURSES_ROLE: Array<string | null> = ["ADMIN", "LOCAL_ADMIN"];

  const switchAccountProfile = (userRoleId: number) => {
    account.switchProfile(userRoleId).then(res => {
      let updatedUserRoles = [...userRoles].map(userRole => {
        if (userRole.current) {
          return { ...userRole, current: false }
        }
        return userRole;
      });
      const { role } = res.data.authorityInfo;
      dispatch({ type: types.account('UPDATE_PROFILE_INFO').SUCCESS, payload: res.data });
      setUserRoles([...updatedUserRoles].map(userRole => {
        if (userRole.id === userRoleId) {
          return { ...userRole, current: true }
        }
        return userRole;
      }));
      handleToggleModal(false);
      if (MY_COURSES_ROLE.includes(role)) {
        navigate('/myCourses');
      } else if (COURSES_ROLE.includes(role)) {
        navigate('/courses');
      } else {
        navigate('/');
      }
    });
  }

  return (
    <CustomModal
      open={openModal}
      handleToggleModal={handleToggleModal}
      // minWidth={450}
      closeIsControlled
      showCloseIcon
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: 'center'
          }}
        >
          <Typography
            id="modal-modal-title"
            sx={{
              fontWeight: 500,
              fontSize: "28px",
              lineHeight: "36px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("LOGIN_AS")}
          </Typography>
        </Box>
        <Typography sx={{ color: '#9C9C9C' }}>
          {t("CHOOSE_SYSTEM_ROLE")}
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            {userRoles.length > 0 &&
              <TableBody>
                {userRoles.map((userRole: any, index: any) => {
                  return (
                    <TableRow
                      key={`${index}_${userRole.id}`}
                      sx={{
                        background: userRole.current ? common.rootBgSecondary : 'none'
                      }}
                    >
                      <TableCell sx={{ pr: 0 }}>
                        {userRole.role.code === 'LISTENER' ?
                          <SchoolOutlinedIcon sx={{ color: common.primaryColor, fontSize: 32 }} /> :
                          <AccountCircleOutlinedIcon sx={{ color: common.primaryColor, fontSize: 32 }} />
                        }
                      </TableCell>
                      <TableCell sx={{ pl: 0 }}>
                        {get(userRole.role, `name${capitalize(lang)}`)}
                      </TableCell>
                      <TableCell>
                        <OrganizationCell id={userRole.orgId} />
                      </TableCell>
                      <TableCell>
                        {userRole.disabled ?
                          <span>{t('user:BLOCKED')}</span> :
                          <Button
                            sx={{
                              background: common.primaryColor,
                              "&:hover": {
                                backgroundColor: common.secondaryColor,
                              },
                              "&:disabled": {
                                backgroundColor: common.inputDisabledBg
                              },
                              width: '40px',
                              height: '40px'
                            }}
                            disabled={userRole.current}
                            onClick={() => switchAccountProfile(userRole.id)}
                          >
                            <LoginIcon sx={{ color: common.fontWhite }} />
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            }
          </Table>
        </TableContainer>
      </Box>
    </CustomModal>
  )
}

export default SwitchProfile;