import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';

import TextReader from '@ui/TextReader';

const CourseDescription = ({ description }) => {
  const { t } = useTranslation(["course"]);

  return (
    <Box>
      <Typography fontSize="32px" fontWeight={700}>{t("course:COURSE_DESCRIPTION")}</Typography>
      <TextReader htmlContent={description} hideBorder numberOfSymbols={300} />
    </Box>
  )
}

export default CourseDescription;
