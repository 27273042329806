import { Box, FormControlLabel, Radio, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Formik, Form as FormikForm } from "formik";
import FormikRadioGroup from '@ui/formik/RadioGroup';
import FormikAutocomplete from '@ui/formik/Autocomplete';
import { serviceUser } from '@services/user';
import { serviceDict } from '@services/dictionary';
import CustomButton from '@ui/Button';
import { useTranslation } from 'react-i18next';
import { serviceCourse } from '@services/course';
import { useQueryClient } from '@tanstack/react-query';
import { ConfirmationStatus } from '@interfaces/index';

export const ChooseAuthor = ({ courseInfo, handleToggleModal }) => {
  const { common } = useTheme().palette;
  const [searchName, setSearchName] = useState<string | null>(null)
  const [userList, setUserList] = useState()
  const [orgList, setOrgList] = useState()
  const queryClient = useQueryClient();
  const { t } = useTranslation(["user", "common", "course", "enum", "dictionary"]);

  useEffect(() => {
    serviceUser.getUserListByFilter({
      pageNumber: 0,
      size: 20,
      filter: { search: searchName || "", roleCodes: ["EDUCATOR"] }
    }).then((res) => {
      if (res.status === 200) {
        setUserList(res.data.dtoList)
      }
    })
  }, [searchName, courseInfo?.courseSetting?.branchId])

  useEffect(() => {
    serviceDict.getDictionaryList(`BRANCH`, {
      pageNumber: 0,
      size: 100,
      filter: { search: searchName }
    }).then((res) => {
      if (res.status === 200) {
        setOrgList(res.data.dtoList)
      }
    })
  }, [searchName])

  const filterOptions = (options, { inputValue }) => {
    return options.filter(option =>
      option?.fullName?.toLowerCase().includes(inputValue?.toLowerCase()) ||
      option?.username?.toLowerCase().includes(inputValue?.toLowerCase())
    );
  };

  return (
    <>
      <Formik
        initialValues={{ author: "USER", authorName: { id: null, fullName: null, nameRu: null } }}
        enableReinitialize
        validationSchema={null}
        onSubmit={(values) => {
          const reqBody = values.author === "USER" ? {
            courseId: courseInfo.id,
            userId: values?.authorName.id,
            username: values?.authorName.fullName,
            status: ConfirmationStatus.NOT_CONFIRMED
          } : {
            courseId: courseInfo.id,
            userId: values?.authorName.id,
            username: values?.authorName.nameRu,
            status: ConfirmationStatus.CONFIRMED
          }

          serviceCourse.creatAuthor(reqBody).then((res) => {
            if (res.status === 200) {
              queryClient.invalidateQueries({ queryKey: ['courseAuthorList'] })
              handleToggleModal(false, t('common:messages.DATA_CHANGED_SUCCESSFULLY'))
            }
          })
        }}
      >
        {({ values, submitForm }) => {
          return (
            <FormikForm>
              <Typography>{t("course:WHO_IS_AUTHOR_COURSE")}</Typography>
              <Box display="flex" gap={1}>
                <FormikRadioGroup
                  name="author"
                  options={[
                    { label: t("user:USER"), value: "USER" },
                    { label: t("user:ORGANIZATION"), value: "ORGANIZATION" }
                  ]} />
              </Box>
              <FormikAutocomplete
                name='authorName'
                sx={{ marginTop: "16px" }}
                options={values.author === "USER" ? userList : orgList}
                label={t("course:AUTHOR_COURSE")}
                onInputChange={(value) => setSearchName(value)}
                valueAsObject
                filterOptions={values.author === "USER" ? filterOptions : undefined}
                placeholder={values.author === "USER" ?
                  t("common:placeholders.ENTER_USER_FULL_NAME_OR_EMAIL") :
                  t("common:placeholders.ENTER_USER_FULL_NAME_ORGANIZATION")}
              />
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  marginTop: "16px"
                }}
              >
                <CustomButton
                  backgroundColor={common.btnCancel}
                  fontColor={common.primaryColor}
                  borderRadius="6px"
                  height="40px"
                  sx={{
                    alignItems: "center",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={() => handleToggleModal(false)}
                >
                  {t('common:actions.CANCEL')}
                </CustomButton>
                <CustomButton
                  backgroundColor={common.primaryColor}
                  fontColor={common.fontWhite}
                  borderRadius="6px"
                  height="40px"
                  onClick={submitForm}
                  sx={{
                    alignItems: "center",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {t('common:actions.SEND')}
                </CustomButton>
              </Box>

            </FormikForm>
          )
        }}
      </Formik>
    </>
  )
}
