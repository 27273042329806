
import { createSlice } from '@reduxjs/toolkit';
import { Reducer } from 'redux';

export interface NotificationState {
  notificationCount: number;
}

const initialState: NotificationState = {
  notificationCount: 0
};

export const notificationSlice=createSlice({
  name:'notification',
  initialState,
  reducers:{
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
  }
})


export const { actions: notificationActions } = notificationSlice;
export const { reducer: notificationReducer } = notificationSlice;

// const notificationReducer: Reducer<NotificationState> = (state = initialState, action) => {
//   switch (action.type) {
//     case 'NOTIFICATION_COUNT':
//       return {
//         ...state,
//         notificationCount: action.payload,
//       };
//     default:
//       return state;
//   }
// };

export default notificationReducer;