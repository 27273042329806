import Typography from '@mui/material/Typography';
import { Formik } from "formik";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CustomModal from '@components/Modal';
import { AnswerTypes, ICourseSetting, TaskFormat } from '@interfaces/index';
import { CourseTaskRecipientTypeEnum } from '@models/course-task-recipient';
import { serviceCourse } from '@services/course';
import { serviceTask } from '@services/task';
import { useTranslation } from 'react-i18next';
import { IStream } from '../../Theme/models';
import { questionRecipientsInitialDto } from '../models';
import AddRecipientForms from './AddRecipientsForms';
import { recipientValidationOfflineConferenceSchema, recipientValidationSchema } from './validation';
import { formatDateWithTimezone } from '@utils/functions';

interface IUseAddRecipientModalProps {
  onCreated: () => void;
  taskStatus: string;
  settingsData?: ICourseSetting,
  dateOfAccess?: string,
  taskType?: string,
  recipientsData?: any,
  classId: string | null,
  currentRecipient: any,
  taskName: string;
  answerType: string;
  currentStream: IStream;
}

const useAddRecipientModal = ({
  onCreated,
  taskStatus,
  settingsData,
  dateOfAccess,
  taskType,
  recipientsData,
  classId,
  currentRecipient,
  taskName,
  answerType,
  currentStream
}: IUseAddRecipientModalProps) => {
  const [initRecipientsData, setInitRecipientsData] = useState({ ...questionRecipientsInitialDto() })
  const [isOpen, setIsOpen] = useState(false)
  const handleOpenModal = () => setIsOpen(x => !x)
  const { courseId, taskId, taskFormat } = useParams();
  const { t } = useTranslation(["common"]);
  const [coordinatorMemberId, setCoordinatorMemberId] = useState('')
  const [meetingPlatform, setMeetingPlatform] = useState<any>([])

  const handleCreate = (data: any) => {
    if (courseId) {
      serviceTask.createTaskRecipients(data, classId || '')
        .then(() => {
          onCreated()
          handleOpenModal()
        })
    }
  }

  useEffect(() => {
    if (currentRecipient?.id)
      serviceTask.getRecipientById(currentRecipient?.id).then((res) => {
        if (res.status === 200) {
          setInitRecipientsData({
            ...res.data,
            isMeetingPlaceAssigned: !!res.data?.meetingStartDate,
            meetingDate: res.data?.meetingStartDate ?? "",
            meetingStartDate: res.data?.meetingStartDate,
            meetingEndDate: res.data?.meetingEndDate,
          })
        }
      })
  }, [currentRecipient?.id])

  useEffect(() => {
    if (classId) {
      serviceCourse.getCourseMe(classId).then((res) => {
        if (res.status === 200) {
          setCoordinatorMemberId(res.data.id)
        }
      })
    }
  }, [classId])

  useEffect(() => {
    serviceCourse.getMeetingPlatform().then((res) => { if (res.status === 200) setMeetingPlatform(res.data) });
  }, [])

  const AddRecipientsModal = (
    <CustomModal
      open={isOpen}
      handleToggleModal={() => {
        handleOpenModal();
        setInitRecipientsData({ ...questionRecipientsInitialDto(), })
      }}
      width={494}
    >
      <>
        <Typography sx={{ fontWeight: 500, fontSize: '28px', lineHeight: '36px' }}>
          {currentRecipient?.id ? t('common:actions.EDIT_RECIPIENT') : t('common:actions.ADD_RECIPIENT')}
        </Typography>
        <Formik
          initialValues={{
            ...initRecipientsData,
            platform: 1,
            hasDeadlineDate: true,
            type: CourseTaskRecipientTypeEnum.ALL,
            ...(currentRecipient ? { ...currentRecipient, members: [currentRecipient.member] } : null),
          } as any}
          enableReinitialize
          validationSchema={taskFormat === TaskFormat.ORAL &&
            answerType === AnswerTypes.ONLINE_CONFERENCE ?
            recipientValidationSchema(t) : recipientValidationOfflineConferenceSchema(t)}
          onSubmit={(values) => {
            const { type, members, group, hasDeadlineDate, dateOfDeadline, isRestrictByIp, ipRangeStart, ipRangeEnd, meetingStartDate, meetingEndDate, meetingDate, meetingPlace } = values
            if (!values.planConference) {
              if (!currentRecipient?.id) {
                handleCreate({
                  type,
                  members,
                  group,
                  hasDeadlineDate,
                  dateOfDeadline: formatDateWithTimezone(dateOfDeadline),
                  isRestrictByIp,
                  ipRangeStart,
                  ipRangeEnd,
                  taskId,
                  meetingStartDate: meetingDate && ((meetingDate ?? "") + "T" + (meetingStartDate?.split("T")?.[1] ?? "") ?? null),
                  meetingEndDate: meetingDate && ((meetingDate ?? "") + "T" + (meetingEndDate?.split("T")?.[1] ?? "") ?? null),
                  meetingPlace: meetingPlace ?? null
                })
              } else {
                // добавить редактирование как бэк будет готов
                serviceTask.updateTaskRecipient(currentRecipient?.id, values).then((res) => {
                  if (res.status === 200) { onCreated(); handleOpenModal() }
                })
              }
            } else {
              // добавить редактирование как бэк будет готов
              const startDate = `${values.conferenceDate.split("T")[0]}T${values.startTime.split("T")[1]}`
              const endDate = `${values.conferenceDate.split("T")[0]}T${values.endTime.split("T")[1]}`
              const platform = meetingPlatform.find(platform => platform.id === values.platform)
              const meeting = { classId, name: taskName, platform, title: taskName, startDate, endDate, coordinatorMemberId, link: values.link }
              if (!currentRecipient?.id) {
                handleCreate({
                  type,
                  members,
                  group,
                  hasDeadlineDate,
                  dateOfDeadline: formatDateWithTimezone(dateOfDeadline),
                  isRestrictByIp,
                  ipRangeStart,
                  ipRangeEnd,
                  meeting,
                  taskId
                })
              } else {
                // добавить редактирование как бэк будет готов
                serviceTask.updateTaskRecipient(currentRecipient?.id, {...values, meeting}).then((res) => {
                  if (res.status === 200) { onCreated(); handleOpenModal() }
                })
              }

            }
          }}
        >
          {() => {
            return <AddRecipientForms
              handleOpenModal={handleOpenModal}
              isOpen={isOpen}
              taskStatus={taskStatus}
              settingsData={settingsData}
              taskType={taskType}
              dateOfAccess={dateOfAccess}
              setInitRecipientsData={setInitRecipientsData}
              recipientsData={recipientsData}
              classId={classId || ''}
              currentRecipient={currentRecipient}
              meetingPlatform={meetingPlatform}
              answerType={answerType}
              currentStream={currentStream}
              initRecipientsData={initRecipientsData}
            />
          }}
        </Formik>
      </>
    </CustomModal>
  )

  return {
    AddRecipientsModal,
    handleOpenModal,
  }
}

export default useAddRecipientModal;