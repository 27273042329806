import { courseGradingApi } from '@services/index';
import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { useParams } from 'react-router-dom';
import { PieChart, Pie, Cell, Label } from "recharts";
import {
  useTheme,
  useMediaQuery,
  Box,
  Typography
} from "@mui/material";
import IconHints from '@components/IconHints';
import { useTranslation } from 'react-i18next';
import { AboutProgressPassedOrNotPassed } from './AboutProgressPassedOrNotPassed';

export const PassedNotPassedPieChart = ({ courseResultDto }) => {
  const theme = useTheme();
  const { t } = useTranslation(["course"]);
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const colorFill = courseResultDto?.currentRate < 30 ? "#D62B20" :
    courseResultDto?.currentRate >= 30 && courseResultDto?.currentRate < 40 ?
      "#FD9C15" : courseResultDto?.currentRate >= 40 && courseResultDto?.currentRate < 50 ?
        "#EAC600" : "#00B998";

  return (
    <Box display="flex" flexDirection={{ xs: "column", lg: "row" }}>
      {courseResultDto?.tasks && courseResultDto?.tasks.filter((task) => task.taskUserPoint).length > 0 ?
        <PieChart
          width={matchesDownSm ? 250 : 300}
          height={matchesDownSm ? 250 : 300}
        >
          <Pie
            data={courseResultDto?.tasks}
            cx={matchesDownSm ? 110 : 160}
            cy={matchesDownSm ? 110 : 160}
            innerRadius={matchesDownSm ? 60 : 90}
            outerRadius={matchesDownSm ? 90 : 120}
            fill={colorFill}
            paddingAngle={5}
            dataKey="taskUserPoint"
          >
            {courseResultDto && (
              <Label
                value={`${courseResultDto?.currentRate}%`}
                position="center"
                fontSize={!matchesDownSm ? "25px" : "18px"}
              />
            )}
            <Cell key={`cell-1`} fill={colorFill} />
          </Pie>
        </PieChart> :
        <>
          <Box sx={{ width: matchesDownSm ? 200 : 250, height: matchesDownSm ? 200 : 250, borderRadius: "50%", background: "#D62B20" }} alignItems="center" marginTop="20px">
            <Box sx={{ width: matchesDownSm ? 150 : 200, height: matchesDownSm ? 150 : 200, borderRadius: "50%", background: "#FFF" }} margin="25px auto" alignItems="center">
              <Typography sx={{ padding: "35%", fontSize: "32px", fontWeight: 600, color: theme.palette.common.fontGray }}>0%</Typography>
            </Box>
          </Box>
        </>
      }
      <Box sx={{ padding: "40px 0 0 20px" }}>
        <AboutProgressPassedOrNotPassed />
      </Box>
    </Box>
  )
}
