import Box from "@mui/material/Box";
import { Formik, Form as FormikForm } from "formik";
import { useTranslation } from 'react-i18next';

import { serviceRole } from "@services/role";
import CustomButton from "@ui/Button";
import FormikInput from "@ui/formik/Input";
import FormikAutocomplete from "@ui/formik/Autocomplete";
import { IRole } from "interfaces";
import { validationSchema } from "./validation";

const Form = ({
  roleId,
  roleList,
  setRoleList,
  permissionList,
  initialData,
  closeModal
}: any) => {
  const { t } = useTranslation(["common", "dictionary"]);

  return (
    <Formik
      initialValues={initialData}
      enableReinitialize
      validationSchema={validationSchema(t)}
      onSubmit={(values: IRole) => {
        if (!roleId) {
          serviceRole.createRole(values).then((res) => {
            if (res.status === 200) {
              setRoleList([res.data, ...roleList]);
              closeModal(false);
            }
          });
        }
        else {
          serviceRole.updateRole(values).then((res) => {
            if (res.status === 200) {
              const index = [...roleList].findIndex(
                (x) => x.id === roleId
              );
              setRoleList([
                ...roleList.slice(0, index),
                res.data,
                ...roleList.slice(index + 1),
              ]);
              closeModal(false);
            }
          });
        }

      }}
    >
      {({ submitForm, isSubmitting }: any) => (
        <FormikForm>
          <FormikInput
            label={t('dictionary:NAME_IN_KAZAKH')}
            name="nameKz"
            isRequired
          />
          <FormikInput
            label={t('dictionary:NAME_IN_RUSSIAN')}
            name="nameRu"
            isRequired
          />
          <FormikInput
            label={t('dictionary:NAME_IN_ENGLISH')}
            name="nameEn"
            isRequired
          />
          <FormikAutocomplete
            name="permissionCodes"
            label={t('dictionary:RIGHTS')}
            options={permissionList}
            valueKey="code"
            multiple
          />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CustomButton
              width="150px"
              disabled={isSubmitting}
              onClick={submitForm}
              mt={2}
            >
              {!roleId ? `${t("common:actions.ADD")}` : `${t("common:actions.SAVE")}`}
            </CustomButton>
          </Box>
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;