import React, { useEffect, useState } from 'react'
import { TabContext } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Tabs, Tab, Typography, useTheme } from '@mui/material'

import { FinishedConference } from './FinishedConference';
import { PlanedConference } from './PlanedConference';

export const ConferenceCurrentCourses = () => {
  const { t } = useTranslation(["course"]);
  const { common } = useTheme().palette;
  const [searchParams, setSearchParams] = useSearchParams();
  const [typeCourse, setTypeCourse] = useState("planned");
  const currentTab = searchParams.get('typeConference');

  const handleChange = (event: React.SyntheticEvent, typeConference: string) => {
    setTypeCourse(typeConference);
    setSearchParams({ typeConference: typeConference });
  };

  useEffect(() => {
    if (currentTab) {
      setTypeCourse(currentTab);
    } else {
      setTypeCourse("planned");
    }
  }, [currentTab])
  return (
    <TabContext value={typeCourse}>
      <Box sx={{ width: "100%", background: "#fff" }}>
        <Box sx={{ padding: "10px 0 0 10px" }}>
          <Tabs
            onChange={handleChange}
            indicatorColor="secondary"
            value={typeCourse}
            sx={{ "& .MuiTab-root.Mui-selected": { color: "#1A141F" }, "& .MuiTabs-indicator": { background: common.borderPrimary } }}>
            <Tab label={t('course:PLANNED_MULTIPLE')} value={"planned"} sx={{ textTransform: "none", fontSize: { xs: '14px', sm: "18px" } }} />
            <Tab label={t('course:COMPLETED_MULTIPLE')} value={"finished"} sx={{ textTransform: "none", fontSize: { xs: '14px', sm: "18px" } }} />
          </Tabs>
        </Box>
      </Box>
      {typeCourse === "planned" && <PlanedConference />}
      {typeCourse === "finished" && <FinishedConference />}
    </TabContext>
  )
}
