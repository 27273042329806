import { Box, List, ListItem, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import { ListArray } from "@interfaces/index";

interface Props {
 
  svg?: any;
  list: ListArray[];
  title?:string;
}
const MeetingList = (props: Props) => {
  const { svg, list,title } = props;
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course"]);
  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "256px" },
        height: "auto",
        borderRadius: "12px",
        borderTop: `solid 12px ${common.primaryColor}`,
        backgroundColor: common.white,
        ml: { xs: 0, md: "26px" },
        py: 1,
      }}
    >
      <Box sx={{ display: "flex", gap: 2, mx: 2 }}>
        {svg && svg}
        <Typography
          sx={{ fontWeight: 500, fontSize: "16px", lineHeight: "24px" }}
        >
          {title}
        </Typography>
      </Box>
      <List>
        {list.map((item, index) => (
          <ListItem key={index}>
            <Typography
              sx={{
                color: common.borderSecondary,
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
              {item?.key}
            </Typography>
            <Box
              display={"flex"}
              gap={1}
              alignContent={"center"}
              sx={
                item?.colored
                  ? {
                      cursor: "pointer",
                      color: common.primaryColor,
                      fill: common.primaryColor,
                      fontWeight: "500",
                      fontSize:'14px'
                    }
                  : undefined
              }
              onClick={() => item && item.onClick && item?.onClick()}
            >
              <Typography
                sx={ item?.colored ?{
                  fontSize: "14px",
                  fontWeight: "500",
                }:{
                  fontWeight: "400",
                  color: common.borderSecondary,
                  fontSize: "12px",
                  lineHeight: "16px",
                }}
              >
                {item?.value}
              </Typography>
              {item?.svg && item.svg}
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default MeetingList;
