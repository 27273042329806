import CustomModal from '@components/Modal'
import { Box, Checkbox, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react'
import CustomButton from '@ui/Button';
import { serviceTask } from '@services/task';
import { serviceAero } from '@services/aero';
import { useParams } from 'react-router-dom';

interface Props {
  openModal: boolean;
  handleToggleModal: (modalState: boolean) => void;
  progressId: string
}

export const AeroRuleModal = ({ openModal, handleToggleModal, progressId }: Props) => {
  const { t } = useTranslation(["user", "common", "enum", "course", "aero"]);
  const { common } = useTheme().palette;
  const [agreed, setAgreed] = useState<boolean>(false)
  const { taskId, classId } = useParams();
  const location = window.location

  const startQuiz = () => {
    if (progressId) {
      serviceTask.startResumeQuiz(progressId).then(async res => {
        if (res.status === 200) {
          if (res.data.id) {
            aeroClick(res.data.id)
          }
        }
      })
    }

  }

  function aeroClick(quizId: string) {
    // Запуск
    serviceAero.getTokens(progressId, `${location.origin}/myCourses/${classId}/tasks/${taskId}/quiz/${quizId}`).then((res) => {
      if (res.status === 200) {
        fetch(`${res.data}`, {
          method: "GET",
          credentials: "include"
        }
        ).then((res) => res.json())
          .then((res) => {
            window.postMessage({ message: "APP_QUIZ_START", payload: { cheating_code: `${progressId}` } })
            //@ts-ignore
            const aero = new AeroLib.AeroProctoring(`${progressId}`);
            aero.startExam();
          })
      }
    })
  }
  return (
    <CustomModal
      open={openModal}
      handleToggleModal={handleToggleModal}
      borderColor={common.primaryColor}
      closeIsControlled
      showCloseIcon
      width={715}
    >
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '36px', textAlign: 'center' }}>
            {t('aero:RULE_AERO_TITLE')}
          </Box>
          <Typography fontSize="11px">- {t('aero:RULE_AERO_ACCOUNT')}</Typography>
          <Typography fontSize="11px">- {t('aero:RULE_AERO_VERIFICATION')}</Typography>
          <Typography fontSize="11px">- {t('aero:RULE_AERO_VISION_WEBCAMERA')}</Typography>
          <Typography fontSize="11px">- {t('aero:RULE_AERO_MICROPHONE')}</Typography>
          <Typography fontSize="11px">- {t('aero:RULE_AERO_WEBCAMERA')}</Typography>
          <Typography fontSize="11px">- {t('aero:RULE_AERO_DEVICE')}</Typography>
          <Typography fontSize="11px">- {t('aero:RULE_AERO_HELP')}</Typography>
          <Typography fontSize="11px">- {t('aero:RULE_AERO_OTHER_PEOPLE')}</Typography>
          <Typography fontSize="11px">- {t('aero:RULE_AERO_BOOK')}</Typography>
          <Typography fontSize="11px">- {t('aero:RULE_AERO_REQUIREMENTS')}</Typography>
          <Typography fontSize="11px">- {t('aero:RULE_AERO_GOOGLE')}</Typography>
          <Typography fontSize="11px">- {t('aero:RULE_AERO_WEBCAMERA_LAPTOP')}</Typography>
          <Typography fontSize="11px">- {t('aero:RULE_AERO_MICROPHONE_LAPTOP')}</Typography>
          <Typography fontSize="11px">- {t('aero:RULE_AERO_CHROME')}</Typography>
          <Typography fontSize="14px" fontWeight={600}>{t('aero:RULE_AERO_ZERO')}</Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: '24px' }}>
          </Box>
        </Box>
        <Box display="flex" gap={8}>
          <Checkbox
            checked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
            sx={{
              marginLeft: 2, color: common.primaryColor, "&, &.Mui-checked": {
                color: common.primaryColor,
              },
            }}
          />
          <CustomButton width="220px"
            backgroundColor={common.primaryColor}
            borderRadius="6px"
            fontColor={common.fontWhite}
            fontWeight={500}
            sx={{ fontSize: '14px', lineHeight: '130%' }}
            disabled={!agreed}
            onClick={() => {
              startQuiz()
              handleToggleModal(false)
            }}
          >
            {t('course:test.START_TESTING')}
          </CustomButton>
        </Box>
      </>
    </CustomModal>
  )
}
