import { useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import { serviceCourse } from '@services/course';

const ImportantEvents = () => {
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const { t } = useTranslation(["course"])
  const { classId } = useParams<{ classId: string }>();
  const [importantEvent, setImportantEvent] = useState([]);

  useEffect(() => {
    serviceCourse.getImportantEvents(classId || "").then((res) => {
      if (res.status === 200) setImportantEvent(res.data);
    })
  }, [classId]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderRadius: '12px',
        border: `1px solid ${common.borderSecondary}`,
        borderTop: `solid 12px ${common.primaryColor}`,
        mt: 3,
        background: common.white
      }}
    >
      <Box display="flex" padding="10px" alignItems="center">
        <EventNoteIcon fontSize="small" />
        <Typography fontWeight={500}>{t("course:listener.IMPORTANT_EVENTS")}</Typography>
      </Box>

      <Box
        sx={{
          p: "10px",
          overflowY: 'scroll',
          '&::-webkit-scrollbar': { width: '7px' },
          '&::-webkit-scrollbar-thumb': { backgroundColor: '#E5E0EB', borderRadius: '6px' },
          borderRadius: "0 0 6px 6px",
          maxHeight: '250px'
        }}
      >
        {importantEvent.length === 0 && <Typography sx={{ mx: '10px' }}>{t('course:messages.NO_EVENTS_YET')}</Typography>}
        {importantEvent.map((event: any, index: number) => (
          <Box marginBottom="10px" key={index}>
            <Typography fontSize="14px" fontWeight={500} sx={{ cursor: "pointer" }}
              onClick={() => {
                if (event.type === "MEETING") {
                  if (event.status === "STARTED") {
                    window.open(event.link, "_blank");
                  }
                } else {
                  navigate(`/myCourses/current/${classId}/tasks/${event.id}`);
                }
              }}>
              {event.name.length > 32 ? event.name.slice(0, 32) + "..." : event.name}
            </Typography>
            {event.type === "MEETING" && <Typography fontSize="12px">{t("course:listener.STATUS_CONFERENCE")}: </Typography>}
            {event.type === "TASK" && <Typography fontSize="12px">{t("course:listener.STATUS_TASK")}: </Typography>}
            {
              event.type === "TASK" &&
              <>
                <Typography
                  sx={{
                    fontSize: "12px",
                    borderRadius: "6px",
                    px: 2,
                    width: "fit-content",
                    background: event.status === "ASSIGNED" ? "#E8E8E8" : "#FCB62E"
                  }}
                >
                  {t(`course:${event.status}`)}
                </Typography>
                <Typography fontSize="12px" color="#708099">
                  {`${event.endDate?.slice(0, 10)} ${event.endDate?.slice(11, 16)}`}
                </Typography>
              </>
            }
            {
              event.type === "MEETING" &&
              <>
                <Box fontSize="12px" width="50%" paddingLeft="15px" borderRadius="6px"
                  sx={event.status === "PLANNED" ? { background: "#E8E8E8" } : { background: "#FCB62E" }}>
                  {event.status === "PLANNED" ? t('course:conference.PLANNED') : t('course:conference.STARTED')}
                </Box>
                {event.status === "PLANNED" ?
                  <Typography fontSize="12px" color="#708099">
                    {/*--TODO-- startDate null MANAS */}
                    {`${event.startDate?.slice(0, 10)} ${event.startDate?.slice(11, 16)}`}
                  </Typography> :
                  <Typography fontSize="12px" color="#708099">
                    {`${event.endDate?.slice(0, 10)} ${event.endDate?.slice(11, 16)}`}
                  </Typography>}
              </>
            }
          </Box>
        ))}
      </Box>
    </Box >
  )
}

export default ImportantEvents;