import { forwardRef, FC, ChangeEvent, useMemo, ReactElement } from 'react';
import { IMaskInput } from 'react-imask';
import { useField, useFormikContext } from "formik";
import { Box, Typography, TextField, useTheme } from "@mui/material";
import { SvgIconProps } from '@mui/material';
import InputAdornment from "@mui/material/InputAdornment";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  type: string;
}

const TextMaskCustom = forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    const currentMask = useMemo(() => {
      switch (props.type) {
        case "cellphone":
          return "+7 (###) ###-##-##"
        case "iin":
          return "############"
        case "extension":
          return ".######"
        case "ip":
          return "###.###.###.###.###"
        default:
          return ""
      }
    }, [props.type])

    const definitionsRegex = useMemo(() => {
      switch (props.type) {
        case "extension":
          return /[a-z, 0-9]/
        default:
          return /[0-9]/
      }
    }, [props.type])

    return (
      <IMaskInput
        {...other}
        mask={currentMask}
        definitions={{
          '#': definitionsRegex
        }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

interface Props {
  label: string;
  name: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  isRequired?: boolean;
  adornmentIcon?: ReactElement<SvgIconProps>;
  onAdornmentClick?: () => void;
  borderRadius?: string;
  height?: string
}

const MaskedInput: FC<Props> = ({
  label,
  name,
  type = "text",
  disabled = false,
  placeholder,
  isRequired,
  adornmentIcon,
  onAdornmentClick,
  borderRadius = '4px',
  height = '40px'
}) => {
  const [field, meta, helpers] = useField(name);
  const formikProps = useFormikContext();
  const { common } = useTheme().palette;

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%"
      }}
    >
      <Typography>
        {label} {isRequired && <span style={{ color: common.errorColor }}>*</span>}
      </Typography>
      <TextField
        size="small"
        value={field.value || ""}
        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => formikProps.setFieldValue(name, e.target.value)}
        name={name}
        type={type}
        disabled={disabled}
        id={`formatted-${name}-mask-input`}
        InputProps={{
          inputComponent: TextMaskCustom as any,
          inputProps: {
            mask: Date,
          },
          endAdornment: adornmentIcon ?
            <InputAdornment position="end" onClick={onAdornmentClick} sx={{ cursor: 'pointer' }}>
              {adornmentIcon}
            </InputAdornment> :
            undefined
        }}
        placeholder={placeholder}
        sx={{
          '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: disabled ? common.inputDisabledBg : "transparent"
          },
          '& label.Mui-focused': { color: common.primaryColor },
          '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
          '& .MuiOutlinedInput-root': {
            borderRadius,
            height,
            '&:hover fieldset': { borderColor: common.primaryColor },
            '&.Mui-focused fieldset': { borderColor: common.primaryColor }
          }
        }}
        onBlur={handleBlur}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      />
    </Box>
  );
}

export default MaskedInput;