import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from "react-i18next";

import { serviceTask } from '@services/task';
import CustomAutocomplete from '@ui/Autocomplete';
import { downloadFile, formatDateWithHourMinute } from '@utils/functions';

const ReplyAttachedFiles = ({ answerType, changeFileId }) => {
  const { common } = useTheme().palette;
  const { progressId } = useParams();
  const [data, setData] = useState<Array<any>>([]);
  const [commentDateOptions, setCommentDateOptions] = useState<Array<any>>([]);
  const [fileNameOptions, setFileNameOptions] = useState<Array<any>>([]);
  const [selectedCommentDate, setSelectedCommentDate] = useState<string | null>(null);
  const [currentFileId, setCurrentFileId] = useState<string | null>(null);
  const { t } = useTranslation(["course"]);

  useEffect(() => {
    if (progressId) {
      serviceTask.getAllTaskCommentsOfListener(progressId).then(res => {
        if (res.status === 200) {
          setData(res.data);
          const options: any = [];
          res.data.forEach((el: any) => {
            options.push({
              id: el.id,
              name: formatDateWithHourMinute(el.createdAt)
            })
          });
          setCommentDateOptions(options);
        }
      })
    }
  }, [progressId]);

  const handleSelectChange = (name: any, value: any) => {
    if (name === 'answers') {
      setSelectedCommentDate(value);
      setCurrentFileId(null);
      changeFileId(null);
      const selectedComment = data.find((x: any) => x.id === value);
      const options: any = [];
      selectedComment.replyFiles.forEach((el: any) => {
        options.push({
          id: el.fileId,
          name: el.fileName
        })
      });
      if (answerType === "FILE") setFileNameOptions(options);
    } else if (name === 'attachedFiles') {
      setCurrentFileId(value);
      changeFileId(value);
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <CustomAutocomplete
        name='answers'
        label={t('course:task.ANSWER_TO_TASK')}
        options={commentDateOptions}
        clearable={false}
        onChange={handleSelectChange}
      />
      {answerType === "FILE" &&
        <CustomAutocomplete
          name='attachedFiles'
          label={t('course:task.ATTACHED_DOCUMENTS')}
          options={fileNameOptions}
          clearable={false}
          disabled={!selectedCommentDate}
          onChange={handleSelectChange}
        />
      }
      {currentFileId &&
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            color: common.primaryColor,
            backgroundColor: '#EAE3F9',
            borderRadius: "6px",
            padding: '10px',
            width: 'fit-content',
            cursor: 'pointer',
            height: '40px',
            mt: 'auto'
          }}
          onClick={() => downloadFile(currentFileId)}
        >
          <FileDownloadOutlinedIcon />{t("common:actions.DOWNLOAD")}
        </Box>
      }
    </Box>
  )
}

export default ReplyAttachedFiles;