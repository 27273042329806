import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { useTheme } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles.scss";
import { useTranslation } from "react-i18next";

interface ITextEditorProps {
  name: string;
  label?: string;
  shortToolbar?: boolean;
  hideToolbar?: boolean;
  showInline?: boolean;
  minChar?: number;
  maxChar?: number;
  isRequired?: boolean;
  showSymbolCount?: boolean;
  showScrollEditor?: boolean;
  setIsValidSubmitBtn?: (val: boolean) => void;
  readOnly?: boolean;
}

export const TextEditor = ({
  name,
  label,
  shortToolbar = false,
  hideToolbar = false,
  showInline = false,
  minChar = 100,
  maxChar = 700,
  isRequired = false,
  showSymbolCount = false,
  showScrollEditor = false,
  setIsValidSubmitBtn,
  readOnly = false
}: ITextEditorProps) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(['common']);
  const formikProps = useFormikContext();
  const [field, meta] = useField(name);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const hasText = editorState.getCurrentContent().hasText();
  const [uploadedImages, setUploadedImages] = useState<any[]>([]);

  useEffect(() => {
    if (toHtml(editorState) === field.value) return;
    field.value && setEditorState(
      EditorState.push(
        editorState,
        ContentState.createFromBlockArray(
          htmlToDraft(field.value || "<p></p>\n").contentBlocks
        ),
        "adjust-depth"
      )
    );
  }, [field.value, editorState]);

  function onEditorStateChange(es: EditorState) {
    setEditorState(es);
    const html = toHtml(es);
    if (field.value !== html) {
      formikProps.setFieldValue(name, html);
    }
  }

  function toHtml(es: EditorState) {
    return draftToHtml(convertToRaw(es.getCurrentContent()));
  }

  useEffect(() => {
    if (((!isRequired && (editorState.getCurrentContent().getPlainText().length > 0 &&
      editorState.getCurrentContent().getPlainText().length < minChar)) ||
      (isRequired && (editorState.getCurrentContent().getPlainText().length < minChar)) ||
      editorState.getCurrentContent().getPlainText().length > maxChar)) {
      setIsValidSubmitBtn && setIsValidSubmitBtn(false)
    } else {
      setIsValidSubmitBtn && setIsValidSubmitBtn(true)
    }

  }, [editorState, setIsValidSubmitBtn, minChar, maxChar, isRequired])

  function _uploadImageCallBack(file: any) {
    // long story short, every time we upload an image, we
    // need to save it to the state so we can get it's data
    // later when we decide what to do with it.

    // Make sure you have a uploadImages: [] as your default state
    let imageList = [...uploadedImages];

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    }

    imageList.push(imageObject);

    setUploadedImages(imageList);

    // We need to return a promise with the image src
    // the img src we will use here will be what's needed
    // to preview it in the browser. This will be different than what
    // we will see in the index.md file we generate.
    return new Promise(
      (resolve, reject) => {
        resolve({ data: { link: imageObject.localSrc } });
      }
    );
  }

  return (
    <Box>
      {label && (
        <Typography sx={{ mb: "8px" }}>
          {label}
          {isRequired && <span style={{ color: common.errorColor }}>*</span>}
        </Typography>
      )}
      <div style={{ border: "1px solid #E9ECEF", borderRadius: "6px", padding: "0px 6px" }}>
        <Editor
          editorState={editorState}
          readOnly={readOnly}
          wrapperClassName={readOnly ? "custom-wrapper-diasbled" : "custom-wrapper"}
          editorClassName={showScrollEditor ? "custom-scroll-editor" : "custom-editor"}
          onEditorStateChange={onEditorStateChange}
          toolbarHidden={hideToolbar || readOnly}
          toolbar={
            shortToolbar ? {
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "colorPicker",
                "history",
              ],
              inline: { inDropdown: !showInline },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
            } : {
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "colorPicker",
                "image",
                "history",
              ],
              inline: { inDropdown: !showInline },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              image: {
                uploadCallback: _uploadImageCallBack,
                urlEnabled: false,
                previewImage: true,
                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                alt: { present: false, mandatory: false },
                defaultSize: {
                  height: '150',
                  width: '300',
                },
              },
            }
          }
          localization={{
            translations: {
              "generic.add": t('common:actions.ADD'),
              "generic.cancel": t('common:actions.CANCEL'),
              "components.controls.image.image": "Картинка",
              "components.controls.image.fileUpload": t('common:actions.UPLOAD'),
              "components.controls.image.dropFileText": "Перетащите изображение сюда или нажмите здесь",
            },
          }}
        />
      </div>
      {((meta.touched && Boolean(meta.error)) || (isRequired && meta.touched && !hasText)) &&
        <FormHelperText error={Boolean(meta.error) || (isRequired && !hasText)} sx={{ px: 1.75 }}>
          {meta.error || t('common:validation.MANDATORY_FIELD')}
        </FormHelperText>
      }
      {showSymbolCount && (
        <Box display="flex" justifyContent="space-between">
          {((editorState.getCurrentContent().getPlainText().length > 0 &&
            editorState.getCurrentContent().getPlainText().length < minChar) ||
            editorState.getCurrentContent().getPlainText().length > maxChar) && (
              <Typography fontSize={"11px"} color={common.errorColor}>
                {`${t('common:validation.MIN_VALUE_DESCRIPTION')} - ${minChar}, ${t('common:validation.MAX_VALUE_DESCRIPTION')} - ${maxChar}`}
              </Typography>
            )
          }
          <Box
            sx={{
              display: 'flex',
              marginLeft: 'auto'
            }}
          >
            <Typography
              fontSize={"14px"}
              color={
                editorState.getCurrentContent().getPlainText().length < minChar ||
                  editorState.getCurrentContent().getPlainText().length > maxChar
                  ? common.errorColor
                  : "#000000"
              }
            >
              {editorState.getCurrentContent().getPlainText().length || 0}
            </Typography>
            <Typography fontSize={"14px"}>/{maxChar}</Typography>
          </Box>
        </Box>
      )}
    </Box >
  );
};
