import { useState, useEffect, ChangeEvent } from "react";
import {
  Box,
  Typography,
  useTheme,
  Checkbox,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import TuneIcon from "@mui/icons-material/Tune";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";

import { serviceTask } from "@services/task";
import { useBreadcrumbs } from "@context/BreadcrumbsContext";
import {
  CourseStatus,
  IBreadcrumb,
  ITasksList,
} from "@interfaces/index";
import { formatDateWithHourMinute } from "@utils/functions";
import CustomAutocomplete from "@ui/Autocomplete";
import { taskTypes, taskFormats } from "@utils/enums";
import { IFilter, initialFilter } from "../../CurrentCourses/Task/models";
import { ContentAccessExpirationStatus, TaskProgressStatus } from "@interfaces/status";
import { useCourseContext } from "@context/CourseContext";

const taskStatusOptions = [
  {
    id: "COMPLETED",
    text: "COMPLETED",
  },
  {
    id: "NOT_COMPLETED_ON_TIME",
    text: "NOT_COMPLETED_ON_TIME",
  },
];

const formatText = {
  ORAL: "ORAL_FORMAT",
  WRITTEN: "WRITTEN",
  QUIZ: "TEST",
};

export const statusBgColor = {
  ASSIGNED: "#E4E4E4",
  UNDER_REVIEW: "#EAC600",
  ON_CORRECTION: "#2074D6",
  COMPLETED: "#88E85B",
  PASSED: "#88E85B",
  NOT_COMPLETED_ON_TIME: "#D62B20",
  NOT_PASSED: "#D62B20",
  STARTED: "#9469E3",
};

export const TaskCompletedCourse = () => {
  const navigate = useNavigate();
  const { t } = useTranslation([
    "user",
    "common",
    "enum",
    "course",
    "dictionary",
  ]);
  const { classId } = useParams<{ classId: string; }>();
  const { common } = useTheme().palette;
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [taskList, setTaskList] = useState<any[]>();
  const [filter, setFilter] = useState<IFilter>(initialFilter);
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const [canStudentViewAfterEnd, setCanStudentViewAfterEnd] =
    useState<boolean>(false);
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { courseData } = useCourseContext();

  const searchByName = (searchValue: string) => {
    const new_filter = { ...filter, name: searchValue };
    setFilter(new_filter);
  };
  const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    const new_filter = {
      ...filter,
      isNoMark: checked && name === "isNoMark",
      isExcludeTotalMark: checked && name === "isExcludeTotalMark",
    };
    setFilter(new_filter);
  };

  const searchByParams = (paramName: string, value: any) => {
    if (paramName === "format") setFilter({ ...filter, format: value?.id });
    if (paramName === "status") setFilter({ ...filter, status: value?.id });
    if (paramName === "type") setFilter({ ...filter, type: value?.id });
  };

  useEffect(() => {
    if (classId) {
      serviceTask
        .getTaskListForStudent({
          classId,
          name: filter.name,
          isNoMark: filter.isNoMark,
          isExcludeTotalMark: filter.isExcludeTotalMark,
          format: filter.format,
          status: filter.status,
          type: filter.type,
        })
        .then((res) => {
          if (res.status === 200) {
            setTaskList(
              res.data.filter(
                (task: ITasksList) =>
                  task?.task.status === CourseStatus.PUBLISHED
              )
            );
          }
        });
    }
  }, [filter, classId]);

  const onClickTaskCard = (taskName: string | null, taskId: string | null) => {
    const crumb: IBreadcrumb = {
      level: 3,
      page: "task",
      label: `${taskName}`,
      path: `/myCourses/completed/${classId}`,
      tabNum: null,
    };
    if (classId) setBreadcrumbs(classId, [...getBreadcrumbs(classId), crumb]);
    navigate(`/myCourses/completed/${classId}/tasks/${taskId}`);
  };

  const handleStatus = (status: string) => {
    switch (status) {
      case TaskProgressStatus.PASSED:
      case TaskProgressStatus.NOT_PASSED:
        return true;
      default:
        return false;
    }
  };

  const now = new Date();

  const nextMonth = new Date(now);
  nextMonth.setMonth(now.getMonth() + 1);
  const threeMonthsFromNow = new Date(now);
  threeMonthsFromNow.setMonth(now.getMonth() + 3);

  const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  const millisecondsInOneMonthExact = nextMonth.getTime() - now.getTime();
  const millisecondsInThreeMonthsExact =
    threeMonthsFromNow.getTime() - now.getTime();

  const getContentAvailable = (
    canStudentViewAfterEnd,
    contentAccessExpiration,
    courseData
  ) => {
    if (!canStudentViewAfterEnd) {
      return true;
    }

    const endDate = new Date(courseData?.endDate).getTime();
    const now = Date.now();

    switch (contentAccessExpiration) {
      case ContentAccessExpirationStatus.IMMEDIATELY_AFTER_COMPLETION:
        return false;
      case ContentAccessExpirationStatus.ONE_WEEK_AFTER_COMPLETION:
        return endDate + oneWeekInMilliseconds > now;
      case ContentAccessExpirationStatus.ONE_MONTH_AFTER_COMPLETION:
        return endDate + millisecondsInOneMonthExact > now;
      case ContentAccessExpirationStatus.THREE_MONTHS_AFTER_COMPLETION:
        return endDate + millisecondsInThreeMonthsExact > now;
      default:
        return false;
    }
  };
  const permission = getContentAvailable(
    courseData?.canStudentViewAfterEnd,
    courseData?.contentAccessExpiration,
    courseData
  )

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        background: "#FFFFFF",
        padding: "15px",
        borderRadius: "6px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
        }}
      >
        <TextField
          label={t("common:placeholders.SEARCH_BY_NAME")}
          color="primary"
          size="small"
          name="searchValue"
          onChange={(e) => searchByName(e.target.value)}
          sx={{
            width: "100%",
            "& label.Mui-focused": { color: common.primaryColor },
            "& .MuiInput-underline:after": {
              borderBottomColor: common.primaryColor,
            },
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": { borderColor: common.primaryColor },
              "&.Mui-focused fieldset": { borderColor: common.primaryColor },
            },
          }}
        />
        <Box
          sx={{
            border: `1px solid ${common.borderSecondary}`,
            height: "40px",
            width: "124px",
            borderRadius: "9px",
            display: "flex",
            alignItems: "center",
            px: "4px",
            cursor: "pointer",
          }}
          onClick={() => setOpenFilter(!openFilter)}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              color: "black",
              mr: "8px",
              ml: "4px",
            }}
          >
            {t("common:FILTER")}
          </Typography>
          <TuneIcon />
          {openFilter ? (
            <ArrowDropUpIcon sx={{ width: "30px", height: "30px" }} />
          ) : (
            <ArrowDropDownIcon sx={{ width: "30px", height: "30px" }} />
          )}
        </Box>
      </Box>
      {openFilter && (
        <Box>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            gap={3}
          >
            <CustomAutocomplete
              name="format"
              placeholder={t("dictionary:FORMAT")}
              options={taskFormats}
              onChange={(name: string, value: any) =>
                searchByParams(name, value)
              }
            />
            <CustomAutocomplete
              name="status"
              placeholder={t("user:STATUS")}
              options={taskStatusOptions}
              onChange={(name: string, value: any) =>
                searchByParams(name, value)
              }
            />
            <CustomAutocomplete
              name="type"
              placeholder={t("dictionary:TYPE")}
              options={taskTypes}
              onChange={(name: string, value: any) =>
                searchByParams(name, value)
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { sm: "center", xs: "flex-start" },
              gap: 2,
            }}
          >
            <Box display="flex" alignItems="center">
              <Checkbox
                name="isNoMark"
                checked={filter.isNoMark}
                onChange={(e) => onChangeCheckbox(e)}
                sx={{
                  color: common.primaryColor,
                  "&, &.Mui-checked": { color: common.primaryColor },
                }}
              />
              <Typography>{t("course:theme.WITHOUT_EVALUATION")}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Checkbox
                name="isExcludeTotalMark"
                checked={filter.isExcludeTotalMark}
                onChange={(e) => onChangeCheckbox(e)}
                sx={{
                  color: common.primaryColor,
                  "&, &.Mui-checked": { color: common.primaryColor },
                }}
              />
              <Typography>
                {t("course:test.NOT_CONSIDER_WHEN_CALCULATING_FINAL_GRADE")}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {taskList &&
        taskList.map((wrappedTask: ITasksList) => {
          const { task } = wrappedTask;
          return (
            <Box
              key={task.id}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                alignItems: { xs: "flex-start", sm: "center" },
                borderRadius: "8px",
                border: "1px solid #C1C1C1",
                p: 2.5,
                cursor: (wrappedTask.allowed && permission) ? "pointer" : "not-allowed",
                ":hover": permission ? { background: "#CACACA" } : undefined,
              }}
              onClick={() => {
                if (wrappedTask.allowed && !canStudentViewAfterEnd && permission) {
                  onClickTaskCard(task.name, task.id);
                }
              }}
            >
              <Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <NoteAltOutlinedIcon />
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    {task.name}
                  </Typography>
                </Box>
                {(task.isNoMark || task.isExcludeTotalMark) && (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    sx={{ mt: "4px" }}
                  >
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "20px",
                        backgroundColor: "#D5BEFF",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          width: "15.5px",
                          height: "15.5px",
                          color: common.primaryColor,
                        }}
                      />
                    </Box>
                    <Typography alignItems="center">
                      {task.isNoMark
                        ? t("course:theme.WITHOUT_EVALUATION")
                        : t(
                          "course:theme.NOT_TAKEN_INTO_ACCOUNT_IN_FINAL_GRADE"
                        )}
                    </Typography>
                  </Box>
                )}
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  gap={1}
                  sx={{ mt: "4px" }}
                >
                  <Typography alignItems="center">
                    Тип:{" "}
                    {task.type === "TASK" ? t("enum:TASK") : t("enum:CONTROL")}
                  </Typography>
                  {!matchesDownSm && (
                    <Box
                      sx={{
                        width: "6px",
                        height: "6px",
                        borderRadius: "6px",
                        backgroundColor: "#D9D9D9",
                      }}
                    />
                  )}
                  <Typography alignItems="center">
                    {task.theme !== null &&
                      t("course:task.REFERS_TO_THEME", {
                        themeName: task?.theme?.name,
                      })}
                    {task.section !== null &&
                      t("course:task.REFERS_TO_SECTION", {
                        sectionName: task?.section.name,
                      })}
                    {task.theme === null &&
                      task.section === null &&
                      t("course:task.REFERS_TO_COURSE")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mt: "4px" }}>
                  <Typography alignItems="center">
                    {t("dictionary:FORMAT")}: {t(`course:${formatText[task?.format || ""]}`)}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography alignItems="center">
                    {t("course:theme.TASK_STATUS")}:
                  </Typography>
                  <Box
                    padding="5px 15px"
                    sx={{
                      background: statusBgColor[wrappedTask.status],
                      borderRadius: "15px",
                    }}
                  >
                    {t(`enum:${wrappedTask.status}`)}
                  </Box>
                </Box>
                {task?.progress?.hasDeadlineDate && (
                  <Typography alignItems="center">
                    {t("course:task.COMPLETE_UNTIL")}:{" "}
                    {formatDateWithHourMinute(task.progress.dateOfDeadline)}
                  </Typography>
                )}
              </Box>
              <Box display="flex" alignItems="center" gap={1}>
                {!task.isNoMark && (
                  <>
                    <Box
                      sx={{
                        background: common.secondaryColor,
                        padding: "5px 12px",
                        borderRadius: "6px",
                      }}
                    >
                      <AssignmentTurnedInOutlinedIcon />
                    </Box>

                    {handleStatus(wrappedTask?.status) ? (
                      <Box>{t(`common:statuses.${wrappedTask?.status}`)}</Box>
                    ) : (
                      <Box>
                        <Typography>{t("course:GRADE")}</Typography>
                        <Typography>
                          {/*Временное округление, поменяется после анализа*/}
                          {wrappedTask?.point
                            ? `${Math.round(Number(wrappedTask?.point))} / 100`
                            : "— / 100"}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};
