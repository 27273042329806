import { useState, useEffect } from 'react';
import CardMedia from '@mui/material/CardMedia';
import { useTheme } from '@mui/material/styles';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { serviceFile } from '@services/file';
import { downloadFile, getBase64 } from '@utils/functions';
import { Box } from '@mui/material';

const ImageViewer = ({ fileId, downloadable = false, viewOnly = false, sxProps = {} }) => {
  const { common } = useTheme().palette;
  const [image, setImage] = useState<string>("");
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    serviceFile.getFileMimeTypePublic(fileId)
      .then(res => {
        if (res.status === 200) {
          const contentDisposition = res.headers['content-disposition'];
          const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          const file = new File([res.data], fileName);
          getBase64(file, url => {
            setImage(url);
          })
        }
      })
      .catch((error) => console.error('Error fetching image:', error));

  }, [fileId]);

  const handleRotation = () => {
    let newValue = rotation + 90 >= 360 ? 0 : rotation + 90;
    setRotation(newValue);
  };

  return (
    <Box>
      {!viewOnly &&
        <Box sx={{ display: 'flex' }}>
          <RotateRightIcon onClick={() => handleRotation()} sx={{ color: common.strokePrimary, cursor: "pointer" }} />
          {downloadable &&
            <FileDownloadOutlinedIcon sx={{ color: common.strokePrimary, display: 'flex', alignItems: "end", cursor: "pointer" }}
              onClick={() => downloadFile(fileId)}
            />
          }
        </Box>
      }
      <CardMedia
        component="img"
        src={image}
        alt="Просмотр файла"
        sx={{ ...sxProps, transform: `rotate(${rotation}deg)` }}
      />
    </Box>
  )
}

export default ImageViewer;