import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import type { ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';

import { serviceFile } from '@services/file';
// import { decodeContentDispositionFilename } from '@utils/functions';
import EmptyDataComponent from './EmptyDataComponent';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfViewer = ({ fileId, downloadable = false, width = 800, height = 700 }) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  // const [fileName, setFileName] = useState<string | null>(null);

  const transformWithoutDownload: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    Open: () => <></>,
    OpenMenuItem: () => <></>
  });

  const transformWithDownload: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    Open: () => <></>,
    OpenMenuItem: () => <></>
  });

  const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
    <Toolbar>{renderDefaultToolbar(downloadable ? transformWithDownload : transformWithoutDownload)}</Toolbar>
  );
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });
  const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

  useEffect(() => {
    if (fileId) {
      serviceFile.getFileMimeTypePublic(fileId)
        .then(res => {
          if (res.status === 200) {
            // const name = decodeContentDispositionFilename(res.headers["content-disposition"]);
            // setFileName(name);
            const url = window.URL.createObjectURL(res.data);
            setFileUrl(url);
          }
        })
        .catch((error) => console.error('Error fetching document:', error));

      return () => {
        // Clean up the URL object when the component unmounts
        URL.revokeObjectURL("");
      };
    }
  }, [fileId]);

  if (fileUrl)
    return (
      <Box
        sx={{
          width: `${width}px`,
          height: `${height}px`,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js">
          <Viewer
            fileUrl={fileUrl}
            plugins={[defaultLayoutPluginInstance]}
          />
        </Worker>
      </Box>
    );
  // Формат прикрепленного файла для проверки не предусмотрен для просмотра в системе. Вы можете скачать выбранный файл из прикреплённых документов
  return (
    <EmptyDataComponent text={`Формат файла не предусмотрен для просмотра в системе.`} />
  )
};

export default PdfViewer;