import axios from '@utils/axios';

const serviceAccount = {
  getProfileInfo: () => axios.get('/api/user/v1/auth/me'),
  switchProfile: (id: number) => axios.get(`/api/user/v1/auth/switch?userRoleId=${id}`),
  getProfileFullInfo: (userId: string) => axios.get(`/api/user/v1/${userId}`),
  updateProfileInfo: (bodyData: any) => axios.post('/api/user/v1/profile/update', bodyData),

  getToken: () => axios.get('/api/token'),
  logout: () => axios.post('/api/logout')
};

export default serviceAccount;
