import { FC, RefObject } from 'react';
import Modal, { ModalProps } from '@mui/material/Modal';
import Backdrop from "@mui/material/Backdrop";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

interface Props extends ModalProps {
  open: boolean;
  handleToggleModal: (modalState: boolean) => void;
  top?: number;
  width?: number | string;
  minWidth?: number;
  maxWidth?: number | string;
  height?: number | string;
  maxHeight?: number | string;
  bgColor?: string;
  borderColor?: string;
  borderRadius?: number;
  boxShadow?: number;
  closeIsControlled?: boolean;
  showCloseIcon?: boolean;
  divRef?: RefObject<HTMLElement>;
  px?: number | string;
  py?: number | string;
  borderAll?: string;
}

const CustomModal: FC<Props> = ({
  open,
  handleToggleModal,
  top = 50,
  width = "auto",
  minWidth = 350,
  maxWidth = "90%",
  height = "auto",
  maxHeight = "90%",
  bgColor = "background.paper",
  borderColor,
  borderRadius = 4,
  boxShadow = 0,
  closeIsControlled = false,
  showCloseIcon = false,
  divRef,
  children,
  px = 5,
  py = 5,
  borderAll
}: Props) => {
  const { common } = useTheme().palette;
  borderColor = borderColor || common.white;

  const style = {
    position: "absolute" as "absolute",
    top: `${top}%`,
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: '85%', sm: width },
    minWidth,
    maxWidth,
    height,
    maxHeight,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '7px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E5E0EB',
      borderRadius: '6px'
    },
    bgcolor: bgColor,
    border: borderAll ?? `2px solid ${borderColor}`,
    "&:focus": { outline: "none" },
    borderRadius,
    boxShadow,
    px,
    py,
  };

  const iconStyle = {
    position: "absolute",
    right: 5,
    top: 5,
    color: common.strokePrimary,
    cursor: "pointer",
    fontSize: 32,
    zIndex: 10
  }

  return (
    <Modal
      open={open}
      onClose={!closeIsControlled ? () => handleToggleModal(false) : undefined}
      closeAfterTransition
      components={{ Backdrop }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style} ref={divRef}>
        {showCloseIcon &&
          <CloseIcon
            sx={iconStyle}
            onClick={() => handleToggleModal(false)}
          />
        }
        {children}
      </Box>
    </Modal>
  )
}

export default CustomModal;