import emptyMeeting from "@assets/images/emptyMeeting.png";
import LoadingSpinner from "@components/LoadingSpinner";
import PasswordHideByRegex from "@components/PasswordHideByRegex";
import { CourseContext } from "@context/CourseContext";
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  serviceCourse,
  useDeleteMeetingMutation,
  useGetMyMeetingByStatusQuery,
} from "@services/course";
import { serviceTask, useGetTaskInfoQuery } from "@services/task";
import { conferenceActions } from "@store/conference/conferenceSlice";
import { useAppDispatch } from "@store/hooks";
import format from "date-fns/format";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { FilterByGroup } from "./model";
interface Props {
  search: string;
  classId: string;
  filterByCategory: FilterByGroup;
}

const LoginAndPassWordComponent = ({
  conference,
  t,
}: {
  conference: any;
  t: any;
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Fragment key={conference?.id}>
      {conference && conference?.login && (
        <Typography sx={{ marginBottom: "12px" }}>
          <Box display={"flex"} gap={1}>
            {t("user:LOGIN")}:
            <PasswordHideByRegex
              password={conference?.login}
              showIcon
              handleClickShowPassword={handleClickShowPassword}
              showPassword={showPassword}
            />
          </Box>
        </Typography>
      )}
      {conference?.password && (
        <Typography sx={{ marginBottom: "12px" }}>
          <Box display={"flex"} gap={1}>
            {t("user:PASSWORD")}:
            <PasswordHideByRegex
              password={conference?.password}
              handleClickShowPassword={handleClickShowPassword}
              showPassword={showPassword}
              showIcon
            />
          </Box>
        </Typography>
      )}
    </Fragment>
  );
};

export const ActiveConference = ({
  search,
  classId,
  filterByCategory,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["dictionary", "user", "course"]);
  const { courseId } = useParams();
  const { common } = useTheme().palette;
  const theme = useTheme();
  const { courseData } = useContext(CourseContext);
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const [filteredConferenceList, setFilteredConferenceList] = useState<Array<any>>([]);
  const [themeList, setThemeList] = useState<Array<any>>([]);
  const [filteredTaskListById, setFilteredTaskListById] = useState<string[]>([]);
  const [filteredTaskInfoById, setFilteredTaskInfoById] = useState<any[]>([]);
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    data: conferenceData,
    isError,
    isFetching,
  } = useGetMyMeetingByStatusQuery({
    courseId: classId ?? "",
    status: "PLANNED",
  });//FIXME: later sent without id 

  const [deleteMeeting, { isError: isErrorDeleting }] =
    useDeleteMeetingMutation();

  const deleteRecipient = (id: string) => {
    deleteMeeting(id);
    if (!isErrorDeleting) {
      dispatch(conferenceActions.setMessageOpen(true));
      dispatch(conferenceActions.setMessageType("success"));
      dispatch(
        conferenceActions.setMessage(t("course:messages.CONFERENCE_DELETED"))
      );
    }
    return new Promise((resolve) => {
      return resolve("");
    });
  };

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteRecipient,
    modalTitle: t("course:messages.ARE_YOU_SURE_TO_DELETE_ENTRY"),
  });

  useEffect(() => {
    let resultAfterSearch = conferenceData;
    if (search) {
      resultAfterSearch = conferenceData?.filter((conference) =>
        conference?.title?.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (filterByCategory === FilterByGroup.ONLINE) {
      resultAfterSearch = conferenceData?.filter((conference) => {
        return (
          conference !== null &&
          conference?.themeId !== "" &&
          conference?.themeId !== undefined
        );
      }
      );
    }
    if (filterByCategory === FilterByGroup.ORAL) {
      resultAfterSearch = conferenceData?.filter((conference) => {
        return (
          conference !== null &&
          conference?.taskId !== "" &&
          conference?.taskId !== undefined
        );
      }
      );
    }
    resultAfterSearch && setFilteredConferenceList(resultAfterSearch);
  }, [search, conferenceData, filterByCategory]);

  useEffect(() => {
    if (courseId) {
      serviceCourse.getOrderedThemeList(courseId).then((res) => {
        setThemeList(res.data);
      });
    }
  }, [courseId]);

  useEffect(() => {
    if (conferenceData) {
      const filteredConferenceListByTaskId = conferenceData
        .filter((conference) => {
          return (
            conference !== null &&
            conference?.taskId !== "" &&
            conference?.taskId !== undefined
          );
        })
        .map((item) => {
          return item.taskId;
        });
      setFilteredTaskListById(
        filteredConferenceListByTaskId as unknown as string[]
      );
    }
  }, [conferenceData]);

  useEffect(() => {
    if (filteredTaskListById.length > 0) {
      for (let i = 0; i < filteredTaskListById.length; i++) {
        const element = filteredTaskListById[i];
        serviceTask.getTaskInfo(element).then((res) => {
          if (res.status === 200) {
            setFilteredTaskInfoById((item) => [...item, res.data]);
          }
        });
      }
    }
  }, [filteredTaskListById]);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {DeleteModal}
      {conferenceData?.length === 0 || isError ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          width="100%"
          paddingTop="50px"
        >
          <img
            src={emptyMeeting}
            width={matchesDownSm ? "200px" : "500px"}
            alt="emptyMeeting"
          />
          <Typography
            sx={{
              fontSize: { xs: "18px" },
              fontWeight: { xs: "700", sm: "500" },
            }}
          >
            {t("course:conference.NO_ACTIVE_CONFERENCES_YET")}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            background: common.fontWhite,
            borderRadius: 10,
            mt: 2,
            flexDirection: "column",
            gap: 2,
          }}
        >
          {filteredConferenceList?.map((conference) => {
            return (
              <Paper
                elevation={3}
                sx={{
                  padding: "24px",
                  height: "auto",
                  "&::-webkit-scrollbar": {
                    width: 3,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "lightgray",
                    borderRadius: "6px",
                  },
                  boxShadow: "none",
                  borderRadius: "16px",
                  border: "1px solid #E0E0E0",
                }}
              >
                <Box
                  width="100%"
                  key={conference.id}
                  display="flex"
                  gap={"12px"}
                  flexDirection={"column"}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display="flex" gap={2}>
                      <Typography color="#708099">
                        {format(new Date(conference.startDate), "dd.MM.yyyy")},{" "}
                        {format(new Date(conference.startDate), "HH:mm")} -{" "}
                        {format(new Date(conference.endDate), "HH:mm")}
                      </Typography>
                      {((new Date(conference.startDate) < new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Qyzylorda" })))
                        && (new Date(conference.endDate) > new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Qyzylorda" })))) &&
                        <Box sx={{ background: common.errorColor, padding: "2px 7px", color: "white", borderRadius: "4px" }}>Live</Box>}
                    </Box>
                    <Box display="flex" gap={2}>
                      <ModeEditOutlineOutlinedIcon
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(
                            `/myCourses/${courseId}/conference/${conference.id}?classesId=${classId}`
                          )
                        }
                      />
                      <DeleteOutlineOutlinedIcon
                        color="error"
                        onClick={() => {
                          openDeleteConfirmModal(conference.id || "");
                        }}
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </Box>
                  <Typography
                    color={common.primaryColor}
                    sx={{ cursor: "pointer" }}
                    fontSize="20px"
                    onClick={() => {
                      if (isAuthor)
                        window.open(`${conference.link}`, "_blank");
                    }}
                  >
                    {conference.title}
                  </Typography>
                  <Box display={"flex"} flexDirection={"column"}>
                    <Box display="flex" justifyContent={"space-between"}>
                      <Box display={"flex"} width={"100%"}>
                        <Typography sx={{ marginBottom: "12px" }}>
                          {t("dictionary:ORGANIZER")}:{" "}
                          {conference.coordinatorUserName}
                        </Typography>
                      </Box>

                      <Box
                        display={"flex"}
                        width={"100%"}
                        gap={6}
                        justifyContent={"flex-end"}
                      >
                        <Typography sx={{ marginBottom: "12px" }}>
                          {t("dictionary:PLATFORM")}:{" "}
                          {conference.platform.nameRu}
                        </Typography>
                        <Typography sx={{ marginBottom: "12px" }}>
                          {t("course:PARTICIPANTS")}: {conference.memberList.length}
                        </Typography>
                      </Box>
                    </Box>
                    {conference.themeId && (
                      <Typography sx={{ marginBottom: "12px" }}>
                        {t("course:theme.THEME")}:{" "}
                        {
                          themeList.filter(
                            (theme) => theme.id === conference.themeId
                          )[0]?.name
                        }{" "}
                      </Typography>
                    )}
                    {conference?.taskId && (
                      <Typography sx={{ marginBottom: "12px" }}>
                        {t("course:Устное задание")}:{" "}
                        {
                          filteredTaskInfoById.filter(
                            (item) => item?.id === conference.taskId
                          )[0]?.name
                        }
                        {"1 "}
                      </Typography>
                    )}
                    <LoginAndPassWordComponent conference={conference} t={t} />
                  </Box>
                </Box>
              </Paper>
            );
          })}
        </Box >
      )}
    </>
  );
};
