import { CourseRole } from '@interfaces/index';
export interface conferenceForm {
    name: string,
    platform: string | null,
    isRelatedTopic: boolean,
    link: string | null,
    login: string | null,
    password: string | null,
    coordinatorMemberId: string | null
    roleType: string | null,
    day: Date | string | null,
    startTime:Date | string | null,
    endTime:Date | string | null,
    memberList:Record<CourseRole,any[]>,
    allUserIds:[],
    classId?:string,
    taskId?:string,
    theme?:string,
    isRelatedTopicCheckbox?:string,
    title?:string,
    id?:string,
    themeId?:string;
    relationId?:string
}

export const conferenceFormInitialDto:conferenceForm = {
    name:"",
    platform:null,
    isRelatedTopic: false,
    link: null,
    login: null,
    password: null,
    coordinatorMemberId: null,
    roleType: null,
    memberList: {} as any,
    allUserIds: [],
    day: null,
    startTime: null,
    endTime: null,
    classId:"",
    theme:"",
    taskId:"",
    isRelatedTopicCheckbox:"",
    title:"",
    relationId:""
}

export enum FilterByGroup {
    ONLINE="ONLINE",
    ORAL="ORAL",
    ALL=""
}