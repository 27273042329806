import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, useStepContext, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { EducatorList } from './EducatorList';
import { StudentList } from './StudentList';
import { CourseList } from './CourseList';
import { CourseChart } from './CourseChart';
import { TotalUser } from './TotalUser';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PeriodPopover from './PeriodPopover';
import CloseIcon from "@mui/icons-material/Close";
import CustomAutocomplete from '@ui/Autocomplete';
import { TypeCode } from '@interfaces/index';
import { formatDateWithTimezone } from '@utils/functions';

const Reports = () => {
  const { t } = useTranslation(["sideMenu", "common", "dictionary", "course"]);
  const { common } = useTheme().palette;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showStartDateEndDate, setShowStartDateEndDate] = useState<boolean>(false)
  const [hint, setHint] = useState<HTMLElement | null>(null)
  const [showCloseIcon, setShowCloseIcon] = useState<boolean>(false)
  const [filter, setFilter] = useState({})
  const isPeriodOpen = Boolean(hint)

  const periodPopoverClose = () => {
    setHint(null);
  };
  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };
  const periodPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHint(event.currentTarget);
  };
  const handleClearDate = () => {
    setFilter((prev) => ({
      ...prev,
      startDate: null,
      endDate: null
    }))
    setHint(null);
    setStartDate(null);
    setEndDate(null);
    setShowStartDateEndDate(false);
  }
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${day}.${month}.${year}`;
  }
  const handleChangeTypeCourseFilter = (value: any) => {
    setFilter({ ...filter, type: value ? value : null });

  }
  useEffect(() => {
    if (startDate && endDate && showStartDateEndDate) {
      setFilter((prev) => ({
        ...prev,
        startDate: formatDateWithTimezone(startDate),
        endDate: formatDateWithTimezone(endDate)
      }))
    }

  }, [startDate, endDate, showStartDateEndDate])

  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" justifyContent="space-between">
        <Typography sx={{ fontWeight: "500", fontSize: "36px", lineHeight: "44px", mb: "27px", mt: '20px' }}>
          {t('sideMenu:REPORTS')}
        </Typography>
        <Box display="flex" gap={2}>
          <CustomAutocomplete
            sx={{ width: { lg: '200px', xs: '100%' }, marginTop: "20px" }}
            placeholder={t("course:COURSE_TYPE")}
            name="typeCourse"
            options={[{ text: TypeCode.EDU, id: TypeCode.EDU }, { text: TypeCode.FREE, id: TypeCode.FREE }]}
            onChange={(_, value: any) => handleChangeTypeCourseFilter(value)}

          />
          <Box sx={{
            border: `1px solid ${common.borderSecondary}`,
            height: '40px',
            width: showStartDateEndDate ? '235px' : 'auto',
            backgroundColor: !isPeriodOpen ? 'white' : common.primaryColor,
            color: isPeriodOpen ? 'white' : 'black',
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            px: '10px',
            cursor: 'pointer',
            marginTop: '20px',

          }}
            onMouseEnter={() => startDate && endDate && setShowCloseIcon(true)}
            onMouseLeave={() => setShowCloseIcon(false)}
            onClick={periodPopoverOpen}
          >
            <Typography sx={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              color: isPeriodOpen ? 'white' : 'black',
              mx: '8px',
            }}>
              {
                (startDate && endDate && showStartDateEndDate) ? `${formatDate(startDate)} ${'\u2014'} ${formatDate(endDate)}` : t('common:PERIOD')
              }
            </Typography>
            {showCloseIcon ?
              <CloseIcon
                sx={{}}
                onClick={handleClearDate}
              /> :
              <CalendarMonthOutlinedIcon />
            }
          </Box>
        </Box>
        <PeriodPopover
          isPeriodOpen={isPeriodOpen}
          startDate={startDate}
          endDate={endDate}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          hint={hint}
          periodPopoverClose={periodPopoverClose}
          handleShowDate={() => {
            setShowStartDateEndDate(true)
            periodPopoverClose()
          }}
        />
      </Box>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <Grid container gap={2}>
          <Grid item xs={12} sm={6} md={5.9}>
            <TotalUser />
            <CourseChart filter={filter} />
          </Grid>
          <Grid item xs={12} sm={6} md={5.9}>
            <EducatorList />

          </Grid>
          <Grid item xs={12} sm={6} md={5.9}>
            <StudentList />
          </Grid>
          <Grid item xs={12} sm={6} md={5.9}>
            <CourseList filter={filter} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Reports