import { FC } from 'react';
import { TextField } from '@mui/material';
import { useField } from 'formik';

// Use React.ComponentPropsWithoutRef to inherit all TextField props
type CustomInputProps = React.ComponentPropsWithoutRef<typeof TextField> & {
  name: string;
  label?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  error?: string;
  touched?: boolean;
  variant?: string;
};

// Custom Input Component
const CustomInput: FC<CustomInputProps> = ({
  label,
  value,
  onChange,
  error,
  touched,
  variant = "outlined",
  ...props
}) => {
  // If name is provided but value/onChange aren't, assume Formik is controlling the field
  const [field, meta] = useField(props.name);
  const isUsingFormik = !value && !onChange;

  const isError = isUsingFormik
    ? Boolean(meta.touched && meta.error)
    : Boolean(touched && error);

  return (
    <TextField
      label={label}
      value={isUsingFormik ? field.value || "" : value || ""}
      onChange={isUsingFormik ? field.onChange : onChange}
      onBlur={isUsingFormik ? field.onBlur : undefined}
      error={isError}
      helperText={isError ? (isUsingFormik ? meta.error : error) : ''}
      variant={variant}
      size="small"
      fullWidth
      {...props} // Spread any other props like type, placeholder, etc.
    />
  );
};

export default CustomInput;