import { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik, Form as FormikForm } from "formik";
import { Box, useTheme } from "@mui/material";
import { AlertColor } from "@mui/material/Alert";
import PercentIcon from "@mui/icons-material/Percent";

import { serviceCourse } from "@services/course";
import { StateSchema } from "@store/StateSchema";
import { CourseContext } from "@context/CourseContext";
import SaveChangesModal from "@components/modals/SaveChangesModal";
import CustomButton from "@ui/Button";
import FormikAutocomplete from "@ui/formik/Autocomplete";
import FormikCheckbox from "@ui/formik/Checkbox";
import FormikInput from "@ui/formik/Input";
import {
  ICourse,
  ICourseSetting,
  IStudyClass,
  CourseStatus,
  TypeCode,
  CourseFormatType,
} from "@interfaces/index";
import { gradeDisplayMethods } from "@utils/enums";
import { earliestDate, latestDate } from "./lib";
import { validationSchemaTaskAndAssesmentsEdu, validationSchemaTaskAndAssesmentsFree } from "./validation";
import { formatDateWithTimezone } from "@utils/functions";

interface Props {
  courseInfo: ICourse;
  setCourseInfo: (course: ICourse) => void;
  setMessageOpen: (isOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  setMessage: (message: string) => void;
}

export const TaskGradesSetting = ({
  courseInfo,
  setCourseInfo,
  setMessageOpen,
  setMessageType,
  setMessage,
}: Props) => {
  const { t } = useTranslation([
    "common",
    "dictionary",
    "enum",
    "course",
    "generalSetting",
  ]);
  const profileId = useSelector((store: StateSchema) => store.account.profileInfo.pltaId);
  const { courseData, setCourseData } = useContext(CourseContext);
  const { common } = useTheme().palette;
  const [courseSetting, setCourseSetting] = useState<ICourseSetting>(courseInfo.courseSetting);
  const [flows, setFlows] = useState<IStudyClass[]>([]);
  const { courseId } = useParams();
  const isClosed = courseInfo.status === CourseStatus.CLOSED;
  const currentDate = formatDateWithTimezone(new Date());
  const filteredFlowData = flows
    .map((item) => ({
      startDate: item.startDate !== null ? item.startDate : "",
      endDate: item.endDate !== null ? item.endDate : "",
    }))
    .filter((item) => item.startDate !== "" && item.endDate !== "");
  const disabledByRole = courseInfo?.authors?.some((author) => author.userId === profileId);

  const fetchArrayFlow = useCallback(async () => {
    if (courseId) {
      try {
        const { data } = await serviceCourse.getAllStudyClassesByCourseId(
          courseId
        );
        data && setFlows(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, [courseId, setFlows]);

  useEffect(() => {
    fetchArrayFlow();
  }, [fetchArrayFlow]);

  return (
    <Box>
      <Formik
        initialValues={courseSetting}
        enableReinitialize
        validationSchema={
          courseInfo.typeCode === TypeCode.EDU
            ? validationSchemaTaskAndAssesmentsEdu(t)
            : validationSchemaTaskAndAssesmentsFree(t)
        }
        onSubmit={(values: ICourseSetting) => {
          if (values.zeroMarkForIncompleteTasks)
            values.markForIncompleteTask = 0;
          if (courseSetting.id) {
            serviceCourse
              .updateCourseSetting(courseSetting.id, values)
              .then((res) => {
                if (res.status === 200) {
                  setCourseData({ ...courseData, ...values });
                  setCourseInfo({ ...courseInfo, courseSetting: values });
                  setCourseSetting(values);
                  setMessageOpen(true);
                  setMessageType("success");
                  setMessage(t("common:messages.DATA_CHANGED_SUCCESSFULLY"));
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 5000);
                }
              })
              .catch((err) => {
                setMessageOpen(true);
                setMessage(t("common:messages.ERROR"));
                setMessageType("error");
                setTimeout(() => {
                  setMessageOpen(false);
                }, 3000);
              })
              .finally(() => {
                setTimeout(() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }, 25);
              });
          }
        }}
      >
        {({ values, submitForm, isValid, dirty }) => {
          return (
            <FormikForm>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {courseInfo.typeCode === TypeCode.FREE && (
                  <>
                    <FormikInput
                      label={t("course:PERCENTAGE_COMPLETED_TASKS_SUCCESSFUL_COMPLETION_OF_THE_COURSE")}
                      name="completedTasksRate"
                      type="number"
                      hintText={t("course:hints.THE_OPTION_DETERMINES_PERCENTAGE_COMPLETED_TASKS_SUCCESSFUL_COMPLETION_OF_THE_COURSE")}
                      adornmentIcon={<PercentIcon />}
                      width="120px"
                      disabled={
                        !disabledByRole ||
                        isClosed ||
                        !(
                          currentDate &&
                          new Date(currentDate) <=
                          new Date(
                            earliestDate(filteredFlowData)?.startDate ??
                            new Date()
                          )
                        )
                      }
                      min={0}
                      max={100}
                    />
                    <FormikInput
                      label={t("course:MINIMUM_THRESHOLD_FOR_SUCCESSFUL_TASK_COMPLETION")}
                      name="minLimitForTask"
                      type="number"
                      width="120px"
                      hintText={t("course:hints.THIS_OPTION_DETERMINES_PERSENTAGE_VALUE_FOR_READING")}
                      adornmentIcon={<PercentIcon />}
                      disabled={
                        !disabledByRole ||
                        isClosed ||
                        !(
                          currentDate &&
                          new Date(currentDate) <=
                          new Date(
                            earliestDate(filteredFlowData)?.startDate ??
                            new Date()
                          )
                        )
                      }
                      min={0}
                      max={100}
                    />
                  </>
                )}
                {courseInfo.typeCode === TypeCode.EDU && (
                  <>
                    <FormikInput
                      label={t("course:ACCURACY_OF_CALCULATING_FINAL_SCORE")}
                      name="gradingAccuracy"
                      type="number"
                      hintText={t("course:hints.OPTION_DETERMINES_FORMAT_OF_CALCULATED_FINAL_SCORE")}
                      disabled={
                        !disabledByRole ||
                        isClosed ||
                        !(
                          currentDate &&
                          new Date(currentDate) <=
                          new Date(
                            latestDate(filteredFlowData)?.endDate ??
                            new Date()
                          )
                        )
                      }
                      width="50%"
                    />
                    <FormikCheckbox
                      label={t("course:COUNT_GRADES_FOR_NOT_COMPLETED_TASKS_AS_0")}
                      name="zeroMarkForIncompleteTasks"
                      labelPlacement="end"
                      hintText={t("course:hints.OPTION_TO_DETERMINE_AUTOMATIC_ASSESSMENT_FOR_OVERFUE_TASKS")}
                      disabled={
                        !disabledByRole ||
                        isClosed ||
                        !(
                          currentDate &&
                          new Date(currentDate) <=
                          new Date(
                            latestDate(filteredFlowData)?.endDate ?? new Date()
                          )
                        )
                      }
                    />
                    {!values.zeroMarkForIncompleteTasks && (
                      <Box width="50%">
                        <FormikInput
                          label={t("course:EVALUATION_FOR_NON_COMPLETED_TASK")}
                          name="markForIncompleteTask"
                          type="number"
                          disabled={!disabledByRole || isClosed ||
                            !(
                              currentDate &&
                              new Date(currentDate) <=
                              new Date(
                                latestDate(filteredFlowData)?.endDate ??
                                new Date()
                              )
                            )
                          }
                          width="50%"
                        />
                      </Box>
                    )}
                  </>
                )}
                <FormikAutocomplete
                  label={t("course:DISPLAY_FINAL_GRADE_TO_STUDENTS")}
                  name="finalGradeDisplayMethod"
                  options={gradeDisplayMethods}
                  clearable={false}
                  sx={{ width: "50%" }}
                  hintText={t("course:hints.OPTION_DETERMINES_CONDITION_TO_DISPLAY_FINAL_GRADE_TO_PARTICIPANTS")}
                  disabled={
                    !disabledByRole ||
                    isClosed ||
                    !(
                      currentDate &&
                      new Date(currentDate) <=
                      new Date(
                        courseInfo.typeCode === TypeCode.EDU
                          ? latestDate(filteredFlowData)?.endDate ?? new Date()
                          : earliestDate(filteredFlowData)?.startDate ??
                          new Date()
                      )
                    )
                    || courseSetting.formatType === CourseFormatType.ASYNC
                  }
                />
                <FormikInput
                  label={t("course:MAX_FILE_SIZE_TO_UPLOAD_INTO_TASK")}
                  name="maxFileSizeCanUploadStudent"
                  type="number"
                  width="120px"
                  adornmentIcon={"кб"}
                  disabled={
                    !disabledByRole ||
                    isClosed ||
                    !(
                      currentDate &&
                      new Date(currentDate) <=
                      new Date(
                        courseInfo.typeCode === TypeCode.EDU
                          ? latestDate(filteredFlowData)?.endDate ?? new Date()
                          : earliestDate(filteredFlowData)?.startDate ??
                          new Date()
                      )
                    )
                  }
                />
                {!isClosed && (
                  <Box
                    sx={{
                      mt: 4,
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <CustomButton
                      backgroundColor={common.primaryColor}
                      fontColor={common.fontWhite}
                      width="98px"
                      borderRadius="6px"
                      height="38px"
                      disabled={!(isValid && dirty)}
                      onClick={submitForm}
                    >
                      {t("common:actions.SAVE")}
                    </CustomButton>
                  </Box>
                )}
                <SaveChangesModal dirty={dirty} />
              </Box>
            </FormikForm>
          );
        }}
      </Formik>
    </Box>
  );
};
