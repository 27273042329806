import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  useTheme,
  TextField,
  InputAdornment,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  SxProps,
  Theme,
} from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CustomAutocomplete from '@ui/Autocomplete';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import kk from "date-fns/locale/kk";
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import PeriodPopover from './PeriodPopover';
import { objectOptions, subjectOptions, typeOptions } from './models';
import { serviceUserAudit } from '@services/userAudit';
import { formatDateWithHourMinute, formatDateWithTimezone } from '@utils/functions';
import Pagination from '@ui/Pagination';
registerLocale('ru', ru);
registerLocale('kk', kk);

const ActionsJournal = () => {
  const [hint, setHint] = useState<HTMLElement | null>(null)
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { common } = useTheme().palette;
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [actionLogList, setActionListLog] = useState<any>()
  const [eventObject, setEventObject] = useState<any>(null)
  const [eventType, setEventType] = useState<any>(null)
  const [eventSubject, setEventSubject] = useState<any>(null)
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { t } = useTranslation(["enum", "common", "sideMenu"]);
  const style = {
    '& label.Mui-focused': { color: common.primaryColor },
    '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': { borderColor: common.primaryColor },
      '&.Mui-focused fieldset': { borderColor: common.primaryColor }
    }
  };

  const baseTableRowCellStyle: SxProps<Theme> = {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#7D7D7D",
    minWidth: "150px",
    mr: "16px",
  }
  const periodPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHint(event.currentTarget);
  };

  const periodPopoverClose = () => {
    setHint(null);
  };
  const isPeriodOpen = Boolean(hint)

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}.${day}.${month}`;
  }

  useEffect(() => {
    serviceUserAudit
      .getListUserLogs(
        {
          pageNumber: currentPage,
          size: rowsPerPage,
          filter: {
            auditType: eventType,
            objectType: eventObject,
            userType: eventSubject,
            search: searchQuery,
            startDate: startDate ? formatDateWithTimezone(startDate) : null,
            endDate: endDate ? formatDateWithTimezone(endDate) : null,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setActionListLog(res.data);
        }
      })
  }, [currentPage, rowsPerPage, searchQuery, eventSubject, eventObject, eventType, startDate, endDate])

  const handleChangePage = (newPage: number) => setCurrentPage(newPage);

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
    setCurrentPage(0);
  };

  return (
    <Box>
      <Typography sx={{ fontWeight: "500", fontSize: "36px", lineHeight: "44px", mb: "27px" }}>
        {t('sideMenu:ACTIONS_LOG')}
      </Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${common.borderSecondary}`,
        backgroundColor: common.fontWhite,
        borderRadius: '24px',
        p: '24px',
        width: '100%',
        minHeight: '650px'
      }}>
        <Box sx={{ mb: '24px', display: 'flex', justifyContent: 'space-between', gap: '12px' }}>
          <Box sx={{ width: '100%' }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              size="small"
              color="primary"
              sx={{ width: "100%", justifyContent: "flex-start", ...style }}
              placeholder={t("common:placeholders.SEARCH_BY_NAME")}
              name="searchValue"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box sx={{ backgroundColor: common.primaryColor, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px', mr: '-14px', borderRadius: '4px' }}>
                      <SearchIcon sx={{ color: '#FFFFFF', width: '26px', height: '26px' }} />
                    </Box>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
          <Box>
            <Box sx={{
              border: `1px solid ${common.borderSecondary}`,
              height: '40px',
              width: '124px',
              backgroundColor: !openFilter ? 'white' : common.primaryColor,
              color: openFilter ? 'white' : 'black',
              borderRadius: '9px', display: 'flex', alignItems: 'center', px: '4px', cursor: 'pointer'
            }}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <Typography sx={{
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                color: openFilter ? 'white' : 'black',
                mr: '8px',
                ml: '4px'
              }}>
                {t('common:FILTER')}
              </Typography>
              <TuneIcon />
              {
                openFilter ? (
                  <ArrowDropUpIcon sx={{ width: '30px', height: '30px' }} />
                ) : (
                  <ArrowDropDownIcon sx={{ width: '30px', height: '30px' }} />
                )
              }
            </Box>
          </Box>
        </Box>
        {
          openFilter && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '20px' }}>
              <Box sx={{
                display: 'flex', gap: 1, mb: '24px', width: '100%'
              }}>
                <CustomAutocomplete
                  name='eventType'
                  sx={{ width: '20%', height: '35px', ...style }}
                  options={typeOptions}
                  label={t('enum:EVENT_TYPE')}
                  onChange={(_, value) => setEventType(value.id)}
                  valueAsObject
                />
                <CustomAutocomplete
                  name='eventObject'
                  sx={{ width: '20%', height: '35px', ...style }}
                  options={objectOptions}
                  label={t('enum:EVENT_OBJECT')}
                  onChange={(_, value) => setEventObject(value.id)}
                  valueAsObject
                />
                <CustomAutocomplete
                  name='eventSubject'
                  sx={{ width: '20%', height: '35px', ...style }}
                  options={subjectOptions}
                  label={t('enum:EVENT_SUBJECT')}
                  onChange={(_, value) => setEventSubject(value.id)}
                  valueAsObject
                />
              </Box>
              <Box sx={{
                border: `1px solid ${common.borderSecondary}`,
                height: '40px',
                width: startDate && endDate ? '300px' : 'auto',
                backgroundColor: !isPeriodOpen ? 'white' : common.primaryColor,
                color: isPeriodOpen ? 'white' : 'black',
                borderRadius: '9px',
                display: 'flex',
                alignItems: 'center',
                px: '4px',
                cursor: 'pointer',
                marginTop: '20px',
              }}
                onClick={periodPopoverOpen}
              >
                <CalendarTodayIcon />
                <Typography sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: isPeriodOpen ? 'white' : 'black',
                  mx: '8px',
                }}>
                  {
                    (startDate && endDate) ? `${formatDate(startDate)} ${'\u2014'} ${formatDate(endDate)}` : t('common:PERIOD')
                  }
                </Typography>
                {
                  isPeriodOpen ? (
                    <ArrowDropUpIcon sx={{ width: '30px', height: '30px' }} />
                  ) : (
                    <ArrowDropDownIcon sx={{ width: '30px', height: '30px' }} />
                  )
                }
              </Box>
            </Box>
          )
        }
        <PeriodPopover
          isPeriodOpen={isPeriodOpen}
          startDate={startDate}
          endDate={endDate}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          hint={hint}
          periodPopoverClose={periodPopoverClose}
        />
        <Box sx={{ mt: "40px", width: '100%', display: 'table', tableLayout: 'fixed' }
        }>
          <TableContainer component={Paper}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('enum:EVENT_TIME')}
                  </TableCell>
                  <TableCell>
                    {t('enum:EVENT_SUBJECT')}
                  </TableCell>
                  <TableCell>
                    {t('enum:EVENT_OBJECT')}
                  </TableCell>
                  <TableCell>
                    {t('enum:EVENT_TYPE')}
                  </TableCell>
                  <TableCell sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {t('enum:IP_ADDRESS')}
                  </TableCell>
                  <TableCell>
                    {t('enum:EVENT_DESCRIPTION')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actionLogList?.dtoList?.map((log) => {
                  return (
                    <TableRow key={log.id}>
                      <TableCell
                        sx={{
                          ...baseTableRowCellStyle,
                        }}
                      >
                        {formatDateWithHourMinute(log.createdAt)}
                      </TableCell>
                      <TableCell
                        sx={baseTableRowCellStyle}
                      >
                        {t(`enum:${log.userType}`)}
                      </TableCell>
                      <TableCell
                        sx={baseTableRowCellStyle}
                      >
                        {t(`enum:${log.objectType}`)}
                      </TableCell>
                      <TableCell
                        sx={baseTableRowCellStyle}
                      >
                        {t(`enum:${log.auditType}`)}
                      </TableCell>
                      <TableCell
                        sx={baseTableRowCellStyle}
                      >
                        {log.ipAddress}
                      </TableCell>
                      <TableCell
                        sx={baseTableRowCellStyle}
                      >
                        {log.description}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Pagination
                count={actionLogList ? actionLogList.totalElements : 0}
                page={currentPage}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </TableContainer>
        </Box >
      </Box>
    </Box>
  )
}

export default ActionsJournal