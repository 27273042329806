import * as React from 'react';
import DraggableListItem from './DraggableAnswer';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from 'react-beautiful-dnd';
import Box from '@mui/material/Box';

export type DraggableListProps = {
  showOrder?: boolean;
  items: Array<any>;
  onDragEnd: OnDragEndResponder;
};

const DraggableAnswerList = React.memo(({ showOrder = false, items, onDragEnd }: DraggableListProps) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {provided => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            {items.map((item, index) => (
              <DraggableListItem showOrder={showOrder} item={item} index={index} key={item.id} />
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default DraggableAnswerList;