import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop
} from "react-image-crop";
import { useDropzone } from "react-dropzone";
import { Box, useTheme, Typography, Grid } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ErrorIcon from "@mui/icons-material/Error";

import { serviceFile } from "@services/file";
import CustomButton from "./Button";

import "react-image-crop/dist/ReactCrop.css";

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

interface Props {
  title: string;
  header?: string;
  fileTypeCode: string;
  acceptedExtensions: string;
  handleFileAfterUpload: (fileId: string | null) => void;
  height?: number | string;
  bgColor?: string;
  hideIcon?: boolean;
  multiple?: boolean;
  sizeLimit?: number; // в килобайтах
  disabled?: boolean;
  width?: string;
}

const CropImageUpload: React.FC<Props> = ({
  title,
  header,
  fileTypeCode,
  acceptedExtensions,
  handleFileAfterUpload,
  height = "auto",
  bgColor = "#FFFFFF",
  hideIcon = false,
  multiple = false,
  sizeLimit = 5000,
  disabled = false,
  width = "100%",
}) => {
  const { t } = useTranslation(["course", "common"]);
  const { common } = useTheme().palette;
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [imgSrc, setImgSrc] = useState<string>("");
  const [file, setFile] = useState<File>();
  const [showSizeLimitWarning, setShowSizeLimitWarning] = useState<boolean>(false);
  const [showFileTypeWarning, setShowFileTypeWarning] = useState<boolean>(false);
  const [isCropped, setIsCropped] = useState<boolean>(false);
  const [croppedImgSrc, setCroppedImgSrc] = useState<string>("");
  const [croppedImg, setCroppedImg] = useState<File>();

  const {
    getRootProps,
    getInputProps,
    isDragReject,
    open,
    acceptedFiles,
  } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    maxSize: sizeLimit * 1000,
    multiple: multiple,
    disabled: disabled,
  });

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 16 / 9));
  }
  useEffect(() => {
    if (file) {
      const extension = file?.name.substring(
        file?.name.lastIndexOf("."),
        file?.name.length
      );
      acceptedExtensions.includes(extension) ? setShowFileTypeWarning(false) : setShowFileTypeWarning(true)
    }
  }, [file, acceptedExtensions])

  async function getCroppedImage(sourceImage: HTMLImageElement, cropConfig: PixelCrop, fileName: string) {
    // creating the cropped image from the source image
    const canvas = document.createElement("canvas");

    const scaleX = sourceImage.naturalWidth / sourceImage.width;

    const scaleY = sourceImage.naturalHeight / sourceImage.height;

    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext("2d");

    ctx && ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        // returning an error
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }

        //@ts-ignore
        blob.name = fileName;
        // creating a Object URL representing the Blob object given
        const croppedImageUrl = window.URL.createObjectURL(blob);
        const newImage = new File([blob], fileName, { type: blob.type });
        setCroppedImgSrc(croppedImageUrl);
        setCroppedImg(newImage);
        resolve(croppedImageUrl);
      }, "image/jpeg");
    });
  }

  const onSendCropImage = async (isImgCropped: boolean) => {
    if (croppedImg && isImgCropped) {
      uploadImg(croppedImg);
    } else {
      handleFileAfterUpload(null);
    }
    setIsCropped(isImgCropped);
  }

  const uploadImg = async (img: File) => {
    const fData = new FormData();
    fData.append("file", img);

    const response = await serviceFile.uploadFile(fData, {
      params: {
        typeCode: fileTypeCode,
        temporary: false,
        confirmed: false,
      },
    });

    // Handle the response from the server as needed.
    if (response.status === 200) {
      handleFileAfterUpload(response.data.uuid);
    }
  }

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      if (acceptedFiles && acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
        setCrop(undefined); // Makes crop preview update between images.
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgSrc(reader.result?.toString() || "");
        });
        reader.readAsDataURL(acceptedFiles[0]);
      }
    }
  }, [acceptedFiles]);

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={7}>
        {(imgSrc && !showFileTypeWarning) ? (
          <Box>
            {!isCropped ?
              <ReactCrop
                crop={crop}
                aspect={16 / 9}
                onComplete={(cropConfig) => imgRef.current && file && getCroppedImage(imgRef.current, cropConfig, file?.name)}
                onChange={(cropConfig) => setCrop(cropConfig)}
                minHeight={100}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ transform: `scale(1) rotate(0deg)` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop> :
              <img
                alt="Cropped img"
                src={croppedImgSrc}
              />
            }
          </Box>
        ) : (
          <Box
            {...getRootProps()}
            sx={{
              p: 2.5,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
              borderWidth: 1,
              borderRadius: 1,
              borderColor: isDragReject
                ? common.errorColor
                : common.primaryColor,
              borderStyle: "dashed",
              backgroundColor: bgColor,
              width,
              height,
            }}
          >
            <input {...getInputProps()} accept={acceptedExtensions} />
            <>
              {!hideIcon && (
                <CloudUploadOutlinedIcon
                  sx={{ color: common.primaryColor, fontSize: 48 }}
                />
              )}
              <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                {title}
              </Typography>
              {header && (
                <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                  {header}
                </Typography>
              )}
              <CustomButton
                width="fit-content"
                borderRadius="4px"
                onClick={open}
                disabled={disabled}
              >
                {t("course:CHOOSE_FILE")}
              </CustomButton>
              {showSizeLimitWarning && (
                <Typography sx={{ color: common.errorColor }}>
                  {t("common:hints.MAX_FILE_SIZE_WARNING", { sizeLimit })}
                </Typography>
              )}
              {showFileTypeWarning && (
                <Typography sx={{ color: common.errorColor }}>
                  {t("common:hints.ACCEPTABLE_FORMATS", { acceptedExtensions })}
                </Typography>
              )}
            </>
          </Box>
        )}
      </Grid>
      <Grid item xs={4}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            p: "10px",
            backgroundColor: "#fff",
            borderRadius: "12px",
            alignItems: "center",
            gap: 2,
            border: `1px solid ${common.borderSecondary}`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "28px",
              height: "28px",
              borderRadius: "50%",
              backgroundColor: common.primaryColor,
              padding: "10px",
            }}
          >
            <ErrorIcon sx={{ color: "white", width: "20px" }} />
          </Box>
          <Box>
            <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
              {t("course:UPLOAD_YOUR_IMAGE")}
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
              {t("course:SIZE_PIXELS", { size: "784x414" })}
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
              {t("course:FORMATS", { formats: acceptedExtensions })}
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
              {t("course:MAX_FILE_SIZE", { size: "5000кб" })}
            </Typography>
          </Box>
        </Box>
        {crop && (
          <Box marginTop="16px">
            <CustomButton
              borderRadius="6px"
              width="fit-content"
              onClick={() => onSendCropImage(!isCropped)}
            >
              {!isCropped ? t("common:actions.CROP_PHOTO") : t("common:actions.GO_BACK")}
            </CustomButton>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default CropImageUpload;
