import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import { useTheme } from "@mui/material/styles";

import useWindowDimensions from "@hooks/useWindowDimensions";
import { DictionaryCode, TypeCode } from "@interfaces/index";
import FreeCourseCard from "@pages/freeCourse/catalogCourse/FreeCourseCard";
import { serviceDict } from "@services/dictionary";
import {
  catalogControllerApi,
  dictionaryControllerApi,
  myCourseControllerApi,
} from "@services/index";
import { useAppSelector } from "@store/hooks";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import CourseCard from "./CourseCard";

const List = () => {
  const location = useLocation();
  const { common } = useTheme().palette;
  const [searchParams] = useSearchParams();
  const { width } = useWindowDimensions();

  //redux
  const dictionary = useAppSelector(
    (store) => store.dictionary.data?.availableCourseTypes
  );

  //Filter
  const [currentPage, setCurrentPage] = useState<number>(1);
  const startDate = searchParams.get("startDate") ?? "";
  const endDate = searchParams.get("endDate") ?? "";
  const name = searchParams.get("name") ?? "";
  const type = searchParams.get("type");
  const tab = searchParams.get("tab") ?? dictionary?.[0] ?? "";

  const Matched = (w: number): number => {
    if (w > 1440) {
      return 8;
    } else if (w > 1200) {
      return 6;
    } else if (w > 992) {
      return 4;
    } else if (w > 768) {
      return 3;
    } else if (w > 576) {
      return 2;
    } else {
      return 1;
    }
  };

  const coursesPerPage = Matched(width);

  const { t } = useTranslation(["course"]);

  const { data: diffLevelList } = useQuery({
    queryKey: ["DictionaryListByCodePublic"],
    queryFn: () =>
      dictionaryControllerApi.getDictionaryListByCodePublic(
        DictionaryCode.COURSE_DIFFICULTY_LEVEL
      ),
    enabled: tab !== TypeCode.EDU,
  });

  const { data: myCoursesList } = useQuery({
    queryKey: [
      "myCoursesList",
      name,
      endDate,
      startDate,
      tab,
      type,
      coursesPerPage,
      currentPage,
    ],
    queryFn: () =>
      myCourseControllerApi.getListenerMyCoursesList({
        pageNumber: currentPage - 1,
        size: coursesPerPage,
        filter: {
          name: name,
          endDate: new Date(endDate) as unknown as string,
          startDate: new Date(startDate) as unknown as string,
          typeCode: tab as TypeCode,
          ...(type !== "" && { status: type }),
        },
      }),
    enabled: !location.pathname.includes("courseCatalog"),
  });

  const { data: catalogCoursesList } = useQuery({
    queryKey: [
      "catalogCoursesList",
      name,
      endDate,
      startDate,
      currentPage,
      coursesPerPage,
    ],
    queryFn: () =>
      catalogControllerApi.getAllCoursesInCatalog({
        pageNumber: currentPage - 1,
        size: coursesPerPage,
        filter: {
          name: name,
          endDate: new Date(endDate) as unknown as string,
          startDate: new Date(startDate) as unknown as string,
        },
      }),
    enabled: location.pathname.includes("courseCatalog"),
  });

  //FIXME: Why this need to be here? Don't use local storage
  useEffect(() => {
    serviceDict.getDictListForDropdown(DictionaryCode.LANGUAGE).then((res) => {
      if (res.status === 200)
        localStorage.setItem("languageDictList", JSON.stringify(res.data));
    });
  }, []);

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
  };

  return (
    <>
      {(myCoursesList?.dtoList || catalogCoursesList?.dtoList) && (
        <Box
          p={
            location.pathname.includes("courseCatalog")
              ? "20px 24px"
              : undefined
          }
        >
          <Box sx={{ marginBottom: "16px" }} display="flex" gap={1}>
            <Typography>{t("course:FOUND")}: </Typography>{" "}
            <Typography color={common.primaryColor}>
              {
                (!location.pathname.includes("courseCatalog")
                  ? myCoursesList
                  : catalogCoursesList
                )?.totalElements
              }
            </Typography>
          </Box>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Grid container spacing={3}>
              {(!location.pathname.includes("courseCatalog")
                ? myCoursesList
                : catalogCoursesList
              )?.dtoList.map((courseInfo) => {
                return tab === TypeCode.EDU ? (
                  <CourseCard
                    courseInfo={courseInfo?.studyClass ?? courseInfo}
                    isDisabled={false}
                    status={courseInfo.status}
                    key={courseInfo.id}
                  />
                ) : (
                  <FreeCourseCard
                    key={courseInfo.id}
                    courseInfo={courseInfo.studyClass}
                    status={courseInfo.status}
                    diffLevelList={diffLevelList}
                  />
                );
              })}
            </Grid>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                mt: 3,
              }}
            >
              <Pagination
                count={Math.ceil(
                  ((!location.pathname.includes("courseCatalog")
                    ? myCoursesList
                    : catalogCoursesList
                  )?.totalElements ?? 1) / coursesPerPage
                )}
                shape="rounded"
                boundaryCount={2}
                siblingCount={1}
                onChange={(_, value: number) => handlePageChange(value)}
                color="secondary"
                page={currentPage}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default List;
