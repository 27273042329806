import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography, useTheme } from '@mui/material';

import account from "@services/account";
import * as types from '@store/account/types';
import { IUserRole, Role } from '@interfaces/index';
import CustomModal from '@components/Modal';
import { ReactComponent as AddEducatorRoleIcon } from "@assets/svg_files/AddEducatorRoleIcon.svg";
import { ReactComponent as AddStudentRoleIcon } from "@assets/svg_files/AddStudentRoleIcon.svg";
import { ReactComponent as SuccessAddRole } from "@assets/svg_files/successAddRole.svg";

interface Props {
  openModal: boolean;
  handleToggleModal: (modalState: boolean) => void;
  userRoles: Array<IUserRole>;
  setUserRoles: (userRoles: Array<IUserRole>) => void;
}

export const AddNewRoleForFreeUserModal = ({ openModal, handleToggleModal, userRoles, setUserRoles }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["profile", "user", "course", "enum"]);
  const dispatch = useDispatch();
  const { common } = useTheme().palette;

  const MY_COURSES_ROLE: Array<string | null> = ["EDUCATOR", "LISTENER", "MANAGER"];
  const COURSES_ROLE: Array<string | null> = ["ADMIN", "LOCAL_ADMIN"];

  const switchAccountProfile = (userRoleId: number) => {
    account.switchProfile(userRoleId).then(res => {
      let updatedUserRoles = [...userRoles].map(userRole => {
        if (userRole.current) {
          return { ...userRole, current: false }
        }
        return userRole;
      });
      const { role } = res.data.authorityInfo;
      dispatch({ type: types.account('UPDATE_PROFILE_INFO').SUCCESS, payload: res.data });
      setUserRoles([...updatedUserRoles].map(userRole => {
        if (userRole.id === userRoleId) {
          return { ...userRole, current: true }
        }
        return userRole;
      }));
      handleToggleModal(false);
      if (MY_COURSES_ROLE.includes(role)) {
        navigate('/myCourses');
      } else if (COURSES_ROLE.includes(role)) {
        navigate('/courses');
      } else {
        navigate('/');
      }
    });
  }
  return (
    <CustomModal
      open={openModal}
      handleToggleModal={handleToggleModal}
      maxWidth={420}
      closeIsControlled
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <SuccessAddRole />
        <Typography fontSize="22px" fontWeight={600} marginTop="12px">{t("course:ROLE_ADDED_SUCCESSFULLY")}</Typography>
        <Typography fontSize="16px" color="#6D737A" marginBottom="24px" marginTop="12px">{t("course:YOU_CAN_SIGN_CONTINUE_WITH_YOUR_CURRENT_ONE")}</Typography>
        {userRoles.map((userRole: any) => {
          return (
            <Box
              key={userRole.role.code}
              width="340px" height="74px" display="flex" gap={1.5} alignItems="center" padding="12px" marginBottom="12px"
              sx={{
                border: `1px solid ${common.borderSecondary}`, borderRadius: "8px", cursor: "pointer",
                "&:hover": {
                  border: `1px solid ${common.primaryColor}`,
                  background: "#FBF9FF"
                }
              }}
              onClick={() => switchAccountProfile(userRole.id)}
            >
              {userRole.role.code === Role.EDUCATOR ? <AddEducatorRoleIcon /> : <AddStudentRoleIcon />}
              <Typography fontSize="16px" fontWeight={600}>{t(`enum:${userRole.role.code}`)}</Typography>
            </Box>
          )
        })}
      </Box>
    </CustomModal>
  )
}
