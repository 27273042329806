import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { TabContext, TabPanel } from "@mui/lab";
import {
  AlertColor,
  Box,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";

import { ReactComponent as EmptyJournaltSvg } from "@assets/svg_files/emptyComponent/emptyJournal.svg";
import { ReactComponent as WarningHintComponent } from "@assets/svg_files/hintComponent/warning.svg";
import Message from "@components/Message";
import UnhiddenHints from "@components/UnhiddenHints";
import { EmptyComponent, HintComponent } from "@components/common";
import { CourseContext } from "@context/CourseContext";
import { CourseRole, IMember, ITask, ITaskFreeCourses, JournalTab, Role } from "@interfaces/index";
import {
  serviceCourse,
  useGetAllStudyClassesByCourseIdQuery,
} from "@services/course";
import { DynamicModuleLoader, ReducersList } from "@store/DynamicModuleLoader";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { getFlowId } from "@store/journal/journalSelector";
import { journalActions, journalReducer } from "@store/journal/journalSlice";
import { useSelector } from "react-redux";
import { ScorePage } from "./Score";
import { VisitPage } from "./Visit";
import { serviceMember } from "@services/member";
import { serviceTask } from "@services/task";

const reducerList: ReducersList = {
  journal: journalReducer,
};
interface TaskItem {
  classId: string;
  id: string;
  responsibleMemberIds: string[];
  task: ITaskFreeCourses;
  taskId: string;
  isCloseAccess: boolean;
  dateOfAccess: string | null;
  responsibleMembers: Array<IMember>;
}


export const JournalPage = () => {
  const { t } = useTranslation(["course"]);
  const { courseId } = useParams();
  const { common } = useTheme().palette;
  const { courseData } = useContext(CourseContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get("type");
  const [typeProfile, setTypeProfile] = useState<JournalTab>(
    JournalTab.JOURNAL
  );
  const dispatch = useAppDispatch();
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [message, setMessage] = useState<string>("");
  const [isJournalHintVisible, setIsJournalHintVisible] =
    useState<boolean>(true);
  const [isVisitHintVisible, setIsVisitHintVisible] = useState<boolean>(true);
  const [journalTasks, setJournalTasks] = useState<TaskItem[]>([]);
  const [activeStudyClass, setActiveStudyClass] = useState<number>(0);
  const profile = useAppSelector((store) => store.account.profileInfo.pltaId);

  const [journalList, setJournalList] = useState<any>(null);
  const handleTabChange = (type: string) => {
    setTypeProfile(type as JournalTab);
    setSearchParams({ type: type });
  };
  const { data: studyClasses, refetch } = useGetAllStudyClassesByCourseIdQuery(
    courseId || ""
  );

  useEffect(() => {
    const storedHintJournalVisibility = localStorage.getItem(
      `hintJournalVisibility/${courseId}`
    );
    const storedHintVisitVisibility = localStorage.getItem(
      `hintVisitVisibility/${courseId}`
    );
    if (storedHintJournalVisibility === "false") {
      setIsJournalHintVisible(false);
    }
    if (storedHintVisitVisibility === "false") {
      setIsVisitHintVisible(false);
    }
  }, [courseId]);

  useEffect(() => {

    if (studyClasses) {
      serviceTask
        .getAssessmentTaskList(studyClasses[activeStudyClass]?.id, {})
        .then((res) => {
          if (res.status === 200) {
            if (courseData?.createdBy === profile) {
              setJournalTasks(res.data)
            }
            else if (
              courseData?.roleType === CourseRole.EXAMINER ||
              courseData?.roleType === CourseRole.ASSISTANT
            ) {
              setJournalTasks(
                res.data.filter((task: ITask) =>
                  task.responsibleMembers.some(
                    (member) => member.userId === courseData?.userId
                  )
                )
              );
            } else if (courseData?.roleType === Role.EDUCATOR) {
              const filteredTasks = res.data.filter((element) => element.responsibleMembers &&
                element.responsibleMembers.some(member => member.userId === profile && element.task));
              setJournalTasks(filteredTasks);
            }
          }
        });
    }
  }, [
    courseData?.createdBy,
    courseData?.roleType,
    courseData?.userId,
    studyClasses,
    activeStudyClass,
    profile,
  ]);

  useEffect(() => {
    localStorage.setItem(
      `hintJournalVisibility/${courseId}`,
      isJournalHintVisible.toString()
    );
  }, [isJournalHintVisible, courseId]);

  useEffect(() => {
    localStorage.setItem(
      `hintVisitVisibility/${courseId}`,
      isVisitHintVisible.toString()
    );
  }, [isVisitHintVisible, courseId]);



  // FIXME: When you delete in flow page or add flow that cached in browser need to fix later
  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (studyClasses && studyClasses.length > 0) {
      dispatch(journalActions.setFlowId(studyClasses[activeStudyClass]?.id));
    }
  }, [activeStudyClass, dispatch, studyClasses]);

  if (studyClasses && studyClasses.length === 0) {
    return (
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <HintComponent
          leftBorder
          svg={<WarningHintComponent />}
          text={t("course:hints.IF_NO_FLOWS")}
        />
        <EmptyComponent
          height={384}
          text={t("common:hints.NO_ASSIGNMENTS_FOR_GRADING")}
          svg={<EmptyJournaltSvg />}
        />
      </Box>
    );
  }

  return (
    <DynamicModuleLoader removeAfterUnmount reducers={reducerList}>
      <Box>
        {typeProfile === JournalTab.JOURNAL &&
          journalList?.dtoList.length > 0 && (
            <Box
              sx={{
                m: "20px 0",
                width: "100%",
                height: "auto",
                background: "#FF9500",
                borderRadius: "6px",
                padding: "10px",
                color: common.fontWhite,
              }}
              display="flex"
              gap="10px"
              alignItems="center"
            >
              <Box
                sx={{
                  background: common.fontWhite,
                  color: "#FF9500",
                  borderRadius: "15px",
                  padding: "1px 4px",
                }}
              >
                <ReportProblemOutlinedIcon fontSize="small" />
              </Box>
              <Typography fontSize={"14px"}>
                {t(
                  "course:hints.ASSIGN_ALL_EVALUATED_TASKS_TO_COURSE_LISTENERS"
                )}
              </Typography>
            </Box>
          )}
        {typeProfile === JournalTab.JOURNAL &&
          isJournalHintVisible &&
          journalList?.dtoList.length > 0 && (
            <UnhiddenHints
              clickHandler={() => setIsJournalHintVisible(false)}
              clearIconVisible={true}
              text={t("course:hints.INFO_ABOUT_GRADEBOOK_SECTION")}
            />
          )}

        {typeProfile === JournalTab.VISIT &&
          journalList?.dtoList.length > 0 &&
          isVisitHintVisible &&
          courseData?.accountAttendanceInControlWork && (
            <UnhiddenHints
              clickHandler={() => setIsVisitHintVisible(false)}
              clearIconVisible={true}
              text={t("course:hints.ATTENDANCE_SECTION")}
            />
          )}
        {messageOpen && (
          <Box sx={{ mb: "12px", mt: "5px" }}>
            <Message
              type={messageType}
              message={message}
              setMessageOpen={setMessageOpen}
            />
          </Box>
        )}
        {studyClasses && (
          <Box
            display="flex"
            gap={1}
            sx={{
              width: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { width: "6px", height: "2px" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#E5E0EB",
                borderRadius: "6px",
              },
            }}
          >
            {studyClasses?.map((classes, index) => {
              return (
                <Box
                  key={classes.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "40px",
                    fontSize: "14px",
                    background:
                      activeStudyClass === index
                        ? common.primaryColor
                        : "white",
                    padding: "12px 16px",
                    borderRadius: "6px",
                    width: "94px",
                    whiteSpace: "nowrap",
                    color: activeStudyClass === index ? "white" : "#8E8E93",
                    cursor: "pointer",
                    "&:hover": {
                      background:
                        activeStudyClass === index
                          ? common.primaryColor
                          : "#F5EFFF",
                    },
                    mt: 2,
                  }}
                  onClick={() => {
                    setActiveStudyClass(index);
                  }}
                >
                  {t("course:EDU_STREAM")} #{index + 1}
                </Box>
              );
            })}
          </Box>
        )}
        <Box
          sx={{
            display: "block",
            width: "100%",
            background: common.fontWhite,
            border: `1px solid ${common.borderPrimary}`,
            borderRadius: 3,
            mt: 2,
          }}
        >
          <TabContext value={typeProfile}>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  padding: "10px 0 0 10px",
                }}
              >
                <Tabs
                  onChange={(_, value: string) => handleTabChange(value)}
                  indicatorColor="secondary"
                  value={typeProfile}
                  sx={{
                    "& .MuiTab-root.Mui-selected": {
                      color: "#1A141F",
                    },
                    "& .MuiTabs-indicator": {
                      background: common.borderPrimary,
                    },
                  }}
                >
                  <Tab
                    label={t("course:GRADEBOOK")}
                    value={JournalTab.JOURNAL}
                    sx={{ textTransform: "none", fontSize: "18px" }}
                  />
                  {courseData?.accountAttendance && (
                    <Tab
                      label={t("course:ATTENDANCE")}
                      value={JournalTab.VISIT}
                      sx={{ textTransform: "none", fontSize: "18px" }}
                    />
                  )}
                </Tabs>
              </Box>
            </Box>
            <TabPanel value={typeProfile}>
              {typeProfile === JournalTab.JOURNAL && (
                <ScorePage
                  tasks={journalTasks}
                  setMessageOpen={setMessageOpen}
                  setMessageType={setMessageType}
                  setMessage={setMessage}
                  journalList={journalList}
                  setJournalList={setJournalList}
                />
              )}
              {typeProfile === JournalTab.VISIT && (
                <VisitPage
                  setMessageOpen={setMessageOpen}
                  setMessageType={setMessageType}
                  setMessage={setMessage}
                />
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </DynamicModuleLoader>
  );
};
