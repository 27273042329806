import CustomModal from '@components/Modal'
import { Box, Divider, Switch, Typography, useTheme } from '@mui/material'
import FormikInput from '@ui/formik/Input'
import React from 'react'
import { Formik, Form as FormikForm } from "formik";
import FormikSwitch from '@ui/formik/Switch';
import CustomButton from '@ui/Button';
import { useTranslation } from 'react-i18next';
import FormikInputNew from '@ui/formik/InputNew';
import FormikSwitchNew from '@ui/formik/SwitchNew';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';

export const AddLicenseModal = ({ openAdd, handleToggleModal }) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(['course', 'common', 'license', 'dictionary'])

  return (
    <CustomModal open={openAdd}
      handleToggleModal={handleToggleModal}
      showCloseIcon
      py={3}
      px={3}
      width="500px">

      <Formik initialValues={{}}
        enableReinitialize
        validationSchema={null}
        onSubmit={() => { }}>
        {({ values, submitForm, touched }) => {
          return (
            <FormikForm>
              <Box>
                <Typography fontSize="20px" fontWeight={500}>{t("license:ADDING_LICENSE")}</Typography>
                <Divider sx={{ margin: "16px 0px" }} />
                <FormikInput name='course' label={t("license:NAME_LICENSE")} isRequired />
                <Box sx={{ mb: "24px" }} />
                <Typography sx={{ mb: "8px" }}>{t("dictionary:TYPE_OF_ORGANIZATION")}<span style={{ color: common.errorColor }}>*</span></Typography>
                <Box display="flex" gap={1.5} sx={{ mb: "16px" }}>
                  <Box display="flex" gap={1} sx={{ border: `1px solid ${common.borderSecondary}`, padding: "8px", borderRadius: "4px", cursor: "pointer" }}>
                    <AccountBalanceOutlinedIcon />
                    <Typography fontWeight={500} fontSize="14px">{t("common:UNIVERSITY")}</Typography>
                  </Box>
                  <Box display="flex" gap={1} sx={{ border: `1px solid ${common.borderSecondary}`, padding: "8px", borderRadius: "4px", cursor: "pointer" }}>
                    <WorkOutlineOutlinedIcon />
                    <Typography fontWeight={500} fontSize="14px">{t("dictionary:ORGANIZATION")}</Typography>
                  </Box>
                </Box>
                <Box sx={{ mb: "12px" }} />
                <FormikInputNew isRequired name='course' width='100px' label={t("license:COURSES_AVAILABLE")} flexDirection="initial" justifyContent='space-between' />
                <Box sx={{ mb: "12px" }} />
                <FormikInputNew isRequired name='author' width='100px' label={t("license:AUTHORS_AVAILABLE")} flexDirection="initial" justifyContent='space-between' />
                <Box sx={{ mb: "12px" }} />
                <FormikInputNew isRequired name='listener' width='100px' label={t("license:LISTENERS_AVAILABLE")} flexDirection="initial" justifyContent='space-between' />
                <Box sx={{ mb: "12px" }} />
                <FormikInputNew isRequired type='number' min={0} name='capacity' width='100px' label={t("license:CAPACITY_AVAILABLE")} adornmentIcon={<Typography>ГБ</Typography>} flexDirection="initial" justifyContent='space-between' />
                <Box sx={{ mb: "12px" }} />
                <FormikSwitchNew name='isPosting' flexDirection="initial" justifyContent='space-between' labelStart={t("license:POSTING_WEBSITE")} />
                <Box sx={{ mb: "12px" }} />
                <FormikSwitchNew name='isActive' flexDirection="initial" justifyContent='space-between' labelStart={t("license:ACTIVATE_LICENSE")} />
                <Divider sx={{ margin: "16px 0px" }} />
                <Box display="flex" justifyContent="flex-end" gap={1}>
                  <CustomButton width="90px" borderRadius='6px' backgroundColor={common.white} fontColor={common.primaryColor}>
                    <Typography>{t("course:task.CANCELLATION")}</Typography>
                  </CustomButton>
                  <CustomButton width='145px' borderRadius='6px'>
                    <Typography>{t("common:actions.ADD")}</Typography>
                  </CustomButton>
                </Box>

              </Box>
            </FormikForm>)
        }}
      </Formik>
    </CustomModal>
  )
}
