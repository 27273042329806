import { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stomp from 'stompjs';

import Timer from './Timer';
import { serviceTask } from '@services/task';
import Question from './Question';
import { serviceListener } from '@services/listener';
import { ITask } from '@interfaces/index';
import { taskInitialDto } from './models';

const target = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_WEBSOCKET_PRODUCTION_TARGET
  : process.env.REACT_APP_WEBSOCKET_DEVELOPMENT_TARGET;

const Quiz = () => {
  const { courseId, taskId, type, quizProgressId } = useParams();
  const [searchParams]=useSearchParams();
  const navigate = useNavigate();
  const [question, setQuestion] = useState<any>();
  const [initialAnswerList, setInitialAnswerList] = useState<Array<any> | null>([]);
  const [currentQuestionId, setCurrentQuestionId] = useState<string | null>(null);
  const [firstQuestionId, setFirstQuestionId] = useState<string | null>(null);
  const [lastQuestionId, setLastQuestionId] = useState<string | null>(null);
  const [questions, setQuestions] = useState<Array<any>>([]);
  const [warningIsUp, setWarningIsUp] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<string>();
  const [taskInitialData, setTaskInitialData] = useState<ITask>(taskInitialDto);
  const progressId=searchParams.get("progressId");

  // Create a STOMP client instance
  const socket = new WebSocket(`${target}/course/ws`);
  const stompClient = Stomp.over(socket);
  useEffect(() => {
    const client = Stomp.client(`${target}/course/ws`);
    // Connect to the WebSocket server
    client.connect({}, frame => {
      console.log('Connected:', frame);

      // Subscribe to a destination (topic/queue)
      client.subscribe(`/topic/quiz/${quizProgressId}`, message => {
        // console.log('Received message body:', JSON.parse(message.body));
        const { left, duration } = JSON.parse(message.body);
        setTimeLeft(left);
        setWarningIsUp(Number(left) <= Math.floor(Number(duration) / 4));
      });
    }, error => {
      console.error('Error during connection:', error);
    });

    // Cleanup function
    return () => {
      client.disconnect(() => {
        console.log('Disconnected');
      });
    };
  }, [quizProgressId]);

  useEffect(() => {
    // Send a message
    setTimeout(() => {
      if (socket.readyState) {
        stompClient.send('/app/ws', {}, JSON.stringify(quizProgressId));
      }
    }, 1000);
  }, [socket.readyState, stompClient, quizProgressId]);

  const [completeQuizModalOpen, setCompleteQuizModalOpen] = useState<boolean>(false);
  const toggleCompleteQuizModal = (modalState: boolean) => setCompleteQuizModalOpen(modalState);

  useEffect(() => {
    const handleWindowBlur = () => {
      // Code to handle the window losing focus
      console.log('Quiz page lost focus');
    };
    window.addEventListener('blur', handleWindowBlur);

    return () => {
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, []);

  useEffect(() => {
    if (quizProgressId ) {
      serviceTask.getQuizQuestionList(quizProgressId).then(res => {
        if (res.status === 200) {
          // console.log(res.data);
          setQuestions(res.data);
          setCurrentQuestionId(res.data[0].questionId);
          setFirstQuestionId(res.data[0].questionId);
          setLastQuestionId(res.data[res.data.length - 1].questionId);
        }
      })
    }
  }, [quizProgressId]);

  useEffect(() => {
    if (progressId) {
      serviceListener.getTaskInfo(progressId).then((res) => {
        if (res.status === 200) {
          setTaskInitialData(res.data);
        }
      })
    }
  }, [progressId])

  const checkAnswerIsUpdated = () => {
    return JSON.stringify(initialAnswerList) !== JSON.stringify(question?.type === "MAPPING" ? question?.children : question?.answers);
  }

  const goToQuestion = (goTo: string, questionId: string) => {
    if (quizProgressId) {
      const index = [...questions].findIndex((x: any) => x.questionId === questionId);
      const questionToGo = [...questions][goTo === 'random' ? index : goTo === 'prev' ? index - 1 : index + 1];
      if (checkAnswerIsUpdated() || ((question.type === "MAPPING" || question.type === "SEQUENCE") && ![...questions].find(x => x.questionId === question.id)?.answered)) {
        let reqBody = question;
        let items = [...question.answers];
        if (question.type === "MAPPING") {
          const updatedChildren = [...question.children].map((x, index) => ({ ...x, userAnswers: [items[index]] }));
          reqBody = { ...reqBody, answers: items, children: updatedChildren };
        } else {
          items = [...items].map((x, index) => ({ ...x, userOrder: index + 1 }));
          reqBody = { ...reqBody, answers: items };
        }
        serviceTask.postQuizQuestion(quizProgressId, reqBody).then(res => {
          if (res.status === 200) {
            setQuestions([...questions].map(x => (x.questionId === question.id ? { ...x, answered: true } : x)));
            setCurrentQuestionId(questionToGo.questionId);
          }
        })
      } else {
        setCurrentQuestionId(questionToGo.questionId);
      }
    }
  }

  const completeQuiz = () => {
    if (quizProgressId) {
      if (checkAnswerIsUpdated() || ((question.type === "MAPPING" || question.type === "SEQUENCE") && ![...questions].find(x => x.questionId === question.id)?.answered)) {
        let reqBody = question;
        let items = [...question?.answers];
        if (question?.type === "MAPPING") {
          const updatedChildren = [...question?.children].map((x, index) => ({ ...x, userAnswers: [items[index]] }));
          reqBody = { ...reqBody, answers: items, children: updatedChildren };
        } else {
          items = [...items].map((x, index) => ({ ...x, userOrder: index + 1 }));
          reqBody = { ...reqBody, answers: items };
        }
        serviceTask.postQuizQuestion(quizProgressId, reqBody).then(res => {
          if (res.status === 200) {
            requestToServer(quizProgressId);
          }
        })
      } else {
        requestToServer(quizProgressId);
      }
    }
  }

  const requestToServer = (quizProgressId: string) => {
    serviceTask.completeQuiz(quizProgressId).then(res => {
      if (res.status === 200) {
        toggleCompleteQuizModal(false);
        if (type === 'selfQuiz') {
          sessionStorage.setItem('selfTestResult', JSON.stringify(res.data))
        }
        window.postMessage({ message: "QUIZ_FINISH", payload: { cheating_code: `${taskInitialData.progress?.id}` } })
        window.postMessage({ message: "APP_QUIZ_FINISH", payload: { cheating_code: `${taskInitialData.progress?.id}` } })
        navigate(`/myCourses/current/${courseId}/tasks/${taskId}`);
      }
    })
  }

  return (
    <Box sx={{ display: 'flex', gap: 2 }} >
      <Box sx={{ width: '85%' }}>
        {/* Here we put the Breadcrumbs */}
        <Box>
          {currentQuestionId &&
            <Question
              question={question}
              setQuestion={setQuestion}
              setInitialAnswerList={setInitialAnswerList}
              questions={questions}
              currentQuestionId={currentQuestionId}
              firstQuestionId={firstQuestionId}
              lastQuestionId={lastQuestionId}
              goToQuestion={goToQuestion}
            />
          }
        </Box>
      </Box >
      <Timer
        completeQuizModalOpen={completeQuizModalOpen}
        toggleCompleteQuizModal={toggleCompleteQuizModal}
        completeQuiz={completeQuiz}
        timeLeft={timeLeft}
        warningIsUp={warningIsUp}
      />
    </Box >
  )
}

export default Quiz;