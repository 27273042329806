import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { serviceCourse } from "@services/course";
import { useTranslation } from "react-i18next";
import ContentCard from "./ContentCard";
import { DurationType } from "@interfaces/index";

const Content = ({ courseId, contentSum, durationType }) => {
  const { t } = useTranslation(["course", "common"]);
  const [contentList, setContentList] = useState<any[]>([]);
  const [activeContent, setActiveContent] = useState<number | null>(null);

  useEffect(() => {
    if (courseId) {
      serviceCourse.getContentStructurePublic(courseId).then((res) => {
        if (res.status === 200) setContentList(res.data);
      });
    }
  }, [courseId]);

  return (
    <Box>
      <Typography fontSize="32px" fontWeight={700}>
        {t("course:CONTENT_COURSE")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2
        }}
      >
        <Typography fontSize="14px" color="#8E8E93">
          {t('course:SECTIONS')}: {contentSum?.sectionSum}
          <span style={{ marginLeft: '4px', marginRight: '4px' }}>•</span>
          {t('course:THEMES')}: {contentSum?.themesSum}
          <span style={{ marginLeft: '4px', marginRight: '4px' }}>•</span>
          {t('course:task.ASSESSED_TASKS')}: {contentSum?.evaluatedTasksSum}
          <span style={{ marginLeft: '4px', marginRight: '4px' }}>•</span>
          {t('course:task.SELF_TEST_TASKS')}: {contentSum?.selfTasksSum}
          {durationType === DurationType.INDEFINITE &&
            <>
              <span style={{ marginLeft: '4px', marginRight: '4px' }}>•</span>
              {t('common:HOURS')}: {contentSum?.estimatedTime ? Math.floor(contentSum?.estimatedTime / 60) : 0}
            </>
          }
        </Typography>
        {contentList.map((content, index) => {
          return (
            <ContentCard
              key={content.id}
              content={content}
              sectionIndex={index}
              sectionNumber={index + 1}
              activeContent={activeContent}
              setActiveContent={setActiveContent}
              durationType={durationType}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default Content;
