import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: `${
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PRODUCTION_TARGET
      : process.env.REACT_APP_DEVELOPMENT_TARGET
  }`,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("plta_token") || "";
    if (token) {
      headers.set("Authorization", "Bearer " + token);
    }

    return headers;
  },
});

const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  //TODO: handle 401 and 409
  if (result.error && result.error.status === 401) {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("contentAccordionData");

    // window.location.href = '/oauth2/authorization/gateway';
  }
  return result;
};

export const rtkApi = createApi({
  reducerPath: "api",
  tagTypes: ["Meeting", "Review", "Participant"],
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({}),
});
