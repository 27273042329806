import React, { useRef } from 'react'
import { Box, useTheme } from '@mui/material'
import { ViolationsLineCharts } from './ViolationsLineCharts'
import { DTO } from '../models';

export const VideoPage = ({ aeroInfo, dtoCourse }: { aeroInfo: any, dtoCourse: DTO }) => {
    const { common } = useTheme().palette
    const videoRef = useRef(null)
    return (
        <Box
            sx={{
                width: "100%",
                background: common.fontWhite,
                padding: "10px 25px",
                marginTop: "10px",
                borderRadius: 3,
            }}
        >
            <video
                controls
                ref={videoRef}
                preload="metadata"
                style={{
                    border: `1px solid ${common.strokePrimary}`,
                    borderRadius: '6px'
                }}>
                <source src={aeroInfo?.video} />
            </video>
            <ViolationsLineCharts aeroInfo={aeroInfo} videoRef={videoRef} dtoCourse={dtoCourse} />
        </Box>
    )
}
