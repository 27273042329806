import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  useTheme,
  Typography,
  Popover,
  Grid,
  AvatarGroup,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import FavoriteIcon from "@mui/icons-material/Favorite";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router-dom";
import { capitalize } from "lodash";

import { serviceUser } from "@services/user";
import { serviceCourse } from "@services/course";
import { serviceFile } from "@services/file";
import { useBreadcrumbs } from "@context/BreadcrumbsContext";
import AvatarPicture from "@components/AvatarPicture";
import { DTO } from "@pages/profile/models";
import { getBase64, removeHtml } from "@utils/functions";
import { DurationType, IBreadcrumb, IDictionary, IStudyClass } from "@interfaces/index";


const FreeCourseCard = ({ courseInfo, status, diffLevelList }) => {
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const [authorsInfo, setAuthorsInfo] = useState<DTO[]>([]);
  const [hint, setHint] = useState<HTMLElement | null>(null);
  const difficultyLevel = diffLevelList.find(
    (x: IDictionary) => x.id === courseInfo?.course?.difficultyLevelId
  );
  const [estimatedTime, setEstimatedTime] = useState<number>(0);
  const [fileUrl, setFileUrl] = useState<string>("");
  const lang = localStorage.getItem("i18nextLng") || "kz";
  const { setBreadcrumbs } = useBreadcrumbs();
  const location = useLocation();
  const { t } = useTranslation(["course", "freeCourse", "common"]);

  const hintPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHint(event.currentTarget);
  };

  const hintPopoverClose = () => {
    setHint(null);
  };
  const openHint = Boolean(hint);

  useEffect(() => {
    if (courseInfo.course?.id) {
      const userIDs = courseInfo.course.authors.map((user) => user.userId);
      Promise.all(
        userIDs.map((userID: string) =>
          serviceUser.getUserByIdPublic(userID).then((response) => {
            if (response.status === 200) {
              if (
                !authorsInfo.some((author) => author.id === response.data.id)
              ) {
                setAuthorsInfo((prev) => [...prev, response.data]);
              }
            }
          })
        )
      );
    }
    // eslint-disable-next-line
  }, [courseInfo.course?.id]);

  useEffect(() => {
    if (
      courseInfo.course?.courseSetting.durationType ===
      DurationType.INDEFINITE &&
      courseInfo?.courseId
    ) {
      serviceCourse.getContentSummaryPublic(courseInfo.courseId).then((res) => {
        if (res.status === 200) setEstimatedTime(res.data.estimatedTime);
      });
    }
  }, [courseInfo.course?.courseSetting.durationType, courseInfo.courseId]);

  useEffect(() => {
    if (courseInfo.course?.coverFileId) {
      serviceFile
        .getFileMimeTypePublic(courseInfo.course.coverFileId)
        .then((res) => {
          const contentDisposition = res.headers["content-disposition"];
          const fileName = contentDisposition
            .split(";")[1]
            .split("filename")[1]
            .split("=")[1]
            .trim();
          const file = new File([res.data], fileName);
          getBase64(file, (url) => setFileUrl(url));
        });
    }
  }, [courseInfo.course?.coverFileId]);

  const onClickCourseCard = (
    courseName: string | null,
    classId: string | null
  ) => {
    const crumb: IBreadcrumb = {
      level: 1,
      page: "course",
      label: `${courseName}`,
      path: `/myCourses/${status?.toLowerCase()}/${classId}`,
      tabNum: null,
    };
    if (classId) setBreadcrumbs(classId, [crumb]);
    if (location.pathname.includes("courseCatalog")) {
      navigate(`/courseCatalog/course/${classId}`);
    } else {
      navigate(`/myCourses/${status?.toLowerCase()}/${classId}`);
    }
  };
  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      xl={location.pathname.includes("/freeCatalogCourse") ? 4 : 3}
    >
      <Box
        sx={{
          minWidth: "293px",
          border: `1px solid ${common.borderSecondary}`,
          padding: "12px 12px",
          borderRadius: "9px",
          cursor: "pointer",
          transition: "ease all 0.5s",
          "&:hover": {
            border: `1px solid ${common.primaryColor}`,
            boxShadow: 3,
          },
        }}
        onClick={() => onClickCourseCard(courseInfo.course.name, courseInfo.id)}
      // elevation={0}
      >
        <Box>
          <Box
            sx={{
              ...(fileUrl
                ? {
                  background: `url(${fileUrl})`,
                  height: "143px",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  position: "relative",
                  borderRadius: "4px",
                }
                : {
                  position: "relative",
                  height: "143px",
                  backgroundColor: courseInfo.course?.coverColor,
                  borderRadius: "4px",
                }),
            }}
          >
            <Box
              sx={{
                top: "8px",
                left: "8px",
                right: "8px",
                display: "flex",
                position: "absolute",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "4px 8px",
                    height: "24px",
                    borderRadius: "6px",
                    background: `#fff`,
                    fontSize: "12px",
                    gap: 0.5,
                  }}
                >
                  {courseInfo.course?.rating && courseInfo.course?.rating > 0
                    ?
                    (
                      <>
                        <StarIcon sx={{ color: "#F4C41A", fontSize: "16px" }} />
                        {courseInfo.course?.rating}
                      </>
                    )
                    :
                    (
                      <>
                        <StarIcon sx={{ color: "#8E8E93", fontSize: "16px" }} />
                        <span style={{color: "#8E8E93"}}>{t('course:NO_RATINGS')}</span>
                      </>
                    )
                  }
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "24px",
                    height: "24px",
                    borderRadius: "50%",
                    background: `rgba(148, 105, 227,0.5)`,
                    cursor: "pointer",
                  }}
                  onMouseEnter={hintPopoverOpen}
                  onMouseLeave={hintPopoverClose}
                >
                  <ErrorIcon
                    sx={{ color: "white", opacity: 1, fontSize: "16px" }}
                  />
                </Box>
              </Box>
              <FavoriteIcon
                sx={{
                  color: "#fff",
                  stroke: common.primaryColor,
                  cursor: "pointer",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              m: "20px 10px",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                fontWeight: "600",
                fontSize: { sm: "17px", xs: "15px" },
                cursor: "pointer",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                lineHeight: "19px",
              }}
            >
              {courseInfo.course?.name}
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
              <Box
                sx={{
                  width: "100%",
                  fontWeight: "600",
                  fontSize: { sm: "17px", xs: "15px" },
                  cursor: "pointer",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {t("freeCourse:FOR_FREE")}
              </Box>
              <Typography fontSize="12px" sx={{ color: "#6F6F7E" }}>
                {courseInfo.course?.courseSetting.durationType !==
                  DurationType.INDEFINITE
                  ? t("freeCourse:WITH_EDUCATOR")
                  : estimatedTime / 60 > 1 ? `${Math.floor(estimatedTime / 60)} ${t('common:HOURS_SHORT')}` : `${estimatedTime} ${t('common:MINUTS_SHORT')}`}
                /{difficultyLevel && difficultyLevel[`name${capitalize(lang)}`]}
              </Typography>
            </Box>
            {authorsInfo &&
              <Box
                sx={{
                  display: 'flex',
                  gap: 2
                }}
              >
                <AvatarGroup max={2} sx={{ width: 'fit-content' }}>
                  {authorsInfo.map(
                    (author) => (
                      <AvatarPicture
                        key={author?.profilePhotoId}
                        userId={author?.profilePhotoId}
                        variant="circular"
                        sxProps={{
                          backgroundColor: common.secondaryColor,
                          width: "44px",
                          height: "44px"
                        }}
                      />
                    )
                  )}
                </AvatarGroup>
                <span style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                  {authorsInfo.map(
                    (author) => (
                      <Typography
                        key={author?.profilePhotoId}
                      >
                        {author?.lastName} {author?.firstName}
                        <span></span>
                      </Typography>
                    )
                  )}
                </span>
              </Box>
            }
          </Box>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={openHint}
            anchorEl={hint}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={hintPopoverClose}
            disableRestoreFocus
            disableScrollLock
          >
            <Box
              sx={{
                p: 2,
                color: common.fontWhite,
                backgroundColor: "#1A1F36",
                width: { sm: "318px", xs: "232px" },
                fontSize: "12px",
                fontFamily: "Golos",
              }}
            >
              {removeHtml(courseInfo.course?.shortDescription || "")}
            </Box>
          </Popover>
        </Box>
      </Box>
    </Grid>
  );
};

export default FreeCourseCard;
