import Img from "@assets/images/home/formatImg.png";
import Img1 from "@assets/images/home/formatImg1.png";
import moreFunc1 from "@assets/images/home/moreFunc1.png";
import moreFunc2 from "@assets/images/home/moreFunc2.png";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HomeVariant } from "../types/home";
import s from "./FormatEducation.module.scss";
import { Role } from "@interfaces/index";
interface Props {
  variant: HomeVariant;
}
const FormatEducation = (props: Props) => {
  const { variant } = props;
  const { t } = useTranslation(["home"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const userInfo = localStorage.getItem("userInfo");
  const role = JSON.parse(userInfo || "{}")?.authorityInfo?.role;
  if (variant === HomeVariant.ORGANIZATION) {
    return (
      <div className={s.wrapper}>
        <div className={s.head}>
          {" "}
          Platonus Academy –{" "}
          <span className={s.purple}>{t('home:MULTIFUNCTIONAL')}</span>
          {t('home:PLTA_MULTIFUNCTIONAL_PLATFORM_FOR_CREATING_COURSES')}
        </div>
        <div className={s.content}>
          <div className={s.card}>
            <div className={[s.img, s.helper_img].join(" ")}>
              <img style={{ objectFit: "contain" }} src={moreFunc1} alt="" />
            </div>
            <div className={[s.block, s.helper].join(" ")}>
              <div className={s.title}>
                {t('home:FILL_THE_COURSE_WITH_INTERESTING_CONTENT')}
              </div>
              <div className={s.text}>
                {t('home:ADD_EDUCATION_MATERIALS_CREATE_SECTIONS_AND_TOPICS')}
                {t('home:ENSURE_THAT_INFO_IS_ACCESSIBLE_AND_STRUCTURED_FOR_STUDENTS')}
              </div>
              <Box>
                <Button
                  sx={{ padding: "8px 22px", borderRadius: "4px" }}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    searchParams.set("modal", "true");
                    setSearchParams(searchParams);
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 400,
                      lineHeight: "28px",
                    }}
                  >
                    {t('home:CONTACT_US')}
                  </Typography>
                </Button>
              </Box>
            </div>
          </div>
          <div className={s.card}>
            <div className={[s.block, s.helper].join(" ")}>
              <div className={s.title}>
                {t('home:PUBLISH_COURSES_AND_ACCEPT_PAYMENTS')}
              </div>
              <div className={s.text}>
                {t('home:EASILY_PUBLISH_COURSES_AND_START_ACCEPTING_PAYMENTS_FROM_STUDENTS')}
                {t('home:CONVENIENTS_TOOLS_FOR_MANAGING_PAYMENTS_AND_REPORTING')}
              </div>
              <Box>
                <Button
                  sx={{ padding: "8px 22px", borderRadius: "4px" }}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    searchParams.set("modal", "true");
                    setSearchParams(searchParams);
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 400,
                      lineHeight: "28px",
                    }}
                  >
                    {t('home:CONTACT_US')}
                  </Typography>
                </Button>
              </Box>
            </div>
            <div className={[s.img, s.helper_img].join(" ")}>
              <img style={{ objectFit: "contain" }} src={moreFunc2} alt="tab" />
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (variant === HomeVariant.EDUCATOR) {
    return (
      <div className={s.wrapper}>
        <div className={s.head}>
          {" "}
          Platonus Academy –{" "}
          <span className={s.purple}>{t('home:MULTIFUNCTIONAL')}</span>
          {t('home:PLTA_MULTIFUNCTIONAL_PLATFORM_FOR_CREATING_COURSES')}
        </div>
        <div className={s.content}>
          <div className={s.card}>
            <div className={[s.img, s.helper_img].join(" ")}>
              <img style={{ objectFit: "contain" }} src={moreFunc1} alt="" />
            </div>
            <div className={[s.block, s.helper].join(" ")}>
              <div className={s.title}>
                {t('home:FILL_THE_COURSE_WITH_INTERESTING_CONTENT')}
              </div>
              <div className={s.text}>
                {t('home:EASILY_PUBLISH_COURSES_AND_START_ACCEPTING_PAYMENTS_FROM_STUDENTS')}
                {t('home:CONVENIENTS_TOOLS_FOR_MANAGING_PAYMENTS_AND_REPORTING')}
              </div>
              {(!role || role === Role.EDUCATOR || role === Role.MANAGER) &&
                <Box>
                  <Button
                    sx={{ padding: "8px 22px", borderRadius: "4px" }}
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      navigate(userInfo ? "/myCourses" : "/registration")
                    }
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: 400,
                        lineHeight: "28px",
                      }}
                    >
                      {t('home:GO_CREATING_COURSE')}
                    </Typography>
                  </Button>
                </Box>
              }
            </div>
          </div>
          <div className={s.card}>
            <div className={[s.block, s.helper].join(" ")}>
              <div className={s.title}>
                {t('home:PUBLISH_COURSES_AND_ACCEPT_PAYMENTS')}
              </div>
              <div className={s.text}>
                {t('home:EASILY_PUBLISH_COURSES_AND_START_ACCEPTING_PAYMENTS_FROM_STUDENTS')}
                {t('home:CONVENIENTS_TOOLS_FOR_MANAGING_PAYMENTS_AND_REPORTING')}
              </div>
              {(!role || role === Role.EDUCATOR || role === Role.MANAGER) &&
                <Box>
                  <Button
                    sx={{ padding: "8px 22px", borderRadius: "4px" }}
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      navigate(userInfo ? "/myCourses" : "/registration")
                    }
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: 400,
                        lineHeight: "28px",
                      }}
                    >
                      {t('home:GO_CREATING_COURSE')}
                    </Typography>
                  </Button>
                </Box>
              }
            </div>
            <div className={[s.img, s.helper_img].join(" ")}>
              <img style={{ objectFit: "contain" }} src={moreFunc2} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      <div className={s.head}> {t("home:EDUCATION_FORMAT")}</div>
      <div className={s.content}>
        <div className={s.card} style={{ gap: "16px" }}>
          <div className={s.img}>
            <img src={Img} alt="" />
          </div>
          <div className={s.block} style={{ width: "406px", flex: "none" }}>
            <div className={s.title}>{t("home:TUTORED_TRAINING")}</div>
            <div className={s.text}>
              {t("home:WILL_HAVE_ACCESS")}
              <br />
              <br /> {t("home:RECEIVE_FEEDBACK")}
            </div>
            <Box>
              <Button
                sx={{ padding: "8px 22px", borderRadius: "4px" }}
                size="large"
                variant="contained"
                color="primary"
                onClick={() => navigate({
                  pathname: "/freeCatalogCourse",
                  search: "?formatType=SYNC"
                })}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 400,
                    lineHeight: "28px",
                  }}
                >
                  {t("home:INSTRUCTOR_LED_COURSE")}
                </Typography>
              </Button>
            </Box>
          </div>
        </div>
        <div className={s.card} style={{ gap: "16px" }}>
          <div className={s.block} style={{ width: "406px", flex: "none" }}>
            <div className={s.title}>{t('home:SELF_STUDY')}</div>
            <div className={s.text}>
              {t('home:MASTER_MATERIAL_YOURSELF')}
              <br />
              <br /> {t('home:YOUR_LEARNING_WILL_DEPENDED')}
            </div>
            <Box>
              <Button
                sx={{ padding: "8px 22px", borderRadius: "4px" }}
                size="large"
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate({
                    pathname: "/freeCatalogCourse",
                    search: "?formatType=ASYNC"
                  })
                }
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 400,
                    lineHeight: "28px",
                  }}
                >
                  {t("home:SELF_PASSES_COURSE")}
                </Typography>
              </Button>
            </Box>
          </div>
          <div className={s.img}>
            <img src={Img1} alt="tab" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormatEducation;
