export interface ISettingAntiPlagiarism {
  orgId?: number | null;
  personMaxCheckCount: number | null;
  taskType: string | null;
  checkWhenSendingRevision: boolean;
  originality: number | null;
  allowedReduceOriginality: number | null;
  similarity: number | null;
  allowedReduceSimilarity: number | null;
  citation: number | null;
  selfCitation: number | null;
  symbolReplacementMaxCount: number | null;
}

export const initialSettingAntiPlagiarismData: ISettingAntiPlagiarism[] = [
  {
    personMaxCheckCount: 0,
    taskType: "TASK",
    checkWhenSendingRevision: true,
    originality: 0,
    allowedReduceOriginality: 0,
    similarity: 0,
    allowedReduceSimilarity: 0,
    citation: 0,
    selfCitation: 0,
    symbolReplacementMaxCount: 0,
  },
  {
    personMaxCheckCount: 0,
    taskType: "CONTROL",
    checkWhenSendingRevision: true,
    originality: 0,
    allowedReduceOriginality: 0,
    similarity: 0,
    allowedReduceSimilarity: 0,
    citation: 0,
    selfCitation: 0,
    symbolReplacementMaxCount: 0,
  },
];

export interface ICourseListToAddAntiPlagiarism {
  courseId: string | null;
  coverColor: string | null;
  coverFileId?: string | null;
  enabled: boolean;
  name: string;
  orgId: number | null;
  status: string | null;
}

export interface AntiPlagiarismFilter {
  search: string | null;
  status: string | null;
}
