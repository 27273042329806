import CustomModal from "@components/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { canvasPreview } from "@pages/generalSettings/CertificateSetting/canvasPreview";
import { Dispatch, FC, RefObject, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
interface CustomModalProps {
  onClose: () => void;
  open: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  crop: Crop | undefined;
  setCrop: Dispatch<SetStateAction<Crop | undefined>>;
  setCompletedCrop: Dispatch<SetStateAction<PixelCrop | undefined>>;
  imgRef: RefObject<HTMLImageElement>;
  imgSrc: string;
  completedCrop: PixelCrop | undefined;
  previewCanvasRef: RefObject<HTMLCanvasElement | null>;
  setCheckerLogoElement: Dispatch<SetStateAction<boolean>>;
  previewCanvasRefHelper: RefObject<HTMLCanvasElement>;
  title: string;
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const AddLogoModal: FC<CustomModalProps> = ({
  onClose,
  open,
  setModal,
  crop,
  setCrop,
  setCompletedCrop,
  imgRef,
  imgSrc,
  completedCrop,
  previewCanvasRef,
  setCheckerLogoElement,
  previewCanvasRefHelper,
  title,
}) => {
  const { t } = useTranslation(['course', 'common'])
  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 16 / 9));
  };
  return (
    <CustomModal
      handleToggleModal={onClose}
      open={open}
      width={700}
      px={3}
      closeIsControlled
      py={3}
    >
      <Stack direction={"column"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              lineHeight: "21px",
            }}
          >
            {title}
          </Typography>
          <CloseIcon
            sx={{ cursor: "pointer", color: "#BDBDBD" }}
            onClick={() => {
              setModal(false)
              setCompletedCrop(undefined)
            }}
          />
        </Stack>
        <Divider
          sx={{
            boxShadow: "0 0 0 100vmax #E8E8E8",
            clipPath: "inset(0 -100vmax)",
            my: 3,
          }}
        />
        <Stack direction={"column"} gap={3}>
          <Typography textAlign={"center"}>
            {t('course:THE_SELECTED_AREA_WILL_BE_SHOW_ON_THE_CERTIFICATE')}
          </Typography>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={undefined}
              minWidth={50}
              minHeight={50}
            // circularCrop
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </Box>
        </Stack>
        <Divider
          sx={{
            boxShadow: "0 0 0 100vmax #E8E8E8",
            clipPath: "inset(0 -100vmax)",
            margin: "24px 0 16px 0",
          }}
        />
        <Stack justifyContent={"flex-end"} direction={"row"} gap={1}>
          <Button
            onClick={() => {
              setModal(false);
              setCompletedCrop(undefined);
            }}
            variant="contained"
            color="secondary"
          >
            {t('common:actions.CANCEL')}
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRefHelper.current
              ) {
                canvasPreview(
                  imgRef.current,
                  previewCanvasRefHelper.current,
                  completedCrop,
                  1,
                  0
                );
              }
              if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
              ) {
                canvasPreview(
                  imgRef.current,
                  previewCanvasRef.current,
                  completedCrop,
                  1,
                  0
                );
              }
              setModal(false);
              setCheckerLogoElement(true);
            }}
          >
            {t('common:actions.SAVE')}
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default AddLogoModal;
