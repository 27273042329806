import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Box, Divider, Rating, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";

import { publicControllerApi } from "@services/index";
import { useGetCourseReviewInfoQuery } from "@services/public";
import AvatarPicture from "@components/AvatarPicture";
import CustomModal from "@components/Modal";
import UserFullName from "@components/UserFullName";
import CustomSearch from "@ui/Search";

interface IProps {
  courseId: string;
  open: boolean;
  handleToggleModal: any;
}
const ReviewCourseModal = ({ courseId, open, handleToggleModal }: IProps) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course"]);
  const iconStyle = {
    color: common.strokePrimary,
    cursor: "pointer",
    fontSize: 32,
  };
  const [searchName, setSearchName] = useState("");

  const { data: courseReview } = useGetCourseReviewInfoQuery(courseId);

  const { data: reviewList } = useQuery({
    queryKey: ["getReviewListByCourseId", searchName, courseId],
    queryFn: () => publicControllerApi.getReviewListByCourseId({
      filter: {
        search: searchName,
      },
      pageNumber: 0,
      size: 100,
      id: courseId,
    }),
    enabled: !!courseId
  });
  
  return (
    <CustomModal
      open={open}
      handleToggleModal={handleToggleModal}
      px={0}
      py={0}
      width={"870px"}
    >
      <Box display={"flex"} flexDirection={"column"} gap={3}>
        <Box>
          <Box
            display={"flex"}
            alignContent={"center"}
            justifyContent={"space-between"}
            padding={"24px"}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "21px",
                alignSelf: "center",
              }}
            >
              {t("course:REVIEWS")}
            </Typography>
            <CloseIcon
              sx={iconStyle}
              onClick={() => handleToggleModal(false)}
            />
          </Box>
          <Divider />
        </Box>
        <Box px={3} display={"flex"} gap={7.5}>
          <Box>
            <Rating
              value={courseReview?.avgRating}
              readOnly
              size="small"
              precision={0.5}
              sx={{ stroke: "#F4C41A", fontSize: "30px" }}
            />
            <Typography fontSize="12px" color="#8E8E93">
              {courseReview?.reviewSum} {t("course:GRADES").toLowerCase()}
            </Typography>
            <Typography fontSize="18px" marginTop="12px" fontWeight={500}>
              {t("course:RATING")}: {courseReview?.avgRating}
            </Typography>
          </Box>

          <Box width={"100%"}>
            <CustomSearch onChange={setSearchName} />
            {reviewList &&
              reviewList.dtoList?.map((node: any) => {
                return (
                  <Fragment key={node.id}>
                    <Box margin="24px 0">
                      <Box display="flex" gap={2} alignItems="center">
                        <AvatarPicture
                          userId={node.userId}
                          sxProps={{ width: "50px", height: "50px" }}
                        />
                        <Box>
                          <Typography fontSize="16px" fontWeight={600}>
                            <UserFullName id={node.userId} />
                          </Typography>
                          <Box display="flex" gap={1}>
                            <Rating
                              value={node.grade}
                              readOnly
                              size="small"
                              precision={0.5}
                              sx={{ stroke: "#F4C41A" }}
                            />
                            <Typography fontSize="12px" color="#8E8E93">
                              {format(
                                new Date(node.createdAt),
                                "dd.MM.yyyy HH:mm"
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Typography
                        marginTop="12px"
                        fontSize="16px"
                        color="#8E8E93"
                      >
                        {node.review}
                      </Typography>
                    </Box>
                    <Divider />
                  </Fragment>
                );
              })}
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default ReviewCourseModal;
