import React, { useEffect, useState } from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip, Box, Typography, Divider, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DTO, initialDtoForEDUCourse } from '../models';
import { serviceDict } from '@services/dictionary';

export const AboutProgress = () => {
  const { t } = useTranslation(["aero"]);
  const { common } = useTheme().palette;

  const [initialData, setInitialData] = useState<DTO>(initialDtoForEDUCourse)

  useEffect(() => {
    serviceDict.getTypeOptionList("1").then((res) => {
      if (res.status === 200) {
        setInitialData(res.data.find(typeOption => typeOption.code === "EDU"));
      }
    })
  }, []);
  return (
    <Tooltip componentsProps={{
      tooltip: { sx: { background: "none" } }
    }} title={<Box
      sx={{
        background: "#fff",
        color: "#000",
        fontSize: 18,
        width: "200px",
        padding: "10px",
        borderRadius: "6px",
        border: `1px solid ${common.borderSecondary}`
      }}>
      <Typography>{t("aero:TRUST_LEVEL")}</Typography>
      <Divider sx={{ margin: "10px 0" }} />
      <Box sx={{ alignItems: "center", display: "flex", gap: 1, mb: "10px" }}>
        <Box sx={{ background: "#D62B20", width: "6px", height: "42px", borderRadius: "4px" }}></Box>
        <Box>
          <Typography fontSize="14px" fontWeight={500}>{t("aero:LOW_TRUST")}</Typography>
          <Typography fontSize="14px" fontWeight={400} color="#6F6F7E">(0-{initialData.violation?.trustLevelLow}%)</Typography>
        </Box>
      </Box>
      <Box sx={{ alignItems: "center", display: "flex", gap: 1, mb: "10px" }}>
        <Box sx={{ background: "#E5D226", width: "6px", height: "42px", borderRadius: "4px" }}></Box>
        <Box>
          <Typography fontSize="14px" fontWeight={500}>{t("aero:AVERAGE_TRUST")}</Typography>
          <Typography fontSize="14px" fontWeight={400} color="#6F6F7E">({initialData.violation && initialData.violation?.trustLevelLow + 1}-{initialData.violation?.trustLevelHigh}%)</Typography>
        </Box>
      </Box>
      <Box sx={{ alignItems: "center", display: "flex", gap: 1, mb: "10px" }}>
        <Box sx={{ background: "#7FE47E", width: "6px", height: "42px", borderRadius: "4px" }}></Box>
        <Box>
          <Typography fontSize="14px" fontWeight={500}>{t("aero:HIGH_TRUST")}</Typography>
          <Typography fontSize="14px" fontWeight={400} color="#6F6F7E">({initialData.violation && initialData.violation?.trustLevelHigh + 1}-100%)</Typography>
        </Box>
      </Box>
    </Box>}
      placement="top-start">
      <Box display="flex" alignItems="center" gap={1} sx={{ color: "#8E8E93", cursor: "pointer" }}>
        <HelpOutlineIcon fontSize="small" />
      </Box>
    </Tooltip>
  )
}
