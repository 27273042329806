import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { serviceNotification } from '@services/notification';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { notificationActions } from '@store/notification/reducers';
import { useDispatch, useSelector } from "react-redux";

interface Item {
  messageRu: string;
  messageKz: string;
  messageEn: string;
}

export const NotificationElement = ({ notification }) => {
  const [notificationCreatedTime, setNotificationCreatedTime] = useState(new Date())
  const { t, i18n } = useTranslation(["notification"]);
  const { common } = useTheme().palette;
  const dispatch = useDispatch();
  const unreadNotificationsCount = useSelector(
    (store: any) => store.notification.notificationCount
  );
  const getMessage = (language: string, item: Item) => {
    switch (language) {
      case "en":
        return item.messageEn;
      case "kz":
        return item.messageKz;
      default:
        return item.messageRu;
    }
  };

  const currentTime = new Date();
  const timeDiffMs = currentTime.getTime() - notificationCreatedTime.getTime();
  const timeDiffDays = Math.floor(timeDiffMs / 86400000);
  const timeDiffHours = Math.floor(timeDiffMs / 3600000);
  const timeDiffMin = Math.floor(timeDiffMs / 60000);
  const timeDiffSec = Math.floor(timeDiffMs / 1000);
  if (timeDiffDays > 0) {
    notification.createdTime = `${timeDiffDays} ${t("DAYS_AGO")}`;
  } else if (timeDiffHours > 0) {
    notification.createdTime = `${timeDiffHours} ${t("HOURS_AGO")}`;
  } else if (timeDiffMin > 0) {
    notification.createdTime = `${timeDiffMin} ${t("MINUTES_AGO")}`;
  } else if (timeDiffSec > 0) {
    notification.createdTime = `${timeDiffSec} ${t("SECONDS_AGO")}`;
  }

  useEffect(() => {
    setNotificationCreatedTime(new Date(notification.createdTime))
    // eslint-disable-next-line
  }, [])


  return (
    <Box sx={{ display: "block" }}>
      <Box
        sx={{ display: "flex", alignItems: "center", width: "100%" }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "50px", mr: "-25px" }}>
            <Box
              sx={{
                border: `1px solid ${common.primaryColor}`,
                width: "16px",
                height: "16px",
                borderRadius: "20px",
                backgroundColor: common.secondaryColor,
                cursor: "pointer",
              }}
              onClick={() => {
                serviceNotification
                  .updateNotificationStatus(notification.id)
                  .then((res: any) => {
                    if (res.status === 200) {
                      dispatch(
                        notificationActions.setNotificationCount(
                          unreadNotificationsCount - 1
                        )
                      );
                    }
                  });
              }}
            ></Box>
          </Box>
          <Avatar />
        </Box>

        <Typography sx={{ pl: "8px", maxHeight: "50px", pr: "10px" }}>
          {getMessage(i18n.language, notification).length > 75
            ? getMessage(i18n.language, notification)?.slice(0, 75) + "..."
            : getMessage(i18n.language, notification)}
        </Typography>
      </Box>
      <Typography
        sx={{
          color: "#A5ACB8",
          ml: "72px",
          mb: "12px",
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "14px",
          mt: "4px",
        }}
      >
        {timeDiffDays > 0
          ? notification.createdTime
          : timeDiffHours > 0
            ? notification.createdTime
            : timeDiffMin > 0
              ? notification.createdTime
              : timeDiffSec > 0
                ? notification.createdTime
                : t("JUST_NOW")}
      </Typography>
    </Box>
  );
}
