import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { JournalSchema } from "./journalSchema";

const initialState:JournalSchema={
    flowId:""
}

export const journalSlice=createSlice({
    name:"journal",
    initialState,
    reducers:{
        setFlowId: (state, action:PayloadAction<string>) => {
            state.flowId = action.payload;
        }
    }
})


export const { actions: journalActions } = journalSlice;
export const { reducer: journalReducer } = journalSlice;