
import EducatorCourseList from "./Educator";
import StudentCourseList from "./Student";
import LoadingSpinner from "@components/LoadingSpinner";
import { Role } from "@interfaces/index";
import { StateSchema } from "@store/StateSchema";
import { useSelector } from "react-redux";

const MyCourses = () => {
  const { role } = useSelector((store: StateSchema) => store.account.profileInfo.authorityInfo);

  switch (role) {
    case Role.EDUCATOR:
    case Role.MANAGER:
      return <EducatorCourseList />;
    case Role.LISTENER:
      return <StudentCourseList />;
    default:
      return <LoadingSpinner />;
  }
}

export default MyCourses;