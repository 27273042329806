import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Autocomplete,
  Box,
  Divider,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CustomSearch from "@ui/Search";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import emptyMeeting from "@assets/images/emptyMeeting.png";
import { serviceCourse } from "@services/course";
import { formatDateWithHourMinute } from "@utils/functions";
import { isWithinInterval } from "date-fns";

export const PlanedConference = () => {
  const { t } = useTranslation(["user", "common", "dictionary", "course"]);
  const { common } = useTheme().palette;
  const { classId } = useParams();
  const params = useParams();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [statusState, setStatusState] = useState<string | null>(null);
  const [searchName, setSearchName] = useState<string>("");
  const [allConferenceList, setAllConferenceList] = useState<any>([]);
  const [conferenceList, setConferenceList] = useState<any>([]);
  const [themeList, setThemeList] = useState<Array<any>>([]);
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role } = profile.authorityInfo;
  const statusOptions = [
    { label: t("course:conference.UPCOMING"), value: "UPCOMING" },
    { label: t("course:conference.LIVE"), value: "LIVE" },
  ];
 
  useEffect(() => {
    if (classId) {
      if (role === "LISTENER") {
        serviceCourse.getMeetingAll(classId, "PLANNED").then((res) => {
          if (res.status === 200) setAllConferenceList(res.data);
        });
      } else {
        serviceCourse.getMyMeetingByStatus(classId, "PLANNED").then((res) => {
          if (res.status === 200) setAllConferenceList(res.data);
        });
      }
    }
  }, [classId, role]);

  useEffect(() => {
    let resultAfterSearch = allConferenceList;
    if (searchName) {
      resultAfterSearch = allConferenceList.filter((conference) =>
        conference.title.toLowerCase().includes(searchName.toLowerCase())
      );
    }
    setConferenceList(resultAfterSearch);
  }, [searchName, allConferenceList]);

  useEffect(() => {
    let resultAfterStatus = allConferenceList;
    if (statusState === "LIVE" && allConferenceList) {
      resultAfterStatus = allConferenceList.filter((conference) =>
        isWithinInterval(new Date(), {
          start: new Date(conference?.startDate),
          end: new Date(conference?.endDate),
        })
      );
    }
    if (statusState === "UPCOMING" && allConferenceList) {
      resultAfterStatus = allConferenceList.filter(
        (conference) =>
          !isWithinInterval(new Date(), {
            start: new Date(conference?.startDate),
            end: new Date(conference?.endDate),
          })
      );
    }
    setConferenceList(resultAfterStatus);
  }, [statusState, allConferenceList]);

  useEffect(() => {
    if (classId) {
      serviceCourse.getOrderedThemeList(classId).then((res) => {
        setThemeList(res.data);
      });
    }
  }, [classId]);

  return (
    <Box
      sx={{
        background: "#fff",
        padding: "15px",
        borderRadius: "6px",
      }}
    >
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={2}
        marginBottom="15px"
      >
        <CustomSearch
          placeholder={t("course:conference.SEARCH_BY_CONFERENCE_NAME")}
          height="44px"
          onChange={setSearchName}
        />
        <Box
          sx={{
            border: `1px solid ${common.borderSecondary}`,
            height: "40px",
            width: "124px",
            backgroundColor: "white",
            borderRadius: "9px",
            display: "flex",
            alignItems: "center",
            px: "4px",
            cursor: "pointer",
          }}
          onClick={() => setOpenFilter(!openFilter)}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              color: "black",
              mr: "8px",
              ml: "4px",
            }}
          >
            {t("common:FILTER")}
          </Typography>
          <TuneIcon />
          {openFilter ? (
            <ArrowDropUpIcon sx={{ width: "30px", height: "30px" }} />
          ) : (
            <ArrowDropDownIcon sx={{ width: "30px", height: "30px" }} />
          )}
        </Box>
      </Box>
      {openFilter && (
        <Box display="flex" alignItems="center" gap={3} marginBottom={"20px"}>
          <Autocomplete
            options={statusOptions}
            getOptionLabel={(option) => `${option.label}`}
            size="small"
            onChange={(e, value) => setStatusState(value?.value || "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("user:STATUS")}
                sx={{ width: "250px" }}
                inputProps={{ ...params.inputProps, readOnly: true }}
              />
            )}
          />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          background: common.fontWhite,
          borderRadius: 10,
          mt: 2,
          flexDirection: "column",
          gap: 2,
        }}
      >
        {conferenceList?.length > 0 &&
          conferenceList.map((conference) => {
            return (
              <Paper
                elevation={3}
                sx={{
                  padding: "24px",
                  height: "auto",
                  "&::-webkit-scrollbar": {
                    width: 3,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "lightgray",
                    borderRadius: "6px",
                  },
                  boxShadow: "none",
                  borderRadius: "16px",
                  border: "1px solid #E0E0E0",
                }}
              >
                <Box
                  width="100%"
                  key={conference.id}
                  display="flex"
                  gap={"12px"}
                  flexDirection={"column"}
                >
                  <Box display="flex" justifyContent={"space-between"}>
                    <Box display="flex">
                      <Typography color={"#708099"}>
                        {formatDateWithHourMinute(conference.startDate)}-
                        {formatDateWithHourMinute(conference.endDate)}
                      </Typography>
                    </Box>
                    <Box display="flex" gap={1} sx={{}}>
                      {isWithinInterval(new Date(), {
                        start: new Date(conference.startDate),
                        end: new Date(conference.endDate),
                      }) ? (
                        <Box
                          display="flex"
                          sx={{ background: "#FE1010" }}
                          alignItems="center"
                          borderRadius="6px"
                          padding={"2px 8px"}
                        >
                          <Typography color="#fff">
                            {t("course:conference.STARTED")}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          display="flex"
                          sx={{ background: "#FCB62E" }}
                          alignItems="center"
                          borderRadius="6px"
                          padding={"2px 8px"}
                        >
                          <Typography color="#fff">
                            {t("course:conference.PLANNED")}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Typography
                    fontSize="20px"
                    color={common.primaryColor}
                    sx={{ cursor: "pointer" }}
                    onClick={() => window.open(`${conference.link}`, "_blank")}
                  >
                    {conference?.title}
                  </Typography>
                  <Box display={"flex"} justifyContent={"space-between"} gap={5}>
                    <Box alignItems={"flex-start"} width={"100%"} display="flex" gap={1}>
                      {conference.themeId && (
                        <Typography>
                          {t("course:theme.THEME")}:{" "}
                          {
                            themeList.filter(
                              (theme) => theme.id === conference.themeId
                            )[0]?.name
                          }{" "}
                        </Typography>
                      )}
                      <Box display="flex" alignItems="center">
                        <Typography>
                          {t("dictionary:ORGANIZER")}:{" "}
                          {conference?.coordinatorUserName}
                        </Typography>
                      </Box>
                    </Box>
                    <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={1}>
                      <Typography>
                        {t("dictionary:PLATFORM")}: {conference.platform.nameRu}
                      </Typography>
                      <Typography>
                        {t("user:LOGIN")}: {conference.login}
                      </Typography>
                      <Typography>
                        {t("user:PASSWORD")}: {conference.password}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            );
          })}
      </Box>

      {conferenceList?.length === 0 && (
        <Box
          margin="0 auto"
          display="flex"
          gap={2}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={matchesDownSm ? "250px" : "550px"}
          paddingTop="50px"
        >
          <img
            src={emptyMeeting}
            width={matchesDownSm ? "200px" : "500px"}
            alt="emptyMeeting"
          />
          <Typography
            sx={{
              fontSize: { xs: "18px" },
              fontWeight: { xs: "700", sm: "500" },
            }}
          >
            {t("course:conference.NO_CONFERENCES_YET")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
