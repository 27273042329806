import { useEffect, useState } from 'react'
import CustomSearch from '@ui/Search'
import { Box, Table, TableContainer, TableHead, Typography, useTheme, TableRow, TableCell, TableBody } from '@mui/material'
import { useTranslation } from 'react-i18next';
import CustomAutocomplete from '@ui/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { serviceAero } from '@services/aero';
import { useParams } from 'react-router';
import { serviceMember } from '@services/member';
import { CourseRole, IBreadcrumb, ICourse, IDictionary, IUserSearchItem } from '@interfaces/index';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import Breadcrumbs from '@components/Breadcrumbs';
import { serviceCourse } from '@services/course';

export const AeroTaskList = () => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["user", "common", "aero"]);
  const navigate = useNavigate();
  const [taskList, setTaskList] = useState<any>(null)
  const { courseId } = useParams();
  const [taskName, setTaskName] = useState<string>('')
  const [responsibleMemberList, setResponsibleMemberList] = useState<IUserSearchItem[]>([])
  const [responsibleMemberId, setResponsibleMemberId] = useState<any>(null)
  const [courseInfo, setCourseInfo] = useState<ICourse>();
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();

  const style = {
    '& label.Mui-focused': { color: common.primaryColor },
    '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': { borderColor: common.primaryColor },
      '&.Mui-focused fieldset': { borderColor: common.primaryColor }
    }
  }

  useEffect(() => {
    serviceAero.getTaskList({
      pageNumber: 0,
      size: 100,
      filter: { courseId: courseId, name: taskName, responsibleMemberId }
    }).then((res) => {
      if (res.status === 200) {
        setTaskList(res.data.dtoList)
      }
    })
  }, [courseId, taskName, responsibleMemberId])

  useEffect(() => {
    if (courseId) {
      serviceMember.getMemberList(courseId, [CourseRole.TUTOR, CourseRole.CURATOR, CourseRole.ASSISTANT, CourseRole.EXAMINER])
        .then(res => {
          if (res.status === 200) {
            setResponsibleMemberList(res.data);
          }
        })
      serviceCourse.getCourseInfo(courseId).then((res) => {
        if (res.status === 200) {
          setCourseInfo(res.data)
        }
      })
    }
  }, [courseId]);

  useEffect(() => {
    if (courseInfo?.name) {
      const updatedCrumbs = [...getBreadcrumbs("aero_task")].filter((x: IBreadcrumb) => x.level !== 4 && x.level !== 3);
      updatedCrumbs.push({
        level: 3,
        page: 'taskList',
        label: courseInfo?.name,
        path: `/settings/aero/course/${courseId}`,
        tabNum: null
      });
      // Check if the breadcrumbs have actually changed before updating
      if (JSON.stringify(updatedCrumbs) !== JSON.stringify(getBreadcrumbs("aero_task"))) {
        setBreadcrumbs("aero_task", updatedCrumbs);
      }
    }
  }, [getBreadcrumbs, setBreadcrumbs, t, courseInfo?.name, courseId]);

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: 3 }}>
        <Breadcrumbs id="aero_task" />
      </Box>
      <Typography sx={{ fontSize: "36px", fontWeight: 600, marginBottom: "30px" }}>{courseInfo?.name}</Typography>
      <Box sx={{ backgroundColor: common.fontWhite, width: "100%", borderRadius: "12px", p: "32px" }}>
        <CustomSearch
          placeholder={t("common:placeholders.SEARCH_BY_NAME")}
          height="44px"
          width='90%'
          onChange={setTaskName}
        />
        <Box display="flex" gap={2} margin="10px 0px">
          <CustomAutocomplete
            name='teacherName'
            sx={{ width: '25%', height: '35px', ...style }}
            options={responsibleMemberList}
            placeholder={t("common:placeholders.SELECT_RESPONSIBLE_TEACHER")}
            onChange={(name: string, value: IDictionary) => setResponsibleMemberId(value)}
          />
        </Box>
        <TableContainer sx={{ mt: '16px', width: "100%" }}>
          <Table>
            <TableHead sx={{ background: common.secondaryColor }}>
              <TableRow>
                <TableCell>{t("course:task.NAME_TASK")}</TableCell>
                {/* <TableCell>{t("course:theme.RESPONSIBLE_TEACHER")}</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {taskList?.map((task) => {
                return (
                  <TableRow key={task?.id} hover sx={{ cursor: "pointer" }} onClick={() => navigate(`/settings/aero/course/${courseId}/task/${task.id}`)}>
                    <TableCell>{task?.name}</TableCell>
                    {/* <TableCell>{task?.responsibleMembers.map((member) => member?.userFullName)}</TableCell> */}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
