import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { PixelCrop } from "react-image-crop";
import { Button } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Skeleton from '@mui/material/Skeleton';

import Img from "@assets/images/home/Certificate.png";
import Logo from "@assets/images/home/Logo.png";
import Qr from "@assets/images/home/qr.png";
import Sign from "@assets/images/home/sign.png";
import OrgLogoTemplate from "@assets/images/logoTemplate.png"
import OrgSignTemplate from "@assets/images/signTemplate.png"
import s from "./Certificate.module.scss";
import { ICertificateData } from "@pages/courses/models";

const url = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_PRODUCTION_URL
  : process.env.REACT_APP_DEVELOPMENT_URL;

interface Props {
  checkerLogoElement?: boolean;
  checkerLogoSignElement?: boolean;
  completedCrop?: PixelCrop | undefined;
  completedCropSign?: PixelCrop | undefined;
  courseName?: string | null;
  certificateDisplay?: "HOURS" | "CREDITS" | null;
  hours?: number | null;
  credits?: number | null;
  orgLogo?: string | null;
  orgLogoSign?: string | null,
  qrCode?: string | null
  certificateData?: ICertificateData,
  isLogoAvailable?: boolean,
  isSignAvailable?: boolean,
}
interface CertificateRef {
  logo: React.RefObject<HTMLCanvasElement>;
  logoSign: React.RefObject<HTMLCanvasElement>;
}
const Certificate = React.forwardRef<CertificateRef, Props>((props, ref) => {
  const {
    checkerLogoElement,
    checkerLogoSignElement,
    completedCrop,
    completedCropSign,
    certificateDisplay,
    hours,
    credits,
    orgLogo,
    orgLogoSign,
    qrCode,
    certificateData,
    isLogoAvailable = true,
    isSignAvailable = true,
  } = props;
  const { t } = useTranslation(["common", "course"]);
  const divRef = useRef<HTMLDivElement | null>(null);
  const [divWidth, setDivWidth] = useState(0);

  useEffect(() => {
    if (divRef.current) {
      setDivWidth(divRef.current.offsetWidth);
    }
    const handleResize = () => {
      if (divRef.current) {
        setDivWidth(divRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        id="certificate"
        className={s.wrapper}
        style={{
          backgroundImage: `url(${Img})`,
          height: `${divWidth * 0.70}px`,
          padding: (divWidth * 50) / 1440,
          marginBottom: '5rem'
        }}
        ref={divRef}
      >
        <div
          className={s.head}
          style={{
            marginBottom: (divWidth * 40) / 1440,
          }}
        >
          <div className={s.org}>
            <div
              className={s.logo}
              style={{
                width: (divWidth * 235) / 1440,
                height: (divWidth * 75) / 1440,
              }}
            >
              {
                certificateData
                  ?
                  <img src={Logo} alt="logo" />
                  :
                  <Skeleton height={(divWidth * 105) / 1440} width={(divWidth * 175) / 1440} />
              }
            </div>
            {!checkerLogoElement && (
              <div
                className={s.org_img}
                style={{
                  width: "auto",
                  height: (divWidth * 75) / 1440,
                }}
              >
                {
                  orgLogo
                    ?
                    <img src={`data:image/img;base64,${orgLogo}`} alt="logo" />
                    :
                    isLogoAvailable ? 
                    <Skeleton height={(divWidth * 105) / 1440} width={(divWidth * 175) / 1440} /> 
                    :
                    <img src={OrgLogoTemplate} alt="OrgLogoTemplate"/>
                }
              </div>
            )}
            {!!completedCrop && (
              <div>
                <canvas
                  ref={
                    (ref as React.MutableRefObject<CertificateRef>).current.logo
                  }
                  style={{
                    objectFit: "contain",
                    width: "auto",
                    height: (divWidth * 75) / 1440,
                  }}
                />
              </div>
            )}
          </div>
          <div className={s.qr_content} 
            style={{
              width: (divWidth * 130) / 1440,
              height: (divWidth * 150) / 1440
            }}
          >
            <div className={s.qr}>
              {
                qrCode
                  ?
                  <img
                    src={qrCode?.length > 1 ? `data:image/img;base64,${qrCode}` : Qr}
                    style={{
                      width: (divWidth * 130) / 1440,
                      height: (divWidth * 130) / 1440,
                    }}
                    alt="logo"
                  />
                  :
                  <Skeleton height={(divWidth * 160) / 1440} width={(divWidth * 120) / 1440} />
              }

            </div>
            <div
              className={s.text}
              style={{
                fontSize: (divWidth * 16) / 1440,
              }}
            >
              {
                certificateData
                  ?
                  certificateData?.number
                  :
                  <Skeleton height={(divWidth * 30) / 1440} width={(divWidth * 120) / 1440} />
              }
            </div>
          </div>
        </div>
        <div
          className={s.content}
          style={{
            gap: (divWidth * 32) / 1440,
            height: '32%'
          }}
        >
          <div
            className={s.title}
            style={{
              fontSize: (divWidth * 25) / 1440,
              lineHeight: `${(divWidth * 39) / 1440}px`,
            }}
          >
            {
              certificateData
                ?
                t('course:CERTIFICATE_OF_COMPLETION_OF_THE_COURSE')
                :
                <Skeleton height={(divWidth * 40) / 1440} width={(divWidth * 450) / 1440} />
            }
          </div>
          <div
            style={{
              fontSize: (divWidth * 70) / 1440,
              lineHeight: `${(divWidth * 79) / 1440}px`,
              maxWidth: '80%',
            }}
            className={s.subtitle}
          >
            {
              certificateData
                ?
                certificateData?.courseName?.length > 0 ? certificateData?.courseName : t('course:COURSE_NAME')
                :
                <Skeleton height={(divWidth * 130) / 1440} width={(divWidth * 630) / 1440} />
            }
          </div>
          {certificateDisplay === "HOURS" &&
            <div
              className={s.title}
              style={{ 
                fontSize: (divWidth * 18) / 1440,
                lineHeight: `${(divWidth * 20.8) / 1440}px`,
              }}
            >
              {t('course:NUMBER_OF_HOURS_COMPLETED')}: {hours}
            </div>
          }
          {certificateDisplay === "CREDITS" &&
            <div
              className={s.title}
              style={{ 
                fontSize: (divWidth * 18) / 1440,
                lineHeight: `${(divWidth * 20.8) / 1440}px`,
              }}
            >
              {t('course:NUMBER_OF_CREDITS_COMPLETED')}: {credits}
            </div>
          }
        </div>

        <div className={s.bottom}>
          <div
            className={s.info}
            style={{
              gap: (divWidth * 24) / 1440,
            }}
          >
            <div
              className={s.name}
              style={{
                fontSize: (divWidth * 52) / 1440,
                lineHeight: `${(divWidth * 68) / 1440}px`,
              }}
            >
              {
                certificateData
                  ?
                  certificateData?.listenerName?.length > 0 ? certificateData?.listenerName : t('course:LASTNAME_FIRSTNAME_LISTENER')
                  :
                  <Skeleton height={(divWidth * 110) / 1440} width={(divWidth * 530) / 1440} />
              }
            </div>
            <div className={s.date}>
              <div className={s.part}>
                <div className={s.block}>
                  <div
                    className={s.label}
                    style={{
                      fontSize: (divWidth * 16) / 1440,
                      lineHeight: `${(divWidth * 20.8) / 1440}px`,
                    }}
                  >
                    {
                      certificateData
                        ?
                        certificateData?.issueDate?.length > 0 ? certificateData?.issueDate : t('course:MONTH_YEAR')
                        :
                        <Skeleton height={(divWidth * 40) / 1440} width={(divWidth * 80) / 1440} />
                    }
                  </div>
                  <div
                    className={s.value}
                    style={{
                      fontSize: (divWidth * 16) / 1440,
                      lineHeight: `${(divWidth * 20.8) / 1440}px`,
                    }}
                  >
                    {t('course:DATE_OF_ISSUE')}
                  </div>
                </div>
                <div className={s.block}>
                  <div
                    className={s.label}
                    style={{
                      fontSize: (divWidth * 16) / 1440,
                      lineHeight: `${(divWidth * 20.8) / 1440}px`,
                    }}
                  >
                    {
                      certificateData
                        ?
                        certificateData?.courseDuration?.length > 0 ? certificateData?.courseDuration : `${t('course:MONTH_YEAR')} - ${t('course:MONTH_YEAR')}`
                        :
                        <Skeleton height={(divWidth * 40) / 1440} width={(divWidth * 160) / 1440} />
                    }
                  </div>
                  <div
                    className={s.value}
                    style={{
                      fontSize: (divWidth * 16) / 1440,
                      lineHeight: `${(divWidth * 20.8) / 1440}px`,
                    }}
                  >
                    {t('course:COURSE_DATES')}
                  </div>
                </div>
              </div>
              <div className={s.part}>
                <div className={s.block}>
                  <div
                    className={s.label}
                    style={{
                      fontSize: (divWidth * 16) / 1440,
                      lineHeight: `${(divWidth * 20.8) / 1440}px`,
                    }}
                  >
                    {
                      certificateData
                        ?
                        certificateData?.certificateTemplateDto.organizationName?.length > 0 ? certificateData?.certificateTemplateDto?.organizationName : 'AstanaHub'
                        :
                        <Skeleton height={(divWidth * 40) / 1440} width={(divWidth * 80) / 1440} />
                    }
                  </div>
                  <div
                    className={s.value}
                    style={{
                      fontSize: (divWidth * 16) / 1440,
                      lineHeight: `${(divWidth * 20.8) / 1440}px`,
                    }}
                  >
                    {t('course:COURSE_ORGANIZATION')}
                  </div>
                </div>
                <div className={s.block}>
                  <div
                    className={s.label}
                    style={{
                      fontSize: (divWidth * 16) / 1440,
                      lineHeight: `${(divWidth * 20.8) / 1440}px`,
                    }}
                  >
                    {
                      certificateData
                        ?
                        certificateData?.certificateTemplateDto.organizationDirectorName?.length > 0 ? certificateData?.certificateTemplateDto?.organizationDirectorName : 'Оспанов Дмитрий'
                        :
                        <Skeleton height={(divWidth * 40) / 1440} width={(divWidth * 160) / 1440} />
                    }
                  </div>
                  <div
                    className={s.value}
                    style={{
                      fontSize: (divWidth * 16) / 1440,
                      lineHeight: `${(divWidth * 20.8) / 1440}px`,
                    }}
                  >
                    {t('course:HEAD_OF_THE_ORGANIZATION')}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={s.link}
              style={{
                fontSize: (divWidth * 16) / 1440,
                lineHeight: `${(divWidth * 20.8) / 1440}px`,
              }}
            >
              {
                certificateData
                  ?
                  `${url}/certificate/${certificateData?.id}`
                  :
                  <Skeleton height={(divWidth * 30) / 1440} width={(divWidth * 380) / 1440} />
              }
            </div>
          </div>
          <div
            className={s.sign}
            style={{
              gap: (divWidth * 67) / 1440,
            }}
          >
            <div
              className={s.seal}
              style={{
                width: (divWidth * 285) / 1440,
                height: (divWidth * 285) / 1440,
              }}
            >
              {
                certificateData
                  ?
                  <img src={Sign} alt="" />
                  :
                  <Skeleton sx={{ borderRadius: '50%' }} height={(divWidth * 350) / 1440} width={(divWidth * 220) / 1440} />
              }
            </div>
            {!checkerLogoSignElement && (
              <div
                className={s.caption}
                style={{
                  width: (divWidth * 235) / 1440,
                  height: (divWidth * 75) / 1440,
                }}
              >
                {
                  orgLogoSign
                    ?
                    <img src={`data:image/img;base64,${orgLogoSign}`} alt="sign" />
                    :
                    isSignAvailable ? 
                    <Skeleton height={(divWidth * 105) / 1440} width={(divWidth * 200) / 1440} /> 
                    : <img src={OrgSignTemplate} alt="OrgSignTemplate"/>
                }
              </div>
            )}
            {!!completedCropSign && (
              <div>
                <canvas
                  ref={
                    (ref as React.MutableRefObject<CertificateRef>).current
                      .logoSign
                  }
                  style={{
                    objectFit: "contain",
                    width: "auto",
                    height: (divWidth * 75) / 1440,
                    maxHeight: "70px"
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Button
        sx={{
          order: -1,
          display: "none",
          "@media (max-width:960px)": {
            display: "flex",
            marginBottom: "20px",
          },
        }}
        endIcon={<FileDownloadOutlinedIcon />}
        variant="contained"
      >
        Скачать
      </Button>
    </>
  );
});

export default Certificate;
