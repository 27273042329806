import { Box, Button, Typography } from "@mui/material";
import s from "./CommonInfo.module.scss";
import { CarouselProps } from "../types/home";

type PropsType = {
  value: Partial<Omit<CarouselProps, "backgroundImage">>;
};
const CommonInfo = (props: PropsType) => {
  const { title, text, onClick, button } = props.value;
  return (
    <div className={s.head}>
      {title && <div className={s.title}> {title}</div>}
      {text && <div className={s.text}>{text}</div>}
      {button && (
        <Box>
          <Button
            sx={{ borderRadius: "4px" }}
            size="large"
            variant="contained"
            color="primary"
            onClick={onClick}
          >
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "28px",
              }}
            >
              {button}
            </Typography>
          </Button>
        </Box>
      )}
    </div>
  );
};

export default CommonInfo;
