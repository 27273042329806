import React, { useEffect, useState } from 'react'
import { TabContext } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Divider, Tabs, Tab, Typography, useTheme } from '@mui/material'
import { FinishedConference } from './FinishedConference';

export const ConferenceCurrentCourses = () => {
    const { t } = useTranslation(["course"]);
    const { common } = useTheme().palette;
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [typeCourse, setTypeCourse] = useState("finished");
    const currentTab = searchParams.get('typeConference');

    const handleChange = (event: React.SyntheticEvent, typeConference: string) => {
        setTypeCourse(typeConference);
        setSearchParams({ typeConference: typeConference });
    };

    useEffect(() => {
        if (currentTab) {
            setTypeCourse(currentTab);
        } else {
            setTypeCourse("finished");
        }
    }, [currentTab])
    return (
        <Box>
            <TabContext value={typeCourse}>
                <Box sx={{ width: "100%", background: "#fff" }}>
                    <Box
                        sx={{
                            padding: "10px 0 0 10px",
                        }}
                    >
                        <Tabs
                            onChange={handleChange}
                            indicatorColor="secondary"
                            value={typeCourse}
                            sx={{
                                "& .MuiTab-root.Mui-selected": {
                                    color: "#1A141F",
                                },
                                "& .MuiTabs-indicator": {
                                    background: common.borderPrimary,
                                },
                            }}
                        >
                            <Tab
                                label={t('course:COMPLETED_MULTIPLE')}
                                value={"finished"}
                                sx={{ textTransform: "none", fontSize: "18px" }}
                            />
                        </Tabs>
                    </Box>
                </Box>
            </TabContext>
            {typeCourse === "finished" && <FinishedConference />}
        </Box>
    )
}
