import { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme, Paper, AlertColor } from '@mui/material';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { serviceDict } from '@services/dictionary';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import Breadcrumbs from '@components/Breadcrumbs';
import DeleteModal from '@components/modals/DeleteModal';
import Message from '@components/Message';
import CustomButton from '@ui/Button';
import FormikAutocomplete from '@ui/formik/Autocomplete';
import FormikSwitch from '@ui/formik/Switch';
import { courseTypes, courseFormats } from "@utils/enums";
import { Role } from '@interfaces/index';

interface GradingSystem {
  gradings: {
    id: number
    name: string
    courseTypeCodes: string[]
    courseFormatCodes: string[]
    isActive: boolean
    createdBy: string
    createdAt: string
  }[]
}
const AssessmentSystem = () => {
  const { t } = useTranslation(["enum", "common", "sideMenu", "course", "generalSetting"]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { authorityInfo: { role } } = profile;
  const isInputDisabled = !!(role === 'SUPER_ADMIN' && id)
  const [messageOpen, setMessageOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messageType, setMessageType] = useState<AlertColor>('success')
  const [gradingSystemInitialData, setGradingSystemInitialData] = useState<GradingSystem | null>(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [evaluationSystemId, setEvaluationSystemId] = useState<string | number | null>(null);
  const { setBreadcrumbs } = useBreadcrumbs();

  const handleToggleDeleteModal = (modalState: boolean, evalSystemId?: string | null) => {
    if (modalState) {
      setEvaluationSystemId(evalSystemId || "");
    } else {
      setEvaluationSystemId(null);
    }
    setDeleteModalOpen(modalState);
  }

  const deleteEvaluationSystem = () => {
    // TODO: call the service to delete
    setDeleteModalOpen(false)
  }

  useEffect(() => {
    serviceDict.getGradingSystemList(id ? Number(id) : undefined).then((res) => {
      if (res.status === 200) setGradingSystemInitialData({ gradings: res.data });
    });
  }, [id])

  useEffect(() => {
    if (role === 'SUPER_ADMIN' && id) {
      setBreadcrumbs("assessment_system", [{
        level: 1,
        page: 'dictionary',
        label: "dictionary:DICTIONARIES",
        path: '/dict',
        tabNum: null
      }, {
        level: 2,
        page: 'organizations',
        label: "dictionary:ORGANIZATIONS",
        path: '/dict/organizations',
        tabNum: null
      },
      {
        level: 3,
        page: 'edit organization',
        label: "dictionary:EDIT_ORGANIZATION",
        path: `/dict/organization/${id}?tab=additionalSettings`,
        tabNum: null
      },
      {
        level: 4,
        page: 'assessment system',
        label: "sideMenu:ASSESSMENT_SYSTEM",
        path: null,
        tabNum: null
      }]);
    } else if (role === 'LOCAL_ADMIN') {
      setBreadcrumbs("assessment_system", [{
        level: 1,
        page: 'settings',
        label: "course:SETTINGS",
        path: '/settings',
        tabNum: null
      }, {
        level: 2,
        page: 'assessment system',
        label: "sideMenu:ASSESSMENT_SYSTEM",
        path: null,
        tabNum: null
      }]);
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Box>
      {((id && role === Role.SUPER_ADMIN) || role === Role.LOCAL_ADMIN) &&
        <Box sx={{ display: "flex", alignItems: 'center', mb: '24px' }}>
          <Breadcrumbs id="assessment_system" />
        </Box>
      }
      <Typography sx={{ fontSize: '36px', fontWeight: 500, mb: '24px' }}>
        {t("sideMenu:ASSESSMENT_SYSTEM")}
      </Typography>
      {
        messageOpen &&
        <Box sx={{ mb: 2 }}>
          <Message type={messageType} message={message} setMessageOpen={setMessageOpen} />
        </Box>
      }
      <Box sx={{ display: 'flex', width: '100%', border: `1px solid ${common.borderSecondary}`, borderRadius: '9px', backgroundColor: 'white', p: '32px', minHeight: '600px' }}>
        <Formik
          initialValues={gradingSystemInitialData || { gradings: [] }}
          enableReinitialize
          validationSchema={null}
          onSubmit={(values) => {
            serviceDict.updateGradingSystem(id ? Number(id) : 0, values.gradings).then(res => {
              if (res.status === 200) {
                setMessageOpen(true);
                setMessage(t("common:messages.DATA_CHANGED_SUCCESSFULLY"));
                setMessageType("success");
                setTimeout(() => {
                  setMessageOpen(false);
                }, 3000)
              }
            }).catch((e) => {
              setMessageOpen(true);
              setMessage(t("common:messages.ERROR"));
              setMessageType("error");
              setTimeout(() => {
                setMessageOpen(false);
              }, 3000);
            }).finally(() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            })
          }}
        >
          {({ values, submitForm }) => {
            return <FieldArray
              name="gradings"
              render={() => (
                <FormikForm>
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '300px', borderRight: `1px solid ${common.borderSecondary}` }}>
                            {t('course:GRADING_SYSTEM')}
                          </TableCell>
                          <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '550px', borderRight: `1px solid ${common.borderSecondary}` }}>
                            {t('course:COURSE_TYPE')}
                          </TableCell>
                          <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '700px', borderRight: `1px solid ${common.borderSecondary}` }}>
                            {t('generalSetting:COURSE_FORMAT_FOR_FREE_COURSES')}
                          </TableCell>
                          <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '200px', borderRight: `1px solid ${common.borderSecondary}` }}>
                            {t('enum:IS_USED')}
                          </TableCell>
                          {
                            !id && <TableCell sx={{ width: '5%' }}>
                            </TableCell>
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values?.gradings.map((gradingSystem, index) => {
                          return <TableRow key={gradingSystem.id}>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Typography sx={{ color: `${common.primaryColor}` }}
                                onClick={() => {
                                  if (!id) {
                                    gradingSystem.name === t('course:CUMULATIVE_ASSESSMENT_SYSTEM')
                                      ? navigate('/assessmentSystem/CumulativeAssessmentSystem')
                                      : gradingSystem.name === '100 бальная шкала оценивания'
                                        ? navigate('/assessmentSystem/assessments')
                                        : gradingSystem.name === '100 бальная шкала оценивания (с FX)'
                                          ? navigate('/assessmentSystem/assessmentsWithFX')
                                          : navigate('/assessmentSystem/PassedNotPassedAssessmentSystem')
                                  }
                                }}
                              >
                                {gradingSystem.name}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ borderRight: `1px solid ${common.borderSecondary}` }}>
                              <FormikAutocomplete
                                sx={{ height: '100%' }}
                                name={`gradings[${index}].courseTypeCodes`}
                                multiple
                                options={courseTypes}
                                valueKey='text'
                                disabled={isInputDisabled}
                              />
                            </TableCell>
                            <TableCell sx={{ borderRight: `1px solid ${common.borderSecondary}` }}>
                              <FormikAutocomplete
                                sx={{ height: '100%' }}
                                name={`gradings[${index}].courseFormatCodes`}
                                multiple
                                options={
                                  (gradingSystem.courseTypeCodes.length === 1 && gradingSystem.courseTypeCodes.includes('EDU'))
                                    ? []
                                    : gradingSystem.name === 'Оценка по системе «Пройдено-Не пройдено»' && gradingSystem.courseTypeCodes.includes('FREE')
                                      ? courseFormats
                                      : [{ id: 1, text: 'SYNC' }]
                                }
                                valueKey='text'
                                disabled={isInputDisabled
                                  || !gradingSystem.courseTypeCodes.includes('FREE')
                                  || (gradingSystem.courseTypeCodes.includes('FREE') && gradingSystem.name !== 'Оценка по системе «Пройдено-Не пройдено»')}
                              />
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px', borderRight: `1px solid ${common.borderSecondary}` }}>
                              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <FormikSwitch name={`gradings[${index}].isActive`} />
                              </Box>
                            </TableCell>
                            {!id && <TableCell sx={{ width: '5%' }}>
                              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <DeleteOutlineIcon
                                  sx={{ color: common.errorColor }}
                                  onClick={() => handleToggleDeleteModal(true, (gradingSystem.id).toString())}
                                />
                              </Box>
                            </TableCell>
                            }
                          </TableRow>
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box sx={{
                    mt: '64px', display: 'flex', justifyContent: 'flex-end'
                  }}>
                    <CustomButton
                      width="98px"
                      height="40px"
                      backgroundColor={common.primaryColor}
                      borderRadius="8px"
                      fontColor={common.fontWhite}
                      onClick={submitForm}
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        alignItems: "center",
                        color: "#FFFFFF",
                      }}
                    >
                      {t('common:actions.SAVE')}
                    </CustomButton>
                  </Box>
                </FormikForm>
              )}
            />
          }}
        </Formik>
      </Box>
      {deleteModalOpen &&
        <DeleteModal
          open={deleteModalOpen}
          handleToggleModal={handleToggleDeleteModal}
          deleteItem={deleteEvaluationSystem}
        />
      }
    </Box >
  )
}

export default AssessmentSystem

