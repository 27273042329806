export enum ConFirmStatus {
  CONFIRMED = "CONFIRMED",
  NOT_CONFIRMED = "NOT_CONFIRMED ",
}

export enum PassingStatus {
  PASSED = "PASSED",
  NOT_PASSED = "NOT_PASSED",
}

export enum CourseCompletionStatus {
  CLOSED = "CLOSED",
  CURRENT = "CURRENT",
  PLANNED = "PLANNED",
  COMPLETED = "COMPLETED",
}

export enum CourseStatus {
  SAVED = "SAVED",
  PUBLISHED = "PUBLISHED",
  WITHDRAWN = "WITHDRAWN",
  CLOSED = "CLOSED",
}

export enum FinalGradeStatus {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}

export enum TaskProgressStatus {
  ASSIGNED = "ASSIGNED",
  NOT_ASSIGNED = "NOT_ASSIGNED",
  STARTED = "STARTED",
  UNDER_REVIEW = "UNDER_REVIEW",
  ON_CORRECTION = "ON_CORRECTION",
  NOT_COMPLETED_ON_TIME = "NOT_COMPLETED_ON_TIME",
  COMPLETED = "COMPLETED",
  PASSED = "PASSED",
  NOT_PASSED = "NOT_PASSED",
}


export enum ContentAccessExpirationStatus{
  IMMEDIATELY_AFTER_COMPLETION="IMMEDIATELY_AFTER_COMPLETION",
  ONE_WEEK_AFTER_COMPLETION="ONE_WEEK_AFTER_COMPLETION",
  ONE_MONTH_AFTER_COMPLETION="ONE_MONTH_AFTER_COMPLETION",
  THREE_MONTHS_AFTER_COMPLETION="THREE_MONTHS_AFTER_COMPLETION"
} 