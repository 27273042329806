import React, { useEffect, useState } from 'react'
import { Formik, Form as FormikForm } from 'formik';
import { AlertColor, Box, TextField, Typography, useTheme } from '@mui/material';
import CustomModal from '@components/Modal';
import FormikInput from '@ui/formik/Input';
import CustomButton from '@ui/Button';
import { useTranslation } from 'react-i18next';
import { serviceIntegration } from '@services/integrations';
import { useNavigate, useParams } from 'react-router-dom';
import { aeroValidationSchema } from './validation';

interface Props {
  openModal: boolean;
  handleToggleModal: () => void;
  integrationsInfo: any
  isReadable?: boolean
}
interface IMessage {
  open: boolean;
  text: string;
  type: AlertColor
}
const AeroIntegrationModal = ({ openModal, handleToggleModal, integrationsInfo, isReadable }: Props) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>(null)
  const { id } = useParams();
  const [message, setMessage] = useState<IMessage>({ open: false, text: '', type: 'success' })

  useEffect(() => {
    const aeroIntegration = integrationsInfo.filter((node) => node.integration ? node.integration.code === "AERO" : node.code === "AERO")[0]
    setInitialValues({
      ...aeroIntegration
    })
  }, [integrationsInfo])

  return (
    <CustomModal
      open={openModal}
      handleToggleModal={handleToggleModal}
      borderColor={common.primaryColor}
      width={515}
      showCloseIcon
    >
      <Formik
        initialValues={initialValues?.aeroInfo || {}}
        enableReinitialize
        validationSchema={aeroValidationSchema(t)}
        onSubmit={(values) => {
          const selectedIntegration = [{
            orgId: Number(id),
            isActive: false,
            integration: initialValues,
            aeroInfo: values
          }]

          serviceIntegration.updateIntegrationByOrgId(selectedIntegration).then((res) => {
            if (res.status === 200) {
              setMessage({ open: true, text: t("enum:SERVICE_ADDED"), type: 'success' })
              setTimeout(() => {
                setMessage({ ...message, open: false })
                navigate(`/dict/organization/${id}/integrations`)
              }, 3000)
            }
          }).catch((e) => {
            setMessage({ open: true, text: t("common:messages.ERROR"), type: 'error' })
            setTimeout(() => {
              setMessage({ ...message, open: false })
            }, 3000);
          })
        }}>
        {({ isValid, submitForm }) => (
          <FormikForm>
            <Typography sx={{ fontSize: "24px", fontWeight: 500, marginBottom: "20px" }}>Сервис интеграции</Typography>
            <Box sx={{ mb: '12px' }}>
              <Typography sx={{ mb: '8px' }}>
                Системы интеграции
              </Typography>
              <TextField
                disabled
                fullWidth
                size="small"
                value={"Онлайн прокторинг. Интеграция с AeroExam"}
                sx={{
                  '& .MuiInputBase-root.Mui-disabled': {
                    backgroundColor: common.inputDisabledBg
                  }
                }} />
            </Box>
            <Box sx={{ mb: '12px' }}>
              <Typography sx={{ mb: '8px' }}>
                Адрес сервера(AeroExam)
              </Typography>
              <TextField
                disabled
                fullWidth
                size="small"
                value={"https://proctoring.aeroexam.org"}
                sx={{
                  '& .MuiInputBase-root.Mui-disabled': {
                    backgroundColor: common.inputDisabledBg
                  }
                }} />
            </Box>
            <Box sx={{ mb: '12px' }}>
              <FormikInput name="orgCode" label='Идентификатор организации в AeroExam' isRequired disabled={isReadable} />
            </Box>
            <Box sx={{ mb: '12px' }}>
              <FormikInput name="secretKey" label='Секретный ключ ВУЗ’а' isRequired disabled={isReadable} />
            </Box>
            {!isReadable && <Box sx={{ display: "flex", justifyContent: "space-between", mt: '24px' }}>
              <CustomButton
                width="209px"
                height="40px"
                borderRadius="8px"
                backgroundColor="#F6F6F6"
                fontColor={common.primaryColor}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => handleToggleModal()}
              >
                {t('common:actions.CANCEL')}
              </CustomButton>
              <CustomButton
                width="209px"
                height="40px"
                borderRadius="8px"
                backgroundColor={common.primaryColor}
                fontColor="#FFFFFF"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                disabled={!isValid || isReadable}
                onClick={submitForm}
              >
                {t('common:actions.ADD')}
              </CustomButton>
            </Box>}
          </FormikForm>)}
      </Formik>
    </CustomModal>
  )
}

export default AeroIntegrationModal;
