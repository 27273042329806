import { AntiPlagiarismTaskSetting } from "@interfaces/index";
import * as yup from "yup";

export const taskValidationSchema = (
  t: any,
  antiPlagiarismTaskSetting: AntiPlagiarismTaskSetting[]
) =>
  yup.object({
    name: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    description: yup
      .string()
      .nullable()
      .when("isSelfTest", {
        is: (value: any) => value === false,
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      }),
    type: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    parentType: yup
      .string()
      .nullable()
      .when("type", {
        is: (value: string) => value === "TASK",
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      }),
    theme: yup.object().when("parentType", {
      is: (value: string) => value === "THEME",
      then: yup
        .object()
        .nullable()
        .required(t("common:validation.MANDATORY_FIELD")),
      otherwise: yup.object().nullable(),
    }),
    section: yup.object().when("parentType", {
      is: (value: string) => value === "SECTION",
      then: yup
        .object()
        .nullable()
        .required(t("common:validation.MANDATORY_FIELD")),
      otherwise: yup.object().nullable(),
    }),
    enabledAntiPlagiarism: yup.boolean(),
    antiPlagiarismTaskSetting: yup.object().shape({
      citation: yup.number().test("citation", function (value, test) {
        const { taskType } = this.parent;
        const citation = antiPlagiarismTaskSetting?.filter(
          (setting) => setting.taskType === taskType
        )[0].citation;
        if (value && citation && value < citation) {
          return this.createError({
            message: t("common:validation.VALUE_MUST_BE_MORE_THAN", {
              value: citation,
            }),
          });
        }
        return true;
      }),
      selfCitation: yup.number().test("selfCitation", function (value) {
        const { taskType } = this.parent;
        const selfCitation = antiPlagiarismTaskSetting?.filter(
          (setting) => setting.taskType === taskType
        )[0].selfCitation;
        if (value && selfCitation && value < selfCitation) {
          return this.createError({
            message: t("common:validation.VALUE_MUST_BE_MORE_THAN", {
              value: selfCitation,
            }),
          });
        }
        return true;
      }),
      originality: yup.number().test("originality", function (value) {
        const { taskType } = this.parent;
        const originality = antiPlagiarismTaskSetting?.filter(
          (setting) => setting.taskType === taskType
        )[0].originality;
        if (value && originality && value < originality) {
          return this.createError({
            message: t("common:validation.VALUE_MUST_BE_MORE_THAN", {
              value: originality,
            }),
          });
        }
        return true;
      }),
      similarity: yup.number().test("similarity", function (value) {
        const { taskType } = this.parent;
        const similarity = antiPlagiarismTaskSetting?.filter(
          (setting) => setting.taskType === taskType
        )[0].similarity;
        if (value && similarity && value < similarity) {
          return this.createError({
            message: t("common:validation.VALUE_MUST_BE_MORE_THAN", {
              value: similarity,
            }),
          });
        }
        return true;
      }),
      symbolReplacementMaxCount: yup
        .number()
        .test("symbolReplacementMaxCount", function (value) {
          const { taskType } = this.parent;
          const symbolReplacementMaxCount = antiPlagiarismTaskSetting?.filter(
            (setting) => setting.taskType === taskType
          )[0].symbolReplacementMaxCount;
          if (
            value &&
            symbolReplacementMaxCount &&
            value < symbolReplacementMaxCount
          ) {
            return this.createError({
              message: t("common:validation.VALUE_MUST_BE_MORE_THAN", {
                value: symbolReplacementMaxCount,
              }),
            });
          }
          return true;
        }),
    }).when("enabledAntiPlagiarism", {
      is: true,
      then: (schema) => schema,
      otherwise: (schema) => schema.nullable()
    }),

    // isNoMark: yup
    //   .boolean()
    //   .test(
    //     "isNoMark",
    //     t("common:validation.MANDATORY_FIELD"),
    //     function (isNoMark) {
    //       const isExcludeTotalMark = this.parent.isExcludeTotalMark;
    //       const courseStatus = this.parent.courseStatus;
    //       const taskFormat = this.parent.format;
    //       return !(
    //         !isNoMark &&
    //         !isExcludeTotalMark &&
    //         courseStatus === "PUBLISHED" &&
    //         taskFormat !== "QUIZ"
    //       );
    //     }
    //   ),
    // isExcludeTotalMark: yup
    //   .boolean()
    //   .test(
    //     "isExcludeTotalMark",
    //     t("common:validation.MANDATORY_FIELD"),
    //     function (isExcludeTotalMark) {
    //       const isNoMark = this.parent.isNoMark;
    //       const courseStatus = this.parent.courseStatus;
    //       const taskFormat = this.parent.format;
    //       return !(
    //         !isExcludeTotalMark &&
    //         !isNoMark &&
    //         courseStatus === "PUBLISHED" &&
    //         taskFormat !== "QUIZ"
    //       );
    //     }
    //   ),
    point: yup
      .number()
      .nullable()
      .typeError(t("common:validation.FIELD_MUST_BE_NUMERIC"))
      .min(0, t("common:validation.SCORE_CAN_NOT_BE_LESS_THAN_0"))
      .max(100, t("common:validation.SCORE_CAN_NOT_BE_MORE_THAN_100"))
      .transform((_, val) => (val ? Number(val) : null)),
    quiz: yup
      .object()
      .nullable()
      .shape({
        duration: yup
          .number()
          .min(1, t("common:validation.VALUE_MUST_BE_MORE_THAN", { value: 0 }))
          .integer(t("common:validation.INTEGER_ONLY"))
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
        showNumberOfQuestions: yup.boolean(),
        numberOfQuestions: yup
          .number()
          .nullable()
          .when("showNumberOfQuestions", {
            is: true,
            then: yup
              .number()
              .nullable()
              .required(t("common:validation.MANDATORY_FIELD")),
          }),
      }),
    numberOfQuestions: yup
      .number()
      .nullable()
      .when("showNumberOfQuestions", {
        is: true,
        then: yup.number().required(t("common:validation.MANDATORY_FIELD")),
      }),
    hasAnswerTypeFileFormats: yup.boolean(),
    answerTypeFileFormats: yup.array().when("hasAnswerTypeFileFormats", {
      is: true,
      then: yup.array().min(1, t("common:validation.MANDATORY_FIELD")),
      // otherwise: yup.array(),
    }),
    answerType: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    videoDuration: yup
      .number()
      .nullable()
      .when("answerType", {
        is: "VIDEO",
        then: yup
          .number()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      }),
    videoSum: yup
      .number()
      .nullable()
      .when("answerType", {
        is: "VIDEO",
        then: yup
          .number()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      }),
  });

export const taskGroupValidationSchema = (t: any) =>
  yup.object({
    gradingSystemId: yup.number().nullable(),
    name: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    percentage: yup
      .number()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD"))
      .positive()
      .moreThan(0, t("common:validation.VALUE_MUST_BE_MORE_THAN", { value: 0 }))
      .lessThan(
        100.01,
        t("common:validation.VALUE_MUST_BE_LESS_THAN", { value: 100 })
      )
      .test(
        "decimal-places",
        t("common:validation.TWO_SYMBOLS_AFTER_COMMA"),
        (value) => {
          if (value) return /^[0-9]*(\.[0-9]{1,2})?$/.test(value.toString());
          return true;
        }
      ),
    taskType: yup.string().when("gradingSystemId", {
      is: (value: number) => value === 3,
      then: yup
        .string()
        .nullable()
        .required(t("common:validation.MANDATORY_FIELD")),
      otherwise: yup.string().nullable(),
    }),
  });

export const allRatioValidationSchema = (t: any) =>
  yup.object({
    groups: yup.array().of(
      yup.object().shape({
        name: yup.string().nullable(),
        percentage: yup
          .number()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD"))
          .positive()
          .moreThan(
            0,
            t("common:validation.VALUE_MUST_BE_MORE_THAN", { value: 0 })
          )
          .lessThan(
            100.01,
            t("common:validation.VALUE_MUST_BE_LESS_THAN", { value: 100 })
          )
          .test(
            "decimal-places",
            t("common:validation.TWO_SYMBOLS_AFTER_COMMA"),
            (value) => {
              if (value)
                return /^[0-9]*(\.[0-9]{1,2})?$/.test(value.toString());
              return true;
            }
          ),
      })
    ),
  });
