import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import Countdown from 'react-countdown';

import CustomButton from '@ui/Button';
import CompleteTestModal from './CompleteTestModal';
import LoadingSpinner from '@components/LoadingSpinner';

export const timeStyle = {
  width: '65px',
  height: '65px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFFFFF',
  fontSize: '36px',
  lineHeight: '24px'
};

const Timer = ({
  completeQuizModalOpen,
  toggleCompleteQuizModal,
  completeQuiz,
  timeLeft, // in seconds
  warningIsUp
}: any) => {
  const { t } = useTranslation(['common', 'course']);
  const { common } = useTheme().palette;

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      completeQuiz();
      return <Typography>{t('course:test.TEST_COMPLETED')}</Typography>;
    } else {
      // Render a countdown
      return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={warningIsUp ? { ...timeStyle, backgroundColor: common.errorColor } : { ...timeStyle, backgroundColor: common.primaryColor }}>{minutes < 10 ? "0" + minutes : minutes}</Box>
          <Typography sx={{ color: '#E6E1E5', fontSize: '36px', lineHeight: '24px', mx: '10px' }}>:</Typography>
          <Box sx={warningIsUp ? { ...timeStyle, backgroundColor: common.errorColor } : { ...timeStyle, backgroundColor: "#49454F" }}>{seconds < 10 ? "0" + seconds : seconds}</Box>
        </Box >
      );
    }
  };
  return (
    <Box sx={{ width: '15%', display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box
        sx={{
          borderRadius: '12px',
          backgroundColor: common.white,
          borderTop: `solid 12px ${common.secondaryColor}`,
          py: 1
        }}
      >
        <Box sx={{ display: 'flex', ml: '18px', justifyContent: 'center', fontWeight: 500, fontSize: '18px', lineHeight: "24px", alignItems: 'center' }}>
          <TimerOutlinedIcon sx={{ mr: '10px' }} />
          <Typography sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '24px' }}>{t('course:test.TIME_LEFT')}</Typography>
        </Box>
        <Box sx={{ mx: '48px', display: 'flex', py: '26px', justifyContent: 'center', alignItems: 'center' }}>
          {timeLeft ?
            <Countdown
              date={Date.now() + (timeLeft * 1000)}
              renderer={renderer}
            // onStart={() => { console.log('start') }}
            // onPause={() => { console.log('pause') }}
            /> :
            <LoadingSpinner />
          }
        </Box>
      </Box>
      <CustomButton backgroundColor={common.primaryColor} borderRadius="6px" onClick={() => toggleCompleteQuizModal(true)}>
        <Typography sx={{ color: 'white', lineHeight: '130%', fontWeight: 500 }}>{t('course:test.COMPLETE_TESTING')}</Typography>
      </CustomButton>
      {completeQuizModalOpen &&
        <CompleteTestModal
          modalOpen={completeQuizModalOpen}
          toggleCompleteQuizModal={toggleCompleteQuizModal}
          completeQuiz={completeQuiz}
        />
      }
    </Box >
  )
}

export default Timer;