/* tslint:disable */
/* eslint-disable */
/**
 * course service
 * <b>use example:</b> http://{host}:4044/course/{this service rest endpoints}
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CourseMember } from './course-member';
import { CourseSetting } from './course-setting';
import { TaskGroup } from './task-group';
/**
 * 
 * @export
 * @interface Course
 */
export interface Course {
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    typeId?: number;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    formatType?: CourseFormatTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    credits?: number;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    promoVideoUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    countRegWithPromoCode?: number;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    status?: CourseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    authorId?: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    authorInfo?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Course
     */
    availableSections?: Array<CourseAvailableSectionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    tutorInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    coverFileId?: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    coverColor?: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Course
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Course
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Array<CourseMember>}
     * @memberof Course
     */
    courseMembers?: Array<CourseMember>;
    /**
     * 
     * @type {Array<TaskGroup>}
     * @memberof Course
     */
    groups?: Array<TaskGroup>;
    /**
     * 
     * @type {CourseSetting}
     * @memberof Course
     */
    courseSetting?: CourseSetting;
    /**
     * Внешний идентификатор
     * @type {string}
     * @memberof Course
     */
    externalId?: string;
    /**
     * Количество всех слушателей
     * @type {number}
     * @memberof Course
     */
    allStudentCount?: number;
    /**
     * Количество активных слушателей
     * @type {number}
     * @memberof Course
     */
    activeStudentCount?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CourseFormatTypeEnum {
    ASYNC = 'ASYNC',
    SYNC = 'SYNC'
}
/**
    * @export
    * @enum {string}
    */
export enum CourseStatusEnum {
    SAVED = 'SAVED',
    PUBLISHED = 'PUBLISHED',
    WITHDRAWN = 'WITHDRAWN',
    COMPLETED = 'COMPLETED'
}
/**
    * @export
    * @enum {string}
    */
export enum CourseAvailableSectionsEnum {
    DESCRIPTION = 'DESCRIPTION',
    SETTING = 'SETTING',
    MEMBER = 'MEMBER',
    SECTION = 'SECTION',
    THEME = 'THEME',
    TASK = 'TASK',
    JOURNAL = 'JOURNAL',
    DISCUSSION = 'DISCUSSION',
    CONFERENCE = 'CONFERENCE',
    ADDITIONAL = 'ADDITIONAL',
    ADS = 'ADS'
}

