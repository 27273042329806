// import { CourseRole } from '@interfaces/index';
import * as yup from 'yup';

export const validationSchema = (t) => yup.object({
  platform: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  // roleType: yup.string().nullable(),
  coordinatorMemberId: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  title: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  link: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  startTime: yup
    .string()
    .nullable()
    .test('start-time', t('common:validation.INCORRECT_START_AND_END_TIMES'), function (startTime) {
      const { endTime } = this.parent;
      if (!startTime || !endTime) {
        return true;
      }
      return endTime !== null && startTime <= endTime;
    })
    .required(t('common:validation.MANDATORY_FIELD')),
  endTime: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  day: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  isReletedTopic: yup.boolean(),
  themeId: yup.string().when("isReletedTopic", {
    is: true,
    then: yup.string().required(t('common:validation.MANDATORY_FIELD'))
  }),
  // memberList: yup.string().nullable().when("roleType", {
  //   is: (val: CourseRole) => Object.values(CourseRole).includes(val),
  //   then: yup.string().required('Обязательное поле').nullable(),
  // })
});