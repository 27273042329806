import { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik, Form as FormikForm } from "formik";

import { serviceCourse } from '@services/course';
import { StateSchema } from "@store/StateSchema";
import FormikInput from '@ui/formik/Input';
import { TextEditor } from '@ui/formik/TextEditor';
import CustomButton from '@ui/Button';
import { specifyManuallyAuthorValidation } from './validation';
import { useQueryClient } from '@tanstack/react-query';

export const SpecifyManuallyAuthor = ({ courseInfo, authorInfo, handleToggleModal }) => {
  const { common } = useTheme().palette;
  const [enableBtnUserInfo, setEnableBtnUserInfo] = useState<boolean>(true);
  const { t } = useTranslation(["user", "common", "course", "enum", "dictionary"]);
  const queryClient = useQueryClient();
  return (
    <>
      <Formik
        initialValues={{ username: authorInfo?.username || null, userInfo: authorInfo?.userInfo || null }}
        enableReinitialize
        validationSchema={specifyManuallyAuthorValidation(t)}
        onSubmit={(values) => {
          const reqBody = {
            courseId: courseInfo.id,
            username: values?.username,
            userInfo: values?.userInfo,
            status: "NOT_CONFIRMED",
          }
          if (authorInfo) {
            serviceCourse.updateAuthorInfo({ ...authorInfo, ...reqBody }).then((res) => {
              if (res.status === 200) {
                queryClient.invalidateQueries({ queryKey: ['courseAuthorList'] })
                handleToggleModal(false, t('common:messages.DATA_CHANGED_SUCCESSFULLY'))
              }
            })
          } else {
            serviceCourse.creatAuthor(reqBody).then((res) => {
              if (res.status === 200) {
                queryClient.invalidateQueries({ queryKey: ['courseAuthorList'] })
                handleToggleModal(false, t('common:messages.DATA_CHANGED_SUCCESSFULLY'))
              }
            })
          }
        }}
      >
        {({ submitForm, dirty, isValid }) => {
          return (
            <FormikForm>
              <FormikInput
                name="username"
                isRequired
                label={t("course:AUTHOR_COURSE")}
                placeholder={t("common:placeholders.ENTER_USER_FULL_NAME")}
              />
              <Box sx={{ marginTop: "12px" }} />
              <TextEditor
                name="userInfo"
                isRequired
                label={t("course:INFORMATION_ABOUT_COURSE_AUTHOR")}
                shortToolbar
                showScrollEditor
                showSymbolCount
                minChar={64}
                maxChar={1000}
                setIsValidSubmitBtn={setEnableBtnUserInfo}
              />
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  marginTop: "16px"
                }}
              >
                <CustomButton
                  backgroundColor={common.btnCancel}
                  fontColor={common.primaryColor}
                  borderRadius="6px"
                  height="40px"
                  sx={{
                    alignItems: "center",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={() => handleToggleModal(false)}
                >
                  {t('common:actions.CANCEL')}
                </CustomButton>
                <CustomButton
                  backgroundColor={common.primaryColor}
                  fontColor={common.fontWhite}
                  borderRadius="6px"
                  height="40px"
                  disabled={!(isValid && dirty && enableBtnUserInfo)}
                  onClick={submitForm}
                  sx={{
                    alignItems: "center",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {t('common:actions.SAVE')}
                </CustomButton>
              </Box>
            </FormikForm>
          )
        }}
      </Formik>
    </>
  )
}
