import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { serviceTask } from '@services/task';
import { IMaterial } from '@interfaces/index';
import { downloadFile } from '@utils/functions';
import FileViewerModal from '@components/modals/FileViewerModal';

const MaterialList = () => {
  const { t } = useTranslation(['course']);
  const { common } = useTheme().palette;
  const { taskId } = useParams();
  const [materialList, setMaterialList] = useState<Array<IMaterial>>([]);
  const [material, setMaterial] = useState<IMaterial>();
  const [openFileModal, setOpenFileModal] = useState<boolean>(false);
  
  
  const handleToggleFileModal = (modalState: boolean, material?: IMaterial) => {
    if (material) setMaterial(material);
    setOpenFileModal(modalState);
  }

  useEffect(() => {
    if (taskId) {
      serviceTask.getTaskMaterial(taskId).then(res => {
        setMaterialList(res.data);
      })
    }
  }, [taskId]);

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '256px' },
        height: 'auto',
        borderRadius: '12px',
        borderTop: 'solid 12px #FD0053',
        backgroundColor: common.white,
        ml: { xs: 0, md: '26px' },
        py: 1
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, mx: 2 }}>
        <FolderOutlinedIcon />
        <Typography sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '24px' }}>{t('course:ADDITIONAL_MATERIALS')}</Typography>
      </Box>
      <Box sx={{ mx: 2, display: 'flex', flexDirection: 'column', py: 1, gap: 2 }}>
        {materialList.length === 0 &&
          <Box
            sx={{ color: '#9C9C9C', display: 'flex', margin: 'auto' }}
          >
            <Typography>{t('course:NO_ADDITIONAL_MATERIALS')}</Typography>
          </Box>
        }
        {materialList.map((material, index) => (
          <Box
            key={index}
            sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Typography onClick={() => handleToggleFileModal(true, material)} sx={{ wordBreak: 'break-all', cursor: 'pointer'}}>
              <AttachFileIcon sx={{ mr: '8px', width: '15px', height: '15px' }} />{material.name}
            </Typography>
            {material.downloadable &&
              <FileDownloadOutlinedIcon
                sx={{ display: 'flex', ml: 'auto', cursor: 'pointer' }}
                onClick={() => downloadFile(material.fileId)}
              />
            }
          </Box>
        ))}
      </Box>
      {openFileModal &&
        <FileViewerModal
          openModal={openFileModal}
          handleToggleModal={handleToggleFileModal}
          file={material}
        />
      }
    </Box>
  )
}

export default MaterialList