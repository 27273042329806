import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { IBreadcrumb } from '@interfaces/index';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Breadcrumbs = ({ id }) => {
  const { t } = useTranslation();
  const { getBreadcrumbs } = useBreadcrumbs();
  const breadcrumbs = getBreadcrumbs(id);

  const renderBreadcrumbs = () => {
    return breadcrumbs.map((crumb: IBreadcrumb, index: number) => {
      // If it's the last crumb, render it as plain text without a link
      if (index === breadcrumbs.length - 1) {
        return <span style={{ fontWeight: 600 }} key={crumb.level}>{t(`${crumb.label}`)}</span>;
      }

      // Render a link for the crumb
      return (
        <span key={crumb.level}>
          <Link to={crumb.path || '/'} style={{ textDecoration: "none", color: '#000000' }}>
            {t(`${crumb.label}`)}
          </Link>
          <ArrowForwardIosIcon sx={{ width: 10, height: 10, mx: 1 }} />
        </span>
      );
    });
  };

  return <div className="breadcrumbs">{renderBreadcrumbs()}</div>;
};

export default Breadcrumbs;