import AvatarPicture from '@components/AvatarPicture'
import { ICourse } from '@interfaces/index';
import { Box } from '@mui/material';
import { serviceCourse } from '@services/course';
import React, { useEffect, useState } from 'react'

export const CoursePicture = ({ courseId }) => {
  const [courseInfo, setCourseInfo] = useState<ICourse>();
  useEffect(() => {
    if (courseId) {
      serviceCourse.getCourseInfo(courseId).then((res) => {
        if (res.status === 200) {
          setCourseInfo(res.data)
        }
      })
    }
  }, [courseId])
  return (
    <>
      {courseInfo?.coverFileId ? <AvatarPicture variant='circular' id={courseInfo?.coverFileId || ""} sxProps={{ width: "60px", height: "45px" }} />
        : <Box sx={{ background: courseInfo?.coverColor, width: "60px", height: "45px", borderRadius: "6px" }}> </Box>}
    </>
  )
}
