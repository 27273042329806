import React, { useEffect, useState } from 'react'
import CustomModal from '@components/Modal'
import { Formik, Form as FormikForm } from 'formik';
import { useTheme, AlertColor, Box, Divider, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigation } from 'react-router-dom';
import FormikInput from '@ui/formik/Input';
import CustomButton from '@ui/Button';
import { serviceIntegration } from '@services/integrations';
import { antiPlagiarismValidationSchema } from './validation';
import Message from '@components/Message';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IntagrationCode } from '@interfaces/index';

interface Props {
  openModal: boolean;
  handleToggleModal: () => void;
  integrationsInfo: any
  handleMessageSuccess: any;
}
interface IMessage {
  open: boolean;
  text: string;
  type: AlertColor
}

export const AntiPlagiarismIntegrationModal = ({ openModal, handleToggleModal, handleMessageSuccess }: Props) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(['common', 'antiPlagiarism']);
  const navigate = useNavigation();
  const [initialValues, setInitialValues] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [showPassword, setShowPassword] = useState<boolean>(false)

  useEffect(() => {
    serviceIntegration
      .getAntiPlagiarismSetting(IntagrationCode.ANTI_PLAGIARISM)
      .then((res) => {
        if (res.status === 200) {
          setInitialValues(res.data)
        }
      })
  }, [])

  return (
    <CustomModal
      open={openModal}
      handleToggleModal={handleToggleModal}
      width={515}
      px={3}
      py={3}
      showCloseIcon
    >
      <Formik
        initialValues={initialValues || {}}
        enableReinitialize
        validationSchema={antiPlagiarismValidationSchema(t)}
        onSubmit={(values) => {
          serviceIntegration.saveAntiPlagiarismSetting({ ...values, integrationCode: IntagrationCode.ANTI_PLAGIARISM }).then((res) => {
            setIsLoading(true)
            if (res.status === 200) {
              setIsLoading(false)
              handleMessageSuccess()
              handleToggleModal()
            }
          }).catch((error: any) => {
            setIsLoading(false)
            setMessage(t(`common:messages.${error?.response?.data?.code}`));
            setMessageOpen(true);
            setMessageType("error");
            setTimeout(() => {
              setMessageOpen(false);
            }, 3000);
          })
        }}>
        {({ isValid, submitForm, dirty }) => {
          return (
            <FormikForm>
              <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>{t("antiPlagiarism:INTEGRATION_ANTI_PLAGIARISM")}</Typography>
              <Divider sx={{ margin: "16px 0px" }} />

              {messageOpen && (
                <Box mb="20px">
                  <Message
                    type={messageType}
                    message={message}
                    setMessageOpen={messageOpen}
                  />
                </Box>
              )}
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
                <FormikInput name="apiUrl" label={t("antiPlagiarism:WEDSITE_COMPANY_ANTIPLAGIARISM")} isRequired />
                <FormikInput name="email" label={t("antiPlagiarism:API_USER_EMAIL")} isRequired />
                <FormikInput name="password" label={t("antiPlagiarism:API_USER_PASSWORD")} isRequired
                  type={showPassword ? 'text' : 'password'}
                  adornmentIcon={<Box onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </Box>} />
                <FormikInput name="reportUrl" label={t("antiPlagiarism:API_ACCESS_ADDRESS")} isRequired />

              </Box>
              <Divider sx={{ margin: "24px 0px" }} />
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: '24px' }} gap={1.5}>
                <CustomButton
                  width="92px"
                  height="40px"
                  borderRadius="8px"
                  backgroundColor="#F6F6F6"
                  fontColor={common.primaryColor}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={() => handleToggleModal()}
                >
                  {t('common:actions.CANCEL')}
                </CustomButton>
                <CustomButton
                  width="146px"
                  height="40px"
                  borderRadius="8px"
                  variant={isLoading ? 'outlined' : "contained"}
                  backgroundColor={common.primaryColor}
                  fontColor="#FFFFFF"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  disabled={!(isValid && dirty)}
                  onClick={submitForm}
                >{isLoading ? <CircularProgress
                  sx={{ color: common.white }}
                  size={24}
                />
                  : t('common:actions.SAVE')}
                </CustomButton>
              </Box>
            </FormikForm>)
        }}
      </Formik>
    </CustomModal >
  )
}
