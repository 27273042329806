import { useState } from "react";
import { t } from "i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import CustomModal from "@components/Modal";
import CustomButton from "@ui/Button";

interface Props {
  onConfirm: () => void;
  modalTitle: string;
  confirmText?: string;
  cancelText?: string;
}

const useConfirmModal = ({
  onConfirm,
  modalTitle,
  confirmText = t('common:YES'),
  cancelText = t('common:NO'),
}: Props) => {
  const { common } = useTheme().palette;
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenModal = () => setIsOpen(x => !x)

  const ConfirmModal = (
    <CustomModal
      open={isOpen}
      handleToggleModal={handleOpenModal}
      width={515}
    >
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            id="transition-modal-title"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              mx: "40px",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "26px",
              mb: "32px",
            }}
          >
            {modalTitle}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <CustomButton
            onClick={handleOpenModal}
            width="209px"
            height="40px"
            borderRadius="8px"
            backgroundColor={common.btnCancel}
            fontColor={common.primaryColor}
            sx={{
              display: "flex",
              alignItems: "center",
              fontStyle: "normal",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {cancelText}
          </CustomButton>
          <CustomButton
            onClick={onConfirm}
            width="209px"
            height="40px"
            borderRadius="8px"
            backgroundColor="#FF0000"
            fontColor={common.fontWhite}
            sx={{
              display: "flex",
              alignItems: "center",
              fontStyle: "normal",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {confirmText}
          </CustomButton>
        </Box>
      </>
    </CustomModal>
  )

  return { ConfirmModal, handleOpenModal }
}

export default useConfirmModal;