import { ReactElement, FC, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PersonalProfile from "./PersonalProfile";
import { TabContext, TabPanel } from "@mui/lab";
import { AlertColor, Box, Tab, Tabs, useTheme } from "@mui/material";
import PublicProfile from "./PublicProfile";
import SettingProfile from "./SettingProfile";
import { useSearchParams } from "react-router-dom";
import Message from "@components/Message";
import { Role } from "@interfaces/index";

const Profile: FC<any> = (): ReactElement => {
  const { t } = useTranslation(["profile"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { common } = useTheme().palette;
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role } = profile.authorityInfo;
  const currentTab = searchParams.get('type');
  const [typeProfile, setTypeProfile] = useState("personal");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('')
  const [messageType, setMessageType] = useState<AlertColor>('success')
  const [migrated, setMigrated] = useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, type: string) => {
    setTypeProfile(type);
    setSearchParams({ type: type });
  };

  useEffect(() => {
    if (currentTab) {
      setTypeProfile(currentTab);
    } else {
      setTypeProfile("personal");
    }
  }, [currentTab]);

  return (
    <Box sx={{ maxWidth: '1880px' }}>
      <Box sx={{
        mb: 2
      }}>
        {messageOpen &&
          <Message
            type={messageType}
            message={message}
            setMessageOpen={setMessageOpen}
          />
        }
      </Box>
      {role !== Role.SUPER_ADMIN &&
        <Box
          sx={{
            display: "block",
            width: "100%",
            background: common.fontWhite,
            border: `1px solid ${common.borderPrimary}`,
            borderRadius: 3,
          }}
        >
          {role === Role.EDUCATOR ?
            <TabContext value={typeProfile}>
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    padding: "10px 0 0 10px",
                  }}
                >
                  <Tabs
                    onChange={handleChange}
                    indicatorColor="secondary"
                    value={typeProfile}
                    sx={{
                      "& .MuiTab-root.Mui-selected": {
                        color: "#1A141F",
                      },
                      "& .MuiTabs-indicator": {
                        background: common.borderPrimary,
                      },
                    }}
                  >
                    <Tab
                      label={t("PERSONAL_INFO")}
                      value={"personal"}
                      sx={{ textTransform: "none", fontSize: "18px" }}
                    />
                    <Tab
                      label={t("PUBLIC_INFO")}
                      value={"public"}
                      sx={{ textTransform: "none", fontSize: "18px" }}
                    />
                  </Tabs>
                </Box>
              </Box>
              <TabPanel value={typeProfile}>
                {typeProfile === "personal" &&
                  <PersonalProfile
                    setMessageOpen={setMessageOpen}
                    setMessage={setMessage}
                    setMessageType={setMessageType}
                    setMigrated={setMigrated}
                    migrated={migrated}
                  />
                }
                {typeProfile === "public" &&
                  <PublicProfile
                    setMessageOpen={setMessageOpen}
                    setMessage={setMessage}
                    setMessageType={setMessageType} />
                }
              </TabPanel>
            </TabContext> :
            <Box sx={{ padding: 3 }}>
              <PersonalProfile
                setMessageOpen={setMessageOpen}
                setMessage={setMessage}
                setMessageType={setMessageType}
                setMigrated={setMigrated}
                migrated={migrated} />
            </Box>
          }
        </Box>
      }
      {typeProfile === "personal" && <SettingProfile migrated={migrated} />}
    </Box>
  );
};

export default Profile;
