import { useState, useEffect } from 'react';
import { get } from 'lodash';

import { serviceUser } from '@services/user';

interface Props {
  id: string;
}

const UserEmail = ({ id }: Props) => {
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    async function formatOrganization() {
      const response = await serviceUser.getUserInfoById(id);
      const userEmail = await get(response.data, `email`);
      setUserEmail(userEmail);
    }

    formatOrganization();
  }, [id]);

  return (
    <>{userEmail}</>
  );
}

export default UserEmail;