import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { serviceCourse } from '@services/course';
import { serviceTask } from '@services/task';
import { ITaskGroup } from '@interfaces/index';
import Settings from '../Content/Task/Settings';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTheme } from '@mui/material/styles';

const TaskParameters = () => {
  const { courseId } = useParams();
  const { t } = useTranslation(["course"]);
  const [sectionOptions, setSectionOptions] = useState<Array<any>>([]);
  const [themeOptions, setThemeOptions] = useState<Array<any>>([]);
  const [taskGroupOptions, setTaskGroupOptions] = useState<Array<ITaskGroup>>([]);
  const [gradingSystemId, setGradingSystemId] = useState<number | null>(null);
  const [taskName, setTaskName] = useState<string>("");
  const navigate = useNavigate();
  const { common } = useTheme().palette;

  useEffect(() => {
    if (courseId) {
      serviceCourse.getSectionList(courseId)
        .then(res => {
          if (res.status === 200) {
            setSectionOptions(res.data);
          }
        });

      serviceCourse.getThemeList(courseId)
        .then(res => {
          if (res.status === 200) {
            setThemeOptions(res.data);
          }
        });

      serviceTask.getTaskGroupList(courseId)
        .then(res => {
          if (res.status === 200) {
            setTaskGroupOptions(res.data);
          }
        })

      serviceCourse.getGradingSystemId(courseId)
        .then(res => {
          if (res.status === 200) {
            setGradingSystemId(res.data);
          }
        })
    }
  }, [courseId]);

  return (
    <Box sx={{ maxWidth: '1880px' }}>
      <Box
        sx={{
          p: 2,
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          border: `1px solid ${common.borderSecondary}`,
          borderRadius: '12px'
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosIcon sx={{ width: 14, height: 14 }} />
          <Typography>
            {t("common:actions.GO_BACK")}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "36px",
            lineHeight: "44px",
            mt: 3
          }}
        >
          {taskName}
        </Typography>
        <Settings
          sectionOptions={sectionOptions}
          themeOptions={themeOptions}
          taskGroupOptions={taskGroupOptions}
          gradingSystemId={gradingSystemId}
          setTaskName={setTaskName}
          isReadOnly={true}
        />
      </Box>
    </Box>
  )
}

export default TaskParameters;