import { serviceNotification } from '@services/notification';
import * as types from './types';
import { notificationActions } from './reducers';

export const getUnreadNotificationsCount = () => (dispatch: any) => {
  // dispatch({ type: types.notification('NOTIFICATION_COUNT').PENDING });

  return serviceNotification
    .getListUserId({ size: 10000, page: 0 })
    .then((res) => {
      const unreadNotificationsCount = res.data.dtoList.filter((item: any) => item.status === 'UNREAD').length;
      dispatch(
      //   {
      //   type: 'NOTIFICATION_COUNT',
      //   payload: unreadNotificationsCount
      // }
      notificationActions.setNotificationCount(unreadNotificationsCount));
    })
    .catch(() => {
      dispatch(
      //   {
      //   type: 'NOTIFICATION_COUNT',
      //   payload: 0
      // }
      notificationActions.setNotificationCount(0))
      
    });
};