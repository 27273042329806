import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

import { UsersTypeCode } from "@interfaces/index";
// import { ConfirmationInvitation } from "./ConfirmationInvitation";
// import CreateCourseModal from "./CreateCourseModal";
import InternalUserList from "./InternalUsersList";
import { TabListUsers } from "@components/common/TabListUsers/TabListUsers";
import ExternalUsersList from "./ExternalUsersList";

export interface CT_ValueState {
  edu: string | null;
  free: string | null;
}

const UsersList = () => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course", "enum"]);

  const [usersType, setUsersType] = useState<string>("");

  return (
    <Box sx={{ maxWidth: "1880px" }}>
      
      <Typography sx={{ fontWeight: "500", fontSize: "36px", lineHeight: "44px", mb: "27px", mt: '20px' }}>
        {t('sideMenu:USERS')}
      </Typography>
      <TabContext value={usersType}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: '20px' }}>
        <TabListUsers 
            onChange={(_, value: string) => setUsersType(value)}
            setUsersType={setUsersType}
          />
      </Box>
        {
          usersType === UsersTypeCode.INTERNAL
          ?
          <InternalUserList/>
          :
          <ExternalUsersList/>
        }
      </TabContext>
    </Box>
  );
};

export default memo(UsersList);