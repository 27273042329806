import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';

import MathJaxComponent from '@components/MathJaxComponent';
import CustomModal from '@components/Modal';

interface FormulaModalProps {
  open: boolean;
  handleToggleModal: () => void;
}

const PassedNotPassedGZModal = ({ open, handleToggleModal }: FormulaModalProps) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course"]);
  const GZFormula = "`Д_{З} = (O_{3} * П_{%})/100`";
  return (
    <CustomModal
      open={open}
      handleToggleModal={handleToggleModal}
      width={515}
      borderColor={common.primaryColor}
    >
      <>
        <Typography>
          {t("course:listener.TOTAL_SCORE_GROUP_TASKS")}:
        </Typography>
        <Box sx={{ background: 'rgba(105, 123, 227, 0.2)', color: "black", border: '1px solid #697BE3', borderRadius: '8px', margin: "0 auto" }}>
          {<MathJaxComponent formula={GZFormula} />}
        </Box>
        <Typography>{t("course:listener.WHERE")}:</Typography>
        <Typography><MathJaxComponent inline formula={"`Д_{З}`"} /> – Количество заданий, достаточное для успешного прохождения курса;</Typography>
        <Typography><MathJaxComponent inline formula={"`O_{З}`"} /> – Общее количество заданий в курсе;</Typography>
        <Typography><MathJaxComponent inline formula={"`П_{%}`"} /> – Значение опции «Процент пройденных заданий для успешного завершения курса»;</Typography>
      </>
    </CustomModal>
  )
}

export default PassedNotPassedGZModal
