import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, AlertColor, Box, Snackbar, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import DoNotDisturbOutlinedIcon from '@mui/icons-material/DoNotDisturbOutlined';
import SportsScoreOutlinedIcon from '@mui/icons-material/SportsScoreOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';

import { serviceCourse } from '@services/course';
import useDeleteConfirmModal from '@hooks/useDeleteConfirmModal';
import PublishCourseModal from '@components/modals/PublishCourseModal';
import CloseCourseModal from '@components/modals/CloseCourseModal';
import { CourseFormatType, CourseStatus, ICourse, Role, TypeCode } from '@interfaces/index';
import YesOrNoModal from '@components/modals/YesOrNoModal';
import { useCourseContext } from '@context/CourseContext';
import { useQueryClient } from '@tanstack/react-query';
import { CourseResultDto } from '@interfaces/course';
interface Props {
  courseInfo?: ICourse;
  unpublishCourseIsOpen?: boolean;
  closeCourseIsOpen?: boolean;
  setMainMessage?: any;
  setMainMessageOpen?: any;
  setMainMessageType?: any;
}
export const ActionCourse = ({ courseInfo, unpublishCourseIsOpen, closeCourseIsOpen, setMainMessage, setMainMessageOpen, setMainMessageType }: Props) => {
  const { common } = useTheme().palette;
  const { courseId, classId } = useParams();
  const navigate = useNavigate();
  const [modalMessage, setModalMessage] = useState<string>("");
  const [typeAction, setTypeAction] = useState<string>("");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(1460));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { t } = useTranslation(["common", "course"]);
  const [openPublishCourseModal, setOpenPublishCourseModal] = useState(false);
  const [triggerMessage, setTriggerMessage] = useState<string>()
  const [message, setMessage] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [attention, setAttention] = useState<boolean>(false);
  const [closeCourseModalIsOpen, setCloseCourseModal] = useState<boolean>(false);
  const [yesOrNoModalIsOpen, setYesOrNoModal] = useState<boolean>(false);
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role } = profile.authorityInfo;
  const { courseData } = useCourseContext();
  const queryClient = useQueryClient();
  const courseResultDto = queryClient.getQueryData(["getGradingSystemById", classId ? classId : ""]) as CourseResultDto | undefined;
  const handleUnpublishCourse = async () => {
    try {
      const res = await serviceCourse.changeCourseStatus(courseId || "", CourseStatus.WITHDRAWN);
      if (res.status === 200) {
        setMessage(t('course:messages.PUBLICATION_CANCELED'));
        setAttention(false);
        setSuccess(true);
        setTriggerMessage("SUCCESS");
        handleTogglePublishCourseModal(true);
      }
    } catch (error) {
      setMainMessage(t(`course:messages.STUDY_CLASS_ALREADY_STARTED`));
      setMainMessageOpen(true);
      setMainMessageType("error");
      setTimeout(() => {
        setMainMessageOpen(false);
      }, 3000);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const handleCompleteCourseByListener = async () => {
    const res = await serviceCourse.completeCourseByListener(classId || '');
    if (res.status === 200) {
      handleTogglePublishCourseModal(true);
      setMessage(t('course:COURSE_COMPLETED'));
      navigate(`/myCourses/completed/${classId}`)
    }
  }

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: typeAction === "unpublish" ?
      handleUnpublishCourse :
      handleCompleteCourseByListener,
    modalTitle: modalMessage,
  })

  const handleTogglePublishCourseModal = (modalState: boolean) => {
    setOpenPublishCourseModal(modalState);
    if (!modalState && triggerMessage === 'SUCCESS') {
      navigate("/myCourses")
    }
  }

  const handleFinalGradeMessage = (completionRate: number, currentRate: number): string => {
    switch (true) {
      case currentRate < completionRate:
        return t('course:messages.GRADE_LESS_THAN_FINAL_RATE', { grade: currentRate, final: completionRate });
      case currentRate > completionRate && currentRate !== 100:
        return t('course:messages.GRADE_MORE_THAN_FINAL_RATE', { grade: currentRate });
      case currentRate === 100:
        return t('course:messages.COMPLETE_FINAL_RATE', { grade: currentRate });
      default:
        return "";
    }
  }

  const actionList = [
    {
      id: 0,
      name: t('common:actions.UNPUBLISH'),
      icon: <DoNotDisturbOutlinedIcon fontSize='small' />,
      onClick: () => {
        setIsPublishing(true)
        setTypeAction("unpublish")
        setModalMessage(t("common:messages.CONFIRMATION_OF_PUBLICATION_CANCELLATION"))
        openDeleteConfirmModal(courseId || "")
      }
    },
    {
      id: 1,
      name: t('common:actions.COMPLETE_THE_COURSE'),
      icon: <SportsScoreOutlinedIcon fontSize='small' />,
      onClick: () => {
        setTypeAction("complete")
        setModalMessage(handleFinalGradeMessage(courseResultDto?.completionRate || 0, courseResultDto?.currentRate || 0))
        openDeleteConfirmModal(courseId || "");
      }
    },
    {
      id: 2,
      name: t('common:actions.CLOSE_THE_COURSE'),
      icon: <FlagOutlinedIcon fontSize='small' />,
      onClick: role === Role.LOCAL_ADMIN ? () => {
        setOpenPublishCourseModal(true);
        setMessage(t('common:messages.ARE_YOU_SURE_WANT_TO_CLOSE_COURSE'));
        setAttention(true);
        setIsPublishing(false);
      } : () => {
        setYesOrNoModal(true);
      }
    },
  ]

  const handleCloseCourseModal = () => {
    setCloseCourseModal(true)
    setOpenPublishCourseModal(false)
  }

  const handleCloseCourseByEducator = async () => {
    setYesOrNoModal(false);
    try {
      const res = await serviceCourse.changeCourseStatus(courseId || "", CourseStatus.CLOSED);
      if (res.status === 200) {
        setMessage(t('course:messages.COURSE_CLOSED'));
        setAttention(false);
        setSuccess(true);
        setTriggerMessage("SUCCESS");
        handleTogglePublishCourseModal(true);
      }
    } catch (error: any) {
      handleTogglePublishCourseModal(true);
      setMessage(t(`course:messages.${error.response.data.code}`));
      setAttention(false);
      setSuccess(false);
    }
  }
  return (
    <>
      {DeleteModal}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          marginTop: "15px",
          border: `1px solid ${common.borderSecondary}`,
          borderRadius: '12px',
          width: '100%'
        }}
      >
        <Box
          sx={{
            background: common.fontWhite,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 1.5,
            gap: 1,
            p: 2
          }}
        >
          <Typography fontWeight={600} fontSize={'16px'}>{t('course:COURSE_ACTIONS')}</Typography>
          <Box width="100%" height="1px" sx={{ background: common.secondaryColor }}></Box>
          <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
            {actionList.map((item, index) => {
              const startDate = courseInfo?.courseSetting?.startDate;
              if (item.id === 0 && ((startDate && new Date(startDate) < new Date()) || role === Role.LISTENER || !unpublishCourseIsOpen)) return null;
              if (item.id === 1 && (role !== Role.LISTENER || !(courseData?.typeCode === TypeCode.FREE && courseData?.formatType === CourseFormatType.ASYNC))) return null;
              if (item.id === 2 && ((role === Role.LOCAL_ADMIN && !courseInfo?.externalId) || !closeCourseIsOpen)) return null;

              return (
                <Box
                  key={index}
                  display="flex"
                  onClick={() => item.onClick()}
                  sx={{
                    color: common.primaryColor,
                    border: `1px solid ${common.primaryColor}`,
                    borderRadius: 1.5,
                    px: 1.5,
                    py: 1.25,
                    alignItems: 'center',
                    gap: 0.5,
                    width: `${!matches ? '100%' : 'fit-content'}`,
                    cursor: "pointer",
                    "&:hover": {
                      background: common.secondaryColor,
                      color: common.primaryColor
                    },
                  }}>
                  {item.icon}
                  <Typography fontSize="14px">{item.name}</Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '30%' }}>
          {t('course:hints.FINAL_GRADE_IS_NOT_CALCULATED_FOR_ALL_STUDENTS')}
        </Alert>
      </Snackbar>
      {openPublishCourseModal &&
        <PublishCourseModal
          openModal={openPublishCourseModal}
          handleToggleModal={handleTogglePublishCourseModal}
          publishCourse={isPublishing ? handleUnpublishCourse : handleCloseCourseModal}
          success={success}
          attention={attention}
          message={message}
        />
      }
      {closeCourseModalIsOpen &&
        <CloseCourseModal
          openModal={closeCourseModalIsOpen}
          handleToggleModal={() => setCloseCourseModal(!closeCourseModalIsOpen)}
          handleToggleSuccessModal={handleTogglePublishCourseModal}
          setSuccess={setSuccess}
          setAttention={setAttention}
          setMessage={setMessage}
        />
      }
      {yesOrNoModalIsOpen &&
        <YesOrNoModal
          openModal={yesOrNoModalIsOpen}
          success={false}
          handleToggleModal={() => setYesOrNoModal(!yesOrNoModalIsOpen)}
          attention={true}
          confirmAction={() => handleCloseCourseByEducator()}
          message={t("common:messages.ARE_YOU_SURE_WANT_TO_CLOSE_COURSE")}
          icon={true}
          text={t("course:messages.CLOSE_COURSE_MODAL_TEXT")}
        />
      }
    </>
  )
}
