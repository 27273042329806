import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { ReactComponent as GraphDown } from "@assets/svg_files/graphDown.svg";
import { useQuery } from '@tanstack/react-query';
import { serviceIntegration } from '@services/integrations';
import { useParams } from 'react-router-dom';
import { formatDateWithHourMinute } from '@utils/functions';

export const AntiPlagiarsmInfo = () => {
  const { common } = useTheme().palette;
  const { id } = useParams()
  const { t } = useTranslation(["antiPlagiarism"]);
  const { data: infoAntiPlagiarism } =
    useQuery({
      queryKey: ['infoAntiPlagiarism'],
      queryFn: () => serviceIntegration.getInfoAntiPlagiarism(Number(id)),
    });

  return (
    <Box display="flex" gap={2}>
      <Box sx={{ minWidth: "172px", width: "50%", height: "96px", background: common.white, border: `1px solid ${common.borderSecondary}`, borderRadius: "12px", padding: "20px 16px" }}>
        <Box display="flex" gap={2} alignItems="center">
          <Box sx={{ background: "#FBF9FF", padding: "8px 12px", borderRadius: "30px" }} alignItems="center">
            <CreditScoreOutlinedIcon sx={{ color: common.primaryColor, fontSize: "32px" }} />
          </Box>
          <Box>
            <Typography sx={{ color: "#6D737A", fontSize: "16px" }}>{t("ACQUIRED")}</Typography>
            <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>{infoAntiPlagiarism?.data.checksCount || 0}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ minWidth: "172px", width: "50%", height: "96px", background: common.white, border: `1px solid ${common.borderSecondary}`, borderRadius: "12px", padding: "20px 16px" }}>
        <Box display="flex" gap={2} alignItems="center">
          <Box sx={{ background: "#FBF9FF", padding: "8px 12px", borderRadius: "30px" }} alignItems="center">
            <GraphDown />
          </Box>
          <Box>
            <Typography sx={{ color: "#6D737A", fontSize: "16px" }}>{t("SPENT")}</Typography>
            <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>{(infoAntiPlagiarism?.data.checksCount - infoAntiPlagiarism?.data.remainedChecksCount) || 0}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ minWidth: "172px", width: "50%", height: "96px", background: common.white, border: `1px solid ${common.borderSecondary}`, borderRadius: "12px", padding: "20px 16px" }}>
        <Box display="flex" gap={2} alignItems="center">
          <Box sx={{ background: "#FBF9FF", padding: "8px 12px", borderRadius: "30px" }} alignItems="center">
            <ShoppingBasketOutlinedIcon sx={{ color: common.primaryColor, fontSize: "32px" }} />
          </Box>
          <Box>
            <Typography sx={{ color: "#6D737A", fontSize: "16px" }}>{t("LEFT")}</Typography>
            <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>{infoAntiPlagiarism?.data.remainedChecksCount || 0}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ minWidth: "172px", width: "50%", height: "96px", background: common.white, border: `1px solid ${common.borderSecondary}`, borderRadius: "12px", padding: "20px 16px" }}>
        <Box display="flex" gap={2} alignItems="center">
          <Box sx={{ background: "#FBF9FF", padding: "8px 12px", borderRadius: "30px" }} alignItems="center">
            <CalendarMonthOutlinedIcon sx={{ color: common.primaryColor, fontSize: "32px" }} />
          </Box>
          <Box>
            <Typography sx={{ color: "#6D737A", fontSize: "16px" }}>{t("VALIDITY_PERIOD")}</Typography>
            <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>{formatDateWithHourMinute(infoAntiPlagiarism?.data.expiryDate) || "-"}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
