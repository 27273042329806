import { Box, Typography, useTheme, TextField, InputAdornment, TablePagination } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect } from 'react';
import { serviceDict } from '@services/dictionary';
import useLocalPagination from '@hooks/useLocalPagination';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import Breadcrumbs from '@components/Breadcrumbs';

interface IComponents {
  id: number;
  name: string;
  navigate: string
}
const SettingsHandbook = () => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["sideMenu", "common", "course", "enum", "user"]);
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { orgId } = profile.authorityInfo;
  const navigate = useNavigate();
  const [branchTypeId, setBranchTypeId] = useState<number>(2);
  const { setBreadcrumbs } = useBreadcrumbs();

  const components = [
    {
      id: 1,
      name: t('enum:EDU_PROGRAMS'),
      navigate: `/settings/handbook/${orgId}/edu_program`
    },
    {
      id: 2,
      name: t('user:DEPARTMENTS'),
      navigate: `/settings/handbook/${orgId}/subdivision`
    },
    {
      id: 3,
      name: t('enum:TYPES_OF_CLASSES'),
      navigate: `/settings/handbook/${orgId}/class_type`
    },
    {
      id: 4,
      name: t('enum:BASE_FOR_EARLY_COMPLETION'),
      navigate: `/settings/handbook/${orgId}/course_close_reason`
    }
  ]

  const {
    rowsPerPage, page, data, setInitData,
    handleChangePage, handleChangeRowsPerPage, totalElements
  } = useLocalPagination<IComponents>()

  const handleSearch = (value: string) => {
    const filtered = components.filter((component) =>
      component.name.toLowerCase().includes(value.toLowerCase())
    );
    setInitData(filtered)
  };

  const styles = {
    color: common.primaryColor,
    p: 2,
    fontSize: '16px',
    lineHeight: '24px'
  }

  useEffect(() => {
    setInitData(components)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    serviceDict.getDictionary('BRANCH', orgId).then((res) => {
      if (res.status === 200) {
        setBranchTypeId(res.data.branchTypeId)
      }
    })
  }, [orgId])

  useEffect(() => {
    setBreadcrumbs("handbook_settings", [{
      level: 1,
      page: 'settings',
      label: "course:SETTINGS",
      path: '/settings',
      tabNum: null
    }, {
      level: 2,
      page: 'dictionary',
      label: "sideMenu:DICTIONARY",
      path: null,
      tabNum: null
    }]);
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Box sx={{ display: "flex", alignItems: 'center', mb: '24px' }}>
        <Breadcrumbs id="handbook_settings" />
      </Box>
      <Typography sx={{ fontWeight: "500", fontSize: "36px", lineHeight: "44px", mb: "24px" }}>
        {t("DICTIONARY")}
      </Typography>
      <Box sx={{
        border: `1px solid ${common.borderSecondary}`, backgroundColor: common.fontWhite, borderRadius: '24px', p: '24px', height: '546px'
      }}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          color="primary"
          onChange={(e) => handleSearch(e.target.value)}
          sx={{
            width: { xs: '100%', md: '70%' }, mr: "24px", justifyContent: "flex-start", '& label.Mui-focused': { color: common.primaryColor },
            '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': { borderColor: common.primaryColor },
              '&.Mui-focused fieldset': { borderColor: common.primaryColor }
            }
          }}
          placeholder={t("common:placeholders.SEARCH_BY_NAME")}
          name="searchValue"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box sx={{ backgroundColor: common.primaryColor, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px', mr: '-14px', borderRadius: '6px' }}>
                  <SearchIcon sx={{ color: '#FFFFFF', width: '26px', height: '26px' }} />
                </Box>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ borderTop: `2px solid ${common.borderSecondary}`, borderBottom: `2px solid ${common.borderSecondary}`, mt: 3 }}>
          <Typography sx={{ ...styles, color: 'black' }}>
            {t('course:task.NAME')}
          </Typography>
        </Box>
        <TableContainer component={Paper} sx={{
          fontSize: '16px', lineHeight: '24px'
        }}>
          <Table aria-label="My Table">
            <TableHead>
              {data.length > 0 ? data.map((component) => {
                return (
                  ((component.id === 1 || component.id === 4) && branchTypeId !== 2) ? null : /*если тип "Организация", не показывать Образовательные программы и Основания досрочного завершения*/
                    <TableRow key={component.id}>
                      <TableCell sx={styles}>
                        <Typography sx={{ cursor: 'pointer' }} onClick={() => { navigate(component.navigate) }}>
                          {component.name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                )
              }) : (
                <TableRow sx={{ display: "flex", justifyContent: "center" }}>
                  <TableCell>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "20px",
                        display: "flex",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: 'center',
                        mt: 2
                      }}
                    >
                      {t('common:messages.NO_ENTIRES_FOUND')}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableHead>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('enum:LINES_PER_PAGE')}
            labelDisplayedRows={
              ({ from, to, count }) => t('enum:TABLE_PAGES', { from, to, count })
            }
          />
        </TableContainer>
      </Box >
    </>

  )
}

export default SettingsHandbook
