import { StudyClassFilter, ResponseFilter } from "@interfaces/filter";
import { ResponseList } from "@interfaces/responseList";
import { StudyClass } from "@interfaces/studyClass";
import API from "./api";

export class CatalogControllerApi extends API {
  private readonly url = 'course/student/catalog';

  getAllCoursesInCatalog(req: ResponseFilter<StudyClassFilter>): Promise<ResponseList<StudyClass>> {
    return this.post(`${this.url}/findAll`, req);
  }
}