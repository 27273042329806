import { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Rating, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';

import { useGetReviewListByCourseIdMutation } from '@services/public';
import AvatarPicture from '@components/AvatarPicture';
import UserFullName from '@components/UserFullName';
import CustomButton from '@ui/Button';
import CourseReviewModal from './CourseReviewModal';

const CourseReview = ({ courseId, courseReview }) => {
  const { common } = useTheme().palette;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  
  const [sendParams, { data: review }] = useGetReviewListByCourseIdMutation();

  const handleFetchReviewList = useCallback(() => {
    sendParams({
      filter: { search: '' },
      pageNumber: 0,
      size: 4,
      id: courseId,
    });
  }, [courseId, sendParams]);

  useEffect(() => {
    handleFetchReviewList();
  }, [handleFetchReviewList]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="24px">
        <Typography fontSize="32px" fontWeight={700} >Отзывы и оценка курса</Typography>
        <Box>
          <Box display="flex" gap={1}>
            <Rating
              value={courseReview?.avgRating || 0}
              readOnly
              size="small"
              precision={0.5}
              sx={{
                '& .MuiSvgIcon-root': {
                  fill: '#F4C41A'
                }
              }}
            />
            <Typography fontSize="16px" fontWeight={500}>
              {courseReview?.avgRating}
            </Typography>
          </Box>
          <Typography fontSize="12px" color="#8E8E93">Рейтинг курса</Typography>
        </Box>
      </Box>
      <Grid container gap={1.5}>
      {review && review.dtoList?.map((node: any) => {
        return (
          <Grid item md={5.8} xs={12} sx={{ padding: "24px", background: "#fff" }} key={node.id}>
            <Box display="flex" gap={2} alignItems="center">
              <AvatarPicture userId={node.userId} sxProps={{ width: "50px", height: "50px" }} />
              <Box>
                <Typography fontSize="16px" fontWeight={600}>
                  <UserFullName id={node.userId} />
                </Typography>
                <Box display="flex" gap={1}>
                  <Rating 
                    value={node.grade}
                    readOnly
                    size="small"
                    precision={0.5}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fill: '#F4C41A'
                      }
                    }}
                  />
                  <Typography fontSize="12px" color="#8E8E93">
                    {format(
                      new Date(node.createdAt),
                      "dd.MM.yyyy HH:mm"
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {node.review?.length <= 150 ?
              <Typography sx={{ display: 'inline' }}>
                {node.review}
              </Typography> :
              <>
                {showMore ? 
                  <>
                    <Typography sx={{ display: 'inline' }}>
                      {node.review}
                    </Typography>
                    <Typography sx={{ cursor: "pointer", color: common.primaryColor, display: 'inline' }}
                      onClick={() => setShowMore(false)}>
                      {`свернуть`}
                    </Typography>
                  </> :
                  <>
                    <Typography sx={{ display: 'inline' }}>
                      {node.review?.substring(0, 150)}
                    </Typography>
                    <Typography sx={{ cursor: "pointer", color: common.primaryColor, display: 'inline' }}
                      onClick={() => setShowMore(true)}>
                      {`...развернуть`}
                    </Typography>
                  </>
                }
              </>
            }
          </Grid>
        )
      })}
      </Grid>
      {review && review.totalElements > 4 &&
        <Box display="flex" justifyContent="space-between" marginTop="24px" alignItems="center">
          <CustomButton
            width="200px"
            sx={{ fontSize: "14px" }}
            onClick={() => setOpenModal(!openModal)}
          >
            Показать все отзывы
          </CustomButton>
        </Box>
      }
      {openModal && 
        <CourseReviewModal
          courseId={courseId}
          open={openModal} 
          handleToggleModal={() => setOpenModal(!openModal)} 
        />
      }
    </Box >
  )
}

export default CourseReview;