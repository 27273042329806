import { AuthorControllerApi } from './author';
import { CatalogControllerApi } from './catalogController';
import { CourseControllerApi } from "./courseController";
import { CourseGradingApi } from "./courseGradingController";
import { DictionaryControllerApi } from './dictionaryController';
import { FileStorageControllerApi } from './fileStorageController';
import { HomeControllerApi } from './homeController';
import { InfoCourseControllerApi } from './info';
import { MemberControllerApi } from "./memberController";
import { MyCourseControllerApi } from './myCourseController';
import { PublicControllerApi } from './publicController';
import { StudyClassControllerApi } from './studyClassController';
import { EventCalendarControllerApi } from './eventCalendarController';

export const courseGradingApi = new CourseGradingApi();
export const courseApi = new CourseControllerApi();
export const memberControllerApi = new MemberControllerApi();
export const authorControllerApi = new AuthorControllerApi();
export const myCourseControllerApi = new MyCourseControllerApi();
export const catalogControllerApi = new CatalogControllerApi();
export const dictionaryControllerApi = new DictionaryControllerApi();
export const infoCourseControllerApi = new InfoCourseControllerApi();
export const studyClassControllerApi = new StudyClassControllerApi();
export const publicControllerApi = new PublicControllerApi();
export const homeControllerApi = new HomeControllerApi();
export const fileStorageControllerApi = new FileStorageControllerApi();
export const eventCalendarControllerApi = new EventCalendarControllerApi();
