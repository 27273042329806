import { forwardRef, useState, useMemo, useCallback } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import { useFormikContext, useField } from "formik";
import ru from "date-fns/locale/ru";
import kk from "date-fns/locale/kk";
import { format } from "date-fns"
import { Box, Button, TextField, Typography } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useTheme } from '@mui/material/styles';

import 'react-datepicker/dist/react-datepicker.css';
import { Placement } from '@popperjs/core';
registerLocale('ru', ru);
registerLocale('kk', kk);

interface Props {
  label?: string;
  name: string;
  disabled?: boolean;
  pickerType?: string;
  isRequired?: boolean;
  minDate?: string | Date;
  maxDate?: string | Date;
  shouldCloseOnSelect?: boolean;
  isClearable?: boolean;
  popperPlacement?: Placement;
  labelPostion?: string;
}

const DateTimePicker: React.FC<Props> = ({
  label,
  name,
  disabled = false,
  pickerType,
  isRequired = false,
  minDate,
  maxDate,
  shouldCloseOnSelect = false,
  isClearable = false,
  popperPlacement = 'bottom-start',
  labelPostion = 'top'
}) => {
  const formikProps = useFormikContext();
  const [field, meta] = useField(name);
  const [datePickerRef, setDatePickerRef] = useState<any>(false)
  const { common } = useTheme().palette;
  const closeCalendar = () => datePickerRef.setOpen(false);

  const ExampleCustomInput = forwardRef(({ value, onClick }: { value: any, onClick: () => void }, ref: any) => (
    <TextField
      ref={ref} onClick={onClick}
      value={value} size="small"
      fullWidth
      sx={{
        '& .MuiInputBase-root.Mui-disabled': {
          backgroundColor: disabled ? common.inputDisabledBg : "transparent"
        },
      }}
      InputProps={{
        startAdornment: (<CalendarMonthIcon sx={{ color: "#757575", mr: '10px' }} />)
      }}
    />
  ));

  const handleTimeChange = (newValue: Date | null) => {
    formikProps.setFieldValue(name, newValue ? format(newValue, "HH:mm") : "00:00")
  };

  const handleChange = (newValue: Date | null) => {
    formikProps.setFieldValue(name, newValue ? format(newValue, "yyyy-MM-dd") : null);
  };

  const handleDateTimeChange = (newValue: Date | null) => {
    if (newValue) {
      if (filterTime(newValue)) {
        formikProps.setFieldValue(name, format(newValue, "yyyy-MM-dd'T'HH:mm"));
      } else {
        if (minDate && new Date(newValue) < new Date(minDate))
          formikProps.setFieldValue(name, minDate);
        if (maxDate && new Date(newValue) > new Date(maxDate))
          formikProps.setFieldValue(name, maxDate);
      }
    }
  };

  const handleToday = () => {
    formikProps.setFieldValue(name, format(new Date(), "yyyy-MM-dd'T'HH:mm"));
    datePickerRef.handleSelect(new Date())
  }

  const filterTime = (date: any) => {
    if (minDate && maxDate) {
      // Allow dates between the selected start and end dates (inclusive).
      return new Date(date).getTime() >= new Date(minDate).getTime() && new Date(date).getTime() <= new Date(maxDate).getTime();
    }
    else if (!minDate && maxDate) {
      return new Date(date).getTime() <= new Date(maxDate).getTime();
    }
    else if (minDate && !maxDate) {
      return new Date(date).getTime() >= new Date(minDate).getTime()
    }
    return true;
  };

  return (
    <Box>
      {label && labelPostion === 'top' &&
        <Typography>
          {label}
          {isRequired && <span style={{ color: common.errorColor }}>*</span>}
        </Typography>
      }

      <Box display="flex" alignItems="center" gap={0.5}>
        {label && labelPostion === 'left' &&
          <Typography>
            {label}
            {isRequired && <span style={{ color: common.errorColor }}>*</span>}
          </Typography>
        }
        {pickerType === "time" ?
          <DatePicker
            showTimeSelectOnly
            dateFormat="HH:mm"
            disabled={disabled}
            ref={(r) => {
              setDatePickerRef(r)
            }}
            timeCaption="Время:"
            placeholderText='чч:мм'
            onChange={(date: any) => handleTimeChange(date)}
            selected={field.value ? new Date(field.value) : null}
            isClearable={isClearable}
          >
          </DatePicker> : pickerType === "dateTime" ?
            <DatePicker
              selected={field.value ? new Date(field.value) : null}
              locale="ru"
              disabled={disabled}
              ref={(r) => setDatePickerRef(r)}
              timeInputLabel="Время:"
              popperPlacement={popperPlacement}
              shouldCloseOnSelect={shouldCloseOnSelect}
              minDate={minDate ? new Date(minDate) : null}
              maxDate={maxDate ? new Date(maxDate) : null}
              filterTime={filterTime}
              placeholderText='дд.мм.гггг чч:мм'
              dateFormat="dd-MM-yyyy HH:mm"
              timeFormat="HH:mm"
              onChange={(date: any) => date ? handleDateTimeChange(date) : null}
              isClearable={isClearable}
              showTimeSelect
              onClickOutside={() => { formikProps.setFieldTouched(name, true) }}
              customInput={
                <TextField
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: (<CalendarMonthIcon sx={{ color: "#757575", mr: '10px' }} />)
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: "#FFFFFF",
                      border: `1px solid ${meta.touched && Boolean(meta.error) ? common.errorColor : common.borderSecondary}`,
                      pr: 0
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                      backgroundColor: disabled ? common.inputDisabledBg : "transparent"
                    },
                    '& label.Mui-focused': { color: common.primaryColor },
                    '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': { borderColor: common.primaryColor },
                      '&.Mui-focused fieldset': { borderColor: common.primaryColor }
                    }
                  }}
                />
              }
            >
              <Box>
                <Button size="small" sx={{ color: "#000", margin: "0", textTransform: "none", background: "#F0F0F0", marginRight: "80px" }} onClick={() => handleToday()}>Сегодня</Button>
                <Button size="small" sx={{ color: "#000", margin: "0", textTransform: "none", background: "#F0F0F0" }} onClick={() => closeCalendar()}>Закрыть</Button>
              </Box>
            </DatePicker> :
            <DatePicker
              locale="ru"
              ref={(r) => {
                setDatePickerRef(r)
              }}
              disabled={disabled}
              dateFormat="dd.MM.yyyy"
              placeholderText='дд.мм.гггг'
              shouldCloseOnSelect={shouldCloseOnSelect}
              customInput={
                <ExampleCustomInput
                  value={field.value ? new Date(field.value) : null}
                  onClick={() => { }}
                />
              }
              minDate={minDate ? new Date(minDate) : null}
              maxDate={maxDate ? new Date(maxDate) : null}
              selected={field.value ? new Date(field.value) : null}
              onChange={(date: any) => date ? handleChange(date) : null}
              isClearable={isClearable}
              onClickOutside={() => { formikProps.setFieldTouched(name, true) }}
            >
              <Box>
                <Button size="small" sx={{ color: "#000", margin: "0", textTransform: "none", background: "#F0F0F0", marginRight: "80px" }} onClick={() => handleToday()}>Сегодня</Button>
                <Button size="small" sx={{ color: "#000", margin: "0", textTransform: "none", background: "#F0F0F0" }} onClick={() => closeCalendar()}>Закрыть</Button>
              </Box>
            </DatePicker>
        }
      </Box>
      {meta.error && meta.touched && <Typography sx={{ color: common.errorColor, fontSize: '12px' }}>{meta.error}</Typography>}
    </Box >
  );
}

export default DateTimePicker;
