import React, { useMemo, useState } from 'react'
import { Box, MenuItem, Switch, Typography, useTheme } from '@mui/material'
import CustomAutocomplete from '@ui/Autocomplete';
import CustomSearch from '@ui/Search'
import { useTranslation } from 'react-i18next';
import { MaterialReactTable, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import {
  type MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";
import { useNavigate } from 'react-router-dom';

const mockData = [{
  name: "Лицензия для частных ОВПО", status: "Активирована",
  typeOrg: "Университет", course: "40", author: "4", listener: "1000000",
  storage: "89 ГБ", date: "10.12.2024 10:30", visibility: true
},
{
  name: "Лицензия для авторов курсов", status: "Активирована",
  typeOrg: "Университет", course: "34", author: "6", listener: "1000000",
  storage: "89 ГБ", date: "10.12.2024 10:30", visibility: false
},
{
  name: "Лицензия для преподавателей ВУЗов", status: "Активирована",
  typeOrg: "Университет", course: "40", author: "4", listener: "1000000",
  storage: "89 ГБ", date: "10.12.2024 10:30", visibility: true
},
{
  name: "Лицензия для сотрудников Platonus", status: "Активирована",
  typeOrg: "Университет", course: "40", author: "4", listener: "1000000",
  storage: "89 ГБ", date: "10.12.2024 10:30", visibility: false
},
{
  name: "Доступная лицензия", status: "Активирована",
  typeOrg: "Университет", course: "40", author: "4", listener: "1000000",
  storage: "89 ГБ", date: "10.12.2024 10:30", visibility: true
}]

export const DirectoryLicense = () => {
  const { t } = useTranslation(["dictionary", "common", "sideMenu", "course", "license", "freeCourse"]);
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const [count, setCount] = useState()
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25, //customize the default page size
  });
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t("license:LICENSE"),
        size: 300,
        enableSorting: true,
        Cell: ({ renderedCellValue, row }) => (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}>
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: common.primaryColor,
                  cursor: "pointer"
                }}
                onClick={() => { navigate("/license/31") }}
              >
                {renderedCellValue}
              </Typography>
            </Box>
          </Box>
        )
      },
      {
        accessorKey: 'status',
        header: t("common:label:STATUS"),
        enableSorting: true,
        size: 50
      },
      {
        accessorKey: 'typeOrg',
        header: t("dictionary:TYPE_OF_ORGANIZATION"),
        enableSorting: true,
        size: 50
      },
      {
        accessorKey: 'course',
        header: t("freeCourse:COURSES"),
        enableSorting: true,
        size: 50
      },
      {
        accessorKey: 'author',
        header: t("license:AUTHORS"),
        enableSorting: true,
        size: 50
      },
      {
        accessorKey: 'listener',
        header: t("license:LISTENERS"),
        enableSorting: true,
        size: 50
      },
      {
        accessorKey: 'storage',
        header: t("license:STORAGE"),
        enableSorting: true,
        size: 50
      },
      {
        accessorKey: 'date',
        header: t("license:DATE_OF_CREATION"),
        enableSorting: true,
        size: 50
      },
      {
        accessorKey: 'visibility',
        header: t("license:VISIBILITY"),
        enableSorting: true,
        size: 50,
        Cell: ({ renderedCellValue, row }) => (

          <Box>
            <Switch />
          </Box>
        )
      },

    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data: mockData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableSorting: true,
    enableColumnActions: false,
    enableExpandAll: false,
    enableColumnResizing: false,
    enableTopToolbar: false,
    enableRowNumbers: false,
    enableColumnPinning: false,
    enableRowActions: true,
    manualPagination: true,
    manualSorting: true,
    positionActionsColumn: "last",
    localization: {
      noRecordsToDisplay: t('dictionary:EMPTY'),
      rowsPerPage: t('enum:LINES_PER_PAGE'),
      actions: "...",
      expand: "",
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: 30,
        muiTableBodyCellProps: { align: "center" },
        muiTableHeadCellProps: { align: "center" }
      },
    },

    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default, //change default background color
    }),
    initialState: {
      columnPinning: { right: ["mrt-row-actions",] },
    },
    muiTableBodyRowProps: { hover: false },
    muiTableHeadCellProps: {
      sx: {
        borderLeft: '1px solid rgba(81, 81, 81, .5)'
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderLeft: '1px solid rgba(81, 81, 81, .5)',
      },
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        display: 'grid'
      },
    },
    muiTableContainerProps: {
      sx: {
        '&::-webkit-scrollbar': { height: '4px', width: '7px' },
        '&::-webkit-scrollbar-thumb': { backgroundColor: '#D9D9D9', borderRadius: '4px' }
      },
    },
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <MenuItem
        key={0}
        onClick={() => { closeMenu() }}
        sx={{

        }}
      >
        {t('common:actions.EDIT')}
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => { closeMenu() }}
        sx={{
        }}
      >
        {t("license:DISPLAY_ON_SITE")}
      </MenuItem>,
      <MenuItem
        key={2}
        onClick={() => {
          closeMenu()
        }}
      >
        {t('common:actions.DEACTIVATE')}
      </MenuItem>,
      <MenuItem
        key={2}
        onClick={() => {
          closeMenu()
        }}
      >
        {t('common:actions.DELETE')}
      </MenuItem>,
    ],
    onPaginationChange: setPagination,
    state: { pagination, sorting }, //pass the pagination state to the table
    rowCount: count,
    onSortingChange: setSorting,
    paginationDisplayMode: "pages",
  });
  return (
    <Box sx={{ marginTop: "24px" }}>
      <Box display="flex" justifyContent="space-between" mb="12px">
        <CustomSearch width='390px' placeholder={t("common:placeholders.SEARCH_BY_ORGANIZATION_LICENSE")} />
        <Box display="flex" gap={1.5}>
          <CustomAutocomplete
            name="typeLicense"
            options={[]}
            sx={{ width: "166px" }}
            clearable={false}
          />
          <CustomAutocomplete
            name="statusLicense"
            options={[]}
            sx={{ width: "166px" }}
            clearable={false} />
        </Box>
      </Box>
      <MaterialReactTable table={table} />
    </Box>
  )
}
