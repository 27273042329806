import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AlertColor } from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { Formik, Form as FormikForm } from "formik";

import { serviceTask } from "@services/task";
import { serviceDict } from "@services/dictionary";
import { CourseContext } from "@context/CourseContext";
import CustomButton from "@ui/Button";
import FormikInput from "@ui/formik/Input";
import FormikCheckbox from "@ui/formik/Checkbox";
import { TextEditor } from "@ui/formik/TextEditor";
import FormikAutocomplete from "@ui/formik/Autocomplete";
import {
  AnswerTypes,
  ContentType,
  CourseFormatType,
  IMaterial,
  ISection,
  ITaskFreeCourses,
  ITaskGroup,
  ITheme,
  CourseStatus,
  TaskFormat,
  TaskType,
  TypeCode,
  DictionaryCode,
  AntiPlagiarismTaskSetting,
} from "@interfaces/index";
import {
  writtenAnswerTypes,
  considerationResults,
  taskParents,
  taskTypes,
  oralAnswerTypes,
} from "@utils/enums";
import { taskInitialDtoFreeCourses } from "./models";
import { taskValidationSchema } from "./validation";
import { serviceCourse } from "@services/course";
import FormikInputNew from "@ui/formik/InputNew";
import { serviceAntiPlagiarism } from "@services/antiPlagiarism";

interface Props {
  sectionOptions: Array<ISection>;
  themeOptions: Array<ITheme>;
  taskGroupOptions: Array<ITaskGroup>;
  gradingSystemId: number | null;
  setTaskName: (taskName: string) => void;
  setMessageOpen?: (modalState: boolean) => void;
  setMessageType?: (messageType: AlertColor) => void;
  setMessage?: (message: string) => void;
  setIsSelfTest?: (value: boolean) => void;
  isReadOnly?: boolean;
}

const Settings = ({
  sectionOptions,
  themeOptions,
  taskGroupOptions,
  gradingSystemId,
  setTaskName,
  setMessageOpen,
  setMessageType,
  setMessage,
  setIsSelfTest,
  isReadOnly = false,
}: Props) => {
  const { courseId, sectionId, themeId, taskId, taskFormat } = useParams();
  const { t } = useTranslation(["common", "course", "aero", "dictionary", "antiPlagiarism"]);
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const [taskInfo, setTaskInfo] = useState<ITaskFreeCourses>(
    taskInitialDtoFreeCourses
  );
  const [fileFormatOptions, setFileFormatOptions] = useState<Array<any>>([]);
  const [draftList, setDraftList] = useState<Array<any>>([]);
  const { courseData } = useContext(CourseContext);
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const publishedCourse = courseData?.status === CourseStatus.PUBLISHED;
  const isCourseAsync = courseData?.formatType === CourseFormatType.ASYNC;
  const publishedTask = taskInfo?.status === CourseStatus.PUBLISHED;
  const disabledByStatus = taskInfo?.status !== CourseStatus.SAVED;
  const [useProctoring, setUseProctoring] = useState<boolean>(false);
  const [isActiveAntiPlagiarismCourse, setIsActiveAntiPlagiarismCourse] = useState<boolean>(false)
  const [isActiveAntiPlagiarismOrg, setIsActiveAntiPlagiarismOrg] = useState<boolean>(false)
  const [showProctoringRule, setShowProctoringRule] = useState<boolean>(false);
  const [taskSettingDefaultAntiPlagiarism, setTaskSettingDefaultAntiPlagiarism] = useState<AntiPlagiarismTaskSetting[]>()

  useEffect(() => {
    serviceCourse.getQuizBankListForDropdown({
      pageNumber: 0,
      size: 100,
      filter: {
        langId: courseData?.langId || null,
        subdivisionIds: courseData?.subdivisionIds || null,
        isDisabled: false
      }
    }).then((res) => {
      if (res.status === 200) {
        setDraftList(res.data.dtoList);
      }
    });

    if (taskId && taskId !== "new") {
      serviceTask.getTaskInfo(taskId).then((res) => {
        if (res.status === 200) {
          setTaskInfo({
            ...res.data,
            quiz:
              res.data.format === TaskFormat.QUIZ
                ? {
                  ...res.data.quiz,
                  showNumberOfQuestions: !!res.data.quiz.numberOfQuestions,
                }
                : null,
          });
          setTaskName(res.data.name);
        }
      });
    } else {
      if (sectionId && sectionId !== "new") {
        setTaskInfo((prev) => ({
          ...prev,
          parentType: ContentType.SECTION,
          section: { id: sectionId },
        }));
      }
      if (themeId && themeId !== "new") {
        setTaskInfo((prev) => ({
          ...prev,
          parentType: ContentType.THEME,
          theme: { id: themeId },
        }));
      }
      if (taskFormat === TaskFormat.QUIZ && publishedCourse) {
        if (setIsSelfTest) setIsSelfTest(true);
        setTaskInfo((prev) => ({
          ...prev,
          isSelfTest: true,
          parentType: ContentType.THEME,
        }));
      }
      if (taskFormat !== TaskFormat.QUIZ) {
        if (publishedCourse)
          setTaskInfo((prev) => ({ ...prev, isNoMark: true }));
        if (taskFormat === TaskFormat.ORAL)
          setTaskInfo((prev) => ({ ...prev, answerType: null }));
        setTaskInfo((prev) => ({ ...prev, quiz: null }));
      }
      if (isCourseAsync) {
        setTaskInfo((prev) => ({
          ...prev,
          quiz: { ...prev.quiz, displayGrade: true, displayRightAnswers: true },
        }));
      }
      if (
        courseData?.typeCode === TypeCode.FREE &&
        taskFormat === TaskFormat.QUIZ
      ) {
        setTaskInfo((prev) => ({
          ...prev,
          quiz: {
            ...prev.quiz,
            allowMultipleAttempts: true,
            numberOfAttempts: 999999,
          },
        }));
      }
      setTaskInfo((prev) => ({
        ...prev,
        courseStatus: courseData?.status,
        format: taskFormat || "",
      }));
    }
  }, [
    taskId,
    taskFormat,
    courseData?.status,
    publishedCourse,
    setTaskName,
    sectionId,
    themeId,
    setIsSelfTest,
    isCourseAsync,
    courseData?.typeCode,
    courseData?.langId,
    courseData?.subdivisionIds
  ]);

  useEffect(() => {
    serviceDict.getDictListForDropdown(DictionaryCode.FILE_TYPE).then((res) => {
      if (res.status === 200) {
        setFileFormatOptions(res.data);
      }
    });
  }, []);

  useEffect(() => {
    serviceAntiPlagiarism.getDefaultTaskSettingAntiPlagiarism().then((res) => {
      if (res.status === 200) {
        setTaskSettingDefaultAntiPlagiarism(res.data);
        taskId === "new" && setTaskInfo((prev) => ({ ...prev, antiPlagiarismTaskSetting: { ...res.data.filter((setting) => setting.taskType === prev.type)[0], courseId: courseId } }))
      }
    });
  }, [courseId, taskId]);


  useEffect(() => {
    if (courseId) {
      serviceCourse.getCourseInfo(courseId).then((result) => {
        if (result.status === 200) {
          serviceDict.getTypeOptionById(result.data.typeId).then((res) => {
            if (res.status === 200) {
              setUseProctoring(res.data.useProctoring);
            }
          });
        }
      });
    }
  }, [courseId]);

  const showMessage = (type: AlertColor, message: string, duration = 5000) => {
    if (setMessageType) {
      setMessageType(type);
    }

    if (setMessage) {
      setMessage(message);
    }

    if (setMessageOpen) {
      setMessageOpen(true);

      setTimeout(() => {
        if (setMessageOpen) {
          setMessageOpen(false);
        }
      }, duration);
    }
  };

  useEffect(() => {
    serviceAntiPlagiarism.getCheckIsActiveAntiPlagiarismCourse(courseId, courseData?.branchId).then((res) => {
      if (res.status === 200) {
        setIsActiveAntiPlagiarismCourse(res.data)
      }
    })
    serviceAntiPlagiarism.getCheckIsActiveAntiPlagiarismOrg(courseData?.branchId).then((res) => {
      if (res.status === 200) {
        setIsActiveAntiPlagiarismOrg(res.data)
      }
    })
  }, [courseId, courseData?.branchId])

  const updateTaskStatus = () => {
    if (taskId) {
      serviceTask
        .changeTaskStatus(taskId)
        .then((res) => {
          if (res.status === 200) {
            setTaskInfo({ ...taskInfo, status: res.data });
            showMessage(
              "success",
              res.data === CourseStatus.SAVED
                ? t("course:messages.TASK_UNPUBLISHED")
                : t("course:messages.TASK_PUBLISHED")
            );
          }
        })
        .catch((err) => {
          if (err.response.data.code === "NUMBER_OF_QUESTIONS_CHECK") {
            showMessage(
              "error",
              t("course:messages.NUMBER_OF_QUESTIONS_CHECK", {
                nameTask: taskInfo.name,
              }),
              10000
            );
          }
          if (err.response.data.code === "NUMBER_OF_QUESTIONS_MIN") {
            showMessage(
              "error",
              t("course:messages.TO_PUBLISH_TEST_YOU_NEED_ADD_AT_LEAST_3_QUESTIONS"),
              10000
            );
          }
          if (err.response.data.code === "TASK_ALREADY_STARTED") {
            showMessage(
              "error",
              taskInfo.format === TaskFormat.ORAL
                ? t("course:messages.LISTENERS_HAVE_GRADES_FOR_ASSIGNMENT")
                : taskInfo.format === "WRITTEN"
                  ? t("course:messages.LISTENERS_ALREADY_SUBMITTED_ANSWERS")
                  : t("course:messages.LISTENERS_HAVE_STARTED_TEST")
            );
          }
          if (err.response.data.code === "NUMBER_OF_QUESTIONS_UNSPECIFIED") {
            showMessage(
              "error",
              t("course:messages.NUMBER_OF_QUESTIONS_UNSPECIFIED")
            );
          }
        })
        .finally(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    }
  };

  return (
    <Formik
      initialValues={taskInfo}
      enableReinitialize
      validationSchema={taskValidationSchema(t, taskSettingDefaultAntiPlagiarism || [])}
      onSubmit={(values: ITaskFreeCourses) => {
        if (values.quiz && !values.quiz?.allowMultipleAttempts) {
          values.quiz.numberOfAttempts = 1;
        }
        if (taskId === "new") {
          if (courseId) values.course = { id: courseId };
          serviceTask
            .createTask(values, courseId || "")
            .then((res) => {
              if (res.status === 200) {
                setTaskInfo(values);
                showMessage(
                  "success",
                  t("course:messages.TASK_WAS_CREATED_SUCCESSFULLY")
                );
                navigate(
                  `/myCourses/${courseId}/tasks/${res.data.id}/${values.format
                  }/${values.section?.id ? values.section.id : "new"}/${values.theme?.id ? values.theme.id : "new"
                  }`
                );
              }
            })
            .catch((error) => {
              const { data } = error.response;
              showMessage("error", t(`course:messages.${data.code}`));
            })
            .finally(() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            });
        } else {
          serviceTask
            .updateTask(taskId || "", values)
            .then((res) => {
              if (res.status === 200) {
                setTaskInfo(values);
                showMessage(
                  "success",
                  t("common:messages.DATA_CHANGED_SUCCESSFULLY")
                );
                navigate(
                  `/myCourses/${courseId}/tasks/${res.data.id}/${values.format
                  }/${values.section?.id ? values.section.id : "new"}/${values.theme?.id ? values.theme.id : "new"
                  }`
                );
              }
            })
            .catch((error) => {
              const { data } = error.response;
              showMessage("error", t(`course:messages.${data.code}`));
            })
            .finally(() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            });
        }
      }}
    >
      {({ values, submitForm, isValid, dirty, setFieldValue }) => {
        if (values.group?.id === null) {
          values.point = null;
        }
        return (
          <FormikForm>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: { xs: "24px", sm: "28px" },
                lineHeight: "36px",
                mb: "24px",
              }}
            >
              {taskFormat === TaskFormat.ORAL
                ? t("course:task.ORAL_TASK")
                : taskFormat === TaskFormat.WRITTEN
                  ? t("course:task.WRITTEN_TASK")
                  : t("course:task.TEST")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", lg: "70%" },
                gap: 2,
              }}
            >
              <FormikInput
                name="name"
                label={t("common:label.NAME")}
                isRequired
                disabled={publishedTask || !isAuthor || isReadOnly}
              />
              {!values.isSelfTest && (
                <TextEditor
                  name="description"
                  label={t("common:label.DESCRIPTION")}
                  isRequired={taskFormat !== TaskFormat.QUIZ}
                  readOnly={publishedTask || !isAuthor || isReadOnly}
                  showSymbolCount
                  maxChar={2000}
                  minChar={0}
                />
              )}
              {taskFormat === TaskFormat.QUIZ &&
                <FormikAutocomplete
                  label={t('course:SELECT_IMPORTED_TEST')}
                  name="quiz.draftId"
                  options={draftList}
                  disabled={publishedTask || !isAuthor || isReadOnly}
                />
              }
              {taskFormat === TaskFormat.QUIZ &&
                (useProctoring || values.quiz?.applyProctoring) && (
                  <FormikCheckbox
                    name="quiz.applyProctoring"
                    label={t("aero:USE_AERO")}
                    labelPlacement="end"
                    disabled={
                      publishedCourse ||
                      publishedTask ||
                      !isAuthor ||
                      isReadOnly
                    }
                    hintText={t("aero:USE_AERO_TEXT")}
                  />
                )}
              {values.quiz?.applyProctoring && (
                <Box>
                  <Box
                    display="flex"
                    gap={1}
                    sx={{ cursor: "pointer" }}
                    onClick={() => setShowProctoringRule(!showProctoringRule)}
                  >
                    {showProctoringRule ? (
                      <ExpandMoreOutlinedIcon />
                    ) : (
                      <ChevronRightOutlinedIcon />
                    )}
                    <Typography>{t("aero:LIST_AERO_RULE")}</Typography>
                  </Box>
                  {showProctoringRule && (
                    <Box
                      fontSize="14px"
                      paddingLeft="10px"
                      borderLeft="1px solid #8E8E93"
                      color="#8E8E93"
                      margin="10px 0 0 30px"
                    >
                      <Typography>
                        {t("aero:VERIFICATION_RULE_AERO")}
                      </Typography>
                      <Typography>
                        {t("aero:SCREEN_TRACKING_RULE_AERO")}
                      </Typography>
                      <Typography>{t("aero:MANY_SCREEN_RULE_AERO")}</Typography>
                      <Typography>{t("aero:AUDIO_RULE_AERO")}</Typography>
                      <Typography>{t("aero:VIEO_TEST_RULE_AERO")}</Typography>
                      <Typography>{t("aero:FACE_TEST_RULE_AERO")}</Typography>
                      <Typography>
                        {t("aero:BUNNING_APLICATIONS_RULE_AERO")}
                      </Typography>
                      <Typography>
                        {t("aero:TRACKING_OTHERS_BROWSER_RULE_AERO")}
                      </Typography>
                      <Typography>
                        {t("aero:BUNNING_OTHERS_BROWSER_RULE_AERO")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
              {taskFormat === TaskFormat.QUIZ &&
                values.type === TaskType.TASK && !values.quiz?.applyProctoring && (
                  <FormikCheckbox
                    name="isSelfTest"
                    label={t("course:test.SELF-TEST_TASK")}
                    labelPlacement="end"
                    onChange={(_, value) => {
                      if (setIsSelfTest) setIsSelfTest(value);
                      setFieldValue("parentType", "THEME");
                      setFieldValue("isCloseAccess", false);
                      setFieldValue("dateOfAccess", null);
                    }}
                    disabled={
                      publishedCourse ||
                      publishedTask ||
                      !isAuthor ||
                      isReadOnly
                    }
                    hintText={t(
                      "course:hints.TASKS_TO_CHECK_QUALITY_OF_MASTERED_MATERIAL_NOT_CONSIDERED_IN_FINAL_GRADE"
                    )}
                  />
                )}
              <FormikAutocomplete
                label={t("common:label.TYPE")}
                name="type"
                options={taskTypes}
                onChange={(value: { id: string; text: string }) => {
                  setFieldValue("isSelfTest", false);
                  values.enabledAntiPlagiarism && (
                  setFieldValue("antiPlagiarismTaskSetting",
                    taskId && taskInfo.antiPlagiarismTaskSetting?.taskType === value.id ? taskInfo.antiPlagiarismTaskSetting : taskSettingDefaultAntiPlagiarism?.filter((taskSetting) => taskSetting.taskType === value.id)[0])
                  )
                    setFieldValue(
                    "parentType",
                    value.id === TaskType.CONTROL
                      ? "COURSE"
                      : sectionId && sectionId !== "new"
                        ? ContentType.SECTION
                        : themeId && themeId !== "new"
                          ? ContentType.THEME
                          : null
                  );
                  setFieldValue(
                    "section",
                    value.id === TaskType.CONTROL
                      ? null
                      : sectionId && sectionId !== "new"
                        ? { id: sectionId }
                        : null
                  );
                  setFieldValue(
                    "theme",
                    value.id === TaskType.CONTROL
                      ? null
                      : themeId && themeId !== "new"
                        ? { id: themeId }
                        : null
                  );
                  setFieldValue("isNoMark", false);
                  setFieldValue("isExcludeTotalMark", false);
                  setFieldValue("isCloseAccess", true);
                }}
                clearable={false}
                disabled={
                  publishedCourse ||
                  values.isSelfTest ||
                  publishedTask ||
                  !isAuthor ||
                  isReadOnly
                }
              />
              {values.type === TaskType.TASK && (
                <>
                  <FormikAutocomplete
                    label={t("course:task.TASK_REFERS_TO")}
                    name="parentType"
                    options={taskParents}
                    isRequired
                    onChange={() => {
                      setFieldValue("section", null);
                      setFieldValue("theme", null);
                    }}
                    clearable={false}
                    disabled={
                      values.isSelfTest ||
                      publishedTask ||
                      !isAuthor ||
                      isReadOnly
                    }
                  />
                  {(values.parentType === ContentType.SECTION ||
                    values.parentType === ContentType.THEME) && (
                      <FormikAutocomplete
                        label={
                          values.parentType === ContentType.THEME
                            ? `${t("course:theme.THEME")}`
                            : `${t("course:section.SECTION")}`
                        }
                        name={
                          values.parentType === ContentType.THEME
                            ? "theme"
                            : "section"
                        }
                        valueAsObject
                        options={
                          values.parentType === ContentType.THEME
                            ? themeOptions
                            : sectionOptions
                        }
                        isRequired
                        clearable={false}
                        disabled={publishedTask || !isAuthor || isReadOnly}
                      />
                    )}
                </>
              )}
              {taskFormat === TaskFormat.WRITTEN && isActiveAntiPlagiarismCourse && isActiveAntiPlagiarismOrg &&
                (
                  <Box>
                    <FormikCheckbox name="enabledAntiPlagiarism" labelPlacement="end" label={t("antiPlagiarism:MANDATORY_PLAGIARISM_CHECK")}
                      onChange={(event) => {
                        event.target.checked && setFieldValue("answerType", AnswerTypes.FILE)
                        event.target.checked && setFieldValue("hasAnswerTypeFileFormats", true)
                        setFieldValue("antiPlagiarismTaskSetting", taskInfo.antiPlagiarismTaskSetting)
                      }} />
                    {values.enabledAntiPlagiarism &&
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Typography fontSize="14px" color="#6D737A">{t("antiPlagiarism:PARAMETER_PROVIDERS_ABILITY_CHECK_STUDENTS_WRITTEN_WORK")}</Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
                          <FormikInputNew fontWidth="100%" width="120px" type="number" min={0} max={100} adornmentIcon={"%"} name="antiPlagiarismTaskSetting.citation" labelEnd={t("antiPlagiarism:CITATION_COEFFICIENT")} isRequired flexDirection="initial" justifyContent="start" />
                          <FormikInputNew fontWidth="100%" width="120px" type="number" min={0} max={100} adornmentIcon={"%"} name="antiPlagiarismTaskSetting.selfCitation" labelEnd={t("antiPlagiarism:SELF_CITATION_COEFFIENT")} isRequired flexDirection="initial" justifyContent="start" />
                          <FormikInputNew fontWidth="100%" width="120px" type="number" min={0} max={100} adornmentIcon={"%"} name="antiPlagiarismTaskSetting.originality" labelEnd={t("antiPlagiarism:ORIGINALITY_COEFFICIENT")} isRequired flexDirection="initial" justifyContent="start" />
                          <FormikInputNew fontWidth="100%" width="120px" type="number" min={0} max={100} adornmentIcon={"%"} name="antiPlagiarismTaskSetting.similarity" labelEnd={t("antiPlagiarism:SIMILARITY_COEFFICIENT")} isRequired flexDirection="initial" justifyContent="start" />
                          <FormikInputNew fontWidth="100%" width="120px" type="number" min={0} name="antiPlagiarismTaskSetting.symbolReplacementMaxCount" labelEnd={t("antiPlagiarism:NUMBER_CHARACTER_SUBSTITUTIONS")} isRequired flexDirection="initial" justifyContent="start" />
                        </Box>
                      </Box>
                    }
                  </Box>
                )
              }
              {taskFormat === TaskFormat.QUIZ && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                    }}
                  >
                    <Typography>
                      {t("course:theme.TESTING_TIME")}
                      <span style={{ color: common.errorColor }}>*</span>
                    </Typography>
                    <Box sx={{ width: "20%" }}>
                      <FormikInput
                        name="quiz.duration"
                        type="number"
                        disabled={publishedTask || !isAuthor || isReadOnly}
                        adornmentIcon={<AccessTimeIcon />}
                      />
                    </Box>
                  </Box>
                  <FormikCheckbox
                    name="quiz.showNumberOfQuestions"
                    label={t("common:label.SHOW_NUMBER_OF_QUESTIONS")}
                    labelPlacement="end"
                    disabled={publishedTask || !isAuthor || isReadOnly}
                    onChange={() =>
                      setFieldValue("quiz", {
                        ...values.quiz,
                        numberOfQuestions: null,
                      })
                    }
                    hintText={t("common:hints.OPTION_NUMBER_OF_QUESTIONS_TASK")}
                  />
                  {values?.quiz?.showNumberOfQuestions && (
                    <FormikInput
                      name="quiz.numberOfQuestions"
                      label={t("common:label.NUMBER_OF_QUESTIONS_TEST")}
                      type="number"
                      min={1}
                      isRequired
                      disabled={publishedTask || !isAuthor || isReadOnly}
                    />
                  )}
                  {!values.isSelfTest &&
                    !values.quiz?.applyProctoring && (
                      <>
                        <FormikCheckbox
                          name="quiz.allowMultipleAttempts"
                          label={t(
                            "course:theme.ALLOW_MULTIPLE_ATTEMPTS_TO_SURRENDER"
                          )}
                          labelPlacement="end"
                          hintText={t(
                            "course:hints.OPTION_PROVIDES_POSSIBILITY_TO_TAKE_TEST_AGAIN_WITHIN_60_MINUTES"
                          )}
                          disabled={publishedTask || !isAuthor || isReadOnly}
                        />
                        {values?.quiz?.allowMultipleAttempts && (
                          <Box>
                            <FormikAutocomplete
                              name="quiz.numberOfAttempts"
                              label={t("course:theme.NUMBER_OF_ATTEMPTS")}
                              options={[
                                { id: 1, name: "1" },
                                { id: 2, name: "2" },
                                { id: 3, name: "3" },
                                { id: 4, name: "4" },
                                { id: 5, name: "5" },
                              ]}
                              clearable={true}
                              disabled={
                                publishedTask || !isAuthor || isReadOnly
                              }
                            />
                            <FormikAutocomplete
                              name="quiz.considerResult"
                              label={t("course:theme.CONSIDER_RESULT")}
                              options={considerationResults}
                              clearable={false}
                              disabled={
                                publishedTask || !isAuthor || isReadOnly
                              }
                            />
                          </Box>
                        )}
                      </>
                    )}
                  <FormikCheckbox
                    name="quiz.displayGrade"
                    label={t("course:theme.DISPLAY_THE_RESULT_TO_STUDENT")}
                    labelPlacement="end"
                    hintText={t(
                      "course:hints.OPTION_PROVIDES_POSSIBILITY_TO_DISPLAY_ASSESSMENT_AND_SCORE"
                    )}
                    disabled={
                      !isAuthor ||
                      disabledByStatus ||
                      isReadOnly ||
                      isCourseAsync
                    }
                  />
                  <FormikCheckbox
                    name="quiz.displayRightAnswers"
                    label={t(
                      "course:theme.DISPLAY_THE_CORRECT_ANSWERS_TO_STUDENT"
                    )}
                    labelPlacement="end"
                    hintText={t(
                      "course:hints.DISPLAY_CORRECT_ANSWERS_SPECIFIED_BY_THE_TEACHER"
                    )}
                    disabled={
                      !isAuthor ||
                      disabledByStatus ||
                      isReadOnly ||
                      isCourseAsync
                    }
                  />
                  {/* Временно скрыть */}
                  {/* <FormikCheckbox
                    name="quiz.textAfterQuiz"
                    label={t("course:theme.DISPLAY_TEXT")}
                    labelPlacement="end"
                    disabled={publishedTask || !isAuthor}
                  /> */}
                </>
              )}
              {values.type === TaskType.TASK && (
                <>
                  {!values.isExcludeTotalMark &&
                    !values.isSelfTest &&
                    gradingSystemId !== 4 && !values.quiz?.applyProctoring && taskFormat !== TaskFormat.QUIZ && (
                      <FormikCheckbox
                        label={t("course:theme.WITHOUT_EVALUATION")}
                        name="isNoMark"
                        isRequired={publishedTask}
                        labelPlacement="end"
                        hintText={t(
                          "course:hints.TASKS_NOT_PROVIDED_FOR_EVALUATION"
                        )}
                        disabled={
                          publishedTask ||
                          !isAuthor ||
                          isReadOnly
                        }
                      />
                    )}
                  {!values.isNoMark &&
                    !values.isSelfTest &&
                    gradingSystemId !== 3 && !values.quiz?.applyProctoring && (
                      <FormikCheckbox
                        label={t("course:theme.EXCLUDE_FROM_FINAL_GRADE")}
                        name="isExcludeTotalMark"
                        isRequired={publishedTask}
                        labelPlacement="end"
                        hintText={t(
                          "course:hints.TASKS_NOT_CONSIDERED_IN_FINAL_GRADE"
                        )}
                        disabled={publishedTask || !isAuthor || isReadOnly}
                      />
                    )}
                </>
              )}
              {!values.isSelfTest && (
                <>
                  {!values.isNoMark && taskGroupOptions.length > 0 && (
                    <FormikAutocomplete
                      name="group.id"
                      label={t("course:task.TASK_GROUP")}
                      options={taskGroupOptions}
                      disabled={publishedTask || !isAuthor}
                    />
                  )}
                  {gradingSystemId === 3 && !values.isNoMark && values && values?.group?.id && (
                    <FormikInput
                      name="point"
                      label={t("course:theme.POINT")}
                      placeholder="0 до 100"
                      disabled={
                        !values.group?.id ||
                        publishedTask ||
                        !isAuthor ||
                        isReadOnly
                      }
                      type="number"
                      min={0}
                      max={100}
                    />
                  )}
                </>
              )}
              {taskFormat !== TaskFormat.QUIZ && (
                <>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "28px",
                      lineHeight: "36px",
                    }}
                  >
                    {t("course:theme.RESPONSE_PRESENTATION_FORMAT")}
                  </Typography>
                  <FormikAutocomplete
                    name="answerType"
                    label={t("course:theme.METHOD_OF_PROVIDING_ANSWERS")}
                    options={
                      taskFormat === TaskFormat.WRITTEN
                        ? writtenAnswerTypes
                        : oralAnswerTypes
                    }
                    clearable={false}
                    disabled={publishedTask || !isAuthor || values.enabledAntiPlagiarism}
                    hintText={(values.answerType === AnswerTypes.OFFLINE) ? t('course:hints.OPTIONS_PROVIDES_ABILITY_WHEN_ADDING_TASK') : undefined}
                  />
                  {values.answerType === AnswerTypes.FILE && (
                    <>
                      <FormikCheckbox
                        name="hasAnswerTypeFileFormats"
                        label={t(
                          "course:theme.SPECIFY_SPECIFIC_FORMATS_TO_DOWNLOAD"
                        )}
                        labelPlacement="end"
                        disabled={publishedTask || !isAuthor || isReadOnly || values.enabledAntiPlagiarism}
                      />
                      {values.hasAnswerTypeFileFormats && (
                        <FormikAutocomplete
                          label={t("course:theme.FORMATS")}
                          name="answerTypeFileFormats"
                          options={fileFormatOptions}
                          multiple
                          valueKey="code"
                          isRequired
                          disabled={publishedTask || !isAuthor || isReadOnly}
                        />
                      )}
                    </>
                  )}
                  {
                    values.answerType === AnswerTypes.VIDEO && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            mt: -1,
                            color: "rgba(142, 142, 147, 1)",
                          }}
                        >
                          {t("common:hints.ACCEPTABLE_FORMATS", {
                            acceptedExtensions: ".mp4, .avi",
                          })}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <FormikInput
                            isRequired
                            name="videoSum"
                            type="number"
                            label={t("course:task:VIDEO_RECORDING_NUMBER")}
                            placeholder={t(
                              "common:placeholders.ENTER_QUANTITY"
                            )}
                            hintText={t(
                              "course:hints.ORAL_TASK_VIDEO_NUMBER_HINT"
                            )}
                            disabled={publishedTask || !isAuthor || isReadOnly}

                          />
                          <FormikInput
                            isRequired
                            name="videoDuration"
                            type="number"
                            adornmentIcon={t("common:MINUTE_SHORT")}
                            label={t("course:task:VIDEO_RECORDING_DURATION")}
                            hintText={t(
                              "course:hints.ORAL_TASK_VIDEO_DURATION_HINT"
                            )}
                            disabled={publishedTask || !isAuthor || isReadOnly}
                          />
                        </Box>
                      </Box>
                    )
                  }
                </>
              )}
            </Box>
            {courseData?.status !== CourseStatus.CLOSED && (
              <Box
                sx={{
                  mt: "100px",
                  display: "flex",
                  justifyContent: "end",
                  gap: 1,
                }}
              >
                {values.status === CourseStatus.SAVED && (
                  <CustomButton
                    backgroundColor={common.fontWhite}
                    fontColor={common.primaryColor}
                    borderRadius="6px"
                    width="98px"
                    height="38px"
                    onClick={submitForm}
                    disabled={
                      !(isValid && dirty) ||
                      !isAuthor ||
                      disabledByStatus ||
                      isReadOnly
                    }
                  >
                    {t("actions.SAVE")}
                  </CustomButton>
                )}
                {values.status === CourseStatus.SAVED && taskId !== "new" && (
                  <CustomButton
                    backgroundColor={common.primaryColor}
                    fontColor={common.fontWhite}
                    borderRadius="6px"
                    width="123px"
                    height="38px"
                    onClick={updateTaskStatus}
                    disabled={(dirty) || !isAuthor}
                  >
                    {t("actions.PUBLISH")}
                  </CustomButton>
                )}
                {values.status !== CourseStatus.SAVED && !isReadOnly && (
                  <CustomButton
                    backgroundColor={common.primaryColor}
                    fontColor={common.fontWhite}
                    borderRadius="6px"
                    width="190px"
                    height="38px"
                    onClick={updateTaskStatus}
                    disabled={
                      !isAuthor || courseData?.status === CourseStatus.CLOSED
                    }
                  >
                    {t("actions.UNPUBLISH")}
                  </CustomButton>
                )}
              </Box>
            )}
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default Settings;
