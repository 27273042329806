import { useEffect, useMemo, useState } from 'react'
import Breadcrumbs from '@components/Breadcrumbs';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import { Box, Typography, useTheme } from '@mui/material';
import CustomButton from '@ui/Button';
import CustomSearch from '@ui/Search';
import { useTranslation } from 'react-i18next';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { CourseStatus, ICourse, TypeCode } from '@interfaces/index';
import AvatarPicture from '@components/AvatarPicture';
import { MaterialReactTable, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import StudyClassList from './StudyClassList';
import { serviceReports } from '@services/reports';
import {
  type MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { formatDateWithTimezone } from '@utils/functions';
import PeriodPopover from './PeriodPopover';
import CustomAutocomplete from '@ui/Autocomplete';

export const AllCourseList = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { t } = useTranslation(["dictionary", "course", "enum", "sideMenu"]);
  const [filter, setFilter] = useState({});
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showStartDateEndDate, setShowStartDateEndDate] = useState<boolean>(false)
  const [hint, setHint] = useState<HTMLElement | null>(null)
  const [courseList, setCourseList] = useState<Array<any>>([]);
  const [count, setCount] = useState<number>(0);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [showCloseIcon, setShowCloseIcon] = useState<boolean>(false)
  const isPeriodOpen = Boolean(hint)
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25, //customize the default page size
  });
  const { common } = useTheme().palette;
  useEffect(() => {
    setBreadcrumbs("report_all_courses", [{
      level: 1,
      page: 'reports',
      label: "sideMenu:REPORTS",
      path: '/reports',
      tabNum: null
    }, {
      level: 2,
      page: 'reports listener',
      label: "course:REPORT_COURSE",
      path: null,
      tabNum: null
    }]);
    // eslint-disable-next-line
  }, [])

  const periodPopoverClose = () => {
    setHint(null);
  };
  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };
  const periodPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHint(event.currentTarget);
  };
  const handleClearDate = () => {
    setFilter((prev) => ({
      ...prev,
      startDate: null,
      endDate: null
    }))
    setHint(null);
    setStartDate(null);
    setEndDate(null);
    setShowStartDateEndDate(false);
  }
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${day}.${month}.${year}`;
  }
  const handleChangeTypeCourseFilter = (value: any) => {
    setFilter({ ...filter, type: value ? value : null });

  }
  const handleChangeStatusCourseFilter = (value: any) => {
    setFilter({ ...filter, status: value ? value : null });
  }
  useEffect(() => {
    if (startDate && endDate && showStartDateEndDate) {
      setFilter((prev) => ({
        ...prev,
        startDate: formatDateWithTimezone(startDate),
        endDate: formatDateWithTimezone(endDate)
      }))
    }

  }, [startDate, endDate, showStartDateEndDate])

  useEffect(() => {
    serviceReports.getAllCourseList(
      {
        pageNumber: pagination.pageIndex,
        size: pagination.pageSize,
        filter,
        sorting: sorting.length > 0 ? { [sorting[0].id]: sorting[0].desc === true ? "desc" : "asc" } : {}
      },
    )
      .then((res) => {
        if (res.status === 200) {
          setCourseList(res.data.dtoList);
          setCount(res.data.totalElements);
        }
      });

  }, [filter, pagination.pageIndex, pagination.pageSize, sorting]);

  const columns = useMemo<any>(
    () => [
      {
        accessorKey: "name",
        header: t("course:COURSE_NAME"),
        size: 370,
        minSize: 190,
        enableSorting: true,
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: '#E8E8E8',
            boxShadow: "5px 0 7px -2px rgba(91, 90, 48, 0.1)",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            backgroundColor: "#FFFFFF",
            boxShadow: "12px 0 8px -1px rgba(91, 90, 48, 0.1)",
          },
        },
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            {row.original.coverFileId ? (
              <AvatarPicture
                id={row.original.coverFileId}
                sxProps={{
                  width: "40px",
                  height: "30px",
                  borderRadius: "2px",
                }}
              />
            ) : (
              <Box
                sx={{
                  backgroundColor: row.original.coverColor,
                  width: "40px",
                  height: "30px",
                  borderRadius: "2px",
                  flexShrink: 0,
                }}
              />
            )}
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              {renderedCellValue}
            </Typography>
          </Box>
        ),
      },
      {
        accessorKey: 'classesCount',
        header: "Потоки",
        enableSorting: true,
        size: 100
      },
      {
        accessorKey: 'listenersCount',
        header: t("enum:LISTENERS"),
        enableSorting: true,
        size: 100
      },
      {
        accessorKey: "status",
        header: t("user:STATUS"),
        size: 160,
        enableResizing: false,
        enableSorting: true,
        Cell: ({ row }) => {
          const course = row.original;
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                background:
                  course.status === CourseStatus.SAVED
                    ? "#E8E8E8"
                    : course.status === CourseStatus.PUBLISHED
                      ? "#EBF6EB"
                      : course.status === CourseStatus.WITHDRAWN
                        ? "#FFF4E4"
                        : "#CFDDFF",
                color:
                  course.status === CourseStatus.SAVED
                    ? "#6B6B6B"
                    : course.status === CourseStatus.PUBLISHED
                      ? "#31AA27"
                      : course.status === CourseStatus.WITHDRAWN
                        ? "#D9B201"
                        : "#2074D6",
                borderRadius: "10px",
                height: "28px",
                width: "fit-content",
                px: 1,
              }}
            >
              {t(`course:${course.status}`)}
            </Box>
          );
        },
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ], [t]);

  const table = useMaterialReactTable({
    columns,
    data: courseList,
    enableSorting: true,
    enableColumnActions: false,
    enableExpandAll: false,
    enableColumnResizing: false,
    enableTopToolbar: false,
    enableRowNumbers: true,
    enableColumnPinning: false,
    enableRowActions: false,
    manualPagination: true,
    manualSorting: true,
    localization: {
      noRecordsToDisplay: t('dictionary:EMPTY'),
      rowsPerPage: t('enum:LINES_PER_PAGE'),
      actions: "",
      expand: "",
      rowNumber: '№',
    },
    displayColumnDefOptions: {
      "mrt-row-expand": {
        size: 30,
        muiTableBodyCellProps: { align: "center" },
      },
      "mrt-row-actions": {
        size: 30,
        muiTableBodyCellProps: { align: "center" },
      },
    },
    layoutMode: "grid",
    columnResizeMode: "onChange",
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        display: "grid",
        borderRadius: "6px",
      },
    },
    muiTableContainerProps: {
      sx: {
        "&::-webkit-scrollbar": { height: "4px", width: "7px" },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#D9D9D9",
          borderRadius: "4px",
        },
      },
    },
    muiTableHeadCellProps: {
      sx: { backgroundColor: '#E8E8E8' },
    },
    muiTableBodyCellProps: {
      sx: { backgroundColor: "#FFFFFF" },
    },
    mrtTheme: (theme) => ({
      draggingBorderColor: theme.palette.common.primaryColor,
    }),
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50, 100],
      shape: "rounded",
      variant: "outlined",
      SelectProps: {
        sx: {
          backgroundColor: "#FFFFFF",
          color: common.primaryColor,
          "& .MuiInputBase-root": {
            "& .MuiSelect-icon": { color: common.primaryColor },
          },
        },
      },
      sx: {
        "& .Mui-selected": {
          background: `${common.primaryColor} !important`,
          color: "#FFFFFF",
        },
      },
    },
    renderDetailPanel: ({ row }) => (
      <StudyClassList potokData={row.original.classList} />
    ),
    onPaginationChange: setPagination,
    state: { pagination, sorting }, //pass the pagination state to the table
    rowCount: count,
    onSortingChange: setSorting,
    paginationDisplayMode: "pages",
  });

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: 'center', mb: '8px', mt: '8px' }}>
        <Breadcrumbs id="report_all_courses" />
      </Box>
      <Box display="flex" justifyContent="space-between" sx={{ mb: "27px", mt: '20px' }}>
        <Typography sx={{ fontWeight: "500", fontSize: "36px", lineHeight: "44px" }}>
          {t("course:REPORT_COURSE")}
        </Typography>
        <CustomButton
          width="fit-content"
          backgroundColor={common.primaryColor}
          borderRadius="6px"
          onClick={() => { }}
        >
          <Typography
            sx={{
              color: common.white,
              display: "flex",
              alignItems: "center",
            }}
          >
            Скачать в Excel
            <FileDownloadOutlinedIcon sx={{ mr: "12px" }} />
          </Typography>
        </CustomButton>
      </Box>
      <Box display='flex' justifyContent='space-between' sx={{ marginBottom: "16px" }}>
        <CustomSearch width='360px' placeholder={t("common:placeholders.SEARCH_BY_COURSE_NAME")} />
        <Box display="flex" gap={2}>
          <CustomAutocomplete
            sx={{ width: { lg: '200px', xs: '100%' } }}
            placeholder={t("course:COURSE_STATUS")}
            name="typeCourse"
            options={[
              // { name: t(`course:${CourseStatus.SAVED}`), id: CourseStatus.SAVED },
              { name: t(`course:${CourseStatus.PUBLISHED}`), id: CourseStatus.PUBLISHED },
              // { name: t(`course:${CourseStatus.WITHDRAWN}`), id: CourseStatus.WITHDRAWN },
              { name: t(`course:${CourseStatus.CLOSED}`), id: CourseStatus.CLOSED }]}
            onChange={(_, value: any) => handleChangeStatusCourseFilter(value)}

          />
          <CustomAutocomplete
            sx={{ width: { lg: '200px', xs: '100%' } }}
            placeholder={t("course:COURSE_TYPE")}
            name="typeCourse"
            options={[{ text: TypeCode.EDU, id: TypeCode.EDU }, { text: TypeCode.FREE, id: TypeCode.FREE }]}
            onChange={(_, value: any) => handleChangeTypeCourseFilter(value)}

          />
          <Box sx={{
            border: `1px solid ${common.borderSecondary}`,
            height: '40px',
            width: showStartDateEndDate ? '235px' : 'auto',
            backgroundColor: !isPeriodOpen ? 'white' : common.primaryColor,
            color: isPeriodOpen ? 'white' : 'black',
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            px: '10px',
            cursor: 'pointer',

          }}
            onMouseEnter={() => startDate && endDate && setShowCloseIcon(true)}
            onMouseLeave={() => setShowCloseIcon(false)}
            onClick={periodPopoverOpen}
          >
            <Typography sx={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              color: isPeriodOpen ? 'white' : 'black',
              mx: '8px',
            }}>
              {
                (startDate && endDate && showStartDateEndDate) ? `${formatDate(startDate)} ${'\u2014'} ${formatDate(endDate)}` : t('common:PERIOD')
              }
            </Typography>
            {showCloseIcon ?
              <CloseIcon
                sx={{}}
                onClick={handleClearDate}
              /> :
              <CalendarMonthOutlinedIcon />
            }
          </Box>
        </Box>
        <PeriodPopover
          isPeriodOpen={isPeriodOpen}
          startDate={startDate}
          endDate={endDate}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          hint={hint}
          periodPopoverClose={periodPopoverClose}
          handleShowDate={() => {
            setShowStartDateEndDate(true)
            periodPopoverClose()
          }}
        />
      </Box>
      <MaterialReactTable table={table} />
    </Box>
  )
}
