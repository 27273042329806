import { useMemo, useState } from "react";

const useLocalPagination = <T extends Record<string, any>>() => {
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0)
  const [data, setData] = useState<T[]>([])

  const setInitData = (data: T[]) => {
    setPage(0);
    setData(data);
    setTotalElements(data.length);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = useMemo(() => {
    return data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  }, [page, data, rowsPerPage])

  return {
    handleChangePage, handleChangeRowsPerPage,
    rowsPerPage, page, totalElements,
    data: paginatedData, setInitData
  }
}

export default useLocalPagination;