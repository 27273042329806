import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import Golos_Text_Regular from "@assets/fonts/Golos-Text_Regular.woff2";
import Golos_Text_Medium from "@assets/fonts/Golos-Text_Medium.woff2";
import Golos_Text_DemiBold from "@assets/fonts/Golos-Text_DemiBold.woff2";
import Golos_Text_Bold from "@assets/fonts/Golos-Text_Bold.woff2";

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#9469E3",
      light: 'rgba(148, 105, 227, 1)',
      dark: 'rgba(148, 105, 227, 1)',
      contrastText: "#FFFFFF"
    },
    secondary:
    {
      main: "#F6F6F6",
      contrastText: '#9469E3',
      light: '#F6F6F6',
      dark: '#F6F6F6',
    },
    mode: "light",
    common: {
      primaryColor: "#9469E3",
      secondaryColor: "#E4D4FF",
      errorColor: "#FF0000",
      greenColor: "#30BE71",
      fontPrimary: "#000000",
      fontWhite: "#FFFFFF",
      fontGray: "#A49D9D",
      rootBgPrimary: "#F9FAFB",
      rootBgSecondary: "#F9F9F9",
      headerBgPrimary: "#FFFFFF",
      footerBgPrimary: "#1A0D50",
      strokePrimary: "#D2D5DA",
      strokeSecondary: "#E5E0EB",
      borderPrimary: "#B395E9",
      borderSecondary: "#CECECE",
      inputDisabledBg: "#E7E7E7",
      tableHeaderColor: "#FBF9FF",
      btnCancel: "#F6F6F6",
    },
  },
  typography: {
    fontFamily: '"Golos Text", sans-serif',
    h1: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22.4px",
      "@media (max-width:480px)": {
        fontSize: "14px",
        lineHeight: "16.8px"
      },
    },
    h2: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20.8px",
      color: "#6D737A",
      "@media (max-width:480px)": {
        fontSize: "14px",
        lineHeight: "16.8px"
      },
    },
    subtitle1: {
      fontSize: "36px",
      fontWeight: 500,
      lineHeight: "44px",
    },
    subtitle2: {
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "28.8px",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Golos';
          font-weight: 400;
          src: url(${Golos_Text_Regular}) format("truetype");
        }
        @font-face {
          font-family: 'Golos';
          font-weight: 500;
          src: url(${Golos_Text_Medium}) format("truetype");
        }
        @font-face {
          font-family: 'Golos';
          font-weight: 600;
          src: url(${Golos_Text_DemiBold}) format("truetype");
        }
        @font-face {
          font-family: 'Golos';
          font-weight: 700;
          src: url(${Golos_Text_Bold}) format("truetype");
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid #E8E8E8",
          borderRadius: "12px"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': 'rgba(0, 0, 0, 0.23)',
          '--TextField-brandBorderHoverColor': '#9469E3',
          '--TextField-brandBorderFocusedColor': '#9469E3',
          '& label.Mui-focused': {
            color: 'var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'var(--TextField-brandBorderColor)',
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'var(--TextField-brandBorderHoverColor)',
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&::before, &::after': {
            borderBottom: '2px solid var(--TextField-brandBorderColor)',
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
          },
          '&.Mui-focused:after': {
            borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&::before': {
            borderBottom: 'transparent',
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
          },
          '&.Mui-focused:after': {
            borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
  },
});
