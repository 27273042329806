import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@components/Breadcrumbs';
import Statistics from './Statistics';
import RecipientList from './RecipientList';
import { ITask } from '@interfaces/index';
import { serviceTask } from '@services/task';
import UnhiddenHints from '@components/UnhiddenHints';
import { useTranslation } from 'react-i18next';

const TaskRecipients = () => {
  const { courseId, taskId, flowId } = useParams<{ courseId: string; taskId: string, flowId: string }>();
  const [task, setTask] = useState<ITask>();
  const [allListeners, setAllListeners] = useState<number | null>(null);
  const [completedListeners, setCompletedListeners] = useState<number | null>(null);
  const { t } = useTranslation(["course"]);

  useEffect(() => {
    if (taskId && flowId) {
      serviceTask.getTaskInfo(taskId).then(res => {
        setTask(res.data);
      });

      serviceTask.getAssessmentSumListenerTaskId(taskId, flowId).then(res => {
        setAllListeners(res.data.all);
        setCompletedListeners(res.data.completed);
      })
    }
  }, [flowId, taskId]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >

      <Box
        sx={{
          display: 'flex',
          height: 'fit-content'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'fit-content'
          }}
        >
          <Breadcrumbs id={courseId} />
          <Typography sx={{ fontSize: 36, lineHeight: "44px" }}>{task?.name}</Typography>
        </Box>
        <Statistics all={allListeners} completed={completedListeners} taskFormat={task?.format} markable={!task?.isNoMark} />
      </Box>
      {task?.isNoMark &&
        <UnhiddenHints text={t('course:task.TASK_WITHOUT_EVALUATION')} />
      }
      {task?.isExcludeTotalMark &&
        <UnhiddenHints text={t('course:task.TASK_NOT_TAKEN_INTO_ACCOUNT_IN_FINAL_GRADE')} />
      }
      <RecipientList markable={!task?.isNoMark} excluded={task?.isExcludeTotalMark} />
    </Box>
  );
}

export default TaskRecipients;