import { useParams } from 'react-router-dom';

import CurrentCoursePage from './CurrentCourses';
import PlannedCoursePage from '@pages/freeCourse/courseDetails';
import CompletedCoursePage from './CompletedCourses';
import LoadingSpinner from '@components/LoadingSpinner';

const CourseInfo = () => {
  const { status } = useParams();

  if (status === "current")
    return (
      <CurrentCoursePage />
    );
  if (status === "planned" || !status)
    return (
      <PlannedCoursePage />
    );
  if (status === "completed")
    return (
      <CompletedCoursePage />
    );

  return (
    <LoadingSpinner />
  );
}

export default CourseInfo;