import { IMeeting } from "@interfaces/index";
import { createApi } from "@reduxjs/toolkit/dist/query";
import { rtkApi } from "@store/rtkApi";

export const meetingServiceApi=rtkApi.injectEndpoints({

    endpoints:(build)=>({
        getMeetingById:build.query<IMeeting,string>({
            query:(id)=>({
                url:`course/meeting/${id}`
            })
        }),
        getMeetingFindOne:build.query<IMeeting,{taskId:string,classId:string}>({
            query:({taskId,classId})=>({
                url:`course/meeting/find-one?taskId=${taskId}&classId=${classId}`
            }),
            keepUnusedDataFor:0
        })
    })
})

export const {useGetMeetingByIdQuery,useGetMeetingFindOneQuery}=meetingServiceApi