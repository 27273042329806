import React, { useEffect, useState } from 'react'
import CustomModal from '@components/Modal'
import { Box, Checkbox, Divider, Tab, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';
import CustomSearch from '@ui/Search';
import { TabList } from '@mui/lab';
import TabContext from "@mui/lab/TabContext";
import { CourseStatus } from '@interfaces/index';
import CustomButton from '@ui/Button';
import { serviceAntiPlagiarism } from '@services/antiPlagiarism';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import AvatarPicture from '@components/AvatarPicture';
import { ICourseListToAddAntiPlagiarism } from './models';
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';

export const CourseListModal = ({ open, handleToggleModal, setMessage, setMessageOpen, setMessageType }) => {
  const { t } = useTranslation(["sideMenu", "common", "dictionary", "antiPlagiarism", "enum"]);
  const { common } = useTheme().palette;
  const [courseList, setCourseList] = useState<ICourseListToAddAntiPlagiarism[] | null>(null)
  const [searchName, setSearchName] = useState<string>("")
  const queryClient = useQueryClient()
  const [courseStatus, setCourseStatus] = useState<string>("ALL")

  const statusList = [
    {
      id: 1,
      label: t("course:ALL"),
      code: "ALL",
    },
    {
      id: 2,
      label: t("course:SAVED_MULTIPLE"),
      code: 'SAVED',
    },
    {
      id: 3,
      label: t("course:PUBLISHED_MULTIPLE"),
      code: 'PUBLISHED',
    },
    {
      id: 4,
      label: t("course:WITHDRAWN_MULTIPLE"),
      code: 'WITHDRAWN',
    }
  ];
  const { data: allCourseListToAdd } =
    useQuery({
      queryKey: ['getAllCourseListToAdd', courseStatus, searchName],
      queryFn: () => serviceAntiPlagiarism.getCourseListToADD({ search: searchName, status: courseStatus === "ALL" ? null : courseStatus }),
    });

  const { mutate: handleSave } = useMutation({
    mutationFn: () => {
      return serviceAntiPlagiarism.AddCourseAntiPlagiarism(courseList?.filter((course) => course.enabled === true));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['courseListAntiPlagiarism'] })
      handleToggleModal()
      setMessage(t("antiPlagiarism:SUCCESSFULLY_CONNECTED_ANTIPLAGIARISM"));
      setMessageOpen(true);
      setMessageType("success");
      setTimeout(() => {
        setMessageOpen(false);
      }, 3000);
    },
  })

  useEffect(() => {
    setCourseList(allCourseListToAdd?.data)
  }, [allCourseListToAdd])

  const handleChangeChecked = (course: ICourseListToAddAntiPlagiarism, checked: boolean) => {
    if (courseList)
      setCourseList(courseList.map((node) => {
        return course.courseId === node.courseId ? { ...course, enabled: checked } : { ...node }
      }))
  }

  return (
    <CustomModal
      open={open}
      handleToggleModal={handleToggleModal}
      closeIsControlled
      showCloseIcon
      width="620px"
      bgColor={common.white}
      px={0}
      py={2}
    >
      <Box>
        <Box position="sticky" top={0} bgcolor={common.white} padding="0px 24px">
          <Typography fontSize="20px" fontWeight={500}>{t("antiPlagiarism:CONNECTING_COURSE_ANTIPLAGIARISM")}</Typography>
          <Divider sx={{ margin: "16px 0px" }} />
          <CustomSearch placeholder={t("common:placeholders.SEARCH_BY_NAME")} onChange={(value) => setSearchName(value)} />
          <TabContext
            value={
              courseStatus
            }
          >
            <TabList
              onChange={(_, value: string) => { setCourseStatus(value) }}
              sx={{
                "& .MuiTab-root": {
                  minHeight: "34px",
                },
                "& .MuiTabs-flexContainer": {
                  gap: 1,
                },
                display: "flex",
                alignItems: "center",
                marginTop: "12px",
                marginBottom: "20px"
              }}
              TabIndicatorProps={{
                style: {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  backgroundColor: common.secondaryColor,
                  opacity: 0.15,
                  border: `1px solid ${common.borderPrimary}`,
                  borderRadius: "6px",
                },
              }}
            >
              {statusList.map((status) => {
                return (
                  <Tab
                    key={status.code}
                    label={status.label}
                    value={status.code}
                    sx={{
                      fontSize: "14px",
                      color: "#686B81",
                      width: "auto",
                      height: "40px",
                      borderRadius: "6px",
                      backgroundColor: "#FFFFFF",
                      textTransform: "capitalize",
                      lineHeight: "14px",
                      transition: "transform 1s",
                      "&:hover": {
                        backgroundColor: "#F5EFFF",
                      },
                      "&.Mui-selected": {
                        backgroundColor: common.primaryColor,
                        color: "#FFFFFF",
                      },
                    }}
                  />
                );
              })}
            </TabList>
          </TabContext>
        </Box>
        <Box height="50vh" sx={{
          paddingLeft: "24px",
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '7px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#E5E0EB',
            borderRadius: '6px'
          }
        }} >
          {courseList && courseList?.length > 0 ? <Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Checkbox
                size="small"
                checked={courseList.length === courseList.filter((course) => course.enabled === true).length ? true : false}
                onChange={(event) => courseList && setCourseList(courseList.map((course) => ({ ...course, enabled: event.target.checked })))}
                sx={{ color: common.primaryColor, "&.Mui-checked": { color: common.primaryColor } }}
              />
              <Typography>{t("antiPlagiarism:CHECK_ALL")}</Typography>
            </Box>
            {courseList.map((course, index) => {
              return (
                <Box display="flex" alignItems="center" justifyContent="space-between" mr="18px" mb="8px" key={course.courseId}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Checkbox
                      size="small"
                      checked={course.enabled || false}
                      onChange={(event) => handleChangeChecked(course, event.target.checked)}
                      sx={{ color: common.primaryColor, "&.Mui-checked": { color: common.primaryColor } }}
                    />

                    {course?.coverFileId ? (
                      <AvatarPicture
                        id={course.coverFileId}
                        sxProps={{
                          width: "40px",
                          height: "30px",
                          borderRadius: "2px",
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          backgroundColor: course?.coverColor,
                          width: "40px",
                          height: "30px",
                          borderRadius: "2px",
                          flexShrink: 0,
                        }}
                      />
                    )}
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      {course?.name.length > 40 ? course?.name.substring(0, 40) + "..." : course.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      background:
                        course.status === CourseStatus.SAVED
                          ? "#E8E8E8"
                          : course.status === CourseStatus.PUBLISHED
                            ? "#EBF6EB"
                            : course.status === CourseStatus.WITHDRAWN
                              ? "#FFF4E4"
                              : "#FFC5D8",
                      color:
                        course.status === CourseStatus.SAVED
                          ? "#6B6B6B"
                          : course.status === CourseStatus.PUBLISHED
                            ? "#31AA27"
                            : course.status === CourseStatus.WITHDRAWN
                              ? "#D9B201"
                              : "#FD0053",
                      borderRadius: "10px",
                      height: "28px",
                      width: "fit-content",
                      px: 1,
                    }}
                  >
                    {t(`course:${course.status}`)}
                  </Box>
                </Box>
              )
            })}
          </Box> :
            <Box width="230px" display="flex" flexDirection="column" alignItems="center" margin="25% auto">
              <Box sx={{ width: "80px", height: "80px", border: `1px solid #E8E8E8`, background: "#F9FAFB", borderRadius: "50%", display: "flex", alignItems: "center", mb: "16px" }}>
                <DataUsageOutlinedIcon sx={{ margin: "0 auto", color: "#6D737A", fontSize: "32px" }} />
              </Box>
              <Typography>{t("antiPlagiarism:INFOIRMATION_COURSES_AVAILABLE")}</Typography>
            </Box>}

        </Box>
        <Box position="sticky" bottom={0} bgcolor={common.white} padding="24px">
          <Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }} gap={1.5}>
              <CustomButton
                width="92px"
                height="40px"
                borderRadius="8px"
                backgroundColor="#F6F6F6"
                fontColor={common.primaryColor}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => handleToggleModal()}
              >
                {t('course:task.CANCELLATION')}
              </CustomButton>
              <CustomButton
                width="148px"
                height="40px"
                borderRadius="8px"
                backgroundColor={common.primaryColor}
                fontColor="#FFFFFF"

                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => handleSave()}
              >
                {t('common:actions.CONNECT')}
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </Box>

    </CustomModal>
  )
}