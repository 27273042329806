import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Formik, Form as FormikForm } from "formik";
import Box from "@mui/material/Box";
import { AlertColor } from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import NewspaperRoundedIcon from '@mui/icons-material/NewspaperRounded';

import { serviceCourse } from "@services/course";
import { serviceDict } from "@services/dictionary";
import { StateSchema } from "@store/StateSchema";
import { CourseContext } from "@context/CourseContext";
import SaveChangesModal from "@components/modals/SaveChangesModal";
import CustomButton from "@ui/Button";
import FormikAutocomplete from "@ui/formik/Autocomplete";
import FormikCheckbox from "@ui/formik/Checkbox";
import {
  ICourse,
  ICourseSetting,
  IDictionary,
  IGradingSystem,
  IStudyClass,
  CourseStatus,
  TypeCode,
  DictionaryCode,
  CourseFormatType,
  DurationType,
} from "@interfaces/index";
import { contentAccessExpirationTypes, courseDurations, courseFormats } from "@utils/enums";
import { formatDateWithTimezone } from "@utils/functions";
import { earliestDate, latestDate } from "./lib";
import { validationSchemaCommonEdu, validationSchemaCommonFree } from "./validation";
import { Typography } from "@mui/material";
import FormikInput from "@ui/formik/Input";
import FormikRadioGroup from "@ui/formik/RadioGroup";
import CustomModal from "@components/Modal";
import Certificate from "@pages/certificate/ui/Certificate/Certificate";
import { serviceFile } from "@services/file";
import { ICertificateData, initialCertificateData } from "@pages/courses/models";

interface Props {
  courseInfo: ICourse;
  setCourseInfo: (course: ICourse) => void;
  setMessageOpen: (isOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  setMessage: (message: string) => void;
}

export const CommonSetting = ({
  courseInfo,
  setCourseInfo,
  setMessageOpen,
  setMessageType,
  setMessage,
}: Props) => {
  const { t, i18n } = useTranslation([
    "common",
    "dictionary",
    "enum",
    "course"
  ]);
  const { common } = useTheme().palette;
  const { courseData, setCourseData } = useContext(CourseContext);
  const [courseSetting, setCourseSetting] = useState<ICourseSetting>(courseInfo.courseSetting);
  const profileId = useSelector((store: StateSchema) => store.account.profileInfo.pltaId);
  const orgId = useSelector((store: any) => store.account.profileInfo.authorityInfo.orgId);
  const dictionaryData = useSelector((store: any) => store.dictionary.data);
  const [orgLogo, setOrgLogo] = useState<string | null>(null)
  const [orgLogoSign, setOrgLogoSign] = useState<string | null>(null)
  const { courseId } = useParams();
  const [languageList, setLanguageList] = useState([]);
  const [subdivisionList, setSubdivisionList] = useState<IDictionary[]>([]);
  const [eduProgramList, setEduProgramList] = useState<IDictionary[]>([]);
  const [gradingSystemList, setGradingSystemList] = useState([]);
  const [flows, setFlows] = useState<IStudyClass[]>([]);
  const isSaved = courseInfo.status === CourseStatus.SAVED;
  const isWithdrawn = courseInfo.status === CourseStatus.WITHDRAWN;
  const isPublished = courseInfo.status === CourseStatus.PUBLISHED;
  const isClosed = courseInfo.status === CourseStatus.CLOSED;
  const isCourseFree = courseInfo.typeCode === TypeCode.FREE;
  const disabledByRole = courseInfo?.authors?.some((author) => author.userId === profileId);
  const [disabledGradingSystemOptions, setDisabledGradingSystemOptions] =
    useState<string[]>([]);
  const [disabledSubdivisionOptions, setDisabledSubdivisionOptions] = useState<
    string[]
  >([]);
  const [disabledOptions, setDisabledOptions] = useState<
    Array<string | number>
  >([]);
  const currentDate = formatDateWithTimezone(new Date());
  const filteredFlowData = flows
    .map((item) => ({
      startDate: item.startDate !== null ? item.startDate : "",
      endDate: item.endDate !== null ? item.endDate : "",
    }))
    .filter((item) => item.startDate !== "" && item.endDate !== "");
  const [showCertificate, setShowCertificate] = useState<boolean>(false);
  const initialCertData: ICertificateData = {
    ...initialCertificateData,
    courseName: courseData?.courseName || initialCertificateData.courseName,
    certificateTemplateDto: {
      ...initialCertificateData.certificateTemplateDto,
      organizationDirectorName: dictionaryData?.directorFullName || initialCertificateData.certificateTemplateDto.organizationDirectorName,
      organizationName: i18n.language === 'ru' ? dictionaryData?.nameRu : i18n.language === 'kz' ? dictionaryData?.nameKz : dictionaryData?.nameEn,
    },
  };

  function sortByDisabled(a: IDictionary, b: IDictionary) {
    if (a.isDisabled && !b.isDisabled) {
      return 1;
    } else if (!a.isDisabled && b.isDisabled) {
      return -1;
    }
    return 0;
  }

  const fetchArrayFlow = useCallback(async () => {
    if (courseId) {
      try {
        const { data } = await serviceCourse.getAllStudyClassesByCourseId(
          courseId
        );
        data && setFlows(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, [courseId, setFlows]);

  const fetchDictList = async () => {
    const [languageRes, eduProgramRes] = await Promise.all([
      serviceDict.getDictListForDropdown(DictionaryCode.LANGUAGE),
      serviceDict.getDictListForDropdown(DictionaryCode.EDU_PROGRAM),
    ]);
    if (languageRes.status === 200) {
      setLanguageList(languageRes.data);
    }
    if (eduProgramRes.status === 200) {
      setEduProgramList(eduProgramRes.data);
    }
  };

  useEffect(() => {
    setDisabledOptions(courseSetting.eduProgramIds);
  }, [courseSetting.eduProgramIds]);

  useEffect(() => {
    if (courseInfo.typeCode === TypeCode.FREE) {
      setCourseSetting((prev) => ({ ...prev, gradingSystemId: 4 }));
    }
  }, [courseInfo.typeCode]);

  useEffect(() => {
    if (courseData?.branchId || courseData?.branchId === 0) {
      serviceDict
        .getDictionaryListByOrgIdForDropdown(courseData.branchId, DictionaryCode.SUBDIVISION)
        .then((res) => {
          if (res.status === 200) {
            setSubdivisionList(res.data.sort(sortByDisabled));
            setDisabledSubdivisionOptions(
              res.data
                .filter((subdivision: IDictionary) => subdivision.isDisabled)
                .map((subdivision: IDictionary) => subdivision.id)
            );
          }
        });

      serviceDict.getGradingSystemList(courseData.branchId).then((res) => {
        if (res.status === 200) {
          setGradingSystemList(res.data.filter((gSystem: IGradingSystem) => gSystem.isActive));
          setDisabledGradingSystemOptions(
            res.data
              .filter((gSystem: IGradingSystem) => !gSystem.isActive)
              .map((gs: IGradingSystem) => gs.id)
          );
        }
      });
    }
  }, [courseData?.branchId]);

  useEffect(() => {
    fetchDictList();
  }, []);

  useEffect(() => {
    fetchArrayFlow();
  }, [fetchArrayFlow]);

  useEffect(() => {
    if(showCertificate) {
      serviceCourse.getOrgCertificate(orgId).then((res) => {
        if(res.status === 200) {
          serviceFile.getFileInBytes(res.data.organizationLogoId).then(response => {
            if(response.status === 200) setOrgLogo(response.data.bytes)
          });
          serviceFile.getFileInBytes(res.data.organizationSignId).then(response => {
            if(response.status === 200) setOrgLogoSign(response.data.bytes)
          });
        }
      })
    }
  }, [orgId, showCertificate])

  const EDUSubdivisionAndTargetAudience = () => {
    return (
      <>
        <FormikAutocomplete
          label={t("enum:SUBDIVISION")}
          name="subdivisionIds"
          options={subdivisionList}
          disabledOptions={
            !disabledByRole ||
              isClosed ||
              !(
                currentDate &&
                new Date(currentDate) <=
                new Date(latestDate(filteredFlowData)?.endDate ?? new Date())
              )
              ? courseSetting.subdivisionIds
              : disabledSubdivisionOptions
          }
          multiple
          selectAll
          sx={{ width: "50%" }}
          disabled={
            !disabledByRole ||
            isClosed ||
            !!courseInfo.externalId ||
            !(
              currentDate &&
              new Date(currentDate) <=
              new Date(latestDate(filteredFlowData)?.endDate ?? new Date())
            )
          }
        />
        {eduProgramList.length > 0 && (
          <FormikAutocomplete
            label={t("dictionary:TARGET_AUDIENCE_OF_THE_COURSE")}
            name="eduProgramIds"
            options={eduProgramList}
            disabledOptions={isPublished ? disabledOptions : []}
            multiple
            selectAll
            sx={{ width: "50%" }}
            disabled={
              !(isSaved || isWithdrawn) ||
              !disabledByRole ||
              isClosed ||
              !!courseInfo.externalId ||
              isPublished
            }
          />
        )}
      </>
    );
  };

  const EDUFormikList = (values: ICourseSetting, setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void) => {
    return (
      <>
        {/* {showCanTutorEditCheckbox && (
          <FormikCheckbox
            label={t("course:PROHIBIT_TEACHERS_TO_EDIT_COURSE_SETTINGS")}
            name="canTutorEdit"
            labelPlacement="end"
            disabled={
              (isSaved && isWithdrawn) ||
              !disabledByRole ||
              isClosed ||
              isPublished
            }
            hintText={t(
              "course:hints.OPTION_RESTRICTS_TEACHERS_ACCESS_TO_SETTINGS_SECTION"
            )}
          />
        )} */}
        <FormikCheckbox
          label={t("course:IT_IS_POSSIBLE_TO_ISSUE_A_CERTIFICATE_OF_COMPLETION_OF_THE_COURSE")}
          name="isProvidedCertificate"
          onChange={(_, checked) => {
            if (!checked) {
              setFieldValue('certificateDisplay', null);
            } else {
              setFieldValue('certificateDisplay', 'CREDITS')
            }
          }}
          labelPlacement="end"
          disabled={
            !(isSaved || isWithdrawn) ||
            !disabledByRole ||
            isClosed ||
            isPublished
          }
          hintText={t(
            "course:hints.THE_OPTION_DETERMINES_ISSUE_CERTIFICATE_AFTER_COMPLETION"
          )}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            pl: 4,
            mt: "-16px"
          }}
        > 
          <Box
            sx={{
              display: "flex",
              gap: 1,
              cursor: "pointer"
            }}
            onClick={() => setShowCertificate(true)}
          >
            <Typography color={`${common.primaryColor}`}>{t('course:VIEW_CERTIFICATE')}</Typography>
            <NewspaperRoundedIcon sx={{ color: common.primaryColor }} />
          </Box>
        </Box>
        <FormikCheckbox
          label={t("course:ALLOW_ASSIGNMENTS_GRADED_AFTER_COURSE_COMPLETION")}
          name="allowEstimateAfterCompletion"
          labelPlacement="end"
          disabled={
            !(isSaved || isWithdrawn) ||
            !disabledByRole ||
            isClosed ||
            isPublished
          }
        />
        {/* <FormikCheckbox
          label={t("course:SEQUENTIAL_STUDY_OF_TOPICS_FOR_STUDENTS")}
          name="isSequentialTopicStudy"
          labelPlacement="end"
          disabled={
            !(isSaved || isWithdrawn) ||
            !disabledByRole ||
            isClosed ||
            isPublished
          }
        /> */}
        <FormikCheckbox
          label={t(
            "course:PROHIBIT_LISTENERS_FROM_VIEWING_COURSE_AFTER_COMPLETION"
          )}
          name="canStudentViewAfterEnd"
          labelPlacement="end"
          disabled={
            !(isSaved || isWithdrawn) ||
            !disabledByRole ||
            isClosed ||
            isPublished
          }
          hintText={t(
            "course:hints.OPTION_RESTRICTS_LISTENERS_ACCESS_TO_VIEW_CONTENT_AFTER_COMPLETION"
          )}
        />
        {values.canStudentViewAfterEnd && (
          <FormikAutocomplete
            name="contentAccessExpiration"
            label={t("course:CLOSE_ACCESS_TO_COURSE_CONTENT")}
            options={contentAccessExpirationTypes}
            isRequired
            clearable={false}
            sx={{ width: "50%" }}
            hintText={t("course:hints.THIS_OPTION_ALLOWS_YOU_TO_SET_A_TIME_LIMIT_FOR_ACCESS_TO_COURSE_CONTENT")}
            disabled={!disabledByRole || isClosed}
          />
        )}
      </>
    );
  };

  const FREEFormatAndDurationCourse = (values: ICourseSetting, setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void) => {
    return (
      <>
        {courseInfo.typeCode === TypeCode.FREE && (
          <>
            <FormikAutocomplete
              name="formatType"
              label={t("course:COURSE_FORMAT")}
              options={courseFormats}
              isRequired
              clearable={false}
              sx={{ width: "50%" }}
              disabled={
                !(isSaved || isWithdrawn) ||
                !disabledByRole ||
                isClosed ||
                isPublished
              }
              onChange={(value: { id: string; text: string }) => {
                if (value.id === CourseFormatType.ASYNC) {
                  setFieldValue("finalGradeDisplayMethod", "MANUAL");
                } else {
                  setFieldValue("durationType", DurationType.PERIOD);
                  setFieldValue("finalGradeDisplayMethod", "MANUAL");
                }
              }}
            />
            <FormikAutocomplete
              name="durationType"
              label={t("course:COURSE_DURATION")}
              options={courseDurations}
              isRequired
              clearable={false}
              sx={{ width: "50%" }}
              disabled={
                !(isSaved || isWithdrawn) ||
                !disabledByRole ||
                isClosed ||
                isPublished || values.formatType === CourseFormatType.SYNC
              }
              onChange={(value: { id: string; text: string }) => {
                if(value.id === DurationType.INDEFINITE) setFieldValue('canStudentViewAfterEnd', false)
              }}
            />
          </>
        )}
      </>
    );
  };

  const FREEFormikList = (
    values: ICourseSetting, setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void) => {
    return (
      <>
        <FormikCheckbox
          label={t("course:IT_IS_POSSIBLE_TO_ISSUE_A_CERTIFICATE_OF_COMPLETION_OF_THE_COURSE")}
          name="isProvidedCertificate"
          onChange={(_, checked) => {
            if (!checked) {
              setFieldValue('hours', null);
              setFieldValue('credits', null);
              setFieldValue('certificateDisplay', null);
            }
          }}
          labelPlacement="end"
          disabled={
            !(isSaved || isWithdrawn) ||
            !disabledByRole ||
            isClosed ||
            isPublished
          }
          hintText={t(
            "course:hints.THE_OPTION_DETERMINES_ISSUE_CERTIFICATE_AFTER_COMPLETION"
          )}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            pl: 4,
            mt: "-16px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              cursor: "pointer"
            }}
            onClick={() => setShowCertificate(true)}
          >
            <Typography color={`${common.primaryColor}`}>{t('course:VIEW_CERTIFICATE')}</Typography>
            <NewspaperRoundedIcon sx={{ color: common.primaryColor }} />
          </Box>
          {values.isProvidedCertificate && <>
            <Box
              sx={{
                display: "flex",
                gap: 1
              }}
            >
              <Box sx={{ width: '20%' }}>
                <FormikInput
                  label={t('course:STUDY_HOURS')}
                  name="hours"
                  type="number"
                  isRequired
                  handleChange={(e) => {
                    if (Number(e.target.value) < 30) {
                      setFieldValue('certificateDisplay', "HOURS")
                    }
                    setFieldValue(
                      'credits',
                      Number(e.target.value) >= 30 ? Math.floor(Number(e.target.value) / 30) : null
                    );
                  }}
                />
              </Box>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "end",
                  color: "#ABA7AF",
                  fontSize: "24px",
                  maxHeight: '70px'
                }}
              >=</Typography>
              <Box sx={{ width: '20%' }}>
                <FormikInput
                  label={t('course:CREDITS')}
                  name="credits"
                  type="number"
                  isRequired
                  handleChange={(e) => {
                    const { value } = e.target;
                    setFieldValue('hours', value ? Number(value) * 30 : null);
                  }}
                />
              </Box>
            </Box>
            <Typography color="#ABA7AF">{t('common:CERTAIN_HOURS', { value: '30' })} = 1 {t('common:CREDIT')}</Typography>
            <FormikRadioGroup
              groupLabel={t('course:DISPLAY_ON_CERTIFICATE')}
              labelPosition="top"
              name="certificateDisplay"
              isRequired
              disabled={Number(values.hours) < 30}
              options={[
                { label: t('course:CREDITS'), value: "CREDITS", hintText: Number(values.hours) < 30 ? t('course:CONDITION_TO_SHOW_CREDIT') : undefined },
                { label: t('common:HOURS'), value: "HOURS" },
              ]}
            />
          </>}
        </Box>
        <FormikCheckbox
          label={t("course:ALLOW_ASSIGNMENTS_GRADED_AFTER_COURSE_COMPLETION")}
          name="allowEstimateAfterCompletion"
          labelPlacement="end"
          disabled={
            !(isSaved || isWithdrawn) ||
            !disabledByRole ||
            isClosed ||
            isPublished
          }
        />
        {/* <FormikCheckbox
          label={t("course:SEQUENTIAL_STUDY_OF_TOPICS_FOR_STUDENTS")}
          name="isSequentialTopicStudy"
          labelPlacement="end"
          disabled={
            !(isSaved || isWithdrawn) ||
            !disabledByRole ||
            isClosed ||
            isPublished
          }
        /> */}
        <FormikCheckbox
          label={t("course:PROHIBIT_LISTENERS_FROM_VIEWING_COURSE_AFTER_COMPLETION")}
          name="canStudentViewAfterEnd"
          labelPlacement="end"
          onChange={() => setFieldValue('contentAccessExpiration', null)}
          disabled={
            !(isSaved || isWithdrawn) ||
            !disabledByRole ||
            isClosed ||
            isPublished || values.durationType === DurationType.INDEFINITE
          }
          hintText={t(
            "course:hints.OPTION_RESTRICTS_LISTENERS_ACCESS_TO_VIEW_CONTENT_AFTER_COMPLETION"
          )}
        />
        {values.canStudentViewAfterEnd && (
          <FormikAutocomplete
            name="contentAccessExpiration"
            label={t("course:CLOSE_ACCESS_TO_COURSE_CONTENT")}
            options={contentAccessExpirationTypes}
            isRequired
            clearable={false}
            sx={{ width: "50%" }}
            hintText={t("course:hints.THIS_OPTION_ALLOWS_YOU_TO_SET_A_TIME_LIMIT_FOR_ACCESS_TO_COURSE_CONTENT")}
            disabled={
              !disabledByRole ||
              isClosed ||
              !(
                currentDate &&
                new Date(currentDate) <=
                new Date(
                  earliestDate(filteredFlowData)?.startDate ?? new Date()
                )
              )
            }
          />
        )}
      </>
    );
  };

  const renderEDUComponents = (
    values: ICourseSetting,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (courseInfo.typeCode === TypeCode.EDU) {
      return (
        <>
          {EDUSubdivisionAndTargetAudience()}
          <FormikAutocomplete
            label={t("course:GRADING_SYSTEM")}
            name="gradingSystemId"
            options={gradingSystemList}
            disabledOptions={disabledGradingSystemOptions}
            clearable={false}
            sx={{ width: "50%" }}
            isGradingSystemVisible={!!values.gradingSystemId}
            gradingSystemId={Number(values.gradingSystemId)}
            onChange={(value) => {
              if (value.id === 3) {
                setFieldValue("zeroMarkForIncompleteTasks", true);
              }
            }}
            disabled={
              !(isSaved || isWithdrawn) ||
              !disabledByRole ||
              isClosed ||
              isPublished
            }
          />
          {EDUFormikList(values, setFieldValue)}
        </>
      );
    }
    return null;
  };

  const renderFREEComponents = (values: ICourseSetting, setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void) => {
    return (
      <>
        {FREEFormatAndDurationCourse(values, setFieldValue)}
        <FormikAutocomplete
          label={t("course:GRADING_SYSTEM")}
          name="gradingSystemId"
          isRequired
          options={gradingSystemList}
          clearable={false}
          sx={{ width: "50%" }}
          disabled={
            (isSaved && isWithdrawn) ||
            !disabledByRole ||
            isClosed ||
            isPublished ||
            isCourseFree
          }
          isGradingSystemVisible={!!values.gradingSystemId}
          gradingSystemId={Number(values.gradingSystemId)}
          hintText={t("course:hints.THE_GRADING_SYSTEM_PASSED_-_NOT_PASSED")}
        />
        {FREEFormikList(values, setFieldValue)}
      </>
    );
  };

  return (
    <Formik
      initialValues={courseSetting}
      enableReinitialize
      validationSchema={
        courseInfo.typeCode === TypeCode.EDU
          ? validationSchemaCommonEdu(t)
          : validationSchemaCommonFree(t)
      }
      onSubmit={(values: ICourseSetting) => {
        if (values.zeroMarkForIncompleteTasks) values.markForIncompleteTask = 0;
        if (courseSetting.id) {
          serviceCourse
            .updateCourseSetting(courseSetting.id, values)
            .then((res) => {
              if (res.status === 200) {
                setCourseData({ ...courseData, ...values });
                setCourseInfo({ ...courseInfo, courseSetting: values });
                setCourseSetting(values);
                setMessageOpen(true);
                setMessageType("success");
                setMessage(t("common:messages.DATA_CHANGED_SUCCESSFULLY"));
                setTimeout(() => {
                  setMessageOpen(false);
                }, 5000);
              }
            })
            .catch((err) => {
              setMessageOpen(true);
              setMessage(t("common:messages.ERROR"));
              setMessageType("error");
              setTimeout(() => {
                setMessageOpen(false);
              }, 3000);
            })
            .finally(() => {
              setTimeout(() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }, 25);
            });
        }
      }}
    >
      {({ values, submitForm, isValid, dirty, setFieldValue }) => {
        return (
          <FormikForm>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <FormikAutocomplete
                name="langId"
                label={t("dictionary:COURSE_LANGUAGE")}
                options={languageList}
                isRequired
                clearable={false}
                sx={{ width: "50%" }}
                disabled={
                  !(isSaved || isWithdrawn) ||
                  !disabledByRole ||
                  isClosed ||
                  isPublished ||
                  !!courseInfo.externalId
                }
              />
              {courseInfo.typeCode === TypeCode.EDU &&
                renderEDUComponents(values, setFieldValue)
              }
              {courseInfo.typeCode === TypeCode.FREE &&
                renderFREEComponents(values, setFieldValue)
              }
            </Box>
            {!isClosed && (
              <Box
                sx={{
                  mt: 4,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <CustomButton
                  backgroundColor={common.primaryColor}
                  fontColor={common.fontWhite}
                  width="98px"
                  borderRadius="6px"
                  height="38px"
                  disabled={!(isValid && dirty)}
                  onClick={submitForm}
                >
                  {t("common:actions.SAVE")}
                </CustomButton>
              </Box>
            )}
            <SaveChangesModal dirty={dirty} />
            <CustomModal
              open={showCertificate}
              handleToggleModal={setShowCertificate}
              width={1000}
              bgColor="transparent"
              borderColor="transparent"
              showCloseIcon
            >
              <Box
                sx={{
                  width: "100%",
                  height: 'auto'
                }}
              >
                <Certificate
                  courseName={courseInfo.name}
                  certificateDisplay={'CREDITS'}
                  hours={values.hours}
                  credits={courseInfo?.credits}
                  certificateData={initialCertData}
                  qrCode={' '}
                  orgLogo={orgLogo}
                  orgLogoSign={orgLogoSign}
                />
              </Box>

            </CustomModal>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
