import { Box, Typography, useTheme } from '@mui/material';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import { useTranslation } from "react-i18next";

interface Props {
  text: string;
}
const Hint = ({ text }: Props) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course"]);

  return (
    <Box sx={{
      width: '100%', backgroundColor: '#fff', minHeight: '90px', borderRadius: '8px',
      display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, py: 1, px: 2, gap: { xs: 1, md: 2 }, border: `1px solid ${common.borderSecondary}`, borderLeft: `8px solid ${common.primaryColor}`
    }}>
      <Box sx={{ display: 'grid', placeContent: 'center', width: '40px', height: '40px', borderRadius: '50%', backgroundColor: '#F6F4F8' }}>
        <FeedbackOutlinedIcon sx={{ color: common.primaryColor }} />
      </Box>
      <Box>
        <Typography sx={{ fontWeight: 600, fontSize: { xs: '16px', lg: '18px' }, lineHeight: '24px', fontFamily: 'Golos' }}>{t('course:note.ATTENTION')}</Typography>
        <Typography sx={{ color: '#6F6F7E', fontSize: { xs: '14px', lg: '16px' }, fontWeight: 400, fontFamily: 'Golos', lineHeight: '20px' }}>
          {text}
        </Typography>
      </Box>
    </Box>
  )
}

export default Hint
