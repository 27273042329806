import { Routes, Route, useLocation } from "react-router-dom";
import { Box, useTheme, useMediaQuery } from "@mui/material";

import { privateRoutes } from "../routes";
import SideMenu from "./SideMenu";
import BreadcrumbsProvider from "@providers/Breadcrumbs";
import CourseProvider from "@providers/CourseProvider";
import { NotificationSocket } from "@pages/notification/NotificationSocket";

const Container = ({ role }) => {
  const location = useLocation();
  const { common } = useTheme().palette;
  const headerHeight =
    Number(localStorage.getItem("headerHeight")) + "px" || "60px";
  const footerHeight =
    Number(localStorage.getItem("footerHeight")) + "px" || "129px";
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const userInfo = localStorage.getItem("userInfo") ?? "";
  return (
    <Box sx={{ display: "flex" }}>
      {location.pathname !== "/" &&
        !location.pathname.includes("/freeCourseDetails") &&
        !location.pathname.includes("freeCatalogCourse") &&
        !location.pathname.includes("task-assessment") &&
        !location.pathname.includes("terms-of-use") &&
        (userInfo || !location.pathname.includes("certificate")) &&
        matches &&
        role && (
          <Box sx={{ backgroundColor: common.fontWhite }}>
            <SideMenu />
          </Box>
        )}
      <Box
        sx={{
          width: "100%",
          display: "grid",
          pt: 8,
          pb: location.pathname === "/" ? 0 : 3,
          px: location.pathname === "/" ? 0 : 3,
          backgroundColor: location.pathname === "/" ? "#FFFFFF" : "#F9FAFB",
          minHeight: `calc(100vh - (${headerHeight} + ${footerHeight}) + 60px)`,
          "@media (max-width:462px)": {
            padding: "18px 16px",
          },
        }}
      >
        <Routes>
          {privateRoutes.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              element={
                <CourseProvider>
                  <BreadcrumbsProvider>
                    <route.element />
                  </BreadcrumbsProvider>
                </CourseProvider>
              }
            />
          ))}
        </Routes>
        <NotificationSocket />
      </Box>
    </Box>
  );
};

export default Container;
