import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Checkbox, Drawer, FormControlLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { AlertColor } from '@mui/material/Alert';
import { useTranslation } from "react-i18next";
import { get, capitalize } from 'lodash';

import CustomButton from "@ui/Button";
import Form from "./Form";
import { serviceDict } from "@services/dictionary";
import { initialFilter } from "./models";
import Pagination from "@ui/Pagination";
import { IDictionary } from "@interfaces/index";
import Message from "@components/Message";
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";

const List = () => {
  const navigate = useNavigate();
  const { dictCode } = useParams();
  const { t } = useTranslation(["dictionary", "common", "enum"]);
  const lng = localStorage.getItem("i18nextLng") || "kz";

  const { common } = useTheme().palette;
  const [dictionaryList, setLists] = useState<IDictionary[]>([]);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [filter, setFilter] = useState<{ search: string; isDisabled: boolean }>(initialFilter);
  const [dictionaryId, setDictionaryId] = useState<string | number | null>(null);
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");

  const searchByName = (searchValue: string) => {
    const new_filter = { ...filter, search: searchValue };
    setFilter(new_filter);
  };

  const searchByActiveness = (checked: boolean) => {
    const new_filter = { ...filter, isDisabled: checked };
    setFilter(new_filter);
  };

  const handleChangePage = (newPage: number) => setCurrentPage(newPage);

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
    setCurrentPage(0);
  };

  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [state, setState] = useState({ create: false });

  const toggleDrawer =
    (anchor: "create", open: boolean, id: number | null) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
        setSelectedId(id);
      };

  const create = () => <Box sx={{ width: 784 }}></Box>;

  const deleteDictionary = () => {
    return serviceDict.deleteDictionary(dictCode || '', dictionaryId).then((res) => {
      if (res.status === 200) {
        setLists(dictionaryList.filter((item) => item.id !== dictionaryId));
        setMessageOpen(true);
        setMessage(t("common:messages.RECORD_DELETED"));
        setMessageType("success");
        setTimeout(() => {
          setMessageOpen(false);
        }, 3000);
      } else {
        setMessage(t("common:messages.RECORD_NOT_DELETED"));
        setMessageOpen(true);
        setMessageType("error");
        setTimeout(() => {
          setMessageOpen(false);
        }, 10000);
      };
    }).finally(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  };

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteDictionary,
    modalTitle: t(`common:DELETE_ITEM_CONFIRMATION_TEXT`),
  })

  useEffect(() => {
    if (dictCode) {
      serviceDict
        .getDictionaryList(dictCode, {
          pageNumber: currentPage,
          size: rowsPerPage,
          filter,
          sorting: lng === 'kz' ? { isStandard: "desc", nameKz: "asc" } : lng === 'ru' ? { isStandard: "desc", nameRu: "asc" } : { isStandard: "desc", nameEn: "asc" },
        })
        .then((res) => {
          if (res.status === 200) {
            setLists(res.data.dtoList);
            setCount(res.data.totalElements);
          }
        });
    }
  }, [currentPage, rowsPerPage, filter, dictCode, state.create, lng]);

  return (
    <Box>
      {DeleteModal}
      <Box sx={{ display: 'flex', mb: '24px', alignItems: 'center' }}>
        <Typography sx={{ cursor: 'pointer' }} onClick={() => navigate('/dict')}>{t("DICTIONARIES")}</Typography>
        <ArrowForwardIosIcon sx={{ width: '10px', height: '10px', mt: '1.5px', ml: '9px' }} />
        <Typography sx={{ ml: '9px' }}>{t(`${dictCode}`)}</Typography>
      </Box>
      <Typography sx={{ fontWeight: 500, fontSize: '36px', lineHeight: '44px', mb: '38px' }}>
        {t(`${dictCode}`)}
      </Typography>

      {messageOpen &&
        <Box sx={{ mb: '24px', mt: '-12px' }}>
          <Message
            type={messageType}
            message={message}
            setMessageOpen={setMessageOpen}
          />
        </Box>
      }

      <Box sx={{
        border: `1px solid ${common.borderSecondary}`, backgroundColor: common.fontWhite,
        borderRadius: '24px', p: '24px'
      }}>
        <Grid container sx={{ ml: '12px' }}>
          <Grid item xs={4} sx={{ mb: 4 }}>
            <TextField
              label={t("common:placeholders.SEARCH_BY_NAME")}
              color="primary"
              size="small"
              name="searchValue"
              onChange={(e) => searchByName(e.target.value)}
              sx={{
                width: '80%', ml: '-15px', '& label.Mui-focused': { color: common.primaryColor },
                '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': { borderColor: common.primaryColor },
                  '&.Mui-focused fieldset': { borderColor: common.primaryColor }
                }
              }}
            />
          </Grid>
          <Grid item xs={4} textAlign="end">
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.isDisabled}
                  onChange={(e) => searchByActiveness(e.target.checked)}
                  sx={{
                    marginLeft: 2, color: common.primaryColor, "&, &.Mui-checked": {
                      color: common.primaryColor,
                    },
                  }}
                />
              }
              label={t("enum:NOT_USED")}
              labelPlacement="end"
              sx={{ ml: '-28px' }}
            />
          </Grid>
          <Grid item xs={3.8} textAlign="end">
            <CustomButton
              backgroundColor={common.primaryColor}
              borderRadius="8px"
              fontColor={common.fontWhite}
              width="90px"
              height="40px"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                alignItems: "center",
              }}
              onClick={toggleDrawer("create", true, null)}
            >
              {t("common:actions.ADD")}
            </CustomButton>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mt: '38px' }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: 18, width: '50%' }}>{t("TITLE")}</TableCell>
                <TableCell sx={{ fontSize: 18, width: '30%' }}>{t("USAGE_STATUS")}</TableCell>
                <TableCell sx={{ fontSize: 18 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', pr: '25px' }}>{t("ACTION")}</Box>
                </TableCell>
              </TableRow>
            </TableHead>

            {dictionaryList?.length && dictionaryList.length > 0 ? (
              <TableBody>
                {dictionaryList?.map((dictionary: any, index: any) => {
                  return (
                    <TableRow key={`${index}_${dictionary.id}`}>
                      <TableCell sx={{ fontSize: 16 }}>
                        {get(dictionary, `name${capitalize(lng)}`)}
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }}>
                        {dictionary.isDisabled ? t("enum:NOT_USED") : t("enum:IS_USED")}
                      </TableCell>
                      <TableCell sx={{ display: 'flex', justifyContent: 'flex-end', pr: '50px' }}>
                        <EditIcon
                          color="info"
                          onClick={toggleDrawer("create", true, dictionary.id)}
                          sx={{ cursor: "pointer", mr: '8px' }}
                        />
                        <Box sx={{ width: '30px' }}>
                          {
                            !dictionary.isStandard &&
                            <DeleteOutlineIcon sx={{ cursor: "pointer", color: common.errorColor }}
                              onClick={() => { setDictionaryId(dictionary.id); openDeleteConfirmModal(dictionary.id) }}
                            />
                          }
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    {t("EMPTY")}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          <Pagination
            count={count}
            page={currentPage}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
      <Drawer
        anchor={"right"}
        open={state["create"]}
        onClose={toggleDrawer("create", false, null)}
      >
        {create()}
        <Form
          state={state}
          setState={setState}
          selectedId={selectedId}
          lists={dictionaryList}
          setLists={setLists}
          dictCode={dictCode}
          setMessage={setMessage}
          setMessageOpen={setMessageOpen}
          setMessageType={setMessageType}
        />
      </Drawer>
    </Box >
  )
}

export default List;