import React from "react";
import s from "./Container.module.scss";
import useWindowDimensions from "@hooks/useWindowDimensions";
interface Props {
  children: React.ReactNode;
  className?: string;
  background?: string;
}
const Container = (props: Props) => {
  const { children, className } = props;
  const { width } = useWindowDimensions();
  return (
    <div
      style={{ background: props.background, width: width }}
      className={[s.container, className].join(" ")}
    >
      {children}
    </div>
  );
};

export default Container;
