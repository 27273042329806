import Helper from "@assets/images/home/helper.png";
import { useNavigate } from "react-router-dom";
import CommonInfo from "../CommonInfo/CommonInfo";
import s from "./MeasureEarning.module.scss";
import { useTranslation } from "react-i18next";
import { Role } from "@interfaces/index";
const MeasureEarning = () => {
  const navigate = useNavigate();
  const {t} = useTranslation(["home", "course"])
  const userInfo = localStorage.getItem("userInfo");
  const role = JSON.parse(userInfo || "{}")?.authorityInfo?.role;
  return (
    <div className={s.wrapper}>
      <div className={s.head}>
        <CommonInfo
          value={{
            title: t('home:EVALUATE_YOUR_POTENTIAL_EARNINGS_AND_REACH'),
            text: t('home:START_PUBLISHING_COURSES_AND_GET_ACCESS_TO_WIDE_AUDIENCE_OF_STUDENTS'),
            button: (!role || role === Role.EDUCATOR || role === Role.MANAGER) ? t('course:CREATE_COURSE') : undefined,
            onClick: () => {
              navigate(userInfo ? "/myCourses" : "/registration");
            },
          }}
        />
      </div>
      <div className={s.content}>
        <div className={s.big}>
          <div className={s.big_content}>
            <div className={[s.title, s.purple].join(" ")}>700 000 ₸</div>
            <div className={s.text_content}>
              <div className={s.text}>{t('home:AVERAGE_EARNINGS_OF_COURSE_AUTHOR')}</div>
              <div className={s.subtext}>
                {t('home:APPROXIMATE_AUTHORS_INCOME')}
              </div>
            </div>
          </div>
          <div className={s.img}>
            <div className={s.img_content}>
              <img src={Helper} alt="" />
            </div>
          </div>
        </div>
        <div className={s.small}>
          <div className={s.small_content}>
            <div className={[s.title, s.purple].join(" ")}>15</div>
            <div className={s.text_content}>
              <div className={s.text}>{t('home:AUTHOR_OF_THE_COURSES')}</div>
              <div className={s.subtext}>
                {t('home:FIND_OUT_ABOUT_OTHER_AUTHORS')}
              </div>
            </div>
          </div>
          <div className={[s.small_content, s.back_purple].join(" ")}>
            <div className={[s.title, s.white].join(" ")}>150 000</div>
            <div className={s.text_content}>
              <div className={s.text}> {t('course:NUMBER_OF_LISTENERS')}</div>
              <div className={s.subtext}>
                {t('home:POTENTIAL_VISITORS')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeasureEarning;
