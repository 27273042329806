import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { Formik, Form as FormikForm } from "formik";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';

import CustomButton from '@ui/Button';
import FormikInput from '@ui/formik/Input';
import { initialDto } from './models';

const AssessmentsWithFX = () => {
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const [initialData, setInitialData] = useState(initialDto);
  const { t } = useTranslation(["course", "common"]);

  return (
    <Box sx={{ m: '32px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
        <Typography
          sx={{ cursor: 'pointer', fontSize: '14px' }}
          onClick={() => {
            navigate('/assessmentSystem')
          }}>Системы оценивания</Typography>
        <ArrowForwardIosIcon sx={{ width: '9px', height: '9px', ml: '9px', mt: '2.5px' }} />
        <Typography sx={{ ml: '8px', fontSize: '14px' }}>100 бальная шкала оценивания (с FX)</Typography>
      </Box>
      <Box>
        <Typography sx={{ fontWeight: 500, fontSize: '36px', mt: '16px', mb: '36px' }}>100 бальная шкала оценивания (с FX)</Typography>
      </Box>
      <Box sx={{ width: '100%', backgroundColor: common.white, height: 'auto', border: `1px solid ${common.borderSecondary}`, borderRadius: '9px', p: '32px' }}>
        <Formik initialValues={initialData} enableReinitialize validationSchema={null} onSubmit={(values) => { }}>
          {({ values, submitForm }) => (
            <FormikForm>
              <TableContainer component={Paper} sx={{ overflowX: 'unset' }}>
                <Table stickyHeader aria-label="sticky table" sx={{ overflow: 'none', border: `1px solid ${common.borderSecondary}` }} >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        {t('GRADE')}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ display: 'block' }}>
                          <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>Диапазон баллов</Typography>
                          <Box sx={{ display: 'flex', mt: '5px' }}>
                            <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>До</Typography>
                            <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>От</Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        {t('common:label.DESCRIPTION')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        A
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ display: 'flex', mt: '5px' }}>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>100</Typography>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>95</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ ml: '-17px', mr: '-24px', mt: '-24px', mb: '-26px' }}>
                          <FormikInput name="Aaa" label="" multiline={true} size='medium' placeholder='Text' maxRows={3} />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        A-
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ display: 'flex', mt: '5px' }}>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>94</Typography>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>90</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ ml: '-17px', mr: '-24px', mt: '-24px', mb: '-26px' }}>
                          <FormikInput name="Aaa" label="" multiline={true} size='medium' placeholder='Text' maxRows={3} />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        B+
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ display: 'flex', mt: '5px' }}>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>89</Typography>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>85</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ ml: '-17px', mr: '-24px', mt: '-24px', mb: '-26px' }}>
                          <FormikInput name="Aaa" label="" multiline={true} size='medium' placeholder='Text' maxRows={3} />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        B
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ display: 'flex', mt: '5px' }}>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>84</Typography>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>80</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ ml: '-17px', mr: '-24px', mt: '-24px', mb: '-26px' }}>
                          <FormikInput name="Aaa" label="" multiline={true} size='medium' placeholder='Text' maxRows={3} />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        B-
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ display: 'flex', mt: '5px' }}>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>79</Typography>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>75</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ ml: '-17px', mr: '-24px', mt: '-24px', mb: '-26px' }}>
                          <FormikInput name="Aaa" label="" multiline={true} size='medium' placeholder='Text' maxRows={3} />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        C+
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ display: 'flex', mt: '5px' }}>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>74</Typography>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>70</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ ml: '-17px', mr: '-24px', mt: '-26px', mb: '-26px' }}>
                          <FormikInput name="Aaa" label="" multiline={true} size='medium' placeholder='Text' maxRows={3} />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        C
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ display: 'flex', mt: '5px' }}>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>69</Typography>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>65</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ ml: '-17px', mr: '-24px', mt: '-24px', mb: '-26px' }}>
                          <FormikInput name="Aaa" label="" multiline={true} size='medium' placeholder='Text' maxRows={3} />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        C-
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ display: 'flex', mt: '5px' }}>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>64</Typography>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>60</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ ml: '-17px', mr: '-24px', mt: '-24px', mb: '-26px' }}>
                          <FormikInput name="Aaa" label="" multiline={true} size='medium' placeholder='Text' maxRows={3} />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        D+
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ display: 'flex', mt: '5px' }}>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>59</Typography>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>55</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ ml: '-17px', mr: '-24px', mt: '-24px', mb: '-26px' }}>
                          <FormikInput name="Aaa" label="" multiline={true} size='medium' placeholder='Text' maxRows={3} />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        D
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ display: 'flex', mt: '5px' }}>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>54</Typography>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>50</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ ml: '-17px', mr: '-24px', mt: '-24px', mb: '-26px' }}>
                          <FormikInput name="Aaa" label="" multiline={true} size='medium' placeholder='Text' maxRows={3} />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        FX
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ display: 'flex', mt: '5px' }}>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>49</Typography>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>25</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ ml: '-17px', mr: '-24px', mt: '-24px', mb: '-26px' }}>
                          <FormikInput name="Aaa" label="" multiline={true} size='medium' placeholder='Text' maxRows={3} />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 400, fontSize: '14px', width: '20%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        F
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '15%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ display: 'flex', mt: '5px' }}>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>24</Typography>
                          <Typography sx={{ width: '50%', fontWeight: 400, fontSize: '14px' }}>0</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: '14px', width: '45%', borderRight: `1px solid ${common.borderSecondary}` }}>
                        <Box sx={{ ml: '-17px', mr: '-24px', mt: '-24px', mb: '-26px' }}>
                          <FormikInput name="Aaa" label="" multiline={true} size='medium' placeholder='Text' maxRows={3} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{
                mt: '64px', display: 'flex', justifyContent: 'flex-end'
              }}>
                <CustomButton
                  width="98px"
                  height="40px"
                  backgroundColor={common.primaryColor}
                  borderRadius="8px"
                  fontColor={common.fontWhite}
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                    alignItems: "center",
                    color: "#FFFFFF",
                  }}
                >
                  {t('common:actions.SAVE')}
                </CustomButton>
              </Box>
            </FormikForm>
          )}
        </Formik>
      </Box>
    </Box>
  )
}

export default AssessmentsWithFX