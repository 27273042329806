import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EditIcon from "@mui/icons-material/Edit";

import { serviceCourse } from "@services/course";
import {
  serviceTask,
  useGetAllTaskCommentsQuery,
  useGetRecipientByIdQuery,
} from "@services/task";
import { formatDateWithHourMinute } from "@utils/functions";
import { taskStatusBgColor } from "@utils/enums";
import CustomInput from "@ui/Input";
import { SwitchListener } from "./SwitchListener";
import ReplyAttachedFiles from "./ReplyAttachedFiles";
import CommentList from "@pages/myCourses/Student/CurrentCourses/Task/CommentList";
import PdfViewer from "@components/PdfViewer";
import {
  AnswerTypes,
  IProgress,
  ITask,
  ListArray,
  TaskFormat,
  TaskStatus,
} from "@interfaces/index";
import { taskInitialDto } from "../Content/Task/models";
import { initialProgress } from "./models";
import EmptyDataComponent from "@components/EmptyDataComponent";
import { CourseContext } from "@context/CourseContext";
import ChangeDateModal from "./ChangeDateModal";
import SuccesModal from "./SuccessModal";
import PublishCourseModal from "@components/modals/PublishCourseModal";
import QuizResult from "@pages/myCourses/Student/CurrentCourses/Task/QuizResult";
import CustomAutocomplete from "@ui/Autocomplete";
import { Divider } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconHints from "@components/IconHints";
import CorrectionModal from "./CorrectionModal";
import RefreshIcon from "@mui/icons-material/Refresh";
import YesOrNoModal from "@components/modals/YesOrNoModal";
import CustomButton from "@ui/Button";
import TextReader from "@ui/TextReader";
import { OralTaskFormat } from "./OralFormatTask";
import { useGetMeetingFindOneQuery } from "@services/meeting";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
const arrowIconStyle = {
  width: "24px",
  height: "24px",
  color: "#1F2855",
  borderRadius: "6px",
  backgroundColor: "#E9E9E9",
  cursor: "pointer",
  mr: 1,
};

const TaskAssessment = () => {
  const { courseId, progressId, pathType, flowId, recipientId } = useParams();
  const { t } = useTranslation(["course", "common", "dictionary", "enum"]);
  const navigate = useNavigate();
  const [courseMeUserId, setCourseMeUserId] = useState<string>("");
  const [task, setTask] = useState<ITask>(taskInitialDto);
  const { common } = useTheme().palette;
  const [tabValue, setTabValue] = useState<string>("files");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDescriptionOpen, setIsDescriptionOpen] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<IProgress>(initialProgress);
  const [deadlineDate, setDeadlineDate] = useState<Date | string | null>(null);
  const [refreshComment, setRefreshComment] = useState<boolean>(false);
  const [listenerId, setListenerId] = useState<string | null>(null);
  const [progressPoint, setProgressPoint] = useState<number | null>(null);
  const [maxPoint, setMaxPoint] = useState<number>(100);
  const [fileId, setFileId] = useState<any>();
  const currentTab = searchParams.get("tab");
  const { courseData } = useContext(CourseContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [openIncorrectDateModal, setOpenIncorrectDateModal] =
    useState<boolean>(false);
  const [quizAttemptList, setQuizAttemptList] = useState<Array<any>>([]);
  const [taskInitialData, setTaskInitialData] = useState<ITask>(taskInitialDto);
  const [isTestResultOpen, setIsTestResultOpen] = useState<boolean>(true);
  const [quizResult, setQuizResult] = useState<any>();
  const [questions, setQuestions] = useState<Array<any>>([]);
  const [currentQuestionId, setCurrentQuestionId] = useState<string | null>(
    null
  );
  const [attemptNumber, setAttemptNumber] = useState<any>();
  const [isCorrectionModalOpen, setIsCorrectionModalOpen] = useState(false);
  const [isVerifiedModalOpen, setIsVerifiedModalOpen] = useState(false);

  const { data: conference } = useGetMeetingFindOneQuery(
    {
      taskId: task.id ?? "",
      classId: flowId ?? "",
    },
    {
      skip: !(task && task.answerType === AnswerTypes.ONLINE_CONFERENCE),
    }
  );

  const { data: taskRecipient } = useGetRecipientByIdQuery(recipientId ?? "", {
    skip: !(task && task.answerType === AnswerTypes.OFFLINE),
  });

  const { data: videoTask } = useGetAllTaskCommentsQuery(progressId ?? "", {
    skip: !(task && task.answerType === AnswerTypes.VIDEO),
  });

  const OnlineConferenceContent: ListArray[] = [
    {
      key: t("enum:LINK"),
      value: conference?.link ?? "",
      colored: true,
      svg: <ContentCopyRoundedIcon />,
      onClick: async () => {
        if (conference && navigator.clipboard && window.isSecureContext) {
          await navigator.clipboard.writeText(conference.link);
        } else {
          // Use the 'out of viewport hidden text area' trick
          const textArea = document.createElement("textarea");
          textArea.value = conference?.link ?? "";

          // Move textarea out of the viewport so it's not visible
          textArea.style.position = "absolute";
          textArea.style.left = "-999999px";

          document.body.prepend(textArea);
          textArea.select();

          try {
            document.execCommand("copy");
          } catch (error) {
            console.error(error);
          } finally {
            textArea.remove();
          }
        }
      },
    },
    {
      key: t("dictionary:ORGANIZER"),
      value: conference?.coordinatorUserName ?? "",
    },
    {
      key: t("course:conference.ABOUT_CONFERENCE"),
      value: t("common:actions.MORE"),
      svg: <OpenInNewRoundedIcon />,
      colored: true,
      onClick: () => {
        window.open(conference?.link ?? "", "_blank");
      },
    },
  ];

  const OfflineConferenceContent: ListArray[] = [
    {
      key: t("course:meeting.MEETING_PLACE"),
      value: taskRecipient?.meetingPlace ?? "",
    },
    {
      key: t("course:meeting.DATE_OF_MEETING"),
      value:
        (taskRecipient &&
          taskRecipient.meetingStartDate &&
          taskRecipient.meetingStartDate.split("T")?.[0]) ||
        "",
    },
    {
      key: t("course:meeting.COLLECTION_START_TIME"),
      value: taskRecipient?.meetingStartDate ?? "",
    },
    {
      key: t("course:meeting.COLLECTION_END_TIME"),
      value: taskRecipient?.meetingEndDate ?? "",
    },
  ];

  useEffect(() => {
    if (currentTab) {
      setTabValue(currentTab);
    } else {
      if (task.answerType === "TEXT") {
        setTabValue("answers");
      } else {
        setTabValue("files");
      }
    }
  }, [currentTab, task.answerType]);

  useEffect(() => {
    if (task.id) {
      serviceTask.getTaskInfo(task.id).then((res) => {
        if (res.status === 200) {
          if (courseData?.gradingSystemId === 3) {
            setMaxPoint(res.data.point);
          }
          setTaskInitialData(res.data);
        }
      });
    }
  }, [task, courseData?.gradingSystemId, courseId]);

  useEffect(() => {
    if (flowId) {
      serviceCourse.getCourseMe(flowId).then((res) => {
        if (res.status === 200) {
          const { id } = res.data;
          setCourseMeUserId(id);
        }
      });
    }
  }, [flowId]);

  useEffect(() => {
    if (progressId) {
      serviceTask.getTaskProgressInfo(progressId).then((res) => {
        if (res.status === 200) {
          setProgressPoint(res.data.point);
          setInitialData(res.data);
          setDeadlineDate(res.data.dateOfDeadline);
          setTask(res.data.task);
        }
      });
      serviceTask.getQuizAttemptResult(progressId).then((res) => {
        if (res.status === 200) {
          const quizAttemptListWithNames = res.data.map((item, index) => ({
            ...item,
            name: (index + 1).toString(),
          }));
          setQuizAttemptList(quizAttemptListWithNames);
          setAttemptNumber(quizAttemptListWithNames[0]);
        }
      });
    }
  }, [progressId]);

  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);
    setSearchParams({ tab: newValue });
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "point") setProgressPoint(value);
  };

  const updateDeadlineDate = () => {
    if (deadlineDate) {
      const localDate = new Date(deadlineDate);
      const formattedDeadlineDate = new Date(
        localDate.getTime() - localDate.getTimezoneOffset() * 60000
      ).toISOString();
      serviceTask
        .updateTaskProgress({
          ...initialData,
          dateOfDeadline: formattedDeadlineDate,
        })
        .then((res) => {
          if (res.status === 200) {
            setIsModalOpen(!isModalOpen);
            setIsSuccessModalOpen(!isSuccessModalOpen);
            setInitialData({
              ...initialData,
              dateOfDeadline: formattedDeadlineDate,
            });
          }
        });
    }
  };

  const evaluateTask = () => {
    serviceTask
      .updateTaskProgress({
        ...initialData,
        status: TaskStatus.COMPLETED,
        point: progressPoint,
      })
      .then((res) => {
        if (res.status === 200) {
          setInitialData((initialData) => ({
            ...initialData,
            status: TaskStatus.COMPLETED,
            point: progressPoint,
          }));
        }
      });
    setIsVerifiedModalOpen(false);
  };

  useEffect(() => {
    if (task.format === TaskFormat.QUIZ && quizAttemptList.length > 0) {
      serviceTask
        .getFullResultViaAttemptId(quizAttemptList[0]?.id)
        .then((res) => {
          if (res.status === 200) {
            setQuizResult(res.data);
            const questionList = res.data.questions;
            setQuestions(questionList);
            setCurrentQuestionId(questionList[0].questionId);
          }
        });
    }
  }, [quizAttemptList, task.format]);

  const getAttemtpResult = (attemptId: string, showAnswers = false, value) => {
    setAttemptNumber(value);
    serviceTask.getFullResultViaAttemptId(attemptId).then((res) => {
      if (res.status === 200) {
        setQuizResult(res.data);
        if (showAnswers) {
          const questionList = res.data.questions;
          setQuestions(questionList);
          setCurrentQuestionId(questionList[0].questionId);
        }
      }
    });
  };

  const goToQuestion = (questionId: string) => {
    const index = [...questions].findIndex(
      (x: any) => x.questionId === questionId
    );
    const questionToGo = [...questions][index];
    setCurrentQuestionId(questionToGo.questionId);
  };
  return (
    <Box
      sx={{
        mx: 4,
        display: "flex",
        gap: 1,
      }}
    >
      <Box
        sx={{
          px: 2,
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "75%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            color: common.primaryColor,
          }}
          onClick={() => {
            if (pathType === "assessment") {
              navigate(
                `/myCourses/${courseId}/task-recipients/${task.id}/flows/${flowId}`
              );
            } else {
              navigate(`/myCourses/${courseId}`);
            }
          }}
        >
          <ArrowBackIosIcon sx={{ width: 14, height: 14 }} />
          <Typography>{t("common:actions.GO_BACK")}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            border: `1px solid ${common.primaryColor}`,
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <Typography variant="h4">{task.name}</Typography>
          {/* {task.dateOfAccess &&
            <Box sx={{ display: 'flex', gap: 2 }}>
              {task.format === 'WRITTEN' &&
                <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f9f9f9', p: 1, borderRadius: '6px' }}>
                  <Box sx={{ display: 'flex', gap: 1 }}><CalendarTodayIcon /> <Typography>{t('course:theme.ANSWER_SENDING_DATE')}</Typography></Box>
                  <Typography>23 декабря 2023, 17:42</Typography>
                </Box>
              }
            </Box>
          } */}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <SwitchListener setListenerId={setListenerId} />
            {task.dateOfAccess && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
              >
                <Box sx={{ color: "#8E8E93" }}>
                  {" "}
                  <Typography>
                    {t("course:theme.ACCESS_OPENING_DATE")}
                  </Typography>
                </Box>
                <Typography>
                  {formatDateWithHourMinute(task.dateOfAccess) || ""}
                </Typography>
              </Box>
            )}
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              fontFamily: "Golos",
              mt: "-8px",
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <Typography>
                <span style={{ color: "#8E8E93" }}>
                  {t("dictionary:FORMAT")}:
                </span>{" "}
                {t(`enum:${task.format}`)}
              </Typography>
              <Typography>
                <span style={{ color: "#8E8E93" }}>
                  {t("course:task.TYPE_OF_TASK")}:
                </span>{" "}
                {t(`enum:${task.type}`)}
              </Typography>
              {task.format === TaskFormat.WRITTEN && (
                <Typography>
                  <span style={{ color: "#8E8E93" }}>
                    {t("course:theme.METHOD_OF_PROVIDING_ANSWERS")}:
                  </span>{" "}
                  {t(`enum:${task.answerType}`)}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                color: common.primaryColor,
                cursor: "pointer",
              }}
              onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                {t("common:label.DESCRIPTION")}
              </Typography>
              {!isDescriptionOpen ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </Box>
          </Box>
          {isDescriptionOpen && <Divider />}
          {isDescriptionOpen && (
            <TextReader htmlContent={taskInitialData?.description} hideBorder />
          )}
        </Box>
        {task.format === TaskFormat.ORAL && (
          <>
            {task &&
            task.answerType &&
            Object.values(AnswerTypes).includes(task.answerType) ? (
              <OralTaskFormat
                answerType={task.answerType}
                onlineConferenceAndOfflineMeeting={{
                  title: task?.name ?? "",
                  list:
                    task.answerType === AnswerTypes.ONLINE_CONFERENCE
                      ? OnlineConferenceContent
                      : OfflineConferenceContent,
                }}
                video={videoTask}
              />
            ) : (
              <EmptyDataComponent text={t("course:ORAL_TASK_WITHOUT_ANSWER")} />
            )}
          </>
        )}
        {task.format === TaskFormat.WRITTEN && (
          <Box>
            <TabContext value={tabValue}>
              <TabList
                onChange={(_, value: string) => handleTabChange(value)}
                textColor="secondary"
                indicatorColor="secondary"
                sx={{ ml: "24px" }}
              >
                {task.answerType === AnswerTypes.FILE && (
                  <Tab
                    label={t("course:theme.FILES")}
                    value="files"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "18px",
                      lineHeight: "24px",
                      fontWeight: 500,
                      color: "#1A141F",
                    }}
                  />
                )}
                <Tab
                  // label={
                  //   <Badge badgeContent={1} color="error"
                  //     sx={{
                  //       paddingRight: '10px'
                  //     }}>
                  //     Комментарии
                  //   </Badge>
                  // }
                  label={t("common:ANSWERS")}
                  value="answers"
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "#1A141F",
                  }}
                />
              </TabList>
              <TabPanel value="files" sx={{ p: 0, height: "100%" }}>
                <Box
                  sx={{
                    border: `1px solid ${common.borderSecondary}`,
                    backgroundColor: common.fontWhite,
                    borderRadius: "24px",
                    p: "24px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    height: "100%",
                  }}
                >
                  {initialData.status === TaskStatus.ASSIGNED ? (
                    <EmptyDataComponent
                      text={t(
                        "course:task.TASK_WILL_BE_READY_TO_CHECK_AFTER_PROVIDING_ANSWER"
                      )}
                    />
                  ) : (
                    <>
                      <ReplyAttachedFiles
                        answerType={task.answerType}
                        changeFileId={(id: string) => setFileId(id)}
                      />
                      <PdfViewer fileId={fileId} />
                    </>
                  )}
                </Box>
              </TabPanel>
              <TabPanel value="answers" sx={{ p: 0, height: "auto", mb: 3 }}>
                <Box
                  sx={{
                    border: `1px solid ${common.borderSecondary}`,
                    backgroundColor: common.fontWhite,
                    borderRadius: "24px",
                    p: "0 24px",
                    height: "100%",
                    minHeight: "10vh",
                  }}
                >
                  <CommentList
                    progressId={progressId || ""}
                    userId={listenerId}
                    refresh={refreshComment}
                  />
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        )}
        {task.format === TaskFormat.QUIZ && (
          <>
            {quizResult && quizAttemptList.length > 0 ? (
              <QuizResult
                showCorrectAnswer
                attemptResult={quizResult}
                questions={questions}
                currentQuestionId={currentQuestionId}
                goToQuestion={goToQuestion}
              />
            ) : (
              <EmptyDataComponent
                text={t("course:task.RESULTS_AVAILABLE_AFTER_PASSING_TEST")}
              />
            )}
          </>
        )}
      </Box>
      <Box
        sx={{
          px: 2,
          pb: 2,
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "25%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "1px",
            backgroundColor: common.borderSecondary,
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2.5,
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ mb: "10px" }}>
              {t("course:theme.TASK_STATUS")}
            </Typography>
            <Box
              sx={{
                width: "fit-content",
                backgroundColor: taskStatusBgColor[initialData.status || ""],
                py: "10px",
                px: "20px",
                color: "#FFFFFF",
                borderRadius: "10px",
              }}
            >
              {t(`enum:${initialData.status}`)}
            </Box>
          </Box>
          {deadlineDate && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Box sx={{ display: "flex", gap: 0.5 }}>
                <Typography sx={{ color: "#8E8E93" }}>
                  {t("course:task.DEADLINE_FOR_COMPLETION")}{" "}
                </Typography>
                <IconHints
                  text={t(
                    "course:hints.PARAMETER_IS_AVAILABLE_TO_CHANGE_DEADLINE_FOR_COMPLETING_TASK_WITHIN_COURSE_COMPLETION_DATE"
                  )}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                {formatDateWithHourMinute(initialData.dateOfDeadline)}
                {((task.format !== TaskFormat.QUIZ &&
                  initialData.status !== TaskStatus.COMPLETED &&
                  initialData.status !== "NOT_COMPLETED_ON_TIME") ||
                  (task.format === TaskFormat.QUIZ &&
                    initialData.status === TaskStatus.ASSIGNED)) && (
                  <EditIcon
                    style={{ color: common.primaryColor, cursor: "pointer" }}
                    onClick={() => setIsModalOpen(true)}
                  />
                )}
              </Box>
              {isModalOpen && (
                <ChangeDateModal
                  open={isModalOpen}
                  handleToggleModal={() => {
                    setDeadlineDate(initialData.dateOfDeadline || null);
                    setIsModalOpen(!isModalOpen);
                  }}
                  updateDeadlineDate={updateDeadlineDate}
                  initialDateOfDeadline={initialData.dateOfDeadline}
                  finalDateOfDeadline={deadlineDate}
                  deadlineDate={deadlineDate}
                  setDeadlineDate={setDeadlineDate}
                />
              )}
              {isSuccessModalOpen && (
                <SuccesModal
                  open={isSuccessModalOpen}
                  handleToggleModal={() =>
                    setIsSuccessModalOpen(!isSuccessModalOpen)
                  }
                />
              )}
            </Box>
          )}
          {Number(taskInitialData.quiz?.numberOfAttempts) > 1 &&
            initialData.status !== TaskStatus.ASSIGNED && (
              <CustomAutocomplete
                name="attempt"
                label={t("course:test.RESULT_PER_ATTEMPT")}
                options={quizAttemptList}
                value={attemptNumber}
                onChange={(_, value) => getAttemtpResult(value.id, true, value)}
                clearable={false}
                valueAsObject
                customIconDisabled={false}
              />
            )}
          {task.format === TaskFormat.QUIZ && !task.isNoMark && (
            <CustomInput
              name="point"
              value={progressPoint ?? ""}
              handleChange={handleInputChange}
              type="number"
              min={0}
              max={maxPoint}
              label={t("course:task.GRADE_OUT_OF_100", { maxPoint })}
              placeholder={t("course:task.GIVE_A_RATING")}
              disabled={initialData.status !== TaskStatus.UNDER_REVIEW}
            />
          )}
          {task.format !== TaskFormat.QUIZ &&
            (initialData.status === TaskStatus.ASSIGNED ||
              initialData.status === TaskStatus.UNDER_REVIEW ||
              initialData.status === TaskStatus.COMPLETED) &&
            (!task.dateOfAccess ||
              (task.dateOfAccess &&
                new Date(task.dateOfAccess) < new Date())) && (
              <Box sx={{ display: "flex", gap: 1 }}>
                {!task.isNoMark && (
                  <CustomInput
                    name="point"
                    value={progressPoint ?? ""}
                    handleChange={handleInputChange}
                    type="number"
                    min={0}
                    max={maxPoint}
                    label={t("course:task.GRADE_OUT_OF_100", { maxPoint })}
                    placeholder={t("course:task.GIVE_A_RATING")}
                    disabled={
                      (task.format !== TaskFormat.ORAL &&
                        initialData.status !== TaskStatus.UNDER_REVIEW) ||
                      (task.format === TaskFormat.ORAL &&
                        initialData.status === TaskStatus.COMPLETED)
                    }
                  />
                )}
                {((task.format === TaskFormat.ORAL) ||
                  initialData.status === TaskStatus.UNDER_REVIEW) && (
                  <CustomButton
                    backgroundColor={common.primaryColor}
                    fontColor={common.fontWhite}
                    width={!task.isNoMark ? "fit-content" : "100%"}
                    borderRadius="6px"
                    height="40px"
                    mt="auto"
                    onClick={() =>
                      !task.isNoMark
                        ? evaluateTask()
                        : setIsVerifiedModalOpen(true)
                    }
                  >
                    {!task.isNoMark ? t("course:POST") : t("course:VERIFIED")}
                  </CustomButton>
                )}
              </Box>
            )}
          {/* <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '20px', cursor: 'pointer' }} onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}>
              {isDescriptionOpen ?
                <KeyboardArrowDownOutlinedIcon sx={arrowIconStyle} /> :
                <KeyboardArrowUpOutlinedIcon sx={arrowIconStyle} />
              } {t('common:label.DESCRIPTION')}
            </Box>
            {
              isDescriptionOpen &&
              <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', border: `1px solid ${common.borderSecondary}`, padding: '10px', borderRadius: '10px' }}>
                {task.format === 'WRITTEN' &&
                  <Typography>
                    {t('course:test.ANSWERING_METHOD')}: {task.answerType ? t(`enum:${task.answerType}`) : 'Формат не выбран'}
                  </Typography>
                }
                {task.format === 'QUIZ' && Number(taskInitialData.quiz?.numberOfAttempts) > 1 &&
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography>{t('course:theme.CONSIDER_RESULT')}: {t(`enum:${taskInitialData?.quiz?.considerResult}`)}</Typography>
                    <Typography>{t('course:theme.NUMBER_OF_ATTEMPTS')}: {quizAttemptList.length}/{taskInitialData.quiz?.numberOfAttempts}</Typography>
                  </Box>
                }
              </Box>
            }
          </Box> */}
          {task.format === TaskFormat.QUIZ && quizAttemptList.length > 0 && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "20px",
                  cursor: "pointer",
                  gap: 0.5,
                }}
                onClick={() => setIsTestResultOpen(!isTestResultOpen)}
              >
                {t("course:test.TEST_RESULTS")}{" "}
                {isTestResultOpen ? (
                  <KeyboardArrowDownOutlinedIcon sx={arrowIconStyle} />
                ) : (
                  <KeyboardArrowUpOutlinedIcon sx={arrowIconStyle} />
                )}
              </Box>
              {isTestResultOpen && (
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    flexDirection: "column",
                    fontSize: "15px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#8E8E93",
                        fontSize: "14px",
                        lineHeight: "24px",
                        fontWeight: 400,
                      }}
                    >
                      {t("course:test.START_TIME")}:
                    </Typography>
                    <Typography>
                      {" "}
                      {formatDateWithHourMinute(quizResult?.startDate)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#8E8E93",
                        fontSize: "14px",
                        lineHeight: "24px",
                        fontWeight: 400,
                      }}
                    >
                      {t("course:test.END_TIME")}:
                    </Typography>
                    <Typography>
                      {formatDateWithHourMinute(quizResult?.endDate)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#8E8E93",
                        fontSize: "14px",
                        lineHeight: "24px",
                        fontWeight: 400,
                      }}
                    >
                      {t("course:test.CORRECT_ANSWERS")}:
                    </Typography>
                    <Typography>
                      {quizResult?.numberOfCorrectAnswers}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#8E8E93",
                        fontSize: "14px",
                        lineHeight: "24px",
                        fontWeight: 400,
                      }}
                    >
                      {t("course:test.NUMBER_OF_POINTS_TAKEN")}:
                    </Typography>
                    <Typography>
                      {quizResult?.takenPoint}/{quizResult?.maxPoint}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {task.format === TaskFormat.QUIZ &&
            taskInitialData.quiz?.applyProctoring &&
            initialData.status === TaskStatus.COMPLETED && (
              <CustomButton
                height="35px"
                onClick={() =>
                  navigate(
                    `/settings/aero/course/${courseId}/task/${task.id}/progress/${progressId}/user/${listenerId}`
                  )
                }
              >
                <Typography fontSize={"11px"}>Подробный отчет</Typography>
              </CustomButton>
            )}
          {
            // task.format === TaskFormat.WRITTEN &&
            initialData.status === TaskStatus.UNDER_REVIEW && (
              <CustomButton
                borderColor={common.primaryColor}
                backgroundColor={common.fontWhite}
                fontColor={common.primaryColor}
                width="100%"
                borderRadius="8px"
                height="40px"
                onClick={() => setIsCorrectionModalOpen(true)}
              >
                <RefreshIcon style={{ marginRight: 0.5 }} />{" "}
                {t("course:SUBMIT_FOR_IMPROVEMENT")}
              </CustomButton>
            )
          }
        </Box>
        {openIncorrectDateModal && (
          <PublishCourseModal
            openModal={openIncorrectDateModal}
            handleToggleModal={() =>
              setOpenIncorrectDateModal(!openIncorrectDateModal)
            }
            publishCourse={() => null}
            success={false}
            attention={false}
            message={t(
              "course:messages.DEADLINE_FOR_COMPLETION_NOT_INCLUDED_IN_COURSE_PERIOD"
            )}
          />
        )}
        {isCorrectionModalOpen && (
          <CorrectionModal
            open={isCorrectionModalOpen}
            handleToggleModal={() =>
              setIsCorrectionModalOpen(!isCorrectionModalOpen)
            }
            setRefreshComment={setRefreshComment}
            progressId={progressId}
            setInitialData={setInitialData}
            deadlineDate={deadlineDate}
            initialData={initialData}
            courseMeUserId={courseMeUserId}
          />
        )}
        {isVerifiedModalOpen && (
          <YesOrNoModal
            openModal={isVerifiedModalOpen}
            handleToggleModal={() =>
              setIsVerifiedModalOpen(!isVerifiedModalOpen)
            }
            success={false}
            attention={true}
            confirmAction={evaluateTask}
            message={t("course:messages.TASK_CHECKED_VERIFICATION")}
          />
        )}
      </Box>
    </Box>
  );
};

export default TaskAssessment;
