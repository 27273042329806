import { useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Formik, Form as FormikForm } from "formik";
import { useTranslation } from "react-i18next";

import { serviceCourse } from '@services/course';
import CustomModal from '@components/Modal';
import CustomButton from '@ui/Button';
import FormikInput from '@ui/formik/Input';
import { initialDto } from './models';
import { validationSchema } from './validation';
import { IContent, ISection } from '@interfaces/index';

interface Props {
  courseId: string;
  sectionId: string;
  modalOpen: boolean;
  handleToggleSectionModal: any;
  contentList: Array<IContent>;
  setContentList: any;
}

const CreateUpdateSection = ({ courseId, sectionId, modalOpen, handleToggleSectionModal, contentList, setContentList }: Props) => {
  const { common } = useTheme().palette;
  const [initialData, setInitialData] = useState<ISection>(initialDto);
  const { t } = useTranslation(["common", "course"]);

  useEffect(() => {
    if (sectionId) {
      serviceCourse.getSectionInfo(sectionId)
        .then(res => {
          if (res.status === 200) {
            setInitialData(res.data);
          }
        })
    } else {
      setInitialData(initialDto);
    }
  }, [sectionId])

  return (
    <CustomModal
      open={modalOpen}
      handleToggleModal={handleToggleSectionModal}
      width={494}
      showCloseIcon
    >
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "36px",
            color: "#000000",
            display: "flex",
            alignItems: "center",
          }}
        >
          {sectionId ? `${t('course:section.SECTION')} «${initialData.name}»` : t("course:section.ADD_SECTION")}
        </Typography>
        <Formik
          initialValues={initialData}
          enableReinitialize
          validationSchema={validationSchema(t)}
          onSubmit={(values) => {
            values.courseId = courseId;
            if (sectionId) {
              serviceCourse.updateSection(sectionId, courseId, values).then((res) => {
                if (res.status === 200) {
                  const content = [...contentList].find(
                    (x) => x.id === sectionId
                  );
                  const index = [...contentList].findIndex(
                    (x) => x.id === sectionId
                  );

                  setContentList([
                    ...contentList.slice(0, index),
                    { ...content, name: values.name, shortDescription: values.shortDescription },
                    ...contentList.slice(index + 1),
                  ]);
                  handleToggleSectionModal(false);
                }
              });
            } else {
              serviceCourse.createSection(values, courseId).then((res) => {
                if (res.status === 200) {
                  let data = res.data;
                  data["type"] = "SECTION";
                  data["parentType"] = "COURSE";
                  data["parentId"] = null;
                  data["format"] = null;
                  data["status"] = "PUBLISHED";
                  data["index"] = contentList.length + 1;
                  data["children"] = [];

                  setContentList([...contentList, res.data]);
                  handleToggleSectionModal(false);
                }
              });
            }
          }}
        >
          {({ submitForm, dirty, isValid, values }) => (
            <FormikForm>
              <Box sx={{ mt: { xs: '4px', sm: '4px', md: '8px', lg: '16px', xl: '24px' } }}>
                <FormikInput
                  name="name"
                  label={t("course:section.NAME_SECTION")}
                  isRequired
                />
              </Box>
              <Box sx={{ mt: { xs: '4px', sm: '4px', md: '8px', lg: '16px', xl: '24px' } }}>
                <FormikInput
                  name="shortDescription"
                  label={t("course:section.SHORT_DESCRIPTION")}
                  isRequired
                  multiline
                  placeholder={t('common:placeholders.THIS_SECTION_WILL_EXAMINE')}
                  minRows={2}
                  maxRows={5}
                  showSymbolCount
                  maxChar={200}
                />
              </Box>
              <Box
                sx={{
                  mt: "24px",
                  display: "flex",
                  gap: 1
                }}
              >
                <CustomButton
                  backgroundColor={common.btnCancel}
                  fontColor={common.primaryColor}
                  borderRadius="6px"
                  height="40px"
                  sx={{
                    alignItems: "center",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={() => handleToggleSectionModal(false)}
                >
                  {t("common:actions.CANCEL")}
                </CustomButton>
                <CustomButton
                  disabled={!(isValid && dirty)}
                  backgroundColor={common.primaryColor}
                  fontColor={common.fontWhite}
                  borderRadius="6px"
                  height="40px"
                  sx={{
                    alignItems: "center",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={submitForm}
                >
                  {sectionId ? t("common:actions.SAVE") : t("common:actions.ADD")}
                </CustomButton>
              </Box>
            </FormikForm>
          )}
        </Formik>
      </>
    </CustomModal>
  )
}

export default CreateUpdateSection;