import {
  AnswerTypes,
  ITask,
  ITaskFormat,
  ITaskFreeCourses,
  ITaskGroup,
} from "@interfaces/index";
import { CourseTaskQuiz } from "@models/course-task-quiz";
import {
  CourseTaskRecipient,
  CourseTaskRecipientTypeEnum,
} from "@models/course-task-recipient";
import { QuizQuestion, QuizQuestionTypeEnum } from "@models/quiz-question";
import { QuizQuestionAnswer } from "@models/quiz-question-answer";
import { getTodayWithSpecificTime } from "@utils/functions";

export const taskFormatInitialDto: ITaskFormat = {
  format: "",
  sectionId: "",
  themeId: "",
};

export const taskInitialDtoFreeCourses: ITaskFreeCourses = {
  id: null,
  name: null,
  description: null,
  type: "TASK",
  parentType: null,
  course: null,
  section: null,
  theme: null,
  point: null,
  format: null,
  answerType: AnswerTypes.TEXT,
  hasAnswerTypeFileFormats: false,
  answerTypeFileFormats: [],
  dateOfDeadline: null,
  isNoMark: false,
  isExcludeTotalMark: false,
  isNotifyStudent: false,
  status: "SAVED",
  createdBy: null,
  createdAt: null,
  materials: [],
  videoSum: 1,
  videoDuration: 10,
  isVideoFormatSpecified: false,
  videoFormats: [],
  quiz: {
    duration: 60,
    numberOfAttempts: 5,
    considerResult: "LAST",
    showNumberOfQuestions: false,
    allowMultipleAttempts: false,
    displayGrade: false,
    displayRightAnswers: false,
    textAfterQuiz: false,
    applyProctoring: false,
  },
  enabledAntiPlagiarism: false,
  antiPlagiarismTaskSetting: {
    originality: 0,
    similarity: 0,
    citation: 0,
    selfCitation: 0,
    symbolReplacementMaxCount: 0,
  },
  responsibleMember: null,
  group: null,
  progress: null,
  prevId: null,
  nextId: null,
  userMark: null,
  allowed: false,
  isSelfTest: false,
};

export const taskInitialDto: ITask = {
  ...taskInitialDtoFreeCourses,
  isCloseAccess: true,
  dateOfAccess: getTodayWithSpecificTime(7, 0),
  responsibleMembers: [],
};

export const taskGroupInitialDto: ITaskGroup = {
  id: null,
  course: null,
  name: null,
  percentage: null,
  taskType: null,
  tasks: [],
};

export const questionInitialDto = (): QuizQuestion =>
  ({
    id: "",
    content: "",
    type: QuizQuestionTypeEnum.ONECORRECTANSWER,
    quiz: {} as CourseTaskQuiz,
    point: "1",
    answers: [],
    children: [],
  } as any);

export const questionChildrenInitialDto = (): QuizQuestion =>
  ({
    content: "",
    answers: [questionAnswerInitialDto()],
  } as QuizQuestion);

export const questionAnswerInitialDto = (): QuizQuestionAnswer => ({
  answer: "",
  correct: false,
  order: 0,
});

export const questionRecipientsInitialDto = (): CourseTaskRecipient => ({
  type: CourseTaskRecipientTypeEnum.ALL,
  hasDeadlineDate: false,
  dateOfDeadline: undefined,
  isRestrictByIp: false,
  ipRangeStart: "",
  ipRangeEnd: "",
  planConference: false,
  platform: 1,
  link: null,
  login: null,
  password: null,
  conferenceDate: null,
  startTime: null,
  endTime: null,
  isMeetingPlaceAssigned: false,
  meetingDate: null,
});
