import React, { Dispatch, FC, SetStateAction } from "react";
import s from "./GreetingModal.module.scss";
import CustomModal from "@components/Modal";
import { ReactComponent as Platonus } from "@assets/svg_files/home/bigplatonus.svg";
import { useTransitionCarousel } from "react-spring-carousel";
import { Box, Button, Typography } from "@mui/material";
import Modal1 from "@assets/images/home/modal1.png";
import Modal2 from "@assets/images/home/modal2.png";
import Modal3 from "@assets/images/home/modal3.png";
import Modal4 from "@assets/images/home/modal4.png";
import Modal5 from "@assets/images/home/modal5.png";
import { useAppSelector } from "@store/hooks";
import { Role } from "@interfaces/index";
interface CustomModalProps {
  onClose: () => void;
  open: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
}
const GreetingModal: FC<CustomModalProps> = ({ onClose, open, setModal }) => {
  const profile = useAppSelector(
    (state) => state.account.profileInfo.authorityInfo.role
  );
  const ItemsListener = [
    {
      id: "item-1",
      renderItem: (
        <div className={s.platonus}>
          <Platonus />
        </div>
      ),
    },
    {
      id: "item-2",
      renderItem: (
        <div className={s.img}>
          <img src={Modal1} alt="" />
        </div>
      ),
    },
    {
      id: "item-3",
      renderItem: (
        <div className={s.img}>
          <img src={Modal2} alt="" />
        </div>
      ),
    },
    {
      id: "item-4",
      renderItem: (
        <div className={s.img}>
          <img src={Modal3} alt="" />
        </div>
      ),
    },
  ];
  const TextListener = [
    {
      title: "Добро пожаловатьв Platonus Academy",
      text: "Познакомьтесь с разнообразными курсами, доступными на нашей платформе",
    },
    {
      title: "Заполните свой профиль",
      text: "Расскажите о себе, чтобы взаимодействовать с другими студентами и преподавателям",
    },
    {
      title: "Выберите подходящий курс",
      text: "Ознакомьтесь с нашим каталогом курсов и выберите курс, который подходит именно вам",
    },
    {
      title: "Изучайте учебные материалы",
      text: "Приступайте к изучению материалов, чтобы углубить свои знания и достичь своих целей",
    },
  ];
  const TextEducator=[
    {
      title: "Добро пожаловать в Platonus Academy",
      text: "Познакомьтесь с разнообразными курсами, доступными на нашей платформе",
    },
    {
      title:"Заполните свой профиль",
      text:"Поделитесь информацией о себе, вашем опыте и квалификациях. Это поможет студентам лучше узнать вас и повысит доверие к вашим курсам"
    },
    {
      title:"Создайте свой первый курс",
      text:"Приступайте к созданию курса. Разработайте структуру, добавьте разделы, лекции и задания для ваших студентов"
    }
  ]
  const ItemsEducator = [
    {
      id: "item-1",
      renderItem: (
        <div className={s.platonus}>
          <Platonus />
        </div>
      ),
    },
    {
      id: "item-2",
      renderItem: (
        <div className={s.img}>
          <img src={Modal4} alt="" />
        </div>
      ),
    },
    {
      id: "item-3",
      renderItem: (
        <div className={s.img}>
          <img src={Modal5} alt="" />
        </div>
      ),
    },
  ];
  const {
    carouselFragment,
    slideToPrevItem,
    slideToNextItem,
    slideToItem,
    activeItem,
  } = useTransitionCarousel({
    withLoop: true,
    items: profile === Role.EDUCATOR ? ItemsEducator : ItemsListener,
  });
  return (
    <CustomModal
      borderAll="none"
      borderRadius={7}
      px={0}
      py={0}
      open={open}
      handleToggleModal={onClose}
      width={1000}
    >
      <div className={s.container}>
        <div className={s.carousel}>{carouselFragment}</div>
        <div className={s.content}>
          {profile === Role.EDUCATOR ? (
            <div className={s.title}>{TextEducator[activeItem].title}</div>
          ) : (
            <div className={s.title}>{TextListener[activeItem].title}</div>
          )}
          {profile === Role.EDUCATOR ? (
            <div className={s.text}>{TextEducator[activeItem].text}</div>
          ) : (
            <div className={s.text}>{TextListener[activeItem].text}</div>
          )}
          <div className={s.buttons}>
            <div className={s.dots}>
              {(profile === Role.EDUCATOR ? ItemsEducator : ItemsListener).map(
                (_, index) => (
                  <div
                    key={index}
                    onClick={() => slideToItem(index)}
                    className={[s.dot, activeItem === index && s.active].join(
                      " "
                    )}
                  ></div>
                )
              )}
            </div>
            <Box>
              <Button
                sx={{ borderRadius: "4px" }}
                size="large"
                variant="contained"
                color="primary"
                onClick={() => slideToNextItem()}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 400,
                    lineHeight: "28px",
                  }}
                >
                  {activeItem === (profile === Role.EDUCATOR ? 2 : 3) ? (
                    <div onClick={() => onClose()}> Начать обучение</div>
                  ) : (
                    "Продолжить"
                  )}
                </Typography>
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default GreetingModal;
