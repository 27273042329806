import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Formik, Form as FormikForm } from "formik";

import { serviceCourse } from '@services/course';
import { resourceTypes } from '@utils/enums';
import CustomModal from '@components/Modal';
import CustomButton from '@ui/Button';
import UploadButton from '@ui/UploadButton';
import FormikAutocomplete from "@ui/formik/Autocomplete";
import FormikInput from '@ui/formik/Input';
import { TextEditor } from '@ui/formik/TextEditor';
import { CourseAdditionalResource, CourseAdditionalResourceResourceTypeEnum } from '@models/course-additional-resource';
import { resourceInitialDto } from './models';
import { validationSchema } from './validation';

interface Props {
  courseId: string;
  openModal: boolean;
  handleToggleModal: (modalState: boolean) => void;
  resource: CourseAdditionalResource;
  updateResourceList: (resource: CourseAdditionalResource, updated: boolean) => void;
}

const AddEditResource = ({ courseId, openModal, handleToggleModal, resource, updateResourceList }: Props) => {
  const { t } = useTranslation(["common", "course"]);
  const { common } = useTheme().palette;
  const [initialValue, setInitialValue] = useState<CourseAdditionalResource>(resource);
  const [file, setFile] = useState<{ id?: string, name?: string }>({});
  const [fileExt, setFileExt] = useState<string | null>(null);
  const [enableAddBtn, setEnableAddBtn] = useState<boolean>(true);

  useEffect(() => {
    if (resource?.id) {
      if (resource?.fileId && resource?.name) {
        const nameWithoutExt = resource.name.split('.')[0];
        setFileExt(resource.name.split('.')[1]);
        setInitialValue((prev: CourseAdditionalResource) => ({ ...prev, name: nameWithoutExt }));
      }
    }
  }, [resource?.id, resource?.fileId, resource?.name])

  const addFile = (fileId: string, fileName?: string) => {
    setFile({ id: fileId, name: fileName });
  }

  return (
    <CustomModal
      open={openModal}
      handleToggleModal={handleToggleModal}
      width={515}
      borderColor={common.primaryColor}
      showCloseIcon
    >
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "36px",
            color: "#000000",
            display: "flex",
            alignItems: "center",
            mb: "24px",
          }}
        >
          {resource?.id ? t("course:content.EDIT_RESOURSE") : t("course:content.ADD_RESOURSE")}
        </Typography>
        <Formik
          initialValues={initialValue}
          enableReinitialize
          validationSchema={validationSchema(t)}
          onSubmit={(values: CourseAdditionalResource) => {
            values.courseId = courseId;
            if (values?.id) {
              if (values.resourceType === CourseAdditionalResourceResourceTypeEnum.FILE) {
                values.name = `${values.name?.trimEnd()}.${fileExt}`;
              }
              serviceCourse.updateResource(values, courseId).then(res => {
                if (res.status === 200) {
                  updateResourceList(values, true);
                }
              });
            } else {
              if (values.resourceType === CourseAdditionalResourceResourceTypeEnum.FILE && file?.name) {
                values.fileId = file.id;
                values.name = `${values.name}.${file.name.split('.')[1]}`;
              }
              serviceCourse.createResource(values, courseId).then(res => {
                if (res.status === 200) {
                  const data = values;
                  data.id = res.data;
                  updateResourceList(data, false);
                }
              });
            }
          }}
        >
          {({ values, submitForm, dirty, isValid }) => {
            if (values?.id) {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2
                  }}
                >
                  <FormikInput name="name" label={t("course:content.TITLE_RESOURSES")} />
                  {values.resourceType === CourseAdditionalResourceResourceTypeEnum.TEXT &&
                    <TextEditor name="content" label={t("course:content.DESCRIPTION")} shortToolbar showScrollEditor showSymbolCount minChar={0} maxChar={500} />
                  }
                  {values.resourceType === CourseAdditionalResourceResourceTypeEnum.LINK &&
                    <FormikInput name="link" label={t("enum:LINK")} />
                  }
                  <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between', mt: '24px' }}>
                    <CustomButton
                      onClick={() => handleToggleModal(false)}
                      width="209px"
                      height="40px"
                      borderRadius="8px"
                      backgroundColor="#F6F6F6"
                      fontColor={common.primaryColor}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      {t("common:actions.CANCEL")}
                    </CustomButton>
                    <CustomButton
                      backgroundColor={common.primaryColor}
                      fontColor={common.fontWhite}
                      width="209px"
                      borderRadius="8px"
                      height="40px"
                      onClick={submitForm}
                      sx={{
                        alignItems: "center",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                      disabled={!(isValid && dirty)}
                    >{t("common:actions.SAVE")}</CustomButton>
                  </Box>
                </Box>
              )
            }
            return (
              <FormikForm>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2
                  }}
                >
                  <FormikAutocomplete
                    label={t("course:content.TYPE_RESOURSE")}
                    name="resourceType"
                    options={resourceTypes}
                    onChange={() => setInitialValue(resourceInitialDto)}
                  />
                  {values.resourceType === CourseAdditionalResourceResourceTypeEnum.FILE &&
                    <Box sx={{ display: 'block' }}>
                      {!file?.id &&
                        <UploadButton
                          label={t("common:label.ADD_FILE")}
                          backgroundColor={common.primaryColor}
                          fontColor={common.fontWhite}
                          width="172px"
                          borderRadius="6px"
                          height="28px"
                          fontWeight={400}
                          typeCode="005"
                          attachAndSaveFile={addFile}
                          acceptedExtensions=".jpg, .jpeg, .png, .pdf, .mp3"
                        />
                      }
                      {file?.id &&
                        <>
                          <FormikInput name="name" label={t("common:label.NAME")} isRequired />
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', mb: '8px', alignItems: 'center' }}>
                              <AttachFileIcon sx={{ width: '15px', height: '15px', mr: '6px' }} />
                              <Typography>{file.name}</Typography>
                            </Box>
                            <DeleteOutlineIcon
                              sx={{ color: common.errorColor, cursor: 'pointer' }}
                              onClick={() => setFile({})}
                            />
                          </Box>
                        </>
                      }
                    </Box>
                  }
                  {values.resourceType === CourseAdditionalResourceResourceTypeEnum.LINK &&
                    <Box>
                      <FormikInput name="name" label={t("common:label.NAME")} isRequired />
                      <FormikInput name="link" label={t("course:content.URL_RESOURSE")} isRequired />
                    </Box>
                  }
                  {values.resourceType === CourseAdditionalResourceResourceTypeEnum.TEXT &&
                    <Box>
                      <FormikInput name="name" label={t("common:label.NAME")} isRequired />
                      <TextEditor name="content" label={t("course:content.DESCRIPTION")}
                        shortToolbar showScrollEditor showSymbolCount minChar={0} maxChar={500}
                        setIsValidSubmitBtn={setEnableAddBtn}
                      />
                    </Box>
                  }
                </Box>
                <Box
                  sx={{
                    mt: "24px",
                    display: "flex",
                    gap: 1
                  }}
                >
                  <CustomButton
                    backgroundColor={common.btnCancel}
                    fontColor={common.primaryColor}
                    borderRadius="6px"
                    height="40px"
                    sx={{
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    onClick={() => handleToggleModal(false)}
                  >
                    {t('actions.CANCEL')}
                  </CustomButton>
                  <CustomButton
                    backgroundColor={common.primaryColor}
                    fontColor={common.fontWhite}
                    borderRadius="6px"
                    height="40px"
                    sx={{
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    disabled={!(dirty && isValid && enableAddBtn)}
                    onClick={submitForm}
                  >
                    {t('actions.ADD')}
                  </CustomButton>
                </Box>
              </FormikForm>
            )
          }}
        </Formik>
      </>
    </CustomModal>
  )
}

export default AddEditResource;