import { ReactElement, FC, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Formik, Form as FormikForm } from "formik";
import { get, capitalize } from "lodash";

import service from "@services/account";
import { serviceUser } from "@services/user";
import { serviceDict } from "@services/dictionary";
import * as types from "@store/account/types";

import AvatarPicture from "@components/AvatarPicture";
import CustomButton from "@ui/Button";
import UploadButton from "@ui/UploadButton";
import FormikInput from "@ui/formik/Input";
import MaskedInput from "@ui/formik/MaskedInput";
import FormikRadioGroup from "@ui/formik/RadioGroup";
import DatePicker from "@ui/formik/DatePicker";
import { IProfile } from "@interfaces/index";
import { DTO, initialDto } from "./models";
import { profileValidationSchema } from "./validation";
import { AlertColor, Grid } from "@mui/material";
import GreetingModal from "@components/modals/GreetingModal/GreetingModal";

interface Props {
  setMessageOpen: (isOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  setMessage: (message: string) => void;
  setMigrated?: (isMigrated: boolean) => void;
}
const PersonalProfile: FC<any> = ({
  setMessageOpen,
  setMessageType,
  setMessage,
  setMigrated,
}: Props): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation([
    "profile",
    "common",
    "enum",
    "user",
    "dictionary",
  ]);
  const profile: IProfile = useSelector((store: any) => store.account.profileInfo);
  const lang = localStorage.getItem("i18nextLng") || "kz";
  const { role, orgId, subdivisionId, eduProgramId } = profile.authorityInfo;
  const { common } = useTheme().palette;
  const [organizationName, setOrganizationName] = useState<string | null>(null);
  const [organizationTypeId, setOrganizationTypeId] = useState<number | null>(null);
  const [subdivisionName, setSubdivisionName] = useState<string | null>(null);
  const [eduProgramName, setEduProgramName] = useState<string | null>(null);
  const [initialData, setInitialData] = useState<DTO>(initialDto);
  const firstTime = localStorage.getItem("firstTime");
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (firstTime === "true") {
      setModal(true);
    }
  }, [firstTime]);

  useEffect(() => {
    if (profile.pltaId) {
      serviceUser.getUserInfoById(profile.pltaId).then((res) => {
        if (res.status === 200) {
          setInitialData((initialData) => ({ ...initialData, ...res.data }));
          if (setMigrated) {
            setMigrated(res.data.migrated);
          }
        }
      });
    }
  }, [profile.pltaId, setMigrated]);

  useEffect(() => {
    if (orgId) {
      serviceDict.getDictionary("BRANCH", orgId).then((res) => {
        setOrganizationTypeId(res.data.branchTypeId);
        setOrganizationName(get(res.data, `name${capitalize(lang)}`));
      });
    }
  }, [orgId, lang]);

  useEffect(() => {
    if (subdivisionId) {
      serviceDict.getDictionary("SUBDIVISION", subdivisionId).then((res) => {
        setSubdivisionName(get(res.data, `name${capitalize(lang)}`));
      });
    }
    if (eduProgramId) {
      serviceDict.getDictionary("EDU_PROGRAM", eduProgramId).then((res) => {
        setEduProgramName(get(res.data, `name${capitalize(lang)}`));
      });
    }
  }, [subdivisionId, eduProgramId, lang]);

  useEffect(() => {
    setInitialData((initialData) => ({
      ...initialData,
      organization: organizationName,
    }));
  }, [organizationName]);

  useEffect(() => {
    setInitialData((initialData) => ({
      ...initialData,
      subdivision: subdivisionName,
      eduProgram: eduProgramName,
    }));
  }, [subdivisionName, eduProgramName]);

  useEffect(() => {
    setInitialData((initialData) => ({
      ...initialData,
      role: t(`roles.${role}`),
    }));
  }, [t, role]);

  const saveProfilePhoto = (photoId: string) => {
    serviceUser.saveProfilePhoto(profile.pltaId || "", photoId).then((res) => {
      if (res.status === 200) {
        setInitialData({ ...initialData, profilePhotoId: photoId });
        dispatch({
          type: types.account("UPDATE_PROFILE_INFO").SUCCESS,
          payload: { ...profile, profilePhotoId: photoId },
        });
      }
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardHeader
        title={t("PERSONAL_INFO")}
        sx={{
          pt: 0,
          pr: 0,
          pl: 0,
          width: "100%",
          borderBottom: `1px solid ${common.strokeSecondary}`,
        }}
        titleTypographyProps={{
          fontWeight: 600,
          fontSize: { xl: 22, lg: 22, md: 20, sm: 18, xs: 16 },
        }}
      />
      <Box
        sx={{
          display: "flex",
          gap: { xl: 6, lg: 6, md: 5, sm: 4, xs: 3 },
          my: { xl: 3, lg: 3, md: 2.8, sm: 2.8, xs: 2.5 },
          width: { xl: "65%", lg: "85%", md: "85%", sm: "90%", xs: "95%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: { xl: 2, lg: 1.8, md: 1.6, sm: 1.4, xs: 1.2 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: { xs: "100%", md: "40%" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AvatarPicture
              id={initialData.profilePhotoId}
              lastName={initialData.lastName || ""}
              sxProps={{
                width: { xl: 130, lg: 100, md: 95, sm: 90, xs: 85 },
                height: { xl: 130, lg: 100, md: 95, sm: 90, xs: 85 },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: { xs: "100%", md: "60%" },
            }}
          >
            <UploadButton
              label={t("user:UPLOAD_IMAGE")}
              backgroundColor={common.secondaryColor}
              fontColor={common.primaryColor}
              borderRadius="6px"
              height="44px"
              typeCode="002"
              width="200px"
              attachAndSaveFile={saveProfilePhoto}
              disabled={initialData.migrated}
            />
            <Typography>{t("dictionary:IMAGE_UP_TO_10_MB")}</Typography>
          </Box>
        </Box>
        <Box sx={{ width: { md: "50%", xs: 0 } }}></Box>
      </Box>
      <Formik
        initialValues={initialData}
        enableReinitialize
        validationSchema={profileValidationSchema(t)}
        onSubmit={(values: DTO) => {
          // console.log(values);
          service
            .updateProfileInfo(values)
            .then((res) => {
              // setSubmitting(true);
              if (res.status === 200) {
                setInitialData(values);
                dispatch({
                  type: types.account("UPDATE_PROFILE_INFO").SUCCESS,
                  payload: {
                    ...profile,
                    lastName: values.lastName,
                    firstName: values.firstName,
                    patronymic: values.patronymic,
                    fullName:
                      `${values.lastName} ${values.firstName} ${values.patronymic}`.trimEnd(),
                  },
                });
                setMessageOpen(true);
                setMessage(t("common:messages.CHANGES_SAVED"));
                setMessageType("success");
                setTimeout(() => {
                  setMessageOpen(false);
                }, 5000);
              }
            })
            .finally(() => {
              setTimeout(() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }, 20);
            });
        }}
      >
        {({ submitForm, dirty, isValid, values }) => (
          <FormikForm>
            <Box
              sx={{
                display: "block",
                width: "100%",
              }}
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{
                  m: 0,
                  width: {
                    xl: "65%",
                    lg: "85%",
                    md: "85%",
                    sm: "90%",
                    xs: "95%",
                  },
                }}
              >
                <Grid item xs={12} md={6}>
                  <FormikInput
                    name="lastName"
                    label={t("profile:SURNAME")}
                    disabled={values.migrated}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikInput
                    name="firstName"
                    label={t("profile:NAME")}
                    disabled={values.migrated}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikInput
                    name="patronymic"
                    label={t("profile:MIDNAME")}
                    disabled={values.migrated}
                  />
                </Grid>
                {role !== "LISTENER" && (
                  <Grid item xs={12} md={6}>
                    <MaskedInput
                      name="iin"
                      label={t("user:IIN")}
                      type="iin"
                      disabled
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label={t("user:DATE_OF_BIRTH")}
                    name="birthdate"
                    disabled={values.migrated}
                    maxDate={new Date()}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MaskedInput
                    name="mobilePhone"
                    label={t("user:PHONE_NUMBER")}
                    type="cellphone"
                    placeholder="+7 (_ _ _) _ _ _-_ _-_ _"
                    disabled={values.migrated}
                  />
                </Grid>
                {role !== "LISTENER" && (
                  <Grid item xs={12} md={6}>
                    <FormikInput name="role" label={t("user:ROLE")} disabled />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <FormikInput
                    name="organization"
                    label={t("user:ORGANIZATION")}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                  <FormikRadioGroup
                    groupLabel={t("user:GENDER")}
                    name="sex"
                    options={[
                      { label: t("user:MALE"), value: "MALE" },
                      { label: t("user:FEMALE"), value: "FEMALE" },
                    ]}
                    disabled={values.migrated}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {role === "LISTENER" && organizationTypeId === 2 && (
                    <FormikInput
                      name="eduProgram"
                      label={t("enum:EDU_PROGRAM")}
                      disabled
                    />
                  )}
                  {role !== "LISTENER" && (
                    <FormikInput
                      name="subdivision"
                      label={t("enum:SUBDIVISION")}
                      disabled
                    />
                  )}
                </Grid>
              </Grid>
              {!values.migrated && (
                <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
                  <CustomButton
                    backgroundColor={common.primaryColor}
                    fontColor={common.fontWhite}
                    borderRadius="6px"
                    width="98px"
                    height="38px"
                    disabled={!(isValid && dirty)}
                    onClick={submitForm}
                  >
                    {t("common:actions.SAVE")}
                  </CustomButton>
                </Box>
              )}
            </Box>
          </FormikForm>
        )}
      </Formik>
      <GreetingModal
        onClose={() => {
          setModal(false);
          localStorage.removeItem("firstTime")
        }}
        open={modal}
        setModal={setModal}
      />
    </Box>
  );
};

export default PersonalProfile;
