import { Box, Button, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HomeVariant } from "../types/home";
import VideoCover from "@assets/images/home/videoCover.png"
import s from "./InspirationStory.module.scss";
import { Role } from "@interfaces/index";

interface Props {
  variant: HomeVariant;
}

interface Result {
  title: string;
  button?: string;
  onClick: () => void;
}
const InspirationStory = (props: Props) => {
  const { variant } = props;
  const { t } = useTranslation(["home"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const userInfo = localStorage.getItem("userInfo");
  const role = JSON.parse(userInfo || "{}")?.authorityInfo?.role;
  const isPublic = true;
  const target =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PRODUCTION_TARGET
      : process.env.REACT_APP_DEVELOPMENT_TARGET;

  const videoRef = useRef<HTMLVideoElement>(null);
  const [isDivVisible, setIsDivVisible] = useState(true);
  const [isVideoCoverVisible, setIsVideoCoverVisible] = useState(true);
  const [mouseEnter, setMouseEnter] = useState(false)

  const handleMouseEnter = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsDivVisible(false);
      setIsVideoCoverVisible(false)
    }
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLVideoElement>) => {
    const relatedTarget = e.relatedTarget as HTMLElement | null;
    if (relatedTarget && relatedTarget.id === 'video') {
      return
    }
    setMouseEnter(false)
  };
  const handleHomeVariant = (e: HomeVariant): Result => {
    switch (e) {
      case HomeVariant.ORGANIZATION:
        return {
          title: t("home:WELLCOME_PLATONUS"),
          button: t('home:ORDER_A_DEMO'),
          onClick: () => {
            searchParams.set("modal", "true");
            setSearchParams(searchParams);
          },
        };
      case HomeVariant.EDUCATOR:
        return {
          title: t("home:WELLCOME_PLATONUS"),
          button: (!role || role === Role.EDUCATOR || role === Role.MANAGER) ? t('home:BECOME_AN_AUTHOR') : undefined,
          onClick: () => {
            navigate(userInfo ? "/myCourses" : "/registration");
          },
        };
      case HomeVariant.LISTENER:
        return {
          title: t("home:WELLCOME_PLATONUS"),
          button: t("home:GO_CATALOG"),
          onClick: () => navigate("/freeCatalogCourse"),
        };
      default:
        return {
          title: t("home:WELLCOME_PLATONUS"),
          button: t("home:GO_CATALOG"),
          onClick: () => navigate("/freeCatalogCourse"),
        };
    }
  };
  const data = handleHomeVariant(variant);

  return (
    <div className={s.wrapper}>
      <div className={s.head}>
        <div className={s.title}> {data.title}</div>
        {data.button &&
          <Box>
            <Button
              sx={{ padding: "8px 22px", borderRadius: "4px" }}
              size="large"
              variant="contained"
              color="primary"
              onClick={data.onClick}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 400,
                  lineHeight: "28px",
                }}
              >
                {data.button}
              </Typography>
            </Button>
          </Box>
        }
      </div>
      <div className={s.content}>
        <div className={s.card}>
          <div className={s.player}

          >
            <video
              ref={videoRef}
              width={"100%"}
              height={"max-content"}
              controls={mouseEnter}
              loop
              preload="metadata"
              controlsList="nodownload"
              onMouseEnter={() => setMouseEnter(true)}
              onMouseLeave={handleMouseLeave}
              onPlay={() => setIsDivVisible(false)}
              onPause={() => setIsDivVisible(true)}
              style={{
                height: "inherit",
                borderRadius: '32px',
                border: '1px solid #E8E8E8'
              }}
            >
              <source
                src={`${target}/video-streaming${isPublic ? "/public" : ""
                  }/video/${['dev', 'test'].some(i => window.location.origin.includes(i)) ? "49b3090b-a59d-43d8-9596-7357c96c999e" : "7c83c148-49ab-4712-9c2d-b48d30cfbcfb"}`}
              />
            </video>
            {isVideoCoverVisible && (
              <img className={s.videoCover} src={VideoCover} alt="videoCover"/>
            )}
            {isDivVisible && (
              <div onClick={handleMouseEnter} className={s.controller} id='video'>
                <svg
                  width="24"
                  height="30"
                  viewBox="0 0 24 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.16406 3.33398L20.6641 15.0007L3.16406 26.6673V3.33398Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="4.86111"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InspirationStory;
