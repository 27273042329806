import { useState } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Avatar, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import i18n from '../../i18n';
import CustomAutocomplete from '@ui/Autocomplete';
import RegistrationForm from './RegistrationForm';
import VerificationCode from './VerificationCode';
import { langOptions } from '@components/Header';

export interface DTO {
  lastName: string;
  firstName: string;
  email: string;
  phone: number | string;
  privacyPolicy: boolean;
  roleCode: string;
}
export const initialDto: DTO = {
  lastName: "",
  firstName: "",
  email: "",
  phone: "",
  privacyPolicy: false,
  roleCode: ""
}

const RegistrationPage = () => {
  const [isRegState, setIsRegState] = useState(true)
  const [formValues, setFormValues] = useState<DTO>(initialDto);
  const { common } = useTheme().palette;
  const [regWithEmail, setRegWithEmail] = useState(true);

  return (
    <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 11,
      height: 'auto',
      minHeight: '101vh',
      background: 'linear-gradient(to left, #2B51DA 11.62%, #ED58B3 127.36%, #EE58B2 127.37%)'
    }}>
      <Box sx={{
        width: '100%', height: '94px', backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 24px'
      }}>
        <RouterLink to="/">
          <Avatar alt="reg-header-logo" variant='square' src={require(`@assets/images/regHeaderIcon.png`)}
            sx={{
              height: 43,
              width: 136,
              cursor: 'pointer'
            }}></Avatar>
        </RouterLink>
        <Box>
          <CustomAutocomplete
            name="lang"
            options={langOptions}
            clearable={false}
            onChange={(_, value: string) => i18n.changeLanguage(value)}
            value={i18n.language}
            customIconDisabled={false}
            sx={{ color: common.primaryColor, borderRadius: '4px' }}
          />
        </Box>
      </Box>
      <Box sx={{ width: '100%', height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {isRegState ?
          <RegistrationForm
            setIsRegState={setIsRegState}
            initialData={formValues}
            setInitialData={setFormValues}
            regWithEmail={regWithEmail}
            setRegWithEmail={setRegWithEmail}
          /> :
          <VerificationCode
            setIsRegState={setIsRegState}
            formValues={formValues}
            regWithEmail={regWithEmail}
          />
        }
      </Box>
    </Box>
  )
}

export default RegistrationPage;
