import React, { useContext, useEffect, useState } from 'react'
import { CourseContext } from '@context/CourseContext';
import { DurationType, IMaterial } from '@interfaces/index';
import { Box, Typography, useTheme, Avatar, useMediaQuery, Link } from '@mui/material';
import { downloadFile } from '@utils/functions';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { serviceCourse } from '@services/course';
import FileViewerModal from '@components/modals/FileViewerModal';

export const MaterialList = () => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(['common', 'course']);
  const theme = useTheme();
  const { themeId } = useParams();
  const matchesDown1000px = useMediaQuery(theme.breakpoints.down(1000));
  const { courseData } = useContext(CourseContext);
  const durationType = courseData?.durationType;
  const [materialList, setMaterialList] = useState<Array<IMaterial>>([]);
  const [material, setMaterial] = useState<IMaterial>();
  const [openFileModal, setOpenFileModal] = useState<boolean>(false);
  
  
  const handleToggleFileModal = (modalState: boolean, material?: IMaterial) => {
    if (material) setMaterial(material);
    setOpenFileModal(modalState);
  }

  useEffect(() => {
    if (themeId) {
      serviceCourse.getThemeMaterials(themeId).then(res => {
        setMaterialList(res.data);
      })
    }
  }, [themeId]);


  return (
    <Box
      sx={{
        width: matchesDown1000px ? '100%' : '256px',
        height: 'auto',
        borderRadius: '12px',
        borderTop: 'solid 12px #FD0053',
        backgroundColor: common.white,
        ml: matchesDown1000px ? 0 : '26px',
        mt: (!matchesDown1000px && durationType === DurationType.INDEFINITE) ? 0 : (!matchesDown1000px && durationType === DurationType.INDEFINITE) ? '50px' : '20px'
      }}
    >
      <Box sx={{ display: 'flex', ml: '18px' }}>
        <FolderOutlinedIcon sx={{ mr: '10px' }} />
        <Typography sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '24px' }}>{t('course:ADDITIONAL_MATERIALS')}</Typography>
      </Box>
      <Box sx={{ pb: 1.25 }}>
        {materialList?.length > 0 ? materialList.map((file) => (
          <Box
            key={file?.id}
            sx={{
              color: '#9C9C9C',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              py: 1,
              px: 2,
              borderTop: `1px solid ${common.strokePrimary}`
            }}
          >
            <Typography onClick={() => handleToggleFileModal(true, file)} sx={{ display: 'flex', color: 'black', alignItems: 'center', wordBreak: 'break-word', cursor: 'pointer'}}>
              <AttachFileIcon sx={{ mr: '8px', width: '15px', height: '15px', color: 'black' }} />
              {file.url ? <Link href={`${file.url}`} target='_blank'>{file.name}</Link> : file.name}
            </Typography>
            {file.downloadable && !file.url &&
              <FileDownloadOutlinedIcon sx={{ color: 'black', cursor: 'pointer' }} onClick={() => downloadFile(file.fileId)} />
            }
          </Box>
        )) :
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar
              alt="Empty theme materials"
              src={require(`@assets/images/emptyDataBg.png`)}
              variant="square"
              sx={{
                height: "90px",
                width: "90px",
              }}
            />
            <Typography fontSize="11px" textAlign="center">{t('course:theme.NO_ADDITIONAL_MATERIALS_FOR_TOPIC')}</Typography>
          </Box>
        }
      </Box>
      {openFileModal &&
        <FileViewerModal
          openModal={openFileModal}
          handleToggleModal={handleToggleFileModal}
          file={material}
        />
      }
    </Box>
  )
}
