import { Box, Fab, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import CustomModal from "@components/Modal";
import VideoPlayer from "@components/VideoPlayer";

interface IProps {
  open: boolean;
  handleToggleModal: any;
  videoId?: string;
  videoUrl?: string;
}

export const PromoVideoModal = ({
  open,
  handleToggleModal,
  videoId,
  videoUrl,
}: IProps) => {
  const { common } = useTheme().palette;

  const closeIcon = () => {
    return (
      <Fab
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          margin: 0.625,
          zIndex: 1,
          backgroundColor: '#F8F8F8',
          border: `1px solid ${common.strokePrimary}`,
          boxShadow: 0
        }}
        size="small"
        onClick={() => handleToggleModal()}
      >
        <CloseIcon sx={{ color: common.errorColor }} />
      </Fab>
    )
  }
  return (
    <CustomModal
      open={open}
      handleToggleModal={handleToggleModal}
      width={"870px"}
      px={0}
      py={0}
    >
      <Box sx={{ position: 'relative' }}>
        {videoId ?
          <VideoPlayer videoFileId={videoId} isPublic /> :
          <iframe
            width="100%"
            height="500px"
            src={videoUrl}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={'Course promo video'}
          />
        }
        {closeIcon()}
      </Box>
    </CustomModal>
  );
};
