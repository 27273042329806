import React, { useEffect, useState } from 'react'
import { Table, TableCell, TableContainer, TableHead, TableRow, Box, useTheme, TableBody, CardMedia, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { DTO } from '../models';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

export const ViolationsPage = ({ aeroInfo, dtoCourse }: { aeroInfo: any, dtoCourse: DTO }) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["aero", "common"]);

  function timeOfNumber(value) {
    let time = '00';
    switch (value.toString().length) {
      case 1: return time = `0${value}`
      case 2: return time = `${value}`
      default: time = '00'
    }
    return time
  }

  function violationTime(value) {
    const minutes = timeOfNumber(Math.trunc(value / 60))
    const seconds = timeOfNumber(value % 60)
    return `${minutes}:${seconds}`
  }

  function capitalizeViolationHeaviness(value) {
    const regex = new RegExp(`[_/](.)`, 'g');
    return value.replace(regex, (match, group) => {
      const capitalizedGroup = group.charAt(0).toUpperCase() + group.slice(1);
      return capitalizedGroup;
    });
  }

  return (
    <Box
      sx={{
        width: "100%",
        background: common.fontWhite,
        padding: "10px 25px",
        marginTop: "10px",
        borderRadius: 3,
      }}
    >
      <TableContainer sx={{ mt: '16px', width: "100%" }}>
        <Table>
          <TableHead >
            <TableRow>
              <TableCell sx={{ width: "20%" }}>{t("aero:VIOLATION")}</TableCell>
              <TableCell sx={{ width: "40%" }}>{t("aero:DETAILS")}</TableCell>
              <TableCell sx={{ width: "20%" }}>{t("aero:SEVERITY_VIOLATION")}</TableCell>
              <TableCell sx={{ width: "20%" }}>{t("aero:TIME_VIOLATION")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {aeroInfo?.cheatings.map((cheating) => {
              return (<TableRow>
                <TableCell>{t(`aero:${cheating?.cheatingType}`)}</TableCell>
                <TableCell>
                  {cheating?.cheatingType === '@AUDIO' && <Box>
                    {cheating?.content.replace(/[0-9-]/g, "").replace(/[:]/g, ",")}</Box>}
                  {cheating?.cheatingType === "@TABS/FORBIDDEN_TAB" &&
                    <Link href={`${cheating?.content}`} style={{ textDecoration: "none" }} target="_blank">
                      <Typography color={common.primaryColor}>{cheating?.content}</Typography>
                    </Link>}
                  {cheating?.image && <Box>
                    <CardMedia
                      component='img'
                      src={cheating?.image}
                      onClick={() => { }}
                      sx={{
                        width: "160px",
                        height: "160px",
                        borderRadius: 3,
                        cursor: "pointer"
                      }} />
                    <Box sx={{ padding: "5px", background: common.white, position: "absolute", margin: "-40px 0 0 5px", borderRadius: "16px" }}>
                      <a href={`${cheating?.image}`} download="image">
                        <FileDownloadOutlinedIcon sx={{ color: common.primaryColor, display: 'flex', cursor: "pointer" }} />
                      </a>
                    </Box>
                  </Box>}
                </TableCell>
                <TableCell>{dtoCourse?.violation && t(`aero:${dtoCourse?.violation[capitalizeViolationHeaviness(cheating?.cheatingType.toLowerCase().replace("@", ""))]}`)}</TableCell>
                <TableCell>{violationTime(cheating?.startTime)} - {violationTime(cheating?.endTime)}</TableCell>
              </TableRow>)
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
