import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, useTheme } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { serviceCourse } from '@services/course';
import { serviceDict } from '@services/dictionary';
import MathJaxComponent from '@components/MathJaxComponent';
import { GZFormulaModal } from '@pages/myCourses/Student/TabList/Main/GZFormulaModal';
import { IOFormulaModal } from '@pages/myCourses/Student/TabList/Main/IOFormulaModal';
import { FX100NotGroupsGZModal } from '@pages/myCourses/Student/TabList/Main/FX100NotGroupsGZModal';
import { FX100NotGroupsIOModal } from '@pages/myCourses/Student/TabList/Main/FX100NotGroupsIOModal';
import { FX100GZModal } from '@pages/myCourses/Student/TabList/Main/FX100GZModal';
import { FX100IOModal } from '@pages/myCourses/Student/TabList/Main/FX100IOModal';
import CallMadeIcon from '@mui/icons-material/CallMade';

const taskStatsBoxStyle = {
  background: "#697BE3",
  height: "75px",
  borderRadius: "10px",
  padding: "10px",
  color: "#fff",
  cursor: "pointer",
  '&:hover': { backgroundColor: '#475BCE' }
}
interface Props {
  gradingSystemId: string | number;
  roleType: string | null,
  changeStep: (step: number, label: string) => void
}

const Evaluation = ({ gradingSystemId, roleType, changeStep }: Props) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course"])
  const { courseId } = useParams();

  const [openGZModal, setGZModal] = useState(false)
  const [openIOModal, setIOModal] = useState(false)
  const navigate = useNavigate();
  const [tasksSummary, setTasksSummary] = useState<any>(null)
  const [gradingSystemData, setGradingSystemData] = useState<any | null>([])

  const FX100GZFormula = "`ГЗ = \\frac{\\sum_{i=1}^n O_{3_{1}} + O_{3_{2}} + ... + O_{3_{n}}}{N}`";
  const FX100IOFormula = "`ИО_{курса}=(ГЗ_{1} * Д_{ГЗ_{1}}) + (ГЗ_{2} * Д_{ГЗ_{2}}) + ... + (ГЗ_{N} * Д_{ГЗ_{N}})`";
  const FX100NotGroupsGZFormula = "`O_{3} = \\sum_{i=1}^n O_{1} + O_{2} + ... + O_{n}`";
  const FX100NotGroupsIOFormula = "`ИО_{курса}=\\frac{O_{3}}{N}`";
  const GZFormula = "`Г_{3} = \\sum_{i=1}^n Б_{3_{1}} + Б_{3_{2}} + ... + Б_{3_{n}}`";
  const IOFormula = "`ИО_{курса}=(ГЗ_{1} * Д_{ГЗ_{1}}) + (ГЗ_{2} * Д_{ГЗ_{2}}) + ... + (ГЗ_{N} * Д_{ГЗ_{N}})`";

  function handleGZModal() {
    setIOModal(false);
    setGZModal(true);
  }
  function handleIOModal() {
    setGZModal(false);
    setIOModal(true);
  }
  useEffect(() => {
    if (gradingSystemId) {
      serviceDict.getGradingSystemById(gradingSystemId.toString()).then((res) => {
        if (res.status === 200) {
          setGradingSystemData(res.data);
        }
      });
    }
  }, [gradingSystemId])

  useEffect(() => {
    if (courseId) {
      serviceCourse.getTasksSummary(courseId).then((res) => {
        if (res.status === 200) {
          setTasksSummary(res.data)
        }
      })
    }
  }, [courseId])

  return (
    <Box>
      {(((gradingSystemId.toString() === "2" || gradingSystemId.toString() === "1") && tasksSummary?.taskSum > 0)
        || (tasksSummary?.groupSum > 0 && tasksSummary?.taskSum > 0)) &&
        <Accordion defaultExpanded={true}
          disableGutters={true}
          sx={{
            backgroundColor: common.rootBgSecondary,
            borderRadius: 1,
          }}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            sx={{
              display: 'flex',
              justifyContent: "space-between"
            }}
          >
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Typography sx={{
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "18px"
              }}>
                {t('course:listener.GRADING_SYSTEM')}: {gradingSystemData?.name}
              </Typography>
              <ExpandMoreIcon />
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={8} sx={{ background: "#F0F2FF", padding: "20px", borderRadius: "10px" }}>
                <Typography fontSize="18px" margin="0 0 20px 0" fontWeight={500}>{t("course:listener.FORMULA_CALCULATING_FINAL_GRADE_COURSE")}</Typography>
                <Typography fontSize="14px" fontWeight={600}
                  sx={{ cursor: "pointer", ":hover": { color: common.primaryColor } }} onClick={() => handleGZModal()}>
                  1. {t("course:listener.TOTAL_SCORE_GROUP_TASKS")}:
                </Typography>
                <Box sx={{ background: 'rgba(105, 123, 227, 0.2)', '&:hover': { background: 'rgba(105, 123, 227, 0.4)' }, color: "black", margin: "0 auto", cursor: "pointer", border: '1px solid #697BE3', borderRadius: '8px', mb: 2 }} onClick={() => handleGZModal()}>
                  {gradingSystemId.toString() === "1" && (tasksSummary?.groupSum > 0 ?
                    <MathJaxComponent formula={FX100GZFormula} /> :
                    <MathJaxComponent formula={FX100NotGroupsGZFormula} />)}
                  {gradingSystemId.toString() === "2" && (tasksSummary?.groupSum > 0 ?
                    <MathJaxComponent formula={FX100GZFormula} /> :
                    <MathJaxComponent formula={FX100NotGroupsGZFormula} />)}
                  {gradingSystemId.toString() === "3" && <MathJaxComponent formula={GZFormula} />}
                </Box>
                <Typography fontSize="14px" fontWeight={600}
                  sx={{ cursor: "pointer", ":hover": { color: common.primaryColor } }} onClick={() => handleIOModal()}>
                  2. {t("course:listener.GENERAL_FORMULA_ROI_COURSE")}:
                </Typography>
                <Box sx={{ background: 'rgba(105, 123, 227, 0.2)', '&:hover': { background: 'rgba(105, 123, 227, 0.4)' }, color: "black", margin: "0 auto", cursor: "pointer", border: '1px solid #697BE3', borderRadius: '8px' }} onClick={() => handleIOModal()}>
                  {gradingSystemId.toString() === "1" && (tasksSummary?.groupSum > 0 ?
                    <MathJaxComponent formula={FX100IOFormula} /> :
                    <MathJaxComponent formula={FX100NotGroupsIOFormula} />)}
                  {gradingSystemId.toString() === "2" && (tasksSummary?.groupSum > 0 ?
                    <MathJaxComponent formula={FX100IOFormula} /> :
                    <MathJaxComponent formula={FX100NotGroupsIOFormula} />)}
                  {gradingSystemId.toString() === "3" && <MathJaxComponent formula={IOFormula} />}
                </Box>
              </Grid>
              <Grid item xs={12} lg={4} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box
                  sx={taskStatsBoxStyle}
                  onClick={roleType !== "ADMIN" && roleType !== "LOCAL_ADMIN" ? () => {
                    changeStep(6, "course:CONTENT_COURSE")
                    navigate(`/myCourses/${courseId}?contentTab=tasks&taskTab=groupTasks`)
                  } : undefined}
                >
                  <Typography fontSize="14px">{t('course:task.TOTAL_TASK_GROUPS')}</Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography fontSize="28px" fontWeight={600}>{tasksSummary?.groupSum}</Typography>
                    <CallMadeIcon width='24px' />
                  </Box>
                </Box>
                <Box
                  sx={taskStatsBoxStyle}
                  onClick={roleType !== "ADMIN" && roleType !== "LOCAL_ADMIN" ? () => {
                    changeStep(6, "course:CONTENT_COURSE")
                    navigate(`/myCourses/${courseId}?contentTab=tasks&taskTab=tasks`)
                  } : undefined}
                >
                  <Typography fontSize="14px">{t('course:task.TOTAL_NUMBER_OF_TASKS')}</Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography fontSize="28px" fontWeight={600}>{tasksSummary?.taskSum}</Typography>
                    <CallMadeIcon width='24px' />
                  </Box>
                </Box>
                <Box
                  sx={taskStatsBoxStyle}
                  onClick={roleType !== "ADMIN" && roleType !== "LOCAL_ADMIN" ? () => {
                    changeStep(6, "course:CONTENT_COURSE")
                    navigate(`/myCourses/${courseId}?contentTab=tasks&taskTab=tasks`)
                  } : undefined}
                >
                  <Typography fontSize="14px">{t('course:task.TOTAL_NUMBER_OF_TESTS')}</Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography fontSize="28px" fontWeight={600}>{tasksSummary?.controlTaskSum}</Typography>
                    <CallMadeIcon width='24px' />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>}
      {gradingSystemId.toString() === "3" && <GZFormulaModal open={openGZModal} handleToggleModal={() => setGZModal(false)} />}
      {gradingSystemId.toString() === "3" && <IOFormulaModal open={openIOModal} handleToggleModal={() => setIOModal(false)} />}
      {
        gradingSystemId.toString() === "2" && (tasksSummary?.groupSum > 0 ?
          <FX100GZModal open={openGZModal} handleToggleModal={() => setGZModal(false)} /> :
          <FX100NotGroupsGZModal open={openGZModal} handleToggleModal={() => setGZModal(false)} />)
      }
      {
        gradingSystemId.toString() === "2" && (tasksSummary?.groupSum > 0 ?
          <FX100IOModal open={openIOModal} handleToggleModal={() => setIOModal(false)} /> :
          <FX100NotGroupsIOModal open={openIOModal} handleToggleModal={() => setIOModal(false)} />)
      }
      {
        gradingSystemId.toString() === "1" && (tasksSummary?.groupSum > 0 ?
          <FX100GZModal open={openGZModal} handleToggleModal={() => setGZModal(false)} /> :
          <FX100NotGroupsGZModal open={openGZModal} handleToggleModal={() => setGZModal(false)} />)
      }
      {
        gradingSystemId.toString() === "1" && (tasksSummary?.groupSum > 0 ?
          <FX100IOModal open={openIOModal} handleToggleModal={() => setIOModal(false)} /> :
          <FX100NotGroupsIOModal open={openIOModal} handleToggleModal={() => setIOModal(false)} />)
      }
    </Box >
  )
}

export default Evaluation
