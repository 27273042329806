interface Event {
  startDate: string;
  endDate: string;
}

export function findTimeInterval(currentDate, intervals) {
  // if array have nothing
  if (intervals.length === 0) {
    return false;
  }

  const lastInterval = intervals[intervals.length - 1];
  const endDateOfLastInterval = new Date(lastInterval.endDate);

  return currentDate < endDateOfLastInterval;
}

export function earliestDate(events: Event[]): Event | null {
  if (events.length === 0) return null;

  let earliestEvent: Event | null = null;
  let earliestDateTime: Date | null = null;

  for (const event of events) {
    const startDateTime: Date = new Date(event.startDate);

    if (!earliestDateTime || startDateTime < earliestDateTime) {
      earliestDateTime = startDateTime;
      earliestEvent = event;
    }
  }

  return earliestEvent;
}

export function latestDate(events: Event[]): Event | null {
  if (events.length === 0) return null;

  let latestEvent: Event | null = null;
  let latestDateTime: Date | null = null;

  for (const event of events) {
    const endDateTime: Date = new Date(event.endDate);

    if (!latestDateTime || endDateTime > latestDateTime) {
      latestDateTime = endDateTime;
      latestEvent = event;
    }
  }

  return latestEvent;
}
