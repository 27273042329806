import { AdditionalMaterialTypes } from '@interfaces/index';
import { removeHtml } from '@utils/functions';
import * as yup from "yup";

export const courseWithPeriodValidationSchema = (t: any) =>
  yup.object({
    name: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    shortDescription: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD"))
      .max(200, t("common:validation.DESCRIPTION_IS_TOO_LONG")),
    description: yup.string().nullable(),
    videoId: yup
      .string()
      .nullable()
      .when(["videoType", "description"], {
        is: (videoType: string, description: string) => videoType === AdditionalMaterialTypes.FILE && !removeHtml(description || ''),
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
        otherwise: yup.string().nullable(),
      }),
    url: yup
      .string()
      .nullable()
      .when(["videoType", "description"], {
        is: (videoType: string, description: string) => videoType === AdditionalMaterialTypes.LINK && !removeHtml(description || ''),
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
        otherwise: yup.string().nullable(),
      })
  });

export const courseWithoutPeriodValidationSchema = (t: any) => courseWithPeriodValidationSchema(t).shape({
  estimatedTime: yup
    .number()
    .nullable()
    .required(t("common:validation.MANDATORY_FIELD")),
})

export const validationSchemaForLink = (t: any) =>
  yup.object({
    name: yup
      .string()
      .nullable()
      .max(64)
      .required(t("common:validation.MANDATORY_FIELD")),
    url: yup
      .string()
      .nullable()
      .matches(
        /^(http:\/\/)|(https:\/\/)/,
        t("common:validation.INCORRECT_FORMAT_OF_URL")
      )
      .required(t("common:validation.MANDATORY_FIELD")),
  });

export const recipientsValidationSchema = (t) => {
  yup.object({
    isCloseAccess: yup.boolean(),
    dateOfAccess: yup
      .string()
      .nullable()
      .when("isCloseAccess", {
        is: true,
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      }),
  })
}
