import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Link,
  Rating,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

import {
  useGetIsCurrentStudentLeftReviewQuery,
  useGetMyReviewCourseQuery,
} from "@services/courseRating";
import { useGetCourseReviewInfoQuery } from "@services/public";
import { useBreadcrumbs } from "@context/BreadcrumbsContext";
import { useCourseContext } from "@context/CourseContext";
import Breadcrumbs from "@components/Breadcrumbs";
import RateCourseModal from "@components/modals/RateCourseModal";
import CourseReviewModal from "@pages/freeCourse/courseDetails/Review/CourseReviewModal";
import CourseMainPage from "../TabList/Main";
import { ConferenceCurrentCourses } from "./Conference";
import { ContentCompletedCourses } from "./Content";
import Notes from "../TabList/Notes";
import { TaskCompletedCourse } from "./Task";
import { IBreadcrumb, TypeCode } from "@interfaces/index";
import { useSearchParams } from "react-router-dom";
import CustomModal from "@components/Modal";

const CoursePage = () => {
  const { classId } = useParams();
  const { t } = useTranslation(["course", "common"]);
  const { common } = useTheme().palette;
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const [searchParams] = useSearchParams();
  const currentRate = searchParams.get("currentRate") ?? 0;
  const done = searchParams.get("done");
  const stepLabel =
    getBreadcrumbs(classId || "").find((x: IBreadcrumb) => x.level === 2)?.label ||
    t("course:listener.MAIN_PAGE");
  const completedStep =
    getBreadcrumbs(classId || "").find((x: IBreadcrumb) => x.level === 2)?.tabNum || 1;
  const { courseData } = useCourseContext();
  const changeStep = (step: number, label: string) => {
    const newCrumbs = [...getBreadcrumbs(classId || "")].map((crumb) => {
      if (crumb.level === 2) {
        return { ...crumb, label, tabNum: step };
      }
      return crumb;
    });
    if (classId) setBreadcrumbs(classId, newCrumbs);
  };
  const [openRateModal, setOpenRateModal] = useState(false);
  const [openRateModalRatingReview, setOpenRateModalRatingReview] = useState(false);
  const [openGratefulModal, setOpenGratefulModal] = useState(false)

  const tabList = [
    {
      label: t("course:MAIN_PAGE"),
      step: 1,
    },
    {
      label: t("course:CONTENT"),
      step: 2,
    },
    {
      label: t("course:TASKS"),
      step: 3,
    },
    {
      label: t("course:CONFERENCES"),
      step: 4,
    },
    {
      label: t("course:NOTE"),
      step: 5,
    },
  ];

  useEffect(() => {
    let updatedCrumbs = [...getBreadcrumbs(classId || "")].filter(
      (x: IBreadcrumb) => x.level !== 3 && x.level !== 4
    );
    if (updatedCrumbs.length === 1) {
      updatedCrumbs.push({
        level: 2,
        page: "course",
        label: t("course:listener.MAIN_PAGE"),
        path: window.location.pathname,
        tabNum: 1,
      });
    }
    // Check if the breadcrumbs have actually changed before updating
    if (JSON.stringify(updatedCrumbs) !== JSON.stringify(getBreadcrumbs(classId || ""))) {
      if (classId) setBreadcrumbs(classId, updatedCrumbs);
    }
  }, [getBreadcrumbs, setBreadcrumbs, t, classId]);

  const { data: isCanLeftReview } = useGetIsCurrentStudentLeftReviewQuery(
    courseData?.courseId
  );

  const { data: courseReview } = useGetCourseReviewInfoQuery(
    courseData?.courseId,
    {
      skip: !courseData?.courseId,
    }
  );

  const { data: currentCourseReview } = useGetMyReviewCourseQuery(
    courseData?.courseId,
    {
      skip: !courseData?.courseId,
    }
  );

  const handleMessageStatus = (n: number) => {
    if ((n) => 0 && n <= 30) {
      return "BADLY";
    } else if (n > 0 && n <= 30) {
      return "NOTBAD";
    } else if (n > 30 && n <= 40) {
      return "BADLY";
    } else if (n > 40 && n <= 50) {
      return "FINE";
    }
    if (n > 50 && n <= 100) {
      return "GREAT";
    } else {
      return "";
    }
  };

  const handleColorStatus = (n: number) => {
    if ((n) => 0 && n <= 30) {
      return "#D62B20";
    } else if (n > 30 && n <= 40) {
      return "#FD9C15";
    } else if (n > 40 && n <= 50) {
      return "#EAC600";
    }
    if (n > 50 && n <= 100) {
      return "#00B998";
    } else {
      return "";
    }
  };

  const handlePassedStatus = (n: number) => {
    if (n > 50) {
      return "COURSE_PASSED";
    } else {
      return "COURSE_NOT_PASSED";
    }
  };

  return (
    <Box display={"flex"} gap={3} flexDirection={"column"}>
      <Box sx={{ display: "flex" }}>
        <Breadcrumbs id={classId} />
      </Box>
      <Box display={"flex"} gap={2} width={"100%"}>
        {tabList.map((item, index) => {
          if (completedStep !== item.step) return null;
          return (
            <Box
              display={"flex"}
              flex={"1"}
              key={`course_creation_step_${item.step}_${index}`}
            >
              <Box sx={{ width: "100%", flex: "1", borderRadius: "12px", height: "auto" }}>
                {item.step !== 1 && (
                  <Typography fontWeight={500} fontSize="36px">
                    {stepLabel}
                  </Typography>
                )}
                {(() => {
                  switch (item.step) {
                    case 1:
                      return <CourseMainPage />;
                    case 2:
                      return <ContentCompletedCourses />;
                    case 3:
                      return <TaskCompletedCourse />;
                    case 4:
                      return <ConferenceCurrentCourses />;
                    case 5:
                      return <Notes />;
                    default:
                      return <CourseMainPage />;
                  }
                })()}
              </Box>
            </Box>
          );
        })}
        <Box display={"flex"} alignItems={"flex-start"} flex={"0 0 256px"} width={"100%"}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "sticky",
                top: "80px",
                alignItems: "center",
                width: { xs: "250px", sm: "550px", lg: "100%" },
                overflowX: "auto",
              }}
            >
              <Box
                sx={{
                  background: common.fontWhite,
                  width: "100%",
                  display: "flex",
                  flexDirection: { lg: "column", xs: "row" },
                  alignItems: "center",
                  borderRadius: "12px",
                  gap: 2,
                  py: 2,
                  overflowX: "auto",
                }}
              >
                {tabList.map((item, index) => {
                  return (
                    <Button
                      key={`course_creation_step_btns_${item.step}_${index}`}
                      sx={{
                        background:
                          completedStep === item.step
                            ? common.primaryColor
                            : common.fontWhite,
                        color:
                          completedStep === item.step
                            ? common.fontWhite
                            : common.primaryColor,
                        "&.MuiButtonBase-root:hover": {
                          background: common.secondaryColor,
                          color: common.primaryColor,
                        },
                        borderRadius: "8px",
                        width: { lg: "85%", xs: "150px" },
                        minWidth: "120px",
                        border: `2px solid ${common.primaryColor}`,
                        textTransform: "none",
                        fontSize: { sm: "14px", lg: "16px" },
                        height: { xs: "50px", lg: "auto" },
                      }}
                      onClick={() => changeStep(item.step, item.label)}
                    >
                      {item.label}
                    </Button>
                  );
                })}
              </Box>
              {courseData?.typeCode !== TypeCode.EDU &&
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    borderRadius: "12px",
                    borderTop: `solid 12px ${common.primaryColor}`,
                    mt: 3,
                    background: common.white,
                    padding: 2,
                  }}
                >
                  {!isCanLeftReview ? (

                    <>
                      <Box display="flex" py="10px" alignItems="center">
                        <Typography fontSize={"18px"} fontWeight={500}>
                          {t("course:MEASURE_GRADE")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography fontWeight={400} fontSize={14}>
                          {t("course:GET_VALUABLE_GRADES")}{" "}
                          <Link
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => setOpenRateModal(true)}
                            underline="none"
                            color={"#9469E3"}
                          >
                            {t("course:MEASURE_GRADE_LEFT_COMMENT")}
                          </Link>
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: 18,
                        fontWeight: 500,
                      }}
                    >
                      {t("course:YOUR_GRADES")}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Rating
                      name="simple-controlled"
                      value={
                        currentCourseReview?.grade || courseReview?.avgRating || 0
                      }
                      readOnly
                      precision={0.5}
                      icon={<StarIcon fontSize="inherit" />}
                      emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    />
                  </Box>
                  <Typography
                    onClick={() => {
                      setOpenRateModalRatingReview(true);
                    }}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    {courseReview?.reviewSum} {""}{" "}
                    {t("course:GRADES").toLowerCase()}
                  </Typography>
                </Box>
              }
              {completedStep === 3 && courseData?.typeCode !== TypeCode.EDU && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    borderRadius: "12px",
                    borderTop: `solid 12px ${handleColorStatus(
                      Number(currentRate)
                    )}`,
                    mt: 3,
                    background: common.white,
                    padding: "16px 24px",
                  }}
                >
                  <Typography fontWeight={500} fontSize={20}>
                    {t(`common:${handlePassedStatus(Number(currentRate))}`)}
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Stack>
                      <Box>
                        <Typography
                          color={handleColorStatus(Number(currentRate))}
                          fontWeight={500}
                          fontSize={24}
                        >
                          {currentRate}%
                        </Typography>
                      </Box>
                      <Box>
                        <Typography color={"#6D737A"}>
                          {t(
                            `common:${handleMessageStatus(Number(currentRate))}`
                          )}
                        </Typography>
                      </Box>
                    </Stack>
                    <Box display={"flex"} alignItems={"flex-end"}>
                      <Box
                        p="2px 12px"
                        borderRadius={"15px"}
                        sx={{
                          backgroundColor: "#E8E8E8",
                        }}
                        height={"fit-content"}
                      >
                        {done} заданий
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <RateCourseModal
        key={"course_creation_rate_modal"}
        openModal={openRateModal}
        handleToggleModal={(openRateModal) => setOpenRateModal(openRateModal)}
        handleGratefulModal={setOpenGratefulModal}
      />
      <CourseReviewModal
        key={"course_creation_review_modal"}
        courseId={courseData?.courseId}
        open={openRateModalRatingReview}
        handleToggleModal={(openRateModalRatingReview) =>
          setOpenRateModalRatingReview(openRateModalRatingReview)
        }
      />
      <CustomModal
        open={openGratefulModal}
        handleToggleModal={setOpenGratefulModal}
        width={494}
        borderColor={common.primaryColor}
        showCloseIcon
      >
        <Box sx={{ display: 'block' }}>
          <Typography sx={{ color: "#4D5377", display: "flex", alignItems: "center", textAlign: "center", justifyContent: 'center', fontSize: '20px', fontWeight: '500', lineHeight: '26px' }}>
            {t('course:messages.YOUR_GRADE_AND_FEEDBACK_ARE_IMPORTANT_TO_US')}
          </Typography>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default CoursePage;
