import React, { useState } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import kk from "date-fns/locale/kk";
import { Box, Button, SxProps, TextField, Typography, useTheme } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TimePicker from 'react-time-picker';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
registerLocale('ru', ru);
registerLocale('kk', kk);

interface Props {
  label?: string;
  name: string;
  disabled?: boolean;
  pickerType?: string;
  isRequired?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  shouldCloseOnSelect?: boolean;
  isClearable?: boolean;
  handleDateChange?: (date: Date | null) => void;
  date: Date | null;
  sx?: SxProps;
  inputWidth?: string
}

const DateTimePicker: React.FC<Props> = ({
  label,
  name,
  disabled = false,
  pickerType,
  isRequired = false,
  minDate,
  maxDate,
  shouldCloseOnSelect = false,
  isClearable = false,
  handleDateChange,
  date,
  sx,
  inputWidth
}) => {
  const { common } = useTheme().palette;
  const [datePickerRef, setDatePickerRef] = useState<any>(false);

  const closeCalendar = () => {
    datePickerRef.setOpen(false)
  }

  const ExampleCustomTimeInput = ({ value, onChange, }: { value: any, onChange: (value: any) => void }) => {
    return <Box display="flex" >
      <TimePicker value={value}
        onChange={(e) => e ? onChange(e) : onChange("00:00")}
        clockIcon={null}
        openClockOnFocus={false}
        format='HH:mm'
      />
    </Box>
  };
  const ExampleCustomInput = React.forwardRef(({ value, onClick }: { value: any, onClick: () => void }, ref: any) => (
    <TextField ref={ref} sx={{ width: inputWidth }} onClick={onClick} value={value} size="small" fullWidth InputProps={{ startAdornment: (<CalendarMonthIcon sx={{ color: "#757575", mr: '10px' }} />) }} />
  ));

  const handleToday = () => {
    datePickerRef.handleSelect(new Date())
  }

  return (
    <Box sx={sx}>
      {label &&
        <Typography>
          {label}
          {isRequired && <span style={{ color: common.errorColor }}>*</span>}
        </Typography>
      }

      <Box display="flex" >
        {pickerType === "time" ?
          <DatePicker
            name={name}
            showTimeSelectOnly
            dateFormat="HH:mm"
            disabled={disabled}
            ref={(r) => {
              setDatePickerRef(r)
            }}
            timeCaption="Время:"
            placeholderText='чч:мм'
            onChange={(date: any) => {
              if (handleDateChange) {
                return handleDateChange(date)
              }
            }}
            selected={date ? date : null}
            isClearable={isClearable}
          >
          </DatePicker> : pickerType === "dateTime" ?
            <DatePicker
              selected={date ? date : null}
              name={name}
              locale="ru"
              showTimeInput
              disabled={disabled}
              ref={(r) => {
                setDatePickerRef(r)
              }}
              timeInputLabel="Время:"
              shouldCloseOnSelect={shouldCloseOnSelect}
              minDate={minDate ? new Date(minDate) : null}
              maxDate={maxDate ? new Date(maxDate) : null}
              placeholderText='дд.мм.гггг чч:мм'
              dateFormat="dd-MM-yyyy HH:mm"
              onChange={(d) => {
                if (handleDateChange) {
                  return handleDateChange(d)
                }
              }}
              customInput={<ExampleCustomInput value={date} onClick={() => { }} />}
              customTimeInput={<ExampleCustomTimeInput value={date}
                onChange={(date) => {
                  if (handleDateChange) {
                    handleDateChange(date)
                  }
                }} />}
              isClearable={isClearable}
            >
              <Box>
                <Button size="small"
                  sx={{ color: "#000", margin: "0", textTransform: "none", background: "#F0F0F0", marginRight: "80px" }}
                  onClick={() => handleToday()}
                >Сегодня</Button>
                <Button size="small"
                  sx={{ color: "#000", margin: "0", textTransform: "none", background: "#F0F0F0" }}
                  onClick={() => closeCalendar()}>Закрыть</Button>
              </Box>
            </DatePicker> :
            <DatePicker
              name={name}
              locale="ru"
              selected={date ? date : null}
              onChange={(d) => {
                if (handleDateChange) {
                  return handleDateChange(d)
                }
              }}
              dateFormat="dd.MM.yyyy"
              placeholderText='дд.мм.гггг'
              customInput={<ExampleCustomInput value={date} onClick={() => { }} />}
              minDate={minDate ? new Date(minDate) : null}
              maxDate={maxDate ? new Date(maxDate) : null}
              isClearable={isClearable}
              ref={(r) => {
                setDatePickerRef(r)
              }}
              disabled={disabled}
              shouldCloseOnSelect={shouldCloseOnSelect}
            >
              <Box>
                <Button size="small"
                  sx={{ color: "#000", margin: "0", textTransform: "none", background: "#F0F0F0", marginRight: "80px" }}
                  onClick={() => handleToday()}
                >Сегодня</Button>
                <Button size="small" sx={{ color: "#000", margin: "0", textTransform: "none", background: "#F0F0F0" }} onClick={() => closeCalendar()}>Закрыть</Button>
              </Box>
            </DatePicker>}
      </Box>
    </Box>
  );
}

export default DateTimePicker;
