import { useState } from 'react'
import { Box, Divider, Rating, Typography, Checkbox, useTheme } from '@mui/material'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

import CustomSearch from '@ui/Search';
import { FreeCouresLangFilter } from './FreeCouresLangFilter';
import { FreeCourseRatingFilter } from './FreeCourseRatingFilter';
import { FreeCourseEducationFormatFilter } from './FreeCourseEducationFormatFilter';
import { FreeCourseComplexityFilter } from './FreeCourseComplexityFilter';
import { FreeCourseOrganizationFilter } from './FreeCourseOrganizationFilter';
import { FreeCourseSubOrganization } from './FreeCourseSubOrganization';
import { FreeCourseTimeFilter } from './FreeCourseTimeFilter';


export const FiltersFreeCourse = ({ filter, setFilter }) => {
  return (
    <Box position="sticky" top="140px"
      sx={{
        overflowY: "auto",
        paddingRight: '5px',
        // '&::-webkit-scrollbar': { width: '2px', height: '7px' },
        // '&::-webkit-scrollbar-thumb': { backgroundColor: '#E5E0EB', borderRadius: '6px' },
        maxHeight: '100vh'
      }}>
      <FreeCourseRatingFilter filter={filter} setFilter={setFilter} />
      <Divider sx={{ margin: "12px 0" }} />
      <FreeCouresLangFilter filter={filter} setFilter={setFilter} />
      <Divider sx={{ margin: "12px 0" }} />
      <FreeCourseEducationFormatFilter filter={filter} setFilter={setFilter} />
      <Divider sx={{ margin: "12px 0" }} />
      <FreeCourseTimeFilter filter={filter} setFilter={setFilter}/>
      <Divider sx={{ margin: "12px 0" }} />
      <FreeCourseComplexityFilter filter={filter} setFilter={setFilter} />
      <Divider sx={{ margin: "12px 0" }} />
      <FreeCourseOrganizationFilter filter={filter} setFilter={setFilter} />
      <Divider sx={{ margin: "12px 0" }} />
      {/* <FreeCourseSubOrganization setFilter={setFilter} /> нужен анализ, сказали скрыть  
      <Divider />*/}
    </Box>
  )
}
