import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import CustomModal from "@components/Modal";
import CustomButton from "@ui/Button";

interface Props {
  deleteFunc: (id: string, values?: any) => Promise<any>;
  modalTitle: string;
  handleModalClose?: () => void
}

const useDeleteConfirmModal = ({
  deleteFunc,
  modalTitle,
  handleModalClose
}: Props) => {
  const { t } = useTranslation(["common", "course"]);
  const { common } = useTheme().palette;
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [values, setValues] = useState();
  const handleOpenModal = () => setIsOpen(x => !x)

  const openDeleteConfirmModal = (deleteId: string, values?: any) => {
    setDeleteId(deleteId);
    if (values) setValues(values);
    handleOpenModal()
  }

  useEffect(() => {
    if (!isOpen) {
      setDeleteId("")
      if (handleModalClose) handleModalClose()
    }
  }, [isOpen, handleModalClose])

  const handleDelete = () => {
    deleteFunc(deleteId, values).then(() => handleOpenModal())
  }

  const DeleteModal = (
    <CustomModal
      open={isOpen}
      handleToggleModal={handleOpenModal}
      width={515}
    >
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            id="transition-modal-title"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              mx: "40px",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "26px",
              mb: "32px",
            }}
          >
            {modalTitle}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <CustomButton
            onClick={handleOpenModal}
            width="209px"
            height="40px"
            borderRadius="8px"
            backgroundColor={common.btnCancel}
            fontColor={common.primaryColor}
            sx={{
              display: "flex",
              alignItems: "center",
              fontStyle: "normal",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {t('NO')}
          </CustomButton>
          <CustomButton
            onClick={handleDelete}
            width="209px"
            height="40px"
            borderRadius="8px"
            backgroundColor="#FF0000"
            fontColor={common.fontWhite}
            sx={{
              display: "flex",
              alignItems: "center",
              fontStyle: "normal",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {t('YES')}
          </CustomButton>
        </Box>
      </>
    </CustomModal>
  )

  return {
    DeleteModal, openDeleteConfirmModal
  }
}

export default useDeleteConfirmModal;