import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Box, useTheme } from '@mui/material'

import CustomModal from "@components/Modal";
import CustomButton from "@ui/Button";
import CustomAutocomplete from "@ui/Autocomplete";
import { serviceDict } from "@services/dictionary";
import { IDictType, initialDTODictType } from '@pages/generalSettings/models';
import { serviceCourse } from '@services/course';
import { useParams, useNavigate } from 'react-router-dom';

interface Props {
  openModal: boolean;
  handleToggleModal: (modalState: boolean) => void;
  handleToggleSuccessModal: (modalState: boolean) => void;
  setSuccess: (success: boolean) => void;
  setAttention: (attention: boolean) => void;
  setMessage: (message: string) => void
}

const CloseCourseModal = ({ openModal, handleToggleModal, handleToggleSuccessModal, setSuccess, setAttention, setMessage }: Props) => {
  const { t } = useTranslation('course');
  const { common } = useTheme().palette;
  const [closeReasons, setCloseReasons] = useState<IDictType[]>([initialDTODictType])
  const [reason, setReason] = useState<string>('')
  const { courseId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    serviceDict.getDictionaryList(`COURSE_CLOSE_REASON`, {
      pageNumber: 0,
      size: 100,
      filter: { search: '' }
    }).then((res) => {
      if (res.status === 200) {
        setCloseReasons(res.data.dtoList)
      }
    })
  }, [])
  const handleChangeReason = (value) => {
    setReason(value.nameRu)
  }
  const handleCloseCourse = () => {
    if (courseId) {
      serviceCourse.closeCourse(courseId, reason).then((res) => {
        if (res.status === 200) {
          handleToggleModal(false)
          handleToggleSuccessModal(true)
          setSuccess(true)
          setAttention(false)
          setMessage(t('course:messages.COURSE_CLOSED'))
          setTimeout(() => {
            navigate('/courses')
          }, 3000)
        }
      })
    }
  }

  return (
    <CustomModal
      open={openModal}
      handleToggleModal={handleToggleModal}
      borderColor={common.primaryColor}
      width={515}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ fontSize: '28px', fontWeight: 500, lineHeight: '36px', textAlign: 'center' }}>
          {t('course:REASON_FOR_CLOSING_COURSE')}
        </Box>
        <Box
          sx={{
            fontWeight: 500, fontSize: "13px", textAlign: 'justify',
            lineHeight: "15.6px",
            mb: '24px'
          }}
        >
          {t('course:WHY_DO_YOU_WANT_TO_CLOSE_COURSE_EARLY')} <span style={{ color: '#8E8E93' }}>{t('course:REASON_CHOOSE_HINT')}</span>
        </Box>
        <CustomAutocomplete
          name="base"
          options={closeReasons}
          onChange={(_, value) => handleChangeReason(value)}
          valueAsObject
        />
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: '24px' }}>
          <CustomButton
            width="209px"
            height="40px"
            borderRadius="8px"
            backgroundColor="#F6F6F6"
            fontColor={common.primaryColor}
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
            }}
            onClick={() => handleToggleModal(false)}
          >
            {t('course:task.CANCELLATION')}
          </CustomButton>
          <CustomButton
            width="209px"
            height="40px"
            borderRadius="8px"
            backgroundColor={common.primaryColor}
            fontColor="#FFFFFF"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
            }}
            onClick={handleCloseCourse}
          >
            {t('common:actions.CLOSE_THE_COURSE')}
          </CustomButton>
        </Box>
      </Box>
    </CustomModal>
  )
}

export default CloseCourseModal;