export interface IProfile {
  authorityInfo: {
    eduProgramId: string | null;
    orgId: number | null;
    orgNameEn: string | null;
    orgNameKz: string | null;
    orgNameRu: string | null;
    role: string | null;
    subdivisionId: number | null;
    userRoleId: number | null;
  };
  email: string | null;
  firstName: string | null;
  fullName: string | null;
  kcId: string | null;
  lastName: string | null;
  patronymic: string | null;
  pltaId: string | null;
  username: string | null;
  profilePhotoId: string | null;
}

export interface IUser {
  id: string;
  lastName: string | null;
  firstName: string | null;
  patronymic: string | null;
  iin: string | null;
  sex: string | null; // MALE, FEMALE
  birthdate: string | null;
  email: string | null;
  username: string | null;
  mobilePhone: string | null;
  sendNotificationAfterCreate: boolean;
  profilePhotoId: string | null;
  detailInfo: string | null;
  youtube: string | null;
  facebook: string | null;
  instagram: string | null;
  langIds: Array<number>;
  userRoles: Array<IUserRole>;
  isDisabled: boolean;
  createdBy: string | null;
  migrated: boolean;
}

export interface IUserFilter {
  name?: string;
  roleCode: string | null;
  subdivisionIds?: number[];
  eduProgramIds?: number[];
}

export interface IUserRole {
  id: number | null;
  belongsToOrg?: boolean;
  disabled: boolean;
  orgId: number | null;
  role: IRole;
  subdivisionId: number | null;
  eduProgramId: number | null;
  used: boolean;
  current: boolean;
}

export interface IUserExternal {
  userId: string;
  fio: string;
  email?: string;
  courseName: string;
  regDate: string;
  status: string;
  profilePhotoId?: string;
}

export interface IRole {
  id: number | null;
  code?: string | null;
  nameKz?: string | null;
  nameRu?: string | null;
  nameEn?: string | null;
  permissionCodes?: Array<string>;
}

export interface IStudyClass {
  id: string;
  courseId: string | null;
  course: ICourse | null;
  scheduleId: number | null; // Расписание
  name: string | null; // Наименование потока
  status: string | null; // Статус потока
  capacity: number | null; // Вместимость потока
  canSelfRegistration: boolean; // Разрешать самостоятельную регистрацию на курс
  regStartDate: string | null; // Дата регистрации с
  regEndDate: string | null; // Дата регистрации до
  startDate: string | null; // Начало курса
  endDate: string | null; // Конец курса
  sendEmailMessage: boolean; // Отправить сообщение на почту слушателя после успешной регистрации на курс
  message: string | null;
  tutorName: string | null; // Преподаватель учебного потока
  allStudentCount: number | null; //Количество всех слушателей
  activeStudentCount: number | null; // Количество активных слушателей
  useTimetable: boolean; // only for front
}

export interface IAuthorInfo {
  courseId: string | null;
  userId: string | null;
  username: string | null;
  status: string | null;
  userInfo: string | null;
}
export interface ICourse {
  id: string | null;
  name: string | null;
  shortDescription: string | null;
  description: string | null;
  typeId: number | string | null;
  typeCode: string | null;
  credits: number | null;
  promoVideoType: string | null;
  promoVideoUrl: string | null;
  promoVideoId: string | null;
  promoVideoName: string | null;
  countRegWithPromoCode: number | null;
  status: string | null; // Enum: [SAVED, PUBLISHED, WITHDRAWN, COMPLETED]
  authors: Array<IAuthorInfo> | null;
  authorInfo: string | null;
  tutorInfo: string | null;
  coverFileId: string | null;
  coverColor: string;
  createdBy: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  courseMembers: Array<IMember>;
  groups: Array<ITaskGroup>;
  courseSetting: ICourseSetting;
  externalId: string | null;
  studentCount: number | null;
  allStudentCount: number | null;
  activeStudentCount: number | null;
  isSelfReg?: boolean;
  published: boolean;
  categoryId?: number | null;
  targetAudience?: string;
  difficultyLevelId?: number | null;
  roleType?: string | null;
  userId?: string | null;
  rating?: number | null;
}

export interface ICourseSetting {
  branchId: number | null;
  canExecuteTaskAfterExpired: boolean;
  canRegWithPromoCode: boolean;
  canSelfRegistration: boolean;
  canStudentViewAfterEnd: boolean;
  canStudentViewBeforeStart: boolean;
  canTutorEdit: boolean;
  capacity: number | null;
  contentAccessExpiration: string | null;
  gradingAccuracy: number | null;
  gradingSystemId: string | number | null;
  id: string | null;
  isProvidedCertificate: boolean;
  isProvidedRegPeriod: boolean;
  useTimetable?: boolean;
  isPublic: boolean;
  isSendMailAfterSuccessReg: boolean;
  isSequentialTopicStudy: boolean;
  allowEstimateAfterCompletion: boolean;
  langId: number | null;
  maxFileSizeCanUploadStudent: number | null;
  regStartDate: string | null;
  regEndDate: string | null;
  scheduleId: number | null;
  sendMailAfterSuccessRegText: string | null;
  startDate: string | null;
  endDate: string | null;
  targetAudiences: Array<any>;
  subdivisionIds: Array<number>;
  eduProgramIds: Array<number>;
  zeroMarkForIncompleteTasks: boolean;
  markForIncompleteTask: number | null;
  finalGradeDisplayMethod: string | null; // AUTOMATIC, MANUAL
  registrationClosed: boolean;
  formatType?: "ASYNC" | "SYNC";
  durationType?: "INDEFINITE" | "PERIOD";
  completedTasksRate?: number | null;
  minLimitForTask?: number | null;
  hours: number | null;
  credits: number | null;
  certificateDisplay: "HOURS" | "CREDITS" | null;
}

interface ICourseType {
  id: number | string | null;
  code: string | null; // FREE, EDU
  maxFileSizeCanUploadPerTheme: number | null;
  maxFileSizeCanTutorUploadPerTask: number | null;
  accountAttendance: boolean;
  accountAttendanceInControlWork: boolean;
  availablePassesPerCourse: number | null;
  branchId: number | null;
  targetAudiences: Array<IEduProgram>;
  createdBy: string | null;
  createdAt: string | null;
  active: boolean;
  useTimetable: boolean;
  provideExpertiseBeforePublish: boolean;
}

interface IEduProgram {
  id: number | null;
  nameKz: string | null;
  nameRu: string | null;
  nameEn: string | null;
  organizationId: string | null;
  externalId: string | null;
  used: boolean;
  courseTypeOptions: Array<ICourseType>;
}

export interface IGradingSystem {
  id: number | string | null;
  name: string | null;
  courseTypeCodes: string | null;
  courseFormatCodes: string | null;
  marks: Array<IMark>;
  isActive: boolean;
  createdBy: string | null;
  createdAt: string | null;
  branches: Array<IGradingSystemBranch>;
}

interface IMark {
  id: number | string | null;
  gradingSystem: IGradingSystem | null;
  name: string | null;
  description: string | null;
  min: number | null;
  max: number | null;
}

interface IGradingSystemBranch {
  id: number | string | null;
  gradingSystemId: number | string | null;
  branchId: number | string | null;
  isActive: boolean;
}

export interface IDictionary {
  availableCourseTypes: TypeCode[];
  bin: string;
  branchTypeId: string;
  hasOwnDomain: false;
  serverAddress: string;
  id: number | string | null;
  active?: boolean;
  nameKz: string;
  nameRu: string;
  nameEn: string;
  isStandard?: boolean;
  isDisabled?: boolean;
}

export interface IDictionaryShort {
  id: number | string | null;
  name: string;
}

export interface IParticipant {
  id: string;
  roleType: string;
  userId: string;
  userFullName: string;
  isActive: boolean;
  status: string;
  isSelfReg: boolean;
  courseId: string;
  groupId: string;
  createdAt: string;
  externalId?: number | null;
  classId?: string | null;
  lastActivityDate?: string | null;
}

export interface IGroupListItem {
  id: string;
  name: string;
  capacity: number;
  courseId: string;
}

export interface IUserSearchItem {
  id: string;
  fullName: string;
  lastName: string;
  firstName: string;
  patronymic: string;
  userFullName: string;
  userId?: string;
}

export interface IContent {
  id: string | null;
  courseId: string | null;
  index: number | null;
  name: string | null;
  parentId: string | null;
  parentType: string | null;
  type: string | null;
  status: string | null;
  format: string | null;
  children: Array<IContent>;
  shortDescription?: string;
}

export interface ISection {
  id: string | null;
  courseId: string | null;
  index: number | null;
  name: string;
  shortDescription: string;
  themes: Array<ITheme>;
}

export interface ITheme {
  id: string | null;
  course: { id: number | string | null } | ICourse | null;
  courseId?: string | null;
  section: { id: number | string | null } | ISection | null;
  sectionId?: string | number | null;
  index: number | null;
  name: string | null;
  shortDescription: string | null;
  description: string | null;
  isCloseAccess: boolean;
  dateOfAccess: string | null;
  isNotifyStudent: boolean;
  videoType: string | null;
  videoId: string | null;
  videoName: string | null;
  url: string | null;
  contentType: string; // VIDEO, DESCRIPTION
  status: string; // SAVED, ACTIVE
  materials: Array<IMaterial>;
  estimatedTime: number | null;
  prevId?: string | null;
  nextId?: string | null;
}

export interface IMaterial {
  id: string | null;
  themeId?: string | null;
  taskId?: string | null;
  name: string | null;
  url: string | null;
  downloadable: boolean;
  fileId: string | null;
}
export interface ITasksList {
  allowed: boolean;
  dateOfAccess: string | null;
  dateOfDeadline: string | null;
  createdAt: string;
  hasDeadlineDate: boolean;
  id: string | null;
  member: {
    id: string | null;
    taskProgresses: any[];
  };
  recipientId: string;
  status: string;
  task: ITaskFreeCourses;
  point?: number;
}
export interface ITaskFreeCourses {
  id: string | null;
  name: string | null;
  description: string | null;
  type: string | null; // TASK, CONTROL
  parentType: string | null; // COURSE, SECTION THEME
  course: { id: number | string | null } | ICourse | null;
  courseStatus?: string | null; // this is only for front validation
  section: { id: number | string | null; name?: string } | ISection | null;
  theme: { id: number | string | null; name?: string } | ITheme | null;
  point: number | null;
  format: string | null; // QUIZ, ORAL, WRITTEN
  answerType: AnswerTypes | null; // FILE, TEXT, ONLINE-CONFERENCE, AUDIO, VIDEO
  hasAnswerTypeFileFormats: boolean;
  answerTypeFileFormats: Array<string>;
  videoSum?: number | null;
  videoDuration?: number | null;
  isVideoFormatSpecified?: boolean;
  videoFormats?: Array<string>;
  dateOfDeadline: string | null;
  isNoMark: boolean;
  isExcludeTotalMark: boolean;
  isNotifyStudent: boolean;
  status: string | null; // SAVED, PUBLISHED
  createdBy: string | null;
  createdAt: string | null;
  materials: Array<IMaterial>;
  quiz: IQuiz | null;
  responsibleMember: IMember | null;
  group: ITaskGroup | null;
  progress: IProgress | null;
  prevId: string | null;
  nextId: string | null;
  userMark: number | null;
  allowed: boolean;
  isSelfTest: boolean;
  enabledAntiPlagiarism?: boolean;
  antiPlagiarismTaskSetting?: AntiPlagiarismTaskSetting;
}
export interface AntiPlagiarismTaskSetting {
  originality?: number | null;
  similarity?: number | null;
  citation?: number | null;
  selfCitation?: number | null;
  symbolReplacementMaxCount?: number | null;
  taskType?: string | null;
  courseId?: string | null;
}
export interface ITask extends ITaskFreeCourses {
  isCloseAccess: boolean;
  dateOfAccess: string | null;
  responsibleMembers: Array<IMember>;
}
export interface IProgress {
  id?: string | null;
  status?: string | null;
  hasDeadlineDate?: boolean;
  dateOfDeadline?: string | null;
  point?: number | null;
}

export interface IComment {
  id: string | null;
  progressId: string | null | undefined;
  commentatorMember?: {
    id: string | null;
    userId?: string | null;
    roleType?: string | null;
  } | null;
  commentatorType: string | null; // USER, LISTENER
  comment: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  replyFiles?: Array<IFile>;
}

export interface IFile {
  id: string | null;
  fileId: string | null;
  fileName?: string | null;
}

export interface ITaskGroup {
  id: string | null;
  course: { id: number | string | null } | ICourse | null;
  gradingSystemId?: number;
  name: string | null;
  percentage: number | null;
  taskType: string | null;
  tasks: any;
}

export interface IQuiz {
  id?: string | null;
  allowMultipleAttempts?: boolean;
  numberOfAttempts?: number | string | null;
  numberOfQuestions?: number | string | null;
  showNumberOfQuestions?: boolean;
  duration?: number | null;
  displayGrade?: boolean;
  displayRightAnswers?: boolean;
  textAfterQuiz?: boolean;
  createdBy?: string | null;
  createdAt?: string | null;
  questions?: Array<IQuestion>;
  considerResult?: "HIGHEST" | "LOWEST" | "AVERAGE" | "LAST";
  applyProctoring?: boolean;
  draftId?: string | null;
}

export interface IQuestion {
  id: string | null;
  quiz: IQuiz | null;
  parent: IQuestion | null;
  content: string | null;
  type: string | null; // ONE_CORRECT_ANSWER, MULTIPLE_CORRECT_ANSWERS, MAPPING, TRUE_OR_FALSE, SEQUENCE
  point: number | null;
  answers: Array<IAnswer>;
  children: Array<IQuestion>; // если тип MAPPING и parent NULL
}

export interface IAnswer {
  id: string | null;
  // question: IQuestion | null;
  answer: string | null;
  correct: boolean;
  order: number | null; // для типа SEQUENCE
}

export interface IMember {
  id: string | null;
  roleType: string | null; // LISTENER, TUTOR, CURATOR, ASSISTANT, EXAMINER
  userId: string | null;
  userFullName: string | null;
  isActive: boolean;
  isSelfReg: boolean;
  courseId: string | null;
  groupId: string | null;
  createdBy: string | null;
  createdAt: string | null;
}

export interface ITaskFormat {
  format: string;
  sectionId: string;
  themeId: string;
}

export interface IShortInfo {
  id: string | number | null;
  name?: string | null;
}

export interface IMyCourseListenerList {
  studyClass: IStudyClass;
  course?: any;
  createdAt: string | null;
  id: string;
  listenerId: string;
  point: number | null;
  status: string;
  totalPoint: number | null;
  updatedAt: string | null;
}

export interface IMyCourseListener {
  dtoList: Array<IMyCourseListenerList>;
  totalElements: number | null;
}
export interface IMyCourseListenerCourseCatalog {
  dtoList: Array<ICourse>;
  totalElements: number | null;
}
export interface IBreadcrumb {
  level: number | string | null;
  page: string;
  label: string | null;
  path: string | null;
  tabNum: number | string | null;
}

export interface IReview {
  id: number;
  courseId: string;
  grade: number;
  userId: string;
  review: string;
  createdAt: string;
}

export interface ReviewInfo {
  avgRating: number;
  reviewSum: number;
}

//enum
export interface ITypeList {
  id: number;
  value: TypeCode;
}

export interface IUsersTypeCode {
  id: number;
  value: UsersTypeCode;
}

export interface ListArray {
  key: string;
  value: string;
  colored?: boolean;
  onClick?: () => void;
  svg?: any;
  bold?: string;
}

interface Platform {
  id: number;
  nameKz: string;
  nameRu: string;
  nameEn: string;
}

export interface IMeeting {
  id: string;
  classId: string;
  name: string;
  platform: Platform;
  title: string;
  link: string;
  login: string;
  password: string;
  themeId: string;
  taskId: string;
  startDate: string;
  endDate: string;
  coordinatorMemberId: string;
  coordinatorUserName: string;
  memberList: IMember[];
}

//enum
export enum TabOptions {
  OPTIONS = "options",
  MEDIA = "media",
  AUTHOR = "author",
}

export enum TabOptionsSettings {
  COMMON = "common",
  TASK_GRADES = "taskGrades",
}

export enum CourseStatus {
  SAVED = "SAVED",
  PUBLISHED = "PUBLISHED",
  WITHDRAWN = "WITHDRAWN",
  CLOSED = "CLOSED",
  // only for Student
  CURRENT = "CURRENT",
  PLANNED = "PLANNED",
  COMPLETED = "COMPLETED",
}

export enum FlowStatus {
  CREATED = "CREATED",
  PUBLISHED = "PUBLISHED",
  WITHDRAWN = "WITHDRAWN",
  COMPLETED = "COMPLETED",
}

export enum Role {
  EDUCATOR = "EDUCATOR",
  MANAGER = "MANAGER",
  LISTENER = "LISTENER",
  SUPER_ADMIN = "SUPER_ADMIN",
  LOCAL_ADMIN = "LOCAL_ADMIN",
  ADMIN = "ADMIN",
}

export enum TypeCode {
  FREE = "FREE",
  EDU = "EDU",
}

export enum UsersTypeCode {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export enum DurationType {
  INDEFINITE = "INDEFINITE",
  PERIOD = "PERIOD",
}

export enum ContentType {
  COURSE = "COURSE",
  SECTION = "SECTION",
  THEME = "THEME",
  TASK = "TASK",
}

export enum TaskFormat {
  WRITTEN = "WRITTEN",
  QUIZ = "QUIZ",
  ORAL = "ORAL",
}

export enum TaskStatus {
  ASSIGNED = "ASSIGNED",
  NOT_ASSIGNED = "NOT_ASSIGNED",
  STARTED = "STARTED",
  UNDER_REVIEW = "UNDER_REVIEW",
  ON_CORRECTION = "ON_CORRECTION",
  NOT_COMPLETED_ON_TIME = "NOT_COMPLETED_ON_TIME",
  COMPLETED = "COMPLETED",
  PASSED = "PASSED",
  NOT_PASSED = "NOT_PASSED",
}
export enum TypeConference {
  ACTIVE = "active",
  FINISHED = "finished",
}

//'success' | 'info' | 'warning' | 'error'
export enum AlertColor {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export enum AnswerTypes {
  VIDEO = "VIDEO",
  ONLINE_CONFERENCE = "ONLINE_CONFERENCE",
  OFFLINE = "OFFLINE",
  TEXT = "TEXT",
  FILE = "FILE",
}

export enum TaskType {
  TASK = "TASK",
  CONTROL = "CONTROL",
}

export enum CourseFormatType {
  SYNC = "SYNC",
  ASYNC = "ASYNC",
}

export enum CourseRole {
  TUTOR = "TUTOR",
  CURATOR = "CURATOR",
  ASSISTANT = "ASSISTANT",
  LISTENER = "LISTENER",
  EXAMINER = "EXAMINER",
}

export enum AdditionalMaterialTypes {
  LINK = "LINK",
  FILE = "FILE",
}

export enum JournalTab {
  VISIT = "visit",
  JOURNAL = "journal",
}

export enum DictionaryCode {
  BRANCH_TYPE = "BRANCH_TYPE",
  BRANCH = "BRANCH",
  CATEGORY = "CATEGORY",
  COURSE_DIFFICULTY_LEVEL = "COURSE_DIFFICULTY_LEVEL",
  LANGUAGE = "LANGUAGE",
  SUBDIVISION = "SUBDIVISION",
  EDU_PROGRAM = "EDU_PROGRAM",
  STUDY_FORM = "STUDY_FORM",
  FILE_TYPE = "FILE_TYPE",
  CLASS_TYPE = "CLASS_TYPE",
  COURSE_CLOSE_REASON = "COURSE_CLOSE_REASON",
}

export enum ConfirmationStatus {
  CONFIRMED = "CONFIRMED",
  NOT_CONFIRMED = "NOT_CONFIRMED",
}

export enum IntagrationCode {
  AERO = "AERO",
  ANTI_PLAGIARISM = "ANTI_PLAGIARISM",
  PLATONUS = "PLATONUS",
}

export enum TypeObjectIntegration {
  COURSE = "COURSE",
  MEMBER = "MEMBER",
  USER = "USER",
  EDU_PROGRAM = "EDU_PROGRAM",
  SUBDIVISION = "SUBDIVISION",
  SCHEDULE = "SCHEDULE",
  STUDY_FORM = "STUDY_FORM",
}
