import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Checkbox, FormControlLabel, useTheme } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link as RouterLink } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { AlertColor } from '@mui/material/Alert';
import { useTranslation } from "react-i18next";
import { get, capitalize } from 'lodash';

import { serviceDict } from "@services/dictionary";
import { IOrganization, initialFilter } from "./models";
import Pagination from "@ui/Pagination";
import CustomButton from "@ui/Button";
import Message from "@components/Message";
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";

const List = () => {
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const { t } = useTranslation(["dictionary", "common", "enum"]);
  const [organizationList, setOrganizationList] = useState<IOrganization[]>([]);
  const [organizationId, setOrganizationId] = useState<string | number | null>(null);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [filter, setFilter] = useState<{ search: string; isDisabled: boolean }>(initialFilter);
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");

  const lng = localStorage.getItem("i18nextLng") || "kz";

  useEffect(() => {
    serviceDict
      .getDictionaryList("BRANCH", {
        pageNumber: currentPage,
        size: rowsPerPage,
        filter,
        sorting: lng === 'kz' ? { isStandard: "desc", nameKz: "asc" } : lng === 'ru' ? { isStandard: "desc", nameRu: "asc" } : { isStandard: "desc", nameEn: "asc" },
      })
      .then((res) => {
        setOrganizationList(res.data.dtoList);
        setCount(res.data.totalElements);
      });
  }, [currentPage, rowsPerPage, filter, lng]);

  const searchByName = (searchValue: string) => {
    const new_filter = { ...filter, search: searchValue };
    setFilter(new_filter);
  };

  const searchByActiveness = (checked: boolean) => {
    const new_filter = { ...filter, isDisabled: checked };
    setFilter(new_filter);
  };

  const handleChangePage = (newPage: number) => setCurrentPage(newPage);

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
    setCurrentPage(0);
  };

  const deleteOrganization = () => {
    return serviceDict.deleteDictionary("BRANCH", organizationId).then((res) => {
      if (res.status === 200) {
        setOrganizationList(organizationList.filter((item) => item.id !== organizationId));
        setMessageOpen(true);
        setMessage(t("common:messages.RECORD_DELETED"));
        setMessageType("success");
        setTimeout(() => {
          setMessageOpen(false);
        }, 3000);
      }
    }).catch((err) => {
      setMessageOpen(true);
      setMessage(t("common:messages.ERROR"));
      setMessageType("error");
      setTimeout(() => {
        setMessageOpen(false);
      }, 3000);
    }).finally(() => {
      setOrganizationId(null);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  };

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteOrganization,
    modalTitle: t(`common:DELETE_ITEM_CONFIRMATION_TEXT`),
  })

  return (
    <Box>
      {DeleteModal}
      <Box sx={{ display: "flex", marginBottom: "24px", alignItems: 'center' }}>
        <RouterLink to={"/dict"} style={{ textDecoration: "none", color: common.black }}>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: 16, mr: '9px' }}>
              {t("DICTIONARIES")}
            </Typography>
          </Box>
        </RouterLink>
        <ArrowForwardIosIcon sx={{ width: '10px', height: '10px', mt: '1.5px' }} />
        <Typography sx={{ ml: '9px' }}>{t("ORGANIZATIONS")}</Typography>
      </Box>
      <Typography sx={{ fontSize: '36px', fontWeight: 500, lineHeight: '44px', mb: '38px' }}>
        {t("ORGANIZATIONS")}
      </Typography>

      {messageOpen &&
        <Box sx={{ mb: '24px', mt: '-12px' }}>
          <Message
            type={messageType}
            message={message}
            setMessageOpen={setMessageOpen}
          />
        </Box>
      }

      <Box sx={{ border: `1px solid ${common.borderSecondary}`, backgroundColor: common.fontWhite, borderRadius: '24px', p: '24px' }}>
        <Grid container>
          <Grid item xs={4} sx={{ mb: 4 }}>
            <TextField
              label={t("common:placeholders.SEARCH_BY_NAME")}
              color="primary"
              size="small"
              name="searchValue"
              onChange={(e) => searchByName(e.target.value)}
              sx={{
                width: '100%',
                '& label.Mui-focused': { color: common.primaryColor },
                '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': { borderColor: common.primaryColor },
                  '&.Mui-focused fieldset': { borderColor: common.primaryColor }
                }
              }}
            />
          </Grid>
          <Grid item xs={4.5} textAlign="end">
            <FormControlLabel
              control={<Checkbox checked={filter.isDisabled} onChange={(e) => searchByActiveness(e.target.checked)} sx={{
                marginLeft: 2, color: common.primaryColor, "&, &.Mui-checked": {
                  color: common.primaryColor,
                },
              }} />}
              label={t("enum:NOT_USED")}
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={3.5} textAlign="end">
            <CustomButton width="90px" height="40px" backgroundColor={common.primaryColor} borderRadius="8px" fontColor={common.fontWhite}
              sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "20px", alignItems: "center" }}
              onClick={() => navigate("/dict/organization/new")}>
              {t("common:actions.ADD")}
            </CustomButton>
          </Grid>
        </Grid>

        <Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: 18, width: '30%' }}>{t("TITLE")}</TableCell>
                  <TableCell sx={{ fontSize: 18, width: '30%' }}>{t("TYPE_OF_ORGANIZATION")}</TableCell>
                  <TableCell sx={{ fontSize: 18, width: '20%' }}>{t("USAGE_STATUS")}</TableCell>
                  <TableCell sx={{ fontSize: 18, width: '20%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', pr: '20px' }}>
                      {t("ACTION")}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              {organizationList?.length && organizationList.length > 0 ? (
                <TableBody>
                  {organizationList?.map((organization, index) => {
                    return (
                      <TableRow key={`${index}_${organization.id}`}>
                        <TableCell sx={{ fontSize: 16, width: '30%' }}>
                          {get(organization, `name${capitalize(lng)}`)}
                        </TableCell>
                        <TableCell sx={{ width: '30%' }}>
                          {get(organization?.branchType, `name${capitalize(lng)}`)}
                        </TableCell>
                        <TableCell sx={{ width: '20%' }}>
                          {organization.isDisabled ? t("enum:NOT_USED") : t("enum:IS_USED")}
                        </TableCell>
                        <TableCell sx={{ width: '20%' }}>
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end', pr: '20px', gap: 1.5 }}>
                            <EditIcon color="info" onClick={() => navigate(`/dict/organization/${organization.id}`)} sx={{ cursor: "pointer", color: common.primaryColor }} />
                            <DeleteOutlineIcon
                              color="error"
                              onClick={() => { setOrganizationId(organization.id); openDeleteConfirmModal(organization.id?.toString() || '') }}
                              sx={{ cursor: "pointer" }}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      {t("EMPTY")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            <Pagination
              count={count}
              page={currentPage}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default List;