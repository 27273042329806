import AvatarViaUserId from "@components/AvatarViaUserId";
import UserFullName from "@components/UserFullName";
import { CourseRole, IComment, IFile } from "@interfaces/index";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { serviceTask } from "@services/task";
import TextReader from "@ui/TextReader";
import { formatDateWithHourMinute, downloadFile } from "@utils/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { VideoItem } from "../VideoItem/VideoItem";
import { useSearchParams } from "react-router-dom";
interface IProps {
  progressId: string;
  userId: string | null;
  refresh?: boolean;
}

const CommentaryInfo = ({ progressId, userId, refresh }: IProps) => {
  const [commentList, setCommentList] = useState<Array<IComment>>([]);

  useEffect(() => {
    if (progressId) {
      serviceTask.getAllTaskComments(progressId).then((res) => {
        setCommentList(res.data.reverse());
      });
    }
  }, [progressId, refresh]);

  if (commentList.length === 0)
    return <Box sx={{ mt: 3 }}>Пока ответов нет</Box>;

  return (
    <Box sx={{ mt: 3 }}>
      {commentList.map((item: IComment) => (
        <SingleCommentComponent key={item.id} item={item} userId={userId} />
      ))}
    </Box>
  );
};

export { CommentaryInfo };

export const SingleCommentComponent = ({ item, userId }) => {
  const { common } = useTheme().palette;
  const [searchParams] = useSearchParams();
  const currentUserId =
    item.commentatorType ===
    (searchParams.get("type") === "video" ? "USER" : CourseRole.LISTENER)
      ? item.commentatorMember?.userId
      : userId;
  const { t } = useTranslation(["notification"]);

  return (
    <Box sx={{ display: "flex", mb: 3, width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          mt: "12px",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#323C47",
            lineHeight: "20px",
            gap: 1,
            width: "100%",
          }}
        >
          <Box display={"flex"} alignItems={"flex-start"} height={"100%"}>
            <AvatarViaUserId userId={currentUserId} sxProps={null} />
          </Box>
          <Box display="flex" flexDirection={"column"} width={"100%"}>
            <Box display="flex" flexDirection={"column"}>
              <Typography fontWeight={500}>
                <UserFullName id={currentUserId || userId} />
              </Typography>
              <Typography
                fontSize={"12px"}
                color={common.borderSecondary}
                gutterBottom
              >
                {item.createdAt
                  ? formatDateWithHourMinute(item.createdAt)
                  : t("notification:JUST_NOW")}
              </Typography>
            </Box>
            <Typography sx={{ wordBreak: "break-word" }}>
              {item.comment}
            </Typography>
            <Grid
              display={"grid"}
              gridTemplateColumns={"repeat(auto-fill,minmax(293px,1fr))"}
              gap={1.5}
            >
              {item?.replyFiles &&
                item.replyFiles.length > 0 &&
                item.replyFiles.map((file: IFile) => {
                  return (
                    <VideoItem
                      name={file.fileName ?? ""}
                      src={file.fileId ?? ""}
                    />
                  );
                })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
