import { Box, useTheme } from '@mui/material'
import React, { useState } from 'react'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useTranslation } from 'react-i18next';

export const Categories = ({ categories, filter, setFilter }) => {
  const { common } = useTheme().palette;
  const {t, i18n} = useTranslation(["freeCourse"])
  const [showAllCategory, setShowAllCategory] = useState<boolean>(false)

  const isSelectedCategory = (categoryId) => {
    return filter.categoryId === categoryId
  }

  return (
    <Box display="flex" gap={1} flexWrap="wrap" bgcolor="#F9FAFB" position="sticky" top="55px" padding="10px 0" sx={{ overflowX: "auto" }} zIndex={100}>
      <Box
        alignItems="center"
        maxWidth="300px"
        sx={{
          padding: "8px 10px 8px 10px",
          borderRadius: "6px",
          border: `1px solid ${common.borderSecondary}`,
          display: 'flex',
          alignItems: 'center',
          color: filter.categoryId ? "#686B81" : "#fff",
          cursor: "pointer",
          fontSize: "14px",
          height: "36px",
          background: filter.categoryId ? "#fff" : common.primaryColor,
          ":hover": {
            background: common.primaryColor,
            color: "#fff"
          }
        }}
        onClick={() => {
          setFilter((prev) => ({ ...prev, categoryId: null }))
        }}>
        {t('freeCourse:ALL_CATEGORIES')}
      </Box>
      {categories?.slice(0, showAllCategory ? categories.length : 12).map((node, index) => {
        return (
          <Box
            key={`${node.id}_${index}`}
            alignItems="center"
            maxWidth="300px"
            sx={{
              padding: "8px 10px 8px 10px",
              borderRadius: "6px",
              border: `1px solid ${common.borderSecondary}`,
              display: 'flex',
              alignItems: 'center',
              color: isSelectedCategory(node.id) ? "#fff" : "#686B81",
              cursor: "pointer",
              fontSize: "14px",
              height: "36px",
              background: isSelectedCategory(node.id) ? common.primaryColor : "#fff",
              ":hover": {
                background: common.primaryColor,
                color: "#fff"
              }
            }}
            onClick={() => {
              setFilter((prev) => ({ ...prev, categoryId: node.id }))
            }}>
            {i18n.language === 'ru' ? node.nameRu : i18n.language === 'en' ? node.nameEn : node.nameKz}
          </Box>
        )
      })}
      <Box sx={{
        padding: "5px 10px",
        borderRadius: "6px",
        color: "#686B81",
        background: "#fff",
        maxWidth: "200px",
        height: "36px",
        fontSize: "14px",
        cursor: "pointer",
        border: `1px solid ${common.borderSecondary}`,
        ":hover": {
          background: common.primaryColor,
          color: "#fff"
        }
      }}
        onClick={() => setShowAllCategory(!showAllCategory)}>
        {showAllCategory ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
      </Box>
    </Box>
  )
}
