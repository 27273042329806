import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { useTheme } from '@mui/material/styles';
import { AlertColor } from '@mui/material';

import { serviceCourse } from '@services/course';
import { serviceTask } from '@services/task';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import Breadcrumbs from '@components/Breadcrumbs';
import Message from '@components/Message';
import Settings from './Settings';
import Questions from './Quiz/Questions';
import RecipientList from './Recipients';
import { CourseFormatType, ITaskGroup, TaskFormat as FormatOfTask, IMaterial, CourseStatus } from '@interfaces/index';
import { CourseContext } from "@context/CourseContext";
import MaterialList from './MaterialList';

const TaskFormat = () => {
  const { courseId, taskId, taskFormat } = useParams();
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course"]);
  const [sectionOptions, setSectionOptions] = useState<Array<any>>([]);
  const [themeOptions, setThemeOptions] = useState<Array<any>>([]);
  const [taskGroupOptions, setTaskGroupOptions] = useState<Array<ITaskGroup>>([]);
  const [gradingSystemId, setGradingSystemId] = useState<number | null>(null);
  const editTaskTabActiveKey = sessionStorage.getItem("editTaskTabActiveKey");
  const [tabValue, setTabValue] = useState(editTaskTabActiveKey || 'taskParams');
  const [quizId, setQuizId] = useState("");
  const [draftId, setDraftId] = useState(null);
  const [taskStatus, setTaskStatus] = useState("");
  const [taskType, setTaskType] = useState("");
  const [isSelfTest, setIsSelfTest] = useState(false);
  const [taskAnswerType, setTaskAnswerType] = useState('')
  const [message, setMessage] = useState<string>("");
  const [taskName, setTaskName] = useState<string>("");
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const { courseData } = useContext(CourseContext);
  const [materialList, setMaterialList] = useState<Array<IMaterial>>([]);


  useEffect(() => {
    const updatedCrumbs = [...getBreadcrumbs(courseId || "")];
    if ((updatedCrumbs.length === 3 && updatedCrumbs[2].page === 'task') || updatedCrumbs.length === 4) updatedCrumbs.pop();
    updatedCrumbs.push({
      level: updatedCrumbs.length === 2 ? 3 : 4,
      page: 'task',
      label: taskId === "new" ? t('course:task.ADDING_TASK') : taskName,
      path: null,
      tabNum: null
    });
    // Check if the breadcrumbs have actually changed before updating
    if (JSON.stringify(updatedCrumbs) !== JSON.stringify(getBreadcrumbs(courseId || ""))) {
      if (courseId) setBreadcrumbs(courseId, updatedCrumbs);
    }
  }, [getBreadcrumbs, setBreadcrumbs, courseId, taskId, taskName, t]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("editTaskTabActiveKey");
    };
  }, []);

  useEffect(() => {
    if (courseId) {
      serviceCourse.getSectionList(courseId)
        .then(res => {
          if (res.status === 200) {
            setSectionOptions(res.data);
          }
        });

      serviceCourse.getThemeList(courseId)
        .then(res => {
          if (res.status === 200) {
            setThemeOptions(res.data);
          }
        });

      serviceTask.getTaskGroupList(courseId)
        .then(res => {
          if (res.status === 200) {
            setTaskGroupOptions(res.data);
          }
        })

      serviceCourse.getGradingSystemId(courseId)
        .then(res => {
          if (res.status === 200) {
            setGradingSystemId(res.data);
          }
        })
    }
  }, [courseId]);

  useEffect(() => {
    if (taskId && taskId !== "new") {
      serviceTask.getTaskInfo(taskId)
        .then((res) => {
          if (res.status === 200) {
            setQuizId(res.data?.quiz?.id);
            setDraftId(res.data?.quiz?.draftId);
            setTaskType(res.data?.type);
            setTaskStatus(res.data?.status);
            setIsSelfTest(res.data?.isSelfTest);
            setTaskAnswerType(res.data?.answerType);
            setTaskName(res.data?.name);
          }
        })
    }
  }, [taskId, tabValue]);

  useEffect(() => {
    if (taskId && taskId !== "new") {
      serviceTask.getTaskMaterial(taskId).then((res) => {
        if (res.status === 200) setMaterialList(res.data);
      });
    }
  }, [taskId]);

  const handleTabChange = (newValue: string) => {
    sessionStorage.setItem("editTaskTabActiveKey", newValue);
    setTabValue(newValue);
  };

  return (
    <Box sx={{ maxWidth: '1880px' }}>
      <Box sx={{ display: 'flex', mb: 3 }}>
        <Breadcrumbs id={courseId} />
      </Box>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "36px",
          lineHeight: "44px",
          mb: '36px'
        }}
      >
        {taskId === "new" ? t('course:task.ADDING_TASK') : taskName}
      </Typography>
      <Box sx={{ mb: 1 }}>
        {messageOpen &&
          <Message
            type={messageType}
            message={message}
            setMessageOpen={setMessageOpen}
          />
        }
      </Box>

      <Box sx={{ width: '100%', backgroundColor: 'white', border: `1px solid ${common.borderSecondary}`, height: 'auto', borderRadius: '12px' }}>
        <TabContext value={tabValue} >
          <TabList onChange={(_, value: string) => handleTabChange(value)} textColor="inherit" indicatorColor="primary"
            sx={{ mt: 2, width: { sm: '100%', xs: '250px' } }}
            variant='scrollable'
            scrollButtons
          >
            <Tab label={t('course:task.TASK_PARAMETERS')} value="taskParams" sx={{ textTransform: 'none', fontSize: { xs: '16px', sm: '18px' }, lineHeight: '24px', fontWeight: 500 }} />
            {taskFormat === FormatOfTask.QUIZ &&
              <Tab label={t('course:task.QUESTIONS')} value="questions" sx={{ textTransform: 'none', fontSize: { xs: '16px', sm: '18px' }, lineHeight: '24px', fontWeight: 500 }} disabled={taskId === 'new'} />
            }
            {!isSelfTest && courseData?.formatType === CourseFormatType.SYNC &&
              <Tab label={t('course:task.RECIPIENTS')} value="recipients" sx={{ textTransform: 'none', fontSize: { xs: '16px', sm: '18px' }, lineHeight: '24px', fontWeight: 500 }} disabled={taskId === 'new'} />
            }
            {
              taskFormat !== FormatOfTask.QUIZ &&
              <Tab label={t('course:ADDITIONAL_MATERIALS')} value="additionalMaterials" sx={{ textTransform: 'none', fontSize: { xs: '16px', sm: '18px' }, lineHeight: '24px', fontWeight: 500 }} disabled={taskId === 'new'} />
            }
          </TabList>
          <TabPanel value="taskParams" sx={{ borderTop: `1px solid ${common.borderSecondary}` }}>
            <Settings
              sectionOptions={sectionOptions}
              themeOptions={themeOptions}
              taskGroupOptions={taskGroupOptions}
              gradingSystemId={gradingSystemId}
              setIsSelfTest={setIsSelfTest}
              setTaskName={setTaskName}
              setMessageOpen={setMessageOpen}
              setMessageType={setMessageType}
              setMessage={setMessage}
            />
          </TabPanel>
          {taskFormat === FormatOfTask.QUIZ &&
            <TabPanel value="questions" sx={{ borderTop: `1px solid ${common.borderSecondary}` }}>
              <Questions quizId={draftId || quizId} taskStatus={taskStatus} isDraft={Boolean(draftId)} />
            </TabPanel>
          }
          {!isSelfTest && courseData?.formatType === CourseFormatType.SYNC &&
            <TabPanel value="recipients" sx={{ borderTop: `1px solid ${common.borderSecondary}` }}>
              <RecipientList
                setMessageOpen={setMessageOpen}
                setMessageType={setMessageType}
                setMessage={setMessage}
                taskType={taskType}
                taskStatus={taskStatus}
                taskName={taskName}
                answerType={taskAnswerType}
              />
            </TabPanel>
          }
          {
            taskFormat !== FormatOfTask.QUIZ &&
            <TabPanel value="additionalMaterials" sx={{ borderTop: `1px solid ${common.borderSecondary}` }}>
              <MaterialList
                materials={materialList}
                setMaterials={setMaterialList}
                setMessage={setMessage}
                setMessageOpen={setMessageOpen}
                setMessageType={setMessageType}
                disabled={taskStatus === CourseStatus.PUBLISHED}
              />
            </TabPanel>
          }
        </TabContext>
      </Box>
    </Box>
  )
}

export default TaskFormat;