import { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { Grid, useTheme, Box, Button, IconButton, Typography, useMediaQuery } from '@mui/material';
import StickyNote2Outlined from '@mui/icons-material/StickyNote2Outlined';

import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import Breadcrumbs from '@components/Breadcrumbs';
import { FloatingNote } from '@components/FloatingNote';
import CourseMainPage from '../TabList/Main';
import ImportantEvents from '../TabList/Main/ImportantEvents';
import { ContentCurrentCourses } from './Content';
import { TaskCurrentCourses } from './Task/List';
import Notes from '../TabList/Notes';
import { ConferenceCurrentCourses } from './Conference';
import { Attendance } from './Attendance';
import { CourseFormatType, IBreadcrumb, TypeCode } from '@interfaces/index';
import { useTranslation } from 'react-i18next';
import { CourseContext } from "@context/CourseContext";
import { ActionCourse } from '@pages/myCourses/Educator/PublishedCourse/ActionCourse';

const CoursePage = () => {
  const { status, classId } = useParams();
  const { t } = useTranslation(["course"])
  const { common } = useTheme().palette;
  const [open, setOpen] = useState<boolean>(false);
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const stepLabel = getBreadcrumbs(classId || "").find((x: IBreadcrumb) => x.level === 2)?.label || t('course:listener.MAIN_PAGE');
  const currentStep = getBreadcrumbs(classId || "").find((x: IBreadcrumb) => x.level === 2)?.tabNum || 1;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const { courseData } = useContext(CourseContext)
  const tabList = [
    {
      label: t('course:listener.MAIN_PAGE'),
      step: 1
    },
    {
      label: t('course:task.CONTENT'),
      step: 2
    },
    {
      label: t('course:TASKS'),
      step: 3
    },
    {
      label: t('course:CONFERENCES'),
      step: 4
    },
    {
      label: t('course:ATTENDANCE'),
      step: 5
    },
    {
      label: t('course:NOTE'),
      step: 6
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (currentStep === "5") {
      setOpen(false);
    }
  }, [currentStep]);

  useEffect(() => {
    let updatedCrumbs = [...getBreadcrumbs(classId || "")].filter((x: IBreadcrumb) => x.level !== 3 && x.level !== 4);
    if (updatedCrumbs.length === 1) {
      updatedCrumbs.push({ level: 2, page: 'course', label: t('course:listener.MAIN_PAGE'), path: window.location.pathname, tabNum: 1 });
    }
    // Check if the breadcrumbs have actually changed before updating
    if (JSON.stringify(updatedCrumbs) !== JSON.stringify(getBreadcrumbs(classId || ""))) {
      if (classId) setBreadcrumbs(classId, updatedCrumbs);
    }
  }, [getBreadcrumbs, status, setBreadcrumbs, classId, t]);

  const changeStep = (step: number, label: string) => {
    const newCrumbs = [...getBreadcrumbs(classId || "")].map((crumb) => {
      // If the crumb to update is found, replace the crumb with updated fields
      if (crumb.level === 2) {
        return { ...crumb, label, tabNum: step };
      }

      // Otherwise, return the original crumb
      return crumb;
    });
    if (classId) setBreadcrumbs(classId, newCrumbs);
  }

  return (
    <Box sx={{ maxWidth: '1880px' }}>
      <Box sx={{ display: 'flex', mb: 3 }}>
        <Breadcrumbs id={classId} />
      </Box>
      <Grid container direction={{ lg: 'row', xs: 'column-reverse' }} spacing={1.5} justifyContent='space-evenly'>
        {tabList.map((item) => {
          if (currentStep !== item.step) return null;
          return (
            <Grid
              item xs={12} lg={8.5} xl={10}
              key={`course_creation_step_${item.step}`}
            >
              <Box sx={{ width: "100%", borderRadius: "12px", height: "auto" }}>
                {item.step !== 1 &&
                  <Typography fontWeight={500} fontSize="36px">{stepLabel}</Typography>
                }
                {(() => {
                  switch (item.step) {
                    case 1: return <CourseMainPage />;
                    case 2: return <ContentCurrentCourses />;
                    case 3: return <TaskCurrentCourses />;
                    case 4: return <ConferenceCurrentCourses />
                    case 5: return <Attendance courseData={courseData} />;
                    case 6: return <Notes />;

                    default: return <CourseMainPage />;
                  }
                })()}
              </Box>
            </Grid>
          );
        })}
        <Grid item xs={12} lg={3} xl={2}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "sticky",
                top: "80px",
                alignItems: "center",
                width: { xs: '250px', sm: '550px', lg: '100%' },
                overflowX: 'auto'
              }}
            >
              <Box
                sx={{
                  background: common.fontWhite,
                  width: '100%',
                  display: "flex",
                  flexDirection: { lg: "column", xs: 'row' },
                  alignItems: "center",
                  borderRadius: "12px",
                  gap: 2,
                  py: 2,
                  overflowX: 'auto',
                  border: `1px solid ${common.borderSecondary}`
                }}
              >
                {tabList.map((item, index) => {
                  if (item.step === 5 && !courseData?.accountAttendance) return null
                  else {
                    return (
                      <Button
                        key={`course_creation_step_btns_${item.step}_${index}`}
                        sx={{
                          background: currentStep === item.step ? common.primaryColor : common.fontWhite,
                          color: currentStep === item.step ? common.fontWhite : common.primaryColor,
                          "&.MuiButtonBase-root:hover": {
                            background: common.secondaryColor,
                            color: common.primaryColor
                          },
                          borderRadius: "8px",
                          width: { lg: "85%", xs: '150px' },
                          minWidth: '120px',
                          border: `2px solid ${common.primaryColor}`,
                          textTransform: "none",
                          fontSize: { sm: '14px', lg: '16px' },
                          height: { xs: '50px', lg: 'auto' }
                        }}
                        onClick={() => changeStep(item.step, item.label)}
                      >
                        {item.label}
                      </Button>
                    )
                  }
                })}
              </Box>

              {matches && (courseData?.typeCode !== TypeCode.FREE ||
                (courseData?.typeCode === TypeCode.FREE &&
                  courseData?.formatType ===
                  CourseFormatType.SYNC)) && <ImportantEvents />}
              {matches && courseData?.typeCode === TypeCode.FREE &&
                courseData?.formatType === CourseFormatType.ASYNC &&
                <ActionCourse />
              }
            </Box>
          </Box>

        </Grid>
      </Grid>
      {currentStep !== 6 && <FloatingNote open={open} setOpen={setOpen} />}
      {
        currentStep !== 6 &&
        <Box sx={{ display: 'flex', position: "fixed", bottom: "100px", right: "50px", zIndex: 100000 }}
          onClick={() => setOpen(!open)}>
          <IconButton sx={{ background: "#F3F56D", padding: "15px", color: "#000", ":hover": { background: "#F3F56D" } }}>
            <StickyNote2Outlined fontSize='small' />
          </IconButton>
        </Box>
      }
    </Box >
  )
}

export default CoursePage;