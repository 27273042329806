import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useTranslation } from 'react-i18next';

import CustomModal from '@components/Modal';

const SuccesSend = ({ open, handleToggleModal }) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course"]);

  return (
    <CustomModal
      open={open}
      handleToggleModal={handleToggleModal}
      width={494}
      borderColor={common.primaryColor}
      showCloseIcon
    >
      <Box sx={{ display: 'block' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CheckCircleOutlineOutlinedIcon sx={{ width: '140px', height: '140px', color: '#00B998', mb: '32px' }} />
        </Box>
        <Typography id="modal-modal-title" sx={{ color: "#4D5377", display: "flex", alignItems: "center", textAlign: "center", justifyContent: 'center', fontSize: '20px', fontWeight: '500', lineHeight: '26px' }}>
          {t('course:messages.TASK_HAS_BEEN_SUBMITTED_SUCCESSFULLY')}
        </Typography>
      </Box>
    </CustomModal>
  )
}

export default SuccesSend;