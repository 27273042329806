import { ICourse, ICourseSetting } from "@interfaces/index";
import { createContext, useContext } from "react";

interface CourseContextSchema {
  courseData: any & ICourse & ICourseSetting;
  setCourseData: (courseData: any) => void;
}

export const CourseContext = createContext<CourseContextSchema>({} as CourseContextSchema);


export function useCourseContext():CourseContextSchema{
  return useContext(CourseContext);
}