import Breadcrumbs from '@components/Breadcrumbs'
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import { Box, Switch, Typography, useTheme, AlertColor } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AntiPlagiarsmInfo } from './AntiPlagiarsmInfo';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CustomButton from '@ui/Button';
import CustomSearch from '@ui/Search';
import CustomAutocomplete from '@ui/Autocomplete';
import { CourseStatus, IntagrationCode } from '@interfaces/index';
import { MaterialReactTable, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import AvatarPicture from '@components/AvatarPicture';
import {
  type MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table"
import { SettingAntiPlagiarismModal } from './SettingAntiPlagiarismModal';
import { CourseListModal } from './CourseListModal';
import Message from '@components/Message';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { serviceAntiPlagiarism } from '@services/antiPlagiarism';
import { AntiPlagiarismFilter } from './models';
import { serviceIntegration } from '@services/integrations';

export const AntiPlagiarismPage = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { id } = useParams();
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const { t } = useTranslation(["dictionary", "sideMenu", "enum", "course", "common", "antiPlagiarism"]);
  const [openSetting, setOpenSetting] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [filter, setFilter] = useState<AntiPlagiarismFilter>({ search: null, status: null });
  const [openCourseList, setOpenCourseList] = useState<boolean>(false);
  const queryClient = useQueryClient()
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });

  const searchByName = (searchValue: string) => {
    setFilter({ ...filter, search: searchValue });
  };

  const handleChangeStatusCourseFilter = (searchValue: string) => {
    setFilter({ ...filter, status: searchValue });
  };

  const { data: antiPlagiarismIntegrations } =
    useQuery({
      queryKey: ['getAntiPlagiarismIntegrations'],
      queryFn: () => serviceIntegration.getIntegrationByOrgId(Number(id))
        .then((res) => res.data.filter((info) => info.integration.code === IntagrationCode.ANTI_PLAGIARISM)[0]),
      retry: 0,
    });

  const { data: courseListAntiPlagiarism } =
    useQuery({
      queryKey: ['courseListAntiPlagiarism', pagination.pageIndex, pagination.pageSize, sorting, filter],
      queryFn: () => serviceAntiPlagiarism.getCourseListAntiPlagiarism({
        pageNumber: pagination.pageIndex,
        size: pagination.pageSize,
        filter,
        sorting: sorting?.length > 0 ? { [sorting[0].id]: sorting[0].desc === true ? "desc" : "asc" } : {}
      },),
      retry: 0,
      placeholderData: keepPreviousData,
    });

  const { mutate: handleUpdateCourseAntiPlagiarsm } = useMutation({
    mutationFn: (reqBody) => {
      return serviceAntiPlagiarism.updateSwitchCoursePlagiarism(reqBody);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['courseListAntiPlagiarism'] })
      setMessage(t("antiPlagiarism:SUCCESSFULLY_CONNECTED_ANTIPLAGIARISM"));
      setMessageOpen(true);
      setMessageType("success");
      setTimeout(() => {
        setMessageOpen(false);
      }, 3000);
    },
  })
  const { mutate: handleUpdateAntiPlagiarusm } = useMutation({
    mutationFn: (reqBody) => {
      return serviceIntegration.updateIntegrationByOrgId([reqBody]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAntiPlagiarismIntegrations'] })
      setMessage(t(!antiPlagiarismIntegrations?.isActive ? "antiPlagiarism:INTEGRATION_ACTIVATED" : "antiPlagiarism:INTEGRATION_DISABLED"));
      setMessageOpen(true);
      setMessageType("success");
      setTimeout(() => {
        setMessageOpen(false);
      }, 3000);
    },
  })

  useEffect(() => {
    setBreadcrumbs("antiPlagiarism", [{
      level: 1,
      page: 'settings',
      label: "course:SETTINGS",
      path: '/settings',
      tabNum: null
    }, {
      level: 2,
      page: 'integrations',
      label: "sideMenu:INTEGRATIONS",
      path: `/settings/integrations/${id}`,
      tabNum: null
      ,
    },
    {
      level: 3,
      page: 'antiPlagiarism',
      label: "antiPlagiarism:ANTIPLAGIARISM",
      path: null,
      tabNum: null
    }]);
    // eslint-disable-next-line
  }, []);
  const columns = useMemo<any>(
    () => [
      {
        accessorKey: "name",
        header: t("course:COURSE_NAME"),
        size: 370,
        minSize: 190,
        enableSorting: true,
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: common.tableHeaderColor,
            boxShadow: "5px 0 7px -2px rgba(91, 90, 48, 0.1)",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            backgroundColor: "#FFFFFF",
            boxShadow: "12px 0 8px -1px rgba(91, 90, 48, 0.1)",
          },
        },
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            {row?.original?.coverFileId ? (
              <AvatarPicture
                id={row?.original?.coverFileId}
                sxProps={{
                  width: "40px",
                  height: "30px",
                  borderRadius: "2px",
                }}
              />
            ) : (
              <Box
                sx={{
                  backgroundColor: row.original.coverColor,
                  width: "40px",
                  height: "30px",
                  borderRadius: "2px",
                  flexShrink: 0,
                }}
              />
            )}
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "20px",
                cursor: "pointer",
                color: common.primaryColor
              }}
              onClick={() => navigate(`/settings/integrations/${id}/anti-plagiarism/${row.original.courseId}`)}
            >
              {renderedCellValue}
            </Typography>
          </Box>
        ),
      },
      {
        accessorKey: "status",
        header: t("user:STATUS"),
        size: 160,
        enableResizing: false,
        enableSorting: false,
        Cell: ({ row }) => {
          const course = row.original;
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                background:
                  course.status === CourseStatus.SAVED
                    ? "#E8E8E8"
                    : course.status === CourseStatus.PUBLISHED
                      ? "#EBF6EB"
                      : course.status === CourseStatus.WITHDRAWN
                        ? "#FFF4E4"
                        : "#CFDDFF",
                color:
                  course.status === CourseStatus.SAVED
                    ? "#6B6B6B"
                    : course.status === CourseStatus.PUBLISHED
                      ? "#31AA27"
                      : course.status === CourseStatus.WITHDRAWN
                        ? "#D9B201"
                        : "#2074D6",
                borderRadius: "10px",
                height: "28px",
                width: "fit-content",
                px: 1,
              }}
            >
              {t(`course:${course.status}`)}
            </Box>
          );
        },
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: 'enabled',
        header: t("antiPlagiarism:ANTIPLAGIARISM"),
        enableSorting: false,
        size: 100,
        Cell: ({ row }) => {
          return (
            <Switch
              checked={row.original.enabled}
              onChange={(event) => handleUpdateCourseAntiPlagiarsm({ ...row.original, enabled: event.target.checked })}
              disabled={!antiPlagiarismIntegrations?.isActive} />
          )
        }
      },
      {
        accessorKey: 'usagesCount',
        header: t("antiPlagiarism:SPENT"),
        enableSorting: true,
        size: 100
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [t, common, antiPlagiarismIntegrations?.isActive]);

  const table = useMaterialReactTable({
    columns,
    data: courseListAntiPlagiarism?.data.dtoList || [],
    enableSorting: true,
    enableColumnActions: false,
    enableExpandAll: false,
    enableColumnResizing: false,
    enableTopToolbar: false,
    enableRowNumbers: true,
    enableColumnPinning: false,
    enableRowActions: false,
    manualPagination: true,
    manualSorting: true,
    localization: {
      noRecordsToDisplay: t('dictionary:EMPTY'),
      rowsPerPage: t('enum:LINES_PER_PAGE'),
      actions: "",
      expand: "",
      rowNumber: '№',
    },
    displayColumnDefOptions: {
      "mrt-row-expand": {
        size: 30,
        muiTableBodyCellProps: { align: "center" },
      },
      "mrt-row-actions": {
        size: 30,
        muiTableBodyCellProps: { align: "center" },
      },
    },
    layoutMode: "grid",
    columnResizeMode: "onChange",
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        display: "grid",
        borderRadius: "6px",
      },
    },
    muiTableContainerProps: {
      sx: {
        "&::-webkit-scrollbar": { height: "4px", width: "7px" },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#D9D9D9",
          borderRadius: "4px",
        },
      },
    },
    muiTableHeadCellProps: {
      sx: { backgroundColor: common.tableHeaderColor },
    },
    muiTableBodyCellProps: {
      sx: { backgroundColor: "#FFFFFF" },
    },
    mrtTheme: (theme) => ({
      draggingBorderColor: theme.palette.common.primaryColor,
    }),
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50],
      shape: "rounded",
      variant: "outlined",
      SelectProps: {
        sx: {
          backgroundColor: "#FFFFFF",
          color: common.primaryColor,
          "& .MuiInputBase-root": {
            "& .MuiSelect-icon": { color: common.primaryColor },
          },
        },
      },
      sx: {
        "& .Mui-selected": {
          background: `${common.primaryColor} !important`,
          color: "#FFFFFF",
        },
      },
    },
    onPaginationChange: setPagination,
    state: { pagination, sorting }, //pass the pagination state to the table
    rowCount: courseListAntiPlagiarism?.data.totalElements || 0,
    onSortingChange: setSorting,
    paginationDisplayMode: "pages",
  });
  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: 'center', mb: '24px' }}>
        <Breadcrumbs id="antiPlagiarism" />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography sx={{ fontSize: '36px', fontWeight: 500, mb: '24px' }}>
          {t("antiPlagiarism:INTEGRATION_WITH_ANTIPLAGIARISM")}
        </Typography>
        <Box display="flex" gap={1.5}>
          <Box sx={{ background: common.white, borderRadius: "6px", padding: "6px 8px 0px 8px", alignItems: "center", cursor: "pointer" }} onClick={() => setOpenSetting(!openSetting)}>
            <SettingsOutlinedIcon sx={{ color: "#6D737A" }} />
          </Box>
          <Box display="flex" gap={1} sx={{ background: common.white, borderRadius: "6px", padding: "0px 8px", alignItems: "center" }} alignItems="center">
            <Typography fontSize="16px" color="#6D737A">{t("antiPlagiarism:ACTIVATED")}</Typography>
            <Switch checked={antiPlagiarismIntegrations?.isActive} onChange={(event) => handleUpdateAntiPlagiarusm({ ...antiPlagiarismIntegrations, isActive: event.target.checked })} />
          </Box>
        </Box>
      </Box>
      {messageOpen && (
        <Box mb="12px">
          <Message
            type={messageType}
            message={message}
            setMessageOpen={messageOpen}
          />
        </Box>
      )}
      <AntiPlagiarsmInfo />
      <Box display="flex" justifyContent="space-between" mt="24px" mb="16px" alignItems="center">
        <Box display="flex" alignItems="center" gap={1}>
          <CustomSearch placeholder={t("common:placeholders.SEARCH_BY_NAME")} width='460px' onChange={(value) => searchByName(value)} />
          <CustomAutocomplete
            sx={{ width: { lg: '200px', xs: '100%' } }}
            placeholder={t("course:COURSE_STATUS")}
            name="typeCourse"
            options={[
              { name: t(`course:${CourseStatus.SAVED}`), id: CourseStatus.SAVED },
              { name: t(`course:${CourseStatus.PUBLISHED}`), id: CourseStatus.PUBLISHED },
              { name: t(`course:${CourseStatus.WITHDRAWN}`), id: CourseStatus.WITHDRAWN },
              { name: t(`course:${CourseStatus.CLOSED}`), id: CourseStatus.CLOSED }]}
            onChange={(_, value: any) => handleChangeStatusCourseFilter(value)}

          />
        </Box>
        <CustomButton disabled={!antiPlagiarismIntegrations?.isActive} borderRadius='6px' width='155px' onClick={() => setOpenCourseList(!openCourseList)}>
          {t("antiPlagiarism:CONNECT_COURSE")}
        </CustomButton>
      </Box>

      <MaterialReactTable table={table} />
      {openSetting && <SettingAntiPlagiarismModal
        open={openSetting}
        handleToggleModal={() => setOpenSetting(!openSetting)} />}
      {openCourseList && <CourseListModal
        open={openCourseList}
        handleToggleModal={() => setOpenCourseList(!openCourseList)}
        setMessage={setMessage}
        setMessageOpen={setMessageOpen}
        setMessageType={setMessageType}
      />}
    </Box>
  )
}
