import { Box, Paper, Typography, useTheme } from "@mui/material";
import React from "react";

interface Props {
  svg?: any;
  text: string;
  height: number;
}
const EmptyComponent = (props: Props) => {
  const { height = "384px", text, svg } = props;
  const { common } = useTheme().palette;
  return (
    <Box
      sx={{
        height: `${height}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: `1px solid ${common.borderSecondary}`,
        borderRadius: "12px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <Box display={"flex"} justifyContent={"center"}>
          {svg && svg}
        </Box>
        <Typography>{text}</Typography>
      </Box>
    </Box>
  );
};

export { EmptyComponent };
