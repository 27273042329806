import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Form as FormikForm } from "formik";
import { Box, Fab, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from "@mui/icons-material/Clear";
import { AlertColor } from '@mui/material/Alert';

import { serviceCourse } from '@services/course';
import { StateSchema } from '@store/StateSchema';
import { CourseContext } from '@context/CourseContext';
import useDeleteConfirmModal from '@hooks/useDeleteConfirmModal';
import ImageViewer from '@components/ImageViewer';
import VideoPlayer from '@components/VideoPlayer';
import CustomButton from '@ui/Button';
import CustomFileUpload from '@ui/FileUpload';
import FormikInput from '@ui/formik/Input';
import { ICourse, CourseStatus, AdditionalMaterialTypes } from '@interfaces/index';
import { subtractFileNameFromFullFileName } from '@utils/functions';
import CropImageUpload from '@ui/CropImageUpload';
import ErrorIcon from '@mui/icons-material/Error';
import { courseMediaValidationSchema } from "./validation";
import SaveChangesModal from '@components/modals/SaveChangesModal';
import { courseCoverColorList } from '../../CourseInfo';

interface Props {
  courseInfo: ICourse;
  setCourseInfo: (courseInfo: any) => void;
  setMessageOpen: (isOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  setMessage: (message: string) => void;
}

export const MediaPage = ({ courseInfo, setCourseInfo, setMessageOpen, setMessageType, setMessage }: Props) => {
  const { courseId } = useParams();
  const { t } = useTranslation(["common", "course", "enum"]);
  const profileId = useSelector(
    (store: StateSchema) => store.account.profileInfo.pltaId
  );
  const { common } = useTheme().palette;
  const { courseData } = useContext(CourseContext);
  const [coverFileDeleteIsOn, setCoverFileDeleteIsOn] = useState<boolean>(true);
  const [videoLinkValue, setVideoLinkValue] = useState<string | null>(courseInfo.promoVideoUrl || null)
  const isSaved = courseInfo.status === CourseStatus.SAVED;
  const isWithdrawn = courseInfo.status === CourseStatus.WITHDRAWN;
  const isPublished = courseInfo.status === CourseStatus.PUBLISHED;
  const isClosed = courseInfo.status === CourseStatus.CLOSED;

  const isAuthor = courseInfo?.authors?.some((author) => author.userId === profileId);

  const deleteCoverFile = async () => {
    const res = await serviceCourse.putCoverImage(courseId || "");
    if (res.status === 200) {
      setCourseInfo({ ...courseInfo, coverFileId: null });
      setMessageOpen(true);
      setMessageType("success");
      setMessage(t('messages.FILE_DELETED'));
      setTimeout(() => {
        setMessageOpen(false);
      }, 3000);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return res;
    }
  }

  const deleteVideoFile = async () => {
    const res = await serviceCourse.updateCourse(courseId || "", { ...courseInfo, promoVideoId: null, promoVideoName: null });
    if (res.status === 200) {
      setCourseInfo({ ...courseInfo, promoVideoId: null });
      setMessageOpen(true);
      setMessageType("success");
      setMessage(t('messages.VIDEO_DELETED'));
      setTimeout(() => {
        setMessageOpen(false);
      }, 3000);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return res;
    }
  }

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: () => coverFileDeleteIsOn ? deleteCoverFile() : deleteVideoFile(),
    modalTitle: coverFileDeleteIsOn ? t('DELETE_COVER_FILE_CONFIRMATION_TEXT') : t('DELETE_VIDEO_CONFIRMATION_TEXT'),
  });

  const handleSubmit = (values: ICourse) => {
    values.courseSetting.formatType = !values.courseSetting.formatType ? "SYNC" : values.courseSetting.formatType;
    if (courseId) {
      serviceCourse.updateCourse(courseId, values).then((res) => {
        if (res.status === 200) {
          setCourseInfo(values);
          setMessageOpen(true);
          setMessageType('success')
          setMessage(t('common:messages.DATA_CHANGED_SUCCESSFULLY'))
          setTimeout(() => {
            setMessageOpen(false);
          }, 5000);
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 25)
      });
    }
  };

  const deleteIcon = (id: string, type: string, setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void) => {
    return (
      <Fab
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          margin: 0.625,
          zIndex: 1,
          backgroundColor: '#F8F8F8',
          border: `1px solid ${common.strokePrimary}`,
          boxShadow: 0
        }}
        size="small"
        onClick={() => {
          if ((courseInfo.coverFileId && type === 'cover-file') || (courseInfo.promoVideoId && type === 'promo-video')) {
            setCoverFileDeleteIsOn(type === 'cover-file');
            openDeleteConfirmModal(id);
          } else {
            if (type === 'promo-video') {
              setFieldValue('promoVideoId', null);
              setFieldValue('promoVideoName', null);
            }
          }
        }}
      >
        <CloseIcon sx={{ color: common.errorColor }} />
      </Fab>
    )
  }

  return (
    <Box>
      <Formik
        initialValues={courseInfo}
        enableReinitialize
        validationSchema={courseMediaValidationSchema(t)}
        onSubmit={handleSubmit}
      >
        {({ values, submitForm, isValid, setFieldValue, dirty }) => (
          <FormikForm>
            <Typography fontSize="16px">{t('course:COVER')}</Typography>
            {!courseInfo.coverFileId ?
              <CropImageUpload
                title={t('course:DRAG_IMAGE_HERE')}
                fileTypeCode="006"
                acceptedExtensions=".jpg, .jpeg, .png"
                handleFileAfterUpload={(fileId: string | null) => setFieldValue('coverFileId', fileId)}
                bgColor={values.coverColor}
                height={255}
              /> :
              <Box sx={{ width: '60%', position: "relative" }}>
                <ImageViewer
                  fileId={courseInfo.coverFileId}
                  viewOnly
                />
                {courseInfo.coverFileId && (isSaved || isWithdrawn) &&
                  deleteIcon(courseInfo.coverFileId, 'cover-file', setFieldValue)
                }
              </Box>
            }
            {!courseInfo.coverFileId && !isClosed &&
              <Box display="flex" gap={4} alignItems="center" marginTop="20px">
                <Typography>{t('course:CHOOSE_COVER_COLOR')}</Typography>
                <Box display="flex" gap={2}>
                  {courseCoverColorList.map((bg) => {
                    return (
                      <Box
                        key={bg}
                        sx={{
                          background: bg,
                          height: "50px",
                          width: "50px",
                          cursor: "pointer",
                          border: values.coverColor === bg ? `2px solid ${common.primaryColor}` : "none"
                        }}
                        onClick={(!isClosed) ? () => {
                          setFieldValue("coverColor", bg)
                        } : undefined}
                      />
                    );
                  })}
                </Box>
              </Box>
            }
            <Typography fontSize="16px" marginTop="24px">{t('course:PROMO_VIDEO')}</Typography>
            <Box display="flex" gap={1} margin="12px 0">
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#1A1F36",
                      display: "flex",
                      borderRadius: "8px",
                      width: "254px"
                    }
                  }
                }}
                title={values.promoVideoUrl && t("course:hints.YOU_MUST_DELETE_CURRENT_LINK")}
                placement="top"
              >
                <Box
                  sx={{
                    background: values.promoVideoType === AdditionalMaterialTypes.FILE ? common.primaryColor : "#fff",
                    color: values.promoVideoUrl ? "#D4D2D5" : values.promoVideoType === AdditionalMaterialTypes.FILE ? "#fff" : "#686B81",
                    borderRadius: "6px",
                    cursor: "pointer",
                    padding: "8px 16px",
                    ":hover": { background: !values.promoVideoUrl ? common.primaryColor : "#fff", color: !values.promoVideoUrl ? "#fff" : "#D4D2D5" }
                  }}
                  onClick={() => !values.promoVideoUrl && setFieldValue("promoVideoType", AdditionalMaterialTypes.FILE)}>
                  {t('enum:UPLOAD_VIDEO')}
                </Box>
              </Tooltip>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#1A1F36",
                      display: "flex",
                      borderRadius: "8px",
                      width: "254px"
                    }
                  }
                }}
                title={values.promoVideoId && t("course:hints.YOU_MUST_DELETE_CURRENT_VIDEO")}
                placement="top"
              >
                <Box
                  sx={{
                    background: values.promoVideoType === AdditionalMaterialTypes.LINK ? common.primaryColor : "#fff",
                    color: values.promoVideoId ? "#D4D2D5" : values.promoVideoType === AdditionalMaterialTypes.LINK ? "#fff" : "#686B81",
                    borderRadius: "6px",
                    cursor: "pointer",
                    padding: "8px 16px",
                    ":hover": { background: !values.promoVideoId ? common.primaryColor : "#fff", color: !values.promoVideoId ? "#fff" : "#D4D2D5" }
                  }}
                  onClick={() => !values.promoVideoId && setFieldValue("promoVideoType", AdditionalMaterialTypes.LINK)}>
                  {t('enum:PROVIDE_LINK')}
                </Box>
              </Tooltip>
            </Box>
            <>
              {values.promoVideoType === AdditionalMaterialTypes.FILE ? (values.promoVideoId ?
                <>
                  <Box sx={{ width: '60%', position: 'relative' }}>
                    <VideoPlayer videoFileId={values.promoVideoId} />
                    {values.promoVideoId && (isSaved || isWithdrawn) &&
                      deleteIcon(values.promoVideoId, 'promo-video', setFieldValue)
                    }
                  </Box>
                  <FormikInput
                    label={t('course:PROMO_VIDEO_NAME')}
                    name="promoVideoName"
                    isRequired
                    disabled={!(isSaved || isWithdrawn) || !isAuthor || isClosed || isPublished}
                  />
                </> :
                <Box>
                  <Grid container gap={1.5}>
                    <Grid item xs={7}>
                      <CustomFileUpload
                        title={t('course:DRAG_VIDEO_TITLE')}
                        header={t('course:DRAG_VIDEO_HEADER')}
                        fileTypeCode="011"
                        acceptedExtensions=".avi, .mp4"
                        disabled={(isSaved && isWithdrawn) || !isAuthor || isClosed || isPublished}
                        sizeLimit={courseData?.maxPromoVideoSize}
                        handleFileAfterUpload={(fileId: string, file: any) => {
                          setFieldValue('promoVideoId', fileId);
                          setFieldValue('promoVideoName', subtractFileNameFromFullFileName(file.name));
                        }}
                        height={200}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Box sx={{
                        display: 'flex',
                        width: '100%',
                        p: '10px',
                        backgroundColor: '#fff',
                        borderRadius: '12px',
                        alignItems: 'center',
                        gap: 2,
                        border: `1px solid ${common.borderSecondary}`
                      }}>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '28px',
                          height: '28px',
                          borderRadius: '50%',
                          backgroundColor: common.primaryColor,
                          padding: '10px'
                        }}
                        >
                          <ErrorIcon sx={{ color: 'white', width: '20px' }} />
                        </Box>
                        <Box>
                          <Typography sx={{ fontWeight: 400, fontSize: '12px', lineHeight: '20px' }}>
                            {t("course:FORMATS", { formats: "mp4, avi." })}
                          </Typography>
                          <Typography sx={{ fontWeight: 400, fontSize: '12px', lineHeight: '20px' }}>
                            {t("course:MAX_FILE_SIZE", { size: "5000кб" })}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>) :
                <Box display="flex" gap={1} marginBottom="15px">
                  <Box sx={{ width: "50%" }}>
                    <FormikInput
                      name="promoVideoUrl"
                      label={t('course:theme.LINK_TO_VIDEO')}
                      placeholder="https://www.youtube.com/embed/"
                      isRequired
                      disabled={!(isSaved || isWithdrawn) || !isAuthor || isClosed || isPublished}
                      adornmentIcon={!((isSaved && isWithdrawn) || !isAuthor || isClosed || isPublished) ? <ClearIcon /> : <></>}
                      onAdornmentClick={() => {
                        setFieldValue("promoVideoUrl", "")
                        setVideoLinkValue(null)
                      }}
                    />
                  </Box>
                  <Box sx={{ marginTop: "30px" }}>
                    <CustomButton
                      disabled={!values.promoVideoUrl}
                      width='100px'
                      borderRadius="6px"
                      onClick={() => setVideoLinkValue(values.promoVideoUrl)}
                    >
                      {t("common:actions.APPLY")}
                    </CustomButton>
                  </Box>
                </Box>
              }
              <Box>
                {
                  videoLinkValue &&
                  <iframe
                    width="60%"
                    height="400px"
                    src={videoLinkValue}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title={videoLinkValue || 'Theme video title'}
                  />
                }
              </Box>
            </>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                marginTop: "20px"
              }}
            >
              <CustomButton
                backgroundColor={common.primaryColor}
                fontColor={common.fontWhite}
                width="98px"
                borderRadius="6px"
                height="38px"
                disabled={!(isValid && dirty) || !isAuthor}
                onClick={submitForm}
              >
                {t('common:actions.SAVE')}
              </CustomButton>
            </Box>
            <SaveChangesModal dirty={dirty} />
          </FormikForm>
        )}
      </Formik>
      {DeleteModal}
    </Box>
  )
}
