import { useState, useEffect } from 'react'
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { serviceDict } from "@services/dictionary";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import General from '@pages/generalSettings/General';
import Course from '@pages/generalSettings/Course';
import { settings } from "@utils/enums";
import Message from "@components/Message";
import { useSearchParams } from 'react-router-dom';
import { AlertColor } from '@mui/material/Alert';
import { useSelector } from "react-redux";
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import Breadcrumbs from '@components/Breadcrumbs';

const CourseSettings = () => {
  const { t } = useTranslation(["dictionary", "course", 'generalSetting', 'sideMenu']);
  const { id } = useParams();
  const [courseType, setCourseType] = useState<string[] | string>('')
  const [value, setValue] = useState(id ? 'trainingCourse' : "general");
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [searchParams, setSearchParams] = useSearchParams();
  const { setBreadcrumbs } = useBreadcrumbs();
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { authorityInfo: { role } } = profile;
  let newSettings = (role === 'LOCAL_ADMIN')
    ? settings.slice(1, -1)
    : (id !== undefined && role === 'SUPER_ADMIN')
      ? settings.slice(1)
      : settings

  useEffect(() => {
    if (id && id !== "new") {
      serviceDict.getDictionary("BRANCH", id).then((res) => {
        setCourseType(res.data.availableCourseTypes)
        if (res.data.availableCourseTypes.length === 1 && res.data.availableCourseTypes[0] === 'EDU') {
          setValue('trainingCourse')
        } else if (res.data.availableCourseTypes.length === 1 && res.data.availableCourseTypes[0] === 'FREE') {
          setValue('freeCourse')
        }
      });
    };
    if (role === 'SUPER_ADMIN' && !id) {
      setValue('general')
    }
  }, [id, role]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setSearchParams({ tab: newValue })
  };

  useEffect(() => {
    if (role === 'SUPER_ADMIN' && id) {
      setBreadcrumbs("general_settings", [{
        level: 1,
        page: 'dictionary',
        label: "DICTIONARIES",
        path: '/dict',
        tabNum: null
      }, {
        level: 2,
        page: 'organizations',
        label: "ORGANIZATIONS",
        path: '/dict/organizations',
        tabNum: null
      },
      {
        level: 3,
        page: 'edit organization',
        label: "EDIT_ORGANIZATION",
        path: `/dict/organization/${id}?tab=additionalSettings`,
        tabNum: null
      },
      {
        level: 4,
        page: 'course settings',
        label: "course:COURSE_SETTINGS",
        path: null,
        tabNum: null
      }]);
    } else if (role === 'LOCAL_ADMIN') {
      setBreadcrumbs("general_settings", [{
        level: 1,
        page: 'settings',
        label: "course:SETTINGS",
        path: '/settings',
        tabNum: null
      }, {
        level: 2,
        page: 'general settings',
        label: "generalSetting:GENERAL_SETTINGS",
        path: null,
        tabNum: null
      }]);
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Box>
      {((role === 'SUPER_ADMIN' && id) || role === 'LOCAL_ADMIN') &&
        <Box sx={{ display: "flex", alignItems: 'center', mb: '24px' }}>
          <Breadcrumbs id="general_settings" />
        </Box>
      }
      <Box>
        <Typography sx={{ fontSize: '36px', fontWeight: 500, mb: '24px' }}>
          {t("generalSetting:GENERAL_SETTINGS")}
        </Typography>
        {messageOpen &&
          <Box sx={{ mb: '24px', mt: '-12px' }}>
            <Message
              type={messageType}
              message={message}
              setMessageOpen={setMessageOpen}
            />
          </Box>
        }
        <TabContext value={value}>
          <TabList onChange={handleChange} textColor="secondary" indicatorColor="secondary" sx={{ ml: '24px' }}>
            {
              newSettings.map((setting, index) => {
                return (
                  <Tab key={index}
                    label={t(`generalSetting:${setting.text}`)}
                    value={setting.value}
                    sx={{ textTransform: 'none', fontSize: '18px', lineHeight: '24px', fontWeight: 500, color: '#1A141F' }}
                    disabled={
                      (role === 'SUPER_ADMIN' && id && setting.text === 'FREE_COURSE' && !courseType.includes('FREE')) ||
                      (role === 'SUPER_ADMIN' && id && setting.text === 'TRAINING_COURSE' && !courseType.includes('EDU')) ||
                      (role === 'LOCAL_ADMIN' && setting.text === 'TRAINING_COURSE' && !courseType.includes('EDU')) ||
                      (role === 'LOCAL_ADMIN' && setting.text === 'FREE_COURSE' && !courseType.includes('FREE'))
                    }
                  />
                )
              })
            }
          </TabList>
          {
            newSettings.map((setting, index) => {
              return (
                <TabPanel value={setting.value} key={index} sx={{ p: 0 }}>
                  {(() => {
                    switch (setting.step) {
                      case 1: return <General setMessage={setMessage} setMessageOpen={setMessageOpen} setMessageType={setMessageType} />;
                      case 2: return <Course orgId={id} code="EDU" setMessage={setMessage} setMessageOpen={setMessageOpen} setMessageType={setMessageType} />;
                      case 3: return <Course orgId={id} code="FREE" setMessage={setMessage} setMessageOpen={setMessageOpen} setMessageType={setMessageType} />;
                      default: <General setMessage={setMessage} setMessageOpen={setMessageOpen} setMessageType={setMessageType} />;
                    }
                  })()}
                </TabPanel>
              )
            })
          }
        </TabContext>
      </Box>
    </Box >
  )
}

export default CourseSettings
