import { useTranslation } from "react-i18next";
import { Box, useTheme } from "@mui/material";
import Clear from "@mui/icons-material/Clear";

import CustomModal from "@components/Modal";
import CustomButton from "@ui/Button";
import { AlertIcon } from "@assets/svg_files/Icons";
import { DoneIcon } from "@assets/svg_files/Icons";

interface Props {
  openModal: boolean;
  handleToggleModal: (modalState: boolean) => void;
  success: boolean | null;
  attention: boolean;
  message: string;
  text?: string;
  confirmAction: () => void;
  icon?: boolean;
}

const YesOrNoModal = ({
  openModal,
  handleToggleModal,
  success,
  attention,
  message,
  confirmAction,
  icon,
  text,
}: Props) => {
  const { t } = useTranslation("course");
  const { common } = useTheme().palette;

  return (
    <CustomModal
      open={openModal}
      handleToggleModal={handleToggleModal}
      borderColor={common.primaryColor}
      width={515}
    >
      <>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Clear
            onClick={() => {
              handleToggleModal(false);
            }}
            sx={{ cursor: "pointer", color: common.borderSecondary }}
          />
        </Box>
        {!icon && (
          <Box sx={{ display: "flex", justifyContent: "center", mb: "32px" }}>
            {success ? <DoneIcon /> : <AlertIcon />}
          </Box>
        )}

        {attention && !icon && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: 500,
              fontSize: "28px",
              color: common.primaryColor,
              mb: "10px",
            }}
          >
            {t("ATTENTION")}
          </Box>
        )}
        <Box mb={4} display={'flex'} gap={1.5} flexDirection={'column'}>
          <Box
            sx={{
              display: "flex",
              fontWeight: 500,
              fontSize: "20px",
              textAlign: "center",
              lineHeight: "26px",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            {message}
          </Box>
          {text && (
            <Box
              sx={{
                display: "flex",
                fontWeight: 400,
                fontSize: "16px",
                textAlign: "center",
                lineHeight: "21px",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                color: "#6D737A"
              }}
            >
              {text}
            </Box>
          )}
        </Box>

        {attention && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <CustomButton
              width="209px"
              height="40px"
              borderRadius="8px"
              backgroundColor="#F6F6F6"
              fontColor={common.primaryColor}
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                lineHeight: "24px",
              }}
              onClick={() => handleToggleModal(false)}
            >
              {t("common:NO")}
            </CustomButton>
            <CustomButton
              width="209px"
              height="40px"
              borderRadius="8px"
              backgroundColor={common.primaryColor}
              fontColor="#FFFFFF"
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                lineHeight: "24px",
              }}
              onClick={confirmAction}
            >
              {t("common:YES")}
            </CustomButton>
          </Box>
        )}
      </>
    </CustomModal>
  );
};

export default YesOrNoModal;
