import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';

const Statistics = ({ all, completed, taskFormat, markable }) => {
  const { t } = useTranslation(['course']);
  const { common } = useTheme().palette;

  return (
    <Box
      sx={{
        width: '256px',
        height: 'fit-content',
        marginLeft: 'auto',
        backgroundColor: common.white,
        p: 2,
        borderTop: 'solid 8px #3AD1C8',
        borderRadius: '8px'
      }}
    >
      <Box sx={{ display: 'flex', ml: '18px' }}>
        <Typography sx={{ fontWeight: 500, fontSize: '20px', lineHeight: '21px' }}>{t('course:listener.STATISTICS')}</Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Avatar
          alt="task-stats"
          src={require("@assets/images/task_stats_icon.png")}
          sx={{
            width: 32,
            height: 32,
            backgroundColor: '#FFF7E5'
          }}
        />
        <Box>
          <Typography sx={{ fontWeight: 500, fontSize: 20 }}>
            {taskFormat === 'QUIZ' ? `${Number(completed)}/${Number(all)}` : `${Number(all) - Number(completed)}/${Number(all)}`}
          </Typography>
          <Typography sx={{ fontSize: 14, color: "#9DA6BA" }}>
            {taskFormat === 'QUIZ' ? `${t('course:test.PASSED_THE_TEST')}` :
              markable ? `${t('course:test.REQUIRE_EVALUATION')}` : `${t('course:test.REQUIRE_REVIEW')}`
            }
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Statistics;