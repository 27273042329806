import { ReactElement, FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from "@mui/material";
import { dictionaryList } from "@utils/enums";

const Dictionary: FC<any> = (): ReactElement => {
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const { t } = useTranslation(["dictionary", "common"]);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredDictionary = dictionaryList.filter((item) => {
    const title = t(`dictionary:${item.text}`);
    return title.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: 28, paddingBottom: 3, mt: '4px' }}>
          {t("DICTIONARIES")}
        </Typography>
        <TextField
          label={t("common:placeholders.SEARCH_BY_NAME")}
          color="primary"
          size="small"
          sx={{
            width: '30%', '& label.Mui-focused': { color: common.primaryColor },
            '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': { borderColor: common.primaryColor },
              '&.Mui-focused fieldset': { borderColor: common.primaryColor }
            }, mt: '8px'
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: 18 }}>{t("TITLE")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDictionary.map((item, index) => {
              return (
                <TableRow
                  key={item.dictCode}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`${item.url}`);
                  }}
                >
                  <TableCell sx={{ fontSize: 16 }} key={index}>
                    {t(`dictionary:${item.text}`)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Dictionary;