import { CourseTaskRecipient } from "@models/course-task-recipient";
import { QuizQuestion } from "@models/quiz-question";
import { rtkApi } from "@store/rtkApi";
import axios from "@utils/axios";
import {
  IComment,
  IMaterial,
  IProgress,
  ITask,
  ITaskFreeCourses,
  ITaskGroup,
} from "interfaces";

export const serviceTask = {
  // Task services
  getTaskList: (courseId: string | undefined, bodyData: any) =>
    axios.post(`/api/course/task/${courseId}/findAll`, bodyData),
  createTask: (bodyData: any, courseId: string) =>
    axios.post(`/api/course/task/create?courseId=${courseId}`, bodyData),
  updateTask: (taskId: string, bodyData: any) =>
    axios.put(`/api/course/task/update/${taskId}`, bodyData),
  getTaskInfo: (taskId: string | undefined) =>
    axios.get(`/api/course/task/${taskId}`),
  getOrderedTaskList: (classId: string) =>
    axios.get(`/api/course/student/ordered/${classId}/findAll`),
  deleteTask: (taskId: string) => axios.delete(`/api/course/task/${taskId}`),
  getTaskCreationAvailability: (courseId: string) =>
    axios.get(`/api/course/task/creation/checker/${courseId}`),
  putTaskMaterial: (bodyData: IMaterial) =>
    axios.put(`/api/course/task/material`, bodyData),
  getTaskMaterial: (taskId: string) =>
    axios.get(`/api/course/task/material/${taskId}/findAll`),
  deleteTaskMaterial: (materialId: string | number) =>
    axios.delete(`/api/course/task/material/${materialId}`),
  getTaskListForDropdown: (courseId: string, queryParams = {}) =>
    axios.get(`/api/course/task/${courseId}/findAll`, { params: queryParams }),
  changeTaskStatus: (taskId: string) =>
    axios.put(`/api/course/task/${taskId}/change-status`),
  // TaskGroup services
  getTaskGroupList: (courseId: string | undefined) =>
    axios.get(`/api/course/group/task/${courseId}/findAll`),
  getTaskGroupInfo: (taskGroupId: string) =>
    axios.get(`/api/course/group/task/${taskGroupId}/find`),
  createTaskGroup: (bodyData: ITaskGroup, courseId: string) =>
    axios.post(`/api/course/group/task/create?courseId=${courseId}`, bodyData),
  updateTaskGroup: (bodyData: ITaskGroup, courseId: string) =>
    axios.put(
      `/api/course/group/task/${bodyData.id}/update?courseId=${courseId}`,
      bodyData
    ),
  updateAllTaskGroupRatio: (bodyData: Array<ITaskGroup>, courseId: string) =>
    axios.put(
      `/api/course/group/task/update-list?courseId=${courseId}`,
      bodyData
    ),
  deleteTaskGroup: (groupId: string | number, courseId: string) =>
    axios.delete(`/api/course/group/task/${groupId}?courseId=${courseId}`),
  getTaskGroupsWithTasks: (courseId: string) =>
    axios.get(`/api/course/group/task/${courseId}/find-with-tasks`),
  // Quiz services
  getAllQuizList: (quizId: string) =>
    axios.get(`/api/course/task/quiz/question/${quizId}/findAll`),
  getQuizListByDraftId: (quizDraftId: string) =>
    axios.get(`/api/course/task/quiz/questions/draft/${quizDraftId}`),
  getQuestionById: (questionId: string, queryParams = {}) =>
    axios.get(`/api/course/task/quiz/question/${questionId}`, {
      params: queryParams,
    }),
  createTaskQuiz: (bodyData: QuizQuestion) =>
    axios.post(`/api/course/task/quiz/question/create`, bodyData),
  updateTaskQuiz: (bodyData: QuizQuestion, id: string) =>
    axios.put(`/api/course/task/quiz/question/${id}/update`, bodyData),
  deleteTaskQuiz: (id: string) =>
    axios.delete(`/api/course/task/quiz/question/${id}`),
  createTaskRecipients: (data: CourseTaskRecipient, classId: string) =>
    axios.post(`/api/course/task-recipient/create?classId=${classId}`, data),
  getRecipientById: (id: string) =>
    axios.get(`/api/course/task-recipient/${id}`),
  getAllRecipientsByClassId: (taskId: string, classId: string) =>
    axios.get(
      `/api/course/task-recipient/${taskId}/findAll?classId=${classId}`
    ),
  getTaskRecipientsWithFilter: (bodyData: {
    taskId: string;
    name?: string;
    status?: string;
    classId?: string;
  }) => axios.post(`/api/course/task-recipient/findAll`, bodyData),
  deleteTaskRecipient: (deleteId: string) =>
    axios.delete(`/api/course/task-recipient/${deleteId}`),
  updateTaskRecipient: (id: string, bodyData: any) =>
    axios.put(`/api/course/task-recipient/update/${id}`, bodyData),
  getQuestionExists: (quizId: string) =>
    axios.get(`/api/course/task/quiz/question-exists/${quizId}`),
  getQuestionSum: (quizId: string) =>
    axios.get(`/api/course/task/quiz/question-sum/${quizId}`),
  // For student
  getTaskListForStudent: (bodyData: any) =>
    axios.post("/api/course/student/my-tasks/findAll", bodyData),
  checkQuizProgress: (taskProgressId: string) =>
    axios.get(`/api/course/quiz-progress/existence/current/${taskProgressId}`),
  getQuizAttemptResult: (taskProgressId: string) =>
    axios.get(`/api/course/quiz-attempt/result/list/${taskProgressId}/find`),
  getFullResultViaAttemptId: (attemptId: string) =>
    axios.get(`/api/course/quiz-attempt/result/${attemptId}/find`),
  startResumeQuiz: (taskProgressId: string) =>
    axios.get(`/api/course/student/${taskProgressId}/begin`),
  startSelfTestQuiz: (taskId: string) =>
    axios.get(`/api/course/student/self-test/${taskId}/begin`),
  getQuizQuestionList: (quizProgressId: string) =>
    axios.get(`/api/course/quiz-execution/current/${quizProgressId}`),
  getQuizQuestionById: (quizProgressId: string, questionId: string) =>
    axios.get(
      `/api/course/quiz-execution/${quizProgressId}/question/${questionId}`
    ),
  postQuizQuestion: (quizProgressId: string, bodyData: any) =>
    axios.post(
      `/api/course/quiz-execution/selected/${quizProgressId}`,
      bodyData
    ),
  completeQuiz: (quizProgressId: string) =>
    axios.post(`/api/course/quiz-execution/finish/${quizProgressId}`),
  // Task comment
  createTaskComment: (bodyData: IComment) =>
    axios.post("/api/course/task/reply/create", bodyData),
  getAllTaskComments: (progressId: string) =>
    axios.get(`/api/course/task/reply/${progressId}/findAll`),
  getAllTaskCommentsOfListener: (progressId: string) =>
    axios.get(`/api/course/task/reply/listener/${progressId}/findAll`),
  downloadTaskReplies: (classId: string, taskId: string) =>
    axios.get(
      `/api/course/file/download/task-reply-files?classId=${classId}&taskId=${taskId}`,
      { responseType: "blob" }
    ),
  // Assessment
  getAssessmentTaskList: (classId: string | undefined, bodyData: any) =>
    axios.post(`/api/course/task-assessment/find?classId=${classId}`, bodyData),
  getAssessmentSumListenerTaskId: (taskId: string, classId: string) =>
    axios.get(`/api/course/task-assessment/sum-listeners/${taskId}/${classId}`),
  // Progress
  getSumTaskListeners: (taskId: string) =>
    axios.get(`/api/course/task-progress/sum-listeners/${taskId}`),
  getListenersOfRecipient: (recipientId: string) =>
    axios.get(
      `/api/course/task-progress/find-by-recipient-id?recipientId=${recipientId}`
    ),
  getTaskProgressInfo: (progressId: string) =>
    axios.get(`/api/course/task-progress/find/${progressId}`),
  updateTaskProgress: (bodyData: IProgress) =>
    axios.put(`/api/course/task-progress/update`, bodyData),
  deleteTaskProgress: (progressId: string) =>
    axios.delete(`/api/course/task-progress/${progressId}`),
};

const serviceTaskApi = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getTaskInfo: build.query<ITaskFreeCourses, string>({
      query: (taskId) => ({
        url: `course/task/${taskId}`,
      }),
    }),
    getRecipientById: build.query<CourseTaskRecipient, string>({
      query: (id) => ({
        url: `course/task-recipient/${id}`,
      }),
    }),
    getAllTaskComments: build.query<IComment[], string>({
      query: (progressId) => ({
        url: `course/task/reply/${progressId}/findAll`,
      }),
    }),
  }),
});

export const {
  useGetTaskInfoQuery,
  useGetRecipientByIdQuery,
  useGetAllTaskCommentsQuery,
} = serviceTaskApi;
