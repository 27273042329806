import { useQuery } from "@tanstack/react-query";

import { ClassAssessment } from "@interfaces/classAssessment";
import { MyCourseFilter, ResponseFilter } from "@interfaces/filter";
import { ResponseList } from "@interfaces/responseList";
import API from "./api";

export class MyCourseControllerApi extends API {
  private readonly url = '/course/student';

  checkStudentHasRestrictedCourses(): Promise<boolean> {
    return this.get(`${this.url}/my-course/has-limited`);
  }
  getListenerMyCoursesList(body: ResponseFilter<MyCourseFilter>): Promise<ResponseList<ClassAssessment>> {
    return this.post(`${this.url}/my-course/findAll`, body);
  }

  getCourseDataByClassId(classId: string): Promise<ResponseList<ClassAssessment>> {
    return this.get(`${this.url}/my-course/${classId}`);
  }
}

const api = new MyCourseControllerApi();

export const useStudentInfoByClassId = (classId: string) => {
  return useQuery({
    queryKey: ['studentInfo', classId],
    queryFn: () => api.getCourseDataByClassId(classId)
  });
}