import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import { Box, SxProps, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ru from "date-fns/locale/ru";
import kk from "date-fns/locale/kk";

import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import IconHints from '@components/IconHints';
registerLocale('ru', ru);
registerLocale('kk', kk);

interface Props {
  label?: string;
  name: string;
  fontSize?: string;
  size?: 'small' | 'medium';
  disabled?: boolean;
  isRequired?: boolean;
  isClearable?: boolean;
  showTime?: boolean;
  placeholder?: string
  selectsRange?: boolean;
  handleDateChange?: (date: Date | null) => void;
  date?: any;
  sx?: SxProps;
  hintText?: string;
  minDate?: string | Date;
  maxDate?: string | Date;
}

const CustomDatePicker: React.FC<Props> = ({
  label,
  name,
  fontSize,
  size,
  disabled = false,
  isRequired = false,
  isClearable = false,
  showTime = false,
  placeholder,
  selectsRange = false,
  handleDateChange,
  date,
  hintText,
  minDate,
  maxDate,
  sx = { width: "100%" }
}) => {
  const { common } = useTheme().palette;
  const [dateValue, setDateValue] = useState<Date | null>(null);
  const handleChange = (newValue: any) => {
    if (handleDateChange) handleDateChange(newValue);

    setDateValue(newValue);
  };

  const getDateValue = () => {
    if (date) return new Date(date);

    return dateValue;
  }

  const isDateSelectable = (date: any) => {
    if (!minDate || !maxDate) {
      // If either start or end date is not selected, allow all dates.
      return true;
    }

    // Allow dates between the selected start and end dates (inclusive).
    return new Date(date) >= new Date(minDate) && new Date(date) <= new Date(maxDate);
  };

  useEffect(() => {
    setDateValue(date ? new Date(date) : null);
  }, [date]);

  return (
    <Box sx={sx}>
      {label &&
        <Box display='flex' alignItems='center' gap={1}>
          <Typography>
            {label}
            {isRequired && <span style={{ color: common.errorColor }}>*</span>}
          </Typography>
          {hintText && <IconHints text={hintText} />}
        </Box>
      }

      <Box display="flex" >
        <DatePicker
          name={name}
          showTimeSelect={showTime}
          selectsRange={selectsRange}
          disabled={disabled}
          timeCaption="Время:"
          placeholderText={placeholder ? placeholder : showTime ? 'дд.мм.гггг чч:мм' : 'дд.мм.гггг'}
          dateFormat={showTime ? "dd-MM-yyyy HH:mm" : "dd-MM-yyyy"}
          onChange={(date: any) => handleChange(date)}
          selected={getDateValue()}
          isClearable={isClearable}
          minDate={minDate ? new Date(minDate) : null}
          maxDate={maxDate ? new Date(maxDate) : null}
          filterTime={isDateSelectable}
          customInput={
            <TextField
              size={size ? size : 'small'}
              fullWidth
              InputProps={{
                endAdornment: (<CalendarMonthIcon sx={{ color: "#757575" }} />)
              }}
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: "#FFFFFF"
                },
                '& .MuiInputBase-root.Mui-disabled': {
                  backgroundColor: disabled ? common.inputDisabledBg : "transparent"
                },
                '& label.Mui-focused': { color: common.primaryColor },
                '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': { borderColor: common.primaryColor },
                  '&.Mui-focused fieldset': { borderColor: common.primaryColor }
                },
                '.MuiOutlinedInput-input': {
                  fontSize: fontSize
                },
                '.MuiOutlinedInput-input::placeholder': {
                  fontSize: fontSize
                },
              }}
            />
          }
          locale={ru}
        />
      </Box>
    </Box>
  );
}

export default CustomDatePicker;