import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import "./i18n";
import "./assets/styles/index.scss";
import { StoreProvider } from "@store/StoreProvider";

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  const script = document.createElement('script');
  script.type = 'text/javascript'
  script.text = `
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();
      for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
      k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

      ym(98326731, "init", {
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true
      });
  `;
  document.body.appendChild(script);
}

const router = createBrowserRouter([{ path: "*", Component: App }]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <StoreProvider>
    <RouterProvider router={router} />
  </StoreProvider>
);
