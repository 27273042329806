import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, FormHelperText, Typography, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import { AlertColor } from '@mui/material/Alert';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Formik, Form as FormikForm } from "formik";

import { serviceUser } from "@services/user";
import { serviceDict } from "@services/dictionary";
import { serviceRole } from "@services/role";
import Message from "@components/Message";
import AvatarPicture from "@components/AvatarPicture";
import CustomButton from "@ui/Button";
import FormikInput from "@ui/formik/Input";
import UploadButton from "@ui/UploadButton";
import DatePicker from "@ui/formik/DatePicker";
import FormikCheckbox from "@ui/formik/Checkbox";
import MaskedInput from "@ui/formik/MaskedInput";
import FormikRadioGroup from "@ui/formik/RadioGroup";
import FormikAutocomplete from "@ui/formik/Autocomplete";
import SelectOptionsByOrgId from "./SelectOptionsByOrgId";
import { IDictionary, IUser, IRole, IUserRole, DictionaryCode } from "@interfaces/index";
import { IOrganization } from "../dictionary/Organization/models";
import { initialDto, initialUserRole } from "./models";
import { validationSchema } from "./validation";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { HintComponent } from "@components/common";

const Form = () => {
  const { t } = useTranslation(["user", "common", "dictionary"]);
  const navigate = useNavigate();
  const { id } = useParams();
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { authorityInfo, authorityInfo: { role } } = profile;
  const { common } = useTheme().palette;
  const [initialData, setInitialData] = useState<IUser>(initialDto);
  const [roleList, setRoleList] = useState<IRole[]>([]);
  const [roleIdList, setRoleCodeList] = useState<number[]>([]);
  const [listenerEducatorIds, setListenerEducatorIds] = useState<number[]>([]);
  const [disabledRoleOptions, setDisabledRoleOptions] = useState<Array<number> | undefined>();
  const [organizationList, setOrganizationList] = useState<IOrganization[]>([]);
  const [languageList, setLanguageList] = useState<IDictionary[]>([]);
  const [showMessageAction, setShowMessageAction] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [disabledLangOptions, setDisabledLangOptions] = useState<string[] | number[]>([]);
  const [existingUserId, setExistingUserId] = useState<string | null>(null)

  useEffect(() => {
    serviceDict.getDictListForDropdown(DictionaryCode.BRANCH).then((res) => {
      if (res.status === 200) {
        setOrganizationList(res.data.filter((x: IOrganization) => x.id !== 0));
      }
    });

    serviceDict.getDictListForDropdown(DictionaryCode.LANGUAGE).then((res) => {
      if (res.status === 200) {
        setLanguageList(res.data);
        setDisabledLangOptions(res.data.filter((language: IDictionary) => language.isDisabled).map((lang: IDictionary) => lang.id));
      }
    });

    serviceRole.getAllRolesForSelect().then((res) => {
      if (res.status === 200) {
        setRoleList(res.data);
        let roleIds: Array<number> = [];
        let listenerEducatorRoleIds: Array<number> = [];
        res.data.forEach((role: IRole) => {
          if (role.id && role.code) {
            roleIds.push(role.id);
            if (role.code === 'LISTENER' || role.code === 'EDUCATOR') {
              listenerEducatorRoleIds.push(role.id);
            }
          }
        });
        setRoleCodeList(roleIds);
        setListenerEducatorIds(listenerEducatorRoleIds);
      }
    });
  }, []);

  const disableRoleOptions = useCallback((userRoles: Array<IUserRole>) => {
    let blockedRoles: Array<number> = [];
    userRoles.forEach((userRole: IUserRole) => {
      if (role === "LOCAL_ADMIN") {
        if (userRole.role?.id) blockedRoles.push(userRole.role.id);
        else {
          const roleId = roleList.find((x: IRole) => x.id === userRole.role.id)?.id;
          if (roleId) blockedRoles.push(roleId);
        }
      }
    });
    if (userRoles.length >= 1 && userRoles[0].role.id) {
      if ([...userRoles].some((userRole: IUserRole) => listenerEducatorIds.includes(userRole.role?.id || -1))) {
        setDisabledRoleOptions(roleIdList.filter(x => !listenerEducatorIds.includes(x)).concat(blockedRoles));
      } else {
        setDisabledRoleOptions(listenerEducatorIds.concat(blockedRoles));
      }
    } else {
      setDisabledRoleOptions(undefined);
    }
  }, [role, roleList, roleIdList, listenerEducatorIds]);

  useEffect(() => {
    if (id === "new") {
      if (role === "LOCAL_ADMIN")
        setInitialData({
          ...initialDto, userRoles: [{ ...initialUserRole, belongsToOrg: true, orgId: authorityInfo.orgId }]
        });
    }
    else {
      if (id) {
        serviceUser.getUserInfoById(id).then((res) => {
          if (res.status === 200) {
            const userRoles = res.data.userRoles;
            userRoles.forEach((userRole: IUserRole) => {
              if (userRole.orgId !== 0) userRole.belongsToOrg = true;
            });
            disableRoleOptions(userRoles);
            setInitialData({ ...res.data, userRoles });
          }
        });
      }
    }
  }, [id, role, authorityInfo.orgId, roleIdList, disableRoleOptions]);

  const saveUserPhoto = (photoId: string) => {
    if (id) {
      serviceUser.saveProfilePhoto(id, photoId).then(res => {
        if (res.status === 200) {
          setInitialData({ ...initialData, profilePhotoId: photoId });
        }
      });
    }
  }

  const getOrgTypeId = (orgId: number) => {
    return organizationList.find(x => x.id === orgId)?.branchTypeId;
  }

  const getRoleCode = (id: number | null) => {
    return roleList.find((x: IRole) => x.id === id)?.code;
  }

  return (
    <Box>
      <Box
        sx={{ display: "flex", alignItems: "center", mb: 3, cursor: "pointer" }}
        onClick={() => navigate('/users')}
      >
        <ArrowBackIosIcon sx={{ width: 24, height: 24 }} />
        <Typography sx={{ fontSize: "20px", fontWeight: "500", lineHeight: "20px" }}>
          {t("common:actions.GO_BACK")}
        </Typography>
      </Box>
      <Box sx={{ mb: 1 }}>
        {messageOpen &&
          <Message
            type={messageType}
            message={message}
            setMessageOpen={setMessageOpen}
            messageAction={showMessageAction && <Box display="flex" color={common.white} gap={1.5}>
              <Typography textTransform="uppercase" sx={{ cursor: "pointer" }} onClick={() => {
                setMessageOpen(false)
                navigate(`/users/${existingUserId}`)
              }
              }>{t("common:actions.OPEN")}</Typography>
              <CloseOutlinedIcon sx={{ cursor: "pointer" }} onClick={() => setMessageOpen(false)} />
            </Box>}
          />
        }
      </Box>

      <Box sx={{ background: "#FFFFFF", border: `1px solid ${common.borderSecondary}`, borderRadius: "9px" }}>
        <Typography sx={{ fontWeight: "500", fontSize: "36px", lineHeight: "44px", m: "24px 32px" }}>
          {id === "new" ? `${t("ADD_USER")}` : `${t("EDIT_USER")}`}
        </Typography>
        <Divider sx={{ color: common.borderSecondary, mx: 4 }} />
        <Box sx={{ display: "flex", m: "32px", alignItems: "center" }}>
          <AvatarPicture
            id={initialData.profilePhotoId}
            lastName={initialData.lastName || ''}
            sxProps={{
              width: { xl: 130, lg: 100, md: 95, sm: 90, xs: 85 },
              height: { xl: 130, lg: 100, md: 95, sm: 90, xs: 85 },
              mr: 3,
              backgroundColor: common.primaryColor
            }}
          />
          <Box sx={{ display: 'block' }}>
            <UploadButton
              label={t("UPLOAD_IMAGE")}
              backgroundColor={common.secondaryColor}
              fontColor={common.primaryColor}
              width="170px"
              borderRadius="6px"
              height="44px"
              disabled={id === "new" || initialData.migrated}
              typeCode="002"
              attachAndSaveFile={saveUserPhoto}
            />
            <Typography>{t("JPG_PNG_UP_TO_10_MB")}</Typography>
          </Box>
        </Box>
        <Formik
          initialValues={initialData}
          enableReinitialize
          validationSchema={validationSchema(t, organizationList)}
          onSubmit={(values: IUser) => {
            if (id === "new") {
              serviceUser.createUser({ ...values, username: values.email }).then((res) => {
                if (res.status === 201) {
                  navigate(`/users/${res.data.id}`);
                  setMessageType("success");
                  setMessageOpen(true);
                  setMessage(t('common:messages.DATA_SAVED_SUCCESSFULLY'))
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 10000)
                }
              }).catch((err) => {
                setMessageType("error");
                if (err.response.data.code === "SAME_USERNAME") {
                  serviceUser.getFullUserInfoByUserName(values.email || "").then((res) => {
                    setExistingUserId(res.data.id)
                  })
                  setShowMessageAction(true)
                  setMessageOpen(true);
                  setMessage(t('user:messages.USER_WITH_SAME_EMAIL_EXISTS'))
                } else if (err.response.data.message === "USER_IIN_EXISTS") {
                  setShowMessageAction(false)
                  setMessageOpen(true);
                  setMessage(t('user:messages.USER_WITH_SAME_IIN_EXISTS', {
                    status: err.response.data.params.status ? t('user:BLOCKED') : t('user:ACTIVE'),
                    userType: JSON.stringify(err.response.data.params.user_type) === JSON.stringify([1, 2]) ? `${t('user:USER')},${t('user:LISTENER')}` :
                      err.response.data.params.user_type.includes(1) ? t('user:USER') :
                        err.response.data.params.user_type.includes(2) ? t('user:LISTENER') : ""
                  }))
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 10000)
                }
                else if (err.response.data.message === "IIN_SEVENTH_NUMBER_INCORRECT") {
                  setShowMessageAction(false)
                  setMessageOpen(true);
                  setMessage(t('user:messages.INCORRECT_IIN'))
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 10000)
                }
                else {
                  setShowMessageAction(false)
                  setMessageOpen(true);
                  setMessage(t('common:messages.ERROR_SAVING_DATA'))
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 10000)
                }
              }).finally(() => {
                setTimeout(() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }, 100);
              })
            } else {
              serviceUser.updateUser(values).then((res) => {
                if (res.status === 200) {
                  setInitialData(values);
                  setMessageType("success");
                  setMessageOpen(true);
                  setMessage(t('common:messages.DATA_SAVED_SUCCESSFULLY'))
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 10000)
                }
              }).catch((err) => {
                setMessageType("error");
                if (err.response.data.message === "USER_IIN_EXISTS") {
                  setMessageOpen(true);
                  setMessage(t('user:messages.USER_WITH_SAME_IIN_EXISTS', {
                    status: err.response.data.params.status ? t('user:BLOCKED_ADJ') : t('user:ACTIVE'),
                    userType: JSON.stringify(err.response.data.params.user_type) === JSON.stringify([1, 2]) ? `${t('user:USER')},${t('user:LISTENER')}` :
                      err.response.data.params.user_type.includes(1) ? t('user:USER') :
                        err.response.data.params.user_type.includes(2) ? t('user:LISTENER') : ""
                  }))
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 10000)
                } else if (err.response.data.code === "SAME_USERNAME") {
                  setMessageOpen(true);
                  setMessage(t('user:messages.USER_WITH_SAME_EMAIL_EXISTS'))
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 10000)
                } else if (err.response.data.message === "IIN_SEVENTH_NUMBER_INCORRECT") {
                  setMessageOpen(true);
                  setMessage(t('user:messages.INCORRECT_IIN'))
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 10000)
                }
                else {
                  setMessageOpen(true);
                  setMessage(t('common:messages.ERROR_SAVING_DATA'))
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 10000)
                }
              }).finally(() => {
                setTimeout(() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }, 100);
              });
            }
          }}
        >
          {({ values, submitForm, isValid, dirty, errors, setFieldValue }) => {
            return (
              <FormikForm>
                <Box sx={{ mx: 5, mt: 3 }}>
                  <Box sx={{ display: "flex", mt: 1, width: '60%', justifyContent: 'space-between' }}>
                    <Box sx={{ width: "48%" }}>
                      <FormikInput
                        name="lastName"
                        label={t("LAST_NAME")}
                        isRequired
                        disabled={values.migrated}
                      />
                    </Box>
                    <Box sx={{ width: "48%" }}>
                      <FormikInput
                        name="firstName"
                        label={t("NAME")}
                        isRequired
                        disabled={values.migrated}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", mt: 1, justifyContent: 'space-between', width: '60%' }}>
                    <Box sx={{ width: "48%" }}>
                      <FormikInput
                        name="patronymic"
                        label={t("PATRONYMIC")}
                        disabled={values.migrated}
                      />
                    </Box>
                    <Box sx={{ width: "48%" }}>
                      <MaskedInput name="iin" label={t("IIN")} type="iin" disabled={values.migrated} />
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", mt: 1, justifyContent: 'space-between', width: '60%' }}>
                    <Box sx={{ width: '48%' }}>
                      <FormikAutocomplete
                        multiple
                        label={t("LANGUAGES")}
                        name="langIds"
                        disabledOptions={disabledLangOptions}
                        options={languageList}
                      />
                    </Box>
                    <Box sx={{ width: "48%" }}>
                      <FormikInput
                        name="email"
                        label={t("EMAIL")}
                        isRequired
                        disabled={values.migrated}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", mt: 1, justifyContent: 'space-between', width: '60%' }}>
                    <Box sx={{ width: "48%" }}>
                      <DatePicker
                        label={t("DATE_OF_BIRTH")}
                        name="birthdate"
                        isRequired={values?.iin?.length === 12}
                        disabled={values.migrated}
                      />
                    </Box>
                    <Box sx={{ width: "48%" }}>
                      <MaskedInput
                        name="mobilePhone"
                        label={t("PHONE_NUMBER")}
                        type="cellphone"
                        placeholder="+7 (_ _ _) _ _ _-_ _-_ _"
                        isRequired={!values.migrated}
                        disabled={values.migrated}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", mt: 1, justifyContent: 'space-between', width: '60%' }}>
                    <Box sx={{ width: '48%' }}>
                      <FormikInput
                        name="id"
                        label={t("EXTERNAL_ID")}
                        disabled
                      />
                    </Box>
                    <Box sx={{ width: "48%", display: 'flex' }}>
                      <FormikRadioGroup
                        groupLabel={t("GENDER")}
                        labelPosition="top"
                        name="sex"
                        isRequired
                        options={[
                          { label: `${t("MALE")}`, value: "MALE" },
                          { label: `${t("FEMALE")}`, value: "FEMALE" },
                        ]}
                        disabled={values.migrated}
                      />
                    </Box>
                  </Box>
                  {id === 'new' &&
                    <Box sx={{ display: "flex", mt: 1, width: '60%' }}>
                      <FormikCheckbox name="sendNotificationAfterCreate" label={t("SEND_NOTIFICATION")} labelPlacement="end" />
                    </Box>
                  }
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    m: 3,
                    width: '70%',
                    border: `1px solid ${common.borderPrimary}`,
                    borderRadius: 1.5,
                    p: 2.5,
                    backgroundColor: common.rootBgPrimary
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      width: '95%'
                    }}
                  >
                    {values.userRoles.length > 0 &&
                      values.userRoles.map((userRole: IUserRole, index: number) => {
                        return (
                          <Box
                            key={`${userRole?.id}_${index}`}
                            sx={{ display: "flex" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 0.5,
                                width: "95%"
                              }}
                            >
                              <FormikAutocomplete
                                label={t("ROLE")}
                                name={`userRoles[${index}].role.id`}
                                options={roleList}
                                onChange={(value: IRole) => {
                                  const updatedUserRoles = [...values.userRoles].map((obj, objIndex) => {
                                    const orgIdIsRequired = value.code !== 'EDUCATOR' && value.code !== 'LISTENER';
                                    if (objIndex === index) {
                                      return role === 'LOCAL_ADMIN' ?
                                        { ...initialUserRole, role: value, belongsToOrg: true, orgId: authorityInfo.orgId } :
                                        { ...initialUserRole, role: value, belongsToOrg: orgIdIsRequired, orgId: orgIdIsRequired ? null : 0 }
                                    }

                                    return obj;
                                  });
                                  setFieldValue('userRoles', updatedUserRoles);
                                }}
                                clearable={false}
                                disabledOptions={values.userRoles.length === 1 ? undefined : disabledRoleOptions}
                                isRequired
                                disabled={values.migrated}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 3
                                }}
                              >
                                <FormikCheckbox
                                  name={`userRoles[${index}].belongsToOrg`}
                                  label={t('user:REFERS_TO_THE_ORGANIZATION')}
                                  labelPlacement="end"
                                  disabled={(userRole.role.code !== 'EDUCATOR' && userRole.role.code !== 'LISTENER') || role === "LOCAL_ADMIN" || values.migrated}
                                  onChange={(_, value) => {
                                    const updatedUserRoles = [...values.userRoles].map((obj, objIndex) => {
                                      if (objIndex === index) {
                                        return { ...obj, belongsToOrg: value, orgId: value ? null : 0 };
                                      }

                                      return obj;
                                    });
                                    setFieldValue('userRoles', updatedUserRoles);
                                  }}
                                />
                                {userRole.belongsToOrg &&
                                  <FormikAutocomplete
                                    label={t('dictionary:ORGANIZATION')}
                                    name={`userRoles[${index}].orgId`}
                                    options={organizationList}
                                    onChange={(value: IOrganization) => {
                                      const updatedUserRoles = [...values.userRoles].map((obj, objIndex) => {
                                        if (objIndex === index) {
                                          return { ...obj, orgId: value.id, eduProgramId: null, subdivisionId: null };
                                        }

                                        return obj;
                                      });
                                      setFieldValue('userRoles', updatedUserRoles);
                                    }}
                                    clearable={false}
                                    isRequired
                                    disabled={role === "LOCAL_ADMIN" || values.migrated}
                                    sx={{ width: "50%", ml: "auto" }}
                                  />
                                }
                              </Box>
                              {userRole.belongsToOrg && (
                                getRoleCode(userRole.role.id) === "LISTENER" &&
                                getOrgTypeId(userRole.orgId || 0) === 2 &&
                                <SelectOptionsByOrgId orgId={userRole.orgId} dictKey="EDU_PROGRAM" name={`userRoles[${index}].eduProgramId`} isRequired disabled={values.migrated} />
                              )}
                              {userRole.belongsToOrg && (
                                getRoleCode(userRole.role.id) === "EDUCATOR" &&
                                <SelectOptionsByOrgId orgId={userRole.orgId} dictKey="SUBDIVISION" name={`userRoles[${index}].subdivisionId`} disabled={values.migrated} />
                              )}
                              <FormikCheckbox name={`userRoles[${index}].disabled`} label={t('user:BLOCK')} labelPlacement="end" disabled={values.migrated} />
                              <Divider sx={{ borderColor: common.primaryColor }} />
                            </Box>
                            {values.userRoles.length !== 1 && !values.migrated &&
                              <DeleteOutlineIcon
                                sx={{
                                  cursor: "pointer",
                                  color: common.errorColor,
                                  mt: 4,
                                  ml: 'auto'
                                }}
                                onClick={() => {
                                  const userRoles = [...values.userRoles.slice(0, index), ...values.userRoles.slice(index + 1)];
                                  disableRoleOptions(userRoles);
                                  setFieldValue('userRoles', userRoles);
                                }}
                              />
                            }
                          </Box>
                        );
                      })
                    }
                    {Boolean(errors.userRoles) && typeof errors.userRoles === 'string' &&
                      <FormHelperText error={Boolean(errors.userRoles)} sx={{ px: 1.75 }}>
                        {errors.userRoles}
                      </FormHelperText>
                    }
                    <CustomButton
                      backgroundColor={common.secondaryColor}
                      width="180px"
                      height="40px"
                      borderRadius="6px"
                      fontColor={common.primaryColor}
                      disabled={(values.userRoles.length > 0 &&
                        !values.userRoles[values.userRoles.length - 1].role.id)
                        || Boolean(typeof errors.userRoles === 'string')
                      }
                      onClick={() => {
                        disableRoleOptions([
                          ...values.userRoles,
                          role === 'LOCAL_ADMIN' ? { ...initialUserRole, belongsToOrg: true, orgId: authorityInfo.orgId } : initialUserRole
                        ]);
                        setFieldValue('userRoles', [
                          ...values.userRoles,
                          role === 'LOCAL_ADMIN' ? { ...initialUserRole, belongsToOrg: true, orgId: authorityInfo.orgId } : initialUserRole
                        ]);
                      }}
                    >
                      <PersonAddOutlinedIcon sx={{ mr: 1 }} />
                      {t('ADD_ROLE')}
                    </CustomButton>
                  </Box>
                </Box>
                <Box sx={{ m: 3, display: "flex", justifyContent: "end" }}>
                  <CustomButton
                    disabled={!(isValid && dirty)}
                    onClick={submitForm}
                    backgroundColor={common.primaryColor}
                    fontColor={common.fontWhite}
                    width="98px"
                    borderRadius="6px"
                    height="38px"
                    sx={{
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "130%",
                    }}
                  >
                    {t("common:actions.SAVE")}
                  </CustomButton>
                </Box>
              </FormikForm>
            )
          }}
        </Formik>
      </Box>
    </Box >
  );
};

export default Form;
