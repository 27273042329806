import StackImg from "@assets/images/home/stackImage.png";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HomeVariant } from "../types/home";
import s from "./ReadyStudentBlock.module.scss";
import { useTranslation } from "react-i18next";
import { Role } from "@interfaces/index";
interface Props {
  variant: HomeVariant;
}
interface Result {
  title: string;
  button?: string;
  onClick: () => void;
}
const ReadyStudentBlock = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation(["home"])
  const { variant } = props;
  const navigate = useNavigate();
  const userInfo = localStorage.getItem("userInfo");
  const role = JSON.parse(userInfo || "{}")?.authorityInfo?.role;
  const handleHomeVariant = (e: HomeVariant): Result => {
    switch (e) {
      case HomeVariant.ORGANIZATION:
        return {
          title: t('home:PREPARE_STUDENTS_FOR_SUCCESSFUL_CAREER'),
          button: t('home:CONTACT_US'),
          onClick: () => {
            searchParams.set("modal", "true");
            setSearchParams(searchParams);
          },
        };
      case HomeVariant.EDUCATOR:
        return {
          title: t('home:CREATE_COURSE_AND_CHANGE_PEOPLES_LIVES'),
          button: (!role || role === Role.EDUCATOR || role === Role.MANAGER) ? t('home:BECOME_AN_AUTHOR') : undefined,
          onClick: () => {
            navigate(userInfo ? "/myCourses" : "/registration");
          },
        };
      case HomeVariant.LISTENER:
        return {} as Result;
      default:
        return {} as Result;
    }
  };

  const data = handleHomeVariant(variant);
  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <div className={s.title}> {data.title}</div>
        {data.button &&
          <Box>
            <Button
              sx={{ borderRadius: "4px" }}
              size="large"
              variant="contained"
              color="primary"
              onClick={data.onClick}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 400,
                  lineHeight: "28px",
                }}
              >
                {data.button}
              </Typography>
            </Button>
          </Box>
        }
      </div>
      <div className={s.img}>
        <img src={StackImg} alt="" />
      </div>
    </div>
  );
};

export default ReadyStudentBlock;
