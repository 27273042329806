import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography, Popover, useTheme, AvatarGroup } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';
import ErrorIcon from '@mui/icons-material/Error';
import { capitalize } from "lodash";

import { serviceFile } from '@services/file';
import { serviceCourse } from '@services/course';
import { StateSchema } from "@store/StateSchema";
import { getBase64, removeHtml } from '@utils/functions';
import { DurationType, ICourse, IDictionary } from '@interfaces/index';
import AvatarPicture from '@components/AvatarPicture';

const OtherCoursesOfAuthorCard = ({ course, classId, diffLevelList }: { course: ICourse | null, classId: string, diffLevelList: Array<IDictionary> }) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course", "freeCourse"]);
  const navigate = useNavigate();
  const profile = useSelector((store: StateSchema) => store.account.profileInfo);
  const { pltaId } = profile;
  const lang = localStorage.getItem("i18nextLng") || "kz";
  const [fileUrl, setFileUrl] = useState<string>("");
  const [estimatedTime, setEstimatedTime] = useState<number>(0);
  const [hint, setHint] = useState<HTMLElement | null>(null);
  const difficultyLevel = diffLevelList.find(
    (x: IDictionary) => x.id === course?.difficultyLevelId
  );

  const hintPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHint(event.currentTarget);
  };

  const hintPopoverClose = () => {
    setHint(null);
  };
  const openHint = Boolean(hint);

  useEffect(() => {
    if (course?.coverFileId) {
      serviceFile.getFileMimeTypePublic(course?.coverFileId).then((res) => {
        const contentDisposition = res.headers['content-disposition'];
        const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        const file = new File([res.data], fileName)
        getBase64(file, url => setFileUrl(url))
      })
    }
  }, [course?.coverFileId]);

  useEffect(() => {
    if (course?.courseSetting?.durationType === DurationType.INDEFINITE && course.id) {
      serviceCourse.getContentSummaryPublic(course.id).then((res) => {
        if (res.status === 200) setEstimatedTime(res.data.estimatedTime)
      })
    }
  }, [course?.courseSetting?.durationType, course?.id]);

  return (
    <Paper
      sx={{
        border: `1px solid ${common.borderSecondary}`,
        padding: "12px 12px",
        borderRadius: "9px",
        width: "100%",
        transition: 'transform 0.3s',
        display: "flex",
        gap: 2,
        alignItems: "center",
        marginBottom: "16px",
        '&:hover': {
          boxShadow: '5px 5px 54px rgba(149,116,193,0.3)'
        },
      }}
      elevation={0}
    >
      {
        <Box
          sx={{
            ...(fileUrl
              ? {
                backgroundImage: `url(${fileUrl})`,
                height: "143px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                position: 'relative',
                borderRadius: '4px',
                width: "264px"
              }
              : {
                position: 'relative',
                height: '143px',
                backgroundColor: course?.coverColor,
                borderRadius: '4px',
                width: "264px"
              }
            )
          }}
        >
          <Box sx={{
            top: '8px',
            left: '8px',
            right: "8px",
            display: 'flex',
            position: 'absolute',
            justifyContent: "space-between"
          }}>
            <Box sx={{ display: 'flex', gap: "8px" }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '54px',
                height: '28px',
                borderRadius: "6px",
                background: `#fff`,
                fontSize: "12px",
                gap: 0.5
              }}
              >
                <StarIcon sx={{ color: "#F4C41A", fontSize: "20px" }} />
                {course?.rating}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '28px',
                  height: '28px',
                  borderRadius: '50%',
                  background: `rgba(148, 105, 227,0.5)`,
                  cursor: 'pointer',
                  padding: '10px'
                }}
                onMouseEnter={hintPopoverOpen}
                onMouseLeave={hintPopoverClose}
              >
                <ErrorIcon sx={{ color: 'white', opacity: 1, width: '20px' }} />
              </Box>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                }}
                open={openHint}
                anchorEl={hint}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                onClose={hintPopoverClose}
                disableRestoreFocus
                disableScrollLock
              >
                <Box
                  sx={{
                    p: 2,
                    color: common.fontWhite,
                    backgroundColor: "#1A1F36",
                    width: { sm: "318px", xs: "232px" },
                    fontSize: "12px",
                    fontFamily: "Golos",
                  }}
                >
                  {removeHtml(course?.shortDescription || "")}
                </Box>
              </Popover>
            </Box>
            <FavoriteIcon
              sx={{ color: "#fff", stroke: common.primaryColor, cursor: "pointer" }}
              onClick={() => { if (!pltaId) window.location.href = '/oauth2/authorization/gateway' }}
            />
          </Box>
        </Box>
      }
      <Box sx={{width: '50%'}}>
        <Box
          sx={{
            width: "100%",
            fontWeight: "600",
            fontSize: { sm: "18px", xs: '16px' },
            cursor: "pointer",
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            overflow: 'hidden',
            maxHeight: '3em',
            textOverflow: 'ellipsis',
          }}
          onClick={() => navigate(`/myCourses/planned/${classId}`)}
        >
          {course?.name}
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
          <Box
            sx={{
              fontWeight: "600",
              fontSize: { sm: "15px", xs: "13px" },
            }}
          >
            {t("freeCourse:FOR_FREE")}
          </Box>
          <Typography fontSize="12px" sx={{ color: "#6F6F7E" }}>
            {course?.courseSetting.durationType !==
              DurationType.INDEFINITE
              ? `${t("freeCourse:WITH_EDUCATOR")} `
              : estimatedTime / 60 > 1 ? `${Math.floor(estimatedTime / 60)} ч ` : `${estimatedTime} мин `}
            / {difficultyLevel && difficultyLevel[`name${capitalize(lang)}`]}
          </Typography>
          {course?.authors &&
            <Box
              sx={{
                display: 'flex',
                gap: 1
              }}
            >
              <AvatarGroup max={2} sx={{ width: 'fit-content' }}>
                {course.authors.map(
                  (author) => (
                    <AvatarPicture
                      key={author.userId}
                      userId={author.userId}
                      variant="circular"
                      sxProps={{
                        backgroundColor: common.secondaryColor,
                        width: "38px",
                        height: "38px"
                      }}
                    />
                  )
                )}
              </AvatarGroup>
              <span style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                {course.authors.map(
                  (author) => (
                    <Typography
                      key={author.userId}
                    >
                      {author.username}
                      <span></span>
                    </Typography>
                  )
                )}
              </span>
            </Box>
          }
        </Box>
      </Box>
    </Paper>
  )
}

export default OtherCoursesOfAuthorCard;
