import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import ReactRouterPrompt from "react-router-prompt";

import CustomModal from '@components/Modal';
import CustomButton from '@ui/Button';

interface IProps {
  dirty: boolean;
  text?: string;
}

const SaveChangesModal = ({ dirty, text }: IProps) => {
  const { t } = useTranslation(['common']);
  const { common } = useTheme().palette;

  return (
    <ReactRouterPrompt when={dirty}>
      {({ isActive, onConfirm, onCancel }) => (
        <CustomModal
          open={isActive}
          handleToggleModal={onCancel}
        >
          <>
            <Typography
              align='center'
              sx={{
                fontWeight: 500,
                fontSize: "28px",
                lineHeight: "36px",
                width: '550px'
              }}
            >
              {text || t('SAVE_CHANGES_CONFIRMATION_TEXT')}
            </Typography>
            <Box
              sx={{
                mt: "24px",
                display: "flex",
                gap: 1
              }}
            >
              <CustomButton
                backgroundColor={common.btnCancel}
                fontColor={common.primaryColor}
                borderRadius="6px"
                height="40px"
                sx={{
                  alignItems: "center",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={onCancel}
              >
                {t('NO')}
              </CustomButton>
              <CustomButton
                backgroundColor={common.primaryColor}
                fontColor={common.fontWhite}
                borderRadius="6px"
                height="40px"
                sx={{
                  alignItems: "center",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={onConfirm}
              >
                {t('YES')}
              </CustomButton>
            </Box>
          </>
        </CustomModal>
      )}
    </ReactRouterPrompt>
  )
}

export default SaveChangesModal;