import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, Typography } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";

import { QuizQuestion, QuizQuestionTypeEnum } from '@models/quiz-question';
import { questionAnswerInitialDto, questionChildrenInitialDto } from '../models';
import { IQuestionsFormikContext } from './Questions';
import { Mapping } from './QuizTypes/Mapping';
import { MultipleCorrectAnswers } from './QuizTypes/MultipleCorrectAnswers';
import { OneCorrectAnswers } from './QuizTypes/OneCorrectAnswer';
import { Sequence } from './QuizTypes/Sequence';
import { TrueOrFalse } from './QuizTypes/TrueOrFalse';

interface IQuestionAnswers {
  index: number
  handleTestType: () => void
}

const QuestionAnswers = ({ index, handleTestType }: IQuestionAnswers) => {
  const { values: formikValues } = useFormikContext<IQuestionsFormikContext>();
  const values = formikValues.data[index]
  const { t } = useTranslation(["course"]);

  useEffect(() => handleTestType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [values.type])

  const QuestionAnswerForms = useCallback((ind: number, arrayHelpers: FieldArrayRenderProps) => {
    switch (values.type) {
      case QuizQuestionTypeEnum.ONECORRECTANSWER:
        return <OneCorrectAnswers key={ind} quizIndex={index} index={ind} arrayHelpers={arrayHelpers} />
      case QuizQuestionTypeEnum.MULTIPLECORRECTANSWERS:
        return <MultipleCorrectAnswers key={ind} quizIndex={index} index={ind} arrayHelpers={arrayHelpers} />
      case QuizQuestionTypeEnum.MAPPING:
        return <Mapping key={ind} quizIndex={index} index={ind} arrayHelpers={arrayHelpers} />
      case QuizQuestionTypeEnum.TRUEORFALSE:
        return <TrueOrFalse key={ind} quizIndex={index} index={ind} arrayHelpers={arrayHelpers} />
      case QuizQuestionTypeEnum.SEQUENCE:
        return <Sequence key={ind} quizIndex={index} index={ind} arrayHelpers={arrayHelpers} />
      default:
        return null
    }
  }, [values, index])


  const fieldArrayName = useMemo(() => {
    return values.type === QuizQuestionTypeEnum.MAPPING ?
      "children" : "answers"
  }, [values.type])

  const AnswersMemozedMapping = useCallback((values: QuizQuestion, arrayHelpers: FieldArrayRenderProps) => {
    return values[fieldArrayName]?.map((el, i) => QuestionAnswerForms(i, arrayHelpers))
  }, [fieldArrayName, QuestionAnswerForms])

  const getAddAnswerItem = () => {
    if (values.type === QuizQuestionTypeEnum.MAPPING) {
      return questionChildrenInitialDto()
    } else {
      return questionAnswerInitialDto()
    }
  }

  return (
    <Box>
      <FormControl>
        <FieldArray
          name={`data.${index}.${fieldArrayName}`}
          render={arrayHelpers => (
            <>
              {
                AnswersMemozedMapping(values, arrayHelpers)
              }
              <div>
                {
                  values.type &&
                  values.type !== QuizQuestionTypeEnum.TRUEORFALSE &&
                  <Button onClick={() => arrayHelpers.push(getAddAnswerItem())} variant="text" startIcon={<ControlPointIcon sx={{ width: '25px', color: '#ABABAB', height: '25px', mr: '18px', ml: '-1px' }} />}>
                    <Typography sx={{ fontWeight: 500, color: '#ABABAB' }}>{t('course:test.ADD_AN_OPTION')}</Typography>
                  </Button>
                }
              </div>
            </>
          )}
        />
      </FormControl>
    </Box>
  )
}

export default QuestionAnswers;