import { Avatar, Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { NotificationDto } from "./models";
import { serviceNotification } from '@services/notification';
import { useSelector } from "react-redux";
import { NotificationElement } from './NotificationElement';


export const NotificationPopoverList = () => {
  const unreadNotificationsCount = useSelector(
    (store: any) => store.notification.notificationCount
  );
  const [notificationList, setNotificationList] = useState<NotificationDto[]>(
    []
  );
  const { t } = useTranslation(["notification"]);
  useEffect(() => {
    serviceNotification.getListUserId({ size: 1000 }).then((res: any) => {
      setNotificationList(
        res.data.dtoList.filter(
          (item: NotificationDto) => item.status === "UNREAD"
        )
      );
    });
  }, [unreadNotificationsCount]);


  return (
    <Box>
      {notificationList.length > 0 ? (
        <Box
          sx={{
            display: "block",
            alignItems: "center",
            p: "12px",
            width: "500px",
            overflowY: "scroll",
            maxHeight: "400px",
            mr: "5px",
            "&::-webkit-scrollbar": { width: "7px" },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#E5E0EB",
              borderRadius: "6px",
            },
          }}
        >
          {notificationList.map((item, index) => {
            return <NotificationElement notification={item} key={index} />
          })}
        </Box>
      ) : (
        <Box display="flex" gap={3} alignItems="center" padding="0px 30px">
          <Avatar
            alt="notification_empty"
            src={require("@assets/images/notification_empty.png")}
            variant="square"
            sx={{
              width: "100px",
              height: "100px",
              cursor: "pointer",
              color: "white",
            }}
          />
          <Box>
            <Typography fontWeight={600}>{t("EMPTY")}...</Typography>
            <Typography>{t("YOU_DONT_HAVE_NOTIFICATION")}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}
