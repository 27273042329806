import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { TabContext, TabPanel } from "@mui/lab";
import {
  AlertColor,
  Box,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";

import ParamsPageEdu from "./ParamsPageEdu";
import ParamsPageFree from "./ParamsPageFree";
import { MediaPage } from "./MediaPage";
import { AuthorPage } from "./AuthorPage";
import { CourseStatus, ICourse, TabOptions, TypeCode } from '@interfaces/index';
import UnhiddenHints from "@components/UnhiddenHints";

interface Props {
  courseInfo: ICourse;
  setCourseInfo: (courseInfo: any) => void;
  setMessageOpen: (isOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  setMessage: (message: string) => void;
}

const Description = ({
  courseInfo,
  setCourseInfo,
  setMessageOpen,
  setMessageType,
  setMessage,
}: Props) => {
  const { t } = useTranslation(["course", "common"]);
  const { common } = useTheme().palette;
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get("paramsTab");
  const [typeDescription, setTypeDescription] = useState("options");

  const handleTabChange = (tab: string) => {
    setTypeDescription(tab);
    setSearchParams({ paramsTab: tab });
  };

  useEffect(() => {
    if (currentTab) {
      setTypeDescription(currentTab);
    } else {
      setTypeDescription("options");
    }
  }, [currentTab]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {(!(courseInfo.promoVideoId || courseInfo.promoVideoUrl) ||
        !courseInfo.name ||
        !courseInfo.shortDescription ||
        !courseInfo.description ||
        !courseInfo.credits) &&
        courseInfo.status === CourseStatus.SAVED &&
        <UnhiddenHints
          text={t("course:hints.GENERAL_INFO_ABOUT_THE_COURSE")}
        />
      }
      <Box
        sx={{
          display: "block",
          width: "100%",
          background: common.fontWhite,
          borderRadius: 3,
          border: `1px solid ${common.borderSecondary}`
        }}
      >
        <TabContext value={typeDescription}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                padding: "10px 0 0 10px",
              }}
            >
              <Tabs
                onChange={(_, value: string) => handleTabChange(value)}
                indicatorColor="secondary"
                value={typeDescription}
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: "#1A141F",
                  },
                  "& .MuiTabs-indicator": {
                    background: common.borderPrimary,
                  },
                }}
              >
                <Tab
                  label={t("common:PARAMS")}
                  value={"options"}
                  sx={{ textTransform: "none", fontSize: "18px" }}
                />
                <Tab
                  label={t("common:MEDIA")}
                  value={"media"}
                  sx={{ textTransform: "none", fontSize: "18px" }}
                />
                {courseInfo.typeCode === TypeCode.FREE && (
                  <Tab
                    label={t("common:AUTHOR")}
                    value={"author"}
                    sx={{ textTransform: "none", fontSize: "18px" }}
                  />
                )}
              </Tabs>
            </Box>
          </Box>
          <TabPanel value={typeDescription}>
            {typeDescription === TabOptions.OPTIONS &&
              (courseInfo.typeCode === TypeCode.EDU ? (
                <ParamsPageEdu
                  courseInfo={courseInfo}
                  setCourseInfo={setCourseInfo}
                  setMessageOpen={setMessageOpen}
                  setMessage={setMessage}
                  setMessageType={setMessageType}
                />
              ) : (
                <ParamsPageFree
                  courseInfo={courseInfo}
                  setCourseInfo={setCourseInfo}
                  setMessageOpen={setMessageOpen}
                  setMessage={setMessage}
                  setMessageType={setMessageType}
                />
              ))}
            {typeDescription === TabOptions.MEDIA && (
              <MediaPage
                courseInfo={courseInfo}
                setCourseInfo={setCourseInfo}
                setMessageOpen={setMessageOpen}
                setMessage={setMessage}
                setMessageType={setMessageType}
              />
            )}
            {typeDescription === TabOptions.AUTHOR &&
              courseInfo.typeCode === TypeCode.FREE && (
                <AuthorPage
                  courseInfo={courseInfo}
                  setMessageOpen={setMessageOpen}
                  setMessage={setMessage}
                  setMessageType={setMessageType}
                />
              )}
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default Description;