import React from 'react'
import { Box, Typography, Popover } from '@mui/material'
import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import kk from "date-fns/locale/kk";
import DateTimePicker from '@ui/DateTimePicker';
import { useTranslation } from "react-i18next";
registerLocale('ru', ru);
registerLocale('kk', kk);

interface Props {
  isPeriodOpen?: boolean;
  startDate: Date | null;
  endDate: Date | null;
  handleStartDateChange?: (date: Date | null) => void;
  handleEndDateChange?: (date: Date | null) => void;
  hint?: HTMLElement | null;
  periodPopoverClose?: () => void;
  setShowPeriod?: (period: boolean) => void;
}

const PeriodPopover: React.FC<Props> = ({
  isPeriodOpen = false,
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  hint,
  periodPopoverClose,
}) => {
  const { t } = useTranslation(["course"]);

  return (
    <Popover
      open={isPeriodOpen}
      anchorEl={hint}
      onClose={periodPopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          maxHeight: 'none', // Remove max height to prevent scrollable popover
          overflow: 'visible', // Allow content to overflow popover
        },
      }}
      disableScrollLock
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: '16px' }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <DateTimePicker name="startDate"
            date={startDate}
            shouldCloseOnSelect={true}
            isClearable={true}
            handleDateChange={handleStartDateChange}
            label={t('course:FROM')}
            inputWidth='160px'
            maxDate={endDate}
          />
          <Typography sx={{ mt: 4 }}>{'\u2014'}</Typography>
          <DateTimePicker name="endDate"
            date={endDate}
            shouldCloseOnSelect={true}
            isClearable={true}
            handleDateChange={handleEndDateChange}
            label={t('course:TO')}
            inputWidth='160px'
            minDate={startDate}
          />
        </Box>
      </Box>
    </Popover>
  )
}

export default PeriodPopover
