import { useTheme } from '@mui/material/styles';

const target = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_PRODUCTION_TARGET
  : process.env.REACT_APP_DEVELOPMENT_TARGET;

const VideoPlayer = ({ videoFileId, isPublic = false, height = 400, width = '100%' }) => {
  const { common } = useTheme().palette;

  return (
    <video
      id={videoFileId}
      width={width}
      height={height}
      controls
      preload="metadata"
      controlsList="nodownload" // This will prevent the download option
      style={{
        border: `1px solid ${common.strokePrimary}`,
        borderRadius: '6px'
      }}
    >
      <source src={`${target}/video-streaming${isPublic ? '/public' : ''}/video/${videoFileId}`} />
    </video>
  );
};

export default VideoPlayer;