import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from 'react-router-dom';
import { AlertColor, Box, useTheme } from "@mui/material";

import { serviceTask } from "@services/task";
import { CourseStatus } from "@interfaces/index";
import UnhiddenHints from "@components/UnhiddenHints";
import { CourseContext } from "@context/CourseContext";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Structure from "./Structure";
import Task from "./Task";

interface Props {
  setMessageOpen: (isOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  setMessage: (message: string) => void;
}

const Content = ({setMessage, setMessageOpen, setMessageType}: Props) => {
  const { courseId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation(['common', "course"]);
  const { common } = useTheme().palette;
  const [contentList, setContentList] = useState<Array<any>>([]);
  const { courseData } = useContext(CourseContext);
  const [taskCount, setTaskCount] = useState<number>(0);
  const currentTab = searchParams.get('contentTab');
  const [tabValue, setTabValue] = useState<string>("content");

  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);
    setSearchParams({ contentTab: newValue })
  };

  useEffect(() => {
    if (currentTab) {
      setTabValue(currentTab);
    } else {
      setTabValue("content");
    }
  }, [currentTab])

  useEffect(() => {
    if (courseId) {
      serviceTask.getTaskList(courseId, {
        pageNumber: 0,
        size: 5,
        filter: { name: "", format: null, status: "" }
      }).then(res => {
        if (res.status === 200) setTaskCount(res.data.totalElements);
      });
    }
  }, [courseId]);

  return (
    <Box sx={{ minHeight: '650px' }}>
      {contentList.every(content => content.children.length === 0) && taskCount === 0 && courseData?.status === CourseStatus.SAVED &&
        <UnhiddenHints text={t('course:hints.SECTION_PROVIDES_FORMING_CONTENT_WITHIN_SUBJECT_AREA')} />
      }
      <Box
        sx={{
          backgroundColor: common.fontWhite,
          width: "100%",
          borderRadius: "12px",
          height: "auto",
          border: `1px solid ${common.borderSecondary}`,
          mt: 2
        }}
      >
        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              padding: "10px 0 0 24px",
            }}
          >
            <TabList
              onChange={(_, value: string) => handleTabChange(value)}
              textColor="inherit"
              TabIndicatorProps={{
                style: {
                  backgroundColor: common.primaryColor
                }
              }}
            >
              <Tab label={t("course:content.CONTENT")} value="content"
                sx={{
                  textTransform: 'capitalize',
                  marginRight: 4,
                  fontSize: '18px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  textAlign: 'center',
                  p: '0 5px 5px'
                }} />
              <Tab label={t("course:TASKS")} value="tasks"
                sx={{
                  textTransform: 'capitalize',
                  marginRight: 4,
                  fontSize: '18px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  textAlign: 'center',
                  p: '0 5px 5px'
                }} />
            </TabList>
          </Box>
          <TabPanel value="content" sx={{ p: '16px 0' }}>
            <Structure
              contentList={contentList}
              setContentList={setContentList}
              setMessageOpen={setMessageOpen}
              setMessage={setMessage}
              setMessageType={setMessageType}
            />
          </TabPanel>
          <TabPanel value="tasks" sx={{ p: 0 }}>
            <Task />
          </TabPanel>
        </TabContext>
      </Box>
    </Box >
  )
}

export default Content;