import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Typography,
  Box,
  useTheme,
  Accordion,
  AccordionSummary,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorIcon from "@mui/icons-material/Error";
import { PieChart, Pie, Cell, Label } from "recharts";

import { serviceCourse } from "@services/course";
import { serviceTask } from "@services/task";
import { serviceDict } from "@services/dictionary";
import MathJaxComponent from "@components/MathJaxComponent";
import GradingSystemTable from "@components/GradingSystemTable";
import { GZFormulaModal } from "./GZFormulaModal";
import { IOFormulaModal } from "./IOFormulaModal";
import { FX100NotGroupsIOModal } from "./FX100NotGroupsIOModal";
import { FX100NotGroupsGZModal } from "./FX100NotGroupsGZModal";
import { FX100GZModal } from "./FX100GZModal";
import { FX100IOModal } from "./FX100IOModal";
import { CourseRole, ITask } from "@interfaces/index";
import PassedNotPassedGZModal from "./PassedNotPassedGZModal";
import { courseGradingApi } from "@services/index";
import { useQuery } from "@tanstack/react-query";
import PassedNotPassedIOModal from "./PassedNotPassedIOModal";
import IconHints from "@components/IconHints";
import { PassedNotPassedPieChart } from "./PassedNotPassedPieChart";

export const Evaluation = ({
  gradingSystemId,
  showFinalMark,
  completedTasksRate,
  minLimitForTask,
}: {
  gradingSystemId: string | number;
  showFinalMark: boolean;
  completedTasksRate: string;
  minLimitForTask: number;
}) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course"]);
  const [activePieIndex, setActivePieIndex] = useState<number | null>(null);
  const [openGZModal, setGZModal] = useState(false);
  const [openIOModal, setIOModal] = useState(false);
  const { classId, status } = useParams();

  const [evaluationData, setEvaluationData] = useState<any | null>([]);
  const [gradingSystemData, setGradingSystemData] = useState<any | null>([]);
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const FX100GZFormula =
    "`ГЗ = \\frac{\\sum_{i=1}^n O_{3_{1}} + O_{3_{2}} + ... + O_{3_{n}}}{N}`";
  const FX100IOFormula =
    "`ИО_{курса}=(ГЗ_{1} * Д_{ГЗ_{1}}) + (ГЗ_{2} * Д_{ГЗ_{2}}) + ... + (ГЗ_{N} * Д_{ГЗ_{N}})`";
  const FX100NotGroupsGZFormula =
    "`O_{3} = \\sum_{i=1}^n O_{1} + O_{2} + ... + O_{n}`";
  const FX100NotGroupsIOFormula = "`ИО_{курса}=\\frac{O_{3}}{N}`";
  const GZFormula =
    "`Г_{3} = \\sum_{i=1}^n Б_{3_{1}} + Б_{3_{2}} + ... + Б_{3_{n}}`";
  const IOFormula =
    "`ИО_{курса}=(ГЗ_{1} * Д_{ГЗ_{1}}) + (ГЗ_{2} * Д_{ГЗ_{2}}) + ... + (ГЗ_{N} * Д_{ГЗ_{N}})`";
  const PassedNotPassedGZFormula = "`Д_{З} = (O_{3} * П_{%})/100`";
  const PassedNotPassedIOFormula = "`Р_{курса} = П_{з} \\geq Д_{з}`";

  const COLORS = [
    "#5BC044",
    "#EAC600",
    "#FD0053",
    "#348AA7",
    "#ECCBD9",
    "#FF6B6B",
    "#4ECDC4",
    "#6F58C9",
    "#685044",
    "#2A324B",
    "#C93D6F",
    "#8BEE8F",
  ];

  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role } = profile.authorityInfo;

  function handleGZModal() {
    setIOModal(false);
    setGZModal(true);
  }
  function handleIOModal() {
    setGZModal(false);
    setIOModal(true);
  }

  useEffect(() => {
    if (classId) {
      if (role === CourseRole.LISTENER) {
        serviceCourse.getListenerCurrent(classId).then((res) => {
          if (res.status === 200) setEvaluationData(res.data);
        });
      } else {
        serviceTask.getTaskGroupsWithTasks(classId).then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0) {
              setEvaluationData((prev) => ({ ...prev, groups: res.data }));
            } else {
              serviceTask
                .getTaskList(classId, { name: "", format: null, status: "" })
                .then((res) => {
                  if (res.status === 200) {
                    setEvaluationData((prev) => ({
                      ...prev,
                      tasks: res.data.dtoList,
                    }));
                  }
                });
            }
          }
        });
      }
    }
  }, [classId, role]);

  useEffect(() => {
    if (gradingSystemId) {
      serviceDict
        .getGradingSystemById(gradingSystemId.toString())
        .then((res) => {
          if (res.status === 200) {
            setGradingSystemData(res.data);
          }
        });
    }
  }, [gradingSystemId]);

  const { data: courseResultDto } = useQuery({
    queryKey: ["getGradingSystemById", classId ? classId : ""],
    queryFn: () => courseGradingApi.getResultByStudyClass(classId ?? ""),
  });

  const [openModal, setOpenModal] = useState(false);
  const handleToggleModal = (modalState: boolean) => setOpenModal(modalState);


  return (
    <Box
      sx={{
        background: "#fff",
        borderRadius: "10px",
        padding: "30px 15px",
        marginTop: "15px",
      }}
    >
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems={{ sm: "center", xs: "flex-start" }}
        gap="10px"
      >
        <Typography fontWeight={600} fontSize={{ sm: "24px", xs: "18px" }}>
          {t("course:listener.GRADING_SYSTEM")}:
        </Typography>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography fontSize={{ sm: "24px", xs: "18px" }}>
            {gradingSystemData?.name}
          </Typography>
          {gradingSystemData.id === 4 && <IconHints text={t("course:hints.PASS_FAIL_HINT")} />}
          {(status === "current" || status === "completed") &&
            gradingSystemData.id !== 4 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "28px",
                  height: "28px",
                  borderRadius: "50%",
                  backgroundColor: common.primaryColor,
                  padding: "10px",
                  cursor: "pointer",
                }}
                onClick={() => handleToggleModal(true)}
              >
                <ErrorIcon sx={{ color: "white", width: "20px" }} />
              </Box>
            )}
        </Box>
      </Box>
      {gradingSystemData.id === 4 && <Typography sx={{ fontSize: "14px", color: "#6D737A" }}>{t("course:COMPLETE_COURSE_YOU_MUST_SUCCESSFULLY_COMPLETE", { number: courseResultDto?.completionRate })}</Typography>}
      {openModal && (
        <GradingSystemTable
          open={openModal}
          handleToggleModal={handleToggleModal}
          gradingSystemId={gradingSystemData.id}
          initialData={[]}
        />
      )}
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: "column", lg: "row" }}
      >
        {gradingSystemData.id === 4 ?
          <PassedNotPassedPieChart courseResultDto={courseResultDto} />
          : courseResultDto && courseResultDto?.groups?.length > 0 ? (
            courseResultDto?.groups?.filter((group) => group.tasks.taskUserPoint).length > 0 ?
              <PieChart
                width={matchesDownSm ? 250 : 300}
                height={matchesDownSm ? 250 : 300}
              >
                <Pie
                  data={courseResultDto?.groups}
                  cx={matchesDownSm ? 110 : 160}
                  cy={matchesDownSm ? 110 : 160}
                  innerRadius={matchesDownSm ? 60 : 90}
                  outerRadius={matchesDownSm ? 90 : 120}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="taskUserPoint"
                  onMouseEnter={(_, index) => setActivePieIndex(index + 1)}
                  onMouseLeave={() => setActivePieIndex(null)}
                >
                  {courseResultDto && (
                    <Label
                      value={`${courseResultDto?.finalUserMark
                        ? courseResultDto?.finalUserMark
                        : "-"
                        }/${courseResultDto?.finalTotalMark
                          ? courseResultDto?.finalTotalMark
                          : "100"
                        } баллов`}
                      position="center"
                      fontSize={!matchesDownSm ? "25px" : "18px"}
                    />
                  )}
                  {courseResultDto?.groups?.map((_, index: number) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart> :
              <Box sx={{ width: matchesDownSm ? 200 : 250, height: matchesDownSm ? 200 : 250, borderRadius: "50%", background: "#FBF9FF", border: `1px solid ${common.borderSecondary}` }} alignItems="center">
                <Box sx={{ width: matchesDownSm ? 150 : 200, height: matchesDownSm ? 150 : 200, borderRadius: "50%", background: "#FFF", border: `1px solid ${common.borderSecondary}` }} margin="25px auto" alignItems="center">
                  {gradingSystemData.id === 3 ?
                    <Typography sx={{ margin: "35% 25%", fontSize: "32px", fontWeight: 600, color: common.fontGray }}>--/100</Typography> :
                    <Typography sx={{ margin: "35% 25%", fontSize: "32px", fontWeight: 600, color: common.fontGray }}>0/A</Typography>}
                </Box>
              </Box>
          ) : (
            (courseResultDto?.tasks && courseResultDto?.tasks.filter((task) => task.taskUserPoint).length > 0) ?
              <PieChart
                width={matchesDownSm ? 250 : 300}
                height={matchesDownSm ? 250 : 300}
              >
                <Pie
                  data={courseResultDto?.tasks && courseResultDto?.tasks?.length > 0 ? courseResultDto?.tasks : []}
                  cx={matchesDownSm ? 110 : 160}
                  cy={matchesDownSm ? 110 : 160}
                  innerRadius={matchesDownSm ? 60 : 90}
                  outerRadius={matchesDownSm ? 90 : 120}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="taskUserPoint"
                  onMouseEnter={(_, index) => setActivePieIndex(index + 1)}
                  onMouseLeave={() => setActivePieIndex(null)}
                >
                  {courseResultDto && (
                    <Label
                      value={`${courseResultDto?.finalUserMark && showFinalMark
                        ? courseResultDto?.finalUserMark.toFixed(1)
                        : "-"
                        }/${courseResultDto?.finalTotalMark
                          ? courseResultDto?.finalTotalMark
                          : "100"
                        }`}
                      position="center"
                      fontSize={!matchesDownSm ? "25px" : "18px"}
                    />
                  )}
                  {courseResultDto?.tasks?.map((_, index: number) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart> :
              <Box sx={{ width: matchesDownSm ? 200 : 250, height: matchesDownSm ? 200 : 250, borderRadius: "50%", background: "#FBF9FF", border: `1px solid ${common.borderSecondary}`, margin: "30px 0 0 50px" }} alignItems="center">
                <Box sx={{ width: matchesDownSm ? 150 : 200, height: matchesDownSm ? 150 : 200, borderRadius: "50%", background: "#FFF", border: `1px solid ${common.borderSecondary}` }} margin="25px auto" alignItems="center">
                  {gradingSystemData.id === 3 ?
                    <Typography sx={{ margin: "35% 25%", fontSize: "32px", fontWeight: 600, color: common.fontGray }}>--/100</Typography> :
                    <Typography sx={{ margin: "35%", fontSize: "32px", fontWeight: 600, color: common.fontGray }}>-/A</Typography>}
                </Box>
              </Box>
          )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            mb: 5,
          }}
        >
          <Typography
            fontSize={matchesDownSm ? "16px" : "18px"}
            marginBottom="20px"
            fontWeight={500}
          >
            {t("course:listener.FORMULA_CALCULATING_FINAL_GRADE_COURSE")}
          </Typography>
          <Typography
            fontSize="14px"
            fontWeight={600}
            sx={{ cursor: "pointer", ":hover": { color: common.primaryColor } }}
            onClick={() => handleGZModal()}
          >
            1.{" "}
            {gradingSystemId === 4
              ? t("course:listener.FORMULA_FOR_CALCULATE_NUMBER_TASKS")
              : t("course:listener.TOTAL_SCORE_GROUP_TASKS")}
            :
          </Typography>
          <Box
            sx={{
              background: common.primaryColor,
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => handleGZModal()}
          >
            {gradingSystemId.toString() === "1" &&
              (evaluationData?.groups?.length > 0 ? (
                <MathJaxComponent formula={FX100GZFormula} />
              ) : (
                <MathJaxComponent formula={FX100NotGroupsGZFormula} />
              ))}
            {gradingSystemId.toString() === "2" &&
              (evaluationData?.groups?.length > 0 ? (
                <MathJaxComponent formula={FX100GZFormula} />
              ) : (
                <MathJaxComponent formula={FX100NotGroupsGZFormula} />
              ))}
            {gradingSystemId.toString() === "3" && (
              <MathJaxComponent formula={GZFormula} />
            )}
            {gradingSystemId.toString() === "4" && (
              <MathJaxComponent formula={PassedNotPassedGZFormula} />
            )}
          </Box>
          <Typography
            fontSize="14px"
            fontWeight={600}
            sx={{ cursor: "pointer", ":hover": { color: common.primaryColor } }}
            onClick={() => handleIOModal()}
          >
            2.{" "}
            {gradingSystemId === 4
              ? t("course:listener.FINAL_RESULT_COURSE")
              : t("course:listener.GENERAL_FORMULA_ROI_COURSE")}
            :
          </Typography>
          <Box
            sx={{
              background: common.primaryColor,
              color: "#fff",
              margin: "0 auto",
              cursor: "pointer",
            }}
            onClick={() => handleIOModal()}
          >
            {gradingSystemId.toString() === "1" &&
              (evaluationData?.groups?.length > 0 ? (
                <MathJaxComponent formula={FX100IOFormula} />
              ) : (
                <MathJaxComponent formula={FX100NotGroupsIOFormula} />
              ))}
            {gradingSystemId.toString() === "2" &&
              (evaluationData?.groups?.length > 0 ? (
                <MathJaxComponent formula={FX100IOFormula} />
              ) : (
                <MathJaxComponent formula={FX100NotGroupsIOFormula} />
              ))}
            {gradingSystemId.toString() === "3" && (
              <MathJaxComponent formula={IOFormula} />
            )}
            {gradingSystemId.toString() === "4" && (
              <MathJaxComponent formula={PassedNotPassedIOFormula} />
            )}
          </Box>
        </Box>
      </Box>

      <Box>
        {evaluationData?.groups?.length > 0 ? (
          evaluationData?.groups?.map((group, index) => (
            <Accordion
              key={group.name}
              disableGutters={true}
              sx={{
                backgroundColor:
                  activePieIndex && activePieIndex === index + 1
                    ? "#C1C1C1"
                    : common.rootBgSecondary,
                border: "1px solid #CACACA",
                borderRadius: 1,
              }}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontSize: { sm: "14px", xs: "10px" },
                  }}
                >
                  <Box
                    width="30px"
                    height="5px"
                    sx={{ background: COLORS[index], borderRadius: "6px" }}
                  />
                  <Box width="200px" sx={{ wordBreak: "break-word" }}>
                    <Typography>{group?.name}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: "60px",
                        height: "30px",
                        background: COLORS[index],
                        borderRadius: "14px",
                      }}
                      padding="3px 6px"
                    >
                      --/100
                    </Box>
                  </Box>
                  <Typography>{t("course:listener.SHARE")}</Typography>
                  <Box
                    sx={{
                      background: common.secondaryColor,
                      borderRadius: "14px",
                      color: common.primaryColor,
                    }}
                    padding="3px 6px"
                  >
                    --%/{group?.percentage}%
                  </Box>
                  <ExpandMoreIcon />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {group?.tasks.map((task: ITask) => (
                  <Box display="flex" gap={24} marginBottom="5px" key={task.id}>
                    <Box
                      marginLeft="15%"
                      width="200px"
                      sx={{
                        wordBreak: "break-word",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography>{task?.name}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          width: "70px",
                          height: "30px",
                          background: COLORS[index],
                          borderRadius: "14px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        padding="3px 6px"
                      >
                        {role === "LISTENER" ? task?.userMark ?? "-" : ""}/
                        {task?.point}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <TableContainer component={Paper} sx={{ mt: "16px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "10%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      № {t("course:TASKS")}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ width: "75%" }}>{t("course:task.TASK_NAME")}</TableCell>
                  <TableCell sx={{ width: "15%" }}>
                    {t("course:GRADES")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {courseResultDto?.tasks?.map((task, index) => (
                  <TableRow key={task.taskId} sx={{
                    backgroundColor:
                      activePieIndex && activePieIndex === index + 1
                        ? "#C1C1C1"
                        : common.rootBgSecondary,
                  }}>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </Box>
                    </TableCell>
                    <TableCell>{task.taskName}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          padding: "3px 5px",
                        }}
                      >
                        {gradingSystemId === 4 ? (role === CourseRole.LISTENER &&
                          minLimitForTask < task?.taskUserPoint ? (
                          <Box display="flex" alignItems={"center"} gap={1}>
                            <Box
                              sx={{
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                                backgroundColor: "#00B998",
                              }}
                            ></Box>
                            {t("common:statuses.PASSED")}
                          </Box>
                        ) : (
                          <Box display="flex" alignItems={"center"} gap={1}>
                            <Box
                              sx={{
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                                backgroundColor: common.errorColor,
                                whiteSpace: "nowrap"
                              }}
                            ></Box>
                            {t("common:statuses.NOT_PASSED")}
                          </Box>
                        )) :
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                              sx={{
                                width: "70px",
                                height: "30px",
                                background: COLORS[index],
                                borderRadius: "14px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              padding="3px 6px"
                            >
                              {role === "LISTENER" ? task?.taskUserPoint ? Math.round(task?.taskUserPoint) : "-" : ""}/
                              {task?.taskMaxPoint}
                            </Box>
                          </Box>}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      {gradingSystemId.toString() === "4" && (
        <PassedNotPassedGZModal
          open={openGZModal}
          handleToggleModal={() => setGZModal(false)}
        />
      )}
      {gradingSystemId.toString() === "4" && (
        <PassedNotPassedIOModal
          open={openIOModal}
          handleToggleModal={() => setIOModal(false)}
        />
      )}
      {gradingSystemId.toString() === "3" && (
        <GZFormulaModal
          open={openGZModal}
          handleToggleModal={() => setGZModal(false)}
        />
      )}
      {gradingSystemId.toString() === "3" && (
        <IOFormulaModal
          open={openIOModal}
          handleToggleModal={() => setIOModal(false)}
        />
      )}
      {gradingSystemId.toString() === "2" &&
        (evaluationData?.groups?.length > 0 ? (
          <FX100GZModal
            open={openGZModal}
            handleToggleModal={() => setGZModal(false)}
          />
        ) : (
          <FX100NotGroupsGZModal
            open={openGZModal}
            handleToggleModal={() => setGZModal(false)}
          />
        ))}
      {gradingSystemId.toString() === "2" &&
        (evaluationData?.groups?.length > 0 ? (
          <FX100IOModal
            open={openIOModal}
            handleToggleModal={() => setIOModal(false)}
          />
        ) : (
          <FX100NotGroupsIOModal
            open={openIOModal}
            handleToggleModal={() => setIOModal(false)}
          />
        ))}
      {gradingSystemId.toString() === "1" &&
        (evaluationData?.groups?.length > 0 ? (
          <FX100GZModal
            open={openGZModal}
            handleToggleModal={() => setGZModal(false)}
          />
        ) : (
          <FX100NotGroupsGZModal
            open={openGZModal}
            handleToggleModal={() => setGZModal(false)}
          />
        ))}
      {gradingSystemId.toString() === "1" &&
        (evaluationData?.groups?.length > 0 ? (
          <FX100IOModal
            open={openIOModal}
            handleToggleModal={() => setIOModal(false)}
          />
        ) : (
          <FX100NotGroupsIOModal
            open={openIOModal}
            handleToggleModal={() => setIOModal(false)}
          />
        ))}
    </Box>
  );
};
