import { useState, useEffect } from "react";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NewPasswordList = () => {
  const [newPasswordData, setNewPasswordData] = useState<[]>([]);
  const [data, setData] = useState<null | string>(null);
  const { t } = useTranslation(["user", "common"]);

  useEffect(() => {
    setData(localStorage.getItem("plt_academy"));
  }, []);

  useEffect(() => {
    if (data) {
      localStorage.removeItem("plt_academy");
      setNewPasswordData(JSON.parse(data));
    }
  }, [data]);
  return (
    <>
      <Box>
        <Typography sx={{ fontWeight: "500", fontSize: "32px", lineHeight: "38px" }}>
          {t("NEW_PASSWORD_HEADER")}
        </Typography>
        <Box sx={{ mt: "44px" }}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: "#7D7D7D",
                      mr: "16px",
                      ml: "8px",
                      width: "235",
                    }}
                  >
                    №
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: "#7D7D7D",
                      mr: "16px",
                    }}
                  >
                    {t("FULL_NAME")}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: "#7D7D7D",
                      mr: "16px",
                    }}
                  >
                    {t("LOGIN")}
                  </TableCell>
                  <TableCell>
                    <span style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: "#7D7D7D",
                    }}
                    >{t("PASSWORD")}</span>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: "#7D7D7D",
                      mr: "16px",
                    }}
                  >
                    {t("NOTIFICATION")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newPasswordData.map((data: any, index: any) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "19px",
                          color: "#323232",
                          mr: "16px",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "19px",
                          color: "#323232",
                          mr: "16px",
                          ml: "8px",
                          width: "235",
                        }}
                      >
                        {data.fullName}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "19px",
                          color: "#323232",
                          mr: "16px",
                        }}
                      >
                        {data.username}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "19px",
                          color: "#323232"
                        }}
                      >
                        {data.password}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "19px",
                          color: "#323232",
                          mr: "16px",
                        }}
                      >
                        {data.sentNotification ? `${t("SENT")}` : `${t("NOT_SENT")}`}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default NewPasswordList;