import { ListInformation } from "@components/common";
import { ListVideo } from "@components/common/ListVideo/ListVideo";
import { AnswerTypes, IComment, ListArray } from "@interfaces/index";
import React, { FC } from "react";

interface Props {
  answerType: AnswerTypes ;
  onlineConferenceAndOfflineMeeting?: {
    title: string;
    list: ListArray[];
  };
  video?:IComment[]
}
const OralTaskFormat: FC<Props> = ({
  answerType,
  onlineConferenceAndOfflineMeeting,
  video
}) => {
  switch (answerType) {
    case AnswerTypes.ONLINE_CONFERENCE:
    case AnswerTypes.OFFLINE:
      return (
        <ListInformation
          list={onlineConferenceAndOfflineMeeting?.list ?? []}
          title={onlineConferenceAndOfflineMeeting?.title ?? ""}
        />
      );
    case AnswerTypes.VIDEO:
      return <ListVideo video={video}/>;
    case AnswerTypes.FILE:
      return <div>FILE</div>;
    default:
      return null;
  }
};

export { OralTaskFormat };
