import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import { IQuestion } from "@interfaces/index";
import Clear from "@mui/icons-material/Clear";
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { TextEditor } from '@ui/formik/TextEditor';
import { FieldArrayRenderProps, useFormikContext } from "formik";
import { IQuestionsFormikContext } from "../Questions";
import { useTranslation } from "react-i18next";

interface IMultipleCorrectAnswersProps {
    arrayHelpers: FieldArrayRenderProps,
    index: number;
    quizIndex: number;
}

export const MultipleCorrectAnswers = ({ arrayHelpers, quizIndex, index }: IMultipleCorrectAnswersProps) => {
    const { values: formikValues, handleChange } = useFormikContext<IQuestionsFormikContext>();
    const values = formikValues.data[quizIndex]
    const { t } = useTranslation(["course"]);

    const deleteAnswer = () => {
        return new Promise((resolve, reject) => {
            arrayHelpers.remove(index)
            return resolve({})
        })
    }

    const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
        deleteFunc: deleteAnswer,
        modalTitle: t('course:messages.ARE_YOU_SURE_TO_DELETE_ANSWER_OPTION')
    })

    return (
        <>
            {DeleteModal}
            <Box sx={{ mb: '24px', width: '70%', display: 'flex', alignItems: 'center', ml: '-11px' }}>
                <FormControlLabel
                    control={<Checkbox checked={values.answers[index].correct} />}
                    label=""
                    name={`data.${quizIndex}.answers.${index}.correct`}
                    onChange={handleChange}
                />
                <Box sx={{ ml: '1px' }}><TextEditor name={`data.${quizIndex}.answers.${index}.answer`} label='' /></Box>
                <IconButton onClick={deleteAnswer} >
                    <Clear sx={{ width: '25px', height: '25px', ml: '15px' }} />
                </IconButton>
            </Box>
        </>
    )
}