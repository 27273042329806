import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Button, { ButtonProps } from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { serviceFile } from "@services/file";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert, Snackbar } from "@mui/material";

const nameRequiredTypeCodes = ["003", "004", "005", "007", "009"];

interface Props extends ButtonProps {
  label?: string;
  backgroundColor?: string;
  fontColor?: string;
  fontWeight?: string | number;
  borderRadius?: string;
  width?: string;
  height?: string;
  typeCode?: string; // profilePhoto: 002, themeMaterials: 003, taskMaterials: 004, additionalRes.: 005, pdf: 007,
  disabled?: boolean;
  attachAndSaveFile: (fileId: string, name?: string) => void;
  acceptedExtensions?: string;
  sizeLimit?: number; // в килобайтах
  videoLimit?: number;
}

const UploadButton: React.FC<Props> = ({
  label = "Загрузить",
  backgroundColor,
  borderRadius = "18px",
  width = "100%",
  height = "40px",
  fontColor = "black",
  fontWeight = "lighter",
  size,
  variant = "contained",
  startIcon,
  endIcon,
  typeCode = "others",
  disabled = false,
  attachAndSaveFile,
  acceptedExtensions = ".png, .jpg, .jpeg, .pdf",
  sizeLimit = 10000,
  videoLimit,
}: Props) => {
  const { t } = useTranslation(["common"]);
  const { common } = useTheme().palette;
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const bgColor = backgroundColor || common.primaryColor;

  const buttonStyles = {
    backgroundColor: bgColor,
    color: fontColor,
    fontWeight,
    borderRadius,
    width,
    height,
    "&.MuiButtonBase-root:hover": {
      bgcolor: bgColor,
    },
    textTransform: "none",
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [showSizeLimitWarning, setShowSizeLimitWarning] =
    useState<boolean>(false);
  const [showFileTypeWarning, setShowFileTypeWarning] =
    useState<boolean>(false);
  const [showVideoLimitWarning, setShowVideoLimitWarning] =
    useState<boolean>(false);

  const handleFileSelect = async (event: any) => {
    const { files } = event?.target;
    const maxAllowedSize = sizeLimit * 1000;

    if (files[0]) {
      if (videoLimit) {
        if (files[0].size > maxAllowedSize) {
          setShowSizeLimitWarning(true);
          setTimeout(() => {
            setShowSizeLimitWarning(false);
            clearFileValue();
          }, 3000);
        } else {
          const video = document.createElement("video");
          video.preload = "metadata";

          video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            if (videoLimit && video.duration > videoLimit) {
              setShowVideoLimitWarning(true);
              setTimeout(() => {
                setShowVideoLimitWarning(false);
                clearFileValue();
              }, 3000);
            } else {
              const fData = new FormData();
              fData.append("file", files[0]);
              setLoading(true);
              serviceFile
                .uploadFile(fData, {
                  params: {
                    typeCode,
                    temporary: false,
                    confirmed: false,
                  },
                })
                .then((res) => {
                  if (res.status === 200) {
                    const nameRequired = nameRequiredTypeCodes.includes(typeCode);
                    attachAndSaveFile(
                      res.data.uuid,
                      nameRequired ? files[0].name : undefined
                    );

                    setLoading(false);
                    clearFileValue();
                  }
                });
            }
          };

          video.src = URL.createObjectURL(files[0]);
        }
      } else {
        if (files[0].size > maxAllowedSize) {
          setShowSizeLimitWarning(true);
          setTimeout(() => {
            setShowSizeLimitWarning(false);
            clearFileValue();
          }, 3000);
        } else {
          const extension = files?.[0].name.substring(
            files?.[0].name.lastIndexOf("."),
            files?.[0].name.length
          );
          setShowSizeLimitWarning(false);
          if (
            acceptedExtensions.includes(extension) ||
            acceptedExtensions === ""
          ) {
            const fData = new FormData();
            fData.append("file", files[0]);
            setLoading(true);
            serviceFile
              .uploadFile(fData, {
                params: {
                  typeCode,
                  temporary: false,
                  confirmed: false,
                },
              })
              .then((res) => {
                if (res.status === 200) {
                  const nameRequired = nameRequiredTypeCodes.includes(typeCode);
                  attachAndSaveFile(
                    res.data.uuid,
                    nameRequired ? files[0].name : undefined
                  );

                  setLoading(false);
                  clearFileValue();
                }
              });
          } else {
            setShowFileTypeWarning(true);
            setTimeout(() => {
              setShowFileTypeWarning(false);
              clearFileValue();
            }, 3000);
          }
        }
      }
    }
  };

  const clearFileValue = () => {
    if (hiddenFileInput.current) hiddenFileInput.current.value = "";
  };

  return (
    <>
      <input
        ref={hiddenFileInput}
        id="upload-file-button"
        hidden
        type="file"
        // accept="video/avi,video/mp4,image/*,application/pdf,audio/mpeg,audio/mp3,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        accept={acceptedExtensions}
        onChange={handleFileSelect}
      />
      <Button
        htmlFor="upload-file-button"
        size={size}
        variant={variant}
        sx={buttonStyles}
        startIcon={startIcon}
        endIcon={endIcon}
        component="label"
        disabled={disabled}
      >
        <FileUploadOutlinedIcon /> {label}
      </Button>
      {loading && (
        <CircularProgress
          color="secondary"
          size={24}
          sx={{ ml: "10px", mt: "3px" }}
        />
      )}
      {showFileTypeWarning && (
        <Typography sx={{ color: common.errorColor }}>
          {t("common:hints.ACCEPTABLE_FORMATS", { acceptedExtensions })}
        </Typography>
      )}
      <Snackbar
        open={showSizeLimitWarning}
        autoHideDuration={6000}
        onClose={() => setShowSizeLimitWarning(false)}
      >
        <Alert onClose={() => setShowSizeLimitWarning(false)} severity="error">
          {t("common:hints.MAX_FILE_SIZE_WARNING", { sizeLimit })}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showVideoLimitWarning}
        autoHideDuration={6000}
        onClose={() => setShowVideoLimitWarning(false)}
      >
        <Alert onClose={() => setShowVideoLimitWarning(false)} severity="error">
          {t("common:hints.UPLOADED_SIZE_EXCEEDS", {
            videoLimit: videoLimit && String(videoLimit / 60),
          })}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UploadButton;
