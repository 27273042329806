import * as yup from "yup";

export const quizValidationSchema = (t: any) =>
  yup.object({
    name: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    langId: yup
      .number()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    subdivisionIds: yup.array().min(1, t("common:validation.MANDATORY_FIELD")),
    fileId: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
  });
