import { useState, useEffect, Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";
import { isEmpty } from "lodash";

import { serviceCourse } from "@services/course";
import { serviceDict } from "@services/dictionary";
import { StateSchema } from "@store/StateSchema";
import { useBreadcrumbs } from "@context/BreadcrumbsContext";
import CustomAutocomplete from "@ui/Autocomplete";
import { IOrganization } from "@pages/dictionary/Organization/models";
import AvatarPicture from "@components/AvatarPicture";
import LanguageCell from "@components/LanguageCell";
import SubdivisionCell from "@components/SubdivisionCell";
import StudyClassList from "./StudyClassList";
import LoadingSpinner from "@components/LoadingSpinner";
import { CT_ValueState } from "@pages/myCourses/Educator";
import { IFilter, initialFilter } from "./models";
import { CourseStatus, DictionaryCode, ICourse, IDictionary, Role, TypeCode } from "@interfaces/index";

interface Props {
  type: string;
  courseStatus: CT_ValueState;
  courseName: CT_ValueState;
  openFilter: boolean;
}

const List = (props: Props) => {
  const { t } = useTranslation(["course", "dictionary", "enum", "user", "common"]);
  const { common } = useTheme().palette;
  const { type, courseStatus, courseName, openFilter } = props;
  const profile = useSelector((store: StateSchema) => store.account.profileInfo);
  const { role, orgId } = profile.authorityInfo;
  const [courseList, setCourseList] = useState<Array<ICourse>>([]);
  const [count, setCount] = useState<number>(0);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const [filter, setFilter] = useState<IFilter>(() => {
    if (
      type === TypeCode.EDU &&
      (!isEmpty(courseStatus.edu) || !isEmpty(courseName.edu))
    )
      return {
        ...initialFilter,
        status: courseStatus.edu,
        name: courseName.edu,
      };
    else if (
      type === TypeCode.FREE &&
      (!isEmpty(courseStatus.free) || !isEmpty(courseName.free))
    )
      return {
        ...initialFilter,
        status: courseStatus.free,
        name: courseName.free,
      };
    return initialFilter;
  });
  const [subdivisionList, setSubdivisionList] = useState<IDictionary[]>([]);
  const [organizationsList, setOrganizationsList] = useState<IOrganization[]>([]);
  const [languageList, setLanguageList] = useState([]);
  const { setBreadcrumbs } = useBreadcrumbs();
  const [isLoadingCourse, setIsLoadingCourse] = useState<boolean>(false);

  useEffect(() => {
    if (
      type === TypeCode.EDU &&
      (!isEmpty(courseStatus.edu) || !isEmpty(courseName.edu))
    ) {
      setFilter((prev) => ({
        ...prev,
        status: courseStatus.edu,
        name: courseName.edu,
      }));
    } else if (
      type === TypeCode.FREE &&
      (!isEmpty(courseStatus.free) || !isEmpty(courseName.free))
    ) {
      setFilter((prev) => ({
        ...prev,
        status: courseStatus.free,
        name: courseName.free,
      }));
    } else {
      setFilter((prev) => ({ ...prev, status: null, name: null }));
    }
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, [courseStatus, courseName, type]);

  const columns = useMemo<MRT_ColumnDef<ICourse>[]>(() => {
    const updateBreadcrumbs = (cName: string | null, courseId: string | null) => {
      const crumb = {
        level: 1,
        page: "course",
        label: `${cName}`,
        path: `/myCourses/${courseId}`,
        tabNum: null,
      };
      if (courseId) setBreadcrumbs(courseId, [crumb]);
    };

    return [
      {
        accessorKey: "name",
        header: t("course:COURSE_NAME"),
        size: 370,
        minSize: 190,
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: common.tableHeaderColor,
            boxShadow: "5px 0 7px -2px rgba(91, 90, 48, 0.1)",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            backgroundColor: "#FFFFFF",
            boxShadow: "12px 0 8px -1px rgba(91, 90, 48, 0.1)",
          },
        },
        Cell: ({ renderedCellValue, row }) => (
          <RouterLink
            style={{ textDecoration: "none" }}
            to={`/myCourses/${row.original.id}`}
            onClick={() => updateBreadcrumbs(row.original.name, row.original.id)}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              {row.original.coverFileId ? (
                <AvatarPicture
                  id={row.original.coverFileId}
                  sxProps={{
                    width: "40px",
                    height: "30px",
                    borderRadius: "2px",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    backgroundColor: row.original.coverColor,
                    width: "40px",
                    height: "30px",
                    borderRadius: "2px",
                    flexShrink: 0,
                  }}
                />
              )}
              <Typography
                sx={{
                  color: common.primaryColor,
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                {renderedCellValue}
              </Typography>
            </Box>
          </RouterLink>
        ),
      },
      {
        accessorKey: "status",
        header: t("user:STATUS"),
        size: 160,
        enableResizing: false,
        Cell: ({ row }) => {
          const course = row.original;
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                background:
                  course.status === CourseStatus.SAVED
                    ? "#E8E8E8"
                    : course.status === CourseStatus.PUBLISHED
                      ? "#EBF6EB"
                      : course.status === CourseStatus.WITHDRAWN
                        ? "#FFF4E4"
                        : "#FFC5D8",
                color:
                  course.status === CourseStatus.SAVED
                    ? "#6B6B6B"
                    : course.status === CourseStatus.PUBLISHED
                      ? "#31AA27"
                      : course.status === CourseStatus.WITHDRAWN
                        ? "#D9B201"
                        : common.errorColor,
                borderRadius: "10px",
                height: "28px",
                width: "fit-content",
                px: 1,
              }}
            >
              {t(`${course.status}`)}
            </Box>
          );
        },
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "langId",
        header: t('dictionary:COURSE_LANGUAGE'),
        minSize: 255,
        enableResizing: false,
        Cell: ({ row }) => {
          const { courseSetting } = row.original;
          return <LanguageCell id={courseSetting.langId} />;
        },
      },
      {
        accessorKey: "subdivisionIds",
        header: t('enum:SUBDIVISION'),
        minSize: 255,
        enableResizing: false,
        muiTableBodyCellProps: {
          sx: { flexWrap: 'wrap' }
        },
        Cell: ({ row }) => row.original.courseSetting?.subdivisionIds.length > 0 ? row.original.courseSetting.subdivisionIds.map((sId: number, index: number) => {
          if (index !== 0)
            return <Fragment key={sId}>
              , <SubdivisionCell id={sId} />
            </Fragment>
          return <SubdivisionCell key={sId} id={sId} />
        }) : <Typography></Typography>
      },
      {
        accessorKey: "externalId",
        header: t('user:EXTERNAL_ID'),
        size: 220,
        enableResizing: false,
        Cell: ({ row }) => {
          const course = row.original;
          return <span>{course.externalId || ""}</span>;
        },
      },
    ];
  }, [t, common, setBreadcrumbs]);

  const table = useMaterialReactTable({
    columns,
    enableSorting: false,
    enableColumnActions: false,
    enableExpandAll: false,
    enableColumnResizing: true,
    enableTopToolbar: false,
    enableColumnPinning: true,
    enableRowActions: true,
    manualPagination: true,
    data: courseList,
    localization: {
      noRecordsToDisplay: t("dictionary:EMPTY"),
      rowsPerPage: t("enum:LINES_PER_PAGE"),
      actions: "",
      rowActions: t("common:ACTIONS"),
      expand: "",
      collapse: "",
    },
    displayColumnDefOptions: {
      "mrt-row-expand": {
        size: 30,
        muiTableBodyCellProps: { align: "center" },
      },
      "mrt-row-actions": {
        size: 30,
        muiTableBodyCellProps: { align: "center" },
      },
    },
    //optionally override the default column widths
    // defaultColumn: {
    //   size: 30, //default size is usually 180
    // },
    layoutMode: "grid",
    columnResizeMode: "onChange", //default
    initialState: {
      columnPinning: { left: ["mrt-row-expand", "mrt-row-actions", "name"] },
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        display: "grid",
        borderRadius: "6px",
      },
    },
    muiTableContainerProps: {
      sx: {
        "&::-webkit-scrollbar": { height: "4px", width: "7px" },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#D9D9D9",
          borderRadius: "4px",
          border: `1px solid ${common.secondaryColor}`
        },
      },
    },
    muiTableHeadCellProps: {
      sx: { backgroundColor: common.tableHeaderColor },
    },
    muiTableBodyCellProps: {
      sx: { backgroundColor: "#FFFFFF" },
    },
    mrtTheme: (theme) => ({
      draggingBorderColor: theme.palette.common.primaryColor,
    }),
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50, 100],
      shape: "rounded",
      variant: "outlined",
      SelectProps: {
        sx: {
          backgroundColor: "#FFFFFF",
          color: common.primaryColor,
          "& .MuiInputBase-root": {
            "& .MuiSelect-icon": { color: common.primaryColor },
          },
        },
      },
      sx: {
        "& .Mui-selected": {
          background: `${common.primaryColor} !important`,
          color: "#FFFFFF",
        },
      },
    },
    renderDetailPanel: ({ row }) => (
      <StudyClassList courseId={row.original.id} />
    ),
    onPaginationChange: setPagination,
    state: { pagination }, //pass the pagination state to the table
    rowCount: count,
    paginationDisplayMode: "pages",
  });

  useEffect(() => {
    if (typeof (orgId) === "number") {
      if (orgId === 0) {
        serviceDict.getDictionaryListByOrgIdForDropdown(orgId, DictionaryCode.SUBDIVISION).then((res) => {
          if (res.status === 200) {
            setSubdivisionList(res.data);
          }
        })
      } else {
        serviceDict.getDictListForDropdown(DictionaryCode.SUBDIVISION).then((res) => {
          if (res.status === 200) setSubdivisionList(res.data);
        })
      }
      serviceDict
        .getDictListForDropdown(DictionaryCode.BRANCH)
        .then((res) => {
          if (res.status === 200) setOrganizationsList(res.data.filter((org: IOrganization) => org.id !== 0))
        })
      serviceDict.getDictListForDropdown(DictionaryCode.LANGUAGE).then((res) => {
        if (res.status === 200) {
          setLanguageList(res.data);
        }
      })
    }
  }, [orgId]);

  // get all courses
  useEffect(() => {
    setIsLoadingCourse(true);
    if (type) {
      serviceCourse
        .getCourses({
          pageNumber: pagination.pageIndex,
          size: pagination.pageSize,
          filter,
        }, type)
        .then((res) => {
          if (res.status === 200) {
            setCourseList(res.data.dtoList);
            setCount(res.data.totalElements);
          }
        })
        .finally(() => setIsLoadingCourse(false));
    }
  }, [pagination, filter, type]);

  const handleChangeSubdivisionAndOrganization = (name: string, value: any) => {
    if (name === 'subdivisionIds') {
      setFilter({ ...filter, subdivisionIds: value?.length > 0 ? value : null });
    } else {
      setFilter({ ...filter, [name]: value })
    }
  }
  const handleChangeLangFilter = (value: any) => {
    setFilter({ ...filter, langId: value ? value : null });

  }

  return (
    <Box
      sx={{
        display: "grid",
        flexDirection: "column",
        gap: 2
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: { md: 'space-between', xs: 'flex-start' }, flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, gap: 3 }}>

          <Box display="flex" justifyContent="space-between" width="100%" gap={1} alignItems="center">
            <Box display="flex">
              <Typography color="#8E8E93">{t("course:FOUND")}:</Typography>{" "}
              <Typography color={common.primaryColor}>{count}</Typography>
            </Box>
            {openFilter &&
              <Box display="flex" gap={1}>
                <CustomAutocomplete
                  sx={{ width: { lg: '250px', xs: '100%' }, }}
                  placeholder={t("dictionary:COURSE_LANGUAGE")}
                  name="langId"
                  options={languageList}
                  onChange={(_, value: any) => handleChangeLangFilter(value)}
                  isInputEnterable
                />
                {role === Role.ADMIN && <CustomAutocomplete
                  sx={{ width: { lg: '350px', xs: '100%' } }}
                  name='orgId'
                  options={organizationsList}
                  placeholder={t('common:placeholders.CHOOSE_ORGANIZATION')}
                  onChange={(name: string, value: IDictionary) => handleChangeSubdivisionAndOrganization(name, value)}
                  isInputEnterable
                  customIconDisabled={false}
                />}
                <CustomAutocomplete
                  sx={{ width: { lg: '350px', xs: '100%' }, }}
                  placeholder={t('common:placeholders.CHOOSE_SUBDIVISION')}
                  name="subdivisionIds"
                  options={subdivisionList}
                  multiple
                  selectAll
                  onChange={(name: string, value: IDictionary) => handleChangeSubdivisionAndOrganization(name, value)}
                  isInputEnterable
                />
              </Box>
            }
          </Box>
        </Box>
      </Box>
      {/* <Box>
          <FormControlLabel control={<Checkbox
            onChange={(e) => setFilter(prev => ({ ...prev, showSameNamed: e.target.checked }))}
            sx={{
              "&, &.Mui-checked": {
                color: common.primaryColor,
              },
            }} />} label="Отображать курсы с идентичным наименованием" />
        </Box> */}
      {isLoadingCourse ? <LoadingSpinner /> : <MaterialReactTable table={table} />}
    </Box>
  );
}

export default List;