import * as yup from 'yup';
import i18n from 'i18next';

export const dictionaryValidationSchema = (t) => yup.object({
    id: yup.number().nullable(),
    nameKz: yup.string().required(t('common:validation.MANDATORY_FIELD')),
    nameRu: yup.string().required(t('common:validation.MANDATORY_FIELD')),
    nameEn: yup.string().required(t('common:validation.MANDATORY_FIELD')),
    isDisabled: yup.boolean()
});

export const fileFormatValidationSchema = (t) => yup.object({
    id: yup.number().nullable(),
    nameKz: yup.string().required(t('common:validation.MANDATORY_FIELD')),
    nameRu: yup.string(),
    nameEn: yup.string(),
    isDisabled: yup.boolean()
});