import { Snackbar, Alert } from "@mui/material";
import { useTranslation } from 'react-i18next';

interface IProps {
  notiOpen: boolean;
  isNotiOpen: (notification: boolean) => void;
}

const SlideNotification = ({ notiOpen, isNotiOpen }: IProps) => {
  const { t } = useTranslation('common');
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={notiOpen} autoHideDuration={3000} onClose={() => isNotiOpen(false)}>
      <Alert onClose={() => isNotiOpen(false)} severity="error" sx={{ width: '100%' }}>
        {t('common:messages.NO_EDITING_RIGHTS')}
      </Alert>
    </Snackbar>
  )
}

export default SlideNotification;
