import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import TextReader from '@ui/TextReader';

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  backgroundColor: isDragging ? "#4a2975" : "#FFFFFF",
  color: isDragging ? "#FFFFFF" : "#000000",
  width: "95%",

  ...draggableStyle
});

export type DraggableListItemProps = {
  showOrder?: boolean;
  item: any;
  index: number;
};

const DraggableAnswer = ({ showOrder = false, item, index }: DraggableListItemProps) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{ display: 'flex', alignItems: 'center', pl: 1 }}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          {showOrder && <Typography sx={{ fontWeight: 500, fontSize: '20px', mr: 3 }}>{index + 1}</Typography>}
          <DragIndicatorIcon sx={{ mr: 1, color: '#BDBDBD' }} />
          <TextReader htmlContent={item?.answer} hideBorder />
        </Box>
      )}
    </Draggable>
  );
};

export default DraggableAnswer;