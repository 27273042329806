import { useState, RefObject, createRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Formik, Form as FormikForm } from "formik";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { serviceTask } from '@services/task';
import Message from '@components/Message';
import CustomModal from '@components/Modal';
import FormikInput from '@ui/formik/Input';
import CustomButton from '@ui/Button';
import { ITaskGroup } from '@interfaces/index';
import { allRatioValidationSchema } from './validation';

interface Props {
  modalOpen: boolean;
  handleToggleModal: (modalState: boolean) => void;
  groups: Array<ITaskGroup>;
  updateTaskGroupList: (list: Array<ITaskGroup>) => void;
}

const EditAllRatioModal = ({ modalOpen, handleToggleModal, groups, updateTaskGroupList }: Props) => {
  const { courseId } = useParams();
  const { t } = useTranslation(["common", "task", "course"]);
  const { common } = useTheme().palette;
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const validationMessageRef: RefObject<HTMLElement> = createRef();

  return (
    <CustomModal
      open={modalOpen}
      handleToggleModal={handleToggleModal}
      top={35}
      divRef={validationMessageRef}
    >
      <>
        {messageOpen &&
          <Message
            type="error"
            message={message}
            setMessageOpen={setMessageOpen}
          />
        }
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '28px',
            lineHeight: '36px'
          }}
        >
          {t("course:task.UPDATE_ALL_RATIO")}
        </Typography>
        <Formik
          initialValues={{ groups }}
          enableReinitialize
          validationSchema={allRatioValidationSchema(t)}
          onSubmit={(values: { groups: Array<ITaskGroup> }) => {
            // console.log(values);
            const totalPercentage = values.groups.reduce((acc: number, gt: ITaskGroup) => {
              return Number(acc) + Number(gt.percentage)
            }, 0);
            if (courseId) {
              if (totalPercentage > 100) {
                setMessageOpen(true);
                setMessage(t('course:task.PERCENTAGE_OF_TASK_GROUPS_CANNOT_EXCEED_100'));
                setTimeout(() => {
                  setMessageOpen(false);
                }, 5000);
                validationMessageRef.current?.scrollTo(0, 0);
              } else {
                serviceTask.updateAllTaskGroupRatio(values.groups, courseId).then(res => {
                  if (res.status === 200) {
                    updateTaskGroupList(values.groups);
                    handleToggleModal(false);
                  }
                }).catch((err) => {
                  if (err.response.data.code === 'OVERALL_PERCENTAGE_ERROR') {
                    setMessageOpen(true)
                    setMessage(t('course:messages.OVERALL_PERCENTAGE_MUST_BE_100'))
                    setTimeout(() => {
                      setMessageOpen(false)
                    }, 5000)
                  }
                })
              }
            }
          }}
        >
          {({ values, submitForm, dirty, isValid }) => (
            <FormikForm>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  mt: 3
                }}
              >
                {values.groups.map((group: ITaskGroup, index: number) => {
                  return (
                    <Box
                      key={`${group?.id}_${index}`}
                      sx={{ display: "flex", gap: 2 }}
                    >
                      <Box sx={{ width: '60%' }}>
                        <FormikInput
                          name={`groups[${index}].name`}
                          label={t("course:task.NAME")}
                          disabled
                        />
                      </Box>
                      <Box sx={{ width: '40%' }}>
                        <FormikInput
                          name={`groups[${index}].percentage`}
                          label={t("course:task.RATIO_SHORT")}
                          type="number"
                          min={0}
                          isRequired
                        />
                      </Box>
                    </Box>
                  );
                })}
                <Box sx={{ display: "flex", gap: 2, justifyContent: "end" }}>
                  <CustomButton
                    backgroundColor={common.btnCancel}
                    fontColor={common.primaryColor}
                    width="199px"
                    borderRadius="6px"
                    height="40px"
                    sx={{
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    onClick={() => handleToggleModal(false)}
                  >
                    {t("common:actions.CANCEL")}
                  </CustomButton>
                  <CustomButton
                    backgroundColor={common.primaryColor}
                    fontColor={common.fontWhite}
                    width="199px"
                    borderRadius="6px"
                    height="40px"
                    sx={{
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    disabled={!(dirty && isValid)}
                    onClick={submitForm}
                  >
                    {t("common:actions.SAVE")}
                  </CustomButton>
                </Box>
              </Box>
            </FormikForm>
          )}
        </Formik>
      </>
    </CustomModal>
  )
}

export default EditAllRatioModal