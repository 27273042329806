import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';
import MathJaxComponent from "@components/MathJaxComponent";
import CustomModal from '@components/Modal';

interface FormulaModalProps {
  open: boolean;
  handleToggleModal: () => void;
}

export const GZFormulaModal = ({ open, handleToggleModal }: FormulaModalProps) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course"]);
  const GZFormula = "`Г_{3} = \\sum_{i=1}^n Б_{3_{1}} + Б_{3_{2}} + ... + Б_{3_{n}}`";
  return (
    <CustomModal
      open={open}
      handleToggleModal={handleToggleModal}
      width={515}
      borderColor={common.primaryColor}
    >
      <>
        <Typography>
          {t("course:listener.TOTAL_SCORE_GROUP_TASKS")}:
        </Typography>
        <Box sx={{ background: common.primaryColor, color: "#fff", margin: "0 auto" }}>
          <MathJaxComponent formula={GZFormula} />
        </Box>
        <Typography>{t("course:listener.WHERE")}:</Typography>
        <Typography><MathJaxComponent inline formula={"`Г_{3}`"} /> – {t('course:listener.TOTAL_SCORE_FOR_GROUP_OF_TASKS')};</Typography>
        <Typography><MathJaxComponent inline formula={"`Б_{3}`"} /> – {t('course:listener.SCORE_RECEIVED_FOR_COMPLETED_TASKS_FOR_FINAL_GRADE')};</Typography>
        <Typography><MathJaxComponent inline formula={"`n`"} /> – {t('course:listener.NUMBER_OF_GRADES_FOR_ALL_EVALUATED_COURSE_TASKS')}</Typography>
      </>
    </CustomModal>
  )
}
