import React from "react";
import { useField, useFormikContext } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box, Switch, SxProps, Typography } from '@mui/material'
import FormControl from "@mui/material/FormControl";

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
  sx?: SxProps,
  flexDirection?: string,
  justifyContent?: string,
  labelStart?: string,
}

const FormikSwitchNew = ({ name, label = '', labelStart = '', disabled = false,
  flexDirection = "column",
  justifyContent = "space-between",
  sx = {
    display: "flex",
    alignItems: 'center',
    gap: 1,
  }, ...rest }: Props) => {
  const [field, meta] = useField(name);
  const formikProps = useFormikContext();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: flexDirection,
        gap: 1,
        width: "100%",
        justifyContent: justifyContent,
        alignItems: "center"
      }}
    >
      {labelStart &&
        <Typography>
          {labelStart}
        </Typography>
      }
      <FormControl
        sx={sx}>
        <FormControlLabel
          control={
            <Switch
              {...field}
              {...rest}
              name={name}
              checked={field.value || false}
              onChange={(event) => {
                formikProps.setFieldValue(name, event.target.checked);
              }
              }
              disabled={disabled}
            />
          }
          label={label}
        />
      </FormControl>
    </Box>
  );
};

export default FormikSwitchNew;