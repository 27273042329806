export interface NotificationDto {
  id: number;
  notificationId: number;
  messageKz: string;
  messageRu: string;
  messageEn: string;
  createdTime: any; //
  routeType: string;
  userId: string;
  status: string;
  linkParams: any;
  readDate: any;
}


export const NotificationRouteENUM: any = {
  CHANGE_PASSWORD: {
    route: '/change'
  },
  CENTER_UNIVERSITY: {
    route: '/user/notification-list'
  },
  LOADED_REPORT: {
    route: '/user/category-reports'
  },
  PUBLIC_SERVICES: {
    route: '/user/ps'
  },
  convertQueryParams(queryString: string) {
    const obj: any = {};
    const pairs = queryString.split('&');
    for (let i = 0; i < pairs.length; i++) {
      const split = pairs[i].split('=');
      obj[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
    }
    return obj;
  }
};
export enum NotificationStatusENUM {
  READ,
  UNREAD,
  DELETED
}

interface IFilter {
  search: string;
  dateType: string; // SELECTED_PERIOD, WEEK, MONTH, LAST_3_MONTH
  status: string,
  startDate: any; // IF SELECTED dataType = SELECTED_PERIOD  long date
  endDate: any; // IF SELECTED dataType = SELECTED_PERIOD
}

export const initialFilter: IFilter = {
  search: '',
  dateType: '',
  status: 'UNREAD',
  startDate: null,
  endDate: null
}