import React, { useEffect, useState, MouseEvent } from 'react'
import { serviceCourse } from '@services/course';
import { useParams } from 'react-router-dom';
import { DTO, initialDto } from "@pages/profile/models";
import { Box, Divider, Menu, MenuItem, Popover, Typography, useTheme } from '@mui/material';
import Fade from "@mui/material/Fade";
import { get, capitalize } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { ICourse } from "@interfaces/index";
import serviceAccount from '@services/account';
import AvatarViaUserId from "@components/AvatarViaUserId";
import { useTranslation } from 'react-i18next';
import AvatarPicture from '@components/AvatarPicture';
import { getLangDictById } from "@utils/functions";
import { serviceDict } from '@services/dictionary';
import FileViewerModal from '@components/modals/FileViewerModal';

export const CreatedUserCourse = ({ courseData }) => {
  const { courseId } = useParams();
  const [courseInfo, setCourseInfo] = useState<ICourse>();
  const [profileInfo, setProfileInfo] = useState<DTO>(initialDto)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(null);
  const [isPublicProfileOpen, setIsPublicProfileOpen] = useState<boolean>(false)
  const [showFullText, setShowFullText] = useState<boolean>(false);
  const [subdivisionId, setSubdivisionId] = useState<number | null>(null)
  const [subdivisionName, setSubdivisionName] = useState<string>('')
  const [eduProgramId, setEduProgramId] = useState<number | null>(null)
  const [eduProgramName, setEduProgramName] = useState<string>('')
  const lang = localStorage.getItem("i18nextLng") || "kz";
  const [currentImage, setCurrentImage] = useState<any>();
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const { t } = useTranslation(['user', 'course', 'enum', 'common', 'profile']);
  const { common } = useTheme().palette;
  const open = Boolean(anchorEl);
  const openPopover = Boolean(popoverAnchorEl)

  const menuItemHoverStyle = {
    backgroundColor: common.primaryColor,
    color: common.fontWhite
  };

  const toggleShowMore = () => {
    setShowFullText(!showFullText);
  };

  const handleToggleImageViewer = (modalState: boolean, imageFile?: any) => {
    if (imageFile) setCurrentImage(imageFile);
    setOpenImageViewer(modalState);
  }

  useEffect(() => {
    if (courseId) {
      serviceCourse.getCourseInfo(courseId).then((res) => {
        if (res.status === 200) {
          setCourseInfo(res.data)
        }
      })
    }
  }, [courseId]);

  useEffect(() => {
    if (subdivisionId) {
      serviceDict.getDictionary('SUBDIVISION', subdivisionId)
        .then((res) => {
          if (res.status === 200) {
            setSubdivisionName(get(res.data, `name${capitalize(lang)}`));
          }
        });
    }
    if (eduProgramId) {
      serviceDict.getDictionary('EDU_PROGRAM', eduProgramId)
        .then((res) => {
          if (res.status === 200) {
            setEduProgramName(get(res.data, `name${capitalize(lang)}`));
          }
        });
    }
  }, [eduProgramId, lang, subdivisionId]);

  useEffect(() => {
    if (courseInfo?.createdBy) {
      serviceAccount.getProfileFullInfo(courseInfo?.createdBy).then((res) => {
        if (res.status === 200) {
          setProfileInfo(res.data)
          setEduProgramId(res.data.userRoles.find(userRole => userRole.orgId === courseData?.branchId).eduProgramId)
          setSubdivisionId(res.data.userRoles.find(userRole => userRole.orgId === courseData?.branchId).subdivisionId)
        }
      })

    }
  }, [courseInfo?.createdBy, courseData?.branchId])

  const handlePopoverOpen = (profile: boolean) => {
    setPopoverAnchorEl(anchorEl);
    setAnchorEl(null)
    setIsPublicProfileOpen(profile)
  };

  const handleClick = (event: MouseEvent<HTMLElement>, item: DTO) => {
    if (courseData?.status !== 'CLOSED' && courseData?.status !== 'COMPLETED') {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box marginBottom="16px" display="flex" justifyContent="space-between" padding="24px" sx={{ background: "#fff", borderRadius: "10px", border: `1px solid ${common.borderSecondary}` }} alignItems="center">
        <Box display="flex" gap={1.5}>
          <AvatarViaUserId
            userId={courseInfo?.createdBy || ""}
            sxProps={{ width: "50px", height: "50px" }}
          />
          <Box>
            <Typography fontSize="16px" fontWeight={600}>{profileInfo.firstName} {profileInfo.lastName}</Typography>
            <Typography fontSize="14px" color="#8E8E93">{profileInfo.email}</Typography>
          </Box>
        </Box>
        <Box display="flex" gap={1.5} alignItems="center">
          <Box sx={{ fontSize: "12px", color: "#fff", background: "#385FE8", padding: "8px 12px", borderRadius: "10px" }}>{t('course:COURSE_CREATOR')}</Box>
          <Box
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => handleClick(e, profileInfo)}
            sx={{ cursor: "pointer" }}
          >
            <MoreVertIcon />
          </Box>
        </Box>
      </Box>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        disableScrollLock
      >
        <MenuItem
          onClick={() => handlePopoverOpen(true)}
          sx={{ "&:hover": menuItemHoverStyle }}
        >
          {t('common:actions.VIEWING_PUBLIC_PROFILE')}
        </MenuItem>

        <MenuItem
          onClick={() => handlePopoverOpen(false)}
          sx={{ "&:hover": menuItemHoverStyle }}
        >
          {t('common:actions.VIEW_PROFILE')}
        </MenuItem>
      </Menu>
      <Popover
        open={openPopover}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}>
        <Box sx={{ width: '393px', height: 'auto', p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <CloseIcon
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
              color: common.strokePrimary,
              cursor: "pointer",
              fontSize: 24
            }}
            onClick={handlePopoverClose}
          />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <AvatarViaUserId userId={courseInfo?.createdBy || ''} sxProps={{ width: '55px', height: '55px' }} variant="square" />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>{`${profileInfo?.lastName} ${profileInfo?.firstName} ${profileInfo?.patronymic}`}</Typography>
              <Typography sx={{ color: '#8E8E93', fontSize: '14px', fontWeight: 400 }}>{profileInfo?.birthdate}</Typography>
            </Box>
          </Box>
          {isPublicProfileOpen &&
            (
              profileInfo?.detailInfo ||
              (profileInfo?.achievements && profileInfo?.achievements.length > 0)
              || profileInfo?.instagram || profileInfo?.facebook || profileInfo?.youtube
            ) &&
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Divider sx={{ color: common.borderPrimary }} />
              {profileInfo?.detailInfo &&
                <Box>
                  <Typography sx={{ color: '#8E8E93' }}>
                    {t('common:label.ABOUT_ME')}
                  </Typography>
                  <Box sx={{ textAlign: 'justify' }}>
                    {showFullText ? profileInfo?.detailInfo?.replace(/<[^>]*>|&nbsp;/g, '').trim() : profileInfo?.detailInfo?.replace(/<[^>]*>|&nbsp;/g, '').trim().slice(0, 100)}
                    {profileInfo?.detailInfo?.replace(/<[^>]*>|&nbsp;/g, '').trim().length >= 100 &&
                      <Typography onClick={toggleShowMore} sx={{ display: 'inline', cursor: 'pointer', color: common.primaryColor }}>
                        {showFullText ? ` ${t('common:actions.SHOW_LESS')}` : `...${t('common:actions.SHOW_MORE')}`}
                      </Typography>
                    }
                  </Box>
                  <Divider sx={{ color: common.borderPrimary, m: '5px 0' }} />
                </Box>}
              {profileInfo?.achievements && profileInfo?.achievements.length > 0 &&
                <Box>
                  <Typography sx={{ color: '#8E8E93' }}>
                    {t('profile:ACHIEVMENTS')}
                  </Typography>
                  <Box sx={{
                    display: 'flex',
                    overflowY: "auto",
                    width: "95%",
                    '&::-webkit-scrollbar': { width: '2px', height: '7px' },
                    '&::-webkit-scrollbar-thumb': { backgroundColor: '#E5E0EB', borderRadius: '6px' }
                  }}>
                    {profileInfo?.achievements.map((achievement) => {
                      const isPDF = achievement.name.endsWith('.pdf')

                      return (
                        <Box
                          key={achievement.fileId}
                          sx={{ p: '12px 8px' }}
                          onClick={() => handleToggleImageViewer(true, achievement)}
                        >
                          {isPDF
                            ? <Box
                              sx={{
                                display: 'grid',
                                placeContent: 'center',
                                borderRadius: "8px",
                                width: '130px',
                                height: '130px',
                                cursor: "pointer",
                                background: '#A9B6FF33'
                              }}>
                              <PictureAsPdfIcon sx={{ width: '50px', height: '50px', color: '#9469E3' }} />
                            </Box>
                            : <AvatarPicture
                              id={achievement.fileId}
                              sxProps={{
                                border: "1px solid #D9D9D9",
                                borderRadius: "8px",
                                width: '130px',
                                height: '130px',
                                cursor: "pointer"
                              }}
                            />}
                        </Box>
                      )
                    })}
                  </Box>
                  <Divider sx={{ color: common.borderPrimary, m: '5px 0' }} />
                </Box>}
              {(profileInfo?.instagram || profileInfo?.facebook || profileInfo?.youtube) &&
                <Box>
                  <Typography sx={{ color: '#8E8E93' }}>
                    {t('profile:SOCIAL_NETWORKS')}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '130px', gap: 2 }}>
                    {profileInfo?.instagram &&
                      <Box onClick={() => { window.open(`${profileInfo?.instagram}`, "_blank") }}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: "pointer", background: '#9469E3', color: 'white', width: '32px', height: '32px', borderRadius: '5px' }}>
                        <InstagramIcon />
                      </Box>}
                    {profileInfo?.facebook &&
                      <Box onClick={() => { window.open(`${profileInfo?.facebook}`, "_blank") }}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: "pointer", background: '#9469E3', color: 'white', width: '32px', height: '32px', borderRadius: '5px' }}>
                        <FacebookIcon />
                      </Box>
                    }
                    {profileInfo?.youtube &&
                      <Box onClick={() => { window.open(`${profileInfo?.youtube}`, "_blank") }}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: "pointer", background: '#9469E3', color: 'white', width: '32px', height: '32px', borderRadius: '5px' }}>
                        <YouTubeIcon />
                      </Box>
                    }
                  </Box>
                </Box>}
            </Box>
          }
          {!isPublicProfileOpen &&
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Divider sx={{ color: common.borderPrimary }} />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ color: '#8E8E93' }}>
                  {t('user:PHONE_NUMBER')}
                </Typography>
                <Typography>
                  {profileInfo?.mobilePhone}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ color: '#8E8E93' }}>
                  {t('user:GENDER')}
                </Typography>
                <Typography>
                  {t(`user:${profileInfo?.sex}`)}
                </Typography>
              </Box>
              {subdivisionName &&
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ color: '#8E8E93' }}>
                    {t('enum:SUBDIVISION')}
                  </Typography>
                  <Typography>
                    {subdivisionName}
                  </Typography>
                </Box>}
              {eduProgramName &&
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ color: '#8E8E93' }}>
                    {t('enum:EDU_PROGRAM')}
                  </Typography>
                  <Typography>
                    {eduProgramName}
                  </Typography>
                </Box>}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ color: '#8E8E93' }}>
                  {t('user:EMAIL')}
                </Typography>
                <Typography>
                  {profileInfo?.email}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ color: '#8E8E93' }}>
                  {t('user:LANGUAGES')}
                </Typography>
                <Typography>
                  {profileInfo?.langIds && profileInfo.langIds.map(langId => (
                    getLangDictById(langId)
                  )).join(', ')}
                </Typography>
              </Box>
            </Box>}
        </Box>
      </Popover>
      {openImageViewer &&
        <FileViewerModal
          openModal={openImageViewer}
          handleToggleModal={handleToggleImageViewer}
          file={currentImage}
        />
      }
    </>
  )
}
