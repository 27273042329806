import ImageViewer from '@components/ImageViewer'
import { TabContext } from '@mui/lab'
import { Box, CardMedia, Divider, Tab, Tabs, Typography, useTheme } from '@mui/material'
import { serviceAero } from '@services/aero'
import React, { useEffect, useState } from 'react'
import { ViolationsPage } from './ViolationsPage'
import { VideoPage } from './VideoPage'
import { useParams, useSearchParams } from 'react-router-dom'
import { LinearProgressWithLabel } from '@components/LinearProgressWithLabel'
import { AboutProgress } from './AboutProgress'
import { useTranslation } from 'react-i18next'
import { DTO, initialDtoForEDUCourse } from '../models'
import { serviceDict } from '@services/dictionary'
import { formatDateWithHourMinute } from '@utils/functions'
import { serviceUser } from '@services/user'
import { DTO as UserDTO, initialDto } from '@pages/profile/models';
import { useBreadcrumbs } from '@context/BreadcrumbsContext'
import { IBreadcrumb } from '@interfaces/index'
import Breadcrumbs from '@components/Breadcrumbs'

export const TestReports = () => {
  const [aeroReportInfo, setAeroReportInfo] = useState<any>(null)
  const { progressId, userId } = useParams();
  const { t } = useTranslation(["aero", "common", "enum"]);
  const { common } = useTheme().palette;
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get('type');
  const [userInfo, setUserInfo] = useState<UserDTO>(initialDto)
  const [typeViolations, setTypeViolations] = useState("violations");
  const [initialData, setInitialData] = useState<DTO>(initialDtoForEDUCourse)
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    if (progressId) {
      serviceAero.getReports(progressId).then((res) => {
        if (res.status === 200) {
          setAeroReportInfo(res.data)
        }
      })
    }
  }, [progressId])

  useEffect(() => {
    serviceDict.getTypeOptionList("1").then((res) => {
      if (res.status === 200) {
        setInitialData(res.data.find(typeOption => typeOption.code === "EDU"));
      }
    })
  }, []);

  const handleChange = (event: React.SyntheticEvent, type: string) => {
    setTypeViolations(type);
    setSearchParams({ type: type });
  };

  useEffect(() => {
    if (currentTab) {
      setTypeViolations(currentTab);
    } else {
      setTypeViolations("violations");
    }
  }, [currentTab]);

  useEffect(() => {
    if (userId) {
      serviceUser.getUserInfoById(userId).then((res) => {
        if (res.status === 200) {
          setUserInfo(res.data);
        }
      })
    }
  }, [userId]);

  useEffect(() => {
    const updatedCrumbs = [...getBreadcrumbs("aero_report")].filter((x: IBreadcrumb) => x.level !== 5);
    updatedCrumbs.push({
      level: 5,
      page: 'aeroPeport',
      label: t('aero:DETAILED_TEST_REPORT'),
      path: null,
      tabNum: null
    });
    // Check if the breadcrumbs have actually changed before updating
    if (JSON.stringify(updatedCrumbs) !== JSON.stringify(getBreadcrumbs("aero_report"))) {
      setBreadcrumbs("aero_report", updatedCrumbs);
    }
  }, [getBreadcrumbs, setBreadcrumbs, t]);

  const bdProgressColor = initialData.violation && (initialData.violation?.trustLevelLow > (100 - aeroReportInfo?.aeroReport?.score) ? "#D62B20" :
    initialData.violation?.trustLevelHigh < (100 - aeroReportInfo?.aeroReport?.score) ? "#7FE47E" : "#E5D226")

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: 3 }}>
        <Breadcrumbs id="aero_report" />
      </Box>
      <Typography sx={{ fontSize: "36px", fontWeight: 600, marginBottom: "30px" }}>{t('aero:DETAILED_TEST_REPORT')}</Typography>
      <Box
        sx={{
          display: "block",
          width: "100%",
          background: common.fontWhite,
          padding: "25px",
          borderRadius: 3,
        }}
      >
        <Box display="flex" gap={2}>
          {userInfo.profilePhotoId && <Box>
            <ImageViewer
              fileId={userInfo.profilePhotoId}
              viewOnly
              sxProps={{
                width: "160px",
                height: "160px",
                borderRadius: 3
              }}
            />
            <Typography>{t("aero:PROFILE_PHOTO")}</Typography>
          </Box>}
          <Box>
            <CardMedia
              component='img'
              src={aeroReportInfo?.aeroReport?.identification}
              sx={{
                width: "160px",
                height: "160px",
                borderRadius: 3
              }} />
            <Typography>{t("aero:PHOTO_VERIFICATION")}</Typography>
          </Box>
          <Box>
            <Typography fontSize="16px" fontWeight={500} sx={{ marginBottom: "20px" }}>{userInfo.firstName} {userInfo.lastName} {userInfo.patronymic}</Typography>
            <Box display="flex" gap={2}>
              <Box color={"#c1c1c1"} gap={1}>
                <Typography>{t("aero:TIME_STARTED_TESTING")}:</Typography>
                <Typography>{t("aero:TIME_FINISHED_TESTING")}:</Typography>
                <Typography>{t("aero:TRUST_LEVEL")}:</Typography>
                <Typography>{t("aero:TOTAL_VIOLATION")}:</Typography>
              </Box>
              <Box sx={{ lineHeight: "5px" }}>
                <Typography>{formatDateWithHourMinute(aeroReportInfo?.aeroReport?.startedAt)}</Typography>
                <Typography>{formatDateWithHourMinute(aeroReportInfo?.aeroReport?.finishedAt)}</Typography>
                <Box display="flex">
                  <LinearProgressWithLabel
                    value={100 - aeroReportInfo?.aeroReport?.score}
                    sx={{
                      width: "130px", height: "8px", borderRadius: "2px",
                      '& .MuiLinearProgress-bar1Determinate': {
                        backgroundColor: bdProgressColor,
                      }
                    }} />
                  <AboutProgress />
                </Box>
                <Typography>{aeroReportInfo?.aeroReport?.cheatings?.length}</Typography>
              </Box>
            </Box>
          </Box>

        </Box>
        <Divider sx={{ marginTop: "20px" }} />
        <TabContext value={typeViolations}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                padding: "10px 0 0 10px",
              }}
            >
              <Tabs
                onChange={handleChange}
                indicatorColor="secondary"
                value={typeViolations}
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: "#1A141F",
                  },
                  "& .MuiTabs-indicator": {
                    background: common.borderPrimary,
                  },
                }}
              >
                <Tab
                  label={t("aero:VIOLATION")}
                  value={"violations"}
                  sx={{ textTransform: "none", fontSize: "18px" }}
                />
                <Tab
                  label={t("enum:VIDEO")}
                  value={"video"}
                  sx={{ textTransform: "none", fontSize: "18px" }}
                />
              </Tabs>
            </Box>
          </Box>
        </TabContext>
      </Box>
      {typeViolations === "violations" && <ViolationsPage aeroInfo={aeroReportInfo?.aeroReport} dtoCourse={initialData} />}
      {typeViolations === "video" && <VideoPage aeroInfo={aeroReportInfo?.aeroReport} dtoCourse={initialData} />}
    </Box>
  )
}
