import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
  AlertColor,
  Box,
  Typography,
  useTheme
} from "@mui/material";
import { Formik, Form as FormikForm } from "formik";

import { serviceDict } from '@services/dictionary';
import CustomModal from '@components/Modal';
import CustomButton from '@ui/Button';
import FormikInput from '@ui/formik/Input';
import FormikCheckbox from '@ui/formik/Checkbox';
import { IDictType, initialDTODictType } from './models';
import { classTypeValidationSchema, dictValidationSchema } from './validation';

interface IProps {
  modalOpen: boolean;
  handleToggleModal: (modalState: boolean) => void;
  isEditing?: boolean;
  setMessage: (message: string) => void;
  setMessageOpen: (open: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  handbookType?: string;
  selectedType?: any;
  onSaveSuccess?: any;
}
const AddHandbookTypeModal = ({ modalOpen, handleToggleModal, isEditing = false,
  handbookType, selectedType, onSaveSuccess, setMessage, setMessageOpen, setMessageType }: IProps) => {
  const { t } = useTranslation(['common', 'dictionary', 'enum']);
  const { common } = useTheme().palette;
  const [initialData, setInitialData] = useState<IDictType>(initialDTODictType)

  useEffect(() => {
    if (isEditing) {
      setInitialData(selectedType)
    }
  }, [selectedType, isEditing])

  return (
    <CustomModal
      open={modalOpen}
      handleToggleModal={handleToggleModal}
    >
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: 500,
            fontSize: { sm: "28px", xs: '20px' },
            lineHeight: "36px",
            color: "#000000",
            display: "flex",
            alignItems: "center",
            mb: "24px",
            width: '100%'
          }}
        >
          {isEditing && (
            handbookType === 'subdivision' ? t('common:actions.EDITING_SUBDIVISION') :
              handbookType === 'edu_program' ? t('common:actions.EDITING_EDU_PROGRAM') :
                handbookType === 'study_form' ? t('common:actions.EDITING_STUDY_FORM') :
                  handbookType === 'class_type' ? t('common:actions.EDITING_CLASS_TYPE') : t('common:actions.EDITING_BASE_FOR_EARLY_COMPLETION')
          )}
          {!isEditing && (
            handbookType === 'subdivision' ? t('common:actions.ADDING_SUBDIVISION') :
              handbookType === 'edu_program' ? t('common:actions.ADDING_EDU_PROGRAM') :
                handbookType === 'study_form' ? t('common:actions.ADDING_STUDY_FORM') :
                  handbookType === 'class_type' ? t('common:actions.ADDING_CLASS_TYPE') : t('common:actions.ADDING_BASE_FOR_EARLY_COMPLETION')
          )}
        </Typography>
        <Formik
          initialValues={initialData}
          enableReinitialize
          validationSchema={handbookType === 'class_type' ? classTypeValidationSchema(t) : dictValidationSchema(t)}
          onSubmit={(values) => {
            serviceDict.createDictionary(`${handbookType?.toUpperCase()}`, { ...values }).then((res) => {
              if (res.status === 200) {
                onSaveSuccess(values)
                if (isEditing) {
                  setMessage(t('common:messages.CHANGES_SAVED'))
                } else {
                  setMessage(t('common:messages.RECORD_ADDED'))
                }
                setMessageType('success')
                setMessageOpen(true)
                setTimeout(() => {
                  setMessageOpen(false)
                }, 3000)
              }
            }).catch((err) => {
              if (err.response.data.message === 'ITEM_IS_EXISTS') {
                setMessage(t('common:messages.VALUE_ALREADY_EXISTS'))
                setMessageType('error')
                setMessageOpen(true)
                setTimeout(() => {
                  setMessageOpen(false)
                }, 5000)
              }
            }).finally(() => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
              handleToggleModal(false)
            })
          }}
        >
          {({ submitForm }) => {
            return (
              <FormikForm>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3
                  }}
                >
                  <FormikInput
                    name='nameKz'
                    type='text'
                    label={handbookType === 'course_close_reason' ? t('enum:BASE_IN_KAZAKH_LANGUAGE') : t('dictionary:NAME_IN_KAZAKH')}
                    isRequired
                  />
                  {handbookType === 'class_type' &&
                    <FormikInput
                      name='shortNameKz'
                      type='text'
                      label={t('dictionary:ABBREVIATED_NAME_IN_KAZAKH')}
                      isRequired
                    />}
                  <FormikInput
                    name='nameRu'
                    type='text'
                    label={handbookType === 'course_close_reason' ? t('enum:BASE_IN_RUSSIAN_LANGUAGE') : t('dictionary:NAME_IN_RUSSIAN')}
                    isRequired
                  />
                  {handbookType === 'class_type' &&
                    <FormikInput
                      name='shortNameRu'
                      type='text'
                      label={t('dictionary:ABBREVIATED_NAME_IN_RUSSIAN')}
                      isRequired
                    />}
                  <FormikInput
                    name='nameEn'
                    type='text'
                    label={handbookType === 'course_close_reason' ? t('enum:BASE_IN_ENGLISH_LANGUAGE') : t('dictionary:NAME_IN_ENGLISH')}
                    isRequired
                  />
                  {handbookType === 'class_type' &&
                    <FormikInput
                      name='shortNameEn'
                      type='text'
                      label={t('dictionary:ABBREVIATED_NAME_IN_ENGLISH')}
                      isRequired
                    />}
                  <FormikCheckbox name='isDisabled' label={t('enum:NOT_USED')} labelPlacement='end' />
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1
                    }}
                  >
                    <CustomButton
                      backgroundColor={common.btnCancel}
                      fontColor={common.primaryColor}
                      borderRadius="6px"
                      height="40px"
                      sx={{
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                      onClick={() => handleToggleModal(false)}
                    >
                      {t('actions.CANCEL')}
                    </CustomButton>
                    <CustomButton
                      backgroundColor={common.primaryColor}
                      fontColor={common.fontWhite}
                      borderRadius="6px"
                      height="40px"
                      onClick={submitForm}
                      sx={{
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      {t('actions.SAVE')}
                    </CustomButton>
                  </Box>
                </Box>
              </FormikForm>
            )
          }}
        </Formik>
      </>
    </CustomModal>
  )
}

export default AddHandbookTypeModal;