export interface DTO {
    id: string | null;
    nameKz: string;
    nameRu: string;
    nameEn: string;
    isDisabled: boolean;
    isStandard?: boolean;
    code: string;
}

export const initialDto: DTO = {
    id: null,
    nameKz: "",
    nameRu: "",
    nameEn: "",
    isDisabled: false,
    isStandard: false,
    code: "",
}

export interface IFilter {
    search: string;
    isDisabled: boolean;
}

export const initialFilter: IFilter = {
    search: "",
    isDisabled: false,
};