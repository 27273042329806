import * as yup from 'yup';

export const aeroValidationSchema = (t) => yup.object({
  orgCode: yup.string().required(t('common:validation.MANDATORY_FIELD')),
  secretKey: yup.string().required(t('common:validation.MANDATORY_FIELD')),
});

export const antiPlagiarismValidationSchema = (t) => yup.object({
  apiUrl: yup.string().required(t('common:validation.MANDATORY_FIELD')),
  email: yup.string().email('Не корректно введена электронная почта')
    .matches(/^[a-zA-Z0-9$&?^_`~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/,
      "email должен содержать только латинские буквы, цифры и спецсимволы: $ & ? ^ _ ` ~ . -")
    .nullable().required(t('common:validation.MANDATORY_FIELD')),
  password: yup.string().required(t('common:validation.MANDATORY_FIELD')),
  reportUrl: yup.string().required(t('common:validation.MANDATORY_FIELD')),
});

export const addAntiPlagiarismAttemptsValidationSchema = (t, attempts) => yup.object({
  attempts: yup.string().required(t('common:validation.MANDATORY_FIELD'))
    .test('len', 'Введенное количество превышает доступное', (val) => {
      if (val === undefined) {
        return true;
      }
      return (Number(val) <= attempts)
    }),
});