import axios from '@utils/axios';

export const serviceFile = {
  uploadFile: (bodyData: any, config = {}) => axios.post(`/api/file-storage/v1/new/upload-file`, bodyData, config),
  getFileMimeType: (fileId: string) => axios.get(`/api/file-storage/v1/get-file-with-mime-type/${fileId}`, { responseType: 'blob' }),
  getFileInBytes: (fileId: string) => axios.get(`/api/file-storage/v1/get-file/${fileId}`),
  deleteFile: (fileId: string) => axios.delete(`/api/file-storage/v1/delete-file/${fileId}`),
  downloadFile: (fileId: string) => axios.get(`/api/file-storage/v1/download-file/${fileId}`, { responseType: 'blob' }),
  occupiedFileStorageSpace: () => axios.get(`/api/file-storage/v1/occupied-size`),
  getVideoFile: (fileId: string, config: any) => axios.get(`/api/file-storage/v1/video/stream/${fileId}`, config),
  // public apis
  getFileMimeTypePublic: (fileId: string) => axios.get(`/api/file-storage/public/v1/get-file-with-mime-type/${fileId}`, { responseType: 'blob' }),
  getProfileImgByUserIdPublic: (userId: string) => axios.get(`/api/file-storage/public/v1/profile-picture/user/${userId}`, { responseType: 'blob' }),
};