import { IMaterial, IMember, ITheme } from '@interfaces/index';
import { getTodayWithSpecificTime } from '@utils/functions';

export const initialDto: ITheme = {
  id: null,
  course: null,
  section: null,
  index: null,
  name: null,
  shortDescription: null,
  description: null,
  isCloseAccess: true,
  dateOfAccess: getTodayWithSpecificTime(7, 0),
  isNotifyStudent: false,
  videoType: null,
  videoId: null,
  videoName: null,
  url: null,
  contentType: "DESCRIPTION", // VIDEO, DESCRIPTION
  status: "SAVED", // SAVED, ACTIVE
  materials: [] as IMaterial[],
  estimatedTime: null
}

export const initialMaterialDto: IMaterial = {
  id: null,
  name: null,
  url: null,
  downloadable: false,
  fileId: null
}
export interface IRecipients {
  id: string | null;
  isCloseAccess: boolean;
  dateOfAccess: string | null;
  responsibleMembers: IMember[];
  responsibleMemberIds: string[];
  classId: string | null
}
export const initialDTO: IRecipients = {
  id: null,
  isCloseAccess: false,
  dateOfAccess: null,
  responsibleMembers: [],
  responsibleMemberIds: [],
  classId: null
}
export interface IStream {
  id: string | null;
  courseId: string | null;
  course: {
    id: string | null;
    published: boolean;
    classes: any[];
    assessments: any[];
  };
  status: string;
  capacity: number;
  canSelfRegistration: boolean;
  regStartDate: string;
  regEndDate: string;
  startDate: string;
  endDate: string;
  sendEmailMessage: boolean;
  allStudentCount: number;
  activeStudentCount: number;
}

export const initialStreamData: IStream[] = [
  {
    id: null,
    courseId: null,
    course: {
      id: null,
      published: false,
      classes: [],
      assessments: []
    },
    status: "CREATED",
    capacity: 100,
    canSelfRegistration: true,
    regStartDate: "",
    regEndDate: "",
    startDate: "",
    endDate: "",
    sendEmailMessage: false,
    allStudentCount: 0,
    activeStudentCount: 0
  }
];