import { useEffect, useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';
// import IconHints from '@components/IconHints';
import CustomButton from '@ui/Button';
import { AddAuthorModal } from './AddAuthorModal';
import { ConfirmationStatus, ICourse } from '@interfaces/index';
import { AlertColor } from '@mui/material/Alert';
import { serviceCourse } from '@services/course';
import { serviceDict } from '@services/dictionary';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteModal from '@components/modals/DeleteModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

interface Props {
  courseInfo: ICourse;
  setMessageOpen: (isOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  setMessage: (message: string) => void;
}

export const AuthorPage = ({ courseInfo, setMessageOpen, setMessageType, setMessage }: Props) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["user", "common", "course", "enum", "dictionary"]);
  const [addAuthor, setAddAuthor] = useState<boolean>(false);
  const [orgInfo, setOrgInfo] = useState<any>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [chooseAuthorInfo, setChooseAuthorInfo] = useState<any>(null);

  const queryClient = useQueryClient();
  const { data: authorList } =
    useQuery({
      queryKey: ['courseAuthorList', courseInfo.id],
      queryFn: () => serviceCourse.getCourseAuthors(courseInfo.id || ""),
      retry: 0,
    });

  useEffect(() => {
    if (courseInfo.courseSetting.branchId) {
      serviceDict.getDictionary("BRANCH", courseInfo.courseSetting.branchId).then((res) => {
        if (res.status === 200) {
          setOrgInfo(res.data)
        }
      })
    }
  }, [courseInfo.courseSetting.branchId])

  const handleToggleAddAuthorModal = (modalState: boolean, message?: string) => {
    setAddAuthor(modalState)
    if (message) {
      setMessage(t(message))
      setMessageOpen(true)
      setMessageType('success')
      setTimeout(() => {
        setMessageOpen(false)
      }, 3000)
    }
  };

  const { mutate: handleDelete } = useMutation({
    mutationFn: async () => {
      const res = chooseAuthorInfo.id ? serviceCourse.updateStatusAuthor(chooseAuthorInfo.id, false) : null;
      return res;
    },
    onSuccess: () => {
      setDeleteModalOpen(false)
      setMessage(t('common:messages.SUCCESSFULLY_REMOVED_MANUALLY'))
      setMessageOpen(true)
      setMessageType('success')
      setTimeout(() => {
        setMessageOpen(false)
      }, 3000);
      return queryClient.invalidateQueries({ queryKey: ['courseAuthorList'] });
    },
    onError: (error: any) => {
      setDeleteModalOpen(false)
      setMessage(t(`common:messages.${error?.response?.data?.code}`))
      setMessageOpen(true)
      setMessageType('error')
      setTimeout(() => {
        setMessageOpen(false)
      }, 3000)
    }
  })

  return (
    <Box>
      {courseInfo?.courseSetting?.branchId ? <Box>
        <Box display="flex" sx={{ marginBottom: "10px" }}>
          <Box sx={{ width: "6px", background: common.primaryColor, borderRadius: "6px", marginRight: "8px" }} />
          <Box>
            <Typography>{orgInfo?.nameRu}</Typography>
          </Box>
        </Box>
        <Typography sx={{ color: "#8E8E93", fontSize: "14px" }}>
          {t("course:ORGANIZATION_AUTOMATICLLY_INDICATED_AUTHOR")}
        </Typography>
      </Box> :
        <Box>
          {authorList?.data.length > 1 ? authorList?.data.map((author) => {
            return (
              <Box key={author?.userId} display="flex" sx={{ marginBottom: "10px" }}>

                <Box sx={{ width: "6px", background: common.primaryColor, borderRadius: "6px", marginRight: "8px" }} />
                {author?.userId === courseInfo?.createdBy ? <Box>
                  <Typography>{author?.username} ({t("course:YOU")})</Typography>
                </Box> : author?.userId ?
                  <Box>
                    <Typography >{author?.username}</Typography>
                    {author?.status === ConfirmationStatus.NOT_CONFIRMED &&
                      <Typography margin="6px 0px" sx={{ fontSize: "12px" }}>{t("course:WAITING_FOR_CONFIRMATION")}</Typography>
                    }
                    <CustomButton borderRadius='6px' onClick={() => {
                      setChooseAuthorInfo(author)
                      handleDelete()
                    }}>
                      {t("common:actions.REVOKE_AUTHORSHIP_COURSE")}
                    </CustomButton>
                  </Box> : <Box>
                    <Typography >{author?.username}</Typography>
                    <Box display="flex" gap={1} alignItems="center">
                      <Box sx={{ background: "#50BE1C", width: "8px", height: "8px", borderRadius: "10px" }}></Box>
                      <Typography fontSize="12px">{t("course:ADDED_MANUALLY")}</Typography>
                    </Box>
                    {author?.status === ConfirmationStatus.NOT_CONFIRMED &&
                      <Typography margin="6px 0px" sx={{ fontSize: "12px" }}>{t("course:WAITING_FOR_CONFIRMATION")}</Typography>
                    }
                    <Box display="flex" gap={1}>
                      <CustomButton borderRadius='6px' onClick={() => {
                        setAddAuthor(true)
                      }}>{t("common:actions.EDIT")}</CustomButton>
                      <CustomButton backgroundColor={common.errorColor} width='50px' borderRadius='6px' onClick={() => {
                        setChooseAuthorInfo(author)
                        setDeleteModalOpen(true)
                      }}>
                        <DeleteOutlineOutlinedIcon />
                      </CustomButton>
                    </Box>
                  </Box>
                }
              </Box>
            )
          }) :
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {authorList?.data.map((author) => {
                return (
                  <Box key={author?.userId} display="flex" sx={{ marginBottom: "10px" }}>

                    <Box sx={{ width: "6px", background: common.primaryColor, borderRadius: "6px", marginRight: "8px" }} />
                    {author?.userId === courseInfo?.createdBy &&
                      <Box>
                        <Typography>{author.username} ({t("course:YOU")})</Typography>
                      </Box>}
                  </Box>
                )
              })}
              <Box sx={{ color: "#8E8E93", fontSize: "14px" }}>
                {t("course:hints.THE_AUTOMATIC_COURSE_CREATOR_IS_THE_COURSE_AUTHOR_ADD_AUTHOR")}
              </Box>
              {/* <IconHints text={t("course:hints.THE_AUTOMATIC_COURSE_CREATOR_IS_THE_COURSE_AUTHOR")} /> */}
              <CustomButton width='fit-content' onClick={() => setAddAuthor(true)}>{t("common:actions.ADD_ANOTHER_AUTHOR")}</CustomButton>
            </Box>
          }
        </Box>
      }
      {addAuthor &&
        <AddAuthorModal
          modalOpen={addAuthor}
          handleToggleModal={handleToggleAddAuthorModal}
          courseInfo={courseInfo}
          authorList={authorList?.data}
        />
      }
      {deleteModalOpen &&
        <DeleteModal
          open={deleteModalOpen}
          handleToggleModal={() => setDeleteModalOpen(false)}
          deleteItem={handleDelete}
        />
      }
    </Box>
  )
}
