import axios from '@utils/axios';
import { IRole } from 'interfaces';

export const serviceRole = {
  createRole: (bodyData: IRole) => axios.post('/api/user/role/create', bodyData),
  updateRole: (bodyData: IRole) => axios.post('/api/user/role/update', bodyData),
  getAllRoles: (bodyData: any) => axios.post('/api/user/role/findAll', bodyData),
  getAllRolesForSelect: () => axios.get('/api/user/role/findAll'),
  deleteRole: (id: number) => axios.delete(`/api/user/role/delete/${id}`),
  getPermissionList: () => axios.get('/api/user/permission/findAll'),
};
