export const initialDto: Issuance = {
    id: null,
    license: null,
    organization: null,
    endDate: null,
    startDate: new Date(),
    isPaid: false,
    isActivity: false
}


export interface Issuance {
    id: string | null;
    license: string | null;
    organization: string | null;
    endDate: Date | null;
    startDate: Date | null;
    isPaid: boolean;
    isActivity: boolean;
}