import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CustomModal from '@components/Modal';
import { useTranslation } from "react-i18next";

const SuccesModal = ({ open, handleToggleModal }) => {
  const { t } = useTranslation(["course"]);
  return (
    <CustomModal open={open} handleToggleModal={handleToggleModal} width={515}>
      <>
        <Box onClick={() => handleToggleModal()}
          sx={{ display: 'flex', justifyContent: 'flex-end', mt: '-30px', mr: '-30px', mb: '20px', color: '#ABA7AF', cursor: 'pointer' }}><ClearOutlinedIcon /></Box>
        <Box sx={{ display: 'block' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CheckCircleOutlineOutlinedIcon sx={{ width: '140px', height: '140px', color: '#00B998', mb: '32px' }} />
          </Box>
          <Typography id="modal-modal-title" sx={{ color: "#4D5377", display: "flex", alignItems: "center", textAlign: "center", justifyContent: 'center', fontSize: '20px', fontWeight: '500', lineHeight: '26px' }}>
            {t('course:messages.DEADLINE_DATE_OF_TASK_SUCCESSFULLY_CHANGED')}
          </Typography>
        </Box>
      </>
    </CustomModal>
  )
}

export default SuccesModal;