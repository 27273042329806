import { useEffect, useState } from 'react'
import CustomSearch from '@ui/Search'
import { Box, Table, TableContainer, TableHead, Typography, useTheme, TableRow, TableCell, TableBody } from '@mui/material'
import { useTranslation } from 'react-i18next';
import CustomAutocomplete from '@ui/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { serviceAero } from '@services/aero';
import { serviceDict } from '@services/dictionary';
import { useSelector } from 'react-redux';
import { IDictionary } from '@interfaces/index';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import Breadcrumbs from '@components/Breadcrumbs';

export const AeroCourseList = () => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["aero", "common", "enum"]);
  const navigate = useNavigate();
  const [courseList, setCourseList] = useState<any>(null)
  const [courseName, setCourseName] = useState<string>('')
  const [gradingSystemInitialData, setGradingSystemInitialData] = useState<any>(null)
  const profile = useSelector((store: any) => store.account.profileInfo);
  const [gradingSystemId, setGradingSystemId] = useState<any>(null)
  const [subdivisionList, setSubdivisionList] = useState<IDictionary[]>([]);
  const [subdivisionIds, setSubdivisionIds] = useState<any>([]);
  const { setBreadcrumbs } = useBreadcrumbs();
  const style = {
    '& label.Mui-focused': { color: common.primaryColor },
    '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': { borderColor: common.primaryColor },
      '&.Mui-focused fieldset': { borderColor: common.primaryColor }
    }
  }

  useEffect(() => {
    serviceAero.getCourseList({
      pageNumber: 0,
      size: 100,
      filter: { name: courseName, gradingSystemId: gradingSystemId, subdivisionIds: subdivisionIds }
    }).then((res) => {
      if (res.status === 200) {
        setCourseList(res.data.dtoList)
      }
    })
  }, [courseName, gradingSystemId, subdivisionIds])

  useEffect(() => {
    if (profile.authorityInfo.orgId) {
      serviceDict.getGradingSystemList(profile.authorityInfo.orgId).then((res) => {
        if (res.status === 200) setGradingSystemInitialData({ gradings: res.data });
      });
      serviceDict.getDictionaryListByOrgIdForDropdown(profile.authorityInfo.orgId, 'SUBDIVISION').then((res) => {
        if (res.status === 200) {
          setSubdivisionList(res.data);
        }
      })
    }
  }, [profile.authorityInfo.orgId])

  function getSubdivisionText(subdivision) {
    let subdivisionText = ''
    subdivision.forEach((acc, index) => {
      return subdivisionText = subdivisionText.concat(index === 0 ? '' : ', ', subdivisionList.filter((division) => division.id === acc)[0]?.nameRu)
    })
    return subdivisionText;
  }

  useEffect(() => {
    setBreadcrumbs("aero_course", [{
      level: 1,
      page: 'settings',
      label: "course:SETTINGS",
      path: '/settings',
      tabNum: null
    },
    {
      level: 2,
      page: 'aeroCourseList',
      label: "aero:ONLINE_PROCTORING",
      path: '/settings/aero',
      tabNum: null
    }])
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: 3 }}>
        <Breadcrumbs id="aero_course" />
      </Box>
      <Typography sx={{ fontSize: "36px", fontWeight: 600, marginBottom: "30px" }}>{t("aero:ONLINE_PROCTORING")}</Typography>
      <Box sx={{ backgroundColor: common.fontWhite, width: "100%", borderRadius: "12px", p: "32px" }}>
        <CustomSearch
          placeholder={t("common:placeholders.SEARCH_BY_NAME")}
          height="44px"
          width='90%'
          onChange={setCourseName}
        />
        <Box display="flex" gap={2} margin="10px 0px">
          <CustomAutocomplete
            name='subdivisionIds'
            sx={{ width: '25%', height: '35px', ...style }}
            options={subdivisionList}
            multiple
            limitTags={1}
            placeholder={t("common:placeholders.CHOOSE_SUBDIVISION")}
            onChange={(name: string, value: IDictionary) => setSubdivisionIds(value)}
          />
          <CustomAutocomplete
            name='gradingSystem'
            sx={{ width: '25%', height: '35px', ...style }}
            options={gradingSystemInitialData?.gradings}
            placeholder={t("common:placeholders.CHOOSE_GRADING_SYSTEM")}
            onChange={(name: string, value: IDictionary) => setGradingSystemId(value)}
          />
        </Box>
        <TableContainer sx={{ mt: '16px', width: "100%" }}>
          <Table>
            <TableHead sx={{ background: common.secondaryColor }}>
              <TableRow>
                <TableCell>{t("aero:COURSE_NAME")}</TableCell>
                <TableCell>{t("enum:SUBDIVISION")}</TableCell>
                <TableCell>{t("aero:COURSE_EVALUATION_SYSTEM")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courseList?.map((course, index) => {
                getSubdivisionText(course.courseSetting.subdivisionIds)
                return (
                  <TableRow key={course.id} hover sx={{ cursor: "pointer" }} onClick={() => navigate(`/settings/aero/course/${course.id}`)}>
                    <TableCell>{course.name}</TableCell>
                    <TableCell>{getSubdivisionText(course.courseSetting.subdivisionIds)}</TableCell>
                    <TableCell>{gradingSystemInitialData?.gradings.filter((grading) => grading.id === course.courseSetting.gradingSystemId)[0].name}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
