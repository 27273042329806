import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import { format } from 'date-fns';
import { useSelector } from "react-redux";

import { serviceCourse } from '@services/course';

export const TaskList = () => {
  const { courseId, themeId } = useParams();
  const { t } = useTranslation(['common', 'course']);
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const [taskList, setTaskList] = useState<Array<any>>([]);
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role } = profile.authorityInfo;

  useEffect(() => {
    if (themeId && courseId) {
      if (role === 'LISTENER') {
        serviceCourse.getAllTasksInTheme(themeId).then((res) => {
          if (res.status === 200) setTaskList(res.data);
        })
      } else {
        serviceCourse.getContentList({ search: '' }, courseId).then((res) => {
          if (res.status === 200) {
            setTaskList(findTasksOfTheme(res.data, themeId))
          }
        })
      }
    }
  }, [themeId, courseId, role]);

  function findTasksOfTheme(array: any, themeId: string) {
    let foundTheme: any = null;

    function findThemeRecursive(items) {
      for (const item of items) {
        if (item.type === 'THEME' && item.id === themeId) {
          foundTheme = item;
          break;
        } else if (item.children && item.children.length > 0) {
          findThemeRecursive(item.children);
        }
      }
    }

    findThemeRecursive(array);

    if (foundTheme) {
      const tasks = foundTheme.children.filter(item => item.type === 'TASK');
      return tasks;
    } else {
      return null;
    }
  }

  return (
    <>
      {taskList.length > 0 &&
        <Box sx={{
          width: '256px',
          maxHeight: '420px',
          borderRadius: '12px',
          borderTop: `solid 12px ${common.primaryColor}`,
          backgroundColor: common.white,
          ml: { xs: 0, md: '26px' },
          py: 1,
          overflowY: "auto",
          '&::-webkit-scrollbar': {
            width: 3,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'lightgray',
            borderRadius: '6px'
          }
        }}>
          <Box sx={{ display: 'flex', ml: '18px', alignItems: 'center' }}>
            <NoteAltOutlinedIcon sx={{ mr: '10px' }} />
            <Typography sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '24px' }}>{t('course:theme.RELEATED_TASKS')}</Typography>
          </Box>
          {taskList.map((task, index) => {
            return <Box sx={{ mt: '12px', mx: '10px' }} key={task.id}>
              <Typography
                sx={{ color: common.primaryColor, fontSize: '14px', fontWeight: 500, mb: '4px', cursor: 'pointer' }}
                onClick={() => new Date(task?.dateOfAccess) <= new Date() && navigate(`/myCourses/current/${courseId}/tasks/${task.id}`)}
              >
                {task.name}
              </Typography>
              <Typography sx={{ color: 'black', fontSize: '14px', fontWeight: 500, mb: '4px' }}>{t('course:theme.TASK_STATUS')}: </Typography>
              <Box sx={{ border: '1px', backgroundColor: '#E8E8E8', borderRadius: '4px', width: '90px', display: 'flex', justifyContent: 'center', alignItems: 'center', mx: '10px' }}>
                <Typography sx={{ border: '1px', backgroundColor: '#E8E8E8', borderRadius: '4px', color: 'black', fontSize: '13px', fontWeight: 400, mb: '4px', lineHeight: '16px' }}>
                  {t('course:theme.ASSIGNED')}
                </Typography>
              </Box>
              {task.dateOfAccess && <Typography sx={{ fontSize: '10px', lineHeight: '12px', color: '#708099', mt: '6px' }}>{t("course:theme.ACCESS_OPENING_DATE")}: {format(new Date(task.dateOfAccess), "dd.MM.yyyy")}</Typography>}
              {task.progress?.dateOfDeadline && <Typography sx={{ fontSize: '10px', lineHeight: '12px', color: '#708099', mt: '4px' }}>{t("course:theme.COMPLETE_TASK_BEFORE")}: {format(new Date(task.progress?.dateOfDeadline), "dd.MM.yyyy")}</Typography>}
              {index !== taskList.length - 1 && <Divider sx={{ margin: "5px 0" }} />}
            </Box>
          })}
        </Box>}
    </>
  )
}
