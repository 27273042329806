import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

import { serviceCourse } from '@services/course';
import { serviceTask } from '@services/task';
import IconHints from '@components/IconHints';
import CustomButton from '@ui/Button';
import { taskInitialDto } from '../../Task/models';
import { ITask } from '@interfaces/index';
import { useSelector } from "react-redux";

export const SelfTestQuiz = () => {
  const { t } = useTranslation(['common', 'course']);
  const { common } = useTheme().palette;
  const [selfTestQuiz, setSelfTestQuiz] = useState<ITask>(taskInitialDto);
  const { classId, themeId } = useParams();
  const navigate = useNavigate();
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role } = profile.authorityInfo;

  useEffect(() => {
    if (themeId) {
      serviceCourse.getSelfTestQuiz(themeId).then((res) => {
        if (res.status === 200) {
          setSelfTestQuiz(res.data);
        }
      })
    }
  }, [themeId]);

  const startQuiz = (taskId: string | null) => {
    if (taskId) {
      serviceTask.startSelfTestQuiz(taskId).then(res => {
        if (res.status === 200) {
          if (res.data.id) navigate(`/myCourses/${classId}/tasks/${selfTestQuiz.id}/selfQuiz/${res.data.id}`);
        }
      })
    }
  }

  return (
    <>
      {selfTestQuiz &&
        <Box
          sx={{
            width: { xs: '100%', sm: '256px' },
            height: 'auto',
            borderRadius: '12px',
            borderTop: 'solid 12px #3B7D79',
            backgroundColor: common.white,
            ml: { xs: 0, md: '26px' },
            py: 1,
            px: 1.5,
            display: 'flex',
            flexDirection: 'column',
            gap: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mx: 0.5 }}>
            <AssignmentTurnedInOutlinedIcon sx={{ mr: '10px' }} />
            <Typography sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '20px' }}>{t('course:theme.TEST_UR_KNOWLEDGE')}</Typography>
            <IconHints text={t('course:hints.SELF_TEST_TASK_IN_INCLUDED_FOR_TOPIC')} />
          </Box>
          <CustomButton
            borderRadius='6px'
            backgroundColor={common.secondaryColor}
            fontColor={common.primaryColor}
            disabled={role !== 'LISTENER'}
            onClick={() => startQuiz(selfTestQuiz.id)}
          >{t('common:actions.UNDERGO')}
          </CustomButton>
        </Box>
      }
    </>
  )
}
