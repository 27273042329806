import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from "react-i18next";

import UnhiddenHints from '@components/UnhiddenHints';
import CustomSearch from '@ui/Search';
import { TypeCode } from '@interfaces/index';
import { TabListCourse } from '@components/common';
import { TabContext } from '@mui/lab';
import List from './List';
import { CT_ValueState } from '.';

const EducatorRestrictedCourse = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "course"]);
  const [courseType, setCourseType] = useState<string>("");
  const [courseStatus, setCourseStatus] = useState<CT_ValueState>({
    edu: null,
    free: null,
  });
  const [courseName, setCourseName] = useState<CT_ValueState>({
    edu: null,
    free: null,
  });

  const searchByName = (searchValue: string) => {
    courseType === TypeCode.EDU
      ? setCourseName({ ...courseName, edu: searchValue })
      : setCourseName({ ...courseName, free: searchValue });
  };


  return (
    <Box>
      <Box sx={{ display: 'flex', mb: '24px' }}>
        <Typography sx={{ cursor: 'pointer' }} onClick={() => navigate('/myCourses')}>{t('course:listener.MY_COURSES')}<ArrowForwardIosIcon sx={{ width: '10px', height: '10px', mt: '3px', mx: '9px' }} /></Typography>
        <Typography sx={{ cursor: 'pointer' }}>{t('course:RESTRICTED_COURSES')}</Typography>
      </Box>
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: { sm: "36px", xs: "28px" },
          lineHeight: "44px",
          alignItems: "center",
          mt: 2,
          mb: 2,
        }}>
        {t('course:RESTRICTED_COURSES')}
      </Typography>
      <Box margin="10px 0">
        <UnhiddenHints text={t('course:hints.EDUCATOR_RESTRICTED_COURSE_HINT')} />
      </Box>
      <Box sx={{ maxWidth: "1880px" }}>
        <TabContext value={courseType}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabListCourse
              onChange={(_, value: string) => setCourseType(value)}
              setCourseType={setCourseType}
            />
          </Box>
        </TabContext>
      </Box>
      <Box>
        <Box sx={{ display: 'flex', width: '100%', p: '24px 0' }}>
          <Box sx={{ width: '80%' }}>
            <CustomSearch placeholder={t('common:placeholders.SEARCH_BY_COURSE_NAME')} onChange={searchByName} />
          </Box>
        </Box>
        <List
          type={courseType}
          courseStatus={courseStatus}
          courseName={courseName}
          limitedAccess
        />
      </Box>
    </Box>
  )
}

export default EducatorRestrictedCourse