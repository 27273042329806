import { ITypeList, TypeCode } from "@interfaces/index";
import { TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "@store/hooks";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  setCourseType?: Dispatch<SetStateAction<string>>;
  onChange?:
    | ((event: React.SyntheticEvent<Element, Event>, value: any) => void)
    | undefined;
}

const TabListCourse: FC<IProps> = memo(({ setCourseType, onChange }) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation();
  const [typeList, setTypeList] = useState<ITypeList[]>([]);
  const getAvailableCourseTypes = useAppSelector(
    (store) => store.dictionary.data?.availableCourseTypes
  );

  const orgId = useAppSelector(
    (store) => store.account.profileInfo.authorityInfo.orgId
  );

  useEffect(() => {
    if (getAvailableCourseTypes && getAvailableCourseTypes.length > 0) {
      setTypeList(
        // if listener doesn't have orgId(0) that show only Free course
        orgId === 0
          ? [
              {
                id: 2,
                value: TypeCode.FREE,
              },
            ]
          : getAvailableCourseTypes
              .map((type) => ({
                id: type === TypeCode.EDU ? 1 : 2,
                value: type,
              }))
              .sort((a, b) => a.id - b.id)
      );
      setCourseType &&
        setCourseType(
          orgId === 0
            ? TypeCode.FREE
            : getAvailableCourseTypes.includes(TypeCode.EDU)
            ? TypeCode.EDU
            : TypeCode.FREE
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAvailableCourseTypes]);

  return (
    <TabList
      onChange={onChange}
      aria-label="course type tabs"
      sx={{
        "& .MuiTab-root.Mui-selected": {
          color: common.primaryColor,
        },
        "& .MuiTabs-indicator": {
          background: common.borderPrimary,
        },
      }}
    >
      {typeList.map((type) => {
        return (
          <Tab
            key={type.id}
            label={t(`enum:${type.value}_COURSES`)}
            value={type.value}
            sx={{ textTransform: "none", color: "#B6B6B6" }}
          />
        );
      })}
    </TabList>
  );
});

export { TabListCourse };
