import React, { useState, useEffect } from 'react'
import { Box, Typography, useTheme } from '@mui/material';
import { Formik, Form as FormikForm, Field } from "formik";
import { initialDto, IOrganization, IOrgType } from "./models";
import { validationSchema } from "./validation";
import { AlertColor } from '@mui/material/Alert';
import { serviceDict } from "@services/dictionary";
import { useParams, useNavigate } from "react-router-dom";
import FormikInput from "@ui/formik/Input";
import FormikSelect from "@ui/formik/Select";
import FormikCheckbox from "@ui/formik/Checkbox";
import { useTranslation } from "react-i18next";
import CustomButton from "@ui/Button";
import FormControl from "@mui/material/FormControl";
import MaskedInput from "@ui/formik/MaskedInput";
import MenuItem from "@mui/material/MenuItem";
import { Select } from "formik-mui";
import { courseTypes } from "@utils/enums";

interface Props {
  setMessage: (message: string) => void;
  setMessageOpen: (isOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
}
const GeneralSettings: React.FC<Props> = ({ setMessage, setMessageOpen, setMessageType }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialData, setInitialData] = useState<IOrganization>(initialDto);
  const { common } = useTheme().palette;
  const { t } = useTranslation(["dictionary", "common", "enum"]);
  const [orgTypeList, setOrgTypeList] = useState<IOrgType[]>([]);

  useEffect(() => {
    serviceDict.getDictListForDropdown("BRANCH_TYPE").then((res) => {
      setOrgTypeList(res.data);
    });
  }, []);

  useEffect(() => {
    if (id !== "new") {
      serviceDict.getDictionary("BRANCH", id).then((res) => {
        if (res.status === 200) setInitialData(res.data);
      });
    };
  }, [id]);

  return (
    <Box sx={{ border: `1px solid ${common.borderSecondary}`, backgroundColor: common.fontWhite, borderRadius: '24px', p: '24px' }}>
      <Formik
        initialValues={initialData}
        enableReinitialize
        validationSchema={validationSchema(t)}
        onSubmit={(values) => {
          serviceDict.createDictionary("BRANCH", values).then((res) => {
            if (res.status === 200) {
              setMessageOpen(true);
              setMessage(id === "new" ? `${t("common:messages.RECORD_ADDED")}` : `${t("common:messages.CHANGES_SAVED")}`);
              setMessageType("success");
              setTimeout(() => {
                setMessageOpen(false);
                navigate("/dict/organizations");
              }, 3000);
            }
          }).catch((err) => {
            if (err.response.status === 400) {
              setMessageOpen(true);
              setMessageType("error");
              err.response.data.message === "ITEM_IS_EXISTS" ?
                setMessage(t("ITEM_IS_EXISTS")) : setMessage(t("common:messages.ERROR"));
              setTimeout(() => {
                setMessageOpen(false);
              }, 10000)
            }
          }).finally(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          });
        }}
      >
        {({ submitForm, dirty, isValid }) => (
          <FormikForm>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: "16px" }}>
              <Box sx={{ width: '48%' }}>
                <FormikInput label={t("NAME_IN_KAZAKH")} name="nameKz" isRequired />
              </Box>
              <Box sx={{ width: '48%' }}>
                <MaskedInput label={t("BIN")} name="bin" type="iin" isRequired />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: '16px' }}>
              <Box sx={{ width: '48%' }}>
                <FormikInput label={t("NAME_IN_RUSSIAN")} name="nameRu" isRequired />
              </Box>
              <Box sx={{ width: '48%', mt: '24px' }}>
                <FormikCheckbox name="isDisabled" label={`${t("enum:NOT_USED")}`} labelPlacement="end" />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: '16px' }}>
              <Box sx={{ width: '48%' }}>
                <FormikInput label={t("NAME_IN_ENGLISH")} name="nameEn" isRequired />
              </Box>
              {/* <Box sx={{ width: '48%', mt: '24px' }}>
                <FormikCheckbox name="hasOwnDomain" label={t("CONNECTING_WITH_YOUR_OWN_DOMAIN_NAME")} labelPlacement="end" />
              </Box> */}
              {/* <Box sx={{ width: '48%' }}>
                <FormikInput label={t("URL_ADDRESS")} name="serverAddress" isRequired />
              </Box> */}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: '16px' }}>
              <Box sx={{ width: '48%', display: 'flex', justifyContent: 'flex-start', mr: '8px' }}>
                <FormControl fullWidth sx={{
                  '& label.Mui-focused': { color: common.primaryColor },
                  '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': { borderColor: common.primaryColor },
                    '&.Mui-focused fieldset': { borderColor: common.primaryColor }
                  }
                }}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ mb: '8px' }}>{t("TYPES_OF_COURSES_AVAILABLE")}</Typography>
                    <Typography sx={{ color: common.errorColor }}>*</Typography>
                  </Box>

                  <Field
                    component={Select}
                    size="small"
                    type="text"
                    name="availableCourseTypes"
                    multiple={true}
                    inputProps={{ name: 'availableCourseTypes' }}
                  >
                    {courseTypes.map((type: { id: string | number, text: string }) => {
                      return (
                        <MenuItem key={type.id} value={type.text}>
                          {t(`enum:${type.text}`)}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: '32px' }}>
              <Box sx={{ width: '48%', display: 'flex', justifyContent: 'flex-start', mr: '8px' }}>
                <FormikSelect label={t("TYPE_OF_ORGANIZATION")} name="branchTypeId" options={orgTypeList} isRequired />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <CustomButton
                width="150px"
                backgroundColor={common.primaryColor}
                borderRadius="6px"
                fontColor={common.fontWhite}
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '130%',
                }}
                disabled={!(isValid && dirty)}
                onClick={submitForm}
              >
                {id === "new" ? `${t("common:actions.ADD")}` : `${t("common:actions.SAVE")}`}
              </CustomButton>
            </Box>
          </FormikForm>
        )}
      </Formik>
    </Box>
  )
}

export default GeneralSettings
