export interface IFilter {
  status?: string | null;
  name?: string | null;
  subdivisionIds?: number[] | null;
  startDate?: Date | string | null;
  endDate?: Date | string | null;
  orgId?: number | null;
  langId?: number | null;
  showSameNamed: boolean;
}

export const initialFilter: IFilter = {
  subdivisionIds: null,
  status: null,
  name: null,
  startDate: null,
  endDate: null,
  showSameNamed: false,
  orgId: null,
};
export interface SubdivisionProps {
  courseId: number;
  id: number;
  nameEn: string;
  nameRu: string;
  nameKz: string;
  isDisabled: boolean;
  isStandard: boolean;
}
export interface ChartProps {
  courseId: number;
  id: number;
  name: string;
}

export enum CertificateStatus {
  ACTIVE = "ACTIVE",
}

export interface ICertificateTemplateDto {
  organizationId: number | null,
  organizationName: string,
  organizationDirectorName: string,
  courseType: string,
  organizationLogoUrl: string,
  organizationSignUrl: string,
  qrCode: string
}
export interface ICertificateData {
    id: string,
    orgId: number | null,
    courseName: string,
    listenerName: string,
    curatorName: string,
    issueDate: string,
    completionDate: string
    number: string,
    baseUrl: string,
    certificateDisplay: string,
    credits: number | null,
    hours: number | null,
    status: CertificateStatus | null,
    courseDuration: string,
    certificateTemplateDto: ICertificateTemplateDto
}

export const initialCertificateTemplateDto: ICertificateTemplateDto = {
  organizationId: null,
  organizationName: '',
  organizationDirectorName: '',
  courseType: '',
  organizationLogoUrl: '',
  organizationSignUrl: '',
  qrCode: ''
}

export const initialCertificateData: ICertificateData = {
  id: '',
  orgId: null,
  courseName: '',
  listenerName: '',
  curatorName: '',
  issueDate: '',
  completionDate: '',
  number: '',
  baseUrl: '',
  certificateDisplay: '',
  credits: null,
  hours: null,
  status: null,
  courseDuration: '',
  certificateTemplateDto: initialCertificateTemplateDto
}