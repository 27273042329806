import { FC, ReactElement } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import { ErrorMessage, Field, useField, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import IconHints from '@components/IconHints';
import { SvgIconProps } from '@mui/material';

interface Props {
  label?: string;
  name: string;
  size?: "small" | "medium" | undefined;
  type?: string;
  disabled?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  handleChange?: (value: any) => void;
  width?: string;
  adornmentIcon?: ReactElement<SvgIconProps> | string;
  borderRadius?: string;
  flexDirection?: string;
  justifyContent?: string;
  min?: number;
  max?: number;
  fontWeight?: number;
  fontWidth?: string;
  fontSize?: string;
  textColor?: string;
  labelEnd?: string
}

const FormikInputNew: FC<Props> = ({
  label,
  name,
  type = "text",
  size = "small",
  disabled = false,
  isRequired = false,
  placeholder,
  adornmentIcon,
  handleChange,
  min = 0,
  max = 9999999999999,
  borderRadius = '4px',
  width = "100%",
  flexDirection = "column",
  fontSize = "16px",
  fontWeight = 400,
  justifyContent = "space-between",
  textColor = "#000",
  fontWidth = "80%",
  labelEnd
}) => {
  const { common } = useTheme().palette;
  const [field] = useField(name); //, meta, helpers
  const formikProps = useFormikContext();

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    if (type === 'number') {
      if ((value >= min && value <= max) || value === "") {
        if (handleChange) handleChange(event);

        formikProps.setFieldValue(name, value);
      }
    } else {
      if (handleChange) handleChange(event);

      formikProps.setFieldValue(name, value);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: flexDirection,
        gap: 1,
        width: "100%",
        justifyContent: justifyContent,
        alignItems: "center"
      }}
    >
      {label &&
        <Box>
          <Typography width={fontWidth} fontWeight={fontWeight} fontSize={fontSize} color={textColor}>
            {label}{isRequired && <span style={{ color: common.errorColor }}>*</span>}
          </Typography>
          {formikProps.errors[name] &&
            <ErrorMessage name={name} />}
        </Box>
      }
      {/* {formikProps.errors[name] &&
        <ErrorMessage name={name} />} */}
      <Field
        onChange={handleInputChange}
        component={TextField}
        size={size}
        type={type}
        variant="outlined"
        color="primary"
        name={name}
        helperText=""
        value={field.value ?? ""}
        onWheel={(e: any) => e.target.blur()}
        placeholder={placeholder}
        InputProps={{
          classes: {
            notchedOutline: common.strokeSecondary,
          },
          endAdornment: adornmentIcon ?
            <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
              {adornmentIcon}
            </InputAdornment> :
            undefined
        }}
        sx={{
          '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: disabled ? common.inputDisabledBg : "transparent"
          },
          '& .MuiFormHelperText-root.Mui-error': {
            display: labelEnd ? "none" : "block"
          },
          overflowY: null,
          '&::-webkit-scrollbar': {
            width: '7px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#E5E0EB',
            borderRadius: '6px'
          },
          '& label.Mui-focused': { color: common.primaryColor },
          '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
          '& .MuiOutlinedInput-root': {
            borderRadius,
            height: null,
            '&:hover fieldset': { borderColor: common.primaryColor },
            '&.Mui-focused fieldset': { borderColor: common.primaryColor }
          },
          width: width
        }}
        disabled={disabled}
      />
      {labelEnd &&
        <Box>
          <Typography width={fontWidth} fontWeight={fontWeight} fontSize={fontSize} color={textColor}>
            {labelEnd}{isRequired && <span style={{ color: common.errorColor }}>*</span>}
          </Typography>
          {formikProps.touched && <Box sx={{ fontSize: "11px", color: common.errorColor }}>
            <ErrorMessage name={name} />
          </Box>}
        </Box>}
    </Box>
  )
}

export default FormikInputNew;