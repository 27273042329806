/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { ChangeEvent, DragEvent } from 'react';
import { ReactComponent as EditSquareIcon } from "@assets/svg_files/editSquare.svg";
import { HintComponent } from "@components/common";
import AddLogoModal from "@components/modals/AddLogoModal/AddLogoModal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  Tooltip,
  styled,
  useTheme,
  AlertColor,
} from "@mui/material";
import Certificate from "@pages/certificate/ui/Certificate/Certificate";
import { useEffect, useRef, useState } from "react";
import { Crop, PixelCrop } from "react-image-crop";
import { useNavigate } from "react-router-dom";
import CanvasHelper from "./CanvasHelper";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import { IProfile, TypeCode } from "@interfaces/index";
import { useSelector } from "react-redux";
import { serviceCourse } from "@services/course";
import { serviceFile } from "@services/file";
import { useTranslation } from "react-i18next";
import Message from '@components/Message';
import { ICertificateData, initialCertificateData } from '@pages/courses/models';
import { useQueries, useQuery } from '@tanstack/react-query';

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CertificateSetting = () => {
  const {t, i18n} = useTranslation(['course', 'common', 'dictionary'])
  const { common } = useTheme().palette;
  const [logoModal, setLogoModal] = useState(false);
  const [logoModalSign, setLogoModalSign] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const imgRefSign = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [cropSign, setCropSign] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [completedCropSign, setCompletedCropSign] = useState<PixelCrop>();
  const [imgSrc, setImgSrc] = useState("");
  const [imgSrcSign, setImgSrcSign] = useState("");
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const previewCanvasRefSign = useRef<HTMLCanvasElement | null>(null);
  const previewCanvasRefHelper = useRef<HTMLCanvasElement | null>(null);
  const previewCanvasRefHelperSign = useRef<HTMLCanvasElement | null>(null);
  const navigate = useNavigate();
  const [checkerLogoElement, setCheckerLogoElement] = useState(false);
  const [checkerLogoElementSign, setCheckerLogoElementSign] = useState(false);
  const [active, setActive] = useState(false);
  const [certificateId, setCertificateId] = useState(null)
  const [courseType, setCourseType] = useState<TypeCode | null>(null)
  const profile: IProfile = useSelector(
    (store: any) => store.account.profileInfo
  );
  const dictionaryData = useSelector((store: any) => store.dictionary.data);
  const [orgLogo, setOrgLogo] = useState<string | null>(null)
  const [orgLogoSign, setOrgLogoSign] = useState<string | null>(null)
  const [logoAvailable, setLogoAvailable] = useState(false)
  const [organizationLogoId, setOrganizationLogoId] = useState<string | null>(null)
  const [organizationSignId, setOrganizationSignId] = useState<string | null>(null)
  const [dragOverLogo, setDragOverLogo] = useState(false);
  const [dragOverSign, setDragOverSign] = useState(false);
  const [dragOverPage, setDragOverPage] = useState(false);
  const [message, setMessage] = useState('')
  const [messageOpen, setMessageOpen] = useState(false)
  const [messageType, setMessageType] = useState<AlertColor>("success")
  const initialCertData: ICertificateData = {
    ...initialCertificateData,
    certificateTemplateDto: {
      ...initialCertificateData.certificateTemplateDto,
      organizationDirectorName: dictionaryData?.directorFullName || initialCertificateData.certificateTemplateDto.organizationDirectorName,
      organizationName: i18n.language === 'ru' ? dictionaryData?.nameRu : i18n.language === 'kz' ? dictionaryData?.nameKz : dictionaryData?.nameEn,
    },
  };

  const saveBtnHandler = () => {
    if(previewCanvasRef.current || previewCanvasRefSign.current) {
      updateCertificateLogo()
    }
  }

  const updateCertificateLogo = async () => {
    const canvasLogo = previewCanvasRef.current;
    const canvasSign = previewCanvasRefSign.current;
  
    if (!canvasLogo && !canvasSign) {
      return;
    }
  
    const uploadImage = async (canvas: HTMLCanvasElement | null): Promise<string | null> => {
      if (!canvas) return null;
      
      return new Promise((resolve, reject) => {
        canvas.toBlob(async (blob) => {
          if (blob) {
            const formData = new FormData();
            formData.append('file', blob);
  
            try {
              const response = await serviceFile.uploadFile(formData, {
                params: {
                  typeCode: '012',
                  temporary: true,
                  confirmed: true,
                },
              });
  
              if (response.status === 200) {
                resolve(response.data.uuid); 
              } else {
                resolve(null);
              }
            } catch (error) {
              reject(error);
            }
          } else {
            resolve(null);
          }
        });
      });
    };

    if(logoAvailable) {
      try {
        let logoId: string | null = null
        let signId: string | null = null
        const certData: any = {
          organizationId: profile.authorityInfo.orgId
        }
        if(canvasLogo) {
          logoId = await uploadImage(canvasLogo);
          certData.organizationLogoId = logoId;
        } else {
          certData.organizationLogoId = organizationLogoId
        }

        if(canvasSign) {
          signId = await uploadImage(canvasSign);
          certData.organizationSignId = signId;
        } else {
          certData.organizationSignId = organizationSignId
        } 

        await serviceCourse.updateOrgCertificate(certData).then((res => {
          res.status === 200 &&
            setActive(true)
            setTimeout(() => {
              setActive(false);
            }, 3000)
            canvasLogo && setOrgLogo(canvasLogo?.toDataURL())
            canvasSign && setOrgLogoSign(canvasSign?.toDataURL())
        }))
      } catch (error) {
      }
    } else {
        try {
          const logoId = await uploadImage(canvasLogo);
          const signId = await uploadImage(canvasSign);
      
          await serviceCourse.createOrgCertificate({
            id: certificateId,
            organizationId: profile.authorityInfo.orgId,
            courseType: courseType,
            organizationLogoId: logoId, 
            organizationSignId: signId, 
            createdAt: new Date(),
          }).then((res => {
            res.status === 200 && 
              setActive(true)
              setTimeout(() => {
                setActive(false);
              }, 3000)
              setLogoAvailable(true)
              setOrgLogo(canvasLogo!.toDataURL())
              setOrgLogoSign(canvasSign!.toDataURL())
          }))
        } catch (error) {
        }
    }
    
  };


  const onSelectFile = (
    e: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
    type: string
  ) => {
    e.preventDefault();
    setDragOverPage(false)
    let file: File | null = null;

    if (e.type === 'change') {
      file = (e as ChangeEvent<HTMLInputElement>).target.files?.[0] || null;
      (e as ChangeEvent<HTMLInputElement>).target.value = ''
    } else if (e.type === 'drop') {
      file = (e as DragEvent<HTMLDivElement>).dataTransfer.files?.[0] || null;
      (e as DragEvent<HTMLDivElement>).dataTransfer.items.clear();
    }

    if (file) {
      const maxSizeInBytes = 10 * 1024 * 1024
      if(file.size > maxSizeInBytes) {
        setDragOverLogo(false)
        setDragOverSign(false)
        setMessage(`${t('course:MAX_FILE_SIZE', {size: 10})} МБ.`)
        setMessageType('error')
        setMessageOpen(true)
        setTimeout(() => {
          setMessageOpen(false)
        }, 5000)
        return
      }

      const reader = new FileReader();

      const handleLoad = (callback: (result: string) => void) => {
        reader.addEventListener("load", () => {
          const result = reader.result?.toString() || "";
          callback(result);
        });
      };

      switch (type) {
        case "logo":
          setLogoModal(true);
          setCrop(undefined);
          setDragOverLogo(false)
          handleLoad(setImgSrc);
          break;
        case "logoSign":
          setLogoModalSign(true);
          setCropSign(undefined);
          setDragOverSign(false)
          handleLoad(setImgSrcSign);
          break;
        default:
          console.error("Invalid type");
          return;
      }

      reader.readAsDataURL(file);
    } else {
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>, type: string) => {
    event.preventDefault();
    type === 'logo' ? setDragOverLogo(true) : setDragOverSign(true)
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    
    const relatedTarget = e.relatedTarget as Node | null;

    if (!e.relatedTarget || !e.currentTarget.contains(relatedTarget)) {
      setDragOverPage(false);
    }
  };
  

  const {data: certificateData, isSuccess: isCertificateLoaded, isError } = useQuery({
    queryKey: ['getOrgCertificate', profile.authorityInfo.orgId],
    queryFn: () => serviceCourse.getOrgCertificate(profile.authorityInfo.orgId),
    retry: 1
  })

  const queryResults = useQueries({
    queries: [
      {
        queryKey: ['orgLogo', certificateData?.data.organizationLogoId],
        queryFn: () => serviceFile.getFileInBytes(certificateData?.data.organizationLogoId),
        enabled: !!certificateData?.data.organizationLogoId
      },
      {
        queryKey: ['orgSign', certificateData?.data.organizationSignId],
        queryFn: () => serviceFile.getFileInBytes(certificateData?.data.organizationSignId),
        enabled: !!certificateData?.data.organizationSignId
      }
    ]
  });

  const [orgLogoQuery, orgLogiSignQuery] = queryResults

  useEffect(() => {
    if (isCertificateLoaded) {
      setCertificateId(certificateData.data.id)
      setCourseType(certificateData.data.courseType)

      setLogoAvailable(!!certificateData.data.organizationLogoId && !!certificateData.data.organizationSignId)
      
      setOrganizationLogoId(certificateData.data.organizationLogoId)
      setOrganizationSignId(certificateData.data.organizationSignId)
    }
  }, [certificateData, isCertificateLoaded])  

  useEffect(() => {
    if(orgLogoQuery.isSuccess) {
      setOrgLogo(orgLogoQuery.data.data.bytes)
    }
    if (orgLogiSignQuery.isSuccess) {
      setOrgLogoSign(orgLogiSignQuery.data.data.bytes)
    }
  }, [orgLogoQuery?.isSuccess, orgLogoQuery?.data, orgLogiSignQuery?.isSuccess, orgLogiSignQuery?.data])
 

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0
        );
      }
    },
    100,
    [completedCrop]
  );

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRefHelper.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRefHelper.current,
          completedCrop,
          1,
          0
        );
      }
    },
    200,
    [completedCrop]
  );

  useDebounceEffect(
    async () => {
      if (
        completedCropSign?.width &&
        completedCropSign?.height &&
        imgRefSign.current &&
        previewCanvasRefSign.current
      ) {
        canvasPreview(
          imgRefSign.current,
          previewCanvasRefSign.current,
          completedCropSign,
          1,
          0
        );
      }
    },
    100,
    [completedCropSign]
  );

  useDebounceEffect(
    async () => {
      if (
        completedCropSign?.width &&
        completedCropSign?.height &&
        imgRefSign.current &&
        previewCanvasRefHelperSign.current
      ) {
        canvasPreview(
          imgRefSign.current,
          previewCanvasRefHelperSign.current,
          completedCropSign,
          1,
          0
        );
      }
    },
    200,
    [completedCropSign]
  );

  return (
    <Stack direction={"column"} gap={4} onDragLeave={handleDragLeave} onDragOver={() => setDragOverPage(true)}>
      <Stack direction={"column"} gap={2}>
        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
          <Typography
            onClick={() => {
              navigate(-1);
            }}
          >
            Настройки
          </Typography>
          <KeyboardArrowRightOutlinedIcon
            sx={{
              width: "16px",
              height: "16px",
            }}
          />
          <Typography>{t('course:CERTIFICATE_SETTINGS')}</Typography>
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant={"subtitle1"}>{t('course:CERTIFICATE_SETTINGS')}</Typography>
          <Tooltip
            title={(!previewCanvasRef.current && !previewCanvasRefSign.current) ? t('common:REQUIRED_FILES_MUST_BE_UPLOAD') : ""}
            disableHoverListener={(!!previewCanvasRef.current && !!previewCanvasRefSign.current)}
          >
            <span>
              <Button
                disabled={active || (!previewCanvasRef.current && !previewCanvasRefSign.current) || 
                  (!organizationLogoId && !previewCanvasRef.current) || (!organizationSignId && !previewCanvasRefSign.current)}
                variant="contained"
                onClick={() => {
                  saveBtnHandler()
                }}
              >
                {t('common:actions.SAVE')}
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </Stack>
      {active && (
        <HintComponent
          backgroundBorder="#FFFFFF"
          text={t('common:messages.CHANGES_SAVED')}
          backgroundColorContent="#00B998"
          leftBorder={true}
          opacityBorderLeft={0.3}
          svg={
            <Box
              width={"28px"}
              height={"28px"}
              sx={{ borderRadius: "50%", background: "#FFFFFF", opacity: 0.8 }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CheckCircleIcon
                sx={{ color: "#00B998", width: "20px", height: "20px" }}
              />
            </Box>
          }
          textColor="#FFFFFF"
        />
      )}
      { messageOpen && <Message type={messageType} message={message} setMessageOpen={setMessageOpen}/>}
      <Grid display={"grid"} gridTemplateColumns={"3fr 1fr"} gap={2}>
        <Box>
          <Certificate
            checkerLogoElement={checkerLogoElement}
            checkerLogoSignElement={checkerLogoElementSign}
            completedCrop={completedCrop}
            completedCropSign={completedCropSign}
            orgLogo={orgLogo}
            orgLogoSign={orgLogoSign}
            certificateData={initialCertData}
            qrCode={' '}
            certificateDisplay={'HOURS'}
            hours={0}
            isLogoAvailable={certificateData?.data.organizationLogoId || !isError}
            isSignAvailable={certificateData?.data.organizationSignId || !isError}
            ref={{
              current: {
                logo: previewCanvasRef,
                logoSign: previewCanvasRefSign,
              },
            }}
          />
        </Box>

        <Stack direction={"column"} gap={2}>
          <Card
            sx={{
              padding: "24px 20px",
              backgroundColor: dragOverLogo ? '#eee' : '',
              border: dragOverPage ? `2px dashed  ${common.borderPrimary}` : ''
            }}
            onDragOver={(e) => handleDragOver(e, 'logo')}
            onDragLeave={() => setDragOverLogo(false)}
            onDrop={e => onSelectFile(e, 'logo')}
          >
            <Stack direction={"column"} gap={2}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "24.6px",
                    fontWeight: 500,
                  }}
                >
                  {t('course:ORGANIZATION_LOGO')}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "15.6px",
                    fontWeight: 400,
                    color: "#6D737A",
                  }}
                >
                  {t('dictionary:IMAGE_UP_TO_10_MB')}
                </Typography>
              </Box>
              {!!completedCrop && (
                <CanvasHelper previewCanvasRef={previewCanvasRefHelper} />
              )}
              <Stack direction={"row"} gap={0.5}>
                <Button
                  fullWidth
                  variant="contained"
                  endIcon={<FileDownloadOutlinedIcon />}
                  tabIndex={-1}
                  component="label"
                  role={undefined}
                >
                  {t('common:actions.UPLOAD')}
                  <VisuallyHiddenInput
                    onChange={(e) => onSelectFile(e, "logo")}
                    type="file"
                    accept=".jpg, .jpeg, .png"
                  />
                </Button>
                {(!!completedCrop && !orgLogo) && (
                  <Button
                    sx={{
                      padding: "0",
                      minWidth: "38px",
                    }}
                    variant="contained"
                    onClick={() => {
                      setLogoModal(true);
                    }}
                  >
                    <EditSquareIcon />
                  </Button>
                )}
              </Stack>
            </Stack>
          </Card>
          <Card
            sx={{
              padding: "24px 20px",
              backgroundColor: dragOverSign ? '#eee' : '#',
              border: dragOverPage ? `2px dashed  ${common.borderPrimary}` : ''
            }}
            onDragOver={(e) => handleDragOver(e, 'sign')}
            onDragLeave={() => setDragOverSign(false)}
            onDrop={e => onSelectFile(e, 'logoSign')}
          >
            <Stack direction={"column"} gap={2}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "24.6px",
                    fontWeight: 500,
                  }}
                >
                  {t('course:MANAGERS_SIGNATURE')}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "15.6px",
                    fontWeight: 400,
                    color: "#6D737A",
                  }}
                >
                  {t('dictionary:PNG_WITH_WHITE_BACKGROUND_UP_TO_10_MB')}
                </Typography>
              </Box>
              {!!completedCropSign && (
                <CanvasHelper previewCanvasRef={previewCanvasRefHelperSign} />
              )}
              <Stack direction={"row"} gap={0.5}>
                <Button
                  fullWidth
                  variant="contained"
                  endIcon={<FileDownloadOutlinedIcon />}
                  tabIndex={-1}
                  component="label"
                  role={undefined}
                >
                  {t('common:actions.UPLOAD')}
                  <VisuallyHiddenInput
                    onChange={(e) => onSelectFile(e, "logoSign")}
                    type="file"
                    accept=".jpg, .jpeg, .png"
                  />
                </Button>
                {(!!completedCropSign && !orgLogoSign) && (
                  <Button
                    sx={{
                      padding: "0",
                      minWidth: "38px",
                    }}
                    variant="contained"
                    onClick={() => {
                      setLogoModalSign(true);
                    }}
                  >
                    <EditSquareIcon />
                  </Button>
                )}
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Grid>
      <AddLogoModal
        key={"logo"}
        onClose={() => setLogoModal(false)}
        open={logoModal}
        setModal={setLogoModal}
        crop={crop}
        setCrop={setCrop}
        setCompletedCrop={setCompletedCrop}
        imgRef={imgRef}
        imgSrc={imgSrc}
        completedCrop={completedCrop}
        previewCanvasRef={previewCanvasRef}
        setCheckerLogoElement={setCheckerLogoElement}
        previewCanvasRefHelper={previewCanvasRefHelper}
        title={t('course:ORGANIZATION_LOGO')}
      />
      <AddLogoModal
        key={"logoSign"}
        onClose={() => setLogoModalSign(false)}
        open={logoModalSign}
        setModal={setLogoModalSign}
        crop={cropSign}
        setCrop={setCropSign}
        setCompletedCrop={setCompletedCropSign}
        imgRef={imgRefSign}
        imgSrc={imgSrcSign}
        completedCrop={completedCropSign}
        previewCanvasRef={previewCanvasRefSign}
        setCheckerLogoElement={setCheckerLogoElementSign}
        previewCanvasRefHelper={previewCanvasRefHelperSign}
        title={t('course:MANAGERS_SIGNATURE')}
      />
    </Stack>
  );
};

export default CertificateSetting;
