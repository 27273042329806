import React, { useContext, useEffect, useState } from 'react'
import { Box, Typography, useTheme, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdditionalMaterialTypes, CourseStatus, IMaterial, ITheme } from '@interfaces/index';
import { serviceCourse } from '@services/course';
import { Formik, Form as FormikForm } from "formik";
import { useParams } from 'react-router-dom';
import { AlertColor } from '@mui/material/Alert';
import { initialDto, initialMaterialDto } from "./models";
import { materialInitialDto } from "../models";
import UploadButton from '@ui/UploadButton';
import { downloadFile, getFileType } from '@utils/functions';
import CustomButton from '@ui/Button';
import { CourseContext } from '@context/CourseContext';

import InsertLinkIcon from '@mui/icons-material/InsertLink';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LinkRounded from '@mui/icons-material/LinkRounded';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { PDFIcon } from '@assets/svg_files/Icons';
import FormikInput from '@ui/formik/Input';
import DeleteMaterialModal from '@components/modals/DeleteModal';
import FileViewerModal from '@components/modals/FileViewerModal';
import EditLinkModal from '@components/modals/EditLinkModal';
import { validationSchemaForLink } from './validation';

interface IProps {
  initialData: ITheme;
  setMessage: (message: string) => void
  setMessageOpen: (isMessageOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void
}

export const AdditionalMaterials = ({ setMessage, setMessageOpen, setMessageType, initialData }: IProps) => {
  const { t } = useTranslation(['common', 'course', 'enum']);
  const [materialList, setMaterialList] = useState<Array<IMaterial>>([]);
  const [tabValue, setTabValue] = useState<string>(AdditionalMaterialTypes.FILE);
  const { courseId, sectionId, themeId } = useParams();
  const { common } = useTheme().palette;
  const [material, setMaterial] = useState<IMaterial>(initialMaterialDto);
  const [openEditLinkModal, setOpenEditLinkModal] = useState(false);
  const [itemId, setItemId] = useState<string | number | null | undefined>(null);
  const [itemType, setItemType] = useState<string | undefined>(undefined);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { courseData } = useContext(CourseContext);
  const [openFileModal, setOpenFileModal] = useState(false);

  const publishedTheme = initialData.status === CourseStatus.PUBLISHED;

  useEffect(() => {
    if (themeId && themeId !== "new") {
      serviceCourse.getThemeMaterials(themeId).then((res) => {
        if (res.status === 200) setMaterialList(res.data)
      })
    } else {
      setMaterialList(initialDto.materials);
    }
  }, [sectionId, themeId]);

  const saveMaterial = (uploadedFileId: string | null, name?: string, url?: string) => {
    let data = materialInitialDto;
    data.themeId = themeId;
    data.fileId = uploadedFileId;
    data.name = name || null;
    data.url = url || null;

    if (data.name && data.name.length > 64) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setMessage(t('course:messages.MAX_LENGTH'));
      setMessageOpen(true);
      setMessageType("error");
      setTimeout(() => {
        setMessageOpen(false);
      }, 10000);
      return;
    }

    updateMaterialList(data);
  }

  const updateMaterialList = (material: IMaterial) => {
    serviceCourse.putThemeMaterial({ ...material, themeId }, courseId || '').then(res => {
      if (res.status === 200) {
        if (material.id) {
          const index = [...materialList].findIndex((x) => x.id === material.id);
          setMaterialList([
            ...materialList.slice(0, index),
            material,
            ...materialList.slice(index + 1),
          ]);
        } else {
          setMaterialList([...materialList, { ...material, ...res.data }]);
        }
        setOpenEditLinkModal(false);
      }
    });
  }
  const handleToggleFileModal = (modalState: boolean, material?: IMaterial) => {
    if (material) setMaterial(material);
    setOpenFileModal(modalState);
  }

  const deleteMaterial = () => {
    serviceCourse.deleteThemeMaterial(itemId || '', courseId || '')
      .then(res => {
        if (res.status === 200) {
          setMaterialList([
            ...materialList.filter((x) => x.id !== itemId)
          ]);
          handleToggleDeleteModal(false);
        }
      })
  }

  const handleToggleDeleteModal = (modalState: boolean, material?: IMaterial) => {
    if (modalState) {
      setItemId(material?.id);
      if (material?.url) {
        setItemType("ITEM");
      } else {
        setItemType("FILE");
      }
    } else {
      setItemId(null);
      setItemType(undefined);
    }
    setDeleteModalOpen(modalState);
  }

  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);
  };

  const handleToggleEditLinkModal = (modalState: boolean, material?: IMaterial) => {
    setMaterial(material || initialMaterialDto);
    setOpenEditLinkModal(modalState);
  }

  return (
    <Box sx={{
      px: 4, pt: 2
    }}>
      <Box sx={{ width: '100%', display: 'flex', gap: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '115px',
            borderRadius: '6px',
            height: '40px',
            p: '12px 16px',
            cursor: 'pointer',
            background: tabValue === AdditionalMaterialTypes.FILE ? common.primaryColor : '#F6F4F8',
            color: tabValue === AdditionalMaterialTypes.FILE ? '#FFFFFF' : '#686B81',
            '&:hover': {
              background: common.secondaryColor,
              color: common.primaryColor
            }
          }}
          onClick={() => handleTabChange(AdditionalMaterialTypes.FILE)}
        >{t('course:theme.FILES')}</Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '115px',
            borderRadius: '6px',
            height: '40px',
            p: '12px 16px',
            cursor: 'pointer',
            background: tabValue === AdditionalMaterialTypes.LINK ? common.primaryColor : '#F6F4F8',
            color: tabValue === AdditionalMaterialTypes.LINK ? '#FFFFFF' : '#686B81',
            '&:hover': {
              background: common.secondaryColor,
              color: common.primaryColor
            }
          }}
          onClick={() => handleTabChange(AdditionalMaterialTypes.LINK)}
        >{t('course:theme.LINKS')}</Box>
      </Box>
      {tabValue === AdditionalMaterialTypes.FILE && <Box sx={{ p: "12px 0 0" }}>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, alignContent: 'center', alignItems: 'center' }}>
          <UploadButton
            label={t('common:actions.ADD_FILE')}
            backgroundColor={common.primaryColor}
            fontColor={common.fontWhite}
            width="172px"
            borderRadius="6px"
            height="28px"
            fontWeight={400}
            disabled={publishedTheme || themeId === "new"}
            typeCode="003"
            attachAndSaveFile={saveMaterial}
            sizeLimit={courseData?.maxFileSizeCanUploadPerTheme}
          />
        </Box>
        <Box>
          {materialList?.length > 0 &&
            <Box sx={{ display: 'block', width: '80%', mt: 2, alignItems: 'center' }}>
              {materialList.filter(x => x.fileId).map((material: IMaterial) => {
                let materialName = material.name || "";
                const ext = materialName.substring(materialName.lastIndexOf("."), materialName.length);
                if (materialName.length > 30) {
                  materialName = `${materialName.substring(0, 27)}...`;
                } else {
                  materialName = materialName.substring(0, materialName.lastIndexOf("."));
                }
                return (
                  <Box
                    key={material.id}
                    sx={{ display: 'flex', width: '100%', gap: 2 }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer', width: '30%' }}
                      onClick={() => handleToggleFileModal(true, material)}
                    >
                      {
                        material.name && getFileType(material.name) === 'pdf' && <PDFIcon />
                      }
                      {
                        material.name && getFileType(material.name) === 'image' && <PhotoOutlinedIcon />
                      }
                      {
                        material.name && getFileType(material.name) === 'audio' && <PlayCircleOutlinedIcon />
                      }
                      <Typography sx={{ width: '90%' }}>
                        {materialName}<span style={{ color: common.primaryColor }}>{ext}</span>
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 2 }}>
                      <FileDownloadOutlinedIcon sx={{ color: '#262626', display: 'flex', cursor: "pointer" }}
                        onClick={() => downloadFile(material.fileId)}
                      />
                      {!publishedTheme && <DeleteOutlineIcon
                        sx={{ color: common.errorColor, display: 'flex', cursor: "pointer" }}
                        onClick={() => handleToggleDeleteModal(true, material)}
                      />}
                      <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                        <Checkbox
                          checked={!material.downloadable || false}
                          onChange={() => updateMaterialList({ ...material, downloadable: !material.downloadable })}
                          sx={{
                            "&, &.Mui-checked": {
                              color: common.primaryColor,
                            },
                            color: common.primaryColor
                          }}
                          disabled={publishedTheme}
                        />
                        <Typography>{t('course:FILE_DOWNLOAD_BAN')}</Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          }
        </Box>
      </Box>}
      {tabValue === AdditionalMaterialTypes.LINK && <Box sx={{ p: "12px 0 0" }}>
        <Formik
          initialValues={{ url: "", name: "" }}
          enableReinitialize
          validationSchema={validationSchemaForLink(t)}
          onSubmit={(values) => {
            saveMaterial(null, values.name, values.url);
            values.name = "";
            values.url = "";
          }}
        >
          {({ submitForm }) => (
            <Box>
              <Box sx={{ display: 'flex', width: { xs: '100%', sm: '50%' }, mt: '8px' }}>
                <FormikInput name="name" label={t('course:content:TITLE_LINK')} disabled={publishedTheme} />
              </Box>
              <Box sx={{ width: { xs: '100%', sm: '50%' }, mt: '8px' }}>
                <FormikInput name="url" label={t('enum:LINK')} placeholder='https://' disabled={publishedTheme} />
              </Box>
              <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: { sm: 'flex-end', xs: 'flex-start' }, width: '50%', mt: '16px' }}>
                <CustomButton
                  backgroundColor={common.primaryColor}
                  fontColor={common.fontWhite}
                  width="182px"
                  borderRadius="6px"
                  height="40px"
                  onClick={submitForm}
                  disabled={publishedTheme || themeId === "new"}
                >
                  <LinkRounded />{t('common:actions.ADD_LINK')}
                </CustomButton>
              </Box>
            </Box>
          )}
        </Formik>
        {materialList?.length > 0 &&
          <Box sx={{ display: 'block', mt: 2, width: '50%', alignItems: 'center' }}>
            {materialList.filter(x => x.url).map((material: IMaterial) => {
              const text = material.name || "";
              const shortText = text.length > 30 ? text.substring(0, 27) + "..." : text;
              return (
                <Box
                  key={material.id}
                  sx={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', width: '100%', mt: '4px' }} >
                  <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <InsertLinkIcon sx={{ width: '20px', height: '20px', mr: '3px' }} />
                    <Typography onClick={() => { window.open(`${material.url}`, "_blank") }}>
                      {shortText}
                    </Typography>
                  </Box>
                  {!publishedTheme && <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <ModeEditOutlineOutlinedIcon
                      sx={{ display: 'flex', alignItems: "end", cursor: "pointer" }}
                      onClick={() => handleToggleEditLinkModal(true, material)}
                    />
                    <DeleteOutlineIcon
                      sx={{ color: common.errorColor, display: 'flex', alignItems: "end", cursor: "pointer" }}
                      onClick={() => handleToggleDeleteModal(true, material)}
                    />
                  </Box>}
                </Box>
              );
            })}
          </Box>
        }
      </Box>}
      {deleteModalOpen &&
        <DeleteMaterialModal
          open={deleteModalOpen}
          handleToggleModal={handleToggleDeleteModal}
          deleteItem={deleteMaterial}
          itemType={itemType}
        />
      }
      {openEditLinkModal &&
        <EditLinkModal
          open={openEditLinkModal}
          handleToggleEditLinkModal={handleToggleEditLinkModal}
          material={material}
          updateMaterialList={updateMaterialList}
        />
      }
      {openFileModal &&
        <FileViewerModal
          openModal={openFileModal}
          handleToggleModal={handleToggleFileModal}
          file={material}
        />
      }
    </Box>
  )
}
