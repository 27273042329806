import React, { FC, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface Props {
  password: string;
  showPassword: boolean;
  handleClickShowPassword: () => void;
  showIcon?: boolean;
}

const PasswordHideByRegex: FC<Props> = ({
  password,
  handleClickShowPassword,
  showPassword,
  showIcon,
}) => {
  const maskPassword = (password) => {
    if (password.length <= 4) {
      return "*".repeat(password.length);
    } else if (password.length <= 6) {
      return password.replace(
        /^(.{1})(.*)(.{1})$/,
        (_, firstThree, middle, lastTwo) => {
          return firstThree + "*".repeat(middle.length) + lastTwo;
        }
      );
    } else {
      return password.replace(
        /^(.{3})(.*)(.{2})$/,
        (_, firstThree, middle, lastTwo) => {
          return firstThree + "*".repeat(middle.length) + lastTwo;
        }
      );
    }
  };

  return (
    <TextField
      sx={{
        border: "none",
        "& .MuiInputBase-input": {
          padding: 0,
          paddingLeft: 2,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": { borderColor: "white" },
          "&:hover fieldset": { borderColor: "white" },
          "&.Mui-focused fieldset": { borderColor: "white" },
        },
      }}
      size="small"
      type={"text"}
      value={showPassword ? password : maskPassword(password)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" >
            {showIcon && (
              <IconButton 
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordHideByRegex;
