import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_TableOptions,
  MRT_RowData
} from "material-react-table";

interface Props<TData extends MRT_RowData> extends MRT_TableOptions<TData> {
  columns: MRT_ColumnDef<TData>[];
  data: TData[];
  error?: string;
  isError?: boolean;
}

const ReactTable = <TData extends MRT_RowData>({
  data,
  columns,
  isError,
  ...rest
}: Props<TData>) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["dictionary", "common", "enum"]);

  const table = useMaterialReactTable({
    columns,
    enableColumnActions: false,
    enableTopToolbar: false,
    manualPagination: true,
    enableTableFooter: false,
    enableRowActions: true,
    positionActionsColumn: 'last',
    data,
    localization: {
      noRecordsToDisplay: t('dictionary:EMPTY'),
      rowsPerPage: t('enum:LINES_PER_PAGE'),
      actions: "",
      rowActions: t('common:ACTIONS'),
      expand: "",
    },
    layoutMode: 'grid',
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        display: 'grid',
        borderRadius: '6px'
      },
    },
    muiTableContainerProps: {
      sx: {
        '&::-webkit-scrollbar': { height: '6px', width: '7px' },
        '&::-webkit-scrollbar-thumb': { backgroundColor: '#D9D9D9', borderRadius: '4px' }
      },
    },
    muiTableHeadCellProps: {
      sx: { backgroundColor: common.tableHeaderColor }
    },
    muiTableBodyCellProps: {
      sx: { backgroundColor: common.white }
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50, 100],
      shape: "rounded",
      variant: "outlined",
      SelectProps: {
        sx: {
          backgroundColor: "#FFFFFF",
          color: common.primaryColor,
          "& .MuiInputBase-root": {
            "& .MuiSelect-icon": { color: common.primaryColor },
          },
        },
      },
      sx: {
        "& .Mui-selected": {
          background: `${common.primaryColor} !important`,
          color: "#FFFFFF",
        },
      },
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: t('dictionary:ACTION'),
        size: 100,
      },
    },
    rowCount: rest.rowCount,
    paginationDisplayMode: "pages",
    ...rest
  });

  return (
    <MaterialReactTable table={table} />
  );
};

export default ReactTable