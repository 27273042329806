import { Box, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  key: React.Key;
  index: number;
  activeStudyClass: number;
  setActiveStudyClass: React.Dispatch<React.SetStateAction<number>>;
}
const FlowItem = (props: Props) => {
  const { index, activeStudyClass, setActiveStudyClass, key } = props;
  const { common } = useTheme().palette;
  const { t } = useTranslation("course");
  return (
    <Box
      key={key}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "40px",
        fontSize: "14px",
        background: activeStudyClass === index ? common.primaryColor : "white",
        padding: "12px 16px",
        borderRadius: "6px",
        width: "94px",
        whiteSpace: "nowrap",
        color: activeStudyClass === index ? "white" : "#8E8E93",
        cursor: "pointer",
        "&:hover": {
          background:
            activeStudyClass === index ? common.primaryColor : "#F5EFFF",
        },
        mt: 2,
      }}
      onClick={() => {
        setActiveStudyClass(index);
      }}
    >
      {t("course:EDU_STREAM")} #{index + 1}
    </Box>
  );
};

export { FlowItem };
