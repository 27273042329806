import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { Formik, Form as FormikForm } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Message from "@components/Message";
import RegisterToCourseModal from "@components/modals/RegisterToCourseModal";
import { IUserFilter, IUserSearchItem } from "@interfaces/index";
import { Divider } from "@mui/material";
import { serviceMember } from "@services/member";
import CustomButton from "@ui/Button";
import FormikAutocomplete from "@ui/formik/Autocomplete";
import { participantRoles } from "@utils/enums";
import { DTO, initialDto, userFilterInitialDto } from "../models";
import { participantValidationSchema } from "../validation";

interface Props {
  modalOpen: boolean;
  handleToggleModal: (modalState: boolean, message?: string) => void;
  tutorIsDisabled: boolean;
  curatorIsDisabled: boolean;
  subdivisionIds: number[];
  eduProgramIds: number[];
  studyClasses?: any;
  refetch: any;
}

const groupEducator = ["TUTOR", "ASSISTANT", "EXAMINER"];

export const AddRegisteredFreeParticipants = ({
  handleToggleModal,
  subdivisionIds,
  eduProgramIds,
  studyClasses,
  refetch,

}) => {
  const { t } = useTranslation(["common", "user", "course"]);
  const { courseId } = useParams();
  const { common } = useTheme().palette;
  const [userFilter, setUserFilter] =
    useState<IUserFilter>(userFilterInitialDto);
  const [userList, setUserList] = useState<IUserSearchItem[]>([]);
  const [disabledOptions, setDisabledOptions] = useState<string[]>([]);
  const [openRegisterToCourseModal, setOpenRegisterToCourseModal] =
    useState<boolean>(false);
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<DTO>(() => {
    if (courseId) return { ...initialDto, courseId };
    return initialDto;
  });

  useEffect(() => {
    if (studyClasses?.id) {
      if (userFilter.roleCode) {
        serviceMember
          .getNonMemberList(studyClasses.id, userFilter)
          .then((res) => {
            if (res.status === 200) setUserList(res.data);
          });
      }
    }
  }, [studyClasses.id, userFilter]);

  const handleRoleChange = (roleType: string, values: DTO) => {
    setInitialData({ ...values, roleType, userIds: [] });
    const roleCode = groupEducator.includes(roleType)
      ? "EDUCATOR"
      : roleType === "CURATOR"
      ? "MANAGER"
      : roleType;
    if (roleCode === "LISTENER") {
      const filter = { ...userFilter, roleCode, eduProgramIds };
      delete filter.subdivisionIds;
      setUserFilter(filter);
    } else {
      const ids = groupEducator.includes(roleType)
        ? subdivisionIds
        : userFilterInitialDto.subdivisionIds;
      const filter = { ...userFilter, roleCode, subdivisionIds: ids };
      delete filter.eduProgramIds;
      setUserFilter(filter);
    }
  };

  const handleInputChange = (keyword: string) => {
    if (keyword.length >= 3) {
      setUserFilter({ ...userFilter, name: keyword });
    } else if (keyword.length === 0) {
      let filter = { ...userFilter };
      delete filter?.name;
      setUserFilter(filter);
    }
  };
  return (
    <Formik
      initialValues={initialData}
      enableReinitialize
      validationSchema={participantValidationSchema(t)}
      onSubmit={(values: DTO) => {
        serviceMember
          .sendInvitationMember({
            ...values,
            classId: studyClasses.id,
            registered: true,
          })
          .then((res) => {
            if (res.status === 200) {
              // updateParticipantList(res.data);
              setOpenRegisterToCourseModal(false);
              handleToggleModal(false, t("course:messages.PARTICIPANTS_ADDED"));
              refetch();
            }
          })
          .catch((error) => {
            if (
              error.response.data.message ===
              "Maximum number of students exceeded"
            ) {
              setOpenRegisterToCourseModal(false);
              setMessageOpen(true);
              setTimeout(() => {
                setMessageOpen(false);
              }, 5000);
            }
          });
      }}
    >
      {({ values, submitForm, isValid, dirty }) => (
        <FormikForm>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            {messageOpen && (
              <Message
                type="error"
                message={t(
                  "course:messages.NUMBER_OF_ADD_PARTICIPANTS_EXCEEDS_MAX",
                  {
                    capacity: studyClasses?.capacity,
                  }
                )}
                setMessageOpen={setMessageOpen}
              />
            )}
            <FormikAutocomplete
              label={t("user:ROLE")}
              name="roleType"
              options={participantRoles}
              disabledOptions={disabledOptions}
              clearable={false}
              onChange={(value: any) => handleRoleChange(value.id, values)}
              isRequired
              isInputEnterable={false}
            />
            {values.roleType && (
              <FormikAutocomplete
                selectAll={
                  userList.length > 1 &&
                  values.roleType !== "EDUCATOR" &&
                  values.roleType !== "CURATOR"
                }
                label={t("common:label.SEARCH_FOR_REGITERED_USERS")}
                placeholder={t("common:placeholders.SEARCH")}
                name="userIds"
                options={userList}
                multiple
                maxSelectedItems={
                  values.roleType === "EDUCATOR" ||
                  values.roleType === "CURATOR"
                    ? 1
                    : undefined
                }
                onInputChange={handleInputChange}
                hintText={t("common:placeholders.ENTER_USER_FULL_NAME_OR_EMAIL")}
              />
            )}

            <Divider />
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <CustomButton
                backgroundColor={common.btnCancel}
                fontColor={common.primaryColor}
                borderRadius="6px"
                height="40px"
                sx={{
                  alignItems: "center",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => handleToggleModal(false)}
              >
                {t("actions.CANCEL")}
              </CustomButton>
              <CustomButton
                backgroundColor={common.primaryColor}
                fontColor={common.fontWhite}
                borderRadius="6px"
                height="40px"
                disabled={!(isValid && dirty)}
                onClick={() => setOpenRegisterToCourseModal((prev) => !prev)}
                sx={{
                  alignItems: "center",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {t("actions.ADD")}
              </CustomButton>
            </Box>
          </Box>
          {openRegisterToCourseModal && (
            <RegisterToCourseModal
              openModal={openRegisterToCourseModal}
              message={t(
                "course:messages.SELECTED_USERS_WILL_BE_REGISTERED_FOR_THE_COURSE"
              )}
              handleToggleModal={() => setOpenRegisterToCourseModal(false)}
              registerToCourse={submitForm}
              success={false}
              attention={false}
            />
          )}
        </FormikForm>
      )}
    </Formik>
  );
};
