import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import CustomModal from '@components/Modal';
import { Divider } from '@mui/material';
import { ChooseAuthor } from './ChooseAuthor';
import { SpecifyManuallyAuthor } from './SpecifyManuallyAuthor';
import { useSelector } from 'react-redux';
import { StateSchema } from "@store/StateSchema";


export const AddAuthorModal = ({ modalOpen,
  handleToggleModal, courseInfo, authorList }) => {
  const profile = useSelector((store: StateSchema) => store.account.profileInfo);
  const { t } = useTranslation(['common', "user", "course"]);
  const { common } = useTheme().palette;
  const authorInfo = authorList.filter((author) => author?.userId !== profile.pltaId)[0]
  const [tabValue, setTabValue] = useState(authorInfo?.userInfo ? "manually" : "author");
  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <CustomModal
      open={modalOpen}
      handleToggleModal={handleToggleModal}
      width={550}
    >
      <>
        <Typography
          id="modal-modal-title"
          sx={{
            fontWeight: 500,
            fontSize: { sm: "28px", xs: '20px' },
            lineHeight: "12px",
            color: "#000000",
            display: "flex",
            alignItems: "center",
            mb: "24px",
            width: '100%'
          }}
        >
          {t("course:CHOOSE_AUTHOR_COURSE")}
        </Typography>
        <Divider />
        <TabContext value={tabValue}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                padding: "10px 0 0 10px",
              }}
            >
              <Tabs
                onChange={(_, value: string) => handleTabChange(value)}
                indicatorColor="secondary"
                value={tabValue}
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: "#1A141F",
                  },
                  "& .MuiTabs-indicator": {
                    background: common.borderPrimary,
                  },
                }}
              >
                <Tab
                  label={t("common:actions.CHOOSE_AUTHOR")}
                  value={"author"}
                  sx={{ textTransform: "none", fontSize: "18px" }}
                />
                <Tab
                  label={t("common:actions.SPECIFY_MANUALLY")}
                  value={"manually"}
                  sx={{ textTransform: "none", fontSize: "18px" }}
                />
              </Tabs>
            </Box>
          </Box>
          <TabPanel value={tabValue}>
            {tabValue === "author" && <ChooseAuthor courseInfo={courseInfo} handleToggleModal={handleToggleModal} />}
            {tabValue === "manually" && <SpecifyManuallyAuthor courseInfo={courseInfo} authorInfo={authorInfo} handleToggleModal={handleToggleModal} />}
          </TabPanel>
        </TabContext>
      </>
    </CustomModal>
  )
}
