/**
* Проект тестирования
*
* @export
* @interface QuizDraft
*/
export interface QuizDraft {

  /**
   * @type {string}
   * @memberof QuizDraft
   */
  id?: string;

  /**
   * Наименование теста
   *
   * @type {string}
   * @memberof QuizDraft
   */
  name: string | null;

  /**
   * Язык теста
   *
   * @type {number}
   * @memberof QuizDraft
   */
  langId: number | null;

  /**
   * Автор теста (user id)
   *
   * @type {string}
   * @memberof QuizDraft
   */
  authorId?: string;

  /**
   * Автор теста (ФИО)
   *
   * @type {string}
   * @memberof QuizDraft
   */
  authorName?: string;

  /**
   * Выбранная дисциплина
   *
   * @type {Array<number>}
   * @memberof QuizDraft
   */
  subdivisionIds: Array<number>;

  /**
   * Идентификатор файла
   *
   * @type {string}
   * @memberof QuizDraft
   */
  fileId: string | null;

  /**
   * Дата создания
   *
   * @type {Date}
   * @memberof QuizDraft
   */
  createdAt?: Date;

  /**
   * Дата изменения
   *
   * @type {Date}
   * @memberof QuizDraft
   */
  updatedAt?: Date;

  /**
   * @type {boolean}
   * @memberof QuizDraft
   */
  isDisabled?: boolean;
}

export const initialDto: QuizDraft = {
  name: null,
  langId: null,
  subdivisionIds: [],
  fileId: null
}

/**
 * 
 *
 * @export
 * @interface QuizDraftFilter
 */
export interface QuizDraftFilter {

  /**
   * @type {string}
   * @memberof QuizDraftFilter
   */
  name?: string;

  /**
   * @type {string}
   * @memberof QuizDraftFilter
   */
  authorId?: string;

  /**
   * @type {number}
   * @memberof QuizDraftFilter
   */
  langId?: number;

  /**
   * @type {Array<number>}
   * @memberof QuizDraftFilter
   */
  subdivisionIds?: Array<number>;

  /**
   * Дата создания начало
   *
   * @type {Date}
   * @memberof QuizDraftFilter
   */
  createdStartDate?: Date;

  /**
   * Дата создания конец
   *
   * @type {Date}
   * @memberof QuizDraftFilter
   */
  createdEndDate?: Date;

  /**
   * Дата изменения начало
   *
   * @type {Date}
   * @memberof QuizDraftFilter
   */
  updatedStartDate?: Date;

  /**
   * Дата изменения конец
   *
   * @type {Date}
   * @memberof QuizDraftFilter
   */
  updatedEndDate?: Date;

  /**
   * @type {boolean}
   * @memberof QuizDraftFilter
   */
  isDisabled?: boolean;
}

/**
 * 
 *
 * @export
 * @interface QuizDraftSorting
 */
export interface QuizDraftSorting {

  /**
   * @type {string}
   * @memberof QuizDraftSorting
   */
  name?: SortingTypeEnum;

  /**
   * Дата создания
   *
   * @type {string}
   * @memberof QuizDraftSorting
   */
  createdAt?: SortingTypeEnum;

  /**
   * Дата изменения
   *
   * @type {string}
   * @memberof QuizDraftSorting
   */
  updatedAt?: SortingTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SortingTypeEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}