import * as yup from "yup";
export const validationSchema = (t: any) =>
  yup.object({
    maxFileSizeCanUploadPerTheme: yup
      .number()
      .positive()
      .max(50000, t("common:validation.MAX_VALUE_IS", { value: 50000 }))
      .required(t("common:validation.MANDATORY_FIELD")),
    maxFileSizeCanTutorUploadPerTask: yup
      .number()
      .positive()
      .max(50000, t("common:validation.MAX_VALUE_IS", { value: 50000 }))
      .required(t("common:validation.MANDATORY_FIELD")),
    maxPromoVideoSize: yup
      .number()
      .positive()
      .max(716800, t("common:validation.MAX_VALUE_IS", { value: 716800 }))
      .required(t("common:validation.MANDATORY_FIELD")),
    maxThemeVideoSize: yup
      .number()
      .positive()
      .max(716800, t("common:validation.MAX_VALUE_IS", { value: 716800 }))
      .required(t("common:validation.MANDATORY_FIELD")),
    useProctoring: yup.boolean(),
    violation: yup
      .object()
      .when("useProctoring", {
        is: true,
        then: yup
          .object()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      })
      .shape({
        faceRecNoStudent: yup.string().when("useProctoring", {
          is: true,
          then: yup.string().required(t("common:validation.MANDATORY_FIELD")),
        }),
        faceRecNotRecognized: yup.string().when("useProctoring", {
          is: true,
          then: yup.string().required(t("common:validation.MANDATORY_FIELD")),
        }),
        faceRecTooManyPeople: yup.string().when("useProctoring", {
          is: true,
          then: yup.string().required(t("common:validation.MANDATORY_FIELD")),
        }),
        faceRecLookAway: yup.string().when("useProctoring", {
          is: true,
          then: yup.string().required(t("common:validation.MANDATORY_FIELD")),
        }),
        screenBrowserFocus: yup.string().when("useProctoring", {
          is: true,
          then: yup.string().required(t("common:validation.MANDATORY_FIELD")),
        }),
        audio: yup.string().when("useProctoring", {
          is: true,
          then: yup.string().required(t("common:validation.MANDATORY_FIELD")),
        }),
        clipboardCopy: yup.string().when("useProctoring", {
          is: true,
          then: yup.string().required(t("common:validation.MANDATORY_FIELD")),
        }),
        clipboardPaste: yup.string().when("useProctoring", {
          is: true,
          then: yup.string().required(t("common:validation.MANDATORY_FIELD")),
        }),
        internetConnectionOffline: yup.string().when("useProctoring", {
          is: true,
          then: yup.string().required(t("common:validation.MANDATORY_FIELD")),
        }),
        deviceAccessWebcam: yup.string().when("useProctoring", {
          is: true,
          then: yup.string().required(t("common:validation.MANDATORY_FIELD")),
        }),
        deviceAccessMicrophone: yup.string().when("useProctoring", {
          is: true,
          then: yup.string().required(t("common:validation.MANDATORY_FIELD")),
        }),
        deviceAccessScreen: yup.string().when("useProctoring", {
          is: true,
          then: yup.string().required(t("common:validation.MANDATORY_FIELD")),
        }),
        tabsForbiddenTab: yup.string().when("useProctoring", {
          is: true,
          then: yup.string().required(t("common:validation.MANDATORY_FIELD")),
        }),
      }),
  });

export const chartValidationSchema = (t: any) =>
  yup.object({
    name: yup.string().required(t("common:validation.MANDATORY_FIELD")),
    startDate: yup
      .date()
      .required(t("common:validation.MANDATORY_FIELD"))
      .test(
        "startDate",
        t("common:validation.START_DATE_HAS_TO_BE_EARLIER_THAN_END_DATE"),
        function (startDate) {
          const endDate = this.parent.endDate;
          // if both values are null, don't return error
          if (!startDate || !endDate) {
            return true;
          }
          return startDate <= endDate;
        }
      ),
    endDate: yup.date().required(t("common:validation.MANDATORY_FIELD")),
    regStartDate: yup
      .date()
      .nullable()
      .test(
        "regStartDate",
        t("common:validation.START_DATE_HAS_TO_BE_EARLIER_THAN_END_DATE"),
        function (regStartDate) {
          const regEndDate = this.parent.regEndDate;
          if (!regStartDate || !regEndDate) {
            return true;
          }
          return regStartDate <= regEndDate;
        }
      ),
    regEndDate: yup
      .date()
      .nullable()
      .test(
        "startDate",
        t("common:validation.REG_PERIOD_IS_EARLIER"),
        function (regEndDate) {
          const startDate = this.parent.startDate;
          if (!regEndDate || !startDate) {
            return true;
          }
          return startDate >= regEndDate;
        }
      ),
  });

export const dictValidationSchema = (t: any) =>
  yup.object({
    nameKz: yup.string().required(t("common:validation.MANDATORY_FIELD")),
    nameRu: yup.string().required(t("common:validation.MANDATORY_FIELD")),
    nameEn: yup.string().required(t("common:validation.MANDATORY_FIELD")),
  });

export const classTypeValidationSchema = (t: any) =>
  yup.object({
    nameKz: yup.string().required(t("common:validation.MANDATORY_FIELD")),
    nameRu: yup.string().required(t("common:validation.MANDATORY_FIELD")),
    nameEn: yup.string().required(t("common:validation.MANDATORY_FIELD")),
    shortNameKz: yup.string().required(t("common:validation.MANDATORY_FIELD")),
    shortNameRu: yup.string().required(t("common:validation.MANDATORY_FIELD")),
    shortNameEn: yup.string().required(t("common:validation.MANDATORY_FIELD")),
  });

export const AboutOrganizationValidationSchema = (t: any) =>
  yup.object({
    nameKz: yup.string().required(t("common:validation.MANDATORY_FIELD")),
    nameRu: yup.string().required(t("common:validation.MANDATORY_FIELD")),
    nameEn: yup.string().required(t("common:validation.MANDATORY_FIELD")),
    branchTypeId: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
  });
