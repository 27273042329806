import { useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { Box, Checkbox, Typography, useTheme } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';

import { serviceTask } from '@services/task';
import { StateSchema } from "@store/StateSchema";
import CustomButton from '@ui/Button';
import QuestionsCard from './QuestionsCard';
import { QuizQuestion, QuizQuestionTypeEnum } from '@models/quiz-question';
import { questionInitialDto } from '../models';
import { useTranslation } from 'react-i18next';
import { Role } from '@interfaces/index';
// import { CourseContext } from "@context/CourseContext";
// import { CourseStatus } from '@interfaces/index';

interface IQuestionsProps {
  quizId: string;
  taskStatus: string;
  isDraft?: boolean;
}

export interface IQuestionsFormikContext {
  data: QuizQuestion[]
}

const Questions = ({ quizId, taskStatus, isDraft = false }: IQuestionsProps) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course", "enum"]);
  const profile = useSelector((store: StateSchema) => store.account.profileInfo);
  const { role } = profile.authorityInfo;
  const [questions, setQuestions] = useState<QuizQuestion[]>([]);
  const [questionsCopy, setQuestionsCopy] = useState<QuizQuestion[]>([]);
  const [isQuizReview, setIsQuizReview] = useState(true)
  // const { courseData } = useContext(CourseContext);
  // const isAuthor = courseData?.userId === courseData?.createdBy;

  useEffect(() => {
    if (quizId) {
      if (isDraft) {
        serviceTask.getQuizListByDraftId(quizId).then(res => {
          if (res.status === 200) setQuestions(res.data);
        })
      } else {
        serviceTask.getAllQuizList(quizId).then(res => {
          if (res.status === 200) setQuestions(res.data);
        })
      }
    }
  }, [quizId, isDraft])

  const questionsInfo = useMemo(() => {
    const data = [...questions].filter(x => x.id)
    return {
      totalCount: data.length,
      oneCorrectAnswer: data.filter(x => x.type === QuizQuestionTypeEnum.ONECORRECTANSWER).length,
      multipleCorrectAnswer: data.filter(x => x.type === QuizQuestionTypeEnum.MULTIPLECORRECTANSWERS).length,
      mapping: data.filter(x => x.type === QuizQuestionTypeEnum.MAPPING).length,
      sequence: data.filter(x => x.type === QuizQuestionTypeEnum.SEQUENCE).length,
      trueOrFalse: data.filter(x => x.type === QuizQuestionTypeEnum.TRUEORFALSE).length,
    }
  }, [questions])

  return (
    <Box>
      <Box sx={{ mb: '24px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: '16px', ml: '-12px' }}>
          <Checkbox checked={isQuizReview} onClick={() => setIsQuizReview(x => !x)} color="secondary" sx={{ mr: '15px', color: common.primaryColor }} />
          <Typography>
            {t('course:test.TEST_REVIEW')}
          </Typography>
        </Box>
        {
          isQuizReview &&
          <Box sx={{
            background: common.rootBgSecondary, border: "1px solid #C7C7C7", borderRadius: "12px", py: "18px", px: "24px"
          }}>
            <Typography sx={{ mb: '16px', fontWeight: 500, fontSize: '20px', lineHeight: '21px' }}>{t('course:test.NUMBER_OF_QUESTIONS_PER_TASK')}: {questionsInfo.totalCount || ""}</Typography>
            {
              questionsInfo.oneCorrectAnswer !== 0 &&
              <Typography>{t('enum:ONE_CORRECT_ANSWER')}: {questionsInfo.oneCorrectAnswer}</Typography>
            }
            {
              questionsInfo.multipleCorrectAnswer !== 0 &&
              <Typography>{t('enum:MULTIPLE_CORRECT_ANSWERS')}: {questionsInfo.multipleCorrectAnswer}</Typography>
            }
            {
              questionsInfo.mapping !== 0 &&
              <Typography>{t('enum:MAPPING')}: {questionsInfo.mapping}</Typography>
            }
            {
              questionsInfo.sequence !== 0 &&
              <Typography>{t('enum:SEQUENCE')}: {questionsInfo.sequence}</Typography>
            }
            {
              questionsInfo.trueOrFalse !== 0 &&
              <Typography>{t('enum:TRUE_OR_FALSE')}: {questionsInfo.trueOrFalse}</Typography>
            }
          </Box>
        }
      </Box>
      <Formik
        initialValues={{ data: questions }}
        enableReinitialize
        onSubmit={() => { }}
      >
        {({ values, setValues }) => {
          return (
            <>
              <Form>
                <FieldArray
                  name="data"
                  render={() => (
                    <>
                      {
                        values.data.map((question, i) => (
                          <QuestionsCard
                            quizId={quizId}
                            index={i}
                            key={i}
                            questions={questions}
                            setQuestions={setQuestions}
                            setQuestionsCopy={setQuestionsCopy}
                            questionsCopy={questionsCopy}
                            questionData={question}
                            taskStatus={taskStatus}
                            isDraft={isDraft}
                          />
                        ))
                      }
                      {(!isDraft && role !== Role.LOCAL_ADMIN) &&
                        <Box sx={{ borderRadius: '6px', border: `1px solid ${common.borderPrimary}`, width: '160px' }}>
                          <CustomButton
                            // disabled={!isAuthor || taskStatus === CourseStatus.PUBLISHED}
                            onClick={() => setValues({ data: [...values.data, { ...questionInitialDto() }] })}
                            borderRadius="6px"
                            backgroundColor={'#F1EAFF'}
                            fontColor={common.primaryColor}
                            sx={{ width: '100%', fontWeight: 500 }}
                          >
                            {t('course:test.ADD_QUESTION')}
                          </CustomButton>
                        </Box>
                      }
                    </>
                  )}
                />
              </Form>
            </>
          )
        }}
      </Formik>
    </Box>
  )
}

export default Questions