import { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import UnhiddenHints from '@components/UnhiddenHints';
import { serviceCourse } from '@services/course';
import { serviceUser } from '@services/user';
import { serviceFile } from '@services/file';

const LocalSettings = () => {
  const [numberOfCourses, setNumberOfCourses] = useState<number | null>(null)
  const [numberOfListeners, setNumberOfListeners] = useState<number | null>(null)
  const [numberOfTutors, setNumberOfTutors] = useState<number | null>(null)
  const [occupiedSpace, setOccupiedSpace] = useState<number | null>(null)
  const { common } = useTheme().palette;
  const { t } = useTranslation(["sideMenu", "common", "course", "user", "enum", "generalSetting"]);
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { orgId } = profile.authorityInfo;
  const navigate = useNavigate();
  const lang = localStorage.getItem('i18nextLng') || 'kz';
  const styles = {
    p: 2,
    fontSize: '16px',
    lineHeight: '24px',
    ":hover": {
      color: common.primaryColor
    }
  }
  const orgInfoStyle = {
    width: '20%',
    height: '105px',
    p: '20px 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: common.fontWhite,
    flexDirection: 'column',
    border: `1px solid ${common.borderSecondary}`,
    borderRadius: '8px',
    fontFamily: 'Golos',
    textAlign: 'center',
    flex: 1
  }
  useEffect(() => {
    serviceCourse.getSumOfCourses().then((res) => {
      if (res.status === 200) {
        setNumberOfCourses(res.data)
      }
    })
    serviceUser.getNumOfListeners().then((res) => {
      if (res.status === 200) {
        setNumberOfListeners(res.data)
      }
    })
    serviceUser.getNumOfTutors().then((res) => {
      if (res.status === 200) {
        setNumberOfTutors(res.data)
      }
    })
    serviceFile.occupiedFileStorageSpace().then((res) => {
      if (res.status === 200) {
        setOccupiedSpace(res.data)
      }
    })
  }, [])

  return (
    <>
      <Typography sx={{ fontWeight: "500", fontSize: "36px", lineHeight: "44px", mb: "27px", mt: '20px' }}>
        {t('course:SETTINGS')}
      </Typography>
      <UnhiddenHints text={t('common:hints.LOCAL_ADMIN_SETTINGS_HINT')} />
      <Box sx={{ width: '100%', display: 'flex', gap: 1.5, mt: 2 }}>
        <Box sx={orgInfoStyle}>
          <Typography sx={{ fontSize: '16px', color: '#8E8E93' }}>{t('generalSetting:schedule.NUMBER_COURSES')}</Typography>
          <Typography sx={{ fontSize: '18px', color: '#262626', fontWeight: 600 }}>{numberOfCourses}</Typography>
        </Box>
        <Box sx={orgInfoStyle}>
          <Typography sx={{ fontSize: '16px', color: '#8E8E93' }}>{t('enum:TUTORS')}</Typography>
          <Typography sx={{ fontSize: '18px', color: '#262626', fontWeight: 600 }}>{numberOfTutors}</Typography>
        </Box>
        <Box sx={orgInfoStyle}>
          <Typography sx={{ fontSize: '16px', color: '#8E8E93' }}>{t('enum:LISTENERS')}</Typography>
          <Typography sx={{ fontSize: '18px', color: '#262626', fontWeight: 600 }}>{numberOfListeners}</Typography>
        </Box>
        <Box sx={orgInfoStyle}>
          <Typography sx={{ fontSize: '16px', color: '#8E8E93' }}>{t('enum:USED_SPACE_IN_FILE_STORAGE')}</Typography>
          <Typography sx={{ fontSize: '18px', color: '#262626', fontWeight: 600 }}>
            {occupiedSpace} {lang === 'en' ? 'GB' : 'ГБ'}
          </Typography>
        </Box>
      </Box>
      <Box sx={{
        border: `1px solid ${common.borderSecondary}`, backgroundColor: common.fontWhite, borderRadius: '24px', p: '24px', height: 'auto', mt: 2
      }}>
        {/* <Box sx={{ borderTop: `2px solid ${common.borderSecondary}`, borderBottom: `2px solid ${common.borderSecondary}` }}>
          <Typography sx={{ ...styles, color: 'black' }}>
            {t('course:task.NAME')}
          </Typography>
        </Box> */}
        <TableContainer component={Paper} sx={{
          fontSize: '16px', lineHeight: '24px'
        }}>
          <Table aria-label="My Table">
            <TableHead>
              <TableRow>
                <TableCell sx={styles}>
                  <Typography sx={{ cursor: 'pointer' }} onClick={() => { navigate(`/settings/roles/${orgId}`) }}>
                    {t('user:ROLES')}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={styles}>
                  <Typography sx={{ cursor: 'pointer' }} onClick={() => { navigate(`/settings/course-settings/${orgId}`) }}>
                    {t("sideMenu:COMMON_SETTINGS")}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={styles}>
                  <Typography sx={{ cursor: 'pointer' }} onClick={() => { navigate(`/settings/handbook/${orgId}`) }}>
                    {t("sideMenu:DICTIONARY")}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={styles}>
                  <Typography sx={{ cursor: 'pointer' }} onClick={() => { navigate(`/settings/assessment-system/${orgId}`) }}>
                    {t("ASSESSMENT_SYSTEM")}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={styles}>
                  <Typography sx={{ cursor: 'pointer' }} onClick={() => { navigate(`/settings/certificate`) }}>
                    {t('generalSetting:SETTINGS_CERTIFICATES')}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={styles}>
                  <Typography sx={{ cursor: 'pointer' }} onClick={() => { navigate(`/settings/charts/${orgId}`) }}>
                    {t('generalSetting:schedule.SCHEDULES')}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={styles}>
                  <Typography sx={{ cursor: 'pointer' }} onClick={() => { navigate(`/settings/integrations/${orgId}`) }}>
                    {t("INTEGRATIONS")}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={styles}>
                  <Typography sx={{ cursor: 'pointer' }}
                    onClick={() => { navigate(`/settings/aero`) }}
                  >
                    {t('generalSetting:REPORT_ONLINE_AERO')}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Box >
    </>

  )
}

export default LocalSettings
