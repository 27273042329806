import React from "react";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { useTheme } from '@mui/material/styles';
import { Box, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface Props extends OutlinedInputProps {
  backgroundColor?: string;
  borderRadius?: string;
  width?: string;
  height?: string;
  onChange?: any;
}

interface State {
  value: string;
}

const CustomSearch: React.FC<Props> = ({
  backgroundColor = "white",
  borderRadius = "6px",
  width = "100%",
  height = "40px",
  placeholder,
  onChange
}: Props) => {
  const [values, setValues] = React.useState<State>({ value: "" });
  const { common } = useTheme().palette;

  const inputStyles = {
    backgroundColor,
    borderRadius,
    width,
    height,
  };

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
      if (onChange) onChange(event.target.value);
    };

  return (
    <FormControl sx={{ width }}>
      <TextField
        id="outlined-basic"
        variant="outlined"
        placeholder={placeholder}
        size="small"
        // value={value}
        onChange={handleChange("value")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px', mr: '-14px', borderRadius: '4px' }}>
                <SearchIcon sx={{ color: '#6D737A', width: '26px', height: '26px' }} />
              </Box>
            </InputAdornment>
          ),
        }}
        sx={{
          ...inputStyles,
          pr: 0,
          '& label.Mui-focused': { color: common.primaryColor },
          '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': { borderColor: common.primaryColor },
            '&.Mui-focused fieldset': { borderColor: common.primaryColor }
          }
        }}
      />
    </FormControl>
  )
}

export default CustomSearch;