import { useState } from "react";
import CustomButton from "@ui/Button";
import { Box, Typography, useTheme } from "@mui/material";
import { CoursePicture } from "./CoursePicture";
import { serviceCourse } from "@services/course";
import YesOrNoModal from "@components/modals/YesOrNoModal";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { memberControllerApi } from "@services/index";

interface MutationProps {
  classId: string;
  confirmed: boolean;
}

export const MemberConfirmation = ({ memberConfirmation }) => {
  const { common } = useTheme().palette;
  const [openMemberModal, setOpenMemberModal] = useState<boolean>(false);
  const { t } = useTranslation(["course", "enum", "common"]);
  const [classId, setClassId] = useState<string | null>(null);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleChangeStatus = useMutation({
    mutationFn: (status: MutationProps) =>
      memberControllerApi.updateConfirmationMember(
        status.classId,
        status.confirmed
      ),
    onSuccess: () => {
      setOpenMemberModal(!openMemberModal);
      queryClient.invalidateQueries({ queryKey: ["memberConfirmation"] });
    },
  });
  return (
    <>
      {memberConfirmation.map((course: any) => {
        return (
          <Box
            key={course.courseId}
            display="flex"
            justifyContent="space-between"
            marginBottom="16px"
          >
            <Box display="flex" gap={1.5}>
              <CoursePicture courseId={course.courseId} />
              <Box>
                <Typography fontSize="16px" fontWeight={600}>
                  {course.courseName}
                </Typography>
                <Typography fontSize="14px" color="#8E8E93">
                  {t("course:HAVE_BEEN_INVITED", {
                    courseName: course.courseName,
                    classRole: t(`common:${course.classRole}_INVITE`),
                  })}{" "}
                </Typography>
                {course.authors.map((author: any) => {
                  return (
                    <Typography
                      key={author.userId}
                      fontSize="14px"
                      color="#8E8E93"
                    >
                      {t("course:AUTHOR_COURSE_USERNAME", {
                        userName: author.username,
                      })}
                    </Typography>
                  );
                })}
              </Box>
              {/* {course} */}
            </Box>
            <Box display="flex" gap={1}>
              <CustomButton
                backgroundColor={common.fontWhite}
                fontColor={common.primaryColor}
                onClick={() => {
                  setClassId(course.classId);
                  setConfirmed(false);
                  setOpenMemberModal(true);
                }}
                borderRadius="4px"
                height="34px"
              >
                {t("common:actions.REJECT")}
              </CustomButton>
              <CustomButton
                onClick={() => {
                  setClassId(course.classId);
                  setConfirmed(true);
                  setOpenMemberModal(true);
                }}
                borderRadius="4px"
                sx={{
                  padding:"4px 16px",
                }}
                height="34px"
              >
                {t("common:actions.ACCEPT")}
              </CustomButton>
            </Box>
          </Box>
        );
      })}
      {openMemberModal && (
        <YesOrNoModal
          openModal={openMemberModal}
          handleToggleModal={() => setOpenMemberModal((prev) => !prev)}
          confirmAction={() =>
            handleChangeStatus.mutate({
              classId: classId ?? "",
              confirmed,
            })
          }
          success={false}
          attention={true}
          message={
            confirmed
              ? t("course:messages.ARE_YOU_SURE_ACCEPT_INVITATION")
              : t("course:messages.ARE_YOU_SURE_REJECT_INVITATION")
          }
        />
      )}
    </>
  );
};
