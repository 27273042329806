import { DictionaryCode, TypeCode } from "./../../interfaces/index";
import { IDictionary } from "@interfaces/index";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ThunkConfig } from "@store/StateSchema";
import * as types from "./types";
import { DictionarySchema } from "./dictionarySchema";

export const dictionaryState: DictionarySchema = {
  dictionaryList: [] as Array<IDictionary>,
  dictionaryListPending: false,
  isLoading: false,
  error: undefined,
  data: undefined,
};

export const getDictionaryList = createAsyncThunk<
  IDictionary,
  { code: DictionaryCode; id: string },
  ThunkConfig<string>
>("dictionary/dictionaryList", async (arg, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  if (!arg) {
    throw new Error("");
  }

  try {
    const response = await extra.api.get<IDictionary>(
      `/api/dictionary/common/${arg.code}/${arg.id}`
    );

    if (!response.data) {
      throw new Error();
    }
    if (
      response.data.availableCourseTypes.includes(TypeCode.FREE) &&
      response.data.availableCourseTypes.includes(TypeCode.EDU)&&
      response.data.availableCourseTypes.length === 2 &&
      response.data.availableCourseTypes[0] === TypeCode.FREE
    ) {
      const indexFree = response.data.availableCourseTypes.indexOf(
        TypeCode.FREE
      );
      const indexEdu = response.data.availableCourseTypes.indexOf(TypeCode.EDU);

      [
        response.data.availableCourseTypes[indexFree],
        response.data.availableCourseTypes[indexEdu],
      ] = [
        response.data.availableCourseTypes[indexEdu],
        response.data.availableCourseTypes[indexFree],
      ];
    }
    return response.data;
  } catch (e) {
    return rejectWithValue("error");
  }
});

export const dictionarySlice = createSlice({
  name: "dictionary",
  initialState: dictionaryState,
  reducers: {
    setData: (state, action: PayloadAction<IDictionary>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(types.dictionary("GET_DICTIONARY_LIST").PENDING, (state) => {
        state.dictionaryListPending = true;
      })
      .addCase(
        types.dictionary("GET_DICTIONARY_LIST").SUCCESS,
        (state, action: PayloadAction<IDictionary[]>) => {
          state.dictionaryListPending = false;
          state.dictionaryList = action.payload;
        }
      )
      .addCase("RESET_STATE", () => dictionaryState)
      .addCase(getDictionaryList.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(
        getDictionaryList.fulfilled,
        (state, action: PayloadAction<IDictionary>) => {
          state.isLoading = false;
          state.data = action.payload;
        }
      )
      .addCase(getDictionaryList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { actions: dictionaryActions } = dictionarySlice;
export const { reducer: dictionaryReducer } = dictionarySlice;

const reducers = (state = dictionaryState, action: any) => {
  switch (action.type) {
    case types.dictionary("GET_DICTIONARY_LIST").PENDING:
      return { ...state, dictionaryListPending: true };
    case types.dictionary("GET_DICTIONARY_LIST").SUCCESS:
      return {
        ...state,
        dictionaryList: action.payload,
        dictionaryListPending: false,
      };
    case "RESET_STATE":
      return { ...dictionaryState };
    default:
      return state;
  }
};

export default reducers;
