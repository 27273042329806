import { InvitationDto } from "@interfaces/invitation";
import API from "./api";

export class CourseControllerApi extends API {
  private readonly url = "/course/info";

  getConfirmationMember(): Promise<InvitationDto[]> {
    return this.get(`${this.url}/on-member-confirmation`)
  }
  getConfirmationAuthor(): Promise<InvitationDto[]> {
    return this.get(`${this.url}/on-author-confirmation`)
  }
  getHasLimitedCourse(): Promise<boolean> {
    return this.get(`${this.url}/has-limited`)
  }
}