import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme, useMediaQuery, Grid } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Pagination from '@mui/material/Pagination';
import { useTranslation } from "react-i18next";

import { serviceCourse } from '@services/course';
import UnhiddenHints from '@components/UnhiddenHints';
import CustomSearch from '@ui/Search';
import CourseCard from './CourseCard';
import { IMyCourseListener } from '@interfaces/index';

export const RestrictedCourse = () => {
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const [restrictedCourseList, setRestrictedCourseList] = useState<IMyCourseListener>();
  const [filter, setFilter] = useState<any>({ name: "" });
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const matchesLg = useMediaQuery(useTheme().breakpoints.up('lg'));
  const coursesPerPage = matchesLg ? 9 : 8;
  const { t } = useTranslation(["common", "course"]);

  useEffect(() => {
    const paginationConfig = {
      pageNumber: currentPage - 1,
      size: coursesPerPage,
      filter: filter,
      sorting: {}
    }
    serviceCourse.getRestrictedCourses(paginationConfig).then((res) => {
      setRestrictedCourseList(res.data);
      setCount(Math.ceil(res.data.totalElements / coursesPerPage))
    });
  }, [filter, coursesPerPage, currentPage]);

  const searchByName = (searchValue: string) => {
    setFilter({ name: searchValue });
  };

  const handleChange = (event: React.ChangeEvent<any>, value: number) => {
    setCurrentPage(value);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: '24px' }}>
        <Typography sx={{ cursor: 'pointer' }} onClick={() => navigate('/myCourses')}>{t('course:listener.MY_COURSES')}<ArrowForwardIosIcon sx={{ width: '10px', height: '10px', mt: '3px', mx: '9px' }} /></Typography>
        <Typography sx={{ cursor: 'pointer' }}>{t('course:RESTRICTED_COURSES')}</Typography>
      </Box>
      <Typography fontSize="36px" fontWeight={700}>{t('course:RESTRICTED_COURSES')}</Typography>
      <UnhiddenHints text={t('course:hints.RESTRICTED_COURSE_HINT')} />
      <Box
        sx={{
          mt: "20px",
          width: "100%",
          height: 'auto',
          borderRadius: "20px",
          padding: "20px",
          background: common.fontWhite,
        }}
      >
        <Box sx={{ display: 'flex', width: '100%', pb: '24px' }}>
          <Box sx={{ width: '80%' }}>
            <CustomSearch placeholder={t('common:placeholders.SEARCH_BY_COURSE_NAME')} onChange={searchByName} />
          </Box>

        </Box>
        <Box sx={{ marginBottom: "16px" }} display="flex" gap={1}>
          <Typography >{t('course:FOUND')}: </Typography> <Typography color={common.primaryColor}>{restrictedCourseList?.dtoList.length}</Typography>
        </Box>
        {restrictedCourseList && (
          <Grid container spacing={3}>
            {restrictedCourseList.dtoList.map((courseInfo: any) => {
              return (
                <CourseCard key={courseInfo.id} courseInfo={{
                  ...courseInfo,
                  course: courseInfo
                }} isDisabled={true} />
              );
            })}
          </Grid>)}
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Pagination count={count}
            shape="rounded"
            boundaryCount={3}
            siblingCount={0}
            onChange={handleChange}
            color='secondary'
          />
        </Box>
      </Box>
    </Box>
  )
}
