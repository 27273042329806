import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MaterialReactTable, //import alternative sub-component if we do not want toolbars
  type MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table';

import { serviceCourse } from '@services/course';
import { IStudyClass } from '@interfaces/index';
import { formatDateWithHourMinute } from '@utils/functions';

interface Props {
  courseId: string | null;
}

export const StudyClassList = ({ courseId }: Props) => {
  const { t } = useTranslation(["dictionary", "course"]);
  const [studyClasses, setStudyClasses] = useState<Array<IStudyClass>>([]);

  useEffect(() => {
    async function getStudyClassList() {
      if (courseId) {
        const response = await serviceCourse.getAllStudyClassesByCourseId(courseId);
        setStudyClasses(response.data);
      }
    }

    getStudyClassList();
  }, [courseId]);

  const columns = useMemo<MRT_ColumnDef<IStudyClass>[]>(
    () => [
      {
        accessorFn: (row) => `${formatDateWithHourMinute(row.startDate)} - ${formatDateWithHourMinute(row.endDate)}`,
        id: 'startDate',
        header: t('course:listener.PASS_PERIOD'),
        size: 400,
        Cell: ({ renderedCellValue }) => (
          <span>{renderedCellValue}</span>
        ),
      },
      {
        accessorKey: 'tutorName',
        header: t('course:TEACHERS'),
        size: 500
      },
      {
        accessorFn: (row) => `${row.allStudentCount ? row.allStudentCount : 0} / ${row.capacity}`,
        id: 'capacity',
        header: t('course:LISTENERS'),
        size: 150,
        Cell: ({ renderedCellValue }) => (
          <span>{renderedCellValue}</span>
        ),
      }
    ],
    [t],
  );

  const table = useMaterialReactTable({
    columns,
    data: studyClasses, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableTopToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    enableTableFooter: false,
    enableBottomToolbar: false,
    enableRowNumbers: true,
    localization: {
      noRecordsToDisplay: t('dictionary:EMPTY'),
      rowsPerPage: t('enum:LINES_PER_PAGE'),
      actions: "",
      expand: "",
      rowNumber: t('course:STREAM_NUMBER'),
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default, //change default background color
    }),
    muiTableBodyRowProps: { hover: false },
    muiTableHeadCellProps: {
      sx: {
        borderLeft: '1px solid rgba(81, 81, 81, .5)'
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderLeft: '1px solid rgba(81, 81, 81, .5)',
      },
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        display: 'grid'
      },
    },
    muiTableContainerProps: {
      sx: {
        '&::-webkit-scrollbar': { height: '4px', width: '7px' },
        '&::-webkit-scrollbar-thumb': { backgroundColor: '#D9D9D9', borderRadius: '4px' }
      },
    },
  });

  //using MRT_Table instead of MaterialReactTable if we do not need any of the toolbar components or features
  return <MaterialReactTable table={table} />;
};

export default StudyClassList;
