import React from "react";
import s from "./SearchSuitable.module.scss";
import Img1 from "@assets/images/home/1.png";
import Img2 from "@assets/images/home/2.png";
import Img3 from "@assets/images/home/3.png";
import Img4 from "@assets/images/home/4.png";
import Img5 from "@assets/images/home/5.png";
import Img6 from "@assets/images/home/6.png";
import Img7 from "@assets/images/home/7.png";
import Img8 from "@assets/images/home/8.png";
import Img9 from "@assets/images/home/9.png";
import Img10 from "@assets/images/home/10.png";
import { useQuery } from "@tanstack/react-query";
import { dictionaryControllerApi, homeControllerApi } from "@services/index";
import { DictionaryCode } from "@interfaces/index";
import { useSpring, animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SearchSuitable = () => {
  const { t } = useTranslation(["home"]);
  const navigate = useNavigate();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const styles = useSpring({
    opacity: inView ? 1 : 0.7,
    transform: inView
      ? "translateX(0) scale(1)"
      : "translateX(-2000px) scale(0.7)",
    config: {
      tension: 500,
      friction: 50,
    },
  });

  const { data: topic } = useQuery({
    queryKey: ["findPopularTopics"],
    queryFn: () => homeControllerApi.findPopularTopics(),
  });
  const { data: category } = useQuery({
    queryKey: ["getDictionaryListByCodePublic"],
    queryFn: () =>
      dictionaryControllerApi.getDictionaryListByCodePublic(
        DictionaryCode.CATEGORY
      ),
  });

  return (
    <div ref={ref} className={s.container}>
      <div className={s.title}>
        {t("home:FIND_COURSE")}
      </div>
      <div className={s.wrapper}>
        <div className={s.parent}>
          <div
            className={s.big}
            style={{
              backgroundImage: `linear-gradient(182deg, rgba(0, 0, 0, 0.60) 15.99%, rgba(0, 0, 0, 0.00) 110.99%), url(${Img1})`,
            }}
          ></div>
          <div className={s.content_text}>
            <div className={s.head}>{t('home:DEVELOPMENT')}</div>
            <div className={s.text}>{t('home:COURSES')} – 43</div>
          </div>
        </div>
        <div className={s.parent}>
          <div
            className={s.big}
            style={{
              backgroundImage: `linear-gradient(182deg, rgba(0, 0, 0, 0.60) 15.99%, rgba(0, 0, 0, 0.00) 110.99%), url(${Img2})`,
            }}
          ></div>
          <div className={s.content_text}>
            <div className={s.head}>{t('home:ACADEMIC_DISCIPLINES')}</div>
            <div className={s.text}>{t('home:COURSES')} – 43</div>
          </div>
        </div>
        <div className={s.parent}>
          <div
            className={s.big}
            style={{
              backgroundImage: `linear-gradient(182deg, rgba(0, 0, 0, 0.60) 15.99%, rgba(0, 0, 0, 0.00) 110.99%), url(${Img3})`,
            }}
          ></div>
          <div className={s.content_text}>
            <div className={s.head}>{t('home:MARKETING')}</div>
            <div className={s.text}>{t('home:COURSES')} – 43</div>
          </div>
        </div>
        <div className={s.parent}>
          <div
            className={s.big}
            style={{
              backgroundImage: `linear-gradient(182deg, rgba(0, 0, 0, 0.60) 15.99%, rgba(0, 0, 0, 0.00) 110.99%), url(${Img4})`,
            }}
          ></div>
          <div className={s.content_text}>
            <div className={s.head}>{t('home:BUSINESS')}</div>
            <div className={s.text}>{t('home:COURSES')} – 43</div>
          </div>
        </div>
        <div className={s.parent}>
          <div
            className={s.big}
            style={{
              backgroundImage: `linear-gradient(182deg, rgba(0, 0, 0, 0.60) 15.99%, rgba(0, 0, 0, 0.00) 110.99%), url(${Img5})`,
            }}
          ></div>
          <div className={s.content_text}>
            <div className={s.head}>{t('home:FINANCE')}</div>
            <div className={s.text}>{t('home:COURSES')} – 43</div>
          </div>
        </div>
        <div className={s.parent}>
          <div
            className={s.big}
            style={{
              backgroundImage: `linear-gradient(182deg, rgba(0, 0, 0, 0.60) 15.99%, rgba(0, 0, 0, 0.00) 110.99%), url(${Img7})`,
            }}
          ></div>
          <div className={s.content_text}>
            <div className={s.head}>{t('home:LEARNING_LANGUAGES')}</div>
            <div className={s.text}>{t('home:COURSES')} – 43</div>
          </div>
        </div>
        <div className={s.parent}>
          <div
            className={s.big}
            style={{
              backgroundImage: `linear-gradient(182deg, rgba(0, 0, 0, 0.60) 15.99%, rgba(0, 0, 0, 0.00) 110.99%), url(${Img9})`,
            }}
          ></div>
          <div className={s.content_text}>
            <div className={s.head}>{t('home:LEISURE')}</div>
            <div className={s.text}>{t('home:COURSES')} – 43</div>
          </div>
        </div>
        <div className={s.parent}>
          <div
            className={s.big}
            style={{
              backgroundImage: `linear-gradient(182deg, rgba(0, 0, 0, 0.60) 15.99%, rgba(0, 0, 0, 0.00) 110.99%), url(${Img8})`,
            }}
          ></div>
          <div className={s.content_text}>
            <div className={s.head}>{t('home:ACCOUNTING')}</div>
            <div className={s.text}>{t('home:COURSES')} – 43</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchSuitable;
