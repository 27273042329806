import { useState } from "react";
import { useTranslation } from 'react-i18next';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import { useTheme } from "@mui/material";
import { useDropzone } from 'react-dropzone';
import axios from "axios";

import { serviceFile } from "@services/file";
import { LinearProgressWithLabel } from "@components/LinearProgressWithLabel";
import CustomButton from "./Button";

interface Props {
  label?: string;
  title: string;
  header?: string;
  fileTypeCode: string;
  acceptedExtensions: string;
  handleFileAfterUpload: any;
  height?: number | string;
  bgColor?: string;
  hideIcon?: boolean;
  multiple?: boolean;
  sizeLimit?: number; // в килобайтах
  disabled?: boolean,
  boxIsEmpty?: boolean,
  confirmed?: boolean,
  fileTypeInfo?: string,
  width?: string
}

const CustomFileUpload: React.FC<Props> = ({
  label,
  title,
  header,
  fileTypeCode,
  acceptedExtensions,
  handleFileAfterUpload,
  height = "auto",
  bgColor = "#FFFFFF",
  hideIcon = false,
  multiple = false,
  sizeLimit = 5000,
  disabled = false,
  boxIsEmpty = false,
  confirmed = false,
  fileTypeInfo,
  width = "100%"
}) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course", "common"]);
  const [showSizeLimitWarning, setShowSizeLimitWarning] = useState<boolean>(false);
  const [showFileTypeWarning, setShowFileTypeWarning] = useState<boolean>(false);
  const [uploadCancelToken, setUploadCancelToken] = useState<any>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const onDrop = (acceptedFiles: Array<any>, rejectedFiles: Array<any>) => {
    setShowSizeLimitWarning(false);
    if (rejectedFiles.length > 0) {
      setShowSizeLimitWarning(true);
      setTimeout(() => {
        setShowSizeLimitWarning(false);
      }, 5000);
    } else {
      // Filter accepted files based on their extension
      const validFiles = acceptedFiles.filter((file) => {
        const fileExtension = file.name.toLowerCase().slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
        return acceptedExtensions.includes(`.${fileExtension}`);
      });

      if (validFiles.length > 0) {
        // Handle valid files
        validFiles.forEach(async (file: any) => {
          const cancelToken = axios.CancelToken.source();
          setUploadCancelToken(cancelToken);

          try {
            setUploading(true);
            const fData = new FormData();
            fData.append("file", file);
            const response = await serviceFile.uploadFile(fData, {
              onUploadProgress: (progressEvent: any) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(percentCompleted);
              },
              cancelToken: cancelToken.token,
              params: {
                typeCode: fileTypeCode,
                temporary: false,
                confirmed
              }
            });

            // Handle the response from the server as needed.
            if (response.status === 200) {
              handleFileAfterUpload(response.data.uuid, file);
            }
          } catch (error: any) {
            if (axios.isCancel(error)) {
              console.log('Upload canceled');
            } else {
              console.log('Upload failed');
            }
          } finally {
            setUploading(false);
          }
        })
      } else {
        setShowFileTypeWarning(false);
        const invalidFiles = acceptedFiles.filter(file => !validFiles.includes(file));
        if (invalidFiles.length > 0) {
          setShowFileTypeWarning(true);
          setTimeout(() => {
            setShowFileTypeWarning(false);
          }, 5000);
        }
      }
    }
  };

  const { getRootProps, getInputProps, isDragReject, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles, rejectedFiles) => onDrop(acceptedFiles, rejectedFiles),
    maxSize: sizeLimit * 1000,
    multiple: multiple,
    disabled: disabled
  });

  const handleCancel = () => {
    if (uploadCancelToken) {
      uploadCancelToken.cancel("Upload canceled by user");
      setUploading(false);
    }
  };

  return (
    <>
      {label && <Typography>{label}</Typography>}
      <Box
        {...getRootProps()}
        sx={{
          p: 2.5,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          alignItems: 'center',
          borderWidth: 1,
          borderRadius: 1,
          borderColor: isDragReject ? common.errorColor : common.primaryColor,
          borderStyle: 'dashed',
          backgroundColor: bgColor,
          width,
          height
        }}
      >
        <input {...getInputProps()} accept={acceptedExtensions} />
        {uploading && (fileTypeCode === '009' || fileTypeCode === '011') ?
          <>
            <MovieCreationOutlinedIcon sx={{ color: common.strokePrimary, fontSize: 48 }} />
            <Typography>{t('VIDEO_UPLOADING')}</Typography>
            <Box sx={{ width: '70%' }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
            <CustomButton
              width="fit-content"
              borderRadius="4px"
              onClick={() => handleCancel()}
              disabled={disabled}
            >{t('common:actions.CANCEL')}</CustomButton>
          </> :
          <>
            {!boxIsEmpty &&
              <>
                {!hideIcon && <CloudUploadOutlinedIcon sx={{ color: common.primaryColor, fontSize: 48 }} />}
                <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{title}</Typography>
                {header && <Typography sx={{ fontSize: 14, fontWeight: 500 }}>{header}</Typography>}
                <CustomButton
                  width="fit-content"
                  borderRadius="4px"
                  onClick={open}
                  disabled={disabled}
                >{t('course:CHOOSE_FILE')}</CustomButton>
                {showSizeLimitWarning && <Typography sx={{ color: common.errorColor }}>{t('common:hints.MAX_FILE_SIZE_WARNING', { sizeLimit })}</Typography>}
                {showFileTypeWarning && <Typography sx={{ color: common.errorColor }}>{t('common:hints.ACCEPTABLE_FORMATS', { acceptedExtensions })}</Typography>}
                {fileTypeInfo && <Typography sx={{ textAlign: 'center', color: common.strokePrimary }}>{fileTypeInfo}</Typography>}
              </>
            }
          </>
        }
      </Box>
    </>
  )
}

export default CustomFileUpload;
