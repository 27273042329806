import { CardHome } from "../types/home";
import s from "./CardItemHome.module.scss";

const CardItemHome = (props: CardHome) => {
  const { icon, title, text } = props;
  return (
    <div className={s.card}>
      <div className={s.icon}>{icon && icon}</div>
      <div className={s.text_wrapper}>
        <div className={s.text}>{title}</div>
        <div className={s.subtitle}>{text}</div>
      </div>
    </div>
  );
};

export default CardItemHome;
