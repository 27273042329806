import { DurationType } from '@interfaces/index';
import * as yup from 'yup';

export const flowValidationSchema = (t: any, durationType: DurationType) => yup.object({
  capacity: yup.number().integer(t("common:validation.INTEGER_ONLY")).nullable().required(t('common:validation.MANDATORY_FIELD')),
  sendEmailMessage: yup.boolean(),
  useTimetable: yup.boolean(),
  scheduleId: yup.number().nullable()
    .test('scheduleId', t('common:validation.MANDATORY_FIELD'), function (scheduleId) {
      const useTimetable = this.parent.useTimetable;
      return scheduleId !== null || !useTimetable;
    }),
  message: yup
    .string()
    .nullable()
    .when("sendEmailMessage", {
      is: (value: boolean) => value === true,
      then: yup
        .string()
        .nullable()
        .min(5, "")
        .max(1000, "")
        .required(t("common:validation.MANDATORY_FIELD")),
      otherwise: yup.string().nullable(),
    }),
  regStartDate: yup.date()
    .nullable()
    .when('canSelfRegistration', {
      is: true,
      then: yup.date().nullable().required(t('common:validation.MANDATORY_FIELD')),
    })
    .test('regStartDate', t('common:validation.START_DATE_HAS_TO_BE_EARLIER_THAN_END_DATE'), function (regStartDate) {
      const regEndDate = this.parent.regEndDate;
      if (!regStartDate || !regEndDate) {
        return true;
      }
      return regStartDate < regEndDate;
    }),
  regEndDate: yup.date()
    .nullable()
    .when('canSelfRegistration', {
      is: (value) => (value && durationType !== DurationType.INDEFINITE),
      then: yup.date().nullable().required(t('common:validation.MANDATORY_FIELD')),
    }),
  startDate: yup.date().nullable()
    .test('startDate', t('common:validation.MANDATORY_FIELD'), function (startDate) {
      const useTimetable = this.parent.useTimetable;
      return startDate !== null || useTimetable;
    })
    .test('startDate', t('common:validation.START_DATE_HAS_TO_BE_EARLIER_THAN_END_DATE'), function (startDate) {
      const endDate = this.parent.endDate;
      // if both values are null, don't return error
      if (!startDate || !endDate) {
        return true;
      }
      return startDate < endDate;
    }),
  endDate: yup.date().nullable()
    .test('endDate', t('common:validation.MANDATORY_FIELD'), function (endDate) {
      const useTimetable = this.parent.useTimetable;
      return endDate !== null || useTimetable || durationType === DurationType.INDEFINITE;
    })
});