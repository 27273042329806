import { useState } from 'react';
import { EditorState, ContentState, convertFromHTML, ContentBlock } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Typography, useTheme } from '@mui/material';
import { useTranslation } from "react-i18next";

const TextReader = ({ htmlContent, hideBorder = false, numberOfSymbols = 800 }) => {
  const { contentBlocks } = convertFromHTML(htmlContent || '');
  const initialContentState = ContentState.createFromBlockArray(contentBlocks);

  const truncateContent = (contentState: ContentState) => {
    const blocks = contentState.getBlocksAsArray();
    let totalLength = 0;
    let truncatedBlocks: any = [];

    for (const block of blocks) {
      const blockText = block.getText();
      const blockLength = blockText.length;

      if (totalLength + blockLength <= numberOfSymbols) {
        truncatedBlocks.push(block);
        totalLength += blockLength;
      } else {
        const truncatedText = blockText.slice(0, numberOfSymbols - totalLength);
        truncatedBlocks.push(
          block.set('text', truncatedText + '...')
        );
        break;
      }
    }

    return ContentState.createFromBlockArray(truncatedBlocks);
  }

  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation('common');
  const { common } = useTheme().palette;

  const displayContentState = showMore
    ? initialContentState
    : truncateContent(initialContentState);

  const editorState = EditorState.createWithContent(displayContentState);

  return (
    <div style={{ wordBreak: 'break-word' }}>
      <Editor
        toolbarHidden
        readOnly
        editorState={editorState}
        editorClassName={hideBorder ? undefined : "custom-editor-content"}
      />
      {initialContentState.getPlainText().length > numberOfSymbols && (
        <>
          <Typography onClick={() => setShowMore(showMore ? false : true)} sx={{ cursor: 'pointer', color: common.primaryColor, mt: 1 }}>
            {showMore ? t('common:actions.SHOW_LESS') : t('common:actions.SHOW_MORE')}
          </Typography>
        </>
      )}
    </div>
  );
};

export default TextReader;