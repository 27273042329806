import { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { Formik, Form as FormikForm } from "formik";
import { AlertColor } from '@mui/material/Alert';
import Fab from '@mui/material/Fab';
import CloseIcon from '@mui/icons-material/Close';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import { isEqual } from 'lodash';

import { serviceCourse } from "@services/course";
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import { subtractFileNameFromFullFileName } from '@utils/functions';
import FormikInput from '@ui/formik/Input';
import FormikAutocomplete from '@ui/formik/Autocomplete';
import { TextEditor } from "@ui/formik/TextEditor";
import CustomButton from '@ui/Button';
import SaveChangesModal from '@components/modals/SaveChangesModal';
import { AdditionalMaterialTypes, DurationType, ITheme, CourseStatus, CourseFormatType } from '@interfaces/index';
import { initialDto } from "./models";
import { courseWithoutPeriodValidationSchema, courseWithPeriodValidationSchema } from './validation';
import { CourseContext } from '@context/CourseContext';
import VideoPlayer from '@components/VideoPlayer';
import LoadingSpinner from '@components/LoadingSpinner';
import CustomFileUpload from '@ui/FileUpload';
import { serviceFile } from '@services/file';

interface IProps {
  initialData: ITheme;
  setInitialData: (data: ITheme) => void;
  setMessage: (message: string) => void
  setMessageOpen: (isMessageOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void
}

const ThemeSettings = ({ setMessage, setMessageOpen, setMessageType, initialData, setInitialData }: IProps) => {
  const { t } = useTranslation(['common', 'course', 'enum']);
  const [sectionOptions, setSectionOptions] = useState<Array<any>>([]);
  const [defaultData, setDefaultData] = useState<ITheme>(initialDto);
  const [prompIsActive, setPrompIsActive] = useState<boolean>(true);

  const { courseId, sectionId, themeId } = useParams();
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const { courseData } = useContext(CourseContext);
  const publishedTheme = initialData.status === CourseStatus.PUBLISHED;
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const courseIsSaved = initialData?.status === CourseStatus.SAVED;

  const getDefaultDateOfAccess = useCallback(() => {
    if (courseData?.startDate) return courseData?.startDate;
    return initialDto.dateOfAccess;
  }, [courseData?.startDate]);

  useEffect(() => {
    if (courseId) {
      serviceCourse.getSectionList(courseId)
        .then(res => {
          setSectionOptions(res.data);
        });
    }
  }, [courseId]);

  useEffect(() => {
    if (themeId && themeId !== "new") {
      serviceCourse.getThemeInfo(themeId).then((res) => {
        if (res.status === 200) {
          setInitialData({ ...res.data, videoType: res.data.url ? AdditionalMaterialTypes.LINK : AdditionalMaterialTypes.FILE });
          setDefaultData({ ...res.data, videoType: res.data.url ? AdditionalMaterialTypes.LINK : AdditionalMaterialTypes.FILE });
        }
      })
    } else {
      const id = sectionId && sectionId !== "new" ? sectionId : null;
      setInitialData({ ...initialDto, sectionId: id, dateOfAccess: getDefaultDateOfAccess(), videoType: AdditionalMaterialTypes.FILE });
      setDefaultData({ ...initialDto, sectionId: id, dateOfAccess: getDefaultDateOfAccess(), videoType: AdditionalMaterialTypes.FILE });
    }
  }, [sectionId, themeId, setInitialData, getDefaultDateOfAccess]);

  const updateThemeStatus = () => {
    if (themeId && courseId) {
      serviceCourse.changeThemeStatus(themeId, courseId).then(res => {
        if (res.status === 200) {
          setInitialData({ ...initialData, status: res.data });
          setDefaultData({ ...initialData, status: res.data });
          setMessageOpen(true);
          setMessage(t('common:messages.CHANGES_SAVED'));
        }
      }).finally(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
          setMessageOpen(false);
        }, 3000);
      });
    }
  }

  const [videoIdIsBinding, setVideoIdIsBinding] = useState<boolean>(false);
  const handleVideoFileAfterUpload = (fileId: string, fileName: string, setFieldValue: any) => {
    if (themeId !== 'new') {
      setVideoIdIsBinding(true);
      if (courseId && themeId) {
        serviceCourse.updateTheme(themeId, courseId, {
          ...initialData,
          videoId: fileId,
          videoName: subtractFileNameFromFullFileName(fileName),
          url: null
        }).then(res => {
          if (res.status === 200) {
            setFieldValue('videoId', fileId);
            setFieldValue('videoName', subtractFileNameFromFullFileName(fileName));
            setFieldValue('url', null);
          }
        }).finally(() => {
          setVideoIdIsBinding(false);
        })
      }
    } else {
      setFieldValue('videoId', fileId);
      setFieldValue('videoName', subtractFileNameFromFullFileName(fileName));
    }
  };

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: (_, values) => deleteVideoFile(values),
    modalTitle: t('common:DELETE_VIDEO_CONFIRMATION_TEXT'),
  })

  const deleteVideoFile = async (values: ITheme) => {
    if (!values.id) {
      serviceFile.deleteFile(values.videoId || '').then((res) => {
        if (res.status === 200) {
          setInitialData({ ...values, videoId: null, videoName: null });
          setDefaultData({ ...values, videoId: null, videoName: null });
        }
      })
    } else {
      const res = await serviceCourse.updateTheme(themeId || "", courseId || "", { ...initialData, videoId: null, videoName: null });
      if (res.status === 200) {
        setInitialData({ ...values, videoId: null, videoName: null });
        setDefaultData({ ...values, videoId: null, videoName: null });
        setMessageOpen(true);
        setMessageType("success");
        setMessage(t('messages.VIDEO_DELETED'));
        setTimeout(() => {
          setMessageOpen(false);
        }, 3000);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return res;
      }
    }
  }

  const handleSubmit = (values: ITheme) => {
    if (!values.course && courseId) values.course = { id: courseId };
    values.videoId = values.url ? null : values.videoId;
    if (courseId) {
      setPrompIsActive(false);
      if (values.id) {
        // when there is an id we call the update method
        serviceCourse.updateTheme(values.id, courseId, values).then((res) => {
          if (res.status === 200) {
            setInitialData(values);
            setDefaultData(values);
            navigate(`/myCourses/${courseId}/${values.sectionId ? values.sectionId : 'new'}/theme/${values.id}`);
            setMessageOpen(true);
            setMessage(t('common:messages.CHANGES_SAVED'));
            setMessageType("success");
            setTimeout(() => {
              setMessageOpen(false);
            }, 5000);
          }
        }).finally(() => {
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setPrompIsActive(true);
          }, 120)
        });
      } else {
        // otherwise we call the create method
        serviceCourse.createTheme({ ...values, courseId }, courseId).then((res) => {
          if (res.status === 200) {
            setInitialData({ ...values, id: res.data.id });
            setDefaultData({ ...values, id: res.data.id });
            navigate(`/myCourses/${courseId}/${values.sectionId ? values.sectionId : 'new'}/theme/${res.data.id}`);
            setMessageOpen(true);
            setMessage(t('course:theme.TOPIC_HAS_BEEN_CREATED'))
            setMessageType("success");
            setTimeout(() => {
              setMessageOpen(false);
            }, 5000);
          }
        }).finally(() => {
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setPrompIsActive(true);
          }, 120)
        });
      }
    }
  }

  const unload = useCallback((event: any) => {
    const currentFormData = sessionStorage.getItem('themeFormData');
    if (currentFormData && !isEqual(JSON.parse(currentFormData), defaultData)) {
      event.returnValue = '';
    }
  }, [defaultData]);

  useEffect(() => {
    window.addEventListener('beforeunload', unload);
    return () => {
      window.removeEventListener('beforeunload', unload);
      sessionStorage.removeItem('themeFormData');
    };
  }, [unload]);

  return (
    <>
      <Formik
        initialValues={initialData}
        enableReinitialize
        validationSchema={courseData?.durationType === DurationType.INDEFINITE && courseData?.formatType === CourseFormatType.ASYNC
          ? courseWithoutPeriodValidationSchema(t)
          : courseWithPeriodValidationSchema(t)}
        onSubmit={(values: ITheme) => handleSubmit(values)}
      >
        {({ submitForm, values, dirty, isValid, setFieldValue }) => {
          sessionStorage.setItem('themeFormData', JSON.stringify(values));
          return (
            <FormikForm>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  px: 4,
                  py: 2,
                  width: { xs: '100%', lg: '70%' }
                }}
              >
                <FormikInput label={t('course:theme.THEME')} name='name' isRequired disabled={publishedTheme || !isAuthor} />
                <Box>
                  <FormikInput disabled={publishedTheme || !isAuthor} label={t('course:section.SHORT_DESCRIPTION')} name='shortDescription' multiline showSymbolCount maxChar={200} isRequired placeholder={t('common:placeholders.THIS_TOPIC_WILL_EXAMINE')} />
                </Box>
                {sectionOptions.length > 0 &&
                  <FormikAutocomplete isInputEnterable={false} label={t('course:section.SECTION')} name='sectionId' options={sectionOptions} disabled={publishedTheme || !isAuthor} />
                }
                <Box sx={{ border: `1px solid ${common.borderSecondary}`, borderLeft: `6px solid rgba(255, 149, 0,1)`, borderRadius: '4px', p: 1, display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Box sx={{ width: '28px', height: '28px', borderRadius: '50%', backgroundColor: 'rgba(255, 149, 0, 0.4)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <WarningOutlinedIcon sx={{ color: 'rgba(255, 149, 0,1)', width: '20px', height: '20px' }} />
                  </Box>
                  {t('course:hints.THEME_PAGE_HINT')}
                </Box>
                {((publishedTheme && values.description) || !publishedTheme) &&
                  <TextEditor label={t('course:theme.TOPIC_CONTENT')} name='description' readOnly={publishedTheme || !isAuthor} />
                }
                <Box sx={{ width: '100%', display: 'flex', gap: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minWidth: '115px',
                      borderRadius: '6px',
                      height: '40px',
                      p: '12px 16px',
                      cursor: 'pointer',
                      background: values.videoType === AdditionalMaterialTypes.FILE ? common.primaryColor : '#F6F4F8',
                      color: values.videoType === AdditionalMaterialTypes.FILE ? '#FFFFFF' : '#686B81',
                      '&:hover': {
                        background: common.secondaryColor,
                        color: common.primaryColor
                      }
                    }}
                    onClick={() => setFieldValue('videoType', AdditionalMaterialTypes.FILE)}
                  >{t('enum:UPLOAD_VIDEO')}</Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minWidth: '115px',
                      borderRadius: '6px',
                      height: '40px',
                      p: '12px 16px',
                      cursor: 'pointer',
                      background: values.videoType === AdditionalMaterialTypes.LINK ? common.primaryColor : '#F6F4F8',
                      color: values.videoType === AdditionalMaterialTypes.LINK ? '#FFFFFF' : '#686B81',
                      '&:hover': {
                        background: common.secondaryColor,
                        color: common.primaryColor
                      }
                    }}
                    onClick={() => setFieldValue('videoType', AdditionalMaterialTypes.LINK)}
                  >{t('enum:PROVIDE_LINK')}</Box>
                </Box>
                {values.videoType === AdditionalMaterialTypes.FILE &&
                  <>
                    {values.videoId ?
                      <>
                        <Box sx={{ width: '100%', position: 'relative' }}>
                          <VideoPlayer videoFileId={values.videoId} />
                          {!publishedTheme && isAuthor &&
                            <Fab
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                margin: 0.625,
                                zIndex: 1,
                                backgroundColor: '#F8F8F8',
                                border: `1px solid ${common.strokePrimary}`,
                                boxShadow: 0
                              }}
                              size="small"
                              onClick={() => {
                                if (values.videoId) {
                                  openDeleteConfirmModal(values.videoId, values);
                                }
                              }}
                            >
                              <CloseIcon sx={{ color: common.errorColor }} />
                            </Fab>
                          }
                        </Box>
                        <FormikInput label={t('course:theme.THEME_VIDEO_NAME')} name='videoName' placeholder={t('course:theme.LINK_TO_VIDEO')} disabled={publishedTheme || !isAuthor} />
                      </> :
                      <Box>
                        {videoIdIsBinding ?
                          <Box sx={{ height: 200 }}>
                            <LoadingSpinner />
                          </Box> :
                          <CustomFileUpload
                            title={t('course:DRAG_VIDEO_TITLE')}
                            header={t('course:DRAG_VIDEO_HEADER')}
                            fileTypeCode="009"
                            acceptedExtensions=".avi, .mp4"
                            disabled={publishedTheme || !isAuthor}
                            sizeLimit={courseData?.maxThemeVideoSize}
                            handleFileAfterUpload={(fileId: string, file: any) => handleVideoFileAfterUpload(fileId, file.name, setFieldValue)}
                          />
                        }
                      </Box>
                    }
                  </>
                }
                {values.videoType === AdditionalMaterialTypes.LINK &&
                  <FormikInput
                    name="url"
                    label={t('course:theme.LINK_TO_VIDEO')}
                    placeholder="https://www.youtube.com/embed/"
                    isRequired
                    disabled={publishedTheme || !isAuthor}
                  />
                }
                {courseData?.durationType === DurationType.INDEFINITE && courseData?.formatType === CourseFormatType.ASYNC &&
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: 'column',
                      gap: 2,
                    }}
                  >
                    <Box sx={{ width: "25%" }}>
                      <FormikInput
                        name="estimatedTime"
                        type="number"
                        adornmentIcon={t('common:MINUTE_SHORT')}
                        isRequired
                        label={t('course:theme.THEME_TESTING_TIME')}
                        hintText={t('course:hints.ESTIMATED_TIME_HINT')}
                      />
                    </Box>
                  </Box>
                }
              </Box>
              {courseData?.status !== CourseStatus.CLOSED &&
                <Box sx={{ display: 'flex', gap: 1, justifyContent: { sm: 'flex-end', xs: 'flex-start' }, mt: '12px', mr: 2 }}>
                  {values.status === CourseStatus.SAVED &&
                    <Box sx={{ border: `1px solid ${common.primaryColor}`, borderRadius: '9px' }}>
                      <CustomButton
                        width="98px"
                        height="38px"
                        backgroundColor={common.fontWhite}
                        borderRadius="8px"
                        fontColor={common.primaryColor}
                        sx={{
                          fontWeight: "500",
                          lineHeight: "20px",
                          alignItems: "center",
                        }}
                        onClick={submitForm}
                        disabled={!(isValid && dirty) || !isAuthor || !courseIsSaved}
                      >
                        {t('actions.SAVE')}
                      </CustomButton>
                    </Box>
                  }
                  {themeId && themeId !== 'new' &&
                    <Box>
                      <CustomButton
                        width="fit-content"
                        height="40px"
                        backgroundColor={common.primaryColor}
                        borderRadius="8px"
                        fontColor={common.fontWhite}
                        sx={{
                          fontWeight: "500",
                          lineHeight: "20px",
                          alignItems: "center",
                        }}
                        onClick={updateThemeStatus}
                        disabled={(dirty || !isAuthor) && values.status === CourseStatus.SAVED}
                      >
                        {values.status === CourseStatus.SAVED ? t('actions.PUBLISH') : t('actions.UNPUBLISH')}
                      </CustomButton>
                    </Box>
                  }
                </Box>
              }
              {prompIsActive &&
                <SaveChangesModal
                  dirty={dirty || !isEqual(defaultData, values)}
                />
              }
            </FormikForm>
          )
        }}
      </Formik>
      {DeleteModal}
    </>
  )
}

export default ThemeSettings;
