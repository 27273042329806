import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box, Typography, useTheme, Divider } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";

import CommentList from "./CommentList";
import { IProfile } from "@interfaces/index";
import { useSelector } from "react-redux";
import { StateSchema } from "@store/StateSchema";
import { CommentaryInfo } from "@components/common";

const MessageHistory = () => {
  const { t } = useTranslation(["common", "course"]);
  const { progressId } = useParams();
  const profile: IProfile = useSelector(
    (store: StateSchema) => store.account.profileInfo
  );
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <Box sx={{ mt: "8px" }}>
      <Typography
        sx={{ cursor: "pointer", mb: "24px" }}
        onClick={() => navigate(-1)}
      >
        <ArrowForwardIosIcon
          sx={{ width: "10px", height: "10px", mt: "3px", rotate: "180deg" }}
        />{" "}
        {t("course:task.BACK_TO_TASK")}
      </Typography>
      <Box
        sx={{ backgroundColor: "#FFFFFF", borderRadius: "8px", p: "36px 24px" }}
      >
        <Typography
          sx={{
            color: common.primaryColor,
            fontSize: "28px",
            lineHeight: "36px",
            fontWeight: "500",
          }}
        >
          {t("course:task.ANSWER_HISTORY")}
        </Typography>
        <Divider sx={{ my: "16px" }} />
        {searchParams.get("type") ? (
          <CommentaryInfo
            progressId={progressId || ""}
            userId={profile.pltaId}
          />
        ) : (
          <CommentList progressId={progressId || ""} userId={profile.pltaId} />
        )}
      </Box>
    </Box>
  );
};

export default MessageHistory;
