import { MathJax, MathJaxContext } from "better-react-mathjax";
import { useTheme, useMediaQuery } from '@mui/material'
interface Props {
  formula: string;
  inline?: boolean;
}

const config = {
  loader: { load: ["input/asciimath"] }
};


const MathJaxComponent = ({ formula, inline }: Props) => {
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const style = inline ? {
    display: 'inline',
    fontSize: matchesDownSm ? '10px' : '16px'
  } : {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    fontSize: matchesDownSm ? '10px' : '16px'
  };

  return (
    <MathJaxContext config={config}>
      <MathJax style={style}>{formula}</MathJax>
    </MathJaxContext>
  )
}

export default MathJaxComponent;