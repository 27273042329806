import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import FormikAutocomplete from '@ui/formik/Autocomplete'
import { serviceDict } from '@services/dictionary';
import { IDictionary } from '@interfaces/index';

interface IProps {
  orgId: number | null;
  dictKey: string;
  name: string;
  disabled?: boolean;
  isRequired?: boolean;
}

const SelectOptionsByOrgId = ({ orgId, dictKey, name, disabled = false, isRequired = false }: IProps) => {
  const { t } = useTranslation(['enum']);
  const [options, setOptions] = useState<IDictionary[]>([]);
  const [disabledOptions, setDisabledOptions] = useState<string[]>([])

  useEffect(() => {
    if (orgId) {
      serviceDict.getDictionaryListByOrgIdForDropdown(orgId, dictKey).then((res) => {
        if (res.status === 200) {
          setOptions(res.data);
          setDisabledOptions(res.data.filter(option => option.isDisabled).map(option => option.id))
        }
      });
    }
  }, [orgId, dictKey]);

  return (
    <FormikAutocomplete
      label={t(`${dictKey}`)}
      name={name}
      options={options}
      disabled={disabled}
      isRequired={isRequired}
      disabledOptions={disabledOptions}
    />
  )
}

export default SelectOptionsByOrgId;
