import { useContext } from 'react';
import { Box, useTheme } from '@mui/material';

import CustomButton from '@ui/Button';
import CustomModal from '@components/Modal';
import { useTranslation } from "react-i18next";
import { CourseContext } from "@context/CourseContext";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const ChangeDateModal = ({ open, handleToggleModal, updateDeadlineDate, initialDateOfDeadline, finalDateOfDeadline, deadlineDate, setDeadlineDate }) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course"]);
  const { courseData } = useContext(CourseContext);

  const handleDeadlineDateChange = (date: Date | null) => {
    setDeadlineDate(date);
  };

  return (
    <CustomModal open={open} handleToggleModal={handleToggleModal} width={515}>
      <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            inputFormat="DD-MM-YYYY HH:mm"
            label={t('course:task.DEADLINE_FOR_COMPLETION')}
            value={new Date(deadlineDate)}
            onChange={handleDeadlineDateChange}
            minDate={courseData?.startDate}
            maxDate={courseData?.endDate}
            ampm={false}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "дд-мм-гггг чч:мм"
                }}
                sx={{
                  width: '100%',
                  '& label.Mui-focused': { color: common.primaryColor },
                  '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': { borderColor: common.primaryColor },
                    '&.Mui-focused fieldset': { borderColor: common.primaryColor }
                  }
                }}
              />
            )}
          />
        </LocalizationProvider>
        <Box sx={{ mt: "24px", display: "flex", justifyContent: "space-between" }}>
          <CustomButton
            backgroundColor={common.btnCancel}
            fontColor={common.primaryColor}
            width="199px"
            borderRadius="6px"
            height="40px"
            sx={{ alignItems: "center", fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}
            onClick={() => handleToggleModal()}
          >
            {t('common:actions.CANCEL')}
          </CustomButton>
          <CustomButton
            backgroundColor={common.primaryColor}
            fontColor={common.fontWhite}
            width="199px"
            borderRadius="6px"
            height="40px"
            sx={{ alignItems: "center", fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}
            onClick={() => updateDeadlineDate()}
          >
            {t('common:actions.SAVE')}
          </CustomButton>
        </Box>
      </>
    </CustomModal>
  )
}

export default ChangeDateModal;