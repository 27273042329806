import axios from "@utils/axios";

export const serviceDict = {
  getDictionaryList: (dictCode: string, bodyData: any) =>
    axios.post(`/api/dictionary/common/${dictCode}/findAll`, bodyData),
  getDictionaryChildList: (dictCode: string, parentId: number) =>
    axios.get(`/api/dictionary/common/${dictCode}/findAllChildren/${parentId}`),
  getDictListForDropdown: (dictCode: string) =>
    axios.get(`/api/dictionary/common/${dictCode}/findAll?showAll=true`),
  getDictionaryListByOrgIdForDropdown: (orgId: number, dictCode: string) =>
    axios.get(`/api/dictionary/common/${orgId}/${dictCode}/findAll`),
  createDictionary: (dictCode: string, bodyData: any) =>
    axios.post(`/api/dictionary/common/${dictCode}/save`, bodyData),
  getDictionary: (dictCode: string, id: any) =>
    axios.get(`/api/dictionary/common/${dictCode}/${id}`),
  deleteDictionary: (dictCode: string, id: any) =>
    axios.delete(`/api/dictionary/common/${dictCode}/${id}`),
  getCurrentBranch: () =>
    axios.get("/api/dictionary/common/get-current-branch"),

  getGradingSystemList: (orgId = 0) =>
    axios.get(`/api/dictionary/grading-system/findAll?orgId=${orgId}`),
  updateGradingSystem: (orgId: number | null, bodyData: any) =>
    axios.put(`/api/dictionary/grading-system/update?orgId=${orgId}`, bodyData),
  getGradingSystemById: (id: string) =>
    axios.get(`/api/dictionary/grading-system/${Number(id)}`),

  createCourseTypeOption: (bodyData: any) =>
    axios.post(`/api/dictionary/common-config/save`, bodyData),
  getTypeOptionList: (branchId?: string) =>
    axios.get(
      `/api/dictionary/common-config/findAll${
        branchId !== null && branchId !== undefined
          ? `?branchId=${branchId}`
          : ""
      }`
    ),
  getTypeOptionById: (id: number) =>
    axios.get(`/api/dictionary/common-config/${id}`),
  // public apis
  getDictionaryListByCodePublic: (dictCode: string) =>
    axios.get(`/api/dictionary/public/dict/${dictCode}/findAll`),
};
