import { FC, ReactElement, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import { get, capitalize } from 'lodash';

import CustomButton from "@ui/Button";
import { IRole } from "interfaces";
import { serviceRole } from "@services/role";
import { initialDto } from "./models";
import Form from "./Form";
import { useTranslation } from 'react-i18next';
import CustomModal from "@components/Modal";

const iconStyle = {
  color: 'white',
  width: 30,
  height: 30,
  borderRadius: 15,
  padding: 0,
  margin: 0
}

const RolesAndRights: FC<any> = (): ReactElement => {
  const [roleList, setRoleList] = useState<Array<any>>([]);
  const [permissionList, setPermissionList] = useState<Array<any>>([]);
  const [roleId, setRoleId] = useState<number | null>();
  const [initialData, setInitialData] = useState<IRole | undefined>(initialDto);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<{ search: string }>({ search: "" });
  const lang = localStorage.getItem("i18nextLng") || "kz";
  const { t } = useTranslation(["common", "dictionary"]);
  const { common } = useTheme().palette;

  const handleModalToggle = (modalOpen: boolean, currentRoleId?: number | null, data?: IRole) => {
    if (!modalOpen) {
      setRoleId(null);
      setInitialData(initialDto);
    } else {
      setRoleId(currentRoleId);
      setInitialData(data);
    }
    setOpen(modalOpen);
  }

  useEffect(() => {
    serviceRole.getAllRoles({ size: 100, filter })
      .then(res => {
        if (res.status === 200) {
          setRoleList(res.data.dtoList);
        }
      });
  }, [filter]);

  useEffect(() => {
    serviceRole.getPermissionList()
      .then(res => {
        if (res.status === 200) {
          setPermissionList(res.data);
        }
      });
  }, []);

  const searchByName = (searchValue: string) => {
    const new_filter = { ...filter, search: searchValue };
    setFilter(new_filter);
  };

  return (
    <>
      <Typography sx={{ fontSize: 28, paddingBottom: 3 }}>
        {t('sideMenu:ROLES_AND_RIGHTS')}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2
        }}
      >
        <TextField
          label={t('common:placeholders.SEARCH_BY_ROLES')}
          name="searchValue"
          onChange={(e) => searchByName(e.target.value)}
          color="primary"
          size="small"
          sx={{ width: 280 }}
        />
        <CustomButton
          onClick={() => handleModalToggle(true, null, initialDto)}
          width="150px"
          color="primary"
        >
          {t('common:actions.ADD')}
        </CustomButton>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  padding: 0,
                  px: 2,
                  width: 300,
                  position: 'sticky',
                  left: 0,
                  backgroundColor: '#FFFFFF',
                  zIndex: 1
                }}
              >
                <TextField label={t('dictionary:RIGHTS')} color='primary' size="small" sx={{ minWidth: 250 }} />
              </TableCell>
              {roleList.map((role, index) => {
                return (
                  <TableCell key={`${index}_${role.id}`} align="center" sx={{ color: 'blue', width: 150 }}>
                    <Button
                      onClick={() => handleModalToggle(true, role.id, role)}
                      sx={{ textTransform: "none", color: "blue" }}
                    >
                      {role.nameRu}
                    </Button>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {permissionList.map((permission, index) => {
              return (
                <TableRow key={`${index}_${permission.id}`}>
                  <TableCell sx={{ margin: 0, py: 2, color: 'blue', position: 'sticky', left: 0, backgroundColor: '#FFFFFF' }}>
                    {get(permission, `name${capitalize(lang)}`)}
                  </TableCell>
                  {roleList.map((role, index) => {
                    const hasRight = role.permissionCodes.includes(permission.code);
                    if (hasRight) {
                      return (
                        <TableCell key={`${index}_${role.id}`} align="center">
                          <DoneIcon
                            sx={{ ...iconStyle, backgroundColor: 'green' }}
                          />
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell key={`${index}_${role.id}`} align="center">
                        <CancelIcon
                          sx={{ ...iconStyle, backgroundColor: common.errorColor }}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {open &&
        <CustomModal
          open={open}
          handleToggleModal={handleModalToggle}
          showCloseIcon
        >
          <>
            <Box>
              <Box>
                <Typography sx={{ fontSize: 20, paddingBottom: 3 }}>
                  {!roleId ? t('common:actions.ADDING_ROLE') : t('common:actions.EDITING_ROLE')}
                </Typography>
              </Box>
            </Box>
            <Form
              roleId={roleId}
              roleList={roleList}
              setRoleList={setRoleList}
              permissionList={permissionList}
              initialData={initialData}
              closeModal={handleModalToggle}
            />
          </>
        </CustomModal>
      }
    </>
  );
};

export default RolesAndRights;
