import { useState, useEffect } from 'react';
import { get } from 'lodash';

import { serviceUser } from '@services/user';

interface Props {
  id: string | null;
}

const UserFullName = ({ id }: Props) => {
  const [userFullName, setUserFullName] = useState('');
  
  useEffect(() => {
    async function getUserFullName() {
      if (id) {
        const response = await serviceUser.getUserByIdPublic(id);
        const userFullName = await get(response.data, `fullName`);
        setUserFullName(userFullName);
      }
    }

    getUserFullName();
  }, [id]);

  return (
    <>{userFullName}</>
  );
}

export default UserFullName;