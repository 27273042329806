import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton, useTheme } from '@mui/material'
import East from '@mui/icons-material/East';

import { serviceCourse } from '@services/course';
import CustomAutocomplete from '@ui/Autocomplete';
import { ITheme } from '@interfaces/index';

export const SelectTheme = () => {
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const { classId, themeId } = useParams();
  const [themeList, setThemeList] = useState<Array<any>>([]);
  const [currentTheme, setCurrentTheme] = useState<ITheme | null>(null);

  useEffect(() => {
    if (classId) {
      serviceCourse.getOrderedThemeAllowedList(classId).then((res) => {
        if (res.status === 200) {
          setThemeList(res.data);
          setCurrentTheme(res.data.find((theme: ITheme) => theme.id === themeId));
        }
      })
    }
  }, [classId, themeId]);

  const disabledOptionsTheme = themeList.filter((theme) => theme.disabled).map((theme) => theme.id)

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: "10px" }}>
      <IconButton sx={{ backgroundColor: 'white' }} disabled={!currentTheme?.prevId} onClick={() => navigate(`/myCourses/${classId}/theme/${currentTheme?.prevId}`)}>
        <East sx={{ color: !currentTheme?.prevId ? "#C1C1C1" : common.primaryColor, fontSize: '28px', rotate: "180deg" }} />
      </IconButton>
      <CustomAutocomplete
        name="themeId"
        valueAsObject
        value={currentTheme}
        options={themeList}
        clearable={false}
        customIconDisabled={false}
        disabledOptions={disabledOptionsTheme}
        onChange={(_: any, theme) => navigate(`/myCourses/${classId}/theme/${theme.id}`)}
      />
      <IconButton sx={{ backgroundColor: 'white' }} disabled={!currentTheme?.nextId} onClick={() => navigate(`/myCourses/${classId}/theme/${currentTheme?.nextId}`)}>
        <East sx={{ color: !currentTheme?.nextId ? "#C1C1C1" : common.primaryColor, fontSize: '28px' }} />
      </IconButton>
    </Box>
  )
}