import { useEffect, useState } from "react";
import { Box, TextField, Typography, useTheme, Avatar, Popover } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowBackdIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSelector } from "react-redux";
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';

import { initialFilter, NotificationDto } from "./models";
import Pagination from "@ui/Pagination";
import { serviceNotification } from "@services/notification";
import { get, capitalize } from 'lodash';
import CustomAutocomplete from "@ui/Autocomplete";
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import { dateOptions, statusOptions } from "@utils/enums";
import { serviceCourse } from "@services/course";
import { serviceTask } from "@services/task";
import { StateSchema } from "@store/StateSchema";
import { notificationActions } from "@store/notification/reducers";
import { useAppDispatch } from "@store/hooks";

const NotificationList = () => {
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const unreadNotificationsCount = useSelector((store: StateSchema) => store.notification.notificationCount);
  const [notificationList, setNotificationList] = useState<NotificationDto[]>([]);
  const [count, setCount] = useState<number>(0);
  const [changedNotificationList, setChangedNotificationList] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [filter, setFilter] = useState<{
    search: string;
    status: string;
    dateType: string;
  }>(initialFilter);
  const { t } = useTranslation(["notification", "common"]);
  const lng = localStorage.getItem("i18nextLng") || "kz";

  const [hint, setHint] = useState<HTMLElement | null>(null);

  const hintPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHint(event.currentTarget);
  };

  const hintPopoverClose = () => {
    setHint(null);
  };

  const openHint = Boolean(hint);

  useEffect(() => {
    serviceNotification
      .getListUserId({ pageNumber: currentPage, size: rowsPerPage, filter })
      .then((res: any) => {
        setNotificationList(res.data.dtoList);
        setCount(res.data.totalElements);
      });
  }, [currentPage, rowsPerPage, filter, unreadNotificationsCount, changedNotificationList]);

  const searchByProp = (key: string, value: any) => {
    const new_filter: any = { ...filter };
    new_filter[key] = value;
    setFilter(new_filter);
  };

  const handleChangePage = (newPage: number) => setCurrentPage(newPage);

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
    setCurrentPage(0);
  };

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: (notificationId: any) => {
      return serviceNotification.deleteByUser(notificationId).then((res: any) => {
        if (res.status === 200) {
          if (filter.status === "UNREAD") {
            dispatch(notificationActions.setNotificationCount(unreadNotificationsCount - 1));
          } else {
            setChangedNotificationList(!changedNotificationList);
          }
        }
      });
    },
    modalTitle: t('common:DELETE_NOTIFICATION_CONFIRMATION_TEXT'),
  })

  const selectByStatus = (value: any) => {
    setFilter({ ...filter, status: value.id });
  }

  const selectByDate = (value: any) => {
    setFilter({ ...filter, dateType: value.id });
  }

  const navigateToNotification = async (notification) => {
    let id = notification.linkParams.taskId || notification.linkParams.courseId || null;
    let info: any | null = null

    if (notification.linkParams.courseId) {
      const res = await serviceCourse.getCourseDataByClassId(id);
      info = await res.data;
    }

    if (notification.linkParams.taskId) {
      const res = await serviceTask.getTaskInfo(id);
      info = await res.data;
    }

    const routeOrComponent = getRouteForNotification(notification.routeType, id, info);
    if (typeof routeOrComponent === 'string') {
      navigate(routeOrComponent);
    } else if (typeof routeOrComponent === 'function') {
      routeOrComponent();
    }
  };
  const getRouteForNotification = (routeType: string, id?: number, info?: any) => {
    const routeMap = {
      "CHANGE_PASSWORD": '/profile',
      "CHANGE_EMAIL": '/profile',
      "CHANGE_PHONE": '/profile',
      "CHANGE_SMS": '/profile',
      "CREATED_USER": '/users',
      "CHANGED_USER": '/users',
      // "ADDING_USER_TO_COURSE": `/myCourses/${id}`,
      // "REGISTRATION_TO_COURSE": `/myCourses/${info?.status.toLowerCase()}/${id}`,
      // "RESTRICTED_ACCESS_TO_COURSE": role !== "LISTENER" ? `/myCourses` : `/myCourses/restricted`,    // DONE
      // "RESTORED_ACCESS_TO_COURSE": role !== "LISTENER" ? `/myCourses` : `/myCourses/current/${id}`,   // DONE
      // "RESPONSIBLE_MEMBER": `/myCourses/${info?.course.id}/tasks/${id}/${info?.format}/${info?.section ? info?.section.id : "new"}/${info?.theme ? info?.theme.id : "new"}`,
      // "REVOKE_RESPONSIBLE_MEMBER": `/myCourses/${info?.course.id}/tasks/${id}/${info?.format}/${info?.section ? info?.section.id : "new"}/${info?.theme ? info?.theme.id : "new"}`,
      // "CANCEL_TASK_PUBLICATION": role !== "LISTENER"
      //   ? `/myCourses/${info?.course.id}/tasks/${id}/${info?.format}/${info?.section ? info?.section.id : "new"}/${info?.theme ? info?.theme.id : "new"}`
      //   : `/myCourses/current/${id}`,
      // "TASK_PUBLICATION": role !== "LISTENER" ? `/myCourses/${taskInfo?.course.id}/tasks/${id}/${taskInfo?.format}/${taskInfo?.section ? taskInfo?.section.id : "new"}/${taskInfo?.theme ? taskInfo?.theme.id : "new"}` : `/myCourses/current/${id}`,
    };

    return routeMap[routeType] || '/notifications'
  };

  return (
    <>
      {DeleteModal}
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: '16px', mt: '10px' }}>
          <ArrowBackdIosIcon sx={{ width: '10px', height: '10px', mt: '1.5px', mr: '9px' }} />
          <Typography sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>{t("common:actions.GO_BACK")}</Typography>
        </Box>
        <Typography sx={{ fontWeight: 500, fontSize: '36px', lineHeight: '44px', mb: '32px' }}>{t("notification:NOTIFICATION")}</Typography>
        <Box sx={{ border: `1px solid ${common.borderSecondary}`, backgroundColor: common.fontWhite, borderRadius: '24px', p: '24px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label={t('common:placeholders.SEARCH')}
              color="primary"
              size="small"
              name="searchValue"
              onChange={(e) => searchByProp('search', e.target.value)}
              sx={{ width: '30%', mr: '16px' }}
            />
            <Box sx={{ width: '15%', mr: '16px' }}>
              <CustomAutocomplete
                name="status"
                value={filter.status}
                options={statusOptions}
                clearable={false}
                placeholder={t('common:label.STATUS')}
                onChange={(_, value: any) => selectByStatus(value)}
              />
            </Box>
            <Box sx={{ width: '20%' }}>
              <CustomAutocomplete
                name="date"
                options={dateOptions}
                onChange={(e, value) => selectByDate(value)}
                clearable={false}
                placeholder={t('notification:SHOW_NOTIFICATIONS_FOR')}
              />
            </Box>
            <Box onMouseEnter={hintPopoverOpen} onMouseLeave={hintPopoverClose} sx={{ ml: '22px', mt: '5px' }}
              onClick={() => {
                serviceNotification.updateAllStatusToRead().then((res: any) => {
                  if (res.status === 200) {
                    dispatch(notificationActions.setNotificationCount(0));
                  }
                });
              }}
            >
              <DraftsOutlinedIcon sx={{ cursor: 'pointer' }} />
            </Box>
          </Box>

          <Box sx={{ display: 'block', alignItems: 'center', p: '12px', overflowY: 'scroll', width: '100%', mr: '5px', mt: '30px', '&::-webkit-scrollbar': { width: '7px' }, '&::-webkit-scrollbar-thumb': { backgroundColor: '#E5E0EB', borderRadius: '6px' } }}>
            {(notificationList?.length && notificationList.length > 0) ?
              <Box>
                {notificationList?.map((notification, index) => {
                  const createdTime = new Date(notification.createdTime);
                  const currentTime = new Date();
                  const timeDiffMs = Math.floor(currentTime.getTime() - createdTime.getTime());
                  const timeDiffDays = Math.floor(timeDiffMs / 86400000);
                  const timeDiffHours = Math.floor(timeDiffMs / 3600000);
                  const timeDiffMin = Math.floor(timeDiffMs / 60000);
                  const timeDiffSec = Math.floor(timeDiffMs / 1000);
                  if (timeDiffDays > 0) {
                    notification.createdTime = `${timeDiffDays} ${t("DAYS_AGO")}`;
                  } else if (timeDiffHours > 0) {
                    notification.createdTime = `${timeDiffHours} ${t("HOURS_AGO")}`;
                  } else if (timeDiffMin > 0) {
                    notification.createdTime = `${timeDiffMin} ${t("MINUTES_AGO")}`;
                  } else if (timeDiffSec > 0) {
                    notification.createdTime = `${timeDiffSec} ${t("SECONDS_AGO")}`;
                  }
                  return (
                    <Box sx={{ display: 'block' }} key={index} >
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }} >
                        <Box sx={{ display: 'flex' }}>
                          <Box sx={{ width: '30px' }}>
                            {notification.status === "UNREAD" &&
                              <Box sx={{ width: '30px' }}>
                                <Box sx={{ border: `1px solid ${common.primaryColor}`, width: '16px', height: '16px', borderRadius: '16px', backgroundColor: common.secondaryColor, cursor: 'pointer' }}
                                  onClick={() => {
                                    serviceNotification.updateNotificationStatus(notification.id).then((res: any) => {
                                      if (res.status === 200) {
                                        dispatch(notificationActions.setNotificationCount(unreadNotificationsCount - 1));
                                      }
                                    });
                                  }}
                                ></Box>
                              </Box>}
                          </Box>
                          <Avatar />
                        </Box>
                        <Typography sx={{ pl: '8px', pr: '10px', fontSize: '20px', cursor: 'pointer' }}
                          onClick={() => {
                            navigateToNotification(notification)
                            if (notification.status === 'UNREAD') {
                              serviceNotification.updateNotificationStatus(notification.id).then((res: any) => {
                                if (res.status === 200) {
                                  dispatch(notificationActions.setNotificationCount(unreadNotificationsCount - 1 ));
                                }
                              });
                            }
                          }}
                        >
                          {get(notification, `message${capitalize(lng)}`)}
                        </Typography>
                        <DeleteOutlineIcon sx={{ cursor: "pointer", color: common.errorColor }} onClick={() => openDeleteConfirmModal(notification.id.toString())} />
                      </Box>
                      <Typography sx={{ color: '#A5ACB8', ml: '78px', mb: '12px', fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}
                        onClick={() => {
                          if (notification.status === 'UNREAD') {
                            dispatch(notificationActions.setNotificationCount(unreadNotificationsCount - 1))
                          }
                        }}
                      >
                        {
                          timeDiffDays > 0 ? notification.createdTime : (
                            timeDiffHours > 0 ? notification.createdTime : (
                              timeDiffMin > 0 ? notification.createdTime : (
                                timeDiffSec > 0 ? notification.createdTime : t("notification:JUST_NOW")
                              )
                            )
                          )
                        }
                      </Typography>
                    </Box>
                  )
                })}
              </Box>
              :
              <Box sx={{ display: 'flex', justifyContent: 'center' }}> <Typography>{t("notification:EMPTY")}</Typography></Box>
            }
          </Box>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={openHint}
            anchorEl={hint}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={hintPopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1, color: common.fontWhite, backgroundColor: '#1A1F36' }}>{t("notification:MARK_ALL_READ")}</Typography>
          </Popover>
          <Pagination
            count={count}
            page={currentPage}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Box >
    </>
  );
};

export default NotificationList;