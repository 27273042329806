import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { HomeVariant } from "../types/home";
import s from "./Faq.module.scss";

interface Props {
  variant: HomeVariant;
}
interface Result {
  title: string;
  button: string;
  onClick: () => void;
  questions: {
    q: string;
    a: JSX.Element;
  }[];
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  borderRadius: "32px",
  overflow: "hidden",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
  "& .MuiButtonBase-root": {
    minHeight: "88px",
    padding: "24px 40px 16px 40px",
    "& .MuiAccordionSummary-expandIconWrapper": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      fill: "black",
      color: "black",
      "&:hover": {
        backgroundColor: "rgba(148, 105, 227, 1)",
        fill: "white",
        color: "white",
      },
    },
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<AddIcon sx={{ fontSize: "41px" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
    backgroundColor: "rgba(148, 105, 227, 1)",
    svg: {
      fill: "white",
      color: "white",
    },
  },
  "& .MuiAccordionSummary-content": {
    //   marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0 40px",
  paddingBottom: "24px",

  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const Faq = (props: Props) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const { t } = useTranslation(["home"]);
  const { variant } = props;
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const handleHomeVariant = (e: HomeVariant): Result => {
    switch (e) {
      case HomeVariant.ORGANIZATION:
        return {
          title: t("home:FAQ"),
          button: t("home:ALL_QUESTION"),
          onClick: () => { },
          questions: [
            {
              q: t('home:HOW_DO_I_START_USING_THE_PLATFORM'),
              a: (
                <>
                  {t('home:TO_START_USING_PLATFORM_NEEDS')}
                  <br />
                  <br />
                  <ul>
                    <li>
                      -{t('home:SUBMIT_A_REQUEST')}
                    </li>
                    <li>
                      -{t('home:SIGN_A_CONTRACT_FORM')} {t('home:FORMALIZE_COOPERATION_BY_SIGNING_OFFICIAL_CONTRACT')}
                    </li>
                    <li>
                      -{t('home:MAKE_A_PAYMENT')}
                    </li>
                    <li>
                      -{t('home:GET_ACCOUNTS')}
                    </li>
                  </ul>
                  <br />
                  <br />
                  {t('home:AFTER_THAT_YOU_CAN_START')}
                </>
              ),
            },
            {
              q: t('home:IS_THERE_ANY_SUPPORT_IN_CASE_OF_TECHNICAL_PROBLEMS'),
              a: (
                <>
                  {t('home:IN_CASE_OF_ANY_TECHNICAL_PROBLEMS_YOU_CAN')}
                </>
              ),
            },
            {
              q: t('home:CAN_WE_CONDUCT_ONLINE_EXAMS_AND_TEST'),
              a: (
                <>
                  {t('home:YES_YOU_CAN_CONDUCT_ONLINE_EXAMS_AND_TEST')}
                  {t('home:MOREOVER_WE_OFFER_CONDUCTING_EXAMS_USING_AERO')}
                  {t('home:ONLINE_PROCTORING_ALLOWS_YOU_TO_CONTROL_TESTING_PROCESS')}
                </>
              ),
            },
          ],
        };
      case HomeVariant.EDUCATOR:
        return {
          title: t("home:FAQ"),
          button: t("home:ALL_QUESTION"),
          onClick: () => { },
          questions: [
            {
              q: t('home:WORK_WITH_INDIVIDUALS'),
              a: <>-</>,
            },
            {
              q: t('home:NEED_TO_SIGN_CONTRACT'),
              a: <>-</>,
            },
            {
              q: t('home:HOW_DOES_WITHDRAWAL'),
              a: <>-</>,
            },
          ],
        };
      case HomeVariant.LISTENER:
        return {
          title: t("home:FAQ"),
          button: t("home:ALL_QUESTION"),
          onClick: () => { },
          questions: [
            {
              q: t('home:IS_CERTIFICATE_PROVIDED'),
              a: (
                <>
                  Подумайте, что вас интересует и какие навыки или знания вы
                  хотели бы получить от курса. Это поможет вам узнать, какие
                  темы или области знаний вас привлекают.
                  <br />
                  <br />
                  Понимание вашего текущего уровня знаний по выбранной теме
                  поможет вам выбрать курс, который соответствует вашим
                  потребностям. Некоторые курсы предлагают разные уровни
                  сложности, начиная от начинающих и заканчивая продвинутыми.
                </>
              ),
            },
            {
              q: t('home:IS_THERE_A_REFUND'),
              a: (
                <>
                  Подумайте, что вас интересует и какие навыки или знания вы
                  хотели бы получить от курса. Это поможет вам узнать, какие
                  темы или области знаний вас привлекают.
                  <br />
                  <br />
                  Понимание вашего текущего уровня знаний по выбранной теме
                  поможет вам выбрать курс, который соответствует вашим
                  потребностям. Некоторые курсы предлагают разные уровни
                  сложности, начиная от начинающих и заканчивая продвинутыми.
                </>
              ),
            },
            {
              q: t('home:IS_THERE_A_REFUND'),
              a: (
                <>
                  Подумайте, что вас интересует и какие навыки или знания вы
                  хотели бы получить от курса. Это поможет вам узнать, какие
                  темы или области знаний вас привлекают.
                  <br />
                  <br />
                  Понимание вашего текущего уровня знаний по выбранной теме
                  поможет вам выбрать курс, который соответствует вашим
                  потребностям. Некоторые курсы предлагают разные уровни
                  сложности, начиная от начинающих и заканчивая продвинутыми.
                </>
              ),
            },
          ],
        };
      default:
        return {
          title: t("home:FAQ"),
          button: t("home:ALL_QUESTION"),
          onClick: () => { },
          questions: [
            {
              q: "Предоставляется ли сертификат по завершению курсов?",
              a: (
                <>
                  Подумайте, что вас интересует и какие навыки или знания вы
                  хотели бы получить от курса. Это поможет вам узнать, какие
                  темы или области знаний вас привлекают.
                  <br />
                  <br />
                  Понимание вашего текущего уровня знаний по выбранной теме
                  поможет вам выбрать курс, который соответствует вашим
                  потребностям. Некоторые курсы предлагают разные уровни
                  сложности, начиная от начинающих и заканчивая продвинутыми.
                </>
              ),
            },
            {
              q: "Есть ли возврат денежных средств?",
              a: (
                <>
                  Подумайте, что вас интересует и какие навыки или знания вы
                  хотели бы получить от курса. Это поможет вам узнать, какие
                  темы или области знаний вас привлекают.
                  <br />
                  <br />
                  Понимание вашего текущего уровня знаний по выбранной теме
                  поможет вам выбрать курс, который соответствует вашим
                  потребностям. Некоторые курсы предлагают разные уровни
                  сложности, начиная от начинающих и заканчивая продвинутыми.
                </>
              ),
            },
            {
              q: "Есть ли возврат денежных средств?",
              a: (
                <>
                  Подумайте, что вас интересует и какие навыки или знания вы
                  хотели бы получить от курса. Это поможет вам узнать, какие
                  темы или области знаний вас привлекают.
                  <br />
                  <br />
                  Понимание вашего текущего уровня знаний по выбранной теме
                  поможет вам выбрать курс, который соответствует вашим
                  потребностям. Некоторые курсы предлагают разные уровни
                  сложности, начиная от начинающих и заканчивая продвинутыми.
                </>
              ),
            },
          ],
        };
    }
  };
  const data = handleHomeVariant(variant);
  return (
    <div id="faq" className={s.wrapper}>
      <div className={s.head}>
        <div className={s.title}>{data.title}</div>

        <Button
          sx={{ padding: "8px 22px", borderRadius: "4px" }}
          size="large"
          variant="contained"
          color="primary"
        >
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 400,
              lineHeight: "28px",
            }}
          >
            {data.button}
          </Typography>
        </Button>
      </div>
      <div className={s.content}>
        {data.questions.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              aria-controls={`panel${index}d-content`}
              id={`panel${index}d-header`}
            >
              <div className={s.title}>{item.q}</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={s.text}>{item.a}</div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default Faq;
