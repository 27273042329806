import { useState, useEffect, RefObject, createRef, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AlertColor } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Formik, Form as FormikForm } from "formik";

import { serviceCourse } from "@services/course";
import { CourseContext } from "@context/CourseContext";
import Message from "@components/Message";
import CustomButton from '@ui/Button';
import { DurationType, FlowStatus, IStudyClass, TypeCode } from "@interfaces/index";
import { flowValidationSchema } from "./validation";
import { initialDto } from "./models";
import FormikInput from '@ui/formik/Input';
import DateTimePicker from '@ui/formik/DateTimePicker';
import FormikCheckbox from '@ui/formik/Checkbox';
import { formatDateWithTimezone } from '@utils/functions';
import { serviceSchedule } from '@services/schedule';
import FormikAutocomplete from '@ui/formik/Autocomplete';

interface Props {
  flowId?: string | null;
  handleToggleModal?: (modalState: boolean, message?: string) => void;
  getAllFlows: () => void;
  flowList: Array<IStudyClass>;
  setFlowList: (list: Array<IStudyClass>) => void;
}

const AddFlowForm = ({
  flowId,
  handleToggleModal,
  getAllFlows,
  flowList,
  setFlowList
}: Props) => {
  const { t } = useTranslation(['common', "user", "course"]);
  const { courseId } = useParams<{ courseId: string }>();
  const { common } = useTheme().palette;
  const { courseData } = useContext(CourseContext);
  const currentDate = formatDateWithTimezone(new Date());
  const [scheduleList, setScheduleList] = useState<Array<any>>([]);
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const validationMessageRef: RefObject<HTMLHeadingElement> = createRef();
  const [initialData, setInitialData] = useState<IStudyClass>(initialDto);

  useEffect(() => {
    serviceSchedule.getScheduleList().then(res => {
      if (res.status === 200) {
        setScheduleList(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (flowId) {
      serviceCourse.getStudyClassById(flowId).then((res) => {
        if (res.status === 200) {
          setInitialData({
            ...initialDto, ...res.data,
            useTimetable: courseData?.useTimetable || false
          });
        }
      });
    } else {
      setInitialData({
        ...initialDto,
        courseId: courseId || null,
        useTimetable: courseData?.useTimetable || false
      });
    }
  }, [flowId, courseId, courseData?.useTimetable]);

  useEffect(() => {
    if (courseData?.typeCode === TypeCode.FREE) {
      setInitialData(prev => ({ ...prev, canSelfRegistration: true }));
    }
  }, [courseData?.typeCode]);
  
  useEffect(() => {
    if (courseData?.durationType === DurationType.INDEFINITE) {
      setInitialData(prev => ({ ...prev, sendEmailMessage: false }));
    }
  }, [courseData?.durationType]);

  const scrollToTop = () => validationMessageRef.current?.scrollTo(0, 0);

  return (
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: "21px",
            color: "#000000",
            display: "flex",
            alignItems: "center",
            mb: "24px",
            width: '100%'
          }}
          ref={validationMessageRef}
        >
          {flowId ? t('common:actions.EDITING_FLOW') : t('common:actions.ADDING_FLOW')}
        </Typography>
        {messageOpen &&
          <Message
            type={messageType}
            message={message}
            setMessageOpen={setMessageOpen} />
        }
        <Formik
          initialValues={initialData}
          enableReinitialize
          validationSchema={flowValidationSchema(t, courseData?.durationType)}
          onSubmit={(values: IStudyClass) => {
            if (!courseData?.useTimetable) values.scheduleId = null;
            if (!values.id) {
              serviceCourse.createStudyClass(values)
                .then((res) => {
                  if (res.status === 200) {
                    getAllFlows()
                    handleToggleModal?.(false, t('course:messages.FLOW_IS_ADDED'));
                  }
                }).catch(err => {
                  if (err.response.status === 400) {
                    setMessage(t(`course:messages.${err.response.data.code}`));
                    setMessageType('error');
                    setMessageOpen(true);
                    setTimeout(() => {
                      setMessageOpen(false);
                    }, 5000);
                  }
                }).finally(() => {
                  scrollToTop()
                });
            } else {
              serviceCourse.updateStudyClass(values)
                .then((res) => {
                  if (res.status === 200) {
                    const index = [...flowList].findIndex((x) => x.id === values.id);
                    setFlowList([
                      ...flowList.slice(0, index),
                      res.data,
                      ...flowList.slice(index + 1),
                    ]);
                    handleToggleModal?.(false, t('common:messages.CHANGES_SAVED'))
                  }
                }).catch(err => {
                  if (err.response.status === 400) {
                    setMessage(t(`course:messages.${err.response.data.code}`));
                    setMessageType('error');
                    setMessageOpen(true);
                    setTimeout(() => {
                      setMessageOpen(false);
                    }, 3000);
                  }
                }).finally(() => {
                  scrollToTop()
                });
            }
          }}
        >
          {({ submitForm, isValid, dirty, values, setFieldValue }) => (
            <FormikForm>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2
                }}
              >
                <FormikInput
                  label={t('course:COURSE_CAPACITY')}
                  name="capacity"
                  placeholder={t('common:placeholders.TYPE_VALUE')}
                  type="number"
                  isRequired
                  max={5000}
                  min={1}
                  width={courseData?.durationType !== DurationType.INDEFINITE ? '100%' : '200px'}
                />
                {courseData?.typeCode === TypeCode.EDU &&
                  <FormikCheckbox
                    label={t('course:ALLOW_SELF_REGISTRATION_FOR_THE_COURSE')}
                    name="canSelfRegistration"
                    labelPlacement="end"
                    onChange={() => {
                      setFieldValue('regStartDate', null);
                      setFieldValue('regEndDate', null);
                    }}
                  />
                }
                {values.canSelfRegistration &&
                  <Box>
                    {courseData?.durationType !== DurationType.INDEFINITE 
                    ?
                        <Typography fontWeight={500}>{t('course:REGISTRATION_PERIOD')}<span style={{ color: common.errorColor }}>*</span></Typography>
                    :
                        <Typography fontWeight={500} mb={1}>{t('course:REGISTRATION_PERIOD')}</Typography>
                    }
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <DateTimePicker
                        name='regStartDate'
                        label={t('course:FROM')}
                        pickerType='dateTime'
                        labelPostion='left'
                        maxDate={values.regEndDate || ''}
                        minDate={currentDate || ''}
                      />
                      <DateTimePicker
                        name='regEndDate'
                        label={t('course:TO')}
                        pickerType='dateTime'
                        labelPostion='left'
                        minDate={values.regStartDate || currentDate || ''}
                        maxDate={values.startDate || ''}
                      />
                    </Box>
                  </Box>
                }
                {((values.scheduleId && values.useTimetable) ||
                  (!values.scheduleId && values.useTimetable && (values.status === FlowStatus.CREATED || values.status === FlowStatus.WITHDRAWN)) ||
                  (!values.useTimetable && (values.status !== FlowStatus.CREATED && values.status !== FlowStatus.WITHDRAWN) && values.scheduleId)) ?
                  <FormikAutocomplete
                    label={t('dictionary:SCHEDULE')}
                    name="scheduleId"
                    options={scheduleList}
                    isRequired
                    placeholder={t('common:placeholders.CHOOSE_SCHEDULE')}
                    clearable={false}
                  /> :
                  <Box>
                    {courseData?.durationType !== DurationType.INDEFINITE 
                    ?
                        <Typography fontWeight={500}>{t('course:COURSE_PERIOD')}<span style={{ color: common.errorColor }}>*</span></Typography>
                    :
                        <Typography fontWeight={500} mb={1}>{t('course:START_DATE_COURSE')}</Typography>
                    }
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <DateTimePicker
                        name='startDate'
                        label={courseData?.durationType !== DurationType.INDEFINITE ? t('course:FROM') : undefined}
                        pickerType='dateTime'
                        labelPostion='left'
                        maxDate={values.endDate || ''}
                        minDate={values.regEndDate || currentDate || ''}
                      />
                      {
                        courseData?.durationType !== DurationType.INDEFINITE && (
                            <DateTimePicker
                                name='endDate'
                                label={t('course:TO')}
                                pickerType='dateTime'
                                labelPostion='left'
                                minDate={values.startDate || currentDate || ''}
                            />
                        )
                      }
                      
                    </Box>
                  </Box>
                }
                  <FormikCheckbox
                    label={t('course:SEND_MESSAGE_TO_STUDENT_AFTER_SUCCESSFULL_REGISTRATION_TO_COURSE')}
                    name="sendEmailMessage"
                    labelPlacement="end"
                  />
                {values.sendEmailMessage &&
                  <FormikInput
                    name="message"
                    label={t('course:MESSAGE')}
                    isRequired
                    multiline
                    placeholder={t('course:ENTER_MESSAGE')}
                    minRows={5}
                    showSymbolCount
                    minChar={5}
                    maxChar={1000}
                    height='auto'
                  />
                }
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    justifyContent: 'end'
                  }}
                >
                    {
                        courseData?.durationType !== DurationType.INDEFINITE && (
                            <CustomButton
                                backgroundColor={common.btnCancel}
                                fontColor={common.primaryColor}
                                borderRadius="6px"
                                width='146px'
                                height="40px"
                                sx={{
                                alignItems: "center",
                                fontWeight: "500",
                                fontSize: "16px",
                                lineHeight: "24px",
                                }}
                                onClick={() => handleToggleModal?.(false)}
                            >
                                {t('actions.CANCEL')}
                            </CustomButton>
                        )
                    }
                  
                  <CustomButton
                    backgroundColor={common.primaryColor}
                    fontColor={common.fontWhite}
                    borderRadius="6px"
                    width='146px'
                    height="40px"
                    disabled={!(isValid && dirty)}
                    onClick={submitForm}
                    sx={{
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    {flowId ? t('actions.SAVE') : t('actions.ADD')}
                  </CustomButton>
                </Box>
              </Box>
            </FormikForm>
          )}
        </Formik>
      </>
  )
}

export default AddFlowForm;