import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

interface Props {
  text: string;
}

const EmptyDataComponent = ({ text }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2
      }}
    >
      <Avatar
        alt="CourseList-default-bg-img"
        src={require(`@assets/images/emptyDataBg.png`)}
        variant="square"
        sx={{
          height: "200px",
          width: "200px"
        }}
      />
      <Typography
        sx={{
          mx: 10,
          textAlign: 'center'
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default EmptyDataComponent