import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { Box, Grid, Pagination, Typography } from '@mui/material';

import { serviceDict } from '@services/dictionary';
import { serviceCourse } from '@services/course';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import Breadcrumbs from '@components/Breadcrumbs';
import { Categories } from './Categories';
import { FiltersFreeCourse } from './FiltersFreeCourse';
import { FreeCoursesContainer } from './FreeCourseContainer';
import { DictionaryCode, IStudyClass } from '@interfaces/index';
import { FilterTypeCatalogCourse, initialFilterTypeCatalogCourse } from './models';
import { useSearchParams } from 'react-router-dom';
import { CourseFormatTypeEnum } from '@models/course';

interface IProps {
  dtoList: IStudyClass[];
  totalElements: number
}

export const CatalogCourse = () => {
  const [allCourses, setAllCourses] = useState<IProps | null>(null);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { setBreadcrumbs } = useBreadcrumbs();
  const { t } = useTranslation(["freeCourse"]);
  const [filterFree, setFilterFree] = useState<FilterTypeCatalogCourse>(initialFilterTypeCatalogCourse);
  const [filterActive, setFilterActive] = useState(false)
  const isInitialRender = useRef(true);
  const [searchParams, setSearchParams] = useSearchParams()
  const formatType = searchParams.get('formatType')

  useEffect(() => {
    setBreadcrumbs("free_courses", [{
      level: 1,
      page: 'main',
      label: "freeCourse:MAIN",
      path: '/',
      tabNum: null
    },
    {
      level: 2,
      page: 'courses',
      label: "header:COURSES",
      path: null,
      tabNum: null
    }])
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(formatType) {
      setFilterFree(prev => ({...prev, formatType: CourseFormatTypeEnum[formatType]}))
      setSearchParams('')
    }

    serviceCourse.findAllFreeCourses({
      pageNumber: currentPage - 1,
      size: 15,
      filter: filterFree,
      sorting: {}
    }).then((res) => {
      if (res.status === 200) {
        setAllCourses(res.data);
        setCount(Math.ceil(res.data.totalElements / 15))
      }
    })

    const isAnyFilterApplied = Object.keys(filterFree).some(key => {
      const value = filterFree[key];
      return Array.isArray(value) ? value.length > 0 : value !== null;
    });
    setFilterActive(isAnyFilterApplied);

    if (isInitialRender.current) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      isInitialRender.current = false
    } else {
      window.scrollTo({
        top: 140,
        behavior: 'smooth'
      })
    }
  }, [currentPage, filterFree, formatType, setSearchParams]);

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
  };

  const { data: CategoryList } =
    useQuery({
      queryKey: ['categoryList'],
      queryFn: () => serviceDict
        .getDictionaryListByCodePublic(DictionaryCode.CATEGORY),
      retry: 0,
      placeholderData: keepPreviousData,
    });

  return (
    <Box margin="0 auto">
      <Box sx={{ display: "flex", alignItems: 'center', mb: '24px' }}>
        <Breadcrumbs id="free_courses" />
      </Box>
      <Typography fontSize="44px" fontWeight={600} marginBottom="32px">
        {t("freeCourse:ALL_COURSE_PLTA")}
      </Typography>
      <Categories categories={CategoryList?.data} filter={filterFree} setFilter={setFilterFree} />
      <Grid container marginTop="32px">
        <Grid item xs={2}>
          <FiltersFreeCourse filter={filterFree} setFilter={setFilterFree} />
        </Grid>
        <Grid item xs={10} paddingLeft={3}>
          <FreeCoursesContainer courses={allCourses?.dtoList} coursesLength={allCourses?.totalElements} setFilter={setFilterFree} filterActive={filterActive} />
        </Grid>
      </Grid>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
        <Pagination
          count={count}
          shape="rounded"
          boundaryCount={2}
          siblingCount={1}
          onChange={(_, value: number) => handlePageChange(value)}
          color='secondary'
        />
      </Box>
    </Box>
  )
}
