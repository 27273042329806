import { useQuery } from "@tanstack/react-query";
import { useTranslation } from 'react-i18next';
import { Box, Typography } from "@mui/material";

import { serviceCourse } from '@services/course';
import CourseAuthorCard from './CourseAuthorCard';

const EduCourseAuthors = ({ courseId }) => {
  const { t } = useTranslation(["course"]);
  const { data: authorList } =
    useQuery({
      queryKey: ['courseAuthorList', courseId],
      queryFn: () => serviceCourse.getCourseAuthors(courseId || ""),
      retry: 0,
    });
  return (
    <Box marginBottom="70px">
      <Typography fontSize="32px" fontWeight={700} marginBottom="24px">
        {t("course:AUTHOR_COURSE")}
      </Typography>
      {authorList?.data?.map((tutor) => {
        return (
          <CourseAuthorCard
            key={tutor?.userId}
            userId={tutor?.userId || ""}
            isAuthor
          />
        );
      })}
    </Box>
  );
}

export default EduCourseAuthors;
