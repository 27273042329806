import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import { IQuestion } from "@interfaces/index";
import Clear from "@mui/icons-material/Clear";
import { Box, FormControl, IconButton } from '@mui/material';
import FormikSelect from '@ui/formik/Select';
import { TextEditor } from '@ui/formik/TextEditor';
import { FieldArrayRenderProps, useFormikContext } from "formik";
import { IQuestionsFormikContext } from "../Questions";
import { useTranslation } from "react-i18next";

interface ISequenceProps {
    arrayHelpers: FieldArrayRenderProps,
    index: number
    quizIndex: number;
}

export const Sequence = ({ index, arrayHelpers, quizIndex }: ISequenceProps) => {
    const { values: formikValues, setValues } = useFormikContext<IQuestionsFormikContext>();
    const values = formikValues.data[quizIndex]
    const { t } = useTranslation(["course"]);

    const deleteAnswer = () => {
        return new Promise((resolve, reject) => {
            arrayHelpers.remove(index)
            return resolve({})
        })
    }

    const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
        deleteFunc: deleteAnswer,
        modalTitle: t('course:messages.ARE_YOU_SURE_TO_DELETE_ANSWER_OPTION')
    })

    return (
        <Box sx={{ mb: '24px', width: '70%', display: 'flex', alignItems: 'center' }}>
            {DeleteModal}
            <Box sx={{ mr: '32px', width: "30%", minWidth: '150px' }}>
                <FormControl fullWidth >
                    <FormikSelect
                        name={`data.${quizIndex}.answers.${index}.order`}
                        label=""
                        options={values.answers?.map((el, i) => ({
                            id: i + 1,
                            nameRu: i + 1,
                            nameEn: i + 1,
                            nameKz: i + 1,
                        }))}
                    />
                </FormControl>
            </Box>
            <Box sx={{ mr: '32px' }}>
                <TextEditor name={`data.${quizIndex}.answers.${index}.answer`} label='' />
            </Box>
            {/* <Clear sx={{ width: '25px', height: '25px', ml: '15px' }} /> */}
            <IconButton onClick={deleteAnswer} >
                <Clear sx={{ width: '25px', height: '25px', ml: '15px' }} />
            </IconButton>
        </Box>
    )
}