import React, { useEffect, useState, useContext } from 'react'
import { AlertColor, Box, Button, Checkbox, Divider, Grid, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, colors, useTheme } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { TagListenerModal } from './TagListenerModal';
import { serviceCourse } from '@services/course';
import { useParams } from 'react-router-dom';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { serviceMember } from '@services/member';
import { EditDayModal } from './EditDayModal';
import { EditSubjectModal } from './EditSubjectModal';
import { ListenerVisitCell } from './ListenerVisitCell';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomAutocomplete from '@ui/Autocomplete';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CourseContext } from "@context/CourseContext";
import { getFlowId } from '@store/journal/journalSelector';
import { useSelector } from 'react-redux';
import { CourseRole } from '@interfaces/index';

interface Props {
  attendanceDateList: any
  setMessage: (message: string) => void;
  setMessageOpen: (open: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  updateLists: () => void;
  filter: any;
  studyDict: any;
  setDisableExportToExcel: (disable: boolean) => void
}

export const VisitTable = ({ attendanceDateList, setMessage, setMessageOpen, setMessageType, updateLists, filter, studyDict, setDisableExportToExcel }: Props) => {
  const { common } = useTheme().palette;
  const { courseId } = useParams();
  const { t } = useTranslation(["common"]);
  const [anchorElSubject, setAnchorElSubject] = useState<HTMLElement | null>(null);
  const [anchorElDay, setAnchorElDay] = useState<HTMLElement | null>(null);
  const [openTagListenerModal, handleCloseTagListenerModal] = useState<boolean>(false);
  const [openDayModal, handleCloseDayModal] = useState<boolean>(false);
  const [openSubjectModal, handleCloseSubjectModal] = useState<boolean>(false);
  const [firstDayInList, setFirstDayInList] = useState<any>(null);
  const [memberList, setMemberList] = useState<any>([])
  const [subjectList, setSubjectList] = useState<any>([])
  const [currentSubject, setCurrentSubject] = useState<any>(null)
  const [markStatus, setMarkStatus] = useState<any>(null)
  const [countAbsent, setCountAbsent] = useState<any>({ id: 1, name: t('course:ABSENSES_FOR_SELECTED_DAY') });
  const [countUnmarked, setCountUnmarked] = useState<any>([])
  const [updateListSubject, setUpdateListSubject] = useState<boolean>(false)
  const [attendanceDateListOptions, setattendanceDateListOptions] = useState<any>([])
  const lng = localStorage.getItem("i18nextLng") || "kz";
  const { courseData } = useContext(CourseContext);
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const flowId = useSelector(getFlowId);
  const handleClickSubject = (event: React.MouseEvent<HTMLElement>, subject) => {
    setAnchorElSubject(event.currentTarget);
    setCurrentSubject(subject)
  };

  const handleCloseSubject = () => {
    setAnchorElSubject(null);
    setCurrentSubject(null);
  };

  useEffect(() => {
    setFirstDayInList({ id: 0, date: attendanceDateList[0] })
    setattendanceDateListOptions(attendanceDateList.map((date, index) => ({ id: index, date: date })))
  }, [attendanceDateList])

  useEffect(() => {
    const paginationMemberConfig = {
      pageNumber: 0,
      size: 100,
      filter: { roleType: CourseRole.LISTENER, classId: flowId, ...filter },
      sorting: {}
    }
    serviceMember.getPaginatedMemberList(paginationMemberConfig).then((res) => {
      setMemberList(res.data.dtoList)
    })
  }, [flowId, filter])

  useEffect(() => {
    if (flowId && firstDayInList?.date) {
      serviceCourse.getSubjectList(flowId, firstDayInList.date).then((res) => {
        setSubjectList(res.data)
      })
    }
  }, [firstDayInList, flowId, updateListSubject])

  useEffect(() => {
    setCountUnmarked([]);
  }, [firstDayInList])

  const handleDeleteSubject = () => {
    serviceCourse.deleteSubjectById(currentSubject.id).then((res) => {
      if (res.status === 200) {
        setMessageOpen(true);
        setMessageType("success");
        setMessage(t('common:messages.RECORD_DELETED'));
        setUpdateListSubject(!updateListSubject)
        setTimeout(() => {
          setMessageOpen(false);
        }, 3000)
      }
    })
  }
  const handleDeleteDay = () => {
    const deleteSubjectList = subjectList.map((subject) => subject.id)
    serviceCourse.deleteAttendanceList(deleteSubjectList).then((res) => {
      if (res.status === 200) {
        setMessageOpen(true);
        setMessageType("success");
        setMessage(t('common:messages.RECORD_DELETED'));
        updateLists()
        setUpdateListSubject(!updateListSubject)
        setTimeout(() => {
          setMessageOpen(false);
        }, 3000)
      }
    })
  }
  const handleSubjectMark = (mark: any) => {
    if (isAuthor) {
      setMarkStatus(mark);
      handleCloseTagListenerModal(true)
    }
  }

  const handleUnmarkedSubmit = (unmarked: any, status: string) => {
    serviceCourse.updateMarkAttendance(unmarked, status).then((res) => {
      if (res.status === 200) {
        setMessageOpen(true);
        setMessageType("success");
        setMessage(t('common:messages.DATA_CHANGED_SUCCESSFULLY'));
        setUpdateListSubject(!updateListSubject)
        setCountUnmarked([])
        setTimeout(() => {
          setMessageOpen(false);
        }, 3000)
      }
    })
  }

  return (
    <Box>
      <TableContainer sx={{
        marginTop: "20px",
        overflowX: "scroll",
        '&::-webkit-scrollbar': { width: '2px' },
        '&::-webkit-scrollbar-thumb': { backgroundColor: '#E5E0EB', borderRadius: '6px' }
      }}>
        <Table sx={{ border: "1px solid #E5E0EB" }}>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2} sx={{ border: "1px solid #E5E0EB" }}>
                {t('enum:LISTENERS')}
              </TableCell>
              {subjectList?.length > 0 && attendanceDateListOptions.length > 0 && <TableCell align="center" sx={{ border: "1px solid #E5E0EB" }} colSpan={subjectList?.length}>
                <Box display="flex" gap={1} alignItems="center" marginBottom="5px" minWidth="300px">
                  <CustomAutocomplete
                    sx={{ width: "180px", marginLeft: "20%" }}
                    name="dateList"
                    clearable={false}
                    customIconDisabled={false}
                    value={firstDayInList}
                    valueAsObject
                    options={attendanceDateListOptions}
                    onChange={(_, value) => setFirstDayInList(value)} />
                  {isAuthor &&
                    <Box>
                      <ModeEditOutlineOutlinedIcon
                        sx={{ color: "#000", cursor: 'pointer', fontSize: "20px" }}
                        onClick={() => {
                          handleCloseDayModal(true)
                          setAnchorElDay(null)
                        }} />
                      <DeleteOutlineOutlinedIcon sx={{ color: common.errorColor, cursor: 'pointer', fontSize: "20px" }} onClick={() => handleDeleteDay()} />
                    </Box>}
                </Box>
                <Grid container>{subjectList?.map((subject) => (
                  <Grid item xs={12 / subjectList?.length} alignItems="center" key={subject.id}>
                    <Grid>
                      {subject.name ? subject.name : studyDict.find(dict => dict.id === subject.classTypeId)[`shortName${capitalize(lng)}`]}
                      <MoreHorizIcon
                        onClick={(e: any) => { if (isAuthor) handleClickSubject(e, subject) }}
                        sx={{ cursor: "pointer", color: !isAuthor ? '#0000001F' : common.primaryColor }} />
                    </Grid>
                  </Grid>
                ))}</Grid>
              </TableCell>}
              <TableCell colSpan={2} sx={{ border: "1px solid #E5E0EB" }}>
                <Box display="flex" alignItems="center">
                  <CustomAutocomplete
                    sx={{ background: "#fff", width: "370px", margin: "0px" }}
                    name='status'
                    inputBgColor='#fff'
                    clearable={false}
                    customIconDisabled={false}
                    value={countAbsent}
                    valueAsObject
                    onChange={(_, value) => setCountAbsent(value)}
                    options={[{ id: 1, name: t('course:ABSENSES_FOR_SELECTED_DAY') }, { id: 2, name: t('course:ABSENSES_FOR_ENTIRE_COURSE') }]}
                  />
                </Box>
                <Divider />
                <Grid container >
                  <Grid item xs={6} paddingLeft="15%">
                    {t('course:IN_DAYS')}
                  </Grid>
                  <Grid item xs={6} paddingLeft="15%">
                    {t('course:IN%')}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {memberList.map((member, index) => {
              return (
                <TableRow key={index}>
                  <TableCell size="small" sx={{ border: "1px solid #E5E0EB" }}>{member?.userFullName}</TableCell>
                  <ListenerVisitCell
                    firstDayInList={firstDayInList?.date}
                    id={member.id}
                    handleSubjectMark={handleSubjectMark}
                    countAbsent={countAbsent.id}
                    setCountUnmarked={setCountUnmarked}
                    countUnmarked={countUnmarked}
                    setDisableExportToExcel={setDisableExportToExcel}
                    updateLists={updateLists}
                  />
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} gap={2} marginTop="50px">
        <Box display="flex" gap={1} alignItems="center">
          <Box padding={"0px 8px"} sx={{ color: "#fff", background: common.primaryColor }} borderRadius="6px">{countUnmarked.length}</Box>
          <Typography>{t('common:statuses.MARKED')}</Typography>
        </Box>
        <Box display="flex" gap={1} alignItems="center">
          <Box
            padding="2px 30px"
            border={`1px solid ${countUnmarked.length > 0 ? "#3BCA23" : "#c1c1c1"}`}
            borderRadius="6px"
            sx={{ cursor: "pointer" }}
            onClick={() =>countUnmarked.length > 0 && handleUnmarkedSubmit(countUnmarked, "PRESENT")}>
            <CheckOutlinedIcon sx={{ color: countUnmarked.length > 0 ? "#3BCA23" : "#c1c1c1", fontSize: "16px" }} />
          </Box>
          <Typography>{t('common:statuses.PRESENT')}</Typography>
        </Box>
        <Box display="flex" gap={1} alignItems="center">
          <Box
            padding="2px 30px"
            border={`1px solid ${countUnmarked.length > 0 ? common.errorColor : "#c1c1c1"}`} borderRadius="6px"
            sx={{ cursor: "pointer" }}
            onClick={() => countUnmarked.length > 0 &&handleUnmarkedSubmit(countUnmarked, "ABSENT")}>
            <ClearOutlinedIcon sx={{ color: countUnmarked.length > 0 ? common.errorColor : "#c1c1c1", fontSize: "16px" }} />
          </Box>
          <Typography>{t('common:statuses.ABSENT')}</Typography>
        </Box>
        <Box display="flex" gap={1} alignItems="center">
          <Box
            padding="2px 30px"
            border={`1px solid ${countUnmarked.length > 0 ? "#FFCA0E" : "#c1c1c1"}`} borderRadius="6px"
            sx={{ cursor: "pointer" }}
            onClick={() =>countUnmarked.length > 0 && handleUnmarkedSubmit(countUnmarked, "WORKED")}>
            <CircleOutlinedIcon sx={{ color: countUnmarked.length > 0 ? "#FFCA0E" : "#c1c1c1", fontSize: "16px" }} />
          </Box>
          <Typography>{t('common:statuses.MADE_UP')}</Typography>
        </Box>
      </Box>

      <Popover
        open={Boolean(anchorElSubject)}
        anchorEl={anchorElSubject}
        onClose={handleCloseSubject}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 1, cursor: "pointer" }} onClick={() => {
          handleCloseSubjectModal(true)
          setAnchorElSubject(null)
        }}>
          {t('common:actions.EDIT')}
        </Typography>
        <Typography sx={{ p: 1, cursor: "pointer" }} onClick={() => {
          handleDeleteSubject()
          setAnchorElSubject(null)
        }}>
          {t('common:actions.DELETE')}
        </Typography>
      </Popover>
      <TagListenerModal
        markStatus={markStatus}
        openTagListenerModal={openTagListenerModal}
        handleCloseTagListenerModal={() => handleCloseTagListenerModal(false)}
        setMessageOpen={setMessageOpen}
        setMessageType={setMessageType}
        setMessage={setMessage}
        updateLists={() => setUpdateListSubject(!updateListSubject)}
      />
      <EditDayModal
        openDayModal={openDayModal}
        handleCloseDayModal={() => handleCloseDayModal(false)}
        currentDay={firstDayInList?.date}
        setMessageOpen={setMessageOpen}
        setMessageType={setMessageType}
        setMessage={setMessage}
        updateLists={updateLists}
        subjectList={subjectList} />
      <EditSubjectModal
        openSubjectModal={openSubjectModal}
        handleCloseSubjectModal={() => handleCloseSubjectModal(false)}
        currentDay={firstDayInList?.date}
        setMessageOpen={setMessageOpen}
        setMessageType={setMessageType}
        setMessage={setMessage}
        updateLists={() => setUpdateListSubject(!updateListSubject)}
        currentSubject={currentSubject} />
    </Box>)
}
