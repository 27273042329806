import { MouseEvent, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Formik, Form as FormikForm } from "formik";

import CustomModal from '@components/Modal';
import { taskFormats } from '@utils/enums';
import CustomButton from '@ui/Button';
import FormikAutocomplete from '@ui/formik/Autocomplete';
import { ITaskFormat } from '@interfaces/index';
import { taskFormatInitialDto } from './models';
import { CourseContext } from "@context/CourseContext";

type handleToggleModalFunctionType = (modalState: boolean) => void;
interface Props {
  openModal: boolean;
  handleToggleModal: handleToggleModalFunctionType;
  sectionId: string;
  themeId: string;
  courseStatus?: string;
}

const AddTask = ({ openModal, handleToggleModal, sectionId, themeId, courseStatus }: Props) => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const { t } = useTranslation(["common", "course"]);
  const { courseData } = useContext(CourseContext);

  return (
    <CustomModal
      open={openModal}
      handleToggleModal={handleToggleModal}
      width={494}
      showCloseIcon
    >
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "36px",
            color: "#000000",
            display: "flex",
            alignItems: "center",
          }}
        >
          {t("course:task.ADD_TASK")}
        </Typography>
        {courseStatus === 'PUBLISHED' &&
          <Box sx={{
            display: 'flex',
            width: '100%',
            p: '10px',
            backgroundColor: '#fff',
            borderRadius: '12px',
            alignItems: 'center',
            gap: 2,
            border: `1px solid ${common.borderSecondary}`,
            mt: 1,
            textAlign: 'justify'
          }}>
            <Typography sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '20px', color: common.primaryColor }}>
              {t('course:hints.NON_EVALUATED_TASKS_IN_PUBLISHED_COURSE')}
            </Typography>
          </Box>
        }
        <Formik
          initialValues={taskFormatInitialDto}
          enableReinitialize
          validationSchema={null}
          onSubmit={(values: ITaskFormat) => {
            if (sectionId !== "new") values.sectionId = sectionId;
            if (themeId !== "new") values.themeId = themeId;
            navigate(`/myCourses/${courseId}/tasks/new/${values.format}/${sectionId}/${themeId}`);
          }}
        >
          {({ submitForm, dirty, isValid }) => (
            <FormikForm>
              <Box sx={{ mt: { xs: '4px', sm: '4px', md: '8px', lg: '16px', xl: '24px' } }}>
                <FormikAutocomplete
                  name="format"
                  label={t("course:task.FORMAT_TASK")}
                  options={courseData.formatType === 'ASYNC' ? [taskFormats.find(format => format.text === 'QUIZ')] : taskFormats} // только "Тест" если формат курса - самостоятельное обучение
                  isRequired
                  isInputEnterable={false}
                />
              </Box>
              <Box
                sx={{
                  mt: "24px",
                  display: "flex",
                  gap: 1
                }}
              >
                <CustomButton
                  backgroundColor={common.btnCancel}
                  fontColor={common.primaryColor}
                  borderRadius="6px"
                  height="40px"
                  sx={{
                    alignItems: "center",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={() => handleToggleModal(false)}
                >
                  {t("common:actions.CANCEL")}
                </CustomButton>
                <CustomButton
                  backgroundColor={common.primaryColor}
                  fontColor={common.fontWhite}
                  borderRadius="6px"
                  height="40px"
                  sx={{
                    alignItems: "center",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  disabled={!(dirty && isValid)}
                  onClick={submitForm}
                >
                  {t("common:actions.FURTHER")}
                </CustomButton>
              </Box>
            </FormikForm>
          )}
        </Formik>
      </>
    </CustomModal>
  )
}

export default AddTask