/* tslint:disable */
/* eslint-disable */
/**
 * course service
 * <b>use example:</b> http://{host}:4044/course/{this service rest endpoints}
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Course } from './course';
import { CourseContentSection } from './course-content-section';
import { CourseMember } from './course-member';
import { CourseTaskMaterial } from './course-task-material';
import { CourseTaskQuiz } from './course-task-quiz';
import { CourseTheme } from './course-theme';
/**
 * Задание
 * @export
 * @interface CourseTask
 */
export interface CourseTask {
    /**
     * 
     * @type {string}
     * @memberof CourseTask
     */
    id?: string;
    /**
     * 
     * @type {Course}
     * @memberof CourseTask
     */
    course?: Course;
    /**
     * 
     * @type {CourseContentSection}
     * @memberof CourseTask
     */
    section?: CourseContentSection;
    /**
     * 
     * @type {CourseTheme}
     * @memberof CourseTask
     */
    theme?: CourseTheme;
    /**
     * 
     * @type {CourseTaskQuiz}
     * @memberof CourseTask
     */
    quiz?: CourseTaskQuiz;
    /**
     * Наименование
     * @type {string}
     * @memberof CourseTask
     */
    name: string;
    /**
     * Описание
     * @type {string}
     * @memberof CourseTask
     */
    description?: string;
    /**
     * Тип
     * @type {string}
     * @memberof CourseTask
     */
    type: CourseTaskTypeEnum;
    /**
     * Задание относится к
     * @type {string}
     * @memberof CourseTask
     */
    parentType: CourseTaskParentTypeEnum;
    /**
     * Формат задания
     * @type {string}
     * @memberof CourseTask
     */
    format: CourseTaskFormatEnum;
    /**
     * Балл
     * @type {number}
     * @memberof CourseTask
     */
    point?: number;
    /**
     * Ограничивать доступ к заданию
     * @type {boolean}
     * @memberof CourseTask
     */
    isCloseAccess?: boolean;
    /**
     * Дата открытия доступа
     * @type {string}
     * @memberof CourseTask
     */
    dateOfAccess?: string;
    /**
     * Способ предоставления ответов
     * @type {string}
     * @memberof CourseTask
     */
    answerType?: CourseTaskAnswerTypeEnum;
    /**
     * Указать определенные форматы для загрузки
     * @type {boolean}
     * @memberof CourseTask
     */
    hasAnswerTypeFileFormats?: boolean;
    /**
     * Формат файла в ответе
     * @type {Array<string>}
     * @memberof CourseTask
     */
    answerTypeFileFormats?: Array<string>;
    /**
     * Без оценивания
     * @type {boolean}
     * @memberof CourseTask
     */
    isNoMark?: boolean;
    /**
     * Не учитывать задание при расчете итоговой оценки за курс
     * @type {boolean}
     * @memberof CourseTask
     */
    isExcludeTotalMark?: boolean;
    /**
     * Уведомлять слушателей об изменении содержания задания
     * @type {boolean}
     * @memberof CourseTask
     */
    isNotifyStudent?: boolean;
    /**
     * Статус задания
     * @type {string}
     * @memberof CourseTask
     */
    status?: CourseTaskStatusEnum;
    /**
     * ID ответственного преподавателя
     * @type {string}
     * @memberof CourseTask
     */
    responsibleMemberId?: string;
    /**
     * Создано кем
     * @type {string}
     * @memberof CourseTask
     */
    createdBy?: string;
    /**
     * Дата создания
     * @type {Date}
     * @memberof CourseTask
     */
    createdAt?: Date;
    /**
     * 
     * @type {Array<CourseTaskMaterial>}
     * @memberof CourseTask
     */
    materials?: Array<CourseTaskMaterial>;
    /**
     * 
     * @type {CourseMember}
     * @memberof CourseTask
     */
    responsibleMember?: CourseMember;
}

/**
    * @export
    * @enum {string}
    */
export enum CourseTaskTypeEnum {
    TASK = 'TASK',
    CONTROL = 'CONTROL'
}
/**
    * @export
    * @enum {string}
    */
export enum CourseTaskParentTypeEnum {
    COURSE = 'COURSE',
    SECTION = 'SECTION',
    THEME = 'THEME'
}
/**
    * @export
    * @enum {string}
    */
export enum CourseTaskFormatEnum {
    QUIZ = 'QUIZ',
    ORAL = 'ORAL',
    WRITTEN = 'WRITTEN'
}
/**
    * @export
    * @enum {string}
    */
export enum CourseTaskAnswerTypeEnum {
    FILE = 'FILE',
    TEXT = 'TEXT'
}
/**
    * @export
    * @enum {string}
    */
export enum CourseTaskStatusEnum {
    SAVED = 'SAVED',
    PUBLISHED = 'PUBLISHED'
}

