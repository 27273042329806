import React, { useEffect, useMemo, useState } from 'react'
import Breadcrumbs from '@components/Breadcrumbs';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import CustomButton from '@ui/Button';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useTranslation } from 'react-i18next';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { MaterialReactTable, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import {
  type MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table"
import AvatarPicture from '@components/AvatarPicture';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import PeriodPopover from '@pages/actionsJournal/PeriodPopover';
import CustomAutocomplete from '@ui/Autocomplete';
import { serviceIntegration } from '@services/integrations';
import { serviceDict } from '@services/dictionary';
import { formatDateWithHourMinute, formatDateWithTimezone } from '@utils/functions';
import { TypeObjectIntegration } from '@interfaces/index';
import { IntegrationLogDetails } from './IntegrationLogDetails';



export const IntegrationLogs = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const { t } = useTranslation(["dictionary", "course", "common", "sideMenu", "integrationLogs"]);
  const { common } = useTheme().palette;
  const [hint, setHint] = useState<HTMLElement | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [filter, setFilter] = useState<{ orgId: string | null, objectType: string | null }>({ orgId: null, objectType: null });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });
  const periodPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHint(event.currentTarget);
  };

  const periodPopoverClose = () => {
    setHint(null);
  };
  const isPeriodOpen = Boolean(hint)

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}.${day}.${month}`;
  }
  useEffect(() => {
    setBreadcrumbs("intagrationLogs", [
      {
        level: 1,
        page: "settings",
        label: "sideMenu:INTEGRATIONS",
        path: "/integrations",
        tabNum: null,
      },
      {
        level: 2,
        page: "integrations",
        label: "integrationLogs:INTEGRATION_LOGS_PLATONUS",
        path: null,
        tabNum: null,
      },
    ]);
    // eslint-disable-next-line
  }, []);
  const { data: integrationLogs } =
    useQuery({
      queryKey: ['integrationLogs', pagination.pageIndex, pagination.pageSize, sorting, filter.objectType, filter.orgId, startDate, endDate],
      queryFn: () => serviceIntegration.getIntegrationLogs({
        limit: pagination.pageSize,
        offset: pagination.pageIndex,
        objectType: filter.objectType,
        orgId: filter.orgId,
        fromDateTime: formatDateWithTimezone(startDate),
        toDateTime: formatDateWithTimezone(endDate),
        sortOrder: sorting?.length > 0 && sorting[0].desc === true ? "desc" : "asc"
      }),
      retry: 0,
      placeholderData: keepPreviousData,
    });

  const { data: organizationList } =
    useQuery({
      queryKey: ['organizationList'],
      queryFn: () => serviceDict.getDictionaryListByCodePublic("BRANCH"),
      retry: 0,
      placeholderData: keepPreviousData,
    });

  const handleChangeOrganizationFilter = (value: any) => {
    setFilter({ ...filter, orgId: value ? value : null });

  }
  const handleChangeTypeObjectFilter = (value: any) => {
    setFilter({ ...filter, objectType: value ? value : null });
  }

  const objbectTypeList = [
    {
      id: TypeObjectIntegration.USER, name: t(`integrationLogs:${TypeObjectIntegration.USER}`)
    },
    {
      id: TypeObjectIntegration.COURSE, name: t(`integrationLogs:${TypeObjectIntegration.COURSE}`)
    },
    {
      id: TypeObjectIntegration.EDU_PROGRAM, name: t(`integrationLogs:${TypeObjectIntegration.EDU_PROGRAM}`)
    },
    {
      id: TypeObjectIntegration.MEMBER, name: t(`integrationLogs:${TypeObjectIntegration.MEMBER}`)
    },
    {
      id: TypeObjectIntegration.SCHEDULE, name: t(`integrationLogs:${TypeObjectIntegration.SCHEDULE}`)
    },
    {
      id: TypeObjectIntegration.STUDY_FORM, name: t(`integrationLogs:${TypeObjectIntegration.STUDY_FORM}`)
    },
    {
      id: TypeObjectIntegration.SUBDIVISION, name: t(`integrationLogs:${TypeObjectIntegration.SUBDIVISION}`)
    }
  ]

  const columns = useMemo<any>(
    () => [
      {
        accessorKey: 'id',
        header: t("integrationLogs:ID_LOGS"),
        enableSorting: false,
        size: 70,
      },
      {
        accessorKey: "orgId",
        header: t("dictionary:ORGANIZATION"),
        size: 270,
        minSize: 190,
        enableSorting: false,
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: common.tableHeaderColor,
            boxShadow: "5px 0 7px -2px rgba(91, 90, 48, 0.1)",
            borderLeft: '1px solid #E8E8E8'
          },
        },
        muiTableBodyCellProps: {
          sx: {
            backgroundColor: "#FFFFFF",
            boxShadow: "12px 0 8px -1px rgba(91, 90, 48, 0.1)",
            borderLeft: '1px solid #E8E8E8'
          },
        },
        Cell: ({ row }) => {
          const orgInfo = organizationList?.data.find((org) => org.id === row?.original?.orgId)
          return <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            {orgInfo?.profilePhotoId ? (
              <AvatarPicture
                id={orgInfo?.profilePhotoId}
                sxProps={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <Avatar sx={{
                width: "36px",
                height: "36px",
              }} />
            )}
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "20px",
                cursor: "pointer",
              }}
            >
              {orgInfo?.nameRu}
            </Typography>
          </Box>
        },
      },

      {
        accessorKey: 'objectType',
        header: t("integrationLogs:TYPE_OBJECT"),
        enableSorting: false,
        size: 150,
        Cell: ({ renderedCellValue }) => (
          <Typography>{t(`integrationLogs:${renderedCellValue}`)}</Typography>
        )
      },
      {
        accessorKey: 'createdAt',
        header: t("integrationLogs:DATE_AND_TIME"),
        enableSorting: true,
        size: 150,
        Cell: ({ renderedCellValue }) => (
          <Typography>{formatDateWithHourMinute(renderedCellValue)}</Typography>
        )
      },
    ], [t, common, organizationList]);


  const table = useMaterialReactTable({
    columns,
    data: integrationLogs?.data.dtoList || [],
    enableSorting: true,
    enableColumnActions: false,
    enableExpandAll: false,
    enableColumnResizing: false,
    enableTopToolbar: false,
    enableRowNumbers: true,
    enableColumnPinning: false,
    enableRowActions: false,
    manualPagination: true,
    manualSorting: true,
    localization: {
      noRecordsToDisplay: t('dictionary:EMPTY'),
      rowsPerPage: t('enum:LINES_PER_PAGE'),
      actions: "",
      expand: "",
      rowNumber: '№',
    },
    displayColumnDefOptions: {
      "mrt-row-expand": {
        size: 30,
        muiTableBodyCellProps: { align: "center" },
      },
    },
    layoutMode: "grid",
    columnResizeMode: "onChange",
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        display: "grid",
        borderRadius: "6px",
        border: "1px solid #E8E8E8"
      },
    },
    muiTableContainerProps: {
      sx: {
        "&::-webkit-scrollbar": { height: "4px", width: "7px" },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#D9D9D9",
          borderRadius: "4px",
        },
      },
    },

    muiTableHeadCellProps: {
      sx: { backgroundColor: common.tableHeaderColor, borderLeft: '1px solid #E8E8E8' },
    },
    muiTableBodyCellProps: {
      sx: { backgroundColor: "#FFFFFF", borderLeft: '1px solid #E8E8E8' },
    },
    mrtTheme: (theme) => ({
      draggingBorderColor: theme.palette.common.primaryColor,
    }),
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
      shape: "rounded",
      variant: "outlined",
      SelectProps: {
        sx: {
          backgroundColor: "#FFFFFF",
          color: common.primaryColor,
          "& .MuiInputBase-root": {
            "& .MuiSelect-icon": { color: common.primaryColor },
          },
        },
      },
      sx: {
        "& .Mui-selected": {
          background: `${common.primaryColor} !important`,
          color: "#FFFFFF",
        },
      },
    },
    renderDetailPanel: ({ row }) => (
      <IntegrationLogDetails stacktrace={row.original.stacktrace} payload={row.original.payload} />
    ),
    onPaginationChange: setPagination,
    state: { pagination, sorting }, //pass the pagination state to the table
    rowCount: integrationLogs?.data.totalElements || 0,
    onSortingChange: setSorting,
    paginationDisplayMode: "pages",
  });

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs id="intagrationLogs" />
      </Box>
      <Box display="flex" justifyContent="space-between" sx={{ mb: "24px", mt: '8px' }}>
        <Typography sx={{ fontSize: "36px", fontWeight: 500, }}>
          {t("integrationLogs:INTEGRATION_LOGS_PLATONUS")}
        </Typography>
        <CustomButton
          width="fit-content"
          backgroundColor={common.primaryColor}
          borderRadius="6px"
          onClick={() => { }}
        >
          <Typography
            sx={{
              color: common.white,
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("common:actions.DOWNLOAD_EXCEL")}
            <FileDownloadOutlinedIcon sx={{ mr: "12px" }} />
          </Typography>
        </CustomButton>
      </Box>
      <Box display="flex" justifyContent="space-between" mb="12px">
        <Box display="flex" gap={1}>
          <CustomAutocomplete
            sx={{ width: { lg: '300px', xs: '100%' } }}
            placeholder={t("dictionary:ORGANIZATION")}
            name="typeCourse"
            options={organizationList?.data || []}
            onChange={(_, value: any) => handleChangeOrganizationFilter(value)}
          />
          <CustomAutocomplete
            sx={{ width: { lg: '200px', xs: '100%' } }}
            placeholder={t("integrationLogs:TYPE_OBJECT")}
            name="typeCourse"
            options={objbectTypeList}
            onChange={(_, value: any) => handleChangeTypeObjectFilter(value)}
          />
        </Box>
        <Box sx={{
          border: `1px solid ${common.borderSecondary}`,
          height: '40px',
          width: startDate && endDate ? '300px' : 'auto',
          backgroundColor: !isPeriodOpen ? 'white' : common.primaryColor,
          color: isPeriodOpen ? 'white' : 'black',
          borderRadius: '9px',
          display: 'flex',
          alignItems: 'center',
          px: '4px',
          cursor: 'pointer',
        }}
          onClick={periodPopoverOpen}
        >
          <CalendarTodayIcon />
          <Typography sx={{
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "20px",
            color: isPeriodOpen ? 'white' : 'black',
            mx: '8px',
          }}>
            {
              (startDate && endDate) ? `${formatDate(startDate)} ${'\u2014'} ${formatDate(endDate)}` : t('common:PERIOD')
            }
          </Typography>
          {
            isPeriodOpen ? (
              <ArrowDropUpIcon sx={{ width: '30px', height: '30px' }} />
            ) : (
              <ArrowDropDownIcon sx={{ width: '30px', height: '30px' }} />
            )
          }
        </Box>
      </Box>
      <PeriodPopover
        isPeriodOpen={isPeriodOpen}
        startDate={startDate}
        endDate={endDate}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        hint={hint}
        periodPopoverClose={periodPopoverClose}
      />
      <MaterialReactTable table={table} />
    </Box>
  )
}
