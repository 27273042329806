import AccordionItem from "@components/AccordionItem";
import {
  CourseRole,
  ITaskFreeCourses,
  IUserSearchItem,
  Role,
} from "@interfaces/index";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import {
  serviceCourse,
  useGetMeetingTasksQuery,
  useGetOrderedThemeListQuery,
} from "@services/course";
import { serviceMember, useGetMemberListMutation } from "@services/member";
import { serviceTask, useGetTaskInfoQuery } from "@services/task";
import CustomButton from "@ui/Button";
import FormikAutocomplete from "@ui/formik/Autocomplete";
import FormikCheckbox from "@ui/formik/Checkbox";
import DatePicker from "@ui/formik/DatePicker";
import DateTimePicker from "@ui/formik/DateTimePicker";
import FormikInput from "@ui/formik/Input";
import FormikRadioGroup from "@ui/formik/RadioGroup";
import FormikSelect from "@ui/formik/Select";
import { participantRoles } from "@utils/enums";
import { formatDateWithTimezone } from "@utils/functions";
import { AlertColor } from "@mui/material/Alert";
import addHours from "date-fns/addHours";
import { Formik, Form as FormikForm, useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { conferenceForm, conferenceFormInitialDto } from "./model";
import { validationSchema } from "./validation";
import { InfoBlock } from "@components/common/InfoBlock/InfoBlock";
import { ReactComponent as WarningHintComponent } from "@assets/svg_files/hintComponent/warning.svg";
import { HintComponent } from "@components/common";
import Message from "@components/Message";


const RevalidateValueConference = () => {
  const { values, resetForm } = useFormikContext<conferenceForm>();
  React.useEffect(() => {
    if (values.isRelatedTopic) {
      resetForm({
        values: {
          ...values,
          relationId: ""
        }
      })
    }
    // eslint-disable-next-line
  }, [values.isRelatedTopic, values.isRelatedTopicCheckbox]);
  return null;
};

export const ConferenceForm = () => {
  const { common } = useTheme().palette;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [initialValue, setInitialValue] = useState<conferenceForm>(
    conferenceFormInitialDto
  );
  const [taskId, setTaskId] = useState<string>("");
  const [meetingPlatform, setMeetingPlatform] = useState<any>([]);
  const [listenerList, setListenerList] = useState<any[]>([]);
  const [educatorList, setEducatorList] = useState<any[]>([]);
  const [assistantList, setAssistantList] = useState<any[]>([]);
  const [curatorList, setCuratorList] = useState<any[]>([]);
  const [examinerList, setExaminerList] = useState<any[]>([]);
  const [userList, setUserList] = useState<IUserSearchItem[]>([]);
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [messageType, setMessageType] = useState<AlertColor>('success');
  const [currentCoordinatorMemberId, setCurrentCoordinatorMemberId] = useState<
    string | null
  >(null);

  const [isThemeAndOral, setIsThemeAndOral] = useState("");
  const [taskIdConference, setTaskIdConference] = useState<{
    taskId: string;
    isRelated: string;
  }>({} as any);
  const { t } = useTranslation([
    "dictionary",
    "course",
    "enum",
    "common",
    "user",
  ]);
  const classesId = String(searchParams.get("classesId"));

  const { courseId, conferenceId } = useParams();

  const { data: taskInfo } = useGetTaskInfoQuery(taskId, {
    skip: taskId === "",
  });

  const handleRoleChange = (roleCode: string) => {
    if (classesId) {
      serviceMember.getMemberList(classesId, [roleCode]).then((res) => {
        if (res.status === 200) {
          setUserList(res.data);
        }
      });
    }
  };
  const handleAddList = useCallback(
    async (taskIdConference: {
      taskId: string;
      isRelated: string;
      setValues: (
        values: React.SetStateAction<conferenceForm>,
        shouldValidate?: boolean | undefined
      ) => void;
      values: conferenceForm;
    }) => {
      if (
        classesId &&
        taskIdConference &&
        taskIdConference.isRelated === "oral"
      ) {
        let listenerListInScope: any[] = [];
        let educatorListInScope: any[] = [];
        try {
          const recipientsResponse =
            await serviceTask.getAllRecipientsByClassId(
              taskIdConference.taskId,
              classesId
            );

          if (recipientsResponse.status === 200) {
            if (recipientsResponse.data?.[0]?.type === "ALL") {
              const listenerResponse = await serviceMember.getMemberList(
                classesId,
                [CourseRole.LISTENER]
              );

              if (listenerResponse.status === 200) {
                setListenerList(listenerResponse.data);
                listenerListInScope = [...listenerResponse.data];
              }
            } else {
              setListenerList(
                recipientsResponse.data?.map((item) => item?.member)
              );
              listenerListInScope = [
                ...recipientsResponse.data?.map((item) => item?.member),
              ];
            }

            const taskRecipientsResponse =
              await serviceCourse.getTaskRecipientsParameters(
                classesId,
                taskIdConference.taskId
              );

            if (taskRecipientsResponse.status === 200) {
              if (taskRecipientsResponse.data !== null) {
                setEducatorList(
                  taskRecipientsResponse.data?.responsibleMembers
                );

                educatorListInScope =
                  taskRecipientsResponse.data.responsibleMembers;
                taskIdConference.setValues({
                  ...taskIdConference.values,
                  memberList: {
                    ...taskIdConference.values.memberList,
                    LISTENER: listenerListInScope?.map((info) => info?.id),
                    TUTOR: educatorListInScope?.map((info) => info?.id),
                  },
                  relationId: taskIdConference.taskId,
                });
              } else {
                setEducatorList([]);
                taskIdConference.setValues({
                  ...taskIdConference.values,
                  memberList: {
                    ...taskIdConference.values.memberList,
                    LISTENER: listenerListInScope?.map((info) => info?.id),
                    TUTOR: [],
                  },
                  relationId: taskIdConference.taskId,
                });
              }
            }
          }
        } catch (error) {
          // Handle errors
          console.error(error);
        }
      }
      if (
        classesId &&
        taskIdConference &&
        taskIdConference.isRelated === "themeId"
      ) {
        let listenerListInScope: any[] = [];
        let educatorListInScope: any[] = [];

        try {
          const listenerResponse = await serviceMember.getMemberList(
            classesId,
            [CourseRole.LISTENER]
          );
          if (listenerResponse.status === 200) {
            setListenerList(listenerResponse.data);
            listenerListInScope = [...listenerResponse.data];
          }

          const educatorResponse =
            await serviceCourse.getThemeRecipientsParameters(
              classesId,
              taskIdConference.taskId
            );
          if (
            educatorResponse.status === 200 &&
            educatorResponse.data !== null
          ) {
            setEducatorList(educatorResponse.data.responsibleMembers);
            educatorListInScope = educatorResponse.data.responsibleMembers;

            taskIdConference.setValues({
              ...taskIdConference.values,
              memberList: {
                ...taskIdConference.values.memberList,
                LISTENER: listenerListInScope?.map((info) => info?.id),
                TUTOR: educatorListInScope?.map((info) => info?.id),
              },
              relationId: taskIdConference.taskId,
            });
          } else {
            setEducatorList([]);
            taskIdConference.setValues({
              ...taskIdConference.values,
              memberList: {
                ...taskIdConference.values.memberList,
                LISTENER: listenerListInScope?.map((info) => info?.id),
                TUTOR: [],
              },
              relationId: taskIdConference.taskId,
            });
          }
        } catch (error) {
          // Handle errors
          console.error(error);
        }
      }
    },
    [classesId]
  );

  const { data: meetingTasks } = useGetMeetingTasksQuery(classesId);

  const [postMemberList, { data: organizerUserList }] =
    useGetMemberListMutation();

  const { data: themeList } = useGetOrderedThemeListQuery(courseId ?? "");

  useEffect(() => {
    if (classesId) {
      postMemberList({
        courseId: classesId,
        bodyData: [
          CourseRole.TUTOR,
          CourseRole.CURATOR,
          CourseRole.ASSISTANT,
          CourseRole.EXAMINER,
        ],
        queryParams: {},
      });
    }
  }, [postMemberList, classesId]);

  useEffect(() => {
    serviceCourse.getMeetingPlatform().then((res) => {
      if (res.status === 200) setMeetingPlatform(res.data);
    });
    if (classesId) {
      serviceCourse.getCourseMe(classesId).then((res) => {
        if (res.status === 200) {
          setCurrentCoordinatorMemberId(res.data.id);
        }
      });
    }
  }, [classesId]);

  const handleSubmit = (values: any) => {
    if (!values.isRelatedTopic) values.themeId = null;
    const platform = meetingPlatform.filter(
      (node) => node.id === Number(values.platform)
    )[0];
    const memberList = listenerList.concat(
      educatorList,
      assistantList,
      curatorList,
      examinerList
    );

    const startDate = `${values.day.split("T")[0]}T${values.startTime.split("T")[1]
      }`;
    const endDate = `${values.day.split("T")[0]}T${values.endTime.split("T")[1]
      }`;
    const meetingBodyData = {
      ...values,
      courseId,
      startDate,
      endDate,
      platform,
      memberList,
      themeId:
        values.isRelatedTopicCheckbox === "themeId" ? values.relationId : "",
      taskId: values.isRelatedTopicCheckbox === "oral" ? values.relationId : "",
    };
    if (conferenceId) {
      serviceCourse.updateMeeting(conferenceId, meetingBodyData).then((res) => {
        if (res.status === 200) {
          setMessageOpen(true);
          setMessage(t('common:messages.DATA_SAVED_SUCCESSFULLY'));
          setMessageType("success");
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setTimeout(() => {
            setMessageOpen(false);
          }, 3000)
        }
      });
    } else {
      serviceCourse.createMeeting(meetingBodyData).then((res) => {
        if (res.status === 200) {
          navigate(-1);
        }
      });
    }
  };

  useEffect(() => {
    if (conferenceId) {
      serviceCourse.getMeetingById(conferenceId).then((res) => {
        setTaskId(res.data?.taskId);
        setInitialValue({
          ...res.data,
          memberList: {
            ASSISTANT: res.data?.memberList
              .filter((item) => item?.roleType === CourseRole.ASSISTANT)
              ?.map((info) => info?.id),
            CURATOR: res.data?.memberList
              .filter((item) => item?.roleType === CourseRole.CURATOR)
              ?.map((info) => info?.id),
            EXAMINER: res.data?.memberList
              .filter((item) => item?.roleType === CourseRole.EXAMINER)
              ?.map((info) => info?.id),
            LISTENER: res.data?.memberList
              .filter((item) => item?.roleType === CourseRole.LISTENER)
              ?.map((info) => info?.id),
            TUTOR: res.data?.memberList
              .filter((item) => item?.roleType === CourseRole.TUTOR)
              ?.map((info) => info?.id),
          },
          platform: `${res.data.platform.id}`,
          day: res.data.startDate,
          startTime: formatDateWithTimezone(new Date(res.data.startDate)),
          endTime: formatDateWithTimezone(new Date(res.data.endDate)),
          isRelatedTopic: res.data.themeId ?? res.data.taskId ? true : false,
          isRelatedTopicCheckbox: res.data.themeId ? "themeId" : "oral",
          relationId: res.data?.themeId || res.data?.taskId,
        });
        setListenerList(
          res.data.memberList.filter(
            (members) => members.roleType === CourseRole.LISTENER
          )
        );
        setEducatorList(
          res.data.memberList.filter(
            (members) => members.roleType === CourseRole.TUTOR
          )
        );
        setCuratorList(
          res.data.memberList.filter(
            (members) => members.roleType === CourseRole.CURATOR
          )
        );
        setAssistantList(
          res.data.memberList.filter(
            (members) => members.roleType === CourseRole.ASSISTANT
          )
        );
        setExaminerList(
          res.data.memberList.filter(
            (members) => members.roleType === CourseRole.EXAMINER
          )
        );
      });
    } else {
      setInitialValue((initialValue) => ({
        ...initialValue,
        day: new Date().toISOString(),
        startTime: formatDateWithTimezone(new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Qyzylorda" }))),
        endTime: formatDateWithTimezone(addHours(new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Qyzylorda" })), 1)),
        coordinatorMemberId: currentCoordinatorMemberId,
        classId: classesId,
      }));
    }
  }, [classesId, conferenceId, currentCoordinatorMemberId, searchParams]);

  return (
    <Box width="70%">
      <Box sx={{ display: "flex", mb: "10px" }}>
        <Typography sx={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <ArrowBackIosIcon
            sx={{ width: "10px", height: "10px", mt: "3px", mx: "9px" }}
          />
          {t("course:conference.BACK_TO_CONFERENCES")}
        </Typography>
      </Box>
      {messageOpen &&
        <Message
          type={messageType}
          message={message}
          setMessageOpen={setMessageOpen}
        />
      }
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ background: "#fff", borderRadius: "6px", padding: "30px" }}
        >
          <Formik
            initialValues={initialValue}
            enableReinitialize
            validationSchema={validationSchema(t)}
            onSubmit={handleSubmit}
          >
            {({ submitForm, dirty, isValid, values, setValues }) => {
              return (
                <FormikForm>
                  <Grid item xs={12}>
                    <RevalidateValueConference />
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{
                        fontWeight: 500,
                        fontSize: "32px",
                        lineHeight: "36px",
                        color: "#000000",
                        display: "flex",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      {conferenceId
                        ? t("common:actions.EDITING_CONFERENCE")
                        : t("common:actions.ADDING_CONFERENCE")}
                    </Typography>
                    <Box sx={{ mb: "12px" }}>
                      <FormikInput
                        label={t("course:task.NAME")}
                        name="title"
                        isRequired
                      />
                    </Box>
                    <Box sx={{ mb: "12px" }}>
                      <FormikSelect
                        label={t("dictionary:PLATFORM")}
                        name="platform"
                        options={meetingPlatform}
                        onChange={(value) => value.target.value === 5 ?
                          setValues({ ...values, link: "https://meet.jit.si" }) :
                          setValues({ ...values, link: "" })}
                        isRequired
                      />
                    </Box>
                    <Box sx={{ mb: "12px" }}>
                      <FormikCheckbox
                        label={t("course:theme.REFERS_TO_THE_TOPIC")}
                        name="isRelatedTopic"
                        labelPlacement="end"
                      />
                    </Box>
                    {values.isRelatedTopic && (
                      <Box sx={{ px: "24px" }}>
                        <FormikRadioGroup
                          name="isRelatedTopicCheckbox"
                          options={[
                            { label: t("Относится к теме"), value: "themeId" },
                            {
                              label: t("Относится к устному заданию"),
                              value: "oral",
                            },
                          ]}

                        />
                        {values.isRelatedTopicCheckbox && (
                          <Box sx={{ mb: "12px" }}>
                            <FormikSelect
                              label={
                                values.isRelatedTopicCheckbox === "themeId"
                                  ? t("course:theme.THEME")
                                  : t("course:task.ORAL_TASK")
                              }
                              name="relationId"
                              options={
                                values.isRelatedTopicCheckbox === "themeId"
                                  ? themeList
                                  : taskInfo && meetingTasks
                                    ? [
                                      ...(meetingTasks as ITaskFreeCourses[]),
                                      taskInfo,
                                    ]
                                    : meetingTasks
                              }
                              isRequired
                              onChange={(e) =>
                                handleAddList({
                                  taskId: e.target.value,
                                  isRelated:
                                    values.isRelatedTopicCheckbox ?? "",
                                  setValues,
                                  values,
                                })
                              }
                            />
                          </Box>
                        )}
                      </Box>
                    )}

                    <Box sx={{ mb: "12px" }}>
                      <FormikInput
                        label={t("enum:LINK")}
                        name="link"
                        isRequired
                        disabled={Number(values.platform) === 5}
                      />
                    </Box>
                    <Grid container sx={{ mb: "12px" }} spacing={2}>
                      {Number(values.platform) !== 5 && <Grid item xs={12} sm={6}>
                        <FormikInput label={t("user:LOGIN")} name="login" />
                      </Grid>}
                      <Grid item xs={12} sm={6}>
                        <FormikInput
                          label={t("user:PASSWORD")}
                          name="password"
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ mb: "12px" }}>
                      <DateTimePicker
                        label={t("course:conference.CONFERENCE_DATE")}
                        name="day"
                        isRequired
                      />
                    </Box>
                    <Grid container sx={{ mb: "12px" }} spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <DatePicker
                          label={t("course:conference.START_TIME")}
                          name="startTime"
                          pickerType="time"
                          isRequired
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePicker
                          label={t("course:conference.END_TIME")}
                          name="endTime"
                          pickerType="time"
                          isRequired
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ mb: "12px" }}>
                      <FormikSelect
                        label={t("course:conference.CONFERENCE_ORGANIZER")}
                        name="coordinatorMemberId"
                        options={organizerUserList}
                        isRequired
                      />
                    </Box>

                    <Box sx={{ mb: "12px" }}>
                      <FormikAutocomplete
                        label={t("user:ROLE")}
                        name="roleType"
                        options={participantRoles}
                        clearable={false}
                        onChange={(value: any) => handleRoleChange(value.id)}
                        isRequired
                        isInputEnterable={false}
                      />
                    </Box>
                    {values.roleType && (
                      <Grid container sx={{ mb: "12px" }} gap={10}>
                        <Grid item xs={9}>
                          <FormikAutocomplete
                            label={t("course:PARTICIPANTS")}
                            name={`memberList.${values.roleType}`}
                            options={userList}
                            isRequired
                            multiple
                            selectAll
                          />
                        </Grid>
                        {values.roleType && (
                          <Grid
                            item
                            xs={2}
                            sx={{ width: "200px", paddingTop: "30px" }}
                          >
                            <CustomButton
                              backgroundColor={common.primaryColor}
                              fontColor={common.fontWhite}
                              borderRadius="6px"
                              height="40px"
                              onClick={() => {
                                values.roleType === Role.LISTENER
                                  ? setListenerList(
                                    values?.memberList.LISTENER.map((id) => {
                                      return userList.filter(
                                        (user) => user.id === id
                                      )[0];
                                    })
                                  )
                                  : values.roleType === CourseRole.TUTOR
                                    ? setEducatorList(
                                      values?.memberList.TUTOR.map((id) => {
                                        return userList.filter(
                                          (user) => user.id === id
                                        )[0];
                                      })
                                    )
                                    : values.roleType === CourseRole.CURATOR
                                      ? setCuratorList(
                                        values?.memberList.CURATOR.map((id) => {
                                          return userList.filter(
                                            (user) => user.id === id
                                          )[0];
                                        })
                                      )
                                      : values.roleType === CourseRole.ASSISTANT
                                        ? setAssistantList(
                                          values?.memberList.ASSISTANT.map((id) => {
                                            return userList.filter(
                                              (user) => user.id === id
                                            )[0];
                                          })
                                        )
                                        : setExaminerList(
                                          values?.memberList.EXAMINER.map((id) => {
                                            return userList.filter(
                                              (user) => user.id === id
                                            )[0];
                                          })
                                        );
                                setValues({ ...values, roleType: "" });
                              }}
                              sx={{
                                alignItems: "center",
                                fontWeight: "500",
                                fontSize: "16px",
                                lineHeight: "24px",
                                marginLeft: "",
                              }}
                            >
                              {t("common:actions.ADD")}
                            </CustomButton>
                          </Grid>
                        )}
                      </Grid>
                    )}

                    {listenerList?.length > 0 && (
                      <Box sx={{ mb: "12px" }}>
                        <InfoBlock
                          userList={listenerList}
                          blockLabel={t("enum:LISTENER")}
                          onChange={setListenerList}
                        />
                      </Box>
                    )}
                    {educatorList?.length > 0 && (
                      <Box sx={{ mb: "12px" }}>
                        <InfoBlock
                          userList={educatorList}
                          blockLabel={t("enum:TUTOR")}
                          onChange={setEducatorList}
                        />
                      </Box>
                    )}
                    {curatorList?.length > 0 && (
                      <Box sx={{ mb: "12px" }}>
                        <InfoBlock
                          userList={curatorList}
                          blockLabel={t("enum:CURATOR")}
                          onChange={setCuratorList}
                        />
                      </Box>
                    )}
                    {assistantList?.length > 0 && (
                      <Box sx={{ mb: "12px" }}>
                        <InfoBlock
                          userList={assistantList}
                          blockLabel={t("enum:ASSISTANT")}
                          onChange={setAssistantList}
                        />
                      </Box>
                    )}
                    {examinerList?.length > 0 && (
                      <Box sx={{ mb: "12px" }}>
                        <InfoBlock
                          userList={examinerList}
                          blockLabel={t("enum:EXAMINER")}
                          onChange={setExaminerList}
                        />
                      </Box>
                    )}
                    {values.isRelatedTopicCheckbox === "oral" &&
                      listenerList?.length === 0 && (
                        <HintComponent
                          leftBorder
                          svg={<WarningHintComponent />}
                          text={t("course:hints.IF_NO_PARTICIPANTS")}
                          textColor="white"
                          backgroundColorContent="#D51A52"
                        />
                      )}
                    <Box
                      sx={{
                        mt: "24px",
                        display: "flex",
                        gap: 3,
                        width: "300px",
                      }}
                    >
                      <CustomButton
                        backgroundColor={common.primaryColor}
                        fontColor={common.fontWhite}
                        fullWidth
                        borderRadius="6px"
                        height="40px"
                        sx={{
                          alignItems: "center",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                        disabled={
                          !(isValid && dirty) ||
                          (values.isRelatedTopicCheckbox === "oral" &&
                            listenerList?.length === 0 && !values?.relationId)
                        }
                        onClick={submitForm}
                      >
                        {t("common:actions.SAVE")}
                      </CustomButton>
                    </Box>
                  </Grid>
                </FormikForm>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );
};
