import { Box, Typography, useTheme } from '@mui/material';

export const IntegrationLogDetails = ({ stacktrace, payload }) => {
  const { common } = useTheme().palette;

  return <Box sx={{ width: "100%", border: `1px solid ${common.borderSecondary}`, borderRadius: "6px", padding: 2, background: "#F9FAFB" }}>
    {stacktrace && <Box><Typography fontSize="14px" fontWeight={500}>Stacktrace</Typography>
      <Typography fontSize="14px" sx={{ color: "#6D737A", wordBreak: "break-all", marginBottom: 2 }}>{stacktrace}</Typography>
    </Box>}
    {payload && <Box>
      <Typography fontSize="14px" fontWeight={500}>Payload</Typography>
      <Typography fontSize="14px" sx={{ color: "#6D737A", wordBreak: "break-all" }}>{payload}</Typography>
    </Box>}
  </Box>;
};
