import axios from "@utils/axios";

export const serviceAero = {
  getTokens: (taskProgressId: string, url: string) =>
    axios.get(`/api/course/proctoring/start/${taskProgressId}?url=${url}`),
  getReports: (taskProgressId: string) =>
    axios.get(`/api/course/proctoring/report/task-progress/${taskProgressId}`),
  getCourseList: (bodyData: any) =>
    axios.post(`/api/course/admin/proctoring/courses`, bodyData),
  getTaskList: (bodyData: any) =>
    axios.post(`/api/course/admin/proctoring/tasks`, bodyData),
  getGradeList: (taskId: string, bodyData: any) =>
    axios.post(`/api/course/admin/proctoring/task/${taskId}/grades`, bodyData),
};
