import React from 'react'
import { Box, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { serviceReports } from '@services/reports';
import AvatarViaUserId from '@components/AvatarViaUserId';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import { useTranslation } from 'react-i18next';

export const EducatorList = () => {
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const { t } = useTranslation(["course", "dictionary", "enum", "user", "common"]);
  const { data: topEducatorList } = useQuery({
    queryKey: ["topEducatorList"],
    queryFn: () =>
      serviceReports.getTopEducatorsList(),
  });

  return (
    <Box sx={{ background: common.white, border: `1px solid ${common.borderSecondary}`, borderRadius: "12px", height: "460px", minWidth: "460px" }}>
      <Grid container margin="20px 16px" alignItems="center">
        <Grid item xs={9.5}>
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>{t("course:REPORT_EDUCATOR")}</Typography>
        </Grid>
        <Grid item xs={2.5}>
          {topEducatorList?.data?.length > 0 && <Box sx={{
            padding: "5px 12px", width: "130px", "&:hover": {
              background: "#F5F5F5",
              borderRadius: "12px"
            }
          }}>
            <Typography sx={{
              color: common.primaryColor, cursor: "pointer",
            }} onClick={() => navigate('/reports/educator')}>{t("course:ALL_LIST")}</Typography>
          </Box>}
        </Grid>
      </Grid>
      <Divider sx={{ margin: "16px 0px" }} />
      {topEducatorList?.data?.length > 0 ? <Table>
        <TableHead>
          <TableRow >
            <Grid container>
              <Grid item xs={7.5}>
                <TableCell sx={{ fontSize: 14, color: "#6D737A", borderBottom: "none" }}>{t("enum:TUTOR")}</TableCell>
              </Grid>
              <Grid item xs={2}>
                <TableCell sx={{ fontSize: 14, color: "#6D737A", borderBottom: "none" }}>{t("enum:COURSE")}</TableCell>
              </Grid>
              <Grid item xs={2.5}>
                <TableCell sx={{ fontSize: 14, color: "#6D737A", borderBottom: "none" }}>{t("enum:LISTENERS")}</TableCell>
              </Grid>
            </Grid>
          </TableRow>
        </TableHead>
        <TableBody>
          {topEducatorList?.data?.map((educator) => {
            return (
              <TableRow key={educator.name}>
                <Grid container>
                  <Grid item xs={7.5}>
                    <TableCell sx={{ fontSize: 16, borderBottom: "none" }} size="small">
                      <Box display="flex" gap={0.5} alignItems="center">
                        <AvatarViaUserId
                          userId={educator?.id || ""}
                          sxProps={{ width: "35px", height: "35px" }}
                        />
                        {educator.name.length > 30 ? educator.name.substring(0, 30) + " ..." : educator.name}
                      </Box>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2}>
                    <TableCell sx={{ fontSize: 16, borderBottom: "none" }} size="small">{educator.coursesCount}</TableCell>
                  </Grid>
                  <Grid item xs={2.5}>
                    <TableCell sx={{ fontSize: 16, borderBottom: "none" }} size="small">{educator.listenersCount}</TableCell>
                  </Grid>
                </Grid>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
        :
        <Box margin="18% 35%">
          <Box sx={{ background: "#F9FAFB", padding: "8px 12px", borderRadius: "50px", width: "80px", height: "80px", marginLeft: "45px", border: "1px solid #E8E8E8" }} alignItems="center">
            <DataSaverOffOutlinedIcon sx={{ mr: 0.8, color: "#6D737A", margin: "20px 15px" }} />
          </Box>
          <Typography sx={{ color: "#6D737A", marginTop: "16px" }}>{t("course:NO_DATA_AVAILABLE")}</Typography>
        </Box>
      }
    </Box>
  )
}
