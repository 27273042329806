import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import { useTheme, styled } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getOnlyDateAndFormat, getTimeFromDate } from '@utils/functions';

interface ExpandMoreProps extends IconButtonProps {
  iconColor: string;
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, iconColor, ...other } = props;
  return <IconButton {...other} sx={{ borderRadius: '6px', p: 0, color: iconColor, cursor: 'pointer' }} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const TaskQuizResult = ({ quizAttemptList, attemptResult, showAnswers, displayGrade, displayRightAnswers, currentAttemptId, getAttemtpResult }) => {
  const { t } = useTranslation(['course', 'common']);
  const { common } = useTheme().palette;
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = (expState: boolean, attemptId: string) => {
    getAttemtpResult(attemptId);
    setExpanded(expState);
  };

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '256px' },
        borderRadius: '12px',
        borderTop: 'solid 12px #0135A7',
        backgroundColor: common.white,
        ml: { xs: 0, md: '26px' },
        py: 1
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, mx: 2 }}>
        <InsertChartOutlinedOutlinedIcon />
        <Typography sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '24px' }}>{t('course:test.TESTING_RESULTS')}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', py: 1 }}>
        {quizAttemptList.length === 0 &&
          <Box
            sx={{ color: '#9C9C9C', display: 'flex', margin: 'auto' }}
          >
            <Typography>{t('course:test.NO_RESULTS')}</Typography>
          </Box>
        }
        {quizAttemptList.map((attempt: any, index: number) => (
          <Box
            key={attempt.id}
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                borderTop: `1px solid ${common.strokePrimary}`,
                borderBottom: index === quizAttemptList.length - 1 ? `1px solid ${common.strokePrimary}` : undefined,
                p: 2,
                "&:hover": {
                  backgroundColor: '#F5F5F5'
                }
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: showAnswers && currentAttemptId === attempt.id ? common.primaryColor : common.black,
                  cursor: 'pointer'
                }}
                onClick={displayRightAnswers ? () => getAttemtpResult(attempt.id, true) : undefined}
              >
                {t('course:task.RESULT')} {attempt.attemptNumber}
              </Typography>
              <ExpandMore
                expand={expanded && currentAttemptId === attempt.id}
                iconColor={expanded && currentAttemptId === attempt.id ? common.primaryColor : common.black}
                onClick={() => handleExpandClick(currentAttemptId === attempt.id ? !expanded : true, attempt.id)}
                aria-expanded={expanded && currentAttemptId === attempt.id}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Box>
            <Collapse in={expanded && currentAttemptId === attempt.id} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {displayGrade &&
                  <Box
                    sx={{
                      display: 'flex',
                      borderTop: index !== quizAttemptList.length - 1 ? `1px solid ${common.strokePrimary}` : undefined
                    }}
                  >
                    <Box
                      sx={{
                        p: 2,
                        width: '50%',
                        borderRight: `1px solid ${common.strokePrimary}`,
                      }}
                    >
                      <Typography fontWeight={500} fontSize='14px'>{attempt.takenPoint}/{attempt.maxPoint}</Typography>
                      <Typography color='#6B7A99' fontSize='12px'>{t('course:SCORES')}</Typography>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        width: '50%'
                      }}
                    >
                      <Typography fontWeight={500} fontSize='14px'>
                        {(attempt.takenPoint * 100 / attempt.maxPoint).toFixed(0)}</Typography>
                      <Typography color='#6B7A99' fontSize='12px'>{t('course:GRADE')}</Typography>
                    </Box>
                  </Box>
                }
                <Box
                  sx={{
                    display: 'flex',
                    borderTop: !displayGrade && index !== quizAttemptList.length - 1 ? `1px solid ${common.strokePrimary}` : undefined
                  }}
                >
                  <Box
                    sx={{
                      p: 2,
                      width: '50%',
                      borderRight: `1px solid ${common.strokePrimary}`,
                    }}
                  >
                    <Typography fontWeight={500} fontSize='14px'>{getTimeFromDate(attemptResult?.startDate)}</Typography>
                    <Typography color='#6B7A99' fontSize='12px'>{t('common:START')}</Typography>
                  </Box>
                  <Box
                    sx={{
                      p: 2,
                      width: '50%'
                    }}
                  >
                    <Typography fontWeight={500} fontSize='14px'>
                      {getTimeFromDate(attemptResult?.endDate)}</Typography>
                    <Typography color='#6B7A99' fontSize='12px'>{t('common:FINISH')}</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    borderTop: `1px solid ${common.strokePrimary}`,
                    borderBottom: index === quizAttemptList.length - 1 ? `1px solid ${common.strokePrimary}` : undefined,
                  }}
                >
                  <Box
                    sx={{
                      p: 2,
                      width: '50%',
                      borderRight: `1px solid ${common.strokePrimary}`,
                    }}
                  >
                    <Typography fontWeight={500} fontSize='14px'>{getOnlyDateAndFormat(attemptResult?.startDate)}</Typography>
                    <Typography color='#6B7A99' fontSize='12px'>{t('common:label.DATE')}</Typography>
                  </Box>
                  <Box
                    sx={{
                      p: 2,
                      width: '50%'
                    }}
                  >
                    <Typography fontWeight={500} fontSize='14px'>
                      {Math.abs(Math.round((new Date(attemptResult?.endDate).getTime() - new Date(attemptResult?.startDate).getTime()) / 60000))} {t('common:MINUTE_SHORT')}
                    </Typography>
                    <Typography color='#6B7A99' fontSize='12px'>{t('common:COMPLETED_IN')}</Typography>
                  </Box>
                </Box>
              </Box>
            </Collapse>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default TaskQuizResult;