import { ResponseFilter, DictionaryFilter } from './../interfaces/filter';
import { DictionaryBaseEntity, DictionaryDTO } from "@interfaces/dictionary";
import { OrdinaryConfig } from "@interfaces/commonConfig";
import { DictionaryCode } from "@interfaces/index";
import { ResponseList } from '@interfaces/responseList';
import API from "./api";

export class DictionaryControllerApi extends API {
  private readonly url = "/dictionary";

  getDictionaryList(dictCode: DictionaryCode, body: ResponseFilter<DictionaryFilter>): Promise<ResponseList<DictionaryDTO>> {
    return this.post(`${this.url}/common/${dictCode}/findAll`, body)
  }
  getDictionaryListByCodePublic(dictCode: DictionaryCode): Promise<DictionaryBaseEntity[]> {
    return this.get(`${this.url}/public/dict/${dictCode}/findAll`)
  }

  getTypeOptionById(id: number): Promise<OrdinaryConfig> {
    return this.get(`${this.url}/common-config/${id}`)
  }
}