
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import { enGB, ru, kk } from "date-fns/locale";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.styles.scss"
import { getTimeFromDate } from "@utils/functions";

const locales = {
  'en': enGB,
  'ru': ru,
  'kz': kk
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const CustomCalendar = ({ list, selecEvent }) => {
  const { i18n } = useTranslation();

  const messages = {
    today: "Сегодня",
    previous: "Пред.",
    next: "След.",
    month: "Месяц",
    week: "Неделя",
    day: "День",
    agenda: "Повестка дня",
    date: "Дата",
    time: "Время",
    event: "Событие",
  };

  const { formats, views } = useMemo(() => ({
    formats: undefined,
    views: [Views.MONTH, Views.WEEK, Views.DAY],
  }), []);

  function CustomEvent({ event }) {
    return (
      <div className={`rbc-event-${event.type}`.toLocaleLowerCase()}>
        <span>{`${getTimeFromDate(event.start)} - ${getTimeFromDate(event.end)}`}</span>
        <div>{event.title}</div>
      </div>
    );
  }

  return (
    <Calendar
      localizer={localizer}
      culture={i18n.language}
      events={list}
      messages={{
        ...messages,
        showMore: (count) => `+${count} больше`
      }}
      startAccessor="start"
      endAccessor="end"
      style={{ height: "500pt" }}
      formats={formats}
      views={views}
      timeslots={1}
      onSelectEvent={selecEvent}
      components={{ event: CustomEvent }}
    />
  )
}

export { CustomCalendar };