import { ChangeEvent, FC } from "react";
import { useFormikContext, useField } from "formik";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { useTheme } from "@mui/material/styles";

import IconHints from "@components/IconHints";

interface Props extends CheckboxProps {
  name: string;
  label: string;
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
  disabled?: boolean;
  isRequired?: boolean;
  hintText?: string;
}

const FormikCheckbox: FC<Props> = ({
  name,
  label,
  labelPlacement = 'start',
  disabled = false,
  isRequired = false,
  hintText,
  onChange
}) => {
  const { common } = useTheme().palette;
  const [field, meta] = useField(name);
  const formikProps = useFormikContext();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (onChange) onChange(e, checked)
    formikProps.setFieldValue(name, checked);
  }

  return (
    <FormControl
      sx={{
        ml: labelPlacement === 'end' ? "-12px" : 0,
        zIndex: 0
      }}
      disabled={disabled}
    >
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={field.value === undefined ? !!field.value : field.value}
            onChange={(event) => handleOnChange(event)}
            sx={{
              "&, &.Mui-checked": {
                color: common.primaryColor,
              },
            }}
          />
        }
        label={
          <Box display='flex' gap={1} alignItems='center'>
            <Typography>
              {label}{isRequired && <span style={{ color: common.errorColor }}>*</span>}
            </Typography>
            {hintText && <IconHints text={hintText} />}
          </Box>
        }
        labelPlacement={labelPlacement}
        sx={{ margin: 0, width: "fit-content" }}
      />
      {meta.touched && Boolean(meta.error) &&
        <FormHelperText error={Boolean(meta.error)} sx={{ px: 1.75 }}>
          {meta.error}
        </FormHelperText>
      }
    </FormControl>
  );
};

export default FormikCheckbox;
