import { IUsersTypeCode, Role, UsersTypeCode } from "@interfaces/index";
import { TabList } from "@mui/lab";
import {  Tab } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface IProps {
  setUsersType?: Dispatch<SetStateAction<string>>;
  onChange?:
    | ((event: React.SyntheticEvent<Element, Event>, value: any) => void)
    | undefined;
}

const TabListUsers: FC<IProps> = memo(({ setUsersType, onChange }) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation();
  const profile = useSelector((store: any) => store.account.profileInfo);
  const [usersTypeList, setUsersTypeList] = useState<IUsersTypeCode[]>([
    {
        id: 1,
        value: UsersTypeCode.INTERNAL
    },
    {
        id: 2,
        value: UsersTypeCode.EXTERNAL
    }
  ]);

  useEffect(() => {
    setUsersType && setUsersType(UsersTypeCode.INTERNAL)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(profile.authorityInfo.role === Role.ADMIN) return null

  return (
    <TabList
      onChange={onChange}
      aria-label="course type tabs"
      sx={{
        "& .MuiTab-root.Mui-selected": {
          color: common.primaryColor,
        },
        "& .MuiTabs-indicator": {
          background: common.borderPrimary,
        },
      }}
    >
      {usersTypeList.map((type) => {
        return (
          <Tab
            key={type.id}
            label={t(`enum:${type.value}_LISTENERS`)}
            value={type.value}
            sx={{ textTransform: "none", color: "#B6B6B6" }}
          />
        );
      })}
    </TabList>
  );
});

export { TabListUsers };
