import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton, useTheme } from '@mui/material'
import East from '@mui/icons-material/East';

import { serviceTask } from '@services/task';
import CustomAutocomplete from '@ui/Autocomplete';
import { ITask } from '@interfaces/index';
import { serviceListener } from '@services/listener';

export const SelectTask = () => {
  const { taskId, status, classId } = useParams();
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const [taskList, setTaskList] = useState<Array<any>>([]);
  const [currentTask, setCurrentTask] = useState<ITask | null>(null);

  useEffect(() => {
    if (classId && taskId) {
      serviceTask.getOrderedTaskList(classId).then((res) => {
        if (res.status === 200) {
          const updatedTaskList = res.data.map((taskInfo) => {
            return {
              ...taskInfo.task,
              id: taskInfo.id,
              taskId: taskInfo.task.id,
              prevId: taskInfo.prevId,
              nextId: taskInfo.nextId
            };
          });
          setTaskList(updatedTaskList);
          setCurrentTask(updatedTaskList.find((taskInfo) => taskInfo.taskId === taskId))
        }
      })
    }
  }, [classId, taskId]);

  const navigateToTaskPage = (progressId: string) => {
    serviceListener.getTaskInfo(progressId).then((res) => {
      if (res.status === 200) navigate(`/myCourses/${status}/${classId}/tasks/${res.data.task.id}`)
    })
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: "10px", mb: 2 }}>
      <IconButton sx={{ backgroundColor: 'white' }} disabled={!currentTask?.prevId}
        onClick={() => navigateToTaskPage(currentTask?.prevId || '')}
      >
        <East sx={{ color: !currentTask?.prevId ? "#C1C1C1" : common.primaryColor, fontSize: '28px', rotate: "180deg" }} />
      </IconButton>
      <CustomAutocomplete
        name="taskId"
        valueAsObject
        value={currentTask}
        options={taskList}
        clearable={false}
        customIconDisabled={false}
        onChange={(_: any, task) => navigate(`/myCourses/${status}/${classId}/tasks/${task.taskId}`)}
      />
      <IconButton sx={{ backgroundColor: 'white' }} disabled={!currentTask?.nextId}
        onClick={() => navigateToTaskPage(currentTask?.nextId || '')}
      >
        <East sx={{ color: !currentTask?.nextId ? "#C1C1C1" : common.primaryColor, fontSize: '28px' }} />
      </IconButton>
    </Box>
  )
}
