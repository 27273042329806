import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { serviceTask } from "@services/task";
import AvatarViaUserId from "@components/AvatarViaUserId";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import { serviceMember } from "@services/member";
import { formatDateWithHourMinute } from "@utils/functions";

export const SwitchListener = ({ setListenerId }) => {
  const { t } = useTranslation(["enum"]);
  const { courseId, recipientId, progressId, pathType,flowId } = useParams();
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const [listenerList, setListenerList] = useState<Array<any>>([]);
  const [lastActivity, setLastActivity] = useState("");

  useEffect(() => {
    if (recipientId) {
      serviceTask.getListenersOfRecipient(recipientId).then((res) => {
        if (res.status === 200) {
          setListenerList(res.data);
        }
      });
    }
  }, [recipientId]);

  const currentListener = listenerList.find(
    (progress) => progress.id === progressId
  );

  useEffect(() => {
    if (currentListener) {
      setListenerId(currentListener?.member.userId);
    }
  }, [currentListener, setListenerId]);

  useEffect(() => {
    if (currentListener?.member.id) {
      serviceMember.getCourseMember(currentListener?.member.id).then((res) => {
        setLastActivity(res.data.lastActivityDate);
      });
    }
  }, [currentListener?.member.id]);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          width: "100%",
          mr: "12px",
        }}
      >
        <AvatarViaUserId
          userId={currentListener?.member.userId || null}
          sxProps={{ width: "55px", height: "55px" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            color: "#7D7D7D",
            fontWeight: 400,
            fontSize: "11px",
            lineHeight: "16px",
          }}
        >
          <Typography>{currentListener?.member.userFullName}</Typography>
          {lastActivity && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography>{t("enum:LAST_ACTIVITY")}</Typography>
              <Typography>{formatDateWithHourMinute(lastActivity)}</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: "28px",
          height: "28px",
          borderRadius: "50%",
          backgroundColor: "#F1EBFA",
          display: "flex",
          justifyContent: "center",
          mr: "12px",
        }}
      >
        <IconButton
          disabled={!currentListener?.prevId}
          onClick={() =>
            navigate(
              `/myCourses/${courseId}/task-assessment/${pathType}/${recipientId}/${currentListener?.prevId}/flows/${flowId}`
            )
          }
        >
          <ArrowBackIosNewIcon
            sx={{
              width: "20px",
              height: "20px",
              color: !currentListener?.prevId ? "#C1C1C1" : common.primaryColor,
              cursor: "pointer",
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: "28px",
          height: "28px",
          borderRadius: "50%",
          backgroundColor: "#F1EBFA",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <IconButton
          disabled={!currentListener?.nextId}
          onClick={() =>
            navigate(
              `/myCourses/${courseId}/task-assessment/${pathType}/${recipientId}/${currentListener?.nextId}/flows/${flowId}`
            )
          }
        >
          <ArrowForwardIosIcon
            sx={{
              width: "20px",
              height: "20px",
              color: !currentListener?.nextId ? "#C1C1C1" : common.primaryColor,
              cursor: "pointer",
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};
