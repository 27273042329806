import { useState, useEffect } from 'react'
import { Grid, Box, Typography } from '@mui/material'

import { serviceDict } from '@services/dictionary';
import CustomAutocomplete from '@ui/Autocomplete';
import FreeCourseCard from './FreeCourseCard';
import { IDictionary } from '@interfaces/index';
import CustomSearch from '@ui/Search';
import { useTranslation } from 'react-i18next';
import { FreeCoursesNotFound } from './FreeCoursesNotFound';
import Button from '@ui/Button';
import { initialFilterTypeCatalogCourse } from './models';

export const FreeCoursesContainer = ({ courses, coursesLength, setFilter, filterActive }) => {
  const { t } = useTranslation(["common", "course", "header", 'freeCourse']);
  const [sortingCourses, setSortingCourses] = useState<any>({ id: 1, name: t('freeCourse:BY_POPULARITY') });
  const [diffLevelList, setDiffLevelList] = useState<IDictionary[]>([]);

  useEffect(() => {
    serviceDict
      .getDictionaryListByCodePublic("COURSE_DIFFICULTY_LEVEL")
      .then((res) => {
        if (res.status === 200) setDiffLevelList(res.data)
      });
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="24px">
        <Typography fontSize="24px" fontWeight={600}>{t("header:COURSES")} ({coursesLength})</Typography>
        <Box display="flex" width="80%" justifyContent="flex-end">
          {filterActive && <Button onClick={() => setFilter(initialFilterTypeCatalogCourse)} borderRadius='8px' width='90px' height='35px' backgroundColor='#8E8E93'>{ t('common:RESET')}</Button>}
          <CustomAutocomplete
            name="sortingCourses"
            sx={{ width: '25%', height: '35px', ml: '10px'}}
            value={sortingCourses}
            options={[
              { id: 1, name: t('freeCourse:BY_POPULARITY') },
              // { id: 2, name: "По рейтингу " },
              // { id: 3, name: "По возрастанию цены" },
              // { id: 4, name: "По убыванию цены" }
            ]}
            clearable={false}
            valueAsObject
            onChange={(_, value) => setSortingCourses(value)} />
        </Box>
      </Box>
      <Box sx={{ margin: "12px 0px" }}>
        <CustomSearch
          placeholder={t('common:placeholders.SEARCH_BY_COURSE_NAME')}
          onChange={(value) => setFilter((prev) => ({ ...prev, search: value }))} />
      </Box>
      <Box display="flex" flexWrap="wrap" gap={2}>
        { coursesLength ? <Grid container spacing={3}>
          { courses?.map((course) => {
            return (
              <FreeCourseCard courseInfo={course} key={course.id} status='planned' diffLevelList={diffLevelList} />
            )
          })}
        </Grid> : <FreeCoursesNotFound setFilter={setFilter}/>}
      </Box>
    </>
  )
}
