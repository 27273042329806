import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
  AlertColor,
  Box,
  Typography,
  useTheme
} from "@mui/material";
import { Formik, Form as FormikForm } from "formik";

import { serviceSchedule } from '@services/schedule';
import CustomModal from '@components/Modal';
import CustomButton from '@ui/Button';
import FormikInput from '@ui/formik/Input';
import DateTimePicker from '@ui/formik/DateTimePicker';
import FormikCheckbox from '@ui/formik/Checkbox';
import { chartValidationSchema } from './validation';

interface Props {
  modalOpen: boolean;
  handleToggleModal: (modalState: boolean) => void;
  isEditing?: boolean;
  selectedChart?: any;
  onSaveSuccess: (chart: any) => any;
  setMessage: (message: string) => void;
  setMessageOpen: (open: boolean) => void;
  setMessageType: (messageType: AlertColor) => void
}
interface FormProps {
  name: string;
  externalId?: string | null;
  startDate?: Date | null,
  endDate?: Date | null,
  regStartDate?: Date | null,
  regEndDate?: Date | null,
  status?: string | boolean,
  canUpdate?: boolean
}
const initialDTO = {
  name: "",
  externalId: null,
  status: false
};
const AddOrEditChartModal = ({ modalOpen, handleToggleModal, isEditing = false, selectedChart, onSaveSuccess, setMessage, setMessageOpen, setMessageType }: Props) => {
  const { t } = useTranslation(['common', "course", "generalSetting", "enum"]);
  const { common } = useTheme().palette;
  const [initialData, setInitialData] = useState<FormProps>(initialDTO);

  useEffect(() => {
    if (isEditing) {
      setInitialData(selectedChart)
    }
  }, [selectedChart, isEditing])
  return (
    <CustomModal
      open={modalOpen}
      handleToggleModal={handleToggleModal}
    >
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: 500,
            fontSize: { sm: "28px", xs: '20px' },
            lineHeight: "36px",
            color: "#000000",
            display: "flex",
            alignItems: "center",
            mb: "24px",
            width: '100%'
          }}
        >
          {isEditing ? t('common:actions.EDITING_SCHEDULE') : t('common:actions.ADDING_SCHEDULE')}
        </Typography>
        <Formik
          initialValues={initialData}
          enableReinitialize
          validationSchema={chartValidationSchema(t)}
          onSubmit={(values) => {
            // во время сабмита значения статусов переводятся в string значение
            if (values.status) {
              values.status = 'ARCHIVED'
            } else {
              values.status = 'SAVED'
            }
            if (!isEditing) values.canUpdate = true
            serviceSchedule.editSchedule(values).then((res) => {
              if (res.status === 200) {
                onSaveSuccess(values)
                if (isEditing) {
                  setMessage(t('common:messages.CHANGES_SAVED'))
                } else {
                  setMessage(t('common:actions.CHART_CREATED'))
                }
                setMessageType('success')
                setMessageOpen(true)
                setTimeout(() => {
                  setMessageOpen(false)
                }, 3000)
              }
            }).catch((err) => {
              setMessageOpen(true);
              setMessage(t("common:messages.ERROR"));
              setMessageType("error");
              setTimeout(() => {
                setMessageOpen(false);
              }, 5000);
            }).finally(() => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
              handleToggleModal(false)
            })
          }}
        >
          {({ values, submitForm, isValid, dirty }) => {
            // статусы приходят как string из Charts.tsx, тут переводятся в boolean
            if (values.status === "ARCHIVED") {
              values.status = true
            } else if (values.status === 'SAVED') {
              values.status = false
            }
            return (
              <FormikForm>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4
                  }}
                >
                  <FormikInput
                    name='name'
                    type='text'
                    label={t('course:task.NAME')}
                    isRequired
                  />
                  <FormikInput
                    name='externalId'
                    type='text'
                    label={t('generalSetting:schedule.EXTERNAL_ID')}
                    disabled
                  />
                  <Box sx={{ display: 'flex', gap: '20px' }}>
                    <DateTimePicker
                      name='startDate'
                      label={t('common:START')}
                      pickerType='dateTime'
                      isRequired
                      popperPlacement='top-start'
                      disabled={isEditing ? !selectedChart?.canUpdate : false}
                    />
                    <DateTimePicker
                      name='endDate'
                      label={t('common:END')}
                      pickerType='dateTime'
                      isRequired
                      popperPlacement='top-end'
                      disabled={isEditing ? !selectedChart?.canUpdate : false}
                    />
                  </Box>
                  {/*Добавить на втором этапе*/}
                  {/* <Box>
                    <Typography>Период регистрации</Typography>
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                      <DateTimePicker name='regStartDate' label='С' pickerType='dateTime' />
                      <DateTimePicker name='regEndDate' label='По' pickerType='dateTime' />
                    </Box>
                  </Box> */}
                  {isEditing &&
                    <FormikCheckbox name='status' label={t('enum:ARCHIVED')} labelPlacement='end' />}
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1
                    }}
                  >
                    <CustomButton
                      backgroundColor={common.btnCancel}
                      fontColor={common.primaryColor}
                      borderRadius="6px"
                      height="40px"
                      sx={{
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                      onClick={() => handleToggleModal(false)}
                    >
                      {t('actions.CANCEL')}
                    </CustomButton>
                    <CustomButton
                      backgroundColor={common.primaryColor}
                      fontColor={common.fontWhite}
                      borderRadius="6px"
                      height="40px"
                      onClick={submitForm}
                      disabled={!(isValid && dirty)}
                      sx={{
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      {isEditing ? t('actions.SAVE') : t('actions.ADD')}
                    </CustomButton>
                  </Box>
                </Box>
              </FormikForm>
            )
          }}
        </Formik>
      </>
    </CustomModal>
  )
}

export default AddOrEditChartModal