import { Box, useTheme, Typography, Divider } from '@mui/material';
import { serviceCourse } from '@services/course'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import format from "date-fns/format"
import { useTranslation } from 'react-i18next';

export const ImportantEventsConferenceCard = ({ conferenceId, status }: { conferenceId: string, status: string }) => {
  const { common } = useTheme().palette;
  const [initialValue, setInitialValue] = useState<any>(null)
  const [themeList, setThemeList] = useState<Array<any>>([])
  const { courseId } = useParams()
  const { t } = useTranslation(["dictionary", "course"]);

  useEffect(() => {
    serviceCourse.getMeetingById(conferenceId).then((res) => setInitialValue(res.data))
  }, [conferenceId])

  useEffect(() => {
    if (courseId) {
      serviceCourse.getOrderedThemeList(courseId).then((res) => {
        setThemeList(res.data);
      })
    }
  }, [courseId]);

  return (
    <Box width="100%" marginBottom="10px">
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="5px">
        <Typography color={common.primaryColor} sx={{ cursor: "pointer" }} fontSize="20px" onClick={() => window.open(`${initialValue?.link}`, "_blank")}>{initialValue?.title}</Typography>
      </Box>
      <Box display="flex" gap={3}>
        {initialValue?.themeId && <Typography sx={{ marginBottom: "5px" }}>{t('course:theme.THEME')}: {themeList.filter((theme) => theme.id === initialValue?.themeId)[0]?.name} </Typography>}
        <Typography sx={{ marginBottom: "5px" }}>{t('dictionary:ORGANIZER')}: {initialValue?.coordinatorUserName}</Typography>
      </Box>
      <Box display="flex" gap={2} alignItems="center" marginBottom="5px">
        <Typography>{t('course:listener.STATUS_CONFERENCE')}: </Typography>{status === "STARTED" ?
          <Box padding="1px 30px" sx={{ background: "#FE1010", color: "white", width: "120px" }} borderRadius="8px">{t('course:conference.STARTED')}</Box>
          : <Box padding="5px 7px" sx={{ background: "#FCB62E", color: "white", width: "150px" }} borderRadius="8px">{t('course:conference.PLANNED')}</Box>}
      </Box>
      {initialValue?.startDate && initialValue?.endDate && <Typography sx={{ marginBottom: "5px" }} color="#708099">{format(new Date(initialValue?.startDate), "dd.MM.yyyy HH:mm")} - {format(new Date(initialValue?.endDate), "dd.MM.yyyy HH:mm")}</Typography>}
      <Divider />
    </Box>
  )
}
