import { CourseResultDto } from "@interfaces/course";
import API from "./api";

export class CourseGradingApi extends API {
	private readonly url = "/course/result/study-class/";

	getResultByStudyClass(classId: string | undefined,): Promise<CourseResultDto> | undefined {
		if (!classId) {
			return undefined;
		}
		return this.get(this.url + classId);
	}
}
