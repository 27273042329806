export enum HomeVariant {
  LISTENER = "LISTENER",
  ORGANIZATION = "ORGANIZATION",
  EDUCATOR = "EDUCATOR",
}

export interface CarouselProps {
  backgroundImage: string;
  title: string;
  text: string;
  button?: string;
  onClick: () => void;
  maxWidth?: string;
  balance?: boolean;
}

export interface CardHome{
  icon?: any;
  title: string;
  text: string;
}

