import { createContext, useContext } from "react";

import { IBreadcrumb } from "@interfaces/index";

interface BreadcrumbsContextSchema {
  setBreadcrumbs: (id: string, breadcrumbs: Array<IBreadcrumb>) => void;
  getBreadcrumbs: (id: string) => Array<IBreadcrumb>;
}

export const BreadcrumbsContext = createContext<BreadcrumbsContextSchema>({} as BreadcrumbsContextSchema);

export const useBreadcrumbs = () => {
  return useContext(BreadcrumbsContext);
};