import React from 'react'
import { Avatar, Box, Grid, Typography, useTheme } from '@mui/material'
import { useQuery } from '@tanstack/react-query';
import { serviceUser } from '@services/user';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HugeiconsTeacher } from "@assets/svg_files/hugeicons_teacher.svg";
import { ReactComponent as PhStudent } from "@assets/svg_files/ph_student.svg";


export const TotalUser = () => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["sideMenu", "common", "dictionary", "profile", "enum"]);
  const { data: totalUser } = useQuery({
    queryKey: ["totalUser"],
    queryFn: () =>
      serviceUser.getTotalUser(),
  });

  return (
    <Box display="flex" gap={2}>
      <Box sx={{ minWidth: "172px", width: "50%", height: "96px", background: common.white, border: `1px solid ${common.borderSecondary}`, borderRadius: "12px", padding: "20px 16px" }}>
        <Box display="flex" gap={2} alignItems="center">
          <Box sx={{ background: "#FBF9FF", padding: "8px 12px", borderRadius: "30px" }} alignItems="center">
            <PhStudent />
          </Box>
          <Box>
            <Typography sx={{ color: "#6D737A", fontSize: "16px" }}>{t("enum:LISTENERS")}</Typography>
            <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>{totalUser?.data?.totalStudent || 0}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ minWidth: "172px", width: "50%", height: "96px", background: common.white, border: `1px solid ${common.borderSecondary}`, borderRadius: "12px", padding: "20px 16px" }}>
        <Box display="flex" gap={2} alignItems="center">
          <Box sx={{ background: "#FBF9FF", padding: "8px 12px", borderRadius: "30px" }} alignItems="center">
            <HugeiconsTeacher />
          </Box>
          <Box>
            <Typography sx={{ color: "#6D737A", fontSize: "16px" }}>{t("enum:TUTORS")}</Typography>
            <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>{totalUser?.data?.totalStaff || 0}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
