import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { isBefore } from 'date-fns';

import { serviceCourse } from '@services/course';
import { ContentType, DurationType } from '@interfaces/index';

interface ISectionSummary {
  themesSum: number;
  evaluatedTasksSum: number;
  selfTasksSum: number;
  estimatedTime: number | null;
}
const ContentCard = ({ content, sectionIndex, setActiveContent, activeContent, sectionNumber, durationType }) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course"]);
  const [sectionSum, setSectionSum] = useState<ISectionSummary | null>(null)

  useEffect(() => {
    if (content?.id) {
      serviceCourse.getSectionSummaryPublic(content.id).then((res) => {
        if (res.status === 200) setSectionSum(res.data)
      })
    }
  }, [content.id])

  if (content.type === ContentType.THEME)
    return (
      <Accordion
        defaultExpanded={true}
        disableGutters={true}
        sx={{
          borderRadius: 1,
        }}
        TransitionProps={{ unmountOnExit: true }}
        onClick={() => setActiveContent(sectionIndex)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Box display="flex" gap={2}>
            <DescriptionOutlinedIcon />
            <Typography sx={{ ":hover": { color: common.primaryColor } }}>
              {content.name}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {content?.shortDescription &&
            <Box sx={{
              pl: 2,
              pr: 2,
              py: 1,
              backgroundColor: common.fontWhite,
              fontSize: "14px"
            }}>
              <Typography >{content.shortDescription}</Typography>
            </Box>
          }
        </AccordionDetails>
      </Accordion>
    )

  return (
    <Accordion
      defaultExpanded={false}
      disableGutters={true}
      sx={{
        backgroundColor: activeContent === sectionIndex ? common.primaryColor : '#F4EEFF',
        borderRadius: 1
      }}
      TransitionProps={{ unmountOnExit: true }}
      onClick={() => setActiveContent(sectionIndex)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: activeContent === sectionIndex ? "#fff" : "#000" }} />}>
        <Box>
          <Typography fontWeight={600} color={activeContent === sectionIndex ? "#fff" : "#000"}>
            {t('course:section.SECTION')} {sectionNumber}: {content.name}
          </Typography>
          <Box display="flex" gap={2} marginTop="8px">
            <Box display="flex" alignItems="center" gap={1}>
              <TextSnippetOutlinedIcon sx={{ color: activeContent === sectionIndex ? "#fff" : "#8E8E93" }} />
              <Typography color={activeContent === sectionIndex ? "#fff" : "#8E8E93"} fontSize="14px">
                {t('course:THEMES')}: {sectionSum?.themesSum}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <TaskOutlinedIcon sx={{ color: activeContent === sectionIndex ? "#fff" : "#8E8E93" }} />
              <Typography color={activeContent === sectionIndex ? "#fff" : "#8E8E93"} fontSize="14px" >
                {t('course:task.ASSESSED_TASKS')}: {sectionSum?.evaluatedTasksSum}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <QuizOutlinedIcon sx={{ color: activeContent === sectionIndex ? "#fff" : "#8E8E93" }} />
              <Typography color={activeContent === sectionIndex ? "#fff" : "#8E8E93"} fontSize="14px">
                {t('course:task.SELF_TEST_TASKS')}: {sectionSum?.selfTasksSum}
              </Typography>
            </Box>
            {durationType === DurationType.INDEFINITE &&
              <Box display="flex" alignItems="center" gap={1}>
                <AccessTimeIcon sx={{ color: activeContent === sectionIndex ? "#fff" : "#8E8E93" }} />
                <Typography color={activeContent === sectionIndex ? "#fff" : "#8E8E93"} fontSize="14px">
                  {t('common:HOURS')}: {sectionSum?.estimatedTime ? Math.floor(sectionSum?.estimatedTime / 60) : 0}
                </Typography>
              </Box>
            }
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
        {content?.shortDescription &&
          <Box sx={{
            pl: 2,
            pr: 2,
            py: 1,
            borderBottom: `1px solid #CACACA`,
            backgroundColor: common.fontWhite,
            fontSize: "14px"
          }}>
            <Typography >{content.shortDescription}</Typography>
          </Box>
        }
        {content.children.length > 0 && content.children.filter((node) => (node.type === ContentType.THEME)).map((child, index) => {
          return (
            <Accordion
              key={index}
              defaultExpanded={true}
              disableGutters={true}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" gap={2}>
                  <DescriptionOutlinedIcon />
                  {child.dateOfAccess && isBefore(new Date(), new Date(child.dateOfAccess)) ? <Typography >
                    {child.name}
                  </Typography> :
                    <Typography sx={{ ":hover": { color: common.primaryColor } }}>
                      {child.name}
                    </Typography>
                  }
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {child?.shortDescription &&
                  <Box sx={{
                    pl: 2,
                    pr: 2,
                    py: 1,
                    backgroundColor: common.fontWhite,
                    fontSize: "14px"
                  }}>
                    <Typography >{child.shortDescription}</Typography>
                  </Box>
                }
              </AccordionDetails>
            </Accordion>
          )
        })}
      </AccordionDetails>
    </Accordion>
  )
}

export default ContentCard;
