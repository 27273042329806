/* tslint:disable */
/* eslint-disable */
/**
 * course service
 * <b>use example:</b> http://{host}:4044/course/{this service rest endpoints}
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Дополнительные ресурсы к курсу
 * @export
 * @interface CourseAdditionalResource
 */
export interface CourseAdditionalResource {
    /**
     * 
     * @type {string}
     * @memberof CourseAdditionalResource
     */
    id?: string;
    /**
     * ID курса
     * @type {string}
     * @memberof CourseAdditionalResource
     */
    courseId?: string;
    /**
     * Тип ресурса
     * @type {string}
     * @memberof CourseAdditionalResource
     */
    resourceType?: CourseAdditionalResourceResourceTypeEnum;
    /**
     * Статус ресурса
     * @type {string}
     * @memberof CourseAdditionalResource
     */
    status: CourseAdditionalResourceStatusEnum;
    /**
     * Наименование файла, текста, ссылки
     * @type {string}
     * @memberof CourseAdditionalResource
     */
    name?: string;
    /**
     * ID файла
     * @type {string}
     * @memberof CourseAdditionalResource
     */
    fileId?: string;
    /**
     * Ссылка
     * @type {string}
     * @memberof CourseAdditionalResource
     */
    link?: string;
    /**
     * Текст контент
     * @type {string}
     * @memberof CourseAdditionalResource
     */
    content?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CourseAdditionalResourceResourceTypeEnum {
    FILE = 'FILE',
    LINK = 'LINK',
    TEXT = 'TEXT'
}
/**
    * @export
    * @enum {string}
    */
export enum CourseAdditionalResourceStatusEnum {
    SAVED = 'SAVED',
    PUBLISHED = 'PUBLISHED'
}

