import { RefObject, createRef } from 'react';
import CustomModal from '@components/Modal';
import { IStudyClass } from "@interfaces/index";
import AddFlowForm from './AddFlowForm';

interface Props {
  flowId?: string | null;
  modalOpen: boolean;
  handleToggleModal: (modalState: boolean, message?: string) => void;
  getAllFlows: () => void;
  flowList: Array<IStudyClass>;
  setFlowList: (list: Array<IStudyClass>) => void;
}

const AddFlowModal = ({
  flowId,
  modalOpen,
  handleToggleModal,
  getAllFlows,
  flowList,
  setFlowList
}: Props) => (
    <CustomModal
      open={modalOpen}
      handleToggleModal={handleToggleModal}
      width={550}
      showCloseIcon
    >
      <AddFlowForm 
        flowId={flowId}
        handleToggleModal={handleToggleModal}
        flowList={flowList}
        getAllFlows={getAllFlows}
        setFlowList={setFlowList}
        
      />
  </CustomModal>
)

export default AddFlowModal;