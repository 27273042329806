import { forwardRef, FC, ChangeEvent, useMemo } from 'react';
import { IMaskInput, IMask } from 'react-imask';
import { useField, useFormikContext } from "formik";
import { Box, Typography, OutlinedInput, useTheme } from "@mui/material";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  type: string;
}

const ipMask = (value: string) => {
  const subips = value.split('.')
  const invalidSubips = subips.filter(ip => {
    let ipValue = parseInt(ip)
    return ipValue < 0 || ipValue > 255
  })
  return invalidSubips.length > 0 ? false : true
}

const prepare = (value: string, func: IMask.MaskedFunction, b: any) => {
  const inputValue = func.value
  const subips = inputValue.split('.')
  const lastValue = subips.length > 1 ? subips[subips.length - 1] : subips[0]
  if(
    (lastValue.length === 3 && subips.length === 4)
    ||
    (
      parseInt(lastValue + value) > 255 && subips.length === 4
    )
  ) return ""
  const idAddDot = lastValue.length !== 0 && lastValue.length % 3 === 0
  return idAddDot || (lastValue.length === 2 && parseInt(lastValue + value) > 255) ? `.${value}` : `${value}`
}

const IPTextMaskCustom = forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    const definitionsRegex = useMemo(() => {
      switch (props.type) {
        case "extension":
          return /[a-z, 0-9]/
        // case "ip":
        //   return '\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b'
        default:
          return /[0-9]/
      }
    }, [props.type])

    return (
      <IMaskInput
        {...other}
        // mask={ipMask}
        mask={ipMask}
        prepare={prepare}
        definitions={{
          '#': definitionsRegex,
          // "*": /^[DE][0-9]{2,3}$/
        }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

interface Props {
  label: string;
  name: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  isRequired?: boolean;
}

const IPMaskedInput: FC<Props> = ({
  label,
  name,
  type = "text",
  disabled = false,
  placeholder,
  isRequired
}) => {
  const [field] = useField(name);
  const formikProps = useFormikContext();
  const { common } = useTheme().palette;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%"
        
      }}
    >
      <Typography>
        {label}
        {isRequired && <span style={{ color: common.errorColor }}>*</span>}
      </Typography>
      <OutlinedInput
        size="small"
        value={field.value || ""}
        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => formikProps.setFieldValue(name, e.target.value)}
        name={name}
        type={type}
        disabled={disabled}
        id={`formatted-${name}-mask-input`}
        inputComponent={IPTextMaskCustom as any}
        placeholder={placeholder}
        sx={{
          '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: disabled ? common.inputDisabledBg : "transparent"
          },
          '& label.Mui-focused': { color: common.primaryColor },
          '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': { borderColor: common.primaryColor },
            '&.Mui-focused fieldset': { borderColor: common.primaryColor }
          }
        }}
      />
    </Box>
  );
}

export default IPMaskedInput;