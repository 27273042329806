import { FC, ReactElement } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import { Field, useField, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import IconHints from '@components/IconHints';
import { SvgIconProps } from '@mui/material';

interface Props {
  label?: string;
  name: string;
  size?: "small" | "medium" | undefined;
  type?: string;
  disabled?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  multiline?: boolean;
  maxRows?: number;
  minRows?: number;
  min?: number;
  max?: number;
  helperText?: string;
  adornmentIcon?: ReactElement<SvgIconProps> | string;
  hintText?: string;
  handleChange?: (value: any) => void;
  onAdornmentClick?: () => void;
  showSymbolCount?: boolean;
  minChar?: number;
  maxChar?: number;
  width?: string;
  borderRadius?: string;
  height?: string
}

const FormikInput: FC<Props> = ({
  label,
  name,
  type = "text",
  size = "small",
  disabled = false,
  isRequired = false,
  placeholder,
  multiline = false,
  maxRows,
  minRows,
  width = "100%",
  adornmentIcon,
  min = -99999999999999,
  max = 9999999999999,
  hintText,
  handleChange,
  onAdornmentClick,
  showSymbolCount = false,
  minChar = 0,
  maxChar = 999999999,
  borderRadius = '4px',
  height = '40px'
}) => {
  const { common } = useTheme().palette;
  const [field] = useField(name); //, meta, helpers
  const formikProps = useFormikContext();

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    if (type === 'number') {
      if ((value >= min && value <= max) || value === "") {
        if (handleChange) handleChange(event);

        formikProps.setFieldValue(name, value);
      }
    } else {
      if (handleChange) handleChange(event);

      formikProps.setFieldValue(name, value);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%",
      }}
    >
      {label &&
        <Box display='flex' gap={1} alignItems='center'>
          <Typography>
            {label}{isRequired && <span style={{ color: common.errorColor }}>*</span>}
          </Typography>
          {hintText && <IconHints text={hintText} />}
        </Box>
      }
      <Field
        onChange={handleInputChange}
        component={TextField}
        multiline={multiline}
        size={size}
        type={type}
        variant="outlined"
        color="primary"
        name={name}
        value={field.value ?? ""}
        onWheel={(e: any) => e.target.blur()}
        placeholder={placeholder}
        InputProps={{
          classes: {
            notchedOutline: common.strokeSecondary,
          },
          endAdornment: adornmentIcon ?
            <InputAdornment position="end" onClick={onAdornmentClick} sx={{ cursor: 'pointer' }}>
              {adornmentIcon}
            </InputAdornment> :
            undefined
        }}
        sx={{
          '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: disabled ? common.inputDisabledBg : "transparent"
          },
          overflowY: multiline ? 'scroll' : null,
          '&::-webkit-scrollbar': {
            width: '7px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#E5E0EB',
            borderRadius: '6px'
          },
          '& label.Mui-focused': { color: common.primaryColor },
          '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
          '& .MuiOutlinedInput-root': {
            borderRadius,
            height: !multiline ? height : null,
            '&:hover fieldset': { borderColor: common.primaryColor },
            '&.Mui-focused fieldset': { borderColor: common.primaryColor }
          },
          width: width
        }}
        disabled={disabled}
        maxRows={maxRows}
        minRows={minRows}
      />
      {
        showSymbolCount && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                width: width
              }}
            >
              <Typography
                fontSize={"14px"}
                color={
                  (field.value ? field.value.toString().length : Number(field.value)) < minChar ||
                    (field.value ? field.value.toString().length : Number(field.value)) > maxChar
                    ? common.errorColor
                    : "#000000"
                }
              >
                {field.value ? field.value.toString().length : 0}
              </Typography>
              <Typography fontSize={"14px"}>/{maxChar}</Typography>
            </Box>
          </>
        )
      }
    </Box>
  )
}

export default FormikInput;