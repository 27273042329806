import React from 'react'
import { Box, Typography, useTheme } from '@mui/material';
import CustomButton from "@ui/Button";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";

const AdditionalSettings = () => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["sideMenu", "common", "course"]);
  const navigate = useNavigate();
  const styles = {
    color: common.primaryColor,
    p: 2,
    fontSize: '16px',
    lineHeight: '24px'
  }
  return (
    <Box sx={{
      border: `1px solid ${common.borderSecondary}`, backgroundColor: common.fontWhite, borderRadius: '24px', p: '24px', height: '546px'
    }}>
      <TableContainer component={Paper} sx={{
        fontSize: '16px', lineHeight: '24px'
      }}>
        <Table aria-label="My Table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#f5f3f780', ...styles }} onClick={() => { navigate('roles') }}>
                <Typography sx={{ cursor: 'pointer' }}>
                  {t("ROLES_AND_RIGHTS")}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>
                <Typography sx={{ cursor: 'pointer' }} onClick={() => { navigate('course-settings') }}>
                  {t("course:COURSE_SETTINGS")}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#f5f3f780', ...styles }}>
                <Typography sx={{ cursor: 'pointer' }} onClick={() => { navigate('assessment-system') }}>
                  {t("ASSESSMENT_SYSTEM")}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>
                <Typography sx={{ cursor: 'pointer' }} onClick={() => { navigate('integrations') }}>
                  {t("INTEGRATIONS")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </Box >
  )
}

export default AdditionalSettings
