import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';

import { serviceUser } from '@services/user';
import AvatarPicture from '@components/AvatarPicture';
import { DTO, initialDto } from '@pages/profile/models';
import AuthorModal from './AuthorModal';
import { removeHtml } from '@utils/functions';

const CourseTeacherCard = ({ userId, isAuthor }: { userId: string, isAuthor: boolean }) => {
  const [openTeacherModal, setOpenTeacherModal] = useState<boolean>(false)
  const [authorData, setAuthorData] = useState<DTO>(initialDto);
  const { common } = useTheme().palette;
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    if (userId) {
      serviceUser.getUserInfoById(userId).then((res) => {
        if (res.status === 200) {
          setAuthorData(res.data);
        }
      })
    }
  }, [userId]);

  return (
    <Box sx={{ background: "#fff", margin: "10px 0px", borderRadius: "10px" }}>
      {/* {isAuthor && <Box position="absolute" sx={{ background: "#385FE8", color: "#fff", fontSize: "14px", padding: "5px 10px", borderRadius: "15px", transform: "rotate(-45deg)", margin: "0 -15px" }}>Автор</Box>} */}
      <Box padding="24px">
        <Box display="flex" justifyContent="space-between" marginBottom="12px">
          <Box display="flex" gap={2} alignItems="center">
            <AvatarPicture id={authorData?.profilePhotoId} sxProps={{ width: "100px", height: "100px" }} />
            <Box>
              <Typography fontSize="16px" fontWeight={600}>{authorData?.firstName} {authorData?.lastName}</Typography>
              <Box sx={{ padding: "10px", background: "#F4F0FC", cursor: "pointer", borderRadius: "10px", marginTop: "14px", display: "flex", gap: 1, alignItems: "center", opacity: authorData?.detailInfo || authorData?.achievements.length > 0 ? 1 : 0.3 }}
                onClick={() => (authorData?.detailInfo || authorData?.achievements.length > 0) && setOpenTeacherModal(!openTeacherModal)}>
                <BadgeOutlinedIcon sx={{ color: common.primaryColor }} />
                <Typography color={common.primaryColor}>{t("common:actions.SHOW_PROFILE_CERTIFICATES")}</Typography>
              </Box>
            </Box>
          </Box>

          <Box>
            {(authorData?.instagram || authorData?.youtube || authorData?.facebook) && <Box display="flex" gap={1}>
              {authorData?.youtube && <YouTubeIcon sx={{ color: "#FF0000", fontSize: "28px", cursor: "pointer" }} onClick={() => { window.open(`https://${authorData?.youtube}`, "_blank") }} />}
              {authorData?.instagram && <InstagramIcon sx={{ color: "#AE38B4", fontSize: "28px", cursor: "pointer" }} onClick={() => { window.open(`https://${authorData?.instagram}`, "_blank") }} />}
              {authorData?.facebook && <FacebookIcon sx={{ color: "#337FFF", fontSize: "28px", cursor: "pointer" }} onClick={() => { window.open(`https://${authorData?.facebook}`, "_blank") }} />}
            </Box>}
          </Box>
        </Box>
        {removeHtml(authorData?.detailInfo || '') &&
          <Typography sx={{ display: 'inline', fontSize: "16px", color: "#8E8E93" }}>
            {removeHtml(authorData?.detailInfo || '').length > 180 ? `${removeHtml(authorData?.detailInfo || '').substring(0, 180)}...` : removeHtml(authorData?.detailInfo || '')}
          </Typography>
        }
      </Box>
      {openTeacherModal && <AuthorModal isAuthor={isAuthor} tutorInfo={authorData} open={openTeacherModal} handleToggleModal={() => setOpenTeacherModal(!openTeacherModal)} />}
    </Box>

  )
}

export default CourseTeacherCard
