import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from './List';

const BankOfTests = () => {
  const { t } = useTranslation(["sideMenu"]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: { sm: "36px", xs: '28px' }
        }}
      >
        {`${t('sideMenu:BANK_OF_TESTS')}`}
      </Typography>
      <List />
    </Box>
  )
}

export default BankOfTests;