import { useState } from 'react'
import { Box, Typography, useTheme, Button } from '@mui/material'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import CustomDatePicker from '@ui/DatePicker';

export const FreeCourseTimeFilter = ({ filter, setFilter }) => {
  const { t } = useTranslation(["freeCourse", "common", "enum"]);
  const { common } = useTheme().palette;
  const [showCertificate, setShowCertificate] = useState<boolean>(true)

  return (
    <Box>
        <Box sx={{
            display: 'flex',
            justifyContent: "space-between",
            cursor: "pointer"
        }}
            onClick={() => setShowCertificate(!showCertificate)}>
            <Typography fontWeight={600} fontSize="18px">{t("freeCourse:PASSING_PERIOD")}</Typography>
            {showCertificate ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
        </Box>
        { showCertificate && <Box marginTop="20px" display='flex' alignItems='center' flexDirection='row' sx={{'@media (max-width: 1680px)': {flexDirection: 'column'}}} >
            <Box sx={{ display: 'flex', alignItems: 'center', mr: '5px', my: '0', '@media (max-width: 1680px)': {mr: '0', my: '5px'}}} >
                <CustomDatePicker name='startDate' maxDate={filter.endDate} sx={{width: '100%', zIndex: 2}} fontSize='14px' size='medium' placeholder={t("freeCourse:START")} date={filter.startDate} handleDateChange={(newValue) => {
                     if (newValue && newValue instanceof Date && !isNaN(newValue.getTime())) {
                        setFilter((prev) => ({
                          ...prev,
                          startDate: dayjs(newValue).format('YYYY-MM-DD'),
                        }));
                      } else {
                        setFilter((prev) => ({
                          ...prev,
                          startDate: '',
                        }));
                      }
                }}/>
            </Box>
            <Box >
                <CustomDatePicker name='endDate' minDate={filter.startDate} sx={{width: '100%', zIndex: 2}} fontSize='14px' size='medium' placeholder={t("freeCourse:END")} date={filter.endDate} handleDateChange={(newValue) => {
                    if (newValue && newValue instanceof Date && !isNaN(newValue.getTime())) {
                        setFilter((prev) => ({
                          ...prev,
                          endDate: dayjs(newValue).format('YYYY-MM-DD'),
                        }));
                      } else {
                        setFilter((prev) => ({
                          ...prev,
                          endDate: '',
                        }));
                      }
                }}/>
            </Box>      
        </Box> }
    </Box>
  )
}