import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import * as types from "./types";
import { IProfile } from "@interfaces/index";

export const profileInitialDto: IProfile = {
  authorityInfo: {
    eduProgramId: null,
    orgId: null,
    orgNameEn: null,
    orgNameKz: null,
    orgNameRu: null,
    role: null,
    subdivisionId: null,
    userRoleId: null,
  },
  email: null,
  firstName: null,
  fullName: null,
  kcId: null,
  lastName: null,
  patronymic: null,
  pltaId: null,
  username: null,
  profilePhotoId: null,
};

export const accountState = {
  profileInfo: profileInitialDto,
  profileInfoPending: false,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState: accountState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(types.account("GET_PROFILE_INFO").PENDING, (state) => {
        state.profileInfoPending = true;
      })
      .addCase(types.account("GET_PROFILE_INFO").SUCCESS, (state, action: PayloadAction<IProfile>) => {
        state.profileInfo = action.payload;
        state.profileInfoPending = false;
      })
      .addCase(types.account("UPDATE_PROFILE_INFO").SUCCESS, (state, action: PayloadAction<IProfile>) => {
        localStorage.setItem('userInfo', JSON.stringify(action.payload));
        state.profileInfo = action.payload;
        state.profileInfoPending = false;
      })
      .addCase("RESET_STATE", () => accountState);
  },
})

export const { actions: accountActions } = accountSlice;
export const { reducer: accountReducer } = accountSlice;

const reducers = (
  state = accountState,
  action: { type: string; payload: IProfile }
) => {
  switch (action.type) {
    case types.account("GET_PROFILE_INFO").PENDING:
      return { ...state, profileInfoPending: true };
    case types.account("GET_PROFILE_INFO").SUCCESS:
      return {
        ...state,
        profileInfo: action.payload,
        profileInfoPending: false,
      };
    case types.account("UPDATE_PROFILE_INFO").SUCCESS:
      localStorage.setItem('userInfo', JSON.stringify(action.payload));
      return {
        ...state,
        profileInfo: action.payload,
        profileInfoPending: false,
      };
    case "RESET_STATE":
      return { ...accountState };
    default:
      return state;
  }
};

export default reducers;
