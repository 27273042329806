import * as yup from "yup";

export const profileValidationSchema = (t) => yup.object({
  lastName: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  firstName: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  birthdate: yup.date().typeError(t('common:validation.FORMAT_IS_INCORRECT')).nullable().test('checkBirthdate', t('common:validation.FORMAT_IS_INCORRECT'), function (value) {
    if(!value) return true

    return new Date(value) <= new Date()
  }),
});

export const emailValidationSchema = (t) => yup.object({
  email: yup
    .string()
    .required(t('common:validation.MANDATORY_FIELD'))
    .email(t('common:validation.FORMAT_IS_INCORRECT'))
    .max(255),
});

export const passwordValidationSchema = (t) => yup.object({
  oldPass: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  newPass: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  confirmPass: yup
    .string()
    .nullable()
    .required(t('common:validation.MANDATORY_FIELD'))
    .oneOf([yup.ref("newPass"), null], t('common:validation.PASSWORD_CONFIRMATION_IS_INCORRECT')),
});

export const socialMediaValidationSchema = (t) => yup.object({
  instagram: yup
  .string()
  .nullable()
  .matches(
    /^https:\/\/(www\.)?instagram\.com/i,
    t('common:validation.INCORRECT_URL')
  ),
  facebook: yup
    .string()
    .nullable()
    .matches(
      /^https:\/\/(www\.)?facebook\.com/i,
      t('common:validation.INCORRECT_URL')
    ),
  youtube: yup
    .string()
    .nullable()
    .matches(
      /^https:\/\/(www\.)?youtube\.com/i,
      t('common:validation.INCORRECT_URL')
    )
})