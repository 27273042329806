import { AxiosResponse } from "axios";
import API from "./api";


export class AuthorControllerApi extends API{
    private readonly url ='/course/author'

    updateConfirmationAuthor(id: string, confirm: boolean,reason?:string):Promise<AxiosResponse<null, any>>{
        return this.post(`${this.url}/${id}/update-status?confirm=${confirm}`)
    }
}