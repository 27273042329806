import { ReactComponent as LeftIcon } from "@assets/svg_files/home/left.svg";
import { ReactComponent as RightIcon } from "@assets/svg_files/home/right.svg";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSpringCarousel } from "react-spring-carousel";
import s from "./CarouselBlock.module.scss";
import { CarouselProps } from "@pages/home/types/home";

interface Props {
  carousel: CarouselProps[];
}
const CarouselBlock = (props: Props) => {
  const { carousel } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const { carouselFragment, slideToPrevItem, slideToNextItem } =
    useSpringCarousel({
      withLoop: true,
      items: carousel.map((e, index) => ({
        id: `item-${index}`,
        renderItem: (
          <div
            style={{
              backgroundImage: `url(${e.backgroundImage})`,
            }}
            className={s.block}
          >
            <div className={s.wrapper}>
              <div
                className={[s.title, e.balance ? s.balance : ""].join(" ")}
                style={{ maxWidth: e.maxWidth ? e.maxWidth : "61%" }}
              >
                {e.title}
              </div>
              <div className={s.text}>{e.text}</div>
              <Box>
                {e.button &&
                  <Button
                    onClick={e.onClick}
                    sx={{ padding: !matches ? "8px 18px" : "20px 36px", borderRadius: "12px" }}
                    size={matches ? "large" : "medium"}
                    variant="contained"
                    color="primary"
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      {e.button}
                    </Typography>
                  </Button>
                }
              </Box>
            </div>
          </div>
        ),
      })),
    });
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    const timer = setInterval(() => {
      !clicked && slideToNextItem();
    }, 6000);
    return () => clearInterval(timer);
  }, [clicked, slideToNextItem]);

  return (
    <div className={s.content}>
      <button
        className={s.left}
        onClick={() => {
          setClicked(true);
          slideToPrevItem();
        }}
      >
        <LeftIcon />
      </button>
      <div className={s.item}>{carouselFragment}</div>
      <button
        className={s.right}
        onClick={() => {
          setClicked(true);
          slideToNextItem();
        }}
      >
        <RightIcon />
      </button>
    </div>
  );
};

export default CarouselBlock;
