import { ListArray } from "@interfaces/index";
import {
    Box,
    CardContent,
    CardHeader,
    Divider,
    List,
    ListItem,
    Typography,
    useTheme
} from "@mui/material";

interface Props {
  title: string;
  list: ListArray[];
}
const ListInformation = (props: Props) => {
  const { title, list } = props;
  const { common } = useTheme().palette;
  return (
    <Box
      sx={{
        border: `1px solid ${common.borderSecondary}`,
        borderRadius: 1.5,
      }}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{
          sx: {
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "24px",
          },
        }}
      />
      <Divider />
      <CardContent
        sx={{
          px: 2,
          py: 1.5,
          "&:last-child": {
            paddingBottom: 2,
          },
        }}
      >
        <List>
          {list.map((item,index) => (
            <ListItem
              sx={{
                gap: 1,
              }}
              key={index}
            >
              <Typography
                sx={{
                  color: common.borderSecondary,
                  minWidth: "180px",
                }}
              >
                {item?.key}:
              </Typography>
              <Box
                display={"flex"}
                gap={1}
                alignContent={"center"}
                sx={item?.colored ? {
                  cursor: "pointer",
                  color: common.primaryColor,
                  fill: common.primaryColor,
                } :undefined}
                onClick={()=>item && item.onClick && item?.onClick()}
              >
                <Typography>{item?.value}</Typography>
                {item?.svg && item.svg}
              </Box>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Box>
  );
};

export { ListInformation };

