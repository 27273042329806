import * as yup from 'yup';

export const validationSchema = (t) => yup.object({
  date: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
  addVisit: yup.array()
    .of(
      yup.object().shape({
        typeOccupations: yup.string().nullable().required(t('common:validation.MANDATORY_FIELD')),
        name: yup.string()
          .when(['typeOccupations'], {
            is: '2',
            then: yup.string().max(12, t('common:validation.MAX_NUMBER_OF_SYMBOLS', { length: 12 })).required(t('common:validation.MANDATORY_FIELD')),
            otherwise: yup.string().nullable(),
          }),
        // name: yup.string().when(['typeOccupations'],{
        //   is: (typeOccupations) => typeOccupations ==="2",
        //   then: yup.string().required("Обязательное поле")
        // })
      })
    )
  // .test(""),
  // test: yup.array().of(yup.object({
  //   date: yup.string().required("Обязательное поле"),
  //   // checked: yup.boolean(),
  // })),
});