import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import Chip from '@mui/material/Chip';
import { SxProps, useTheme } from '@mui/material/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ErrorIcon from '@mui/icons-material/Error';
import { useField } from 'formik';
import { get, capitalize, unionBy } from 'lodash';

import IconHints from '@components/IconHints';
import GradingSystemTable from '@components/GradingSystemTable';
import { formatDateWithHourMinute } from '@utils/functions';
import { FilterOptionsState } from '@mui/material';

interface Props {
  label?: string;
  name: string;
  options?: any;
  isRequired?: boolean;
  multiple?: boolean;
  sx?: SxProps
  valueKey?: string;
  placeholder?: string;
  valueAsObject?: boolean;
  disabled?: boolean;
  disabledOptions?: Array<number | string>;
  optionDisableKey?: string;
  clearable?: boolean;
  onChange?: any;
  onInputChange?: (value: string) => void;
  onChangeParams?: any;
  selectAll?: boolean;
  hintText?: string;
  gradingSystemId?: number | null;
  isGradingSystemVisible?: boolean;
  maxSelectedItems?: number;
  isInputEnterable?: boolean;
  clearOptions?: boolean;
  filterOptions?: (options: any[], state: FilterOptionsState<string>) => any[]
}

const FormikAutocomplete: React.FC<Props> = ({
  label,
  name,
  options = [],
  isRequired = false,
  multiple = false,
  sx = {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%"
  },
  valueKey = "id",
  placeholder,
  valueAsObject = false,
  disabled = false,
  disabledOptions = [],
  optionDisableKey = "id",
  clearable = true,
  onChange,
  onInputChange,
  onChangeParams,
  selectAll = false,
  hintText,
  gradingSystemId = null,
  isGradingSystemVisible = false,
  maxSelectedItems = 9999999999999,
  isInputEnterable = true,
  clearOptions = false,
  filterOptions,
  ...rest
}) => {
  const lang = localStorage.getItem("i18nextLng") || "kz";
  const { t } = useTranslation(['common', 'enum']);
  const { common } = useTheme().palette;
  const [field, meta, helpers] = useField(name);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('');

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  const handleChange = (value: any) => {
    if (valueAsObject) {
      if (multiple) {
        if (selectAll && value.some((v: any) => v?.id === 'selectAll')) {
          if (options.length === field?.value?.length) helpers.setValue([]);
          else helpers.setValue(options.map((option: any) => option));
        } else {
          helpers.setValue(value);
        }
      } else {
        helpers.setValue(value ? value : { id: null });
      }
    } else {
      if (multiple) {
        if (selectAll && value.some((v: any) => v?.id === 'selectAll')) {
          if (options.length === field?.value?.length) helpers.setValue([]);
          else if (disabledOptions.length > 0) {
            helpers.setValue(options.filter((option) => !disabledOptions.includes(option?.id)).map((option: any) => option[valueKey]));
          }
          else helpers.setValue(options.map((option: any) => option[valueKey]));
        } else {
          helpers.setValue(value.map((option: any) => option[valueKey]));
        }
      } else {
        helpers.setValue(value ? value[valueKey] : null);
      }
    }

    if (onChange) {
      onChange(value, onChangeParams);
    }
  };

  const handleInputChange = (event: any, value: string, reason: string) => {
    if (onInputChange) {
      if (event && event.type === 'blur') {
        onInputChange('');
      } else if (reason !== 'reset') {
        onInputChange(value);
      }
    }
    setAutocompleteInputValue(value);
  }

  const getValue = () => {
    if (valueAsObject) {
      if (multiple) {
        return field.value ? field.value : [];
      } else {
        if (field.value && options.length > 0) {
          return getOptions().find((option: any) => option[valueKey] === field.value[valueKey]);
        }
        return null;
      }
    } else {
      if (multiple) {
        return field.value
          ? getOptions().filter((option: any) => field.value.includes(option[valueKey]))
          : [];
      } else {
        return getOptions().find((option: any) => option[valueKey] === field.value) || null;
      }
    }
  };

  const getOptions = () => {
    if (selectAll && options.length > 1) {
      const selectAllOption = { id: 'selectAll', text: 'ALL' };
      const mergedOptions = unionBy(selectedOptions, options, valueKey);
      return [selectAllOption, ...mergedOptions];
    } else {
      return unionBy(options, selectedOptions, valueKey);
    }
  };

  useEffect(() => {
    if (!field.value || field.value.length < 1) setAutocompleteInputValue('');
  }, [field.value]);

  useEffect(() => {
    if (multiple && field.value) {
      if (valueAsObject) {
        setSelectedOptions(field.value);
      } else {
        let arrayOfObjects: Array<any> = [];
        field.value.forEach((fieldValue: string | number) => {
          if (options.some((obj: any) => obj[valueKey] === fieldValue)) {
            arrayOfObjects.push(options.find((obj: any) => obj[valueKey] === fieldValue))
          }
        });
        setSelectedOptions(arrayOfObjects);
      }
    }

    return () => {
      clearOptions && setSelectedOptions([]);
    }
  }, [multiple, valueAsObject, options, valueKey, field.value, clearOptions]);

  const [openModal, setOpenModal] = useState(false);
  const handleToggleModal = (modalState: boolean) => setOpenModal(modalState);

  return (
    <Box sx={sx}>
      <Box display='flex' gap={1} alignItems='center'>
        <Typography sx={{ display: 'flex' }}>{label} {isRequired && <span style={{ color: common.errorColor }}>*</span>}</Typography>
        {hintText && <IconHints text={hintText} />}
        {isGradingSystemVisible && gradingSystemId !== 4 &&
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '28px',
              height: '28px',
              borderRadius: '50%',
              backgroundColor: common.primaryColor,
              padding: '10px',
              cursor: 'pointer'
            }}
            onClick={() => handleToggleModal(true)}
          >
            <ErrorIcon sx={{ color: 'white', width: '20px' }} />
          </Box>
        }
        {openModal &&
          <GradingSystemTable
            open={openModal}
            handleToggleModal={handleToggleModal}
            gradingSystemId={gradingSystemId}
            initialData={[]}
          />
        }
      </Box>
      <Autocomplete
        size='small'
        multiple={multiple}
        options={getOptions()}
        disabled={disabled}
        disableCloseOnSelect={multiple}
        disableClearable={!clearable}
        disabledItemsFocusable
        getOptionLabel={(option) => {
          if (name === 'scheduleId') {
            return (`${formatDateWithHourMinute(option.startDate)} - ${formatDateWithHourMinute(option.endDate)}  ${option.name}`)
          }
          return (
            (option?.text && t(`enum:${option.text}`)) ||
            (option?.userFullName && option.userFullName) ||
            (option?.fullName && option.fullName) ||
            (option?.name && option.name) ||
            get(option, `name${capitalize(lang)}`) || ''
          )
        }}
        isOptionEqualToValue={(option, value) => { return value && option[valueKey] === value[valueKey]; }}
        ListboxProps={{
          sx: {
            maxHeight: 200,
            '&::-webkit-scrollbar': { width: '7px' },
            '&::-webkit-scrollbar-thumb': { backgroundColor: '#E5E0EB', borderRadius: '6px' }
          }
        }}
        renderOption={(props: any, option: any, { selected }: any) => {
          if (name === 'scheduleId') // exception
            return (
              <li
                {...props}
                key={option?.id}
                style={{
                  display: 'flex',
                  gap: '8px',
                  borderBottom: `1px solid ${common.strokeSecondary}`
                }}
              >
                <Typography sx={{ display: 'flex', width: '45%' }}>
                  {`${formatDateWithHourMinute(option.startDate)}-${formatDateWithHourMinute(option.endDate)}`}
                </Typography>
                <Typography sx={{ display: 'flex', fontSize: '12px', color: '#8E8E93', width: '50%' }}>{option.name}</Typography>
              </li>
            );
          else if (option?.isDisabled) return null;
          return (
            <li {...props} key={option?.id}>
              {multiple &&
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: "8px", color: common.primaryColor }}
                  checked={selected || (options.length === field?.value?.length)}
                />
              }
              {
                (option?.text && t(`enum:${option.text}`)) ||
                (option?.userFullName && option.userFullName) ||
                (option?.fullName && option.fullName) ||
                (option?.name && option.name) ||
                get(option, `name${capitalize(lang)}`)
              }
            </li>
          )
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            return (
              <Chip
                label={
                  (option?.text && t(`enum:${option.text}`)) ||
                  (option?.userFullName && option.userFullName) ||
                  (option?.fullName && option.fullName) ||
                  (option?.name && option.name) ||
                  get(option, `name${capitalize(lang)}`)
                }
                {...getTagProps({ index })}
                disabled={disabledOptions.includes(option[optionDisableKey])}
                sx={{
                  m: '0 !important',
                  height: '28px'
                }}
              />
            )
          })
        }
        getOptionDisabled={(option) => {
          return (
            disabledOptions.includes(option[optionDisableKey]) ||
            (selectedOptions.length >= maxSelectedItems && !selectedOptions.includes(option)) ||
            (option?.id === 'selectAll' && maxSelectedItems < options.length)
          );
        }}
        value={getValue()}
        inputValue={autocompleteInputValue}
        onChange={(_, value) => handleChange(value)}
        onInputChange={(event, value, reason) => handleInputChange(event, value, reason)}
        onBlur={handleBlur}
        clearOnBlur={false}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: "#FFFFFF",
                maxHeight: '200px',
                overflowY: 'auto',
                '&::-webkit-scrollbar': { width: '7px' },
                '&::-webkit-scrollbar-thumb': { backgroundColor: disabled ? '#9D9D9D' : '#E5E0EB', borderRadius: '6px' },
              },
              '& .MuiInputBase-root.Mui-disabled': {
                backgroundColor: disabled ? common.inputDisabledBg : "transparent"
              },
              '& label.Mui-focused': { color: common.primaryColor },
              '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
              '& .MuiOutlinedInput-root': {
                position: 'unset',
                '&:hover fieldset': { borderColor: common.primaryColor },
                '&.Mui-focused fieldset': { borderColor: common.primaryColor }
              }
            }}
            inputProps={{ ...params.inputProps, readOnly: !isInputEnterable }}
            placeholder={placeholder || t('placeholders.CHOOSE_ITEM')}
            error={meta.touched && Boolean(meta.error)}
          />
        )}
        {...rest}
      />
      {meta.touched && Boolean(meta.error) &&
        <FormHelperText error={Boolean(meta.error)} sx={{ px: 1.75, mt: '-4px' }}>
          {meta.error}
        </FormHelperText>
      }
    </Box>
  );
};

export default FormikAutocomplete;