import React, { useState } from 'react'
import { DTO } from '../models';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import { CartesianGrid, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Line } from 'recharts'


export const ViolationsLineCharts = ({ aeroInfo, videoRef, dtoCourse }: { aeroInfo: any, videoRef: any, dtoCourse: DTO }) => {
  const { common } = useTheme().palette
  const { t } = useTranslation(["aero", "common"]);

  function timeOfNumber(value) {
    let time = '00';
    switch (value.toString().length) {
      case 1: return time = `0${value}`
      case 2: return time = `${value}`
      default: time = '00'
    }
    return time
  }

  function violationTime(value) {
    const minutes = timeOfNumber(Math.trunc(value / 60))
    const seconds = timeOfNumber(value % 60)
    return `${minutes}:${seconds}`
  }

  function capitalizeViolationHeaviness(value) {
    const regex = new RegExp(`[_/](.)`, 'g');
    return value.replace(regex, (match, group) => {
      const capitalizedGroup = group.charAt(0).toUpperCase() + group.slice(1);
      return capitalizedGroup;
    });
  }

  function heavinessNumber(value) {
    let number;
    switch (value) {
      case "EASY": return number = 1
      case "HEAVY": return number = 3
      case "MEDIUM": return number = 2
      default: number = 2
    }
  }

  const chartInfo = aeroInfo?.cheatings.map((cheating, index) => {
    return {
      time: index + 1,
      name: t(`aero:${cheating.cheatingType}`),
      startTimeInSeconds: cheating.startTime,
      startTime: violationTime(cheating.startTime),
      endTime: violationTime(cheating.endTime),
      heaviness: dtoCourse?.violation && heavinessNumber(dtoCourse?.violation[capitalizeViolationHeaviness(cheating?.cheatingType.toLowerCase().replace("@", ""))]),
      heavinessText: dtoCourse?.violation && t(`aero:${dtoCourse?.violation[capitalizeViolationHeaviness(cheating?.cheatingType.toLowerCase().replace("@", ""))]}`)
    }
  })

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Box sx={{ background: "#9574C1", width: "300px", height: "90px", borderRadius: 6, color: "white", padding: "5px 15px" }}>
          <Typography fontSize="14px" marginBottom="10px">{payload[0].payload.name}</Typography>
          <Box display="flex" gap={5}>
            <Typography fontSize="12px">{t("aero:TIME_VIOLATION")}:</Typography>
            <Typography fontSize="12px">{payload[0].payload.startTime} - {payload[0].payload.endTime}</Typography>
          </Box>
          <Box display="flex" gap={4}>
            <Typography fontSize="12px">{t("aero:SEVERITY_VIOLATION")}:</Typography>
            <Typography fontSize="12px">{payload[0].payload.heavinessText}</Typography>
          </Box>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={{ width: "90%", padding: "20px", border: `1px solid ${common.borderSecondary}`, borderRadius: 4 }}>
      <Typography fontSize="14px">{t("aero:VIOLATION_COMMITTED_TESTING")}</Typography>
      <Typography fontSize="14px" color="#8E8E93" marginBottom="10px">{t("aero:BY_TYPE_SEVERITY")}</Typography>
      <ResponsiveContainer height={200}>
        <LineChart
          width={500}
          height={200}
          data={chartInfo}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="1 0" />
          <XAxis dataKey="time" />
          <YAxis
            tickFormatter={(value) => value === 1 ? t(`aero:EASY`) : value === 2 ? t(`aero:MEDIUM`) : t(`aero:HEAVY`)}
            ticks={[1, 2, 3]}
            interval="preserveStartEnd" />
          {
            //@ts-ignore
            <Tooltip content={<CustomTooltip />} />
          }
          <Line type="monotone" dataKey="heaviness" stroke="#8884d8" fill="#8884d8"
            dot={{ strokeWidth: 10 }}
            activeDot={{
              style: { cursor: "pointer" },
              onClick: (_, value) => {
                //@ts-ignore
                return videoRef.current.currentTime = value?.payload?.startTimeInSeconds
              },
              strokeWidth: 10,
            }
            }>
          </Line>
        </LineChart>
      </ResponsiveContainer>
    </Box >
  )
}
