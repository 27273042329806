import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { AlertColor } from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { get, capitalize } from 'lodash';

import { serviceUser } from "@services/user";
import { serviceRole } from "@services/role";
import { serviceDict } from "@services/dictionary";
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import GenerateModal from "@components/modals/GenerateModal";
import OrganizationCell from "@components/OrganizationCell";
import AvatarPicture from "@components/AvatarPicture";
import Message from "@components/Message";
import CustomButton from "@ui/Button";
import Pagination from "@ui/Pagination";
import CustomAutocomplete from "@ui/Autocomplete";
import { IUser, IRole, IDictionary } from "@interfaces/index";
import { IOrganization } from "@pages/dictionary/Organization/models";
import { IFilter, initialFilter } from "./models";
import './styles.scss';
import { decodeContentDispositionFilename } from "@utils/functions";
import { LoadingButton } from "@mui/lab";
const avatarStyle = {
  cursor: "pointer",
  width: "43px",
  height: "43px",
  mr: '12px'
};
interface IStatus {
  id: number;
  value: boolean;
  name: string;
}

const InternalUserList = () => {
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const { t } = useTranslation(["user", "common", "sideMenu", "enum"]);
  const lang = localStorage.getItem("i18nextLng") || "kz";
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role, orgId } = profile.authorityInfo;
  const [usersList, setUsersList] = useState<IUser[]>([]);
  const [roleList, setRoleList] = useState<IRole[]>([]);
  const [userId, setUserId] = useState<string | null>(null);
  const [checkedUserIds, setCheckedUserIds] = useState<string[]>([]);
  const [checkedAllValue, setCheckedAllValue] = useState<boolean>(false);
  const [count, setCount] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [filter, setFilter] = useState<IFilter>(initialFilter);
  const [newPassword, setNewPassword] = useState<[]>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(true);
  const [organizationsList, setOrganizationsList] = useState<IOrganization[]>([]);
  const [subDivisionList, setSubdivisionList] = useState<IDictionary[]>([]);
  const [eduProgrammList, setEduProgramList] = useState<IDictionary[]>([]);
  const [generatePasswordModalOpen, setGeneratePasswordModalOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [isLoadingDownloadFile, setIsLoadingDownloadFile] = useState<boolean>(false)

  useEffect(() => {
    if (usersList.length > 0) {
      if (usersList.length === checkedUserIds.length) {
        setCheckedAllValue(true);
      } else {
        setCheckedAllValue(false);
      }
    }
    return localStorage.setItem("plt_academy", JSON.stringify(newPassword));
  }, [newPassword, checkedUserIds, usersList]);

  const handleChangePage = (newPage: number) => setCurrentPage(newPage);

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
    setCurrentPage(0);
  };

  const handleChangeAutocomplete = (name: string, value: IDictionary | IOrganization | IStatus) => {
    setCurrentPage(0);
    setFilter({
      ...filter,
      [name]: value
    });
  }

  const searchByName = (searchValue: string) => {
    setCurrentPage(0);
    const new_filter = { ...filter, name: searchValue };
    setFilter(new_filter);
  };

  const deleteUser = () => {
    return serviceUser.deleteUser(userId).then((res) => {
      if (res.status === 200) {
        getUserList(currentPage, rowsPerPage, filter);
        setMessage(t('common:messages.USER_DELETED'))
        setMessageType('success')
        setMessageOpen(true);
        setTimeout(() => {
          setMessageOpen(false)
        }, 5000)
      }
    }).catch(err => {
      if (err.response.data.message === 'The user cannot be deleted because he is a member of courses') {
        setMessage(t('common:messages.USER_CANNOT_BE_DELETED'))
        setMessageType('error')
        setMessageOpen(true)
        setTimeout(() => {
          setMessageOpen(false)
        }, 5000)
      }
    }).finally(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    });
  };

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteUser,
    modalTitle: t(`common:DELETE_ITEM_CONFIRMATION_TEXT`),
  })

  useEffect(() => {
    serviceRole
      .getAllRolesForSelect()
      .then((res) => {
        if (res.status === 200) setRoleList(res.data);
      });
    serviceDict
      .getDictListForDropdown("BRANCH")
      .then((res) => {
        if (res.status === 200) setOrganizationsList(res.data.filter((org: IOrganization) => org.id !== 0))
      })
  }, []);

  useEffect(() => {
    getUserList(currentPage, rowsPerPage, filter);
  }, [currentPage, rowsPerPage, filter]);

  const getUserList = (pageNumber: number, size: number, filter: IFilter) => {
    serviceUser
      .getUserList({
        pageNumber, size, filter,
        // sorting: { "lastName": "asc", "firstName": "asc", "patronymic": "asc" }
      })
      .then((res) => {
        if (res.status === 200) {
          setUsersList(res.data.dtoList);
          setCount(res.data.totalElements);
        }
      });
  }

  useEffect(() => {
    if (filter.orgId || orgId) {
      serviceDict
        .getDictionaryListByOrgIdForDropdown(filter.orgId ? filter.orgId : orgId, 'EDU_PROGRAM')
        .then((res) => {
          if (res.status === 200) setEduProgramList(res.data)
        })
      serviceDict
        .getDictionaryListByOrgIdForDropdown(filter.orgId ? filter.orgId : orgId, 'SUBDIVISION')
        .then((res) => {
          if (res.status === 200) setSubdivisionList(res.data)
        })
    } else {
      serviceDict
        .getDictListForDropdown('SUBDIVISION')
        .then((res) => {
          if (res.status === 200) setSubdivisionList(res.data)
        })
      serviceDict
        .getDictListForDropdown('EDU_PROGRAM')
        .then((res) => {
          if (res.status === 200) setEduProgramList(res.data)
        })
    }
  }, [filter.orgId, orgId])

  useEffect(() => {
    setCheckedAllValue(false);
    setCheckedUserIds([]);
  }, [currentPage]);

  const handleToggleGeneratePasswordModal = (modalState: boolean) => {
    setGeneratePasswordModalOpen(modalState);
  }

  const handleSubmitGeneratePassword = async (event: React.MouseEvent<HTMLButtonElement>, creationState: boolean) => {
    event.preventDefault();
    await serviceUser.generatePassword(checkedUserIds, creationState).then((res) => {
      if (res.status === 200) {
        setCheckedUserIds([]);
        setCheckedAllValue(false);
        setNewPassword(res.data);
        window.open("/newpasswordlist");
      }
    }).catch((err) => {
      alert(t('common:messages.LOGIN_PASSOWRD_WAS_NOT_GENERATED'));
    });
  };

  const downloadUserList = async () => {
    setIsLoadingDownloadFile(true)
    await serviceUser.downloadUserList({ pageNumber: 0, size: count, filter: filter, sorting: {} }).then((res) => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          "download",
          `${decodeContentDispositionFilename(res.headers["content-disposition"])}`
        );
        document.body.appendChild(link);
        link.click();
        link.remove()
        window.URL.revokeObjectURL(url)
        setIsLoadingDownloadFile(false)
      }
    })
  }

  const style = {
    '& label.Mui-focused': { color: common.primaryColor },
    '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': { borderColor: common.primaryColor },
      '&.Mui-focused fieldset': { borderColor: common.primaryColor }
    }
  };

  const tableStyle = {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#7D7D7D",
  }

  const statusList = [
    {
      id: 1,
      name: t('ACTIVE'),
      value: false
    },
    {
      id: 2,
      name: t('BLOCKED'),
      value: true
    }
  ]

  return (
    <Box>
      {DeleteModal}
      {messageOpen &&
        <Box sx={{ mb: '24px', mt: '-12px' }}>
          <Message
            type={messageType}
            message={message}
            setMessageOpen={setMessageOpen}
          />
        </Box>
      }
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${common.borderSecondary}`,
        backgroundColor: common.fontWhite,
        borderRadius: '24px',
        p: '24px',
        width: '100%',
        minHeight: '650px'
      }}>
        <Box sx={{ mb: '24px' }}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                color="primary"
                sx={{ width: "100%", justifyContent: "flex-start", ...style }}
                placeholder={t("common:placeholders.SEARCH_BY_FULL_NAME_AND_ID")}
                name="searchValue"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => searchByName(e.target.value)}
              />
            </Grid>
            <Grid item xs>
              <RouterLink to={"/users/new"} style={{ textDecoration: "none" }}>
                <CustomButton
                  width="100%"
                  height="40px"
                  backgroundColor={common.primaryColor}
                  borderRadius="8px"
                  fontColor={common.fontWhite}
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                    alignItems: "center",
                  }}
                >
                  {t("common:actions.ADD")}
                </CustomButton>
              </RouterLink>
            </Grid>
            <Grid item xs>
              <Box sx={{
                border: `1px solid ${common.borderSecondary}`,
                height: '40px',
                width: '124px',
                backgroundColor: !openFilter ? 'white' : `${common.primaryColor}`, borderRadius: '9px', display: 'flex', alignItems: 'center', px: '4px', cursor: 'pointer',
                color: !openFilter ? 'black' : 'white',
                ":hover": { borderColor: `${common.borderPrimary}` }
              }}
                onClick={() => setOpenFilter(!openFilter)}
              >
                <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "20px", color: !openFilter ? 'black' : 'white', mr: '8px', ml: '4px' }}>
                  {t('common:FILTER')}
                </Typography>
                <TuneIcon />
                {
                  openFilter ? (
                    <ArrowDropUpIcon sx={{ width: '30px', height: '30px' }} />
                  ) : (
                    <ArrowDropDownIcon sx={{ width: '30px', height: '30px' }} />
                  )
                }
              </Box>
            </Grid>
            <Grid item xs>
              <LoadingButton
                disabled={!filter.orgId}
                onClick={() => downloadUserList()}
                loading={isLoadingDownloadFile}
                sx={{
                  backgroundColor: filter.orgId ? `${common.primaryColor}` : `${common.borderSecondary}`,
                  height: "40px",
                  "&:hover": {
                    backgroundColor: `${common.primaryColor}`
                  },
                  "& .MuiCircularProgress-root": {
                    color: `${common.white}`
                  }
                }}
                loadingPosition="end"
                endIcon={<FileDownloadOutlinedIcon sx={{ color: common.white }} />}
              >
                <Typography
                  sx={{
                    color: common.white,
                    display: "flex",
                  }}
                >
                  {t("common:actions.DOWNLOAD_EXCEL")}
                </Typography>
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        {
          openFilter && (
            <>
              <Box sx={{
                display: 'flex', gap: 1, mb: '40px'
              }}>
                {role === "ADMIN" &&
                  <CustomAutocomplete
                    name='orgId'
                    sx={{ minWidth: "50%", height: '35px', ...style }}
                    options={organizationsList}
                    label={t("ORGANIZATION")}
                    onChange={(name: string, value: IDictionary) => handleChangeAutocomplete(name, value)}
                    isInputEnterable
                    customIconDisabled={false}
                  />
                }
                <CustomAutocomplete
                  name='roleId'
                  sx={{ width: role === "ADMIN" ? '25%' : '50%', height: '35px', ...style }}
                  options={roleList}
                  label={t('user:ROLE')}
                  onChange={(name: string, value: IDictionary) => handleChangeAutocomplete(name, value)}
                  customIconDisabled={false}
                />
                <CustomAutocomplete
                  name='isDisabled'
                  sx={{ width: role === "ADMIN" ? '25%' : '50%', height: '35px', ...style }}
                  options={statusList}
                  label={t('STATUS')}
                  valueKey='value'
                  onChange={(name: string, statusObj: IStatus) => handleChangeAutocomplete(name, statusObj)}
                  customIconDisabled={false}
                />
              </Box>
              <Box sx={{
                display: 'flex', gap: 1, mb: '24px'
              }}>
                <CustomAutocomplete
                  name='eduProgramId'
                  sx={{ width: '50%', height: '35px', ...style }}
                  options={eduProgrammList}
                  label={t('enum:EDU_PROGRAM')}
                  onChange={(name: string, value: IDictionary) => handleChangeAutocomplete(name, value)}
                  isInputEnterable
                  customIconDisabled={false}
                />
                <CustomAutocomplete
                  name='subdivisionId'
                  sx={{ width: '50%', height: '35px', ...style }}
                  options={subDivisionList}
                  label={t('enum:SUBDIVISION')}
                  onChange={(name: string, value: IDictionary) => handleChangeAutocomplete(name, value)}
                  isInputEnterable
                  customIconDisabled={false}
                />
              </Box>
            </>
          )
        }
        <TableContainer component={Paper} sx={{ mt: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    checked={checkedAllValue}
                    sx={{ color: common.primaryColor }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCheckedAllValue(true);
                        setCheckedUserIds(usersList.map((user) => user.id));
                      } else {
                        setCheckedUserIds([]);
                        setCheckedAllValue(false);
                      }
                    }}
                  />
                </TableCell>
                <TableCell sx={tableStyle}>
                  {t("FULL_NAME")}
                </TableCell>
                {role === "ADMIN" ?
                  <TableCell sx={tableStyle}>
                    {t("ROLE")} / {t("ORGANIZATION")}
                  </TableCell> :
                  <TableCell sx={tableStyle}>
                    {t("ROLE")}
                  </TableCell>
                }
                <TableCell sx={tableStyle}>
                  {t("EMAIL")}
                </TableCell>
                <TableCell sx={tableStyle}>
                  {t("STATUS")}
                </TableCell>
                <TableCell sx={tableStyle}>
                  {t("EXTERNAL_ID")}
                </TableCell>
                <TableCell sx={tableStyle}>
                  {" "}
                </TableCell>
              </TableRow>
            </TableHead>
            {usersList.length > 0 &&
              <TableBody>
                {usersList.map((user, index) => {
                  let currentUserRoles = "";
                  if (user.userRoles && user.userRoles?.length) {
                    user.userRoles.forEach((userRole, index) => {
                      if (index === 0) currentUserRoles += get(userRole.role, `name${capitalize(lang)}`);
                      else currentUserRoles += `, ${get(userRole.role, `name${capitalize(lang)}`)}`;
                    });
                  }
                  return (
                    <TableRow key={`${index}_${user.id}`} sx={{ ':hover': { backgroundColor: '#F5F5F5' } }}>
                      <TableCell sx={{ width: "40px" }}>
                        <Checkbox
                          color="primary"
                          sx={{ color: common.primaryColor }}
                          checked={checkedUserIds.includes(user.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setCheckedUserIds((checkedUserIds) => [
                                ...checkedUserIds,
                                user.id,
                              ]);
                            } else {
                              setCheckedUserIds(
                                checkedUserIds.filter(
                                  (item) => !user.id.includes(item)
                                )
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ ...tableStyle, width: 200 }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            color: "#323C47",
                            fontWeight: "500",
                            lineHeight: "20px",
                            height: "20px",
                          }}
                          onClick={() => navigate(`/users/${user.id}`)}
                        >
                          <AvatarPicture
                            id={user.profilePhotoId}
                            lastName={user.lastName || undefined}
                            sxProps={avatarStyle}
                          />
                          {user.lastName} {user.firstName} {user?.patronymic}
                        </Box>
                      </TableCell>
                      {role === "ADMIN" ?
                        <TableCell>
                          {user.userRoles?.length > 0 && user.userRoles.map((userRole) => {
                            return (
                              <Typography key={userRole.id}>
                                <span style={{ marginRight: 5 }}>{get(userRole.role, `name${capitalize(lang)}`)} /</span>
                                <OrganizationCell id={userRole.orgId} />
                              </Typography>
                            );
                          })}
                        </TableCell> :
                        <TableCell sx={tableStyle}>
                          {currentUserRoles}
                        </TableCell>
                      }
                      <TableCell sx={tableStyle}>
                        {user.email}
                      </TableCell>
                      <TableCell sx={tableStyle}>
                        {user.isDisabled === null ? "" : user.isDisabled ? `${t("BLOCKED")}` : `${t("ACTIVE")}`}
                      </TableCell>
                      <TableCell sx={tableStyle}>
                        {user.id}
                      </TableCell>
                      <TableCell>
                        {(role === user.createdBy || (role === "LOCAL_ADMIN" && user.createdBy !== "PLATONUS")) &&
                          <DeleteOutlineIcon
                            sx={{ color: common.errorColor, cursor: 'pointer' }}
                            onClick={() => { setUserId(user.id); openDeleteConfirmModal(user.id) }}
                          />
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            }
          </Table>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Pagination
              count={count}
              page={currentPage}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            mt: "24px",
            minWidth: "290px"
          }}>
          <Box>
            <CustomButton
              disabled={checkedUserIds.length > 0 ? false : true}
              onClick={() => handleToggleGeneratePasswordModal(true)}
              height="40px"
              backgroundColor={common.primaryColor}
              borderRadius="8px"
              fontColor={common.fontWhite}
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                alignItems: "center",
                color: "#FFFFFF",
              }}
            >
              {t("GENERATE_PASSWORD")}
            </CustomButton>
          </Box>
        </Box>
      </Box>
      {generatePasswordModalOpen &&
        <GenerateModal
          handleToggleGeneratePasswordModal={handleToggleGeneratePasswordModal}
          modalOpen={generatePasswordModalOpen}
          handleSubmitGeneratePassword={handleSubmitGeneratePassword}
        />
      }
    </Box >
  );
};

export default InternalUserList;